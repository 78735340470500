import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Anchor, Stack, Text, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";

import { MappedSkuPair, UnknownBarcodeProblemRow } from "common/components/ProblemsTable/types";
import { ProblemBaseBlade, ProblemBladeProps } from "../ProblemBaseBlade";
import { PROBLEM_TYPE_LABEL_MAP } from "common/problems/problemTypeLabelMap";
import { ProblemType } from "common/problems/ProblemType";
import { DetailItemSection } from "../components/DetailItemSection";
import { ProblemDetailItem } from "../components/ProblemDetailItem";
import { SKULink } from "../components/SKULink";
import { NonComplianceProductPhotoSection } from "inbounds/non-compliance/components";
import { ProblemBladeStatusSection } from "../components/ProblemBladeStatusSection";
import { ShippingPlanNameDisplay } from "common/components/ShippingPlanNameDisplay";

export interface UnknownBarcodeBladeProps extends ProblemBladeProps {
  shipmentProblem: UnknownBarcodeProblemRow;
  onShippingPlanClick: () => void;
  shippingPlanId?: number;
  shippingPlanName?: string;
  product?: MappedSkuPair;
  images: string[];
}

const StatusSectionWithMargin = styled(ProblemBladeStatusSection)<ThemeProps>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S5};
  `
);

const StyledStack = styled(Stack)`
  width: 100%;
`;

const SpacedProblemDetailItem = styled(ProblemDetailItem)<ThemeProps>(
  ({ theme }) => `
  min-width: 50%;
  display: inline-block;

  dt {
    font-weight: ${theme.font.weight.BOLD};
  }
`
);

const ShippingPlanLink = styled(Anchor)`
  cursor: pointer;
`;

export const UnknownBarcodeBlade: FC<UnknownBarcodeBladeProps> = ({
  id,
  shipmentProblem,
  onShippingPlanClick,
  shippingPlanId,
  shippingPlanName,
  onCloseClick,
  isBladeOpen,
  product,
  images,
}) => {
  const { barcode, status, updatedAt } = shipmentProblem;
  const { msku, dsku, productName } = product ?? {};

  return (
    <ProblemBaseBlade
      id={id}
      title={PROBLEM_TYPE_LABEL_MAP[ProblemType.UNKNOWN_BARCODE]}
      onCloseClick={onCloseClick}
      isBladeOpen={isBladeOpen}
      updatedAt={updatedAt}
    >
      <StatusSectionWithMargin status={status} />
      <StyledStack>
        <DetailItemSection>
          <StyledStack gap="S4">
            <div>
              <Text size="bodyLarge" as="span">
                <FormattedMessage id="inbounds.unknownBarcodeBlade.detailsTitle" defaultMessage="Details" />
              </Text>
            </div>
            <SpacedProblemDetailItem
              label={<FormattedMessage id="inbounds.unknownBarcodeBlade.shippingPlan" defaultMessage="Shipping plan" />}
              value={
                <>
                  <ShippingPlanLink onClick={onShippingPlanClick}>
                    <ShippingPlanNameDisplay shippingPlanId={shippingPlanId} shippingPlanName={shippingPlanName} />
                  </ShippingPlanLink>
                </>
              }
            />
            <div>
              <SpacedProblemDetailItem
                label={
                  <FormattedMessage
                    id="inbounds.unknownBarcodeBlade.nonCompliantBarcode"
                    defaultMessage="Unknown barcode"
                  />
                }
                value={barcode}
              />
              {dsku && (
                <SpacedProblemDetailItem
                  label={<FormattedMessage id="inbounds.unknownBarcodeBlade.verifiedSku" defaultMessage="SKU" />}
                  value={<SKULink dsku={dsku}>{msku}</SKULink>}
                />
              )}
            </div>
            {productName && (
              <SpacedProblemDetailItem
                label={
                  <FormattedMessage id="inbounds.unknownBarcodeBlade.nonCompliantProduct" defaultMessage="Product" />
                }
                value={productName}
              />
            )}
            {images.length > 0 && (
              <SpacedProblemDetailItem
                label={
                  <FormattedMessage id="inbounds.unknownBarcodeBlade.photoSection" defaultMessage="Product photos" />
                }
                value={
                  <Stack gap="S2">
                    <NonComplianceProductPhotoSection photos={images} isSmall />
                  </Stack>
                }
              />
            )}
          </StyledStack>
        </DetailItemSection>
      </StyledStack>
    </ProblemBaseBlade>
  );
};
