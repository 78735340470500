import { addDays } from "date-fns";

// DATES
export const EARLIEST_PICKUP_OFFSET = 1;
export const LATEST_PICKUP_OFFSET = 14;
export const LATEST_STORAGE_FREIGHT_PICKUP_OFFSET = 90;
export const DEFAULT_DATE_OFFSET = 10;
export const EARLIEST_PICKUP_DATE = addDays(new Date(), EARLIEST_PICKUP_OFFSET);
export const LATEST_PICKUP_DATE = addDays(new Date(), LATEST_PICKUP_OFFSET);
export const LATEST_STORAGE_FREIGHT_PICKUP_DATE: Date = addDays(new Date(), LATEST_STORAGE_FREIGHT_PICKUP_OFFSET);
export const DEFAULT_DATE = addDays(new Date(), DEFAULT_DATE_OFFSET);
