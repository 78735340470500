import { Dictionary } from "lodash";
import { flowRight, fromPairs, map, toPairs } from "lodash/fp";

import { createReducer, ReducerDictionary } from "common/ReduxUtils";
import { Enable, FastTagsActionTypes } from "tags/FastTagsActions";
import { MultipleShopFastTag } from "./model/multipleShop/MultipleShopFastTagProgram";
import { Shop } from "./model/Shop";
import produce from "immer";

export interface FastTagsState {
  activationDates: Dictionary<Date>;
  nextButtonEnabled: Dictionary<boolean>;
  pageTransitionEnabled: Dictionary<boolean>;
  ableToReactivate: Dictionary<boolean>;
  shopConnectionStatus: Partial<Record<MultipleShopFastTag, Shop[]>>;
}

export const fastTagsInitialState: FastTagsState = {
  activationDates: {},
  nextButtonEnabled: {},
  pageTransitionEnabled: {},
  ableToReactivate: {},
  shopConnectionStatus: {},
};

const reducers: ReducerDictionary<FastTagsState> = {
  [FastTagsActionTypes.GET_STATUS]: (state, { program, programProps }) => {
    return {
      ...state,
      ...flowRight(
        fromPairs,
        map(([programPropType, programPropValue]) => [
          programPropType,
          {
            ...state[programPropType],
            [program]: programPropValue,
          },
        ]),
        toPairs
      )(programProps),
    };
  },
  [FastTagsActionTypes.ENABLE_NEXT]: (state, { enableType, propName }) => {
    const reducerProp = enableType === Enable.NextButton ? "nextButtonEnabled" : "pageTransitionEnabled";
    return {
      ...state,
      [reducerProp]: {
        ...state[reducerProp],
        [propName]: true,
      },
    };
  },
  [FastTagsActionTypes.DISABLE_NEXT]: (state, { enableType, propName }) => {
    const reducerProp = enableType === Enable.NextButton ? "nextButtonEnabled" : "pageTransitionEnabled";
    return {
      ...state,
      [reducerProp]: {
        ...state[reducerProp],
        [propName]: false,
      },
    };
  },
  [FastTagsActionTypes.UPDATE_LOCAL_TAGS]: () => {
    return fastTagsInitialState;
  },
  [FastTagsActionTypes.SHOP_CONNECTION_STATUS_RECEIVED]: (state, { fastTag, shops }) =>
    produce(state, (draftState) => {
      draftState.shopConnectionStatus[fastTag] = shops;
    }),
};

export const fastTagsReducer = createReducer<FastTagsState>(fastTagsInitialState, reducers);
