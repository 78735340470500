import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "RootReducer";
import { showBladeAction, hideBladeAction } from "common/components/Blade/BladeActions";

export const useBlade = (id: string) => {
  const dispatch = useDispatch();
  const showBlade = useCallback(() => {
    dispatch(showBladeAction(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const hideBlade = useCallback(() => {
    dispatch(hideBladeAction(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const isActive = useSelector((state: RootState) => state.blade.blades[id]);

  return { showBlade, hideBlade, isActive };
};
