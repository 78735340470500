import Checkbox from "material-ui/Checkbox";
import React from "react";
import { Size } from "common/components/componentTypes";
import { Icon } from "common/components/ui";

import cls from "./Checkbox.less";

export enum CheckBoxStates {
  CHECKED = "CHECKED",
  UNCHECKED = "UNCHECKED",
}
interface CheckboxProps {
  label?: JSX.Element | string;
  description?: JSX.Element | string;
  style?: { [styleName: string]: any };
  className?: string;
  onCheck?: (e: React.MouseEvent<HTMLInputElement>, checked: boolean) => void;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  sizeOfInput?: Size;
  name?: string;
  indeterminate?: boolean;
}

const initialIconStyle = {
  fill: "rgb(52, 142, 234)",
  width: "16px",
  height: "16px",
  fontSize: "16px",
};

const getIconStyle = (sizeOfInput: Size = Size.sm) => {
  const sizeMap = {
    [Size.sm]: "16px",
    [Size.md]: "25px",
    [Size.lg]: "30px",
  };
  return {
    ...initialIconStyle,
    width: sizeMap[sizeOfInput],
    height: sizeMap[sizeOfInput],
    fontSize: sizeMap[sizeOfInput],
  };
};

export default (props: CheckboxProps) => {
  const iconStyle = getIconStyle(props.sizeOfInput);
  const checkedIcon = (
    <Icon type={props.indeterminate ? "minus-square" : "check-square"} color={iconStyle.fill} style={iconStyle} />
  );
  const uncheckedIcon = <Icon type="square-lt" color="rgb(205, 207, 212)" style={iconStyle} />;

  return (
    <div style={{ display: "inline-block", verticalAlign: "middle", ...props.style }}>
      <Checkbox
        className={props.label ? cls.checkbox : cls.checkboxNoLabel}
        iconStyle={iconStyle}
        labelStyle={{ fontFamily: "inherit" }}
        name={props.name}
        defaultChecked={props.defaultChecked}
        checkedIcon={checkedIcon}
        uncheckedIcon={uncheckedIcon}
        {...props}
      />
      {props.description && <div className={cls.description}>{props.description}</div>}
    </div>
  );
};
