import { sortBy } from "lodash/fp";

import { EntityWithDimensions } from "common/types/EntityWithDimensions";

interface MaxDimensions {
  maxShortestSide: number;
  maxMiddleSide: number;
  maxLongestSide: number;
}

export const composeGetIsOverDimensions =
  ({ maxShortestSide, maxMiddleSide, maxLongestSide }: MaxDimensions) =>
  ({ length, width, height }: Exclude<EntityWithDimensions, "weight">) => {
    const sortedDims = sortBy<number>((value) => value, [Number(width ?? 0), Number(height ?? 0), Number(length ?? 0)]);
    return sortedDims[0] > maxShortestSide || sortedDims[1] > maxMiddleSide || sortedDims[2] > maxLongestSide;
  };
