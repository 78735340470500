import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useLifecycles } from "react-use";
import { RootState } from "RootReducer";
import { getReturnItem, resetReturnLabel } from "./store/ReturnsLabelAction";

export const useReturnsLabelOrchestration = () => {
  const returnItem = useSelector((state: RootState) => state.returnsLabel.returnItem);
  const error = useSelector((state: RootState) => state.returnsLabel.error);
  const shareLabelStatus = useSelector((state: RootState) => state.returnsLabel.shareLabelStatus);
  const email = useSelector((state: RootState) => state.returnsLabel.email);

  const dispatch = useDispatch();
  const { returnId } = useParams<{ returnId: string }>();
  useLifecycles(
    () => {
      dispatch(getReturnItem(returnId));
    },
    () => {
      dispatch(resetReturnLabel());
    }
  );

  return {
    dispatch,
    error,
    returnItem,
    shareLabelStatus,
    email,
  };
};
