import { crossdockClient } from "common/clients/instances";
import { CrossdockInboundShipment } from "common/clients/crossdock/CrossdockInboundShipment/CrossdockInboundShipment";
import { SPThunkAction } from "common/ReduxUtils";
import { setCrossdockInboundQuote } from "../quote";
import { setCrossdockInboundShipment } from "./setCrossdockInboundShipment";
import log from "Logger";
import { batch } from "react-redux";

export const fetchCrossdockInboundShipment = (
  sellerId: string,
  shippingPlanId: number
): SPThunkAction<Promise<CrossdockInboundShipment | undefined>> => async (
  dispatch
): Promise<CrossdockInboundShipment | undefined> => {
  const ctx = { fn: "fetchCrossdockInboundShipment", sellerId, shippingPlanId };
  log.info(ctx, "start");

  try {
    const shipment = await crossdockClient.getInboundShipmentByShippingPlan(sellerId, shippingPlanId);

    if (shipment) {
      batch(() => {
        dispatch(setCrossdockInboundShipment(shipment));
        dispatch(setCrossdockInboundQuote(shipment.quote));
      });
    }

    return shipment;
  } catch (err) {
    log.info(ctx, "No crossdock inbound shipment found.");
    return;
  }
};
