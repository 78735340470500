export enum StorageInboundStep {
  SELECT_INBOUND_TYPE = "SELECT_INBOUND_TYPE",
  STORAGE_SELECT_PRODUCTS = "STORAGE_SELECT_PRODUCTS",
  STORAGE_ADD_LOT_INFORMATION = "STORAGE_ADD_LOT_INFORMATION",
  STORAGE_ADD_CASE_DIMENSIONS = "STORAGE_ADD_CASE_DIMENSIONS",
  STORAGE_ADD_PRODUCTS_BARCODE = "STORAGE_ADD_PRODUCTS_BARCODE",
  STORAGE_FROM_ADDRESS = "STORAGE_FROM_ADDRESS",
  STORAGE_DESTINATION_WAREHOUSE = "STORAGE_DESTINATION_WAREHOUSE",
  STORAGE_SELECT_SHIPPING = "STORAGE_SELECT_SHIPPING",
  STORAGE_ADD_PICKUP_DETAILS = "STORAGE_ADD_PICKUP_DETAILS",
  STORAGE_CONFIRM_SHIPMENT = "STORAGE_CONFIRM_SHIPMENT",
  STORAGE_SHIPMENT_CONFIRMED = "STORAGE_SHIPMENT_CONFIRMED",
}
