export enum ParcelListStatusType {
  CREATED = "CREATED",
  IN_TRANSIT = "IN_TRANSIT",
  RECEIVED = "RECEIVED",
  DELIVERED = "DELIVERED",
  RETURN_INITIATED = "RETURN_INITIATED",
  RETURN_IN_TRANSIT = "RETURN_IN_TRANSIT",
  OUT_FOR_RETURN = "OUT_FOR_RETURN",
  RETURN_COMPLETED = "RETURN_COMPLETED",
}
