import React, { FC } from "react";
import cx from "classnames";
import HtmlToReact from "../HtmlToReact";

import { Size } from "../componentTypes";

import shippingBox from "assets/img/icons/shipping-box.svg";
import cls from "./PackageIcon.less";

export interface PackageIconProps {
  size?: Size; // Defaults to md / Medium
  count?: number | string;
}

export const PackageIcon: FC<PackageIconProps> = (props: PackageIconProps) => {
  const { size, count } = props;

  return (
    <div className={cx(cls.boxContainer, cls[`size-${size ?? "md"}`])}>
      <HtmlToReact html={shippingBox} />
      {count && <div className={cx(cls.countBadge, cls[`size-${size ?? "md"}`])}>{count}</div>}
    </div>
  );
};
