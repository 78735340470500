import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import Table, { TableColumn } from "common/components/lists/Table";
import { ProductDisplay } from "common/components/ProductDisplay";
import { AmazonBarcodeValueCell } from "./AmazonBarcodeValueCell";
import { SelectedProductData } from "common/components/ProductChooser";
import { AmazonBarcodeEntry } from "./AmazonBarcodeEntry";

interface AddAmazonProductBarcodesTableProps {
  products: SelectedProductData[];
  barcodeMap: Record<string, AmazonBarcodeEntry>;
  onBarcodeValueChange: (dsku: string, barcodeValue: string) => void;
}

const StyledTable = styled(Table)`
  th:first-of-type,
  td:first-of-type {
    padding-left: 0 !important;
  }
  & > tbody > tr > td {
    vertical-align: top;
  }
` as typeof Table;

export const AddAmazonProductBarcodesTable = ({
  products,
  barcodeMap,
  onBarcodeValueChange,
}: AddAmazonProductBarcodesTableProps) => {
  const { formatMessage } = useIntl();

  const columns = useMemo<TableColumn<SelectedProductData>[]>(
    () => [
      {
        header: formatMessage({
          id: "transfers.fba.unitPrep.addProductBarcodes.productHeader",
          defaultMessage: "Product",
        }),
        width: "60%",
        renderCell: (product) => <ProductDisplay product={product} />,
      },
      {
        header: formatMessage({
          id: "transfers.fba.unitPrep.addProductBarcodes.amazonBarcode",
          defaultMessage: "Amazon barcode (FNSKU)",
        }),
        width: "40%",
        renderCell: (product) => {
          const { dsku } = product;

          return (
            <AmazonBarcodeValueCell
              barcodeEntry={barcodeMap[dsku]}
              onChange={({ target: { value } }) => onBarcodeValueChange(dsku, value)}
            />
          );
        },
      },
    ],
    [barcodeMap, formatMessage, onBarcodeValueChange]
  );

  return (
    <StyledTable<SelectedProductData>
      columns={columns}
      items={products}
      page="0"
      onPageChange={() => {}}
      showCheckboxCondition={() => false}
      showAllSelectionCheckbox={false}
      allowRowSelection={false}
      hidePaginate
      hideHover
      hideBoxShadow
    />
  );
};
