import { ReturnAddress, SellerMinimal } from "@deliverr/returns-client";

import { MessageDescriptor } from "react-intl";

export interface DestinationOptions extends ReturnAddress {
  addressType: "NEW" | "SAVED" | "NEW_QC" | "SAVED_QC";
}

export enum ReturnsSellerActionTypes {
  RETURNS_SELLER_RESET = "RETURNS_SELLER_RESET",
  RETURNS_SELLER_RESET_MESSAGE = "RETURNS_SELLER_RESET_MESSAGE",
  RETURNS_SELLER_ADD_ADDRESS = "RETURNS_SELLER_ADD_ADDRESS",
  RETURNS_SELLER_UPDATE_SUCCESS = "RETURNS_SELLER_UPDATE_SUCCESS",
  RETURNS_SELLER_UPDATE_FAIL = "RETURNS_SELLER_UPDATE_FAIL",
  RETURNS_SELLER_UPDATE_REQUEST = "RETURNS_SELLER_UPDATE_REQUEST",
}

export interface ReturnsSellerState {
  destinationOptions: DestinationOptions[];
  qcAddresses: ReturnAddress[];
  sellerInfo?: SellerMinimal;
  errorMessage?: MessageDescriptor;
  successMessage?: MessageDescriptor;
  fetching: boolean;
}
