import React, { FC } from "react";
import { useScrollbarWidth } from "react-use";

// `import { css, Global } from "@emotion/core";` fails as of 2021-10-01 because
// of typing conflicts between Emotion 9 and 10, so I'm using require instead,
// so as to not pull in the types for @emotion/core.
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { css, Global } = require("@emotion/core");

export const ElevioLauncherMargin: FC = () => {
  const scrollbarWidth = useScrollbarWidth();
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const right = scrollbarWidth ? `${scrollbarWidth}px` : "0";

  return (
    <Global
      styles={css`
        ._elevio_launcher {
          button {
            margin-bottom: ${right};
          }
        }
      `}
    />
  );
};
