import { SPThunkAction } from "common/ReduxUtils";
import { batch } from "react-redux";
import { wholesaleClient } from "Clients";
import {
  wholesaleCreateSetClearingLabel,
  wholesaleCreateSetError,
  wholesaleCreateSetLabelFilename,
} from "./WholesaleCreateActions";
import { selectWholesaleCreate } from "../store/WholesaleCreateSelectors";
import { UploadAttachmentType } from "../../../transfers/create/actions";
import { WholesaleErrorType } from "../../../common/wholesale-common/utils/WholesaleErrorTypes";

export const wholesaleClearUploadedLabelFile = (attachmentType: UploadAttachmentType): SPThunkAction => async (
  dispatch,
  getState
) => {
  const { wholesaleOrder } = selectWholesaleCreate(getState());
  batch(() => {
    dispatch(wholesaleCreateSetClearingLabel(attachmentType, true));
    dispatch(wholesaleCreateSetError(WholesaleErrorType.NONE));
  });

  try {
    const shipment = wholesaleOrder!.shipments && wholesaleOrder!.shipments[0];
    shipment &&
      (await wholesaleClient.deleteLabel(shipment.id, attachmentType, wholesaleOrder?.id, wholesaleOrder?.sellerId));
    dispatch(wholesaleCreateSetLabelFilename(attachmentType, null));
  } catch (e) {
    dispatch(wholesaleCreateSetError(WholesaleErrorType.UNTYPED));
  } finally {
    dispatch(wholesaleCreateSetClearingLabel(attachmentType, false));
  }
};
