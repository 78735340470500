import styled from "@emotion/styled";
import { HTMLAttributes } from "react";
import { isMobile } from "../shared/helpers/utils";
import { Appearance, ContentElement, DefaultTheme, TextAlign } from "../shared";

export type TextType = "caption" | "body" | "bodyLarge" | "navigation" | "label" | "h2";

export interface TextProps extends HTMLAttributes<HTMLParagraphElement> {
  /** Html element of Text */
  as?: keyof typeof ContentElement;
  /** Set type of field */
  size?: TextType;
  /** Bold */
  bold?: boolean;
  /** Color */
  appearance?: keyof typeof Appearance;
  /** Uppercase */
  uppercase?: boolean;
  /** Set text alignment */
  textAlign?: keyof typeof TextAlign;
}

const setSize = (size: TextType = "body", theme: DefaultTheme) => {
  const sizes: Record<TextType, string> = {
    navigation: "0.68rem",
    caption: theme.font.size.F1,
    body: isMobile(theme) ? theme.font.size.F3 : theme.font.size.F2,
    label: theme.font.size.F2,
    bodyLarge: theme.font.size.F3,
    h2: theme.font.size.F6,
  };
  return `font-size: ${sizes[size]};`;
};

export const Text = styled.p<TextProps, DefaultTheme>(
  ({ theme, appearance, bold, uppercase, size, textAlign = TextAlign.start }) => `
  margin-bottom: 0;
  color: ${appearance ? theme.colors[theme.config.colorByAppearance[appearance]][300] : "inherit"};
  font-weight: ${bold ? theme.font.weight.BOLD : theme.font.weight.REGULAR};
  text-transform: ${uppercase ? `uppercase` : `none`};
  text-align: ${textAlign};
  ${setSize(size, theme)}
`
);
