import { AmazonAddress } from "inbounds/createShipment/CreateShipmentTypes";
import { useFbaLocations } from "inbounds/createShipment/steps/priceViewStep/selectShipping/selectShippingFlow/steps/SelectDistribution/AmazonDestination/useFbaLocations";
import { useMemo } from "react";

export const getFormattedName = (fc: AmazonAddress) => {
  return `Amazon ${fc.code}`;
};

export const getAddressString = (fc: AmazonAddress) => {
  const addressParts = [fc.street1, fc.street2, `${fc.city}, ${fc.state}, ${fc.zip}`].filter(Boolean);
  return addressParts.join(", ");
};

export const useAmazonAddressInput = (
  onChangeCallback: (address: AmazonAddress) => void,
  selectedAddress: AmazonAddress
) => {
  const { data, error, isLoading } = useFbaLocations();
  const locations = data ? ((data.value as unknown) as AmazonAddress[]) : undefined;

  const getOptionLabel = (fc: AmazonAddress) => {
    const name = getFormattedName(fc);
    const addressString = getAddressString(fc);
    return `${name} - ${addressString}`;
  };

  const options = useMemo(
    () =>
      locations?.map((fc) => ({
        value: fc,
        label: getOptionLabel(fc),
      })) ?? [],
    [locations]
  );

  const onChange = ({ value }: { value: AmazonAddress }) => {
    onChangeCallback(value);
  };

  const value = selectedAddress
    ? {
        value: selectedAddress,
        label: getOptionLabel(selectedAddress),
      }
    : undefined;

  const isOptionSelected = (option: AmazonAddress, selectedValue: AmazonAddress) => {
    return option?.code === selectedValue?.code;
  };

  return {
    options,
    onChange,
    value,
    hasError: Boolean(error),
    isOptionSelected,
    isLoading,
    hasServerError: Boolean(error),
  };
};
