import { Appearance, BorderIcon, Box, BoxReset, Card, Stack, Text, ThemeProps, Title } from "common/components/ui";
import styled from "@emotion/styled";
import { InboundFooter } from "inbounds/steps/InboundFooter";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { shipmentConfirmedHeadingLabels } from "./shipmentConfirmedHeading.labels";
import { useShipmentConfirmed } from "./useShipmentConfirmed";

const StyledCard = styled(Card)<ThemeProps>(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.spacing.S7} ${theme.spacing.S5};
    height: 100%;
  `
);

export const ShipmentConfirmed: FC = () => {
  const { onNextClick, shipmentConfirmedInstructionSteps } = useShipmentConfirmed();

  return (
    <Box height="36.25rem" width="62.5rem">
      <StyledCard data-testid="shipment-instructions-list">
        <Stack center direction="VERTICAL">
          <BorderIcon appearance={Appearance.SUCCESS} iconType="check" size="XXL" />
          <BoxReset marginBottom={null}>
            <Title displayAs="h4">
              <FormattedMessage {...shipmentConfirmedHeadingLabels.shipmentConfirmedTitle} />
            </Title>
          </BoxReset>
          <BoxReset marginTop={null} marginBottom="S8">
            <Text size="label">
              <FormattedMessage {...shipmentConfirmedHeadingLabels.shipmentConfirmedCaption} />
            </Text>
          </BoxReset>

          <Stack justifyContent="flex-start" direction="VERTICAL">
            {shipmentConfirmedInstructionSteps}
          </Stack>
        </Stack>
      </StyledCard>
      <InboundFooter onButtonClick={onNextClick} hidePreviousButton />
    </Box>
  );
};
