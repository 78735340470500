import React from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "common/components/ui";
import { ShippingPlan } from "@deliverr/legacy-inbound-client";
import { logError, logStart } from "Logger";
import { inboundClient } from "Clients";
import history from "BrowserHistory";
import { Path } from "paths";

const FetchShippingPlanErrorMessage = () => (
  <FormattedMessage id={"inbounds.fetchShippingPlan.error"} defaultMessage={"Oops. We can't find that inbound"} />
);

export const fetchShippingPlan = async (
  sellerId: string,
  shippingPlanId: number
): Promise<ShippingPlan | undefined> => {
  const ctx = logStart({ fn: "loadShippingPlan", shippingPlanId });
  try {
    const shippingPlan = await inboundClient.getShippingPlan(sellerId, shippingPlanId);
    return {
      ...shippingPlan,
      useCasePack: Boolean(shippingPlan.useCasePack),
    };
  } catch (err) {
    logError(ctx, err);
    toast.error(<FetchShippingPlanErrorMessage />, {
      toastId: "inbounds.fetchShippingPlan.error",
    });
    history.push(Path.inboundsList);
    return;
  }
};
