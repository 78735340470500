import { DefaultTheme, LocalizedMessage } from "common/components/ui";
import { IconV2, IconV2Types } from "common/components/ui/IconV2";
import { Appearance } from "common/components/ui/shared";
import { Text } from "common/components/ui/Text";
import styled from "@emotion/styled";
import { ExternalLink } from "common/components/ExternalLink";
import { LocationDescriptor } from "history";
import React, { FC } from "react";
import { Link } from "react-router-dom";

const Grid = styled.div<{ appearance?: Appearance }, DefaultTheme>(({ theme, appearance }) => {
  const color = appearance ? theme.config.colorByAppearance[appearance] : undefined;

  return `
      padding: ${theme.spacing.S5} ${theme.spacing.S5} ${theme.spacing.S5} ${theme.spacing.S3};
      display: grid;
      grid-template-columns: 2.5rem ${theme.spacing.S2} 1fr;
      border-bottom: 1px solid ${theme.colors.NEUTRAL[60]};
      ${color && `background-color: ${theme.colors[color][50] ?? theme.colors[color][100]};`}
    `;
});

const DateContainer = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    grid-column: 3;
    grid-row: 1;
    margin-bottom: ${theme.spacing.S2};
  `
);

const IconContainer = styled.div<{ appearance: Appearance }, DefaultTheme>(
  ({ theme, appearance }) => `
    grid-column: 1;
    grid-row: 2;
    display: flex;
    justify-content: center;
    color: ${theme.colors[theme.config.colorByAppearance[appearance]][300]};
  `
);

const MessageContainer = styled.div`
  grid-column: 3;
  grid-row: 2;
`;

const Description = styled(Text)<{}, DefaultTheme>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S2};
  `
);

const CallToActionContainer = styled.div<{ appearance?: Appearance }, DefaultTheme>(
  ({ theme, appearance }) => `
    grid-column: 3;
    grid-row: 3;
    margin-top: ${theme.spacing.S4};
    
    > a {
      font-weight: ${theme.font.weight.BOLD};
      ${
        appearance !== undefined &&
        appearance !== Appearance.PRIMARY &&
        `
          color: ${theme.colors[theme.config.colorByAppearance[appearance]][500]};
          &:hover {
            color: ${theme.colors[theme.config.colorByAppearance[appearance]][400]};
          }
        `
      }
    }
  `
);

export interface NotificationItemProps {
  date?: LocalizedMessage;
  title: LocalizedMessage;
  description?: LocalizedMessage;
  iconType: IconV2Types;
  iconAppearance: Appearance;
  backgroundAppearance?: Appearance;
  callToActionPath?: LocationDescriptor;
  callToActionExternalPath?: string;
  callToActionMessage?: LocalizedMessage;
  onCallToActionClick?: () => void;
}

export const NotificationItem: FC<NotificationItemProps> = ({
  date,
  title,
  description,
  iconType,
  iconAppearance,
  backgroundAppearance,
  callToActionMessage,
  callToActionPath,
  callToActionExternalPath,
  onCallToActionClick,
}) => {
  const dateContainer = date && (
    <DateContainer>
      <Text size="caption" appearance="INFO">
        {date}
      </Text>
    </DateContainer>
  );

  const iconContainer = (
    <IconContainer appearance={iconAppearance}>
      <IconV2 type={iconType} size="large" />
    </IconContainer>
  );

  const messageContainer = (
    <MessageContainer>
      <Text bold>{title}</Text>
      {description && <Description>{description}</Description>}
    </MessageContainer>
  );

  const callToActionContainer = (callToActionPath ?? callToActionExternalPath) && callToActionMessage && (
    <CallToActionContainer appearance={backgroundAppearance}>
      {callToActionPath ? (
        <Link to={callToActionPath} onClick={onCallToActionClick}>
          {callToActionMessage}
        </Link>
      ) : (
        <ExternalLink href={callToActionExternalPath} onClick={onCallToActionClick}>
          {callToActionMessage}
        </ExternalLink>
      )}
    </CallToActionContainer>
  );

  return (
    <Grid appearance={backgroundAppearance}>
      {dateContainer}
      {iconContainer}
      {messageContainer}
      {callToActionContainer}
    </Grid>
  );
};
