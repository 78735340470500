import React, { FC } from "react";
import { Text } from "common/components/ui";
import { ProblemRowData } from "../types";
import { ProblemTypeLabel } from "./ProblemTypeLabel";
import { ProblemTypeCaption } from "./ProblemTypeCaption";

export const ProblemTypeCell: FC<ProblemRowData> = (problem) => (
  <>
    <Text bold>
      <ProblemTypeLabel problemRow={problem} />
    </Text>
    <Text appearance="INFO" size="caption">
      <ProblemTypeCaption problemRow={problem} />
    </Text>
  </>
);
