import { createSelector } from "reselect";
import { getLoadedShipmentId } from "./getLoadedShipmentId";
import { validateBoxDimsAndWeights } from "inbounds/utils/box/validateBoxDimsAndWeights";
import { selectBoxesByShipment } from "../plan/selectBoxesByShipment";

/** Determine if packages have valid dimensions */
export const selectLoadedShipmentHasValidBoxDimensions = createSelector(
  getLoadedShipmentId,
  selectBoxesByShipment,
  (loadedShipmentId, packagesByShipment) => {
    if (!loadedShipmentId) {
      return false;
    }
    const packages = packagesByShipment[loadedShipmentId];
    return Boolean(packages?.every((pkg) => validateBoxDimsAndWeights(pkg)));
  }
);
