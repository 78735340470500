import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Appearance, Notification } from "common/components/ui";
import {
  STORAGE_INBOUND_MAX_SAFE_CASE_WEIGHT_LBS,
  STORAGE_INBOUND_SAFE_MAX_CASE_MEASUREMENT_LONGEST_IN,
  STORAGE_INBOUND_SAFE_MAX_CASE_MEASUREMENT_MIDDLE_IN,
  STORAGE_INBOUND_SAFE_MAX_CASE_MEASUREMENT_SHORTEST_IN,
} from "storage/inbounds/CaseDimensionsConstants";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";

export const CaseDimensionsAboveRecommendedForFCInfo: FC = () => (
  <Notification
    appearance={Appearance.PRIMARY}
    append={<ElevioLearnMoreButton articleId={ElevioArticleId.DimensionalRequirements} />}
    content={{
      description: (
        <FormattedMessage
          id="storage.inbounds.dimensionsAndWeights.notification"
          defaultMessage={`Learn requirements for case picks larger than {maxSafeLength}" x {maxSafeWidth}" x {maxSafeHeight}" or {maxSafeWeight} lbs`}
          values={{
            maxSafeLength: STORAGE_INBOUND_SAFE_MAX_CASE_MEASUREMENT_LONGEST_IN,
            maxSafeWidth: STORAGE_INBOUND_SAFE_MAX_CASE_MEASUREMENT_MIDDLE_IN,
            maxSafeHeight: STORAGE_INBOUND_SAFE_MAX_CASE_MEASUREMENT_SHORTEST_IN,
            maxSafeWeight: STORAGE_INBOUND_MAX_SAFE_CASE_WEIGHT_LBS,
          }}
        />
      ),
    }}
  />
);
