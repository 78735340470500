import React, { FC } from "react";
import { DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import ReactCalendar, { CalendarProps } from "react-calendar";

const SINGLE_CALENDAR_WIDTH: number = 281;
const StyledCalendar = styled(ReactCalendar)<CalendarProps, DefaultTheme>(
  ({ theme, showDoubleView }) => `
  width: ${showDoubleView ? 2 * SINGLE_CALENDAR_WIDTH : SINGLE_CALENDAR_WIDTH}px;
  background-color: ${theme.colors.NEUTRAL["00"]};
  color: ${theme.colors.NEUTRAL["500"]};
  font-family: ${theme.font.family.STANDARD as string};
  border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL["80"]};
  box-shadow: ${theme.shadow.S3};
  
  /* override day-of-week underline and cursor */
  abbr[data-original-title], abbr[title] {
    border: none;
    cursor: default;
    text-decoration: none;
  }

  .react-calendar__tile {
    /*
      TODO: add this later when we address the gap between days / tiles in the mock-ups
      border-radius: ${theme.border.radius.R2};
    */
    /* prevent the border from hover from causing contents to jump */
    border: ${theme.border.width.B1} ${theme.border.type} transparent;
  }

  .react-calendar__tile--now {
    background: none;
    color: ${theme.colors.BLUE["300"]};
    font-weight: ${theme.font.weight.BOLD as string};
    position: relative;

    /* add dot below the number for today's date */
    abbr::after {
      content: "·";
      font-size: ${theme.font.size.F4};
      display: inline-block;

      position: absolute;
      bottom: -1px;
      left: 0;

      text-align: center;
      width: 100%;
    }
  }

  .react-calendar__tile:hover:enabled {
    background: none;
    color: ${theme.colors.BLUE["300"]};
    border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.BLUE["300"]};
  }

  .react-calendar__month-view__days__day--weekend {
    color: ${theme.colors.NEUTRAL["500"]};
  }
  
  .react-calendar__tile--active,
  .react-calendar__tile--range {
    background: ${theme.colors.BLUE["300"]};
    color: ${theme.colors.NEUTRAL["00"]};
  }

  .react-calendar__tile:disabled {
    background: ${theme.colors.NEUTRAL["40"]};
    text-decoration: line-through;
  }
`
);

export interface ExtendedCalendarProps {
  /** determine if a specific date should be disabled on the calendar. This is a wrapper for tileDisabled */
  getIsDateDisabled?: (date: Date) => boolean;
}

/**
 * Specifies base styles and behavior for our implementation of react-calendar.
 * Hence, should not use the Calendar directly from react-calendar elsewhere.
 */
export const Calendar: FC<ExtendedCalendarProps & CalendarProps> = ({ getIsDateDisabled, ...props }) => (
  <StyledCalendar
    locale="en-US" // makes Sunday the first day of the week
    tileDisabled={getIsDateDisabled ? ({ date }) => getIsDateDisabled(date) : undefined}
    {...props}
  />
);
