import React from "react";
import { CarrierAccountStatus } from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { SPThunkAction } from "common/ReduxUtils";
import { logError } from "Logger";
import { transferCreateDeleteCarrierAccount, transferCreateSetSelectedCarrierAccount } from "transfers/create/actions";
import { ReplenishmentCarrierAccountLoader } from "../ReplenishmentCarrierAccountLoader";
import { toast } from "react-toastify";
import { FormattedMessage } from "common/components/ui/FormattedMessage";

export const deleteReplenishmentCarrierAccount =
  (carrierAccountId: number, selectedCarrierAccountId?: number): SPThunkAction<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch(addLoader(ReplenishmentCarrierAccountLoader.CarrierModalButtonLoader));
      await replenishmentClient.updateCarrierAccountById(carrierAccountId, {
        accountStatus: CarrierAccountStatus.INACTIVE,
      });
      dispatch(transferCreateDeleteCarrierAccount(carrierAccountId));
      if (selectedCarrierAccountId === carrierAccountId) {
        dispatch(transferCreateSetSelectedCarrierAccount(undefined));
      }
    } catch (err) {
      logError({ fn: "deleteReplenishmentCarrierAccount", carrierAccountId }, err, "Error deleting carrier account");
      toast.error(
        err.message ?? (
          <FormattedMessage
            id="deleteReplenishmentCarrierAccount.handleDelete.error"
            defaultMessage="Failed to delete carrier account"
          />
        )
      );
    } finally {
      dispatch(clearLoader(ReplenishmentCarrierAccountLoader.CarrierModalButtonLoader));
    }
  };
