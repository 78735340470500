import React, { FC } from "react";
import { DefaultTheme, Notification, Text } from "common/components/ui";
import styled from "@emotion/styled";
import { FormattedMessage, MessageDescriptor } from "react-intl";

import { FreightPalletValidations } from "../../../inbounds/steps/ship/ltl/util/validations";
import { CargoPalletValidations } from "freight/steps/setup/steps/cargoPalletDetails/CargoPalletTable/CargoPalletValidations";

const MarginedNotification = styled(Notification)<{}, DefaultTheme>`
  margin-bottom: ${({ theme }) => theme.spacing.S4};
`;

const StyledUl = styled.ul<{}, DefaultTheme>`
  padding-left: ${({ theme }) => theme.spacing.S5};
`;

type PalletValidationMap =
  | Record<
      FreightPalletValidations,
      {
        messageDescriptor: MessageDescriptor;
        values?: Record<string, unknown> | undefined;
      }
    >
  | Record<
      CargoPalletValidations,
      {
        messageDescriptor: MessageDescriptor;
        values?: Record<string, unknown> | undefined;
      }
    >;

interface ValidationErrorsNotificationProps {
  errors: FreightPalletValidations[] | CargoPalletValidations[];
  palletValidationMap: PalletValidationMap;
}

export const ValidationErrorsNotification: FC<ValidationErrorsNotificationProps> = ({
  errors,
  palletValidationMap,
}) => {
  const Error = <FormattedMessage id="inbounds.ltl.error" defaultMessage="Error" />;

  const content = (
    <StyledUl>
      {errors.map((error) => (
        <li key={error}>
          <Text>
            <FormattedMessage
              {...palletValidationMap[error].messageDescriptor}
              values={palletValidationMap[error].values}
            />
          </Text>
        </li>
      ))}
    </StyledUl>
  );

  return <MarginedNotification appearance="DANGER" content={{ title: Error, description: content }} />;
};
