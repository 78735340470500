import { IconV2 } from "common/components/ui/IconV2";
import { Stack } from "common/components/ui/Stack";
import React, { FC } from "react";

export interface LoadingSpinnerProps {
  center?: boolean;
  size?: "medium" | "large" | "4x";
}

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ center, size = "large" }) => {
  const spinner = <IconV2 data-testid="loading-spinner" type="loading" size={size} spin />;
  return center ? <Stack center>{spinner}</Stack> : spinner;
};
