import { createContext } from "react";
import { InboundBookingV2 } from "../CreateShipmentTypes";

export interface SaveStepOptions {
  shouldSkipSaveBooking?: boolean;
  canCreateBooking?: boolean;
}

export interface CreateShipmentContextValue {
  saveBooking: (shouldSubmit?: boolean) => Promise<InboundBookingV2>;
  composeSaveStep: (
    stepSubmitter: () => Promise<any>,
    options?: SaveStepOptions | (() => SaveStepOptions)
  ) => () => Promise<any>;
  isSaving: boolean;
  hasPendingChanges: boolean; // whether the step has submittable redux state changes
}

export const CreateShipmentContext = createContext<CreateShipmentContextValue>({
  saveBooking: async () => ({}) as InboundBookingV2,
  composeSaveStep: () => async () => {},
  isSaving: false,
  hasPendingChanges: false,
});
