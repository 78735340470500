import React, { FC } from "react";
import styled from "@emotion/styled";

import { Appearance, BorderIcon, Box, Card, Grid, Modal, Stack, Text, ThemeProps, Title } from "common/components/ui";
import { FormattedMessage } from "react-intl";

import { ShipmentResponse } from "@deliverr/replenishment-client";
import { fbaIntegrationCommonLabels } from "wholesale/common/FbaIntegrationCommon.labels";
import { StyledCloseButton } from "transfers/ui/StyledCloseButton";
import { FormatAddress } from "common/components/Address";

export interface ReplenishmentShipmentModalProps {
  showModal: boolean;
  onClose: () => void;
  shipments?: ShipmentResponse[];
}

const ReplenishmentShipmentModalHeader = styled(Stack)<ThemeProps>(
  ({ theme }) => `
  margin: ${theme.spacing.S3};
`
);

const ShipmentDestDetailsCard: FC<{ shipment: ShipmentResponse; key: string }> = ({ shipment }) => {
  return (
    <Card>
      <Grid columns={"1fr 3fr"} gap="S1">
        <>
          <BorderIcon appearance={Appearance.PRIMARY} iconType="location" size="XXL" />
        </>
        <Box>
          <FormatAddress
            address={shipment.destinationWarehouseAddress!}
            boldName={true}
            hideCompany={true}
          ></FormatAddress>
        </Box>
      </Grid>
    </Card>
  );
};

export const ReplenishmentShipmentModal: FC<ReplenishmentShipmentModalProps> = ({ showModal, onClose, shipments }) => {
  const labels = fbaIntegrationCommonLabels.shipmentsModal;
  return (
    <Modal show={showModal} onClose={onClose} hasCloseButton={false}>
      <ReplenishmentShipmentModalHeader center gap="S5">
        <StyledCloseButton iconType="close" appearance={Appearance.INFO} onClick={onClose} />
        <Title as="h3" displayAs="h4">
          <FormattedMessage {...labels.title} />
        </Title>
        <Text as="p">
          <FormattedMessage {...labels.subtitle} />
        </Text>
      </ReplenishmentShipmentModalHeader>
      <Grid columns={"1fr"}>
        <>
          {shipments
            ?.filter((shipment) => shipment.destinationWarehouseAddress ?? false)
            .map((shipment) => <ShipmentDestDetailsCard key={`${shipment.shipmentId}`} shipment={shipment} />)}
        </>
      </Grid>
    </Modal>
  );
};
