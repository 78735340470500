import React, { FC } from "react";
import { ShipmentDetailCard } from "./ShipmentDetailCard";
import { ListItemInfo, ListItemProps } from "common/components/InstructionList/InstructionListItem";
import { getOrdinalDisplay } from "common/components/InstructionList/ListOrdinalUtil";
import { Collapsible } from "common/components/CollapsibleSection";
import { ShipmentDetailCardButtonBarProps } from "./ShipmentDetailCardButtonBar";

export type InstructionCardId = string | number; // use an enum, yo
export interface InstructionCardInfo extends ListItemInfo, Collapsible, ShipmentDetailCardButtonBarProps {
  title: string | React.ReactNode;
  id: InstructionCardId;
  headerIcon?: React.ReactNode; // alternative to ordinalOptions
  cardContentClassName?: string;
  cardSectionClassName?: string;
  isDisabled?: boolean;
}

export interface InstructionCardProps extends ListItemProps, InstructionCardInfo {}

export const InstructionCard: FC<InstructionCardProps> = ({
  className,
  content,
  title,
  ordinalOptions,
  headerIcon,
  itemIndex,
  isCollapsible,
  isDefaultOpen,
  buttonBarClassName,
  buttons,
  cardContentClassName,
  cardSectionClassName,
  isDisabled,
}) => {
  const ordinalDisplay = getOrdinalDisplay({ icon: headerIcon, ordinalOptions, itemIndex });

  return (
    <ShipmentDetailCard
      className={className}
      header={title}
      headerIcon={ordinalDisplay}
      isCollapsible={isCollapsible}
      isDefaultOpen={isDefaultOpen}
      buttonBarClassName={buttonBarClassName}
      bodyClassName={cardSectionClassName}
      buttons={buttons}
      isDisabled={isDisabled}
    >
      <div className={cardContentClassName}>{content}</div>
    </ShipmentDetailCard>
  );
};
