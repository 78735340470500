import React, { FC } from "react";
import styled from "@emotion/styled";
import { UnstyledButton } from "dashboard/common";
import { Text, IconV2, ThemeProps, IconSizes } from "common/components/ui";
import { FlexDiv, FlexDivProps } from "common/components/FlexDiv";

const CardTitleText = styled(Text)<ThemeProps>(
  ({ theme }) => `
    align-items: center; 
    color: ${theme.colors.NEUTRAL["500"]};
    display: flex; 
    transition: color 250ms linear;

    &:hover {
      color: ${theme.colors.BLUE[300]};
    }
  `
);

const SubText = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-top: 2px;
    margin-left: ${theme.spacing.S1};
  `
);

const StyledIcon = styled(IconV2)<ThemeProps>(
  ({ theme }) => `
    font-size: ${theme.font.size.F3};
    margin-top: 2px;
  `
);

export interface CardTitleProps extends FlexDivProps {
  title: string | JSX.Element;
  onClick?: () => void;
}

export const CardTitle: FC<CardTitleProps> = ({ title, onClick, children, justifyContent, gap }) => {
  return (
    <FlexDiv justifyContent={justifyContent} gap={gap}>
      {onClick ? (
        <UnstyledButton onClick={onClick} data-testid="card-title-button">
          <CardTitleText size="bodyLarge">
            {title}
            <StyledIcon type="angle-right" data-testid="card-title-arrow" />
          </CardTitleText>
        </UnstyledButton>
      ) : (
        <Text size="bodyLarge">{title}</Text>
      )}
      {children}
    </FlexDiv>
  );
};

export interface CardTitlePropsV2 {
  title: React.ReactNode;
  clickable?: boolean;
  subText?: React.ReactNode;
}

export const CardTitleV2: React.FC<CardTitlePropsV2> = ({ title, children, clickable, subText }) => (
  <FlexDiv>
    {clickable ? (
      <FlexDiv className="card-title">
        <Text size="bodyLarge">{title}</Text>
        {subText && (
          <SubText size="caption" as="span">
            {subText}
          </SubText>
        )}
        <StyledIcon type="angle-right" data-testid="card-title-arrow" size={IconSizes.small} />
      </FlexDiv>
    ) : (
      <Text size="bodyLarge">{title}</Text>
    )}
    {children}
  </FlexDiv>
);
