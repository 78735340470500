import { createSelector } from "reselect";
import { getUserEmail } from "common/user/UserSelectors";
import { getIsTestSeller } from "organization/utils/getIsTestSeller";

export interface SelectIsTestSellerOptions {
  isLoose?: boolean;
}

export const selectIsTestSeller = ({ isLoose }: SelectIsTestSellerOptions = {}) =>
  createSelector(
    getUserEmail,
    (state) => state.organization.isTestAccount,
    (email, isTestAccount) => getIsTestSeller({ email, isTestAccount, isLoose })
  );
