export enum FreightPalletValidations {
  OVER_MAX_PALLET_QTY = "OVER_MAX_PALLET_QTY",
  OVER_MAX_PALLET_TOTAL_WEIGHT = "OVER_MAX_PALLET_TOTAL_WEIGHT",
  UNDER_MINIMUM_TOTAL_WEIGHT = "UNDER_MINIMUM_TOTAL_WEIGHT",
  PALLETS_OVER_MAX_WIDTH = "PALLETS_OVER_MAX_WIDTH",
  PALLETS_OVER_MAX_LENGTH = "PALLETS_OVER_MAX_LENGTH",
  PALLETS_OVER_MAX_HEIGHT = "PALLETS_OVER_MAX_HEIGHT",
  PALLETS_OVER_MAX_WEIGHT = "PALLETS_OVER_MAX_WEIGHT",
  MORE_PALLETS_THAN_BOXES = "MORE_PALLETS_THAN_BOXES",
  PALLET_COUNT_NOT_MATCHING = "PALLET_COUNT_NOT_MATCHING",
}
