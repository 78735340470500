import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import {
  AddressInputRequiredField,
  addressInputFieldNames,
  intlAddressInputFieldNames,
} from "common/components/AddressInput";
import { StyledErrorsList } from "./InboundAddressForm.styles";

interface InboundAddressErrorToastProps {
  requiredFields: AddressInputRequiredField[];
  isIntlAddress?: boolean;
}

export const InboundAddressErrorToast: FC<InboundAddressErrorToastProps> = ({
  requiredFields,
  isIntlAddress = false,
}) => {
  const fieldNames = isIntlAddress ? intlAddressInputFieldNames : addressInputFieldNames;
  return (
    <div>
      <FormattedMessage id={"inbounds.fromAddress.requiredFields"} defaultMessage={"Required Fields"} />:
      <StyledErrorsList>
        {requiredFields.map((field) => (
          <li key={field}>
            <FormattedMessage {...fieldNames[field]} />
          </li>
        ))}
      </StyledErrorsList>
    </div>
  );
};
