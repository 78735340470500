import React from "react";
import { Button } from "common/components/ui";
import styled from "@emotion/styled";
import { TooltipWrapperReturnsButton } from "./TooltipWrapperReturnsButton";
import { useCreateReturnsButton } from "./useCreateReturnsButton";

const StyledButton = styled(Button)`
  height: 40px;
`;

export const CreateReturnsButton = ({ children }) => {
  const { navigateToCreateReturns, showTooltip, tooltipMessage } = useCreateReturnsButton();
  return (
    <TooltipWrapperReturnsButton showTooltip={showTooltip} label={tooltipMessage}>
      <StyledButton
        disabled={showTooltip}
        secondary
        onClick={navigateToCreateReturns}
        data-testid="returns-create-button"
      >
        {children}
      </StyledButton>
    </TooltipWrapperReturnsButton>
  );
};
