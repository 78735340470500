import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { AxiosError } from "axios";
import { ApiError, DeliverrError } from "@deliverr/errors";
import { ListingSolution } from "./ListingSolution";
import { JobStatus } from "./JobStatus/JobStatus";
import { ProcessEntityList } from "./ProcessEntityList";
import { ProcessEntityResponse } from "./ProcessEntityResponse";
import { OnboardingOrder } from "./OnboardingOrder";
import { OauthResponse } from "./OauthResponse";

export type OnboardingClientConfig = ApiClientConfig;

export class OnboardingClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: OnboardingClientConfig = { baseURL: mustBeDefined(process.env.ONBOARDING_URL) }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`,
    });
  }

  async getSellerHash(sellerId: string): Promise<{ hash: string; state: string }> {
    try {
      return await this.apiClient.get({
        url: `/oauth/verifier/${sellerId}`,
        params: {},
        authentication: isAuthenticated,
      });
    } catch (error) {
      this.handleOnboardingError("getSellerHash", error);
    }
  }

  /**
   * Get a deejayOrderV2 record from DynamoDB querying the sellerId-sellerOrderId-index.
   * @returns OnboardingOrder
   */
  async getOnboardingOrderBySellerOrderId(
    sellerId: string,
    channel: string,
    sellerOrderId: string
  ): Promise<OnboardingOrder> {
    try {
      return await this.apiClient.get({
        url: `/ecommerce/order/statusBySellerId`,
        params: { sellerId, channel, sellerOrderId },
        authentication: isAuthenticated,
      });
    } catch (error) {
      this.handleOnboardingError("getOnboardingOrderBySellerOrderId", error);
    }
  }

  async getPipe17ApiKey(code: string): Promise<{ apiKey: string }> {
    try {
      return await this.apiClient.get({
        url: `/pipe17/apikey`,
        params: {
          code,
        },
        authentication: isAuthenticated,
      });
    } catch (error) {
      this.handleOnboardingError("getPipe17ApiKey", error);
    }
  }

  async connectListingSolution(
    listingSolution: ListingSolution,
    sellerId: string,
    data: { email: string; apiKey: string } | { redirectUrl: string; storeName?: string; integration?: string }
  ): Promise<OauthResponse> {
    try {
      return await this.apiClient.post({
        url: `/${listingSolution}/setup`,
        body: {},
        params: { sellerId, ...data },
        authentication: isAuthenticated,
      });
    } catch (error) {
      this.handleOnboardingError("connectListingSolution", error);
    }
  }

  async addAndActivateSalesChannel(
    salesChannelName: ListingSolution,
    sellerId: string,
    data: { email: string; apiKey: string } | { redirectUrl: string; storeName?: string; integration?: string }
  ): Promise<OauthResponse> {
    try {
      return await this.apiClient.post({
        url: `/${salesChannelName}/addchannel`,
        body: {},
        params: { sellerId, ...data },
        authentication: isAuthenticated,
      });
    } catch (error) {
      this.handleOnboardingError("addAndActivateSalesChannel", error);
    }
  }

  // Requires jwtToken authentication
  async getCatalogUpdateStatus(): Promise<JobStatus> {
    try {
      return await this.apiClient.get({
        url: `/jobs/product/sync`,
        params: {},
        authentication: isAuthenticated,
      });
    } catch (error) {
      this.handleOnboardingError("getCatalogUpdateStatus", error);
    }
  }

  async getOrderForCreation(
    sellerId: string,
    listingSolution: ListingSolution,
    orderId: string
  ): Promise<ProcessEntityList> {
    try {
      return await this.apiClient.get({
        url: `${listingSolution}/order/creation/${orderId}`,
        params: {
          sellerId,
        },
        authentication: isAuthenticated,
      });
    } catch (error) {
      this.handleOnboardingError("getOrderForCreation", error);
    }
  }

  async deleteOnboardingOrder(sellerId: string, channel: string, sellerOrderId: string): Promise<any> {
    try {
      return await this.apiClient.delete({
        url: `/ecommerce/order`,
        params: {
          sellerId,
          channel,
          sellerOrderId,
        },
        authentication: isAuthenticated,
      });
    } catch (error) {
      this.handleOnboardingError("deleteOnboardingOrder", error);
    }
  }

  async createOrders(
    sellerId: string,
    listingSolution: ListingSolution,
    orders: any[]
  ): Promise<ProcessEntityResponse> {
    try {
      /**
       * even if order fails to fully process - create a deliverrOrder in fulfillment - ProcessEntityResult.status can
       * equal "SUCCESS". In this case, that means we saw a known error that was handled appropriately.
       * "SUCCESS" should not be used to determine successful order creation, use getOnboardingOrderBySellerOrderId to
       * look up an OnboardingOrder and check for the presence of a deliverrOrderId.
       */
      return await this.apiClient.post({
        url: `${listingSolution}/orders`,
        body: orders,
        params: { sellerId },
        authentication: isAuthenticated,
      });
    } catch (error) {
      this.handleOnboardingError("createOrders", error);
    }
  }

  protected handleOnboardingError(name: string, err: AxiosError): never {
    if (!err.response) {
      throw new DeliverrError({
        code: ApiError.UNKNOWN_ERROR,
        functionName: name,
        payload: err,
      });
    }

    if (err.response?.status !== undefined && err.response.status === 504) {
      const networkError = new DeliverrError({
        code: ApiError.NETWORK_ERROR,
        functionName: name,
      });

      throw networkError;
    }

    throw new DeliverrError({
      code: err.response.data ? err.response.data.code : ApiError.UNKNOWN_ERROR,
      subcode: String(err.response.status), // TODO use response.data.error.subcode when error handling is standardized
      functionName: name,
      payload: err.response.data,
    });
  }
}
