import { RootState } from "RootReducer";
import { FlowEnabledStepMap } from "./FlowEnabledStepMap";
import { last } from "lodash/fp";

export const getPreviousEnabledFlowStep = <FlowStepType extends string>(
  currentStep: FlowStepType | undefined,
  flowStepType: { [key: string]: FlowStepType },
  flowEnabledStepMap: FlowEnabledStepMap<FlowStepType>,
  state: RootState
) => {
  const flowSteps = Object.values(flowStepType);
  const currentStepIndex = flowSteps.findIndex((step) => step === currentStep);
  const previousSteps = flowSteps.slice(0, currentStepIndex);

  return currentStepIndex === -1 ? undefined : last(previousSteps.filter((step) => flowEnabledStepMap[step]?.(state)));
};
