import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { CurrentOrdersV3 } from "./CurrentOrder";
import { FastTagRecommendation } from "./FastTagRecommendation";
import { FulfilledOrders } from "./FulfilledOrders/FulfilledOrders";
import { FulfillmentMetricsV2 } from "./FulfillmentMetrics/FulfillmentMetrics";
import { ReturnItemStatusBreakdown } from "./ReturnItems/ReturnItemStatusBreakdown";
import { ReturnOrdersV3 } from "./ReturnOrders/ReturnOrders";
import { ServiceLevelsV2 } from "./ServiceLevels";
import { ShopifyFastTagPerformance } from "@deliverr/commons-clients";
import { SkuReplenishment } from "./SkuReplenishment/SkuReplenishment";
import { SkuReplenishmentFilters } from "dashboard/SkuReplenishment/SkuReplenishmentState";
import { SkuReplenishmentProduct } from "./SkuReplenishment/SkuReplenishmentProduct";
import { SortMethod } from "./SkuReplenishment/SortMethod";
import { UnitsOverview } from "./UnitsOverview/UnitsOverview";
import { isAuthenticated } from "../core/authentication";
import { mapError } from "../core/mapError";
import { mustBeDefined } from "common/utils/mustBeDefined";

export type DashboardClientConfig = ApiClientConfig;
export class DashboardClient {
  private apiClient: ApiClient;

  constructor(
    { baseURL }: DashboardClientConfig = {
      baseURL: mustBeDefined(process.env.DASHBOARD_SERVICE_URL),
    }
  ) {
    this.apiClient = new ApiClient({
      baseURL,
    });
  }

  async getFulfilledOrders(
    sellerId: string,
    startDate: string,
    endDate: string,
    timezone?: string
  ): Promise<FulfilledOrders> {
    try {
      return await this.apiClient.get({
        url: `/v1/seller/${sellerId}/orders/fulfilled-orders`,
        params: {
          startDate,
          endDate,
          timezone,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err);
    }
  }

  async getServiceLevelBreakdownV2(
    sellerId: string,
    startDate: string,
    endDate: string,
    timezone?: string
  ): Promise<ServiceLevelsV2 | undefined> {
    try {
      return await this.apiClient.get({
        url: `/v2/seller/${sellerId}/orders/service-level-breakdown`,
        params: {
          startDate,
          endDate,
          timezone,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err);
    }
  }

  async getCurrentOrdersV3(sellerId: string, timezone?: string): Promise<CurrentOrdersV3 | undefined> {
    try {
      return await this.apiClient.get({
        url: `/v3/seller/${sellerId}/orders/current`,
        params: { timezone },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err);
    }
  }

  async getFulfillmentMetricsV2(
    sellerId: string,
    startDate: string,
    endDate: string,
    timezone?: string
  ): Promise<FulfillmentMetricsV2 | undefined> {
    try {
      return await this.apiClient.get({
        url: `/v2/seller/${sellerId}/orders/fulfillment-metrics`,
        params: {
          startDate,
          endDate,
          timezone,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err);
    }
  }

  async getFastTagRecommendations(sellerId: string): Promise<FastTagRecommendation[]> {
    try {
      return await this.apiClient.get({
        url: `/v1/seller/${sellerId}/fast-tags/recommendations`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err);
    }
  }

  async getSkuReplenishment(
    sellerId: string,
    page?: number,
    maxDaysLeft?: number,
    sortMethod?: SortMethod,
    maxRegions?: number,
    excludeSkusWithInbounds?: boolean
  ): Promise<SkuReplenishment | undefined> {
    try {
      return await this.apiClient.get({
        url: `/v1/seller/${sellerId}/inventory/replenishment`,
        params: {
          page,
          maxDaysLeft,
          sortMethod,
          maxRegions,
          excludeSkusWithInbounds,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err);
    }
  }

  async getUnitsOverview(sellerId: string): Promise<UnitsOverview | undefined> {
    try {
      return await this.apiClient.get({
        url: `/v1/seller/${sellerId}/units/overview`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err);
    }
  }

  async getSkuReplenishmentCsv(
    sellerId: string,
    skuReplenishmentFilters: SkuReplenishmentFilters
  ): Promise<SkuReplenishmentProduct[] | undefined> {
    try {
      return await this.apiClient.get({
        url: `/v1/seller/${sellerId}/inventory/replenishment/csv`,
        params: skuReplenishmentFilters,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err);
    }
  }

  async getShopifyFastTagPerformance(sellerId: string): Promise<ShopifyFastTagPerformance> {
    try {
      return await this.apiClient.get({
        url: `/v1/seller/${sellerId}/fast-tags/performance/shopify`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err);
    }
  }

  async getReturnOrdersV3(
    sellerId: string,
    startDate: string,
    endDate: string,
    timezone?: string
  ): Promise<ReturnOrdersV3 | undefined> {
    try {
      return await this.apiClient.get({
        url: `/v3/seller/${sellerId}/returns/daily-breakdown`,
        params: {
          startDate,
          endDate,
          timezone,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err);
    }
  }

  async getReturnItemStatusBreakdown(
    sellerId: string,
    startDate: string,
    endDate: string,
    timezone?: string
  ): Promise<ReturnItemStatusBreakdown | undefined> {
    try {
      return await this.apiClient.get({
        url: `/v1/seller/${sellerId}/returns/item-status-breakdown`,
        params: {
          startDate,
          endDate,
          timezone,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err);
    }
  }
}
