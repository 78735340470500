import sumBy from "lodash/sumBy";
import { RootState } from "RootReducer";
import { getTotalUnitQuantity } from "inbounds/steps/InboundStepSelectors";
import { PrepCreationSource } from "prep/types/PrepCreationSource";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";

export const fetchTotalUnits =
  (source: PrepCreationSource) =>
  (state: RootState): number => {
    if (source === PrepCreationSource.INBOUNDS) {
      return getTotalUnitQuantity(state);
    } else if (source === PrepCreationSource.REPLENISHMENT) {
      const transferState = selectTransferCreate(state);
      const items = transferState?.replenishmentOrder?.orderItems ?? [];
      return items && items.length > 0 ? sumBy(items, "totalUnits") : 0;
    }
    return 0;
  };
