import { FacebookAdAccount } from "common/clients/facebook/FacebookAdAccount";
import { FacebookInstagramAccount } from "common/clients/facebook/FacebookInstagramAccount";
import { FacebookPage } from "common/clients/facebook/FacebookPage";
import { FacebookPixel } from "common/clients/facebook/FacebookPixel";
import { facebookClient } from "common/clients/instances";
import { notifyUserOfError } from "common/ErrorToast";
import log, { logError, logStart } from "Logger";
import { Auth } from "../../model/Auth";
import { hasProfileInfo, ProfileInfoResponse } from "../../model/Profile";
import { FacebookConnectActionTypes } from "./FacebookConnectActionTypes";
import { SPThunkAction } from "common/ReduxUtils";

export const activateFacebook = () => async (dispatch, getState) => {
  const ctx = logStart({ fn: "activateFacebook" });
  const {
    user: { sellerId },
    facebook: { activationError },
  } = getState();
  try {
    log.info({ ...ctx }, "activating facebook");
    // eslint-disable-next-line no-extra-boolean-cast
    if (Boolean(activationError)) {
      dispatch({ type: FacebookConnectActionTypes.FACEBOOK_ACTIVATION_ERROR, activationError: undefined });
    }
    await facebookClient.activateSeller(sellerId);
  } catch (err) {
    notifyUserOfError({ err, toastId: "facebookActivationError" });
    logError(ctx, err);
  }
};

export const deactivateFacebook = () => async (_, getState) => {
  const ctx = logStart({ fn: "deactivateFacebok" });
  const {
    user: { sellerId },
  } = getState();
  try {
    await facebookClient.deactivateSeller(sellerId);
    location.reload();
  } catch (err) {
    notifyUserOfError({ err, toastId: "facebookDeactivationError" });
    logError(ctx, err);
  }
};

export const adAccountsReceived = (adAccounts: FacebookAdAccount[]) => ({
  type: FacebookConnectActionTypes.FACEBOOK_AD_ACCOUNTS_RECEIVED as const,
  adAccounts,
});

export const adAccountSelected = (adAccountId: FacebookAdAccount["adAccountId"]) => ({
  type: FacebookConnectActionTypes.FACEBOOK_AD_ACCOUNT_SELECTED as const,
  adAccountId,
});

export const getAdAccounts = (sellerId: string, userId: string, accessToken: string) => async (dispatch) => {
  const adAccountsResponse = await facebookClient.getAdAccounts(sellerId, userId, accessToken);

  dispatch(adAccountsReceived(adAccountsResponse));

  return adAccountsResponse;
};

export const pagesReceived = (pages: FacebookPage[]) => ({
  type: FacebookConnectActionTypes.FACEBOOK_PAGES_RECEIVED as const,
  pages,
});

export const pageSelected = (pageId: string) => ({
  type: FacebookConnectActionTypes.FACEBOOK_PAGE_SELECTED as const,
  pageId,
});

export const getPages = (sellerId: string, userId: string, accessToken: string) => async (dispatch) => {
  const pagesResponse = await facebookClient.getPages(sellerId, userId, accessToken);
  dispatch(pagesReceived(pagesResponse));

  return pagesResponse;
};

export const pixelsReceived = (pixels: FacebookPixel[]) => ({
  type: FacebookConnectActionTypes.FACEBOOK_PIXELS_RECEIVED as const,
  pixels,
});

export const permissionsChecked = (hasValidPermissions: boolean) => ({
  type: FacebookConnectActionTypes.FACEBOOK_PERMISSIONS_CHECKED as const,
  hasValidPermissions,
});

export const checkPermissions = (
  sellerId: string,
  userId: string,
  accessToken: string
): SPThunkAction<Promise<boolean>> => async (dispatch) => {
  const hasValidPermissions = await facebookClient.hasValidPermissions(sellerId, userId, accessToken);

  dispatch(permissionsChecked(hasValidPermissions));

  return hasValidPermissions;
};

export const sellerRegistrationComplete = () => ({
  type: FacebookConnectActionTypes.FACEBOOK_SELLER_REGISTRATION_COMPLETE as const,
});

export const getProfile = (userId: string, accessToken: string) => async (dispatch) => {
  const getProfileResponse = await fetch(
    `https://graph.facebook.com/${userId}?access_token=${accessToken}&fields=name,picture`
  );

  if (hasProfileInfo(getProfileResponse)) {
    dispatch(profileInfoReceived(await getProfileResponse.json()));
  }

  return getProfileResponse;
};

export const getInstagramAccounts = (sellerId: string, businessId: string, accessToken: string) => async (dispatch) => {
  const instagramAccountsResponse = await facebookClient.getInstagramAccounts(sellerId, businessId, accessToken);

  dispatch(instagramAccountsReceived(instagramAccountsResponse));

  return instagramAccountsResponse;
};

export const instagramAccountsReceived = (instagramAccounts: FacebookInstagramAccount[]) => ({
  type: FacebookConnectActionTypes.FACEBOOK_INSTAGRAM_ACCOUNTS_RECEIVED as const,
  instagramAccounts,
});

export const instagramAccountSelected = (instagramAccountId: FacebookInstagramAccount["id"]) => ({
  type: FacebookConnectActionTypes.FACEBOOK_INSTAGRAM_ACCOUNT_SELECTED as const,
  instagramAccountId,
});

export const pixelSelected = (pixelId: FacebookPixel["id"]) => ({
  type: FacebookConnectActionTypes.FACEBOOK_PIXEL_SELECTED as const,
  pixelId,
});

export const profileInfoReceived = (profileInfo: ProfileInfoResponse) => ({
  type: FacebookConnectActionTypes.FACEBOOK_PROFILE_INFO_RECIEVED as const,
  profileInfo,
});

export const instagramAdsToggleSet = (active: boolean) => ({
  type: FacebookConnectActionTypes.FACEBOOK_INSTAGRAM_ADS_TOGGLE_SET as const,
  active,
});

export const loginStatusReceived = (auth: Auth, newLogin = false) => ({
  type: FacebookConnectActionTypes.FACEBOOK_LOGIN_STATUS_RECEIVED as const,
  auth,
  newLogin,
});
