import React from "react";
import cls from "./BarcodeConfirmModalBarcode.less";

interface BarcodeConfirmModalBarcodeProps {
  barcodes: string[];
}

export const BarcodeConfirmModalBarcode: React.ComponentType<BarcodeConfirmModalBarcodeProps> = ({ barcodes }) => (
  <div className={cls.barcodeConfirmModalBarcode}>
    {barcodes.map((barcode) => (
      <div className={cls.barcodeContainer} key={barcode}>
        <img
          key={barcode}
          src={`${process.env.INBOUND_SERVICE_URL}/v1/barcode/image/?code=${barcode}&includetext=true&scale=2`}
          alt="Inbound item barcode"
        />
      </div>
    ))}
  </div>
);
