import { LocalizedMessage } from "common/components/ui";
import cx from "classnames";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import cls from "./Table.less";

interface TableNoResultProps {
  message?: LocalizedMessage;
  hideBoxShadow?: boolean;
}

export const TableNoResult: FC<TableNoResultProps> = ({ message, hideBoxShadow }) => {
  return (
    <div className={cx(cls.noSearchResults, { [cls.hideBoxShadow]: hideBoxShadow })}>
      <h1>
        <FormattedMessage id={"Table.oops"} defaultMessage={"Oops..."} />
      </h1>
      <p>
        {message ?? (
          <FormattedMessage
            id={"Table.noResultsMessage"}
            defaultMessage={"We couldn't find any results, try searching for something else"}
          />
        )}
      </p>
    </div>
  );
};
