import { dateToAlgoliaFilter } from "./dateToAlgoliaFilter";
import { serviceToAlgoliaFilter } from "./serviceToAlgoliaFilter";
import { statusToAlgoliaFilter } from "./statusToAlgoliaFilter";
import { OrderTag } from "@deliverr/commons-objects";
import { OrderListDateFilter, OrderListServiceFilter, OrderListStatusFilter } from "../OrderListReducer";

export interface GetAlgoliaFiltersProps {
  status: OrderListStatusFilter;
  serviceLevel: OrderListServiceFilter;
  date: OrderListDateFilter;
  isAdmin?: boolean;
}

export const getAlgoliaFilters = ({ status, serviceLevel, date, isAdmin = false }: GetAlgoliaFiltersProps) => {
  const orderStatusFilters = statusToAlgoliaFilter(status);
  const orderServiceFilters = serviceToAlgoliaFilter(serviceLevel);
  const orderDateFilters = dateToAlgoliaFilter(date);
  // admins should be able to view distribution orders
  const distributionFilter = isAdmin ? [] : [`NOT tags:${OrderTag.DISTRIBUTION_ORDER}`];
  return [...orderStatusFilters, ...orderServiceFilters, ...orderDateFilters, ...distributionFilter];
};
