import { FreightPalletValidations } from "inbounds/steps/ship/ltl/util/validations";
import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { selectLtlPalletDimsViolations } from "./selectLtlPalletDimsViolations";
import { selectFtlPalletDimsViolations } from "./selectFtlPalletDimsViolations";
import { selectIsLtlPallets } from "./selectIsLtlPallets";

interface PalletDimsCompliance {
  readonly isValid: boolean; // are all expected dims compliant?
  readonly palletDimsViolations: FreightPalletValidations[]; // list of dims violations
}

/**
 * Checks for dimensions compliance on each individual pallet and on cumulative totals for weight, height and qty
 */
export const selectPalletDimsCompliance = createSelector<
  RootState,
  boolean,
  FreightPalletValidations[],
  FreightPalletValidations[],
  PalletDimsCompliance
>(
  selectIsLtlPallets,
  selectLtlPalletDimsViolations,
  selectFtlPalletDimsViolations,
  (isLtlPallets, ltlPalletDimsViolations, ftlPalletDimsViolations) => {
    const palletDimsViolations = isLtlPallets ? ltlPalletDimsViolations : ftlPalletDimsViolations;

    return {
      palletDimsViolations,
      isValid: palletDimsViolations.length === 0,
    };
  }
);
