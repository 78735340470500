import { RootState } from "../../RootReducer";

export const getFlexportUser = (state: RootState) => state.flexportUser;

export const getFlexportUserId = (state: RootState) => state.flexportUser.dbid;

export const getFlexportUserName = (state: RootState) => state.flexportUser.name;

export const getFlexportUserEmail = (state: RootState) => state.flexportUser.email;

export const getFlexportUserCompanyEntityId = (state: RootState) => state.flexportUser.companyEntityId;

export const getFlexportUserClientFid = (state: RootState) => state.flexportUser.clientFid;

export const getFlexportUserDisplaySmbOnboarding = (state: RootState) => state.flexportUser.displaySmbOnboarding;
