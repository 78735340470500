import React, { useMemo } from "react";
import { defaultTheme, IconV2, Text } from "common/components/ui";
import { format } from "date-fns-tz";
import { useIntl } from "react-intl";

import { useCurrencyValue } from "common/components/Currency";
import { TableColumn } from "common/components/lists/Table/Table";
import { ShortMonthDateYear } from "common/date/DateFormat";
import { fromLooseDate } from "common/date/LooseDate";
import { NCStatusPill } from "inbounds/steps/ship/NCStatusPill";
import { ProblemTypeCell } from "./ProblemTypeCell";
import { ProblemUnitsCell } from "./ProblemUnitsCell";
import { ProblemRowData } from "./types";
import { isEmpty } from "lodash/fp";

export const useProblemsTableColumns = (): {
  columns: TableColumn<ProblemRowData>[];
  valueLabels: Record<string, string | JSX.Element | React.ReactNodeArray>;
} => {
  const { formatMessage } = useIntl();
  const formatCurrency = useCurrencyValue();

  const valueLabels = {
    unknown: formatMessage({
      id: "unknown",
      defaultMessage: "N/A",
    }),
    multipleSkus: formatMessage({
      id: "inbounds.shipmentProblemsTable.multipleSkus",
      defaultMessage: "Multiple SKUs",
    }),
  };

  const columns: TableColumn<ProblemRowData>[] = useMemo(() => {
    const tableColumns: TableColumn<ProblemRowData>[] = [
      {
        header: formatMessage({
          id: "inbounds.shipmentProblems.header.problemType",
          defaultMessage: "Problem Type",
        }),
        renderCell: ProblemTypeCell,
      },
      {
        header: formatMessage({
          id: "inbounds.shipmentProblems.header.problemId",
          defaultMessage: "Issue ID",
        }),
        renderCell: ({ id }: ProblemRowData) => <Text bold>{id}</Text>,
        // long enough to fit  NC-(8 chars)
        width: "145px",
      },
      {
        header: formatMessage({
          id: "inbounds.shipmentProblems.header.affectedCdskus",
          defaultMessage: "CDSKU",
        }),
        renderCell: ({ affectedCdskus }: ProblemRowData) =>
          affectedCdskus && affectedCdskus.length > 0
            ? affectedCdskus.length > 1
              ? formatMessage(
                  {
                    id: "multiple",
                    defaultMessage: "Multiple ({amount})",
                  },
                  { amount: affectedCdskus.length }
                )
              : affectedCdskus[0]
            : valueLabels.unknown,
        // long enough for typical cdsku format
        width: "125px",
      },
      {
        header: formatMessage({
          id: "sku",
          defaultMessage: "SKU",
        }),
        ellipsis: true,
        renderCell: ({ mappedSkus }: ProblemRowData) =>
          mappedSkus.length === 0
            ? valueLabels.unknown
            : mappedSkus.length > 1
            ? valueLabels.multipleSkus
            : !isEmpty(mappedSkus[0].msku)
            ? mappedSkus[0].msku
            : mappedSkus[0].dsku,
      },
      {
        header: formatMessage({
          id: "qty",
          defaultMessage: "Qty",
        }),
        renderCell: ProblemUnitsCell,
        width: "80px",
      },
      {
        header: formatMessage({
          id: "inbounds.shipmentProblems.header.date",
          defaultMessage: "Date",
        }),
        renderCell: ({ createdAt }: ProblemRowData) => format(fromLooseDate(createdAt), ShortMonthDateYear),
        width: "125px",
      },
      {
        header: formatMessage({
          id: "status",
          defaultMessage: "Status",
        }),
        renderCell: ({ status, problemType }: ProblemRowData) => (
          <NCStatusPill status={status} shipmentProblemType={problemType} />
        ),
        width: "150px",
      },
      {
        header: "",
        renderCell: () => <IconV2 type="angle-right" color={defaultTheme.colors.BLUE[300]} />,
        width: "44px",
      },
    ];

    return tableColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatCurrency, formatMessage]);

  return {
    columns,
    valueLabels,
  };
};
