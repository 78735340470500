// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.InstructionCardList__gaplessCards--SnrrX > div:not(:first-child) {\n  margin-top: 1px;\n}\n", "",{"version":3,"sources":["webpack://./src/app/inbounds/steps/ship/view/cards/InstructionCardList.less"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF","sourcesContent":["div.gaplessCards > div:not(:first-child) {\n  margin-top: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gaplessCards": "InstructionCardList__gaplessCards--SnrrX"
};
export default ___CSS_LOADER_EXPORT___;
