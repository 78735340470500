import { AttachmentType, WholesaleShipmentDTO, WholesaleShipmentType } from "@deliverr/wholesale-client";
import { any } from "lodash/fp";

const CASE_LABELS_VALUES: AttachmentType[] = [
  AttachmentType.CASE_LABELS,
  AttachmentType.CASE_LABELS_ZPL,
  AttachmentType.SHIPPING_LABELS,
  AttachmentType.SHIPPING_LABELS_ZPL,
];

export function shipmentHasAllAttachments(shipment?: WholesaleShipmentDTO): boolean {
  if (!shipment) {
    return true;
  }
  return (
    any((att) => CASE_LABELS_VALUES.includes(att.attachmentType), shipment.attachments) &&
    (shipment.shipmentType !== WholesaleShipmentType.PARTIAL_PALLET ||
      any((att) => att.attachmentType === AttachmentType.PALLET_LABELS, shipment.attachments))
  );
}
