export enum PickupDetailsActionTypes {
  SET_PICKUP_APPOINTMENT_REQUIRED = "SET_PICKUP_APPOINTMENT_REQUIRED",
  SET_CARGO_READY_DATE = "SET_CARGO_READY_DATE",
  SET_PICKUP_REFERENCE_NUMBER = "SET_PICKUP_REFERENCE_NUMBER",
  SET_CUSTOMER_REFERENCE_NUMBER = "SET_PICKUP_CUSTOMER_REFERENCE_NUMBER",
  SET_PICKUP_FACILITY_OPERATING_HOURS = "SET_PICKUP_FACILITY_OPERATING_HOURS",
  SET_PICKUP_ADDRESS = "SET_PICKUP_ADDRESS",
  SET_PICKUP_INSTRUCTIONS = "SET_PICKUP_INSTRUCTIONS",
  SET_PICKUP_COMPANY_NAME = "SET_PICKUP_COMPANY_NAME",
  SET_PICKUP_CONTACT = "SET_PICKUP_CONTACT",
}
