import styled from "@emotion/styled";
import React from "react";
import { Appearance, Color } from "../shared/theme/color";
import { ThemeProps, DefaultTheme } from "../shared";
import { IconV2Types, IconV2 } from "../IconV2";

/** Designer will decide what colors are suitable for design system */
export type BorderIconColors = Exclude<keyof typeof Appearance, "DEFAULT"> | Extract<keyof typeof Color, "INDIGO">;

/** Additional Sizes were included in the situation the BorderIcon would like to be implemented somewhere else */
export type BorderIconSize = "XL" | "XXL";

export interface BorderIconProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Type of Icon */
  iconType: IconV2Types;
  /** Color of background and Icon displayed */
  appearance?: BorderIconColors;
  /** Icon Size */
  size?: BorderIconSize;
}

type BorderIconStyleProps = Omit<BorderIconProps, "iconType">;

const setColor = ({ appearance = "PRIMARY", theme }: ThemeProps<BorderIconStyleProps>) => {
  const appearanceColor = theme.config.colorByAppearance[appearance] ?? appearance;
  const color = theme.colors[appearanceColor];
  const background = color[appearance === Appearance.INFO ? 40 : 100];
  const foreground = color[300];

  return `
    background-color: ${background};
		color: ${foreground};
  `;
};

const propsBySize = ({ size = "XL", theme }: ThemeProps<BorderIconStyleProps>) => {
  const sizeObj: Record<BorderIconSize, string> = {
    XL: `
      width: 2.5rem;
      height: 2.5rem;
      font-size: 1.25rem;
    `,
    XXL: `
      width: 4rem;
      height: 4rem;
      font-size: 2rem;
    `,
  };

  return sizeObj[size];
};

export const IconContainer = styled.div<BorderIconStyleProps, DefaultTheme>(
  ({ theme, appearance, size }) => `
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 0;
    ${setColor({ appearance, theme })};
    ${propsBySize({ size, theme })};
  `
);

export const BorderIcon: React.FC<BorderIconProps> = ({ appearance, iconType, size, ...props }) => (
  <IconContainer appearance={appearance} size={size} {...props}>
    <IconV2 type={iconType} size={size === "XXL" ? "large" : "medium"} />
  </IconContainer>
);
