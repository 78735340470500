import { ReducerDictionary, createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { DrayageCargoDetailsState } from "freight/types/DrayageCargoDetailsTypes";
import { drayageCargoDetailsInitialState } from "./drayageCargoDetailsInitialState";
import { DrayageCargoActionTypes } from "../actions/DrayageCargoActionTypes";
import { FreightBookingActionTypes } from "freight/store/freightBooking/actions/FreightBookingActionTypes";

const reducer: ReducerDictionary<DrayageCargoDetailsState> = {
  ...handleSimpleReducerUpdates([
    DrayageCargoActionTypes.SET_PACKING_LIST_DOCUMENT_KEY,
    DrayageCargoActionTypes.SET_PACKING_LIST_FILE_NAME,
  ]),
  [FreightBookingActionTypes.RESET_FREIGHT_BOOKING_STATE]: () => drayageCargoDetailsInitialState,
  [FreightBookingActionTypes.FREIGHT_BOOKING_COMPLETED]: () => drayageCargoDetailsInitialState,
};

export const drayageCargoDetailsReducer = createReducer<DrayageCargoDetailsState>(
  drayageCargoDetailsInitialState,
  reducer
);
