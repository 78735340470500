import produce from "immer";

import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { CargoPalletDetailsActionTypes } from "../actions/CargoPalletDetailsActionTypes";
import { CargoPalletDetailsState } from "freight/types/CargoPalletTypes";
import { cargoPalletDetailsInitialState, newPalletItem } from "./cargoPalletDetailsInitialState";
import { LTL_MAX_PALLET_COUNT } from "freight/constants/cargoPalletConstants";
import { FreightMode } from "@deliverr/freight-client";
import { FreightBookingActionTypes } from "freight/store/freightBooking/actions/FreightBookingActionTypes";

const IDENTICAL_PALLETS = "identicalPallets";

const reducer: ReducerDictionary<CargoPalletDetailsState> = {
  ...handleSimpleReducerUpdates([CargoPalletDetailsActionTypes.SET_TOTAL_PALLET_WEIGHT]),
  [CargoPalletDetailsActionTypes.SET_TOTAL_PALLET_COUNT]: (state, { totalPalletCount }) => {
    return produce(state, (draft) => {
      draft.totalPalletCount = totalPalletCount;
      if (draft.palletLineItems.length === 1) {
        draft.palletLineItems[0].totalPallets = totalPalletCount;
      } else if (draft.palletLineItems.length === 0) {
        draft.palletLineItems.push({
          ...newPalletItem,
          totalPallets: totalPalletCount,
        });
      }
      if (totalPalletCount <= LTL_MAX_PALLET_COUNT) {
        draft.type = FreightMode.LTL;
        draft.totalPalletWeight = draft.palletLineItems.reduce((acc, item) => {
          return acc + item.totalPallets * item.palletDimensions.weight;
        }, 0);
      } else {
        draft.type = FreightMode.FTL;
      }
    });
  },
  [CargoPalletDetailsActionTypes.ADD_NEW_PALLET_LINE_ITEM]: (state, { palletLineItem }) => {
    return produce(state, (draft) => {
      draft.palletLineItems.push(palletLineItem);
    });
  },
  [CargoPalletDetailsActionTypes.REMOVE_PALLET_LINE_ITEM]: (state, { index }) => {
    return produce(state, (draft) => {
      draft.palletLineItems.splice(index, 1);
    });
  },
  [CargoPalletDetailsActionTypes.UPDATE_PALLET_LINE_ITEM]: (state, { index, name, value }) => {
    return produce(state, (draft) => {
      if (name === IDENTICAL_PALLETS) {
        draft.palletLineItems[index].totalPallets = value;
      } else {
        draft.palletLineItems[index].palletDimensions[name] = value;
        draft.totalPalletWeight = draft.palletLineItems.reduce((acc, item) => {
          return acc + item.totalPallets * item.palletDimensions.weight;
        }, 0);
      }
    });
  },
  [FreightBookingActionTypes.FREIGHT_BOOKING_COMPLETED]: () => cargoPalletDetailsInitialState,
  [FreightBookingActionTypes.RESET_FREIGHT_BOOKING_STATE]: () => cargoPalletDetailsInitialState,
  [FreightBookingActionTypes.SET_FREIGHT_BOOKING_QUOTE]: (state, { quote }) =>
    produce(state, (draft) => {
      draft.totalPalletCount = quote.cargo;
      draft.palletLineItems.push({
        ...newPalletItem,
        totalPallets: quote.cargo,
      });
    }),
};

export const cargoPalletDetailsReducer = createReducer<CargoPalletDetailsState>(
  cargoPalletDetailsInitialState,
  reducer
);
