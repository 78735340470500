import {
  AmazonAddress,
  AmazonDestinationType,
  DistributionType,
  UnitSystem,
  PlaceType,
  OriginType,
  DomesticDestinationType,
  CargoInsuranceType,
  ShipmentSteps,
  SingleSkuPackageCollection,
  PackageSummary,
  DtcPrepOption,
  CargoInputType,
} from "../CreateShipmentTypes";
import { FreightService } from "../steps/priceViewStep/selectShipping/selectShippingFlow/types";
import { SmbBookingSupplierInput } from "@deliverr/commons-clients";
import { BookingAddress, InboundBookingProduct, ShippingPlanCreatedBy } from "@deliverr/legacy-inbound-client";
import { PrepOption } from "@deliverr/business-types";

export interface CreateShipmentFormState {
  origin: PlaceType;
  isOriginPort: boolean | null;
  originType: OriginType | null;
  originFacilityName: string | null;
  supplier: SmbBookingSupplierInput;
  destination: PlaceType;
  destinationFacilityName: string | null;
  destinationAddressFid: string | null;
  isLiftgateRequired: boolean | null;
  deliveryDate: {
    date: Date | null;
    isFlexible: boolean;
  };
  cargoInputType: CargoInputType;
  isCargoMixedSku: boolean;
  bookingProducts: InboundBookingProduct[];
  singleSkuPackages: SingleSkuPackageCollection;
  mixedSkuPackages: PackageSummary[];
  airShipmentCargoDetails: InboundBookingProduct[];
  unitSystem: UnitSystem;
  shipmentTotals: {
    volume: number;
    weight: number;
    pallets: number;
    boxes: number;
    units: number;
  };
  cargoReadyDate: Date | null;
  hasDangerousGoods: boolean | null;
  dangerousGoods: {
    hasLithiumBatteries: boolean;
    hasNonLithiumBatteries: boolean;
    hasOther: boolean;
    hasNoDangerousGoods: boolean;
  };
  lithiumBatteryPackaging: {
    packedLoose: boolean;
    packedEquipment: boolean;
    inEquipment: boolean;
    inVehicle: boolean;
  };
  destinationSelect: {
    isByFlexport: boolean;
    isByAmazon: boolean;
    isDTCNetwork: boolean;
    isOwnDestination: boolean;
  };
  hasAnnualCustomsBond: boolean;
  flexportCapitalSelected: boolean;
  distributionType: DistributionType | null;
  amazonDestinationType: AmazonDestinationType | null;
  amazonPrepType: PrepOption | null;
  amazonAddress: AmazonAddress | null;
  reserveStorage: {
    address: BookingAddress | null;
    warehouseId: string | null;
  };
  crossdockWarehouse: {
    address: BookingAddress | null;
    warehouseId: string | null;
  };
  freightService: FreightService | null;
  isOwnTransportation: boolean;
  dtcPrepOption: DtcPrepOption;
  // domestic
  domesticDestinationType: DomesticDestinationType | null;
  hasCargoInsurance: boolean;
  cargoInsuranceType: CargoInsuranceType | null;
  insuranceInfo: {
    totalCargoValue: number;
    totalInsuranceValueCents: number;
    insurancePremiumCents: number;
  };
  createdBy?: ShippingPlanCreatedBy;
}

export const createShipmentInitialFormState: CreateShipmentFormState = {
  originType: null,
  isOriginPort: null,
  origin: {
    label: "",
    value: {
      address: {
        name: "",
        street1: "",
        street2: "",
        city: "",
        zip: "",
        state: "",
        country: "",
        countryCode: "",
        latLng: undefined,
      },
      ports: [],
      distanceKm: 0,
    },
  },
  originFacilityName: null,
  supplier: {
    companyName: "",
    contactEmail: "",
    companyEntityFid: "",
    contactFid: "",
    contactName: "",
    contactPhone: "",
  },
  destination: {
    label: "",
    value: {
      address: {
        name: "",
        street1: "",
        street2: "",
        city: "",
        zip: "",
        state: "",
        country: "",
        countryCode: "",
        latLng: undefined,
      },
      ports: [],
      distanceKm: 0,
    },
  },
  destinationFacilityName: null,
  destinationAddressFid: null,
  isLiftgateRequired: null,
  deliveryDate: {
    date: null,
    isFlexible: false,
  },
  cargoInputType: CargoInputType.PRODUCTS,
  isCargoMixedSku: false,
  bookingProducts: [],
  singleSkuPackages: {},
  mixedSkuPackages: [],
  airShipmentCargoDetails: [],
  unitSystem: UnitSystem.CBM_KG,
  shipmentTotals: {
    volume: 0,
    weight: 0,
    pallets: 0,
    boxes: 0,
    units: 0,
  },
  cargoReadyDate: null,
  hasDangerousGoods: null,
  dangerousGoods: {
    hasLithiumBatteries: false,
    hasNonLithiumBatteries: false,
    hasOther: false,
    hasNoDangerousGoods: false,
  },
  lithiumBatteryPackaging: {
    packedLoose: false,
    packedEquipment: false,
    inEquipment: false,
    inVehicle: false,
  },
  destinationSelect: {
    isByFlexport: false,
    isByAmazon: false,
    isDTCNetwork: false,
    isOwnDestination: false,
  },
  hasAnnualCustomsBond: false,
  flexportCapitalSelected: false,
  distributionType: DistributionType.REPLENISH_ON_DEMAND,
  amazonDestinationType: null,
  amazonPrepType: null,
  amazonAddress: null,
  reserveStorage: {
    address: null,
    warehouseId: null,
  },
  crossdockWarehouse: {
    address: null,
    warehouseId: null,
  },
  freightService: null,
  isOwnTransportation: false,
  domesticDestinationType: null,
  hasCargoInsurance: false,
  cargoInsuranceType: null,
  dtcPrepOption: {
    boxLabelingUnitsSelected: false,
    unitPrepService: null,
  },
  insuranceInfo: {
    totalCargoValue: 0,
    totalInsuranceValueCents: 0,
    insurancePremiumCents: 0,
  },
};

export interface CreateShipmentMetaState {
  stepStack: ShipmentSteps[];
  shippingPlanId: number | null;
  isSaving: boolean;
  isLoading: boolean;
  // this is also behind a feature flag
  isIpbDevToolsHidden: boolean;
  isReturningUser: boolean;
  // used to rehydrate freight service selection
  savedFreightServiceTitle: string | null;
}

export interface CreateShipmentQueryState {
  suppliers: SmbBookingSupplierInput[];
}

export const createShipmentInitialMetaState: CreateShipmentMetaState = {
  stepStack: [ShipmentSteps.OriginSearch],
  shippingPlanId: null,
  isSaving: false,
  isLoading: false,
  isIpbDevToolsHidden: false,
  isReturningUser: false,
  savedFreightServiceTitle: null,
};

export interface CreateShipmentState {
  meta: CreateShipmentMetaState;
  form: CreateShipmentFormState;
}

export const createShipmentInitialState: CreateShipmentState = {
  meta: createShipmentInitialMetaState,
  form: createShipmentInitialFormState,
};
