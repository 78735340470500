import { ApiError, DeliverrError, HttpErrorCodes } from "@deliverr/errors";

export function mapError(err: any, validErrorCodes: string[] = []): DeliverrError {
  if (!err.response) {
    return new DeliverrError({
      code: ApiError.UNKNOWN_ERROR,
      payload: err,
    });
  }

  if (validErrorCodes.includes(err.response.data)) {
    return new DeliverrError({
      code: err.response.data,
    });
  }

  if (validErrorCodes.includes(err.response.data?.code)) {
    return new DeliverrError({
      code: err.response.data.code,
    });
  }

  const { status, data } = err.response;
  const code: string = status >= 400 ? HttpErrorCodes[status] || ApiError.UNKNOWN_ERROR : ApiError.UNKNOWN_ERROR;
  return new DeliverrError({
    code,
    payload: { status, data },
  });
}
