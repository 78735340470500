import React, { FC } from "react";
import { StepCard, StepCardProps } from "./StepCard";
import { useStepManager } from "./useStepManager";

interface ManagedStepProps extends StepCardProps {
  nextData?: () => any;
  validate?: (() => boolean) | (() => Promise<boolean>);
}

export const ManagedStep: FC<ManagedStepProps> = ({ nextData, validate, ...rest }) => {
  const { stepManager } = useStepManager();

  return (
    <StepCard
      {...rest}
      hasBack={stepManager.hasBack()}
      onClickNext={async () => {
        if (!validate || (await validate())) {
          stepManager.next(await nextData?.());
        }
      }}
      onClickBack={() => stepManager.back()}
      isDone={stepManager.isDone()}
    />
  );
};
