import { Stack } from "common/components/ui";
import React from "react";
import { useSelector } from "react-redux";
import { StorageCargoType } from "storage/inbounds/create/cargoType/StorageCargoType";
import { LimitedAccessAddressNotification } from "inbounds/components/LimitedAccessAddressNotification";
import { getStorageInboundCreate } from "storage/inbounds/create/store/selector/getStorageInboundCreate";
import { CalculateFreightRate } from "../calculateFreightRate/CalculateFreightRate";
import { selectFromAddress } from "storage/inbounds/create/store/selector/selectFromAddress";
import { isInternationalAddress } from "common/AddressUtils";
import { StorageFreightInternationalAddressNotification } from "./StorageFreightInternationalAddressNotification";
import { CarrierType } from "@deliverr/storage-client";

export const AddShipmentDetails: React.FC = () => {
  const { carrierType, isLimitedAccess } = useSelector(getStorageInboundCreate);
  const fromAddress = useSelector(selectFromAddress);

  const isDeliverrCarrier = carrierType === CarrierType.DELIVERR;
  return (
    <Stack>
      {isDeliverrCarrier && isLimitedAccess ? (
        <LimitedAccessAddressNotification />
      ) : (
        <>
          {isDeliverrCarrier && isInternationalAddress(fromAddress) ? (
            <StorageFreightInternationalAddressNotification />
          ) : (
            <>
              <StorageCargoType />
              {isDeliverrCarrier && <CalculateFreightRate />}
            </>
          )}
        </>
      )}
    </Stack>
  );
};
