import React, { ChangeEventHandler } from "react";
import { Input, Stack, Text, Box } from "common/components/ui";
import { AmazonBarcodeEntry } from "../AmazonBarcodeEntry";

interface BarcodeValueCellProps {
  barcodeEntry?: AmazonBarcodeEntry;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const AmazonBarcodeValueCell = ({ barcodeEntry, onChange }: BarcodeValueCellProps) => {
  const value = barcodeEntry?.barcodeValue;
  const error = barcodeEntry?.error;

  return (
    <Stack gap="S4">
      <Stack gap="S3" direction="HORIZONTAL">
        <Input value={value} onChange={onChange} hasError={Boolean(error)} />
      </Stack>
      {error && (
        <Box marginTop="S1">
          <Text appearance="DANGER">{error}</Text>
        </Box>
      )}
    </Stack>
  );
};
