import { ContainerSize } from "@deliverr/storage-client";
import { MessageDescriptor, defineMessages } from "react-intl";

export const CONTAINER_SIZE_MESSAGES: Record<ContainerSize, MessageDescriptor> = defineMessages({
  [ContainerSize.GENERAL_20FT]: {
    id: "storage.inbounds.containerSize.20ft",
    defaultMessage: "20' floor load",
  },
  [ContainerSize.GENERAL_40FT]: {
    id: "storage.inbounds.containerSize.40ft",
    defaultMessage: "40' floor load",
  },
});
