import { RmtErrorMessages, fetchRmtsAction, goToRmtStep } from "returns/rmt/store/action/RmtActions";
import { RmtId, SellerRmtMinimal } from "@deliverr/returns-client";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Path } from "paths";
import React from "react";
import { ReturnLoader } from "returns/ReturnsLoader";
import { RmtStepId } from "returns/rmt/store/RmtReducer";
import { RootState } from "RootReducer";
import _ from "lodash";
import log from "Logger";
import { returnsClient } from "Clients";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";
import { useLoader } from "./../../../../common/components/WithLoader/useLoader";
import { useUpdateEffect } from "react-use";

export const useSetupRmtCard = (rmt: SellerRmtMinimal | undefined) => {
  const dispatch = useDispatch();
  const deliverrSellerId = useSelector((state: RootState) => state.user.sellerId);
  const { rmtId } = useParams<{ rmtId: string }>() as { rmtId: RmtId };

  const [rmtToken, setRmtToken] = React.useState("");
  const [amazonSellerId, setAmazonSellerId] = React.useState("");

  const [isValidToken, setIsValidToken] = React.useState(true);

  const [isConnectDisabled, setIsConnectDisabled] = React.useState(true);

  const { push } = useHistory();
  const isLoading = useLoader(ReturnLoader.ReturnsRmtLoader);
  const { formatMessage } = useIntl();
  const sellerRmtId = rmt?.rmtId ?? rmtId;

  const isValidKey = (token: string) => {
    return !_.isEmpty(token) && !token.includes(" ");
  };

  const validateRmtToken = (input: string) => {
    if (!isValidKey(input)) {
      setIsValidToken(false);
    } else {
      setIsValidToken(true);
    }
  };

  useUpdateEffect(() => {
    if (sellerRmtId === RmtId.AMAZON && isValidKey(amazonSellerId) && isValidKey(rmtToken)) {
      setIsConnectDisabled(false);
    } else if (sellerRmtId !== RmtId.AMAZON && isValidKey(rmtToken)) {
      setIsConnectDisabled(false);
    } else {
      setIsConnectDisabled(true);
    }
  }, [isValidToken, rmtToken, amazonSellerId]);

  const onConnectClicked = async () => {
    const ctx = { fn: "connectRmtOnSetup" };
    dispatch(addLoader(ReturnLoader.ReturnsRmtLoader));
    try {
      const apiToken = rmtId === "amazon" ? `${amazonSellerId}:${rmtToken}` : rmtToken;
      if (!rmt) {
        await returnsClient.addNewRmtBySellerId(
          deliverrSellerId,
          {
            accessKeys: {
              apiToken,
            },
          },
          rmtId
        );
      } else {
        await returnsClient.updateRmtInfoBySellerIdAndRmtId(
          deliverrSellerId,
          {
            accessKeys: {
              apiToken,
            },
            isActive: true,
          },
          rmtId
        );
      }

      dispatch(fetchRmtsAction());
      dispatch(goToRmtStep(RmtStepId.CONNECTED_RMT));
    } catch (err) {
      log.error({ ...ctx, err }, `error connecting rmt : ${rmtId}`);
      const errorMessage = formatMessage(RmtErrorMessages.connectRmt);

      toast.error(errorMessage, {
        autoClose: 5000,
        toastId: "connectRmtOnSetup",
      });
    } finally {
      dispatch(clearLoader(ReturnLoader.ReturnsRmtLoader));
    }
  };

  const onBackClicked = async () => {
    push(generatePath(Path.returnsPolicy));
  };

  return {
    onConnectClicked,
    onBackClicked,
    rmtToken,
    setRmtToken,
    isLoading,
    sellerRmtId,
    amazonSellerId,
    setAmazonSellerId,
    isValidToken,
    validateRmtToken,
    isConnectDisabled,
  };
};
