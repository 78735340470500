import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { UploadPresignedPostResponse } from "../upload/UploadResponses/UploadPresignedPostResponse";
import { Claim } from "./Claim/Claim";
import { ClaimFilter } from "./Claim/ClaimFilter";
import { ClaimProofUploadResponse } from "../upload/UploadResponses/ClaimProofUploadResponse";

export type ClaimClientConfig = ApiClientConfig;

export class ClaimClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: ClaimClientConfig = { baseURL: mustBeDefined(process.env.CLAIM_CLIENT_URL) }) {
    this.apiClient = new ApiClient({ baseURL });
  }

  async createClaim(claim: Claim): Promise<Claim | undefined> {
    return await this.apiClient.post({
      url: "/claims/create",
      body: claim,
      authentication: isAuthenticated,
    });
  }

  async getClaimsByFilter(filter: ClaimFilter): Promise<Claim[]> {
    return await this.apiClient.get({
      url: "/claims/claims",
      params: filter,
      authentication: isAuthenticated,
    });
  }

  async indexFile(contentType: string, title: string, sellerId: string): Promise<UploadPresignedPostResponse> {
    return await this.apiClient.post({
      url: "/claims/index-file",
      body: {
        contentType,
        title,
        sellerId,
      },
      authentication: isAuthenticated,
    });
  }

  async approveClaim(claimId: number, by: string, comment: string): Promise<Claim> {
    const baseURL = `/claims/approve?claimId=${claimId}&by=${by}`;

    return await this.apiClient.put({
      url: comment ? `${baseURL}&comment=${encodeURIComponent(comment)}` : baseURL,
      authentication: isAuthenticated,
    });
  }

  async approveRevisedClaim(claimId: number, by: string, revisedAmount: number, comment: string): Promise<Claim> {
    const baseURL = `/claims/approve-revised?claimId=${claimId}&by=${by}&revisedAmount=${revisedAmount}`;
    return await this.apiClient.put({
      url: comment ? `${baseURL}&comment=${encodeURIComponent(comment)}` : baseURL,
      authentication: isAuthenticated,
    });
  }

  async rejectClaim(claimId: number, by: string, rejectionReason: string, comment: string): Promise<Claim> {
    const body = { by, claimId, rejectionReason };

    return await this.apiClient.put({
      url: "/claims/reject",
      body: comment ? { ...body, comment } : body,
      authentication: isAuthenticated,
    });
  }

  async retractClaim(claimId: number, by: string, retractionReason: string): Promise<Claim> {
    return await this.apiClient.put({
      url: "/claims/retract",
      body: { by, claimId, retractionReason },
      authentication: isAuthenticated,
    });
  }

  async uploadUrl(
    contentType: string,
    title: string,
    sellerId: string,
    index?: boolean
  ): Promise<ClaimProofUploadResponse> {
    return await this.apiClient.post({
      url: "/claims/upload-url",
      params: { index },
      body: { contentType, title, sellerId },
      authentication: isAuthenticated,
    });
  }
}
