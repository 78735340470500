import { DeliverrAddress } from "@deliverr/commons-objects";
import { DistributionChannel, ShipmentResponse } from "@deliverr/replenishment-client";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectWarehouseAddress } from "wholesale/detail/store/selectWholesaleOrderDetail";

function extractDestinationForSingleShipmentOrder(shipments?: ShipmentResponse[]): DeliverrAddress | undefined {
  return shipments?.[0].destinationWarehouseAddress ?? undefined;
}
export interface UseReplenishmentShipmentAddressDisplayProps {
  shipments?: ShipmentResponse[];
  originWarehouseId?: string;
  distributionChannel?: DistributionChannel;
}
export const useReplenishmentShipmentAddressDisplay = ({
  shipments,
  originWarehouseId,
}: UseReplenishmentShipmentAddressDisplayProps) => {
  const isMultipleShipments = (shipments?.length ?? 0) > 1;
  const toAddress = isMultipleShipments ? undefined : extractDestinationForSingleShipmentOrder(shipments);
  const { originAddress } = useSelector(selectWarehouseAddress(originWarehouseId));
  const hideDestinationName = toAddress?.name === toAddress?.company;

  const [shouldShowShipmentsModal, setShowShipmentsModal] = useState(false);

  const onShipmentsModalClose = () => {
    setShowShipmentsModal(false);
  };

  const onViewMoreClickonShipmentsModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowShipmentsModal(true);
  };

  return {
    shouldShowShipmentsModal,
    onShipmentsModalClose,
    onViewMoreClickonShipmentsModal,
    toAddress,
    originAddress,
    hideDestinationName,
    isMultipleShipments,
  };
};
