export {
  INBOUND_SPD_MAX_BOX_LONGEST,
  INBOUND_SPD_MAX_BOX_MIDDLE,
  INBOUND_SPD_MAX_BOX_SHORTEST,
  INBOUND_SPD_MIN_BOX_DIM,
  INBOUND_SPD_MAX_BOX_WEIGHT,
  INBOUND_SPD_MIN_BOX_WEIGHT,
} from "@deliverr/legacy-inbound-client";

export const INBOUND_SPD_SIOC_MIN_BOX_LONGEST: number = 18;
export const INBOUND_SPD_SIOC_MIN_BOX_MIDDLE: number = 14;
export const INBOUND_SPD_SIOC_MIN_BOX_SHORTEST: number = 8;
export const INBOUND_SPD_SIOC_MIN_BOX_WEIGHT: number = 20;

export const INBOUND_SPD_MAX_TOTAL_WEIGHT: number = 250;
