import { SPThunkAction } from "common/ReduxUtils";
import { logError } from "Logger";
import { setStorageWarehouses } from "./setStorageWarehouses";
import { inboundClient } from "Clients";
import { StorageWarehouseSellerView } from "../StorageWarehouseSellerView";
import { DeliverrAddress } from "@deliverr/commons-objects";

export const getRankedStorageWarehousesForInbounds =
  (isInternational: boolean, customAddress?: DeliverrAddress): SPThunkAction =>
  async (dispatch, getState) => {
    const address = customAddress ?? getState().inbound.fromAddress;
    const ctx = { fn: "getRankedStorageWarehousesForInbounds" };
    const sellerId = getState().user.sellerId;
    try {
      const storageWarehouses =
        (await inboundClient.getRankedStorageWarehousesForInbounds(sellerId, { address, isInternational }))
          ?.warehouses ?? [];
      dispatch(setStorageWarehouses(storageWarehouses.map((warehouse) => warehouse as StorageWarehouseSellerView)));
    } catch (error) {
      logError(ctx, error);
    }
  };
