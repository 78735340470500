import { addLocalTimeZoneOffset } from "common/date/addLocalTimeZoneOffset";
import { MonthAndYear, ShortMonthDateYear } from "common/date/DateFormat";
import { fromLooseDate } from "common/date/LooseDate";
import { format } from "date-fns-tz";
import { DraftShippingPlanItem } from "inbounds/InboundTypes";

export const getFormattedExpirationDate = (
  shippingPlanItem: Pick<DraftShippingPlanItem, "expirationDate" | "expirationDateHasDay">
) => {
  const { expirationDate, expirationDateHasDay: hasFullExpirationDate } = shippingPlanItem;
  if (!expirationDate) {
    return;
  }
  const dateFormat = hasFullExpirationDate ? ShortMonthDateYear : MonthAndYear;
  const parsedDate = addLocalTimeZoneOffset(fromLooseDate(expirationDate));
  return format(parsedDate, dateFormat);
};
