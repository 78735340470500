import { useTheme } from "emotion-theming";
import { isMobile } from "../shared/helpers/utils";
import * as React from "react";
import ReactSelect, { components, Props } from "react-select";
import { SelectProps, container, optionColors, menuList, indicatorContainer, indicatorSeparator } from "./BaseSelect";
import { DefaultTheme } from "../shared";
import { IconV2 } from "../IconV2";
import { extractFormProps, FormGroup } from "../FormGroup";

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    {props.selectProps.menuIsOpen ? <IconV2 type="angle-up" /> : <IconV2 type="angle-down" />}
  </components.DropdownIndicator>
);

export const control = (theme: DefaultTheme, mobile: boolean) => (styles, state) => ({
  ...styles,
  appearance: "none",
  backgroundColor: "inherit",
  boxShadow: "none",
  border: "none",
  cursor: "pointer",
  fontSize: theme.font.size.F5,
  fontFamily: theme.font.family.TITLE,
  justifyContent: "flex-start",
  transition: "all .2s ease-in-out",
  "& > :first-of-type": {
    flex: "0 0 auto",
  },
  flexWrap: "nowrap",
});

export const valueContainer = (theme: DefaultTheme, mobile: boolean) => (styles) => ({
  ...styles,
  padding: "0px",
  cursor: "pointer",
  color: theme.colors.NEUTRAL[300],
});

export const singleValue = (theme: DefaultTheme) => (styles) => ({
  ...styles,
  color: theme.colors.NEUTRAL[500],
  fontFamily: theme.font.family.TITLE,
  fontWeight: theme.font.weight.BOLD,
  margin: "0px",
  maxWidth: "none",
  position: "relative",
  transform: "none",
});

export const option = (theme: DefaultTheme) => (styles, state) => ({
  ...styles,
  backgroundColor: state.isSelected
    ? optionColors(theme).selected.backgroundColor
    : optionColors(theme).backgroundColor,
  fontFamily: theme.font.family.STANDARD,
  cursor: "default",
  color: theme.colors.NEUTRAL[500],
  padding: "10px 12px",
  label: {
    color: theme.colors.NEUTRAL[500],
  },
  ":hover": {
    backgroundColor: state.isSelected
      ? optionColors(theme).selected.hoverBackgroundColor
      : optionColors(theme).hoverBackgroundColor,
  },
});

export const menu = (theme: DefaultTheme, mobile: boolean) => (styles) => ({
  ...styles,
  marginTop: theme.spacing.S1,
  borderColor: theme.colors.NEUTRAL[80],
  borderRadius: theme.border.radius.R2,
  boxShadow: mobile ? theme.shadow.S3 : styles.boxShadow,
  fontSize: mobile ? theme.font.size.F3 : theme.font.size.F2,
  zIndex: 3,
  width: "100%",
  minWidth: "100%",
});

export const dropdownIndicator = (theme: DefaultTheme, mobile: boolean) => (styles) => ({
  ...styles,
  color: theme.colors.NEUTRAL[300],
  padding: mobile ? `0 ${theme.spacing.S4}` : `0 ${theme.spacing.S2}`,
  fontSize: theme.font.size.F1,
});

const selectStyles: (theme: DefaultTheme, flexWidth?: boolean) => Props["styles"] = (theme, flexWidth) => {
  const mobile = isMobile(theme);
  return {
    container: container(flexWidth),
    control: control(theme, mobile),
    valueContainer: valueContainer(theme, mobile),
    singleValue: singleValue(theme),
    option: option(theme),
    menu: menu(theme, mobile),
    menuList: menuList(theme),
    dropdownIndicator: dropdownIndicator(theme, mobile),
    indicatorContainer,
    indicatorSeparator,
  };
};

export const LargeSelect: React.FC<SelectProps> = (props) => {
  const theme = useTheme<DefaultTheme>();
  const [formProps, { flexWidth, ...selectProps }] = extractFormProps(props);

  return (
    <FormGroup {...formProps} flexWidth={flexWidth}>
      <ReactSelect
        menuPlacement="auto"
        components={{ DropdownIndicator }}
        styles={selectStyles(theme, flexWidth)}
        isSearchable={false}
        {...selectProps}
      />
    </FormGroup>
  );
};
