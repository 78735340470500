import { DefaultTheme, Space, getResponsiveStyles } from "../shared";
import { BoxProps } from "./Box";
import { kebabCase } from "lodash/fp";
import { BoxResetProps } from "../BoxReset";

export const isSpacingProp = {
  margin: true,
  marginTop: true,
  marginBottom: true,
  marginLeft: true,
  marginRight: true,
  padding: true,
  paddingTop: true,
  paddingBottom: true,
  paddingLeft: true,
  paddingRight: true,
};

export const getBoxStylesHelper = (theme: DefaultTheme) => (gap: Space | null) => (gap ? theme.spacing[gap] : "0");

export const getBoxStyles = (theme: DefaultTheme, styles: BoxProps | BoxResetProps) =>
  Object.entries(styles)
    .filter(([_, value]) => value !== undefined)
    .map(([styleProp, value]) =>
      getResponsiveStyles(kebabCase(styleProp), value, isSpacingProp[styleProp] ? getBoxStylesHelper(theme) : undefined)
    )
    .join(" ");
