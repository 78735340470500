import { PlannedShipment } from "inbounds/InboundTypes";
import { getLoadedPlannedShipment } from "inbounds/steps/InboundStepSelectors";
import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { getIsDeliverr } from "inbounds/utils/shippingMethodUtils";

/**
 *  Checks if the selected shippingMethod is of a Flexport rate shopping shipping option
 */
export const getIsDeliverrRates = createSelector<RootState, PlannedShipment, boolean>(
  getLoadedPlannedShipment,
  ({ shippingMethod }) => getIsDeliverr(shippingMethod)
);
