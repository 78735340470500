import { createSelector } from "reselect";

import { getShipmentLtl } from "./getShipmentLtl";

/**
 * Checks if a user has completed all required steps of an LTL flow for a shipment
 *
 */
export const getLtlCompliance = createSelector(getShipmentLtl, (shipmentLtl) => {
  return shipmentLtl.hasConfirmedPallets && shipmentLtl.hasConfirmedPickupInfo && shipmentLtl.hasConfirmedRateQuote;
});
