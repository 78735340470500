import { FeatureName } from "common/Split";
import { useCompositeFeatureOn } from "../useCompositeFeatureOn";

/**
 * Helper hook to use for all feature related to the SMB Accelerate release.
 *
 * Removal is tracked in https://flexport.atlassian.net/browse/SMB-1402
 */
export const useSmbAccelerateFeatureOn = (featureName: FeatureName) =>
  useCompositeFeatureOn(FeatureName.SmbAccelerate, featureName);
