import { ProductCasePack } from "@deliverr/commons-clients/lib/product/ProductCasePack";
import { createActionCreator } from "common/ReduxUtils";
import { StorageSelectCasePackActionTypes } from "./SelectProductCasePackAction.types";

export const setProductCasePackOptions = createActionCreator<string, Record<string, ProductCasePack>>(
  StorageSelectCasePackActionTypes.STORAGE_SET_PRODUCT_CASE_PACK_OPTIONS,
  "dsku",
  "productCasePacks"
);

export const setSelectedCasePackOption = createActionCreator<string, ProductCasePack>(
  StorageSelectCasePackActionTypes.STORAGE_SET_SELECTED_CASE_PACK_OPTION,
  "dsku",
  "productCasePack"
);

export const setNewProductCasePack = createActionCreator<string, ProductCasePack>(
  StorageSelectCasePackActionTypes.STORAGE_SET_NEW_PRODUCT_CASE_PACK,
  "dsku",
  "productCasePack"
);

export const setArePacksLoading = createActionCreator<string, boolean>(
  StorageSelectCasePackActionTypes.STORAGE_SET_CASE_PACK_LOADING,
  "dsku",
  "arePacksLoading"
);
