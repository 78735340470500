import { showModal } from "common/components/Modal/ModalActions";
import { createActionCreator, SPThunkAction } from "common/ReduxUtils";
import InboundModalId from "inbounds/InboundModalId";
import { dispatchThenSaveInbound } from "inbounds/steps/InboundSaveActions";
import { RootState } from "RootReducer";
import { some, values } from "lodash";

export enum ProductComplianceActionTypes {
  TOGGLE_COMPLIANCE_CRITERIA = "TOGGLE_COMPLIANCE_CRITERIA",
  COMPLETE_COMPLIANCE = "COMPLETE_COMPLIANCE",
  CLEAR_PRODUCT_COMPLIANCE = "CLEAR_PRODUCT_COMPLIANCE",
  BATTERY_COMPLIANCE = "BATTERY_COMPLIANCE",
  ACCEPT_ALL_COMPLIANCE_CRITERIA = "ACCEPT_ALL_COMPLIANCE_CRITERIA",
}

export const verifyProductCompliance = (): SPThunkAction => (dispatch, getState) => {
  const {
    inbound: { plan, productDetailsCache },
    productCompliance: { completedShippingPlanIds },
  } = getState() as RootState;
  const hasProductsWithNoBarcodes = some(
    values(productDetailsCache),
    // eslint-disable-next-line no-prototype-builtins
    (product) => !product.hasOwnProperty("barcodes") || product?.barcodes?.length === 0
  );
  const isComplianceCompleted = plan && completedShippingPlanIds.includes(plan.id);

  if (hasProductsWithNoBarcodes && !isComplianceCompleted) {
    dispatch(showModal(InboundModalId.ProductCompliance));
  }
};

export const toggleCompliance = createActionCreator<string>(
  ProductComplianceActionTypes.TOGGLE_COMPLIANCE_CRITERIA,
  "criteriaName"
);

export const completeCompliance = dispatchThenSaveInbound(() => (dispatch, getState) => {
  const {
    inbound: { plan },
  } = getState();
  dispatch({
    type: ProductComplianceActionTypes.COMPLETE_COMPLIANCE,
    shippingPlanId: plan.id,
  });
});

export const clearProductCompliance = createActionCreator(ProductComplianceActionTypes.CLEAR_PRODUCT_COMPLIANCE);

export const initBatteryCompliance = createActionCreator(ProductComplianceActionTypes.BATTERY_COMPLIANCE);

export const acceptAllComplianceCriteria = createActionCreator(
  ProductComplianceActionTypes.ACCEPT_ALL_COMPLIANCE_CRITERIA
);
