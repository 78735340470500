import React from "react";
import { AttachmentType, PatchShipmentRequest } from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { logError } from "Logger";
import { RootState } from "RootReducer";
import { SPThunkAction } from "common/ReduxUtils";
import { ERROR_TOAST_AUTO_CLOSE_TIME } from "common/utils/toasts/toasts";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { markShipmentAttachmentsAsUploaded } from "./markShipmentAttachmentsAsUploaded";

export const updateShipmentExternalIds =
  (shipmentId: string, attachmentTypes: AttachmentType[]): SPThunkAction<Promise<void>> =>
  async (dispatch, getState: () => RootState) => {
    const state = getState();
    const ctx = { fn: "updateShipmentExternalIds" };

    const { fbaShipmentId, fbaReferenceId } = selectTransferCreate(state);
    let patchShipmentRequest: PatchShipmentRequest = {
      distributionChannelShipmentId: fbaShipmentId,
    };
    if (fbaReferenceId) {
      patchShipmentRequest = {
        ...patchShipmentRequest,
        distributionChannelReferenceNumber: fbaReferenceId,
      };
    }
    try {
      await markShipmentAttachmentsAsUploaded(shipmentId, attachmentTypes);
      await replenishmentClient.patchShipment(shipmentId, patchShipmentRequest);
    } catch (e) {
      logError(ctx, e);
      toast.error(
        <FormattedMessage
          id="transfers.create.actions.replenishmentOrder.updateShipmentExternalIds.error"
          defaultMessage="Failed to update shipment external ids"
        />,
        {
          autoClose: ERROR_TOAST_AUTO_CLOSE_TIME,
        }
      );
    }
  };
