import React, { FC } from "react";
import styled from "@emotion/styled";
import { DefaultTheme } from "common/components/ui";

import classNames from "classnames/dedupe";
import { BorderlessButton } from "common/components/BorderlessButton";
import { useElevio } from "./useElevio";
import { ElevioButtonProps } from "./ElevioButtonProps";

const ElevioButton = styled(BorderlessButton)<{}, DefaultTheme>(
  ({ theme }) => `
  color: ${theme.colors.BLUE["300"]};
  font-weight: ${theme.font.weight.BOLD};
  `
);

const ElevioButtonAsLink = styled(BorderlessButton)`
  font-weight: normal;
  color: inherit;
  text-decoration: underline;
`;

export interface ElevioBorderlessButtonProps extends ElevioButtonProps {
  asLink?: boolean;
}

export const ElevioBorderlessButton: FC<ElevioBorderlessButtonProps> = ({
  articleId,
  className,
  id,
  onClick,
  children,
  asLink,
}) => {
  const { handleClick } = useElevio(articleId, onClick);
  const ButtonComponent = asLink ? ElevioButtonAsLink : ElevioButton;
  return (
    <ButtonComponent id={id} className={classNames(className)} onClick={handleClick}>
      {children}
    </ButtonComponent>
  );
};
