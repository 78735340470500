import { Box, Button, ThemeProps } from "common/components/ui";
import { PackageSummary } from "inbounds/createShipment/CreateShipmentTypes";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";
import React from "react";
import { useFieldArray } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { DtcMixedSkuBoxConfiguration } from "./DtcMixedSkuBoxConfiguration";
import { DtcShipmentTotalsSection } from "../ProductChooserSection";
import styled from "@emotion/styled";

const StyledContainer = styled.section<ThemeProps>(
  ({ theme }) => `
  border: ${theme.border.width.B2} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
`
);

export const DtcMixedSkuBoxConfirgurations = ({ mixedSkuPackages }: { mixedSkuPackages: PackageSummary[] }) => {
  const { control } = useCreateShipmentFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: CreateShipmentInputName.MIXED_SKU_PACKAGES,
  });

  const appendEmptyPackage = () => {
    append({
      casePackDsku: "",
      numberOfPkgs: 0,
      items: [],
    });
  };

  return (
    <>
      <StyledContainer>
        {fields.map((row, key) => (
          <DtcMixedSkuBoxConfiguration
            pkg={row}
            key={row.id}
            removeBox={remove}
            index={key}
            isRemoveDisabled={fields.length === 1 && key === 0}
            mixedSkuPackage={mixedSkuPackages[key]}
          />
        ))}
        {fields.length > 0 && <DtcShipmentTotalsSection packages={mixedSkuPackages} />}
      </StyledContainer>
      <Box paddingTop={"S3"}>
        <Button secondary onClick={appendEmptyPackage}>
          <FormattedMessage
            id="createShipment.dtc.cargoDetailStep.mixedSku.addBoxConfiguration"
            defaultMessage="Add a box configuration"
          />
        </Button>
      </Box>
    </>
  );
};
