/**
 * `customPropsFilter` creates an `options` argument to `styled(Tag, options)`,
 * in which you can specify an array of prop names that must not
 * be passed to the underlying DOM element.
 *
 * Example:
 *
 * If you do:
 *
 * ~~~~
 * const CustomDiv = styled.div<CustomProps, ThemeProps>(
 *     ({loading, theme}) => `...`
 * );
 * ~~~~
 *
 * it will warn in console, because `loading` is passed to the underlying div.
 *
 * You can use instead:
 *
 * ~~~~
 * const CustomDiv = styled("div", customPropsFilter<CustomProps>(["loading"]))<CustomProps, ThemeProps>(
 *     ({loading, theme}) => `...`
 * );
 * ~~~~
 *
 * which will strip the `loading` prop from the output, while letting you
 * use the prop in the css construction.
 */

export const customPropsFilter = <T>(propNames: (keyof T)[]) => ({
  shouldForwardProp: (propName) => !propNames.includes(propName),
});
