import { LocationType } from "@deliverr/freight-client";
import { selectAnyIsLoading } from "common/store/selectors/selectAnyIsLoading";
import { isNull } from "lodash";
import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { getOrderBolId } from "./common/utils/getOrderBolId";
import {
  mapFreightOrderToOrderListItem,
  mapQuoteRequestResponseToQuoteListItem,
} from "./FreightList/freightBookingListUtils";
import {
  freightOrdersLoaderId,
  freightOrderTrackingLoaderId,
} from "./store/freightList/actions/FreightListActionTypes";

export const getFreightState = ({ freight }: RootState) => freight;

export const getFreightListState = createSelector(getFreightState, ({ list }) => list);

export const getFreightOrders = createSelector(getFreightListState, ({ orders }) => orders);

export const getFreightQuoteRequests = createSelector(getFreightListState, ({ quoteRequests }) => quoteRequests);

export const getFreightOrderItems = createSelector(getFreightOrders, (orders) =>
  mapFreightOrderToOrderListItem(orders)
);

export const getFreightQuoteRequestItems = createSelector(getFreightQuoteRequests, (quoteRequests) =>
  mapQuoteRequestResponseToQuoteListItem(quoteRequests)
);

export const getAreFreightOrdersLoading = createSelector(
  ({ loading }: RootState) => loading.loaders,
  getFreightOrders,
  (loaders, orders) => loaders.includes(freightOrdersLoaderId) && isNull(orders)
);

export const getHasFreightOrderItems = createSelector(getFreightOrders, (orders) => orders?.length !== 0);

export const getHasFreightQuoteRequestItems = createSelector(
  getFreightQuoteRequestItems,
  (quoteRequests) => quoteRequests?.length !== 0
);

export const getFreightListActiveView = createSelector(getFreightListState, ({ activeView }) => activeView);

export const getFreightBooking = createSelector(getFreightState, ({ booking }) => booking);

export const getFreightAccordionSteps = createSelector(getFreightBooking, ({ bookingSteps }) => bookingSteps);

export const getFreightPickupDetails = createSelector(
  getFreightBooking,
  ({ freightPickupDetails }) => freightPickupDetails
);

export const getFreightDeliveryDetails = createSelector(
  getFreightBooking,
  ({ freightDeliveryDetails }) => freightDeliveryDetails
);

export const getFreightCargoPalletDetails = createSelector(
  getFreightBooking,
  ({ cargoPalletDetails }) => cargoPalletDetails
);

export const getDrayageDetails = createSelector(getFreightBooking, ({ drayageDetails }) => drayageDetails);

export const getDrayageCargoDetails = createSelector(
  getFreightBooking,
  ({ drayageCargoDetails }) => drayageCargoDetails
);

export const getFreightOrder = createSelector(getFreightState, ({ order }) => order);
export const getFreightOrderDetail = createSelector(getFreightOrder, ({ detail }) => detail);
export const getFreightOrderBolId = createSelector(getFreightOrderDetail, (order) => order && getOrderBolId(order));
export const getFreightOrderTracking = createSelector(getFreightOrder, ({ tracking }) => tracking);
export const getFreightOrderTrackingError = createSelector(getFreightOrder, ({ trackingError }) => trackingError);
export const isFreightOrderTrackingLoading = createSelector(selectAnyIsLoading, (select) =>
  select(freightOrderTrackingLoaderId)
);
export const getFreightBols = createSelector(getFreightOrder, ({ bol }) => bol);
export const getFreightOrderDetailError = createSelector(getFreightOrder, ({ error }) => error);

// Freight Quoting Blade Selectors
export const getFreightQuoting = createSelector(getFreightState, ({ quoting }) => quoting);

export const getFreightQuotingBladeOpen = createSelector(
  getFreightQuoting,
  ({ freightQuotingBladeOpen }) => freightQuotingBladeOpen
);

export const getFreightQuotingPickupDetails = createSelector(
  getFreightQuoting,
  ({ pickupAddress, pickupAddressMetadata, pickupLiftgateRequired, pickupLocationType }) => ({
    pickupAddress,
    pickupAddressMetadata,
    pickupLiftgateRequired,
    pickupLocationType,
  })
);

export const getFreightQuotingDeliveryDetails = createSelector(
  getFreightQuoting,
  ({ deliveryAddress, deliveryAddressMetadata, deliveryLiftgateRequired, deliveryLocationType }) => ({
    deliveryAddress,
    deliveryAddressMetadata,
    deliveryLiftgateRequired,
    deliveryLocationType,
  })
);

export const getIsPickupLocationFromPort = createSelector(
  getFreightQuoting,
  ({ pickupLocationType }) => pickupLocationType === LocationType.MARITIME_PORT
);

export const getFreightGeneratedOrders = createSelector(getFreightBooking, ({ generatedOrders }) => generatedOrders);
export const getFreightBookingQuote = createSelector(getFreightBooking, ({ quote }) => quote);
export const getFreightFbaDeliveryDetails = createSelector(
  getFreightBooking,
  ({ freightFbaDeliveryDetails }) => freightFbaDeliveryDetails
);
