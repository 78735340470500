import React from "react";

import { ProblemRowData, ShortShipProblemRow, UnexpectedSkuProblemRow } from "common/components/ProblemsTable/types";
import { ShipmentProblemMismatchCaseQtyBlade } from ".";
import { ProblemType } from "common/problems/ProblemType";
import { logError } from "Logger";
import { ShipmentProblemUnexpectedSkuBlade } from "./ShipmentProblemUnexpectedSkuBlade";

const getBladeByProblem = (problem: ProblemRowData) => {
  switch (problem.problemType) {
    case ProblemType.UNEXPECTED_SKU:
      return <ShipmentProblemUnexpectedSkuBlade shipmentProblem={problem as UnexpectedSkuProblemRow} />;
    case ProblemType.MISMATCH_CASE_QTY:
      return <ShipmentProblemMismatchCaseQtyBlade shipmentProblem={problem as UnexpectedSkuProblemRow} />;
    default:
      logError({ fn: "getBladeByProblem", problem }, "Failed to get Blade for an unrecognized blade type");
      return null;
  }
};

export interface ShipmentProblemsBladeProps {
  shipmentProblem?: ShortShipProblemRow;
}

export const ShipmentProblemBlade = ({ shipmentProblem }) =>
  shipmentProblem ? getBladeByProblem(shipmentProblem) : null;
