import { Product } from "@deliverr/commons-clients";
import { isEmpty, reduce, uniq, values } from "lodash";
import { getDefaultPackFromProduct } from "./getDefaultPackFromProduct";
import { OrderItemDto } from "@deliverr/replenishment-client";

// maps and aggregates order items with a mix of product and pack skus to only pack skus
export const mapReplenishItemsToPackSkus = <T extends OrderItemDto>(
  items: T[],
  unifiedProducts: { [dsku: string]: Product }
): T[] => {
  if (isEmpty(items)) {
    return [];
  }

  const dskus = uniq(items?.map(({ dsku }) => dsku) ?? []);

  const productSkuToDefaultPackSku = values(dskus).reduce((agg, dsku) => {
    const product = unifiedProducts[dsku];

    if (product && !product.packOf) {
      agg[product.dsku] = getDefaultPackFromProduct(product)?.dsku;
    }
    return agg;
  }, {});

  const itemsWithPackSkus: T[] =
    items?.map((item) => ({
      ...item,
      dsku: productSkuToDefaultPackSku[item.dsku] ?? item.dsku,
      totalUnits: item.totalUnits,
      unitsPerPack: item.unitsPerPack,
      packOf: item.packOf,
    })) ?? [];

  return values(
    reduce(
      itemsWithPackSkus,
      (itemsByPackSkus, item) => {
        itemsByPackSkus[item.dsku] = {
          ...item,
          dsku: item.dsku,
          totalUnits: (item.totalUnits as number) + ((itemsByPackSkus[item.dsku]?.totalUnits ?? 0) as number),
          unitsPerPack: item.unitsPerPack as number,
          packOf: item.packOf,
        };
        return itemsByPackSkus;
      },
      {} as { [dsku: string]: T }
    )
  );
};
