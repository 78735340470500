export const POOL_ONE: string[] = [
  "Adventurously",
  "Bitterly",
  "Blissfully",
  "Boldly",
  "Calmly",
  "Coolly",
  "Curiously",
  "Defiantly",
  "Delightfully",
  "Dreamily",
  "Elegantly",
  "Enthusiastically",
  "Extremely",
  "Faithfully",
  "Famously",
  "Fervently",
  "Generously",
  "Gratefully",
  "Greatly",
  "Highly",
  "Honestly",
  "Intensely",
  "Inwardly",
  "Jovially",
  "Jubilantly",
  "Justly",
  "Kindheartedly",
  "Keenly",
  "Knowingly",
  "Lazily",
  "Lively",
  "Lightly",
  "Madly",
  "Merrily",
  "Mostly",
  "Nearly",
  "Neatly",
  "Oddly",
  "Patiently",
  "Perfectly",
  "Physically",
  "Questionably",
  "Quickly",
  "Quietly",
  "Randomly",
  "Rapidly",
  "Rarely",
  "Separately",
  "Seriously",
  "Tenderly",
  "Tensely",
  "Unabashedly",
  "Unaccountably",
  "Unbearably",
  "Vacantly",
  "Vaguely",
  "Valiantly",
  "Warmly",
  "Wearily",
  "Youthfully",
  "Yawningly",
  "Yearly",
  "Zealously",
  "Zestfully",
  "Zestily",
];

export const POOL_TWO: string[] = [
  "Attractive",
  "Beautiful",
  "Clean",
  "Dazzling",
  "Elegant",
  "Fancy",
  "Fit",
  "Glamorous",
  "Gorgeous",
  "Handsome",
  "Long",
  "Magnificent",
  "Muscular",
  "Plain",
  "Quaint",
  "Stocky",
  "Agreeable",
  "Ambitious",
  "Brave",
  "Calm",
  "Delightful",
  "Eager",
  "Faithful",
  "Gentle",
  "Happy",
  "Jolly",
  "Kind",
  "Lively",
  "Nice",
  "Obedient",
  "Polite",
  "Proud",
  "Silly",
  "Thankful",
  "Victorious",
  "Witty",
  "Wonderful",
  "Zealous",
];

export const POOL_THREE: string[] = [
  "Aardvark",
  "Affenpinscher",
  "Akbash",
  "Akita",
  "Albatross",
  "Alligator",
  "Alpaca",
  "Angelfish",
  "Ant",
  "Anteater",
  "Antelope",
  "Ape",
  "Armadillo",
  "Avocet",
  "Axolotl",
  "Baboon",
  "Badger",
  "Balinese",
  "Bandicoot",
  "Barb",
  "Barnacle",
  "Barracuda",
  "Bat",
  "Beagle",
  "Bear",
  "Beaver",
  "Bee",
  "Beetle",
  "Binturong",
  "Bird",
  "Birman",
  "Bison",
  "Bloodhound",
  "Boar",
  "Bobcat",
  "Bongo",
  "Bonobo",
  "Budgerigar",
  "Buffalo",
  "Bulldog",
  "Bullfrog",
  "Butterfly",
  "Caiman",
  "Camel",
  "Capybara",
  "Caracal",
  "Caribou",
  "Cassowary",
  "Cat",
  "Caterpillar",
  "Catfish",
  "Cattle",
  "Centipede",
  "Chameleon",
  "Chamois",
  "Cheetah",
  "Chicken",
  "Chihuahua",
  "Chimpanzee",
  "Chinchilla",
  "Chinook",
  "Chipmunk",
  "Chough",
  "Cichlid",
  "Clam",
  "Coati",
  "Cobra",
  "Cockroach",
  "Cod",
  "Collie",
  "Coral",
  "Cormorant",
  "Cougar",
  "Coyote",
  "Crab",
  "Crane",
  "Crocodile",
  "Crow",
  "Curlew",
  "Cuscus",
  "Cuttlefish",
  "Dachshund",
  "Dalmatian",
  "Deer",
  "Dhole",
  "Dingo",
  "Dinosaur",
  "Discus",
  "Dodo",
  "Dogfish",
  "Dolphin",
  "Dormouse",
  "Dotterel",
  "Dove",
  "Dragonfly",
  "Drever",
  "Duck",
  "Dugong",
  "Dunker",
  "Dunlin",
  "Eagle",
  "Earwig",
  "Echidna",
  "Eel",
  "Eland",
  "Elephant",
  "Elephant Seal",
  "Elk",
  "Emu",
  "Falcon",
  "Ferret",
  "Finch",
  "Fish",
  "Flamingo",
  "Flounder",
  "Fly",
  "Fossa",
  "Fox",
  "Frigatebird",
  "Frog",
  "Galago",
  "Gar",
  "Gaur",
  "Gazelle",
  "Gecko",
  "Gerbil",
  "Gharial",
  "Giant Panda",
  "Gibbon",
  "Giraffe",
  "Gnat",
  "Gnu",
  "Goat",
  "Goldfinch",
  "Goldfish",
  "Goose",
  "Gopher",
  "Gorilla",
  "Goshawk",
  "Grasshopper",
  "Greyhound",
  "Grouse",
  "Guanaco",
  "Guinea fowl",
  "Gull",
  "Guppy",
  "Hamster",
  "Hare",
  "Harrier",
  "Havanese",
  "Hawk",
  "Hedgehog",
  "Heron",
  "Herring",
  "Hippopotamus",
  "Hornet",
  "Horse",
  "Hummingbird",
  "Hyena",
  "Ibis",
  "Iguana",
  "Impala",
  "Indri",
  "Insect",
  "Jackal",
  "Jaguar",
  "Jay",
  "Blue Jay",
  "Jellyfish",
  "Kakapo",
  "Kangaroo",
  "Kingfisher",
  "Kiwi",
  "Koala",
  "Komodo Dragon",
  "Kouprey",
  "Kudu",
  "Labradoodle",
  "Ladybird",
  "Lapwing",
  "Lark",
  "Lemming",
  "Lemur",
  "Leopard",
  "Liger",
  "Lion",
  "Lionfish",
  "Lizard",
  "Llama",
  "Lobster",
  "Locust",
  "Loris",
  "Louse",
  "Lynx",
  "Lyrebird",
  "Macaw",
  "Magpie",
  "Mallard",
  "Manatee",
  "Mandrill",
  "Markhor",
  "Marten",
  "Mastiff",
  "Mayfly",
  "Meerkat",
  "Millipede",
  "Mink",
  "Mole",
  "Molly",
  "Mongoose",
  "Monkey",
  "Moorhen",
  "Moose",
  "Mosquito",
  "Moth",
  "Mouse",
  "Mule",
  "Narwhal",
  "Newfoundland",
  "Newt",
  "Nightingale",
  "Numbat",
  "Ocelot",
  "Octopus",
  "Okapi",
  "Olm",
  "Opossum",
  "Orangutan",
  "Oryx",
  "Ostrich",
  "Otter",
  "Owl",
  "Ox",
  "Oyster",
  "Pademelon",
  "Panther",
  "Parrot",
  "Partridge",
  "Peacock",
  "Peafowl",
  "Pekingese",
  "Pelican",
  "Penguin",
  "Persian",
  "Pheasant",
  "Pigeon",
  "Pika",
  "Pike",
  "Piranha",
  "Platypus",
  "Pointer",
  "Pony",
  "Poodle",
  "Porcupine",
  "Porpoise",
  "Possum",
  "Prairie Dog",
  "Prawn",
  "Puffin",
  "Pug",
  "Puma",
  "Quail",
  "Quelea",
  "Quetzal",
  "Quokka",
  "Quoll",
  "Rabbit",
  "Raccoon",
  "Ragdoll",
  "Rail",
  "Ram",
  "Rattlesnake",
  "Raven",
  "Red Deer",
  "Red Panda",
  "Reindeer",
  "Rhinoceros",
  "Robin",
  "Rook",
  "Rottweiler",
  "Ruff",
  "Salamander",
  "Salmon",
  "Sand Dollar",
  "Sandpiper",
  "Saola",
  "Sardine",
  "Scorpion",
  "Sea Lion",
  "Sea Urchin",
  "Seahorse",
  "Seal",
  "Serval",
  "Shark",
  "Sheep",
  "Shrew",
  "Shrimp",
  "Skunk",
  "Sloth",
  "Snail",
  "Snake",
  "Snowshoe",
  "Sparrow",
  "Spider",
  "Sponge",
  "Squid",
  "Squirrel",
  "Starfish",
  "Starling",
  "Stingray",
  "Stoat",
  "Stork",
  "Swallow",
  "Swan",
  "Tang",
  "Tapir",
  "Tarsier",
  "Termite",
  "Tetra",
  "Tiger",
  "Toad",
  "Tortoise",
  "Toucan",
  "Tropicbird",
  "Trout",
  "Tuatara",
  "Turkey",
  "Turtle",
  "Uakari",
  "Uguisu",
  "Umbrellabird",
  "Vicuña",
  "Viper",
  "Vulture",
  "Wallaby",
  "Walrus",
  "Warthog",
  "Wasp",
  "Water Buffalo",
  "Whale",
  "Whippet",
  "Wildebeest",
  "Wolf",
  "Wolverine",
  "Wombat",
  "Woodcock",
  "Woodlouse",
  "Woodpecker",
  "Worm",
  "Wrasse",
  "Wren",
  "Yak",
  "Zebra",
  "Zebu",
  "Zonkey",
  "Zorse",
];
