import { inboundClient } from "Clients";
import { separateAsnsByCrossDock } from "inbounds/steps/ship/InboundUtils";
import { InboundShipmentActionTypes } from "./InboundShipmentActionTypes";
import { SPThunkAction } from "common/ReduxUtils";
import { logError, logStart } from "Logger";

export const getShipmentAsns = (): SPThunkAction => async (dispatch, getState) => {
  const state = getState();
  const {
    inbound: {
      plan: { id: shippingPlanId },
      loadedShipmentId: shipmentId,
    },
    user: { sellerId },
  } = state;
  const ctx = logStart({ fn: "getShipmentAsns", shippingPlanId, shipmentId });

  if (!shipmentId) {
    logError(ctx, "Missing shipmentId");
    throw new Error("Missing shipmentId");
  }

  try {
    const asns = await inboundClient.getAsnsByShipmentId(sellerId, shipmentId);
    const { normalAsns, crossDockAsn } = separateAsnsByCrossDock(asns);

    dispatch({
      type: InboundShipmentActionTypes.LOAD_ASNS,
      asns: normalAsns,
      shipmentId,
      crossDockAsn,
    });
  } catch (err) {
    logError(ctx, err);
  }
};
