import { InboundActionTypes } from "inbounds/store/InboundActionTypes";

export const setBoxLabelUrl = ({ format, labelLanguage, shipmentId, shippingPlanId, url }) => (dispatch) => {
  dispatch({
    type: InboundActionTypes.SET_BOX_LABEL_URL,
    format,
    labelLanguage,
    shipmentId,
    shippingPlanId,
    url,
  });
};
