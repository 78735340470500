import { FormattedMessage, useIntl } from "react-intl";
import { transfersShipmentCreateLabels } from "../../../transfersShipmentCreate.labels";
import React from "react";
import { Box, Notification } from "common/components/ui";
import { ElevioArticleId, ElevioBorderlessButton } from "../../../../../common/elevio";

export const ReferenceIdNotification: React.FunctionComponent<{}> = () => {
  const { formatMessage } = useIntl();
  return (
    <Box width="80%" paddingBottom="S6" paddingRight={"S5"}>
      <Notification
        data-testid="reference-id-notification"
        appearance="WARNING"
        content={{
          title: formatMessage(transfersShipmentCreateLabels.steps.shipping.referenceIdNotificationTitle),
          description: formatMessage(transfersShipmentCreateLabels.steps.shipping.referenceIdNotification),
        }}
        append={
          <ElevioBorderlessButton articleId={ElevioArticleId.FbaReferenceId}>
            <FormattedMessage {...transfersShipmentCreateLabels.steps.shipping.learnMore} />
          </ElevioBorderlessButton>
        }
      />
    </Box>
  );
};
