import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Anchor, Stack, Text, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";

import { ProblemRowData } from "common/components/ProblemsTable/types";
import { ProblemBaseBlade, ProblemBladeProps } from "../ProblemBaseBlade";
import { ProblemBladeStatusSection } from "../components/ProblemBladeStatusSection";
import { DetailItemSection } from "../components/DetailItemSection";
import { ProblemDetailItem } from "../components/ProblemDetailItem";
import { SKULink } from "../components/SKULink";
import { NonComplianceProductPhotoSection } from "inbounds/non-compliance/components";
import { PROBLEM_TYPE_LABEL_MAP } from "common/problems/problemTypeLabelMap";
import { ShippingPlanNameDisplay } from "common/components/ShippingPlanNameDisplay";
import { useFetchProduct } from "inbounds/hooks";
import RouteParser from "common/RouteParser";
import { Path } from "paths";
import { UnitCountMessage } from "common/components/ProblemsTable/UnitCountMessage";
import { format } from "date-fns";
import { fromLooseDate } from "common/date/LooseDate";
import { ShortMonthDateYear } from "common/date/DateFormat";
import { getNCStatusLabel } from "inbounds/NCShipmentSummaryDataStatus";

export interface GenericIssueBladeProps extends ProblemBladeProps {
  problem: ProblemRowData;
  onShippingPlanClick?: () => void;
}

const StatusSectionWithMargin = styled(ProblemBladeStatusSection)<ThemeProps>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S5};
  `
);

const StyledStack = styled(Stack)`
  width: 100%;
`;

const SpacedProblemDetailItem = styled(ProblemDetailItem)<ThemeProps>(
  ({ theme }) => `
  min-width: 50%;
  display: inline-block;

  dt {
    font-weight: ${theme.font.weight.BOLD};
  }
`
);

const ShippingPlanLink = styled(Anchor)`
  cursor: pointer;
`;

export const GenericIssueBlade: FC<GenericIssueBladeProps> = ({
  id,
  problem,
  onCloseClick,
  isBladeOpen,
  onShippingPlanClick,
}) => {
  const { shippingPlanId, shippingPlanName, mappedSkus, photos, updatedAt, status, affectedCdskus, units, createdAt } =
    problem;

  const { value: product } = useFetchProduct({ dsku: mappedSkus[0]?.dsku });

  const { dsku, name: productName } = product ?? {};

  const handleShippingPlanClick = () => {
    if (onShippingPlanClick) {
      return onShippingPlanClick();
    } else {
      window.open(new RouteParser(Path.inboundsShippingPlanRedirect).parse({ shippingPlanId }), "_blank");
    }
  };

  return (
    <ProblemBaseBlade
      id={id}
      title={PROBLEM_TYPE_LABEL_MAP[problem.problemType]}
      onCloseClick={onCloseClick}
      isBladeOpen={isBladeOpen}
      updatedAt={updatedAt}
    >
      <StatusSectionWithMargin status={status} />
      <StyledStack>
        <DetailItemSection>
          <StyledStack gap="S4">
            <div>
              <Text size="bodyLarge" as="span">
                <FormattedMessage id="inbounds.GenericIssueBlade.detailsTitle" defaultMessage="Details" />
              </Text>
            </div>
            <SpacedProblemDetailItem
              label={<FormattedMessage id="inbounds.GenericIssueBlade.issueId" defaultMessage="Issue ID" />}
              value={problem.id}
            />
            <SpacedProblemDetailItem
              label={<FormattedMessage id="inbounds.GenericIssueBlade.affectedCdskus" defaultMessage="CDSKU" />}
              value={
                affectedCdskus?.length ? (
                  affectedCdskus?.join(", ")
                ) : (
                  <FormattedMessage id="inbounds.GenericIssueBlade.notAvailable" defaultMessage="N/A" />
                )
              }
            />

            <SpacedProblemDetailItem
              label={<FormattedMessage id="inbounds.GenericIssueBlade.shippingPlan" defaultMessage="Shipping plan" />}
              value={
                shippingPlanId && shippingPlanName ? (
                  <>
                    <ShippingPlanLink onClick={handleShippingPlanClick}>
                      <ShippingPlanNameDisplay shippingPlanId={shippingPlanId} shippingPlanName={shippingPlanName} />
                    </ShippingPlanLink>
                  </>
                ) : (
                  "N/A - Occurred post receiving"
                )
              }
            />

            {dsku ? (
              <SpacedProblemDetailItem
                label={<FormattedMessage id="inbounds.GenericIssueBlade.verifiedSku" defaultMessage="SKU" />}
                value={<SKULink dsku={dsku}>{dsku}</SKULink>}
              />
            ) : null}
            {productName && (
              <SpacedProblemDetailItem
                label={
                  <FormattedMessage id="inbounds.GenericIssueBlade.nonCompliantProduct" defaultMessage="Product" />
                }
                value={productName}
              />
            )}
            <SpacedProblemDetailItem
              label={<FormattedMessage id="inbounds.GenericIssueBlade.reportedQty" defaultMessage="Quantity" />}
              value={units ? <UnitCountMessage unitCount={units} /> : "N/A"}
            />
            <SpacedProblemDetailItem
              label={<FormattedMessage id="inbounds.GenericIssueBlade.status" defaultMessage="Status" />}
              value={<FormattedMessage {...getNCStatusLabel(status, problem.problemType)} />}
            />
            <SpacedProblemDetailItem
              label={
                <FormattedMessage id="inbounds.GenericIssueBlade.dateIdentified" defaultMessage="Date identified" />
              }
              value={format(fromLooseDate(createdAt), ShortMonthDateYear)}
            />
            <SpacedProblemDetailItem
              label={
                <FormattedMessage
                  id="inbounds.GenericIssueBlade.dateOfLastUpdate"
                  defaultMessage="Date of last update"
                />
              }
              value={format(fromLooseDate(updatedAt), ShortMonthDateYear)}
            />

            {photos?.length ? (
              <SpacedProblemDetailItem
                label={<FormattedMessage id="inbounds.GenericIssueBlade.photoSection" defaultMessage="Photos" />}
                value={<NonComplianceProductPhotoSection key={id} photos={photos} isSmall />}
              />
            ) : null}
          </StyledStack>
        </DetailItemSection>
      </StyledStack>
    </ProblemBaseBlade>
  );
};
