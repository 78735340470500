import { PrepCombo, SimplePrepType, PrepType } from "@deliverr/prep-service-client";
import { SimpleSellerSelfServePrepType } from "prep/types";

/**
 * Self-serve Prep Types ordered based on design's display order.
 */
export const ORDERED_SELF_SERVE_PREP_TYPES: SimpleSellerSelfServePrepType[] = [
  SimplePrepType.SKU_LABELS,
  PrepCombo.DAMAGE_PROTECTION,
  PrepCombo.BAGGING_PROTECTION,
  PrepType.KITTING,
];
