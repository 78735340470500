import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { combineReducers } from "redux";
import produce from "immer";
import { CreateShipmentActionTypes, CreateShipmentFormActionTypes } from "./CreateShipmentActionTypes";
import {
  createShipmentInitialFormState,
  createShipmentInitialMetaState,
  CreateShipmentFormState,
  CreateShipmentState,
  CreateShipmentMetaState,
} from "./CreateShipmentState";
import { DomesticDestinationType, OriginType } from "../CreateShipmentTypes";
import { cloneDeep } from "lodash";

const formReducer: ReducerDictionary<CreateShipmentFormState> = {
  ...handleSimpleReducerUpdates([
    CreateShipmentFormActionTypes.SET_ORIGIN_TYPE,
    CreateShipmentFormActionTypes.SET_ORIGIN,
    CreateShipmentFormActionTypes.SET_ORIGIN_FACILITY_NAME,
    CreateShipmentFormActionTypes.SET_DESTINATION_FACILITY_NAME,
    CreateShipmentFormActionTypes.SET_IS_LIFTGATE_REQUIRED,
    CreateShipmentFormActionTypes.SET_DELIVERY_DATE,
    CreateShipmentFormActionTypes.SET_UNIT_SYSTEM,
    CreateShipmentFormActionTypes.SET_SHIPMENT_TOTALS,
    CreateShipmentFormActionTypes.SET_CARGO_READY_DATE,
    CreateShipmentFormActionTypes.SET_HAS_DANGEROUS_GOODS,
    CreateShipmentFormActionTypes.SET_DANGEROUS_GOODS,
    CreateShipmentFormActionTypes.SET_AMAZON_DESTINATION_TYPE,
    CreateShipmentFormActionTypes.SET_AMAZON_ADDRESS,
    CreateShipmentFormActionTypes.SET_FREIGHT_SERVICE,
    CreateShipmentFormActionTypes.SET_DOMESTIC_DESTINATION_TYPE,
    CreateShipmentFormActionTypes.SET_DISTRIBUTION_TYPE,
    CreateShipmentFormActionTypes.SET_SUPPLIER,
    CreateShipmentFormActionTypes.SET_AMAZON_PREP_TYPE,
    CreateShipmentFormActionTypes.SET_IS_OWN_TRANSPORTATION,
    CreateShipmentFormActionTypes.SET_HAS_CARGO_INSURANCE,
    CreateShipmentFormActionTypes.SET_INSURANCE_INFO,
    CreateShipmentFormActionTypes.SET_BOOKING_PRODUCTS,
    CreateShipmentFormActionTypes.SET_AIR_SHIPMENT_CARGO_DETAILS,
    CreateShipmentFormActionTypes.SET_HAS_ANNUAL_CUSTOMS_BOND,
    CreateShipmentFormActionTypes.SET_FLEXPORT_CAPITAL_SELECTED,
    CreateShipmentFormActionTypes.SET_LITHIUM_BATTERY_PACKAGING,
    CreateShipmentFormActionTypes.SET_IS_ORIGIN_PORT,
    CreateShipmentFormActionTypes.SET_IS_CARGO_MIXED_SKU,
    CreateShipmentFormActionTypes.SET_DTC_PREP_OPTION,
    CreateShipmentFormActionTypes.SET_MIXED_SKU_PACKAGES,
    CreateShipmentFormActionTypes.SET_CARGO_INPUT_TYPE,
    CreateShipmentFormActionTypes.SET_RESERVE_STORAGE,
    CreateShipmentFormActionTypes.SET_DESTINATION_ADDRESS_FID,
    CreateShipmentFormActionTypes.SET_CROSSDOCK_WAREHOUSE,
  ]),
  [CreateShipmentFormActionTypes.SET_SINGLE_SKU_PACKAGES]: (state, { singleSkuPackages }) => {
    return produce(state, (draft: CreateShipmentFormState) => {
      const mutablePackages = cloneDeep(singleSkuPackages);
      draft.singleSkuPackages = mutablePackages;
    });
  },
  [CreateShipmentFormActionTypes.SET_DESTINATION_SELECT]: (state, { destinationSelect }) => {
    return produce(state, (draft: CreateShipmentFormState) => {
      draft.destinationSelect = destinationSelect;
      draft.destination = createShipmentInitialFormState.destination;
    });
  },
  [CreateShipmentFormActionTypes.SET_DESTINATION]: (state, { destination }) => {
    return produce(state, (draft: CreateShipmentFormState) => {
      draft.destination = destination;
      draft.destinationSelect = createShipmentInitialFormState.destinationSelect;
    });
  },
  [CreateShipmentFormActionTypes.SET_IS_OWN_TRANSPORTATION]: (
    state: CreateShipmentFormState,
    { isOwnTransportation }
  ) => {
    return produce(state, (draft: CreateShipmentFormState) => {
      draft.isOwnTransportation = isOwnTransportation;
    });
  },
  [CreateShipmentFormActionTypes.CLEAR_FORM]: () => ({
    ...createShipmentInitialFormState,
  }),
  [CreateShipmentFormActionTypes.PREPOPULATE_FORM]: (_, { formState }) => ({
    ...formState,
  }),
  // handle loading of domestic ecom inbound
  [CreateShipmentFormActionTypes.SET_DOMESTIC_ECOMM_STATE]: (state) => {
    return produce(state, (draft: CreateShipmentFormState) => {
      draft.originType = OriginType.DOMESTIC;
      draft.destinationSelect.isByFlexport = true;
      draft.domesticDestinationType = DomesticDestinationType.DTC;
    });
  },
  // handle loading of domestic storage inbound
  [CreateShipmentFormActionTypes.SET_DOMESTIC_STORAGE_STATE]: (state) => {
    return produce(state, (draft: CreateShipmentFormState) => {
      draft.originType = OriginType.DOMESTIC;
      draft.destinationSelect.isByFlexport = true;
      draft.domesticDestinationType = DomesticDestinationType.RESERVE_STORAGE;
    });
  },
};

const metaReducer: ReducerDictionary<CreateShipmentMetaState> = {
  ...handleSimpleReducerUpdates([
    CreateShipmentActionTypes.SET_STEP_STACK,
    CreateShipmentActionTypes.SET_IS_IPB,
    CreateShipmentActionTypes.SET_SHIPPING_PLAN_ID,
    CreateShipmentActionTypes.SET_IS_SAVING,
    CreateShipmentActionTypes.SET_IS_LOADING,
    CreateShipmentActionTypes.HIDE_IPB_DEV_TOOLS,
    CreateShipmentActionTypes.SET_IS_RETURNING_USER,
    CreateShipmentActionTypes.SET_SAVED_FREIGHT_SERVICE_TITLE,
  ]),
  [CreateShipmentActionTypes.CLEAR_META]: (state) => ({
    ...createShipmentInitialMetaState,
    isIpbDevToolsHidden: state.isIpbDevToolsHidden,
  }),
};

export const createShipmentReducer = combineReducers<CreateShipmentState>({
  meta: createReducer<CreateShipmentMetaState>(createShipmentInitialMetaState, metaReducer),
  form: createReducer<CreateShipmentFormState>(createShipmentInitialFormState, formReducer),
});
