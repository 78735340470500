import RouteParser from "common/RouteParser";

export enum Path {
  root = "/",
  loginRedirect = "/login-redirect",
  emailVerificationRedirect = "/email-verification-redirect",
  headlessLogin = "#__headlessLogin",

  billing = "/billing",
  billingNewCredit = "/billing/credits/new",

  brandedPackagingList = "/branded-packaging",
  newBrandedPackaging = "/branded-packaging/new",
  brandedPackagingDetail = "/branded-packaging/:dsku",

  lotExpiration = "/lot-expiration",
  stopShipByDays = "/stop-ship-by-days",

  egd = "/fast-tags/egd/:page",
  egdStart = "/fast-tags/egd/start",
  egdComplete = "/fast-tags/egd/complete",
  egdCutoff = "/fast-tags/egd/cutoff",
  egdOAuth = "/fast-tags/egd/oauth",
  egdRateTable = "/fast-tags/egd/rate-table",

  fastTagConnect = "/fast-tags",
  fastTagShopDetail = "/fast-tags/:program/shop/:shop",
  fastTagDetail = "/fast-tags/:program",

  fastTagsProgram = "/fast-tags/:program/:page",

  financing = "/financing",
  financingSettings = "/financing/settings",
  financingRequestForm = "/financing/request-form",

  freightQuoteTool = "/freight-quote",
  freightQuoteSummary = "/freight-quote/:freightQuoteId",

  freight = "/freight/bookings",
  freightBookingSetup = "/freight/booking/setup/:quoteId",
  freightBookingSuccess = "/freight/booking/success",
  freightOrderDetail = "/freight/order/:orderId",

  publicFreightQuoteSummary = "/public/freight-quote/:freightQuoteId",
  publicFreightQuoteSummaryRoot = "/public/freight-quote",

  prep = "/prep",
  prepDetails = "/prep/detail/:prepId",

  inboundsList = "/inbounds",
  inboundsSelectDestinationType = "/inbounds/type",
  inboundsSelectProducts = "/inbounds/new",
  inboundsShippingPlanRedirect = "/inbounds/:shippingPlanId",
  inboundsShippingPlanProducts = "/inbounds/:shippingPlanId/products",
  inboundsLotInformation = "/inbounds/:shippingPlanId/lot-information",
  inboundsAssignCategories = "/inbounds/:shippingPlanId/categories",
  inboundsPackagingRequirements = "/inbounds/:shippingPlanId/packaging-requirements",
  inboundsCustoms = "/inbounds/:shippingPlanId/customs",
  inboundsBarcodeInput = "/inbounds/:shippingPlanId/barcode",
  inboundsBarcodePrint = "/inbounds/:shippingPlanId/barcode-print",
  inboundsDeliverrPrep = "/inbounds/:shippingPlanId/deliverr-prep",
  inboundsFromAddress = "/inbounds/:shippingPlanId/from-address",
  inboundsDistributeSelect = "/inbounds/:shippingPlanId/distribute-select",
  inboundsAddDimensions = "/inbounds/:shippingPlanId/dimensions",
  inboundsShip = "/inbounds/:shippingPlanId/shipments/:shipmentId",
  inboundsShipmentConfirmed = "/inbounds/:shippingPlanId/shipments/:shipmentId/shipment-confirmed",
  inboundsShipAddDimensions = "/inbounds/:shippingPlanId/shipments/:shipmentId/dimensions",
  inboundsSmbShipmentDetails = "/inbounds/:shippingPlanId/shipment-details",
  inboundsSmbShipmentMessages = "/inbounds/:shippingPlanId/shipment-details/messages",
  inboundsSmbShipmentTask = "/inbounds/:shippingPlanId/shipment-details/task/:taskId",
  inboundsSmbShipmentProblem = "/inbounds/:shippingPlanId/shipment-details/problems/:problemId",
  inboundsConnectAmazonTask = "/inbounds/:shippingPlanId/shipment-details/connect-amazon/:taskId",
  inboundsAddProductsTask = "/inbounds/:shippingPlanId/shipment-details/add-products/:taskId",
  inboundsAddCasePacksTask = "/inbounds/:shippingPlanId/shipment-details/add-packing-information/:taskId",
  inboundsAddFBADetailsTask = "/inbounds/:shippingPlanId/shipment-details/add-amazon-details/:taskId",
  inboundsAddFBAShipmentReferenceIDTask = "/inbounds/:shippingPlanId/shipment-details/add-amazon-refID/:taskId",
  inboundsDownloadBoxContentsLabelsTask = "/inbounds/:shippingPlanId/shipment-details/download-box-contents-labels/:taskId",
  inboundsPackagingRequirementsTask = "/inbounds/:shippingPlanId/shipment-details/packaging-requirements/:taskId",
  inboundsAddProductsAndBoxConfigurationsTask = "/inbounds/:shippingPlanId/shipment-details/add-products-and-box-configurations/:taskId",
  inboundsAddProductsAndUnitsTask = "/inbounds/:shippingPlanId/shipment-details/add-products-and-units/:taskId",
  inboundsAddHTSCodeTask = "/inbounds/:shippingPlanId/shipment-details/add-hts-code/:taskId",
  inboundsStorageSelectProducts = "/inbounds/storage/select-products",
  inboundsStorageLotInformation = "/inbounds/storage/add-lot-information",
  inboundsStorageDraftRedirect = "/inbounds/:shippingPlanId/storage",
  inboundsStorageAddProductsBarcode = "/inbounds/storage/add-products-barcode",
  inboundsStorageAddDimensions = "/inbounds/storage/select-dimensions",
  inboundsStorageFromAddress = "/inbounds/storage/select-from-address",
  inboundsStorageDestination = "/inbounds/storage/select-destination",
  inboundsStorageCargoType = "/inbounds/storage/select-cargo-type",
  inboundsStorageShipmentConfirmed = "/inbounds/:shippingPlanId/storage/shipment-confirmed",
  inboundsStorageDetail = "/inbounds/:shippingPlanId/storage/detail",
  inboundsStorageConfirmShipment = "/inbounds/storage/confirm-shipment",
  inboundsStorageSelectShipping = "/inbounds/storage/select-shipping",
  inboundsStorageAddPickupDetails = "/inbounds/storage/add-pickup-details",
  inboundsOnboardingChecklist = "/inbounds?onboarding=true",

  /* start - IPB steps */
  inboundsIPBOrigin = "/inbounds/ipb/:shippingPlanId?/origin",
  inboundsIPBSupplier = "/inbounds/ipb/:shippingPlanId?/supplier",
  inboundsIPBFulfillment = "/inbounds/ipb/:shippingPlanId?/fulfillment-network",
  inboundsIPBFulfillmentDestination = "/inbounds/ipb/:shippingPlanId?/fulfillment-destination",
  inboundsIPBDirectDestination = "/inbounds/ipb/:shippingPlanId?/direct-destination",
  inboundsIPBReserveStorageLocation = "/inbounds/ipb/:shippingPlanId?/storage-location",
  inboundsIPBCargoDetails = "/inbounds/ipb/:shippingPlanId?/cargo-details",
  inboundsIPBPackagingRequirements = "/inbounds/ipb/:shippingPlanId?/packaging-requirements",
  inboundsIPBPriceView = "/inbounds/ipb/:shippingPlanId?/checkout",
  /* end - IPB steps */

  transfersList = "/transfers",
  transferDetails = "/transfers/:orderId/shipments",
  replenishmentDetails = "/replenishment/:orderId/shipments",
  transfersNew = "/transfers/new",
  transfersDraft = "/transfers/draft/:transferId",

  inventoryDetail = "/inventory/:dsku",
  inventoryDetailViewProblem = "/inventory/:dsku/:problemType/:problemId",
  inventorySetupAsBundle = "/inventory/:dsku/setup-as-bundle",
  inventoryAddComponents = "/inventory/:dsku/add-components",
  inventoryList = "/inventory",
  inventoryNewProduct = "/inventory/new",
  inventoryNewBundle = "/inventory/bundles/new",
  inventoryNewKitOrBundle = "/inventory/kits-and-bundles/new",
  inventoryBundleDetail = "/inventory/bundles/:bsku",

  // keep those 2 routes to forward to login.deliverr
  login = "/login",
  onboardingSignup = "/onboarding/signup",

  onboarding = "/onboarding",
  onboardingAllSet = "/onboarding/all-set",
  onboardingImportingCatalog = "/onboarding/importing-catalog/:slsUuid?",
  onboardingIntegrationSetup = "/onboarding/listing-tool-setup/:channel",
  onboardingIntegrationSetupWithToken = "/onboarding/listing-tool-setup/:channel/:token",
  onboardingChooseIntegration = "/onboarding/choose-your-integration",
  onboardingIntegrationSetupComplete = "/onboarding/listing-tool-setup/complete/:channel",
  onboardingCreateCatalog = "/onboarding/create-catalog",
  onboardingRedirect = "/onboarding/redirect",
  onboardingShopifySettings = "/onboarding/shopify-settings",
  onboardingSurvey = "/onboarding/setup",

  // SMB onboarding
  onboardingSmb = "/onboarding/smb",

  // First module of the SMB onboarding - Complete shipment details
  onboardingSmbCompanyCountryOfRegistration = "/onboarding/smb/company/country-of-registration",
  onboardingSmbCompany = "/onboarding/smb/company",
  onboardingSmbTaxId = "/onboarding/smb/company/tax-id",
  onboardingSmbCompanyDetails = "/onboarding/smb/company/details",
  onboardingSmbCompanyIrsUpload = "/onboarding/smb/company/irs-upload",
  onboardingSmbCompanyMailingAddress = "/onboarding/smb/company/mailing-address",
  onboardingSmbCompanyPhysicalAddress = "/onboarding/smb/company/physical-address",
  onboardingSmbSupplier = "/onboarding/smb/supplier",
  onboardingSmbPickup = "/onboarding/smb/pickup",
  onboardingSmbDelivery = "/onboarding/smb/delivery",

  // Second module of the SMB onboarding - Authorize shipment handling
  onboardingSmbAuthorize = "/onboarding/authorize",
  onboardingSmbCustomsBond = "/onboarding/authorize/customs-bond",
  onboardingSmbCustomsClearance = "/onboarding/authorize/customs-clearance",
  onboardingSmbPowerOfAttorney = "/onboarding/authorize/power-of-attorney",
  onboardingSmbPowerOfAttorneyDocusignRedirect = "/onboarding/authorize/power-of-attorney/docusign-redirect",

  // Foreign importer related pages
  onboardingArticleOfIncorporationUpload = "/onboarding/smb/company/article-of-incorporation-upload",

  orderDetail = "/orders/:orderId/detail",
  orderList = "/orders",
  orderNew = "/orders/new",
  orderRemoval = "/orders/removal",
  orderClaim = "/orders/claim/:orderId",
  orderClaimAppeal = "/orders/claim/:orderId/appeal",
  orderClaimReview = "/orders/claim/:orderId/view",
  lotOrderRemoval = "/orders/lotRemoval",

  wholesaleList = "/wholesale",
  wholesaleListV2 = "/wholesale-v2",
  wholesaleDetail = "/wholesale/:orderId/detail",
  wholesaleDetailV2 = "/wholesale/:orderId/detail-v2",
  wholesaleNew = "/wholesale/new",
  wholesaleNewV2 = "/wholesale/new-v2",
  wholesaleDuplicate = "/wholesale/:orderId/duplicate",

  reports = "/reports",

  returnsCreate = "/returns/create/:orderId?",
  returns = "/returns",
  // fix route
  returnsLabel = "/returns/:returnId/orders/:orderId/label",
  returnsList = "/returns",
  returnsPolicy = "/returns/settings",
  returnsDetail = "/returns/:returnId/orders/:orderId/detail",
  returnsDetailWithId = "/returns/:returnId/details",
  returnsRmtPolicy = "/returns/settings/:rmtId",
  returnsRmtPolicyWithToken = "/returns/settings/:rmtId/:token",
  returnsProblemDetails = "/returns/problems/:problemId",
  returnsProblemList = "/returns/problems",

  settings = "/settings",
  settingsAccount = "/settings/account",
  settingsAccountFlexportPlus = "/settings/account/plus",
  settingsReplenishment = "/settings/replenishment",
  settingsShippingOptionMapping = "/settings/shipping-option-mapping",
  settingsInternationalShipping = "/settings/international-shipping",
  settingsInternationalShippingCustoms = "/settings/international-shipping/customs",
  settingsReferrals = "/settings/referrals",
  settingsUsersList = "/settings/users",
  settingsUsersNew = "/settings/users/new",
  settingsUsersEdit = "/settings/users/edit",
  settingsApiTokens = "/settings/api-tokens",
  settingsApiTokensGenerate = "/settings/api-tokens/generate",
  settingsApiTokensEdit = "/settings/api-tokens/edit",
  settingsAcceptedAddresses = "/settings/accepted-addresses",

  shopifyStart = "/fast-tags/shopify/start",
  shopifyInstallApp = "/fast-tags/shopify/install-app",
  shopifyEnableAppEmbed = "/fast-tags/shopify/enable-app-embed",
  shopifyStoreCredit = "/fast-tags/shopify/store-credit",
  shopifyProductDetailBadge = "/fast-tags/shopify/product-detail-badge",
  shopifyOtherBadges = "/fast-tags/shopify/other-badges",
  shopifyTurnOnBadges = "/fast-tags/shopify/turn-on-badges",
  shopifyComplete = "/fast-tags/shopify/complete",
  shopifyFastTagBadge = "/fast-tags/shopify/fast-tag-badge",
  shopifyStandardBadgeV2 = "/fast-tags/shopify/standard-badge",
  shopifyInternationalBadge = "/fast-tags/shopify/international-badge",

  walmart = "/fast-tags/walmart/:page",
  walmartRequestAccess = "/fast-tags/walmart/request-access",
  walmartConfigureTwoDay = "/fast-tags/walmart/configure",
  walmartAcknowledge = "/fast-tags/walmart/acknowledge",
  walmartComplete = "/fast-tags/walmart/complete",
  walmartKeys = "/fast-tags/walmart/keys",
  walmartStart = "/fast-tags/walmart/start",
  walmartTimeZone = "/fast-tags/walmart/time-zone",
  walmartUpdate = "/fast-tags/walmart/update",

  googleStart = "/fast-tags/google/start",
  googleVerifyShopify = "/fast-tags/google/verify-shopify",
  googleSelectPrograms = "/fast-tags/google/select-programs",
  googleLinkAccount = "/fast-tags/google/link-account",
  googleSupplementalFeed = "/fast-tags/google/supplemental-feed",
  googleComplete = "/fast-tags/google/complete",

  facebookStart = "/fast-tags/facebook/start",
  facebookConnectShopify = "/fast-tags/facebook/connect-shopify",
  facebookConnectAccount = "/fast-tags/facebook/connect-account",
  facebookAdsSettings = "/fast-tags/facebook/ads-settings",
  facebookSavedAudienceWarning = "/fast-tags/facebook/saved-audience-warning",
  facebookConfigureAds = "/fast-tags/facebook/configure-ads",
  facebookSelectPixel = "/fast-tags/facebook/select-pixel",
  facebookAdSetsCreated = "/fast-tags/facebook/ad-sets-created",
  facebookAdSetsSync = "/fast-tags/facebook/ad-sets-sync",
  facebookSelectAction = "/fast-tags/facebook/select-action",

  wishStart = "/fast-tags/wish/start",
  wishEnroll = "/fast-tags/wish/enroll",
  wishLinkDeliverr = "/fast-tags/wish/link-deliverr",
  wishActivate = "/fast-tags/wish/activate",
  wishComplete = "/fast-tags/wish/complete",

  barcodePage = "/barcodes/:dsku",

  nonCompliance = "/inbounds/problems",

  channels = "/channels/",
  channelsEdit = "/channels/:slsUuid",
  channelsEditLegacy = "/channels/edit/:channel",
  channelsIntegrationSetup = "/channels/listing-tool-setup/:channel",
  channelsUpdateCredentials = "/channels/:slsUuid/update-credentials",

  shopifyApp = "/shopify-app",

  // parcel specific routes
  parcel = "/parcel",
  parcelIntegrations = "/parcel/integrations",
  parcelDirectApi = "/parcel/integrations/direct-api",
  parcelShipStation = "/parcel/integrations/shipstation",
  parcelEasyPost = "/parcel/integrations/easypost",
  parcelExtensiv = "/parcel/integrations/extensiv",
  parcelProShip = "/parcel/integrations/proship",
  parcelDetail = "/parcel/detail/:parcelId",
  parcelClaim = "/parcel/claim/:parcelId",
}

// Used to associate paths with the inbounds sidebar link
export const INBOUND_PATHS = [
  Path.inboundsList,
  Path.inboundsSelectDestinationType,
  Path.inboundsSelectProducts,
  Path.inboundsShippingPlanProducts,
  Path.inboundsLotInformation,
  Path.inboundsAssignCategories,
  Path.inboundsPackagingRequirements,
  Path.inboundsCustoms,
  Path.inboundsBarcodeInput,
  Path.inboundsBarcodePrint,
  Path.inboundsDeliverrPrep,
  Path.inboundsFromAddress,
  Path.inboundsDistributeSelect,
  Path.inboundsAddDimensions,
  Path.inboundsShip,
  Path.inboundsShipAddDimensions,
  Path.inboundsAddFBAShipmentReferenceIDTask,
  Path.inboundsSmbShipmentDetails,
  Path.inboundsSmbShipmentTask,
  Path.inboundsSmbShipmentProblem,
  Path.inboundsSmbShipmentMessages,
  Path.inboundsConnectAmazonTask,
  Path.inboundsAddProductsTask,
  Path.inboundsAddCasePacksTask,
  Path.inboundsAddFBADetailsTask,
  Path.inboundsStorageSelectProducts,
  Path.inboundsStorageLotInformation,
  Path.inboundsStorageAddProductsBarcode,
  Path.inboundsStorageAddDimensions,
  Path.inboundsStorageFromAddress,
  Path.inboundsStorageDestination,
  Path.inboundsStorageCargoType,
  Path.inboundsStorageSelectShipping,
  Path.inboundsStorageAddPickupDetails,
  Path.inboundsStorageShipmentConfirmed,
  Path.inboundsStorageConfirmShipment,
  Path.inboundsStorageDetail,
  /* start - IPB steps */
  Path.inboundsIPBOrigin,
  Path.inboundsIPBSupplier,
  Path.inboundsIPBFulfillment,
  Path.inboundsIPBFulfillmentDestination,
  Path.inboundsIPBDirectDestination,
  Path.inboundsIPBCargoDetails,
  Path.inboundsIPBPriceView,
  /* end - IPB steps */
];

export const RETURNS_PATH = [Path.returnsCreate, Path.returnsDetail, Path.returnsList, Path.returnsLabel];

export const REPLENISHMENT_PATHS = [Path.transfersList, Path.transferDetails];

export const BRANDED_PACKAGING_PATHS = [
  Path.brandedPackagingList,
  Path.newBrandedPackaging,
  Path.brandedPackagingDetail,
];

export const LOT_EXPIRATION_PATHS = [Path.lotExpiration];

export const FREIGHT_QUOTE_PATHS = [Path.freightQuoteTool, Path.freightQuoteSummary, Path.publicFreightQuoteSummary];

export const DynamicRoutes = {
  barcodePage: new RouteParser(Path.barcodePage),
  inventoryDetail: new RouteParser(Path.inventoryDetail),
  brandedPackagingDetail: new RouteParser(Path.brandedPackagingDetail),
  lotExpiration: new RouteParser(Path.lotExpiration),
  freightOrderDetail: new RouteParser(Path.freightOrderDetail),
  shippingPlanProducts: new RouteParser(Path.inboundsShippingPlanProducts),
  onboardingSetupPageWithToken: new RouteParser(Path.onboardingIntegrationSetupWithToken),
  orderDetail: new RouteParser(Path.orderDetail),
  returnsDetail: new RouteParser(Path.returnsDetail),
  inboundsShip: new RouteParser(Path.inboundsShip),
  inboundsShipAddDimensions: new RouteParser(Path.inboundsShipAddDimensions),
  inboundsShippingPlanRedirect: new RouteParser(Path.inboundsShippingPlanRedirect),
  inboundsStorageDetail: new RouteParser(Path.inboundsStorageDetail),
  inboundsAddFBAShipmentReferenceIDTask: new RouteParser(Path.inboundsAddFBAShipmentReferenceIDTask),
  inboundsSmbShipmentDetails: new RouteParser(Path.inboundsSmbShipmentDetails),
  inboundsSmbShipmentMessages: new RouteParser(Path.inboundsSmbShipmentMessages),
  inboundsSmbShipmentTask: new RouteParser(Path.inboundsSmbShipmentTask),
  inboundsSmbShipmentProblem: new RouteParser(Path.inboundsSmbShipmentProblem),
  inboundsConnectAmazonTask: new RouteParser(Path.inboundsConnectAmazonTask),
  inboundsAddProductsTask: new RouteParser(Path.inboundsAddProductsTask),
  inboundsAddCasePacksTask: new RouteParser(Path.inboundsAddCasePacksTask),
  inboundsAddFBADetailsTask: new RouteParser(Path.inboundsAddFBADetailsTask),
  replenishmentDetails: new RouteParser(Path.replenishmentDetails),
  wholesaleDetail: new RouteParser(Path.wholesaleDetail),
};

export interface PathParams {
  [Path.barcodePage]: "dsku";
  [Path.channelsEdit]: "channel";
  [Path.egd]: "page";
  [Path.fastTagsProgram]: "program" | "page";
  [Path.freightQuoteSummary]: "freightQuoteId";
  [Path.publicFreightQuoteSummary]: "freightQuoteId";
  [Path.inboundsShippingPlanProducts]: "shippingPlanId";
  [Path.inboundsCustoms]: "shippingPlanId";
  [Path.inboundsBarcodeInput]: "shippingPlanId";
  [Path.inboundsBarcodePrint]: "shippingPlanId";
  [Path.inboundsDeliverrPrep]: "shippingPlanId";
  [Path.inboundsFromAddress]: "shippingPlanId";
  [Path.inboundsDistributeSelect]: "shippingPlanId";
  [Path.inboundsAddDimensions]: "shippingPlanId";
  [Path.inboundsShip]: "shippingPlanId" | "shipmentId";
  [Path.inboundsShipAddDimensions]: "shippingPlanId" | "shipmentId";
  [Path.inventoryDetail]: "dsku";
  [Path.onboardingIntegrationSetupComplete]: "channel";
  [Path.onboardingIntegrationSetup]: "channel" | "token";
  [Path.orderDetail]: "orderId";
  [Path.orderClaim]: "orderId";
  [Path.orderClaimAppeal]: "orderId";
  [Path.orderClaimReview]: "orderId";
  [Path.returnsDetail]: "orderId" | "returnId";
  [Path.walmart]: "page";
  [Path.inboundsStorageDetail]: "shippingPlanId";
  [Path.inboundsAddFBAShipmentReferenceIDTask]: "shippingPlanId" | "taskId";
  [Path.inboundsSmbShipmentDetails]: "shippingPlanId";
  [Path.inboundsSmbShipmentTask]: "shippingPlanId" | "taskId";
  [Path.inboundsSmbShipmentProblem]: "shippingPlanId" | "problemId";
  [Path.inboundsConnectAmazonTask]: "shippingPlanId" | "taskId";
  [Path.inboundsAddProductsTask]: "shippingPlanId" | "taskId";
  [Path.inboundsAddCasePacksTask]: "shippingPlanId" | "taskId";
  [Path.inboundsAddFBADetailsTask]: "shippingPlanId" | "taskId";
}
