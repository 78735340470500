import { Appearance } from "common/components/ui/shared";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { NotificationItem } from "../NotificationItem/NotificationItem";

export const SeeWhatsNew: FC = () => (
  <NotificationItem
    title={
      <FormattedMessage id="notifications.banners.seeWhatsNew.title" defaultMessage="See what's new at Flexport" />
    }
    description={
      <FormattedMessage
        id="notifications.banners.seeWhatsNew.description"
        defaultMessage="We're always making improvements to help you run your business better."
      />
    }
    iconType="launch"
    iconAppearance={Appearance.PRIMARY}
    backgroundAppearance={Appearance.PRIMARY}
    callToActionExternalPath="https://www.flexport.com/blog/"
    callToActionMessage={
      <FormattedMessage id="notifications.banners.seeWhatsNew.callToActionMessage" defaultMessage="Learn more" />
    }
  />
);
