import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import React from "react";
import ReactSelect, { components, Props } from "react-select";
import { DefaultTheme } from "../shared";
import { Icon } from "../Icon";

interface SelectProps extends Exclude<Props, "theme"> {}

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    {props.selectProps.menuIsOpen ? <Icon type="caret-up" /> : <Icon type="caret-down" />}
  </components.DropdownIndicator>
);

const ValueContainer =
  ({ label, theme }: any) =>
  (props: any) => (
    <components.ValueContainer {...props}>
      <LabelStyle isDisabled={props.isDisabled} theme={theme}>
        {label}
      </LabelStyle>
      {props.children}
    </components.ValueContainer>
  );

const selectStyles: (theme: DefaultTheme) => Props["styles"] = (theme) => {
  const primaryColor = theme.colors[theme.config.colorByAppearance.DEFAULT];
  return {
    container: (styles, state) => ({
      ...styles,
      pointerEvents: "inherit",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      display: "inline-block",
    }),
    // tslint:disable-next-line: cyclomatic-complexity
    control: (styles, state) => ({
      ...styles,
      cursor: "pointer",
      color: state.isDisabled ? theme.colors.NEUTRAL[100] : primaryColor[300],
      backgroundColor: state.isDisabled ? theme.colors.NEUTRAL[80] : theme.colors.NEUTRAL["00"],
      pointerEvents: state.isDisabled ? "none" : "inherit",
      borderRadius: theme.border.radius.R2,
      borderColor: state.isDisabled ? theme.colors.NEUTRAL[100] : primaryColor[300],
      boxShadow: "none",
      transition: "border-color .2s ease-in-out",
      ":hover": {
        backgroundColor: primaryColor[300],
        borderColor: state.isDisabled ? theme.colors.NEUTRAL[100] : primaryColor[300],
        color: theme.colors.NEUTRAL["00"],
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: `0px ${theme.spacing.S3}`,
    }),
    singleValue: (styles, state) => ({
      ...styles,
      color: state.isDisabled ? theme.colors.NEUTRAL[100] : theme.colors.NEUTRAL["00"],
      fontFamily: theme.font.family.STANDARD,
      maxWidth: "0px",
    }),
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isSelected ? theme.colors.NEUTRAL[40] : theme.colors.NEUTRAL["00"],
      color: theme.colors.NEUTRAL[300],
      fontFamily: theme.font.family.STANDARD,
      ":hover": {
        backgroundColor: state.isSelected ? theme.colors.NEUTRAL[80] : theme.colors.NEUTRAL[60],
      },
    }),
    menu: (styles) => ({
      ...styles,
      marginTop: theme.spacing.S1,
      borderColor: theme.colors.NEUTRAL[80],
      borderRadius: theme.border.radius.R2,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: theme.colors.NEUTRAL[80],
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "inherit",
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none",
    }),
  };
};

const LabelStyle = styled.label<Pick<SelectProps, "isDisabled" | "theme">, DefaultTheme>(({ theme }) => {
  return `
    z-index: ${theme.zindex.Z1};
    color: inherit;
    font-weight: ${theme.font.weight.BOLD};
    cursor: pointer;
  `;
});

export const ButtonMenu: React.FC<SelectProps> = (props) => {
  const theme = useTheme<DefaultTheme>();
  return (
    <ReactSelect
      components={{
        DropdownIndicator,
        ValueContainer: ValueContainer({ label: props.label, theme }),
      }}
      styles={selectStyles(theme)}
      {...props}
      openMenuOnFocus={true}
      placeholder={""}
      isSearchable={false}
    />
  );
};
