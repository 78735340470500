import { useForm } from "react-hook-form";
import { DimensionsFormValues } from "inbounds/steps/dimensions/DimensionsFormValues";
import { useStorageInboundNavigation } from "../navigation/useStorageInboundNavigation";
import { useDispatch, useSelector } from "react-redux";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { getSelectedProductsCaseDimensions } from "../store/selector/getSelectedProductsCaseDimensions";
import { getDefaultDimensions } from "inbounds/steps/dimensions/getDefaultDimensions";
import { setSelectedProductsCaseDimensions } from "./StorageCaseDimensionActions";
import { some } from "lodash/fp";
import { getCaseDimensionsError } from "storage/inbounds/utils/getCaseDimensionsError";
import { useMount } from "react-use";
import { getStorageProductDimensions } from "../cargoType/actions/getStorageProductDimensions";
import { useEffect, useMemo } from "react";
import { useLoader } from "common/components/WithLoader/useLoader";
import { CaseDimensionLoaderId } from "./CaseDimensionLoaderId";
import { updateStorageInboundOrder } from "../store/actions/updateStorageInboundOrder";
import { FeatureName, useFeatureOn } from "common/Split";

export const useCaseDimensionsAndWeights = () => {
  const selectedProductsCaseDimensions = useSelector(getSelectedProductsCaseDimensions);
  const { goToNextStep, goToPreviousStep } = useStorageInboundNavigation();
  const dispatch = useDispatch();
  const formHandler = useForm<DimensionsFormValues>({
    mode: "onChange",
    defaultValues: useMemo(
      () => getDefaultDimensions(selectedProductsCaseDimensions),
      [selectedProductsCaseDimensions]
    ),
  });
  const isDimensionOverrideFeatureEnabled = useFeatureOn(FeatureName.InboundRsDimRelaxation);
  const currentFormData = formHandler.watch();
  const isNextDisabled = some(
    (caseDimension) => !!getCaseDimensionsError(caseDimension, isDimensionOverrideFeatureEnabled),
    Object.values(currentFormData)
  );

  const isProductCaseDimensionsLoading = useLoader(CaseDimensionLoaderId.productCaseDimensions);

  useMount(() => {
    dispatch(getStorageProductDimensions());
  });

  useEffect(() => {
    formHandler.reset(getDefaultDimensions(selectedProductsCaseDimensions));
  }, [selectedProductsCaseDimensions]);

  const saveFormDataToRedux = async () => dispatch(setSelectedProductsCaseDimensions(currentFormData));

  const onNextClick = async () => {
    dispatch(addLoader(InboundLoaderId.transition));
    await saveFormDataToRedux();
    if (await dispatch(updateStorageInboundOrder())) {
      await goToNextStep();
    }
    dispatch(clearLoader(InboundLoaderId.transition));
  };

  const onPreviousClick = async () => {
    await saveFormDataToRedux();
    goToPreviousStep();
  };

  return {
    isLoading: isProductCaseDimensionsLoading,
    formHandler,
    selectedProductsCaseDimensions,
    onNextClick,
    onPreviousClick,
    isNextDisabled,
    isDimensionOverrideFeatureEnabled,
  };
};
