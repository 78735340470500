import React from "react";
import { FormattedMessage } from "react-intl";
import { FormGroup, Text } from "common/components/ui";
import { AnyAction } from "redux";
import { TimeWindow } from "@deliverr/commons-objects";

import { useFacilityOperatingHours } from "./useFacilityOperatingHours";
import { StyledTimePicker } from "common/components/TimePicker/StyledTimePicker";
import styled from "@emotion/styled";

import cls from "common/components/TimePicker/TimePicker.less";
import {
  EARLIEST_PICKUP_TIME,
  LATEST_PICKUP_TIME,
  MINIMUM_PICKUP_WINDOW_HOURS,
} from "common/utils/constants/FacilityOperatingHoursConstants";
import { addHours } from "date-fns";

const HeightContainer = styled.div<{ croppedHeight: boolean }>(
  ({ croppedHeight }) => `
  ${
    croppedHeight
      ? `
    ul.react-datepicker__time-list {
      height: 120px !important;
    }`
      : ""
  }
`
);

export interface FacilityOperatingHoursProps {
  updateHours: (time) => AnyAction;
  facilityOperatingHours: TimeWindow | undefined;
  hasMinRange?: boolean;
  isOptional?: boolean;
  croppedHeight?: boolean;
  title?: JSX.Element;
  minTime?: Date;
  maxTime?: Date;
  minWindowHours?: number;
}

export const FacilityOperatingHours: React.FC<FacilityOperatingHoursProps> = ({
  croppedHeight = false,
  minTime = EARLIEST_PICKUP_TIME,
  maxTime = LATEST_PICKUP_TIME,
  minWindowHours = MINIMUM_PICKUP_WINDOW_HOURS,
  ...props
}) => {
  const {
    facilityOperatingHoursStartInput,
    facilityOperatingHoursStart,
    handleFacilityOperatingHoursStartChange,
    facilityOperatingHoursEndInput,
    facilityOperatingHoursEnd,
    handleFacilityOperatingHoursEndChange,
    facilityOperatingHoursFormGroupProps,
  } = useFacilityOperatingHours(props);

  return (
    <FormGroup as="div" formGroupStyles={cls.datePickerForm} {...facilityOperatingHoursFormGroupProps}>
      <HeightContainer croppedHeight={croppedHeight}>
        <StyledTimePicker
          input={facilityOperatingHoursStartInput}
          selected={facilityOperatingHoursStart}
          onChange={handleFacilityOperatingHoursStartChange}
          minTime={minTime}
          maxTime={addHours(maxTime, -minWindowHours)}
        />
      </HeightContainer>
      <Text>
        <FormattedMessage id="inbounds.metro.timepickerConnector" defaultMessage="to" />
      </Text>
      <HeightContainer croppedHeight={croppedHeight}>
        <StyledTimePicker
          input={facilityOperatingHoursEndInput}
          selected={facilityOperatingHoursEnd}
          onChange={handleFacilityOperatingHoursEndChange}
          minTime={addHours(minTime, minWindowHours)}
          maxTime={maxTime}
        />
      </HeightContainer>
    </FormGroup>
  );
};
