import React, { FC } from "react";
import { DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";

export interface ProductPhotoProps {
  src: string;
  onClick?: () => void;
  isSmall?: boolean;
}

const ProductImage = styled.img<{ isSmall?: boolean; onClick?: () => void }, DefaultTheme>(
  ({ isSmall, onClick, theme }) => `
    height: ${isSmall ? "60px" : "96px"};
    width: ${isSmall ? "60px" : "107px"};
    border-radius: ${theme.border.radius.R5};
    cursor: ${onClick !== undefined ? "pointer" : "default"};
    position: relative;
  `
);

export const ProductPhoto: FC<ProductPhotoProps> = React.memo(({ src, onClick, isSmall }) =>
  src.length ? <ProductImage isSmall={isSmall} onClick={onClick} src={src} /> : null
);
