import AlgoliaService from "common/list/AlgoliaService";
import { SearchService, SearchRequest, SearchResults } from "../SearchService";
import { Dictionary } from "lodash";

export interface AlgoliaServiceConfig {
  indexName: string;
  highlightMatches?: boolean; // FIXME: Why is AlgoliaService accepting that flag twice?
  searchConfig: {
    hitsPerPage: number;
    disjunctiveFacets?: string[];
    maxValuesPerFacet?: number;
    highlightMatches?: boolean;
  };
}

export class AlgoliaSearchService extends SearchService {
  private algoliaService: AlgoliaService;

  constructor(config: AlgoliaServiceConfig) {
    super();
    this.algoliaService = AlgoliaService.get({
      indexName: config.indexName,
      searchConfig: config.searchConfig,
      highlightMatches: config.highlightMatches,
    });
  }

  public clearCache(): SearchService {
    this.algoliaService.clearCache();
    return this;
  }

  public async execute(request: SearchRequest): Promise<SearchResults> {
    if (request.clearCacheOnSearch) {
      this.algoliaService.clearCache();
    }

    return await this.algoliaService.search(
      request.searchTerm!,
      request.page,
      request.sort,
      request.filters,
      request.numericFilter,
      request.pageSize
    );
  }

  public async searchByIds(
    ids: Array<number | string>,
    query: string,
    idField: string,
    resultsSize?: number
  ): Promise<SearchResults> {
    return await this.algoliaService.searchByIds(ids, query, idField, resultsSize);
  }

  public async getRecordByObjectId<T>(objectId: string): Promise<T> {
    return await this.algoliaService.getRecordByObjectId(objectId);
  }

  public async getAllRecordsByObjectIds<T>(objectIds: string[]): Promise<Dictionary<T>> {
    return await this.algoliaService.getAllRecordsByObjectIds(objectIds);
  }
}
