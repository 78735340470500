import { TextAlign, ThemeProps } from "common/components/ui/shared";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useFreightAddressType } from "./useFreightAddressType";
import { Box, Checkbox, Text } from "common/components/ui";
import styled from "@emotion/styled";

const StyledText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[500]};
`
);

export const FreightAddressType: FC = () => {
  const {
    handleIsLimitedAccessChange,
    handleIsResidentialChange,
    isResidential,
    isLimitedAccess,
  } = useFreightAddressType();
  return (
    <div>
      <Checkbox
        label={
          <StyledText as="div" textAlign={TextAlign.start}>
            <FormattedMessage
              id="storage.inbounds.fromAddress.isResidential"
              defaultMessage="This is a residential location"
            />
          </StyledText>
        }
        checked={isResidential}
        onChange={(event) => handleIsResidentialChange(event.target.checked)}
      />
      <Checkbox
        label={
          <StyledText as="div" textAlign={TextAlign.start}>
            <FormattedMessage
              id="storage.inbounds.fromAddress.isLimitedAccess"
              defaultMessage="This is a limited access location for freight pickup"
            />
          </StyledText>
        }
        checked={isLimitedAccess}
        onChange={(event) => handleIsLimitedAccessChange(event.target.checked)}
      />
      <Box paddingLeft={"S5"}>
        <Text appearance="INFO" as="footer" textAlign={TextAlign.start} size="caption">
          <FormattedMessage
            id="storage.inbounds.fromAddress.limitedAccess.footNote"
            defaultMessage="Maritime port, airport, military base"
          />
        </Text>
      </Box>
    </div>
  );
};
