import { sumBy } from "lodash/fp";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { getWarehouseAddressLabel } from "transfers/utils/getWarehouseAddressLabel";
import { getIsLoading } from "common/components/WithLoader/LoadingSelectors";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { selectWholesaleCreate } from "../store/WholesaleCreateSelectors";
import { wholesaleCreateLabels } from "../wholesaleCreate.labels";
import { wholesaleCreateChooseOriginWarehouse } from "../actions/WholesaleCreateActions";

export const useWholesalePickStorageWarehouseStep = () => {
  const dispatch = useDispatch();
  const { originStorageWarehouse, availableInventory } = useSelector(selectWholesaleCreate);
  const { formatMessage } = useIntl();

  const storageWarehouseOptions = availableInventory.map((storageWarehouseInventory) => ({
    value: storageWarehouseInventory.warehouse.id,
    label: getWarehouseAddressLabel(storageWarehouseInventory?.warehouse),
    content: formatMessage(wholesaleCreateLabels.steps.origin.options.productAvailable, {
      qty: sumBy((dskuQty) => dskuQty.qty ?? 0, storageWarehouseInventory.dskuQuantities),
    }),
  }));

  const onStorageWarehouseSelect = (evt) => {
    const selectedWarehouseId = evt.currentTarget.value;
    const selectedStorageWarehouseInventory = availableInventory.find(
      (warehouseInventory) => warehouseInventory.warehouse.id === selectedWarehouseId
    );
    dispatch(wholesaleCreateChooseOriginWarehouse(selectedStorageWarehouseInventory));
  };

  const isNextLoading = useSelector(getIsLoading)(CreateTransferLoader.UpdateTransferOrderLoader);

  return {
    storageWarehouseOptions,
    onStorageWarehouseSelect,
    selectedWarehouseOption: originStorageWarehouse?.warehouse.id,
    isNextLoading,
  };
};
