import React, { FC } from "react";
import { TooltipWithIcon } from "common/components/icons/TooltipWithIcon";
import { FormattedMessage, MessageDescriptor } from "react-intl";

interface TooltipWrapperProps {
  showTooltip: boolean;
  label?: MessageDescriptor;
  children: JSX.Element;
}

export const TooltipWrapperReturnsButton: FC<TooltipWrapperProps> = ({ children, showTooltip, label }) => {
  return showTooltip && label ? (
    <TooltipWithIcon
      message={<FormattedMessage id={label.id} defaultMessage={label.defaultMessage} />}
      placement="bottom"
    >
      {children}
    </TooltipWithIcon>
  ) : (
    children
  );
};
