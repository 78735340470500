import styled from "@emotion/styled";
import { ThemeProps } from "common/components/ui";

export const LabelWithPill = styled.div<ThemeProps>(
  ({ theme }) => `
    column-gap: ${theme.spacing.S2};
    align-items: center;
    display: flex;
  `
);
