import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { Notification } from "./Notification/Notification";

export type NotificationClientConfig = ApiClientConfig;

export class NotificationClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: NotificationClientConfig = { baseURL: mustBeDefined(process.env.NOTIFICATION_CLIENT_URL) }) {
    this.apiClient = new ApiClient({
      baseURL,
    });
  }

  async getNotifications(
    sellerId: string,
    limit?: number,
    offset?: number,
    excludeBy?: string,
    excludes?: string[]
  ): Promise<Notification[]> {
    return await this.apiClient.get({
      url: `/v2/notifications/sellers/${encodeURIComponent(sellerId)}`,
      params: {
        limit,
        offset,
        excludeBy,
        excludes,
      },
      authentication: isAuthenticated,
    });
  }
}
