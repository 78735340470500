import React from "react";
import { useShipmentTotalsValidation } from "./useShipmentTotalsValidation";
import { OverVolumeWarning, OverWeightWarning } from "./validationNotifications";
import { Box, Stack } from "common/components/ui";

interface Props {
  omitPallets?: boolean;
}

export const ShipmentTotalsNotifications = ({ omitPallets }: Props) => {
  const { isOverVolume, isOverWeight } = useShipmentTotalsValidation({
    omitPallets,
  });

  return (
    <Box marginTop="S4">
      <Stack gap="S4">
        {isOverVolume && <OverVolumeWarning />}
        {isOverWeight && <OverWeightWarning />}
      </Stack>
    </Box>
  );
};
