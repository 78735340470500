import { WithAdSetSyncState } from "./adSetsReducer";

export const NONE = "NONE";

export const SYNCING = "SYNCING";

export const ERRORED_WHILE_SYNCING = "ERRORED_WHILE_SYNCING";

export const SUCCEEDED = "SUCCEEDED";

export type AdSetSyncStatus = typeof SYNCING | typeof ERRORED_WHILE_SYNCING | typeof SUCCEEDED;

export const adSetSyncStatus = ({ adSets }: WithAdSetSyncState) => {
  if (adSets === null) {
    return NONE;
  } else if (adSets === "syncing") {
    return SYNCING;
  } else if (adSets === "error") {
    return ERRORED_WHILE_SYNCING;
  }

  return "SUCCEEDED";
};
