import React from "react";
import styled from "@emotion/styled";
import { css } from "emotion";

import { Text, defaultTheme } from "common/components/ui";
import { NumberInput, NumberInputProps } from "common/components/NumberInput";

/**
 *  Allows the display of an inline label and unit
 */

const INLINE_STYLES = css`
  display: grid !important;
  gap: ${defaultTheme.spacing.S2};
  grid-template-columns: min-content auto;
  align-items: center;
  margin-bottom: 0;

  .formGroup_label {
    padding-bottom: 0;
  }
`;

/** using Stack within a Stack can result in gap not being applied here */
const Container = styled.div`
  display: inline-flex;
  gap: ${defaultTheme.spacing.S2};
  align-items: center;
`;

export interface InlineNumberInputProps extends NumberInputProps {
  unit?: string;
}

/**
 * IMPORTANT: When using react hook form, use a controller (Controller, useController) and pass the ref with getInputRef
 */

export const InlineNumberInput = ({ unit, getInputRef, ...numberInputProps }: InlineNumberInputProps) => (
  <Container>
    <NumberInput formGroupStyles={INLINE_STYLES} {...numberInputProps} />
    {unit && <Text as="span">{unit}</Text>}
  </Container>
);
