import { createSelector } from "reselect";

import { getLoadedPlannedShipment } from "inbounds/steps/InboundStepSelectors";
import BoxArrangement from "inbounds/steps/ship/BoxArrangement";
import { getLoadedShipmentBoxQtyBySku } from "./getLoadedShipmentBoxQtyBySku";
import { selectLoadedShipmentItems } from "./selectLoadedShipmentItems";

/**
 * Checks if any Package on the Shipment has only one unit per box even though there's more than
 * 1 unit allotted for that SKU at the Shipment Item level (ShipmentItem.qty).
 */
export const getShipmentHasIllAdvisedOneUnitPerBox = createSelector(
  getLoadedPlannedShipment,
  selectLoadedShipmentItems,
  getLoadedShipmentBoxQtyBySku,
  ({ boxArrangement }, shipmentItems, boxConfigQtysBySku) => {
    return (
      boxArrangement === BoxArrangement.OneSKUPerBox &&
      shipmentItems.some(({ qty, dsku }) => {
        const boxQtys = boxConfigQtysBySku[dsku] ?? [];
        // Check if any box configs have a 1 unit per box config
        return qty > 1 && boxQtys.includes(1);
      })
    );
  }
);
