function loadElevio(e, l, v, i, o, n) {
  e[i] || (e[i] = {}), (e[i].account_id = n);
  var g, h;
  (g = l.createElement(v)),
    (g.type = "text/javascript"),
    (g.async = 1),
    (g.src = o + n),
    (h = l.getElementsByTagName(v)[0]),
    h.parentNode.insertBefore(g, h);
  e[i].q = [];
  e[i].on = function(z, y) {
    e[i].q.push([z, y]);
  };
}

export function loadElevioIfNotLoaded() {
  if (window?._elev !== undefined) {
    return;
  }
  loadElevio(
    window,
    document,
    "script",
    "_elev",
    "https://cdn.elev.io/sdk/bootloader/v4/elevio-bootloader.js?cid=",
    "59e14cc63ca88"
  );
}
