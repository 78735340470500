// preload react select

import "@deliverr/react-select-v1";

import cx from "classnames";
import React, { FC, memo } from "react";

import cls from "./DisabledSelect.less";

interface Props {
  option: {
    label: string;
    value?: string;
  };
  name?: string;
  className?: string;
}

export const DisabledSelect: FC<Props> = memo(({ option, className, name }) => (
  // use CSS classes from react-select
  <div className={cx("Select--single", className)}>
    <div className={cx("Select-control", cls.control)}>
      <div className="Select-multi-value-wrapper">
        <div className="Select-value">{option.label}</div>
        <input name={name} type="hidden" value={option.value} />
      </div>
      <span className={cx("Select-arrow-zone", cls.arrowZone)}>
        <span className="Select-arrow" />
      </span>
    </div>
  </div>
));
