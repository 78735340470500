import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { Accordion, Box, BoxReset, Text, ThemeProps, Title } from "common/components/ui";
import { Currency, CurrencyFormat } from "common/components/Currency";
import { useShipmentConfirmation } from "./useShipmentConfirmation";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";
import { InfoIcon } from "common/components/icons";
import { ShipmentConfirmationEstimatedCostBreakup } from "./ShipmentConfirmationEstimatedCostBreakup";
import { useShipmentConfirmationCostTable } from "./useShipmentConfirmationCostTable";
import { ShipmentConfirmationTile } from "common/components/ShipmentConfirmationTile";

const ShipmentDetailContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  width: 100%;
  line-height: ${theme.spacing.S4};
`
);

const StyledTitle = styled(Title)<ThemeProps>(
  ({ theme }) => `
      line-height: ${theme.font.lineHeight.LH2};
      font-size: ${theme.font.size.F2};
    `
);

export const StorageShipmentConfirmation: React.FC = () => {
  const title = (
    <BoxReset margin={null}>
      <StyledTitle displayAs="h5" as="h5" uppercase>
        <FormattedMessage id={"storage.inbounds.detail.confirmation.title"} defaultMessage={"SHIPMENT CONFIRMATION"} />
      </StyledTitle>
    </BoxReset>
  );

  const learnMore = <ElevioLearnMoreButton articleId={ElevioArticleId.ReserveStorage} />;

  const {
    cargoTypeTitle,
    shippingTitle,
    cargoReadyDateTitle,
    cargoReadyDate,
    cargoReadyTimeWindow,
    cargoTypeContent,
    shippingContent,
    shippingDetails,
    toggleOpen,
    isOpen,
    isZoneBasedMerchant,
  } = useShipmentConfirmation();

  const { costTitle, cost, subTables } = useShipmentConfirmationCostTable();

  const costDetails = <ShipmentConfirmationEstimatedCostBreakup subTables={subTables} />;

  return (
    <Accordion indicator isOpen={isOpen} toggleOpen={toggleOpen} headerContent={title} noBodyPadding>
      <ShipmentDetailContainer>
        {cargoReadyDate && cargoReadyTimeWindow && (
          <ShipmentConfirmationTile
            header={cargoReadyDateTitle}
            content={cargoReadyDate}
            footer={cargoReadyTimeWindow}
          />
        )}
        <ShipmentConfirmationTile data-testid="cargo-type-title" header={cargoTypeTitle} content={cargoTypeContent} />
        <ShipmentConfirmationTile
          data-testid="shipment-confirmation-shipping-title"
          header={shippingTitle}
          content={shippingContent}
          footer={shippingDetails}
        />
        {!isZoneBasedMerchant && (
          <ShipmentConfirmationTile
            data-testid="shipment-confirmation-cost-title"
            header={
              <Text size="bodyLarge">
                {costTitle}
                <Box as="span" marginLeft="S1">
                  <InfoIcon wrap messageWidth={300} placement="top" message={costDetails} />
                </Box>
              </Text>
            }
            content={<Currency value={cost} currency={CurrencyFormat.USD} />}
            footer={learnMore}
          />
        )}
      </ShipmentDetailContainer>
    </Accordion>
  );
};
