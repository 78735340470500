import { BoxReset, Input, Box, Text, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { STORAGE_MAX_FTL_CARGO_WEIGHT } from "../../../SelectShippingConstants";

const NumberInput = styled(Input)<ThemeProps>(
  ({ theme }) => `
      padding-right: ${theme.spacing.S3};
    `
);

export interface StorageTotalCargoWeightProps {
  isTotalCargoWeightError: boolean;
  totalCargoWeight: number;
  onTotalCargoWeightUpdate: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const StorageTotalCargoWeight: FC<StorageTotalCargoWeightProps> = ({
  isTotalCargoWeightError,
  totalCargoWeight,
  onTotalCargoWeightUpdate,
}) => {
  const totalCargoWeightErrorMessage = (
    <FormattedMessage
      id="storage.inbounds.cargoType.totalCargoWeight.error"
      defaultMessage="Total weight cannot exceed {STORAGE_MAX_FTL_CARGO_WEIGHT} lbs."
      values={{ STORAGE_MAX_FTL_CARGO_WEIGHT }}
    />
  );
  return (
    <Box marginTop="S7">
      <Text bold>
        <FormattedMessage id="storage.inbounds.cargoType.totalCargoWeight" defaultMessage="Add total cargo weight" />
      </Text>
      <BoxReset width="5rem" marginTop="S4">
        <NumberInput
          name="numOfPallets"
          hasError={isTotalCargoWeightError}
          errorMessage={totalCargoWeightErrorMessage}
          type="number"
          min={1}
          value={totalCargoWeight}
          onChange={onTotalCargoWeightUpdate}
        />
      </BoxReset>
    </Box>
  );
};
