import { DskuQty } from "@deliverr/business-types";
import { selectAnyIsLoading } from "common/store/selectors/selectAnyIsLoading";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useAsync } from "react-use";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { areAllItemsRemovedFromInvalidItemOrder } from "../invalid-items/InvalidItemOrderUtils";
import { setOriginWarehouseForReplenishmentOutOfStockOrder } from "./setOriginWarehouseForReplenishmentOutOfStockOrder";
import { selectAugmentedOutOfStockOrderItems } from "transfers/create/store/selectAugmentedOutOfStockOrderItems";
import { isOutOfStockItemUnremoved } from "./OutOfStockOrderUtils";
import { updateInvalidOrderItem } from "transfers/create/actions";
import { InvalidOrderItemDto, InvalidOrderItemResolution } from "@deliverr/replenishment-client";

export const useReplenishmentOutOfStockOrderItems = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { replenishmentOrder, unifiedProductResponseCache, originStorageWarehouse } = useSelector(selectTransferCreate);
  const augmentedOutOfStockOrderItems = useSelector(selectAugmentedOutOfStockOrderItems);
  const orderItemsDskuQty: DskuQty[] = useMemo(
    () => [
      ...(replenishmentOrder?.invalidOrderItems
        ?.filter((item) => isOutOfStockItemUnremoved(item))
        .map((item) => ({
          dsku: item.resolvedDsku!,
          qty: item.requestedTotalUnits,
        })) ?? []),
      ...(replenishmentOrder?.orderItems?.map((item) => ({ dsku: item.dsku, qty: item.totalUnits })) ?? []),
    ],
    [replenishmentOrder]
  );

  const isReplenishmentOrderLoading = useSelector(selectAnyIsLoading)(CreateTransferLoader.CreateTransferOrderLoader);

  const areAllOrderItemsRemoved = areAllItemsRemovedFromInvalidItemOrder(replenishmentOrder!);

  const { loading: isFetchAvailableWarehouseInventoryLoading, value: warehouseInventory } = useAsync(async () => {
    return dispatch(setOriginWarehouseForReplenishmentOutOfStockOrder(orderItemsDskuQty));
  }, [orderItemsDskuQty]);

  const isNextDisabled =
    areAllOrderItemsRemoved ||
    isReplenishmentOrderLoading ||
    isFetchAvailableWarehouseInventoryLoading ||
    !warehouseInventory;

  const removeItem = (item: InvalidOrderItemDto) => {
    dispatch(
      updateInvalidOrderItem({
        ...item,
        resolution: InvalidOrderItemResolution.REMOVED,
        resolvedTotalUnits: 0,
        resolvedDsku: undefined,
      })
    );
  };

  return {
    formatMessage,
    replenishmentOrder,
    unifiedProductResponseCache,
    areAllOrderItemsRemoved,
    removeItem,
    isReplenishmentOrderLoading,
    isNextDisabled,
    warehouseInventory,
    originStorageWarehouse,
    augmentedOutOfStockOrderItems,
  };
};
