import { defineMessages, MessageDescriptor } from "react-intl";

export const shipmentConfirmedHeadingLabels: Record<string, MessageDescriptor> = defineMessages({
  shipmentConfirmedCaption: {
    id: "inbounds.storage.shipmentConfirmed.caption",
    defaultMessage: "You're almost done. Complete your shipment on the next page",
  },
  shipmentConfirmedTitle: {
    id: "inbounds.storage.shipmentConfirmed.title",
    defaultMessage: "Shipment confirmed",
  },
});
