import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { DaysLeftApiReponse } from "./DaysLeftApiResponse";

export type AvailabilityClientConfig = ApiClientConfig;

export class AvailabilityClient {
  private apiClient: ApiClient;

  constructor(
    { baseURL }: AvailabilityClientConfig = { baseURL: mustBeDefined(process.env.AVAILABILITY_SERVICE_URL) }
  ) {
    this.apiClient = new ApiClient({
      baseURL,
    });
  }

  async getFastTagDaysLeft(dsku: string, sellerId: string): Promise<DaysLeftApiReponse> {
    return await this.apiClient.get({
      url: `/v2/fast-tags/${dsku}/${sellerId}`,
      authentication: isAuthenticated,
    });
  }
}
