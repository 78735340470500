import { ShopifySellerConnectionStatus } from "common/clients/shopify/ShopifySellerConnectionStatus";
import { ShippingSettingsState } from "./ShippingSettingsState";

export interface ShopifyState {
  storeName: string;
  appInstalled?: boolean;
  useStoreCredit?: boolean;
  useDeliverrPlacementProductDetail?: boolean;
  useDeliverrPlacementOther?: boolean;
  exampleLinks: {
    productDetail?: string;
    other?: string;
  };
  isReconfiguring: boolean;
  cartMinimum: number;
  paidFastTagCost: number;
  standardBadge?: boolean;
  shop?: string;
  connectionStatus?: ShopifySellerConnectionStatus;
  appBlockTemplates?: string[];
  appEmbedBlockEnabled?: boolean;
  shippingSettings: ShippingSettingsState[];
  isExperimentAbTestEnable: boolean;
}

export const shopifyInitialState: ShopifyState = {
  storeName: "",
  exampleLinks: {},
  isReconfiguring: false,
  cartMinimum: 0,
  paidFastTagCost: 0,
  standardBadge: undefined,
  shippingSettings: [],
  isExperimentAbTestEnable: false,
};
