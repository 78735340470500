import { useDispatch, useSelector } from "react-redux";

import { ReturnStatus, ReturnType } from "@deliverr/returns-client";
import { RootState } from "RootReducer";
import { resetReturnCancel } from "../../cancel/store/ReturnsCancelAction";
import { useUnmount } from "react-use";

export const useReturnsCancelState = () => {
  const { returnsDetail } = useSelector((state: RootState) => state.returnsDetail);
  const disableCancel = ReturnStatus.CREATED !== returnsDetail.status || returnsDetail.returnType === ReturnType.RTS;
  const error = useSelector((state: RootState) => state.returnsDetail.returnsCancel.error);
  const returnCancelSuccess = useSelector((state: RootState) => state.returnsDetail.returnsCancel.returnCancelSuccess);

  const dispatch = useDispatch();
  useUnmount(() => {
    dispatch(resetReturnCancel());
  });

  return {
    error,
    returnCancelSuccess,
    disableCancel,
  };
};
