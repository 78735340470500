import styled from "@emotion/styled";
import { Card } from "common/components/Card";
import { Box, Text, ThemeProps } from "common/components/ui";
import { TextAlign } from "common/components/ui/shared";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";
import { LabeledCost } from "inbounds/components/LabeledCost/LabeledCost";
import React, { ChangeEventHandler, FC } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { PrepStepContainer, PrepStepContent, PrepStepDescription, PrepStepTitle } from "prep/components/PrepElements";
import { PrepCustomizedServicesSection } from "./PrepCustomizedServicesSection";
import { SIMPLE_PREP_PAGE_LABELS } from "./SimplePrepPage.labels";
import { PrepTileGroup, PrepTileGroupProps } from "../PrepTileGroup";
import { SimplePrepPageFooter } from "./SimplePrepPageFooter";
import { useSimplePrepPage } from "./useSimplePrepPage";
import { PrepCreationSource } from "prep/types/PrepCreationSource";
import { SimpleSellerSelfServePrepType } from "prep/types";
import { PrepType } from "@deliverr/prep-service-client";
import { KittingAdditionalFeesNotification } from "./KittingAdditionalFeesNotification";
import { KittingPrepInfoSection } from "./KittingPrepInfoSection";

const DetailContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const SpacedPrepStepContent = styled(PrepStepContent)<ThemeProps>(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    width: inherit;
    padding-left: ${theme.spacing.S7};
    padding-right: ${theme.spacing.S7};
    gap: ${theme.spacing.S5};
  `
);

const StyledPrepStepDescription = styled(PrepStepDescription)`
  max-width: 49.25rem;
`;

const FaintText = styled(Text)<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL["300"]};
  `
);

const SelectServicesText = styled(Text)<ThemeProps>(
  ({ theme }) => `
    padding-bottom: ${theme.spacing.S1};
  `
);

export interface UnconnectedSimplePrepPageProps extends PrepTileGroupProps {
  totalEstimatedCost: number;
  source: PrepCreationSource;
  selectedKitting?: SimpleSellerSelfServePrepType[];
  handleSelectKitting?: ChangeEventHandler<HTMLInputElement>;
  isPrepKittingEnabled: boolean;
}

export const UnconnectedSimplePrepPage: FC<UnconnectedSimplePrepPageProps> = ({
  itemCount,
  totalEstimatedCost,
  selectedPrepTypes,
  selectedKitting,
  handleSelectPrepType,
  handleSelectKitting,
  options,
  source,
  shouldShowKittingService,
  isKittingServiceDisabled,
  isPrepKittingEnabled,
}) => {
  const kittingOption = options.filter((option) => option.value === PrepType.KITTING);
  const simplePrepOptions = options.filter((option) => option.value !== PrepType.KITTING);

  return (
    <PrepStepContainer>
      <Card>
        <PrepStepTitle removeTopMargin={source === PrepCreationSource.REPLENISHMENT}>
          <FormattedMessage {...SIMPLE_PREP_PAGE_LABELS.title} />
        </PrepStepTitle>
        <StyledPrepStepDescription appearance="INFO">
          <FormattedMessage
            {...SIMPLE_PREP_PAGE_LABELS.description}
            values={{
              prepServicesLink: (
                <ElevioBorderlessButton articleId={ElevioArticleId.PrepServicesOverview}>
                  <FormattedMessage {...SIMPLE_PREP_PAGE_LABELS.prepServicesLink} />
                </ElevioBorderlessButton>
              ),
            }}
          />
        </StyledPrepStepDescription>
        <SpacedPrepStepContent>
          {
            <>
              <DetailContainer>
                <Box>
                  <SelectServicesText bold>
                    <FormattedMessage
                      {...(shouldShowKittingService
                        ? SIMPLE_PREP_PAGE_LABELS.selectKittingHeading
                        : SIMPLE_PREP_PAGE_LABELS.selectServicesHeading)}
                    />
                  </SelectServicesText>
                  <FaintText>
                    <FormattedMessage
                      {...(shouldShowKittingService
                        ? SIMPLE_PREP_PAGE_LABELS.kittingServicesClarification
                        : SIMPLE_PREP_PAGE_LABELS.prepServicesClarification)}
                      values={{
                        unitCount: <FormattedNumber value={itemCount} />,
                      }}
                    />
                  </FaintText>
                </Box>
                <LabeledCost
                  amount={totalEstimatedCost}
                  maximumFractionDigits={2}
                  label={
                    <Text>
                      <FormattedMessage {...SIMPLE_PREP_PAGE_LABELS.quoteLabel} />
                    </Text>
                  }
                  textAlign={TextAlign.end}
                />
              </DetailContainer>
              <PrepTileGroup
                options={shouldShowKittingService ? kittingOption : simplePrepOptions}
                itemCount={itemCount}
                selectedPrepTypes={shouldShowKittingService && selectedKitting ? selectedKitting : selectedPrepTypes}
                handleSelectPrepType={
                  shouldShowKittingService && handleSelectKitting ? handleSelectKitting : handleSelectPrepType
                }
                disabled={shouldShowKittingService ? isKittingServiceDisabled : false}
                tileWidth={shouldShowKittingService ? "100%" : "28rem"}
              />
            </>
          }
          {shouldShowKittingService && (
            <>
              <DetailContainer>
                <Box>
                  <SelectServicesText bold>
                    <FormattedMessage {...SIMPLE_PREP_PAGE_LABELS.selectServicesHeading} />
                  </SelectServicesText>
                  <FaintText>
                    <FormattedMessage
                      {...SIMPLE_PREP_PAGE_LABELS.prepServicesClarification}
                      values={{
                        unitCount: <FormattedNumber value={itemCount} />,
                      }}
                    />
                  </FaintText>
                </Box>
              </DetailContainer>
              <PrepTileGroup
                options={simplePrepOptions}
                itemCount={itemCount}
                selectedPrepTypes={selectedPrepTypes}
                handleSelectPrepType={handleSelectPrepType}
              />
            </>
          )}
          {isPrepKittingEnabled ? (
            shouldShowKittingService ? null : (
              <KittingPrepInfoSection />
            )
          ) : (
            <PrepCustomizedServicesSection />
          )}
          {shouldShowKittingService && !isKittingServiceDisabled && selectedKitting && selectedKitting?.length > 0 && (
            <KittingAdditionalFeesNotification />
          )}
          <FaintText>
            <FormattedMessage {...SIMPLE_PREP_PAGE_LABELS.finalInvoiceNote} />
          </FaintText>
        </SpacedPrepStepContent>
      </Card>
      {source === PrepCreationSource.INBOUNDS ? (
        <SimplePrepPageFooter
          selectedPrepTypes={selectedKitting ? [...selectedPrepTypes, ...selectedKitting] : selectedPrepTypes}
        />
      ) : null}
    </PrepStepContainer>
  );
};

export const SimplePrepPage: FC<{
  source: PrepCreationSource;
  defaultSelectedPrepType?: SimpleSellerSelfServePrepType;
}> = ({ source, defaultSelectedPrepType }) => {
  const {
    handleSelectPrepType,
    handleSelectKitting,
    options,
    totalEstimatedCost,
    selectedPrepTypes,
    selectedKitting,
    itemCount,
    shouldShowKittingService,
    isKittingServiceDisabled,
    isPrepKittingEnabled,
  } = useSimplePrepPage(source, defaultSelectedPrepType);

  return (
    <UnconnectedSimplePrepPage
      selectedPrepTypes={selectedPrepTypes}
      selectedKitting={selectedKitting}
      handleSelectPrepType={handleSelectPrepType}
      handleSelectKitting={handleSelectKitting}
      itemCount={itemCount}
      totalEstimatedCost={totalEstimatedCost}
      options={options}
      source={source}
      shouldShowKittingService={shouldShowKittingService}
      isKittingServiceDisabled={isKittingServiceDisabled}
      isPrepKittingEnabled={isPrepKittingEnabled}
    />
  );
};
