import { DestinationOptions, ReturnsSellerActionTypes } from "../ReturnsSellerStore.types";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { isNil, some } from "lodash";

import { ReturnAddress } from "@deliverr/returns-client";
import { ReturnLoader } from "returns/ReturnsLoader";
import { SPThunkAction } from "common/ReduxUtils";
import { defineMessages } from "react-intl";
import { isNull } from "lodash/fp";
import { returnsClient } from "Clients";

const errorMessage = defineMessages({
  networkError: {
    id: "returns.seller.error",
    defaultMessage: "Network error. Please refresh the page",
  },
});

export const getSellerInfo =
  (sellerId: string): SPThunkAction =>
  async (dispatch) => {
    try {
      dispatch({
        type: ReturnsSellerActionTypes.RETURNS_SELLER_UPDATE_SUCCESS,
        fetching: true,
      });
      dispatch(addLoader(ReturnLoader.ReturnsSellerLoader));
      const sellerInfo = await returnsClient.getSellerInfo(sellerId, true);
      const selfDestinationOptions: DestinationOptions[] =
        sellerInfo?.value?.qcAddresses
          .filter((address) => address.qcId === "Self" || isNull(address.qcId))
          .map((qcAddress) => ({
            ...qcAddress,
            addressType: "SAVED",
          })) ?? [];

      // TODO: update API to send these addresses as separate array
      const qcAddresses: ReturnAddress[] =
        sellerInfo?.value?.qcAddresses
          .filter((address) => !(address.qcId === "Self" || isNull(address.qcId)))
          .map((qcAddress) => ({
            ...qcAddress,
          })) ?? [];
      const hasQcAddress = qcAddresses.length > 0;

      let destinationOptions: DestinationOptions[] = [];
      if (hasQcAddress) {
        // TODO: to maintain compatibility with the existing code, we continue to add one (and only one) "SAVED_QC" placeholder
        //   to configure the UI to show a "Return to Flexport" option. This can be refactored.
        destinationOptions = [{ addressType: "SAVED_QC" } as DestinationOptions, ...selfDestinationOptions];
      } else {
        destinationOptions = selfDestinationOptions;
      }

      const hasAddress = some(destinationOptions, (option) => option.qcId === "Self" || isNil(option.qcId));
      if (!hasAddress) {
        destinationOptions = [...destinationOptions, { addressType: "NEW", country: "US" } as DestinationOptions];
      }
      if (!hasQcAddress) {
        destinationOptions = [...destinationOptions, { addressType: "NEW_QC", country: "US" } as DestinationOptions];
      }
      dispatch({
        type: ReturnsSellerActionTypes.RETURNS_SELLER_UPDATE_SUCCESS,
        destinationOptions,
        qcAddresses,
        sellerInfo: { deliverrSellerId: sellerId, ...(sellerInfo?.value ?? {}) },
        fetching: false,
      });
    } catch (error) {
      dispatch({
        type: ReturnsSellerActionTypes.RETURNS_SELLER_UPDATE_FAIL,
        errorMessage: errorMessage.networkError,
        fetching: false,
      });
    } finally {
      dispatch(clearLoader(ReturnLoader.ReturnsSellerLoader));
    }
  };
