import { listingConfigProp } from "common/ListingSolutionUtils";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { IntegrationSetupForm, IntegrationSetupFormProps } from "./IntegrationSetupForm";
import { InstructionsText } from "./styles";

export interface OAuthIntegrationSetupFormProps extends IntegrationSetupFormProps {
  instructions?: React.ReactNode;
}

export const OAuthIntegrationSetupForm: FC<OAuthIntegrationSetupFormProps> = ({ children, instructions, ...props }) => {
  const channelName = listingConfigProp(props.channel, "name") as string;

  return (
    <IntegrationSetupForm {...props}>
      <>
        <div>
          {instructions ?? (
            <InstructionsText>
              <FormattedMessage
                id="onboarding.integrationSetup.oauth.instructions"
                defaultMessage="You will be redirected to {channelName}. Grant permission for Flexport to access your products and orders."
                values={{ channelName }}
              />
            </InstructionsText>
          )}
        </div>
        {children}
      </>
    </IntegrationSetupForm>
  );
};
