import React, { FC } from "react";
import { DynamicRoutes } from "paths";
import { Product } from "@deliverr/commons-clients";
import { isEmpty } from "lodash";
import history from "BrowserHistory";
import { LinkButton } from "../LinkButton";
import styled from "@emotion/styled";

interface ProductLinkProps {
  product: Pick<Product, "dsku" | "brandedPackaging" | "packOf">;
  shouldOpenInNewTab?: boolean;
}

const StyledLinkButton = styled(LinkButton)`
  text-align: left;
`;

export const ProductLink: FC<ProductLinkProps> = ({
  product: { dsku, brandedPackaging, packOf },
  children,
  shouldOpenInNewTab,
  ...props
}) => {
  const route = brandedPackaging ? DynamicRoutes.brandedPackagingDetail : DynamicRoutes.inventoryDetail;
  const sku = isEmpty(packOf) ? dsku : packOf;
  const parsedRoute = route.parse({ dsku: sku });

  const handleClick = () => {
    shouldOpenInNewTab ? window.open(`/inventory/${sku}`, "_blank") : history.push(parsedRoute);
  };

  return (
    <StyledLinkButton {...props} onClick={handleClick}>
      {children}
    </StyledLinkButton>
  );
};
