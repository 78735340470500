import React, { useMemo } from "react";
import produce from "immer";
import { Appearance, Box, Notification, ThemeProps } from "common/components/ui";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Product } from "@deliverr/commons-clients/lib/product/Product";
import { removeAllEmTags } from "common/StringUtils";
import { FastTagCoverageAlert } from "inbounds/steps/ship/alerts/FastTagCoverageAlert";
import { BRANDED_PACKAGING_TAG } from "inventory/list/InventoryListActions";
import { ProductListItem } from "inventory/list/InventoryListReducer";
import { ProductSearch } from "../ProductSearch";
import cls from "./ProductChooser.less";
import { determineVisibility, SelectedProductsList } from "./SelectedProductList";
import { ProductChooserProps } from "./ProductChooserProps";
import { StorageRecommendationAlert } from "../StorageRecommendation/StorageRecommendationAlert";
import { useStorageRecommendationAlert } from "../StorageRecommendation/useStorageRecommendationAlert";
import { UnsetPackConfigNotification } from "storage/inbounds/create/selectProducts/UnsetPackConfigNotification";
import styled from "@emotion/styled";
import { LinkButton } from "../LinkButton";
import { DtcSingleSkuSelectedProductList } from "./IntlDtc/DtcSingleSkuSelectedProductList";
import { DtcMixedSkuSelectedProductList } from "./IntlDtc/DtcMixedSkuSelectedProductList";
import { SelectedProductData } from "./SelectedProductData";

const ProductChooser: React.FC<ProductChooserProps & WrappedComponentProps> = (props) => {
  const {
    selectedProducts,
    autoFocus: isAutoFocus,
    allowZeroInventorySelection: shouldAllowZeroInventorySelection,
    classNames: classNamesProp,
    excludedDsku,
    isPromotional,
    productLimit,
    disabled: isDisabled,
    isDimsRequired,
    hideHiddenSKUs: shouldHideHiddenSKUs,
    shouldShowAvailability,
    isReserveStorage,
    shouldHideCreateProduct,
    shouldHideFastTagAlert,
    shouldShowProductsLockedAlert,
    onClickProductsLockedLink,
    shouldIncludeBrandedPackaging,
    withWalmartChannelIdOnly,
    applyFbaConditions,
    isIntegratedFBA,
    notificationComponent,
    placeholder,
    showCasePackSelectionDropdown,
    selectedProductCasePacks,
    showOnlyPacks,
    showStorageRecommendation,
    shouldSortDropdownList,
    shouldHideSelectedProductList,
    bulkUploadButton,
    addProduct,
    isOrder,
    maxSkuLimitMessage,
    checkForInvalidProducts,
    useCasePack: shouldUseCasePack,
    onCreateProductSuccess,
    isDtcSingleSkuBooking,
    isDtcMixedSkuBooking,
    singleSkuPackages,
    mixedSkuPackage,
    storageMaximumSelectableCaseQty,
    excludedPackOfSkusForStorage,
    index,
    shouldShowKitHint,
  } = props;
  const { shouldHideSearchBar } = determineVisibility(isPromotional);
  const hasReachedSkuLimit = productLimit ? selectedProducts.length >= productLimit : false;
  const { showStorageRecommendationAlert, isStorageRecommendationAlertVisible, hideStorageRecommendationAlert } =
    useStorageRecommendationAlert();

  const onSearchResultClick = (inventoryListItem: ProductListItem) => {
    if (inventoryListItem) {
      const { dsku, raw } = inventoryListItem;
      const DSKU = removeAllEmTags(dsku);
      // brandedPackaging is not returned by search, but is in getUnifiedProducts
      // this ensures the product in the initial productDetailsCache update can be identified as branded packaging
      const product = produce(raw, (draft) => {
        if (draft._tags?.includes(BRANDED_PACKAGING_TAG)) {
          draft.brandedPackaging = {} as Product["brandedPackaging"];
        }
      });
      addProduct(DSKU, product, undefined, shouldUseCasePack);
      if (isOrder && checkForInvalidProducts) {
        checkForInvalidProducts(DSKU);
      }
    }
  };
  const MaxSkuLimitMessageSpan = styled.span<ThemeProps>(
    ({ theme }) => `
   display: block;
   margin-bottom: ${theme.spacing.S1};
  `
  );

  const customDisabledProductSkus = isDtcSingleSkuBooking
    ? Object.keys(singleSkuPackages ?? {})
    : isDtcMixedSkuBooking
    ? mixedSkuPackage?.map((item) => item.dsku) ?? []
    : undefined;

  const isIntlDtcInboundFlow = isDtcSingleSkuBooking ?? isDtcMixedSkuBooking;

  const selectedProductsByDsku: {
    [dsku: string]: SelectedProductData;
  } = useMemo(
    () =>
      isIntlDtcInboundFlow
        ? selectedProducts.reduce((acc, product) => {
            acc[product.dsku] = product;
            return acc;
          }, {})
        : {},
    [selectedProducts, isIntlDtcInboundFlow]
  );

  return (
    <div className={classNamesProp?.wrapper}>
      {hasReachedSkuLimit && (
        <MaxSkuLimitMessageSpan className={cls.maxSkusReached}>
          {maxSkuLimitMessage ?? (
            <FormattedMessage
              id="inbounds.productChooser.skuLimit"
              defaultMessage="You have reached the maximum number of SKUs for this inbound. Create a separate inbound to send additional skus."
            />
          )}
        </MaxSkuLimitMessageSpan>
      )}
      {!shouldHideSearchBar && (
        <ProductSearch
          autoFocus={isAutoFocus}
          selectedProducts={selectedProducts}
          disabled={hasReachedSkuLimit || isDisabled}
          allowZeroInventorySelection={shouldAllowZeroInventorySelection}
          onSelectProduct={onSearchResultClick}
          isDimsRequired={isDimsRequired}
          hideHiddenSKUs={shouldHideHiddenSKUs}
          bulkUploadButton={bulkUploadButton}
          excludedDsku={excludedDsku}
          showAvailability={shouldShowAvailability}
          isReserveStorage={isReserveStorage}
          shouldSortDropdownList={shouldSortDropdownList}
          shouldHideCreateProduct={shouldHideCreateProduct}
          shouldIncludeBrandedPackaging={shouldIncludeBrandedPackaging}
          withWalmartChannelIdOnly={withWalmartChannelIdOnly}
          applyFbaConditions={applyFbaConditions}
          isIntegratedFBA={isIntegratedFBA}
          placeholder={placeholder}
          showOnlyPacks={showOnlyPacks}
          isOrder={isOrder}
          onCreateProductSuccess={onCreateProductSuccess}
          storageMaximumSelectableCaseQty={storageMaximumSelectableCaseQty}
          excludedPackOfSkusForStorage={excludedPackOfSkusForStorage}
          customDisabledProductSkus={customDisabledProductSkus}
          shouldShowKitHint={shouldShowKitHint}
        />
      )}
      {notificationComponent}
      {selectedProducts.length > 0 && !shouldHideFastTagAlert && (
        <Box marginBottom="S4" marginTop="S4">
          <FastTagCoverageAlert />
        </Box>
      )}
      {shouldShowProductsLockedAlert && (
        <Notification
          appearance={Appearance.PRIMARY}
          content={{
            description: (
              <FormattedMessage
                id="inbounds.productChooser.bulkUpload.description"
                defaultMessage="To modify your products please {link}"
                values={{
                  link: (
                    <LinkButton
                      onClick={() => {
                        if (onClickProductsLockedLink !== undefined) {
                          onClickProductsLockedLink();
                        }
                      }}
                    >
                      <FormattedMessage
                        id="inbounds.productChooser.bulkUpload.button"
                        defaultMessage="upload a new file"
                      />
                    </LinkButton>
                  ),
                }}
              />
            ),
          }}
        />
      )}
      {showStorageRecommendation && isStorageRecommendationAlertVisible && (
        <Box marginBottom="S4" marginTop="S4">
          <StorageRecommendationAlert onClose={hideStorageRecommendationAlert} />
        </Box>
      )}
      {showCasePackSelectionDropdown && (
        <UnsetPackConfigNotification
          selectedProducts={selectedProducts}
          selectedProductCasePacks={selectedProductCasePacks}
          isDtcSingleSkuBooking={isDtcSingleSkuBooking}
          singleSkuPackages={singleSkuPackages ?? {}}
        />
      )}
      {!shouldHideSelectedProductList && (
        <>
          {isDtcSingleSkuBooking ? (
            <DtcSingleSkuSelectedProductList
              {...props}
              showStorageRecommendationAlert={showStorageRecommendationAlert}
              singleSkuPackages={singleSkuPackages ?? {}}
              selectedProductsByDsku={selectedProductsByDsku}
            />
          ) : isDtcMixedSkuBooking ? (
            <DtcMixedSkuSelectedProductList
              {...props}
              showStorageRecommendationAlert={showStorageRecommendationAlert}
              mixedSkuPackage={mixedSkuPackage}
              index={index ?? 0}
              selectedProductsByDsku={selectedProductsByDsku}
            />
          ) : (
            <SelectedProductsList {...props} showStorageRecommendationAlert={showStorageRecommendationAlert} />
          )}
        </>
      )}
    </div>
  );
};

export default injectIntl(ProductChooser);
