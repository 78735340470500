import React from "react";
import { useIntl } from "react-intl";
import { ManagedStep } from "common/components/StepContainer";
import { Appearance, BorderIcon } from "common/components/ui";

export const SharedConfirmedStep = (labels) => {
  const { formatMessage } = useIntl();

  return () => {
    return (
      <ManagedStep
        data-testid="confirmed-step"
        title={formatMessage({ ...labels.steps.confirmed.title })}
        subtitle={formatMessage({ ...labels.steps.confirmed.subtitle })}
        header={<BorderIcon appearance={Appearance.SUCCESS} iconType="check" size="XXL" />}
      />
    );
  };
};
