import { fromPairs } from "lodash/fp";
import { updateOrganizationClaims } from "../../organization/OrganizationActions";
import { OnboardingStage as OnboardingStageInterface } from "common/clients/seller/Organization/OnboardingStage";

import { RootState } from "RootReducer";

export enum OnboardingStage {
  ListingTool = "listingTool",
  ImportingCatalog = "importingCatalog",
  AllSet = "allSet",
  CompletedInbound = "completedInbound",
  CreatedInbound = "createdInbound",
}

export const ONBOARDING_STAGES = Object.values(OnboardingStage);
export const BASE_ONBOARDING_STAGE = JSON.stringify(fromPairs(ONBOARDING_STAGES.map((stage) => [stage, false])));

export const createNewOnboardingStage = (stage: OnboardingStage, getState: () => RootState) => {
  const currentOnboardingStage = (getState() as RootState).organization.onboardingStage;
  return { ...currentOnboardingStage, [stage]: true };
};

export const updateOnboardingStage = (newOnboardingStage: OnboardingStageInterface) => {
  return updateOrganizationClaims({
    onboardingStage: newOnboardingStage,
  });
};
