import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";
import { InboundStepTitle, InboundStepDescription } from "inbounds/steps/InboundStepElements";

const ID_PREFIX = "inbounds.lotInformationHeader";

const FEFO_CONTENT = {
  TITLE: {
    id: `${ID_PREFIX}.fefoTitle`,
    defaultMessage: "Add Expiration Date",
  },
  DESCRIPTION: {
    id: `${ID_PREFIX}.fefoDescription`,
    defaultMessage:
      "Fulfill oldest expiration date first. Incorrect expiration dates will result in delays and additional fees. {learnMoreLink}",
  },
};

const LOT_TRACKING_CONTENT = {
  TITLE: {
    id: `${ID_PREFIX}.lotTrackingTitle`,
    defaultMessage: "Add Lot Tracking Details",
  },
  DESCRIPTION: {
    id: `${ID_PREFIX}.lotTrackingDescription`,
    defaultMessage: "Incorrect lot numbers will result in delays and additional fees. {learnMoreLink}",
  },
};

const GENERAL_CONTENT = {
  TITLE: {
    id: `${ID_PREFIX}.generalTitle`,
    defaultMessage: "Add Lot Tracking Details",
  },
  DESCRIPTION: {
    id: `${ID_PREFIX}.generalDescription`,
    defaultMessage:
      "Fulfill oldest expiration date first. Incorrect information will result in delays and additional fees. {learnMoreLink}",
  },
};

export const LotInformationHeader: FC<{
  hasFefoEnabledProducts: boolean;
  hasLotTrackingEnabledProducts: boolean;
}> = ({ hasFefoEnabledProducts, hasLotTrackingEnabledProducts }) => {
  const hasBoth = hasFefoEnabledProducts && hasLotTrackingEnabledProducts;
  const content = hasBoth ? GENERAL_CONTENT : hasFefoEnabledProducts ? FEFO_CONTENT : LOT_TRACKING_CONTENT;

  return (
    <>
      <InboundStepTitle>
        <FormattedMessage {...content.TITLE} />
      </InboundStepTitle>
      <InboundStepDescription appearance="INFO">
        <FormattedMessage
          {...content.DESCRIPTION}
          values={{
            learnMoreLink: <ElevioLearnMoreButton articleId={ElevioArticleId.LotTracking} />,
          }}
        />
      </InboundStepDescription>
    </>
  );
};
