import { StorageInboundResponse } from "@deliverr/storage-client";
import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";
import { Phase } from "common/components/Timeline/Timeline";
import { TimelineDate } from "common/date/DateFormat";
import { useIntl } from "react-intl";

export interface StorageInboundTimelineData {
  storageInbound: StorageInboundResponse;
}

export const useStorageInboundStatusHistory = ({ storageInbound }: StorageInboundTimelineData) => {
  const { formatMessage } = useIntl();

  const getPhases = (): Phase[] => [
    {
      status: InboundShipmentStatus.READY_FOR_WAREHOUSE,
      label: formatMessage({ id: "storage.inbound.statusHistory.created", defaultMessage: "Shipment created" }),
      timestamp: "storageInbound.createdAt",
      timeFormat: TimelineDate,
    },
    {
      status: InboundShipmentStatus.ARRIVED,
      label: formatMessage({ id: "storage.inbound.statusHistory.arrived", defaultMessage: "Arrived" }),
      timestamp: "storageInbound.arrivedAt",
      timeFormat: TimelineDate,
    },
    {
      status: InboundShipmentStatus.RECEIVING,
      label: formatMessage({
        id: "storage.inbound.statusHistory.receivingStarted",
        defaultMessage: "Receiving started",
      }),
      timestamp: "storageInbound.receivingBeganAt",
      timeFormat: TimelineDate,
    },
    {
      status: InboundShipmentStatus.COMPLETED,
      label: formatMessage({ id: "storage.inbound.statusHistory.completed", defaultMessage: "Receiving completed" }),
      timestamp: "storageInbound.completedAt",
      timeFormat: TimelineDate,
    },
  ];

  const calculateCurrentPhase = (data: StorageInboundTimelineData) => {
    const { storageInbound: inbound } = data;
    if (inbound.completedAt) {
      return InboundShipmentStatus.COMPLETED;
    } else if (inbound.receivingBeganAt) {
      return InboundShipmentStatus.RECEIVING;
    } else if (inbound.arrivedAt) {
      return InboundShipmentStatus.ARRIVED;
    } else if (inbound.createdAt) {
      return InboundShipmentStatus.READY_FOR_WAREHOUSE;
    }
    return "";
  };

  return {
    phases: getPhases(),
    calculateCurrentPhase,
    timelineData: { storageInbound },
  };
};
