import { SPThunkAction } from "common/ReduxUtils";
import { ParcelListActionTypes } from "parcel/screens/list/ParcelListActionTypes";
import { SearchResultsResponse } from "common/search/SearchService";

export const searchParcelSuccess = (
  { nbPages, page, nbHits, hitsPerPage }: SearchResultsResponse,
  hits
): SPThunkAction => (dispatch) =>
  dispatch({
    type: ParcelListActionTypes.SEARCH_PARCELS_SUCCESS,
    searchInfo: {
      totalPages: nbPages,
      currentPage: page,
      totalHits: nbHits,
      itemsPerPage: hitsPerPage,
    },
    currentPageItems: hits,
  });
