import React, { FC, useContext } from "react";

import { UnknownBarcodeCaseContext } from "../../context";
import { IdentifyProduct } from "inbounds/non-compliance/accordion-steps";

export const UnknownBarcodeIdentify: FC = () => {
  const { onProductSelect, foundProduct, onSubmit, isOnSubmitLoading, onContinue } =
    useContext(UnknownBarcodeCaseContext);

  return (
    <IdentifyProduct
      onProductSelect={onProductSelect}
      foundProduct={foundProduct}
      onContinue={onContinue}
      onSubmit={onSubmit}
      isOnSubmitLoading={isOnSubmitLoading}
    />
  );
};
