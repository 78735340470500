import React, { useEffect } from "react";
import { ThemeProvider } from "emotion-theming";
import { defaultTheme, mobileTheme } from "common/components/ui";
import { useMobile } from "common/hooks/useMobile";
import { useDispatch } from "react-redux";
import { UserActionTypes } from "common/user/UserActions";
// Issues caused by emotion v9.
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { css, Global } = require("@emotion/core");

export const DefaultThemeProvider = ({ children }) => {
  const dispatch = useDispatch();
  const isMobile = useMobile();
  useEffect(() => {
    dispatch({ type: UserActionTypes.SET_IS_MOBILE, isMobile });
  }, [isMobile, dispatch]);

  return (
    <>
      <Global
        styles={
          isMobile
            ? css`
                body {
                  font-size: ${mobileTheme.font.size.F3};
                }
              `
            : css``
        }
      />
      <ThemeProvider theme={isMobile ? mobileTheme : defaultTheme}>{children}</ThemeProvider>
    </>
  );
};
