import { SPThunkAction } from "common/ReduxUtils";
import { SubmitBookingRequest, SubmitBookingResponse } from "@deliverr/legacy-inbound-client";
import { inboundClient } from "Clients";
import { logError, logStart, logSuccess } from "Logger";
import { SmbBookingCreationTimedEvent } from "inbounds/createShipment/common/analytics/analytics.types";
import { selectBookingSubmitRequest } from "../selectors/selectBookingSubmitRequest";
import { getSellerId } from "common/user/UserSelectors";

export const updateBookingCheckout =
  (shippingPlanId: number, shouldSubmit: boolean): SPThunkAction<Promise<SubmitBookingResponse>> =>
  async (dispatch, getState) => {
    const state = getState();
    const sellerId = getSellerId(state);
    const bookingRequest: SubmitBookingRequest = selectBookingSubmitRequest()(state);
    const ctx = logStart({ fn: SmbBookingCreationTimedEvent.UpdateBooking, shippingPlanId, bookingRequest });

    try {
      let response;
      if (!shouldSubmit) {
        response = await inboundClient.bookingSaveFreight(sellerId, shippingPlanId, bookingRequest);
        logSuccess(ctx, "successfully saved checkout");
      } else {
        response = await inboundClient.submitBooking(sellerId, shippingPlanId, bookingRequest);
        logSuccess(ctx, "successfully submitted booking");
      }
      return response;
    } catch (error) {
      logError(ctx, error);
      throw error;
    }
  };
