import React, { FC } from "react";
import { DamageType } from "@deliverr/commons-clients/lib/non-compliance/damages/DamagedProductUnified";
import { FormattedMessage } from "react-intl";
import { ProblemType } from "common/problems/ProblemType";
import { PROBLEM_TYPE_LABEL_MAP } from "common/problems/problemTypeLabelMap";
import { DamagedProductProblemRow, ProblemRowData } from "../types";

export const ProblemTypeLabel: FC<{ problemRow: ProblemRowData }> = ({ problemRow }) => {
  const { problemType } = problemRow;

  if (
    problemType === ProblemType.DAMAGED_PRODUCT &&
    (problemRow as DamagedProductProblemRow).damageType === DamageType.EXPIRED
  ) {
    return <FormattedMessage id="inbounds.ProblemType.expiredProduct" defaultMessage="Expired units" />;
  }

  return PROBLEM_TYPE_LABEL_MAP[problemType];
};
