import { ThemeProps, Text } from "common/components/ui";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

const MessageContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: grid;
  place-items: start;
  text-align: left;
  color: ${theme.colors.NEUTRAL["300"]};

  & *:not(:last-of-type) {
    padding-bottom: ${theme.spacing.S3};
  }
`
);

export const CreatedSection: FC = () => (
  <MessageContainer>
    <Text>
      <FormattedMessage
        id="inbounds.shipmentProblemShortShipBlade.message"
        defaultMessage="Flexport detected a potential short-ship."
      />
    </Text>
  </MessageContainer>
);
