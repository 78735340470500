import classNames from "classnames/dedupe";
import { Box, Grid, Icon, IconV2, Stack, Text } from "common/components/ui";
import React, { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ProductCasePack } from "@deliverr/commons-clients/lib/product/ProductCasePack";
import styled from "@emotion/styled";
import cx from "classnames";
import { InputError } from "common/components/InputError";
import { ProductLink } from "common/components/ProductLink";
import { Appearance, ThemeProps } from "common/components/ui/shared";
import { isUndefined, values } from "lodash";
import { ProductCasePackSelect } from "../../ProductCasePackSelect";
import cls from "../ProductChooser.less";
import { useSelectedProduct } from "../useSelectedProduct";
import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";
import { useFieldArray } from "react-hook-form";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { LinkButton } from "../../LinkButton";
import { ControlledNumberInput } from "../../reactHookForm";
import { Divider } from "inbounds/createShipment/steps/CargoDetailsStep/CargoDetailsStep";
import { SelectedProductProps } from "../SelectedProduct";
import { PackageSummary } from "inbounds/createShipment/CreateShipmentTypes";
import { getSelectedProductCasePacks } from "storage/inbounds/create/store/selector/getSelectedCasePacks";
import { useSelector } from "react-redux";

const StyledBox = styled(Box)`
  padding: 12px 12px 12px 12px;
`;

const StyledUnitBox = styled(StyledBox)`
  align-self: center;
`;

const Container = styled(Box)<ThemeProps>(
  ({ theme }) => `
    border-left: solid ${theme.border.width.B1} ${theme.colors.NEUTRAL[60]};;
    margin: 0;
  `
);

const StyledStack = styled(Stack)`
  margin: 0;
`;

const StyledGrid = styled(Grid)`
  margin: 0;
`;

const mapPackageToCasePack = (pkg: PackageSummary): ProductCasePack => {
  return {
    dsku: pkg.casePackDsku ?? "",
    packOf: pkg.items?.[0].dsku,
    quantity: pkg.items?.[0].qty,
    weight: pkg.weight,
    height: pkg.height,
    length: pkg.length,
    width: pkg.width,
  };
};

export const DtcSingleSkuSelectedProductRow: FC<
  SelectedProductProps & {
    singleSkuPackage?: PackageSummary[];
    isLastRow?: boolean;
  }
> = (props) => {
  const { formatMessage } = useIntl();
  const {
    product,
    disabled,
    invalidDskus,
    onRemoveItemClick,
    useCasePack: shouldUseCasePack,
    showCasePackSelectionDropdown,
    onNewCasePackFormSubmit,
    shouldHideCasepackSingleUnitWarning,
    shouldShowBrandedPackagingAlert,
    singleSkuPackage,
    isLastRow,
  } = props;
  const selectedCasePack = useSelector(getSelectedProductCasePacks)[product.dsku];
  const [selectedRow, setSelectedRow] = useState<number | undefined>();
  const { caseQty, dsku, name: productName } = product;
  const { actualCaseQty, isBrandedPackaging, showExcessInventory } = useSelectedProduct(props);

  const errorMessageForProduct = props.ShowWarningForProductFn?.(product);

  const { setValue, control } = useCreateShipmentFormContext();
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: `${CreateShipmentInputName.SINGLE_SKU_PACKAGES}.${product.dsku}`,
    shouldUnregister: false,
  });

  useEffect(() => {
    if (singleSkuPackage && singleSkuPackage.length > 0) {
      setValue(`${CreateShipmentInputName.SINGLE_SKU_PACKAGES}.${product.dsku}`, singleSkuPackage!);
    } else {
      appendEmptyPackage();
    }
  }, []);

  const appendEmptyPackage = () => {
    append({
      casePackDsku: "",
      numberOfPkgs: 0,
      items: [
        {
          dsku,
          qty: 0,
        },
      ],
    });
  };

  useEffect(() => {
    if (!isUndefined(selectedRow)) {
      update(selectedRow, {
        ...fields[selectedRow],
        height: selectedCasePack.height,
        width: selectedCasePack.width,
        weight: selectedCasePack.weight,
        length: selectedCasePack.length,
        casePackDsku: selectedCasePack.dsku,
        items: [{ ...fields[selectedRow].items[0], qty: selectedCasePack.quantity, dsku: selectedCasePack.packOf }],
      });
    }
  }, [selectedCasePack]);

  return (
    <>
      <StyledStack>
        <StyledGrid columns="2fr 3fr" fullWidth>
          <StyledBox>
            <ProductLink product={product} shouldOpenInNewTab>
              <Text bold appearance="PRIMARY" as="div">
                {productName}
              </Text>
              <Text appearance="INFO" as="div">
                {product.msku}
              </Text>
            </ProductLink>
          </StyledBox>
          {!product.arePacksLoading && (
            <Container>
              {fields.map((row, key) => (
                <div key={row.id}>
                  <Grid columns="5fr 2fr 1fr 1fr" fullWidth key={row.id}>
                    <StyledBox marginTop={"S1"}>
                      <ProductCasePackSelect
                        product={product}
                        productCasePacks={values(product.productCasePackOptions).filter(
                          (casePack) =>
                            !singleSkuPackage
                              ?.map((pkg) => pkg.casePackDsku)
                              .filter((_, index) => index !== key)
                              .includes(casePack.dsku)
                        )}
                        selectedCasePack={mapPackageToCasePack(row)}
                        onSelectCasePack={(productCasePack) => {
                          update(key, {
                            ...row,
                            height: productCasePack.height,
                            width: productCasePack.width,
                            weight: productCasePack.weight,
                            length: productCasePack.length,
                            casePackDsku: productCasePack.dsku,
                            items: [{ ...row.items[0], qty: productCasePack.quantity, dsku: productCasePack.packOf }],
                          });
                        }}
                        onNewCasePackFormSubmit={(casePackData) => {
                          onNewCasePackFormSubmit?.(casePackData);
                          setSelectedRow(key);
                        }}
                        noMargin
                        isIntlDtc
                        includeNewCasePackOption
                      />
                    </StyledBox>
                    <StyledBox marginTop={"S1"}>
                      <ControlledNumberInput
                        step="1"
                        name={`${CreateShipmentInputName.SINGLE_SKU_PACKAGES}.${product.dsku}[${key}].numberOfPkgs`}
                        min={0}
                        defaultValue={row.numberOfPkgs}
                        data-testid={shouldUseCasePack ? "selected-product-case-count-input" : undefined}
                        data-sku={dsku}
                        inputSize="medium"
                      />
                    </StyledBox>
                    <StyledUnitBox marginBottom={"S1"}>
                      {(singleSkuPackage?.[key]?.numberOfPkgs ?? 0) * (singleSkuPackage?.[key]?.items?.[0].qty ?? 0)}
                    </StyledUnitBox>
                    <StyledUnitBox marginBottom={"S1"}>
                      <div className={cls.removeItemColumn}>
                        <div
                          className={classNames(cls.removeButton, {
                            [cls.disabledRemoveButton]: disabled,
                          })}
                          role="button"
                          aria-label={formatMessage({
                            id: "productChooser.selectedProduct.removeItem",
                            defaultMessage: "Remove Item",
                          })}
                          onClick={(e) => {
                            if (fields.length === 1) {
                              onRemoveItemClick?.(e);
                            } else {
                              remove(key);
                            }
                          }}
                          data-sku={dsku}
                        />
                      </div>
                    </StyledUnitBox>
                  </Grid>
                  <Divider />
                </div>
              ))}
              <StyledUnitBox marginBottom={isLastRow ? "S2" : null}>
                <LinkButton
                  onClick={() => {
                    appendEmptyPackage();
                  }}
                  bold
                >
                  <IconV2 type="plus" />
                  {formatMessage({
                    id: "inbounds.createShipment.steps.cargoDetailStep.dtc.productChooser.addNewCasePack",
                    defaultMessage: "Add another case pack",
                  })}
                </LinkButton>
              </StyledUnitBox>
            </Container>
          )}
        </StyledGrid>
      </StyledStack>

      {errorMessageForProduct?.showError && (
        <div className={cls.qtyWarningMessage}>
          <Icon className={cls.icon} type="exclamation-triangle" />
          {errorMessageForProduct.message}
        </div>
      )}

      {showExcessInventory && (
        <div className={cx(cls.qtyWarningMessage, "storage-recommendation-excess")}>
          <Icon className={cls.icon} type="exclamation-triangle" />
          <FormattedMessage
            id={"inbounds.selectedProduct.excessToEcomRecommendation"}
            defaultMessage={"Sending this quantity will give you more than 3 months of supply in ecom fulfillment."}
          />
        </div>
      )}

      {invalidDskus?.includes(dsku) && (
        <div className={cls.noDimsErrorContainer}>
          <Icon color="red" type="exclamation-circle" className={cls.noDimsExclamationIcon} />
          <span>
            <FormattedMessage
              id="NewOrder.noDimsMessage"
              defaultMessage="You cannot create an order for this SKU because DIMs and weights haven't yet been confirmed by Flexport."
            />
          </span>
        </div>
      )}

      {showCasePackSelectionDropdown &&
        !shouldHideCasepackSingleUnitWarning &&
        (actualCaseQty === 1 || caseQty === 1) && (
          <InputError
            isBold={false}
            appearance={Appearance.WARNING}
            message={
              <FormattedMessage
                id="inbounds.selectedProduct.casePackSingleUnitWarning"
                defaultMessage="Make sure you are entering correct units per box. Incorrect values may lead to issues and delays."
              />
            }
          />
        )}

      {shouldShowBrandedPackagingAlert && isBrandedPackaging && (
        <InputError
          isBold={false}
          appearance={Appearance.DANGER}
          message={
            <FormattedMessage
              id="inbounds.selectedProduct.brandedPackagingAlert"
              defaultMessage="Branded packaging cannot be added to bundles."
            />
          }
        />
      )}
    </>
  );
};
