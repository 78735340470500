import styled from "@emotion/styled";
import { BorderlessButton } from "./BorderlessButton";
import { DefaultTheme } from "./ui";

export const LinkButton = styled(BorderlessButton)<{ bold?: boolean }, DefaultTheme>(
  ({ bold, theme }) => `
    color: ${theme.colors[theme.config.colorByAppearance.PRIMARY][300]};
    font-weight: ${bold ? "bold" : "normal"};

    &:hover {
      color: #11bfe3;
    }
  `
);
