import { useSelector } from "react-redux";
import { getIsLoading } from "./LoadingSelectors";

type UseLoaderReturn = boolean;
type UseLoadersReturn = UseLoaderReturn[];

/**
 * Returns a boolean representing whether the specified loader is currently loading.
 * @param loaderId
 */
export const useLoader = (loaderId: string): UseLoaderReturn => useSelector(getIsLoading)(loaderId);

/**
 * Determine whether multiple loaders are currently loading.
 * @param loaderIds
 * @returns an array of booleans in the same order of provided loaderIds
 */
export const useLoaders = (...loaderIds: string[]): UseLoadersReturn => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return loaderIds.map((loaderId) => useLoader(loaderId));
};
