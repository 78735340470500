import React, { FC } from "react";
import ncProductSVG from "assets/img/icons/nc-product.svg";
import HtmlToReact from "common/components/HtmlToReact";
import styled from "@emotion/styled";
import { TooltipWithIcon } from "./icons/TooltipWithIcon";
import { FormattedMessage } from "react-intl";

const Container = styled.div`
  height: 20px;
  position: relative;

  &:hover {
    .icon-tooltip {
      display: inline-block;
    }
  }
`;

const NCProductIcon = styled(HtmlToReact)`
  height: 20px;

  svg {
    height: 20px;
  }
`;

export interface NCIconProps {
  message: string;
  tooltipWidth?: number;
}

export const NCIcon: FC<NCIconProps> = ({ message, tooltipWidth = 178 }) => {
  const TooltipText = styled.p`
    margin: 0;
    max-width: ${tooltipWidth};
  `;

  return (
    <TooltipWithIcon
      placement="top"
      message={
        <FormattedMessage id={"NCIcon"} defaultMessage={message}>
          {() => <TooltipText>{message}</TooltipText>}
        </FormattedMessage>
      }
    >
      <Container>
        <NCProductIcon html={ncProductSVG} />
      </Container>
    </TooltipWithIcon>
  );
};
