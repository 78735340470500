import { mustBeDefined } from "common/utils/mustBeDefined";
import { WishError } from "./WishError";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { mapError } from "../core/mapError";
import { FastTagActive } from "../fastTag/FastTagActive";
import { isAuthenticated } from "../core/authentication";
import { SkuLinks } from "../fastTag/SkuLinks";
import { WishSellerUpdate } from "./WishSeller/WishSellerUpdate";
import { WishSeller } from "./WishSeller/WishSeller";
import { CoverageApiResponse } from "../fastTag/CoverageApiResponse";
import { ServiceLevelCoverage } from "../fastTag/ServiceLevelCoverage";
import { FastTagClient } from "../fastTag/FastTagClient";

export class WishClient implements FastTagClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: ApiClientConfig = { baseURL: mustBeDefined(process.env.WISH_URL) }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1/`,
    });
  }

  async isFastTagEnabled(sellerId: string, dsku: string): Promise<boolean> {
    try {
      const { isActive } = await this.apiClient.get<FastTagActive>({
        url: `/product/seller/${sellerId}/dsku/${dsku}/active`,
        authentication: isAuthenticated,
      });

      return isActive;
    } catch (err) {
      throw mapError(err, [WishError.SELLER_NOT_FOUND]);
    }
  }

  async getLinksForDsku(sellerId: string, dsku: string): Promise<SkuLinks> {
    try {
      return await this.apiClient.get({
        url: `/product/seller/${sellerId}/dsku/${dsku}/links`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async updateLinkForDsku(sellerId: string, dsku: string, variationIdentifier: string): Promise<void> {
    try {
      await this.apiClient.put({
        url: `/product/seller/${sellerId}/dsku/${dsku}/variant`,
        body: {
          variationIdentifier,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [WishError.WISH_PRODUCT_IDENTIFIER_NOT_FOUND]);
    }
  }

  async updateSeller(sellerId: string, update: WishSellerUpdate): Promise<WishSeller> {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}`,
        body: update,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [WishError.SELLER_NOT_FOUND]);
    }
  }

  async getSeller(sellerId: string): Promise<WishSeller> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [WishError.SELLER_NOT_FOUND]);
    }
  }

  async authorizeSeller(sellerId: string, authorizationCode: string): Promise<void> {
    try {
      await this.apiClient.put({
        url: `/seller/${sellerId}/authorization`,
        body: { authorizationCode },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [WishError.ACCESS_TOKEN_ERROR, WishError.DUPLICATE_MERCHANT_ID, WishError.DUPLICATE_TOKEN]);
    }
  }

  async enableFastTag(sellerId: string, dsku: string): Promise<void> {
    try {
      await this.apiClient.put({
        url: `/product/seller/${sellerId}/fast-tag/enable`,
        body: [dsku],
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async disableFastTag(sellerId: string, dsku: string): Promise<void> {
    try {
      await this.apiClient.put({
        url: `/product/seller/${sellerId}/fast-tag/disable`,
        body: [dsku],
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [WishError.SELLER_NOT_FOUND, WishError.NO_WISH_EXPRESS_WAREHOUSE]);
    }
  }

  async getDskuStateCoverage(dsku: string): Promise<CoverageApiResponse> {
    try {
      return await this.apiClient.get({
        url: `/availability/dsku/${dsku}/coverage/twoday`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async getStateCoverageByServiceLevel(dsku: string): Promise<ServiceLevelCoverage> {
    const coverage = await this.getDskuStateCoverage(dsku);

    return { 2: coverage };
  }
}
