import React, { FC, useContext } from "react";
import { defaultTheme, Text, useMobile } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import { isNil } from "lodash/fp";

import { SimpleLoader } from "common/components/WithLoader/WithLoader";
import { MissingBarcodeCaseContext } from "../context";
import { ShippingPlanSummary } from "inbounds/components";
import {
  NonComplianceCard,
  NonComplianceCaseSummary,
  NonComplianceSection,
  NonComplianceTitle,
  TextWithBottomMargin,
  NonComplianceProductPhotoSection,
  NonComplianceFlowSteps,
  NonComplianceFooter,
  NonComplianceRadioTileGroup,
} from "inbounds/non-compliance/components";
import { NonComplianceDismissSection } from "inbounds/non-compliance/components/NonComplianceDismissSection";

export const MissingBarcodeCase: FC = () => {
  const {
    isRecognizedProduct,
    setIsRecognizedProduct,
    onDismiss,
    isOnDismissLoading,
    stepMetadata,
    missingBarcodeCase,
    isCaseLoading,
    shippingPlan,
  } = useContext(MissingBarcodeCaseContext);

  const isMobile = useMobile();

  if (isCaseLoading || isNil(missingBarcodeCase)) {
    return <SimpleLoader />;
  }

  return (
    <NonComplianceCard>
      <NonComplianceCaseSummary>
        <NonComplianceSection>
          <NonComplianceTitle displayAs="h4">
            <FormattedMessage id="nonCompliance.missingBarcode.title" defaultMessage="Identify Missing Barcode" />
          </NonComplianceTitle>
        </NonComplianceSection>
        <NonComplianceSection>
          <Text>
            <FormattedMessage
              id="nonCompliance.missingBarcode.description"
              defaultMessage="Our fulfillment centers were unable to receive this product because the barcode was missing, covered, or damaged."
            />
          </Text>
        </NonComplianceSection>
        {shippingPlan && (
          <NonComplianceSection>
            <ShippingPlanSummary shippingPlan={shippingPlan} />
          </NonComplianceSection>
        )}
        <NonComplianceSection>
          <TextWithBottomMargin bold margin={defaultTheme.spacing.S4}>
            <FormattedMessage id="nonCompliance.productPhotosTitle" defaultMessage="Product photos" />
          </TextWithBottomMargin>
          <NonComplianceProductPhotoSection photos={missingBarcodeCase.rawPhotoKeys} isSmall={isMobile} />
        </NonComplianceSection>
        <NonComplianceSection>
          <TextWithBottomMargin bold margin={defaultTheme.spacing.S2}>
            <FormattedMessage id="nonCompliance.identifyProductTitle" defaultMessage="Is this your product?" />
          </TextWithBottomMargin>
          <NonComplianceRadioTileGroup
            currentValue={isRecognizedProduct as any}
            direction="HORIZONTAL"
            onChange={setIsRecognizedProduct}
            options={
              [
                {
                  label: <FormattedMessage id="yes" defaultMessage="Yes" />,
                  value: true,
                },
                {
                  label: <FormattedMessage id="no" defaultMessage="No" />,
                  value: false,
                },
              ] as any
            }
          />
        </NonComplianceSection>
      </NonComplianceCaseSummary>
      {isRecognizedProduct ? (
        <NonComplianceFlowSteps {...stepMetadata} />
      ) : (
        <NonComplianceDismissSection onDismiss={onDismiss} isOnDismissLoading={isOnDismissLoading} />
      )}
      <NonComplianceFooter />
    </NonComplianceCard>
  );
};
