import { getFeatureSelector, FeatureName } from "common/Split";
import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { selectIpbFeatureOn } from "./selectIpbFeatureOn";

export const selectIpbDraftsFeatureOn = createSelector(
  selectIpbFeatureOn,
  (state: RootState) => getFeatureSelector(FeatureName.SmbIpbDrafts)(state),
  (ipbFeatureOn, ipbDraftsOn) => ipbFeatureOn && ipbDraftsOn
);
