import React, { FC } from "react";
import styled from "@emotion/styled";
import css from "@emotion/css";
import { DefaultTheme } from "common/components/ui";

const Table = styled.table`
  width: 100%;
`;

type Align = "left" | "right";

export const cardTableAlignment = css`
  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  &.left + &.right {
    width: 0.1%;
    white-space: nowrap;
  }

  &.right {
    text-align: right;

    & + .left {
      padding-left: 2.5rem;
    }
  }
`;

const TableCell = styled.td`
  line-height: 1.25rem;
  padding: 15px 1rem;
  max-width: 165px;
  min-width: 100px;
  ${cardTableAlignment};
`;

const TableHeader = styled.thead`
  border-bottom: 1px solid #d7dce6;
`;

const TableHeaderCell = styled.th`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 18px;
  padding: 1rem;
  ${cardTableAlignment}
`;

const TableDataRow = styled.tr<{ rowHover?: boolean }, DefaultTheme>(({ rowHover, theme }) =>
  !rowHover
    ? ""
    : `
      &:hover {
        background-color: ${theme.colors.NEUTRAL["40"]};
      }
    `
);

const TableFooter = styled.tfoot`
  border-top: 1px solid #edeff3;
`;

const TableFooterCell = styled.td`
  font-weight: 700;
  line-height: 17px;
  padding: 14px 1rem;
  ${cardTableAlignment}
`;

export interface CellObject {
  className: Align;
  content: React.ReactNode;
  testId?: string;
}
interface CardTableProps {
  className?: string;
  footerCells?: CellObject[];
  headerCells: CellObject[];
  rows: CellObject[][];
  rowHover?: boolean;
}

export const createCellObject = (content: React.ReactNode, className: Align = "left", testId?: string): CellObject => ({
  className,
  content,
  testId,
});

export const CardTable: FC<CardTableProps> = ({ className, footerCells, headerCells, rows, rowHover }) => {
  return (
    <Table className={className}>
      <TableHeader>
        <tr>
          {headerCells.map(({ className: headerCellClassName, content }, index) => (
            <TableHeaderCell key={index} className={headerCellClassName}>
              {content}
            </TableHeaderCell>
          ))}
        </tr>
      </TableHeader>
      <tbody>
        {rows.map((row, rowIndex) => (
          <TableDataRow key={`body-row${rowIndex}`} rowHover={rowHover}>
            {row.map(({ className: rowClassName, content, testId }, index) => (
              <TableCell key={`row${rowIndex}-col${index}`} className={rowClassName} data-testid={testId}>
                {content}
              </TableCell>
            ))}
          </TableDataRow>
        ))}
      </tbody>
      {footerCells && (
        <TableFooter>
          <tr>
            {footerCells.map(({ className: footerCellClassName, content }, index) => (
              <TableFooterCell key={`footer-cell-${index}`} className={footerCellClassName}>
                {content}
              </TableFooterCell>
            ))}
          </tr>
        </TableFooter>
      )}
    </Table>
  );
};
