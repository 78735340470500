import { transferCreateSetReplenishmentOrder } from "../TransferCreateActions";
import { populateReplenishmentOrderWithPacks } from "storage/packUtils/populateReplenishmentOrderWithPacks";
import { createReplenishmentOrder } from "./createReplenishmentOrder";
import { OrderResponse } from "@deliverr/replenishment-client";

export const createAndSetReplenishmentOrder = async (dispatch, submitDraft, createDraftOrderInChannel?: boolean) => {
  const replenishmentOrderResponse: OrderResponse = await dispatch(
    createReplenishmentOrder(submitDraft, createDraftOrderInChannel)
  );
  const replenishmentOrderWithPacks = await dispatch(populateReplenishmentOrderWithPacks(replenishmentOrderResponse));
  await dispatch(transferCreateSetReplenishmentOrder(replenishmentOrderWithPacks));
};
