import { LocalizedText } from "common/components/LocalizedText";
import { Content, DarkLocalizedText, Tile } from "../../components/ServiceSelector/ServiceSelector.styles";
import React from "react";
import { DefaultTheme, IconV2, Stack } from "common/components/ui";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import styled from "@emotion/styled";
import { CREDIT_CARD_FEE_PERCENT } from "inbounds/createShipment/steps/priceViewStep/chargesSummary/constants";
import { usePrimaryPaymentSource } from "billing/PrimaryPaymentSource/usePrimaryPaymentSource";

const ProcessingFeeLearnMoreButton = () => (
  <ElevioBorderlessButton articleId={ElevioArticleId.CreditCardFees} asLink>
    <FormattedMessage id="shipment.shippingSelection.payment.processingFee.learnMore" defaultMessage="Learn more" />
  </ElevioBorderlessButton>
);
const Icon = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(1.25rem + ${theme.spacing.S3});
    height: 1.25rem;
  `
);

export const ProcessingFeeInstruction = () => {
  const { hasCreditCardFee } = usePrimaryPaymentSource();
  return hasCreditCardFee ? (
    <Tile>
      <Content>
        <Stack gap="S1">
          <DarkLocalizedText
            bold
            as="div"
            message={{
              id: "shipment.shippingSelection.payment.processingFee.subtitle",
              defaultMessage: "{processingFeePercent}% processing fee",
              values: { processingFeePercent: CREDIT_CARD_FEE_PERCENT },
            }}
          />
          <LocalizedText
            message={{
              id: "shipment.shippingSelection.payment.processingFee.instruction",
              defaultMessage:
                "Credit card, and PayPal payments will incur a {processingFeePercent}% processing fee. You can avoid the fee by using Direct Debit. {learnMore}",
              values: { processingFeePercent: CREDIT_CARD_FEE_PERCENT, learnMore: <ProcessingFeeLearnMoreButton /> },
            }}
          />
        </Stack>
        <Icon>
          <IconV2 type="credit-card" />
        </Icon>
      </Content>
    </Tile>
  ) : null;
};
