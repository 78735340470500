import React from "react";
import { useIntl } from "react-intl";
import { ManagedStep } from "common/components/StepContainer";
import { noop } from "lodash/fp";
import { Box } from "common/components/ui";
import { SelectedProductsList } from "common/components/ProductChooser/SelectedProductList";
import { useSelector } from "react-redux";
import { getIsLoading } from "common/components/WithLoader/LoadingSelectors";
import { useSelectProductQuantityStep } from "./useSelectProductQuantityStep";
import { wholesaleCreateLabels } from "../wholesaleCreate.labels";
import { CreateWholesaleLoader } from "../CreateWholesaleLoader.types";
import { FeatureName, isFeatureOn } from "common/Split";

export const SelectProductQuantityStep = () => {
  const { formatMessage } = useIntl();
  const { selectedProductsQty, updateNumberOfCases, isNextDisabled, isLoading } = useSelectProductQuantityStep();
  const isLoadingOrder = useSelector(getIsLoading)(CreateWholesaleLoader.CreateWholesaleOrderLoader);
  const isMultiCasePackEnabled = isFeatureOn(FeatureName.StorageMultiCasePack);

  return (
    <ManagedStep
      title={formatMessage(wholesaleCreateLabels.steps.productQuantity.title)}
      subtitle={formatMessage(wholesaleCreateLabels.steps.productQuantity.subtitle)}
      isNextDisabled={isNextDisabled}
      isLoading={isLoading || isLoadingOrder}
    >
      <Box width="85%">
        <SelectedProductsList
          selectedProducts={selectedProductsQty}
          removeProduct={noop as any}
          updateQty={noop as any}
          updateNumberOfCases={updateNumberOfCases}
          canRemoveProduct={false}
          useCasePack
          isCaseQtyReadonly={true}
          isReserveStorage
          showOnlyPacks={isMultiCasePackEnabled}
          hasMaxValue
        />
      </Box>
    </ManagedStep>
  );
};
