import { ShipmentSteps } from "../CreateShipmentTypes";

export const supplyStepPathParams = (path: string, shippingPlanId: number | null): string =>
  path.replace("/:shippingPlanId?", shippingPlanId ? "/" + shippingPlanId.toString() : "");

// slices existing stack up to the provided step if found (navigating to a prev step), or pushes the step onto the stack
export const getNewStack = (stepStack: ShipmentSteps[], step: ShipmentSteps): ShipmentSteps[] => {
  const indexOfStep = stepStack.indexOf(step);
  if (indexOfStep === -1) {
    const newStack = [...stepStack, step];
    return newStack;
  }
  return stepStack.slice(0, indexOfStep + 1);
};
