import { SplitFactory } from "@splitsoftware/splitio";
import log, { logError } from "Logger";
import { getSplitConfig } from "./getSplitConfig";
import { clearSplitLocalStorageValues } from "./clearSplitLocalStorageValues";

if (!process.env.SPLIT_AUTHORIZATION_KEY) {
  logError({ fn: "splitClient" }, "SPLIT_AUTHORIZATION_KEY not defined");
}

let splitClient: SplitIO.IClient | undefined;

export const getSplitClient = () => splitClient;

export const destroySplit = async () => {
  if (splitClient) {
    await splitClient.destroy();
    splitClient = undefined;
    clearSplitLocalStorageValues();
  }
};

export const initSplitClient = async (sellerId: string) => {
  await destroySplit();

  const factory: SplitIO.ISDK = SplitFactory(getSplitConfig(sellerId));

  const client = factory.client();

  return await new Promise<void>((resolve) => {
    client.once(client.Event.SDK_READY, () => {
      splitClient = client;
      resolve();
    });

    client.once(client.Event.SDK_READY_FROM_CACHE, () => {
      splitClient = client;
      resolve();
    });

    client.once(client.Event.SDK_READY_TIMED_OUT, () => {
      log.warn({ fn: "useInitSplit", sellerId }, "Split timed out");
      resolve();
    });
  });
};
