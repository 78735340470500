import React, { FC } from "react";
import { SearchResultItem } from "./SearchResultItem";
import { FormattedMessage } from "react-intl";
import { ListItemHeading, SearchListItem, SearchListItemContent, StyledH5 } from "./GlobalSearchStyledComponents";
import { serviceIconMap, GlobalSearchResults, ServiceNames } from "../utils";
import { GlobalSearchListItemHeading } from "../labels";
import { DefaultTheme, IconV2 } from "common/components/ui";
import { useTheme } from "emotion-theming";

interface SearchResultListProps {
  searchResults: GlobalSearchResults;
}

export const SearchResultList: FC<SearchResultListProps> = ({ searchResults }) => {
  const theme = useTheme<DefaultTheme>();
  return (
    <>
      {Object.entries(searchResults).map(
        ([serviceName, results]: [ServiceNames, GlobalSearchResults[ServiceNames]]) => {
          return (
            <SearchListItem key={serviceName}>
              <ListItemHeading>
                <IconV2 type={serviceIconMap[serviceName]} color={theme.colors.NEUTRAL["300"]} />
                <StyledH5>
                  <FormattedMessage {...GlobalSearchListItemHeading[serviceName]} />
                </StyledH5>
              </ListItemHeading>
              <SearchListItemContent>
                {results?.map((result, index) => (
                  <SearchResultItem key={`${serviceName}-${index}`} serviceName={serviceName} result={result} />
                ))}
              </SearchListItemContent>
            </SearchListItem>
          );
        }
      )}
    </>
  );
};
