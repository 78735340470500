import React from "react";
import { DefaultTheme, ThemeProps } from "../../../../../common/components/ui/shared/theme/theme";
import { Box, Grid } from "common/components/ui";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { useCargoSkuSwitch } from "./useCargoSkuSwitch";

const StyledBox = styled(Box)<ThemeProps>(
  ({ theme }) => `
    border: 1px solid ${theme.colors.NEUTRAL[100]};
    width: 100%;
    height: 100%;
    border-radius: 2px;
  `
);

const StyledOption = styled(Box)<ThemeProps>`
  padding: 2px;
`;

export const OptionItemLabel = styled("label")<{ selected: boolean }, DefaultTheme>(
  ({ theme, selected }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: color 0.1s linear;
  cursor: pointer;
  font-size: 14px;
  color: ${selected ? theme.colors.NEUTRAL["00"] : theme.colors.NEUTRAL[500]};
  background-color: ${selected ? theme.colors.BLUE[300] : theme.colors.NEUTRAL["00"]};
  border-radius: 2px;
`
);

const StyledInput = styled.input<ThemeProps>`
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -1;
  position: absolute;
  pointer-events: none;
`;

export const CargoSkuSwitch = () => {
  const { isMixedSkuCargo, setIsMixedSku } = useCargoSkuSwitch();

  return (
    <StyledBox>
      <Grid columns="1fr 1fr" gap="S2">
        <StyledOption marginRight={null}>
          <OptionItemLabel selected={!isMixedSkuCargo ?? false} htmlFor="single-sku-option">
            <StyledInput
              type="radio"
              id="single-sku-option"
              name="single-sku-option"
              onChange={() => setIsMixedSku(false)}
              checked={!isMixedSkuCargo ?? false}
            />
            <Box padding="S1" marginLeft="S3" marginRight="S3">
              <FormattedMessage id="singleSkusPerBox" defaultMessage={"Single SKUs per box"} />
            </Box>
          </OptionItemLabel>
        </StyledOption>
        <StyledOption marginLeft={null}>
          <OptionItemLabel selected={isMixedSkuCargo ?? false} htmlFor="mixed-sku-option">
            <StyledInput
              type="radio"
              id="mixed-sku-option"
              name="mixed-sku-option"
              onChange={() => setIsMixedSku(true)}
              checked={isMixedSkuCargo ?? false}
            />
            <Box padding="S1" marginLeft="S3" marginRight="S3">
              <FormattedMessage id="mixedSkuPerBox" defaultMessage={"Mixed SKU per box"} />
            </Box>
          </OptionItemLabel>
        </StyledOption>
      </Grid>
    </StyledBox>
  );
};
