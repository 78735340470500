import React from "react";
import { batch } from "react-redux";
import { transferCreateSetShipmentValidationError } from "transfers/create/actions";
import { ShippingPartner, ShippingType } from "@deliverr/replenishment-client";
import { FormattedMessage } from "react-intl";

export const eachPickingOrderValidation = (dispatch) => {
  const defaultTransportValidationErrorState = {
    hasError: false,
    disabled: false,
    isPending: false,
    retryable: false,
    showMessage: (
      <FormattedMessage
        id="transfers.create.wholesale.eachPicking.validation"
        defaultMessage="Estimates will be available once the order is ready to be shipped"
      />
    ),
  };

  batch(() => {
    dispatch(
      transferCreateSetShipmentValidationError(
        ShippingPartner.DELIVERR,
        ShippingType.LTL,
        defaultTransportValidationErrorState
      )
    );
    dispatch(
      transferCreateSetShipmentValidationError(
        ShippingPartner.DELIVERR,
        ShippingType.PARCEL,
        defaultTransportValidationErrorState
      )
    );
  });
};
