import React, { useState, FC } from "react";
import { isEmpty } from "lodash/fp";
import { useMobile } from "common/components/ui";

import { ProblemsTable } from "common/components/ProblemsTable";
import { NonComplianceEmptyList } from "../components/NonComplianceEmptyList";
import { SimpleLoader } from "common/components/WithLoader/WithLoader";
import { NonComplianceListMobile } from "./NonComplianceListMobile";
import { NonComplianceListBlade } from "./blades";
import { useNonComplianceSpvList } from "./useNonComplianceSpvList";
import { RootState } from "RootReducer";
import { useSelector } from "react-redux";

const DEFAULT_PROBLEMS_PER_PAGE = 10;

export const NonComplianceSpvList: FC = () => {
  const pageSize =
    useSelector((state: RootState) => state.user.resultsPerPage.inboundProblemsList) ?? DEFAULT_PROBLEMS_PER_PAGE;
  const [currentPage, savePage] = useState(0);
  const { columns, rows, onRowClick, isLoading, activeCase, totalPages } = useNonComplianceSpvList(
    pageSize,
    currentPage
  );

  const onPageChange = (page: number) => {
    savePage(page);
  };

  const isMobile = useMobile();

  if (isLoading) {
    return <SimpleLoader />;
  } else if (!isEmpty(rows)) {
    return (
      <>
        <NonComplianceListBlade nonComplianceCase={activeCase} />
        {isMobile ? (
          <NonComplianceListMobile rows={rows} onRowClick={onRowClick} />
        ) : (
          <ProblemsTable
            columns={columns}
            rows={rows}
            onRowClick={onRowClick}
            hidePaginate={false}
            pagingInfo={{
              totalPages: totalPages ?? 0,
              currentPage,
            }}
            onPageChange={onPageChange}
          />
        )}
      </>
    );
  } else {
    return <NonComplianceEmptyList />;
  }
};
