export enum FreightQuotingActionTypes {
  SET_FREIGHT_QUOTING_BLADE_OPEN = "SET_FREIGHT_QUOTING_BLADE_OPEN",
  SET_FREIGHT_QUOTING_PICKUP_ADDRESS = "SET_FREIGHT_QUOTING_PICKUP_ADDRESS",
  SET_FREIGHT_QUOTING_PICKUP_ADDRESS_IS_DELIVERR_WAREHOUSE = "SET_FREIGHT_QUOTING_PICKUP_ADDRESS_IS_DELIVERR_WAREHOUSE",
  SET_FREIGHT_QUOTING_PICKUP_LOCATION_TYPE = "SET_FREIGHT_QUOTING_PICKUP_LOCATION_TYPE",
  SET_FREIGHT_QUOTING_PICKUP_LIFTGATE_REQUIRED = "SET_FREIGHT_QUOTING_PICKUP_LIFTGATE_REQUIRED",
  SET_FREIGHT_QUOTING_DELIVERY_ADDRESS = "SET_FREIGHT_QUOTING_DELIVERY_ADDRESS",
  SET_FREIGHT_QUOTING_DELIVERY_ADDRESS_IS_DELIVERR_WAREHOUSE = "SET_FREIGHT_QUOTING_DELIVERY_ADDRESS_IS_DELIVERR_WAREHOUSE",
  SET_FREIGHT_QUOTING_DELIVERY_LOCATION_TYPE = "SET_FREIGHT_QUOTING_DELIVERY_LOCATION_TYPE",
  SET_FREIGHT_QUOTING_DELIVERY_LIFTGATE_REQUIRED = "SET_FREIGHT_QUOTING_DELIVERY_LIFTGATE_REQUIRED",
  SET_FREIGHT_QUOTING_NUMBER_OF_PALLETS = "SET_FREIGHT_QUOTING_NUMBER_OF_PALLETS",
  SET_FREIGHT_QUOTING_QUOTE_REQUEST = "SET_FREIGHT_QUOTING_QUOTE_REQUEST",
  SET_FREIGHT_QUOTE = "SET_FREIGHT_QUOTE",
  LOAD_FREIGHT_QUOTE_REQUEST = "LOAD_FREIGHT_QUOTE_REQUEST",
  RESET_FREIGHT_QUOTE_REQUEST = "RESET_FREIGHT_QUOTE_REQUEST",
  RESET_FREIGHT_QUOTING_STATE = "RESET_FREIGHT_QUOTING_STATE",
}
