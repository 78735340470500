import { FreightTrackingInfo } from "@deliverr/commons-clients/lib/transportation/legacy-copy-pasta";
import {
  Asn,
  InboundPackage,
  InboundShipment,
  ShippingPlan,
  ShippingPlanDispersalMethod,
} from "@deliverr/legacy-inbound-client";
import { compact, flatten, groupBy, head, map, uniq, omitBy } from "lodash/fp";
import { getOffsetDateWeekdays } from "common/date/getOffsetDateWeekdays";
import { BASE_SHIPPING_DAY_OFFSET } from "../distribute/DistributeSelectConstants";
import { FreightShipmentInfo, FreightShipmentBaseInfo } from "inbounds/InboundTypes";

export const separateAsnsByCrossDock = (asns: Asn[]): { normalAsns: Asn[]; crossDockAsn?: Asn } => {
  const { true: crossDockAsns, false: normalAsns } = groupBy((asn) => Boolean(asn.isCrossDock), asns);
  return { normalAsns: normalAsns || [], crossDockAsn: head(crossDockAsns) };
};

export const getWarehouseIdsFromShipments = (shipments: InboundShipment[]): string[] => {
  return compact([
    ...shipments.map(({ warehouseId }) => warehouseId),
    shipments.length > 0 ? shipments[0].crossDockWarehouseId : undefined,
  ]);
};

export function getInboundWarehouseIds(
  shipments: InboundShipment[],
  packagesByShipment: Record<string, InboundPackage[]>
) {
  const shipmentWarehouseIds = getWarehouseIdsFromShipments(shipments);
  const packages = flatten(Object.values(packagesByShipment));
  const packageWarehouseIds = map("warehouseId", packages);
  const warehouseIds = shipmentWarehouseIds.concat(packageWarehouseIds);
  return uniq(warehouseIds);
}

export const getDispersalMethodFromFlag = (
  isShipToOne: boolean | undefined
): ShippingPlanDispersalMethod | undefined => {
  if (isShipToOne === undefined) {
    return undefined;
  }

  return isShipToOne ? ShippingPlanDispersalMethod.DISTRIBUTION : ShippingPlanDispersalMethod.DIRECT;
};

export const isShipToOneDispersalMethod = (
  dispersalMethod: ShippingPlanDispersalMethod | undefined
): boolean | undefined => {
  if (dispersalMethod === undefined) {
    return undefined;
  }

  return (
    dispersalMethod === ShippingPlanDispersalMethod.FORWARDING ||
    dispersalMethod === ShippingPlanDispersalMethod.DISTRIBUTION
  );
};

export const isShipToOnePlan = (shippingPlan: Partial<ShippingPlan>): boolean | undefined =>
  isShipToOneDispersalMethod(shippingPlan.dispersalMethod);

export const getDefaultFreightInfoEstimatedShipDate = () =>
  getOffsetDateWeekdays(BASE_SHIPPING_DAY_OFFSET.weekdays ?? 0);

export const extractFreightShipmentInfo = (
  info: FreightTrackingInfo | FreightShipmentInfo,
  cleanNullishValues: boolean = true
): FreightShipmentBaseInfo => {
  const { estimatedDeliveryDate, deliveredAt, estimatedShipDate, shippingProvider, bolId, proId } = info;
  const extractedInfo = { estimatedShipDate, estimatedDeliveryDate, deliveredAt, shippingProvider, bolId, proId };

  return cleanNullishValues ? { ...omitBy((val) => val == null, extractedInfo) } : extractedInfo;
};
