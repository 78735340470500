import { ParcelDimensions } from "@deliverr/parcel-client";

export const getDefaultParcelDimensions = (): ParcelDimensions => {
  return {
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
    lengthUnit: "in",
    weightUnit: "lb",
  };
};
