import { DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { ShipmentStatusBarProps } from "inbounds/steps/ship/ShipmentHeaderEta/ShipmentStatusBar";
import { FormattedNumber, FormattedPlural } from "react-intl";
import { ShipmentStatusSliceAttribute } from "./utils";
import { EmptySpace } from "common/components/EmptySpace";

interface ColorCellProps {
  color: string; // Ideally this would be Color from @deliverr/ui but the typing does not exist
}

const ColorCell: FC = styled.td<{}, DefaultTheme>(
  ({ theme }) => `
  width: ${theme.spacing.S2};
`
);

const ColorBlock: FC<ColorCellProps> = styled.div<ColorCellProps, DefaultTheme>(
  ({ color, theme }) => `
  background-color: ${color};
  width: ${theme.spacing.S2};
  height: ${theme.spacing.S2};
  border-radius: ${theme.border.radius.R2};
`
);

const LabelCell: FC = styled.td<{}, DefaultTheme>(
  ({ theme }) => `
  padding-left: ${theme.spacing.S1};
`
);

const UnitsCell: FC = styled.td<{}, DefaultTheme>(
  ({ theme }) => `
  text-align: right;
  padding-left: ${theme.spacing.S6};
`
);

const TooltipTable: FC = styled.table<{}, DefaultTheme>(
  ({ theme }) => `
  margin: ${theme.spacing.S2};
`
);

export const ShipmentEtaDetailsTooltip: FC<ShipmentStatusBarProps> = ({ slices }) => {
  return (
    <TooltipTable>
      {slices.map((slice: ShipmentStatusSliceAttribute) => {
        return (
          <tr key={`ShipmentEtaDetailsTooltip.${slice.label}`}>
            <ColorCell>
              <ColorBlock color={slice.color} />
            </ColorCell>
            <LabelCell>{slice.label}</LabelCell>
            <UnitsCell>
              <FormattedNumber value={slice.value} />
              <EmptySpace />
              <FormattedPlural value={slice.value} one="unit" other="units" />
            </UnitsCell>
          </tr>
        );
      })}
    </TooltipTable>
  );
};
