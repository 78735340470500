import { LoadingMessageProps } from "@deliverr/react-select-v1";
import { SelectComponents, ThemeProps } from "common/components/ui";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import React, { FC } from "react";
import styled from "@emotion/styled";

const Container = styled.div<ThemeProps>(
  ({ theme }) => `
    padding: ${theme.spacing.S5};
  `
);

export const SearchLoading: FC<LoadingMessageProps> = ({ children, ...props }) => (
  <SelectComponents.LoadingMessage {...props}>
    <Container>
      <LoadingSpinner center />
    </Container>
  </SelectComponents.LoadingMessage>
);
