import { createSelector } from "reselect";
import { getProductDetailsCache } from "../productDetailsCache";
import { BarcodeFormat, getBarcodeFormat } from "common/components/ui/utils/BarcodeUtils";
import { Product } from "@deliverr/commons-clients";
import { map } from "lodash/fp";

export const getShowBarcodeInput = createSelector(getProductDetailsCache, (productDetailsCache) => {
  const planItemDetails: Product[] = map(({ dsku }) => productDetailsCache[dsku], productDetailsCache);

  const hasNonDSKUBarcode = (product: Product) =>
    product.barcodes?.some((barcode) => getBarcodeFormat(barcode) !== BarcodeFormat.DSKU);

  const hasAllProductsNonDSKUBarCode = planItemDetails?.every((product) => hasNonDSKUBarcode(product));

  return !hasAllProductsNonDSKUBarCode;
});
