import React, { useEffect } from "react";
import { FieldError, useController } from "react-hook-form";
import { InlineNumberInput, InlineNumberInputProps, NumberInput } from "common/components/NumberInput";
import { useSimpleFieldValidators } from "common/hooks/reactHookForm/useSimpleFieldValidators";

export interface ControlledNumberInputProps extends InlineNumberInputProps {
  name: string;
  min?: number;
  max?: number;
  required?: boolean;
  validate?: Record<string, (value: number) => string | undefined>;
  inline?: boolean;
  onError?: (error: FieldError | undefined) => void;
  defaultValue?: number | undefined;
  onBlurAction?: () => void;
}

/**
 * Accesses the react-hook-form context and returns a controlled number input
 * Used for shipment totals.
 */

export const ControlledNumberInput = (props: ControlledNumberInputProps) => {
  const { name, label, unit, decimalScale, inputSize, disabled, min, max, onError, defaultValue, onBlurAction } = props;
  const { validateRequiredNumber, validateGreaterThan, validateLessThan } = useSimpleFieldValidators();
  const validatorFallback = () => undefined;
  const { field, fieldState } = useController({
    name,
    rules: {
      validate: {
        required: props.required ? validateRequiredNumber() : validatorFallback,
        greaterThan: min !== undefined ? validateGreaterThan(min) : validatorFallback,
        lessThan: max !== undefined ? validateLessThan(max) : validatorFallback,
        ...props.validate,
      },
    },
    defaultValue,
  });
  const { onChange, ref, onBlur, ...fieldProps } = field;
  const { error } = fieldState;
  const handleChange = ({ floatValue }) => {
    /** do not pass undefined to RHF, it will not be able to validate the field */
    onChange(floatValue ?? null);
  };
  const handleBlur = () => {
    if (onBlurAction) {
      onBlurAction();
    }
    onBlur();
  };

  useEffect(() => {
    onError?.(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const numberInputProps = {
    label,
    decimalScale,
    inputSize,
    getInputRef: ref,
    hasError: fieldState.invalid,
    placeholder: props.placeholder ?? "0",
    disabled,
    onValueChange: handleChange,
    onBlur: handleBlur,
  };

  return props.inline ? (
    <InlineNumberInput {...fieldProps} {...numberInputProps} unit={unit} />
  ) : (
    <NumberInput {...fieldProps} {...numberInputProps} />
  );
};
