import styled from "@emotion/styled";
import { DefaultTheme } from "../../shared";

export interface DividerProps extends React.HTMLAttributes<HTMLHRElement> {
  rotate?: boolean;
  length?: string;
}

export const Divider = styled.hr<DividerProps, DefaultTheme>`
  ${({ rotate, length = "100%", theme }) => `
    border: ${theme?.border.width.B1} ${theme?.border.type} ${theme.colors.NEUTRAL[100]};
    width: ${length};
    ${rotate ? "transform: rotate(90deg);" : ""}
  `}
`;
