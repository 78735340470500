import { OutboundChargeType } from "@deliverr/replenishment-client";
import { MessageDescriptor } from "react-intl";

export const feeDescriptionMessages: Record<OutboundChargeType, MessageDescriptor> = {
  [OutboundChargeType.PARCEL_OUTBOUND_HANDLING]: {
    id: "replenishment.quote.chargeType.parcel_outbound_handling",
    defaultMessage: "Case Handling",
  },
  [OutboundChargeType.PARCEL_SHIPMENT_LABELING]: {
    id: "replenishment.quote.chargeType.parcel_shipment_labeling",
    defaultMessage: "Parcel Shipment Labeling",
  },
  [OutboundChargeType.PARCEL_SHIPMENT_PROCESSING]: {
    id: "replenishment.quote.chargeType.parcel_shipment_processing",
    defaultMessage: "Parcel Shipment Processing",
  },
  [OutboundChargeType.PARCEL_CARTON_LABELLING]: {
    id: "replenishment.quote.chargeType.parcel_carton_labelling",
    defaultMessage: "Parcel Carton Labelling",
  },
  [OutboundChargeType.PALLET_OUTBOUND_HANDLING]: {
    id: "replenishment.quote.chargeType.pallet_outbound_handling",
    defaultMessage: "Pallet Outbound Handling",
  },
  [OutboundChargeType.PALLET_CARTON_LABELLING]: {
    id: "replenishment.quote.chargeType.pallet_carton_labelling",
    defaultMessage: "Pallet Carton Labelling",
  },
  [OutboundChargeType.PALLET_LABELING]: {
    id: "replenishment.quote.chargeType.pallet_labeling",
    defaultMessage: "Pallet Labeling",
  },
  [OutboundChargeType.PALLET_SHIPMENT_PROCESSING]: {
    id: "replenishment.quote.chargeType.pallet_shipment_processing",
    defaultMessage: "Pallet Shipment Processing",
  },
  [OutboundChargeType.PALLET_SHIPMENT_BOL]: {
    id: "replenishment.quote.chargeType.pallet_shipment_bol",
    defaultMessage: "Pallet Shipment Bol",
  },
  [OutboundChargeType.PALLET_STRETCH_WRAP]: {
    id: "replenishment.quote.chargeType.pallet_stretch_wrap",
    defaultMessage: "Pallet Stretch Wrap",
  },
  [OutboundChargeType.PALLET_SUPPLIED_PALLET]: {
    id: "replenishment.quote.chargeType.pallet_supplied_pallet",
    defaultMessage: "Pallet Supplied Pallet",
  },
  [OutboundChargeType.SHIPPING_FEE]: {
    id: "replenishment.quote.chargeType.pallet_supplied_pallet",
    defaultMessage: "Shipping Fee",
  },
  [OutboundChargeType.LABELING_FEE]: {
    id: "replenishment.quote.chargeType.labelingFee",
    defaultMessage: "Labeling Fee",
  },
  [OutboundChargeType.UNIT_PREP_FEE]: {
    id: "replenishment.quote.chargeType.unitPrepFee",
    defaultMessage: "Unit and shipping prep",
  },
  [OutboundChargeType.EACH_PICK_FEE]: {
    id: "replenishment.quote.chargeType.each_pick_fee",
    defaultMessage: "Each Pick Handling",
  },
  [OutboundChargeType.PREP_FEE]: {
    id: "replenishment.quote.chargeType.prepFee",
    defaultMessage: "Prep Fee (Includes labeling and handling)",
  },
};
