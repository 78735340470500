import classNames from "classnames/dedupe";
import React, { FC } from "react";
import { Modal as BSModal } from "react-bootstrap";
import { connect } from "react-redux";

import { hideModal } from "common/components/Modal/ModalActions";
import { ActionCreator } from "common/ReduxUtils";
import { RootState } from "RootReducer";

import cls from "./Modal.less";

interface ReduxProps {
  modals: { [modalId: string]: boolean };
}

interface DispatchProps {
  hideModal: ActionCreator;
}

export interface ModalProps {
  id: string;
  children: JSX.Element[] | JSX.Element;
  className?: string;
  backdrop?: "static";
  keyboard?: boolean;
  size?: string;
  hasCloseButton?: boolean;
  onEnter?: () => any;
  onClose?: () => any;
}

const Modal: FC<ModalProps> = (props: ReduxProps & DispatchProps & ModalProps) => {
  const onHide = () => {
    if (props.onClose) {
      props.onClose();
    }
    props.hideModal(props.id);
  };

  return (
    <BSModal
      className={classNames(cls.modal, props.className)}
      animation={true}
      backdrop={props.backdrop ? props.backdrop : true}
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      bsSize={props.size === "md" ? undefined : props.size || "sm"}
      show={props.modals[props.id]}
      keyboard={props.keyboard}
      onHide={onHide}
      onEntered={props.onEnter}
    >
      {props.hasCloseButton && (
        <button className={cls.closeButton} onClick={onHide}>
          <span aria-hidden="true">&times;</span>
        </button>
      )}
      {props.children}
    </BSModal>
  );
};

export default connect<ReduxProps, DispatchProps, ModalProps>((state: RootState) => ({ modals: state.modal.modals }), {
  hideModal,
})(Modal);
