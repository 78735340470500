import { reduce } from "lodash";
import { ATP } from "common/clients/inventory/ATP/ATP";
import { Mutable } from "common/utils/types";

export const aggregateATP = (atp1?: ATP, atp2?: ATP) => {
  const inventoryKeys: Exclude<keyof ATP, "poolName" | "details">[] = [
    "onHandQty",
    "availableToPurchase",
    "inTransferQty",
    "nonPickableQty",
    "pickableQty",
    "unavailableQty",
    "unfilledOrderQty",
  ];
  const aggregatedATP = reduce(
    inventoryKeys,
    (agg, key) => {
      agg[key] = (atp1?.[key] ?? 0) + (atp2?.[key] ?? 0);
      return agg;
    },
    {} as Mutable<ATP>
  );
  aggregatedATP.poolName = atp1?.poolName ?? atp2?.poolName ?? "";
  aggregatedATP.details = [...(atp1?.details ?? []), ...(atp2?.details ?? [])];
  return aggregatedATP;
};
