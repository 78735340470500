import styled from "@emotion/styled";
import React from "react";
import { Appearance, ThemeProps, DefaultTheme } from "../shared";

export type AnchorAppearance = Extract<keyof typeof Appearance, "DEFAULT" | "INFO">;

export interface AnchorProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  /** Appearance of the link */
  appearance?: AnchorAppearance;
}

const setColor = ({ appearance = Appearance.DEFAULT, theme }: ThemeProps<AnchorProps>) => {
  const color = theme.colors[theme.config.colorByAppearance[appearance]];
  return `
    color: ${color[300]};
    :active,
    :focus {
      outline: 0;
      color: ${color[400]};
    }
  `;
};

const StyledAnchor = styled.a<AnchorProps, DefaultTheme>(
  ({ appearance = Appearance.DEFAULT as AnchorAppearance, theme }) => `
  text-decoration: none;
  font-family: ${theme.font.family.STANDARD};
  font-size: ${theme.font.size.F2};
  font-weight: ${theme.font.weight.BOLD};
  ${setColor({ appearance, theme })}
`
);

export const Anchor: React.FC<AnchorProps> = ({
  target = "_blank",
  rel = "noopener noreferrer",
  children,
  ...props
}) => (
  <StyledAnchor target={target} rel={rel} {...props}>
    {children}
  </StyledAnchor>
);
