import { useEffect } from "react";
import { batch, useDispatch } from "react-redux";
import { clearProductCompliance, initBatteryCompliance, verifyProductCompliance } from "./ProductComplianceActions";

export default function useProductCompliance() {
  const dispatch = useDispatch();

  const verify = () => {
    batch(() => {
      dispatch(clearProductCompliance());
      dispatch(verifyProductCompliance());
      dispatch(initBatteryCompliance());
    });
  };

  useEffect(() => {
    verify();

    // componentDidUnmount
    return verify;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
