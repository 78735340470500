import { useState } from "react";
import { useInterval } from "react-use";

export const useConnectStart = () => {
  const [cursor, setCursor] = useState(0);

  useInterval(() => {
    setCursor((cursor + 1) % 2);
  }, 4000);

  return {
    cursor,
  };
};
