import { defineMessages, MessageDescriptor } from "react-intl";

export const shipmentContentsLabels: Record<string, MessageDescriptor> = defineMessages({
  title: {
    id: "transfers.shipment.contents.title",
    defaultMessage: "CONTENTS",
  },
  productName: {
    id: "transfers.shipment.contents.productName.label",
    defaultMessage: "Product",
  },
  units: {
    id: "transfers.shipment.contents.units.label",
    defaultMessage: "Units",
  },
  expectedUnits: {
    id: "transfers.shipment.contents.expectedUnits.label",
    defaultMessage: "Expected Units",
  },
  actualFulfilledUnits: {
    id: "transfers.shipment.contents.actualFulfilledUnits.label",
    defaultMessage: "Actual Fulfilled Units",
  },
});
