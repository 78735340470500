import { SPThunkAction } from "common/ReduxUtils";

import { freightApiAdapter } from "common/clients/instances";
import { addLocalTimeZoneOffset } from "common/date/addLocalTimeZoneOffset";
import { getSellerId } from "common/user/UserSelectors";
import { extractFreightShipmentInfo } from "inbounds/steps/ship/InboundUtils";
import log, { logStart } from "Logger";
import { setShipmentFreightInfo } from "./setShipmentFreightInfo";
import { FreightTrackingInfoExternalIdType } from "common/clients/transportation/FreightTrackingInfo/FreightTrackingInfoExternalIdType";

/**
 * Fetches the FreightTrackingInfo for the loaded shipment and updates it in the store.
 *
 * @param shipmentId  number: shipmentId associated with FreightTrackingInfo
 * @param updateStore boolean: flag for whether or not to update data in redux on fetch. Defaults false.
 * @returns boolean representing whether or not FreightTrackingInfo was retrieved successfully
 */
export const fetchFreightTrackingInfo =
  (shipmentId: number, updateStore = false): SPThunkAction<Promise<boolean>> =>
  async (dispatch, getState) => {
    const rootState = getState();

    const sellerId = getSellerId(rootState);
    const ctx = logStart({ fn: "fetchFreightTrackingInfo", sellerId, shipmentId });

    try {
      const fullFreightInfo = await freightApiAdapter.getFreightTrackingInfo(
        sellerId,
        String(shipmentId),
        FreightTrackingInfoExternalIdType.INBOUND_SHIPMENT
      );

      if (fullFreightInfo) {
        const updateInfo = {
          ...extractFreightShipmentInfo(fullFreightInfo),
          externalId: String(shipmentId),
          externalIdType: FreightTrackingInfoExternalIdType.INBOUND_SHIPMENT,
        };

        // Update redux store
        if (updateStore) {
          // These dates are stored as just a date string (assumed to be UTC)
          // Since the browser automatically converts the date to local TZ, we effectively
          // need to undo that conversion
          if (updateInfo.estimatedShipDate) {
            updateInfo.estimatedShipDate = addLocalTimeZoneOffset(updateInfo.estimatedShipDate);
          }

          if (updateInfo.estimatedDeliveryDate) {
            updateInfo.estimatedDeliveryDate = addLocalTimeZoneOffset(updateInfo.estimatedDeliveryDate);
          }

          if (updateInfo.deliveredAt) {
            updateInfo.deliveredAt = addLocalTimeZoneOffset(updateInfo.deliveredAt);
          }

          dispatch(
            setShipmentFreightInfo({
              ...updateInfo,
              saveSource: "FETCH",
            })
          );
        }
      }
      return !!fullFreightInfo;
    } catch (err) {
      log.warn(ctx, err);
      return false;
    }
  };
