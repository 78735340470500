import { ReducerDictionary, createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { DrayageDetailsState } from "freight/types/DrayageDetailsTypes";
import { drayageDetailsInitialState } from "./drayageDetailsInitialState";
import { DrayageDetailsActionTypes } from "../actions/DrayageDetailsActionTypes";
import { FreightBookingActionTypes } from "freight/store/freightBooking/actions/FreightBookingActionTypes";

const reducer: ReducerDictionary<DrayageDetailsState> = {
  ...handleSimpleReducerUpdates([
    DrayageDetailsActionTypes.SET_DELIVERY_ORDER_DOCUMENT_KEY,
    DrayageDetailsActionTypes.SET_MASTER_BOL_NUMBER,
    DrayageDetailsActionTypes.SET_COMMODITY_TRANSPORTED,
    DrayageDetailsActionTypes.SET_DELIVERY_ORDER_FILE_NAME,
    DrayageDetailsActionTypes.SET_CONTAINER_ID,
  ]),
  [FreightBookingActionTypes.RESET_FREIGHT_BOOKING_STATE]: () => drayageDetailsInitialState,
  [FreightBookingActionTypes.FREIGHT_BOOKING_COMPLETED]: () => drayageDetailsInitialState,
};

export const drayageDetailsReducer = createReducer<DrayageDetailsState>(drayageDetailsInitialState, reducer);
