import { IconV2 } from "common/components/ui";
import styled from "@emotion/styled";
import classNames from "classnames/dedupe";
import { RCTooltip } from "rc-tooltip";
import React, { ReactNodeArray } from "react";
import { TooltipWithIcon } from "./TooltipWithIcon";

import cls from "./InfoIcon.less";

// otherwise tooltip will render as one long message
export const MessageContainer = styled.div<{ messageWidth?: number }>(
  ({ messageWidth }) => `
  max-width: ${messageWidth ?? 224}px;
`
);

export type InfoIconProps = {
  message: JSX.Element | ReactNodeArray | string;
  tooltipProps?: Exclude<Partial<RCTooltip.Props>, "placement"> & { className?: string };
  className?: string;
  wrap?: boolean;
  size?: "small" | "medium" | "large";
  alignTop?: boolean;
  messageWidth?: number;
} & Pick<RCTooltip.Props, "placement">;

export class InfoIcon extends React.Component<InfoIconProps, {}> {
  public render() {
    return (
      <TooltipWithIcon
        placement={this.props.placement}
        message={
          typeof this.props.message === "string" || this.props.wrap ? (
            <MessageContainer messageWidth={this.props.messageWidth}>{this.props.message}</MessageContainer>
          ) : (
            this.props.message
          )
        }
        tooltipProps={this.props.tooltipProps}
      >
        <IconV2
          type="info-circle"
          className={classNames(cls.infoIcon, this.props.className, { [cls.alignTop]: this.props.alignTop })}
          size={this.props.size}
        />
      </TooltipWithIcon>
    );
  }
}
