import { wholesaleClient } from "Clients";
import {
  WholesaleShippingMethod,
  WholesaleShipmentPatchDTO,
  WholesaleShipmentAttributeResponse,
} from "@deliverr/wholesale-client";
import { logError } from "Logger";
import { selectTransferCreate } from "../store/TransferCreateSelectors";
import { transferCreateSetError, transferCreateSetWholesaleOrder } from "./TransferCreateActions";
import { SPThunkAction } from "../../../common/ReduxUtils";
import { addLoader, clearLoader } from "../../../common/components/WithLoader/LoadingActions";
import { CreateTransferLoader } from "../CreateTransferLoader.types";
import { batch } from "react-redux";
import { loadAndDispatchWholesaleOrder } from "common/wholesale-common/loadAndDispatchWholesaleOrder";
import { TransferCreationTypes } from "../transferCreationTypes";
import { WholesaleErrorType } from "../../../common/wholesale-common/utils/WholesaleErrorTypes";

export const patchWholesaleOrder = async (dispatch, getState): Promise<boolean> => {
  const loadAndDispatch = loadAndDispatchWholesaleOrder({
    dispatch,
    setValue: transferCreateSetWholesaleOrder,
    loaderId: CreateTransferLoader.CreateTransferOrderLoader,
  });

  const { wholesaleOrder } = selectTransferCreate(getState());
  if (!wholesaleOrder?.id) {
    return false;
  }
  batch(() => {
    dispatch(addLoader(CreateTransferLoader.CreateTransferOrderLoader));
    dispatch(transferCreateSetError(WholesaleErrorType.NONE));
  });

  try {
    await patchAllShipmentsOfWholesaleOrder(getState);
    await loadAndDispatch(wholesaleOrder.id);
    return true;
  } catch (err) {
    batch(() => {
      dispatch(clearLoader(CreateTransferLoader.CreateTransferOrderLoader));
      dispatch(transferCreateSetError(WholesaleErrorType.UNTYPED));
    });
    logError({ fn: "createOrUpdateWholesaleOrder" }, err);
    return false;
  }
};

export const updateExternalAttributesIfNeeded = (
  shipmentInstruction,
  fbaShipmentId,
  fbaReferenceId
): WholesaleShipmentAttributeResponse[] | undefined => {
  const attributesList = shipmentInstruction.externalAttributes ?? [];
  const attributesMap: Map<string, string> = attributesList.reduce((map, attribute) => {
    map.set(attribute.attributeName, attribute.attributeValue);
    return map;
  }, new Map<string, string>());

  let wasChanged = false;
  if (fbaShipmentId && attributesMap.get("shipmentId") !== fbaShipmentId) {
    wasChanged = true;
    attributesMap.set("shipmentId", fbaShipmentId);
  }
  if (fbaReferenceId && attributesMap.get("referenceId") !== fbaReferenceId) {
    wasChanged = true;
    attributesMap.set("referenceId", fbaReferenceId);
  }
  if (!wasChanged) {
    return undefined;
  }
  const newAttrs = [] as WholesaleShipmentAttributeResponse[];
  for (const [attributeName, attributeValue] of attributesMap) {
    newAttrs.push({ attributeName, attributeValue });
  }
  return newAttrs;
};

const patchAllShipmentsOfWholesaleOrder = async (getState): Promise<boolean> => {
  const {
    wholesaleOrder,
    shippingMethod,
    cargoType,
    fbaShipmentId,
    fbaReferenceId,
    destinationType,
  } = selectTransferCreate(getState());
  if (!wholesaleOrder?.shipments || !wholesaleOrder.shipments.length) {
    return false;
  }
  for (const shipmentInstruction of wholesaleOrder.shipments) {
    let patchRequest: WholesaleShipmentPatchDTO = {
      shipmentType: cargoType,
      shippingMethod: shippingMethod ?? WholesaleShippingMethod.DELIVERR,
    };

    // safety mechanism: we should never update fbsShipmentId for integrated FBA since it is set by Amazon
    const fbaShipmentIdForPatch = destinationType === TransferCreationTypes.FbaIntegration ? undefined : fbaShipmentId;
    patchRequest = {
      ...patchRequest,
      externalAttributes: updateExternalAttributesIfNeeded(shipmentInstruction, fbaShipmentIdForPatch, fbaReferenceId),
    };
    await wholesaleClient.patchShipment(shipmentInstruction.wholesaleOrderId, shipmentInstruction.id, patchRequest);
  }
  return true;
};

export const patchWholesaleOrderAction = (): SPThunkAction => async (dispatch, getState) => {
  await patchWholesaleOrder(dispatch, getState);
};
