import React from "react";
import { FormattedMessage } from "react-intl";
import { transferCreateSetShipmentValidationError } from "transfers/create/actions";
import { ShippingPartner, ShippingType } from "@deliverr/replenishment-client";
import { replenishmentShipmentCreateLabels } from "../../common/replenishment/replenishmentShipmentCreate.labels";
import { isInternationalDestination } from "../../common/utils/isInternationalDestination";

export const shipmentInternationalTransportFBAValidation = (dispatch, country?: string) => {
  const labels = replenishmentShipmentCreateLabels.steps.shipping.cargoOptions;

  const defaultTransportValidationErrorState = {
    hasError: true,
    isPending: false,
    disabled: true,
    retryable: false,
  };

  if (isInternationalDestination(country)) {
    dispatch(
      transferCreateSetShipmentValidationError(ShippingPartner.DELIVERR, ShippingType.PARCEL, {
        ...defaultTransportValidationErrorState,
        errorMessage: <FormattedMessage {...labels.parcel.internationaShippingNotSupported} />,
      })
    );
    dispatch(
      transferCreateSetShipmentValidationError(ShippingPartner.DELIVERR, ShippingType.LTL, {
        ...defaultTransportValidationErrorState,
        errorMessage: <FormattedMessage {...labels.pallet.internationaShippingNotSupported} />,
      })
    );
  }
};
