import { RootState } from "RootReducer";
import { FeatureName, isFeatureOn } from "common/Split";
import { PlannedShipment } from "inbounds/InboundTypes";
import { selectHasPrep } from "prep/store/selectors/selectHasPrep";
import { getLoadedPlannedShipment } from "inbounds/steps/InboundStepSelectors";
import { getIsFreightExternal } from "inbounds/utils/shippingMethodUtils";
import { createSelector } from "reselect";
import { selectIsForwardingPlan } from "../plan/selectIsForwardingPlan";
import { selectFloorLoadedContainerDetails } from "prep/store/selectors/selectFloorLoadedContainerDetails";
import { FloorLoadedContainerDetail } from "@deliverr/prep-service-client";

export const selectCanDownloadPrepPalletLabels = createSelector<
  RootState,
  boolean,
  PlannedShipment,
  boolean,
  FloorLoadedContainerDetail | undefined,
  boolean
>(
  selectIsForwardingPlan,
  getLoadedPlannedShipment,
  selectHasPrep,
  selectFloorLoadedContainerDetails,
  (isForwarding, { shippingMethod }, hasPrep, flcDetails) => {
    return (
      isForwarding &&
      getIsFreightExternal(shippingMethod) &&
      Boolean(hasPrep || flcDetails) &&
      isFeatureOn(FeatureName.PrepPalletLabelsEnabled)
    );
  }
);
