import React, { FC } from "react";

import { UnexpectedBarcodeProblemRow } from "common/components/ProblemsTable/types";
import { SHIPMENT_PROBLEMS_UNEXPECTED_BARCODE_BLADE_ID } from "../blades";
import { UnexpectedBarcodeBlade } from "common/components/ProblemBlade/UnexpectedBarcodeBlade";
import { useShipmentProblemUnexpectedBarcodeBlade } from "./useShipmentProblemUnexpectedBarcodeBlade";
import { SelectedShipmentProblem } from "../../context";

export interface ShipmentProblemUnexpectedBarcodeBladeProps {
  shipmentProblem: UnexpectedBarcodeProblemRow;
  hideShipmentProblemBlade: () => void;
  setSelectedProblem: (problem: SelectedShipmentProblem | undefined) => void;
  isShipmentProblemBladeOpen: boolean;
}

export const ShipmentProblemUnexpectedBarcodeBlade: FC<ShipmentProblemUnexpectedBarcodeBladeProps> = ({
  shipmentProblem,
  hideShipmentProblemBlade,
  setSelectedProblem,
  isShipmentProblemBladeOpen,
}) => {
  const { resetBladeState, shippingPlanId, shippingPlanName, mappedSkus } = useShipmentProblemUnexpectedBarcodeBlade(
    shipmentProblem,
    hideShipmentProblemBlade,
    setSelectedProblem
  );

  return (
    <UnexpectedBarcodeBlade
      id={SHIPMENT_PROBLEMS_UNEXPECTED_BARCODE_BLADE_ID}
      shipmentProblem={shipmentProblem}
      onShippingPlanClick={resetBladeState}
      shippingPlanId={shippingPlanId}
      shippingPlanName={shippingPlanName}
      onCloseClick={resetBladeState}
      isBladeOpen={isShipmentProblemBladeOpen}
      product={mappedSkus[0]}
    />
  );
};
