import React from "react";

import cls from "./Content.less";

interface OwnProps {
  title?: React.ReactNode;
  body?: React.ReactNode;
  before?: React.ReactNode;
  after?: React.ReactNode;
  startAlign?: boolean;
  className?: string;
}

const Content = (props: OwnProps) => {
  return (
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    <div className={`${cls.content} ${props.startAlign ? cls.start : cls.center} ${props.className}`}>
      {props.before}
      <h5 className={cls.title}>{props.title}</h5>
      <div className={cls.body}>{props.body}</div>
      {props.after}
    </div>
  );
};

export default Content;
