import { toast } from "common/components/ui";
import {
  ShippingPlanItemsError,
  SHIPPING_PLAN_ITEMS_ERROR_MESSAGES,
} from "inbounds/store/actions/shippingPlan/updateShippingPlanItems/shippingPlanItemsErrorMessages";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import React from "react";
import { DeliverrError } from "@deliverr/errors";
import {
  BOX_MEASUREMENT_LIMITS,
  OVERSIZED_RELAXED_MEASUREMENT_LIMITS,
  ProductMeasurements,
} from "common/constants/inbounds/inbounds.const";
export const PRODUCT_OUTSIDE_GLOBAL_DIMS = "PRODUCT_OUTSIDE_GLOBAL_DIMS";

const getFormattedErrorMessageForOversizedProduct = (
  messageDescriptor: MessageDescriptor,
  reasonCode: string,
  msku: string,
  dimensionsLimit: ProductMeasurements
) => {
  const { weight, length, height, width } = dimensionsLimit;
  return reasonCode === ShippingPlanItemsError.PRODUCT_OVER_WEIGHT_LIMIT ? (
    <FormattedMessage key={`${msku}-error`} {...messageDescriptor} values={{ msku, weight }} />
  ) : (
    <FormattedMessage key={`${msku}-error`} {...messageDescriptor} values={{ msku, length, width, height }} />
  );
};

export const handleShippingPlanItemsError = (err: DeliverrError, isRestrictionsRelaxed: boolean) => {
  const TOAST_OPTIONS = {
    autoClose: 5000,
    toastId: "shippingPlanItemsError",
  };

  try {
    const invalidProductsMskuWithReason = JSON.parse(err.payload);

    if (err.subcode === PRODUCT_OUTSIDE_GLOBAL_DIMS && invalidProductsMskuWithReason?.length) {
      const errorMessages = invalidProductsMskuWithReason.map(([msku, reasonCode]) => {
        const dimensionsLimit = isRestrictionsRelaxed ? OVERSIZED_RELAXED_MEASUREMENT_LIMITS : BOX_MEASUREMENT_LIMITS;
        const messageDescriptor = SHIPPING_PLAN_ITEMS_ERROR_MESSAGES[reasonCode];
        return getFormattedErrorMessageForOversizedProduct(messageDescriptor, reasonCode, msku, dimensionsLimit);
      });
      toast.error(errorMessages, TOAST_OPTIONS);
    } else {
      toast.error(<FormattedMessage {...SHIPPING_PLAN_ITEMS_ERROR_MESSAGES.UNKNOWN} />, TOAST_OPTIONS);
    }
  } catch (error) {
    toast.error(<FormattedMessage {...SHIPPING_PLAN_ITEMS_ERROR_MESSAGES.UNKNOWN} />, TOAST_OPTIONS);
  }
};
