export type AuthStatus = "authorization_expired" | "connected" | "not_authorized" | "unknown";

export interface Credentials {
  accessToken: string;
  expiresIn: number | "never";
  signedRequest: string;
  userID: string;
  grantedScopes?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  reauthorize_required_in?: number;
}

export interface Auth {
  authResponse: Credentials | null;
  status: AuthStatus;
}

export interface LoginOptions {
  scope: string;
}

export enum LoginStatus {
  LoggedIn = "LoggedIn",
  LoggedOut = "LoggedOut",
  Unknown = "Unknown",
}
export interface UserIsLoggedIn {
  authResponse: Credentials;
  status: "connected";
}

export interface UserIsLoggedOut {
  authResponse: null;
  status: "unknown";
}

export function isLoginSuccessful(response: Auth): response is UserIsLoggedIn {
  return response.status === "connected";
}

export function isLoggedOut(response: Auth): response is UserIsLoggedOut {
  return response.status === "unknown";
}

export const FastTagsLoginOptions = {
  scope: "ads_management, business_management, catalog_management, pages_read_engagement",
};

export const getAuthCredentials = ({ authResponse: { userID, accessToken } }: UserIsLoggedIn) => ({
  userId: userID,
  accessToken,
});
