import React from "react";
import styled from "@emotion/styled";
import { DefaultTheme, ThemeProps } from "../shared/theme";
import { transitionFast } from "../shared/helpers/css";
import { isUndefined } from "lodash";

export interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Label for the Radio */
  label?: string | JSX.Element;
  /** Label styles */
  labelStyles?: string;
  /** Description for the Radio label */
  description?: string | JSX.Element;
}

const StyledRadio = styled.input<RadioProps, DefaultTheme>(({ theme }) => {
  const checkDim = "8px";
  return `
    appearance: none;
    cursor: pointer;
    position: relative;
    background-color: ${theme.colors.NEUTRAL["00"]};
    border: ${theme.border.width.B1} ${theme.border.type};
    border-color: ${theme.colors.NEUTRAL[100]};
    border-radius: 50%;
    width: ${theme.input.selectionHeight};
    height: ${theme.input.selectionHeight};
    font-size: ${theme.font.size.F3};
    outline: 0;
    margin: 0;
    ${transitionFast()}
    :focus {
      box-shadow: ${theme.input.focus.BLUE};
    }
    :disabled {
      background-color: ${theme.colors.NEUTRAL[40]};
      border-color: ${theme.colors.NEUTRAL[100]};
      cursor: not-allowed;
      :hover {
        background-color: ${theme.colors.NEUTRAL[40]};
      }
    }
    :hover {
      background-color: ${theme.colors.NEUTRAL[40]};
    }
    :checked {
      border-color: ${theme.colors.BLUE[300]};
      background-color: ${theme.colors.NEUTRAL["00"]};
      :disabled {
        background-color: ${theme.colors.NEUTRAL["00"]};
        border-color: ${theme.colors.NEUTRAL[100]};
        :hover {
          background-color: ${theme.colors.NEUTRAL["00"]};
          border-color: ${theme.colors.NEUTRAL[100]};
          :after {
            background-color: ${theme.colors.NEUTRAL[100]};
          }
        }
        :after {
          background-color: ${theme.colors.NEUTRAL[100]};
        }
      }
      :hover {
        border-color: ${theme.colors.BLUE[400]};
        :after {
          background-color: ${theme.colors.BLUE[400]};
        }
      }
      :after {
        content: '';
        background-color: ${theme.colors.NEUTRAL["00"]};
        z-index: 1;
        position: absolute;
        width: ${checkDim};
        height: ${checkDim};
        background-color: ${theme.colors.BLUE[300]};
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  `;
});

const Label = styled.label<{ disabled?: boolean; labelStyles?: string }, DefaultTheme>(
  ({ disabled, labelStyles, theme }) => {
    return `
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: ${theme.spacing.S2};
    cursor: ${disabled ? `not-allowed` : `pointer`};
    font-weight: ${theme.font.weight.REGULAR};
    color: ${theme.colors.NEUTRAL[300]};
    ${labelStyles}
  `;
  }
);

const Description = styled.div<ThemeProps>(
  ({ theme }) => `
    padding-left: ${theme.spacing.S5};
    color: ${theme.colors.NEUTRAL["300"]}
  `
);

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const ele = <StyledRadio type="radio" {...props} ref={ref} />;

  if (props.label) {
    return (
      <>
        <Label disabled={props.disabled} labelStyles={props.labelStyles}>
          {ele}
          <span>{props.label}</span>
        </Label>
        {!isUndefined(props.description) && <Description>{props.description}</Description>}
      </>
    );
  }
  return ele;
});
