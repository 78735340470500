import { parse } from "qs";
import { isString, toNumber } from "lodash/fp";

/**
 * Parses the query parameters from the location object, specifically the 'page' and 'search' parameters.
 *
 * @param location - The location object from react-router-dom.
 * @returns An object containing the parsed 'formattedPage' as a number and 'search' as a string or undefined.
 */
export const getQueryParams = (location): { formattedPage?: number; search?: string } => {
  if (!location.search) {
    return {};
  }
  const { page = undefined, search = undefined } = parse(location.search.slice(1));
  const formattedPage = page && (isString(page) ? toNumber(page) : undefined);

  return { formattedPage, search };
};
