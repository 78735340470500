import { DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import { sumBy } from "lodash/fp";
import React, { FC } from "react";
import { ShipmentStatusSliceAttribute } from "./utils";

const BarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Bar = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
  border-radius: ${theme.border.radius.R4};
  height: 8px;
  max-width: 380px;
  width: 100%;
`
);

interface StatusProps {
  width: string;
  color: string; // Ideally this would be Color from @deliverr/ui but the typing does not exist
}

const InnerBar = styled.div<StatusProps, DefaultTheme>(
  ({ width, color, theme }) => `
  height: 100%;
  width: ${width};
  border-radius: ${theme.border.radius.R4};
  background-color: ${color};
`
);

export interface ShipmentStatusBarProps {
  slices: ShipmentStatusSliceAttribute[];
}

export const ShipmentStatusBar: FC<ShipmentStatusBarProps> = ({ slices }) => {
  const totalValue: number = sumBy("value", slices);

  const getInnerBars = (
    sliceAttributes: ShipmentStatusSliceAttribute[],
    index: number,
    total: number,
    accum: number
  ) => {
    if (index < 0) {
      return null;
    }

    const width: string = `${Math.floor((accum * 100) / total)}%`;

    return (
      <InnerBar
        width={width}
        color={sliceAttributes[index].color}
        aria-label={sliceAttributes[index].label + " " + sliceAttributes[index].value.toString()}
      >
        {getInnerBars(sliceAttributes, index - 1, total, accum - sliceAttributes[index].value)}
      </InnerBar>
    );
  };

  const ariaLabel = slices.map((slice) => slice.label + " " + slice.value.toString()).toString();

  return (
    <BarContainer aria-label={ariaLabel}>
      <Bar>{getInnerBars(slices, slices.length - 1, totalValue, totalValue)}</Bar>
    </BarContainer>
  );
};
