import { InvalidOrderItemResolution } from "@deliverr/replenishment-client";
import { FeatureName, useFeatureOn } from "common/Split";
import { SelectedProductData } from "common/components/ProductChooser";
import { compact, isEmpty, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";

export const useUnknownItemResolution = ({ setSelectedProduct, setResolution }) => {
  const isMultiCasePackEnabled = useFeatureOn(FeatureName.StorageMultiCasePack);
  const dispatch = useDispatch();

  const { replenishmentOrder, unifiedProductResponseCache } = useSelector(selectTransferCreate);

  const addProduct = (dsku: string, currentSelectedProduct: SelectedProductData): void => {
    setSelectedProduct(currentSelectedProduct);
    setResolution(InvalidOrderItemResolution.MODIFIED);
  };

  const orderItemProductSkus = map(replenishmentOrder?.orderItems, (item) => item.packOf);
  const invalidOrderItemProductSkus = map(replenishmentOrder?.invalidOrderItems, (item) =>
    // requestedPackOf is being set to empty string in backend
    !isEmpty(item.requestedPackOf)
      ? item.requestedPackOf
      : item.resolvedDsku
      ? unifiedProductResponseCache?.[item.resolvedDsku]?.packOf
      : undefined
  );

  const alreadySelectedProductDskus = compact([...orderItemProductSkus, ...invalidOrderItemProductSkus]);

  const removeProduct = () => setSelectedProduct(undefined);

  return {
    dispatch,
    isMultiCasePackEnabled,
    addProduct,
    removeProduct,
    alreadySelectedProductDskus,
  };
};
