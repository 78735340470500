import cn from "classnames/dedupe";
import React, { FC } from "react";
import cls from "./ViewShipmentCard.less";
import { CollapsibleSection, Collapsible } from "common/components/CollapsibleSection";
import { ShipmentDetailCardButtonBarProps, ShipmentDetailCardButtonBar } from "./ShipmentDetailCardButtonBar";

export interface ShipmentDetailCardProps extends Collapsible, ShipmentDetailCardButtonBarProps {
  header?: React.ReactNode;
  headerIcon?: React.ReactNode;
  headerClassName?: string;
  bodyClassName?: string;
  className?: string; // Overall card className
  isDisabled?: boolean;
}

const CollapsibleShipmentDetailCard: FC<React.PropsWithChildren<ShipmentDetailCardProps>> = ({
  header,
  headerIcon,
  headerClassName,
  className,
  bodyClassName,
  children,
  isDefaultOpen,
  buttons,
  buttonBarClassName,
  isDisabled,
}) => (
  <div className={cn(cls.asnCard, className, { [cls.disabled]: isDisabled })}>
    {header ?? headerIcon ? (
      <CollapsibleSection
        isDefaultOpen={isDefaultOpen}
        classNameCollapsed={cn(cls.shipmentDetailCardHeader, cls.collapsedHeader)}
        classNameExpanded={cn(cls.cardSection, bodyClassName)}
        collapsedContent={
          <div className={cn(cls.headerTitle, headerClassName)}>
            {headerIcon && <span className={cls.headerIcon}>{headerIcon}</span>}
            {header}
          </div>
        }
        isDisabled={isDisabled}
      >
        {children}
        <ShipmentDetailCardButtonBar buttons={buttons} buttonBarClassName={buttonBarClassName} />
      </CollapsibleSection>
    ) : null}
  </div>
);

/**
 * Generic card for the Create / View Shipments pages. Primary card content is passed as a child to this
 * component.
 *
 * @param props
 */
export const ShipmentDetailCard: FC<React.PropsWithChildren<ShipmentDetailCardProps>> = ({
  isCollapsible,
  children,
  ...props
}) => {
  const { header, headerIcon, headerClassName, className, bodyClassName, buttons, buttonBarClassName } = props;

  return !isCollapsible ? (
    <div className={cn(cls.asnCard, className)}>
      {header ?? headerIcon ? (
        <div className={cn(cls.shipmentDetailCardHeader, headerClassName)}>
          {headerIcon && <span className={cls.headerIcon}>{headerIcon}</span>}
          {header}
        </div>
      ) : null}
      {children ? <div className={cn(cls.cardSection, bodyClassName)}>{children}</div> : null}
      <ShipmentDetailCardButtonBar buttons={buttons} buttonBarClassName={buttonBarClassName} />
    </div>
  ) : (
    <CollapsibleShipmentDetailCard {...props}>{children}</CollapsibleShipmentDetailCard>
  );
};
