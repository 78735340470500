export enum ShopifyActionTypes {
  UPDATE_STORE_NAME = "UPDATE_STORE_NAME",
  UPDATE_USE_STORE_CREDIT = "UPDATE_USE_STORE_CREDIT",
  UPDATE_BADGE_PLACEMENT = "UPDATE_BADGE_PLACEMENT",
  UPDATE_IS_RECONFIGURING_SHOPIFY = "UPDATE_IS_RECONFIGURING_SHOPIFY",
  GET_SHOPIFY_EXAMPLE_LINK = "GET_SHOPIFY_EXAMPLE_LINK",
  APP_INSTALLED = "APP_INSTALLED",
  UPDATE_CART_MINIMUM = "UPDATE_CART_MINIMUM",
  STANDARD_BADGE_OPTION_SELECTED = "STANDARD_BADGE_OPTION_SELECTED",
  SELLER_CONNECTION_STATUS_RECEIVED = "SELLER_CONNECTION_STATUS_RECEIVED",
  SHOPIFY_SHOP_DEACTIVATED = "SHOPIFY_SHOP_DEACTIVATED",
  SHOPIFY_SHOP_RECEIVED = "SHOPIFY_SHOP_RECEIVED",
  SHOPIFY_SHOP_CONFIG_RECEIVED = "SHOPIFY_SHOP_CONFIG_RECEIVED",
  SHOPIFY_CONFIG_LOADER = "SHOPIFY_CONFIG_LOADER",
  SHOPIFY_SHOP_APP_EMBED_BLOCK_ENABLED_RECEIVED = "SHOPIFY_SHOP_APP_EMBED_BLOCK_ENABLED_RECEIVED",
  SHOPIFY_SHOP_APP_BLOCK_TEMPLATES_RECEIVED = "SHOPIFY_SHOP_APP_BLOCK_TEMPLATES_RECEIVED",
  SHOPIFY_SHIPPING_SETTINGS_CONFIG_LOADED = "SHOPIFY_SHIPPING_SETTINGS_CONFIG_LOADED",
  SHOPIFY_SHIPPING_SETTINGS_CONFIG_UPDATED = "SHOPIFY_SHIPPING_SETTINGS_CONFIG_UPDATED",
  SHOPIFY_SHIPPING_SETTINGS_CONFIG_RESET = "SHOPIFY_SHIPPING_SETTINGS_CONFIG_RESET",
  SET_EXPERIMENT_ENROLLMENT = "SET_EXPERIMENT_ENROLLMENT",
}
