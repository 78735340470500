import { getDeliverrUser } from "@deliverr/commons-objects";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { UserData } from "common/user/UserReducer";
import { mapKeys, snakeCase } from "lodash/fp";

const REQUIRED_FIELDS = ["name", "email", "phoneNumber", "updatedAt"];

export const toCognito = (attributes: UserData): UserData =>
  mapKeys(
    (attribute) => (REQUIRED_FIELDS.includes(attribute) ? snakeCase(attribute) : `custom:${attribute}`),
    attributes
  );

export const fromCognito = (userData: UserData | CognitoUserAttribute) => getDeliverrUser(userData);
