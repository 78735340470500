import * as React from "react";
import styled from "@emotion/styled";

import { Box, Card, ThemeProps } from "common/components/ui";
import { SimpleLoader } from "common/components/WithLoader/WithLoader";
import { InboundFooter } from "inbounds/steps/InboundFooter";
import { SelectStorageInboundProductsHeader } from "./SelectStorageInboundProductsHeader";
import { useSelectStorageInboundProductsStep } from "./useSelectStorageInboundProductsStep";
import { useSelector } from "react-redux";
import { selectHasInvalidSelectedProducts } from "../store/selector/selectHasInvalidSelectedProducts";
import { StorageInboundProductChooser } from "./StorageInboundProductChooser/StorageInboundProductChooser";
import { useLoader } from "common/components/WithLoader/useLoader";
import { CreateStorageInboundLoader } from "../CreateStorageInboundLoader.types";

interface SelectPackTypeProps {
  labelClassName?: string;
  error?: boolean;
}

const StyledCard = styled(Card)<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing.S7} ${theme.spacing.S5};
  min-height: 36.25rem;
  height: 100%;
`
);

export const SelectStorageInboundProducts: React.ComponentType<SelectPackTypeProps> = () => {
  const hasInvalidSelectedProducts = useSelector(selectHasInvalidSelectedProducts);
  const isLoading = useLoader(CreateStorageInboundLoader.LoadStorageInboundCreateDraft);

  const { onNextClick, onPreviousClick } = useSelectStorageInboundProductsStep();

  return isLoading ? (
    <SimpleLoader />
  ) : (
    <Box minHeight="36.25rem" minWidth="62.5rem">
      <StyledCard>
        <SelectStorageInboundProductsHeader />
        <Box width="80%">
          <StorageInboundProductChooser />
        </Box>
      </StyledCard>
      <InboundFooter
        onButtonClick={onNextClick}
        onPreviousButtonClick={onPreviousClick}
        disabled={hasInvalidSelectedProducts}
        manualLoader
      />
    </Box>
  );
};
