import { useState, useEffect } from "react";
import { useMedia } from "react-use";
import { DefaultTheme, PopoverMenuAlign } from "common/components/ui";
import { useTheme } from "emotion-theming";
import { useSelector } from "react-redux";
import { getSellerId } from "common/user/UserSelectors";

export const usePopoverMenu = () => {
  const [expanded, setExpanded] = useState(false);
  const sellerId = useSelector(getSellerId);
  const theme = useTheme<DefaultTheme>();

  const isDesktop = useMedia(`(max-width: ${theme.breakpoints.XL})`);

  const popoverMenuAlignment: PopoverMenuAlign = isDesktop ? "right" : "left";

  const onClick = () => setExpanded(!expanded);

  const onBlur = () => setExpanded(false);

  useEffect(() => {
    setExpanded(false);
  }, [sellerId]);

  return {
    expanded,
    popoverMenuAlignment,
    onClick,
    onBlur,
  };
};
