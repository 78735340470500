import { Path } from "paths";
import { generatePath } from "react-router-dom";
import { FastTagPage } from "../FastTagPage";
import { PathPage } from "../FastTagPage/PathPage";
import { UnknownPage } from "../FastTagPage/UnknownPage";
import { fastTagDetailPath } from "./fastTagDetailPath";

export const fastTagPagePath = (page: FastTagPage | UnknownPage | PathPage, fallback = fastTagDetailPath) => {
  switch (page.type) {
    case "unknown":
      return fallback(page);
    case "path":
      return page.id;
    default:
      return generatePath(Path.fastTagsProgram, { program: page.fastTag, page: page.id });
  }
};
