import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { mapError } from "../core/mapError";
import { CoverageApiResponse } from "../fastTag/CoverageApiResponse";
import { FastTagActive } from "../fastTag/FastTagActive";
import { FastTagClient } from "../fastTag/FastTagClient";
import { ServiceLevelCoverage } from "../fastTag/ServiceLevelCoverage";
import { GoogleShoppingEligibleForShoppingActions } from "./GoogleShoppingEligibleForShoppingActions";
import { GoogleShoppingError } from "./GoogleShoppingError";
import { GoogleShoppingSeller } from "./GoogleShoppingSeller/GoogleShoppingSeller";
import { GoogleShoppingSellerData } from "./GoogleShoppingSeller/GoogleShoppingSellerData";
import { GoogleShoppingSellerUpdate } from "./GoogleShoppingSeller/GoogleShoppingSellerUpdate";

export type GoogleShoppingClientConfig = ApiClientConfig;

export class GoogleShoppingClient implements FastTagClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: GoogleShoppingClientConfig = { baseURL: mustBeDefined(process.env.GOOGLE_SHOPPING_URL) }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`,
    });
  }

  async enableFastTag(sellerId: string, dsku: string): Promise<void> {
    try {
      await this.apiClient.put({
        url: `/products/seller/${sellerId}/fast-tag/enabled`,
        body: [dsku],
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async disableFastTag(sellerId: string, dsku: string): Promise<void> {
    try {
      await this.apiClient.put({
        url: `/products/seller/${sellerId}/fast-tag/disabled`,
        body: [dsku],
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [GoogleShoppingError.SELLER_NOT_FOUND]);
    }
  }

  async getDskuStateCoverage(dsku: string): Promise<CoverageApiResponse> {
    try {
      return await this.apiClient.get({
        url: `/availability/dsku/${dsku}/coverage/twoday`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async getStateCoverageByServiceLevel(dsku: string): Promise<ServiceLevelCoverage> {
    try {
      return await this.apiClient.get({
        url: `/availability/servicelevel/${dsku}`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async isFastTagEnabled(sellerId: string, dsku: string): Promise<boolean> {
    try {
      const { isActive } = await this.apiClient.get<FastTagActive>({
        url: `/products/seller/${sellerId}/dsku/${dsku}/active`,
        authentication: isAuthenticated,
      });
      return isActive;
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async getLinkForDsku(sellerId: string, dsku: string): Promise<string> {
    try {
      return await this.apiClient.get({
        url: `/products/seller/${sellerId}/dsku/${dsku}/link`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [
        GoogleShoppingError.SELLER_NOT_FOUND,
        GoogleShoppingError.MISSING_MERCHANT_ID,
        GoogleShoppingError.NOT_FOUND_IN_MARKETPLACE,
      ]);
    }
  }

  async updateLinkForDsku(
    sellerId: string,
    dsku: string,
    listingUrl?: string,
    productIdentifier?: string
  ): Promise<void> {
    try {
      await this.apiClient.put({
        url: `/products/seller/${sellerId}/dsku/${dsku}/product`,
        body: {
          offerId: productIdentifier,
          listingUrl,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [GoogleShoppingError.PRODUCT_NOT_FOUND, GoogleShoppingError.INVALID_URL]);
    }
  }

  async saveOrUpdateSeller(seller: GoogleShoppingSellerData): Promise<GoogleShoppingSeller> {
    try {
      return await this.apiClient.put({
        url: "/seller",
        body: seller,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [
        GoogleShoppingError.DUPLICATE_MERCHANT_ID,
        GoogleShoppingError.NOT_AN_ADMIN,
        GoogleShoppingError.UNAUTHORIZED,
        GoogleShoppingError.UNKNOWN_GOOGLE_API_CLIENT_ERROR,
      ]);
    }
  }

  async getSeller(sellerId: string): Promise<GoogleShoppingSeller> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [GoogleShoppingError.SELLER_NOT_FOUND]);
    }
  }

  async isSellerEligibleForShoppingActions(sellerId: string): Promise<boolean> {
    try {
      const { isEligibleForShoppingActions } = await this.apiClient.get<GoogleShoppingEligibleForShoppingActions>({
        url: `/seller/${sellerId}/shopping-actions-eligibility`,
        authentication: isAuthenticated,
      });
      return isEligibleForShoppingActions;
    } catch (err) {
      throw mapError(err);
    }
  }

  async updateSeller(sellerId: string, update: GoogleShoppingSellerUpdate): Promise<GoogleShoppingSeller> {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}`,
        body: update,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [
        GoogleShoppingError.DUPLICATE_MERCHANT_ID,
        GoogleShoppingError.INVALID_FEED_ID,
        GoogleShoppingError.NOT_AN_ADMIN,
        GoogleShoppingError.SELLER_NOT_FOUND,
        GoogleShoppingError.UNAUTHORIZED,
      ]);
    }
  }

  async deactivateSeller(sellerId: string): Promise<void> {
    try {
      await this.apiClient.delete({
        url: `/seller/${sellerId}`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [GoogleShoppingError.SELLER_NOT_FOUND]);
    }
  }

  async getServiceAccountEmail(merchantId?: string): Promise<string> {
    try {
      return await this.apiClient.get({
        url: "service-account-email",
        params: { merchantId },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }
}
