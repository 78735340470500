import React, { FC } from "react";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { ThemeProps } from "../ui";

interface RemoveProductProps {
  dsku: string;
  removeProduct: (dsku: string) => void;
}

const StyledRemoveButton = styled.div<ThemeProps>(
  ({ theme }) => `
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${theme.colors.NEUTRAL[80]};
    color: ${theme.colors.NEUTRAL[300]};
    font-size: ${theme.font.size.F2};
    font-weight: 700;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
`
);

export const RemoveProductButton: FC<RemoveProductProps> = ({ dsku, removeProduct }) => {
  const { formatMessage } = useIntl();

  return (
    <StyledRemoveButton
      role="button"
      data-testid="replenishment-error-remove-product-button"
      aria-label={formatMessage({
        id: "productChooser.selectedProduct.removeItem",
        defaultMessage: "Remove Item",
      })}
      onClick={() => removeProduct(dsku)}
      data-sku={dsku}
    >
      X
    </StyledRemoveButton>
  );
};
