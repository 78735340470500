// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DisabledSelect__control--w_ZGb {\n  background-color: #f5f4f8;\n  color: #333;\n}\n.DisabledSelect__arrowZone--plO63 {\n  cursor: default;\n}\n", "",{"version":3,"sources":["webpack://./src/app/common/components/DisabledSelect/DisabledSelect.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,WAAA;AACF;AAEA;EACE,eAAA;AAAF","sourcesContent":[".control {\n  background-color: #f5f4f8;\n  color: #333;\n}\n\n.arrowZone {\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": "DisabledSelect__control--w_ZGb",
	"arrowZone": "DisabledSelect__arrowZone--plO63"
};
export default ___CSS_LOADER_EXPORT___;
