import { BRANDED_PACKAGING_FILTER, MULTI_CASE_PACK_FILTER } from "inventory/list/InventoryListActions";
import { ProductErrorPredicateParams, getProductError } from "./ProductErrors";
import { logError, logStart } from "Logger";
import { useCallback, useMemo, useState } from "react";

import { InventorySearchService } from "common/search/services/InventorySearchService";
import { ListType } from "common/list/ListType";
import { LogisticsSearchConfig } from "common/search/services/LogisticsSearchConfig";
import { PACK_INCLUSION_FILTER } from "./ProductSearchFilters";
import { ProductSearchResultItem } from "./ProductSearchResult";
import { SearchResults } from "common/search/SearchService";
import { SelectedProductData } from "../ProductChooser";
import algoliaService from "./algoliaService";
import { calculateAvailableQuantity } from "common/utils/product/calculateAvailableQuantity";
import { calculateReserveStorageAvailableQuantity } from "common/utils/product/calculateReserveStorageAvailableQuantity";
import compact from "lodash/fp/compact";
import { configFor } from "common/search/services/SearchConfig";
import { constructOpenSearchFilters } from "common/search/services/InventorySearchUtils";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import { mapPacksToProductListItem } from "./Packs/utils/mapPacksToProductListItem";
import { removeAllEmTags } from "common/StringUtils";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";

interface UseProductSearchProps {
  selectedProducts?: SelectedProductData[];
  allowZeroInventorySelection: boolean;
  isDimsRequired?: boolean;
  hideHiddenSKUs?: boolean;
  onSelectProduct: (inventoryListItem: SelectedProductData) => void;
  isBarcodeRequired?: boolean;
  excludedDsku?: string;
  isReserveStorage?: boolean;
  shouldSortDropdownList?: boolean;
  shouldIncludeBrandedPackaging?: boolean;
  withWalmartChannelIdOnly?: boolean;
  applyFbaConditions?: boolean;
  isIntegratedFBA?: boolean;
  showOnlyPacks?: boolean;
  isOrder?: boolean;
  storageMaximumSelectableCaseQty?: number;
  excludedPackOfSkusForStorage?: string[];
  customDisabledProductSkus?: string[];
  shouldShowKitHint?: boolean;
}

const mapToProductSearchResultItem = (params: ProductErrorPredicateParams): ProductSearchResultItem => ({
  ...params.product,
  error: getProductError(params),
  availableQty: calculateAvailableQuantity(params.product),
  descriptionHtml: params.product.name,
  storageAvailableQty: calculateReserveStorageAvailableQuantity(params.product),
});

const getProductSearchFilters = (
  hideHiddenSkus?: boolean,
  excludedDsku?: string,
  shouldIncludeBrandedPackaging?: boolean,
  showOnlyPacks?: boolean
) => {
  const hiddenFilter = hideHiddenSkus ? "NOT isHiddenOnSellerPortal=1" : undefined;
  const excludedDskuFilter = excludedDsku ? `NOT dsku:${excludedDsku}` : undefined;
  const brandedPackagingFilter = shouldIncludeBrandedPackaging ? undefined : BRANDED_PACKAGING_FILTER;
  const productPackFilter = showOnlyPacks ? PACK_INCLUSION_FILTER : MULTI_CASE_PACK_FILTER;

  const filters = compact([hiddenFilter, excludedDskuFilter, brandedPackagingFilter, productPackFilter]);
  return filters.length > 0 ? filters : undefined;
};

export const useProductSearch = ({
  selectedProducts = [],
  allowZeroInventorySelection,
  isDimsRequired,
  hideHiddenSKUs,
  onSelectProduct,
  isBarcodeRequired,
  excludedDsku,
  isReserveStorage,
  shouldSortDropdownList,
  shouldIncludeBrandedPackaging,
  showOnlyPacks,
  withWalmartChannelIdOnly,
  applyFbaConditions,
  isIntegratedFBA,
  isOrder = false,
  storageMaximumSelectableCaseQty,
  excludedPackOfSkusForStorage,
  customDisabledProductSkus,
  shouldShowKitHint,
}: UseProductSearchProps) => {
  const { formatMessage } = useIntl();
  const isLogisticsSearchProductsReadEnabled = true;

  const selectedSkus = customDisabledProductSkus ?? selectedProducts.map((p) => p.dsku);

  const handleChange = (product: SelectedProductData | null) => {
    if (product) {
      onSelectProduct(product);
    }
  };
  const getIsOptionDisabled = (option: ProductSearchResultItem) => {
    if (!isOrder) {
      return option.error !== undefined;
    } else {
      if (option.error !== undefined) {
        return true;
      }
      return isReserveStorage ? !option.storageAvailableQty : !option.availableQty;
    }
  };

  const filters = useMemo(
    () => getProductSearchFilters(hideHiddenSKUs, excludedDsku, shouldIncludeBrandedPackaging),
    [excludedDsku, hideHiddenSKUs, shouldIncludeBrandedPackaging]
  );

  const inventorySearchService = useMemo(
    () => new InventorySearchService(configFor(ListType.InventoryV2) as LogisticsSearchConfig),
    []
  );

  const executeSearch = async (searchTerm: string, searchFilters: string[] | undefined): Promise<SearchResults> => {
    if (isLogisticsSearchProductsReadEnabled) {
      const customizedOpenSearchFilters = constructOpenSearchFilters({ searchTerm, isHiddenOnSellerPortal: false });
      return await inventorySearchService.execute({ customizedOpenSearchFilters });
    }

    return await algoliaService.search(searchTerm, undefined, undefined, searchFilters);
  };

  const search = async (searchTerm: string): Promise<ProductSearchResultItem[]> => {
    const res = await executeSearch(searchTerm, filters);
    if (showOnlyPacks) {
      const searchResultProducts = res.hits;
      if (isEmpty(searchResultProducts)) {
        return [];
      }

      const productSkus = searchResultProducts.map((product) => removeAllEmTags(product.dsku));

      const packResults = isLogisticsSearchProductsReadEnabled
        ? await inventorySearchService.searchByIds(productSkus, "", "packOf")
        : await algoliaService.searchByIds(productSkus, "", "packOf");

      const productListItems = mapPacksToProductListItem(searchResultProducts, packResults.hits ?? []);
      return productListItems
        .map((product) =>
          mapToProductSearchResultItem({
            product,
            selectedSkus,
            allowZeroInventorySelection,
            isDimsRequired,
            isBarcodeRequired,
            isReserveStorage,
            withWalmartChannelIdOnly,
            applyFbaConditions,
            isIntegratedFBA,
            showOnlyPacks,
            selectedPackSkus: selectedProducts.map((p) => p.packOf!).filter(Boolean),
            storageMaximumSelectableCaseQty,
            excludedPackOfSkusForStorage,
            shouldShowKitHint,
            selectedKitSkus: selectedProducts ? selectedProducts.some((item) => item.isKit!) : undefined,
          })
        )
        .sort((prodA, prodB) => (prodB.storageAvailableQty ?? 0) - (prodA.storageAvailableQty ?? 0));
    }

    if (shouldSortDropdownList) {
      return res.hits
        .map((product) =>
          mapToProductSearchResultItem({
            product,
            selectedSkus,
            allowZeroInventorySelection,
            isDimsRequired,
            isBarcodeRequired,
            isReserveStorage,
            applyFbaConditions,
            isIntegratedFBA,
            withWalmartChannelIdOnly,
          })
        )
        .sort(shouldSortProductsByValidAndAvailable);
    }

    return res.hits.map((product) =>
      mapToProductSearchResultItem({
        product,
        selectedSkus,
        allowZeroInventorySelection,
        isDimsRequired,
        isBarcodeRequired,
        isReserveStorage,
        applyFbaConditions,
        isIntegratedFBA,
        withWalmartChannelIdOnly,
      })
    );
  };

  const searchProducts = useCallback(
    async (searchTerm: string): Promise<ProductSearchResultItem[]> => {
      const ctx = {
        fn: searchProducts.name,
        searchTerm,
      };

      try {
        logStart(ctx);
        return await search(searchTerm);
      } catch (error) {
        logError(ctx, error, "error searching for products");
        toast.error(formatMessage({ id: "productSearch.error", defaultMessage: "Error searching for products" }), {
          toastId: "productSearch.error",
        });

        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      allowZeroInventorySelection,
      isBarcodeRequired,
      isDimsRequired,
      isReserveStorage,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(selectedProducts),
      showOnlyPacks,
      applyFbaConditions,
      isIntegratedFBA,
      formatMessage,
      filters,
      selectedSkus,
    ]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptions = useCallback(
    debounce((query: string, callback: (results: ProductSearchResultItem[]) => void) => {
      void searchProducts(query).then((results) => callback(results));
    }, Number(process.env.DEFAULT_SEARCH_THROTTLE_MS)),
    [searchProducts]
  );

  const [newProductSearchTerm, setNewProductSearchTerm] = useState("");
  const [isNewProductModalOpen, setIsNewProductModalOpen] = useState(false);

  const shouldSortProductsByValidAndAvailable = (prodA, prodB) => {
    if (prodA.error ?? prodB.error) {
      if (prodA.error && prodB.error) {
        return prodA.error === "OutOfStock" ? -1 : 1;
      } else {
        return prodA.error ? 1 : -1;
      }
    } else {
      return (prodB.availableQty ?? 0) - (prodA.availableQty ?? 0);
    }
  };

  const openNewProductModal = (searchTerm: string) => {
    setNewProductSearchTerm(searchTerm);
    setIsNewProductModalOpen(true);
  };
  const closeNewProductModal = () => {
    setIsNewProductModalOpen(false);
    setNewProductSearchTerm("");
  };

  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (value: string) => setInputValue(value);
  const isMenuOpen = !isEmpty(inputValue.trim());

  return {
    loadOptions,
    isNewProductModalOpen,
    newProductSearchTerm,
    openNewProductModal,
    closeNewProductModal,
    handleInputChange,
    handleChange,
    isMenuOpen,
    getIsOptionDisabled,
  };
};
