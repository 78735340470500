import React, { FC } from "react";
import { NavbarEditableItem } from "common/components/NavbarEditableItem";
import { useNavbarShippingPlanName } from "./useNavbarShippingPlanName";
import promo from "assets/img/icons/promo-inbound.svg";
import { PromoIcon, ShippingPlanName, ShippingPlanNameContainer } from "./NavbarShippingPlanName.styles";

export const NavbarShippingPlanName: FC = () => {
  const { displayName, handleSubmit, isPromotional } = useNavbarShippingPlanName();

  // promotional navbar early return
  if (isPromotional) {
    return (
      <ShippingPlanNameContainer data-testid="NavbarShippingPlanName__promoNameWrapper">
        <ShippingPlanName>{displayName}</ShippingPlanName>
        <PromoIcon html={promo} />
      </ShippingPlanNameContainer>
    );
  }

  // for non-promotional plans
  return (
    <NavbarEditableItem
      displayItemValue={displayName}
      handleSubmit={handleSubmit}
      testId={{
        nameInput: "NavbarShippingPlanName__nameInput",
        nameWrapper: "NavbarShippingPlanName__nameWrapper",
      }}
    />
  );
};
