import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { replenishmentClient } from "Clients";
import { ShippingPartner, ShippingPreference, SubmitOrderRequest } from "@deliverr/replenishment-client";
import { batch } from "react-redux";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { transferCreateSetError, transferCreateSetReplenishmentError } from "../TransferCreateActions";
import { WholesaleErrorType } from "common/wholesale-common/utils/WholesaleErrorTypes";
import { logError } from "Logger";
import { getReplenishmentShippingTypeFromWholesaleType } from "transfers/create/steps/common/utils/getReplenishmentShippingTypeFromWholesaleType";
import { getReplenishmentShippingPartnerFromWholesaleType } from "transfers/create/steps/common/utils/getReplenishmentShippingPartnerFromWholesaleType";
import { ApiError } from "@deliverr/errors";
import { FeatureName, getFeatureSelector } from "common/Split";

export const submitReplenishmentOrder = async (dispatch, getState): Promise<boolean> => {
  const {
    replenishmentOrder,
    cargoType,
    shippingMethod,
    destinationType,
    shipmentShippingMethodMap,
    selectedCarrierAccount,
  } = selectTransferCreate(getState());
  const isWholesaleServiceSyncApiOn = getFeatureSelector(FeatureName.WholesaleServiceSyncAPI)(getState());

  batch(() => {
    dispatch(addLoader(CreateTransferLoader.CreateTransferOrderLoader));
    dispatch(transferCreateSetError(WholesaleErrorType.NONE));
    dispatch(transferCreateSetReplenishmentError({ errors: [] }));
  });

  try {
    const shippingPartner = getReplenishmentShippingPartnerFromWholesaleType(shippingMethod!, destinationType);
    const carrierAccountId = selectedCarrierAccount?.id;

    const shipmentShippingPreferences: ShippingPreference[] =
      replenishmentOrder?.shipments?.map(({ shipmentId }) => {
        const shippingType = getReplenishmentShippingTypeFromWholesaleType(
          shipmentShippingMethodMap?.[shippingMethod!]?.[shipmentId] ?? cargoType
        );

        const carrierAccountInfo = shippingPartner === ShippingPartner.COLLECT ? { carrierAccountId } : {};

        return {
          shipmentId,
          shippingPartner,
          shippingType,
          ...carrierAccountInfo,
        };
      }) ?? [];
    const submitOrderRequest: SubmitOrderRequest = { shipmentShippingPreferences };
    if (replenishmentOrder?.orderId) {
      if (isWholesaleServiceSyncApiOn) {
        await replenishmentClient.submitOrderV2(replenishmentOrder?.orderId, submitOrderRequest);
      } else {
        await replenishmentClient.submitOrder(replenishmentOrder?.orderId, submitOrderRequest);
      }
    } else {
      throw new Error(ApiError.UNKNOWN_ERROR);
    }
    dispatch(clearLoader(CreateTransferLoader.CreateTransferOrderLoader));
    return true;
  } catch (err) {
    batch(() => {
      dispatch(transferCreateSetReplenishmentError({ errors: [err] }));
      dispatch(transferCreateSetError(WholesaleErrorType.UNTYPED));
    });
    logError({ fn: "submitReplenishmentOrder" }, err);
    dispatch(clearLoader(CreateTransferLoader.CreateTransferOrderLoader));
    return false;
  }
};
