import React, { FC } from "react";

import { UnexpectedSkuNonComplianceRow } from "../../types";
import { UNEXPECTED_SKU_NON_COMPLIANCE_BLADE_ID } from "../bladeIds";
import { useUnexpectedSkuNonComplianceBlade } from "./useUnexpectedSkuNonComplianceBlade";
import { UnexpectedSkuBlade } from "common/components/ProblemBlade/UnexpectedSkuBlade/UnexpectedSkuBlade";

interface UnexpectedSkuNonComplianceBladeProps {
  nonComplianceCase: UnexpectedSkuNonComplianceRow;
}

export const UnexpectedSkuNonComplianceBlade: FC<UnexpectedSkuNonComplianceBladeProps> = ({ nonComplianceCase }) => {
  const {
    onShippingPlanClick,
    shippingPlanId,
    shippingPlanName,
    resetBladeState,
    skuPair,
  } = useUnexpectedSkuNonComplianceBlade(nonComplianceCase);

  return (
    <UnexpectedSkuBlade
      id={UNEXPECTED_SKU_NON_COMPLIANCE_BLADE_ID}
      shipmentProblem={nonComplianceCase}
      onShippingPlanClick={onShippingPlanClick}
      shippingPlanId={shippingPlanId}
      shippingPlanName={shippingPlanName}
      onCloseClick={resetBladeState}
      isBladeOpen
      product={skuPair}
    />
  );
};
