import { BarcodeEditComponentError } from "barcodes/BarcodeEditComponentError";
import { useSelector } from "react-redux";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { useAddNewAmazonBarcode } from "./AddAmazonProductBarcodesTable/useAddNewAmazonBarcode";
import { isEmpty } from "lodash";
import { isFNSKUOrASINBarcode } from "transfers/utils/isFNSKUBarcode";
import { productClient } from "Clients";
import { BarcodeResponseCode } from "@deliverr/commons-clients";
import React, { useState } from "react";
import { toast } from "common/components/ui";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { SelectedProductData } from "common/components/ProductChooser";

export const useTransferAddAmazonBarcodesForUnitPrep = () => {
  const [isNextLoading, setIsNextLoading] = useState(false);
  const { selectedProducts } = useSelector(selectTransferCreate);
  const [productsWithoutFNSKU, setProductsWithoutFNSKU] = useState<SelectedProductData[]>(
    Object.values(selectedProducts).filter(({ barcodes }) => !barcodes?.find(isFNSKUOrASINBarcode))
  );
  const { barcodeMap, onBarcodeValueChange, setError } = useAddNewAmazonBarcode(productsWithoutFNSKU);

  const submitBarcodeAndValidateNextClick = async () => {
    setIsNextLoading(true);
    const parentToCasePackDskuMap: { [dsku: string]: string } = {};
    const createBarcodeRequest: {
      [dsku: string]: string[];
    } = productsWithoutFNSKU.reduce((acc, { dsku }) => {
      const { packOf } = selectedProducts[dsku];
      const barcodeValue = barcodeMap[dsku].barcodeValue;
      // barcodes are stored at only parent product level and not case pack level
      acc[packOf ?? dsku] = [barcodeValue];
      parentToCasePackDskuMap[packOf ?? dsku] = dsku;
      return acc;
    }, {});

    try {
      const responseObj = await productClient.createBarcodes(createBarcodeRequest);
      let isNextAllowed = true;
      const errorDSKUs: string[] = [];
      Object.entries(responseObj).forEach(([dsku, response]) => {
        if (response?.[0]?.code === BarcodeResponseCode.ERROR) {
          const casePackDsku = parentToCasePackDskuMap[dsku];
          setError(casePackDsku, <BarcodeEditComponentError barcodeError={{ code: response?.[0]?.subcode }} />);
          errorDSKUs.push(casePackDsku);
          isNextAllowed = false;
        }
      });
      if (!isEmpty(errorDSKUs)) {
        // show only error DSKUs
        const newProductsWithoutFNSKU = productsWithoutFNSKU.filter(({ dsku }) => errorDSKUs.includes(dsku));
        setProductsWithoutFNSKU([...newProductsWithoutFNSKU]);
      }
      return isNextAllowed;
    } catch (err) {
      toast.error(
        <FormattedMessage
          id="transfers.fba.unitPrep.addProductBarcodes.errorMessage"
          defaultMessage="Failed to add Amazon barcode. Please try again later."
        />,
        {
          autoClose: 5000,
          toastId: "transferAddAmazonBarcodeUnitPrepError",
        }
      );
      return false;
    } finally {
      setIsNextLoading(false);
    }
  };

  const isNextDisabled = Object.values(barcodeMap).some(
    ({ error, barcodeValue }) => Boolean(error) || isEmpty(barcodeValue)
  );

  return {
    isNextDisabled,
    isNextLoading,
    products: productsWithoutFNSKU,
    barcodeMap,
    onBarcodeValueChange,
    submitBarcodeAndValidateNextClick,
  };
};
