import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, BoxReset, Button, Stack, Text } from "common/components/ui";
import { useDownloadBOL } from "./useDownloadBOL";

export const DownloadBOL: React.FC = () => {
  const { onDownloadClick } = useDownloadBOL();

  return (
    <Stack direction="VERTICAL" data-testid="storage_inbound_bol_download_section">
      <BoxReset marginTop={null} marginBottom={"S2"}>
        <Text size="body">
          <FormattedMessage
            id="storage.inbounds.detail.shipment.instruction.bol.text"
            defaultMessage="Print the bill of lading and give it to the driver upon pickup."
          />
        </Text>
      </BoxReset>

      <Box>
        <Button data-testid="storage_inbound_bol_download_button" secondary onClick={onDownloadClick}>
          <FormattedMessage
            id="storage.inbounds.detail.shipment.instruction.bol.download"
            defaultMessage={"Download bill of lading"}
          />
        </Button>
      </Box>
    </Stack>
  );
};
