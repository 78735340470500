import classNames from "classnames/dedupe";
import { DefaultTheme, LocalizedMessage } from "common/components/ui";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { InfoIcon } from "common/components/icons";
import { HorizontalAlignment, Sort, SortDir } from "common/models";

import cls from "./ColumnHeader.less";

const HelpText = styled.span<{}, DefaultTheme>(
  ({ theme }) => `
    margin-left: ${theme.spacing.S1};
  `
);

export interface ColumnHeaderProps {
  content: React.ReactNode;
  helpText?: LocalizedMessage;
  className?: string;
  sortField?: string;
  onSort?: (e: React.MouseEvent<HTMLTableHeaderCellElement>) => void;
  currentSort?: Sort;
  width?: string;
  align?: HorizontalAlignment;
}

const ColumnHeader: FC<ColumnHeaderProps> = ({
  content,
  helpText,
  className,
  sortField,
  onSort,
  currentSort,
  width = "",
  align,
}) => {
  const isSorted = currentSort && currentSort.fieldName === sortField;

  return (
    <th
      className={classNames(className, {
        [cls.sortable]: !!sortField,
        [cls.sorted]: isSorted,
        [cls.alignRight]: align === HorizontalAlignment.RIGHT,
        [cls.alignCenter]: align === HorizontalAlignment.CENTER,
        [cls.sortedDesc]: isSorted && currentSort && currentSort.direction === SortDir.DESC,
      })}
      onClick={sortField ? onSort : undefined}
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      data-sortfield={sortField || ""}
      style={{ width }}
    >
      {content}
      {helpText && (
        <HelpText>
          <InfoIcon wrap placement="top" message={helpText} size="small" alignTop />
        </HelpText>
      )}
    </th>
  );
};

export default ColumnHeader;
