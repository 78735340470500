import { WholesaleOrderStatus } from "@deliverr/wholesale-client";

class OrderStatusMapping {
  isCancellable? = false;
  allowSubmit? = false;
}

export const mapWholesaleOrderStatusUiConfigs: Record<WholesaleOrderStatus, OrderStatusMapping> = {
  [WholesaleOrderStatus.SCHEDULED]: { isCancellable: true, allowSubmit: true },
  [WholesaleOrderStatus.DRAFT]: { isCancellable: true, allowSubmit: true },
  [WholesaleOrderStatus.PROCESSING]: { isCancellable: true, allowSubmit: true },
  [WholesaleOrderStatus.SHIPPED]: { allowSubmit: false },
  [WholesaleOrderStatus.CANCELLED]: { allowSubmit: false },
  [WholesaleOrderStatus.DELIVERED]: { allowSubmit: false },
};
