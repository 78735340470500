import { castArray, find, flowRight, get, map } from "lodash/fp";

import { RootState } from "RootReducer";
import { isShipToOneDispersalMethod } from "../InboundUtils";

export const getDisplayCdskus =
  (packageId?: number) =>
  ({ inbound: { packages, loadedShipmentId } }: RootState): string[] => {
    const loadedPackages = packages[loadedShipmentId!];
    if (packageId) {
      return flowRight(
        castArray,
        get("cdsku"),
        find(({ id }) => id === packageId)
      )(loadedPackages);
    } else {
      return map(get("cdsku"), loadedPackages);
    }
  };

export const getReceivingInfo = (state: RootState) => state.inbound.receivingInfo[state.inbound.loadedShipmentId!];

export const getDispersalMethod = (state: RootState) => state.inbound.plan.dispersalMethod;

export const getIsShipToOne = (state: RootState) => {
  const dispersalMethod = getDispersalMethod(state);
  return Boolean(dispersalMethod ? isShipToOneDispersalMethod(dispersalMethod) : state.inbound.plan.isForwarding);
};

export const getShipmentPackages = (state: RootState) =>
  state.inbound.loadedShipmentId ? state.inbound.packages[state.inbound.loadedShipmentId] : [];
