import { useGetAllRelatedProductsAndCasePacks } from "storage/hooks/useGetAllRelatedProductsAndCasePacks";
import { useSelector } from "react-redux";
import { UnexpectedSkuProblemRow } from "common/components/ProblemsTable/types";
import { useShipmentProblems } from "../../useShipmentProblems";
import { getStorageInboundDetail } from "storage/inbounds/detail/store";

export const useShipmentProblemMismatchCaseQtyBlade = (shipmentProblem: UnexpectedSkuProblemRow) => {
  const { hideShipmentProblemBlade, setSelectedProblem, isShipmentProblemBladeOpen } = useShipmentProblems();
  const { storageInboundOrder } = useSelector(getStorageInboundDetail);
  const { shippingPlanId, shippingPlanName, asnId } = storageInboundOrder;

  const { value: product } = useGetAllRelatedProductsAndCasePacks({
    dsku: shipmentProblem.barcode,
    asnId: parseInt(asnId),
  });

  const resetBladeState = () => {
    hideShipmentProblemBlade();
    setSelectedProblem(undefined);
  };

  return {
    resetBladeState,
    shippingPlanId,
    shippingPlanName,
    isShipmentProblemBladeOpen,
    product,
  };
};
