// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateMoreAdSetsModal__modalContainer--fxKPY {\n  padding: 40px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  gap: 20px;\n}\n.CreateMoreAdSetsModal__title--g4yNI {\n  margin: 0;\n}\n.CreateMoreAdSetsModal__buttons--152hw {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  gap: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/app/tags/facebook/common/ConfigureAds/CreateMoreAdSetsModal.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,4BAAA;EAAA,6BAAA;MAAA,0BAAA;UAAA,sBAAA;EACA,SAAA;AACF;AAEA;EACE,SAAA;AAAF;AAGA;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,qBAAA;MAAA,kBAAA;UAAA,yBAAA;EACA,SAAA;AADF","sourcesContent":[".modalContainer {\n  padding: 40px;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.title {\n  margin: 0;\n}\n\n.buttons {\n  display: flex;\n  justify-content: flex-end;\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": "CreateMoreAdSetsModal__modalContainer--fxKPY",
	"title": "CreateMoreAdSetsModal__title--g4yNI",
	"buttons": "CreateMoreAdSetsModal__buttons--152hw"
};
export default ___CSS_LOADER_EXPORT___;
