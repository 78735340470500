import { createActionCreator } from "common/ReduxUtils";
import { TransferShipmentDetailActionTypes } from "./TransferShipmentDetailActionTypes";

export const transferDetailClearMutating = createActionCreator(
  TransferShipmentDetailActionTypes.TRANSFER_MUTATING_CLEAR
);
export const transferDetailClearError = createActionCreator(
  TransferShipmentDetailActionTypes.TRANSFER_DETAILS_CLEAR_ERROR
);
export const transferDetailSetError = createActionCreator(TransferShipmentDetailActionTypes.TRANSFER_DETAILS_SET_ERROR);
export const transferDetailSetUploading = createActionCreator<boolean>(
  TransferShipmentDetailActionTypes.TRANSFER_DETAILS_UPLOADING,
  "isUploading"
);

export const setTransferDetailFailed = createActionCreator(
  TransferShipmentDetailActionTypes.TRANSFER_DETAILS_SET_ERROR
);

export const setTransferDetailFailedWithReason = createActionCreator<string>(
  TransferShipmentDetailActionTypes.TRANSFER_DETAILS_SET_ERROR,
  "errorReason"
);

export const setRedirectToInboundFailed = createActionCreator(
  TransferShipmentDetailActionTypes.TRANSFER_REDIRECT_TO_INBOUND_ERROR
);
