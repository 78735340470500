import { createSelector } from "reselect";

import { RootState } from "RootReducer";
import { getFreightCargoPalletDetails } from "freight/FreightSelectors";
import { getTotalCargoPalletDimensions } from "./getTotalCargoPalletDimensions";
import { CargoPalletDetailsState } from "freight/types";
import { CargoPalletValidations } from "freight/steps/setup/steps/cargoPalletDetails/CargoPalletTable/CargoPalletValidations";
import {
  LTL_MAX_INDIVIDUAL_PALLET_HEIGHT,
  LTL_MAX_INDIVIDUAL_PALLET_LENGTH,
  LTL_MAX_INDIVIDUAL_PALLET_WEIGHT,
  LTL_MAX_INDIVIDUAL_PALLET_WIDTH,
  LTL_MAX_TOTAL_PALLET_WEIGHT,
  LTL_MIN_TOTAL_PALLET_WEIGHT,
} from "freight/constants/cargoPalletConstants";

interface PalletDimsCompliance {
  readonly palletDimsCompliant: boolean; // are all expected dims compliant?
  readonly palletDimsViolations: CargoPalletValidations[]; // list of dims violations
}

export const getCargoDimsComplianceMessages = (dims, palletLineItems, totalPalletCount) => {
  const [palletQtySum, palletWeightSum] = dims!;
  const {
    PALLET_QTY,
    OVER_MAX_PALLET_TOTAL_WEIGHT,
    PALLETS_OVER_MAX_WIDTH,
    PALLETS_OVER_MAX_LENGTH,
    PALLETS_OVER_MAX_HEIGHT,
    PALLETS_OVER_MAX_WEIGHT,
    UNDER_MINIMUM_TOTAL_WEIGHT,
  } = CargoPalletValidations;

  const validationMap: Record<CargoPalletValidations, boolean> = {
    [PALLET_QTY]: Boolean(totalPalletCount && palletQtySum !== totalPalletCount),
    [OVER_MAX_PALLET_TOTAL_WEIGHT]: palletWeightSum > LTL_MAX_TOTAL_PALLET_WEIGHT,
    [UNDER_MINIMUM_TOTAL_WEIGHT]: palletWeightSum > 0 && palletWeightSum < LTL_MIN_TOTAL_PALLET_WEIGHT,
    [PALLETS_OVER_MAX_WIDTH]: palletLineItems.some(
      (pallet) => pallet.palletDimensions.width > LTL_MAX_INDIVIDUAL_PALLET_WIDTH
    ),
    [PALLETS_OVER_MAX_LENGTH]: palletLineItems.some(
      (pallet) => pallet.palletDimensions.length > LTL_MAX_INDIVIDUAL_PALLET_LENGTH
    ),
    [PALLETS_OVER_MAX_HEIGHT]: palletLineItems.some(
      (pallet) => pallet.palletDimensions.height > LTL_MAX_INDIVIDUAL_PALLET_HEIGHT
    ),
    [PALLETS_OVER_MAX_WEIGHT]: palletLineItems.some(
      (pallet) => pallet.palletDimensions.weight > LTL_MAX_INDIVIDUAL_PALLET_WEIGHT
    ),
  };

  return {
    palletDimsCompliant: !Object.keys(CargoPalletValidations).some((violation) => !!validationMap[violation]),
    palletDimsViolations: Object.keys(CargoPalletValidations).filter(
      (violation) => !!validationMap[violation]
    ) as CargoPalletValidations[],
  };
};

/**
 * Checks for dimensions compliance on each individual pallet and on cumulative totals for weight, height and qty
 */
export const getCargoDimsCompliance = createSelector<
  RootState,
  number[],
  CargoPalletDetailsState,
  PalletDimsCompliance
>(
  getTotalCargoPalletDimensions, // [sum of all # of pallets, sum of all pallet weight * # of pallets]
  getFreightCargoPalletDetails,
  (dims, { palletLineItems, totalPalletCount }) =>
    getCargoDimsComplianceMessages(dims, palletLineItems, totalPalletCount)
);
