import { AxiosError } from "axios";
import { ApiError, DeliverrError } from "@deliverr/errors";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { ListingSolution } from "./ListingSolution";
import { SalesChannel } from "./SalesChannel/SalesChannel";
import { SellerSalesChannels } from "./SellerSalesChannels/SellerSalesChannels";
import { mustBeDefined } from "common/utils/mustBeDefined";
import { ShippingOptionZone } from "./ShippingOptionZone";
import { SellerIntegrationCredentials } from "./SellerIntegrationCredentials";
import { JobStatus } from "./JobStatus/JobStatus";
import { ChannelAttributes } from "./SalesChannel/ChannelAttributes";
import { Seller } from "./Seller";
import { DeejayIntegrationStatus } from "./DeejayIntegrationStatus";
import { IntegrationSetupParameters } from "./IntegrationSetupParameters";
import { ShopPromise } from "./ShopPromise";
import { DeliveryPromiseProvider } from "./DeliveryPromiseProvider";

export type OnboardingClientConfig = ApiClientConfig;

export class OnboardingClientV2 {
  private apiClient: ApiClient;
  private v1ApiClient: ApiClient;

  constructor({ baseURL }: OnboardingClientConfig = { baseURL: mustBeDefined(process.env.ONBOARDING_URL) }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v2`,
    });
    this.v1ApiClient = new ApiClient({
      baseURL: `${baseURL}/v1`,
    });
  }

  async getSellerSalesChannels(sellerId: string): Promise<SellerSalesChannels> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/channels`,
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("getSellerSalesChannels", error);
    }
  }

  async deactivateSellerIntegration(slsUuid: string): Promise<SalesChannel> {
    try {
      return await this.apiClient.post({
        url: `/channel/deactivate/${slsUuid}`,
        body: {},
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("deactivateSellerIntegration", error);
    }
  }

  async activateSellerIntegration(slsUuid: string): Promise<SalesChannel> {
    try {
      return await this.apiClient.post({
        url: `/channel/activate/${slsUuid}`,
        body: {},
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("activateSellerIntegration", error);
    }
  }

  async startCatalogUpdate(sendSellerEmailWhenDone: boolean): Promise<JobStatus> {
    try {
      return await this.apiClient.post({
        url: `/jobs/product/sync`,
        body: {},
        params: { email: sendSellerEmailWhenDone ? "true" : "" },
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("startCatalogUpdate", error);
    }
  }

  async getCatalogUpdateStatus(): Promise<JobStatus> {
    try {
      return await this.apiClient.get({
        url: `/jobs/product/sync`,
        params: {},
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("getCatalogUpdateStatus", error);
    }
  }

  /**
   * Formerly known as getShopifyOauthConsentUrlForAppInstallation
   */
  public async getOauthConsentUrl(fullQueryString: string, channelId?: string): Promise<{ oauth_url?: string }> {
    try {
      return await this.apiClient.get({
        url: `/setup/oauth/url`,
        params: {
          fullQueryString,
          channelId,
        },
      });
    } catch (error) {
      throw this.newOnboardingError("getOauthConsentUrl", error);
    }
  }

  /**
   * Formerly known as addShopifyIntegrationFromAppInstallation
   *
   * TODO: It may be possible to change the code to not have this special purpose method
   * (using just createChannel with flexible parameters).
   *
   * @returns an object that includes slsUuid of the newly created channel
   */
  public async createOrUpdateChannelFromAppStore(
    sellerId: string,
    shop: string,
    generatedUUID: string
  ): Promise<{ slsUuid: string }> {
    try {
      return await this.apiClient.post({
        url: `/channel/app`,
        params: {
          sellerId,
          shop,
          uuid: generatedUUID, // uuid is a reserved keyword
        },
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("createOrUpdateChannelFromAppStore", error);
    }
  }

  /**
   * Creates a new channel connection
   *
   * In the data dictionary that is passed as a parameter only apiKey is needed,
   * but keeping the method signature for now.
   *
   * @returns an object that includes slsUuid of the newly created channel
   */
  async createChannel(
    listingSolution: ListingSolution,
    sellerId: string,
    data: IntegrationSetupParameters
  ): Promise<{ slsUuid?: string; oauth_url?: string }> {
    try {
      return await this.apiClient.post({
        url: `/channel`,
        params: { sellerId, name: listingSolution, ...data },
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("createChannel", error);
    }
  }

  /**
   * Updates an existing channel
   *
   * In the data dictionary that is passed as a parameter only apiKey is needed,
   * but keeping the method signature for now.
   */
  async updateChannel(slsUuid: string, data: IntegrationSetupParameters): Promise<{ oauth_url?: string }> {
    try {
      return await this.apiClient.post({
        url: `/channel/${slsUuid}`,
        body: {},
        params: { ...data },
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("updateChannel", error);
    }
  }

  async createShippingOptionZones(slsUuid: string): Promise<ShippingOptionZone[]> {
    try {
      return await this.apiClient.post({
        url: `/shipping-option-zones`,
        body: [],
        params: {
          slsUuid,
        },
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("createShippingOptionZones", error);
    }
  }

  async getShippingOptionZones(slsUuid: string): Promise<ShippingOptionZone[]> {
    try {
      return await this.apiClient.get({
        url: `/shipping-option-zones`,
        params: { slsUuid },
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("getShippingOptionZones", error);
    }
  }

  async updateShippingOptionZones(
    slsUuid: string,
    shippingOptionZones: {
      [zoneId: string]: { [property: string]: string | number };
    }
  ): Promise<ShippingOptionZone[]> {
    try {
      return await this.apiClient.put({
        url: `/shipping-option-zones`,
        body: shippingOptionZones,
        params: {
          slsUuid,
        },
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("updateShippingOptionZones", error);
    }
  }

  async getCredentials(slsUuid: string): Promise<SellerIntegrationCredentials> {
    try {
      return await this.apiClient.get({
        url: `/credentials`,
        params: { slsUuid },
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("getCredentials", error);
    }
  }

  async importCatalog(params: { slsUuid?: string; sellerId: string; email: string }): Promise<{}> {
    try {
      return await this.apiClient.post({
        url: `/product/import`,
        body: {},
        params,
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("importCatalog", error);
    }
  }

  async updateSellerIntegrationAttributes(
    slsUuid: string,
    listingSolution: ListingSolution,
    attributes: ChannelAttributes
  ): Promise<{}> {
    try {
      return await this.apiClient.post({
        url: `/${listingSolution}/updateAttributes/${slsUuid}`,
        body: attributes,
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("updateSellerIntegrationAttributes", error);
    }
  }

  async getSeller(sellerId: string): Promise<Seller> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}`,
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("getSeller", error);
    }
  }

  async getShopifyIntegrationStatus(shopDomain: string): Promise<DeejayIntegrationStatus | null> {
    try {
      return await this.v1ApiClient.get({
        url: `/shopify-integration-status`,
        params: { shopDomain },
        authentication: isAuthenticated,
      });
    } catch (error) {
      if (error?.response?.status === 404) {
        return null;
      }
      throw this.newOnboardingError("getShopifyIntegrationStatus", error);
    }
  }

  async setShopifyIntegrationStatus(params: {
    shopDomain: string;
    newStatus: DeejayIntegrationStatus;
    sellerId: string;
  }): Promise<DeejayIntegrationStatus | null> {
    try {
      return await this.v1ApiClient.put({
        params,
        url: `/shopify-integration-status`,
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("getShopifyIntegrationStatus", error);
    }
  }

  async getShopPromiseProgram(sellerId: string, shop: string): Promise<ShopPromise> {
    try {
      return await this.apiClient.get({
        url: `/shopify/promise/seller/${sellerId}/shop/${shop}/enabled`,
        params: {},
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("getShopPromiseProgram", error);
    }
  }

  async getDeliveryPromiseProvider(sellerId: string, shop: string): Promise<DeliveryPromiseProvider> {
    try {
      return await this.apiClient.get({
        url: `/shopify/promise/seller/${sellerId}/shop/${shop}/provider/fetch`,
        params: {},
        authentication: isAuthenticated,
      });
    } catch (error) {
      throw this.newOnboardingError("getDeliveryPromiseProvider", error);
    }
  }

  protected newOnboardingError(name: string, err: AxiosError): Error {
    if (!err.response) {
      return new DeliverrError({
        code: ApiError.UNKNOWN_ERROR,
        functionName: name,
        payload: err,
      });
    }

    if (err.response?.status !== undefined && err.response.status === 504) {
      const networkError = new DeliverrError({
        code: ApiError.NETWORK_ERROR,
        functionName: name,
      });
      return networkError;
    }

    return new DeliverrError({
      code: err.response.data ? err.response.data.code : ApiError.UNKNOWN_ERROR,
      subcode: String(err.response.status), // TODO use response.data.error.subcode when error handling is standardized
      functionName: name,
      payload: err.response.data,
    });
  }
}
