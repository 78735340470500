import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { fetchRmtsAction, goToRmtStep } from "../../store/action/RmtActions";
import { useDispatch, useSelector } from "react-redux";

import { ReturnLoader } from "returns/ReturnsLoader";
import { RmtErrorMessages } from "./../../store/action/RmtActions";
import { RmtStepId } from "returns/rmt/store/RmtReducer";
import { SellerRmtMinimal } from "@deliverr/returns-client";
import { getSellerId } from "common/user/UserSelectors";
import log from "Logger";
import { returnsClient } from "Clients";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";
import { useModal } from "common/hooks/useModal";

export const useManageRmtCard = (rmt: SellerRmtMinimal) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const deliverrSellerId = useSelector(getSellerId);
  const disconnectRmtModalId = "disconnectRmtModalId";
  const { showModal: showDisconnectModal, hideModal: hideDisconnectModal } = useModal(disconnectRmtModalId);

  const onUpdateCredentialsClick = () => {
    dispatch(goToRmtStep(RmtStepId.SETUP_RMT));
  };

  const onConnectRmtClick = async () => {
    const ctx = { fn: "connectRmt" };
    dispatch(addLoader(ReturnLoader.ReturnsRmtLoader));
    try {
      await returnsClient.updateRmtInfoBySellerIdAndRmtId(deliverrSellerId, { isActive: true }, rmt.rmtId);
      dispatch(fetchRmtsAction());
    } catch (err) {
      log.error({ ...ctx, err }, `error activating rmt : ${rmt.rmtId}`);
      const errorMessage = formatMessage(RmtErrorMessages.connectRmt);

      toast.error(errorMessage, {
        autoClose: 5000,
        toastId: "connectRmt",
      });
    } finally {
      dispatch(clearLoader(ReturnLoader.ReturnsRmtLoader));
    }
  };

  const onDisconnectRmtClick = async () => {
    const ctx = { fn: "disconnectRmt" };
    dispatch(addLoader(ReturnLoader.ReturnsRmtLoader));
    try {
      await returnsClient.updateRmtInfoBySellerIdAndRmtId(deliverrSellerId, { isActive: false }, rmt.rmtId);
      dispatch(fetchRmtsAction());
      hideDisconnectModal();
    } catch (err) {
      log.error({ ...ctx, err }, `error deactivating rmt : ${rmt.rmtId}`);
      const errorMessage = formatMessage(RmtErrorMessages.disconnectRmt);

      toast.error(errorMessage, {
        autoClose: 5000,
        toastId: "disconnectRmt",
      });
    } finally {
      dispatch(clearLoader(ReturnLoader.ReturnsRmtLoader));
    }
  };

  return {
    onConnectRmtClick,
    onDisconnectRmtClick,
    onUpdateCredentialsClick,
    showDisconnectModal,
    hideDisconnectModal,
  };
};
