import { createActionCreator } from "common/ReduxUtils";
import { PrepActionTypes } from "prep/store/PrepActionTypes";
import { PrepCreationSource } from "prep/types/PrepCreationSource";

/**
 * @param source the current source of prep creation - Inbounds / Replenishment
 */
export const setCreationSource = createActionCreator<PrepCreationSource>(
  PrepActionTypes.SET_PREP_CREATION_SOURCE,
  "source"
);
