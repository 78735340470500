import { StorageWarehouseSellerView } from "storage/inbounds/create/selectDestinationV2/StorageWarehouseSellerView";

export const checkIfWarehousesHaveSameZipCode = (
  warehouse: StorageWarehouseSellerView,
  storageWarehouseToBeShown: StorageWarehouseSellerView[]
): boolean => {
  return !!storageWarehouseToBeShown.find(
    (storageWarehouse) =>
      storageWarehouse.id !== warehouse.id &&
      storageWarehouse.address.zip === warehouse.address.zip &&
      storageWarehouse.address.city === warehouse.address.city
  );
};
