import React, { useState, FC } from "react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { LinkButton } from "../LinkButton";
import { PRODUCT_CHOOSER_LABELS } from "./ProductChooser.labels";
import { ProductChooserKittedProducts } from "./ProductChooserReducer";
import { DefaultTheme } from "../ui";

interface KittedComponentsProps {
  dsku: string;
  inventory?: ProductChooserKittedProducts;
}

const ProductDetails = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL[200]};
    font-size: ${theme.font.size.F1};
    margin-left: ${theme.spacing.S1};
  `
);

const StyledLabel = styled.label`
  margin-bottom: 0;
`;

export const KittedComponents: FC<KittedComponentsProps> = ({ dsku, inventory }) => {
  const [isComponentsVisible, setComponentsVisibility] = useState(false);
  const components = inventory?.[dsku].kitComponents ?? [];

  const toggleOpen = () => {
    setComponentsVisibility(!isComponentsVisible);
  };

  if (!components.length) {
    return null;
  }

  return (
    <div>
      <LinkButton onClick={toggleOpen}>
        <FormattedMessage
          {...(isComponentsVisible
            ? PRODUCT_CHOOSER_LABELS.selectedProduct.kitComponent.hideHeader
            : PRODUCT_CHOOSER_LABELS.selectedProduct.kitComponent.showHeader)}
          values={{ qty: components.length }}
        />
      </LinkButton>
      {isComponentsVisible &&
        components.map((component) => (
          <ProductDetails key={component.dsku}>
            <StyledLabel>
              <FormattedMessage
                {...PRODUCT_CHOOSER_LABELS.selectedProduct.kitComponent.details}
                values={{ units: component.qty, componentName: component.name }}
              />
            </StyledLabel>
          </ProductDetails>
        ))}
    </div>
  );
};
