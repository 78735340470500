import React, { FC } from "react";
import { FormattedPlural, FormattedMessage, FormattedNumber } from "react-intl";

interface BoxCountMessageProps {
  boxCount?: number;
}

export const BoxCountMessage: FC<BoxCountMessageProps> = ({ boxCount = 0 }) => (
  <>
    <FormattedNumber value={boxCount} />{" "}
    <FormattedPlural
      value={boxCount}
      one={<FormattedMessage id="box" defaultMessage="box" />}
      other={<FormattedMessage id="boxes" defaultMessage="boxes" />}
    />
  </>
);
