import React from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "common/components/ui";

export enum ToastTypes {
  boxLabels = "labels",
  barcodes = "barcodes",
}

export const generatingToasts = (toastType: ToastTypes, closeDelay?: number) => {
  const message = (
    <FormattedMessage
      id={`generating-${toastType}`}
      defaultMessage={`We're generating your {toastType} now. It may take a few minutes to complete.`}
      values={{ toastType }}
    />
  );

  toast.info(message, {
    autoClose: closeDelay,
    toastId: `generating-${toastType}`,
  });
};
