import { getItemFromLocalStorageAndParse, LocalStorageKey } from "common/LocalStorage";
import { UserActionTypes } from "common/user/UserActions";

export const fetchBoxLabelsDefaults = () => (dispatch) => {
  const boxContentsPreferences: { format: string; language: string } | undefined = getItemFromLocalStorageAndParse(
    LocalStorageKey.BoxContentsPreferences
  );
  if (!boxContentsPreferences) {
    return;
  }
  dispatch({
    type: UserActionTypes.SET_BOX_LABELS_DEFAULTS,
    format: boxContentsPreferences.format,
    language: boxContentsPreferences.language,
  });
};
