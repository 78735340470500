import { DeliverrAddress } from "@deliverr/commons-objects";
import { Step } from "common/components/StepContainer";
import {
  wholesaleCreateAddNotes,
  wholesaleCreateChooseOriginWarehouse,
  wholesaleCreateChooseShippingConfig,
  wholesaleCreateReset,
  wholesaleSelectDestinationAddress,
  wholesaleSetPoNumber,
} from "../actions/WholesaleCreateActions";
import { WholesaleSelectAddressStep } from "./SelectAddressStep";
import { WholesalePickProductStep } from "./SelectProductsStep";
import { ConfirmStep } from "./ConfirmStep";
import { SelectProductQuantityStep } from "./SelectProductsQuantityStep";
import { selectWholesaleCreate } from "../store/WholesaleCreateSelectors";
import { createOrUpdateWholesaleOrder } from "../actions/createOrUpdateWholesaleOrder";
import { WholesaleShipmentType, WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { WholesalePickShippingStep } from "./WholesalePickShippingStep";
import { noop } from "lodash";
import { LabelsStep } from "./LabelsStep";
import { submitWholesaleOrder } from "../actions/submitWholesaleOrder";
import { WholesalePickStorageWarehouseStep } from "./WholesalePickStorageWarehouseStep";
import { WholesaleCreateState } from "../store/WholesaleCreateState";
import { batch } from "react-redux";
import { patchWholesaleOrder } from "../actions/patchWholesaleOrder";
import { fetchStorageProductsCaseInfo } from "../store/fetchStorageProductsCaseInfo";
import { SharedConfirmedStep } from "common/wholesale-common/components/SharedConfirmedStep";
import { wholesaleCreateLabels } from "../wholesaleCreate.labels";
import history from "BrowserHistory";
import { searchWholesaleOrders } from "wholesale/list/search/searchWholesaleOrders";

export enum WholesaleSteps {
  SelectAddress = "selectAddress",
  SelectProduct = "selectProduct",
  SelectQuantities = "SelectQuantities",
  SelectStorageWarehouse = "SelectStorageWarehouse",
  SelectShippingMethod = "selectShippingMethod",
  Labels = "uploadLabels",
  Confirm = "confirm",
  Confirmed = "confirmed",
}

export const WholesaleCreateInitialStep = WholesaleSteps.SelectAddress;

export const createWholesaleSteps: (dispatch, getState) => Record<string, Step> = (dispatch, getState) => ({
  [WholesaleSteps.SelectAddress]: {
    component: WholesaleSelectAddressStep,
    async next({ address, poNumber }: { address: DeliverrAddress; poNumber: WholesaleCreateState["poNumber"] }) {
      batch(() => {
        dispatch(wholesaleSelectDestinationAddress(address));
        dispatch(wholesaleSetPoNumber(poNumber));
      });
      return WholesaleSteps.SelectProduct;
    },
    back() {
      dispatch(wholesaleCreateReset());
      history.goBack();
    },
  },
  [WholesaleSteps.SelectProduct]: {
    component: WholesalePickProductStep,
    async next() {
      const { availableInventory, selectedProducts } = selectWholesaleCreate(getState());
      await dispatch(fetchStorageProductsCaseInfo(Object.keys(selectedProducts)));
      if (availableInventory.length === 1) {
        dispatch(wholesaleCreateChooseOriginWarehouse(availableInventory[0]));
        return WholesaleSteps.SelectQuantities;
      }
      return WholesaleSteps.SelectStorageWarehouse;
    },
  },
  [WholesaleSteps.SelectStorageWarehouse]: {
    component: WholesalePickStorageWarehouseStep,
    next: async () => WholesaleSteps.SelectQuantities,
  },
  [WholesaleSteps.SelectQuantities]: {
    component: SelectProductQuantityStep,
    next: async () => {
      createOrUpdateWholesaleOrder(dispatch, getState).then(noop, noop);
      return WholesaleSteps.SelectShippingMethod;
    },
  },

  [WholesaleSteps.SelectShippingMethod]: {
    component: WholesalePickShippingStep,
    async next(value: {
      selectedShippingMethod: WholesaleShippingMethod;
      selectedCargo: WholesaleShipmentType;
      selectedShipByDate?: Date;
    }) {
      const shipBy = value.selectedShipByDate ? value.selectedShipByDate.toISOString() : undefined;
      dispatch(
        wholesaleCreateChooseShippingConfig({
          shippingMethod: value.selectedShippingMethod,
          cargoType: value.selectedCargo,
          shipBy,
        })
      );
      await patchWholesaleOrder(dispatch, getState);
      return WholesaleSteps.Labels;
    },
  },

  [WholesaleSteps.Labels]: {
    component: LabelsStep,
    async next({ additionalNotes }: { additionalNotes: string }) {
      dispatch(wholesaleCreateAddNotes(additionalNotes));
      patchWholesaleOrder(dispatch, getState).then(noop, noop);
      return WholesaleSteps.Confirm;
    },
  },

  [WholesaleSteps.Confirm]: {
    component: ConfirmStep,
    async next() {
      if (await submitWholesaleOrder(dispatch, getState)) {
        dispatch(searchWholesaleOrders());
        return WholesaleSteps.Confirmed;
      }
      return;
    },
  },

  [WholesaleSteps.Confirmed]: {
    component: SharedConfirmedStep(wholesaleCreateLabels),
    hasNoBack: true,
  },
});
