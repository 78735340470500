import log from "Logger";
import history from "BrowserHistory";
import { SPThunkAction } from "common/ReduxUtils";
import { RootState } from "RootReducer";
import { fulfillmentInboundStepToPath } from "inbounds/navigation/fulfillment/fulfillmentInboundStepToPath";
import { setCurrentStep } from "inbounds/store/actions/navigation/setCurrentStep";
import { InboundStep } from "inbounds/InboundTypes";

const supplyInboundPathParams = (path: string, shippingPlanId?: number, shipmentId?: number): string =>
  path
    .replace(":shippingPlanId", shippingPlanId ? shippingPlanId.toString() : "")
    .replace(":shipmentId", shipmentId ? shipmentId.toString() : "");

export const goToInboundStep = (step: InboundStep, replace: boolean = false): SPThunkAction => (
  dispatch,
  getState: () => RootState
) => {
  log.info({ fn: "goToInboundStep", step }, `sending to ${step}`);
  const {
    inbound: { plan, loadedShipmentId },
  } = getState();
  dispatch(setCurrentStep(step));

  const stepPath = fulfillmentInboundStepToPath[step];

  const destinationPath = supplyInboundPathParams(stepPath, plan.id, loadedShipmentId);
  if (replace) {
    history.replace(destinationPath);
  } else {
    history.push(destinationPath);
  }
};
