import { ShipmentAttachmentResponse, ShipmentResponse } from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { keyBy } from "lodash";
import { replenishmentOrderDeatilsSetShipmentAttachmentDataMap } from "transfers/detail/store/replenishment/ReplenishmentOrderDetailsActions";
import { ShipmentAttachmentData } from "transfers/detail/store/replenishment/ReplenishmentOrderDetailsState";

export const getAndSetReplenishmentOrderShipmentAttachmentState = async (shipments: ShipmentResponse[], dispatch) => {
  const shipmentAttachmentsList: ShipmentAttachmentData[] = await Promise.all(
    (shipments ?? [])?.map(async (shipment) => {
      const shipmentAttachment: ShipmentAttachmentResponse[] = (
        await replenishmentClient.getDownloadUrlsForShipmentAttachments(shipment.shipmentId)
      ).value;
      return {
        shipmentId: shipment.shipmentId,
        shipmentAttachment: keyBy(shipmentAttachment, "attachmentType"),
      };
    })
  );
  const shipmentAttachmentDataMap: { [shipment: string]: ShipmentAttachmentData } = keyBy(
    shipmentAttachmentsList,
    "shipmentId"
  );
  dispatch(replenishmentOrderDeatilsSetShipmentAttachmentDataMap(shipmentAttachmentDataMap));
};
