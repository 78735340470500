export enum WholesaleListActionTypes {
  WHOLESALE_LIST_GET_LOADING = "WHOLESALE_LIST_GET_LOADING",
  WHOLESALE_LIST_GET_SUCCESS = "WHOLESALE_LIST_GET_SUCCESS",
  WHOLESALE_LIST_GET_ERROR = "WHOLESALE_LIST_GET_ERROR",
  WHOLESALE_LIST_CHANGE_PAGE_NUM = "WHOLESALE_LIST_CHANGE_PAGE_NUM",
  WHOLESALE_LIST_CHANGE_SEARCH_TERM = "WHOLESALE_LIST_CHANGE_SEARCH_TERM",
  WHOLESALE_LIST_CHANGE_STATUS_FILTERS = "WHOLESALE_LIST_CHANGE_STATUS_FILTERS",
  WHOLESALE_LIST_CHANGE_INGESTION_CHANNEL_FILTERS = "WHOLESALE_LIST_CHANGE_INGESTION_CHANNEL_FILTERS",
  WHOLESALE_LIST_SET_TAB_FILTER = "WHOLESALE_LIST_SET_TAB_FILTER",
}
