import styled from "@emotion/styled";
import { DefaultTheme, IconV2, Pill, Stack, ThemeProps } from "common/components/ui";
import React, { ReactElement } from "react";
import visa from "assets/img/billing/visa.svg";
import mastercard from "assets/img/billing/mastercard.svg";
import american_express from "assets/img/billing/american_express.svg";
import discover from "assets/img/billing/discover.svg";
import bank from "assets/img/billing/bank.svg";
import paypal from "assets/img/billing/paypal.svg";
import HtmlToReact from "common/components/HtmlToReact";
import { LocalizedText } from "common/components/LocalizedText";
import { MessageDescriptor, defineMessages, useIntl } from "react-intl";
import { CardBrand, PaymentType, usePrimaryPaymentSource } from "billing/PrimaryPaymentSource/usePrimaryPaymentSource";
import { LoadingSpinner } from "common/components/LoadingSpinner";

const Tile = styled.div<ThemeProps>(
  ({ theme }) => `
    border-radius: ${theme.border.radius.R4};
    border: ${`${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL["60"]}`};
    padding: ${theme.spacing.S3};
  `
);
const Logo = styled(HtmlToReact)`
  width: 2rem;
  height: 2rem;
`;
const VisaLogo = styled(Logo)(
  () => `
    height: 1.4rem;
  `
);
const BankLogo = styled(Logo)(
  () => `
      width: 1rem;
      height: 1rem;
    `
);
const PaypalLogo = styled(Logo)(
  () => `
      width: 1rem;
      height: 1rem;
    `
);
const CardLogo = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    width: 2rem;
    height: 1.25rem;
  `
);
const PrimaryPill = styled(Pill)<ThemeProps>(
  ({ theme }) => `
    border-radius: 6px;
    padding: 1px 10px;
    background-color: ${theme.colors.NEUTRAL["500"]};
    border-color: ${theme.colors.NEUTRAL["500"]};
    color: ${theme.colors.NEUTRAL["00"]};
  `
);

const PAYMENT_LOGOS: Record<PaymentType | CardBrand, ReactElement> = {
  visa: <VisaLogo html={visa} />,
  mastercard: <Logo html={mastercard} />,
  american_express: <Logo html={american_express} />,
  discover: <Logo html={discover} />,
  CARD: (
    <CardLogo>
      <IconV2 type="credit-card" />
    </CardLogo>
  ),
  BANK_ACCOUNT: <BankLogo html={bank} />,
  PAYPAL: <PaypalLogo html={paypal} />,
};

const PAYMENT_LABELS: Record<PaymentType, MessageDescriptor> = defineMessages({
  CARD: {
    id: "shipment.shippingSelection.payment.primaryPaymentLabel.card",
    defaultMessage: "ending {lastDigits}",
  },
  BANK_ACCOUNT: {
    id: "shipment.shippingSelection.payment.primaryPaymentLabel.bankAccount",
    defaultMessage: "ending {lastDigits}",
  },
  PAYPAL: {
    id: "shipment.shippingSelection.payment.primaryPaymentLabel.paypal",
    defaultMessage: "{email}",
  },
});

interface PaymentSourceProps {
  paymentType: PaymentType;
  lastDigits?: string;
  brand?: string;
  email?: string;
}

export const PrimaryPaymentSource = () => {
  const { loaded, isCreditCardFeeEnabled, paymentType, lastDigits, email, brand } = usePrimaryPaymentSource();
  if (!isCreditCardFeeEnabled) {
    return null;
  }
  if (!loaded) {
    return <LoadingSpinner center />;
  }
  return paymentType ? (
    <UnconnectedPrimaryPaymentSource paymentType={paymentType} lastDigits={lastDigits} email={email} brand={brand} />
  ) : null;
};

const UnconnectedPrimaryPaymentSource = ({ paymentType, lastDigits, email, brand }: PaymentSourceProps) => {
  const { formatMessage } = useIntl();
  let paymentLogo: ReactElement | null = null;
  if (paymentType === "BANK_ACCOUNT" || paymentType === "PAYPAL") {
    paymentLogo = PAYMENT_LOGOS[paymentType];
  } else if (paymentType === "CARD") {
    if (brand && PAYMENT_LOGOS[brand]) {
      paymentLogo = PAYMENT_LOGOS[brand];
    } else {
      paymentLogo = PAYMENT_LOGOS[paymentType];
    }
  }
  return (
    <Tile>
      <Stack direction="HORIZONTAL" gap="S3" alignItems="center">
        {paymentLogo}
        <LocalizedText
          size="caption"
          bold
          appearance="INFO"
          message={{
            ...PAYMENT_LABELS[paymentType],
            values: { lastDigits, email },
          }}
        />
        <PrimaryPill>
          {formatMessage({
            id: "shipment.shippingSelection.payment.primaryPaymentSource.primary",
            defaultMessage: "PRIMARY",
          })}
        </PrimaryPill>
      </Stack>
    </Tile>
  );
};
