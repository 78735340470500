import React from "react";
import { Appearance, Stack, Text, ThemeProps } from "common/components/ui";
import { components } from "react-select";
import styled from "@emotion/styled";
import { StorageWarehouseSellerView } from "storage/inbounds/create/selectDestinationV2/StorageWarehouseSellerView";
import { getWarehouseAddress } from "./getWarehouseAddress";
import { getStorageWarehouseDiscountString } from "./getStorageWarehouseDiscountString";

const StyledDiv = styled.div<ThemeProps>(
  ({ theme }) => `
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: ${theme.font.size.F2};
  color: ${theme.colors.NEUTRAL[100]};
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
`
);

export const StorageDestinationV2SelectCustomOption = (props) => {
  const { children, label, isDisabled, hasSameZipCode } = props.data;
  const value = props.data.value as StorageWarehouseSellerView;

  const style: React.CSSProperties = {
    ...props.getStyles("option", props),
    pointerEvents: value.hasAvailablePalletCapacity ? undefined : "none",
    cursor: value.hasAvailablePalletCapacity ? undefined : "not-allowed",
    color: value.hasAvailablePalletCapacity ? undefined : "lightgray",
  };

  return (
    <components.Option {...props} getStyles={() => style}>
      <Stack gap="S1">
        <div style={{ position: "relative" }}>
          {children}
          {isDisabled && <StyledDiv>Not available</StyledDiv>}
        </div>
        <Text as="header" bold>
          {label}
        </Text>
        <Text appearance={Appearance.INFO}>
          <span style={{ color: value.hasAvailablePalletCapacity ? "inherit" : "lightgray" }}>
            {getWarehouseAddress(value, hasSameZipCode)}
          </span>
        </Text>
        {value.discount && (
          <Text appearance={Appearance.SUCCESS}>{getStorageWarehouseDiscountString(value.discount)}</Text>
        )}
      </Stack>
    </components.Option>
  );
};
