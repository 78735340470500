import produce from "immer";
import { ReducerDictionary, createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { PrepActionTypes, PrepStoreState } from "prep/store";
import { processPrepJobResponse } from "prep/util";
import { PrepCreationSource } from "prep/types/PrepCreationSource";

export const prepStoreInitialState: PrepStoreState = {
  selectedBclOption: undefined,
  prepRequest: undefined,
  floorLoadedContainerDetails: undefined,
  source: PrepCreationSource.INBOUNDS,
};

export const reducers: ReducerDictionary<PrepStoreState> = {
  ...handleSimpleReducerUpdates([
    PrepActionTypes.SET_FLOOR_LOADED_CONTAINER_DETAILS,
    PrepActionTypes.SET_PREP_CREATION_SOURCE,
  ]),
  [PrepActionTypes.SET_BCL_SELECTION]: (state, { selectedBclOption, shipmentId }) => {
    if (!shipmentId) {
      return state;
    }

    return produce(state, (draft) => {
      draft.selectedBclOption = selectedBclOption;
    });
  },
  [PrepActionTypes.SET_PREP_REQUEST]: (state, { prepRequest }) => {
    return produce(state, (draft) => {
      draft.prepRequest = prepRequest;
    });
  },
  [PrepActionTypes.SET_PREP_JOB]: (state, { prepJob }) => {
    return produce(state, (draft) => {
      draft.prepJob = prepJob ? processPrepJobResponse(prepJob) : undefined;
    });
  },
};

export const prepStoreReducer = createReducer<PrepStoreState>(prepStoreInitialState, reducers);
