import { StorageItem } from "common/clients/storage/StorageItem/StorageItem";
import { isEmpty, reduce, uniq, values } from "lodash";
import { getDefaultPackFromProduct } from "./getDefaultPackFromProduct";
import { fetchMultiPackProductsByDskus } from "storage/utils/fetchMultiPackProductsByDskus";

// maps and aggregates order items with a mix of product and pack skus to only pack skus
export const mapStorageItemsToPackSkus = async (items: StorageItem[], dispatch): Promise<StorageItem[]> => {
  if (isEmpty(items)) {
    return [];
  }

  const dskus = uniq(items?.map(({ dsku }) => dsku) ?? []);
  const draftProducts = await fetchMultiPackProductsByDskus(dskus, dispatch);

  const productSkuToDefaultPackSku = dskus?.reduce((agg, dsku) => {
    const product = draftProducts[dsku];

    if (product && !product.packOf) {
      agg[product.dsku] = getDefaultPackFromProduct(product)?.dsku;
    }
    return agg;
  }, {});

  const itemsWithPackSkus: StorageItem[] =
    items?.map((item) => {
      const defaultPackSku = productSkuToDefaultPackSku[item.dsku];
      const dsku = defaultPackSku ?? item.dsku;
      return {
        ...item,
        dsku,
      };
    }) ?? [];

  const combinedPackSkus = reduce(
    itemsWithPackSkus,
    (itemsByPackSkus, item) => {
      itemsByPackSkus[item.dsku] = {
        ...item,
        expectedQty: item.expectedQty + (itemsByPackSkus[item.dsku]?.expectedQty ?? 0),
      };
      return itemsByPackSkus;
    },
    {} as { [dsku: string]: StorageItem }
  );

  return values(combinedPackSkus);
};
