export enum ReplenishmentOrderDetailsActionTypes {
  REPLENISHMENT_SET_REPLENISHMENT_ORDER = "REPLENISHMENT_SET_REPLENISHMENT_ORDER",
  REPLENISHMENT_SET_SHIPMENT_HISTORY = "REPLENISHMENT_SET_SHIPMENT_HISTORY",
  REPLENISHMENT_SET_PRODUCTS = "REPLENISHMENT_SET_PRODUCTS",
  REPLENISHMENT_SET_SHIPMENT_ATTACHMENTS = "REPLENISHMENT_SET_SHIPMENT_ATTACHMENTS",
  REPLENISHMENT_SET_ORDER_COSTS = "REPLENISHMENT_SET_ORDER_COSTS",
  REPLENISHMENT_RESET_REPLENISHMENT_ORDER = "REPLENISHMENT_RESET_REPLENISHMENT_ORDER",
  REPLENISHMENT_SET_ORDER_NON_COMPLIANCES = "REPLENISHMENT_SET_ORDER_NON_COMPLIANCES",
  REPLENISHMENT_UPDATE_NON_COMPLIANCE = "REPLENISHMENT_UPDATE_NON_COMPLIANCE",
  REPLENISHMENT_SET_NON_COMPLIANT_ORDERS_COUNT = "REPLENISHMENT_SET_NON_COMPLIANT_ORDERS_COUNT",
}
