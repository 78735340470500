import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { calculateAvailableQuantity } from "common/utils/product/calculateAvailableQuantity";
import { ProductListItem } from "inventory/list/InventoryListReducer";
import { Text } from "common/components/ui/Text";
import { ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";

interface Props {
  product: ProductListItem;
}

const StyledText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[200]};
  font-size: ${theme.font.size.F1};
`
);

export const ProductEcomDetails: FC<Props> = ({ product }) => {
  const { unitsSoldLast30Days = 0 } = product;
  return (
    <>
      <StyledText>
        <FormattedMessage
          id="productSearchOption.ecomInventory"
          defaultMessage="{availableQty} units available in Flexport Ecom"
          values={{
            availableQty: calculateAvailableQuantity(product),
          }}
        />
      </StyledText>
      <StyledText>
        <FormattedMessage
          id="productSearchOption.unitsSoldLast30Days"
          defaultMessage="{unitsSoldLast30Days} units sold on Ecom in 30 days"
          values={{
            unitsSoldLast30Days,
          }}
        />
      </StyledText>
    </>
  );
};
