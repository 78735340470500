import { filter, find, flatMap, includes, isEmpty, isEqual, keys, map, values } from "lodash";
import { useEffect, useState } from "react";
import { useMount } from "react-use";
import { toast } from "common/components/ui";
import useProductCompliance from "../compliance/useProductCompliance";
import { BarcodeEditErrors } from "barcodes/BarcodeEditReducer";
import {
  BarcodeCollections,
  bulkSerializeBarcodes,
  serializeBarcodeServiceErrors,
  SerializedBarcodes,
} from "barcodes/serializer";
import { BarcodeEditChangeResult } from "barcodes/useBarcodeEdit";
import { useBarcodeService } from "barcodes/useBarcodeService";
import { useModal } from "common/hooks/useModal";
import { ProductCollection } from "common/models/Product";
import InboundModalId from "inbounds/InboundModalId";
import { useDispatch } from "react-redux";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import InboundLoaderId from "inbounds/InboundLoaderId";

// to avoid expensive component updates we temporarily store new values in this local cache.
let newBarcodesCache: BarcodeCollections = {};

export function checkDuplicatedBarcodesInPage(barcodeDraft: BarcodeCollections) {
  const allBarcodesInPage = flatMap(map(values(barcodeDraft), (draftItem) => map(draftItem, "value")));

  return filter(allBarcodesInPage, (val, i, iteratee) => includes(iteratee, val, i + 1));
}

export const shouldDisableNextButton = (
  products: ProductCollection,
  barcodeDraft: BarcodeCollections,
  duplicatedBarcodes: string[]
) => {
  const dskus = keys(products);
  const findInvalidBarcodeInDSKU = (dsku: string) =>
    find(
      barcodeDraft[dsku],
      ({ valid, value }) => isEmpty(value) || valid === false || valid === BarcodeEditErrors.FORMAT
    );
  const noBarcodeToSave = (dsku: string) => !barcodeDraft || isEmpty(barcodeDraft[dsku]);
  const noBarcodeSaved = (dsku: string) => isEmpty(products[dsku]) || isEmpty(products[dsku].barcodes);
  const foundInvalidCase = find(
    dskus,
    (dsku) =>
      ((noBarcodeToSave(dsku) && noBarcodeSaved(dsku)) || findInvalidBarcodeInDSKU(dsku)) ?? duplicatedBarcodes.length
  );

  return Boolean(foundInvalidCase);
};

export function useBarcodeInputStep(goToNextStep, goToPreviousStep, productDetailsCache) {
  useProductCompliance();
  const dispatch = useDispatch();

  const [barcodeValues, setBarcodeValues] = useState<SerializedBarcodes>({});
  const [pageDuplicatedBarcodes, setDuplicated] = useState<string[]>(checkDuplicatedBarcodesInPage(newBarcodesCache));
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(
    shouldDisableNextButton(productDetailsCache, newBarcodesCache, pageDuplicatedBarcodes)
  );

  const confirmation = useModal(InboundModalId.BarcodeConfirm);
  const { barcodeSubmitResult, submitBarcodes } = useBarcodeService();

  const onNextClick = async () => {
    const currentValues = bulkSerializeBarcodes(newBarcodesCache);

    if (!isEqual(currentValues, barcodeValues)) {
      setBarcodeValues(currentValues);
    }

    if (!isEmpty(currentValues)) {
      confirmation.showModal();
      return;
    }

    dispatch(addLoader(InboundLoaderId.transition));
    await goToNextStep();
    dispatch(clearLoader(InboundLoaderId.transition));
  };

  const onBarcodeWarningConfirm = async () => {
    confirmation.hideModal();
    await submitBarcodes(barcodeValues);
  };

  const onBarcodeChange = (result: BarcodeEditChangeResult) => {
    // if some service error occurs behind the scenes and modal is open
    if (confirmation.isActive) {
      confirmation.hideModal();
    }

    Object.assign(newBarcodesCache, result);

    const checkPageDuplicated = checkDuplicatedBarcodesInPage(newBarcodesCache);
    const shouldDisableNext = shouldDisableNextButton(productDetailsCache, newBarcodesCache, checkPageDuplicated);

    if (isNextDisabled !== shouldDisableNext) {
      setIsNextDisabled(shouldDisableNext);
    }

    if (checkPageDuplicated.length !== pageDuplicatedBarcodes.length) {
      setDuplicated(checkPageDuplicated);
    }
  };

  const barcodeSaveError = serializeBarcodeServiceErrors(barcodeSubmitResult);

  useEffect(() => {
    const { error = null, loading: isLoading, value } = barcodeSubmitResult;

    if (error) {
      // this error is already logged in useBarcodeService
      toast.error("Unexpected Error saving barcodes");
    } else if (!isLoading && value && keys(barcodeSaveError).length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      goToNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barcodeSubmitResult]);

  useMount(() => {
    newBarcodesCache = {};
  });

  return {
    barcodeValues,
    barcodeSaveError,
    productDetailsCache,
    isNextDisabled,
    onBarcodeWarningConfirm,
    onNextClick,
    onBarcodeChange,
    pageDuplicatedBarcodes,
    onPreviousClick: goToPreviousStep,
  };
}
