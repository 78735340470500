import { useIntl } from "react-intl";
import { ReplenishmentListLabels } from "./ReplenishmentListLabels";
import { isMultiShipmentReplenishmentOrder } from "./ReplenishmentListUtils";
import { ReplenishmentIndexRow } from "./ReplenishmentIndexRow";
import { map, uniq } from "lodash";
import { OrderStatus } from "@deliverr/replenishment-client/lib/dtos";
import { useDispatch } from "react-redux";
import { replenishmentListPageOnRowClick } from "./replenishmentListPageOnRowClick";

export const useReplenishmentListTable = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const onRowClick = async (item: ReplenishmentIndexRow) => {
    await replenishmentListPageOnRowClick(item, formatMessage, dispatch);
  };

  const getPoNumber = (item: ReplenishmentIndexRow): string => {
    if (isMultiShipmentReplenishmentOrder(item)) {
      return formatMessage(ReplenishmentListLabels.multiple);
    }

    return (
      item.shipments?.[0]?.poNumber ??
      item.shipments?.[0]?.distributionChannelShipmentId ??
      item.shipments?.[0]?.shipmentId ??
      item.distributionChannelOrderId ??
      ""
    );
  };

  const getSkuCount = (item: ReplenishmentIndexRow) => {
    return uniq(map(item.orderItems, (orderItem) => orderItem.packOf)).length;
  };

  const getTotalOrderQuantity = (item: ReplenishmentIndexRow) => {
    return item.orderItems.reduce((acc, orderItem) => acc + orderItem.totalUnits ?? 0, 0);
  };

  const handleCheckboxCondition = (row) => [OrderStatus.CREATED, OrderStatus.DRAFT].includes(row.status);

  return {
    onRowClick,
    getSkuCount,
    getTotalOrderQuantity,
    handleCheckboxCondition,
    getPoNumber,
  };
};
