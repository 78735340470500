const BASE = 10;

/**
 * Round a number UP to a specified number of decimal points.
 *
 * @param value number to ceil
 * @param numDecimals number of decimal places to ceil at (default: 2)
 */
export const ceilDecimal = (value: number | null, numDecimals: number = 2): number | null => {
  if (!value || numDecimals < 1) {
    return value;
  }

  const scalar = BASE ** numDecimals;
  const result = (Math.ceil(value * scalar) / scalar).toFixed(numDecimals);
  return Number.parseFloat(result);
};
