import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CONTAINER_SIZE_MESSAGES } from "./ContainerSizeMessages";
import { getStorageInboundCreate } from "../../store/selector/getStorageInboundCreate";
import { setContainerSize } from "../actions/setContainerSize";
import { setContainerId } from "../actions/setContainerId";
import { ContainerSize } from "@deliverr/storage-client";

export const useStorageFloorLoadedContainerInput = () => {
  const dispatch = useDispatch();
  const { containerSize, containerId } = useSelector(getStorageInboundCreate);

  const containerSizeOptions = Object.values(ContainerSize).map((containerSizeValue) => ({
    label: <FormattedMessage {...CONTAINER_SIZE_MESSAGES[containerSizeValue]} />,
    value: containerSizeValue,
  }));

  const onContainerSizeSelect = (ev: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setContainerSize(ev.target.value as ContainerSize));
  };

  const onContainerIdUpdate = (ev: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setContainerId(ev.target.value));
  };

  return {
    containerSize,
    containerId,
    containerSizeOptions,
    onContainerSizeSelect,
    onContainerIdUpdate,
  };
};
