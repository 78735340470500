// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/** Flexport colors */\n/** Design system colors as of 8/27/21 **/\n.AdSetsSync__adSetsSyncContainer--irnp0 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  gap: 10px;\n}\n.AdSetsSync__productAccordion--mDMfs {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  gap: 4px;\n}\n.AdSetsSync__adSets--UkwoN {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  gap: 10px;\n  font-size: 14px;\n  text-align: left;\n}\n.AdSetsSync__noName--J8Hc_ {\n  color: #98a6b2;\n}\n", "",{"version":3,"sources":["webpack://./src/app/tags/facebook/common/AdSetsSync/AdSetsSync.less"],"names":[],"mappings":"AAAA,qBAAqB;AACrB,yCAAyC;AACzC;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,4BAAA;EAAA,6BAAA;MAAA,0BAAA;UAAA,sBAAA;EACA,SAAA;AACF;AAEA;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,4BAAA;EAAA,6BAAA;MAAA,0BAAA;UAAA,sBAAA;EACA,QAAA;AAAF;AAGA;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,4BAAA;EAAA,6BAAA;MAAA,0BAAA;UAAA,sBAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;AADF;AAIA;EACE,cAAA;AAFF","sourcesContent":["@import \"~app/common/styles/colors\";\n\n.adSetsSyncContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.productAccordion {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.adSets {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  font-size: 14px;\n  text-align: left;\n}\n\n.noName {\n  color: @lightGray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adSetsSyncContainer": "AdSetsSync__adSetsSyncContainer--irnp0",
	"productAccordion": "AdSetsSync__productAccordion--mDMfs",
	"adSets": "AdSetsSync__adSets--UkwoN",
	"noName": "AdSetsSync__noName--J8Hc_"
};
export default ___CSS_LOADER_EXPORT___;
