export type WishNotAuthenticated = Readonly<{ status: "not authenticated" }>;

export type WishAuthenticated = Readonly<{ status: "authenticated"; code: string }>;

export type WishAuth = WishAuthenticated | WishNotAuthenticated;

export const wishNotAuthenticated: WishNotAuthenticated = { status: "not authenticated" };

export function isWishAuthenticated(auth: WishAuth): auth is WishAuthenticated {
  return auth !== wishNotAuthenticated;
}

export const wishAuthenticated = (code: string): WishAuthenticated => ({ status: "authenticated", code });
