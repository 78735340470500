import React, { FC } from "react";
import { IconV2, DefaultTheme, Text } from "common/components/ui";
import { TooltipWrapper, Tooltip, TooltipSkin } from "common/components/Tooltip";
import styled from "@emotion/styled";
import cls from "./InfoTooltip.less";
import { useInfoTooltip } from "./useInfoTooltip";

export interface InfoTooltipProps {
  message: string | JSX.Element;
  infoIconColor: string;
  timeout?: number;
  width?: number;
  size?: "small" | "medium" | "large";
}

const TooltipTextContainer = styled.div<{ width: number }, DefaultTheme>(
  ({ theme, width }) => `
    width: ${width}px;
    min-height: 40px;
    padding: 10px 0px;
    white-space: normal; 
  `
);

export const InfoTooltip: FC<InfoTooltipProps> = ({ message, infoIconColor, timeout, width = 224, size }) => {
  const { tooltipActive, onMouseOver, onMouseOut } = useInfoTooltip(timeout);

  return (
    <TooltipWrapper className={cls["tooltip-wrapper"]}>
      <IconV2
        type="info-circle"
        data-testid="infoTooltip-icon"
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        color={infoIconColor}
        size={size}
      />
      <Tooltip
        skin={TooltipSkin.PRIMARY}
        arrowReverse
        active={tooltipActive}
        className={{ content: cls["tooltip-content"] }}
        data-testid="infoTooltip-tooltip"
      >
        <TooltipTextContainer data-testid="infoTooltip-container" width={width} onMouseOut={onMouseOut}>
          <Text>{message}</Text>
        </TooltipTextContainer>
      </Tooltip>
    </TooltipWrapper>
  );
};
