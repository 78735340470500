import { storageClient } from "common/clients/instances";
import { SPThunkAction } from "common/ReduxUtils";
import log from "Logger";
import { StorageInboundDetailActionTypes } from "../StorageInboundDetailStore.types";
import { getStorageRates } from "./getStorageRates";
import { loadWarehouses } from "common/deliverr/DeliverrActions";
import { getStorageItemDetails } from "./getStorageItemDetails";
import { toast } from "common/components/ui";
import history from "BrowserHistory";
import { Path } from "paths";
import { FormattedMessage } from "react-intl";
import React from "react";
import { getStorageInboundOrderProblems } from "./getStorageInboundOrderProblems";
import { StorageInboundResponse } from "@deliverr/storage-client";

export const getStorageInboundOrder =
  (shippingPlanId: number, sellerId: string): SPThunkAction =>
  async (dispatch) => {
    try {
      dispatch({ type: StorageInboundDetailActionTypes.STORAGE_GET_DETAILS });
      const storageInboundOrderDetail = await storageClient.getStorageInboundByShippingPlanId(sellerId, shippingPlanId);
      const storageInboundOrder = storageInboundOrderDetail?.value as StorageInboundResponse;
      const { storageItems } = storageInboundOrder;
      await Promise.all([
        dispatch(getStorageInboundOrderProblems(shippingPlanId)),
        dispatch(getStorageItemDetails(storageItems ?? [])),
        dispatch(loadWarehouses([storageInboundOrder.warehouseId])),
        dispatch(getStorageRates()),
      ]);
      dispatch({
        type: StorageInboundDetailActionTypes.STORAGE_GET_DETAILS_SUCCESS,
        storageInboundOrder: { ...storageInboundOrder },
      });
    } catch (err) {
      log.error({ err, shippingPlanId }, "error getting storage details for shippingPlanId");
      dispatch({ type: StorageInboundDetailActionTypes.STORAGE_GET_DETAILS_ERROR, err });
      toast.error(
        <FormattedMessage
          id={"storage.inbounds.getStorageInboundOrder.error"}
          defaultMessage={"Network error, please try again!"}
        />
      );
      history.push(Path.inboundsList);
    }
  };
