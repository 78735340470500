import { DeliverrAddress } from "@deliverr/commons-objects";
import classNames from "classnames/dedupe";
import React, { FC } from "react";
import { Input, PhoneNumberInput } from "common/components/ui";
import { FormattedMessage, useIntl } from "react-intl";
import { emptyAddressWithPhone } from "common/AddressUtils";
import { CountrySelect, SelectableCountries } from "common/components/CountrySelect";
import { addressInputFieldNames } from "./addressInputFieldNames";
import {
  AddressInputContainer,
  CityInput,
  CountryInput,
  StateInput,
  CountrySelectContainer,
  StyledGrid,
  ZipInput,
} from "./AddressInput.styles";
import { StyledPlacesAutocomplete } from "./StyledPlacesAutocomplete";
import { useAddressInput } from "./useAddressInput";
import { AddressSearch } from "./AddressSearch";

interface AddressInputProps {
  address?: DeliverrAddress;
  autoFocus?: boolean;
  showName?: boolean | string;
  hideCompany?: boolean;
  showPhone?: boolean;
  onChange: (address: DeliverrAddress) => void;
  countryAsSelect?: boolean;
  // only used if countryAsSelect is true
  countryOptions?: SelectableCountries;
  disableCountrySelect?: boolean;
  disableAddressAutocomplete?: boolean;
  // only used if showPhone is true and validating phoneNumber
  isValidPhoneNumber?: boolean;
  onPhoneNumberChange?: (phoneNumber: string) => void;
  enableAddressAutocomplete?: boolean;
  showCountry?: boolean;
  showEmail?: boolean;
  disabled?: boolean;
  disableFields?: {
    disableStreet1?: boolean;
    disableStreet2?: boolean;
    disableCity?: boolean;
    disableState?: boolean;
    disableCountry?: boolean;
    disableZip?: boolean;
    disablePhone?: boolean;
    disableName?: boolean;
    disableCompany?: boolean;
    disableEmail?: boolean;
  };
}

export const AddressInput: FC<AddressInputProps> = ({
  address = emptyAddressWithPhone,
  autoFocus,
  showName = true,
  hideCompany = true,
  showPhone,
  onChange,
  countryAsSelect,
  countryOptions = "ALL",
  disableCountrySelect = false,
  disableAddressAutocomplete = false,
  isValidPhoneNumber = true,
  onPhoneNumberChange,
  showCountry = true,
  showEmail = false,
  disabled = false,
  enableAddressAutocomplete = false,
  disableFields = {
    disableStreet1: false,
    disableStreet2: false,
    disableCity: false,
    disableState: false,
    disableCountry: false,
    disableZip: false,
    disablePhone: false,
    disableName: false,
    disableCompany: false,
    disableEmail: false,
  },
}) => {
  const { formatMessage } = useIntl();
  const { isGoogleAPILoaded, isFetching, handleSelect, updateAddress, onStreetChange, handleAddressSearch } =
    useAddressInput(address, countryOptions, onChange, countryAsSelect, showPhone);

  return (
    <AddressInputContainer>
      {enableAddressAutocomplete && <AddressSearch id="search-input-id" onSelectAddress={handleAddressSearch} />}
      {showName && (
        <Input
          autoFocus={!!showName}
          value={address.name}
          onChange={(e) => updateAddress("name", e.target.value)}
          placeholder={showName === true ? formatMessage(addressInputFieldNames.name) : showName}
          data-testid="address_name"
          disabled={disabled || disableFields.disableName}
        />
      )}
      {!hideCompany && (
        <Input
          value={address.company ?? ""}
          onChange={(e) => updateAddress("company", e.target.value)}
          placeholder={formatMessage(addressInputFieldNames.company)}
          data-testid="address_company"
          disabled={disabled || disableFields.disableCompany}
        />
      )}
      {isGoogleAPILoaded && !(disableAddressAutocomplete || disabled || disableFields.disableStreet1) ? (
        <StyledPlacesAutocomplete
          isLoading={isFetching}
          inputProps={{
            value: address.street1,
            onChange: (street1: string) => updateAddress("street1", street1),
            placeholder: formatMessage(addressInputFieldNames.street1),
            "data-testid": "address_street1",
            "data-lpignore": "true",
            type: "text",
            ...(showName ? {} : { autoFocus }),
          }}
          onSelect={handleSelect}
          onEnterKeyDown={handleSelect}
          isIntl={false}
          options={
            countryOptions === "US"
              ? {
                  componentRestrictions: { country: ["us"] },
                }
              : undefined
          }
        />
      ) : (
        <Input
          onChange={onStreetChange}
          value={address.street1}
          placeholder={formatMessage(addressInputFieldNames.street1)}
          data-testid="address_street1"
          disabled={disabled || disableFields.disableStreet1}
        />
      )}
      <Input
        value={address.street2}
        placeholder={formatMessage(addressInputFieldNames.street2)}
        onChange={(e) => updateAddress("street2", e.target.value)}
        disabled={disabled || disableFields.disableStreet2}
      />
      <StyledGrid className={classNames({ withCountry: showCountry })}>
        <CityInput
          value={address.city}
          placeholder={formatMessage(addressInputFieldNames.city)}
          onChange={(e) => updateAddress("city", e.target.value)}
          data-testid="address_city"
          disabled={disabled || disableFields.disableCity}
        />
        <StateInput
          value={address.state}
          placeholder={formatMessage(addressInputFieldNames.state)}
          onChange={(e) => updateAddress("state", e.target.value)}
          data-testid="address_state"
          disabled={disabled || disableFields.disableState}
        />
        {showCountry ? (
          countryAsSelect ? (
            <CountrySelectContainer>
              <CountrySelect
                name="country"
                value={address.country}
                onChange={(val) => updateAddress("country", val)}
                selectableCountries={countryOptions}
                disabled={disabled || disableFields.disableCountry}
              />
            </CountrySelectContainer>
          ) : (
            <CountryInput
              value={address.country}
              placeholder={formatMessage(addressInputFieldNames.country)}
              onChange={(e) => updateAddress("country", e.target.value)}
              data-testid="address_country"
              disabled={disabled || disableFields.disableCountry}
            />
          )
        ) : null}
        <ZipInput
          value={address.zip}
          placeholder={formatMessage(addressInputFieldNames.zip)}
          onChange={(e) => updateAddress("zip", e.target.value)}
          data-testid="address_zip"
          disabled={disabled || disableFields.disableZip}
        />
      </StyledGrid>
      {showPhone ? (
        <PhoneNumberInput
          label={<FormattedMessage id="addressInput.phoneNumber.label" defaultMessage="Phone number" />}
          initialCountry="us"
          value={address.phone}
          disableCountrySelect={disableCountrySelect}
          hasError={!isValidPhoneNumber}
          onChange={(e) => {
            updateAddress("phone", e);
            onPhoneNumberChange?.(e);
          }}
          inputProps={{ "data-testid": "address_phone" }}
          errorMessage={
            <FormattedMessage id="addressInput.phoneNumber.invalid" defaultMessage="Phone number is invalid" />
          }
          disabled={disabled || disableFields.disablePhone}
        />
      ) : null}
      {showEmail ? (
        <Input
          value={address.email}
          placeholder={formatMessage(addressInputFieldNames.email)}
          onChange={(e) => updateAddress("email", e.target.value)}
          disabled={disabled || disableFields.disableEmail}
        />
      ) : null}
    </AddressInputContainer>
  );
};
