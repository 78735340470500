import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { Appearance, Notification } from "common/components/ui";

export const FixUnitsPerCasePackWarning: FC = () => (
  <Notification
    appearance={Appearance.WARNING}
    content={{
      title: (
        <FormattedMessage
          id="sku.casePack.freeze.instructions"
          defaultMessage="Make sure units per box is accurate to avoid processing delays."
        />
      ),
    }}
  />
);
