import { SPThunkAction } from "common/ReduxUtils";
import { BookingSupplierResponse } from "@deliverr/legacy-inbound-client";
import { inboundClient } from "Clients";
import { logError, logStart, logSuccess } from "Logger";
import { SmbBookingCreationTimedEvent } from "inbounds/createShipment/common/analytics/analytics.types";
import { selectBookingSupplierRequest } from "../selectors/selectBookingSupplierRequest";
import { SmbBookingSupplierInput } from "@deliverr/legacy-inbound-client/lib/legacy-copy-pasta";
import { getSellerId } from "common/user/UserSelectors";

export const updateBookingSupplier =
  (shippingPlanId: number): SPThunkAction<Promise<BookingSupplierResponse>> =>
  async (dispatch, getState) => {
    const state = getState();
    const sellerId = getSellerId(state);
    const bookingRequest: SmbBookingSupplierInput = selectBookingSupplierRequest()(state);
    const ctx = logStart({ fn: SmbBookingCreationTimedEvent.UpdateBooking, shippingPlanId, bookingRequest });

    try {
      const response = await inboundClient.bookingAssignSupplier(sellerId, shippingPlanId, bookingRequest);
      logSuccess(ctx, "successfully updated supplier");
      return response;
    } catch (error) {
      logError(ctx, error);
      throw error;
    }
  };
