import { RootState } from "RootReducer";
import { DeliverrLtlShipment } from "inbounds/InboundTypes";
import { FreightPalletValidations } from "inbounds/steps/ship/ltl/util/validations";
import { createSelector } from "reselect";
import { selectLoadedShipmentConfirmedBoxCount } from "../shipments";
import { getShipmentLtl } from "./getShipmentLtl";
import {
  FTL_PALLETS_PER_TRUCK,
  LTL_MAX_TOTAL_PALLET_WEIGHT,
  LTL_MIN_TOTAL_PALLET_WEIGHT,
} from "freight/constants/cargoPalletConstants";

/**
 * Checks for dimensions compliance on the freight shipment for weight and qty
 */
export const selectFtlPalletDimsViolations = createSelector<
  RootState,
  DeliverrLtlShipment,
  number,
  FreightPalletValidations[]
>(
  getShipmentLtl,
  selectLoadedShipmentConfirmedBoxCount,
  ({ numberOfPallets, totalCargoWeight }, loadedShipmentConfirmedBoxCount) => {
    const {
      OVER_MAX_PALLET_QTY,
      OVER_MAX_PALLET_TOTAL_WEIGHT,
      UNDER_MINIMUM_TOTAL_WEIGHT,
      MORE_PALLETS_THAN_BOXES,
    } = FreightPalletValidations;

    const validationMap: Partial<Record<FreightPalletValidations, boolean>> = {
      [OVER_MAX_PALLET_QTY]: Boolean(numberOfPallets > FTL_PALLETS_PER_TRUCK),
      [OVER_MAX_PALLET_TOTAL_WEIGHT]: totalCargoWeight > LTL_MAX_TOTAL_PALLET_WEIGHT,
      [UNDER_MINIMUM_TOTAL_WEIGHT]: totalCargoWeight > 0 && totalCargoWeight < LTL_MIN_TOTAL_PALLET_WEIGHT,
      // with non-case-packed shipments with Prep, we may have no estimated box quantity
      [MORE_PALLETS_THAN_BOXES]:
        loadedShipmentConfirmedBoxCount > 0 && numberOfPallets > loadedShipmentConfirmedBoxCount,
    };

    return Object.values(FreightPalletValidations).filter((violation) => !!validationMap[violation]);
  }
);
