import { SelectedProductData } from "common/components/ProductChooser";
import produce from "immer";
import { find } from "lodash/fp";
import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { WholesaleCreateState } from "./WholesaleCreateState";
import { selectWholesaleCreate } from "./WholesaleCreateSelectors";

export const selectWholesaleSelectedProductsQty = createSelector<
  RootState,
  WholesaleCreateState,
  SelectedProductData[]
>(selectWholesaleCreate, (wholesaleCreate) => {
  const { selectedProducts, originStorageWarehouse } = wholesaleCreate;
  const selectedProductsQtyForGivenStorageWarehouse = produce(selectedProducts, (draft) => {
    Object.keys(draft).forEach((dsku) => {
      const availableQtyForDskuInWarehouse = find(
        (dskuQty) => dskuQty.dsku === dsku,
        originStorageWarehouse?.dskuQuantities
      );
      const availableQty = availableQtyForDskuInWarehouse?.qty ?? 0;
      draft[dsku].storageOnHandQty = availableQty;
      draft[dsku].storageUnfilledOrderQty = 0;
      draft[dsku].storageUnavailableQty = 0;
    });
  });
  return Object.values(selectedProductsQtyForGivenStorageWarehouse);
});
