import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { ParcelDetailActionTypes } from "parcel/screens/details/ParcelDetailsActionTypes";
import { ParcelDetails, ParcelTrackingEventsResponse } from "@deliverr/parcel-client";
import { getDefaultDeliverrAddress, getDefaultParcelDimensions } from "parcel/screens/details/helpers";
import produce from "immer";
import { Claim } from "common/clients/claim/Claim/Claim";

export interface ParcelDetailState extends ParcelDetails {
  isLoading: boolean;
  claim?: Claim;
  parcelTrackingData?: ParcelTrackingEventsResponse;
}

export const parcelDetailInitialState: ParcelDetailState = {
  parcelId: 0,
  destination: getDefaultDeliverrAddress(),
  shippingMethod: "",
  dimensions: getDefaultParcelDimensions(),
  late: false,
  trackingCode: "",
  serviceLevel: "",
  isLoading: false,
};

const reducers: ReducerDictionary<ParcelDetailState> = {
  ...handleSimpleReducerUpdates([ParcelDetailActionTypes.GET_PARCEL_DETAILS_SUCCESS]),
  [ParcelDetailActionTypes.GET_PARCEL_DETAILS]: (state) =>
    produce(state, (draft) => {
      draft.isLoading = true;
    }),
  [ParcelDetailActionTypes.UPDATE_PARCEL_DETAILS_LOADING_STATE]: (state, { isLoading }) =>
    produce(state, (draft) => {
      draft.isLoading = isLoading;
    }),
};

export const parcelDetailsReducer = createReducer<ParcelDetailState>(parcelDetailInitialState, reducers);
