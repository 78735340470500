/**
 * Input names should match the path to the field in the state object.
 */

import { ControllerRenderProps } from "react-hook-form";
import { CreateShipmentFormState } from "../store";

export enum CreateShipmentInputName {
  // originSearch
  ORIGIN = "origin",
  ORIGIN_FACILITY_NAME = "originFacilityName",
  IS_ORIGIN_PORT = "isOriginPort",
  // suppliers
  SUPPLIER = "supplier",
  SUPPLIER_COMPANY_NAME = "supplier.companyName",
  SUPPLIER_CONTACT_NAME = "supplier.contactName",
  SUPPLIER_CONTACT_EMAIL = "supplier.contactEmail",
  SUPPLIER_CONTACT_PHONE = "supplier.contactPhone",
  // destinationSearch
  DESTINATION = "destination",
  DESTINATION_FACILITY_NAME = "destinationFacilityName",
  IS_LIFTGATE_REQUIRED = "isLiftgateRequired",
  DELIVERY_DATE = "deliveryDate",
  DELIVERY_DATE_DATE = "deliveryDate.date",
  DELIVERY_DATE_IS_FLEXIBLE = "deliveryDate.isFlexible",
  ORIGIN_TYPE = "originType",
  CARGO_INPUT_TYPE = "cargoInputType",
  // unitSystem
  UNIT_SYSTEM = "unitSystem",
  // shipmentTotals
  TOTAL_VOLUME = "shipmentTotals.volume",
  TOTAL_WEIGHT = "shipmentTotals.weight",
  TOTAL_PALLETS = "shipmentTotals.pallets",
  TOTAL_UNITS = "shipmentTotals.units",
  TOTAL_BOXES = "shipmentTotals.boxes",
  // bookingProducts
  BOOKING_PRODUCTS = "bookingProducts",
  SINGLE_SKU_PACKAGES = "singleSkuPackages",
  MIXED_SKU_PACKAGES = "mixedSkuPackages",
  AIR_SHIPMENT_CARGO_DETAILS = "airShipmentCargoDetails",
  // cargoSkuSwitch
  IS_CARGO_MIXED_SKU = "isCargoMixedSku",
  // destinationSelect
  DESTINATION_SELECT = "destinationSelect",
  DESTINATION_SELECT_IS_BY_FLEXPORT = "destinationSelect.isByFlexport",
  DESTINATION_SELECT_IS_BY_AMAZON = "destinationSelect.isByAmazon",
  DESTINATION_SELECT_IS_DTC_NETWORK = "destinationSelect.isDTCNetwork",
  DESTINATION_SELECT_IS_OWN_DESTINATION = "destinationSelect.isOwnDestination",
  // reserveStorageSelect
  RESERVE_STORAGE = "reserveStorage",
  RESERVE_STORAGE_ADDRESS = "reserveStorage.address",
  RESERVE_STORAGE_WAREHOUSE_ID = "reserveStorage.warehouseId",
  // cargoReadyDate
  CARGO_READY_DATE = "cargoReadyDate",
  // dangerousGoods
  HAS_DANGEROUS_GOODS = "hasDangerousGoods",
  DANGEROUS_GOODS_LITHIUM_BATTERIES = "dangerousGoods.hasLithiumBatteries",
  DANGEROUS_GOODS_NON_LITHIUM_BATTERIES = "dangerousGoods.hasNonLithiumBatteries",
  DANGEROUS_GOODS_OTHER = "dangerousGoods.hasOther",
  DANGEROUS_GOODS_NONE = "dangerousGoods.hasNoDangerousGoods",
  // lithiumBatteries
  LITHIUM_BATTERIES_PACKED_LOOSE = "lithiumBatteryPackaging.packedLoose",
  LITHIUM_BATTERIES_PACKED_EQUIPMENT = "lithiumBatteryPackaging.packedEquipment",
  LITHIUM_BATTERIES_IN_EQUIPMENT = "lithiumBatteryPackaging.inEquipment",
  LITHIUM_BATTERIES_IN_VEHICLE = "lithiumBatteryPackaging.inVehicle",
  HAS_ANNUAL_CUSTOMS_BOND = "hasAnnualCustomsBond",
  FLEXPORT_CAPITAL_SELECTED = "flexportCapitalSelected",
  DISTRIBUTION_TYPE = "distributionType",
  AMAZON_DESTINATION_TYPE = "amazonDestinationType",
  AMAZON_PREP_TYPE = "amazonPrepType",
  AMAZON_ADDRESS = "amazonAddress",
  DTC_PREP_OPTION_BOX_LABELING_UNITS_SELECTED = "dtcPrepOption.boxLabelingUnitsSelected",
  DTC_PREP_OPTION_UNIT_PREP_SERVICE = "dtcPrepOption.unitPrepService",
  // selectShipping
  FREIGHT_SERVICE = "freightService",
  // domestic destination
  DOMESTIC_DESTINATION_TYPE = "domesticDestinationType",
  HAS_CARGO_INSURANCE = "hasCargoInsurance",
  CARGO_INSURANCE_TYPE = "cargoInsuranceType",
  INSURANCE_INFO_TOTAL_CARGO_VALUE = "insuranceInfo.totalCargoValue",
}

export type Field<T extends CreateShipmentInputName> = ControllerRenderProps<CreateShipmentFormState, T>;

export type FieldChangeHandler<T extends CreateShipmentInputName, R = void> = (
  event: React.ChangeEvent<HTMLInputElement>,
  field: Field<T>
) => R;
