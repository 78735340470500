import { defineMessages } from "react-intl";

export const transferCostsLabels = defineMessages({
  shipmentQuantity: {
    id: "transfers.create.transferCostsLabels.shipmentQuantity",
    defaultMessage: `{numOfShipments, plural,
      one {# shipment}
      other {# shipments}
    }`,
  },
  orderQuantity: {
    id: "transfers.create.transferCostsLabels.orderQuantity",
    defaultMessage: `one order`,
  },
  breakdown: {
    labeling: {
      id: "transfers.create.transferCostsLabels.breakdown.labels",
      defaultMessage: "Labeling",
    },
    orderProcessingFee: {
      id: "transfers.create.transferCostsLabels.breakdown.orderProcessingFee",
      defaultMessage: "Handling",
    },
    parcelOutboundHandling: {
      id: "transfers.create.transferCostsLabels.breakdown.parcelOutboundHandling",
      defaultMessage: "Parcel Outbound Handling",
    },
    parcelEstimation: {
      id: "transfers.create.transferCostsLabels.breakdown.parcelEstimation",
      defaultMessage: "Parcel Shipping",
    },
    amazonShipping: {
      id: "transfers.create.transferCostsLabels.breakdown.amazonShipping",
      defaultMessage: "Amazon Partner Shipping (billed by Amazon)",
    },
  },
});
