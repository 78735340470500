import { WholesaleCreateState } from "../../wholesale/create/store/WholesaleCreateState";
import { AttachmentType } from "@deliverr/wholesale-client";
import { SPThunkAction } from "../ReduxUtils";
import { batch } from "react-redux";
import { wholesaleClient } from "../../Clients";
import { uploadFile } from "../../transfers/utils/uploadFile";
import { RootState } from "../../RootReducer";
import { AnyAction } from "redux";
import { WholesaleErrorType } from "./utils/WholesaleErrorTypes";
import { useIntl } from "react-intl";
import { shipmentCreateLabels } from "./shipmentCreate.labels";

export const sharedCreateUploadWholesaleLabelFile = ({
  selector,
  setError,
  setFilename,
  setUploading,
}: {
  selector: (state: RootState) => WholesaleCreateState;
  setError: (errorType: string) => AnyAction;
  setFilename: (label: string, fileName: string) => AnyAction;
  setUploading: (label: string, uploading: boolean) => AnyAction;
}) => (file: File, attachmentType: AttachmentType): SPThunkAction => async (dispatch, getState) => {
  const { wholesaleOrder } = selector(getState());
  let isDuplicateLabelsError = false;
  batch(() => {
    dispatch(setUploading(attachmentType, true));
    dispatch(setError(WholesaleErrorType.NONE));
  });

  try {
    if (!wholesaleOrder?.shipments) {
      dispatch(setError(WholesaleErrorType.UNTYPED));
    } else {
      const attachmentMetaDate = { fileName: file.name, fileSize: file.size };
      let url;
      try {
        url = (
          await wholesaleClient.requestLabelUpload(
            wholesaleOrder!.id,
            wholesaleOrder?.shipments[0].id,
            attachmentType,
            undefined,
            attachmentMetaDate
          )
        ).data.url;
      } catch (e) {
        if (e.response.status === 400) {
          dispatch(setError(WholesaleErrorType.DUPLICATE_LABELS));
          isDuplicateLabelsError = true;
        }
        throw e;
      }
      await uploadFile(url, file);
      dispatch(setFilename(attachmentType, file.name));
    }
  } catch (e) {
    if (!isDuplicateLabelsError) {
      dispatch(setError(WholesaleErrorType.UNTYPED));
    }
  } finally {
    dispatch(setUploading(attachmentType, false));
  }
};

export const useCheckDuplicationLabelsError = () => {
  const { formatMessage } = useIntl();
  return (errorType?: WholesaleErrorType) => {
    const msg = checkDuplicationLabelsError(errorType);
    return msg && formatMessage(msg);
  };
};

export const checkDuplicationLabelsError = (errorType?: WholesaleErrorType) => {
  return errorType === WholesaleErrorType.DUPLICATE_LABELS
    ? shipmentCreateLabels.steps.labels.duplicateLabelsError
    : undefined;
};
