export enum Appearance {
  Default = "Default",
  Success = "Success",
  Danger = "Danger",
  Warning = "Warning",
  Admin = "Admin",
}

export enum Size {
  sm = "sm",
  md = "md",
  lg = "lg",
}

export enum Containers {
  div = "div",
  section = "section",
  article = "article",
}
