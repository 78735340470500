import { ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import { PrintBarcodesSelect, PrintBarcodesSelectProps } from "common/components/PrintBarcodesSelect";
import * as React from "react";
import { useStorageAdminBarcodeDownload } from "./useStorageAdminBarcodeDownload";

const StyledPrintBarcodeSelect = styled(PrintBarcodesSelect)<PrintBarcodesSelectProps & ThemeProps>(
  ({ theme }) => `
  * {
    color: ${theme.colors.ORANGE["400"]} !important;
    border-color: ${theme.colors.ORANGE["400"]} !important;
  }
  
  .Select-arrow {
    border-color: ${theme.colors.ORANGE["400"]} transparent transparent !important;
  }
`
);

/**
 * This component is a temporary solution to facilitate Storage -> Fulfillment unit labeling
 * until Prep Portal is functional. It intends to be fully self-contained to make future
 * removal
 */
export const StorageAdminBarcodeDownload: React.FC = () => {
  const { shouldSeeBarcodeDownload, printItems, filename, validateDownload } = useStorageAdminBarcodeDownload();

  return !shouldSeeBarcodeDownload ? null : (
    <StyledPrintBarcodeSelect filename={filename} items={printItems} validateDownload={validateDownload} />
  );
};
