import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";
import { ShipmentStatusGrouping, SHIPMENT_STATUS_BY_GROUPING } from "inbounds/ShipmentStatus";
import { SHIPPING_PLAN_STATUS } from "inbounds/ShippingPlanStatus";
import { flatten, difference, includes } from "lodash";
import log from "Logger";
import { InboundListSearchFilters, ShippingPlanType, DiscrepancyStatusType } from "./InboundListFilterTypes";
import AlgoliaService, { NumericFilter } from "common/list/AlgoliaService";
import { searchServiceFor } from "../../../common/search/Factory";
import { ListType } from "../../../common/list";
import { Sort } from "../../../common/models";
import { SearchResults } from "../../../common/search/SearchService";

export enum InboundListAlgoliaField {
  SHIPMENT_STATUS = "shipmentStatus",
  IS_FORWARDING = "isForwarding",
  PLAN_STATUS = "shippingPlanStatus",
  SHIPMENT_COMPLETED_WITH_DISCREPANCY = "shipmentCompletedWithDiscrepancy",
  SHIPMENT_ID = "shipmentId",
  INBOUND_SOURCE = "inboundSource",
}

export interface InboundCountSearchOptions {
  searchTerm: string;
  searchFilters: InboundListSearchFilters;
  actionNeededShipments: number[];
  showArchivedShippingPlans: boolean;
  resultsPerPage: number;
  isOpenSearchReadOn: boolean;
}

const getShipmentStatusesFromGroupings = (groupings?: ShipmentStatusGrouping[]) =>
  groupings == null ? [] : flatten(groupings?.map((grouping) => SHIPMENT_STATUS_BY_GROUPING[grouping]));

const createShipmentStatusClause = (filters: InboundListSearchFilters, actionNeededShipments: number[]) => {
  const statuses = getShipmentStatusesFromGroupings(filters.shipmentStatuses);
  if (statuses == null || statuses.length === 0) {
    return null;
  }

  const joinedStatuses = statuses.map((status) => `${InboundListAlgoliaField.SHIPMENT_STATUS}:${status}`);
  if (includes(filters.shipmentStatuses, ShipmentStatusGrouping.IN_DRAFT)) {
    joinedStatuses.push(`${InboundListAlgoliaField.PLAN_STATUS}:${SHIPPING_PLAN_STATUS.PENDING}`);
  }

  if (includes(filters.shipmentStatuses, ShipmentStatusGrouping.ACTION_NEEDED)) {
    const joinedActionNeeded = actionNeededShipments.map((id) => `${InboundListAlgoliaField.SHIPMENT_ID}:${id}`);
    joinedStatuses.push(...joinedActionNeeded);
  }

  return `(${joinedStatuses.join(" OR ")})`;
};

const createDiscrepancyFilter = () =>
  `(${InboundListAlgoliaField.SHIPMENT_STATUS}:${InboundShipmentStatus.COMPLETED} AND ${InboundListAlgoliaField.SHIPMENT_COMPLETED_WITH_DISCREPANCY}:1)`;

const createShipmentPlanFilter = (filters: InboundListSearchFilters) => {
  if (filters.shippingPlanType === ShippingPlanType.ALL_TYPES) {
    return null;
  }
  const joinedStatuses = [
    `${InboundListAlgoliaField.IS_FORWARDING}:${(filters.shippingPlanType === ShippingPlanType.FORWARDING).toString()}`,
  ];
  if (filters.shippingPlanType === ShippingPlanType.STORAGE) {
    joinedStatuses.push(`${InboundListAlgoliaField.INBOUND_SOURCE}:${filters.shippingPlanType}`);
  } else if (filters.shippingPlanType === ShippingPlanType.DIRECT) {
    joinedStatuses.push(`NOT ${InboundListAlgoliaField.INBOUND_SOURCE}:${ShippingPlanType.STORAGE}`);
  }
  return `(${joinedStatuses.join(" AND ")})`;
};

/**
 * Excludes all shipping plan types
 *  */
const createEmptyStatusFilter = () =>
  `((NOT ${InboundListAlgoliaField.PLAN_STATUS}:${SHIPPING_PLAN_STATUS.PENDING}) AND (NOT ${InboundListAlgoliaField.PLAN_STATUS}:${SHIPPING_PLAN_STATUS.SHIPMENTS_CREATED}))`;

/**
 * Combines all clauses created from different filter options to
 * create an overall filter clause to accompany the search term for Algolia. Some
 * aspects of this are non-trivial because of unexpected ways Algolia handles our data.
 *  */
export const createInboundListFilterClause = (
  filters: InboundListSearchFilters,
  actionNeededShipments: number[]
): string[] => {
  // Grab all active inbounds. Unfortunately, it seems that some inbounds
  // aren't tagged with isActive at all, and would get filtered out if we
  // did isActive:1 here. This way, we filter out everything that is not
  // explicitly inactive.
  const filterClauses: (string | null)[] = ["(NOT isActive:0)", "(NOT isActive:false)"]; // place to combine all the clauses
  if (filters.shipmentStatuses.length === 0) {
    filterClauses.push(createEmptyStatusFilter());
  } else {
    filterClauses.push(createShipmentStatusClause(filters, actionNeededShipments));
  }

  if (filters.discrepancyStatus === DiscrepancyStatusType.WITH_DISCREPANCY) {
    filterClauses.push(createDiscrepancyFilter());
  }

  filterClauses.push(createShipmentPlanFilter(filters));
  return difference(filterClauses, [null]) as string[];
};

export const algoliaShipmentsService = AlgoliaService.get({
  indexName: process.env.ALGOLIA_INDEX_INBOUNDS!,
  searchConfig: {
    hitsPerPage: 20,
    maxValuesPerFacet: 3,
  },
  highlightMatches: true,
});

export const createSearchTerm = (searchTerm: string, showArchivedShippingPlans: boolean) =>
  `${showArchivedShippingPlans ? "" : "-ARCHIVED "}${searchTerm}`;

export const getTotalInbounds = async ({
  searchTerm,
  searchFilters,
  showArchivedShippingPlans,
  actionNeededShipments,
  resultsPerPage,
  isOpenSearchReadOn,
}: InboundCountSearchOptions) => {
  const pageNum = 0;

  const term = createSearchTerm(searchTerm, showArchivedShippingPlans);
  const filterClauses = createInboundListFilterClause(searchFilters, actionNeededShipments);
  const ctx = {
    fn: "getTotalInbounds",
    searchTerm,
    searchConfig: {
      term,
      filterClauses,
      pageNum,
    },
  };

  log.info(ctx, "searching for total inbounds count");
  try {
    // putting entire operation in a try catch block to prevent any errors from breaking the entire operation - experimental feature
    try {
      if (isOpenSearchReadOn) {
        const searchResult = await executeOpenSearch(
          term,
          pageNum,
          undefined,
          filterClauses,
          undefined,
          resultsPerPage
        );
        return searchResult.response.nbHits;
      }
    } catch (err) {
      log.error({ ...ctx, err }, "error searching for inbounds in open search");
    }

    log.info(ctx, "searching via Algolia");

    const searchResult = await algoliaShipmentsService.search(
      term,
      pageNum,
      undefined,
      filterClauses,
      undefined,
      resultsPerPage
    );
    log.info({ ...ctx }, "successfully retrieved inbound results");
    return searchResult.response.nbHits;
  } catch (err) {
    log.error(
      {
        ...ctx,
        err,
      },
      "error searching for inbounds"
    );

    throw new Error(err);
  }
};

export const shipmentSearchService = searchServiceFor(ListType.InboundListV2);

export const executeOpenSearch = async (
  query: string,
  pageNum: number = 0,
  sortBy?: Sort,
  filters: string[] = [],
  numericFilter?: NumericFilter,
  resultsSize?: number,
  restrictSearchableAttributes?: string[],
  attributesToRetrieve?: string[]
): Promise<SearchResults> => {
  const searchParameters = {
    searchTerm: query,
    page: pageNum,
    pageSize: resultsSize,
    filters,
    highlightMatches: true,
  };
  return await shipmentSearchService.execute(searchParameters);
};
