import styled from "@emotion/styled";
import React from "react";
import { transitionFast } from "../shared/helpers/css";
import { isMobile } from "../shared/helpers/utils";
import { FormGroup, FormGroupProps, extractFormProps } from "../FormGroup";
import { DefaultTheme } from "../shared";
import { ERROR_COLOR } from "../Input";

export interface TextareaProps extends FormGroupProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>> {}

const StyledTextarea = styled.textarea<TextareaProps, DefaultTheme>(({ hasError, theme }) => {
  return `
    font-family: ${theme.font.family.STANDARD};
    border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
    line-height: ${theme.font.lineHeight.LH2};
    width: 100%;
    min-height: 96px;
    outline: none;
    ${
      isMobile(theme)
        ? `
        font-size: ${theme.font.size.F3};
        border-radius: ${theme.border.radius.R3};
        padding: ${theme.spacing.S3} ${theme.spacing.S4};
      `
        : `
        font-size: ${theme.font.size.F2};
        border-radius: ${theme.border.radius.R2};
        padding: ${theme.spacing.S2} ${theme.spacing.S3};
      `
    }

    ${transitionFast()}
    :focus {
      border-color: ${theme.colors.BLUE[300]};
      box-shadow: ${theme.input.focus.BLUE};
    }
    ::placeholder {
      color: ${theme.colors.NEUTRAL[300]};
    }
    :disabled {
      cursor: not-allowed;
      background-color: ${theme.colors.NEUTRAL[60]};
      border-color: ${theme.colors.NEUTRAL[100]};
    }
    ${
      hasError
        ? `
      border-color: ${ERROR_COLOR(theme)};
      :focus {
        border-color: ${ERROR_COLOR(theme)};
        box-shadow: ${theme.input.focus.RED};
      }
    `
        : ``
    }
  `;
});

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const [formProps, textareaProps] = extractFormProps(props);
  return (
    <FormGroup {...formProps}>
      <StyledTextarea {...textareaProps} ref={ref} />
    </FormGroup>
  );
});

Textarea.displayName = "Textarea";
