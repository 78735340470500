import { MessageDescriptor } from "react-intl";

export enum ReturnsCancelTypes {
  RETURNS_CANCEL_SUCCESS = "RETURNS_CANCEL_SUCCESS",
  RETURNS_CANCEL_FAIL = "RETURNS_CANCEL_FAIL",
  RETURNS_CANCEL_RESET = "RETURNS_CANCEL_RESET",
}

export interface ReturnsCancelState {
  error?: MessageDescriptor | any;
  returnCancelSuccess?: boolean;
}
