import { TimeWindow } from "@deliverr/commons-objects";
import { MilitaryTime } from "common/date/DateFormat";
import { format } from "date-fns";

export interface TimeWindowDates {
  startTime: Date | null;
  endTime: Date | null;
}

export const convertTimeWindowTo24Hours = (dates: TimeWindowDates): TimeWindow => ({
  startTime: dates.startTime ? format(dates.startTime, MilitaryTime) : "",
  endTime: dates.endTime ? format(dates.endTime, MilitaryTime) : "",
});

export const convertTimeWindowTo12Hours = (times: TimeWindow) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    hour12: true,
  };
  const newStartTime = new Date();
  const newEndTime = new Date();
  newStartTime.setHours(parseInt(times.startTime), 0);
  newEndTime.setHours(parseInt(times.endTime), 0);
  return {
    startTime: newStartTime.toLocaleString("en-US", options),
    endTime: newEndTime.toLocaleString("en-US", options),
  };
};
