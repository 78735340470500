import { PrepOption } from "@deliverr/business-types";

export const isPerUnitPrep = (prepOption?: PrepOption) => {
  return (
    !!prepOption &&
    [PrepOption.UNIT_PREP, PrepOption.SKU_LABELS, PrepOption.BAGGING_PROTECTION, PrepOption.DAMAGE_PROTECTION].includes(
      prepOption
    )
  );
};
