export enum InventoryError {
  DSKU_ALREADY_EXISTS = "DSKU_ALREADY_EXISTS",
  UNKNOWN_SKU = "UNKNOWN_SKU",
  STOCKOUT = "STOCKOUT",
  INVENTORY_OUT_OF_SYNC = "INVENTORY_OUT_OF_SYNC",
  UNKNOWN_SELLER = "UNKNOWN_SELLER",
  INSUFFICIENT_INVENTORY_AVAILABLE = "INSUFFICIENT_INVENTORY_AVAILABLE",
  UNKNOWN_WAREHOUSE = "UNKNOWN_WAREHOUSE",
  BAD_REQUEST = "BAD_REQUEST",
}
