import styled from "@emotion/styled";
import { Required } from "utility-types";
import { ThemeProps, Size, DefaultTheme } from "../shared";
import { screenReaderOnly } from "../shared/helpers/css";
import React from "react";

export type TableSize = Extract<keyof typeof Size, "SM" | "MD">;

export interface TableProps extends React.TableHTMLAttributes<HTMLTableElement> {
  /** Heading of each column */
  headings: any[];
  /** Items in the table */
  rows: any[][];
  /** Description of what's in the table for those who can't scan the items in the table */
  caption: string;
  /** Size of padding of each cell */
  size?: TableSize;
  /** style of each column */
  widths?: string[];
}

type StyleProps = Pick<TableProps, "size">;

interface Width {
  width?: string;
}

const StyledTable = styled.table<StyleProps>`
  width: 100%;
`;

const Caption = styled.caption`
  ${screenReaderOnly}
`;

const Tr = styled.tr<StyleProps, DefaultTheme>(
  ({ theme }) => `
  border-bottom: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
`
);

const setSize = ({ size, theme }: ThemeProps<Required<StyleProps>>) => {
  const sizes: Record<TableSize, string> = {
    [Size.SM]: `${theme.spacing.S4}`,
    [Size.MD]: `${theme.spacing.S5}`,
  };
  return `
    &:first-of-type {
      padding-left: ${theme.spacing.S6};
    }
    &:last-of-type {
      padding-right: ${theme.spacing.S6};
    }
    padding: ${sizes[size as TableSize]};
  `;
};

/* eslint-disable no-unneeded-ternary */
const Th = styled.th<Required<StyleProps> & Width, DefaultTheme>(
  ({ size, width, theme }) => `
  text-align: left;
  font-weight: ${theme.font.weight.BOLD};
  font-family: ${theme.font.family.TITLE};
  color: ${theme.colors.NEUTRAL[200]};
  width: ${width ? width : `inherit`};
  ${setSize({ size, theme })}
`
);
/* eslint-enable no-unneeded-ternary */

const Td = styled.td<Required<StyleProps>, DefaultTheme>(
  ({ size, theme }) => `
  ${setSize({ size, theme })}
`
);

export const Table: React.FC<TableProps> = ({
  headings,
  rows,
  caption,
  size = Size.MD as TableSize,
  widths = [],
  ...props
}) => (
  <StyledTable {...props}>
    <Caption>{caption}</Caption>
    <thead>
      <Tr>
        {headings.map((heading, i) => (
          <Th key={i} size={size} width={widths[i]}>
            {heading}
          </Th>
        ))}
      </Tr>
    </thead>
    <tbody>
      {rows.map((row, i) => (
        <Tr key={i}>
          {row.map((cell, j) => (
            <Td key={j} size={size}>
              {cell}
            </Td>
          ))}
        </Tr>
      ))}
    </tbody>
  </StyledTable>
);
