import { OrderTag } from "@deliverr/commons-objects";
import { RemovalMethods } from "order/new/ShippingMethods";
import { OrderListServiceFilter } from "../OrderListReducer";
import { QueryDslQueryContainer } from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";

export const serviceToOpenSearchFilter = (serviceFilter?: OrderListServiceFilter): QueryDslQueryContainer[] => {
  if (serviceFilter === "ALL_SERVICE") {
    return [];
  }

  if (serviceFilter === RemovalMethods.REMOVAL) {
    return [
      {
        match: {
          tags: `${OrderTag.INVENTORY_REMOVAL}`,
        },
      },
    ];
  }

  if (Array.isArray(serviceFilter)) {
    return [
      {
        terms: {
          "orderDetails.serviceLevel": serviceFilter.map((service) => `${service}`),
        },
      },
    ];
  }

  if (serviceFilter) {
    return [
      {
        match: {
          "orderDetails.serviceLevel": `${serviceFilter}`,
        },
      },
    ];
  }
  return [];
};
