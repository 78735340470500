// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/** Flexport colors */\n/** Design system colors as of 8/27/21 **/\n.SavedAudienceWarning__warningIcon--zd7IF {\n  color: #e3a81e;\n  margin-right: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/app/tags/facebook/common/SavedAudienceWarning/SavedAudienceWarning.less"],"names":[],"mappings":"AAAA,qBAAqB;AACrB,yCAAyC;AACzC;EACE,cAAA;EACA,iBAAA;AACF","sourcesContent":["@import \"~app/common/styles/colors\";\n\n.warningIcon {\n  color: @darkYellow;\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warningIcon": "SavedAudienceWarning__warningIcon--zd7IF"
};
export default ___CSS_LOADER_EXPORT___;
