import React from "react";
import { StorageInboundDetail } from "chunks";
import { Path } from "paths";
import { Route } from "react-router-dom";
import { linkWithState, NavBar } from "common/components/NavBar";
import { StorageNavbarShippingPlanName } from "./inbounds/detail/StorageShipmentNavbarPlanName/StorageShipmentNavbarPlanName";

export const storageHeaderRoutes = [
  <Route
    key="storageInboundDetail"
    path={Path.inboundsStorageDetail}
    exact
    render={() => (
      <NavBar
        breadcrumbs={[
          {
            pageName: "Inbounds",
            path: linkWithState(Path.inboundsList, { preserveState: true }),
          },
          { pageName: "Shipment detail", component: <StorageNavbarShippingPlanName /> },
        ]}
      />
    )}
  />,
];

export const storageRoutes = [
  <Route key={"storageInboundDetail"} path={Path.inboundsStorageDetail} exact component={StorageInboundDetail} />,
];
