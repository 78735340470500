import { Product } from "@deliverr/commons-clients";
import { composeGetIsOverWeight } from "common/utils/product/composeGetIsOverWeight";
import {} from "inbounds/constants/spdConstants";
import { composeGetIsOverDimensions } from "common/utils/product/composeGetIsOverDimensions";
import {
  STORAGE_INBOUND_SAFE_MAX_CASE_MEASUREMENT_LONGEST_IN,
  STORAGE_INBOUND_SAFE_MAX_CASE_MEASUREMENT_MIDDLE_IN,
  STORAGE_INBOUND_SAFE_MAX_CASE_MEASUREMENT_SHORTEST_IN,
  STORAGE_INBOUND_MAX_SAFE_CASE_WEIGHT_LBS,
} from "../CaseDimensionsConstants";

type CaseDimensionsRecommendedForFCValidator = (product: Partial<Product>) => boolean;

export const isCaseDimensionsAboveRecommendedForFC: CaseDimensionsRecommendedForFCValidator = (product) =>
  composeGetIsOverDimensions({
    maxLongestSide: STORAGE_INBOUND_SAFE_MAX_CASE_MEASUREMENT_LONGEST_IN,
    maxMiddleSide: STORAGE_INBOUND_SAFE_MAX_CASE_MEASUREMENT_MIDDLE_IN,
    maxShortestSide: STORAGE_INBOUND_SAFE_MAX_CASE_MEASUREMENT_SHORTEST_IN,
  })(product) || composeGetIsOverWeight(STORAGE_INBOUND_MAX_SAFE_CASE_WEIGHT_LBS)(product);
