import { DeliverrAddress } from "@deliverr/commons-objects";
import { getItemFromLocalStorageAndParse } from "common/LocalStorage";
import { InboundStep, PlannedShipment } from "inbounds/InboundTypes";
import { InboundPackageSummary } from "@deliverr/legacy-inbound-client";

export interface SavedInbound {
  step: InboundStep;
  completedShippingPlanIds?: number[];
  fromAddress: DeliverrAddress;
}

const getInboundCacheKey = (planId: number) => `plan-${planId}`;
const getPlannedShipmentCacheKey = (shipmentId: number) => `plannedShipment-${shipmentId}`;
const getPlannedPackagesCacheKey = (planId: number) => `plannedPackages-${planId}`;

export const saveInboundLocally = (inbound: SavedInbound, shippingPlanId: number) => {
  localStorage.setItem(getInboundCacheKey(shippingPlanId), JSON.stringify(inbound));
};

export const savePlannedShipmentLocally = (plannedShipment: PlannedShipment) => {
  localStorage.setItem(getPlannedShipmentCacheKey(plannedShipment.id), JSON.stringify(plannedShipment));
};

export const savePlannedPackagesLocally = (plannedPackages: InboundPackageSummary[], shippingPlanId: number) => {
  localStorage.setItem(getPlannedPackagesCacheKey(shippingPlanId), JSON.stringify(plannedPackages));
};

export const getLocalInbound = (planId: number): SavedInbound | undefined =>
  getItemFromLocalStorageAndParse(getInboundCacheKey(planId));

export const getLocalPlannedShipment = (shipmentId: number): PlannedShipment | undefined =>
  getItemFromLocalStorageAndParse(getPlannedShipmentCacheKey(shipmentId));

export const getLocalPlannedPackages = (planId: number): InboundPackageSummary[] | undefined =>
  getItemFromLocalStorageAndParse(getPlannedPackagesCacheKey(planId));

export const removeLocalPlannedShipment = (shipmentId: number) =>
  localStorage.removeItem(getPlannedShipmentCacheKey(shipmentId));
