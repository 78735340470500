import { transfersShipmentCreateLabels } from "../../transfersShipmentCreate.labels";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ManagedStep, useStepManager } from "common/components/StepContainer";
import { Box, IconV2, Notification, Text } from "common/components/ui";
import { useSelector } from "react-redux";
import { selectTransferCreate } from "../../store/TransferCreateSelectors";
import { useFBAConfirmStatusMonitor } from "./useFBAConfirmStatusMonitor";
import { MonitorStatus } from "./MonitorStatus";
import { getIsDeliverrFreight } from "../../../../common/wholesale-common/utils/wholesale-utils";

const labels = transfersShipmentCreateLabels.steps.fbaIntegration.waitForShipmentConfirmation;

export const FbaWaitForShipmentConfirmation = () => {
  const { formatMessage } = useIntl();
  const { monitorStatus } = useFBAConfirmStatusMonitor();
  const { wholesaleOrder } = useSelector(selectTransferCreate);
  const { stepManager } = useStepManager();

  const isDeliverrFreight = getIsDeliverrFreight(wholesaleOrder);
  const canProceed = monitorStatus !== MonitorStatus.POLLING;

  useEffect(() => {
    if (canProceed) {
      stepManager.next();
    }
  }, [stepManager, canProceed]);

  return (
    <ManagedStep
      title={formatMessage(labels.title)}
      subtitle={
        <Box maxWidth="32rem">
          <FormattedMessage {...labels.subtitle} />
        </Box>
      }
      header={
        <div>
          <IconV2 type="loading" size="large" spin data-testid="loading-spinner" />
        </div>
      }
      isNextDisabled={!canProceed}
    >
      <Box width="80%" maxWidth="27rem" marginBottom="S4" padding={"S4"}>
        <Notification
          appearance="WARNING"
          showIcon={true}
          content={{
            title: <FormattedMessage {...labels.warningTitle} />,
            description: isDeliverrFreight ? (
              <div>
                <FormattedMessage {...labels.warningSubtitle1Freight} />
                <Text bold>{formatMessage(labels.warningSubtitle2Freight)}</Text>
              </div>
            ) : (
              <div>
                <FormattedMessage {...labels.warningSubtitleParcel} />
              </div>
            ),
          }}
        />
      </Box>
    </ManagedStep>
  );
};
