import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { mapError } from "../core/mapError";
import { isAuthenticated } from "../core/authentication";
import { CoverageApiResponse } from "../fastTag/CoverageApiResponse";
import { ServiceLevelCoverage } from "../fastTag/ServiceLevelCoverage";
import { FacebookError } from "./FacebookError";
import { FacebookAdSet } from "./FacebookAdSet/FacebookAdSet";
import { FacebookAdAccount } from "./FacebookAdAccount";
import { FacebookPixel } from "./FacebookPixel";
import { FacebookPage } from "./FacebookPage";
import { FacebookInstagramAccount } from "./FacebookInstagramAccount";
import { FacebookSellerRegistrationData } from "./FacebookSellerRegistrationData/FacebookSellerRegistrationData";

export class FacebookClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: ApiClientConfig = { baseURL: mustBeDefined(process.env.FACEBOOK_URL) }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1/`,
    });
  }

  public async syncSellerCreatedAdSets(sellerId: string): Promise<FacebookAdSet[]> {
    try {
      return await this.apiClient.post({
        url: `/sync-seller-created-ad-sets/seller/${sellerId}`,
        body: undefined,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [FacebookError.SELLER_NOT_FOUND]);
    }
  }

  async getDskuStateCoverage(dsku: string): Promise<CoverageApiResponse> {
    try {
      return await this.apiClient.get({
        url: `/availability/dsku/${dsku}/coverage/twoday`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async getStateCoverageByServiceLevel(dsku: string): Promise<ServiceLevelCoverage> {
    const coverage = await this.getDskuStateCoverage(dsku);

    return { 2: coverage };
  }

  async refreshSeller(sellerId: string): Promise<void> {
    try {
      await this.apiClient.post({
        url: `refresh/seller/${sellerId}`,
        body: undefined,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [FacebookError.SELLER_NOT_FOUND, FacebookError.REFRESH_ALREADY_IN_PROGRESS]);
    }
  }

  async activateSeller(sellerId: string): Promise<void> {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}/activate`,
        body: undefined,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [FacebookError.SELLER_NOT_FOUND]);
    }
  }

  async deactivateSeller(sellerId: string) {
    try {
      return await this.apiClient.delete({
        url: `/seller/${sellerId}`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [FacebookError.SELLER_NOT_FOUND]);
    }
  }

  async getAdAccounts(sellerId: string, userId: string, accessToken: string): Promise<FacebookAdAccount[]> {
    try {
      return await this.apiClient.get<FacebookAdAccount[]>({
        url: `/seller/${sellerId}/user/${userId}/token/${accessToken}/ad-accounts`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [FacebookError.INVALID_OAUTH_ACCESS_TOKEN]);
    }
  }

  async getPixels(sellerId: string, adAccountId: string, accessToken: string): Promise<FacebookPixel[]> {
    try {
      return await this.apiClient.get<FacebookPixel[]>({
        url: `/seller/${sellerId}/ad-account/${adAccountId}/token/${accessToken}/pixels`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [FacebookError.INVALID_OAUTH_ACCESS_TOKEN]);
    }
  }

  async getPages(sellerId: string, userId: string, accessToken: string): Promise<FacebookPage[]> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/user/${userId}/token/${accessToken}/pages`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [FacebookError.INVALID_OAUTH_ACCESS_TOKEN]);
    }
  }

  async hasValidPermissions(sellerId: string, userId: string, accessToken: string): Promise<boolean> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/user/${userId}/token/${accessToken}/permissions`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [FacebookError.INVALID_OAUTH_ACCESS_TOKEN]);
    }
  }

  async getInstagramAccounts(
    sellerId: string,
    businessId: string,
    accessToken: string
  ): Promise<FacebookInstagramAccount[]> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/business/${businessId}/token/${accessToken}/instagram-accounts`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [FacebookError.INVALID_OAUTH_ACCESS_TOKEN]);
    }
  }

  async registerSeller(sellerId: string, sellerRegistrationData: FacebookSellerRegistrationData) {
    try {
      return await this.apiClient.post({
        url: `/seller/${sellerId}`,
        body: sellerRegistrationData,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [
        FacebookError.BUSINESS_USER_NOT_FOUND,
        FacebookError.FB_USER_IS_NOT_ADMIN,
        FacebookError.INVALID_OAUTH_ACCESS_TOKEN,
        FacebookError.MULTIPLE_SHOPIFY_PRODUCT_CATALOGS,
        FacebookError.SHOPIFY_PRODUCT_CATALOG_NOT_FOUND,
      ]);
    }
  }
}
