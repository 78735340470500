import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { UnknownBarcodeNonComplianceRow } from "../../types";
import { UNKNOWN_BARCODE_NON_COMPLIANCE_BLADE_ID } from "../bladeIds";
import { UnknownBarcodeBlade } from "common/components/ProblemBlade/UnknownBarcodeBlade";
import RouteParser from "common/RouteParser";
import { Path } from "paths";
import { useFetchProduct } from "inbounds/hooks";
import { MappedSkuPair } from "common/components/ProblemsTable/types";
import { isNil } from "lodash";
import { nonComplianceInternalRoutes } from "inbounds/non-compliance/NonComplianceRoutes";
import { usePreventScrollToTopNavigation } from "common/hooks/usePreventScrollToTopNavigation";

interface UnknownBarcodeNonComplianceBladeProps {
  nonComplianceCase: UnknownBarcodeNonComplianceRow;
}

export const UnknownBarcodeNonComplianceBlade: FC<UnknownBarcodeNonComplianceBladeProps> = ({ nonComplianceCase }) => {
  const navigate = usePreventScrollToTopNavigation();
  const { nonCompliantId } = useParams<{ nonComplianceType?: string; nonCompliantId?: string }>();

  const { shippingPlanId, shippingPlanName, mappedSkus, photos } = nonComplianceCase;

  const { value: product } = useFetchProduct({ dsku: mappedSkus[0]?.dsku });

  const skuPair: MappedSkuPair | undefined = product && {
    dsku: product.dsku,
    msku: product.msku,
    productName: product.name,
  };

  const onShippingPlanClick = () => {
    window.open(new RouteParser(Path.inboundsShippingPlanRedirect).parse({ shippingPlanId }), "_blank");
  };

  const resetBladeState = () => {
    if (!isNil(nonCompliantId) && nonCompliantId.length > 0) {
      navigate(nonComplianceInternalRoutes.summary);
    }
  };

  return (
    <UnknownBarcodeBlade
      id={UNKNOWN_BARCODE_NON_COMPLIANCE_BLADE_ID}
      shipmentProblem={nonComplianceCase}
      onShippingPlanClick={onShippingPlanClick}
      shippingPlanId={shippingPlanId}
      shippingPlanName={shippingPlanName}
      onCloseClick={resetBladeState}
      isBladeOpen={true}
      product={skuPair}
      images={photos ?? []}
    />
  );
};
