import { useState } from "react";
import { LabelLayoutType } from "@deliverr/legacy-inbound-client";

import { generatingToasts, ToastTypes } from "common/InfoToast";
import { initiateDownloadFromUrl } from "common/InitiateDownload";
import { inboundClient } from "Clients";
import { default as logger, logError } from "Logger";
import { useIntl } from "react-intl";
import { ERROR_TOAST_AUTO_CLOSE_TIME } from "common/utils/toasts/toasts";
import { toast } from "common/components/ui";
import { SkuToBarcode } from "../PrintBarcodesButton";

export interface PrintBarcodesSelectProps {
  disabled?: boolean;
  filename: string;
  items: SkuToBarcode[];
  onDownloadComplete?: () => void;
  validateDownload?: () => boolean;
  hideModal?: () => void;
  className?: string;
}

export const usePrintBarcodesSelect = ({
  filename,
  items,
  onDownloadComplete,
  validateDownload,
}: PrintBarcodesSelectProps) => {
  const ctx = { fn: "usePrintBarcodesSelect#handleDownloadClick", items, filename };
  const { formatMessage } = useIntl();

  const [layoutType, setLayoutType] = useState<LabelLayoutType | undefined>();

  const handleOptionSelectDownload = async ({ value }) => {
    handleOptionSelect({ value });
    await handleDownloadClick();
  };

  const handleOptionSelect = ({ value }) => {
    setLayoutType(value);
  };

  const handleDownloadClick = async () => {
    if (validateDownload && !validateDownload()) {
      logger.warn(ctx, "Barcode download validation failed.");
      return;
    }

    generatingToasts(ToastTypes.barcodes, 8000);
    try {
      const pdf = await inboundClient.downloadBarcodesPdf(layoutType!, items, true);
      initiateDownloadFromUrl(URL.createObjectURL(new Blob([pdf], { type: "application/pdf" })), filename);
      onDownloadComplete?.();
    } catch (err) {
      toast.error(
        formatMessage({
          id: "common.printBarcodesSelect.downloadErrorMessage",
          defaultMessage: "Error downloading barcodes, please try again or contact support.",
        }),
        { autoClose: ERROR_TOAST_AUTO_CLOSE_TIME }
      );
      logError(ctx, err);
    }
  };

  return {
    handleOptionSelectDownload,
    handleOptionSelect,
    handleDownloadClick,
    layoutType,
  };
};
