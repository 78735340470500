import { format, startOfMonth, subMonths } from "date-fns";

import { SelectOption } from "common/utils/types";
import { MonthAndYear } from "../../common/date/DateFormat";

export const getMonthSelectOptions = (fromDate: Date, toDate: Date = new Date()): SelectOption<Date>[] => {
  const options: SelectOption<Date>[] = [];

  // create select options for each month in reverse order
  for (let to = startOfMonth(toDate); to >= fromDate; to = subMonths(to, 1)) {
    options.push({
      label: format(to, MonthAndYear),
      value: to,
    });
  }
  return options;
};
