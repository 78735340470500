import { WholeEventVisibilityScope, WholesaleOrderDTO } from "@deliverr/wholesale-client";
import { wholesaleClient } from "Clients";
import { SPThunkAction } from "common/ReduxUtils";
import { FeatureName, isFeatureOn } from "common/Split";
import { fetchMultiPackProductsByDskus } from "storage/utils/fetchMultiPackProductsByDskus";
import { mapWholesaleOrderSkusToPackSkus } from "transfers/utils/mapWholesaleOrderSkusToPackSkus";

export const getWholesaleOrderWithPacks = (
  duplicatingOrderId: string,
  mergeProductPacks = false
): SPThunkAction<Promise<WholesaleOrderDTO>> => async (dispatch) => {
  let wholesaleOrder = (
    await wholesaleClient.getOrderAndShipments(duplicatingOrderId, WholeEventVisibilityScope.External)
  ).data;
  const isMultiCasePackEnabled = isFeatureOn(FeatureName.StorageMultiCasePack);

  const wholesaleItems = wholesaleOrder.orderItems ?? [];

  if (isMultiCasePackEnabled || mergeProductPacks) {
    const orderSkus = (wholesaleItems ?? []).map((item) => item.dsku);

    const unifiedProducts = await fetchMultiPackProductsByDskus(orderSkus, dispatch);

    wholesaleOrder = mapWholesaleOrderSkusToPackSkus(wholesaleOrder, unifiedProducts);
  }

  return wholesaleOrder;
};
