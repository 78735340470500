import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/dedupe";
import React from "react";

import cls from "./PencilIcon.less";

interface OwnProps {
  className?: string;
  onClick?: () => any;
}

export class PencilIcon extends React.Component<OwnProps, {}> {
  public render() {
    const { className, onClick } = this.props;

    return (
      <div className={classNames(cls.iconContainer, className)} onClick={onClick}>
        <FontAwesomeIcon icon="pencil-alt" className={cls.icon} />
      </div>
    );
  }
}
