import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Card } from "common/components/Card";
import { FormProvider } from "react-hook-form";
import {
  InboundStepContainer,
  InboundStepContent,
  InboundStepDescription,
  InboundStepTitle,
} from "inbounds/steps/InboundStepElements";
import { InboundFooter } from "inbounds/steps/InboundFooter";
import { DimensionsAndWeightsTable } from "inbounds/steps/dimensions/DimensionsAndWeightsTable";
import { Text } from "common/components/ui";
import { useCaseDimensionsAndWeights } from "./useCaseDimensionsAndWeights";
import { getCaseDimensionsError } from "storage/inbounds/utils/getCaseDimensionsError";
import { SimpleLoader } from "common/components/WithLoader/WithLoader";

export const CaseDimensionsAndWeights: FC = () => {
  const {
    formHandler,
    selectedProductsCaseDimensions,
    onNextClick,
    onPreviousClick,
    isNextDisabled,
    isLoading,
    isDimensionOverrideFeatureEnabled,
  } = useCaseDimensionsAndWeights();
  return isLoading ? (
    <SimpleLoader />
  ) : (
    <FormProvider {...formHandler}>
      <InboundStepContainer>
        <Card>
          <InboundStepTitle>
            <FormattedMessage
              id="storage.inbounds.dimensionsAndWeights.title"
              defaultMessage="Add case-pack weight and dimensions"
            />
          </InboundStepTitle>
          <InboundStepDescription appearance="INFO">
            <FormattedMessage
              id="storage.inbounds.dimensionsAndWeights.description"
              defaultMessage="Providing dimensions allows Flexport to more accurately estimate your storage and fulfillment costs."
            />
          </InboundStepDescription>
          <InboundStepContent>
            <DimensionsAndWeightsTable
              tableHeaderOverride={
                <thead>
                  <tr>
                    <td>
                      <Text bold size="caption">
                        <FormattedMessage
                          id="storage.inbounds.dimensionsAndWeightsTable.productHeader"
                          defaultMessage="Product"
                        />
                      </Text>
                    </td>
                    <td>
                      <Text bold size="caption">
                        <FormattedMessage
                          id="storage.inbounds.dimensionsAndWeightsTable.caseDimensionsHeader"
                          defaultMessage="Box Dimensions (in)"
                        />
                      </Text>
                    </td>
                    <td>
                      <Text bold size="caption">
                        <FormattedMessage
                          id="storage.inbounds.dimensionsAndWeights.caseWeightHeader"
                          defaultMessage="Box Weight (lbs)"
                        />
                      </Text>
                    </td>
                  </tr>
                </thead>
              }
              showCaseDimensionInfo
              products={selectedProductsCaseDimensions}
              isDimensionOverrideFeatureEnabled={isDimensionOverrideFeatureEnabled}
              getDimensionsErrorOverride={getCaseDimensionsError}
            />
          </InboundStepContent>
          <InboundFooter
            onButtonClick={onNextClick}
            onPreviousButtonClick={onPreviousClick}
            disabled={isNextDisabled}
            manualLoader
          />
        </Card>
      </InboundStepContainer>
    </FormProvider>
  );
};
