import React from "react";
import { useCurrencyValue } from "common/components/Currency";
import { ServiceSelector } from "inbounds/createShipment/steps/priceViewStep/selectShipping/selectShippingFlow/components/ServiceSelector";
import { PREP_OPTION_MESSAGES } from "./prepOptionMessages";
import { TextAlign } from "common/components/ui/shared";
import { Text } from "common/components/ui";
import { LocalizedText } from "common/components/LocalizedText";
import { PrepOption } from "@deliverr/business-types";
import { isPerUnitPrep } from "prep/util/isPerUnitPrep";

export type ChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => void;

export const mergeValues = (msgConfig: Record<string, any>, values: Record<string, any>) => ({
  ...msgConfig,
  values: {
    ...msgConfig.values,
    ...values,
  },
});

interface PrepOptionProps {
  prepOption: PrepOption;
  currentValue?: PrepOption;
  onChange: ChangeHandler;
  costPerUnit?: number;
  boxLabelingDiscount?: number;
  unitLabelingDiscount?: number;
  numOfBoxes?: number;
  numOfUnits?: number;
  boxHandlingFee?: number;
  palletHandlingFee?: number;
  appendToPriceColumn?: JSX.Element;
}

export const SelectPrepOption = ({
  prepOption,
  currentValue,
  onChange,
  costPerUnit,
  numOfBoxes,
  numOfUnits,
  boxHandlingFee,
  palletHandlingFee,
}: PrepOptionProps) => {
  const isPerUnit = isPerUnitPrep(prepOption);
  const formatCurrency = useCurrencyValue();
  const messages = PREP_OPTION_MESSAGES[prepOption];
  const isSelected = currentValue === prepOption;
  return (
    <ServiceSelector
      key={prepOption}
      as="radio"
      value={prepOption}
      name="fbaPrepType"
      label={messages.label}
      description={messages.description}
      price={costPerUnit}
      isPerUnit={isPerUnit}
      compact
      checked={isSelected}
      onChange={onChange}
      customPriceLabel={
        messages.customPriceLabel
          ? mergeValues(messages.customPriceLabel, {
              price: formatCurrency(costPerUnit),
            })
          : undefined
      }
      appendToPriceColumn={
        <Text textAlign={TextAlign.end} appearance="INFO" as="div">
          {messages.priceDescription && (
            <LocalizedText
              textAlign={TextAlign.end}
              as="div"
              appearance="INFO"
              size="caption"
              message={mergeValues(messages.priceDescription, { numOfBoxes, numOfUnits })}
            />
          )}
          {messages.priceDescription2 && (
            <LocalizedText
              textAlign={TextAlign.end}
              as="div"
              appearance="INFO"
              size="caption"
              message={mergeValues(messages.priceDescription2, {
                boxHandlingFee: formatCurrency(boxHandlingFee),
                palletHandlingFee: formatCurrency(palletHandlingFee),
              })}
            />
          )}
        </Text>
      }
    />
  );
};
