import React, { FC } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Text, Button, ThemeProps, defaultTheme } from "common/components/ui";
import styled from "@emotion/styled";

import { ProductSearch } from "common/components/ProductSearch";
import { NonComplianceSection, TextWithBottomMargin } from "inbounds/non-compliance/components/NonComplianceStyles";
import { ProductListItem } from "inventory/list/InventoryListReducer";
import { ProductSearchSelection } from "common/components/ProductSearchSelection";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";

export interface IdentifyProductProps {
  onProductSelect: (inventoryListItem?: ProductListItem) => void;
  foundProduct?: ProductListItem;
  onContinue: () => void;
  barcode?: string;
  onSubmit?: () => void;
  isOnSubmitLoading?: boolean;
}

const SpacedElevioLink = styled(ElevioBorderlessButton)<ThemeProps>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S4};
    margin-bottom: ${theme.spacing.S2};
  `
);

export const IdentifyProduct: FC<IdentifyProductProps> = ({
  onProductSelect,
  foundProduct,
  onContinue,
  barcode,
  onSubmit,
  isOnSubmitLoading,
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <NonComplianceSection>
        <TextWithBottomMargin margin={defaultTheme.spacing.S4}>
          {barcode ? (
            <FormattedMessage
              id="nonCompliance.identifyProductStep.descriptionWithBarcode"
              defaultMessage="Identify the product shown in the photos above with barcode {barcode}."
              values={{
                barcode: (
                  <Text bold as="span">
                    {barcode}
                  </Text>
                ),
              }}
            />
          ) : (
            <FormattedMessage
              id="nonCompliance.identifyProductStep.description"
              defaultMessage="Identify the product shown in the photos above."
            />
          )}
        </TextWithBottomMargin>
        {foundProduct ? (
          <ProductSearchSelection selectedProduct={foundProduct} onClear={() => onProductSelect(undefined)} />
        ) : (
          <>
            <ProductSearch
              allowZeroInventorySelection
              onSelectProduct={onProductSelect}
              placeholder={formatMessage({
                id: "nonCompliance.identifyProductStep.searchPlaceholder",
                defaultMessage: "Search for product",
              })}
              isBarcodeRequired
              shouldIncludeBrandedPackaging
            />
            <SpacedElevioLink articleId={ElevioArticleId.AddNewSkus}>
              <Text bold>
                <FormattedMessage
                  id="nonCompliance.identifyProductStep.helpArticleLink"
                  defaultMessage="Can't find your product?"
                />
              </Text>
            </SpacedElevioLink>
            <Text appearance="INFO">
              <FormattedMessage
                id="nonCompliance.identifyProductStep.syncProductsMessage"
                defaultMessage="Sync your products and search again in a few minutes."
              />
            </Text>
          </>
        )}
      </NonComplianceSection>
      <NonComplianceSection>
        {onSubmit ? (
          <Button
            data-testid="submit_button"
            onClick={onSubmit}
            disabled={!foundProduct || isOnSubmitLoading}
            loading={isOnSubmitLoading}
          >
            <FormattedMessage id="submit" defaultMessage="Submit" />
          </Button>
        ) : (
          <Button data-testid="continue_button" secondary disabled={!foundProduct} onClick={onContinue}>
            {formatMessage({
              id: "continue",
              defaultMessage: "Continue",
            })}
          </Button>
        )}
      </NonComplianceSection>
    </>
  );
};
