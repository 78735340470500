import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import DismissableAlert from "common/components/DismissableAlert/DismissableAlert";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";

export const StorageRecommendationAlert: FC<{ onClose: () => void }> = (props) => {
  return (
    <DismissableAlert
      onClose={props.onClose}
      alertType="PRIMARY"
      className="storage-recommendation-banner"
      title={
        <FormattedMessage
          id={"inbounds.storageRecommendation.excessToEcomRecommendation.heading"}
          defaultMessage={"Consider sending to reserve storage"}
        />
      }
      description={
        <FormattedMessage
          id={"inbounds.storageRecommendation.excessToEcomRecommendation.content"}
          defaultMessage={
            "To save on storage costs, enable smart replenishment to ecom, and more, select “reserve storage” in the previous step. {here}"
          }
          values={{
            here: (
              <ElevioBorderlessButton articleId={ElevioArticleId.ReserveStorage}>
                <FormattedMessage
                  id="inbounds.storageRecommendation.excessToEcomRecommendation.learnMore"
                  defaultMessage="Learn more about reserve storage benefits"
                />
              </ElevioBorderlessButton>
            ),
          }}
        />
      }
    />
  );
};
