import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { isAddressValid } from "freight/common/utils/validateAddressDetails";
import { FreightBookingActionTypes } from "freight/store/freightBooking/actions/FreightBookingActionTypes";
import { DeliveryDetailsState } from "freight/types/DeliveryTypes";
import produce from "immer";
import { DeliveryDetailsActionTypes } from "../actions/DeliveryDetailsActionTypes";
import { deliveryDetailsInitialState } from "./freightDeliveryDetailsInitialState";

const reducer: ReducerDictionary<DeliveryDetailsState> = {
  ...handleSimpleReducerUpdates([
    DeliveryDetailsActionTypes.SET_DELIVERY_APPOINTMENT_REQUIRED,
    DeliveryDetailsActionTypes.SET_DELIVERY_REFERENCE_NUMBER,
    DeliveryDetailsActionTypes.SET_DELIVERY_INSTRUCTIONS,
    DeliveryDetailsActionTypes.SET_DELIVERY_FACILITY_OPERATING_HOURS,
    DeliveryDetailsActionTypes.SET_DELIVERY_CONTACT,
  ]),
  [DeliveryDetailsActionTypes.SET_DELIVERY_ADDRESS]: (state, { address }) =>
    produce(state, (draft) => {
      const { name, ...deliveryAddress } = address;
      draft.address = { ...deliveryAddress, name: draft.address.name };
    }),
  [DeliveryDetailsActionTypes.SET_DELIVERY_COMPANY_NAME]: (state, { companyName }) =>
    produce(state, (draft) => {
      draft.address = { ...draft.address, name: companyName };
    }),
  [FreightBookingActionTypes.FREIGHT_BOOKING_COMPLETED]: () => deliveryDetailsInitialState,
  [FreightBookingActionTypes.RESET_FREIGHT_BOOKING_STATE]: () => deliveryDetailsInitialState,
  [FreightBookingActionTypes.SET_FREIGHT_BOOKING_QUOTE]: (state, { quote }) =>
    produce(state, (draft) => {
      if (quote.to?.address && isAddressValid(quote.to.address)) {
        draft.address = quote.to.address;
      }
    }),
};

export const deliveryDetailsReducer = createReducer<DeliveryDetailsState>(deliveryDetailsInitialState, reducer);
