import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { StorageInboundCreateState } from "../StorageInboundCreateState.types";
import { getStorageInboundCreate } from "./getStorageInboundCreate";
import { isEmpty, values } from "lodash";
import { StorageInboundProductItemDetails } from "../StorageInboundProductItemDetails.types";

export const getSelectedProductsWithUnsetCasePacks = createSelector<
  RootState,
  StorageInboundCreateState,
  StorageInboundProductItemDetails[]
>(getStorageInboundCreate, (storageInboundCreate) => {
  const { selectedProductsCasePacks, selectedProducts } = storageInboundCreate;
  return values(selectedProducts).filter(({ dsku }) => isEmpty(selectedProductsCasePacks[dsku]));
});
