import { createReducer, ReducerDictionary } from "common/ReduxUtils";
import { ProductChooserActionTypes } from "./ProductChooserActions";
import { KitComponentResponse } from "@deliverr/business-types";

export interface KitComponentInfoResponse extends KitComponentResponse {
  name?: string;
  isFefoEnabled?: boolean;
  isLotTrackingEnabled?: boolean;
  packOf: string;
  kittedDSKU?: string;
  caseQty: number;
}

export interface MaxInventoryByWarehouse {
  dsku: string;
  availableToPurchase: number;
  quantity: number;
}

export interface AvailableInventoryMap {
  [packOf: string]: MaxInventoryByWarehouse;
}

export interface ProductChooserKittedProductResponse {
  kitComponents: KitComponentInfoResponse[];
  availableInventoryMap: AvailableInventoryMap;
  availableToKit: number;
}

export interface ProductChooserKittedProducts {
  [dsku: string]: ProductChooserKittedProductResponse;
}

export interface ProductChooserState {
  kittedProducts?: ProductChooserKittedProducts;
}

export const productChooserInitialState: ProductChooserState = {};

const reducers: ReducerDictionary<ProductChooserState> = {
  [ProductChooserActionTypes.SAVE_SEARCHED_KITTED_PRODUCT_DETAILS]: (state, { dsku, kittedProductDetails }) => ({
    ...state,
    kittedProducts: {
      ...state?.kittedProducts,
      [dsku]: kittedProductDetails,
    },
  }),
  [ProductChooserActionTypes.RESET_SEARCHED_PRODUCT_DETAILS]: () => productChooserInitialState,
};

export const productChooserReducer = createReducer<ProductChooserState>(productChooserInitialState, reducers);
