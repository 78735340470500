import { NetworkMiddleware } from "./NetworkMiddleware";
import GraphQLError from "./GraphQLError";
import { logStart, logSuccess, logError } from "Logger";

/* PartialResponseMiddleware enables partial responses, since it changes the
 * response behavior, it should most often be placed at the end of the
 * middlewares list since response travels upward through the middleware chain
 * */
export const PartialResponseMiddleware = new NetworkMiddleware(async (request, next) => {
  const ctx = logStart({ fn: "PartialResponseMiddleware", request });
  try {
    const response = await next(request);
    logSuccess(ctx, "GraphQL request succeeded with full response");
    return response;
  } catch (error) {
    if (error instanceof GraphQLError) {
      const typedError: GraphQLError = error;

      // If the error contains a partialResponse, return it instead
      if (typedError.extra?.partialResponse != null) {
        const { partialResponse } = typedError.extra;
        logSuccess(ctx, "GraphQL request succeeded with partial response");
        return partialResponse;
      }
    }

    logError(ctx, "GraphQL request failed");
    throw error;
  }
});
