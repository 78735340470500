import { useContext } from "react";
import { useMobile } from "common/components/ui";

import { MissingBarcodeCaseContext } from "../context";

export const useMissingBarcodeSuccess = () => {
  const {
    missingBarcodeCase,
    isCaseLoading,
    shippingPlan,
    foundProduct,
    isRecognizedProduct,
    selectedBarcode,
  } = useContext(MissingBarcodeCaseContext);
  const isMobile = useMobile();

  const shouldRedirect = isRecognizedProduct && !foundProduct;

  return {
    missingBarcodeCase,
    isCaseLoading,
    shippingPlan,
    isRecognizedProduct,
    foundProduct,
    shouldRedirect,
    selectedBarcode,
    isMobile,
  };
};
