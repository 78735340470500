import { Button, DefaultTheme, ThemeProps, Title } from "common/components/ui";
import { ButtonType, LoaderButton } from "common/components/WithLoader/LoaderButton";
import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import Modal from "common/components/Modal";
import { ReturnLoader } from "returns/ReturnsLoader";
import { RmtId } from "@deliverr/returns-client";
import { rmtConfigProp } from "returns/rmt/RmtUtils";
import styled from "@emotion/styled";

const StyledDiv = styled.div<ThemeProps>(
  ({ theme }) => `
    padding-left: ${theme.spacing.S7};
    padding-right: ${theme.spacing.S7};
  `
);

const StyledButton = styled(Button)<{}, DefaultTheme>(
  ({ theme }) => `
    margin-left: ${theme.spacing.S4};
  `
);

const StyledTitle = styled(Title)<{}, DefaultTheme>(
  ({ theme }) => `
  padding: ${theme.spacing.S6} ${theme.spacing.S7} ${theme.spacing.S3};
  width: 100%;
  text-align: left;
  `
);

const StyledContainer = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: ${theme.spacing.S6};
  padding-top: ${theme.spacing.S6};
  padding-bottom: ${theme.spacing.S6};
  `
);

interface DisconnectRmtProps {
  deactivateRmt: () => void;
  hideDisconnectModal: () => void;
  rmtId: RmtId;
}

export const DisconnectRmt: FC<DisconnectRmtProps> = ({ deactivateRmt, rmtId, hideDisconnectModal }) => {
  const rmtName = rmtConfigProp(rmtId, "name");

  return (
    <Modal id="disconnectRmtModalId" size={"md"} hasCloseButton={true}>
      <StyledTitle data-testid="returns-rmt-disconnect-modal" displayAs="h3">
        <FormattedMessage
          id="rmt.edit.disconnectConfirmTitle"
          defaultMessage={"Disconnect {rmtName}"}
          values={{ rmtName }}
        />
      </StyledTitle>
      <>
        <StyledDiv>
          <FormattedMessage
            id="rmt.edit.disconnectConfirmBody"
            defaultMessage={`
              Are you sure you want to disconnect {rmtName}?
              Flexport will no longer ingest returns from this integration.
              Return updates for pending orders will continue to update. You can reconnect again later.
            `}
            values={{ rmtName }}
          />
        </StyledDiv>
      </>
      <StyledContainer data-testid="returns-rmt-manage-disconnect-container">
        <LoaderButton
          name={ReturnLoader.ReturnsRmtLoader}
          buttonType={ButtonType.PrimaryReversed}
          onClick={deactivateRmt}
          loaderText={<FormattedMessage id="Disconnecting" defaultMessage={"Disconnecting"} />}
        >
          <FormattedMessage id="Disconnect" defaultMessage={"Disconnect"} />
        </LoaderButton>
        <StyledButton onClick={hideDisconnectModal}>
          <FormattedMessage id="cancel" defaultMessage={"Cancel"} />
        </StyledButton>
      </StyledContainer>
    </Modal>
  );
};
