import { createActionCreator } from "common/ReduxUtils";
import { ReplenishmentError, TransferCreateActionTypes, TransferCreateState } from "../store";
import { SelectedProductData } from "common/components/ProductChooser";
import {
  AttachmentType,
  WholesaleOrderDTO,
  WholesaleShipmentType,
  WholesaleShippingMethod,
} from "@deliverr/wholesale-client";
import { StorageTransferOrderResponse } from "common/clients/storage/StorageTransferOrderResponse/StorageTransferOrderResponse";
import { WholesaleErrorType } from "../../../common/wholesale-common/utils/WholesaleErrorTypes";
import {
  CarrierAccount,
  InvalidOrderItemDto,
  KitComponentOrderItemDto,
  OrderResponse,
  ShippingPartner,
  ShippingType,
} from "@deliverr/replenishment-client";
import { ChargeRows } from "./replenishmentOrder/utils/help";
import { Estimation } from "../steps/common/replenishment/Estimation";
import { ATPDetails } from "common/clients/inventory/ATP/ATPDetails";

export const transferCreateChooseDestinationType = createActionCreator<TransferCreateState["destinationType"]>(
  TransferCreateActionTypes.TRANSFER_CREATE_CHOOSE_DESTINATION_TYPE,
  "destinationType"
);

interface ShippingMethodPayload {
  shippingMethod: TransferCreateState["shippingMethod"];
}

interface ShipmentTypePayload {
  shipmentType: TransferCreateState["cargoType"];
}
type ShippingConfig = (ShippingMethodPayload & ShipmentTypePayload) | ShippingMethodPayload | ShipmentTypePayload;

export const transferCreateChooseShippingConfig = createActionCreator<ShippingConfig>(
  TransferCreateActionTypes.TRANSFER_CREATE_CHOOSE_SHIPPING_CONFIG,
  "shippingConfig"
);

export const transferCreateSetShipmentShippingMethod = createActionCreator<
  string,
  WholesaleShippingMethod,
  WholesaleShipmentType
>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_SHIPMENT_SHIPPING_CONFIG,
  "shipmentId",
  "shippingMethod",
  "shipmentType"
);

export const transferCreateSetFbaShipmentId = createActionCreator<string>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_FBA_SHIPMENT_ID,
  "fbaShipmentId"
);

export const transferCreateSetFbaReferenceId = createActionCreator<string>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_FBA_REFERENCE_ID,
  "fbaReferenceId"
);

export const transferCreateSetAttachments = createActionCreator<TransferCreateState["attachments"]>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_ATTACHMENTS,
  "attachments"
);

export const transferCreateSelectDestinationAddress = createActionCreator<TransferCreateState["destinationAddress"]>(
  TransferCreateActionTypes.TRANSFER_CREATE_SELECT_AMAZON_ADDRESS,
  "destinationAddress"
);

export const transferCreateGetUnifiedProduct = createActionCreator<TransferCreateState["unifiedProductResponseCache"]>(
  TransferCreateActionTypes.TRANSFER_CREATE_GET_UNIFIED_PRODUCT,
  "unifiedProductResponseCache"
);

export const transferCreateStoreSteps = createActionCreator<TransferCreateState["stack"]>(
  TransferCreateActionTypes.TRANSFER_CREATE_STORE_STEPS,
  "stack"
);

export const transferCreateReset = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_RESET);

export const transferCreateAddProduct = createActionCreator<string, SelectedProductData>(
  TransferCreateActionTypes.TRANSFER_CREATE_ADD_PRODUCT,
  "dsku",
  "product"
);

export const transferCreateResetProductSelection = createActionCreator(
  TransferCreateActionTypes.TRANSFER_CREATE_RESET_PRODUCT_SELECTION
);

export const transferBulkAddProduct = createActionCreator<TransferCreateState["selectedProducts"]>(
  TransferCreateActionTypes.TRANSFER_CREATE_BULK_ADD_PRODUCT,
  "selectedProducts"
);

export const transferCreateRemoveProduct = createActionCreator<string>(
  TransferCreateActionTypes.TRANSFER_CREATE_REMOVE_PRODUCT,
  "dsku"
);

export const transferCreateUpdateProductQty = createActionCreator<string, number>(
  TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_TOTAL_QTY,
  "dsku",
  "totalQty"
);

export const transferCreateUpdateProductNumberOfCases = createActionCreator<string, number>(
  TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_NUMBER_OF_CASES,
  "dsku",
  "numberOfCases"
);

export const transferCreateUpdateProductCaseQty = createActionCreator<string, number>(
  TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_CASE_QUANTITY,
  "dsku",
  "caseQty"
);

export const transferCreateUpdateProductLotNumber = createActionCreator<string, ATPDetails | undefined>(
  TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_LOT_NUMBER,
  "dsku",
  "atpDetails"
);

export const transferCreateChooseOriginWarehouse = createActionCreator<TransferCreateState["originStorageWarehouse"]>(
  TransferCreateActionTypes.TRANSFER_CREATE_CHOOSE_ORIGIN_STORAGE_WAREHOUSE,
  "originStorageWarehouse"
);

export const transferCreateSetStorageTransferOrder = createActionCreator<
  Partial<StorageTransferOrderResponse>,
  TransferCreateState["selectedProducts"] | undefined,
  TransferCreateState["selectedProductsCaseInfo"] | undefined
>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_STORAGE_TRANSFER_ORDER,
  "storageTransferOrder",
  "selectedProducts",
  "selectedProductsCaseInfo"
);

export const transferCreateSetDraftFlag = createActionCreator<TransferCreateState["isTransferToEcomDraft"]>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_DRAFT_FLAG,
  "isTransferToEcomDraft"
);

export const transferCreateSetWholesaleOrder = createActionCreator<WholesaleOrderDTO>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_WHOLESALE_ORDER,
  "wholesaleOrder"
);

export const transferCreateUpdateStateFromWholesaleOrder = (data: TransferCreateState) => ({
  type: TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_STATE_FROM_WHOLESALE_ORDER,
  payload: data,
});
export const transferCreateSetError = createActionCreator<WholesaleErrorType>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_ERROR,
  "errorType"
);

export const transferCreateSetUploading = createActionCreator<AttachmentType, boolean>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_UPLOADING,
  "attachmentType",
  "value"
);

export type UploadAttachmentType = keyof NonNullable<TransferCreateState["labelFiles"]>;

export const transferCreateSetLabelFilename = createActionCreator<UploadAttachmentType, string | null>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_LABEL_FILENAME,
  "label",
  "file"
);

export const transferCreateSetReplenishmentOrder = createActionCreator<OrderResponse>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_REPLENISHMENT_ORDER,
  "replenishmentOrder"
);

export const transferCreateSetReplenishmentOrderQuotes = createActionCreator<ChargeRows>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_REPLENISHMENT_ORDER_QUOTES,
  "replenishmentOrderEstimates"
);

export const transferCreateSetReplenishmentError = createActionCreator<ReplenishmentError>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_REPLENISHMENT_ERROR,
  "replenishmentError"
);

export const transferCreateSetPrepType = createActionCreator<TransferCreateState["prepOption"]>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_PREP_TYPE,
  "prepOption"
);

export const transferCreateSetPrepTypes = createActionCreator<TransferCreateState["prepOptions"]>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_PREP_TYPES,
  "prepOptions"
);

export const transferCreateSetValidShipBy = createActionCreator<TransferCreateState["validShipBy"]>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_VALID_SHIP_BY,
  "validShipBy"
);

export const transferCreateSetPoNumber = createActionCreator<TransferCreateState["poNumber"]>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_PO_NUMBER,
  "poNumber"
);

export const transferCreateSetShipmentValidationError = createActionCreator<
  ShippingPartner,
  ShippingType,
  Partial<Estimation>
>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_SHIPMENT_VALIDATION_ERROR,
  "shippingPartner",
  "shippingType",
  "shipmentValidationError"
);

export const updateInvalidOrderItem = createActionCreator<InvalidOrderItemDto>(
  TransferCreateActionTypes.TRANSFER_CREATE_MUTATE_INVALID_ORDER_ITEM,
  "invalidOrderItem"
);

export const setInvalidOrderItems = createActionCreator<InvalidOrderItemDto[]>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_INVALID_ORDER_ITEMS,
  "invalidOrderItems"
);

export const setPartialFill = createActionCreator<boolean>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_PARTIAL_FILL,
  "partialFill"
);

export const transferCreateSetIsEachPickingOrderFlag = createActionCreator<TransferCreateState["isEachPickingOrder"]>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_IS_EACH_PICKING_ORDER,
  "isEachPickingOrder"
);

export const transferCreateSetCarrierAccounts = createActionCreator<TransferCreateState["carrierAccounts"]>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_CARRIER_ACCOUNTS,
  "carrierAccounts"
);

export const transferCreateAddCarrierAccount = createActionCreator<CarrierAccount>(
  TransferCreateActionTypes.TRANSFER_CREATE_ADD_CARRIER_ACCOUNT,
  "carrierAccount"
);

export const transferCreateUpdateCarrierAccount = createActionCreator<CarrierAccount>(
  TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_CARRIER_ACCOUNT,
  "carrierAccount"
);

export const transferCreateDeleteCarrierAccount = createActionCreator<number>(
  TransferCreateActionTypes.TRANSFER_CREATE_DELETE_CARRIER_ACCOUNT,
  "carrierAccountId"
);

export const transferCreateSetSelectedCarrierAccount = createActionCreator<CarrierAccount | undefined>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_SELECTED_CARRIER_ACCOUNT,
  "selectedCarrierAccount"
);

export const transferCreateSetKitComponentOrderItems = createActionCreator<KitComponentOrderItemDto | undefined>(
  TransferCreateActionTypes.TRANSFER_CREATE_SET_KIT_COMPONENT_ORDER_ITEMS,
  "kitComponentOrderItem"
);

export const transferCreateRemoveKitComponentOrderItems = createActionCreator<string>(
  TransferCreateActionTypes.TRANSFER_CREATE_REMOVE_KIT_COMPONENT_ORDER_ITEMS,
  "dsku"
);

export const transferCreateUpdateKitComponentOrderItemUnits = createActionCreator<string, number, number>(
  TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_KIT_COMPONENT_ORDER_ITEM_UNIT,
  "dsku",
  "kitComponentQuantity",
  "selectedKitUnits"
);

export const resetTransferCreateUpdateKitComponentOrderItemUnits = createActionCreator(
  TransferCreateActionTypes.TRANSFER_RESET_KIT_COMPONENT_ORDER_ITEM_UNITS
);
