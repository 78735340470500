import { Sort } from "common/models/Sort";
import { Thunk } from "common/ReduxUtils";
import { OrderListActionTypes } from "../OrderListActionTypes";
import { searchOrders } from "./searchOrders";

export const setOrdersSort: Thunk =
  (sort: Sort, isOrdersLogisticsSearchOn: boolean, useOrdersLogisticsSearchV2: boolean) => (dispatch) => {
    dispatch({
      type: OrderListActionTypes.SET_ORDERS_SORT,
      sort,
    });
    dispatch(searchOrders({ isOrdersLogisticsSearchOn, useOrdersLogisticsSearchV2 }));
  };
