import { generatePath } from "react-router-dom";
import { Path } from "paths";

import { LiveFastTag } from "common/FastTag/LiveFastTag";

export interface PathPage {
  type: "path";
  id: string;
}

export const fastTagDetailPage = (fastTag: LiveFastTag): PathPage =>
  ({
    type: "path",
    id: generatePath(Path.fastTagDetail, { program: fastTag }),
  } as const);
