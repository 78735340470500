import { QuoteLocationTypes } from "freight/types/QuoteLocationTypes";
import { Ports } from "../constants/Ports";
import React from "react";
import { FreightMode, LocationType, SupportedTradingPartner } from "@deliverr/freight-client";
import { getSelectOptions } from "common/utils/getSelectOptions";
import { defineMessages, FormattedMessage, MessageDescriptor } from "react-intl";
import { AddressInputErrorTypes } from "./AddressInputErrorTypes";
import { Anchor, Notification } from "common/components/ui";
import { Link } from "react-router-dom";
import { Path } from "paths";
import { FREIGHT_CONTACT_EMAIL } from "freight/constants/links";
import { freightAddressErrorMessages } from "freight/store/freightAddress/freightAddressLabels";
import { FreightAddressTypes } from "freight/store/freightAddress/FreightAddressTypes";
import { CreateInboundButton } from "inbounds/CreateInboundButton";
import { LinkButton } from "common/components/LinkButton";
import { FTL_PALLETS_PER_TRUCK } from "freight/constants/cargoPalletConstants";

export const freightQuoteLabels = defineMessages({
  bladeHeader: {
    id: "freight.quoting.blade.header",
    defaultMessage: "Quote freight order",
  },
  pickupAddress: {
    id: "freight.quoting.blade.pickupAddress",
    defaultMessage: "Pickup address or postal code",
  },
  pickupLiftgateRequired: {
    id: "freight.quoting.blade.pickupLiftgateRequired",
    defaultMessage: "Liftgate required for pickup",
  },
  pickupAddressZipRequiredError: {
    id: "freight.quoting.blade.pickupAddress.zipRequired",
    defaultMessage: "Please make sure the pickup address includes a zip",
  },
  pickupAddressNotification: {
    deliverrWarehouse: {
      title: {
        id: "freight.quoting.blade.pickupAddress.notification.deliverrWarehouse.title",
        defaultMessage: "Shipping from Flexport",
      },
      buttonText: {
        id: "freight.quoting.blade.pickupAddress.notification.deliverrWarehouse.buttonText",
        defaultMessage: "Create transfer",
      },
    },
    portAddressNotSupported: {
      title: {
        id: "freight.quoting.blade.pickupAddress.notification.portAddressNotSupported.title",
        defaultMessage: "Other ports not yet supported",
      },
      description: {
        id: "freight.quoting.blade.pickupAddress.notification.portAddressNotSupported.description",
        defaultMessage: "Contact us directly to book your order.",
      },
      buttonText: {
        id: "freight.quoting.blade.pickupAddress.notification.portAddressNotSupported.buttonText",
        defaultMessage: "Contact us",
      },
    },
  },
  locationTypeRequired: {
    id: "freight.quoting.blade.locationTypeRequired",
    defaultMessage: "Please select a location type",
  },
  deliveryAddress: {
    id: "freight.quoting.blade.deliveryAddress",
    defaultMessage: "Delivery address or postal code",
  },
  deliveryLiftgateRequired: {
    id: "freight.quoting.blade.deliveryLiftgateRequired",
    defaultMessage: "Liftgate required for delivery",
  },
  deliveryAddressZipRequiredError: {
    id: "freight.quoting.blade.deliveryAddress.zipRequired",
    defaultMessage: "Please make sure the delivery address includes a zip",
  },
  deliveryAddressNotification: {
    deliverrWarehouse: {
      title: {
        id: "freight.quoting.blade.deliveryAddress.notification.deliverrWarehouse.title",
        defaultMessage: "Shipping to Flexport",
      },
    },
  },
  locationType: {
    id: "freight.quoting.blade.locationType",
    defaultMessage: "Location type",
  },
  numberOfPallets: {
    id: "freight.quoting.blade.numberOfPallets",
    defaultMessage: "Number of pallets",
  },
  numberOfPalletsRequiredError: {
    id: "freight.quoting.blade.numberOfPalletsRequired",
    defaultMessage: `Please enter at least 1 pallet, up to a maximum of ${FTL_PALLETS_PER_TRUCK}`,
  },
  calculateRate: {
    id: "freight.quoting.blade.calculateRate",
    defaultMessage: "Calculate rate",
  },
  calculateRateMessage: {
    id: "freight.quoting.blade.calculateRateMessage",
    defaultMessage: "Enter addresses and pallet details to calculate rates.",
  },
  bookQuote: {
    id: "freight.quoting.blade.bookQuote",
    defaultMessage: "Book quote",
  },
  liftgateRequiredTooltip: {
    id: "freight.quoting.blade.liftgateRequiredTooltip",
    defaultMessage: "A liftgate is required when the facility doesn't have a forklift or loading dock.",
  },
  quoteSuccessMessage: {
    id: "freight.quoting.success",
    defaultMessage: "<Text>Quote {quoteId}</Text> can now be found in your quotes dashboard.",
  },
  drayageQuoteSuccessMessage: {
    id: "freight.quoting.drayage.success",
    defaultMessage: "<Text>Quote {drayageQuoteId}</Text> can now be found in your quotes dashboard.",
  },
  quoteErrorMessage: {
    id: "freight.quoting.error",
    defaultMessage:
      "There was an error generating your quotes, Please try again. If the issue persists, reach out to {email}",
  },
  [FreightMode.LTL]: {
    estimatedDeliveryTime: {
      id: "freight.quoting.blade.rate.deliveryTime",
      defaultMessage: "{lowerBoundTransitDays} - {transitDays} estimated calendar days",
    },
  },
  [FreightMode.DRAYAGE]: {
    estimatedDeliveryTime: {
      id: "freight.quoting.blade.rate.deliveryTime",
      defaultMessage: "{transitDays} estimated calendar days",
    },
  },
  [FreightMode.FTL]: {
    estimatedDeliveryTime: {
      id: "freight.quoting.blade.rate.deliveryTime",
      defaultMessage: "{transitDays} estimated calendar days",
    },
  },
  expirationTime: {
    id: "freight.quoting.blade.rate.expirationTime",
    defaultMessage: `{numberOfQuotes, plural, one {Quote expires} other {Quotes expire}} {expirationTime}`,
  },
  expiredQuote: {
    id: "freight.quoting.blade.rate.expiredQuote",
    defaultMessage: "Quote expired",
  },
  shippingQuote: {
    id: "freight.quoting.blade.rate.shippingQuote",
    defaultMessage: "Shipping quote",
  },
  loadQuoteErrorMessage: {
    id: "freight.quoting.loadQuote.error",
    defaultMessage: "There was an error loading your quote, Please try again.",
  },
  portAddress: {
    id: "freight.quoting.blade.portAddress",
    defaultMessage: "Select port",
  },
  numberOfContainers: {
    id: "freight.quoting.blade.numberOfContainers",
    defaultMessage: "Number of containers",
  },
  oneContainer: {
    id: "freight.quoting.blade.numberOfContainers.one",
    defaultMessage: "1 container",
  },
  containerTooltip: {
    id: "freight.quoting.blade.numberOfContainers.tooltip",
    defaultMessage: "To quote and book multiple containers contact us at {email}",
  },
});

export const freightQuoteCarrierTitleLabels = defineMessages({
  [FreightMode.FTL]: {
    id: "freight.quoting.blade.rate.ftl.title",
    defaultMessage: "Exclusive<p>(Full truckload)</p>",
  },
  [FreightMode.LTL]: {
    id: "freight.quoting.blade.rate.ltl.title",
    defaultMessage: "Shared<p>(Less than truckload)</p>",
  },
  [FreightMode.DRAYAGE]: {
    id: "freight.quoting.blade.rate.drayage.title",
    defaultMessage: "Port Pickup<p>(Drayage)</p>",
  },
  longHaulTitle: {
    id: "freight.quoting.blade.rate.longHaul.title",
    defaultMessage: "Long Haul<p>(Drayage & full truckload)</p>",
  },
});

export const LOCATION_TYPE_LABELS: Record<QuoteLocationTypes, MessageDescriptor> = defineMessages({
  [LocationType.COMMERCIAL]: {
    id: "freight.quoting.locationType.commercial",
    defaultMessage: "Commercial",
  },
  [LocationType.RESIDENTIAL]: {
    id: "freight.quoting.locationType.residential",
    defaultMessage: "Residential",
  },
  [LocationType.AIRPORT]: {
    id: "freight.quoting.locationType.airport",
    defaultMessage: "Airport",
  },
  [LocationType.MILITARY_BASE]: {
    id: "freight.quoting.locationType.militaryBase",
    defaultMessage: "Military base",
  },
  [LocationType.MARITIME_PORT]: {
    id: "freight.quoting.locationType.maritimePort",
    defaultMessage: "Maritime port",
  },
  [SupportedTradingPartner.FULFILLMENT_BY_AMAZON]: {
    id: "freight.quoting.locationType.fba",
    defaultMessage: "Amazon Warehouse",
  },
});

export const LOCATION_TYPES = [
  LocationType.COMMERCIAL,
  LocationType.RESIDENTIAL,
  SupportedTradingPartner.FULFILLMENT_BY_AMAZON,
  LocationType.MARITIME_PORT,
];

export const getFreightQuoteLocationTypeOptions = (isDrayageSelfServeOn: boolean, isFbaDeliveryOn: boolean) =>
  getSelectOptions(
    LOCATION_TYPES.filter((type) => {
      const shouldShowMaritimePort = isDrayageSelfServeOn || type !== LocationType.MARITIME_PORT;
      const shouldShowFba = isFbaDeliveryOn || type !== SupportedTradingPartner.FULFILLMENT_BY_AMAZON;

      return shouldShowMaritimePort && shouldShowFba;
    }) as QuoteLocationTypes[],
    LOCATION_TYPE_LABELS
  );

export const PORT_SELECT_LABELS: Record<Ports, MessageDescriptor> = {
  [Ports.PORT_OF_NEWARK]: { id: "freight.quoting.ports.portOfNewark", defaultMessage: "Port Newark, Elizabeth, NJ" },
  [Ports.PORT_OF_LONG_BEACH]: {
    id: "freight.quoting.ports.portOfLongBeach",
    defaultMessage: "Port of Long Beach, Long Beach, CA",
  },
  [Ports.PORT_OF_LOS_ANGELES]: {
    id: "freight.quoting.ports.portOfLosAngeles",
    defaultMessage: "Port of Los Angeles, Los Angeles, CA",
  },
  [Ports.PORT_OF_SAVANNAH]: {
    id: "freight.quoting.ports.portOfSavannah",
    defaultMessage: "Port of Savannah, Port Wentworth, GA",
  },
  [Ports.PORT_OF_JERSEY]: {
    id: "freight.quoting.ports.portOfJersey",
    defaultMessage: "Port Jersey - GCT Bayonne, Jersey City, NJ",
  },
  [Ports.OTHER]: {
    id: "freight.quoting.ports.other",
    defaultMessage: "Other",
  },
};

export const getFreightQuotePortLocationOptions = getSelectOptions(Object.values(Ports), PORT_SELECT_LABELS);

export const pickupAddressErrorMessageMap = {
  [AddressInputErrorTypes.IS_DELIVERR_WAREHOUSE]: (
    <Notification
      showIcon
      appearance="DANGER"
      content={{
        title: <FormattedMessage {...freightQuoteLabels.pickupAddressNotification.deliverrWarehouse.title} />,
        description: (
          <FormattedMessage {...freightAddressErrorMessages[FreightAddressTypes.PICKUP].deliverrWarehouse} />
        ),
      }}
      append={
        <Link to={Path.transfersNew}>
          <FormattedMessage {...freightQuoteLabels.pickupAddressNotification.deliverrWarehouse.buttonText} />
        </Link>
      }
    />
  ),
  [AddressInputErrorTypes.PORT_NOT_SUPPORTED]: (
    <Notification
      showIcon
      appearance="DANGER"
      content={{
        title: <FormattedMessage {...freightQuoteLabels.pickupAddressNotification.portAddressNotSupported.title} />,
        description: (
          <FormattedMessage {...freightQuoteLabels.pickupAddressNotification.portAddressNotSupported.description} />
        ),
      }}
      append={
        <Anchor href={FREIGHT_CONTACT_EMAIL}>
          <FormattedMessage {...freightQuoteLabels.pickupAddressNotification.portAddressNotSupported.buttonText} />
        </Anchor>
      }
    />
  ),
};

export const deliveryAddressErrorMessageMap = {
  [AddressInputErrorTypes.IS_DELIVERR_WAREHOUSE]: (
    <Notification
      showIcon
      appearance="DANGER"
      content={{
        title: <FormattedMessage {...freightQuoteLabels.deliveryAddressNotification.deliverrWarehouse.title} />,
        description: (
          <FormattedMessage {...freightAddressErrorMessages[FreightAddressTypes.DELIVERY].deliverrWarehouse} />
        ),
      }}
      append={
        <CreateInboundButton
          render={({ onClick, defaultLabel }) => (
            <LinkButton bold onClick={onClick}>
              {defaultLabel}
            </LinkButton>
          )}
        />
      }
    />
  ),
};
