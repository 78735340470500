import React, { FC } from "react";
import { useIntl } from "react-intl";
import { NavBarButton } from "common/components/NavBarButton";
import { NotificationsBlade } from "../NotificationsBlade/NotificationsBlade";
import { useNotificationsButton } from "./useNotificationsButton";

export const NotificationsButton: FC = () => {
  const { formatMessage } = useIntl();
  const { isEnabled, indicator, isOpen, onOpen, onClose } = useNotificationsButton();

  if (!isEnabled) {
    return null;
  }

  return (
    <>
      <NavBarButton
        iconType="bell"
        indicator={indicator}
        aria-label={formatMessage({ id: "notifications.openNotifications", defaultMessage: "Open notifications" })}
        onClick={onOpen}
      />
      <NotificationsBlade isOpen={isOpen} onClose={onClose} />
    </>
  );
};
