import styled from "@emotion/styled";
import Table, { TableProps } from "common/components/lists/Table";
import { Pill, PillColors } from "common/components/ui";
import { DefaultTheme, ThemeProps } from "common/components/ui/shared";
import React from "react";
import { FormattedMessage } from "react-intl";
import { AugmentedOutOfStockOrderItem, InvalidOrderItemInventoryStatus } from "./AugmentedOutOfStockOrderItem";
import { outOfStockOrderItemLabel } from "./outOfStockOrderItemLabel";

const StatusPill = styled(Pill)<ThemeProps>(
  ({ theme }) => `
  white-space: nowrap;
  margin-top: ${theme.spacing.S1};
`
);

export const getOOSStatusPill = (item: AugmentedOutOfStockOrderItem) => {
  switch (item.inventoryStatus) {
    case InvalidOrderItemInventoryStatus.OUT_OF_STOCK:
      return (
        <StatusPill color={PillColors.RED}>
          <FormattedMessage {...outOfStockOrderItemLabel.status.outOfStock} />
        </StatusPill>
      );
    case InvalidOrderItemInventoryStatus.PARTIAL_STOCK:
      return (
        <StatusPill color={PillColors.YELLOW}>
          <FormattedMessage {...outOfStockOrderItemLabel.status.lowStock} />
        </StatusPill>
      );

    default:
      return null;
  }
};

export const OutOfStockProductTable = styled(Table)<TableProps<AugmentedOutOfStockOrderItem>, DefaultTheme>(
  ({ theme }) => `
  box-shadow: none;
  margin-bottom: 0;

  thead > tr > th {
    color: ${theme.colors.NEUTRAL["500"]};
  }

  tbody {
    tr, td {
      height: 70px;
    }
  }

  /* calculate width of first column with 2rem of extra space due to padding */
  tr > th:first-of-type, tr > td:first-of-type {
    padding-left: 0;
    width: calc(((998px - 2rem) / 6) + 2rem);
  }
`
);
