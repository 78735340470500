import { getBoxesLocked, getLoadedPlannedShipment } from "inbounds/steps/InboundStepSelectors";
import { getIsSpdDeliverr } from "inbounds/utils/shippingMethodUtils";
import { createSelector } from "reselect";
import { selectAreBoxDimensionsLocked } from "./selectAreBoxDimensionsLocked";
import { selectLoadedShipmentHasValidBoxDimensions } from "./selectLoadedShipmentHasValidBoxDimensions";

/**
 * Determine if boxes should be re-confirmed outside of the normal Shipment lifecycle.
 */
export const selectShouldReconfirmBoxes = createSelector(
  getLoadedPlannedShipment,
  getBoxesLocked,
  selectLoadedShipmentHasValidBoxDimensions,
  selectAreBoxDimensionsLocked,
  ({ shippingMethod }, areBoxesLocked, hasValidBoxDimensions, areBoxDimensionsLocked) =>
    getIsSpdDeliverr(shippingMethod) && areBoxesLocked && (!hasValidBoxDimensions || !areBoxDimensionsLocked)
);
