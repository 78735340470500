import React from "react";
import { toast } from "common/components/ui";

import { ElevioBorderlessButton, ElevioArticleId } from "common/elevio";
import { FormattedMessage } from "react-intl";

export const invalidApiKeyErr = () => {
  toast.error(
    <FormattedMessage
      id={"walmartErrors.invalidApiKey"}
      defaultMessage={
        "Your Walmart API keys are invalid. Please carefully follow {theseDirections} to input valid API keys."
      }
      values={{
        theseDirections: (
          <ElevioBorderlessButton articleId={ElevioArticleId.WalmartKeys}>
            <FormattedMessage id="walmartErrors.invalidApiKeyInterpolation" defaultMessage={"these directions"} />
          </ElevioBorderlessButton>
        ),
      }}
    />,
    { autoClose: 5000, toastId: "walmartErrors.invalidApiKey" }
  );
};

export const nonDeliverrApiKeyErr = () => {
  toast.error(
    <FormattedMessage
      id={"walmartErrors.nonDeliverrApiKey"}
      defaultMessage={
        "The API keys you submitted aren't delegated for Flexport. Please carefully follow {theseDirections} to input valid API keys for Flexport."
      }
      values={{
        theseDirections: (
          <ElevioBorderlessButton articleId={ElevioArticleId.WalmartKeys}>
            <FormattedMessage id="walmartErrors.nonDeliverrApiKeyInterpolation" defaultMessage={"these directions"} />
          </ElevioBorderlessButton>
        ),
      }}
    />,
    { autoClose: 5000, toastId: "walmartErrors.invalidApiKey" }
  );
};

export const invalidApiPermissions = () => {
  toast.error(
    <div>
      <FormattedMessage
        id={"walmartErrors.invalidApiPermissions"}
        defaultMessage={
          "You input API keys that do not provide Flexport with sufficient access to your Walmart.com account. Please follow these directions:"
        }
      />
      <ul style={{ listStyleType: "decimal" }}>
        <li>
          <FormattedMessage
            id={"walmartErrors.invalidApiPermissions1"}
            defaultMessage={"Go {here}"}
            values={{
              here: (
                <a href="https://developer.walmart.com/#/generateKey" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="walmartErrors.invalidApiPermissions1interpolation" defaultMessage={"here"} />
                </a>
              ),
            }}
          />
        </li>
        <li>
          <FormattedMessage
            id={"walmartErrors.invalidApiPermissions2"}
            defaultMessage={`Under "Production Keys for Solution Providers (Delegated Access)", find the row for Flexport.`}
          />
        </li>
        <li>
          <FormattedMessage
            id={"walmartErrors.invalidApiPermissions3"}
            defaultMessage={`Set all permissions with a "Full Access" option to "Full Access". All permissions without a 'Full Access' option must be set to 'View Only'.`}
          />
        </li>
        <li>
          <FormattedMessage id={"walmartErrors.invalidApiPermissions3"} defaultMessage={`Click the "save" button.`} />
        </li>
        <li>
          <FormattedMessage
            id={"walmartErrors.invalidApiPermissions4"}
            defaultMessage={`Wait 10 minutes, and then return to this page and resubmit your API keys.`}
          />
        </li>
      </ul>
    </div>,
    { closeOnClick: false, autoClose: 5000, toastId: "walmartErrors.invalidApiPermissions" }
  );
};
