import { FormattedMessage, MessageDescriptor } from "react-intl";
import React, { FC } from "react";

import { RefundStatus } from "@deliverr/returns-client";

const refundedMessage = {
  id: "returns.returnsDetail.returnRefundStatusInfo.refunded",
  defaultMessage: "The customer was refunded for the full amount.",
};

const notRefundedMessage = {
  id: "returns.returnsDetail.returnRefundStatusInfo.notRefunded",
  defaultMessage: "The customer was not refunded.",
};

const notInitiatedMessage = {
  id: "returns.returnsDetail.returnRefundStatusInfo.notInitiatedTitle",
  defaultMessage: "An automatic refund was not initiated.",
};

const refundInitiatedMessage = {
  id: "returns.returnsDetail.returnRefundStatusInfo.refundInitiated",
  defaultMessage: "A refund is being processed.",
};

export const refundStatusMessage: Record<string, MessageDescriptor> = {
  [RefundStatus.REFUNDED]: refundedMessage,
  [RefundStatus.REFUND_NOT_INITIATED]: notInitiatedMessage,
  [RefundStatus.REFUND_INITIATED]: refundInitiatedMessage,
  [RefundStatus.NOT_REFUNDED]: notRefundedMessage,
};

export const RefundStatusInfo: FC<{ refundStatus: RefundStatus }> = ({ refundStatus }) => {
  const { id, defaultMessage } = refundStatusMessage[refundStatus];
  return (
    <div>
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
    </div>
  );
};
