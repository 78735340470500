import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "../ReduxUtils";
import { FlexportUserActionTypes } from "./FlexportUserActions";
import { AnyAction } from "redux";

export interface FlexportUserState {
  dbid: number | undefined;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  admin: boolean | null;
  companyEntityId: number | undefined;
  clientFid: string | undefined;
  displaySmbOnboarding: boolean | null;
  completedSmbCompanyDetailsOnboarding: boolean | null;
  submittedSmbCompanyDetailsOnboarding: boolean | null;
  completedSmbAuthorizationOnboarding: boolean | null;
  submittedSmbAuthorizationOnboarding: boolean | null;
  isFlexportCompanyExisting: boolean | null;
  isUserConnectedWithFlexport: boolean | null;
}

export const flexportUserInitialState: FlexportUserState = {
  dbid: undefined,
  name: "",
  firstName: "",
  lastName: "",
  email: "",
  admin: false,
  companyEntityId: undefined,
  clientFid: undefined,
  displaySmbOnboarding: false,
  completedSmbCompanyDetailsOnboarding: false,
  submittedSmbCompanyDetailsOnboarding: false,
  completedSmbAuthorizationOnboarding: false,
  submittedSmbAuthorizationOnboarding: false,
  isFlexportCompanyExisting: null,
  isUserConnectedWithFlexport: null,
};

const reducerDict: ReducerDictionary<FlexportUserState> = {
  ...handleSimpleReducerUpdates([
    FlexportUserActionTypes.SET_IS_FLEXPORT_COMPANY_EXISTING,
    FlexportUserActionTypes.SET_IS_USER_CONNECTED_WITH_FLEXPORT,
  ]),
  [FlexportUserActionTypes.SET_FLEXPORT_USER]: (state: FlexportUserState, action: AnyAction) => {
    return {
      ...state,
      name: action.name,
      firstName: action.firstName,
      lastName: action.lastName,
      email: action.email,
      admin: action.admin,
      dbid: action.dbid,
      companyEntityId: action.companyEntityId,
      clientFid: action.clientFid,
      displaySmbOnboarding: action.displaySmbOnboarding,
      completedSmbCompanyDetailsOnboarding: action.completedSmbCompanyDetailsOnboarding,
      submittedSmbCompanyDetailsOnboarding: action.submittedSmbCompanyDetailsOnboarding,
      completedSmbAuthorizationOnboarding: action.completedSmbAuthorizationOnboarding,
      submittedSmbAuthorizationOnboarding: action.submittedSmbAuthorizationOnboarding,
      // the following values are set from separate REST calls
      isFlexportCompanyExisting: action.isFlexportCompanyExisting ?? state.isFlexportCompanyExisting,
      isUserConnectedWithFlexport: action.isUserConnectedWithFlexport ?? state.isUserConnectedWithFlexport,
    };
  },
};

export const flexportUserReducer = createReducer<FlexportUserState>(flexportUserInitialState, reducerDict);
