import { batch } from "react-redux";
import { inboundClient } from "Clients";
import { getItemsFromById } from "common/ById";
import { SPThunkAction } from "common/ReduxUtils";
import { DraftShippingPlanItem } from "inbounds/InboundTypes";
import { handleShippingPlanItemsError } from "inbounds/store/actions/shippingPlan/updateShippingPlanItems/handleShippingPlanItemsError";
import { InboundActionTypes } from "inbounds/store/InboundActionTypes";
import log, { logError, logStart, logSuccess } from "Logger";
import { updatePlan } from "../updatePlan";
import { FeatureName, getFeatureSelector } from "common/Split";

export const updateShippingPlanItems = (): SPThunkAction<Promise<void>> => async (dispatch, getState) => {
  const state = getState();

  const {
    inbound: {
      plan: { id: planId },
      planItems,
    },
    user: { sellerId },
  } = state;

  const ctx = logStart({ fn: "updateShippingPlanItems", sellerId, planId });

  const allItems = getItemsFromById<DraftShippingPlanItem>(planItems);
  log.info({ ...ctx, allItems }, "updating shipping plan items");

  const isBoxMeasurementRestrictionsRelaxed = getFeatureSelector(FeatureName.BoxDimensionsRestrictionsRelaxed)(state);

  try {
    // Targeted inbounds currently use zero quantity to determine which items to remove from the plan
    // Once a user proceeds past the 'Select and add products' page, all 0 qty items are removed from plan
    const validItems = allItems.filter(({ qty }) => qty > 0);
    const zeroQtyItems = allItems.filter(({ qty }) => qty === 0);

    const updatedPlan = await inboundClient.setShippingPlanItems(
      sellerId,
      planId,
      validItems.map((item) => ({
        sellerId,
        shippingPlanId: planId,
        labelledBySeller: false,
        caseQty: item.caseQty || 1,
        dsku: item.dsku,
        qty: item.qty,
      }))
    );

    // remove all zero quantity items from the shipping plan
    batch(() => {
      zeroQtyItems.forEach(({ dsku }) => dispatch({ type: InboundActionTypes.REMOVE_PRODUCT, dsku }));
      dispatch(updatePlan(updatedPlan));
    });
    logSuccess({ ...ctx, updatedPlan }, "successfully updated shipping plan items");
  } catch (error) {
    logError(ctx, error, "error updating shipping plan items");
    handleShippingPlanItemsError(error, isBoxMeasurementRestrictionsRelaxed);
    throw error;
  }
};
