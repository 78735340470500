import { SPThunkAction } from "common/ReduxUtils";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { getSkuQtiesForInvalidItemOrder } from "./InvalidItemOrderUtils";
import { getAvailableInventoryInEachStorageWarehouse } from "transfers/create/store/getAvailableInventoryInEachStorageWarehouse";
import { isEmpty } from "lodash";
import { transferCreateChooseOriginWarehouse } from "transfers/create/actions";

export const setOriginWarehouseForReplenishmentInvalidItemOrder = (): SPThunkAction => async (dispatch, getState) => {
  const { replenishmentOrder } = selectTransferCreate(getState());
  const dskuQties = getSkuQtiesForInvalidItemOrder(replenishmentOrder!);

  if (dskuQties.length === 0) {
    return;
  }

  const warehouses = await dispatch(getAvailableInventoryInEachStorageWarehouse(dskuQties, false));
  if (isEmpty(warehouses)) {
    return;
  }

  // Default to any warehouse for SPS flow
  dispatch(transferCreateChooseOriginWarehouse(warehouses?.[0]));
};
