import React, { FC } from "react";
import { Box, Text } from "common/components/ui";
import { FromAddressRenderer } from "inbounds/steps/address/FromAddress";
import { useStorageFromAddress } from "./useStorageFromAddress";
import { FormattedMessage } from "react-intl";
import { FreightAddressType } from "./freightAddressType/FreightAdressType";

/**
 * @deprecated - will be replaced by OriginSearchStep in createShipment, new IPB flow
 */
export const StorageFromAddress: FC = () => {
  const {
    addresses,
    isSelectLoading,
    fromAddress,
    onAddressSubmit,
    handleAddressSelectChange,
    handleAddressInputChange,
    onPreviousClick,
  } = useStorageFromAddress();
  const title = (
    <Box marginTop="S5">
      <FormattedMessage id="storage.inbounds.fromAddress.title" defaultMessage="Where are you shipping from?" />
    </Box>
  );

  const body = (
    <Text as="p">
      <FormattedMessage
        id="storage.inbounds.fromAddress.body"
        defaultMessage="Enter the address where you'll be shipping your inventory from."
      />
    </Text>
  );
  const addressFooter = <FreightAddressType />;

  return (
    <FromAddressRenderer
      isSelectLoading={isSelectLoading}
      addresses={addresses}
      fromAddress={fromAddress}
      onNextClick={onAddressSubmit}
      handleAddressSelectChange={handleAddressSelectChange}
      handleAddressInputChange={handleAddressInputChange}
      onPreviousClick={onPreviousClick}
      titleOverride={title}
      bodyOverride={body}
      addressFooter={addressFooter}
    />
  );
};
