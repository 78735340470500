import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { ReturnsListActionTypes, OrderReturnsListState } from "./OrderReturnsListStore.types";

export const orderReturnsListInitialState: OrderReturnsListState = {
  orderReturnsList: [],
};

const reducers: ReducerDictionary<OrderReturnsListState> = {
  ...handleSimpleReducerUpdates([ReturnsListActionTypes.RETURNS_GET_LIST]),
  [ReturnsListActionTypes.RETURNS_RESET_LIST]: (state) => ({
    ...state,
    ...orderReturnsListInitialState,
  }),
};

export const orderReturnsListReducer = createReducer<OrderReturnsListState>(orderReturnsListInitialState, reducers);
