import {
  all,
  complement,
  equals,
  isEqual,
  keyBy,
  map,
  mapValues,
  omitBy,
  pipe,
  propOr,
  reject,
  set,
  sortBy,
  values,
} from "lodash/fp";
import { isTrue, notEmpty, pickAll } from "common/utils/helpers";

import { Dictionary } from "lodash";
import { PartialCustoms } from "./InternationalShippingTypes";
import idx from "idx";

export const CUSTOMS_DESCRIPTION_MIN_LENGTH = 3;
export const CUSTOMS_DESCRIPTION_MAX_LENGTH = 35;

export const getCustomsProps: (a1: PartialCustoms) => PartialCustoms = pickAll([
  "originCountry",
  "tariffCode",
  "description",
  "customsValue",
]);

const customsHasAllValues = pipe(
  values,
  all((x) => notEmpty(x) && complement(equals(0))(x))
);

const hasCustomsValues: (a1: PartialCustoms | undefined | null) => boolean = pipe(
  propOr({}, "customsInformation"),
  getCustomsProps,
  customsHasAllValues
);

const formatDescription = (desc: string) => desc?.substring(0, CUSTOMS_DESCRIPTION_MAX_LENGTH);

export const customsIsFilledValidly: (a1: PartialCustoms) => boolean = (a1) => {
  const descLength = a1?.description?.trim().length ?? 0;
  const isValidDescriptionLength =
    descLength <= CUSTOMS_DESCRIPTION_MAX_LENGTH && descLength >= CUSTOMS_DESCRIPTION_MIN_LENGTH;
  return isValidDescriptionLength && pipe(getCustomsProps, customsHasAllValues)(a1);
};

export const hasChangedCompletedCustoms = (ref: PartialCustoms, customs: PartialCustoms) =>
  !isEqual(ref, customs) && customsIsFilledValidly(customs);

export const hasFilledAndValidCustomProps: (a1: PartialCustoms | undefined | null) => boolean = pipe(
  propOr({}, "customsInformation"),
  customsIsFilledValidly
);

export const hasFilledOutValidCustomsInformation = pipe<any, any, Dictionary<boolean>, boolean>(
  omitBy("brandedPackaging"), // does not require customs information
  mapValues(hasFilledAndValidCustomProps),
  all(isTrue)
);

export const hasCustomsInformation = pipe<any, any, Dictionary<boolean>, boolean>(
  omitBy("brandedPackaging"),
  mapValues(hasCustomsValues),
  all(isTrue)
);

export const applyToAll = (prop: string, value: string) =>
  mapValues<any, any>(set(["customsInformation", prop], value));

export const sortByName = pipe(sortBy("name"), keyBy("dsku"));
const autoFillValues = (product) => {
  const salesPrice = idx(product, (_) => _.salesPrice);
  const name = idx(product, (_) => _.name);
  // eslint-disable-next-line no-extra-boolean-cast
  const hasNoCustomsValue = !Boolean(idx(product, (_) => _.customsInformation.customsValue));
  if (hasNoCustomsValue) {
    product.customsInformation = {
      ...product.customsInformation,
      customsValue: salesPrice,
      description: formatDescription(product.customsInformation?.description || name),
    };
  } else {
    product.customsInformation = {
      ...product.customsInformation,
      description: formatDescription(product.customsInformation?.description || name),
    };
  }
  return product;
};

export const deserializeProductRes = pipe<any, any, any, any>(
  reject(hasCustomsValues),
  map(autoFillValues),
  sortByName
);
