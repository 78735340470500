import { ThemeProps, Title, Button, Text, Notification, StyledButton } from "common/components/ui";
import styled from "@emotion/styled";

export const ContentContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-flow: column;
`;

export const FlexButton = styled(Button)`
  flex: 1;
`;

export const FlexButtonLink = styled(StyledButton.withComponent("a"))`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const DropzoneContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S2};
  margin-bottom: ${theme.spacing.S2};
`
);

export const TextContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S5};
  `
);

export const CenteredTitle = styled(Title)<ThemeProps>(
  ({ theme }) => `
    text-align: center;
    margin-bottom: ${theme.spacing.S4};
    margin-top: ${theme.spacing.S4};
  `
);

export const CenteredLoading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DescriptionText = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S6};

    a {
      font-weight: ${theme.font.weight.BOLD};
    }
  `
);

export const IconContainer = styled.div`
  text-align: center;
`;

export const NotificationWithMargin = styled(Notification)<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S2};
  `
);
