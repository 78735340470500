import styled from "@emotion/styled";
import { MODAL_BODY_PADDING, ThemeProps } from "common/components/ui";

// provides a full width container that extends to modal boundaries
export const ModalFooterContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  margin: 0 -${MODAL_BODY_PADDING} -${MODAL_BODY_PADDING} -${MODAL_BODY_PADDING};
  padding: 16px ${MODAL_BODY_PADDING};
  border-top: 1px solid ${theme.colors.NEUTRAL[60]};
`
);
