import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { defaultTheme, Stack } from "common/components/ui";

import {
  NonComplianceCard,
  NonComplianceSection,
  NonComplianceSuccessContainer,
  NonComplianceProductPhotoSection,
  TextWithBottomMargin,
  NonComplianceFooter,
  NonComplianceConfirmationHeader,
  NonComplianceProductSummary,
} from "inbounds/non-compliance/components";
import { ShippingPlanSummary } from "inbounds/components";
import { SimpleLoader } from "common/components/WithLoader/WithLoader";
import { useUnknownBarcodeSuccess } from "./useUnknownBarcodeSuccess";

export const UnknownBarcodeSuccess: FC = () => {
  const { ncProduct, isNCProductLoading, shippingPlan, isRecognizedProduct, foundProduct, isMobile } =
    useUnknownBarcodeSuccess();

  if (isNCProductLoading || !ncProduct) {
    return <SimpleLoader />;
  }

  const { rawPhotoKeys } = ncProduct;

  return (
    <NonComplianceCard>
      <NonComplianceSuccessContainer>
        <NonComplianceSection>
          <NonComplianceConfirmationHeader dismissed={!isRecognizedProduct} />
        </NonComplianceSection>
        {shippingPlan && (
          <NonComplianceSection>
            <ShippingPlanSummary shippingPlan={shippingPlan} />
          </NonComplianceSection>
        )}
        {rawPhotoKeys.length > 0 && (
          <NonComplianceSection>
            <TextWithBottomMargin bold margin={defaultTheme.spacing.S4}>
              <FormattedMessage id="nonCompliance.productPhotosTitle" defaultMessage="Product photos" />
            </TextWithBottomMargin>
            <Stack gap="S2">
              <NonComplianceProductPhotoSection photos={rawPhotoKeys} isSmall={isMobile} />
            </Stack>
          </NonComplianceSection>
        )}
        {isRecognizedProduct && foundProduct && (
          <>
            <NonComplianceSection>
              <NonComplianceProductSummary product={foundProduct} />
            </NonComplianceSection>
          </>
        )}
      </NonComplianceSuccessContainer>
      <NonComplianceFooter />
    </NonComplianceCard>
  );
};
