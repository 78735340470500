import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import DismissableAlert from "common/components/DismissableAlert/DismissableAlert";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";

export const FastTagCoverageAlert: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <DismissableAlert
      alertType="PRIMARY"
      appendContent={
        <ElevioLearnMoreButton articleId={ElevioArticleId.PackingInstructions}>
          {formatMessage({
            id: "inbounds.skubanaFields.instructions",
            defaultMessage: "Learn more",
          })}
        </ElevioLearnMoreButton>
      }
    >
      <FormattedMessage
        id="inbounds.boxPackingArrangement.packingInstructionsAlertDescription"
        defaultMessage="Inbound each SKU in 5 or more boxes to maximize your Fast Tag coverage"
      />
    </DismissableAlert>
  );
};
