import { InboundBooking } from "@deliverr/legacy-inbound-client";
import { AmazonAddress, AmazonDestinationType } from "inbounds/createShipment/CreateShipmentTypes";
import { parseBookingType } from "inbounds/createShipment/store/utils/parseBookingType";

export const _parseAmazonAddress = (booking: InboundBooking): AmazonAddress | null => {
  const { isByAmazon, isByFlexport, amazonDestinationType } = parseBookingType(booking.bookingType!);
  const isAmazonOnly = isByAmazon && !isByFlexport;
  const isSingleAmazonDistribution = isAmazonOnly && amazonDestinationType === AmazonDestinationType.SINGLE;
  const amazonAddress = booking.to?.fbaWarehouseAddress;
  // fbaWarehouseId is not currently returned by the API, but we can use the name as a fallback
  // TODO: Remove fallback once the code is returned
  // https://flexport.atlassian.net/browse/SMB-3680
  const fbaWarehouseId = booking.to?.fbaWarehouseId ?? booking.to?.fbaWarehouseAddress?.name;

  if (!isSingleAmazonDistribution || !amazonAddress || !fbaWarehouseId) {
    return null;
  }

  return {
    ...amazonAddress,
    latitude: amazonAddress.latLng?.lat ?? null,
    longitude: amazonAddress.latLng?.lng ?? null,
    code: fbaWarehouseId ?? null,
  } as AmazonAddress;
};
