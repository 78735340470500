import { ReducerDictionary, createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { RmtId, SellerRmtMinimal } from "@deliverr/returns-client";

import produce from "immer";

export enum RmtIntegrationActionTypes {
  SET_RMT_STATE = "SET_RMTS",
  GOTO_RMT_STEP = "GOTO_RMT_STEP",
  FETCH_RMTS_START = "FETCH_RMTS_START",
  FETCH_RMTS_SUCCESS = "FETCH_RMTS_SUCCESS",
  FETCH_RMTS_ERROR = "FETCH_RMTS_ERROR",
}

export enum RmtStepId {
  CONNECTED_RMT = "CONNECTED_RMT",
  MANAGE_RMT = "MANAGE_RMT",
  SETUP_RMT = "SETUP_RMT",
}

export interface ReturnsRmtState {
  allRmtIds: RmtId[];
  sellerRmts: {
    loading: boolean;
    error: boolean;
    loaded: boolean;
    data: SellerRmtMinimal[];
  };
  stepId?: RmtStepId;
}

const reducers: ReducerDictionary<ReturnsRmtState> = {
  ...handleSimpleReducerUpdates([RmtIntegrationActionTypes.GOTO_RMT_STEP, RmtIntegrationActionTypes.SET_RMT_STATE]),
  [RmtIntegrationActionTypes.FETCH_RMTS_START]: (state) =>
    produce(state, (draft) => {
      draft.sellerRmts.loading = true;
      draft.sellerRmts.loaded = false;
    }),
  [RmtIntegrationActionTypes.FETCH_RMTS_SUCCESS]: (state, { sellerRmts }) =>
    produce(state, (draft) => {
      draft.sellerRmts.loading = false;
      draft.sellerRmts.error = false;
      draft.sellerRmts.loaded = true;
      draft.sellerRmts.data = sellerRmts;
    }),
  [RmtIntegrationActionTypes.FETCH_RMTS_ERROR]: (state) =>
    produce(state, (draft) => {
      draft.sellerRmts.loading = false;
      draft.sellerRmts.error = true;
    }),
};

export const returnsInitialRmtState: ReturnsRmtState = {
  allRmtIds: [RmtId.RETURNLY, RmtId.RETURNGO, RmtId.LOOP, RmtId.AMAZON],
  sellerRmts: {
    loading: false,
    loaded: false,
    error: false,
    data: [],
  },
  stepId: undefined,
};

export const returnsRmtReducer = createReducer<ReturnsRmtState>(returnsInitialRmtState, reducers);
