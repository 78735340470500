import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeProps } from "../shared";
import { UploadFileValue } from "./DropzoneUtils";
import Tooltip from "rc-tooltip";
import { Anchor } from "../Anchor";
import { Stack } from "../Stack";
import { IconV2 } from "../IconV2";
import { FormattedMessage } from "react-intl";

export interface MultiMediaGalleryProps {
  values?: UploadFileValue[];
  onFileClear?: (UploadFileValue) => Promise<void>;
  allowRemovingMedia?: boolean;
}

const MediaGalleryWrapper = styled.div<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S4};
  border: ${theme.border.width.B1} ${theme.colors.NEUTRAL[80]} ${theme.border.type};
  border-radius: ${theme.spacing.S3};
  text-align: center;
`
);

const Previews = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.S2};
`
);

const Preview = styled.div<ThemeProps>(
  ({ theme }) => `
  position: relative;
  width: 150px;
  height: 150px;
  border: ${theme.border.width.B1} ${theme.colors.NEUTRAL[80]} ${theme.border.type};
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img, video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
);

const ScaledImage = styled.img<ThemeProps>`
  width: 500px;
  height: auto;
`;

const PreviewButton = styled.button<ThemeProps>(
  ({ theme }) => `
  position: absolute;
  top: ${theme.spacing.S1};
  right: ${theme.spacing.S1};
  background: ${theme.colors.SHADOW[500]};
  color: ${theme.colors.NEUTRAL["00"]};
  border: none;
  border-radius: ${theme.border.width.B2}
  cursor: pointer;
  padding: 0;
  display: flex;

  &:hover {
    color: ${theme.colors.RED["500"]};
  }
`
);

const NoPreview = styled(Stack)<ThemeProps>(
  ({ theme }) => `
  text-align: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: ${theme.spacing.S3};
`
);

export const MultiMediaGallery: React.FC<MultiMediaGalleryProps> = ({ values, onFileClear, allowRemovingMedia }) => {
  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    if (values && values.length > 0) {
      setFiles(values);
    }
  }, [values]);

  if (!values?.length) {
    return null;
  }

  return (
    <MediaGalleryWrapper>
      <Previews>
        {files.map((file) => (
          <Preview key={file.id} data-testid={`media-preview-${file.id}`}>
            {file.fileType === "image" && (
              <Tooltip placement="top" overlay={<ScaledImage src={file.url} alt={file.filename} />} mouseEnterDelay={1}>
                <Anchor href={file.url} target="_blank" download>
                  <img src={file.url} alt={file.filename} data-testid={`image-preview-${file.id}`} />
                </Anchor>
              </Tooltip>
            )}
            {file.fileType === "video" && (
              <NoPreview center>
                <IconV2 type="video" size="5x" />
                <Anchor href={file.url} target="_blank" download>
                  <FormattedMessage id="multiMediaDropzone.noPreview" defaultMessage="Click to view / download" />
                </Anchor>
              </NoPreview>
            )}
            {file.fileType === "pdf" && (
              <NoPreview center>
                <IconV2 type="file-pdf" size="5x" />
                <Anchor href={file.url} target="_blank" download>
                  <FormattedMessage id="multiMediaDropzone.noPreview" defaultMessage="Click to view" />
                </Anchor>
              </NoPreview>
            )}
            {allowRemovingMedia && (
              <PreviewButton data-testid={`remove-button-${file.id}`} onClick={() => onFileClear?.(file.id)}>
                <IconV2 type="close" size="2x" />
              </PreviewButton>
            )}
          </Preview>
        ))}
      </Previews>
    </MediaGalleryWrapper>
  );
};
