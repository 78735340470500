import React, { FC } from "react";

export interface HtmlToReactProps {
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  html: string;
}

const HtmlToReact: FC<HtmlToReactProps> = ({ as: Component = "div", className, html }) => (
  <Component className={className} dangerouslySetInnerHTML={{ __html: html }} />
);

export default HtmlToReact;
