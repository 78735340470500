import { Box, Text } from "common/components/ui";

import { CarrierEmailLink } from "common/components/CarrierEmailLink";
import { FormattedMessage } from "react-intl";
import React from "react";
import { usePickupAppointmentDetails } from "./usePickupAppointmentDetails";

export const PickupAppointmentDetail: React.FC = () => {
  const { cargoReadyDate, startTime, endTime, email, phone } = usePickupAppointmentDetails();
  const shouldShowPickupAppointmentDetails = cargoReadyDate && startTime && endTime && email && phone;

  return shouldShowPickupAppointmentDetails ? (
    <Box marginBottom={"S6"}>
      <FormattedMessage
        id="storage.inbounds.detail.pickupAppointmentDetails.pickupAppointmentDetailMessage"
        defaultMessage="Freight should be ready for pickup before {cargoReadyDate}, {startTime} – {endTime}. You may be contacted at
            {phone} or {email} if there are questions prior to pickup."
        values={{
          email: <b>{email}</b>,
          phone: <b>{phone}</b>,
          cargoReadyDate: <b>{cargoReadyDate}</b>,
          startTime: <b>{startTime}</b>,
          endTime: <b>{endTime}</b>,
        }}
      />
      <br />

      <br />
      <Text appearance="INFO">
        <FormattedMessage
          id="storage.inbounds.detail.pickupAppointmentDetails.readyMessage"
          defaultMessage="To cancel or reschedule, please contact {email} at least 24 hours before the start of your pickup day or you will be charged a cancellation fee. If your pickup doesn't happen, please contact {email} with your BOL # to resolve the issue."
          values={{
            email: CarrierEmailLink,
          }}
        />
      </Text>
    </Box>
  ) : null;
};
