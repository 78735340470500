import { capitalize } from "lodash";

const EM_TAG_REGEX = /^<em>|<\/em>/g;
export const removeEmTag = (str: string | number) => {
  return str.toString().replace(EM_TAG_REGEX, "");
};

// Replace any dashes or underscores with space,
// with the first letter of each word in uppercase and the rest in lowercase.
export const formatListing = (value: string) =>
  value
    .replace(/[-_]/g, " ")
    .split(" ")
    .map((word) => capitalize(word))
    .join(" ");
