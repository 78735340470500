import { AxiosResponse } from "axios";
import { FileExtensionType } from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { uploadSheet } from "transfers/utils/uploadFile";
import { REPLENISHMENT_TIMEOUT_MS } from "./BulkUploadConstants";
import { logError } from "Logger";

export const uploadAndFetchReplenishmentProductDetails = async (
  sellerId,
  file,
  distributionChannel,
  isEachesOrder,
  ctx
) => {
  // To handle cases where file name has multiple periods
  const fileExtension = file.name.split(".").pop();
  const timeoutPromise = new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, REPLENISHMENT_TIMEOUT_MS);
  });
  try {
    return await replenishmentClient
      .requestSheetUploadUrl({
        sellerId,
        fileExtensionType: FileExtensionType[fileExtension.toUpperCase()],
        originalFileName: file.name,
        fileSize: file.size,
      })
      .then(async (shipmentAttachmentResponse) => {
        const { url, s3FilePath, originalFileName } = shipmentAttachmentResponse?.value;
        await uploadSheet(url, file, fileExtension);
        const uploadPromise: Promise<AxiosResponse<any>> | Promise<any> =
          replenishmentClient.bulkProductSpreadsheetUpload({
            sellerId,
            s3FilePath: s3FilePath.split("/").pop() ?? "",
            originalFileName,
            distributionChannel,
            isEachesOrder,
          });
        return await Promise.race([uploadPromise, timeoutPromise]);
      });
  } catch (error) {
    logError(ctx, error, "Error uploading sheet for bulk product upload");
    return null;
  }
};
