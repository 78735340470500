export enum TransferCreateActionTypes {
  TRANSFER_CREATE_CHOOSE_DESTINATION_TYPE = "TRANSFER_CREATE_CHOOSE_DESTINATION_TYPE",
  TRANSFER_CREATE_CHOOSE_SHIPPING_CONFIG = "TRANSFER_CREATE_CHOOSE_SHIPPING_CONFIG",
  TRANSFER_CREATE_SET_SHIPMENT_SHIPPING_CONFIG = "TRANSFER_CREATE_SET_SHIPMENT_SHIPPING_CONFIG",
  TRANSFER_CREATE_SET_FBA_SHIPMENT_ID = "TRANSFER_CREATE_SET_FBA_SHIPMENT_ID",
  TRANSFER_CREATE_SET_FBA_REFERENCE_ID = "TRANSFER_CREATE_SET_FBA_REFERENCE_ID",
  TRANSFER_CREATE_SET_ATTACHMENTS = "TRANSFER_CREATE_SET_ATTACHMENTS",
  TRANSFER_CREATE_SELECT_AMAZON_ADDRESS = "TRANSFER_CREATE_SELECT_AMAZON_ADDRESS",
  TRANSFER_CREATE_GET_UNIFIED_PRODUCT = "TRANSFER_CREATE_GET_UNIFIED_PRODUCT",
  TRANSFER_CREATE_ADD_PRODUCT = "TRANSFER_CREATE_ADD_PRODUCT",
  TRANSFER_CREATE_BULK_ADD_PRODUCT = "TRANSFER_CREATE_BULK_ADD_PRODUCT",
  TRANSFER_CREATE_REMOVE_PRODUCT = "TRANSFER_CREATE_REMOVE_PRODUCT",
  TRANSFER_CREATE_UPDATE_NUMBER_OF_CASES = "TRANSFER_CREATE_UPDATE_NUMBER_OF_CASES",
  TRANSFER_CREATE_UPDATE_TOTAL_QTY = "TRANSFER_CREATE_UPDATE_TOTAL_QTY",
  TRANSFER_CREATE_UPDATE_STATE_FROM_WHOLESALE_ORDER = "TRANSFER_CREATE_UPDATE_STATE_FROM_WHOLESALE_ORDER",
  TRANSFER_CREATE_UPDATE_CASE_QUANTITY = "TRANSFER_CREATE_UPDATE_CASE_QUANTITY",
  TRANSFER_CREATE_UPDATE_LOT_NUMBER = "TRANSFER_CREATE_UPDATE_LOT_NUMBER",
  TRANSFER_CREATE_RESET = "TRANSFER_CREATE_RESET",
  TRANSFER_CREATE_RESET_PRODUCT_SELECTION = "TRANSFER_CREATE_RESET_PRODUCT_SELECTION",
  TRANSFER_CREATE_STORE_STEPS = "TRANSFER_CREATE_STORE_STEPS",
  TRANSFER_CREATE_CHOOSE_ORIGIN_STORAGE_WAREHOUSE = "TRANSFER_CREATE_CHOOSE_ORIGIN_STORAGE_WAREHOUSE",
  TRANSFER_SET_AVAILABLE_INVENTORY = "TRANSFER_SET_AVAILABLE_INVENTORY",
  TRANSFER_SET_STORAGE_TO_ECOM_RATES = "TRANSFER_SET_STORAGE_TO_ECOM_RATES",
  TRANSFER_SET_SELECTED_PRODUCTS_CASE_INFO = "TRANSFER_SET_SELECTED_PRODUCTS_CASE_INFO",
  TRANSFER_SET_ESTIMATED_NUMBER_OF_PALLETS = "TRANSFER_SET_ESTIMATED_NUMBER_OF_PALLETS",
  TRANSFER_CREATE_SET_WHOLESALE_ORDER_OLD = "TRANSFER_CREATE_SET_WHOLESALE_ORDER_OLD",
  TRANSFER_CREATE_SET_WHOLESALE_ORDER = "TRANSFER_CREATE_SET_WHOLESALE_ORDER",
  TRANSFER_CREATE_SET_ERROR = "TRANSFER_CREATE_SET_ERROR",
  TRANSFER_CREATE_SET_UPLOADING = "TRANSFER_CREATE_SET_UPLOADING",
  TRANSFER_CREATE_SET_LABEL_FILENAME = "TRANSFER_CREATE_SET_LABEL_FILENAME",
  TRANSFER_CREATE_SET_STORAGE_TRANSFER_ORDER = "TRANSFER_CREATE_SET_STORAGE_TRANSFER_ORDER",
  TRANSFER_CREATE_SET_DRAFT_FLAG = "TRANSFER_CREATE_SET_DRAFT_FLAG",
  TRANSFER_CREATE_SET_REPLENISHMENT_ORDER = "TRANSFER_CREATE_SET_REPLENISHMENT_ORDER",
  TRANSFER_CREATE_SET_REPLENISHMENT_ORDER_QUOTES = "TRANSFER_CREATE_SET_REPLENISHMENT_ORDER_QUOTES",
  TRANSFER_CREATE_SET_REPLENISHMENT_ERROR = "TRANSFER_CREATE_SET_REPLENISHMENT_ERROR",
  TRANSFER_CREATE_SET_PREP_TYPE = "TRANSFER_CREATE_SET_PREP_TYPE",
  TRANSFER_CREATE_SET_PREP_TYPES = "TRANSFER_CREATE_SET_PREP_TYPES",
  TRANSFER_CREATE_SET_SHIPMENT_VALIDATION_ERROR = "TRANSFER_CREATE_SET_SHIPMENT_VALIDATION_ERROR",
  TRANSFER_CREATE_MUTATE_INVALID_ORDER_ITEM = "TRANSFER_CREATE_MUTATE_INVALID_ORDER_ITEM",
  TRANSFER_CREATE_SET_INVALID_ORDER_ITEMS = "TRANSFER_CREATE_SET_INVALID_ORDER_ITEMS",
  TRANSFER_CREATE_SET_PARTIAL_FILL = "TRANSFER_CREATE_SET_PARTIAL_FILL",
  TRANSFER_CREATE_SET_VALID_SHIP_BY = "TRANSFER_CREATE_SET_VALID_SHIP_BY",
  TRANSFER_CREATE_SET_PO_NUMBER = "TRANSFER_CREATE_SET_PO_NUMBER",
  TRANSFER_CREATE_SET_IS_EACH_PICKING_ORDER = "TRANSFER_CREATE_SET_IS_EACH_PICKING_ORDER",
  TRANSFER_CREATE_SET_CARRIER_ACCOUNTS = "TRANSFER_CREATE_SET_CARRIER_ACCOUNTS",
  TRANSFER_CREATE_ADD_CARRIER_ACCOUNT = "TRANSFER_CREATE_ADD_CARRIER_ACCOUNT",
  TRANSFER_CREATE_UPDATE_CARRIER_ACCOUNT = "TRANSFER_CREATE_UPDATE_CARRIER_ACCOUNT",
  TRANSFER_CREATE_DELETE_CARRIER_ACCOUNT = "TRANSFER_CREATE_DELETE_CARRIER_ACCOUNT",
  TRANSFER_CREATE_SET_SELECTED_CARRIER_ACCOUNT = "TRANSFER_CREATE_SET_SELECTED_CARRIER_ACCOUNT",
  TRANSFER_CREATE_SET_KIT_COMPONENT_ORDER_ITEMS = "TRANSFER_CREATE_SET_KIT_COMPONENT_ORDER_ITEMS",
  TRANSFER_CREATE_REMOVE_KIT_COMPONENT_ORDER_ITEMS = "TRANSFER_CREATE_REMOVE_KIT_COMPONENT_ORDER_ITEMS",
  TRANSFER_CREATE_UPDATE_KIT_COMPONENT_ORDER_ITEM_UNIT = "TRANSFER_CREATE_UPDATE_KIT_COMPONENT_ORDER_ITEM_UNIT",
  TRANSFER_RESET_KIT_COMPONENT_ORDER_ITEM_UNITS = "TRANSFER_RESET_KIT_COMPONENT_ORDER_ITEM_UNITS",
}
