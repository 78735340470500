import { InboundPrepRequestData } from "@deliverr/legacy-inbound-client";
import { PrepJobDetailOption, PrepJobSourceType } from "@deliverr/prep-service-client";
import { prepClient } from "Clients";
import { SPThunkAction } from "common/ReduxUtils";
import {
  setPrepRequest,
  initializeBclPrepContext,
  setPrepJob,
  initializeFloorLoadedContainerDetails,
} from "prep/store";
import { mapPrepJobResponseToPrepRequest } from "prep/util";
import { getLoadedShipmentId } from "inbounds/store/selectors/shipments";

interface LoadPrepByShippingPlanIdParams {
  sellerId: string;
  shippingPlanId: number;
}

/**
 * Load Prep data for the specified seller and Shipping Plan.
 * Note: must be called after loading shipment info.
 */
export const loadPrepByShippingPlanId =
  ({
    sellerId,
    shippingPlanId,
  }: LoadPrepByShippingPlanIdParams): SPThunkAction<Promise<InboundPrepRequestData | undefined>> =>
  async (dispatch, getState) => {
    const loadedShipmentId = getLoadedShipmentId(getState());
    const prepJobResponse = await prepClient.getPrepJobBySellerAndSource(
      sellerId,
      PrepJobSourceType.SHIPPING_PLAN,
      shippingPlanId.toString(),
      [PrepJobDetailOption.FLOOR_LOADED_CONTAINER]
    );

    const prepRequest = prepJobResponse ? mapPrepJobResponseToPrepRequest(prepJobResponse) : undefined;

    // only can initialize BCL Prep when shipment is loaded
    if (loadedShipmentId) {
      dispatch(initializeBclPrepContext(prepRequest));
      dispatch(initializeFloorLoadedContainerDetails(prepJobResponse));
    }
    dispatch(setPrepRequest(prepRequest));
    dispatch(setPrepJob(prepJobResponse));

    return prepRequest;
  };
