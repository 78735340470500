import styled from "@emotion/styled";
import React, { CSSProperties } from "react";
import { Space, DefaultTheme, Direction, Responsive } from "../shared";
import { getVerticalStyles } from "./getVerticalStyles";
import { getHorizontalStyles } from "./getHorizontalStyles";

export interface StackProps extends React.HTMLAttributes<HTMLDivElement> {
  /** What direct the children stack */
  direction?: keyof typeof Direction;
  /** Space between children */
  gap?: Responsive<keyof typeof Space | null>;
  /** Aligns to the center. This cannot be used with the alignItems or justifyContent props. */
  center?: boolean;
  /** inline flex */
  inline?: boolean;
  alignItems?: CSSProperties["alignItems"];
  justifyContent?: "flex-end" | "flex-start" | "center" | "normal" | "space-between" | "space-evenly";
}

export const Stack = styled.div<StackProps, DefaultTheme>(
  ({
    direction = Direction.VERTICAL,
    gap,
    center = false,
    theme,
    inline,
    justifyContent = "normal",
    alignItems = "normal",
  }) => {
    return `
      display: ${inline ? "inline-flex" : "flex"};

      ${
        !center
          ? `
            justify-content: ${justifyContent};
            align-items: ${alignItems};
          `
          : ""
      }

      ${
        direction === Direction.VERTICAL
          ? getVerticalStyles(theme, center, gap)
          : getHorizontalStyles(theme, center, gap)
      }
    `;
  }
);
