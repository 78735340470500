import React from "react";
import { ReplenishmentProductError } from "./ReplenishmentProductError";
import { SelectedProductData } from "common/components/ProductChooser";
import {
  LightDivider,
  ReplenishmentErrorContainer,
  ReplenishmentErrorTitle,
} from "./ReplenishmentOrderCreationErrorStyles";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { ReplenishmentOrderErrorMessages } from "./ReplenishmentErrorMessages";

interface ProductErrorListProps {
  productsWithErrors: {
    product: SelectedProductData;
    productErrorDescription: string;
    errorCode: string;
    rawErrorMessage?: string;
  }[];
  removeProduct: (dsku: string) => void;
}

export const ReplenishmentProductErrorList: React.FC<ProductErrorListProps> = ({
  productsWithErrors,
  removeProduct,
}) => {
  return productsWithErrors.length ? (
    <ReplenishmentErrorContainer>
      <ReplenishmentErrorTitle>
        <FormattedMessage {...ReplenishmentOrderErrorMessages.OrderItemErrorTitle} />
      </ReplenishmentErrorTitle>

      <LightDivider />

      <div>
        {productsWithErrors.map((productWithError) => (
          <ReplenishmentProductError
            key={productWithError.product.dsku}
            productWithError={productWithError}
            removeProduct={removeProduct}
          />
        ))}
      </div>
    </ReplenishmentErrorContainer>
  ) : null;
};
