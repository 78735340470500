import React from "react";
import { Appearance, Box, Text, ThemeProps } from "common/components/ui";
import { components } from "react-select";
import styled from "@emotion/styled";
import { getWarehouseAddress } from "./getWarehouseAddress";
import { StorageWarehouseSellerView } from "storage/inbounds/create/selectDestinationV2/StorageWarehouseSellerView";

const StyledBox = styled(Box)<ThemeProps>(
  ({ theme }) => `
  min-width: 292px;
  height: ${theme.spacing.S8};
  margin-top: ${theme.spacing.S2};
  `
);

export const StorageDestinationSelectedOption = (props) => {
  const { label, hasSameZipCode } = props.data;
  const value = props.data.value as StorageWarehouseSellerView;

  return (
    <components.SingleValue {...props}>
      <StyledBox>
        <Text as="header" bold>
          {label}
        </Text>
        <Text appearance={Appearance.INFO}>
          <span style={{ fontSize: 12 }}>{getWarehouseAddress(value, hasSameZipCode)}</span>
        </Text>
      </StyledBox>
    </components.SingleValue>
  );
};
