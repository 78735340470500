import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { UploadGetPresignedDataRequest } from "./UploadRequests/UploadGetPresignedDataRequest";
import { UploadTarget } from "./UploadRequests/UploadTarget";
import { UploadPresignedPostResponse } from "./UploadResponses/UploadPresignedPostResponse";
import { claimClient } from "common/clients/instances";
import { ClaimProofUploadResponse } from "./UploadResponses/ClaimProofUploadResponse";

export type UploadClientConfig = ApiClientConfig;

export class UploadClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: UploadClientConfig = { baseURL: mustBeDefined(process.env.UPLOAD_SERVICE_URL) }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1/upload`,
    });
  }

  async uploadFile(target: UploadTarget, file: File) {
    const presignedData = await this.getS3PresignedData({
      target,
      contentType: file.type,
      title: file.name,
    });
    await this.sendFormData(presignedData, file);
    return presignedData.fields.Key;
  }

  async uploadProof(file: File, sellerId: string, index?: boolean) {
    const response: ClaimProofUploadResponse = await claimClient.uploadUrl(file.type, file.name, sellerId, index);
    await this.uploadFileToS3(response.presignedUrl, file);
    let key = `claim/${file.name}`;
    if (response.key) {
      key = response.key;
    }
    return key;
  }

  async uploadFileToS3(url: string, file: File) {
    const headers = new Headers();
    return await fetch(url, {
      method: "PUT",
      headers,
      body: file,
      mode: "cors",
    });
  }

  async deleteFile(path: string): Promise<void> {
    return await this.apiClient.delete({
      url: "/delete",
      body: { path },
      authentication: isAuthenticated,
    });
  }

  async createSignedUrl(target: UploadTarget, key: string): Promise<string> {
    return await this.apiClient.post({
      url: `/download/${target}`,
      body: { key },
      authentication: isAuthenticated,
    });
  }

  private async getS3PresignedData(req: UploadGetPresignedDataRequest): Promise<UploadPresignedPostResponse> {
    return await this.apiClient.post({
      url: req.target,
      body: {
        contentType: req.contentType,
        title: req.title,
      },
      authentication: isAuthenticated,
    });
  }

  private async sendFormData(presignedData: UploadPresignedPostResponse, file: File): Promise<void> {
    const formData = new FormData();

    Object.keys(presignedData.fields).forEach((key) => {
      formData.append(key, presignedData.fields[key]);
    });

    // file must be last field
    formData.append("file", file);

    await this.apiClient.post({
      url: presignedData.url,
      body: formData,
    });
  }
}
