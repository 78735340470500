import React, { FC, useContext } from "react";

import { MissingBarcodeCaseContext } from "../../context";
import { SelectBarcode } from "inbounds/non-compliance/accordion-steps";

export const MissingBarcodeSelect: FC = () => {
  const {
    isSelectedProductBarcodesLoading,
    onBarcodeSelect,
    selectedBarcode,
    selectedProductBarcodes,
    onResolve,
    isOnResolveLoading,
    foundProduct,
  } = useContext(MissingBarcodeCaseContext);

  return (
    <SelectBarcode
      loading={isSelectedProductBarcodesLoading}
      onBarcodeSelect={onBarcodeSelect}
      selectedBarcode={selectedBarcode}
      barcodes={selectedProductBarcodes}
      onSubmit={onResolve}
      isOnSubmitLoading={isOnResolveLoading}
      dsku={foundProduct?.dsku}
    />
  );
};
