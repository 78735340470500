import { DeliverrError } from "@deliverr/errors";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { mustBeDefined } from "common/utils/mustBeDefined";
import { reformatScopesForRenderingByPermissions } from "./getUniqueScopesByPermission";
import { CreateApiTokenData } from "./CreateApiTokenData";
import { InstalledApplication } from "common/clients/logisticsApi/InstalledApplication";

export type LogisticsApiClientConfig = ApiClientConfig;

export class LogisticsApiClient {
  private apiClient: ApiClient;

  constructor(logisticsApiClientConfig = { baseURL: mustBeDefined(process.env.LOGISTICS_API_URL) }) {
    this.apiClient = new ApiClient({
      baseURL: `${logisticsApiClientConfig.baseURL}/logistics/api/unstable`,
    });
  }

  async getIsSandboxAccount(): Promise<boolean> {
    try {
      return await this.apiClient.get({
        url: `/accounts/isSandboxAccount`,
        authentication: isAuthenticated,
      });
    } catch (ex) {
      throw new DeliverrError({
        code: ex.response.data.code,
        functionName: "getIsSandboxAccount",
        payload: ex.response.data,
      });
    }
  }

  async getAllInstalledApplications(): Promise<InstalledApplication[]> {
    try {
      const installedApp: InstalledApplication[] = await this.apiClient.get({
        url: `/installed_applications`,
        authentication: isAuthenticated,
      });
      return installedApp.map((item) => ({
        ...item,
        uniqueScopes: reformatScopesForRenderingByPermissions(item.scopes.split(" ")),
      }));
    } catch (ex) {
      switch (ex.response.status) {
        default:
          throw new DeliverrError({
            code: ex.response.data.code,
            functionName: "getAllInstalledApplications",
            payload: ex.response.data,
          });
      }
    }
  }

  async saveInstalledApplication(createData: CreateApiTokenData): Promise<InstalledApplication> {
    try {
      const reformatScope = { displayName: createData.displayName, scopes: createData.scopes.join(" ") };
      return await this.apiClient.post({
        url: `/installed_applications`,
        body: reformatScope,
        authentication: isAuthenticated,
      });
    } catch (ex) {
      switch (ex.response.status) {
        default:
          throw new DeliverrError({
            code: ex.response.data.code,
            functionName: "saveInstalledApplication",
            payload: ex.response.data,
          });
      }
    }
  }

  async updateInstalledApplication(installedApplicationId: string, displayName: string): Promise<InstalledApplication> {
    try {
      return await this.apiClient.put({
        url: `/installed_applications/${installedApplicationId}/update`,
        body: { displayName },
        authentication: isAuthenticated,
      });
    } catch (ex) {
      switch (ex.response.status) {
        default:
          throw new DeliverrError({
            code: ex.response.data.code,
            functionName: "updateInstalledApplication",
            payload: ex.response.data,
          });
      }
    }
  }

  async disableInstalledApplication(installedApplicationId: string): Promise<InstalledApplication> {
    try {
      return await this.apiClient.put({
        url: `/installed_applications/${installedApplicationId}/disable`,
        authentication: isAuthenticated,
      });
    } catch (ex) {
      switch (ex.response.status) {
        default:
          throw new DeliverrError({
            code: ex.response.data.code,
            functionName: "disableInstalledApplication",
            payload: ex.response.data,
          });
      }
    }
  }
}
