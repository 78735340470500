import * as React from "react";
import { icon } from "../../common";

export default icon("0 0 16 16")(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14.25 1.49023H1.75C1.61193 1.49023 1.5 1.60216 1.5 1.74023V11.2402C1.50803 11.3902 1.59422 11.5249 1.727 11.595L7.773 14.3855C7.91887 14.443 8.08113 14.443 8.227 14.3855L14.273 11.595C14.4058 11.5249 14.492 11.3902 14.5 11.2402V1.74023C14.5 1.60216 14.3881 1.49023 14.25 1.49023ZM11.7178 6.17898L9.9085 8.03298L10.3355 10.6482C10.3485 10.7277 10.3043 10.7625 10.237 10.7255L8 9.48873L5.763 10.7252C5.69575 10.7622 5.6515 10.7275 5.6645 10.648L6.068 8.17673L6.091 8.03073L4.28225 6.17873C4.2275 6.12273 4.24425 6.06773 4.319 6.05648L6.8205 5.67523L7.93825 3.29548C7.97225 3.22348 8.02775 3.22348 8.06175 3.29548L9.1805 5.67523L11.6805 6.05798C11.7558 6.06798 11.7725 6.12298 11.7178 6.17898Z"
  />
);
