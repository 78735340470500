import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { Text, defaultTheme } from "common/components/ui";

import {
  NonComplianceCard,
  NonComplianceSection,
  NonComplianceSuccessContainer,
  NonComplianceProductPhotoSection,
  TextWithBottomMargin,
  NonComplianceFooter,
  NonComplianceConfirmationHeader,
  NonComplianceProductSummary,
} from "inbounds/non-compliance/components";
import { ShippingPlanSummary } from "inbounds/components";
import { SimpleLoader } from "common/components/WithLoader/WithLoader";
import { Path } from "paths";
import { useMissingBarcodeSuccess } from "./useMissingBarcodeSuccess";
import { removeAllEmTags } from "common/StringUtils";

export const MissingBarcodeSuccess: FC = () => {
  const {
    missingBarcodeCase,
    isCaseLoading,
    shippingPlan,
    isRecognizedProduct,
    foundProduct,
    shouldRedirect,
    selectedBarcode,
    isMobile,
  } = useMissingBarcodeSuccess();

  // redirects if not coming from a resolution page
  if (shouldRedirect) {
    return <Redirect to={Path.nonCompliance} />;
  }

  if (isCaseLoading || !missingBarcodeCase) {
    return <SimpleLoader />;
  }

  return (
    <NonComplianceCard>
      <NonComplianceSuccessContainer>
        <NonComplianceSection>
          <NonComplianceConfirmationHeader dismissed={!isRecognizedProduct} />
        </NonComplianceSection>
        {shippingPlan && (
          <NonComplianceSection>
            <ShippingPlanSummary shippingPlan={shippingPlan} />
          </NonComplianceSection>
        )}
        <NonComplianceSection>
          <TextWithBottomMargin bold margin={defaultTheme.spacing.S4}>
            <FormattedMessage id="nonCompliance.productPhotosTitle" defaultMessage="Product photos" />
          </TextWithBottomMargin>
          <NonComplianceProductPhotoSection photos={missingBarcodeCase.rawPhotoKeys} isSmall={isMobile} />
        </NonComplianceSection>
        {isRecognizedProduct && foundProduct && (
          <>
            <NonComplianceSection>
              <NonComplianceProductSummary product={foundProduct} />
            </NonComplianceSection>
            {selectedBarcode && (
              <NonComplianceSection>
                <TextWithBottomMargin bold>
                  <FormattedMessage id="barcode" defaultMessage="Barcode" />
                </TextWithBottomMargin>
                <Text>{removeAllEmTags(selectedBarcode)}</Text>
              </NonComplianceSection>
            )}
          </>
        )}
      </NonComplianceSuccessContainer>
      <NonComplianceFooter />
    </NonComplianceCard>
  );
};
