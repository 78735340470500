import { CreateTransferLoader } from "../CreateTransferLoader.types";
import { sharedLoadWholesaleOrderAction } from "common/wholesale-common/sharedLoadWholesaleOrder";
import { transferCreateSetError, transferCreateSetWholesaleOrder } from "./TransferCreateActions";
import { selectTransferCreate } from "../store/TransferCreateSelectors";

export const loadWholesaleOrderAction = sharedLoadWholesaleOrderAction({
  loaderId: CreateTransferLoader.CreateTransferOrderLoader,
  setError: transferCreateSetError,
  setValue: transferCreateSetWholesaleOrder,
  selector: selectTransferCreate,
});
