import { InboundPackageData, InboundShipment, InboundShipmentItem } from "@deliverr/legacy-inbound-client";
import { Dictionary } from "lodash";
import { flatMap as uncappedFlatMap, flowRight, groupBy, isNil, isUndefined, mapValues, sumBy } from "lodash/fp";
import { BoxSize, DraftShippingPlan, DraftShippingPlanItem, PlannedShipment } from "inbounds/InboundTypes";
import log from "Logger";
import { composeGetIsOverDimensions } from "common/utils/product/composeGetIsOverDimensions";
import {
  INBOUND_SPD_MAX_BOX_LONGEST,
  INBOUND_SPD_MAX_BOX_MIDDLE,
  INBOUND_SPD_MAX_BOX_SHORTEST,
  INBOUND_SPD_MAX_BOX_WEIGHT,
  INBOUND_SPD_MIN_BOX_DIM,
} from "./constants/spdConstants";
import { getIsSpdDeliverr } from "inbounds/utils/shippingMethodUtils";
import { PackageDetails } from "inbounds/types/PackageDetails";
import { INBOUND_PKG_MAX_SKUS_PER_BOX } from "inbounds/constants/packageConstants";

const flatMap = (uncappedFlatMap as any).convert({ cap: false });

export const MAX_BOX_COUNT = 2000;
export const MIN_NATIONAL_QTY_PER_SKU = 5;
export const MIN_NATIONAL_CASE_PER_SKU = 5;
export const MIN_NATIONAL_QTY_PER_CASE = 1;
export const MAX_SKU_QTY = 4000;
export const INBOUND_MAX_QTY_PER_SKU = 21000;

interface ValidationOptions {
  hasBlindBoxContentsLabelsPrep: boolean;
}

/**
 * Returns whether the box quantities, weights, and dimensions are valid
 * Used to determine whether to proceed when Confirm button is clicked
 */
export const validateBoxes = (
  plannedShipment: PlannedShipment,
  { items: shipmentItems }: InboundShipment,
  { hasBlindBoxContentsLabelsPrep }: ValidationOptions
): boolean => {
  const ctx = { fn: "validateBoxes" };
  log.info({ ...ctx, plannedShipment, shipmentItems, hasBlindBoxContentsLabelsPrep }, "validating shipment packages");

  const { identicalPackageCounts, packageCount, shippingMethod } = plannedShipment;

  // Only validate that shipping method is present when BCL Prep is requested
  if (hasBlindBoxContentsLabelsPrep) {
    log.info({ ...ctx, shippingMethod }, "BCL prep requested; skipping box validations except for shipping method");
    return !!shippingMethod;
  }

  const packages = plannedShipment.packages.slice(0, packageCount);
  const isSpdDeliverr = getIsSpdDeliverr(shippingMethod);
  const invalidConditions = [
    [!shippingMethod, "shipment shipping method not set"],
    [!boxTotalsMatchSkuTotals(packages, identicalPackageCounts, shipmentItems), `box totals don't match sku totals`],
    [packages.find(packageHasZeroUnits), "contains packages with no units"],
    [packages.find(tooManySkusInPackage), "contains packages with too many skus"],
    [isSpdDeliverr && boxSizesInvalid(plannedShipment), "box sizes are invalid"],
    [isSpdDeliverr && boxWeightsInvalid(packages), "one or more of boxes fall outside our weight limits."],
  ].filter(([isInvalid, _]) => isInvalid);

  invalidConditions.forEach(([_, errorMsg]) => {
    log.info(ctx, errorMsg);
  });

  return invalidConditions.length === 0;
};

// All dimensions must be >= 1 in.
export const boxSizesInvalid = ({ boxSizes, packageCount, selectedBoxSizes }: PlannedShipment): boolean =>
  selectedBoxSizes
    .slice(0, packageCount)
    .some(
      (selectedBoxSize) => !dimensionsAreValid(boxSizes[selectedBoxSize]) || isMaxDimensions(boxSizes[selectedBoxSize])
    );

// All box weights must fall within weight limits
export const boxWeightsInvalid = (packages: InboundPackageData[]): boolean =>
  packages.some(({ weight }) => {
    // skip check if non deliverr rates (no weight provided)
    if (weight === undefined) {
      return false;
    }
    const isPackageBelowMinWeight = weight < INBOUND_SPD_MIN_BOX_DIM;
    const isPackageAboveMaxWeight = weight > INBOUND_SPD_MAX_BOX_WEIGHT;
    return isPackageBelowMinWeight || isPackageAboveMaxWeight;
  });

export const dimensionsAreValid = (size: BoxSize): boolean =>
  !["width", "length", "height"].find((side) => isNil(size[side]) || isNaN(size[side]) || size[side] < 1);

export const isMaxDimensions = composeGetIsOverDimensions({
  maxShortestSide: INBOUND_SPD_MAX_BOX_SHORTEST,
  maxMiddleSide: INBOUND_SPD_MAX_BOX_MIDDLE,
  maxLongestSide: INBOUND_SPD_MAX_BOX_LONGEST,
});

export const tooManySkusInPackage = (pkg: InboundPackageData): boolean =>
  pkg.items.filter((pkgItem) => pkgItem.qty && pkgItem.qty > 0).length > INBOUND_PKG_MAX_SKUS_PER_BOX;

export const packageHasZeroUnits = ({ items }: InboundPackageData): boolean => sumBy("qty", items) === 0;

/** Returns whether a shipment is fully valid and ready for completion */
export const validateShipmentAndBoxes = (
  plannedShipment: PlannedShipment,
  shipment: InboundShipment,
  { hasBlindBoxContentsLabelsPrep }: ValidationOptions
): boolean =>
  !isUndefined(plannedShipment.shippingMethod) &&
  !isUndefined(plannedShipment.boxesConfirmed) &&
  validateBoxes(plannedShipment, shipment, { hasBlindBoxContentsLabelsPrep });

export const boxTotalsMatchSkuTotals = (
  packages: ReadonlyArray<InboundPackageData>,
  identicalPackageCounts: ReadonlyArray<number>,
  shipmentItems: InboundShipmentItem[]
): boolean =>
  flowRight<
    ReadonlyArray<InboundPackageData>,
    boolean,
    Dictionary<number>,
    Dictionary<Array<[string, number]>>,
    Array<[string, number]>
  >(
    (boxTotals) => shipmentItems.every(({ dsku, qty }) => qty === boxTotals[dsku]),
    mapValues<Array<[string, number]>, number>(sumBy(([_, qty]) => qty)),
    groupBy(([dsku, _]) => dsku),
    flatMap(({ items }, pkgIndex) =>
      items.map(({ dsku, qty }) => [dsku, qty * (identicalPackageCounts[pkgIndex] || 1)])
    )
  )(packages);

export const validateProductQties = (plan: DraftShippingPlan, planItems: DraftShippingPlanItem[]): boolean => {
  if (planItems.length === 0) {
    return false;
  }

  // promotional inbounds should be able to proceed with some 0 quantity items
  if (plan.isPromotional) {
    // make sure that they arent proceeding without any units (all 0 qty)
    const totalQuantity = planItems.reduce((sum, current) => sum + current.qty, 0);
    return totalQuantity > 0;
  }

  if (plan.useCasePack) {
    return !planItems.some((item) => item.caseQty === 0 || item.numberOfCases === 0);
  } else {
    return !planItems.some((item) => item.qty === 0);
  }
};

export const getHasPackagesWithMissingWeight = (packageDetailsList: PackageDetails[]): boolean =>
  packageDetailsList.some(({ weight }) => weight === 0);

const getSelectedBoxSize = (
  boxSizes: BoxSize[],
  selectedBoxSizes: readonly number[],
  packageIndex: number
): BoxSize | undefined => {
  const boxSizeIndex = selectedBoxSizes[packageIndex];
  return boxSizeIndex !== undefined ? boxSizes[boxSizeIndex] : undefined;
};

export const getHasPackagesWithMissingDimensions = (
  packageDetailsList: PackageDetails[],
  boxSizes: BoxSize[],
  selectedBoxSizes: readonly number[]
): boolean =>
  packageDetailsList.some(({ packageIndex }) => {
    const packageBoxSize = getSelectedBoxSize(boxSizes, selectedBoxSizes, packageIndex);
    return packageBoxSize !== undefined && Object.values(packageBoxSize).some((dim) => dim === 0);
  });

export const getHasPackagesWithOverMaxDimensions = (
  packageDetailsList: PackageDetails[],
  boxSizes: BoxSize[],
  selectedBoxSizes: readonly number[]
): boolean =>
  packageDetailsList.some(({ packageIndex }) => {
    const packageBoxSize = getSelectedBoxSize(boxSizes, selectedBoxSizes, packageIndex);
    return packageBoxSize !== undefined && isMaxDimensions(packageBoxSize);
  });
