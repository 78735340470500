import { Space, DefaultTheme, Responsive, getResponsiveStyles } from "../shared";
import { getNegativeMargin } from "./getNegativeMargin";
import { getPositiveMargin } from "./getPositiveMargin";

export const getVerticalStyles = (
  theme: DefaultTheme,
  center: boolean,
  gap?: Responsive<keyof typeof Space | null>
) => `
  flex-direction: column;
  ${center ? `align-items: center;` : ``}
  ${getResponsiveStyles("margin-top", gap, getNegativeMargin(theme))}
  ${getResponsiveStyles("margin-bottom", gap, getNegativeMargin(theme))}
  > * {
    ${getResponsiveStyles("margin-top", gap, getPositiveMargin(theme))}
    ${getResponsiveStyles("margin-bottom", gap, getPositiveMargin(theme))}
  }
`;
