import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";

import { Container } from "common/components/Container";

import { nonComplianceInternalRoutes as routes } from "./NonComplianceRoutes";
import { NonComplianceSpvList } from "./list/NonComplianceSpvList";
import { UnknownBarcodeRoutes } from "./flows/unknown-barcode";
import { MissingBarcodeRoutes } from "./flows/missing-barcode";

export const NonCompliance: FC = () => {
  return (
    <Container>
      <Switch>
        <Route
          key="NonCompliance.UnknownBarcode"
          path={routes.unknownBarcode}
          render={() => <UnknownBarcodeRoutes />}
        />

        <Route
          key="NonCompliance.MissingBarcode"
          path={routes.missingBarcode}
          render={() => <MissingBarcodeRoutes />}
        />

        <Route key="NonCompliance.Summary" path={routes.viewCompletedCase} render={() => <NonComplianceSpvList />} />

        <Route key="NonCompliance.Summary" path={routes.summary} render={() => <NonComplianceSpvList />} />
      </Switch>
    </Container>
  );
};

export default NonCompliance;
