import { DateOffset } from "common/date/DateOffset";

export const BASE_SHIPPING_DAY_OFFSET: DateOffset = {
  weekdays: 3,
};

export enum DistributeSelectShippingOptions {
  DIRECT = "DIRECT",
  FORWARDING = "FORWARDING",
}

export const DistributeSelectShippingDays: Record<DistributeSelectShippingOptions, string> = {
  [DistributeSelectShippingOptions.DIRECT]: "1-3",
  [DistributeSelectShippingOptions.FORWARDING]: "3-11",
};
