import { Select } from "common/components/ui";
import cx from "classnames";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { LoginStatus } from "tags/facebook/model/Auth";
import { FastTagsNav } from "tags/base/FastTagsNav";
import { Path } from "paths";
import sharedCls from "../../../common/facebook.less";
import cls from "./AdsSettings.less";
import { useAdsSettings } from "./useAdsSettings";

export const AdsSettings: FC = () => {
  const {
    adAccountOptions,
    loginStatus,
    onAdAccountSelected,
    onNextClick,
    selectedAdAccount,
    isSettingsComplete,
  } = useAdsSettings();

  switch (loginStatus) {
    case LoginStatus.LoggedOut:
      return <Redirect to={Path.facebookConnectAccount} />;
    case LoginStatus.LoggedIn:
      return (
        <div className={sharedCls.container}>
          <div className={cx(sharedCls.inner, sharedCls.boundedWidth, cls.inner)}>
            <h4 className={cx(sharedCls.title, sharedCls.fullTitle)}>
              <FormattedMessage id="FacebookFastTag.AdsSettings.Title" defaultMessage="Facebook Ads Settings" />
            </h4>
            <p className={cx(sharedCls.description, sharedCls.boundedWidth)}>
              <FormattedMessage
                id="FacebookFastTag.AdsSettings.Description"
                defaultMessage="Select the ad account you want to connect to Flexport."
              />
            </p>

            <div className={cx(cls.adsSettings, sharedCls.boundedWidth)}>
              <div>
                <Select
                  label={
                    <FormattedMessage
                      id="FacebookFastTag.AdsSettings.AdAccountSelectDescription"
                      defaultMessage="Select Ad Account"
                    />
                  }
                  clearable={false}
                  isDisabled={adAccountOptions === null || adAccountOptions?.length === 0}
                  placeholder={
                    adAccountOptions?.length === 0 ? (
                      <FormattedMessage
                        id="FacebookFastTag.AdSettings.NoAdAccountsFoundPlaceholder"
                        defaultMessage="No ad accounts found for this user."
                      />
                    ) : (
                      <FormattedMessage
                        id="FacebookFastTag.AdsSettings.AdAccountSelectPlaceholder"
                        defaultMessage="Select"
                      />
                    )
                  }
                  value={selectedAdAccount}
                  options={adAccountOptions ?? []}
                  onChange={onAdAccountSelected}
                />
              </div>
            </div>
          </div>

          <FastTagsNav
            className={sharedCls.nav}
            onNext={onNextClick}
            nextPage={Path.facebookSavedAudienceWarning}
            nextDisabled={!isSettingsComplete}
            previousPage={Path.facebookConnectAccount}
            nextButtonText={<FormattedMessage id="next" defaultMessage={"Next"} />}
            previousButtonText={<FormattedMessage id="back" defaultMessage={"Back"} />}
            nextButtonClassName={sharedCls.actionButton}
            previousButtonClassName={sharedCls.actionButton}
          />
        </div>
      );
    default:
      return null;
  }
};
