import React, { FC, useState } from "react";
import { TextContainer, TitleText, SubtitleText, SearchListItemRow } from "./GlobalSearchStyledComponents";
import { generateStatusPill, getSubtitleText, getTitleText, GlobalSearchResults, ServiceNames } from "../utils";
import { useSearchResultItem } from "../hooks";
import { LoadingSpinner } from "common/components/LoadingSpinner";

interface SearchResultItemProps {
  serviceName: ServiceNames;
  result: GlobalSearchResults[ServiceNames];
}

export const SearchResultItem: FC<SearchResultItemProps> = ({ serviceName, result }) => {
  const { handleSearchResultItemClick } = useSearchResultItem(serviceName, result);
  const [isLoading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      await handleSearchResultItemClick();
    } finally {
      setLoading(false);
    }
  };

  return (
    <SearchListItemRow onClick={handleClick}>
      {isLoading ? (
        <LoadingSpinner size="4x" />
      ) : (
        <>
          <TextContainer>
            <TitleText as="p" html={getTitleText(serviceName, result)} />
            <SubtitleText>{getSubtitleText(serviceName, result)}</SubtitleText>
          </TextContainer>
          {generateStatusPill(serviceName, result)}
        </>
      )}
    </SearchListItemRow>
  );
};
