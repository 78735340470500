import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import produce from "immer";
import { PrepListActionTypes, PrepSearchResult } from "prep/types";

export interface PrepListState {
  currentSearchTerm: string;
  currentPageItems: PrepSearchResult[];
  searchInfo?: {
    totalHits: number;
    currentPage: number;
    itemsPerPage: number;
    totalPages: number;
  };
}

export const prepListInitialState: PrepListState = {
  currentSearchTerm: "",
  currentPageItems: [],
};

export const reducers: ReducerDictionary<PrepListState> = {
  ...handleSimpleReducerUpdates([
    PrepListActionTypes.SEARCH_PREP_SUCCESS,
    PrepListActionTypes.SEARCH_PREP_ERROR,
    PrepListActionTypes.SET_CURRENT_PREP_SEARCH_TERM,
  ]),
  [PrepListActionTypes.SET_CURRENT_PREP_PAGE]: (state, { page }) =>
    produce(state, (draft) => {
      if (draft.searchInfo) {
        draft.searchInfo.currentPage = page;
      }
    }),
};

export const prepListReducer = createReducer<PrepListState>(prepListInitialState, reducers);
