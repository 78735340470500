import { ReceivingInfo, SellerProblemsDetailView } from "@deliverr/legacy-inbound-client";
import { RateCard, StorageInboundResponse } from "@deliverr/storage-client";
import { Packs } from "common/clients/storage/Packs/Packs";

export enum StorageInboundDetailActionTypes {
  STORAGE_GET_DETAILS = "STORAGE_GET_DETAILS",
  STORAGE_GET_DETAILS_SUCCESS = "STORAGE_GET_DETAILS_SUCCESS",
  STORAGE_GET_DETAILS_ERROR = "STORAGE_GET_DETAILS_ERROR",
  RESET_STORAGE_DETAILS = "RESET_STORAGE_DETAILS",
  STORAGE_GET_ITEM_DETAILS_SUCCESS = "STORAGE_GET_ITEM_DETAILS_SUCCESS",
  STORAGE_GET_ITEM_DETAILS_ERROR = "STORAGE_GET_ITEM_DETAILS_ERROR",
  STORAGE_GET_RATES_SUCCESS = "STORAGE_GET_RATES_SUCCESS",
  STORAGE_GET_RATES_ERROR = "STORAGE_GET_RATES_ERROR",
  STORAGE_UPDATE_ITEM_DETAILS_SUCCESS = "STORAGE_UPDATE_ITEM_DETAILS_SUCCESS",
  STORAGE_UPDATE_ITEM_DETAILS_ERROR = "STORAGE_UPDATE_ITEM_DETAILS_ERROR",
  STORAGE_FETCH_RECEIVE_INFO = "STORAGE_FETCH_RECEIVE_INFO",
  STORAGE_GET_PROBLEMS = "STORAGE_GET_PROBLEMS",
}

export interface StorageInboundDetailState {
  storageItemDetails: Packs;
  storageRates?: { [warehouseId: string]: RateCard };
  storageInboundOrder: StorageInboundResponse;
  isLoading: boolean;
  receivingInfo?: ReceivingInfo;
  storageInboundOrderProblems: SellerProblemsDetailView[];
}
