import { DeliverrError } from "@deliverr/errors";
import { toast } from "common/components/ui";
import { useFulfillmentInboundNavigation } from "inbounds/navigation/fulfillment/useFulfillmentInboundNavigation";
import { PREP_ERROR_CODE_LABELS, UNEXPECTED_ERROR_LABEL } from "prep/labels";
import { selectHasPrepV2, updateSimplePrepV2 } from "prep/store";
import { SimpleSellerSelfServePrepType } from "prep/types";
import { InboundFooter } from "inbounds/steps/InboundFooter";
import React, { FC } from "react";
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

interface UnconnectedSimplePrepPageFooterProps {
  handleNextAndRequestPrep: () => Promise<void>;
  handleSkipPrep: () => Promise<void>;
  goToPreviousStep: () => void;
  isSubmitDisabled?: boolean;
  isSkipDisabled?: boolean;
}

export const UnconnectedSimplePrepPageFooter: FC<UnconnectedSimplePrepPageFooterProps> = ({
  handleNextAndRequestPrep,
  handleSkipPrep,
  goToPreviousStep,
  isSubmitDisabled,
  isSkipDisabled,
}) => {
  return (
    <InboundFooter
      onButtonClick={handleNextAndRequestPrep}
      onPreviousButtonClick={goToPreviousStep}
      disabled={isSubmitDisabled}
      manualLoader
      additionalButtons={[
        {
          label: (
            <FormattedMessage id="inbounds.prep.simplePrepPageFooter.skip" defaultMessage="Skip, I don't need Prep" />
          ),
          onClick: handleSkipPrep,
          disabled: isSkipDisabled,
          id: "inbounds-skip-prep-button",
        },
      ]}
    />
  );
};

interface SimplePrepPageFooterProps {
  selectedPrepTypes: SimpleSellerSelfServePrepType[];
}

export const SimplePrepPageFooter: FC<SimplePrepPageFooterProps> = ({ selectedPrepTypes }) => {
  const { goToNextStep, goToPreviousStep } = useFulfillmentInboundNavigation();
  const hasPrep = useSelector(selectHasPrepV2);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const submitSimplePrepAndAdvance = async (prepTypes?: SimpleSellerSelfServePrepType[]) => {
    try {
      await dispatch(updateSimplePrepV2(prepTypes));
      await goToNextStep();
    } catch (err) {
      const errMessageDescriptor: MessageDescriptor =
        err instanceof DeliverrError ? PREP_ERROR_CODE_LABELS[err.code] : UNEXPECTED_ERROR_LABEL;
      toast.error(formatMessage(errMessageDescriptor), { toastId: "inbounds.prep.simplePrepPage.submitError" });
    }
  };

  const handleNextAndRequestPrep = async () => {
    await submitSimplePrepAndAdvance(selectedPrepTypes);
  };

  const handleSkipPrep = async () => {
    if (!hasPrep) {
      await goToNextStep();
      return;
    }

    // if there's already a prep request, remove all SimplePrepTypes
    await submitSimplePrepAndAdvance();
  };

  const isSubmitDisabled = selectedPrepTypes.length === 0;
  const isSkipDisabled = selectedPrepTypes.length > 0;

  return (
    <UnconnectedSimplePrepPageFooter
      handleNextAndRequestPrep={handleNextAndRequestPrep}
      handleSkipPrep={handleSkipPrep}
      goToPreviousStep={goToPreviousStep}
      isSubmitDisabled={isSubmitDisabled}
      isSkipDisabled={isSkipDisabled}
    />
  );
};
