import { defineMessages, MessageDescriptor } from "react-intl";

export enum ShippingPlanItemsError {
  PRODUCT_OVER_WEIGHT_LIMIT = "PRODUCT_OVER_WEIGHT_LIMIT",
  PRODUCT_OVER_DIMENSIONS_LIMIT = "PRODUCT_OVER_DIMENSIONS_LIMIT",
  UNKNOWN = "UNKNOWN",
}

export const SHIPPING_PLAN_ITEMS_ERROR_MESSAGES: Record<ShippingPlanItemsError, MessageDescriptor> = defineMessages({
  [ShippingPlanItemsError.PRODUCT_OVER_WEIGHT_LIMIT]: {
    id: "inbounds.updateShippingPlanItemsError.overWeight",
    defaultMessage: "{msku} is over the allowable weight {weight} lbs",
  },
  [ShippingPlanItemsError.PRODUCT_OVER_DIMENSIONS_LIMIT]: {
    id: "inbounds.updateShippingPlanItemsError.overDimensions",
    defaultMessage: `{msku} is over the allowable dimensions {length}"x{width}"x{height}"`,
  },
  [ShippingPlanItemsError.UNKNOWN]: {
    id: "inbounds.updateShippingPlanItemsError.unknown",
    defaultMessage: "There was an error setting products on the Shipping Plan. Please try again later.",
  },
});
