import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Stack, Title } from "common/components/ui";
import ShareLabel from "returns/share/ShareLabel";
import { useShareReturnLabel } from "./useShareReturnLabel";

export const ShareReturnLabelButton: FC = () => {
  const { title, description, showShareLabelModal, setShowShareLabelModal, shareLabelSuccess } = useShareReturnLabel();
  return (
    <>
      <Button data-testid="share-return-label-button" secondary onClick={() => setShowShareLabelModal(true)}>
        <FormattedMessage id={"returns.returnsDetail.shareReturnLabelButton"} defaultMessage={"Share return label"} />
      </Button>
      <Modal show={showShareLabelModal} onClose={() => setShowShareLabelModal(false)}>
        <Stack center gap="S5">
          <Title as="h3" displayAs="h4">
            {title}
          </Title>
          <p>{description}</p>
          <ShareLabel shareLabelSuccess={shareLabelSuccess} />
        </Stack>
      </Modal>
    </>
  );
};
