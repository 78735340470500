import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/dedupe";
import ClipboardJS from "clipboard";
import React, { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "common/components/ui";

import { useEffectOnce } from "react-use";

import cls from "./CopyToClipboard.less";

export interface CopyToClipboardProps {
  textToCopy: string;
  className?: string;
  disabled?: boolean;
}

export const CopyToClipboard: FC<CopyToClipboardProps> = ({ disabled, textToCopy, className }) => {
  const [clipboard, setClipboard] = useState<ClipboardJS | undefined>(undefined);
  const copyClass = "copyToClipboard";

  useEffectOnce(() => {
    const cb = new ClipboardJS(`.${copyClass}`);
    cb.on("success", () =>
      toast.info("Code snippet copied to clipboard", {
        autoClose: 5000,
        toastId: "codeSnippetCopiedToClipboardSuccess",
      })
    );
    setClipboard(cb);

    return () => clipboard?.destroy();
  });

  return clipboard === undefined ? null : (
    <button
      className={classNames("btn", copyClass, cls.copyButton, className)}
      data-clipboard-text={textToCopy}
      disabled={disabled}
    >
      <FontAwesomeIcon icon="copy" className={cls.copyIcon} />
      <FormattedMessage id="copyToClipboard" defaultMessage={"Copy to Clipboard"} />
    </button>
  );
};
