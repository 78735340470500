import React, { FC } from "react";
import { ModalFormButtonsContainer, ModalHeading } from "./ModalStyles";
import { FormattedMessage, useIntl } from "react-intl";
import { wholesaleCreateLabels } from "wholesale/create/wholesaleCreate.labels";
import { CarrierAccount } from "@deliverr/replenishment-client";
import { Button } from "common/components/ui";
import { Text } from "common/components/ui/Text";
import HtmlToReact from "common/components/HtmlToReact";

export interface DeleteCarrierAccountModalContentProps {
  carrierAccount?: CarrierAccount;
  isLoading: boolean;
  onClose: () => void;
  handleDelete: () => Promise<void>;
}

export const DeleteCarrierAccountModalContent: FC<DeleteCarrierAccountModalContentProps> = ({
  carrierAccount,
  isLoading,
  onClose,
  handleDelete,
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <ModalHeading>
        <FormattedMessage {...wholesaleCreateLabels.steps.shipping.labelProvider.deleteCarrierAccount.title} />
      </ModalHeading>
      <Text>
        <HtmlToReact
          as="span"
          html={formatMessage(
            {
              ...wholesaleCreateLabels.steps.shipping.labelProvider.deleteCarrierAccount.description,
            },
            { displayName: `<strong>${carrierAccount?.displayName}</strong>` }
          )}
        />
      </Text>
      <ModalFormButtonsContainer>
        <Button block onClick={onClose} secondary>
          <FormattedMessage {...wholesaleCreateLabels.steps.shipping.labelProvider.deleteCarrierAccount.cancel} />
        </Button>
        <Button block onClick={handleDelete} loading={isLoading} appearance="DANGER">
          <FormattedMessage {...wholesaleCreateLabels.steps.shipping.labelProvider.deleteCarrierAccount.delete} />
        </Button>
      </ModalFormButtonsContainer>
    </>
  );
};
