import {
  Channel,
  DeliverrAddress,
  ErrorCode,
  ExternalDestinationType,
  WholesaleOrderDTO,
  WholesaleOrderStatus,
  WholesaleShipmentDTO,
  WholesaleShipmentStatus,
  WholesaleShipmentType,
  WholesaleShippingMethod,
} from "@deliverr/wholesale-client";
import { isFbaIntegration } from "../../../transfers/utils/fbaIntegration";
import { mapWholesaleOrderStatusUiConfigs } from "./mapWholesaleOrderStatusUiConfigs";
import { mapWholesaleShipmentStatusUiConfigs } from "./mapWholesaleShipmentStatusUiConfigs";
import { extractFbaShipmentId } from "../../../transfers/utils/extractFbaShipmentId";
import { isEmpty } from "lodash";
import { AddressInputRequiredField } from "common/components/AddressInput";
import { addressRequiredFieldsValidation, isInternationalAddress } from "common/AddressUtils";
import { isValidFreightPhoneNumber } from "common/forms/PhoneUtils";
import { WholesaleOrderItem } from "wholesale/list/store";

export const shouldShowShipmentInstructions = (shipment: WholesaleShipmentDTO, wholesaleOrder: WholesaleOrderDTO) => {
  return (
    !isFbaIntegration(wholesaleOrder) &&
    shipment.carrierInfo?.shippingMethod === WholesaleShippingMethod.SELLER &&
    shipment.shipmentType === WholesaleShipmentType.PARTIAL_PALLET &&
    (
      [WholesaleShipmentStatus.SUBMITTED, WholesaleShipmentStatus.PICKING_STARTED] as WholesaleShipmentStatus[]
    ).includes(shipment.status)
  );
};

export const shouldShowBookFreight = (shipment: WholesaleShipmentDTO, wholesaleOrder: WholesaleOrderDTO) => {
  return (
    !isFbaIntegration(wholesaleOrder) &&
    shipment.carrierInfo?.shippingMethod === WholesaleShippingMethod.SELLER &&
    shipment.shipmentType === WholesaleShipmentType.PARTIAL_PALLET &&
    shipment.status === WholesaleShipmentStatus.READY_TO_SHIP
  );
};

export function getIsDeliverrFreight(
  wholesaleOrder?: WholesaleOrderDTO,
  wholesaleShipment?: WholesaleShipmentDTO
): boolean {
  if (!wholesaleOrder || (!wholesaleShipment && isEmpty(wholesaleOrder.shipments))) {
    return false;
  }
  const shipment = wholesaleShipment ?? wholesaleOrder.shipments![0]; // shipment type and method will be equal for all shipments on the order
  return (
    shipment?.carrierInfo?.shippingMethod === WholesaleShippingMethod.DELIVERR &&
    shipment?.shipmentType === WholesaleShipmentType.PARTIAL_PALLET
  );
}

export function getIsAmazonFreight(wholesaleOrder?: WholesaleOrderDTO): boolean {
  return !!(
    wholesaleOrder?.shipments?.some((shipment) => shipment.carrierInfo?.shippingMethod === "SELLER") &&
    isFbaIntegration(wholesaleOrder)
  );
}

export function getIsShippingDeliverr(
  wholesaleOrder?: WholesaleOrderDTO,
  wholesaleShipment?: WholesaleShipmentDTO
): boolean {
  if (!wholesaleOrder || (!wholesaleShipment && isEmpty(wholesaleOrder.shipments))) {
    return false;
  }
  const shipment = wholesaleShipment ?? wholesaleOrder.shipments![0]; // shipment type and method will be equal for all shipments on the order
  return shipment?.carrierInfo?.shippingMethod === WholesaleShippingMethod.DELIVERR;
}

export function getShipmentType(
  wholesaleOrder?: WholesaleOrderDTO,
  wholesaleShipment?: WholesaleShipmentDTO
): WholesaleShipmentType | undefined {
  if (!wholesaleOrder || (!wholesaleShipment && isEmpty(wholesaleOrder.shipments))) {
    return undefined;
  }
  const shipment = wholesaleShipment ?? wholesaleOrder.shipments![0]; // shipment type and method will be equal for all shipments on the order
  return shipment?.shipmentType ?? undefined;
}

export const shouldShowAmazonReferenceId = (
  wholesaleOrder: WholesaleOrderDTO,
  wholesaleShipment?: WholesaleShipmentDTO
) => {
  return (
    isFbaIntegration(wholesaleOrder) &&
    wholesaleOrder.status !== WholesaleOrderStatus.DRAFT &&
    wholesaleOrder.status !== WholesaleOrderStatus.CANCELLED &&
    getIsDeliverrFreight(wholesaleOrder, wholesaleShipment)
  );
};

export function checkIsCancellable(wholesaleOrder: WholesaleOrderDTO) {
  return !!(
    wholesaleOrder.status &&
    mapWholesaleOrderStatusUiConfigs[wholesaleOrder.status]?.isCancellable &&
    wholesaleOrder.shipments?.find(
      (shipment) => shipment.status && mapWholesaleShipmentStatusUiConfigs[shipment.status]?.isCancellable
    ) &&
    wholesaleOrder.externalDestinationType !== ExternalDestinationType.TRANSFER_ECOM
  );
}

export function extractShipmentExternalId(
  wholesaleShipment: WholesaleShipmentDTO,
  wholesaleOrder: WholesaleOrderDTO
): string | undefined {
  return isFbaIntegration(wholesaleOrder) // for fba integration, we ignore the order external id and use the shipment's id if exists
    ? extractFbaShipmentId(wholesaleShipment)
    : wholesaleOrder.externalId;
}

export const isAddressIncomplete = (address: DeliverrAddress): boolean => {
  const requiredFields: AddressInputRequiredField[] = isInternationalAddress(address)
    ? ["company", "street1", "city", "country"]
    : ["company", "street1", "city", "state", "country", "zip"];
  return (
    addressRequiredFieldsValidation(address, requiredFields).filter((field) => field !== "name").length > 0 ||
    !safeIsValidFreightPhoneNumber(address.phone)
  );
};

export const safeIsValidFreightPhoneNumber = (phoneNumber?: string) => {
  try {
    return isValidFreightPhoneNumber(phoneNumber ?? "");
  } catch (e) {
    return false;
  }
};

export function isDraftSPSOrderWithOOSError(wholesaleOrder: WholesaleOrderDTO | WholesaleOrderItem) {
  return !!(
    wholesaleOrder.status &&
    mapWholesaleOrderStatusUiConfigs[wholesaleOrder.status]?.allowSubmit &&
    wholesaleOrder.shipments?.find((shipment) => shipment.status === WholesaleShipmentStatus.DRAFT) &&
    wholesaleOrder.channel &&
    SPS_CHANNELS.includes(wholesaleOrder.channel) &&
    wholesaleOrder.errors?.includes(ErrorCode.OUT_OF_STOCK)
  );
}

export const SPS_CHANNELS: ReadonlyArray<Channel> = Object.freeze([Channel.SPS, Channel.SPS_TEST]);

export const isValidCompany = (company: string | undefined): boolean => {
  return !!company && company.length >= 3;
};
