import styled from "@emotion/styled";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Text, ThemeProps } from "common/components/ui";
import { Product } from "@deliverr/commons-clients";
import { isEmpty } from "lodash";
import { useInfoTooltip } from "dashboard/common/InfoTooltip/useInfoTooltip";
import { Tooltip, TooltipSkin, TooltipWrapper } from "common/components/Tooltip";

const StyledDiv = styled.div<ThemeProps>(
  ({ theme }) => `
  width: fit-content;
  padding: 0 ${theme.spacing.S1};
`
);

const DisplayContainer = styled.span<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  color: ${theme.colors.ORANGE[400]};
`
);

export const AdminProductAndCasePackDskuDisplay: FC<{
  product: Product;
}> = ({ product }) => {
  return (
    <DisplayContainer>
      {!isEmpty(product.packOf) && product.dsku !== product.packOf ? (
        <FormattedMessage
          defaultMessage="{productCasePackDsku} {casePackTooltip} -> {productDsku} {productTooltip}"
          id="storage.admin.productWithPack.display"
          values={{
            productCasePackDsku: product.dsku,
            productDsku: product.packOf,
            casePackTooltip: <CasePackDskuTooltip />,
            productTooltip: <ProductDskuTooltip />,
          }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="{productDsku} {productTooltip}"
          id="storage.admin.productOnly.display"
          values={{
            productDsku: product.dsku,
            productTooltip: <ProductDskuTooltip />,
          }}
        />
      )}
    </DisplayContainer>
  );
};

const CasePackDskuTooltip: FC = () => {
  const { tooltipActive, onMouseOut, onMouseOver } = useInfoTooltip();

  return (
    <StyledDiv onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      {"(C)"}
      <TooltipWrapper>
        <Tooltip skin={TooltipSkin.PRIMARY} active={tooltipActive}>
          <Box width={"9rem"}>
            <Text>
              <FormattedMessage id="storage.product.adminDisplay.child.dsku" defaultMessage="Child (Case pack) dsku" />
            </Text>
          </Box>
        </Tooltip>
      </TooltipWrapper>
    </StyledDiv>
  );
};

const ProductDskuTooltip: FC = () => {
  const { tooltipActive, onMouseOut, onMouseOver } = useInfoTooltip();

  return (
    <StyledDiv onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      {"(P)"}
      <TooltipWrapper>
        <Tooltip skin={TooltipSkin.PRIMARY} active={tooltipActive}>
          <Box width={"9rem"}>
            <Text>
              <FormattedMessage id="storage.product.adminDisplay.parent.dsku" defaultMessage="Parent (Product) dsku" />
            </Text>
          </Box>
        </Tooltip>
      </TooltipWrapper>
    </StyledDiv>
  );
};
