import { Bundle } from "@deliverr/commons-clients";
import { inventoryClient } from "common/clients/instances";
import { getBundleAvailableQty } from "./getBundleAvailableQty";

const getUniqueProductIds = (bundles: Bundle[]) => {
  const set = bundles.reduce((acc, { bundleItems = [] }) => {
    for (const { dsku } of bundleItems) {
      acc.add(dsku);
    }
    return acc;
  }, new Set<string>());

  return Array.from(set);
};

interface BskuToAvailableQty {
  [bsku: string]: number;
}

/**
 * Given a list of bundles, fetches a map of each bsku to its available quantity.
 */

export const fetchBskuToAvailableQty = async (bundles: Bundle[]): Promise<BskuToAvailableQty> => {
  const productIds = getUniqueProductIds(bundles);
  const productATPs = await inventoryClient.getATP(productIds);
  const bskuToAvailableQty = bundles.reduce((acc, bundle) => {
    acc[bundle.bsku] = getBundleAvailableQty(bundle, productATPs);
    return acc;
  }, {});
  return bskuToAvailableQty;
};
