// might get rid of gray;

import styled from "@emotion/styled";
import React, { ReactNode } from "react";
import { IconV2Types, IconV2 } from "../IconV2";
import { Appearance, DefaultTheme, defaultTheme, ThemeProps } from "../shared";
import { isMobile } from "../shared/helpers/utils";
export type NotificationAppearance = Extract<
  keyof typeof Appearance,
  "WARNING" | "DANGER" | "INFO" | "SUCCESS" | "DEFAULT" | "PRIMARY" | "LAUNCH"
>;

export interface NotificationProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Type of Alert Notification */
  appearance?: NotificationAppearance;
  /** Shows icon based off appearance */
  showIcon?: boolean;
  /** Styled notification content, will not render children */
  content?: {
    title?: ReactNode;
    description?: ReactNode;
  };
  append?: ReactNode;
  /** Deprecated, changes background to white */
  old?: boolean;
  onClose?: () => void;
  iconType?: IconV2Types;
}

const defaultApperance: NotificationAppearance = "WARNING";

const iconsByAppearance: Record<NotificationAppearance, IconV2Types> = {
  [Appearance.WARNING]: "exclamation-triangle",
  [Appearance.DANGER]: "exclamation-circle",
  [Appearance.INFO]: "bell",
  [Appearance.SUCCESS]: "check-circle",
  [Appearance.DEFAULT]: "info-circle",
  [Appearance.PRIMARY]: "info-circle",
  [Appearance.LAUNCH]: "launch",
};
// box-shadow: ${theme.shadow.S1}, inset 4px 0px 0px 0px ${theme.colors[color][300]};

const borderRadius = "4px";
const Wrapper = styled.div<NotificationProps, DefaultTheme>(({ appearance = defaultApperance, old, theme }) => {
  const color = theme.config.colorByAppearance[appearance];
  return `
    position: relative;
    padding: ${theme.spacing.S4};
    width: 100%;
    display: flex;
    ${
      isMobile(theme)
        ? `
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto;
    `
        : `
      display: flex;
      align-items: center;
      padding-right: ${theme.spacing.S5};

    `
    }
    background-color: ${
      appearance === Appearance.INFO
        ? theme.colors[color][40]
        : appearance === Appearance.DEFAULT || appearance === Appearance.PRIMARY
        ? theme.colors[color][50]
        : theme.colors[color][100]
    };
    ${old ? `background-color: ${theme.colors.NEUTRAL["00"]};` : ``}
    color: ${theme.colors.NEUTRAL[500]};
    border-radius: ${borderRadius};
    :after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 4px;
      background-color: ${theme.colors[color][300]};
      border-radius: ${borderRadius} 0 0 ${borderRadius};
    }
  `;
});

const IconContainer = styled.div<NotificationProps, DefaultTheme>(({ appearance = defaultApperance, theme }) => {
  const color = theme.config.colorByAppearance[appearance];
  return `
    margin: ${
      isMobile(theme)
        ? `
      ${theme.spacing.S1} ${theme.spacing.S2} 0 ${theme.spacing.S1};
    `
        : `
      auto ${theme.spacing.S4} auto ${theme.spacing.S1};
    `
    }
    display: flex;
    font-size: ${theme.iconSize.I8};
    color: ${theme.colors[color][300]};
  `;
});

const largeIconSize = "24px";
const smallIconSize = "16px";

const StyledIcon = styled(IconV2)<ThemeProps>(
  ({ theme }) => `
  width: 24px;
  font-size: ${isMobile(theme) ? smallIconSize : largeIconSize};
`
);

const NotificationDesc = styled.span<ThemeProps>`
  line-height: ${({ theme }) => theme.font.lineHeight.LH2};
`;

// exposes title for customization
export const NotificationTitle = styled.span<ThemeProps>(
  ({ theme }) => `
  font-weight: ${theme.font.weight.BOLD};
  display: block;
  line-height: ${theme.font.lineHeight.LH3};
`
);

export const AppendChild = styled.div<NotificationProps, DefaultTheme>`
  margin-left: auto;
  flex-shrink: 0;
  ${({ showIcon, theme }) =>
    isMobile(theme) &&
    `
    width: 100%;
    margin-top: ${theme.spacing.S2};
    grid-column: ${showIcon ? "2" : "1"};
    grid-row: 2;
  `}
`;
export const IconButton = styled.button<ThemeProps>`
  border: none;
  background: none;
  padding: 0;
  margin-left: 1rem;

  :hover {
    background: none;
    border: none;
    background-color: none;
  }
  ${({ theme }) =>
    isMobile(theme) &&
    `
    align-self:flex-start;
    grid-column: 3;
    margin-left: 0;
    `}
`;
const ContentContainer = styled.span<ThemeProps>`
  width: inherit;
`;
export const Notification: React.FC<NotificationProps> = React.memo(
  ({ showIcon = true, content, children, append, onClose, iconType, ...props }) => (
    <Wrapper {...props}>
      {showIcon && (
        <IconContainer appearance={props.appearance}>
          {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
          <StyledIcon type={iconType ?? iconsByAppearance[props.appearance || defaultApperance]} data-testid="icon" />
        </IconContainer>
      )}
      {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
      {content?.title || content?.description ? (
        <ContentContainer>
          {content.title && <NotificationTitle>{content.title}</NotificationTitle>}
          {content.description && <NotificationDesc>{content.description}</NotificationDesc>}
        </ContentContainer>
      ) : (
        children
      )}
      {append && <AppendChild showIcon={showIcon}>{append}</AppendChild>}
      {onClose && (
        <IconButton onClick={onClose} aria-label="close">
          <IconV2 type="close" color={defaultTheme.colors.NEUTRAL[500]} />
        </IconButton>
      )}
    </Wrapper>
  )
);

Notification.displayName = "Notification";
