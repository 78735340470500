import React, { FC } from "react";
import { ExternalLink } from "../ExternalLink";
import styled from "@emotion/styled";
import { ThemeProps } from "common/components/ui";
import { getHelpCenterNewRequestUrl } from "common/support/helpCenter";
import { getIsSupportChatLive, getIsSupportChatLiveExtendedHours } from "./getIsSupportChatLive";
import { useZendeskChatLauncher } from "./useZendeskChatLauncher";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";
import { FeatureName, useFeatureOn } from "common/Split";

const UnstyledButton = styled.button<ThemeProps<{ renderAsLink: boolean }>>(
  ({ renderAsLink, theme }) => `
    appearance: none;
    margin: 0;
    padding: 0;
    border: 0;
    display: ${renderAsLink ? "inline" : "inline-block"};
    width: ${renderAsLink ? "auto" : "100%"};
    background: transparent;
    outline: none;
    box-shadow: none;
    color: ${renderAsLink ? theme.colors.BLUE["300"] : "inherit"};
    font-weight: ${renderAsLink ? theme.font.weight.BOLD : "inherit"};
  `
);

export interface SupportChatLauncherProps {
  onLaunch?: () => void;
  /**
   * By default, this will be rendered as a un-styled, black-text button.
   * This renders it as link style instead.
   **/
  renderAsLink?: boolean;
}

const ZendeskChatLauncher: FC<SupportChatLauncherProps> = ({ onLaunch, renderAsLink = false, children }) => {
  const { onClick } = useZendeskChatLauncher(onLaunch);

  return (
    <UnstyledButton onClick={onClick} renderAsLink={renderAsLink}>
      {children}
    </UnstyledButton>
  );
};

export const SupportChatLauncher: FC<SupportChatLauncherProps> = ({ onLaunch, renderAsLink = false, children }) => {
  const isSupportChatExtendedHoursActive = useFeatureOn(FeatureName.SupportChatExtendedHoursActive);
  const currentDate = new Date();
  const isSupportChatLive = isSupportChatExtendedHoursActive
    ? getIsSupportChatLiveExtendedHours(currentDate)
    : getIsSupportChatLive(currentDate);

  return (
    <ErrorBoundary name="chat-launcher-error-boundary" errorDisplay={<></>}>
      {isSupportChatLive ? (
        <ZendeskChatLauncher onLaunch={onLaunch} renderAsLink={renderAsLink}>
          {children}
        </ZendeskChatLauncher>
      ) : (
        <ExternalLink onClick={onLaunch} href={getHelpCenterNewRequestUrl()}>
          {children}
        </ExternalLink>
      )}
    </ErrorBoundary>
  );
};
