import styled from "@emotion/styled";
import { format } from "date-fns-tz";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { shipmentCreateLabels } from "../shipmentCreate.labels";
import { Text } from "common/components/ui";
import { CommaDate } from "common/date/DateFormat";

const SummaryContainer = styled.div`
  display: flex;
  width: 80%;
`;

const SummarySection = styled.div`
  flex-grow: 1;
  width: 25%;
`;

export const SharedConfirmShipmentSummary: FC<{
  numOfSkus: number;
  numOfCases?: number;
  numOfUnits: number;
  fromAddress: string | JSX.Element;
  toAddress: string | JSX.Element;
  shipping?: string;
  carrier?: string | JSX.Element;
  shipBy?: string | null;
}> = ({ numOfCases, numOfUnits, numOfSkus, fromAddress, toAddress, shipping, carrier, shipBy }) => {
  return (
    <>
      <SummaryContainer data-testid="shipment-summary">
        <SummarySection data-testid="summary-contents">
          <Text bold>
            <FormattedMessage {...shipmentCreateLabels.steps.confirm.contentsTitle} />
          </Text>
          <FormattedMessage
            {...(numOfCases
              ? shipmentCreateLabels.steps.confirm.contentsDescription
              : shipmentCreateLabels.steps.confirm.contentsDescWithoutNumOfCases)}
            values={{ br: <br />, numOfCases, numOfUnits, numOfSkus }}
          />
        </SummarySection>
        <SummarySection data-testid="summary-from-address">
          <Text bold>
            <FormattedMessage {...shipmentCreateLabels.steps.confirm.fromTitle} />
          </Text>
          {fromAddress}
        </SummarySection>
        <SummarySection data-testid="summary-to-address">
          <Text bold>
            <FormattedMessage {...shipmentCreateLabels.steps.confirm.toTitle} />
          </Text>
          {toAddress}
        </SummarySection>
        {carrier && (
          <SummarySection data-testid="summary-carrier">
            <Text bold>
              <FormattedMessage {...shipmentCreateLabels.steps.confirm.carrierTitle} />
            </Text>
            {carrier}
          </SummarySection>
        )}
        {shipping && (
          <SummarySection data-testid="summary-shipping">
            <Text bold>
              <FormattedMessage {...shipmentCreateLabels.steps.confirm.shippingTitle} />
            </Text>
            {shipping}
          </SummarySection>
        )}
      </SummaryContainer>
      <SummaryContainer>
        {shipBy && (
          <SummarySection data-testid="summary-shipby">
            <Text bold>
              <FormattedMessage {...shipmentCreateLabels.steps.confirm.shipByTitle} />
            </Text>
            {format(new Date(shipBy), CommaDate)}
          </SummarySection>
        )}
      </SummaryContainer>
    </>
  );
};
