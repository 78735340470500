import React, { FC } from "react";
import { DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import { DynamicRoutes } from "paths";
import { Link, LinkProps } from "react-router-dom";

interface SKULinkProps {
  dsku: string;
}

const StyledSKULink = styled(Link)<LinkProps, DefaultTheme>(
  ({ theme }) => `
  font-weight: ${theme.font.weight.BOLD};
`
);

export const SKULink: FC<React.PropsWithChildren<SKULinkProps>> = ({ dsku, children }) => (
  <StyledSKULink to={DynamicRoutes.inventoryDetail.parse({ dsku })} rel="noopener noreferrer" target="_blank">
    {children}
  </StyledSKULink>
);
