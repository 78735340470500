import {
  CARRIER_INPUT_DEBOUNCE_INTERVAL,
  CARRIER_SELECT_ALGOLIA_CONFIG,
  CarrierSelectOption,
  CarrierSelectOptions,
  carrierCodeToOption,
  lookupCarrier,
  processSearchResultsToOptions,
} from "./CarrierSelectUtil";
import { FeatureName, useFeatureOn } from "common/Split";
import { useCallback, useEffect, useState } from "react";

import { debounce } from "lodash/fp";
import { useAlgoliaService } from "common/hooks/useAlgoliaService";
import { useIntl } from "react-intl";
import { useLogisticsCarrierSearchService } from "./useLogisticsCarrierSearchService";

export const useCarrierSelect = (selectOptions?: CarrierSelectOptions) => {
  const isLogisticsSearchEnabled = useFeatureOn(FeatureName.LogisticsSearchUseCarrierSearch);
  const { handleInputChange, handleSelectionChange, carrier } = selectOptions ?? {};
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<CarrierSelectOption | undefined>(carrierCodeToOption(carrier));

  const setDebouncedTerm = debounce(CARRIER_INPUT_DEBOUNCE_INTERVAL, (term: string) => setSearchTerm(term));

  const { formatMessage } = useIntl();
  const { data } = useAlgoliaService({
    searchTerm,
    serviceConfig: CARRIER_SELECT_ALGOLIA_CONFIG,
    isAlgoliaSearchEnabled: !isLogisticsSearchEnabled,
  });
  const { data: lookupData } = useAlgoliaService({
    searchTerm: carrier ?? "",
    serviceConfig: CARRIER_SELECT_ALGOLIA_CONFIG,
    isAlgoliaSearchEnabled: !isLogisticsSearchEnabled,
  });
  const { data: carrierOpenSearchData } = useLogisticsCarrierSearchService({
    searchTerm,
    isLogisticsSearchEnabled,
  });
  const { data: carrierOpenSearchLookupData } = useLogisticsCarrierSearchService({
    searchTerm: carrier ?? "",
    isLogisticsSearchEnabled,
  });

  useEffect(() => {
    setSelectedOption(lookupCarrier(carrier)(isLogisticsSearchEnabled ? carrierOpenSearchLookupData : lookupData));
  }, [carrier, carrierOpenSearchLookupData, isLogisticsSearchEnabled, lookupData]);

  // labels
  const placeholder = formatMessage({ id: "CarrierSearchBar.placeholder", defaultMessage: "Enter your carrier" });

  const handleSearch = useCallback(
    (searchText) => {
      handleInputChange?.(searchText);
      setDebouncedTerm(searchText);
      return searchText;
    },
    [handleInputChange, setDebouncedTerm]
  );

  const onChange = (option: CarrierSelectOption) => {
    handleSelectionChange?.(option?.value);
    setSelectedOption(option);
  };

  return {
    handleSearch,
    selectedOption,
    setSelectedOption,
    searchTerm,
    placeholder,
    onChange,
    options: processSearchResultsToOptions(isLogisticsSearchEnabled ? carrierOpenSearchData : data),
  };
};
