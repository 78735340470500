import React, { useRef } from "react";
import styled from "@emotion/styled";

import { defaultTheme, DefaultTheme, ThemeProps } from "../shared";
import { isMobile } from "../shared/helpers/utils";
import { Icon, IconsProps } from "../Icon";
import Tooltip, { RCTooltip } from "rc-tooltip";

export type CheckableTileType = "radio" | "checkbox";

const CHECKED_BOX_SHADOW = "0px 1px 18px 0px rgba(0, 0, 0, 0.05), 0px 6px 10px 0px rgba(0, 0, 0, 0.05)";
const CHECKED_BORDER_BOX_SHADOW = `0 0 0 ${defaultTheme.border.width.B2} ${defaultTheme.colors.BLUE["300"]}`;

export interface CheckableTileProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: JSX.Element | string;
  content?: JSX.Element | string;
  optionId?: string;
  /** additional content that appears to the right of the label and text content */
  imgContent?: JSX.Element;
  type: CheckableTileType;
  block?: boolean;
  reverse?: boolean;
  height?: string;
  iconType?: "circle" | "square";
  tooltipProps?: RCTooltip.Props;
}

const Tile = styled.label<
  Pick<CheckableTileProps, "checked" | "block" | "disabled" | "reverse" | "height" | "tooltipProps">,
  DefaultTheme
>(
  ({ checked, block, disabled, reverse, height, theme }) => `
  display: ${block ? "flex" : "inline-flex"};
  flex-direction: ${reverse ? "row-reverse" : "row"};
  align-items: center;
  background-color: ${disabled ? theme.colors.NEUTRAL[20] : theme.colors.NEUTRAL["00"]};
  padding: ${isMobile(theme) ? theme.spacing.S4 : theme.spacing.S5};
  border: ${
    checked && disabled
      ? `${theme.border.width.B2} ${theme.border.type} ${theme.colors.BLUE[200]}`
      : `${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL["60"]}`
  };
  height: ${height};
  border-radius: ${theme.border.radius.R4};
  cursor: ${disabled ? "not-allowed" : "pointer"};
  box-shadow: ${checked ? CHECKED_BOX_SHADOW : "none"};
  transition: box-shadow 0.1s ease-in-out;
  position: relative;

  &:before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: ${checked && !disabled ? CHECKED_BORDER_BOX_SHADOW : "none"};
    border-radius: ${theme.border.radius.R4};
  }

  &:hover, &:focus-within {
    &:before {
      box-shadow: ${!disabled ? CHECKED_BORDER_BOX_SHADOW : "none"};
    }
  }
`
);

const TextContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    line-height: ${theme.font.lineHeight.LH2};
    flex-grow: 1;
  `
);

const Label = styled.label<Pick<CheckableTileProps, "disabled">, DefaultTheme>(
  ({ disabled, theme }) => `
    color: ${disabled ? theme.colors.NEUTRAL["300"] : theme.colors.NEUTRAL["500"]};
    font-size: ${theme.font.size.F2};
    font-weight: ${theme.font.weight.BOLD};
    font-family: ${theme.font.family.STANDARD};
    margin-bottom: 0;
  `
);

const Content = styled.div<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL["300"]}
    margin-top: ${theme.spacing.S1};
  `
);

const Circle = styled.span<ThemeProps>(
  ({ theme }) => `
    border: 1px solid ${theme.colors.NEUTRAL["80"]};
    border-radius: 50%;
    min-width: ${theme.spacing.S5};
    height: ${theme.spacing.S5};
  `
);

const Square = styled.span<ThemeProps>(
  ({ theme }) => `
    border: 1px solid ${theme.colors.NEUTRAL["80"]};
    min-width: ${theme.spacing.S5};
    height: ${theme.spacing.S5};
  `
);

const Input = styled.input<React.InputHTMLAttributes<HTMLInputElement>, DefaultTheme>`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

const StyledIcon = styled(Icon)<IconsProps & Pick<CheckableTileProps, "disabled">, DefaultTheme>(
  ({ disabled, theme }) => `
  min-width: ${theme.spacing.S5};
  height: ${theme.spacing.S5};
  color: ${disabled ? theme.colors.NEUTRAL[200] : theme.colors.GREEN[300]}
`
);

const PaddingCheckbox = styled.div<ThemeProps>(
  ({ theme }) => `
  padding-left: ${isMobile(theme) ? theme.spacing.S4 : theme.spacing.S5};
`
);

const PaddingImage = styled.div<ThemeProps>(
  ({ theme }) => `
  padding-left: ${theme.spacing.S3};
`
);

const SquareIcon = ({ checked, disabled }: { checked?: boolean; disabled?: boolean }) =>
  checked ? (
    <StyledIcon data-testid="checkable_tile_checkmark_square" type="check-square" disabled={disabled} />
  ) : (
    <Square aria-hidden />
  );

const CircleIcon = ({ checked, disabled }: { checked?: boolean; disabled?: boolean }) =>
  checked ? (
    <StyledIcon data-testid="checkable_tile_checkmark_circle" type="check-circle" disabled={disabled} />
  ) : (
    <Circle aria-hidden />
  );

export const CheckableTile: React.FC<CheckableTileProps> = ({
  label,
  content,
  optionId,
  imgContent,
  checked,
  block,
  disabled,
  id,
  className,
  height,
  reverse,
  iconType,
  tooltipProps,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <Tooltip key={id} trigger={[]} overlay={<></>} {...tooltipProps}>
      <Tile
        id={optionId}
        checked={checked}
        block={block}
        data-testid="checkable_tile"
        disabled={disabled}
        className={className}
        reverse={reverse}
        onMouseOut={() => ref.current?.blur()}
        height={height}
      >
        <Input
          id={id}
          aria-checked={checked}
          data-testid="checkable_tile_input"
          checked={checked}
          disabled={disabled}
          {...props}
          ref={ref}
        />
        {iconType ? (
          iconType === "square" ? (
            <SquareIcon checked={checked} disabled={disabled} />
          ) : (
            <CircleIcon checked={checked} disabled={disabled} />
          )
        ) : props.type === "checkbox" ? (
          <SquareIcon checked={checked} disabled={disabled} />
        ) : (
          <CircleIcon checked={checked} disabled={disabled} />
        )}
        <PaddingCheckbox />
        <TextContainer>
          {label && (
            <Label disabled={disabled} data-testid="checkable_tile_label" htmlFor={id}>
              {label}
            </Label>
          )}
          {content && <Content data-testid="checkable_tile_content">{content}</Content>}
        </TextContainer>
        {imgContent && (
          <>
            <PaddingImage />
            <div data-testid="checkable_tile_image_content">{imgContent}</div>
          </>
        )}
      </Tile>
    </Tooltip>
  );
};
