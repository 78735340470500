import { Sort } from "common/models";
import { NumericFilter } from "common/list/AlgoliaService";
import { Dictionary } from "lodash";
import { QueryDslQueryContainer } from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";

export interface SearchRequest {
  searchTerm?: string;
  filters?: string[];
  numericFilter?: NumericFilter;
  customizedOpenSearchFilters?: QueryDslQueryContainer[];
  page?: number;
  pageSize?: number;
  sort?: Sort;
  highlightMatches?: boolean;
  clearCacheOnSearch?: boolean;
}

export interface SearchResultsResponse {
  nbHits: number;
  nbPages: number;
  hitsPerPage: number;
  page: number;
}

export interface SearchResults {
  hits: any[];
  response: SearchResultsResponse;
}

export abstract class SearchService {
  async search(
    query: string,
    pageNum: number = 0,
    sortBy?: Sort,
    filters: string[] = [],
    numericFilter?: NumericFilter,
    resultsSize?: number
  ): Promise<SearchResults> {
    return await this.execute({
      searchTerm: query,
      page: pageNum,
      pageSize: resultsSize,
      filters,
      numericFilter,
      sort: sortBy,
    });
  }

  clearCache(): SearchService {
    return this;
  }

  public abstract searchByIds(
    ids: Array<number | string>,
    query: string,
    idField: string,
    resultsSize?: number
  ): Promise<SearchResults>;

  public abstract execute(request: SearchRequest): Promise<SearchResults>;

  public abstract getRecordByObjectId<T>(objectId: string): Promise<T>;

  public abstract getAllRecordsByObjectIds<T>(objectIds: string[]): Promise<Dictionary<T>>;
}
