import { Container, ThemeProps } from "common/components/ui";
import React, { FC } from "react";
import { RmtId, SellerRmtMinimal } from "@deliverr/returns-client";

import { ElevioArticleId } from "common/elevio";
import { OAuthIntegrationSetupForm } from "onboarding/ListingTool/tools/common/OAuthIntegrationSetupForm";
import { ReturnsRmtConnectHelp } from "../RmtHelp/ReturnsRmtHelp";
import { rmtConfigProp } from "returns/rmt/RmtUtils";
import styled from "@emotion/styled";
import { useAmazonOAuthError } from "./useAmazonOAuthError";
import { useSetupRmtCardAmazon } from "./useSetupRmtCardAmazon";

export interface RmtCardAmazonProps {
  rmt: SellerRmtMinimal;
}

const StyledContainer = styled(Container)<ThemeProps>(
  ({ theme }) => `
  margin: ${theme.spacing.S7} auto;
  width: 1000px;
  max-width: none;
`
);

const StyledReturnsRmtConnectHelp = styled.div<ThemeProps>(
  ({ theme }) => `
  text-align: center;
`
);
const connectRmtElevioArticleId = rmtConfigProp(RmtId.AMAZON, "connectRmtElevioArticleId") as ElevioArticleId;

export const SetupRmtCardAmazon: FC<{ rmt }> = ({ rmt }) => {
  const { formProps } = useSetupRmtCardAmazon(rmt);
  useAmazonOAuthError();

  return (
    <StyledContainer>
      <OAuthIntegrationSetupForm {...formProps} showHelp={false}>
        <StyledReturnsRmtConnectHelp>
          <ReturnsRmtConnectHelp articleId={connectRmtElevioArticleId} marginTop />
        </StyledReturnsRmtConnectHelp>
      </OAuthIntegrationSetupForm>
    </StyledContainer>
  );
};

export default SetupRmtCardAmazon;
