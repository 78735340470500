import React, { FC } from "react";
import styled from "@emotion/styled";
import { DefaultTheme, Text, Title } from "common/components/ui";
import { InfoIcon } from "common/components/icons";
import { ShipmentStatusBar } from "inbounds/steps/ship/ShipmentHeaderEta/ShipmentStatusBar";
import { ShipmentEtaDetailsTooltip } from "inbounds/steps/ship/ShipmentHeaderEta/ShipmentEtaDetailsTooltip";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useGetStorageShipmentUnitStatus } from "./useGetStorageShipmentUnitStatus";

const InfoIconContainer = styled.span<{}, DefaultTheme>(
  ({ theme }) => `
  margin-left: ${theme.spacing.S1};
`
);

const UnitStatusSection = styled.div`
  padding: 0px;
  width: 100%;
`;

const UnitStatusRow = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
  display: flex;
  padding-top: ${theme.spacing.S2};
`
);

const QtyTextWrapper = styled(Text)`
  display: flex;
  align-items: baseline;
`;

const LargeAvailableText = styled(Title)<{}, DefaultTheme>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S2};
`
);

const TotalQtyText = styled(Title)<{}, DefaultTheme>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S2};
  margin-left: ${theme.spacing.S1};
  color: ${theme.colors.NEUTRAL[200]};
  `
);

export const StorageShipmentAvailabilityDisplay: FC = () => {
  const { unitBreakdown, slices } = useGetStorageShipmentUnitStatus();

  return unitBreakdown.expectedQty ? (
    <UnitStatusSection>
      <UnitStatusRow>
        <Text>
          <FormattedMessage id={"storage.inbound.availableUnits.summaryReceived"} defaultMessage="Available units" />
        </Text>
        <InfoIconContainer>
          <InfoIcon placement="top" message={<ShipmentEtaDetailsTooltip slices={slices} />} />
        </InfoIconContainer>
      </UnitStatusRow>
      <QtyTextWrapper>
        <LargeAvailableText displayAs="h2">{unitBreakdown.receivedQty}</LargeAvailableText>
        <TotalQtyText displayAs="h4">
          <FormattedMessage
            id={"storage.inbound.totalQty.summary"}
            defaultMessage={" of {expected}"}
            values={{
              expected: <FormattedNumber value={unitBreakdown.expectedQty} />,
            }}
          />
        </TotalQtyText>
      </QtyTextWrapper>
      <ShipmentStatusBar slices={slices} />
    </UnitStatusSection>
  ) : null;
};
