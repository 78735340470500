import { Product } from "@deliverr/commons-clients";
import { WholesaleOrderDTO } from "@deliverr/wholesale-client";
import { forEach, isEmpty } from "lodash";
import { getDefaultPackFromProduct } from "storage/packUtils/getDefaultPackFromProduct";
import { mapWholesaleItemsToPackSkus } from "storage/packUtils/mapWholesaleItemsToPackSkus";

export function mapWholesaleOrderSkusToPackSkus(
  wholesaleorder: WholesaleOrderDTO,
  products: { [dsku: string]: Product }
): WholesaleOrderDTO {
  wholesaleorder.orderItems = mapWholesaleItemsToPackSkus(wholesaleorder.orderItems ?? [], products);

  // Map shipment items and parcel package quantities
  forEach(wholesaleorder.shipments, (shipment) => {
    shipment.shipmentItems = mapWholesaleItemsToPackSkus(shipment.shipmentItems ?? [], products);
    shipment.packages = forEach(shipment.packages, (pkg) => {
      pkg.dskuToQtyMap = mapPackageSkuToPackSku(pkg.dskuToQtyMap, products);
    });
  });
  return wholesaleorder;
}

export const mapPackageSkuToPackSku = (
  dskuToQtyMap?: { [key: string]: number },
  products?: { [dsku: string]: Product }
) => {
  if (isEmpty(dskuToQtyMap)) {
    return dskuToQtyMap;
  }

  return Object.entries(dskuToQtyMap!).reduce<{ [key: string]: number }>((agg, [dsku, qty]) => {
    const product = products?.[dsku];
    const packDsku = product && !product.packOf ? getDefaultPackFromProduct(product)?.dsku : dsku;
    const productSku = packDsku ?? dsku;
    agg[productSku] = (agg[productSku] ?? 0) + qty;

    return agg;
  }, {});
};
