import { toast } from "common/components/ui";
import { initiateDownloadFromUrl } from "common/InitiateDownload";
import React from "react";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { sendShippingLabelEmail } from "returns/label/store/ReturnsLabelAction";
import { useReturnsLabelOrchestration } from "returns/label/useReturnsLabelOrchestration";
import isEmail from "validator/lib/isEmail";

export const returnLabelMessages = defineMessages({
  labelDoesNotExist: {
    id: "returns.useReturnsLabel.error",
    defaultMessage: "Label does not exist for this returns",
  },
  labelDownloaded: {
    id: "returns.useReturnsLabel.success",
    defaultMessage: "Label downloaded",
  },
  shareLabel: {
    id: "returns.shareReturnsLabel.success",
    defaultMessage: "An email with the return label was sent to {buyerEmail}",
  },
});

export const useShareLabel = (shareLabelSuccess?) => {
  const { error, returnItem, dispatch, shareLabelStatus, email } = useReturnsLabelOrchestration();
  const { formatMessage } = useIntl();
  const downloadDisabled = !!error || !returnItem;
  const [buyerEmail, setBuyerEmail] = React.useState("");
  const [isBuyerEmailValid, setIsBuyerEmailValid] = React.useState(true);
  const [shareDisabled, setShareDisabled] = React.useState(true);

  const validateEmail = (inputEmail: string) => {
    if (!error && isEmail(inputEmail)) {
      setIsBuyerEmailValid(true);
      setShareDisabled(false);
    } else {
      setIsBuyerEmailValid(false);
      setShareDisabled(true);
    }
  };

  React.useEffect(() => {
    if (shareLabelStatus) {
      toast.success(formatMessage(returnLabelMessages.shareLabel, { buyerEmail: email }), {
        toastId: returnLabelMessages.shareLabel.id,
      });
      shareLabelSuccess?.();
    }
    if (error) {
      toast.error(formatMessage(error as MessageDescriptor), { toastId: "returnLabelErrorToast" });
    }
  }, [shareLabelStatus, shareLabelSuccess, email, error, formatMessage]);

  const downloadClick = () => {
    if (returnItem) {
      const label = returnItem.packages[0].label;
      if (label?.labelUrl) {
        initiateDownloadFromUrl(label.labelUrl, `returnsLabel_${label.id}.png`);
        toast.success(formatMessage(returnLabelMessages.labelDownloaded));
      } else {
        toast.error(formatMessage(returnLabelMessages.labelDoesNotExist));
      }
      shareLabelSuccess?.();
    }
  };

  const shareClick = () => {
    setShareDisabled(true);
    dispatch(sendShippingLabelEmail(returnItem?.id?.toString() ?? "", buyerEmail));
  };

  return {
    downloadClick,
    downloadDisabled,
    shareClick,
    setBuyerEmail,
    validateEmail,
    buyerEmail,
    isBuyerEmailValid,
    shareDisabled,
  };
};
