import { DskuQty } from "@deliverr/commons-clients";
import { storageClient } from "common/clients/instances";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { SPThunkAction } from "common/ReduxUtils";
import { logError } from "Logger";
import { CreateWholesaleLoader } from "../CreateWholesaleLoader.types";
import { transferCreateSetReplenishmentError } from "transfers/create/actions";
import { batch } from "react-redux";
import { ReplenishmentPickProductErrorCodes } from "transfers/create/steps/common/replenishment/errors/pick-products-errors/ReplenishmentPickProductErrorCodes";
import { StorageWarehouseInventory } from "common/clients/storage/StorageWarehouseInventory";
import { isEmpty } from "lodash";
import { setAvailableInventoryInEachStorageWarehouse } from "../actions/WholesaleCreateActions";

export const getAvailableInventoryInEachStorageWarehouse =
  (dskuQuantities: DskuQty[], includeInventoryBreakdown?: boolean): SPThunkAction =>
  async (dispatch) => {
    batch(() => {
      dispatch(transferCreateSetReplenishmentError({ errors: [] }));
      dispatch(addLoader(CreateWholesaleLoader.FetchStorageInventoryLoader));
    });

    let availableInventoryInEachStorageWarehouse: StorageWarehouseInventory[] | undefined;
    try {
      availableInventoryInEachStorageWarehouse = includeInventoryBreakdown
        ? (await storageClient.getUnifiedAvailablePackInventoryByWarehouse(dskuQuantities))?.value
        : (await storageClient.validateAndReturnInventoryAvailableInEachStorageWarehouse(dskuQuantities))?.value;

      if (!isEmpty(availableInventoryInEachStorageWarehouse)) {
        dispatch(setAvailableInventoryInEachStorageWarehouse(availableInventoryInEachStorageWarehouse!));
      } else {
        if (dskuQuantities.length === 1) {
          dispatch(
            transferCreateSetReplenishmentError({
              errors: [
                {
                  code: ReplenishmentPickProductErrorCodes.PRODUCT_NOT_AVAILABLE_IN_ANY_WAREHOUSE,
                  message: "Product not available in any warehouse",
                  name: "SingleProductNotAvailableInAnyWarehouse",
                },
              ],
            })
          );
        } else {
          dispatch(
            transferCreateSetReplenishmentError({
              errors: [
                {
                  code: ReplenishmentPickProductErrorCodes.PRODUCTS_NOT_AVAILABLE_AT_SAME_WAREHOUSE,
                  message: "Products are not available at the same warehouse",
                  name: "ProductsNotAvailableAtSameWarehouse",
                },
              ],
            })
          );
        }
      }
    } catch (err) {
      logError({ fn: "getAvailableInventoryInEachStorageWarehouse" }, err);
      dispatch(
        transferCreateSetReplenishmentError({ errors: err?.response?.data?.error ? [err?.response?.data?.error] : [] })
      );
    } finally {
      dispatch(clearLoader(CreateWholesaleLoader.FetchStorageInventoryLoader));
    }

    return availableInventoryInEachStorageWarehouse;
  };
