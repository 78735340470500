import { ErrorType } from "@deliverr/commons-clients/lib/event-log/model/BaseEventLog";

export const SupportedOrderImportErrorTypes = [
  ErrorType.ADDRESS_CHECK_ERROR,
  ErrorType.DSKU_NEVER_INBOUNDED_ERROR,
  ErrorType.INVALID_COUNTRY_CODE_ERROR,
  ErrorType.PRODUCT_VALIDATION_ERROR,
  ErrorType.PRODUCT_IMPORT_ERROR,
] as const;

export type SupportedOrderImportErrorType = typeof SupportedOrderImportErrorTypes[number];
