import { DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";

import { ProblemBladeTable, ProblemBladeTableProps } from "../ProblemBladeTable";

export const EstimatedTotalChargeTable = styled(ProblemBladeTable)<ProblemBladeTableProps, DefaultTheme>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL[300]};

    th {
      padding-bottom: ${theme.spacing.S2};
      color: ${theme.colors.NEUTRAL[300]};
    }

    td {
      padding-top: ${theme.spacing.S2};
    }
`
);
