import { SPThunkAction } from "common/ReduxUtils";
import { clearInbound as clearInboundAction } from "./clearInbound";
import { InboundStep } from "inbounds/InboundTypes";
import { goToInboundStep } from "inbounds/store/actions/navigation/goToInboundStep";
import { selectIpbFeatureOn } from "../selectors/selectIpbFeatureOn";
import { goToCreateShipmentStep } from "inbounds/createShipment/routing/goToCreateShipmentStep";

export interface CreateInboundOptions {
  replace?: boolean;
  clearInbound?: boolean;
  /**
   * @deprecated - Temporary flag to route to legacy products page
   */
  routeToProducts?: boolean;
}

export type GoToCreateInboundHandler = (options?: CreateInboundOptions) => SPThunkAction<void>;

export const goToCreateInbound: GoToCreateInboundHandler = ({ replace, clearInbound, routeToProducts } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const isIpbFeatureOn = selectIpbFeatureOn(state);

  if (clearInbound) {
    dispatch(clearInboundAction());
  }

  isIpbFeatureOn
    ? dispatch(goToCreateShipmentStep())
    : dispatch(
        goToInboundStep(routeToProducts ? InboundStep.SELECT_PRODUCTS : InboundStep.SELECT_INBOUND_TYPE, replace)
      );
};
