import { useCreateShipmentContext } from "inbounds/createShipment/CreateShipmentContext";
import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";
import { useDispatch, useSelector } from "react-redux";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { StorageDestinationTypeV2 } from "storage/inbounds/create/selectDestinationV2/StorageDestinationTypeV2.types";
import { useEffect, useState } from "react";
import { useMount } from "react-use";
import { getRankedStorageWarehousesForInbounds } from "storage/inbounds/create/selectDestinationV2/actions/getRankedStorageWarehousesForInbounds";
import { getStorageInboundCreate } from "storage/inbounds/create/store/selector/getStorageInboundCreate";
import { getStorageWarehouseOptions } from "storage/inbounds/create/selectDestinationV2/getStorageWarehouseOptions";
import { getRecommendedWarehouse } from "storage/inbounds/create/selectDestinationV2/getRecommendedWarehouse";
import { StorageWarehouseSellerView } from "storage/inbounds/create/selectDestinationV2/StorageWarehouseSellerView";
import { StorageWarehouseOption } from "storage/inbounds/create/selectDestinationV2/StorageWarehouseOption.types";
import { checkIfWarehousesHaveSameZipCode } from "inbounds/components/StorageFacilitySelect/checkIfWarehousesHaveSameZipCode";
import { logError } from "Logger";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { setReserveStorage } from "inbounds/createShipment/store/actions";
import { selectOrigin } from "inbounds/createShipment/store/selectors";
import { placeToBookingAddress } from "inbounds/createShipment/store/utils";
import { DeliverrAddress } from "@deliverr/commons-objects";
import { getSelectedProductCasePacks } from "storage/inbounds/create/store/selector/getSelectedCasePacks";
import {
  STORAGE_INBOUND_ADDITIONAL_MAX_CASE_LONGEST_IN_FOR_SOME_WH,
  STORAGE_INBOUND_MAX_CASE_LONGEST_IN,
} from "storage/inbounds/CaseDimensionsConstants";
import { WAREHOUSES_SUPPORTING_MAX_HEIGHT } from "storage/inbounds/WarehouseConstants";
import { ProductCasePackCollection } from "storage/inbounds/create/selectProductCasePacks/ProductCasePackCollection.types";
import { ProductCasePack } from "@deliverr/commons-clients/lib/product/ProductCasePack";
import { FeatureName, useFeatureOn } from "common/Split";
import { WAREHOUSES_WITH_RELAXED_STORAGE_DIMENSIONS } from "storage/inbounds/StorageDimensionsOverrideConstants";

export const useReserveStorageFcSelect = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { storageWarehouses } = useSelector(getStorageInboundCreate);
  const origin = useSelector(selectOrigin);
  const storageWarehouseToBeShown = getStorageWarehouseOptions(storageWarehouses as StorageWarehouseSellerView[]);
  const selectedProductCasePacks = useSelector(getSelectedProductCasePacks);
  const recommendedWarehouse = getRecommendedWarehouse(storageWarehouseToBeShown, selectedProductCasePacks);
  const isDimensionOverrideFeatureEnabled = useFeatureOn(FeatureName.InboundRsDimRelaxation);
  const storageWarehouseOptions = storageWarehouseToBeShown
    .filter((warehouse) => warehouse.id !== recommendedWarehouse?.id)
    .map((warehouse) => ({
      value: warehouse,
      label: `${String(warehouse.region.charAt(0).toUpperCase()) + String(warehouse.region.slice(1))}`,
      isDisabled: isStorageWarehouseDisabled(selectedProductCasePacks, warehouse, isDimensionOverrideFeatureEnabled),
      hasSameZipCode: checkIfWarehousesHaveSameZipCode(warehouse, storageWarehouseToBeShown),
    }));
  const warehouseMap = storageWarehouseOptions.reduce((acc, option) => {
    acc[option.value.id] = option;
    return acc;
  }, {});
  const { getValues, setValue } = useCreateShipmentFormContext();
  const initialValue = getValues(CreateShipmentInputName.RESERVE_STORAGE);

  const initialFacilityType =
    initialValue && recommendedWarehouse
      ? initialValue.warehouseId === recommendedWarehouse.id
        ? StorageDestinationTypeV2.RECOMMENDED_WAREHOUSE
        : StorageDestinationTypeV2.SPECIFIC_WAREHOUSE
      : StorageDestinationTypeV2.RECOMMENDED_WAREHOUSE;

  const [facilityType, setFacilityType] = useState<StorageDestinationTypeV2>(initialFacilityType);

  let initialWarehouseOption;
  if (initialValue && storageWarehouseOptions) {
    initialWarehouseOption = storageWarehouseOptions.find(
      (warehouse) => warehouse.value.id === initialValue.warehouseId
    );
  }

  const [selectedWarehouseOption, setSelectedWarehouseOption] = useState<StorageWarehouseOption | undefined>(
    initialWarehouseOption
  );
  const { composeSaveStep, isSaving } = useCreateShipmentContext();

  useMount(async () => {
    await dispatch(getRankedStorageWarehousesForInbounds(true, placeToBookingAddress(origin) as DeliverrAddress));
  });

  useEffect(() => {
    if (
      recommendedWarehouse &&
      initialValue.warehouseId &&
      storageWarehouseOptions &&
      recommendedWarehouse.id !== initialValue.warehouseId
    ) {
      setFacilityType(StorageDestinationTypeV2.SPECIFIC_WAREHOUSE);
      setSelectedWarehouseOption(warehouseMap[initialValue.warehouseId]);
    }
  }, [recommendedWarehouse]);

  const onFacilityTypeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDestinationType = ev.target.value as StorageDestinationTypeV2;
    setFacilityType(selectedDestinationType);
  };

  const onStorageWarehouseSelect = (selectedOption: StorageWarehouseOption) => {
    setSelectedWarehouseOption(selectedOption);
  };

  const submitData = composeSaveStep(async () => {
    const warehouse =
      facilityType === StorageDestinationTypeV2.RECOMMENDED_WAREHOUSE
        ? recommendedWarehouse
        : selectedWarehouseOption?.value;
    if (warehouse) {
      setValue(CreateShipmentInputName.RESERVE_STORAGE, {
        address: warehouse.address,
        warehouseId: warehouse.id,
      });
      dispatch(
        setReserveStorage({
          address: warehouse.address,
          warehouseId: warehouse.id,
        })
      );
    } else {
      logError({ fn: "ReserveStorageFcSelectStep.facilityAddress" }, "Facility address is undefined.");
    }
    return;
  });

  const validateStep = async () => {
    if (
      !(
        facilityType === StorageDestinationTypeV2.RECOMMENDED_WAREHOUSE ||
        (facilityType === StorageDestinationTypeV2.SPECIFIC_WAREHOUSE && !!selectedWarehouseOption)
      )
    ) {
      toast.error(
        formatMessage({
          id: "reserveStorageFcSelectStep.reserveStorage.toast.error",
          defaultMessage: "Please select a reserve storage location.",
        }),
        {
          autoClose: 5000,
          toastId: "reserveStorageFcSelectStep.reserveStorage.requiredFields",
        }
      );
      return false;
    }
    return true;
  };

  return {
    facilityType,
    selectedWarehouseOption,
    isSaving,
    storageWarehouseToBeShown,
    recommendedWarehouse,
    validateStep,
    onFacilityTypeChange,
    onStorageWarehouseSelect,
    submitData,
  };
};

export const isStorageWarehouseDisabled = (
  selectedProductCasePacks: ProductCasePackCollection,
  warehouse: StorageWarehouseSellerView,
  isDimensionOverrideFeatureEnabled: boolean = false
) => {
  return (
    !warehouse.hasAvailablePalletCapacity ||
    !isWarehouseSupportingDims(selectedProductCasePacks, warehouse, isDimensionOverrideFeatureEnabled)
  );
};

const getLongestSide = (casePack: ProductCasePack) => {
  return Math.max(Number(casePack.height) || 0, Number(casePack.length) || 0, Number(casePack.width) || 0);
};

export const isWarehouseSupportingDims = (
  selectedProductCasePacks: ProductCasePackCollection,
  warehouse: StorageWarehouseSellerView,
  isDimensionOverrideFeatureEnabled?: boolean
): boolean => {
  const isOverriddenDimensions = Object.values(selectedProductCasePacks).some(
    (casePack) => getLongestSide(casePack) > STORAGE_INBOUND_ADDITIONAL_MAX_CASE_LONGEST_IN_FOR_SOME_WH
  );

  if (isOverriddenDimensions) {
    return isDimensionOverrideFeatureEnabled
      ? WAREHOUSES_WITH_RELAXED_STORAGE_DIMENSIONS.includes(warehouse.id)
      : false;
  }

  const isHeightGreaterThanMaxForSomeWH = Object.values(selectedProductCasePacks).some(
    (casePack) => getLongestSide(casePack) > STORAGE_INBOUND_MAX_CASE_LONGEST_IN
  );

  return isHeightGreaterThanMaxForSomeWH ? WAREHOUSES_SUPPORTING_MAX_HEIGHT.includes(warehouse.id) : true;
};
