import { createSelector } from "reselect";

import { getPlanItemDetails } from "inbounds/steps/InboundStepSelectors";
import { hasCustomsInformation } from "InternationalShipping/InternationalShippingHelpers";
import { getIsInternationalShipping } from "common/user/UserSelectors";

export const getShowCustoms = createSelector(
  getIsInternationalShipping,
  getPlanItemDetails,
  (isInternationalShipping, planItemDetails) => isInternationalShipping && !hasCustomsInformation(planItemDetails)
);
