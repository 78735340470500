import { ManagedStep } from "common/components/StepContainer";

import { Box, Notification, ThemeProps, Text, BoxReset, defaultTheme, IconButton, IconV2 } from "common/components/ui";
import React from "react";
import { FormattedMessage } from "react-intl";
import { outOfStockOrderItemLabel } from "./outOfStockOrderItemLabel";
import { ReplenishmentOrderDetailsHeader } from "../ReplenishmentOrderDetailsHeader";
import { SummaryContainer } from "../SummaryCard";
import { ReplenishmentOrderSummary } from "../ReplenishmentOrderSummary";
import { ReplenishmentShipmentAddressDisplay } from "../ReplenishmentShipmentAddressDisplay";
import { useReplenishmentOutOfStockOrderItems } from "./useReplenishmentOutOfStockOrderItems";
import { OutOfStockProductTable, getOOSStatusPill } from "./OutOfStockProductTable";
import { noop } from "lodash";
import styled from "@emotion/styled";
import { ResolvedProductDetails } from "../invalid-items/ResolvedProductDetails";
import { HorizontalAlignment } from "common/models";
import { AugmentedOutOfStockOrderItem, InvalidOrderItemInventoryStatus } from "./AugmentedOutOfStockOrderItem";

const StyledSummaryCard = styled(SummaryContainer)<ThemeProps>(
  ({ theme }) => `
    border-bottom: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
  `
);

export const ReplenishmentOutOfStockOrderItems = () => {
  const {
    formatMessage,
    replenishmentOrder,
    isReplenishmentOrderLoading,
    isNextDisabled,
    areAllOrderItemsRemoved,
    removeItem,
    originStorageWarehouse,
    augmentedOutOfStockOrderItems,
  } = useReplenishmentOutOfStockOrderItems();

  return (
    <ManagedStep
      data-testid="transfer-sps-oos-items-step"
      title={formatMessage(outOfStockOrderItemLabel.step.title)}
      subtitle={formatMessage(outOfStockOrderItemLabel.step.subtitle)}
      isNextDisabled={isNextDisabled}
      isLoading={isReplenishmentOrderLoading}
    >
      <Box width={"90%"}>
        <>
          {areAllOrderItemsRemoved && (
            <Notification
              appearance="WARNING"
              content={{
                description: (
                  <b>
                    <FormattedMessage
                      id="replenishment.invalid.orderItems.allRemoved"
                      defaultMessage="No items left in the order"
                    />
                  </b>
                ),
              }}
            />
          )}
          {replenishmentOrder && (
            <>
              <ReplenishmentOrderDetailsHeader replenishmentOrder={replenishmentOrder} />
              <StyledSummaryCard>
                <ReplenishmentOrderSummary replenishmentOrder={replenishmentOrder} shouldHideSkusAndUnits />
                <ReplenishmentShipmentAddressDisplay
                  distributionChannel={replenishmentOrder.distributionChannel}
                  originWarehouseId={originStorageWarehouse?.warehouse?.id}
                  shipments={replenishmentOrder.shipments}
                />
              </StyledSummaryCard>
              <BoxReset marginTop={"S6"} marginBottom={"S5"}>
                <Text bold size="label">
                  <FormattedMessage id={"transfers.outOfStockOrderItem.contents"} defaultMessage={"CONTENTS"} />
                </Text>
              </BoxReset>
              <OutOfStockProductTable
                items={augmentedOutOfStockOrderItems}
                columns={[
                  {
                    header: (
                      <FormattedMessage id={"transfers.outOfStockOrderItem.product"} defaultMessage={"Product"} />
                    ),
                    renderCell: (item: AugmentedOutOfStockOrderItem) => {
                      return (
                        <Box>
                          {<ResolvedProductDetails dsku={item.resolvedDsku} />}
                          {getOOSStatusPill(item)}
                        </Box>
                      );
                    },
                    align: HorizontalAlignment.LEFT,
                    width: "50%",
                  },
                  {
                    header: (
                      <FormattedMessage id={"transfers.oosOrderItem.expectedUnits"} defaultMessage={"Expected Units"} />
                    ),
                    renderCell: (item: AugmentedOutOfStockOrderItem) => item.requestedTotalUnits,
                    align: HorizontalAlignment.RIGHT,
                    width: "20%",
                  },
                  {
                    header: (
                      <FormattedMessage
                        id={"transfers.oosOrderItem.expectedUnits"}
                        defaultMessage={"Available Units"}
                      />
                    ),
                    renderCell: (item: AugmentedOutOfStockOrderItem) => item.availableQty ?? 0,
                    align: HorizontalAlignment.RIGHT,
                    width: "20%",
                  },
                  {
                    width: "44px",
                    header: "",
                    renderCell: (item: AugmentedOutOfStockOrderItem) => {
                      return item.inventoryStatus !== InvalidOrderItemInventoryStatus.IN_STOCK ? (
                        <IconButton onClick={() => removeItem(item)} aria-label="close">
                          <IconV2 type="close" color={defaultTheme.colors.NEUTRAL[100]} />
                        </IconButton>
                      ) : null;
                    },
                    align: HorizontalAlignment.RIGHT,
                  },
                ]}
                page="outOfStockItemProblemsList"
                onPageChange={noop}
                allowRowSelection={false}
                showCheckboxCondition={() => false}
                hidePaginate
                hideHover
              />
            </>
          )}
        </>
      </Box>
    </ManagedStep>
  );
};
