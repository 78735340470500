import { Card, DefaultTheme, Grid } from "common/components/ui";
import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import { RefundStatusIcon } from "./RefundStatusIcon";
import { RefundStatusInfo } from "./RefundStatusInfo";
import { RefundStatusTitle } from "./RefundStatusTitle";
import { ReturnStatus } from "@deliverr/returns-client";
import { ReturnStatusBar } from "./ReturnStatusBar";
import { ReturnStatusIcon } from "./ReturnStatusIcon";
import { ReturnStatusTitle } from "./ReturnStatusTitle";
import { RootState } from "RootReducer";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";

const LargeCard = styled(Card)`
  padding: 40px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StatusHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StatusDetailContainer = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
  border: 1px solid #e9edf2;
  padding: ${theme.spacing.S4};
`
);

const ClosedTextBox = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
  padding: ${theme.spacing.S4};
  margin-left: ${theme.spacing.S4};
  `
);

const closedDetailsMessage = {
  id: "returns.closedDetailsMessage",
  defaultMessage:
    "A closed return indicates that no further activity is expected on the return. If there is any activity in the future, the status will be updated to reflect this.",
};

export const ReturnsDetailStatus: FC = () => {
  const returnOrder = useSelector((state: RootState) => state.returnsDetail.returnsDetail);
  const { status } = returnOrder;
  const refundStatus = returnOrder.refundStatus;
  const { isQcProcessed } = useSelector((state: RootState) => state.returnsDetail.returnsQcDetails);

  return (
    <LargeCard size="LG">
      <Grid columns="1.1fr 1fr">
        <div>
          <FlexContainer>
            <ReturnStatusIcon status={status} />
            <ReturnStatusTitle status={status} />
          </FlexContainer>
          {status === ReturnStatus.CLOSED && (
            <ClosedTextBox>
              <FormattedMessage id={closedDetailsMessage.id} defaultMessage={closedDetailsMessage.defaultMessage} />
            </ClosedTextBox>
          )}
          <div>
            <ReturnStatusBar status={status} />
          </div>
        </div>
        {isQcProcessed && refundStatus && (
          <StatusDetailContainer data-testid="returns-refund-details-container">
            <StatusHeaderContainer>
              <RefundStatusIcon refundStatus={refundStatus} />
              <RefundStatusTitle refundStatus={refundStatus} />
            </StatusHeaderContainer>
            <RefundStatusInfo refundStatus={refundStatus} />
          </StatusDetailContainer>
        )}
      </Grid>
    </LargeCard>
  );
};
