/* eslint-disable no-sequences */
/* eslint-disable no-console */
import {
  all,
  anyPass,
  complement,
  curry,
  equals,
  includes,
  isBoolean,
  isEmpty,
  isNil,
  isNumber,
  pipe,
  values,
} from "lodash/fp";
import { uniq } from "lodash";

export const anyTrue = pipe(values, includes(true));

export const hasValue = complement(isNil);

export const isTrue = equals(true);

export const notEmpty = anyPass([isBoolean, isNumber, complement(isEmpty)]);

export const either = <T>(fn1: (arg0: T) => boolean, fn2: (arg1: T) => boolean) => (val: T) => fn1(val) || fn2(val);

export const objHasAllValues = pipe(values, all(notEmpty));

// This is different from lodash pickAll. Where lodash pickAll and pick are equal.
// This pickAll is a copy of ramda's pickAll where properties that don't exist return undefined.
export const pickAll = curry((names: string[], obj: {}) => {
  const result = {};
  let idx = 0;
  const len = names.length;
  while (idx < len) {
    const name = names[idx];
    result[name] = obj[name];
    idx += 1;
  }
  return result;
});

export const hasDuplicates = (list: Array<string>) => uniq(list).length !== list.length;

export const pipeDebug = (x) => (console.debug(x), x);

export const inArray = <T extends any[]>(...args: T) => args;

export const wrapValue = (min: number, max: number) => (value: number) =>
  value > max ? min : value < min ? max : value;
