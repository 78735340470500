import React, { FC } from "react";

import { LoadingWithMessage } from "common/components/LoadingWithMessage";
import { Button, DefaultTheme, IconV2, Modal } from "common/components/ui";
import { ErrorModalLabels } from "common/wholesale-common/components/ErrorModalLabels";
import { useTheme } from "emotion-theming";
import {
  CenteredTitle,
  DescriptionText,
  IconContainer,
  TextContainer,
} from "common/components/BulkUpload/BulkUploadStyledComponents";
import { FormattedMessage } from "react-intl";
import { CancelConfirmationContent } from "./CancelConfirmationContent";
import { CancelModalProps } from "./CancelModalProps";
import { CancelTransferActionItems } from "./CancelTransferActionItems";

const ErrorModal: React.FC<{ onClose: () => void; errorModalLabels: ErrorModalLabels }> = ({
  onClose,
  errorModalLabels,
}) => {
  const theme = useTheme<DefaultTheme>();
  return (
    <>
      <TextContainer>
        <IconContainer>
          <IconV2 size="3x" type="exclamation-circle" color={theme.colors.RED[300]} />
        </IconContainer>
        <CenteredTitle as="h4" displayAs="h4">
          <FormattedMessage {...errorModalLabels.title} />
        </CenteredTitle>

        <DescriptionText>
          <FormattedMessage {...errorModalLabels.description} />
        </DescriptionText>
      </TextContainer>

      <CancelTransferActionItems columns="1fr" fullWidth>
        <Button secondary block onClick={onClose}>
          <FormattedMessage {...errorModalLabels.buttonDescription} />
        </Button>
      </CancelTransferActionItems>
    </>
  );
};

const CancelTransferModalContent = (props: CancelModalProps) => {
  if (props.cancelState?.loading) {
    return (
      <LoadingWithMessage
        loadingMessage={<FormattedMessage id="cancelTransferModal.loading.message" defaultMessage="Cancelling..." />}
      />
    );
  } else if (props.cancelState?.error) {
    return <ErrorModal onClose={props.onClose} errorModalLabels={props.errorModalLabels} />;
  } else {
    return <CancelConfirmationContent {...props} />;
  }
};
export const CancelTransferModal: FC<CancelModalProps> = (props) => {
  return (
    <Modal show={props.showModal} onClose={props.onClose} hasCloseButton={false}>
      <CancelTransferModalContent {...props} />
    </Modal>
  );
};
