import { IndeterminateCheckboxState } from "../IndeterminateCheckbox/indeterminateCheckboxState";
import React from "react";
import { OptionProps, components, SingleValueProps, MenuListComponentProps } from "react-select";
import { optionColors, SelectOptionType } from "./BaseSelect";
import { Checkbox } from "../Checkbox";
import styled from "@emotion/styled";
import { FormattedMessage } from "../FormattedMessage";
import { IndeterminateCheckbox } from "../IndeterminateCheckbox/IndeterminateCheckbox";
import { DefaultTheme } from "../shared";

const StyledCheckbox = styled(Checkbox)`
  pointer-events: none;
`;

const StyledIndeterminateCheckbox = styled(IndeterminateCheckbox)`
  pointer-events: none;
`;

export const CustomOption: React.FC<OptionProps<any>> = ({ children: optionChildren, selectProps, ...optionProps }) => {
  // check if the option is part of the value array
  const isSelectedOption =
    selectProps.value?.find(({ value }) => value === (optionProps.data as SelectOptionType).value) != null;

  // prevent clicks on the wrapper to avoid the menu closing itself
  return (
    <components.Option {...optionProps} selectProps={selectProps}>
      <div className="option-wrapper" onClick={(e) => e.preventDefault()}>
        <StyledCheckbox readOnly checked={isSelectedOption} label={optionChildren as any} />
      </div>
    </components.Option>
  );
};

export const CustomSingleValue: React.FC<SingleValueProps<any>> = ({ children, selectProps, ...controlProps }) => {
  const getControlMessage = () => {
    if (selectProps.value?.length === 1) {
      return (
        <FormattedMessage
          defaultMessage={(selectProps.value[0] as SelectOptionType).label}
          id="multiselect.customSingleValue.displayIndividualValue"
        />
      );
    }

    // if there's more than 1 value selected, but not all
    if (
      selectProps.value != null &&
      selectProps.value?.length > 1 &&
      selectProps.options != null &&
      selectProps.value?.length < selectProps.options?.length
    ) {
      return (
        <FormattedMessage
          defaultMessage={`${selectProps.value.length} ${selectProps.pluralEntityName} selected`}
          id="multiselect.customSingleValue.displaySelectedCount"
        />
      );
    }

    // if all are selected
    return <FormattedMessage defaultMessage={`All ${selectProps.pluralEntityName.toLowerCase()}`} id="id" />;
  };

  return (
    <components.SingleValue {...controlProps} selectProps={selectProps}>
      {getControlMessage()}
    </components.SingleValue>
  );
};

const StyledToggleAll = styled.div<{ isSelected: boolean }, DefaultTheme>(
  ({ theme, isSelected }) => `
    padding: 10px 12px;
    background-color: ${
      isSelected ? optionColors(theme).selected.backgroundColor : optionColors(theme).backgroundColor
    };

    :hover {
      background-color: ${
        isSelected ? optionColors(theme).selected.hoverBackgroundColor : optionColors(theme).hoverBackgroundColor
      };
    }
  `
);

export const CustomMenuList: React.FC<MenuListComponentProps<any>> = ({ children, selectProps, ...menuProps }) => {
  return (
    // prevent clicks on the wrapper to avoid the menu closing itself
    <components.MenuList {...menuProps} selectProps={selectProps}>
      {selectProps.inputValue === "" && (
        <StyledToggleAll
          className="toggle-all"
          onClick={selectProps.toggleAll}
          isSelected={selectProps.indeterminantState === IndeterminateCheckboxState.ALL_SELECTED}
        >
          <div className="option-wrapper" onClick={(e) => e.preventDefault()}>
            <StyledIndeterminateCheckbox
              readOnly
              state={selectProps.indeterminantState}
              label={`All ${selectProps.pluralEntityName.toLowerCase()}`}
            />
          </div>
        </StyledToggleAll>
      )}
      {children}
    </components.MenuList>
  );
};
