import { LotInformationError } from "inbounds/steps/lot-information/types/LotInformationError";
import { get, uniq } from "lodash/fp";
import { Product } from "@deliverr/commons-clients";
import { FieldErrors } from "react-hook-form";
import { LotInformationFormValues } from "../types/LotInformationFormValues";

export const getErrorsForDsku = (
  dsku: Product["dsku"],
  errors: FieldErrors<LotInformationFormValues>
): LotInformationError[] => {
  const dskuErrorMap = errors[dsku];
  return dskuErrorMap ? uniq(Object.values(dskuErrorMap).map(get("message"))) : [];
};
