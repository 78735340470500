import { defineMessages, MessageDescriptor } from "react-intl";
import { ProblemType } from "common/problems/ProblemType";
import { SellerProblemStatus } from "@deliverr/legacy-inbound-client";

type NCStatusLabelMap = Record<SellerProblemStatus, MessageDescriptor>;
const DEFAULT_NC_STATUS_LABEL_MAP: NCStatusLabelMap = defineMessages({
  [SellerProblemStatus.RESOLVED]: {
    id: "inbounds.nonCompliance.status.resolved",
    defaultMessage: "Resolved",
  },
  [SellerProblemStatus.CREATED]: {
    id: "inbounds.nonCompliance.status.created",
    defaultMessage: "Action Needed",
  },
  [SellerProblemStatus.ACTION_NEEDED]: {
    id: "inbounds.nonCompliance.status.actionNeeded",
    defaultMessage: "Action Needed",
  },
  [SellerProblemStatus.SUPPORT_CONTACTED]: {
    id: "inbounds.nonCompliance.status.supportContacted",
    defaultMessage: "With Flexport",
  },
});

export const NC_SHIPMENT_SUMMARY_STATUS_DISPLAY_TEXT_DEFAULT = defineMessages({
  default: {
    id: "inbounds.noncomplianceStatus.unknown",
    defaultMessage: "Unknown",
  },
});

export const getNCStatusLabel = (status: SellerProblemStatus, shipmentProblemType?: ProblemType) =>
  (shipmentProblemType && DEFAULT_NC_STATUS_LABEL_MAP[status]) ??
  NC_SHIPMENT_SUMMARY_STATUS_DISPLAY_TEXT_DEFAULT.default;
