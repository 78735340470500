import { storageClient } from "common/clients/instances";
import { logError, logStart } from "Logger";
import {
  transferCreateChooseDestinationType,
  transferCreateSetDraftFlag,
  transferCreateSetStorageTransferOrder,
  transferCreateStoreSteps,
} from "transfers/create/actions";

import { DskuQty } from "@deliverr/commons-clients";
import { EcomV2Step } from "transfers/create/ecomV2Steps";
import { FormattedMessage } from "react-intl";
import { Path } from "paths";
import React from "react";
import { SPThunkAction } from "common/ReduxUtils";
import { StorageTransferStatus } from "common/clients/storage/StorageTransferStatus/StorageTransferStatus";
import { TransferToEcomDetailActionTypes } from "./TransferToEcomDetailActionTypes";
import { getAvailableInventoryInEachStorageWarehouse } from "transfers/create/store/getAvailableInventoryInEachStorageWarehouse";
import { getSellerId } from "common/user/UserSelectors";
import history from "BrowserHistory";
import { setTransferDetailFailed } from "./TransferShipmentDetailAction";
import { toast } from "common/components/ui";
import { mapStorageOutboundItemToSelectedProductsCaseInfo } from "transfers/create/store/mapStorageOutboundItemToSelectedProductsCaseInfo";
import { mapStorageItemsToPackSkus } from "storage/packUtils/mapStorageItemsToPackSkus";
import { FeatureName, isFeatureOn } from "common/Split";
import { getItemProductAndInventoryDetails } from "common/wholesale-common/getItemProductAndInventoryDetails";
import { map, values } from "lodash";
import { generatePath } from "react-router-dom";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";

export const getTransferToEcomDetails =
  (storageTransferOrderId: string): SPThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const ctx = logStart({ fn: "getTransferToEcomDetails", storageTransferOrderId });
    try {
      dispatch({ type: TransferToEcomDetailActionTypes.TRANSFER_TO_ECOM_GET_DETAILS });
      const sellerId = getSellerId(getState());
      const isMultiCasePackEnabled = isFeatureOn(FeatureName.StorageMultiCasePack);

      const transferToEcomOrder = (await storageClient.getStorageTransferOrder(sellerId, storageTransferOrderId)).value;
      if (transferToEcomOrder.internalStatus === StorageTransferStatus.IN_DRAFT) {
        const storageOutboundItems = isMultiCasePackEnabled
          ? await mapStorageItemsToPackSkus(transferToEcomOrder.storageOutboundItems, dispatch)
          : transferToEcomOrder.storageOutboundItems;

        const { selectedProducts } = await getItemProductAndInventoryDetails(
          map(storageOutboundItems, ({ dsku, caseQty = 1, expectedQty = 0 }) => ({
            dsku,
            caseQty,
            qty: expectedQty,
          })),
          dispatch,
          isMultiCasePackEnabled,
          true
        );

        const dskuList: DskuQty[] = values(selectedProducts).map(({ dsku }) => ({ dsku }));
        await dispatch(getAvailableInventoryInEachStorageWarehouse(dskuList, isMultiCasePackEnabled));

        dispatch(
          transferCreateSetStorageTransferOrder(
            { ...transferToEcomOrder, storageOutboundItems },
            selectedProducts,
            mapStorageOutboundItemToSelectedProductsCaseInfo(storageOutboundItems)
          )
        );
        dispatch(transferCreateChooseDestinationType(TransferCreationTypes.Ecom));
        dispatch(transferCreateStoreSteps([EcomV2Step.SelectProduct]));
        dispatch(transferCreateSetDraftFlag(true));

        history.push(Path.transfersNew);
      } else if (transferToEcomOrder.internalStatus === StorageTransferStatus.ARCHIVED) {
        toast.error(
          <FormattedMessage
            id={"getTransferToEcomDetails.archivedTransfer.message"}
            defaultMessage={"Archived order. Please refer to latest recommendations."}
          />,
          {
            autoClose: 5000,
            toastId: "getTransferToEcomDetails.archivedTransfer",
          }
        );
        history.push(Path.transfersList);
      } else {
        history.push(generatePath(Path.transferDetails, { orderId: transferToEcomOrder.outboundOrderId }));
      }
      dispatch({
        type: TransferToEcomDetailActionTypes.TRANSFER_TO_ECOM_GET_DETAILS_SUCCESS,
        transferToEcomOrder,
      });

      if (!transferToEcomOrder) {
        return;
      }
    } catch (err) {
      logError(ctx, err, "Error getting transfer to ecom order");
      dispatch(setTransferDetailFailed());
    }
  };
