import React from "react";
import { useIntl } from "react-intl";
import { ManagedStep } from "common/components/StepContainer/ManagedStep";
import styled from "@emotion/styled";
import ProductChooser from "common/components/ProductChooser";
import { noop } from "lodash/fp";
import { useWholesalePickProductStep } from "./useWholesaleSelectProductsStep";
import { wholesaleCreateLabels } from "../wholesaleCreate.labels";
import { FeatureName, isFeatureOn } from "common/Split";
import {
  ReplenishmentAvailableInventoryErrorNotification,
  useReplenishmentAvailableInventoryErrorNotification,
} from "transfers/create/steps/common/ReplenishmentAvailableInventoryErrorNotification";

const ProductSearchContainer = styled.div`
  width: 80%;
`;

export const WholesalePickProductStep = () => {
  const { formatMessage } = useIntl();
  const { selectedProducts, addProduct, removeProduct, isNextDisabled, isMultiPackEnabled, dskuQuantities } =
    useWholesalePickProductStep();

  const { shouldShowErrorNotification, errorCode, isAvailableWarehouseLoading } =
    useReplenishmentAvailableInventoryErrorNotification(dskuQuantities);
  return (
    <ManagedStep
      title={formatMessage(wholesaleCreateLabels.steps.products.title)}
      isNextDisabled={isNextDisabled || shouldShowErrorNotification}
      isLoading={isAvailableWarehouseLoading}
    >
      <ProductSearchContainer>
        {shouldShowErrorNotification && <ReplenishmentAvailableInventoryErrorNotification errorCode={errorCode} />}
        <ProductChooser
          addProduct={addProduct}
          removeProduct={removeProduct}
          selectedProducts={selectedProducts}
          updateQty={noop as any}
          useCasePack={false}
          allowZeroInventorySelection={false}
          shouldShowAvailability
          isReserveStorage
          hideUnitsColumn
          shouldHideCreateProduct
          shouldHideFastTagAlert
          shouldIncludeBrandedPackaging={isFeatureOn(FeatureName.BrandedPackagingInboundCreation)}
          showOnlyPacks={isMultiPackEnabled}
        />
      </ProductSearchContainer>
    </ManagedStep>
  );
};
