import { LocalizedMessage } from "common/components/ui";
import { MessageDescriptor, defineMessages } from "react-intl";

export interface EstimatedTotalChargeColumn extends Record<string, unknown> {
  type?: LocalizedMessage;
  units: number;
  perUnitCharge: number;
  amount: number;
}

export const ESTIMATED_TOTAL_CHARGE_TABLE_LABELS: Record<keyof EstimatedTotalChargeColumn, MessageDescriptor> =
  defineMessages({
    type: {
      id: "inbounds.shipmentProblems.estimatedTotalChargeTable.type",
      defaultMessage: "Type",
    },
    units: {
      id: "inbounds.shipmentProblems.estimatedTotalChargeTable.units",
      defaultMessage: "Quantity",
    },
    perUnitCharge: {
      id: "inbounds.shipmentProblems.estimatedTotalChargeTable.perUnitCharge",
      defaultMessage: "Charge per unit",
    },
    totalCharge: {
      id: "inbounds.shipmentProblems.estimatedTotalChargeTable.totalCharge",
      defaultMessage: "Total charge",
    },
    totalReimbursement: {
      id: "inbounds.shipmentProblems.estimatedTotalTable.totalReimbursement",
      defaultMessage: "Total reimbursement",
    },
  });
