export enum WishError {
  ACCESS_TOKEN_ERROR = "ACCESS_TOKEN_ERROR",
  DUPLICATE_MERCHANT_ID = "DUPLICATE_MERCHANT_ID",
  DUPLICATE_SELLER_ID = "DUPLICATE_SELLER_ID",
  DUPLICATE_TOKEN = "DUPLICATE_TOKEN",
  REFRESH_ALREADY_IN_PROGRESS = "REFRESH_ALREADY_IN_PROGRESS",
  SELLER_NOT_FOUND = "SELLER_NOT_FOUND",
  NO_WISH_EXPRESS_WAREHOUSE = "NO_WISH_EXPRESS_WAREHOUSE",
  WISH_PRODUCT_IDENTIFIER_NOT_FOUND = "WISH_PRODUCT_IDENTIFIER_NOT_FOUND",
  VARIATION_NOT_FOUND = "VARIATION_NOT_FOUND",
}
