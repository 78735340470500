import styled from "@emotion/styled";
import { Currency, CurrencyFormat } from "common/components/Currency";
import React, { FC } from "react";
import { BasicTable, RootTableProps } from "common/components/BasicTable";
import { Appearance, DefaultTheme, ThemeProps } from "common/components/ui/shared/theme";
import { Text, TextProps } from "common/components/ui/Text";
import { FormattedMessage } from "react-intl";
import { CostTableSubTableConfig } from "./CostTableTypes";
import { Box, BoxReset, Stack } from "common/components/ui";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";

const StyledSubTable = styled(BasicTable)<RootTableProps, DefaultTheme>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL["500"]};

  th {
    font-weight: ${theme.font.weight.BOLD};
  }

  th:not(:first-of-type),
  td:not(:first-of-type) {
    text-align: right;
    padding-left: ${theme.spacing.S5};

    /* column width is based on content width */
    width: 1%;
    white-space: nowrap;
  }

  tbody tr:last-child {
    border-color: ${theme.colors.NEUTRAL["100"]};
  }
`
);

const TableContainer = styled.div<{ hasDiscount: boolean }, DefaultTheme>(
  ({ theme, hasDiscount }) => `
  background-color: ${theme.colors.NEUTRAL["40"]};
  padding: ${`${theme.spacing.S6} ${theme.spacing.S5}`};
  

  table:not(:first-of-type) {
    margin-top: ${theme.spacing.S5};
  }

  // Metro P&D table config needs discount column to be included so that amount row
  // can access the data, thus we hide it
  ${
    hasDiscount &&
    `
    table tr td:nth-of-type(4), table tr th:nth-of-type(4) {
      display: none;
    }
  `
  }
`
);

const TotalContainer = styled.div<{ hasAggregatedRow: boolean }, DefaultTheme>(
  ({ theme, hasAggregatedRow }) => `
  display: flex;
  margin-top: ${theme.spacing.S5};
  justify-content: space-between;
  align-items: center;
  ${!hasAggregatedRow && `border-top: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[100]}`}
`
);

const StorageTotalContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  margin-top: ${theme.spacing.S2};
  justify-content: space-between;
  align-items: center;
`
);

const CurrencyWrapper = styled(Text)<TextProps, DefaultTheme>(
  ({ theme }) => `
  font-size: ${theme.font.size.F5};
  font-family: ${theme.font.family.TITLE as string};
  margin-left: ${theme.spacing.S4};
`
);

const AggregationDescription = styled(Text)<ThemeProps<TextProps>>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL["300"]};
`
);

export interface CostTableProps {
  subTables: CostTableSubTableConfig[];
  totalAggregation?: number | string;
  hasAggregatedRow?: boolean; // used primarily to denote Metro P&D, whose table has a different UI
  storageTotalAggregation?: number;
  hasDiscount?: boolean;
  aggregationDescription?: JSX.Element | string;
  storagePromoMessage?: JSX.Element | string;
  totalCostLabel?: JSX.Element | string;
}

export const CostTable: FC<CostTableProps> = ({
  subTables,
  totalAggregation,
  hasAggregatedRow = true,
  storageTotalAggregation,
  hasDiscount = false,
  aggregationDescription,
  storagePromoMessage,
  totalCostLabel,
}) => {
  return (
    <TableContainer hasDiscount={hasDiscount} data-testid="cost-table">
      {subTables.map((tableProps, tableIndex) => {
        const hideHeaders: string[] | undefined =
          tableIndex > 0 ? ["quantity", "unitCost", "amount"] : (tableProps.hideHeaders as string[] | undefined);
        return (
          <StyledSubTable
            key={`costTable-subTable-${tableIndex}`}
            hasAggregatedRow={hasAggregatedRow}
            {...tableProps}
            hideHeaders={hideHeaders}
          />
        );
      })}
      {totalAggregation !== undefined && (
        <TotalContainer hasAggregatedRow={hasAggregatedRow}>
          <div>
            <Text bold>
              {totalCostLabel ?? (
                <FormattedMessage id="CostTable.estimatedTotalLabel" defaultMessage="Estimated total" />
              )}
            </Text>
            {aggregationDescription && (
              <AggregationDescription size="caption">{aggregationDescription}</AggregationDescription>
            )}
          </div>
          <CurrencyWrapper bold data-testid="cost-table-total">
            {typeof totalAggregation === typeof "string" ? (
              totalAggregation
            ) : (
              <Currency value={totalAggregation as number} currency={CurrencyFormat.USD} />
            )}
          </CurrencyWrapper>
        </TotalContainer>
      )}
      {storageTotalAggregation && (
        <StorageTotalContainer>
          <Stack direction="VERTICAL">
            <Text bold data-testid="cost-total-label">
              <FormattedMessage id="CostTable.estimatedTotalLabel" defaultMessage="Estimated total upon receiving" />
            </Text>
            <Box width="60%">
              <Text size="caption" appearance="INFO">
                <FormattedMessage
                  id="CostTable.estimatedTotalLabel"
                  defaultMessage="Your final invoice amount may change due to additional carrier pickup fees or unexpected cargo quantities, weights, and dimensions. You will be billed for monthly storage starting on the first day of the month. {learnMore}"
                  values={{
                    learnMore: <ElevioLearnMoreButton articleId={ElevioArticleId.ReserveStorage} />,
                  }}
                />
              </Text>
              {storagePromoMessage && (
                <BoxReset marginTop="S3" marginBottom={null}>
                  <Text appearance={Appearance.SUCCESS} size="caption" bold>
                    {storagePromoMessage}
                  </Text>
                </BoxReset>
              )}
            </Box>
          </Stack>

          <CurrencyWrapper bold data-testid="cost-table-total">
            <Currency value={storageTotalAggregation} currency={CurrencyFormat.USD} />
          </CurrencyWrapper>
        </StorageTotalContainer>
      )}
    </TableContainer>
  );
};
