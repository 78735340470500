import { useSelector } from "react-redux";
import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { selectSmbBookingCreationContext } from "./selectSmbBookingCreationContext";
import { useSmbAccelerateFeatureOn } from "common/featureFlags";
import { FeatureName } from "common/Split";

/**
 * This context is used to augment actions submitted to DataDog
 * if context variables are renamed, they will require an update to DataDog if they are being used as facets or measures
 */

export const useSmbBookingContext = (): void => {
  const isSmbRevampedOriginSearchFeatureOn = useSmbAccelerateFeatureOn(FeatureName.SmbAccelerateRevampedOriginSearch);
  const context = useSelector(selectSmbBookingCreationContext(isSmbRevampedOriginSearchFeatureOn));

  useEffect(() => {
    datadogRum.addRumGlobalContext("smbBookingCreate", context);
  }, [context]);
};
