import { ISplitState } from "@splitsoftware/splitio-redux/types/types";

export const SPLIT_INITIAL_STATE: ISplitState = {
  isReady: false,
  isTimedout: false,
  isDestroyed: false,
  isReadyFromCache: false,
  hasTimedout: false,
  lastUpdate: 0,
  treatments: {},
};
