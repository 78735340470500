import React from "react";
import { Route } from "react-router-dom";

import { Path } from "paths";
import { StorageInboundProgressNavBar } from "inbounds/components";
import { InboundStepContainer } from "inbounds/steps/InboundStepContainer";
import { SelectStorageInboundProducts } from "storage/inbounds/create/selectProducts";
import { CaseDimensionsAndWeights } from "storage/inbounds/create/dimensions";
import { StoragePathKey } from "./StoragePathKey";
import { StorageInboundStep } from "storage/inbounds/create/StorageInboundStep";
import { StorageFromAddress } from "storage/inbounds/create/fromAddress/StorageFromAddress";
import { ShipmentConfirmed } from "storage/inbounds/create/shipmentConfirmed/ShipmentConfirmed";
import { ConfirmShipment } from "storage/inbounds/create/confirmShipment/ConfirmShipment";
import { StorageAddProductBarcode } from "storage/inbounds/create/storageAddProductBarcode/StorageAddProductBarcode";
import { StorageLotInformation } from "storage/inbounds/create/lotTracking/StorageLotInformation";
import { StorageSelectShipping } from "storage/inbounds/create/selectShipping/StorageSelectShipping";
import { StorageAddPickupDetails } from "storage/inbounds/create/addPickUpDetails/StorageAddPickupDetails";
import { StorageDestinationV2 } from "storage/inbounds/create/selectDestinationV2/StorageDestinationV2";

export const storageInboundStepToPath: Record<StorageInboundStep, Path> = {
  [StorageInboundStep.SELECT_INBOUND_TYPE]: Path.inboundsSelectDestinationType,
  [StorageInboundStep.STORAGE_SELECT_PRODUCTS]: Path.inboundsStorageSelectProducts,
  [StorageInboundStep.STORAGE_ADD_LOT_INFORMATION]: Path.inboundsStorageLotInformation,
  [StorageInboundStep.STORAGE_ADD_PRODUCTS_BARCODE]: Path.inboundsStorageAddProductsBarcode,
  [StorageInboundStep.STORAGE_ADD_CASE_DIMENSIONS]: Path.inboundsStorageAddDimensions,
  [StorageInboundStep.STORAGE_FROM_ADDRESS]: Path.inboundsStorageFromAddress,
  [StorageInboundStep.STORAGE_DESTINATION_WAREHOUSE]: Path.inboundsStorageDestination,
  [StorageInboundStep.STORAGE_SELECT_SHIPPING]: Path.inboundsStorageSelectShipping,
  [StorageInboundStep.STORAGE_ADD_PICKUP_DETAILS]: Path.inboundsStorageAddPickupDetails,
  [StorageInboundStep.STORAGE_CONFIRM_SHIPMENT]: Path.inboundsStorageConfirmShipment,
  [StorageInboundStep.STORAGE_SHIPMENT_CONFIRMED]: Path.inboundsStorageShipmentConfirmed,
};

export const storageInboundStepHeaderRoutes = Object.values(storageInboundStepToPath)
  .filter((inboundPath) => inboundPath !== Path.inboundsSelectDestinationType)
  .map((inboundPath, i) => (
    <Route
      path={inboundPath}
      exact={true}
      key={`storage-inbound-${i}-header`}
      render={() => <StorageInboundProgressNavBar />}
    />
  ));

export const storageInboundStepDefinitions: {
  pathKey: StoragePathKey;
  component: JSX.Element;
}[] = [
  {
    pathKey: "inboundsStorageSelectProducts",
    component: <SelectStorageInboundProducts />,
  },
  {
    pathKey: "inboundsStorageLotInformation",
    component: <StorageLotInformation />,
  },
  {
    pathKey: "inboundsStorageAddProductsBarcode",
    component: <StorageAddProductBarcode />,
  },
  {
    pathKey: "inboundsStorageAddDimensions",
    component: <CaseDimensionsAndWeights />,
  },
  {
    pathKey: "inboundsStorageFromAddress",
    component: <StorageFromAddress />,
  },
  {
    pathKey: "inboundsStorageDestination",
    component: <StorageDestinationV2 />,
  },
  {
    pathKey: "inboundsStorageSelectShipping",
    component: <StorageSelectShipping />,
  },
  {
    pathKey: "inboundsStorageAddPickupDetails",
    component: <StorageAddPickupDetails />,
  },
  {
    pathKey: "inboundsStorageConfirmShipment",
    component: <ConfirmShipment />,
  },
  {
    pathKey: "inboundsStorageShipmentConfirmed",
    component: <ShipmentConfirmed />,
  },
  {
    pathKey: "inboundsStorageDraftRedirect",
    component: <SelectStorageInboundProducts />,
  },
];

export const storageInboundStepRoutes = storageInboundStepDefinitions.map(({ component, pathKey }) => (
  <Route
    path={Path[pathKey]}
    exact={true}
    component={() => <InboundStepContainer component={component} pathKey={pathKey} />}
    key={pathKey}
  />
));
