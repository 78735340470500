import { generatePath } from "react-router-dom";
import { createSelector } from "reselect";

import { inArray, isTrue } from "common/utils/helpers";
import {
  all,
  filter,
  find,
  flatMap,
  identity,
  isUndefined,
  orderBy,
  pipe,
  prop,
  propEq,
  propOr,
  uniq,
  useWith,
  sortBy,
  includes,
} from "lodash/fp";
import { RootState } from "RootReducer";
import { SalesChannelsState } from "./ChannelsReducers";
import { getSellerId, selectIsFederatedShopifyUser } from "common/user/UserSelectors";
import { listingConfigProp } from "common/ListingSolutionUtils";
import { salesChannelLoaderId } from "./ChannelsActions";
import { ListingSolution } from "common/clients/onboarding/ListingSolution";
import { FastTag } from "common/clients/fastTag/FastTag";
import { FastTagState } from "common/clients/fastTag/FastTagState";
import { FastTagType } from "common/clients/fastTag/FastTagType";
import { SalesChannel } from "common/clients/onboarding/SalesChannel/SalesChannel";
import { SalesChannelConnectionStatus } from "common/clients/onboarding/SellerSalesChannels/SalesChannelConnectionStatus";
import { SalesChannelType } from "common/clients/onboarding/SellerSalesChannels/SalesChannelType";
import { Path } from "paths";

const findChannel = (channel: ListingSolution) => find<SalesChannel>(propEq("salesChannelId", channel));
const findWalmartChannel = findChannel(ListingSolution.WALMARTDIRECT);
export const isConnected = propEq("connectionStatus", SalesChannelConnectionStatus.CONNECTED);

export const isOnlyWalmart = createSelector<RootState, SalesChannel[], boolean>(
  (state: RootState) => state.channels.salesChannels,
  (salesChannels) => {
    const getConnected = filter(isConnected, salesChannels);
    return getConnected.length === 1 && getConnected[0].salesChannelId === ListingSolution.WALMARTDIRECT;
  }
);

export const isWalmartActive = createSelector<RootState, SalesChannel[], boolean>(
  (state: RootState) => state.channels.salesChannels,
  pipe(findWalmartChannel, isConnected)
);

const allIsTrue = pipe(inArray, all(isTrue));
const isWalmartNum = (num: number) => pipe(findWalmartChannel, propEq("walmartVersion", num));

export const isWalmartVersion = (num: number) =>
  createSelector<RootState, boolean, SalesChannel[], boolean>(
    isWalmartActive,
    (state: RootState) => state.channels.salesChannels,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useWith(allIsTrue, [identity, isWalmartNum(num)])
  );

export const activeFastTagsByChannel = (channel: ListingSolution) =>
  createSelector<RootState, SalesChannel[], FastTag[]>(
    (state: RootState) => state.channels.salesChannels,
    (salesChannels) => {
      const fastTags: FastTag[] | [] = pipe(findChannel(channel), propOr([], "availableFastTags"))(salesChannels);
      return filter(propEq("state", FastTagState.ACTIVATED), fastTags);
    }
  );

export const activeFastTags = createSelector<RootState, SalesChannel[], FastTagType[]>(
  (state: RootState) => state.channels.salesChannels,
  (salesChannels) => {
    const allActiveTags = salesChannels.reduce<FastTagType[]>((acc, val) => {
      if (val.connectionStatus === SalesChannelConnectionStatus.CONNECTED) {
        val.availableFastTags.forEach((fastTag) => {
          if (fastTag.state === FastTagState.ACTIVATED) {
            acc.push(fastTag.name);
          }
        });
      }
      return acc;
    }, []);
    return uniq(allActiveTags);
  }
);

export const fastTagsForConnectedChannels = createSelector(
  (state: RootState) => state.channels.salesChannels,
  (salesChannels) =>
    flatMap<SalesChannel, FastTag>(
      ({ availableFastTags }) => availableFastTags,
      salesChannels.filter(({ connectionStatus }) => connectionStatus === SalesChannelConnectionStatus.CONNECTED)
    )
);

export const doubleConnectPotential = (integrationName: string) =>
  createSelector<RootState, SalesChannelsState, boolean>(
    (state: RootState) => state.channels,
    ({ directChannels, indirectChannels, salesChannels, recommendedChannels }) => {
      const allChannels = [...directChannels, ...indirectChannels, ...recommendedChannels];
      const isIncomingSalesChannelDirect =
        find((channel: SalesChannel) => channel.salesChannelId.toLowerCase() === integrationName, allChannels)?.type ===
        SalesChannelType.DIRECT;
      const onlyHasDirectIntegrations = isUndefined(
        find((channel) => channel.type !== SalesChannelType.DIRECT, salesChannels)
      );
      return !(onlyHasDirectIntegrations && isIncomingSalesChannelDirect);
    }
  );

export const getConnectedChannels = createSelector<RootState, SalesChannel[], SalesChannel[]>(
  (state: RootState) => state.channels.salesChannels,
  (salesChannels) => salesChannels.filter(isConnected)
);

export const getLowerCaseChannelName = (channel: SalesChannel) =>
  (listingConfigProp(channel.salesChannelId, "name") as string).toLowerCase();

export const getAllAvailableChannels = createSelector<RootState, SalesChannelsState, string, SalesChannel[]>(
  (state: RootState) => state.channels,
  getSellerId,
  ({ directChannels, indirectChannels, recommendedChannels }, sellerId) => {
    const sortedRecommendedChannels = orderBy<SalesChannel>(
      [prop("predictedRevenue"), prop("freePromoOffer"), getLowerCaseChannelName],
      ["desc", "desc", "asc"],
      recommendedChannels
    );
    const sortedOtherChannels = sortBy<SalesChannel>(getLowerCaseChannelName, [...directChannels, ...indirectChannels]);

    return [...sortedRecommendedChannels, ...sortedOtherChannels];
  }
);

export const getIntegrations = (state: RootState) => state.channels.salesChannels;

export const getIsPipe17Active = createSelector(
  (state: RootState) => state.channels.indirectChannels,
  pipe(findChannel(ListingSolution.PIPE17), isConnected)
);

export const getIsChannelsLoading = createSelector(
  (state: RootState) => state.loading.loaders,
  includes(salesChannelLoaderId)
);

export const findShopifyIntegrationV1 = createSelector(getIntegrations, findChannel(ListingSolution.SHOPIFY));

export const findShopifyIntegrationV2 = createSelector(getIntegrations, findChannel(ListingSolution.SHOPIFY_V2));

export const findShopifyIntegrationV3 = createSelector(getIntegrations, findChannel(ListingSolution.SHOPIFY_V3));

const selectShopifyListingSolutionToOnboard = createSelector(
  (state: RootState) => state.channels.directChannels,
  (channels) => {
    return channels.find((channel) => channel.salesChannelId === ListingSolution.SHOPIFY_V3)
      ? ListingSolution.SHOPIFY_V3
      : ListingSolution.SHOPIFY_V2;
  }
);

export const selectCreateCatalogPath = createSelector(
  selectIsFederatedShopifyUser,
  selectShopifyListingSolutionToOnboard,
  (isFederatedShopifyUser, shopifyListingSolution) =>
    isFederatedShopifyUser
      ? generatePath(Path.onboardingIntegrationSetup, {
          channel: shopifyListingSolution,
        })
      : Path.onboardingCreateCatalog
);

export const findWalmartIntegration = createSelector(getIntegrations, findChannel(ListingSolution.WALMARTDIRECT));

export const selectIntegrationBySlsUuid = createSelector(
  getIntegrations,
  (_: RootState, slsUuid: string) => slsUuid,
  (integrations, slsUuid) => integrations.find((integration) => integration.slsUuid === slsUuid)
);

export const selectIntegrationByChannelId = createSelector(
  getIntegrations,
  (_: RootState, channelId: string) => channelId,
  (integrations, channelId) => integrations.find((integration) => integration.salesChannelId === channelId)
);

export const getIntegrationName = (integration: SalesChannel, doesChannelHaveMultipleIntegrations: boolean): string => {
  return doesChannelHaveMultipleIntegrations && integration.storeName
    ? integration.storeName
    : (listingConfigProp(integration.salesChannelId, "name") as string);
};

export const getDoesChannelHaveMultipleIntegrations = (
  integrations: SalesChannel[],
  salesChannelName: string
): boolean => {
  const integrationsForChannel = integrations.filter((integration) => salesChannelName === integration.channelName!);
  return integrationsForChannel.length > 1;
};

export const selectIntegrationNameBySlsUuid = createSelector(
  getIntegrations,
  (_: RootState, slsUuid: string) => slsUuid,
  (integrations, slsUuid) => {
    const integration = integrations.find((currIntegration) => currIntegration.slsUuid === slsUuid);

    if (!integration) {
      return "";
    }

    const doesChannelHaveMultipleIntegrations = getDoesChannelHaveMultipleIntegrations(
      integrations,
      integration.channelName!
    );
    return getIntegrationName(integration, doesChannelHaveMultipleIntegrations);
  }
);
