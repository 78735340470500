import { BoxReset, Stack, Text, ThemeProps } from "common/components/ui";
import { TextAlign } from "common/components/ui/shared";
import styled from "@emotion/styled";

import React, { FC } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";

const CircularDiv = styled.div<ThemeProps>(
  ({ theme }) => `
    width: ${theme.spacing.S6};
    height: ${theme.spacing.S6};
    border-color: ${theme.colors.NEUTRAL[60]};
    background-color: ${theme.colors.NEUTRAL[60]};
    color: ${theme.colors.NEUTRAL[500]};
    border-radius: 50%;
    border-style: ${theme.border.type};
    border-width: ${theme.border.width.B0};
  `
);

export interface ShipmentInstructionItemProps {
  index: number;
  shipmentInstructionLabel: MessageDescriptor;
}
export const ShipmentInstructionItem: FC<ShipmentInstructionItemProps> = ({ index, shipmentInstructionLabel }) => {
  return (
    <Stack inline justifyContent="flex-start" direction="HORIZONTAL">
      <CircularDiv>
        <BoxReset margin={"S2"}>
          <Text textAlign={TextAlign.center} size="caption">
            {index + 1}
          </Text>
        </BoxReset>
      </CircularDiv>
      <BoxReset marginTop="S2">
        <Text size="label" bold>
          <FormattedMessage {...shipmentInstructionLabel} />
        </Text>
      </BoxReset>
    </Stack>
  );
};
