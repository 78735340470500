import { BarcodeResponseSubcode } from "@deliverr/commons-clients";
import { BarcodeFormat } from "common/components/ui/utils/BarcodeUtils";
import React from "react";
import { defineMessages, FormattedMessage, MessageDescriptor } from "react-intl";

export const barcodeGenericError = (
  <FormattedMessage id={"barcode.error.generic"} defaultMessage={`Unexpected barcode error.`} />
);

export const selectBarcodeFormatError = (
  <FormattedMessage id={"BarcodePageError.selectBarcodeFormat"} defaultMessage={`Select a barcode format.`} />
);

export const duplicateNewBarcodeInputsError = (
  <FormattedMessage
    id="duplicateNewBarcodes"
    defaultMessage={"This barcode is identical to another inputted barcode. Barcode values must be unique."}
  />
);

export const barcodeFormatToInvalidBarcodeError = {
  [BarcodeFormat.FNSKU]: (
    <FormattedMessage
      id={"BarcodePageError.invalidBarcodeFNSKU"}
      defaultMessage={`FNSKUs must begin with an 'X00' or 'B', and must contain exactly 10 characters.`}
    />
  ),
  [BarcodeFormat.UPC]: (
    <FormattedMessage
      id={"BarcodePageError.invalidBarcodeUPC"}
      defaultMessage={`This barcode value failed Flexport's barcode checksum algorithm.
Review the entered value for typos or letters instead of numbers.`}
    />
  ),
};

export const barcodeServiceErrors: Record<string, MessageDescriptor> = defineMessages({
  [BarcodeResponseSubcode.INVALID_BARCODE]: {
    id: "barcode.error.invalidBarcode",
    defaultMessage: "Invalid barcode.",
  },
  [BarcodeResponseSubcode.DUPLICATE]: {
    id: "barcode.error.duplicate",
    defaultMessage: `This barcode is currently being utilized by another product.
Since Flexport does not support co-mingling, please resubmit with a different barcode.`,
  },
  [BarcodeResponseSubcode.RESERVED_BY_ANOTHER_SELLER]: {
    id: "barcode.error.reservedByAnotherSeller",
    defaultMessage: "This barcode has been used by another seller. Please use a unique barcode.",
  },
  [BarcodeResponseSubcode.INVALID_TYPE]: {
    id: "barcode.error.invalidType",
    defaultMessage: "Invalid barcode type.",
  },
  [BarcodeResponseSubcode.NO_BARCODE_SLOTS_REMAINING]: {
    id: "barcode.error.noBarcodeSlotsRemaining",
    defaultMessage: "No barcode slots remaining.",
  },
  [BarcodeResponseSubcode.ALREADY_ASSIGNED]: {
    id: "barcode.error.noBarcodeSlotsRemaining",
    defaultMessage:
      "This barcode is currently being utilized by another product. Since Flexport does not support co-mingling, please resubmit with a different barcode.",
  },
});
