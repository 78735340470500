import { ExternalDestinationType, WholesaleOrderDTO } from "@deliverr/wholesale-client";
import { extractFbaShipmentId } from "./extractFbaShipmentId";

export function isFbaIntegration(wholesaleOrder: WholesaleOrderDTO): boolean {
  return (
    wholesaleOrder.externalDestinationType === ExternalDestinationType.FBA &&
    (wholesaleOrder.createdAutomatically ?? false)
  );
}

export function isFbaMultipleShipments(wholesaleOrder: WholesaleOrderDTO): boolean {
  return isFbaIntegration(wholesaleOrder) && (wholesaleOrder.shipments?.length ?? 0) > 1;
}

export function getConcatenatedFbaShipmentIdsFromWholesaleOrder(
  wholesaleOrder: WholesaleOrderDTO | undefined
): string | undefined {
  return wholesaleOrder?.shipments
    ?.map((shipment) => extractFbaShipmentId(shipment))
    .filter((fbaShipmentId) => fbaShipmentId !== undefined)
    .join(", ");
}
