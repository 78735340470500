import { InvalidOrderItemDto } from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { SPThunkAction } from "common/ReduxUtils";
import { updateInvalidOrderItem } from "./TransferCreateActions";
import { selectTransferCreate } from "../store/TransferCreateSelectors";
import { toast } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import React from "react";

export const resolveInvalidItem =
  (invalidItem: InvalidOrderItemDto): SPThunkAction =>
  async (dispatch, getState) => {
    const { replenishmentOrder } = selectTransferCreate(getState());
    const orderId = replenishmentOrder?.orderId;

    try {
      await replenishmentClient.invalidItemResolution(orderId!, {
        id: invalidItem.id!,
        resolution: invalidItem.resolution!,
        resolvedDsku: invalidItem.resolvedDsku!,
        resolvedTotalUnits: invalidItem.resolvedTotalUnits!,
      });
      dispatch(updateInvalidOrderItem({ ...invalidItem }));
    } catch (err) {
      toast.error(
        <FormattedMessage
          id="replenishment.invalidorder.resolve.error"
          defaultMessage="Error resolving invalid item. Please try again later"
        />,
        {
          autoClose: 5000,
          toastId: "getSalesChannels",
        }
      );
    }
  };
