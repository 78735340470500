import React from "react";
import { FormattedMessage } from "react-intl";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";

export const FBAProductValidationWarningMessage: React.FunctionComponent<{}> = () => {
  return (
    <FormattedMessage
      id="transfers.create.fba.productValidation"
      defaultMessage="This product exceeds Amazon's product requirements. {learnMore}."
      values={{
        learnMore: <ElevioLearnMoreButton articleId={ElevioArticleId.ShippingRestrictionsForFbaInbounds} />,
      }}
    />
  );
};
