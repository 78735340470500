import flatMap from "lodash/flatMap";

export const fetchKitComponents = (planItems) => {
  return flatMap(planItems, (item) => {
    if (!item.kitResponse) {
      return [];
    }
    return item.kitResponse.kitComponents.map((component) => component.dsku);
  });
};
