/** @module common/date/DateFormat */
import { Brand } from "utility-types";

export type DateFormat<T extends string = string> = Brand<"date-format", T>;

export const SlashDate = "M/d/yyyy" as DateFormat<"M/d/yyyy">;

export const FullSlashDate = "MM/dd/yyyy" as DateFormat<"MM/dd/yyyy">;

export const CommaDate = "MMM d, yyyy" as DateFormat<"MMM d, yyyy">;

// E.g. Jan 1
export const CommaDateNoYear = "MMM d" as DateFormat<"MMM d">;

export const FullCommonDate = "MMMM d, yyyy" as DateFormat<"MMMM d, yyyy">;

export const TimeWithTz = "h:mm aaa z" as DateFormat<"h:mm aaa z">;

export const TimeWithoutTz = "h:mm aaa" as DateFormat<"h:mm aaa">;

export const TimeWithTzCap = "h:mm a z" as DateFormat<"h:mm a z">;

export const VanillaTime = "h:mm a" as DateFormat<"h:mm a">;

export const MilitaryTime = "HH:mm" as DateFormat<"HH:mm">;

export const ShortDate = "M/d" as DateFormat<"M/d">;

export const ShortDateTimeWithTz = "M/d - haaa z" as DateFormat<"M/d - haaa z">;

export const ISODate = "yyyy-MM-dd" as DateFormat<"yyyy-MM-dd">;

export const DayOfWeekAndMonth = "EEEE, MMMM do" as DateFormat<"EEEE, MMMM do">;

export const FileNameTimestamp = "yyyyMMdd'T'HHmmss" as DateFormat<"yyyyMMdd[T]HHmmss">;

export const ShortDayMonth = "EEE, MMM d" as DateFormat<"EEE, MMM d">;

// E.g. Jan 1, 2021
export const ShortMonthDateYear = "MMM d, yyyy" as DateFormat<"MMM d, yyyy">;

// E.g. 06-15-2021
export const DashFullDate = "MM-dd-yyyy" as DateFormat<"MM-dd-yyyy">;

// E.g. Jan 2021
export const MonthAndYear = "MMM yyyy" as DateFormat<"MMM yyyy">;

// E.g EST
export const TimeZone = "z" as DateFormat<"z">;

// E.g. January 1, 2022, 12:01 AM
export const FullMonthDateAndTime = "PPp" as DateFormat<"PPp">;

// I.e. Thursday, Jul 4, 1776 at 12:30pm
export const TimelineDate = "EEEE, LLLL d, yyyy 'at' hh:mm aaa";
