export enum FacebookConnectActionTypes {
  FACEBOOK_ACTIVATION_ERROR = "FACEBOOK_ACTIVATION_ERROR",
  FACEBOOK_AD_ACCOUNTS_RECEIVED = "FACEBOOK_AD_ACCOUNTS_RECEIVED",
  FACEBOOK_AD_ACCOUNT_SELECTED = "FACEBOOK_AD_ACCOUNT_SELECTED",
  FACEBOOK_LOGIN_STATUS_RECEIVED = "FACEBOOK_LOGIN_STATUS_RECEIVED",
  FACEBOOK_PAGES_RECEIVED = "FACEBOOK_PAGES_RECEIVED",
  FACEBOOK_PAGE_SELECTED = "FACEBOOK_PAGE_SELECTED",
  FACEBOOK_PERMISSIONS_CHECKED = "FACEBOOK_PERMISSIONS_CHECKED",
  FACEBOOK_SELLER_REGISTRATION_COMPLETE = "FACEBOOK_SELLER_REGISTRATION_COMPLETE",
  FACEBOOK_INSTAGRAM_ADS_TOGGLE_SET = "FACEBOOK_INSTAGRAM_ADS_SET",
  FACEBOOK_INSTAGRAM_ACCOUNTS_RECEIVED = "FACEBOOK_INSTAGRAM_ACCOUNTS_RECEIVED",
  FACEBOOK_INSTAGRAM_ACCOUNT_SELECTED = "FACEBOOK_INSTAGRAM_ACCOUNT_SELECTED",
  FACEBOOK_PROFILE_INFO_RECIEVED = "FACEBOOK_PROFILE_INFO_RECIEVED",
  FACEBOOK_PIXELS_RECEIVED = "FACEBOOK_PIXELS_RECEIVED",
  FACEBOOK_PIXEL_SELECTED = "FACEBOOK_PIXEL_SELECTED",
  FACEBOOK_AD_SETS_CONFIRMATION_TOGGLE = "FACEBOOK_AD_SETS_CONFIRMATION_TOGGLE",
  FACEBOOK_AD_SET_CHECK_COMPLETED = "FACEBOOK_AD_SET_CHECK_COMPLETED",
  FACEBOOK_GENERATING_AD_SETS = "FACEBOOK_GENERATING_AD_SETS",
}
