import { BarcodeResponse, BarcodeResponseCode, BarcodeResponseSubcode } from "@deliverr/commons-clients";
import { removeAllEmTags } from "common/StringUtils";
import { filter, find, keys } from "lodash";
import { BarcodeEditCollection } from "./BarcodeEditReducer";
import { BarcodeResponseCollection, BarcodeSubmitResult } from "./useBarcodeService";

export interface BarcodeCollections {
  [dsku: string]: BarcodeEditCollection | undefined;
}

export interface SerializedBarcodes {
  [dsku: string]: string[];
}

export interface BarcodeServiceCallback {
  success: boolean;
  barcodesCollection?: BarcodeEditCollection;
}

export const serializeBarcodes = (dsku: string, barcodesCollection: BarcodeEditCollection | undefined) =>
  barcodesCollection
    ? {
        [dsku]: barcodesCollection
          .filter(({ locked, value }) => !locked && value !== "")
          .map<string>(({ value }) => removeAllEmTags(value)),
      }
    : null;

export const bulkSerializeBarcodes = (barcodeCollections: BarcodeCollections) =>
  Object.keys(barcodeCollections).reduce<SerializedBarcodes>((accumulator, dsku) => {
    const serializedBarcodeList = serializeBarcodes(dsku, barcodeCollections[dsku]) ?? {};

    return serializedBarcodeList[dsku]?.length
      ? {
          ...accumulator,
          ...serializedBarcodeList,
        }
      : accumulator;
  }, {});

const barcodeSaveDefaultValue = {};

export const serializeBarcodeServiceErrors = (barcodeSubmitResult: BarcodeSubmitResult) => {
  const { value = barcodeSaveDefaultValue, loading: isLoading } = barcodeSubmitResult;

  if (isLoading || value === true) {
    return barcodeSaveDefaultValue;
  }

  const listOfKeys = keys(value);

  return filter(listOfKeys, (dsku) => find(value[dsku], ["code", BarcodeResponseCode.ERROR])).reduce<
    BarcodeResponseCollection
  >((response, dsku: string) => {
    response[dsku] = value[dsku];
    return response;
  }, {});
};

interface DuplicatedBarcodeResponseItem {
  dsku?: string;
  barcode: string;
}

export const createDuplicatedBarcodeResponse = (serviceResponse: DuplicatedBarcodeResponseItem[]) =>
  serviceResponse.map<BarcodeResponse>((response) => ({
    barcode: response.barcode,
    code: BarcodeResponseCode.ERROR,
    subcode: BarcodeResponseSubcode.DUPLICATE,
  }));
