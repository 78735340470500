import React, { FC, MouseEvent } from "react";
import { defaultTheme, IconV2 } from "common/components/ui";
import { CarrierAccount } from "@deliverr/replenishment-client";
import styled from "@emotion/styled";
import { CarrierAccountModalMode } from "../modals/CarrierAccountModal";
import { FlexDiv } from "common/components/FlexDiv";

export interface CarrierAccountActionsProps {
  onOpenModal: (modalMode: CarrierAccountModalMode, carrierAccount?: CarrierAccount) => void;
  carrierAccount: CarrierAccount;
}

const EditIconButton = styled(IconV2)`
  margin-right: ${defaultTheme.spacing.S2};
  color: ${defaultTheme.colors.NEUTRAL[300]};
`;

const DeleteIconButton = styled(IconV2)`
  color: ${defaultTheme.colors.NEUTRAL[300]};
`;

export const CarrierAccountSelectActions: FC<CarrierAccountActionsProps> = ({ onOpenModal, carrierAccount }) => {
  const handleEdit = (evt: MouseEvent) => {
    evt.stopPropagation();
    onOpenModal(CarrierAccountModalMode.UPDATE, carrierAccount);
  };

  const handleDelete = (evt: MouseEvent) => {
    evt.stopPropagation();
    onOpenModal(CarrierAccountModalMode.DELETE, carrierAccount);
  };

  return (
    <FlexDiv>
      <EditIconButton type="pen" data-testid="edit-icon-button" onClick={handleEdit} />
      <DeleteIconButton type="trash" data-testid="delete-icon-button" onClick={handleDelete} />
    </FlexDiv>
  );
};
