import FsLightbox from "fslightbox-react";
import React, { FC, memo } from "react";

import "./Lightbox.less";

export interface LightboxState {
  slideIdx: number;
  toggler: boolean;
}

export interface LightboxProps {
  /**
   * Any regular unique key value to identify a given image set
   * This is necessary for fslightbox to cache image sets correctly
   */
  key: string | number;
  /**
   * Absolute paths for image source URLs
   * Note that, any time this changes, you need to update the "key" prop as well
   * to ensure fslightbox clears its cache
   */
  sources: string[];
  state: LightboxState;
}

export const Lightbox: FC<LightboxProps> = memo(({ sources, state }) => {
  const { slideIdx, toggler } = state;
  const slide = slideIdx + 1; // fslightbox uses 1-based indexing for slides

  if (sources.length < slide) {
    return null;
  }

  return <FsLightbox slide={slide} sources={sources} toggler={toggler} type="image" />;
});
