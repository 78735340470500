import React from "react";
import { transfersShipmentCreateLabels } from "../../../transfers/create/transfersShipmentCreate.labels";
import { useIntl } from "react-intl";
import { ManagedStep } from "common/components/StepContainer";
import { Box, Stack } from "common/components/ui";
import { useTransferSelectUnitPrepStep } from "./useTransferSelectUnitPrepStep";
import { SelectPrepOption } from "./SelectPrepOption";
import { Divider } from "inbounds/createShipment/steps/priceViewStep/selectShipping/selectShippingFlow/components/Divider";
import { ExternalLink } from "common/components/ExternalLink";
import { getHelpCenterCategoryUrl } from "common/support/helpCenter";
import { PrepOption } from "@deliverr/business-types";

const PREP_HELP_CENTRE_CATEGORY = "4478542544535-Prep-Services";

export const TransferSelectPrepTypeStep = () => {
  const { formatMessage } = useIntl();
  const {
    isNextDisabled,
    isLoading,
    prepOption,
    onPrepTypeChange,
    boxHandlingFee,
    palletHandlingFee,
    numOfCases,
    numOfUnits,
    shippingPrepFee,
    unitPrepFee,
    boxLabelingDiscount,
    unitLabelingDiscount,
  } = useTransferSelectUnitPrepStep();

  return (
    <ManagedStep
      title={formatMessage(transfersShipmentCreateLabels.steps.fbaPrepServices.title)}
      subtitle={formatMessage(transfersShipmentCreateLabels.steps.fbaPrepServices.subtitle, {
        a: (linkText: string) => (
          <ExternalLink href={getHelpCenterCategoryUrl(PREP_HELP_CENTRE_CATEGORY)}>{linkText}</ExternalLink>
        ),
      })}
      isNextDisabled={isNextDisabled}
      isLoading={isLoading}
    >
      <Box width="85%">
        <Stack gap="S8">
          <SelectPrepOption
            prepOption={PrepOption.SHIPPING_ONLY}
            onChange={onPrepTypeChange}
            currentValue={prepOption}
            costPerUnit={shippingPrepFee}
            numOfBoxes={numOfCases}
            boxHandlingFee={boxHandlingFee}
            palletHandlingFee={palletHandlingFee}
            boxLabelingDiscount={boxLabelingDiscount}
          />
          <Divider />
          <SelectPrepOption
            prepOption={PrepOption.UNIT_PREP}
            onChange={onPrepTypeChange}
            currentValue={prepOption}
            costPerUnit={unitPrepFee}
            numOfUnits={numOfUnits}
            unitLabelingDiscount={unitLabelingDiscount}
          />
        </Stack>
      </Box>
    </ManagedStep>
  );
};
