import { SalesChannelConnectionStatus } from "common/clients/onboarding/SellerSalesChannels/SalesChannelConnectionStatus";
import { Pill, PillColors } from "common/components/Pill";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

export enum RmtConnectionStatus {
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
}

const messages: Record<RmtConnectionStatus, JSX.Element> = {
  [RmtConnectionStatus.CONNECTED]: <FormattedMessage id="rmt.status.connected" defaultMessage="Connected" />,
  [RmtConnectionStatus.DISCONNECTED]: <FormattedMessage id="rmt.status.disconnected" defaultMessage="Disconnected" />,
};

const statusPillColors: Record<RmtConnectionStatus, PillColors> = {
  [SalesChannelConnectionStatus.CONNECTED]: PillColors.GREEN,
  [SalesChannelConnectionStatus.DISCONNECTED]: PillColors.GRAY,
};

interface RmtStatusPillProps {
  status: RmtConnectionStatus;
}

export const RmtStatusPill: FC<RmtStatusPillProps> = ({ status }) => {
  return <Pill color={statusPillColors[status]}>{messages[status]}</Pill>;
};
