import { Text, Box, BoxReset } from "common/components/ui";
import { ExternalLink } from "common/components/ExternalLink";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { usePricingSummary } from "./usePricingSummary";
import { CostTable } from "common/components/CostTable";

export const PricingSummary: FC<{ isDeliverrFreight: boolean }> = ({ isDeliverrFreight }) => {
  const { costTableProps } = usePricingSummary(isDeliverrFreight);
  return (
    <Box width="80%" marginBottom={null}>
      <BoxReset marginBottom="S1">
        <Text bold size="caption">
          <FormattedMessage
            id="storage.inbounds.create.confirmShipment.pricingSummary.title"
            defaultMessage="Pricing summary"
          />
        </Text>
      </BoxReset>
      <br />
      <CostTable {...costTableProps} />

      <BoxReset marginTop="S4">
        <Text size="caption">
          <FormattedMessage
            id="storage.inbounds.create.confirmShipment.pricingSummary.terms"
            defaultMessage="By continuing, you accept Flexport's {storageTermsAndConditionsLink}. You will not be charged until we receive your shipment."
            values={{
              storageTermsAndConditionsLink: (
                <ExternalLink href={"https://www.flexport.com/terms-and-conditions-3/"}>
                  <FormattedMessage
                    id="storage.inbounds.create.confirmShipment.pricingSummary.terms.title"
                    defaultMessage="terms and conditions"
                  />
                </ExternalLink>
              ),
            }}
          />
        </Text>
      </BoxReset>
    </Box>
  );
};
