import React from "react";
import { ReplenishmentShipmentStatusPill } from "transfers/create/steps/common/replenishment/details/ReplenishmentShipmentStatusPill";
import { PrepStatusPill } from "prep/PrepStatusPill";
import { ParcelListStatus } from "parcel/screens/list/ParcelListTable/ParcelListStatus";
import { ShipmentStatusPill } from "inbounds/steps/ship/ShipmentStatusPill";
import { Anchor, IconV2Types } from "../../ui";
import { isAmazonTransfer, isFlexportEcomTransfer, isWalmartTransfer } from "transfers/listV2/ReplenishmentListUtils";
import { ServiceNames } from "./searchIndexes";
import { ShipmentStatus } from "@deliverr/replenishment-client";
import { PrepJobResponseStatus } from "@deliverr/prep-service-client";
import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";
import { OrderListStatus } from "order/listV2/OrderListTable/OrderListStatus/OrderListStatus";
import { generateFlexportTrackingUrl } from "parcel/utils/generateFlexportTrackingUrl";

export const getReplenishmentOrderName = (item) => {
  let message: string;

  if (isFlexportEcomTransfer(item)) {
    message = "Transfer to Flexport Ecom";
  } else if (isAmazonTransfer(item)) {
    message = "Transfer to Amazon";
  } else if (isWalmartTransfer(item)) {
    message = "Transfer to Walmart";
  } else {
    message = "Transfer";
  }

  return message;
};

export const serviceIconMap: Record<ServiceNames, IconV2Types> = {
  [ServiceNames.REPLENISHMENT]: "sync",
  [ServiceNames.INBOUND]: "inbounds",
  [ServiceNames.PREP]: "gift",
  [ServiceNames.PARCEL]: "truck",
  [ServiceNames.WHOLESALE]: "shopping-bag",
  [ServiceNames.ECOM]: "shopping-bag",
  [ServiceNames.INVENTORY]: "inventory",
};

export const getTitleText = (serviceName: ServiceNames, result) => {
  switch (serviceName) {
    case ServiceNames.REPLENISHMENT:
      return getReplenishmentOrderName(result);
    case ServiceNames.INBOUND: {
      const shippingPlanName = result?.shippingPlanName ?? "";
      return shippingPlanName;
    }
    case ServiceNames.PREP: {
      const dpId = result?.dpId ?? "dpId";
      return dpId;
    }
    case ServiceNames.PARCEL: {
      const customerName = result?.customerName ?? "";
      return customerName;
    }
    case ServiceNames.WHOLESALE: {
      const destinationName = result?.shipments?.[0]?.destinationAddress?.name ?? "";
      return destinationName;
    }
    case ServiceNames.INVENTORY: {
      const productName = result?.raw?.name ?? result?.name ?? "";
      return productName;
    }
    case ServiceNames.ECOM: {
      const customer = result?.raw?.originalShippingAddress?.name ?? result?.raw?.cleanShippingAddress?.name ?? "";
      return customer;
    }
    default:
      return "";
  }
};

export const getSubtitleText = (serviceName: ServiceNames, result) => {
  switch (serviceName) {
    case ServiceNames.REPLENISHMENT:
    case ServiceNames.WHOLESALE: {
      const orderId = result?.orderId ?? "";
      return orderId;
    }
    case ServiceNames.INBOUND: {
      const shippingPlanId = result?.shippingPlanId ?? "";
      return shippingPlanId;
    }
    case ServiceNames.PREP: {
      const sourceId = result?.sourceId ?? "";
      return sourceId;
    }
    case ServiceNames.PARCEL: {
      const trackingCode = result?.trackingCode;
      const handleClick = (event) => {
        event.stopPropagation();
      };
      return (
        <Anchor
          href={trackingCode ? generateFlexportTrackingUrl(trackingCode) : "#"}
          appearance="INFO"
          onClick={handleClick}
        >
          {trackingCode}
        </Anchor>
      );
    }
    case ServiceNames.INVENTORY: {
      const msku = result?.raw?.msku ?? result?.msku ?? "";
      return msku;
    }
    case ServiceNames.ECOM: {
      const marketplaceOrderId = result?.marketplaceOrderId ?? result?.raw?.marketplaceOrderId ?? "";
      return marketplaceOrderId;
    }
    default:
      return "";
  }
};

export const generateStatusPill = (serviceName: ServiceNames, result) => {
  switch (serviceName) {
    case ServiceNames.REPLENISHMENT:
    case ServiceNames.WHOLESALE: {
      const status = result?.status ?? ShipmentStatus.CREATED;
      return <ReplenishmentShipmentStatusPill status={status} />;
    }
    case ServiceNames.PARCEL:
      return <ParcelListStatus parcel={result} />;
    case ServiceNames.PREP: {
      const prepStatus = result?.status ?? PrepJobResponseStatus.INITIALIZED;
      return <PrepStatusPill prepStatus={prepStatus} />;
    }
    case ServiceNames.INBOUND: {
      const shipmentStatus = result?.shipmentStatus ?? InboundShipmentStatus.NEED_PACKAGE_DETAILS;
      return <ShipmentStatusPill shipmentStatus={shipmentStatus} />;
    }
    case ServiceNames.ECOM: {
      return <OrderListStatus order={result} />;
    }
    default:
      return null;
  }
};
