import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { StyledAnchor } from "../../ui/StyledAnchor";
import { fbaIntegrationCommonLabels } from "../../../wholesale/common/FbaIntegrationCommon.labels";

export const FbaMultipleDestination: FC<{
  onViewMoreClick?: (e) => void;
}> = ({ onViewMoreClick }) => {
  const labels = fbaIntegrationCommonLabels.shipmentDetails;

  return (
    <>
      <FormattedMessage {...labels.fbaDestination} />
      <br />
      <FormattedMessage {...labels.multipleLocation} />
      <br />
      <StyledAnchor data-testid="view-more-fba-shipments" onClick={onViewMoreClick}>
        <FormattedMessage {...labels.viewDetails} />
      </StyledAnchor>
    </>
  );
};
