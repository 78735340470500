import { defineMessages } from "react-intl";

export const PREP_LIST_LABELS = defineMessages({
  search: {
    placeHolderMessage: {
      id: "prep.list.search.placeholder",
      defaultMessage: "Search prep jobs",
    },
  },
  emptyList: {
    noPrepJobsTitle: {
      id: "prep.list.noPrepJobsTitle",
      defaultMessage: "No prep jobs created yet",
    },
    noResultsTitle: {
      id: "prep.list.noResultsTitle",
      defaultMessage: "Prep not found",
    },
    noPrepJobsContent: {
      id: "prep.list.noPrepJobsContent",
      defaultMessage: "You currently don't have any prep jobs created, start by visiting the {helpLink}.",
    },
    prepHelpCenter: {
      id: "prep.list.prepHelpCenter",
      defaultMessage: "Prep Help Center",
    },
    noResultsContent: {
      id: "prep.list.noResultsContent",
      defaultMessage: "No results found",
    },
  },
  list: {
    createdAt: {
      id: "prep.prepList.dpId.date",
      defaultMessage: "Created {updatedAt}",
    },
    dpIdHead: {
      id: "prep.prepList.dpIdHead",
      defaultMessage: "Prep ID",
    },
    prepServicesHead: {
      id: "prep.prepList.prepServicesHead",
      defaultMessage: "Prep Services",
    },
    sourceIdHead: {
      id: "prep.prepList.sourceIdHead",
      defaultMessage: "Source ID",
    },
    originHead: {
      id: "prep.prepList.originHead",
      defaultMessage: "Origin",
    },
    destinationHead: {
      id: "prep.prepList.destinationHead",
      defaultMessage: "Destination",
    },
    statusHead: {
      id: "prep.prepList.statusHead",
      defaultMessage: "Prep Status",
    },
  },
  sourceLink: {
    emptySourceId: {
      id: "prep.sourceLink.emptySourceId",
      defaultMessage: "--",
    },
    shippingPlan: {
      id: "prep.sourceLink.shippingPlan",
      defaultMessage: "Shipping Plan",
    },
    wholesaleShipment: {
      id: "prep.sourceLink.wholesaleShipment",
      defaultMessage: "Wholesale Order",
    },
    replenishment: {
      id: "prep.sourceLink.replenishment",
      defaultMessage: "Replenishment",
    },
    externalTask: {
      id: "prep.sourceLink.externalTask",
      defaultMessage: "Other",
    },
  },
  origin: {
    shippingPlan: {
      id: "prep.origin.shippingPlan",
      defaultMessage: "Merchant",
    },
    wholesaleShipment: {
      id: "prep.origin.wholesaleShipment",
      defaultMessage: "Reserve Storage",
    },
    externalTask: {
      id: "prep.origin.externalTask",
      defaultMessage: "--",
    },
  },
  destination: {
    d2cNetwork: {
      id: "prep.destination.d2cNetwork",
      defaultMessage: "Ecom Forwarding",
    },
    storage: {
      id: "prep.destination.storage",
      defaultMessage: "Reserve Storage",
    },
  },
});
