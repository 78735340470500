import styled from "@emotion/styled";
import { DistributionChannel } from "@deliverr/replenishment-client";
import { DeliverrAddress } from "@deliverr/wholesale-client";
import { FormatAddress } from "common/components/Address";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import { ManagedStep } from "common/components/StepContainer";
import { Box, Text } from "common/components/ui";
import { SharedConfirmShipmentSummary } from "common/wholesale-common/components/SharedConfirmShipmentSummary";
import { SummaryErrorNotification } from "common/wholesale-common/components/SummaryErrorNotification";
import { WholesaleErrorType } from "common/wholesale-common/utils/WholesaleErrorTypes";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { TransferConfirmPricingSummary } from "../../TransferConfirmPricingSummary";
import { replenishmentShipmentCreateLabels } from "../replenishmentShipmentCreate.labels";
import { ReplenishmentOrderMultipleDestination } from "./ReplenishmentOrderMultipleDestination";
import { ReplenishmentShipmentModal } from "./ReplenishmentShipmentModal";
import { useReplenishmentOrderConfirmation } from "./useReplenishmentOrderConfirmation";
import { useReplenishmentShipmentSummary } from "./useReplenishmentShipmentSummary";
import { useReplenishmentOrderPricingSummary } from "./useReplenishmentOrderPricingSummary";
import { useSelector } from "react-redux";
import { selectIsMultiShipmentWithFeatureOn } from "transfers/create/store/TransferCreateSelectors";
import { TextAlign, ThemeProps } from "common/components/ui/shared";

const StyledText = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S2};
`
);

const labels = replenishmentShipmentCreateLabels.steps;

const DestinationAddress: FC<{
  isMultipleShipments: boolean;
  toAddress?: DeliverrAddress;
  onViewMoreClick: (e) => void;
  distributionChannel: DistributionChannel;
}> = ({ isMultipleShipments, toAddress, onViewMoreClick, distributionChannel }) => {
  if (distributionChannel === DistributionChannel.ECOM_VIA_CROSSDOCK) {
    return <FormattedMessage {...replenishmentShipmentCreateLabels.steps.confirm.toDescription} />;
  }

  return isMultipleShipments ? (
    <>
      <ReplenishmentOrderMultipleDestination
        onViewMoreClickonShipmentsModal={onViewMoreClick}
        distributionChannel={distributionChannel}
      />
    </>
  ) : (
    <>{toAddress && <FormatAddress address={toAddress}></FormatAddress>}</>
  );
};

export const ReplenishmentOrderConfirmation = () => {
  const { numOfSkus, numOfCases, numOfUnits, originStorageWarehouse, toAddress, errorType, replenishmentOrder } =
    useReplenishmentShipmentSummary();

  const {
    replenishmentOrderEstimates,
    isLoading,
    onViewMoreClick,
    shouldShowShipmentsModal,
    onModalClose,
    formatMessage,
    shippingTypeMessage,
    shippingPartnerMessage,
    isMultipleShipments,
    replenishmentError,
    isEachPickingOrder,
    shouldShowPricingSummary,
  } = useReplenishmentOrderConfirmation();

  const isMultiShipmentWithFeatureOn = useSelector(selectIsMultiShipmentWithFeatureOn);

  const distributionChannel = replenishmentOrder?.distributionChannel;
  const costTableProps = useReplenishmentOrderPricingSummary(replenishmentOrderEstimates);

  return (
    <ManagedStep
      data-testid="transfer-confirm-step"
      title={formatMessage(labels.confirm.title)}
      nextCaption={formatMessage(labels.confirm.nextButtonCaption)}
      isLoading={isLoading}
      hasBack={errorType === WholesaleErrorType.UNTYPED ?? !isLoading} // check
    >
      <SharedConfirmShipmentSummary
        numOfSkus={numOfSkus}
        numOfCases={!isEachPickingOrder ? numOfCases : undefined}
        numOfUnits={numOfUnits}
        fromAddress={
          <>
            <FormattedMessage {...labels.confirm.fromWarehouseDescription} />
            <br />
            {!!originStorageWarehouse && (
              <>
                {originStorageWarehouse?.warehouse.address.city}, {originStorageWarehouse?.warehouse.address.state}
              </>
            )}
          </>
        }
        toAddress={
          <DestinationAddress
            toAddress={toAddress}
            isMultipleShipments={isMultipleShipments}
            onViewMoreClick={onViewMoreClick}
            distributionChannel={distributionChannel!}
          />
        }
        carrier={
          !isMultiShipmentWithFeatureOn ? (
            <FormattedMessage
              {...labels.shipping.options.carrier.label}
              values={{
                shippingType: shippingTypeMessage,
                shippingPartner: shippingPartnerMessage,
              }}
            />
          ) : undefined
        }
        shipping={isMultiShipmentWithFeatureOn ? shippingPartnerMessage : undefined}
      />
      <ReplenishmentShipmentModal
        data-testid="confirm-shipments-modal"
        showModal={shouldShowShipmentsModal}
        onClose={onModalClose}
        shipments={replenishmentOrder?.shipments}
      />
      <Box width="80%">
        {errorType === WholesaleErrorType.UNTYPED ? (
          <Box marginBottom="S2" marginTop="S7">
            <SummaryErrorNotification
              orderId={replenishmentOrder?.orderId}
              errorMessage={
                replenishmentError?.errors?.[0]?.message ? (
                  <FormattedMessage
                    id="shipment.create.steps.confirm.raw.error"
                    defaultMessage={replenishmentError?.errors?.[0]?.message}
                  />
                ) : (
                  <FormattedMessage id="shipment.create.steps.confirm.error" defaultMessage="Unknown error" />
                )
              }
            />
          </Box>
        ) : (
          <>
            {isLoading && (
              <Box marginBottom="S2" marginTop="S7">
                <LoadingSpinner data-testid="loading-spinner" center size="4x" />
              </Box>
            )}
            {!isLoading && shouldShowPricingSummary && (
              <>
                <TransferConfirmPricingSummary {...costTableProps} />
                {isEachPickingOrder && (
                  <StyledText textAlign={TextAlign.end} size="body">
                    <FormattedMessage {...labels.confirm.eachPickTransportationText} />
                  </StyledText>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </ManagedStep>
  );
};
