import { FreightTrackingInfoExternalIdType } from "common/clients/transportation/FreightTrackingInfo/FreightTrackingInfoExternalIdType";
import { FreightShipmentInfo } from "inbounds/InboundTypes";
import { setShipmentFreightInfo } from "inbounds/store/actions/freight/setShipmentFreightInfo";
import { getLoadedShipmentFreightInfo } from "inbounds/store/selectors/freight/getShipmentFreightInfo";
import { getLoadedShipment } from "inbounds/store/selectors/shipments/getLoadedShipment";
import { useDispatch, useSelector } from "react-redux";

export function useInboundFreightTrackingInfo() {
  const dispatch = useDispatch();

  const freightInfo = useSelector(getLoadedShipmentFreightInfo) ?? {};
  const { id: shipmentId } = useSelector(getLoadedShipment);

  const updateInboundFreightTrackingInfo = (updateData: FreightShipmentInfo) => {
    dispatch(
      setShipmentFreightInfo({
        externalId: String(shipmentId),
        externalIdType: FreightTrackingInfoExternalIdType.INBOUND_SHIPMENT,
        ...updateData,
      })
    );
  };

  return {
    ...freightInfo,
    updateInboundFreightTrackingInfo,
  };
}
