import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import { Box, Grid, Stack } from "common/components/ui";
import { ManagedStep } from "common/components/StepContainer";
import { createShipmentLabels } from "../../createShipment.labels";
import { ThemeProps } from "common/components/ui/shared";
import { useCargoDetailsStep } from "./useCargoDetailsStep";
import { CargoReadyDateInput } from "./CargoReadyDateInput";
import { FlexportProductComplianceButton, ProductChooserSection } from "./ProductChooserSection";
import { EstimatedTotalsSection } from "./EstimatedTotalsSection";
import { DangerousGoodsSection } from "./DangerousGoodsSection";
import { useFetchFlexportUser } from "common/flexportUser/useFetchFlexportUser";
import { trackSmbBookingCreationEvent } from "inbounds/createShipment/common/analytics/trackSmbBookingCreationEvent";
import { LocalizedText } from "common/components/LocalizedText";
import { CargoInputTypeSelector } from "./CargoInputTypeRadioGroup";
import { DtcCargoSkuSelector } from "./Dtc/DtcCargoSkuSelector";
import { PackageSummary, SingleSkuPackageCollection } from "inbounds/createShipment/CreateShipmentTypes";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import { AssignProductCategoriesModal } from "./Dtc/AssignProductCategoriesModal";
import { Product } from "@deliverr/commons-clients";
import { ProductCollection } from "common/models";

interface UnconnectedProps {
  isDtcNetworkBooking: boolean;
  isCargoMixedSku: boolean | null;
  validateStep: () => Promise<boolean>;
  submitData: () => void;
  shouldShowProductsSection: boolean;
  setShouldShowProductsSection: (value: boolean) => void;
  isValidated: boolean;
  isSaving: boolean;
  singleSkuPackages: SingleSkuPackageCollection;
  mixedSkuPackages: PackageSummary[];
  isIncompletedPackages: boolean;
  isLatLngLoaded: boolean;
  productsWithNoCategory: Product[];
  showProductCategoriesModal: boolean;
  productDetails: ProductCollection;
  setShowProductCategoriesModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Divider = styled.hr<ThemeProps>(
  ({ theme }) => `
    border-width: ${theme.border.width.B1} 0 0 0;
    border-color: ${theme.colors.NEUTRAL[60]};
    margin: 0;
    width: 100%;
  `
);

export const CargoDetailStepSubtitle = () => (
  <LocalizedText
    style={{ marginTop: -8 }}
    textAlign="start"
    message={{
      id: "inbounds.createShipment.selectProducts.subtitle",
      defaultMessage: "Products must comply with {flexportComplianceButton}",
      values: {
        flexportComplianceButton: <FlexportProductComplianceButton />,
      },
    }}
  />
);

const UnconnectedCargoDetailsStep = ({
  isDtcNetworkBooking,
  isCargoMixedSku,
  validateStep,
  submitData,
  shouldShowProductsSection,
  isValidated,
  isSaving,
  singleSkuPackages,
  mixedSkuPackages,
  isIncompletedPackages,
  isLatLngLoaded,
  productsWithNoCategory,
  showProductCategoriesModal,
  productDetails,
  setShowProductCategoriesModal,
}: UnconnectedProps) => {
  const { formatMessage } = useIntl();

  return (
    <ManagedStep
      data-testid="cargo-details-step"
      title={formatMessage(createShipmentLabels.steps.cargoDetails.title)}
      subtitle={<CargoDetailStepSubtitle />}
      validate={validateStep}
      nextData={submitData}
      alignItems="start"
      isLoading={isSaving}
    >
      {isLatLngLoaded ? (
        <Box width="100%">
          {isDtcNetworkBooking && (
            <AssignProductCategoriesModal
              productsWithNoCategory={productsWithNoCategory}
              productDetails={productDetails}
              showModal={showProductCategoriesModal}
              setShowModal={setShowProductCategoriesModal}
            />
          )}
          <Stack gap="S7">
            <Stack gap="S5">
              <CargoInputTypeSelector />
              {shouldShowProductsSection ? (
                <>
                  {isDtcNetworkBooking && <DtcCargoSkuSelector />}
                  <ProductChooserSection
                    isDtcSingleSkuBooking={!isCargoMixedSku && isDtcNetworkBooking}
                    isIntlDtc={isDtcNetworkBooking}
                    isStepValidated={isValidated}
                    singleSkuPackages={singleSkuPackages}
                    mixedSkuPackages={mixedSkuPackages}
                    isIncompletedPackages={isIncompletedPackages}
                  />
                </>
              ) : (
                <EstimatedTotalsSection isIntlDtc={isDtcNetworkBooking} />
              )}
            </Stack>
            <>
              <DangerousGoodsSection />
              <Box>
                <Divider />
              </Box>
              <Grid columns="1fr 1fr" gap="S5">
                <Box>
                  <CargoReadyDateInput />
                  <LocalizedText
                    textAlign="start"
                    appearance="INFO"
                    message={{
                      id: "inbounds.createShipment.cargoReadyDate.info",
                      defaultMessage: "This date can be updated later.",
                    }}
                  />
                </Box>
              </Grid>
            </>
          </Stack>
        </Box>
      ) : (
        <Box marginTop="S6" width="100%">
          <LoadingSpinner center size="4x" />
        </Box>
      )}
    </ManagedStep>
  );
};

export const CargoDetailsStep = () => {
  const {
    validateStep,
    submitData,
    shouldShowProductsSection,
    isValidated,
    isSaving,
    isDtcNetworkBooking,
    isCargoMixedSku,
    singleSkuPackages,
    mixedSkuPackages,
    isIncompletedPackages,
    isLatLngLoaded,
    productsWithNoCategory,
    showProductCategoriesModal,
    productDetails,
    setShowProductCategoriesModal,
  } = useCargoDetailsStep();
  const flexportUser = useFetchFlexportUser();

  useEffect(() => {
    if (flexportUser?.clientFid) {
      trackSmbBookingCreationEvent("cargo_details_step", { clientFid: flexportUser?.clientFid });
    }
  }, [flexportUser?.clientFid]);

  return (
    <UnconnectedCargoDetailsStep
      isDtcNetworkBooking={isDtcNetworkBooking}
      isCargoMixedSku={isCargoMixedSku}
      validateStep={validateStep}
      submitData={submitData}
      shouldShowProductsSection={shouldShowProductsSection}
      setShouldShowProductsSection={() => {}}
      isValidated={isValidated}
      isSaving={isSaving}
      singleSkuPackages={singleSkuPackages}
      mixedSkuPackages={mixedSkuPackages}
      isIncompletedPackages={isIncompletedPackages}
      isLatLngLoaded={isLatLngLoaded}
      productsWithNoCategory={productsWithNoCategory}
      showProductCategoriesModal={showProductCategoriesModal}
      productDetails={productDetails}
      setShowProductCategoriesModal={setShowProductCategoriesModal}
    />
  );
};
