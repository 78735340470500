import React from "react";
import { Box, BoxReset, Input, Text, ThemeProps } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import { useStoragePalletizedInput } from "./useStoragePalletizedInput";
import styled from "@emotion/styled";
import { STORAGE_MAX_FTL_PALLETS } from "../CargoTypeConstants";
import {
  STORAGE_INBOUND_MAX_CASE_LONGEST_IN,
  STORAGE_INBOUND_ADDITIONAL_MAX_CASE_LONGEST_IN_FOR_SOME_WH,
} from "storage/inbounds/CaseDimensionsConstants";
import { useSelector } from "react-redux";
import { WAREHOUSES_SUPPORTING_MAX_HEIGHT } from "storage/inbounds/WarehouseConstants";
import { getStorageInboundCreate } from "storage/inbounds/create/store/selector/getStorageInboundCreate";

const NumberInput = styled(Input)<ThemeProps>(
  ({ theme }) => `
    padding-right: ${theme.spacing.S3};
  `
);
export const StoragePalletizedInput: React.ComponentType = () => {
  const { numOfPallets, onNumOfPalletsUpdate, isNumOfPalletsError } = useStoragePalletizedInput();
  const warehouseId = useSelector(getStorageInboundCreate)?.destinationStorageWarehouse?.id ?? "";
  const maxPalletLengthSupportedIn = WAREHOUSES_SUPPORTING_MAX_HEIGHT.includes(warehouseId)
    ? STORAGE_INBOUND_ADDITIONAL_MAX_CASE_LONGEST_IN_FOR_SOME_WH
    : STORAGE_INBOUND_MAX_CASE_LONGEST_IN;
  const numOfPalletsErrorMessage = (
    <FormattedMessage
      id="storage.inbounds.cargoType.numOfPallets.error"
      defaultMessage="To send more than {STORAGE_MAX_FTL_PALLETS} pallets, create multiple shipments."
      values={{ STORAGE_MAX_FTL_PALLETS }}
    />
  );
  return (
    <Box marginTop="S7">
      <Text bold>
        <FormattedMessage id="storage.inbounds.cargoType.numOfPalletsLabel" defaultMessage="How many pallets?" />
      </Text>
      <BoxReset width="5rem" marginTop="S4">
        <NumberInput
          errorMessage={numOfPalletsErrorMessage}
          hasError={isNumOfPalletsError}
          name="numOfPallets"
          type="number"
          min={1}
          value={numOfPallets}
          onChange={onNumOfPalletsUpdate}
        />
      </BoxReset>
      <BoxReset width="25rem" marginTop="S2">
        <Text appearance="INFO" size="caption">
          <FormattedMessage
            id="storage.inbounds.cargoType.numOfPalletsDescription"
            defaultMessage={
              "Estimated based on your case dimensions and weights. Pallets taller than {maxPalletLengthSupportedIn}” are re-palletized at an additional cost to fit Flexport's pallet racking for this warehouse."
            }
            values={{ maxPalletLengthSupportedIn }}
          />
        </Text>
      </BoxReset>
    </Box>
  );
};
