import React from "react";
import styled from "@emotion/styled";

import { DefaultTheme, ThemeProps } from "../shared";
import { isMobile } from "../shared/helpers/utils";
import { Icon, IconsProps } from "../Icon";

export interface RadioTileProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: JSX.Element | string;
  content?: JSX.Element | string;
  block?: boolean;
}

const Tile = styled.label<Pick<RadioTileProps, "checked" | "block" | "disabled">, DefaultTheme>(
  ({ checked, block, disabled, theme }) => `
  display: ${block ? "flex" : "inline-flex"};
  align-items: center;
  padding: calc(${isMobile(theme) ? theme.spacing.S4 : theme.spacing.S5} - ${
    checked ? theme.border.width.B2 : theme.border.width.B1
  });
  background-color: ${disabled ? theme.colors.NEUTRAL[20] : theme.colors.NEUTRAL["00"]};
  border: ${
    checked
      ? `${theme.border.width.B2} ${theme.border.type} ${disabled ? theme.colors.BLUE[200] : theme.colors.BLUE["300"]}`
      : `${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL["60"]}`
  };
  cursor: ${disabled ? "not-allowed" : "pointer"};
  &:hover, &:focus-within {
    ${
      !disabled &&
      `
      border: ${theme.border.width.B2} ${theme.border.type} ${theme.colors.BLUE["300"]};
      padding: calc(${isMobile(theme) ? theme.spacing.S4 : theme.spacing.S5} - ${theme.border.width.B2});
    `
    }
  }
`
);

const TextContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    padding-left: ${isMobile(theme) ? theme.spacing.S4 : theme.spacing.S5};
    line-height: ${theme.font.lineHeight.LH2};
  `
);

const Label = styled.div<Pick<RadioTileProps, "disabled">, DefaultTheme>(
  ({ disabled, theme }) => `
    color: ${disabled ? theme.colors.NEUTRAL["300"] : theme.colors.NEUTRAL["500"]};
    font-size: ${theme.font.size.F2};
    font-weight: ${theme.font.weight.BOLD};
    font-family: ${theme.font.family.STANDARD};
  `
);

const Content = styled.div<ThemeProps>(
  ({ theme }) => `
    padding-top: ${theme.spacing.S1};
    color: ${theme.colors.NEUTRAL["300"]}
  `
);

const Circle = styled.span<ThemeProps>(
  ({ theme }) => `
    border: 1px solid ${theme.colors.NEUTRAL["80"]};
    border-radius: 50%;
    min-width: ${theme.spacing.S5};
    height: ${theme.spacing.S5};
  `
);

const Input = styled.input<React.InputHTMLAttributes<HTMLInputElement>, DefaultTheme>`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

const StyledIcon = styled(Icon)<IconsProps & Pick<RadioTileProps, "disabled">, DefaultTheme>(
  ({ disabled, theme }) => `
  width: ${theme.spacing.S5} !important;
  height: ${theme.spacing.S5};
  color: ${disabled ? theme.colors.NEUTRAL[200] : theme.colors.GREEN[300]}
`
);

export const RadioTile: React.FC<RadioTileProps> = ({ label, content, checked, block, disabled, ...props }) => {
  return (
    <Tile checked={checked} block={block} data-testid="radio_tile" disabled={disabled}>
      <Input data-testid="radio_tile_input" type="radio" checked={checked} disabled={disabled} {...props} />
      {checked ? (
        <StyledIcon data-testid="radio_tile_checkmark" type="check-circle" disabled={disabled} />
      ) : (
        <Circle aria-hidden />
      )}
      <TextContainer>
        <Label disabled={disabled} data-testid="radio_tile_label">
          {label}
        </Label>
        {content && <Content data-testid="radio_tile_content">{content}</Content>}
      </TextContainer>
    </Tile>
  );
};
