import { User } from "common/clients/seller/User/User";
import { UserStatus } from "./UserStatus";

export const getUserStatus = (
  user: Pick<User, "isArchived" | "pendingInvite" | "pendingInviteExpirationDate">,
  now: Date = new Date()
): UserStatus => {
  if (user.isArchived) {
    return "ARCHIVED";
  } else if (user.pendingInvite) {
    return user.pendingInviteExpirationDate && new Date(user.pendingInviteExpirationDate) < now ? "EXPIRED" : "PENDING";
  } else {
    return "ACTIVE";
  }
};
