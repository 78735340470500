export enum InventoryListActionTypes {
  SET_ESTIMATED_COSTS_SERVICE_LEVEL = "SET_ESTIMATED_COSTS_SERVICE_LEVEL",
  SET_INVENTORY_LIST_COUNTRY_CODE = "SET_INVENTORY_LIST_COUNTRY_CODE",
  SET_IS_ACTIVE = "SET_IS_ACTIVE",
  PRODUCT_SYNC = "PRODUCT_SYNC",
  ESTIMATE_PRODUCT_LIST_COSTS_LOADING = "ESTIMATE_PRODUCT_LIST_COSTS_LOADING",
  ESTIMATE_PRODUCT_LIST_COSTS = "ESTIMATE_PRODUCT_LIST_COSTS",
  INVENTORY_LIST_LOADER = "INVENTORY_LIST_LOADER",
  SET_FILTERS_BLADE_OPEN = "SET_FILTERS_BLADE_OPEN",
  SET_TAB = "SET_TAB",
  SET_SEARCH_TERM = "SET_SEARCH_TERM",
  SET_BUNDLE_LIST_AVAILABLE_QTY = "SET_BUNDLE_LIST_AVAILABLE_QTY",
  ESTIMATE_BUNDLE_LIST_COSTS = "ESTIMATE_BUNDLE_LIST_COSTS",
  ESTIMATE_BUNDLE_LIST_COSTS_LOADING = "ESTIMATE_BUNDLE_LIST_COSTS_LOADING",
  ESTIMATE_BUNDLE_LIST_COSTS_COMPLETED = "ESTIMATE_BUNDLE_LIST_COSTS_COMPLETED",
  SET_INVENTORY_LOCATION_FILTER = "SET_INVENTORY_LOCATION_FILTER",
  CALCULATE_MULTI_PACK_STORAGE_INVENTORY_LOADING = "CALCULATE_MULTI_PACK_STORAGE_INVENTORY_LOADING",
  CALCULATE_MULTI_PACK_STORAGE_INVENTORY = "CALCULATE_MULTI_PACK_STORAGE_INVENTORY",
}
