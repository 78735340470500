import { OptionProps as SelectOptionProps } from "@deliverr/react-select-v1";
import styled from "@emotion/styled";
import { InputError } from "common/components/InputError";
import { Appearance, DefaultTheme, Select, SelectComponentOption, Text } from "common/components/ui";
import { AmazonAddress } from "inbounds/createShipment/CreateShipmentTypes";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { getAddressString, getFormattedName, useAmazonAddressInput } from "./useAmazonAddressInput";

const Option: FC<SelectOptionProps> = (props) => {
  return (
    <SelectComponentOption {...props}>
      <strong>{getFormattedName(props.data.value)}</strong>
      <Text>{getAddressString(props.data.value)}</Text>
    </SelectComponentOption>
  );
};
const SelectWrapper = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    width: 80%;
  `
);

export const AmazonAddressInput = ({
  onChangeCallback,
  selectedAddress,
}: {
  onChangeCallback: (address: AmazonAddress) => void;
  selectedAddress: AmazonAddress;
}) => {
  const { formatMessage } = useIntl();
  const { options, onChange, isOptionSelected, hasError, isLoading, hasServerError, value } = useAmazonAddressInput(
    onChangeCallback,
    selectedAddress
  );

  return (
    <SelectWrapper>
      <Select
        label={formatMessage({
          id: "amazonDestinationModal.amazonAddressLabel",
          defaultMessage: "Amazon address",
        })}
        placeholder={formatMessage({
          id: "amazonDestinationModal.amazonAddressPlaceholder",
          defaultMessage: "Search locations",
        })}
        id={"amazonDestinationModal.singleLocation"}
        inputId={"amazonDestinationModal.singleLocationInput"}
        options={options}
        customComponents={{ Option }}
        maxMenuHeight={400}
        onChange={onChange}
        value={value}
        hasError={hasError}
        isOptionSelected={(option, selectedOption) => isOptionSelected(option?.value, selectedOption?.[0]?.value)}
        isLoading={isLoading}
        isDisabled={hasServerError}
      />
      {hasServerError && (
        <InputError
          appearance={Appearance.DANGER}
          message={formatMessage({
            id: "amazonDestinationModal.serverError",
            defaultMessage: "Unable to fetch Amazon locations. Please try again later.",
          })}
        />
      )}
    </SelectWrapper>
  );
};
