import { PrepJobResponse } from "@deliverr/prep-service-client";
import { SPThunkAction } from "common/ReduxUtils";
import { setFloorLoadedContainerDetails } from "inbounds/InboundActions";
import { setFCLContainerDetails } from "./setFCLContainerDetails";

export const initializeFloorLoadedContainerDetails =
  (prepJobResponse?: PrepJobResponse): SPThunkAction =>
  (dispatch) => {
    const details = prepJobResponse?.floorLoadedContainerDetails?.containerDetails[0];
    if (details) {
      dispatch(setFloorLoadedContainerDetails(details.type, details.containerId));
    } else {
      dispatch(setFCLContainerDetails(undefined));
    }
  };
