import cx from "classnames/dedupe";
import { Size } from "common/components/componentTypes";
import React, { FC } from "react";

import cls from "app/common/styles/pills.less";

export enum PillColors {
  GRAY = "Gray",
  DARK_GRAY = "DarkGray",
  BLUE = "Blue",
  PURPLE = "Purple",
  ORANGE = "Orange",
  YELLOW = "Yellow",
  PINK = "Pink",
  GREEN = "Green",
  RED = "Red",
}

interface OwnProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  className?: string;
  color?: PillColors;
  bold?: boolean;
  widePill?: boolean;
  icon?: React.ReactNode;
  tag?: boolean;
  size?: Size;
}

export const Pill: FC<OwnProps> = ({
  className,
  children,
  color = PillColors.GRAY,
  widePill,
  bold,
  icon,
  tag,
  size = Size.md,
  ...props
}) => {
  const classes = cx(
    cls.pill,
    cls[`pill${color}`],
    {
      [cls.widePill]: widePill,
      [cls.bold]: bold,
      [cls.hasIcon]: icon,
      [cls.tag]: tag,
    },
    cls[`pill--${size}`],
    className
  );

  return (
    <div className={classes} {...props}>
      {icon}
      {React.Children.toArray(children)}
    </div>
  );
};

export default Pill;
