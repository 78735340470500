// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmphasizedText__emphasizedText--Qtjp0 {\n  background-color: #d5e8fb;\n  border-radius: 12px;\n  padding: 2px 8px;\n  letter-spacing: 0.25px;\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./src/app/common/components/InstructionList/EmphasizedText.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;EACA,iBAAA;AACF","sourcesContent":[".emphasizedText {\n  background-color: #d5e8fb;\n  border-radius: 12px;\n  padding: 2px 8px;\n  letter-spacing: 0.25px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emphasizedText": "EmphasizedText__emphasizedText--Qtjp0"
};
export default ___CSS_LOADER_EXPORT___;
