import { GoogleShoppingError } from "common/clients/googleShopping/GoogleShoppingError";
import { ShopifyError } from "common/clients/shopify/ShopifyError";
import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { GoogleActionTypes } from "tags/google/GoogleActions";

export interface GoogleState {
  isActive: boolean;
  merchantId?: string;
  productFeedId?: string;
  isActiveForAds: boolean;
  isActiveForShoppingActions: boolean;
  isEligibleForShoppingActions: boolean;
  activationError?: ShopifyError;
  supplementalFeedError?: GoogleShoppingError;
  merchantIdError?: GoogleShoppingError;
  serviceAccountEmail?: string;
}

export const googleInitialState: GoogleState = {
  activationError: undefined,
  supplementalFeedError: undefined,
  merchantIdError: undefined,
  isActive: false,
  merchantId: undefined,
  productFeedId: undefined,
  isActiveForAds: false,
  isActiveForShoppingActions: false,
  isEligibleForShoppingActions: false,
};

const reducers: ReducerDictionary<GoogleState> = {
  ...handleSimpleReducerUpdates([
    GoogleActionTypes.UPDATE_GOOGLE_ACTIVATION_ERROR,
    GoogleActionTypes.UPDATE_GOOGLE_SUPPLEMENTAL_FEED_ERROR,
    GoogleActionTypes.UPDATE_GOOGLE_SELLER,
    GoogleActionTypes.GET_GOOGLE_SELLER_CONFIGS,
    GoogleActionTypes.UPDATE_GOOGLE_MERCHANT_ID_ERROR,
    GoogleActionTypes.GOOGLE_SERVICE_ACCOUNT_EMAIL_RECEIVED,
  ]),
};

export const googleReducer = createReducer<GoogleState>(googleInitialState, reducers);
