import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "RootReducer";
import { fetchCredits, showBillingAddress, showPaymentMethods } from "../BillingActions";

export const useBillingSummary = () => {
  const dispatch = useDispatch();
  const {
    unpaidInvoiceCount,
    totalInvoiceCount,
    dueNow,
    nextInvoiceDate,
    creditBalance,
    hasCreditHistory,
  } = useSelector((state: RootState) => state.billing.summary.data);

  const updatePaymentMethod = () => dispatch(showPaymentMethods());
  const updateBillingAddress = () => dispatch(showBillingAddress());

  const hasUnpaidInvoices = unpaidInvoiceCount > 0;
  const hasInvoices = totalInvoiceCount > 0;

  const [isPromotionalCreditHistoryOpen, setIsPromotionalCreditHistoryOpen] = useState(false);
  const openPromotionalCreditHistory = () => {
    dispatch(fetchCredits());
    setIsPromotionalCreditHistoryOpen(true);
  };
  const closePromotionalCreditHistory = () => setIsPromotionalCreditHistoryOpen(false);

  return {
    updatePaymentMethod,
    updateBillingAddress,
    hasUnpaidInvoices,
    hasInvoices,
    dueNow,
    nextInvoiceDate,
    creditBalance: creditBalance ?? 0,
    hasCreditHistory,
    isPromotionalCreditHistoryOpen,
    openPromotionalCreditHistory,
    closePromotionalCreditHistory,
  };
};
