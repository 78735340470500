import { InfoIcon } from "common/components/icons";
import { Icon, defaultTheme } from "common/components/ui";
import React from "react";
import { FormattedMessage } from "react-intl";

export const storagePickupDetailsLabels: Record<string, JSX.Element> = {
  firstName: <FormattedMessage id="storage.inbound.create.addPickupDetails.firstName" defaultMessage="First name" />,
  lastName: <FormattedMessage id="storage.inbound.create.addPickupDetails.lastName" defaultMessage="Last name" />,
  email: <FormattedMessage id="storage.inbound.create.addPickupDetails.email" defaultMessage="Email address" />,
  phoneNumber: (
    <FormattedMessage id="storage.inbound.create.addPickupDetails.phoneNumber" defaultMessage="Phone number" />
  ),
};

export const cargoPickupDetailsLabels: Record<string, JSX.Element> = {
  readyDateLabel: (
    <>
      <FormattedMessage id="cargoPickupDetails.readyDateLabel" defaultMessage="Cargo ready date" />{" "}
      <InfoIcon
        messageWidth={175}
        wrap
        placement="top"
        message={
          <FormattedMessage
            id="cargoPickupDetails.readyDateLabel.tooltipMessage"
            defaultMessage="Date your cargo is ready to be picked up by the Flexport partner carrier."
          />
        }
      >
        <Icon type="info-circle-alt" color={defaultTheme.colors.NEUTRAL[400]} />
      </InfoIcon>
    </>
  ),
  readyDateError: <FormattedMessage id="cargoPickupDetails.readyDateError" defaultMessage="Please pick a date" />,
  readyDateHelpText: <FormattedMessage id="cargoPickupDetails.readyDateHelpText" defaultMessage="Business days only" />,
  pickupReferenceNumber: (
    <>
      <FormattedMessage id="cargoPickupDetails.pickupReferenceNumber" defaultMessage="Pickup reference number" />{" "}
      <InfoIcon
        messageWidth={175}
        wrap
        placement="top"
        message={
          <FormattedMessage
            id="cargoPickupDetails.pickupReferenceNumber.tooltipMessage"
            defaultMessage="A reference number the driver can provide to the shipper to release / pick up the freight."
          />
        }
      >
        <Icon type="info-circle-alt" color={defaultTheme.colors.NEUTRAL[400]} />
      </InfoIcon>
    </>
  ),
  customerReferenceNumber: (
    <>
      <FormattedMessage id="cargoPickupDetails.customerReferenceNumber" defaultMessage="Customer reference ID" />{" "}
      <InfoIcon
        messageWidth={175}
        wrap
        placement="top"
        message={
          <FormattedMessage
            id="cargoPickupDetails.customerReferenceNumber.tooltipMessage"
            defaultMessage="Used to identify the shipment for billing and invoicing purposes."
          />
        }
      >
        <Icon type="info-circle-alt" color={defaultTheme.colors.NEUTRAL[400]} />
      </InfoIcon>
    </>
  ),
  liftgateRequired: (
    <>
      <FormattedMessage id="cargoPickupDetails.liftgateRequired" defaultMessage="Liftgate required" />{" "}
      <InfoIcon
        messageWidth={175}
        wrap
        placement="top"
        message={
          <FormattedMessage
            id="cargoPickupDetails.liftgateRequired.tooltipMessage"
            defaultMessage="A liftgate is required when the facility doesn’t have a forklift or loading dock. This option affects the shipping quote."
          />
        }
      >
        <Icon type="info-circle-alt" color={defaultTheme.colors.NEUTRAL[400]} />
      </InfoIcon>
    </>
  ),
};
