import { OrderResponse, QuoteRequestResponse } from "@deliverr/freight-client";

import { createReducer } from "common/ReduxUtils";
import { FreightListActionTypes } from "../actions/FreightListActionTypes";
import produce from "immer";
import { ListType } from "freight/FreightList/useFreightBookingList";

export interface FreightListState {
  orders: null | OrderResponse[];
  quoteRequests: null | QuoteRequestResponse[];
  activeView: ListType;
}

export const freightListInitialState: FreightListState = {
  orders: null,
  quoteRequests: null,
  activeView: ListType.ORDERS,
};

export const freightListReducer = createReducer<FreightListState>(freightListInitialState, {
  [FreightListActionTypes.FREIGHT_ORDERS_RECEIVED]: (state, { orders }) =>
    produce(state, (draftState) => {
      draftState.orders = orders;
    }),
  [FreightListActionTypes.FREIGHT_QUOTE_REQUESTS_RECEIVED]: (state, { quoteRequests }) =>
    produce(state, (draftState) => {
      draftState.quoteRequests = quoteRequests;
    }),
  [FreightListActionTypes.TOGGLE_LIST_VIEW]: (state, { listType }) =>
    produce(state, (draftState) => {
      draftState.activeView = listType;
    }),
  [FreightListActionTypes.FREIGHT_QUOTE_REQUESTS_GENERATED]: (state, { quoteRequests }) =>
    produce(state, (draftState) => {
      if (draftState.quoteRequests) {
        draftState.quoteRequests = [...quoteRequests, ...draftState.quoteRequests];
        return;
      }

      draftState.quoteRequests = quoteRequests;
    }),
});
