import React from "react";
import { SelectComponentsConfig } from "@deliverr/react-select-v1";
import {
  OriginSearchOption,
  DestinationSearchOption,
  PlaceSearchControl,
  PlaceSearchGroupHead,
  PlaceSearchInput,
} from "./select-components";
import { SelectAsync } from "common/components/ui";
import { useIntl, defineMessages } from "react-intl";
import { usePlaceSearch } from "./usePlaceSearch";
import { PlaceType } from "inbounds/createShipment/CreateShipmentTypes";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm/useCreateShipmentForm.types";

interface PlaceSearchTitle {
  title: string;
  description: string;
}

export interface OriginOption {
  options: PlaceType[] | undefined;
  label: PlaceSearchTitle;
}

const PLACE_SEARCH_PLACE_HOLDERS = defineMessages({
  newUser: {
    id: "inbounds.createShipment.placeSearch.placeHolder",
    defaultMessage: "Search by a city, port, address, or postal code",
  },
  returningUser: {
    id: "inbounds.createShipment.steps.placeSearch.destination.placeHolder",
    defaultMessage: "Search by a port or an address",
  },
});

export const PlaceSearch = ({
  type,
  isAddressReturningUserOnly,
  isPortOnly = false,
}: {
  type: CreateShipmentInputName.ORIGIN | CreateShipmentInputName.DESTINATION;
  isAddressReturningUserOnly: boolean;
  isPortOnly?: boolean;
}) => {
  const CUSTOM_COMPONENTS: SelectComponentsConfig<any> = {
    Input: PlaceSearchInput,
    GroupHeading: PlaceSearchGroupHead,
    Control: PlaceSearchControl,
    ClearIndicator: null,
    Option: type === CreateShipmentInputName.ORIGIN ? OriginSearchOption : DestinationSearchOption,
  };
  const { formatMessage } = useIntl();
  const {
    inputValue,
    placeOption,
    isMenuOpen,
    handleInputChange,
    handleSelect,
    handleBlur,
    loadOptions,
  } = usePlaceSearch(type, isAddressReturningUserOnly, isPortOnly);
  return (
    <SelectAsync
      blurInputOnSelect
      menuIsOpen={isMenuOpen}
      classNamePrefix="place_search"
      backspaceRemovesValue
      customComponents={CUSTOM_COMPONENTS}
      onInputChange={handleInputChange}
      loadOptions={loadOptions}
      maxMenuHeight={400}
      onChange={handleSelect}
      placeholder={formatMessage(
        isAddressReturningUserOnly ? PLACE_SEARCH_PLACE_HOLDERS.returningUser : PLACE_SEARCH_PLACE_HOLDERS.newUser
      )}
      onBlur={handleBlur}
      value={placeOption.label.length > 0 ? placeOption : null}
      inputValue={inputValue}
    />
  );
};

// TODO(pgao1): Remove this when we add the RelayEnvironment to the App
export default function PlaceSearchWithRelayEnvironment({
  type,
  isAddressReturningUserOnly,
  isPortOnly,
}: {
  type: CreateShipmentInputName.ORIGIN | CreateShipmentInputName.DESTINATION;
  isAddressReturningUserOnly: boolean;
  isPortOnly?: boolean;
}) {
  return <PlaceSearch type={type} isAddressReturningUserOnly={isAddressReturningUserOnly} isPortOnly={isPortOnly} />;
}
