import { sumBy } from "lodash/fp";
import { useIntl } from "react-intl";
import { BasicTableColumn } from "common/components/BasicTable";
import { WholesaleOrderDTO, PricingItemBreakdownResponse } from "@deliverr/wholesale-client";
import { transferCostsLabels } from "../../../transfers/create/steps/common/transferCosts.labels";
import { wholesaleCreateLabels } from "../wholesaleCreate.labels";
import { useWholesaleConfirmCostsColumns } from "./useWholesaleConfirmCostsColumns";
import { CostTableProps } from "common/components/CostTable";

type LabelResolver = ({ breakdownKey, details }: { breakdownKey: string; details: string }) => string;

export const useWholesalePricingSummary = (wholesaleOrder?: WholesaleOrderDTO): CostTableProps => {
  const { formatMessage } = useIntl();
  const aggregatorOptions = { totalRowLabel: formatMessage(wholesaleCreateLabels.steps.confirm.totalRowLabel) };
  const columns = useWholesaleConfirmCostsColumns();

  const resolveLabel: LabelResolver = ({ breakdownKey, details }) =>
    transferCostsLabels.breakdown[breakdownKey] ? formatMessage(transferCostsLabels.breakdown[breakdownKey]) : details;

  const data: (BasicTableColumn | undefined | 0)[] = [
    ...Object.entries(wholesaleOrder?.estimations?.computedPriceBreakdown ?? {})
      .filter(([_key, costEntry]) => !costEntry.chargedByExternalPartner)
      .map(
        ([breakdownKey, costEntry]: [string, PricingItemBreakdownResponse]): BasicTableColumn => ({
          rowLabel: resolveLabel({ breakdownKey, details: costEntry.details }),
          quantity: costEntry.multiplier,
          unitLabel: costEntry.category,
          unitCost: costEntry.price,
          amount: costEntry.calculatedPrice,
        })
      ),
    {},
  ];

  return {
    subTables: [{ data: data as BasicTableColumn[], columns, aggregatorOptions }],
    totalAggregation: sumBy("amount", data),
  };
};
