import produce from "immer";

import { createReducer, ReducerDictionary } from "common/ReduxUtils";
import { FreightOrderActionTypes } from "../actions/FreightOrderActionTypes";
import { FreightBookingActionTypes } from "freight/store/freightBooking/actions/FreightBookingActionTypes";

const reducer: ReducerDictionary<number | null> = {
  [FreightOrderActionTypes.SET_FREIGHT_BOOKING_ID]: (_, action) => action.id,
  [FreightBookingActionTypes.RESET_FREIGHT_BOOKING_STATE]: (state) => {
    return produce(state, (draft) => {
      draft = null;
    });
  },
};

export const freightOrderIdReducer = createReducer<number | null>(null, reducer);
