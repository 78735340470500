import React, { FC } from "react";

import { UnexpectedSkuProblemRow } from "common/components/ProblemsTable/types";
import { SHIPMENT_PROBLEMS_UNEXPECTED_SKU_BLADE_ID } from "../blades";
import { useShipmentProblemUnexpectedSkuBlade } from "./useShipmentProblemUnexpectedSkuBlade";
import { UnexpectedSkuBlade } from "common/components/ProblemBlade/UnexpectedSkuBlade/UnexpectedSkuBlade";

export interface ShipmentProblemUnexpectedSkuBladeProps {
  shipmentProblem: UnexpectedSkuProblemRow;
}

export const ShipmentProblemUnexpectedSkuBlade: FC<ShipmentProblemUnexpectedSkuBladeProps> = ({ shipmentProblem }) => {
  const {
    resetBladeState,
    shippingPlanId,
    shippingPlanName,
    isShipmentProblemBladeOpen,
    skuPair,
  } = useShipmentProblemUnexpectedSkuBlade(shipmentProblem);

  return (
    <UnexpectedSkuBlade
      id={SHIPMENT_PROBLEMS_UNEXPECTED_SKU_BLADE_ID}
      shipmentProblem={shipmentProblem}
      onShippingPlanClick={resetBladeState}
      shippingPlanId={shippingPlanId}
      shippingPlanName={shippingPlanName}
      onCloseClick={resetBladeState}
      isBladeOpen={isShipmentProblemBladeOpen}
      product={skuPair}
    />
  );
};
