import React from "react";
import { StyledButton } from "../Button";
import { Icon, IconsProps } from "../Icon";
import styled from "@emotion/styled";
import { DefaultTheme } from "../shared";

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string | JSX.Element;
  /** adds a spinner */
  loading?: boolean;
  /** Non-primary action */
  secondary?: boolean;
  /** Width to full length of container */
  block?: boolean;
  /** Disable state */
  disabled?: boolean;
}

const AsLabel = StyledButton.withComponent("label");
const Label = styled(AsLabel)`
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
const Input = styled.input`
  opacity: 0;
  position: absolute;
  width: 1px;
`;
const Spinner = styled(Icon)<IconsProps, DefaultTheme>`
  width: 14px;
  margin-right: ${({ theme }) => theme.spacing.S2};
`;

export const FileInput: React.FC<FileInputProps> = ({
  label,
  loading,
  onChange,
  secondary,
  block,
  disabled,
  ...props
}) => (
  <Label appearance="DEFAULT" secondary={secondary} block={block} disabled={disabled}>
    {loading && <Spinner type="circle-notch" spin={true} />}
    <span>{label}</span>
    <Input type="file" onChange={onChange} disabled={disabled} {...props} />
  </Label>
);
