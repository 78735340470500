import React, { ComponentProps } from "react";
import NumberFormat from "react-number-format";
import styled from "@emotion/styled";
import { ThemeProps } from "common/components/ui";

// defining the styled component this way resolves test failures caused by circular dependencies
const StyledNumberFormat = styled((props) => <NumberFormat {...props} />)<ThemeProps>(
  ({ theme }) => `
    width: 50px;
    height: 36px;
    text-align: center;
    border-radius: ${theme.border.radius.R2};
    border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
  `
);

/**
 * must pass a defined value to NumberFormat, otherwise the last valid value will be used,
 * potentially persisting data from a previous render. We dont use nullish coalescing since
 * we want to also display an empty string if the value is 0.
 * https://github.com/s-yadav/react-number-format/issues/494
 */

export const EditableNumberField: React.FC<ComponentProps<typeof NumberFormat>> = ({
  allowNegative = false,
  value,
  ...props
}) => (
  <StyledNumberFormat
    allowNegative={allowNegative}
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    value={value || ""}
    {...props}
  />
);
