import cx from "classnames";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "RootReducer";

import cls from "./AdminOnly.less";

interface OwnProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  adminStyles?: boolean;
}

export const AdminOnly: FC<OwnProps> = ({ adminStyles = false, children, className }) => {
  const shouldShowAdmin = useSelector((state: RootState) => state.admin.showAdminFeatures);
  const isAdmin = useSelector((state: RootState) => state.user.isAdmin);
  if (isAdmin && shouldShowAdmin) {
    return <div className={cx(className, { [cls.adminOnly]: adminStyles })}>{children}</div>;
  }
  return null;
};
