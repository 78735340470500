import { OrderStatus } from "@deliverr/replenishment-client";

export const ReplenishmentListStatuses = [
  OrderStatus.ARRIVED,
  OrderStatus.CANCELLED,
  OrderStatus.COMPLETED,
  OrderStatus.DELIVERED,
  OrderStatus.DRAFT,
  OrderStatus.PROCESSING,
  OrderStatus.READY_TO_SHIP,
  OrderStatus.RECEIVING,
  OrderStatus.SCHEDULED,
  OrderStatus.SHIPPED,
  OrderStatus.SUBMITTED,
] as const;

export type ReplenishmentListStatus = (typeof ReplenishmentListStatuses)[number];
