import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import isEmpty from "lodash/isEmpty";
import { FormattedMessage } from "react-intl";
import { TextAlign } from "common/components/ui/shared";
import { CostTableProps } from "common/components/CostTable";
import { Accordion, Modal, ModalProps, Text, ThemeProps, Title } from "common/components/ui";
import { replenishmentOrderDetailLabels } from "transfers/create/steps/common/replenishment/replenishmentOrderDetails.labels";
import { TransferConfirmPricingSummary } from "../create/steps/common/TransferConfirmPricingSummary";

const ContentBox = styled.div<ThemeProps>(
  ({ theme }) => `
  padding-bottom: ${theme.spacing.S6};
`
);

interface ModalPricingSummaryProps extends Pick<ModalProps, "show" | "onClose"> {
  actualCostTableProps: CostTableProps;
  estimatedCostTableProps: CostTableProps;
}

export const ModalPricingSummary: FC<ModalPricingSummaryProps> = ({
  show,
  onClose,
  actualCostTableProps,
  estimatedCostTableProps,
}) => {
  const [shouldShowActualCost, setShowActualCost] = useState(true);
  const [shouldShowEstimatedCost, setShowEstimatedCost] = useState(false);
  const hasActualCostData = !isEmpty(actualCostTableProps.subTables[0].data[0]);

  const handleToggleEstimatedCost = () => {
    setShowEstimatedCost(!shouldShowEstimatedCost);
  };

  const handleToggleActualCost = () => {
    setShowActualCost(!shouldShowActualCost);
  };

  return (
    <Modal hasCloseButton show={show} onClose={onClose} width="80%">
      <Title displayAs="h4" textAlign={TextAlign.center}>
        <FormattedMessage {...replenishmentOrderDetailLabels.order.pricingSummary.title} />
      </Title>
      {hasActualCostData && (
        <ContentBox>
          <Accordion
            indicator
            headerContent={
              <Text size="label" bold>
                <FormattedMessage {...replenishmentOrderDetailLabels.order.costs.actualCostTitle} />
              </Text>
            }
            isOpen={shouldShowActualCost}
            toggleOpen={handleToggleActualCost}
            noBodyPadding
            divider
          >
            <TransferConfirmPricingSummary
              {...actualCostTableProps}
              shouldHideTitle
              totalCostLabel={<FormattedMessage {...replenishmentOrderDetailLabels.order.costs.actualTotalTitle} />}
            />
          </Accordion>
        </ContentBox>
      )}
      <ContentBox>
        {hasActualCostData ? (
          <Accordion
            indicator
            headerContent={
              <Text size="label" bold>
                <FormattedMessage {...replenishmentOrderDetailLabels.order.costs.estimatedCostTitle} />
              </Text>
            }
            isOpen={shouldShowEstimatedCost}
            toggleOpen={handleToggleEstimatedCost}
            noBodyPadding
            divider
          >
            <TransferConfirmPricingSummary {...estimatedCostTableProps} shouldHideTitle />
          </Accordion>
        ) : (
          <TransferConfirmPricingSummary {...estimatedCostTableProps} shouldHideTitle />
        )}
      </ContentBox>
    </Modal>
  );
};
