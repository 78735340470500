import React from "react";
import { useIntl } from "react-intl";
import { Appearance, Notification } from "common/components/ui";
import {
  MAX_TOTAL_VOLUME_CBM,
  MAX_TOTAL_VOLUME_CU_FT,
  MAX_TOTAL_WEIGHT_KG,
  MAX_TOTAL_WEIGHT_LB,
} from "inbounds/constants/cargoConstantsIntl";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";

export const DangerousGoodsNotAllowedError = () => {
  const { formatMessage } = useIntl();

  return (
    <Notification
      appearance={Appearance.DANGER}
      content={{
        title: formatMessage({
          id: "inbounds.createShipment.dangerousGoods.notAllowedError.title",
          defaultMessage: "Flexport currently does not support shipping dangerous goods.",
        }),
        description: formatMessage({
          id: "inbounds.createShipment.dangerousGoods.notAllowedError.description",
          defaultMessage: "We will reach out once we support shipping dangerous goods.",
        }),
      }}
    />
  );
};

export const DangerousGoodsWarning = () => {
  const { formatMessage } = useIntl();
  return (
    <Notification
      appearance={Appearance.WARNING}
      append={
        <ElevioLearnMoreButton articleId={ElevioArticleId.DangerousGoods}>
          {formatMessage({
            id: "inbounds.createShipment.dangerousGoods.warning.learnMore",
            defaultMessage: "Learn more",
          })}
        </ElevioLearnMoreButton>
      }
      content={{
        description: formatMessage({
          id: "inbounds.createShipment.dangerousGoods.warning.description",
          defaultMessage:
            "This shipment will require a product review and may need special handling during transportation. Additional handling fees may apply.",
        }),
      }}
    />
  );
};

export const OverVolumeWarning = () => {
  const { formatMessage, formatNumber } = useIntl();
  return (
    <Notification
      appearance={Appearance.DANGER}
      content={{
        title: formatMessage(
          {
            id: "inbounds.createShipment.overVolume.title",
            defaultMessage: "Your shipment exceeds the max volume of {maxVolumeCuFt} cu ft/{maxVolumeCbm} cbm.",
          },
          {
            // format.js does not support cubic units yet
            // https://formatjs.io/docs/polyfills/intl-numberformat#SupportedUnits
            maxVolumeCuFt: formatNumber(MAX_TOTAL_VOLUME_CU_FT),
            maxVolumeCbm: formatNumber(MAX_TOTAL_VOLUME_CBM),
          }
        ),
        description: formatMessage({
          id: "inbounds.createShipment.overVolume.description",
          defaultMessage: "Please split the shipment into multiple inbounds.",
        }),
      }}
    />
  );
};

export const OverWeightWarning = () => {
  const { formatMessage, formatNumber } = useIntl();
  return (
    <Notification
      appearance={Appearance.DANGER}
      content={{
        title: formatMessage(
          {
            id: "inbounds.createShipment.overWeight.title",
            defaultMessage: "Your shipment exceeds the max weight of {maxWeightLb}/{maxWeightKg}.",
          },
          {
            maxWeightLb: formatNumber(MAX_TOTAL_WEIGHT_LB, { style: "unit", unit: "pound" }),
            maxWeightKg: formatNumber(MAX_TOTAL_WEIGHT_KG, { style: "unit", unit: "kilogram" }),
          }
        ),
        description: formatMessage({
          id: "inbounds.createShipment.overWeight.description",
          defaultMessage: "Please split the shipment into multiple inbounds.",
        }),
      }}
    />
  );
};
