import { transferCreateChooseDestinationType } from "../../actions";
import { TransferCreationTypes } from "../../transferCreationTypes";
import { useDispatch } from "react-redux";
import { useStepManager } from "../../../../common/components/StepContainer";
import { FbaV2Steps } from "../../fbaV2Steps";

export const useSwitchToManualFba = () => {
  const dispatch = useDispatch();
  const { stepManager } = useStepManager();

  return () => {
    dispatch(transferCreateChooseDestinationType(TransferCreationTypes.Fba));
    stepManager.reassess(FbaV2Steps.SelectAmazonFacility);
  };
};
