import { FreightBolTracking, OrderResponse } from "@deliverr/freight-client";
import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import produce from "immer";
import { FreightOrderDetailActionTypes } from "./actions/FreightOrderDetailActionTypes";

export interface FreightOrderDetailState {
  detail: OrderResponse | null;
  tracking: FreightBolTracking | null;
  trackingError: Error | null;
  bol: Record<string, { url?: string; isBolComplete?: boolean }>;
  error: Error | null;
}

export const freightOrderDetailInitialState: FreightOrderDetailState = {
  detail: null,
  tracking: null,
  trackingError: null,
  bol: {},
  error: null,
};

export const freightOrderReducer = createReducer<FreightOrderDetailState>(freightOrderDetailInitialState, {
  ...handleSimpleReducerUpdates([
    FreightOrderDetailActionTypes.FREIGHT_ORDER_DETAIL_RECEIVED,
    FreightOrderDetailActionTypes.FREIGHT_ORDER_DETAIL_ERRORED,
  ]),
  [FreightOrderDetailActionTypes.FREIGHT_BOL_RECEIVED]: (state, { bolId, isBolComplete, url }) =>
    produce(state, (draftState) => {
      draftState[bolId] = {
        url: url.customerBolUrl,
        isBolComplete,
      };
    }),
  [FreightOrderDetailActionTypes.FREIGHT_ORDER_TRACKING_ERRORED]: (state, { error }) =>
    produce(state, (draftState) => {
      draftState.trackingError = error;
      draftState.tracking = null;
    }),
  [FreightOrderDetailActionTypes.FREIGHT_ORDER_TRACKING_RECEIVED]: (state, { tracking }) =>
    produce(state, (draftState) => {
      draftState.error = null;
      draftState.trackingError = null;
      draftState.tracking = tracking;
    }),
});
