import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isNil } from "lodash/fp";

import { getShowAdminFeatures } from "common/user/UserSelectors";
import { logError, logStart } from "Logger";
import { ProblemRowData } from "common/components/ProblemsTable/types";
import { useBlade } from "common/hooks/useBlade";
import { SellerPortalBlade } from "common/components/Blade/SellerPortalBlades";
import { getNonComplianceFlowRoute } from "inbounds/non-compliance/list/utils/getNonComplianceFlowRoute";
import { getIsUnacknowledgedProblemRow } from "common/components/ProblemsTable/getIsUnacknowledgedProblemRow";
import { useFetchUnexpectedSkuCases } from "./useFetchUnexpectedSkuCases";
import { SelectedShipmentProblem } from "inbounds/steps/ship/view/cards/ShipmentProblems/context";
import { compareShipmentProblemRows } from "inbounds/steps/ship/view/cards/ShipmentProblems/utils/compareShipmentProblemRows";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { ProblemType } from "common/problems/ProblemType";
import { getShipmentProblemsFromUnexpectedSkuCases } from "./utils/getShipmentProblemsFromUnexpectedSkuCases";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { SellerProblemStatus } from "@deliverr/legacy-inbound-client";

export const useShipmentProblems = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    showBlade: showShipmentProblemBlade,
    hideBlade: hideShipmentProblemBlade,
    isActive: isShipmentProblemBladeOpen,
  } = useBlade(SellerPortalBlade.PROBLEM_BLADE);
  const shouldShowAdminFeatures = useSelector(getShowAdminFeatures);

  const [selectedProblem, setSelectedProblem] = useState<SelectedShipmentProblem | undefined>();
  const {
    data: unexpectedSkuCases,
    refetch: refetchUnexpectedSkuCases,
    isLoading: isUnexpectedSkuCasesLoading,
  } = useFetchUnexpectedSkuCases();

  const refreshShipmentProblemRows = async (problemType: ProblemType) => {
    const ctx = { fn: "refreshShipmentProblemRows", problemType };
    try {
      dispatch(addLoader(InboundLoaderId.shipmentProblemsTable));

      switch (problemType) {
        case ProblemType.UNEXPECTED_SKU:
          await refetchUnexpectedSkuCases();
          break;
        default:
          logError(ctx, "Invalid ProblemType when attempting to refresh rows");
          break;
      }
    } catch (err) {
      logError({ ...ctx, err }, "Unable to refresh shipment problems data");
    } finally {
      dispatch(clearLoader(InboundLoaderId.shipmentProblemsTable));
    }
  };

  const shipmentProblemRows = useMemo(
    () => getShipmentProblemsFromUnexpectedSkuCases(unexpectedSkuCases).sort(compareShipmentProblemRows),
    [unexpectedSkuCases]
  );

  const selectedProblemCase = shipmentProblemRows.find(
    ({ id, problemType }) => id === selectedProblem?.id && problemType === selectedProblem?.problemType
  );

  const onRowClick = async (row: ProblemRowData) => {
    logStart({ fn: "useShipmentProblems.onRowClick", row });

    // send the seller to the non-compliance flow if they have not resolved the case
    if (row.status === SellerProblemStatus.CREATED && !isNil(row.id)) {
      const route = getNonComplianceFlowRoute(row.problemType);
      if (route !== null) {
        history.push(
          route.parse({
            nonCompliantId: row.id,
          })
        );
        return;
      }
    }

    setSelectedProblem({ id: row.id, problemType: row.problemType });
    showShipmentProblemBlade();

    // if the seller has not acknowledged the incident, mark it as acknowledged
    if (getIsUnacknowledgedProblemRow(row)) {
      // to avoid accidental acknowledgements from Flexport admins and thus users not seeing the row,
      // only acknowledge the incident if admin features are not enabled. This allows admins to acknowledge
      // the incident if they turn off admin features.
      if (!shouldShowAdminFeatures) {
        // force the table to refresh after acknowledging so that it reflects the server state
        await refreshShipmentProblemRows(row.problemType);
      }
    }
  };

  return {
    rows: shipmentProblemRows,
    onRowClick,
    selectedProblemCase,
    setSelectedProblem,
    isDataLoading: isUnexpectedSkuCasesLoading,
    isShipmentProblemBladeOpen,
    hideShipmentProblemBlade,
    showShipmentProblemBlade,
  };
};
