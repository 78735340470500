import classNames from "classnames/dedupe";
import React from "react";
import cls from "./NavBarProgress.less";

export interface ReduxProps {
  currentStep?: string;
}

export interface NavBarProgressRendererProps extends ReduxProps {
  steps: string[];
  pageType?: string;
}

export const NavBarProgressRenderer: React.FC<NavBarProgressRendererProps> = ({ currentStep, steps }) => {
  if (!currentStep) {
    return null;
  }

  const currentStepIndex = steps.indexOf(currentStep);
  return (
    <span className={cls.progress}>
      {steps.map((_, stepIndex) => (
        <div
          className={classNames(cls.step, {
            [cls.done]: stepIndex < currentStepIndex,
            [cls.current]: stepIndex === currentStepIndex,
          })}
          key={`step-${stepIndex}`}
        />
      ))}
    </span>
  );
};
