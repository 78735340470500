import { generatePath, useHistory } from "react-router-dom";
import { OrderStatus, Source } from "@deliverr/replenishment-client";
import { ErrorCode } from "@deliverr/wholesale-client";
import { Path } from "paths";
import { isEmpty, isNil, map, uniq } from "lodash";
import { ReplenishmentIndexRow } from "transfers/listV2/ReplenishmentIndexRow";
import { SPS_CHANNELS } from "./WholesaleListIngestionChannelFilter";
import { useCallback } from "react";

export const useWholesaleListTableV2 = () => {
  const history = useHistory();

  const getPoNumber = useCallback((item: ReplenishmentIndexRow): string => {
    return (
      item.shipments?.[0]?.poNumber ??
      item.shipments?.[0]?.distributionChannelShipmentId ??
      item.distributionChannelOrderId ??
      ""
    );
  }, []);

  const isInvalidItemOrder = useCallback((item: ReplenishmentIndexRow): boolean => {
    return (
      !isEmpty(item.invalidOrderItemStatus) &&
      !isNil(item.ingestionChannel) &&
      SPS_CHANNELS.includes(item.ingestionChannel)
    );
  }, []);

  const onRowClick = useCallback(
    async (item: ReplenishmentIndexRow) => {
      if (
        item.status === OrderStatus.DRAFT &&
        item.orderId?.startsWith("RP") &&
        isInvalidItemOrder(item) &&
        !item.errors?.includes(ErrorCode.OUT_OF_STOCK)
      ) {
        history.push(`${generatePath(Path.transfersNew)}?replenishmentOrderId=${item.orderId}&selectProvider=true`);
        return;
      }

      const isOrderStartsWithRP = item.orderId?.startsWith("RP");
      const isOrderPartOfWholesaleMigration =
        item.source === Source.WHOLESALE_MIGRATION || item.isWholesaleMigrationOrder;

      // If EDI Order starts with RP, it is flowing through replenishment flow
      const shouldEDIOrderPoweredByReplenishment =
        isOrderStartsWithRP || !SPS_CHANNELS.includes(item.ingestionChannel!);

      const shouldPowerDetailsByReplenishment =
        (isOrderStartsWithRP || isOrderPartOfWholesaleMigration) && shouldEDIOrderPoweredByReplenishment;

      history.push(
        generatePath(shouldPowerDetailsByReplenishment ? Path.wholesaleDetailV2 : Path.wholesaleDetail, {
          orderId: item.orderId,
        })
      );
    },
    [history, isInvalidItemOrder]
  );

  const getSkuCount = useCallback((item: ReplenishmentIndexRow) => {
    return uniq(map(item.orderItems, (orderItem) => orderItem.packOf)).length;
  }, []);

  const getTotalOrderQuantity = useCallback((item: ReplenishmentIndexRow) => {
    return item.orderItems.reduce((acc, orderItem) => acc + (orderItem.totalUnits ?? 0), 0);
  }, []);

  return {
    getPoNumber,
    onRowClick,
    getSkuCount,
    getTotalOrderQuantity,
  };
};
