import { useIntl } from "react-intl";
import { TransferCreationTypes } from "../transferCreationTypes";
import { transfersShipmentCreateLabels } from "../transfersShipmentCreate.labels";
import { useStepManager } from "common/components/StepContainer";
import { useSelector } from "react-redux";
import { selectTransferCreate } from "../store/TransferCreateSelectors";
import { CreateTransferLoader } from "../CreateTransferLoader.types";
import { ConnectDestinationLabel } from "./ConnectDestinationLabel";
import { useAmazonChannelIntegrationStatus } from "./fbaIntegration/useAmazonChannelIntegrationStatus";
import React, { useState } from "react";
import { useWalmartAuth } from "./wfs/useWalmartAuth";
import { selectAnyIsLoading } from "common/store/selectors/selectAnyIsLoading";

export const useTransferPickDestinationStep = () => {
  const { formatMessage } = useIntl();
  const { stepManager } = useStepManager();
  const { destinationType } = useSelector(selectTransferCreate);
  const [selectedDestination, setSelectedDestination] = useState(destinationType);
  const [isPendingNext, setPendingNext] = useState(false);
  const isLoading = useSelector(selectAnyIsLoading)(CreateTransferLoader.CreateTransferOrderLoader);
  const { isFbaCatalogSynced } = useAmazonChannelIntegrationStatus();
  const { isAuthorized: isWFSAuthorized } = useWalmartAuth();

  const onChangeDestination = (evt) => {
    const { value } = evt.currentTarget;
    setSelectedDestination(value);
  };

  const onManualTransferClick = () => {
    setSelectedDestination(TransferCreationTypes.Fba);
    stepManager.next(TransferCreationTypes.Fba);
  };

  const isNextDisabled = !selectedDestination;

  const transferPickDestinationOptions = [
    {
      disabled: false,
      value: TransferCreationTypes.Ecom,
      label: formatMessage(transfersShipmentCreateLabels.steps.destination.options.ecom.label),
      content: formatMessage(transfersShipmentCreateLabels.steps.destination.options.ecom.description),
    },
    {
      disabled: false,
      value: TransferCreationTypes.FbaIntegration,
      label: (
        <ConnectDestinationLabel
          isConnected={isFbaCatalogSynced}
          label={transfersShipmentCreateLabels.steps.destination.options.fbaIntegration.label}
        />
      ),
      content: formatMessage(transfersShipmentCreateLabels.steps.destination.options.fbaIntegration.description),
    },
  ];

  transferPickDestinationOptions.splice(1, 0, {
    disabled: false,
    value: TransferCreationTypes.Wfs,
    label: (
      <ConnectDestinationLabel
        isConnected={isWFSAuthorized}
        label={transfersShipmentCreateLabels.steps.destination.options.wfs.label}
      />
    ),
    content: formatMessage(transfersShipmentCreateLabels.steps.destination.options.wfs.description),
  });

  return {
    isPendingNext,
    setPendingNext,
    isNextDisabled,
    selectedDestination,
    isLoading,
    transferPickDestinationOptions,
    onChangeDestination,
    onManualTransferClick,
    formatMessage,
  };
};
