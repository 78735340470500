import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { SelectAsync, LocalizedMessage } from "common/components/ui";
import { SelectComponentsConfig } from "@deliverr/react-select-v1";
import { loadOptions } from "./loadAddressSearch";
import { AddressSearchOption } from "./AddressSearchOption";
import { SearchLoading, SearchNoOptions } from "../AsyncSelectComponents";
import { DeliverrAddress } from "@deliverr/commons-objects";

const StyledSelectAsync = styled(SelectAsync)`
  label {
    margin-bottom: 0;
  }
`;

const StyledMenu = styled.div`
  max-height: 30vh;
  overflow-y: auto;
`;

const customComponents: SelectComponentsConfig<any> = {
  LoadingMessage: SearchLoading,
  LoadingIndicator: null,
  MenuList: ({ children }) => <StyledMenu>{children}</StyledMenu>,
  NoOptionsMessage: SearchNoOptions,
  Option: AddressSearchOption,
};

interface AddressSearchProps {
  id?: string;
  placeholder?: LocalizedMessage;
  onSelectAddress: (address: DeliverrAddress) => void;
}

export const AddressSearch: FC<AddressSearchProps> = ({ placeholder, onSelectAddress, id }) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (value: string) => setInputValue(value);
  const isMenuOpen = inputValue.trim().length > 0;

  const handleChange = (address: DeliverrAddress) => {
    if (address) {
      onSelectAddress(address);
    }
  };

  return (
    <StyledSelectAsync
      id={id}
      captureMenuScroll={false}
      backspaceRemovesValue={false}
      classNamePrefix="address_search"
      customComponents={customComponents}
      isClearable
      isOptionDisabled={() => false}
      onInputChange={handleInputChange}
      loadOptions={loadOptions}
      menuIsOpen={isMenuOpen}
      onChange={handleChange}
      placeholder={
        placeholder ?? <FormattedMessage id="addressSearch.placeholder" defaultMessage="Search and select address" />
      }
      value={null}
    />
  );
};
