import { useSelector } from "react-redux";
import { getIntegrations } from "../../../../channels/ChannelsSelectors";
import { ListingSolution } from "../../../../common/clients/onboarding/ListingSolution";
import { SalesChannel } from "../../../../common/clients/onboarding/SalesChannel/SalesChannel";
import { SalesChannelConnectionStatus } from "../../../../common/clients/onboarding/SellerSalesChannels/SalesChannelConnectionStatus";
import history from "BrowserHistory";
import { generatePath } from "react-router-dom";
import { Path } from "paths";

export const useWalmartChannelIntegrationStatus: () => {
  isWalmartConnected: boolean;
  walmartChannel?: SalesChannel;
  onWalmartConnectClick: () => void;
} = () => {
  const integrations: Array<SalesChannel> = useSelector(getIntegrations);
  const walmartChannel = integrations.find((channel) => channel.salesChannelId === ListingSolution.WALMARTDIRECT);
  const isWalmartConnected = walmartChannel?.connectionStatus === SalesChannelConnectionStatus.CONNECTED;

  const slsUuid = walmartChannel?.slsUuid;

  const onWalmartConnectClick = () => {
    slsUuid
      ? history.push(generatePath(Path.channelsEdit, { slsUuid }))
      : history.push(generatePath(Path.channelsIntegrationSetup, { channel: ListingSolution.WALMARTDIRECT }));
  };

  return { isWalmartConnected, walmartChannel, onWalmartConnectClick };
};
