import { selectTreatmentValue } from "@splitsoftware/splitio-redux";
import { Selector } from "react-redux";
import { RootState } from "RootReducer";
import { FeatureName, SPLITS_WITH_DEFAULT_ON_TREATMENT } from "./FeatureName";
import { getSellerId } from "common/user/UserSelectors";

export const getFeatureSelector = (
  featureName: FeatureName,
  splitsWithDefaultOnTreatment: FeatureName[] = SPLITS_WITH_DEFAULT_ON_TREATMENT
): Selector<RootState, boolean> => (state) => {
  const sellerId = getSellerId(state);
  const isDefaultOn = splitsWithDefaultOnTreatment.includes(featureName);
  return selectTreatmentValue(state.splitIo, featureName, sellerId, isDefaultOn ? "on" : "off") === "on";
};
