import cx from "classnames";
import { Icon, Title } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import commonCls from "../../../../common/styles.less";
import cls from "./SelectAction.less";
import { useSelectAction } from "./useSelectAction";
import { RECONFIGURE_FACEBOOK, SYNC_AD_SETS, UNSYNC_AD_SETS } from "../../FacebookEditAction";
import { FastTagsNav } from "tags/base/FastTagsNav";
import { Path } from "paths";
import { generatePath } from "react-router-dom";
import { FastTagType } from "common/clients/fastTag/FastTagType";
import { FacebookEditFlowState } from "../../state/FacebookEditFlowState";
import { FastTagPageComponentProps } from "tags/model/FastTagPage";
import { fastTagPagePath } from "tags/model/path/fastTagPagePath";
import { fastTagServiceLabel } from "tags/model/fastTagServiceLabel";

export const SelectAction: FC<FastTagPageComponentProps<FacebookEditFlowState>> = ({ page, flow, state }) => {
  const { onSelectAction, selectedAction } = useSelectAction();

  return (
    <div className={commonCls.container}>
      <div className={commonCls.inner}>
        <Title displayAs="h4" className={cx(commonCls.title, commonCls.fullTitle)}>
          <FormattedMessage id="FacebookFastTag.SelectAction.Title" defaultMessage="Select Action" />
        </Title>
        <div className={cls.optionsContainer}>
          <div
            className={cx(cls.optionContainer, { [cls.selected]: selectedAction === UNSYNC_AD_SETS })}
            onClick={onSelectAction(UNSYNC_AD_SETS)}
          >
            <div className={cx(cls.selectMarker, cls.selected)}>
              <Icon className={cls.selectMarkerInner} type="check" />
            </div>
            <Title displayAs="h5">
              <FormattedMessage id="FacebookFastTag.Edit.UnsyncAdSets.Title" defaultMessage="Unsync ad sets" />
            </Title>
            <p>
              <FormattedMessage
                id="FacebookFastTag.Edit.UnsyncAdSets.Description"
                defaultMessage="Remove ad sets that are currently running."
              />
            </p>
          </div>

          <div
            className={cx(cls.optionContainer, { [cls.selected]: selectedAction === SYNC_AD_SETS })}
            onClick={onSelectAction(SYNC_AD_SETS)}
          >
            <div className={cls.selectMarker}>
              <Icon className={cls.selectMarkerInner} type="check" />
            </div>
            <Title displayAs="h5">
              <FormattedMessage id="FacebookFastTag.Edit.SyncAdSets.Title" defaultMessage="Sync ad sets" />
            </Title>
            <p>
              <FormattedMessage
                id="FacebookFastTag.Edit.SyncAdSets.Description"
                defaultMessage="Add Flexport targeting to your ad sets"
              />
            </p>
          </div>

          <div
            className={cx(cls.optionContainer, { [cls.selected]: selectedAction === RECONFIGURE_FACEBOOK })}
            onClick={onSelectAction(RECONFIGURE_FACEBOOK)}
          >
            <div className={cls.selectMarker}>
              <Icon className={cls.selectMarkerInner} type="check" />
            </div>
            <Title displayAs="h5">
              <FormattedMessage
                id="FacebookFastTag.SelectAction.ReconfigureFacebook.Title"
                defaultMessage="Reconfigure Facebook"
              />
            </Title>
            <p>
              <FormattedMessage
                id="FacebookFastTag.SelectAction.ReconfigureFacebook.Description"
                defaultMessage="Start over your Facebook {twoDay} delivery ads configuration"
                values={{
                  twoDay: fastTagServiceLabel[2],
                }}
              />
            </p>
          </div>
        </div>
      </div>
      <FastTagsNav
        nextPage={fastTagPagePath(flow.getNextPage(page, state))}
        nextDisabled={!selectedAction}
        previousPage={generatePath(Path.fastTagDetail, { program: FastTagType.FACEBOOK })}
        nextButtonText={<FormattedMessage id="next" defaultMessage={"Next"} />}
        previousButtonText={<FormattedMessage id="back" defaultMessage={"Cancel"} />}
      />
    </div>
  );
};
