import { toast } from "common/components/ui";
import { storageClient } from "common/clients/instances";
import { getSellerId } from "common/user/UserSelectors";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useAsyncFn } from "react-use";
import { getStorageInboundDetail } from "storage/inbounds/detail/store";

export const useAddContainerId = () => {
  const sellerId = useSelector(getSellerId);
  const { storageInboundOrder } = useSelector(getStorageInboundDetail);
  const { formatMessage } = useIntl();
  const [containerId, setContainerId] = useState<string>(storageInboundOrder.containerId ?? "");
  const [isUpdateDisabled, setIsUpdateDisabled] = useState<boolean>(true);

  const updateContainerId = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isUpdateDisabled) {
      setIsUpdateDisabled(false);
    }

    setContainerId(event.target.value);
  };

  const [updateContainerIdState, doUpdateContainerId] = useAsyncFn(async () => {
    try {
      await storageClient.patchStorageInbound(sellerId, storageInboundOrder.id, { containerId });
      setIsUpdateDisabled(true);
      toast.success(
        formatMessage({
          id: "storage.inbounds.detail.shipment.instruction.containerId.update.success",
          defaultMessage: "Container ID added",
        })
      );
    } catch (err) {
      setIsUpdateDisabled(false);
      toast.error(
        formatMessage({
          id: "storage.inbounds.detail.shipment.instruction.containerId.update.error",
          defaultMessage: "Container ID update failed",
        })
      );
    }
  }, [storageInboundOrder, sellerId, containerId]);

  const onUpdateClick = async () => {
    await doUpdateContainerId();
  };

  return {
    onUpdateClick,
    updateContainerId,
    containerId,
    updateContainerIdState,
    isUpdateDisabled: isUpdateDisabled || updateContainerIdState.loading,
  };
};
