import React, { FC } from "react";
import { DefaultTheme, Text } from "common/components/ui";
import styled from "@emotion/styled";
import { useIntl, FormattedPlural } from "react-intl";

import { ShippingPlanItemDetail } from "inbounds/InboundTypes";
import { assignCategoriesContent } from "./assignCategories.labels";

interface PlanItemSummaryProps {
  planItemDetail: ShippingPlanItemDetail;
}

const ItemName = styled(Text)<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S1};
    color: ${theme.colors.NEUTRAL["500"]};
  `
);

const ItemDetails = styled(Text)<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
    margin-right: ${theme.spacing.S4};
  `
);

export const PlanItemSummary: FC<PlanItemSummaryProps> = ({ planItemDetail }) => {
  const { name, msku, qty } = planItemDetail;
  const { formatMessage } = useIntl();

  return (
    <>
      <ItemName bold as="div">
        {name}
      </ItemName>
      <ItemDetails as="span" appearance="INFO" size="caption">
        {msku}
      </ItemDetails>
      {qty && (
        <ItemDetails as="span" appearance="INFO" size="caption">
          <FormattedPlural
            value={qty}
            one={formatMessage(assignCategoriesContent.singleUnit)}
            other={formatMessage(assignCategoriesContent.unitsPlural, { qty })}
          />
        </ItemDetails>
      )}
    </>
  );
};
