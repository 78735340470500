import React, { FC } from "react";
import { FormattedPlural, FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { DefaultTheme, Select, SelectProps } from "common/components/ui";
import { Card } from "common/components/Card";
import { InboundFooter } from "../InboundFooter";
import {
  InboundStepContainer,
  InboundStepContent,
  InboundStepDescription,
  InboundStepTitle,
} from "../InboundStepElements";
import { assignCategoriesContent as content } from "./assignCategories.labels";
import { ElevioBorderlessButton, ElevioArticleId } from "common/elevio";
import Table, { TableProps } from "common/components/lists/Table/Table";
import { ShippingPlanItemDetail } from "inbounds/InboundTypes";
import { useAssignProductCategories } from "./useAssignProductCategories";
import { PlanItemSummary } from "./PlanItemSummary";
import { InfoIcon, InfoIconProps } from "common/components/icons";
import { ProductPrepCategory } from "@deliverr/commons-clients";
import { SelectOption } from "../ship/labels/BoxLabelsSelect";
import { getPlanItemDetails } from "../InboundStepSelectors";
import { useSelector } from "react-redux";

const EmbeddedTable = styled(Table)<TableProps<ShippingPlanItemDetail>, DefaultTheme>(
  ({ theme }) => `
    margin-bottom: 0;
    box-shadow: none;
  `
);

const CategorySelect = styled(Select)<SelectProps, DefaultTheme>(
  ({ theme }) => `
    white-space: normal;
    > div[class*=menu] {
      z-index: ${theme.zindex.Z2};
    }
  `
);

const ProductsLabelHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DarkInfoIcon = styled(InfoIcon)<InfoIconProps, DefaultTheme>(
  ({ theme }) => `
    margin-left: ${theme.spacing.S1};
    color: ${theme.colors.NEUTRAL[500]};
    &:hover {
      color: ${theme.colors.NEUTRAL[300]};
    }
  `
);

interface ItemDetail extends ShippingPlanItemDetail {
  category?: ProductPrepCategory;
}

interface UnconnectedAssignProductCategoriesContentProp {
  allRowsSelected: boolean;
  anyRowsSelected: boolean;
  categorySelectOptions: SelectOption<ProductPrepCategory>[];
  itemDetails: ItemDetail[];
  productsSelected: number;
  selectedRows: Record<number, boolean>;
  setCategoryForSelectedItems: (value: ProductPrepCategory) => void;
  setItemCategory: (idx: number, value: ProductPrepCategory) => void;
  toggleAllRows: () => void;
  toggleRow: (idx: number, value: boolean) => void;
}

export const UnconnectedAssignProductCategoriesContent = (props: UnconnectedAssignProductCategoriesContentProp) => {
  const {
    allRowsSelected,
    anyRowsSelected,
    categorySelectOptions,
    itemDetails,
    productsSelected,
    selectedRows,
    setCategoryForSelectedItems,
    setItemCategory,
    toggleAllRows,
    toggleRow,
  } = props;
  return (
    <EmbeddedTable
      page="assignCategories"
      columns={[
        {
          width: "66%",
          renderCell: (planItemDetail: ShippingPlanItemDetail) => <PlanItemSummary planItemDetail={planItemDetail} />,
          header: productsSelected ? (
            <ProductsLabelHeader>
              <FormattedPlural
                value={productsSelected}
                one={<FormattedMessage {...content.productSelected} />}
                other={<FormattedMessage {...content.productsSelected} values={{ productsSelected }} />}
              />
              <span>
                <FormattedMessage {...content.prep} />
              </span>
            </ProductsLabelHeader>
          ) : (
            <FormattedMessage {...content.products} />
          ),
        },
        {
          width: "34%",
          renderCell: ({ category }: ShippingPlanItemDetail, idx) => (
            <CategorySelect
              name="assign-product-categories-category-select-cell"
              value={categorySelectOptions.find((option) => option.value === category)}
              placeholder={<FormattedMessage {...content.productCategoryPlaceholder} />}
              onChange={({ value }) => setItemCategory(idx, value)}
              options={categorySelectOptions}
            />
          ),
          header: productsSelected ? (
            <CategorySelect
              name="assign-product-categories-category-select-header"
              value={undefined}
              placeholder={
                <FormattedPlural
                  value={productsSelected}
                  one={<FormattedMessage {...content.productCategoryPlaceholder} />}
                  other={<FormattedMessage {...content.multipleCategories} />}
                />
              }
              onChange={({ value }) => setCategoryForSelectedItems(value)}
              options={categorySelectOptions}
            />
          ) : (
            <div>
              <FormattedMessage {...content.eachUnitPrep} />
              <DarkInfoIcon
                placement="top"
                message={<FormattedMessage {...content.productPrepTooltip} />}
                size="small"
                alignTop
              />
            </div>
          ),
        },
      ]}
      items={itemDetails}
      selectedRows={selectedRows}
      anyRowsSelected={!allRowsSelected && anyRowsSelected}
      onPageCheck={toggleAllRows}
      onRowCheck={toggleRow}
      pageChecked={allRowsSelected}
      hidePaginate
      onPageChange={() => {}}
    />
  );
};

export const AssignProductCategories: FC = () => {
  const planItemDetails = useSelector(getPlanItemDetails).filter((itemDetail) => !itemDetail.category);
  const {
    allRowsSelected,
    anyRowsSelected,
    categorySelectOptions,
    hasMissingCategories,
    itemDetails,
    productsSelected,
    selectedRows,
    setCategoryForSelectedItems,
    setItemCategory,
    onNextClick,
    onPreviousClick,
    toggleAllRows,
    toggleRow,
  } = useAssignProductCategories(planItemDetails);

  return (
    <InboundStepContainer>
      <Card>
        <InboundStepTitle>
          <FormattedMessage {...content.title} />
        </InboundStepTitle>
        <InboundStepDescription appearance="INFO">
          <FormattedMessage
            {...content.description}
            values={{
              productCategoriesLink: (
                <ElevioBorderlessButton
                  key={ElevioArticleId.ProductCategories}
                  articleId={ElevioArticleId.ProductCategories}
                >
                  <FormattedMessage {...content.productCategoriesLink} />
                </ElevioBorderlessButton>
              ),
            }}
          />
        </InboundStepDescription>
        <InboundStepContent>
          <UnconnectedAssignProductCategoriesContent
            allRowsSelected={allRowsSelected}
            anyRowsSelected={anyRowsSelected}
            categorySelectOptions={categorySelectOptions}
            itemDetails={itemDetails}
            productsSelected={productsSelected}
            selectedRows={selectedRows}
            setCategoryForSelectedItems={setCategoryForSelectedItems}
            setItemCategory={setItemCategory}
            toggleAllRows={toggleAllRows}
            toggleRow={toggleRow}
          />
        </InboundStepContent>
      </Card>
      <InboundFooter
        onButtonClick={onNextClick}
        onPreviousButtonClick={onPreviousClick}
        disabled={hasMissingCategories}
        manualLoader
      />
    </InboundStepContainer>
  );
};
