import { FormattedMessage } from "react-intl";
import { shippingTypeMessages } from "./ReplenishmentOrderShippingTileMessages";
import React, { FC, useCallback } from "react";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { Amount } from "../../fba/shipping/AmazonShipmentType";
import { Box, Radio, ThemeProps, Text } from "common/components/ui";
import { WholesaleShipmentType, WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { ShipmentResponse, ShippingType } from "@deliverr/replenishment-client";
import { Estimation } from "./Estimation";
import { BorderlessButton } from "common/components/BorderlessButton";
import styled from "@emotion/styled";
import { LocalizedText } from "common/components/LocalizedText";
import { useDispatch, useSelector } from "react-redux";
import { transferCreateSetShipmentShippingMethod } from "transfers/create/actions";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";

const StyledButton = styled(BorderlessButton)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.BLUE["300"]};
  font-weight: ${theme.font.weight.BOLD};
  margin-left: ${theme.spacing.S2};
  `
);

export const RadioLabel = styled(LocalizedText)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[500]};
`
);

export interface SelectMultipleShipmentRadioOptionProps {
  estimation?: Estimation;
  shipment: ShipmentResponse;
  shippingType: ShippingType;
  shippingMethod: WholesaleShippingMethod;
  retryShippingEstimation: (
    shippingMethod: WholesaleShippingMethod,
    destinationType: TransferCreationTypes,
    shippingType: ShippingType
  ) => Promise<void>;
}

export const SelectMultipleShipmentRadioOption: FC<SelectMultipleShipmentRadioOptionProps> = ({
  shipment,
  estimation,
  shippingType,
  shippingMethod,
  retryShippingEstimation,
}) => {
  const shippingPartnerMessage = shippingTypeMessages[shippingType]!;
  const dispatch = useDispatch();
  const { destinationType, shipmentShippingMethodMap } = useSelector(selectTransferCreate);

  const onChangeShipmentShippingMethod = useCallback(
    (shipmentId: string, shippingMethodValue: WholesaleShippingMethod, shipmentType: WholesaleShipmentType) => {
      dispatch(transferCreateSetShipmentShippingMethod(shipmentId, shippingMethodValue, shipmentType));
    },
    [dispatch]
  );

  const estimatedNumOfPallets =
    (estimation?.shipmentEstimationBreakdown?.[shipment.shipmentId]?.payload as any)?.response?.quantity ?? 1;
  let content = (
    <div>
      <FormattedMessage {...transfersShipmentCreateLabels.steps.shipping.cargoOptions.estimating} />
    </div>
  );
  if (estimation?.hasError && estimation.errorMessage) {
    content = (
      <div>
        {estimation?.shipmentErrorMessageBreakdown?.[shipment.shipmentId] ?? estimation.errorMessage}
        {(estimation.retryable === undefined || estimation.retryable) && (
          <StyledButton
            onClick={async () => await retryShippingEstimation(shippingMethod, destinationType!, shippingType)}
          >
            <FormattedMessage id={"replenishment.create.steps.shipping.cargoOptions.retry"} defaultMessage="Retry" />
          </StyledButton>
        )}
      </div>
    );
  }
  if (!estimation?.isPending && estimation?.estimation) {
    content =
      shippingType === ShippingType.PARCEL ? (
        <Amount value={estimation?.shipmentEstimationBreakdown?.[shipment.shipmentId]?.totalEstimatedCost ?? 0} />
      ) : (
        <FormattedMessage
          {...transfersShipmentCreateLabels.steps.shipping.cargoOptions.pallet.estimatedPalletShippingCost}
          values={{
            formattedAmount: (
              <Amount value={estimation?.shipmentEstimationBreakdown?.[shipment.shipmentId]?.totalEstimatedCost ?? 0} />
            ),
            numOfPallets: estimatedNumOfPallets,
          }}
        />
      );
  }
  return (
    <>
      <Radio
        value={shippingPartnerMessage?.value}
        disabled={estimation?.disabled}
        label={<RadioLabel message={shippingPartnerMessage?.label} />}
        onClick={(evt) =>
          onChangeShipmentShippingMethod(
            shipment.shipmentId,
            shippingMethod,
            evt.currentTarget.value as WholesaleShipmentType
          )
        }
        checked={shipmentShippingMethodMap[shippingMethod][shipment.shipmentId] === shippingPartnerMessage?.value}
      />
      {!estimation?.hideEstimation && (
        <Box paddingLeft="S5">
          <Text size="caption" appearance="INFO">
            {content}
          </Text>
        </Box>
      )}
    </>
  );
};
