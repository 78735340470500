import { createSelector } from "reselect";
import { getStorageInboundCreate } from "storage/inbounds/create/store/selector/getStorageInboundCreate";
import { selectTotalSingleSkuPackages } from "./selectTotalSingleSkuPackages";
import { selectTotalMixedSkuPackages } from "./selectTotalMixedSkuPackages";
import { selectIsDTCNetwork } from "./selectIsDTCNetwork";
import { selectIsCargoMixedSku } from "./selectIsCargoMixedSku";

export const selectTotalSkus = createSelector(
  getStorageInboundCreate,
  selectTotalSingleSkuPackages,
  selectTotalMixedSkuPackages,
  selectIsDTCNetwork,
  selectIsCargoMixedSku,
  (storageInboundCreate, totalSingleSkuPackages, totalMixedSkuPackages, isDTCNetwork, isCargoMixedSku): number => {
    const storageInboundTotalSku = Object.keys(storageInboundCreate.selectedProducts).length;
    return isDTCNetwork ? (isCargoMixedSku ? totalMixedSkuPackages : totalSingleSkuPackages) : storageInboundTotalSku;
  }
);
