import { ApiClientConfig } from "../core/ApiClientConfig";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
import { mustBeDefined } from "common/utils/mustBeDefined";
import { handleSellerServiceError } from "./handleSellerServiceError";

export type AdminClientConfig = ApiClientConfig;

export class AdminClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: AdminClientConfig = { baseURL: mustBeDefined(process.env.SELLER_SERVICE_URL) }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1/admin`,
    });
  }

  async impersonateOrganization(sellerId: string): Promise<void> {
    try {
      await this.apiClient.put({
        url: `/organization/${sellerId}/impersonate`,
        authentication: isAuthenticated,
      });
    } catch (error) {
      handleSellerServiceError("impersonateOrganization", error);
    }
  }
}
