import { Notification } from "common/components/ui";
import React, { FC } from "react";
import { useIntl } from "react-intl";

export const NetworkErrorNotification: FC<{ errorMessage: string }> = ({ errorMessage }) => {
  const { formatMessage } = useIntl();
  return (
    <Notification
      appearance="DANGER"
      content={{
        title: formatMessage({
          id: "transfers.error",
          defaultMessage: `${errorMessage}`,
        }),
      }}
    />
  );
};
