import classNames from "classnames/dedupe";
import { isUndefined } from "lodash/fp";
import React, { ReactNode } from "react";
import { connect } from "react-redux";

import WithLoader from "common/components/WithLoader";
import { toast } from "common/components/ui";
import {
  addLoader as addLoaderAction,
  clearLoader as clearLoaderAction,
} from "common/components/WithLoader/LoadingActions";
import { ActionCreator } from "common/ReduxUtils";
import log from "Logger";
import { RootState } from "RootReducer";

export enum ButtonType {
  Primary = "btn-primary",
  Success = "btn-success",
  Danger = "btn-danger",
  PrimaryReversed = "btn-primary-reversed",
  SuccessReversed = "btn-success-reversed",
  DangerReversed = "btn-danger-reversed",
  Admin = "btn-admin",
  AdminReversed = "btn-admin-reversed",
}

export enum ButtonSize {
  xs = "xs",
  sm = "sm",
  lg = "lg",
  "2x" = "2x",
}

interface ReduxProps {
  loaders: string[];
}

interface DispatchProps {
  addLoader: ActionCreator;
  clearLoader: ActionCreator;
}

export interface LoaderButtonProps {
  name: string;
  size?: ButtonSize;
  id?: string;
  buttonClass?: string;
  buttonType?: ButtonType;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  buttonProps?: {};
  // if set to true, will hide while loading instead of showing spinner
  hideSpinner?: boolean;
  manualLoader?: boolean; // control loader manually even with on click function
  loaderText?: ReactNode | string;
}

interface State {
  disabled: boolean;
}

class LoaderButtonBase extends React.Component<ReduxProps & DispatchProps & LoaderButtonProps, State> {
  public displayLoader = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const { addLoader, clearLoader, name, onClick } = this.props;
    if (!onClick) {
      return;
    }

    if (!this.props.manualLoader) {
      addLoader(name);
    }

    try {
      await onClick(e);
    } catch (err) {
      log.error({ fn: "LoaderButton.onClick", err }, "uncaught error during onClick");
      toast.error(err, { toastId: "uncaughtOnClickError" });
    } finally {
      if (!this.props.manualLoader) {
        clearLoader(name);
      }
    }
  };

  public render() {
    const {
      buttonClass,
      buttonProps,
      buttonType,
      children,
      disabled,
      id,
      hideSpinner,
      loaders,
      onFocus,
      onMouseEnter,
      onMouseLeave,
      name,
      size,
    } = this.props;
    const isLoading = loaders.includes(name);

    return (
      <button
        id={id ?? ""}
        className={classNames("btn", buttonType, buttonClass)}
        disabled={isUndefined(disabled) ? isLoading : disabled || isLoading}
        type="button"
        onClick={this.displayLoader}
        onFocus={onFocus}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...buttonProps}
      >
        {hideSpinner ? (
          children
        ) : (
          <WithLoader name={name} size={size} loaderText={this.props.loaderText}>
            {children}
          </WithLoader>
        )}
      </button>
    );
  }
}

export const LoaderButton = connect<ReduxProps, DispatchProps, LoaderButtonProps>(
  (state: RootState): ReduxProps => ({
    loaders: state.loading.loaders,
  }),
  { addLoader: addLoaderAction, clearLoader: clearLoaderAction }
)(LoaderButtonBase);
