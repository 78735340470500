import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { DefaultTheme, ThemeProps, Stack } from "common/components/ui";
import styled from "@emotion/styled";

import { ProblemBaseBlade } from "common/components/ProblemBlade/ProblemBaseBlade";
import { Currency, DEFAULT_CURRENCY } from "common/components/Currency";
import { ProblemRowData, RelabeledBoxProblemRow } from "common/components/ProblemsTable/types";
import { SHIPMENT_PROBLEMS_RELABELED_BOX_BLADE_ID } from "../blades";
import { BigTextProblemDetailItem } from "common/components/ProblemBlade/components/ProblemDetailItem";
import { EstimatedTotalChargeTable } from "common/components/ProblemBlade/components/EstimatedTotalChargeTable";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";
import { useShipmentProblemRelabeledBoxBlade } from "./useShipmentProblemRelabeledBoxBlade";
import { PROBLEM_TYPE_LABEL_MAP } from "common/problems/problemTypeLabelMap";
import { ProblemType } from "common/problems/ProblemType";
import { SelectedShipmentProblem } from "../../context";

const MessageContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
  padding-bottom: ${theme.spacing.S5};
`
);

export interface ShipmentProblemRelabeledBoxBladeProps {
  shipmentProblem: RelabeledBoxProblemRow;
  hideShipmentProblemBlade: () => void;
  setSelectedProblem: (problem: SelectedShipmentProblem | undefined) => void;
  isShipmentProblemBladeOpen: boolean;
  selectedProblemCase: ProblemRowData | undefined;
}

const DetailRow = styled.dl<{ cost?: boolean }, DefaultTheme>(
  ({ theme, cost }) => `
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: ${theme.spacing.S2} 0;
    padding: ${theme.spacing.S5};
    border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
    border-radius: ${theme.border.radius.R4};
    ${cost ? `background-color: ${theme.colors.NEUTRAL[20]}` : ""};
  `
);

export const ShipmentProblemRelabeledBoxBlade: FC<ShipmentProblemRelabeledBoxBladeProps> = ({
  shipmentProblem,
  hideShipmentProblemBlade,
  setSelectedProblem,
  isShipmentProblemBladeOpen,
  selectedProblemCase,
}) => {
  const { estimateTotalChargeTableProps, resetBladeState } = useShipmentProblemRelabeledBoxBlade(
    shipmentProblem,
    hideShipmentProblemBlade,
    setSelectedProblem
  );

  return (
    <ProblemBaseBlade
      id={SHIPMENT_PROBLEMS_RELABELED_BOX_BLADE_ID}
      title={PROBLEM_TYPE_LABEL_MAP[ProblemType.RELABELED_BOX]}
      isBladeOpen={isShipmentProblemBladeOpen}
      onCloseClick={resetBladeState}
      updatedAt={selectedProblemCase?.updatedAt}
    >
      <Stack>
        <MessageContainer>
          <FormattedMessage
            id="inbounds.shipmentProblemRelabeledBoxBlade.message"
            defaultMessage="All boxes must arrive with {boxContentsLabels} to be processed through automation. Boxes arriving without labels (or with non-scannable labels) are relabeled by Flexport at a per box {ncFee}. "
            values={{
              boxContentsLabels: (
                <ElevioBorderlessButton articleId={ElevioArticleId.BoxContentsLabelingReqs}>
                  <FormattedMessage
                    id="inbounds.shipmentProblemRelabeledBoxBlade.boxContentsLabelsLink"
                    defaultMessage="Box Contents Labels"
                  />
                </ElevioBorderlessButton>
              ),
              ncFee: (
                <ElevioBorderlessButton articleId={ElevioArticleId.NCFees}>
                  <FormattedMessage id="inbounds.shipmentProblemRelabeledBoxBlade.ncFeesLink" defaultMessage="fee" />
                </ElevioBorderlessButton>
              ),
            }}
          />
        </MessageContainer>
        <DetailRow cost>
          <BigTextProblemDetailItem
            label={
              <FormattedMessage
                id="inbounds.shipmentProblemRelabeledBoxesBlade.totalCharge"
                defaultMessage="Total charge"
              />
            }
            value={<Currency value={0} currency={DEFAULT_CURRENCY} />}
            showIcon
          />
          <EstimatedTotalChargeTable {...estimateTotalChargeTableProps} />
        </DetailRow>
        {/* Commenting this table out for now while Product/Design decide what to do with it, MG currently asked to remove it
          <DetailRow>
            <Text size="bodyLarge">
              <FormattedMessage
                id="inbounds.shipmentProblemRelabeledBoxesBlade.boxContents"
                defaultMessage="Box Contents"
              />
            </Text>
            <ShipmentProblemBladeTable {...relabeledBoxContentsTableProps} />
          </DetailRow> */}
      </Stack>
    </ProblemBaseBlade>
  );
};
