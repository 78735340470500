import { NetworkInboundShippingOption } from "@deliverr/legacy-inbound-client";
import { ShippingMethod } from "inbounds/ShippingMethod";

// maps shipping method to the option sent to the BE
// FTL external is treated as LTL on the BE, as they are both external freight shipments
// FTL deliverr creates shipments through an external form, so it does not require this mapping
export const SHIPPING_METHOD_TO_OPTION: Record<ShippingMethod, NetworkInboundShippingOption> = {
  [ShippingMethod.SPD_DELIVERR]: NetworkInboundShippingOption.SPD,
  [ShippingMethod.SPD_EXTERNAL]: NetworkInboundShippingOption.SPD_EXTERNAL,
  [ShippingMethod.METRO_DELIVERR]: NetworkInboundShippingOption.LTL_DELIVERR,
  [ShippingMethod.LTL_DELIVERR]: NetworkInboundShippingOption.LTL_DELIVERR,
  [ShippingMethod.LTL_EXTERNAL]: NetworkInboundShippingOption.LTL_EXTERNAL,
  [ShippingMethod.FTL_EXTERNAL]: NetworkInboundShippingOption.FTL_EXTERNAL,
  [ShippingMethod.FTL_DELIVERR]: NetworkInboundShippingOption.FTL_DELIVERR,
};
