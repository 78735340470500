import { createSelector } from "reselect";
import { getLoadedShipment } from "./getLoadedShipment";

export const getLoadedShipmentStatus = createSelector(getLoadedShipment, (shipment) => {
  if (!shipment) {
    // In reality, this should never be thrown as it should be caught in getLoadedShipment.
    // Adding for extra safety.
    throw new Error("No loaded shipment found while attempting to get status.");
  }
  return shipment.status;
});
