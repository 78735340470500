export enum Zindex {
  "Z0" = "Z0",
  "Z1" = "Z1",
  "Z2" = "Z2",
  "Z3" = "Z3",
  "Z4" = "Z4",
  "Z5" = "Z5",
  "Z6" = "Z6",
}

export const zindex: Record<Zindex, number | string> = {
  [Zindex.Z0]: 0, // reset
  [Zindex.Z1]: 100, // whatever
  [Zindex.Z2]: 200, // nav
  [Zindex.Z3]: 300, // input popups
  [Zindex.Z4]: 400, // banners
  [Zindex.Z5]: 500, // dialog
  [Zindex.Z6]: 600, // dev
};
