import { storageClient } from "common/clients/instances";
import { SPThunkAction } from "common/ReduxUtils";
import { getSellerId } from "common/user/UserSelectors";
import { divide } from "lodash/fp";
import { logError } from "Logger";
import { freightClient, productClient } from "Clients";
import { setStorageInboundCreateDraft } from "./setStorageInboundCreateDraft";
import { getStorageProductDimensions } from "../../cargoType/actions/getStorageProductDimensions";
import { FormattedMessage } from "react-intl";
import { toast } from "common/components/ui";
import React from "react";
import { CreateStorageInboundLoader } from "../../CreateStorageInboundLoader.types";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { setFromAddress } from "inbounds/steps/address/AddressActions";
import { batch } from "react-redux";
import { getProductIsLotTrackingEnabled } from "common/utils/product/getProductIsLotTrackingEnabled";
import { getProductIsFefoEnabled } from "common/utils/product/getProductIsFefoEnabled";
import { LotInformationFormValues } from "inbounds/steps/lot-information/types/LotInformationFormValues";
import { mapStorageItemToProductLotInformationFields } from "storage/inbounds/utils/mapStorageItemToProductLotInformationFields";
import { CaseDimensionCollection } from "../../dimensions/CaseDimensionCollection.types";
import { keyBy, values } from "lodash";
import { setSelectedQuoteDetails } from "../../selectShipping/actions/setSelectedQuoteDetails";
import { FeatureName, isFeatureOn } from "common/Split";
import { CarrierType, ShipmentType } from "@deliverr/storage-client";
import { StorageInboundCreateActionTypes } from "../StorageInboundCreateAction.types";
import { goToCreateInbound } from "inbounds/createShipment/store/actions";
import { setDomesticStorageIPBState } from "inbounds/createShipment/store/actions/setDomesticStorageIPBState";

export const loadStorageInboundCreateDraft =
  (shippingPlanId: number): SPThunkAction =>
  async (dispatch, getState) => {
    dispatch({ type: StorageInboundCreateActionTypes.LOAD_STORAGE_INBOUND_DRAFT });
    dispatch(addLoader(CreateStorageInboundLoader.LoadStorageInboundCreateDraft));
    const isMultiPackEnabled = isFeatureOn(FeatureName.StorageMultiCasePack);
    try {
      const sellerId = getSellerId(getState());

      const storageInboundResponse = (await storageClient.getStorageInboundByShippingPlanId(sellerId, shippingPlanId))
        ?.value;

      let caseSkuToProductSku;
      let selectedProductsCasePacks;

      if (isMultiPackEnabled) {
        const productCasePacks = await productClient.getProductCasePacks(
          storageInboundResponse?.storageItems.map((storageItem) => storageItem.dsku) ?? [],
          { includeProductInformation: true }
        );

        caseSkuToProductSku = values(productCasePacks)?.reduce((acc, pack) => {
          acc[pack.dsku] = pack?.productDetails?.dsku;
          return acc;
        }, {});

        selectedProductsCasePacks = keyBy(values(productCasePacks), (casePack) => casePack.packOf);
      } else {
        caseSkuToProductSku = {};
        selectedProductsCasePacks = {};
      }

      const dskus = storageInboundResponse?.storageItems.map((storageItem) =>
        getStorageItemSku(storageItem.dsku, caseSkuToProductSku)
      );

      const freightOrderDetails = storageInboundResponse?.freightOrderDetails;

      const quoteResponse =
        freightOrderDetails?.quoteId && (await freightClient.getQuoteByQuoteId(freightOrderDetails?.quoteId, sellerId));

      const selectedProductsCaseDimensions: CaseDimensionCollection = (
        storageInboundResponse?.storageItems ?? []
      ).reduce((map, storageItem) => {
        const dsku = getStorageItemSku(storageItem.dsku, caseSkuToProductSku);
        map[dsku] = {
          weight: storageItem.caseWeight,
          height: storageItem.caseHeight,
          width: storageItem.caseWidth,
          length: storageItem.caseLength,
          weightUnit: "lb",
          lengthUnit: "in",
        };
        return map;
      }, {});

      const [storageItemDetails, storageWarehouses] = await Promise.all([
        dskus && (await productClient.getUnifiedProducts(dskus, { includeProductCasePacks: true })),
        (await storageClient.getStorageWarehouses())?.value ?? [],
      ]);

      const selectedProductsLotInformation: LotInformationFormValues = (storageInboundResponse?.storageItems ?? [])
        .filter(({ dsku }) => {
          const productSku = getStorageItemSku(dsku, caseSkuToProductSku);
          return (
            storageItemDetails?.[productSku] &&
            (getProductIsLotTrackingEnabled(storageItemDetails?.[productSku]) ||
              getProductIsFefoEnabled(storageItemDetails?.[productSku]))
          );
        })
        .reduce((acc, storageItem) => {
          acc[getStorageItemSku(storageItem.dsku, caseSkuToProductSku)] =
            mapStorageItemToProductLotInformationFields(storageItem);
          return acc;
        }, {});

      const selectedProducts = storageInboundResponse?.storageItems.reduce((map, storageItem) => {
        const productSku = getStorageItemSku(storageItem.dsku, caseSkuToProductSku);
        map[productSku] = {
          dsku: productSku,
          caseQty: Number(storageItem.caseQty),
          qty: Number(storageItem.expectedQty),
          numberOfCases: divide(Number(storageItem.expectedQty), Number(storageItem.caseQty)),
          productCasePackOptions: keyBy(
            storageItemDetails?.[productSku].productCasePacks ?? [],
            (casePack) => casePack.dsku
          ),
        };
        return map;
      }, {});

      // set state for IPB steps
      dispatch(setDomesticStorageIPBState());

      await dispatch(
        setStorageInboundCreateDraft({
          storageInboundOrderId: storageInboundResponse?.id,
          shippingPlanId: storageInboundResponse?.shippingPlanId,
          cargoType: storageInboundResponse?.shipmentType ?? ShipmentType.PALLETIZED,
          numOfPallets: storageInboundResponse?.palletCount,
          containerSize: storageInboundResponse?.containerSize,
          containerId: storageInboundResponse?.containerId,
          selectedProducts,
          selectedProductsCasePacks,
          productDetails: storageItemDetails,
          selectedProductsCaseDimensions,
          selectedProductsLotInformation,
          internalStatus: storageInboundResponse?.internalStatus,
          storageWarehouses,
          freightOrderDetails,
          palletConfigurations: storageInboundResponse?.palletConfigurations,
          isLimitedAccess: !!freightOrderDetails?.pickupDetails?.isLimitedAccess,
          isResidential: !!freightOrderDetails?.pickupDetails?.location?.isResidential,
          carrierType: storageInboundResponse?.carrierType ?? CarrierType.DELIVERR,
        })
      );

      await batch(async () => {
        quoteResponse && (await dispatch(setSelectedQuoteDetails(quoteResponse)));
        await dispatch(getStorageProductDimensions());
        storageInboundResponse?.from && (await dispatch(setFromAddress(storageInboundResponse.from)));
      });

      dispatch(goToCreateInbound());
    } catch (err) {
      logError({ fn: "loadStorageInboundCreateDraft" }, err);
      toast.error(
        <FormattedMessage
          id="storage.inbounds.create.loadStorageInboundCreateDraft"
          defaultMessage="Loading draft inbound failed."
        />,
        {
          autoClose: 5000,
          toastId: "loadStorageInboundCreateDraft",
        }
      );
      return false;
    } finally {
      dispatch(clearLoader(CreateStorageInboundLoader.LoadStorageInboundCreateDraft));
    }
    return true;
  };

const getStorageItemSku = (dsku: string, skuToCaseSku: { [dsku: string]: string }) => skuToCaseSku[dsku] ?? dsku;
