import React from "react";
import { SingleSkuPackageCollection } from "inbounds/createShipment/CreateShipmentTypes";
import { UnconnectedStorageInboundProductChooser } from "../StorageInboundProductChooser/StorageInboundProductChooser";
import { useIntlDtcInboundProductChooser } from "./useIntlDtcInboundProductChooser";

export const IntlDtcInboundProductChooser = ({
  isDtcSingleSkuBooking,
  isDtcMixedSkuBooking,
  singleSkuPackages,
  isIntlRs,
  isBulkUploadButtonEnabled = true,
  index,
}: {
  isDtcSingleSkuBooking?: boolean;
  isDtcMixedSkuBooking?: boolean;
  singleSkuPackages?: SingleSkuPackageCollection;
  isIntlRs?: boolean;
  isBulkUploadButtonEnabled?: boolean;
  index?: number;
}) => {
  const connectedProps = useIntlDtcInboundProductChooser(isDtcMixedSkuBooking, index, singleSkuPackages);

  return (
    <UnconnectedStorageInboundProductChooser
      {...connectedProps}
      isDtcSingleSkuBooking={isDtcSingleSkuBooking}
      isDtcMixedSkuBooking={isDtcMixedSkuBooking}
      singleSkuPackages={singleSkuPackages ?? {}}
      isIntlRs={isIntlRs}
      isBulkUploadButtonEnabled={isBulkUploadButtonEnabled}
      index={index}
    />
  );
};
