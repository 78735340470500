import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { StyledAnchor } from "transfers/ui/StyledAnchor";
import { replenishmentOrderDestinationMap } from "./ReplenishmentOrderConfirmationMessages";
import { DistributionChannel } from "@deliverr/replenishment-client";
import { replenishmentShipmentCreateLabels } from "../replenishmentShipmentCreate.labels";

export const ReplenishmentOrderMultipleDestination: FC<{
  onViewMoreClickonShipmentsModal?: (e) => void;
  distributionChannel: DistributionChannel;
}> = ({ onViewMoreClickonShipmentsModal, distributionChannel }) => {
  const labels = replenishmentShipmentCreateLabels.shipmentDetails;

  return (
    <>
      <FormattedMessage {...replenishmentOrderDestinationMap[distributionChannel]} />
      <br />
      <FormattedMessage {...labels.multipleLocation} />
      <br />
      <StyledAnchor data-testid="view-more-replenishment-shipments" onClick={onViewMoreClickonShipmentsModal}>
        <FormattedMessage {...labels.viewDetails} />
      </StyledAnchor>
    </>
  );
};
