import { TimeWindow } from "@deliverr/commons-objects";

import { FacilityOperatingHoursInputNames } from "common/components/FacilityOperatingHours/facilityOperatingHoursTypes";

export interface CargoPickupDetailsState {
  requiresLiftgate: boolean;
  facilityOperatingHours?: TimeWindow;
  customerReferenceNumber?: string;
  pickupReferenceNumber: string;
  appointmentDate?: Date;
}

export interface ValidCargoPickupDetails {
  requiresLiftgate: boolean;
  facilityOperatingHours: TimeWindow;
  customerReferenceNumber: string;
  pickupReferenceNumber: string;
  appointmentDate: Date;
}

export enum CargoPickupInputNames {
  READY_DATE = "READY_DATE",
  CUSTOMER_REFERENCE_NUMBER = "CUSTOMER_REFERENCE_NUMBER",
  LIFTGATE_REQUIRED = "LIFTGATE_REQUIRED",
  PICKUP_REFERENCE_NUMBER = "PICKUP_REFERENCE_NUMBER",
}

export interface CargoPickupInputValues {
  [CargoPickupInputNames.READY_DATE]: Date;
  [CargoPickupInputNames.CUSTOMER_REFERENCE_NUMBER]: string;
  [CargoPickupInputNames.PICKUP_REFERENCE_NUMBER]: string;
  [FacilityOperatingHoursInputNames.OPERATING_HOURS_START]: string;
  [FacilityOperatingHoursInputNames.OPERATING_HOURS_END]: string;
  [CargoPickupInputNames.LIFTGATE_REQUIRED]: boolean;
}
