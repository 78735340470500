import { FC } from "react";
import { MessageDescriptor } from "react-intl";

export enum ShipmentInstructionsStepId {
  DOWNLOAD_PALLET_LABEL = "DOWNLOAD_PALLET_LABEL",
  SEND_SHIPMENT = "SEND_SHIPMENT",
  ADD_CONTAINER_ID = "ADD_CONTAINER_ID",
  ADVANCED_SCHEDULE_APPOINTMENT = "ADVANCED_SCHEDULE_APPOINTMENT",
  PACKING_INSTRUCTIONS = "PACKING_INSTRUCTIONS",
  PICKUP_DETAILS = "PICKUP_DETAILS",
  DOWNLOAD_BOL = "DOWNLOAD_BOL",
  SEND_AND_TRACK_SHIPMENT = "SEND_AND_TRACK_SHIPMENT",
}

export interface ShipmentInstructionsStepType {
  id: ShipmentInstructionsStepId;
  title: MessageDescriptor;
  component: FC;
}
