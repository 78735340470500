import { CognitoRefreshToken } from "amazon-cognito-identity-js";
import * as pify from "pify";

import * as clientMap from "Clients";
import log from "Logger";
import store from "store";

export const forceRefreshSession = async () => {
  const ctx = { fn: "forceRefreshSession" };
  const state = store.getState();

  if (state.cognito?.user) {
    const { user } = state.cognito;
    const refreshToken = new CognitoRefreshToken({
      RefreshToken: user.signInUserSession.refreshToken.token,
    });
    await pify(user.refreshSession.bind(user))(refreshToken);
    log.info(ctx, "session refreshed");
  }
};

export const refreshSessionIfExpired = async (onSessionRefresh?: (session) => any, onUnexpiredSession?: () => any) => {
  const user = store.getState().cognito?.user;
  const currentTime = Date.now();
  const expirationTime = user?.signInUserSession?.idToken?.payload?.exp;

  const ctx = { fn: "refreshSessionIfExpired" };

  if (expirationTime && currentTime > expirationTime * 1000 && user) {
    log.info({ ...ctx, currentTime, expirationTime }, "session expired, refreshing");
    const refreshToken = new CognitoRefreshToken({
      RefreshToken: user.signInUserSession.refreshToken.token,
    });
    const session = await pify(user.refreshSession.bind(user))(refreshToken);
    log.info(ctx, "session refreshed");

    if (onSessionRefresh) {
      onSessionRefresh(session);
    }
  } else {
    if (onUnexpiredSession) {
      onUnexpiredSession();
    }
  }
};

const addTokenCheck = () => {
  const clients = Object.values(clientMap);

  clients.forEach((client) => {
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    client.addBeforeEach(() => {
      return new Promise((resolve) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        refreshSessionIfExpired((session) => {
          clients.forEach((clientToRefresh) => clientToRefresh.withAccessToken(session.idToken.jwtToken));
          resolve();
        }, resolve);
      });
    });
  });
};

export default addTokenCheck;
