import { Column } from "react-table";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { BasicTableColumn, useRenderMoneyCell } from "common/components/BasicTable";
import { perUnitTypeLabels } from "common/labels";
import { TransferPricingCategory } from "@deliverr/wholesale-client";
import { transferToEcomConfirmStepLabels } from "../../../transfers/create/steps/ecom/confirm/transferToEcomConfirmStep.labels";
import { wholesaleCreateLabels } from "../wholesaleCreate.labels";
import { useRenderMoneyOrStringCell } from "../../../common/components/BasicTable/useRenderMoneyOrStringCell";

type UnitType =
  | "unit"
  | "pallet"
  | "box"
  | typeof TransferPricingCategory.CARTON
  | typeof TransferPricingCategory.PALLET
  | typeof TransferPricingCategory.SHIPMENT;

const MAX_FRACTION_DIGITS_MONEY = 3;

export const useWholesaleConfirmCostsColumns = (): Column<BasicTableColumn>[] => {
  const { formatMessage } = useIntl();

  const renderMoneyCell = useRenderMoneyCell();
  const renderMoneyOrStringCell = useRenderMoneyOrStringCell();

  const renderMoneyPerUnitCell = useRenderMoneyCell({
    appendedLabel: formatMessage(perUnitTypeLabels.perUnit),
    maximumFractionDigits: MAX_FRACTION_DIGITS_MONEY,
  });
  const renderMoneyPerPalletCell = useRenderMoneyCell({
    appendedLabel: formatMessage(perUnitTypeLabels.perPallet),
    maximumFractionDigits: MAX_FRACTION_DIGITS_MONEY,
  });
  const renderMoneyPerShipmentCell = useRenderMoneyCell({
    appendedLabel: formatMessage(perUnitTypeLabels.perShipment),
    maximumFractionDigits: MAX_FRACTION_DIGITS_MONEY,
  });
  const renderMoneyPerBoxCell = useRenderMoneyCell({
    appendedLabel: formatMessage(perUnitTypeLabels.perBox),
    maximumFractionDigits: MAX_FRACTION_DIGITS_MONEY,
  });

  const unitCostMap: Record<UnitType, ReturnType<typeof useRenderMoneyCell>> = useMemo(
    () => ({
      unit: renderMoneyPerUnitCell,
      box: renderMoneyPerBoxCell,
      [TransferPricingCategory.CARTON]: renderMoneyPerBoxCell,
      pallet: renderMoneyPerPalletCell,
      [TransferPricingCategory.PALLET]: renderMoneyPerPalletCell,
      [TransferPricingCategory.SHIPMENT]: renderMoneyPerShipmentCell,
    }),
    [renderMoneyPerBoxCell, renderMoneyPerPalletCell, renderMoneyPerUnitCell, renderMoneyPerShipmentCell]
  );

  const unitLabelToQuantityMap: Record<UnitType, (qty: number) => string> = useMemo(
    () => ({
      unit: (qty) => formatMessage(transferToEcomConfirmStepLabels.unitQuantity, { numOfUnits: qty }),
      box: (qty) => formatMessage(transferToEcomConfirmStepLabels.boxQuantity, { numOfCases: qty }),
      kit: (qty) => formatMessage(transferToEcomConfirmStepLabels.kitQuantity, { numOfKits: qty }),
      [TransferPricingCategory.CARTON]: (qty) =>
        formatMessage(transferToEcomConfirmStepLabels.boxQuantity, { numOfCases: qty }),
      pallet: (qty) => formatMessage(transferToEcomConfirmStepLabels.palletQuantity, { numOfPallets: qty }),
      [TransferPricingCategory.PALLET]: (qty) =>
        formatMessage(transferToEcomConfirmStepLabels.palletQuantity, { numOfPallets: qty }),
      [TransferPricingCategory.SHIPMENT]: (qty) =>
        formatMessage(wholesaleCreateLabels.steps.confirm.costs.shipmentQuantity, { qty }),
    }),
    [formatMessage]
  );

  return React.useMemo(
    () => [
      {
        Header: formatMessage(transferToEcomConfirmStepLabels.feesTitle),
        accessor: "rowLabel",
      },
      {
        Header: formatMessage(transferToEcomConfirmStepLabels.quantityTitle),
        accessor: "quantity",
        Cell: ({ row }) => {
          const unitLabel: UnitType = row.original.unitLabel;
          const quantity = row.original.quantity;
          return quantity > 0 ? (
            unitLabel ? (
              unitLabelToQuantityMap[unitLabel]?.(quantity) ?? quantity
            ) : (
              <>{quantity}</>
            )
          ) : (
            ""
          );
        },
      },
      {
        Header: formatMessage(transferToEcomConfirmStepLabels.costTitle),
        accessor: "unitCost",
        Cell: ({ row }) => {
          const unitLabel: UnitType = row.original.unitLabel;
          const unitCostRow = row.cells[2];
          return unitLabel ? (unitCostMap[unitLabel] ?? renderMoneyCell)(unitCostRow) : "";
        },
      },
      {
        Header: formatMessage(transferToEcomConfirmStepLabels.amountTitle),
        accessor: "amount",
        Cell: renderMoneyOrStringCell,
      },
    ],
    [formatMessage, renderMoneyCell, unitCostMap, unitLabelToQuantityMap]
  );
};
