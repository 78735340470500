import { SPThunkAction } from "common/ReduxUtils";
import { isEmpty } from "lodash";
import { transferCreateChooseOriginWarehouse } from "transfers/create/actions";
import { DskuQty } from "@deliverr/business-types";
import { replenishmentClient, warehouseClient } from "Clients";

import { FulfillStrategy, WarehouseInventory } from "@deliverr/replenishment-client";
import { loadWarehouses } from "common/deliverr/DeliverrActions";

export const setOriginWarehouseForReplenishmentOutOfStockOrder =
  (orderItemsDskuQty?: DskuQty[]): SPThunkAction =>
  async (dispatch): Promise<WarehouseInventory[] | undefined> => {
    if (orderItemsDskuQty && !isEmpty(orderItemsDskuQty)) {
      const availableWarehouseInventory = (
        await replenishmentClient.fetchAvailableWarehouseInventory(orderItemsDskuQty, FulfillStrategy.PARTIAL)
      ).value;
      const warehouseIds = availableWarehouseInventory?.map(({ warehouseId }) => warehouseId) ?? [];
      !isEmpty(warehouseIds) && dispatch(loadWarehouses(warehouseIds));
      const selectedWarehouseId = availableWarehouseInventory?.[0]?.warehouseId;
      const selectedWarehouse = selectedWarehouseId
        ? await warehouseClient.getWarehouse(selectedWarehouseId)
        : undefined;

      selectedWarehouse &&
        dispatch(
          transferCreateChooseOriginWarehouse({ ...availableWarehouseInventory?.[0], warehouse: selectedWarehouse })
        );
      return availableWarehouseInventory;
    }
    return undefined;
  };
