import React from "react";
import { FormattedMessage } from "react-intl";
import { ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import Address from "common/components/Address";
import { useStorageShipmentDetail } from "../../../StorageShipmentDetail/useStorageShipmentDetail";

const StyledAddress = styled.div<ThemeProps>(
  ({ theme }) => `
      flex-grow: 1;
      white-space: pre-line;
      height: fit-content;

      .address {
        color: ${theme.colors.NEUTRAL[500]};
      }
    `
);
const StyledProvider = styled.p<ThemeProps>(
  ({ theme }) => `
    margin-bottom: 0;
    color: ${theme.colors.NEUTRAL[500]};
    `
);

export const SendShipment: React.FC = () => {
  const { to: toAddress, storageWarehouse } = useStorageShipmentDetail();
  const warehouseProvider = storageWarehouse?.provider;

  return (
    <StyledAddress>
      {warehouseProvider && (
        <StyledProvider>
          <FormattedMessage
            id={"storage.inbounds.sendShipment.provider"}
            defaultMessage={"{provider} c/o Flexport"}
            values={{ provider: warehouseProvider }}
          />
        </StyledProvider>
      )}
      {toAddress && <Address address={toAddress} hideName />}
    </StyledAddress>
  );
};
