import { Reducer } from "redux";

import { adUnsyncCompleted } from "../actions/adUnsyncCompleted";
import { adUnsyncErrored } from "../actions/adUnsyncErrored";
import { adUnsyncRequested } from "../actions/adUnsyncRequested";
import { FacebookEditActionTypes } from "../actions/FacebookEditActionTypes";
import { FacebookUnsyncStatus } from "./FacebookUnsyncStatus";

export type UnsyncStatusState = FacebookUnsyncStatus | null;

export type UnsyncStatusAction = ReturnType<
  typeof adUnsyncCompleted | typeof adUnsyncErrored | typeof adUnsyncRequested
>;

export const initialUnsyncStatus: UnsyncStatusState = null;

export const unsyncStatusReducer: Reducer<UnsyncStatusState, UnsyncStatusAction> = (
  state = initialUnsyncStatus,
  action: UnsyncStatusAction
) => {
  switch (action.type) {
    case FacebookEditActionTypes.FACEBOOK_AD_UNSYNC_COMPLETED:
      return "completed";
    case FacebookEditActionTypes.FACEBOOK_AD_UNSYNC_ERRORED:
      return "errored";
    case FacebookEditActionTypes.FACEBOOK_AD_UNSYNC_REQUESTED:
      return null;
    default:
      return state;
  }
};
