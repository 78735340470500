import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import Select from "@deliverr/react-select-v1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BarcodeDetails from "barcodes/BarcodeDetails";
import cx from "classnames";
import { BarcodeFormat, getActiveUniqueBarcodesCount } from "common/components/ui/utils/BarcodeUtils";

import { BARCODE_LIMIT, barcodeFormatToDisplay, unknownBarcodeDisplay } from "common/BarcodeOptions";
import { BorderlessButton } from "common/components/BorderlessButton";
import { DisabledSelect } from "common/components/DisabledSelect";
import HtmlToReact from "common/components/HtmlToReact";
import {
  barcodeFormatToInvalidBarcodeError,
  barcodeGenericError,
  duplicateNewBarcodeInputsError,
  selectBarcodeFormatError,
} from "./BarcodePageErrors";
import useBarcodeEdit, { BarcodeEditComponentProps } from "./useBarcodeEdit";
import lockIcon from "assets/img/lock-alt-solid.svg";
import unlockedIcon from "assets/img/lock-open-alt-solid.svg";
import { BarcodeEditErrors } from "./BarcodeEditReducer";
import { Tooltip, TooltipSkin, TooltipWrapper } from "common/components/Tooltip";
import { BarcodeEditComponentError } from "./BarcodeEditComponentError";

import cls from "inbounds/steps/barcode/BarcodeInputStep.less";
import editCls from "./BarcodeEditComponent.less";

export const BarcodeEditComponent: FC<BarcodeEditComponentProps> = (props) => {
  const {
    barcodeOptions,
    barcodesCollection,
    hasSelectedDSKU,
    handleBarcodeValidation,
    handleBarcodeChange,
    handleBarcodeFormatChange,
    handleAddBarcode,
    handleRemoveBarcode,
  } = useBarcodeEdit(props);
  const { pageDuplicatedBarcodes } = props;

  return barcodesCollection ? (
    <tr>
      <BarcodeDetails className={editCls.cell} product={props.product} />
      <td className={editCls.cell}>
        {barcodesCollection.map(({ format, locked }, index) => (
          <div key={`format${index}`} className={editCls.barcodeFragment}>
            {!locked ? (
              <Select
                clearable={false}
                placeholder="Select barcode format"
                className={cx(cls.selectFormat, editCls.selectFormat)}
                options={hasSelectedDSKU && format !== BarcodeFormat.DSKU ? barcodeOptions.slice(1) : barcodeOptions}
                onChange={handleBarcodeFormatChange(index)}
                value={format}
              />
            ) : (
              <DisabledSelect
                option={{
                  label: format ? barcodeFormatToDisplay[format] : unknownBarcodeDisplay,
                }}
                className={cx(cls.selectFormat, editCls.selectFormat)}
              />
            )}
          </div>
        ))}
        {getActiveUniqueBarcodesCount(barcodesCollection) < BARCODE_LIMIT && (
          // class added for heap event tracking
          <BorderlessButton
            onClick={handleAddBarcode}
            className={cx(editCls.addBarcode, "inbounds-add-barcode-button")}
          >
            <FontAwesomeIcon className={editCls.addIcon} icon="plus-circle" />
            <FormattedMessage id={"BarcodePage.addBarcode"} defaultMessage={"Add alternate barcode"} />
          </BorderlessButton>
        )}
      </td>
      <td className={editCls.cell}>
        {barcodesCollection.map(({ value, locked, error: barcodeErr, format, valid }, index, list) => {
          const isDuplicatedInPage = value.length && pageDuplicatedBarcodes?.includes(value);
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          const hasError = barcodeErr || valid === false || valid === BarcodeEditErrors.FORMAT || isDuplicatedInPage;

          const isSelectedDSKU =
            hasSelectedDSKU && hasSelectedDSKU.value === value && hasSelectedDSKU.format === format;

          return (
            <div
              key={`barcodeInput${index}`}
              className={editCls.barcodeFragment}
              data-testid={`barcodeInput_value${index}`}
            >
              <div className={editCls.inputWrapper}>
                <TooltipWrapper>
                  <input
                    className={cx(editCls.textInput, {
                      // apply elevio class only in first new barcode
                      [cls.textInput]: !locked && (index === 0 || list[index - 1].locked),
                      [editCls.textInputInvalid]: hasError,
                    })}
                    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                    disabled={isSelectedDSKU || locked}
                    type="text"
                    value={value}
                    onBlur={handleBarcodeValidation(index)}
                    onChange={handleBarcodeChange(index)}
                  />
                  <Tooltip
                    active={Boolean(hasError)}
                    skin={TooltipSkin.ERROR}
                    className={{
                      content: editCls.errorTooltip,
                    }}
                  >
                    <ul className={editCls.errorList}>
                      {valid === BarcodeEditErrors.FORMAT && format && (
                        <li className={editCls.errorText}>
                          {barcodeFormatToInvalidBarcodeError[format] || barcodeGenericError}
                        </li>
                      )}
                      {barcodeErr && (
                        <li className={editCls.errorText}>
                          <BarcodeEditComponentError barcodeError={barcodeErr} />
                        </li>
                      )}
                      {valid === BarcodeEditErrors.FORMAT && !format && (
                        <li className={editCls.errorText}>{selectBarcodeFormatError}</li>
                      )}
                      {isDuplicatedInPage && <li className={editCls.errorText}>{duplicateNewBarcodeInputsError}</li>}
                    </ul>
                  </Tooltip>
                </TooltipWrapper>
                <HtmlToReact
                  html={locked ? lockIcon : unlockedIcon}
                  className={cx(editCls.inputLockIcon, {
                    [editCls.inputUnlockedIcon]: !locked,
                  })}
                />
              </div>
              {!locked && list.length > 1 && (
                <BorderlessButton key={`rB${index}`} onClick={handleRemoveBarcode(index)}>
                  <FontAwesomeIcon className={editCls.removeIcon} icon="times-circle" />
                </BorderlessButton>
              )}
            </div>
          );
        })}
      </td>
    </tr>
  ) : null;
};
