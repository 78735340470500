import React from "react";
import { FormattedMessage } from "react-intl";
import { Text } from "common/components/ui";
import { Currency, CurrencyFormat } from "common/components/Currency";
import { addDays, format, addWeeks } from "date-fns";
import { CommaDateNoYear } from "common/date/DateFormat";
import { useSelector } from "react-redux";
import { getStorageInboundCreate } from "storage/inbounds/create/store/selector/getStorageInboundCreate";

export const useSelectService = (quoteResponse) => {
  const { freightOrderDetails } = useSelector(getStorageInboundCreate);

  const serviceCosts = quoteResponse.serviceCosts;
  const quoteId = quoteResponse.id;
  const quoteValidDate = format(addWeeks(new Date(quoteResponse.createdAt), 2), CommaDateNoYear);
  const quoteOptions = serviceCosts.map((serviceCost) => {
    const arrivalDate = format(addDays(new Date(), serviceCost.transitDays), CommaDateNoYear);
    const quotePrice = serviceCost.totalCost;
    const quoteType = serviceCost.type;
    const tileContent = (
      <div>
        <Text>
          <FormattedMessage
            defaultMessage="Estimated arrival by {arrivalDate}"
            id="storage.inbound.selectShipping.quote.arrivalDate"
            values={{ arrivalDate }}
          />
        </Text>
        <Text>
          <Currency currency={CurrencyFormat.USD} value={quotePrice} />
        </Text>
      </div>
    );
    const title = (
      <Text>
        <FormattedMessage
          defaultMessage="{quoteType}"
          id="storage.inbound.selectShipping.quote.title"
          values={{ quoteType }}
        />
      </Text>
    );
    const quoteOption = {
      content: tileContent,
      label: title,
      value: quoteId,
    };
    return quoteOption;
  });

  return { quoteOptions, quoteValidDate, selectedQuote: freightOrderDetails?.quoteId };
};
