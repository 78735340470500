import { useMemo } from "react";
import useSWR from "swr";

import { inboundClient } from "Clients";
import log from "Logger";

interface UseFetchFreightStateConfig {
  sellerId: string;
  shipmentId?: number;
}

export const useFetchFreightState = ({ sellerId, shipmentId }: UseFetchFreightStateConfig) => {
  const ctx = useMemo(() => ({ fn: "useFetchFreightState", sellerId, shipmentId }), [sellerId, shipmentId]);

  const { data, error, mutate } = useSWR(
    shipmentId !== undefined ? ["freightState", sellerId, shipmentId] : null,
    async ([_, _sellerId, _shipmentId]) => {
      log.info(ctx, "getting freight state");
      const freightState = await inboundClient.getFreightStates(sellerId, [shipmentId!]);
      return freightState;
    },
    {
      errorRetryCount: 3,
      revalidateOnFocus: false, // don't refresh automatically when the seller returns focus to the page
      onError: (err) => {
        log.warn({ ...ctx, err }, "error fetching freight state");
      },
    }
  );

  return {
    data,
    isLoading: !data && !error,
    error,
    refetch: mutate,
  };
};
