import React, { FC } from "react";
import { isEmpty } from "lodash";
import { LoadingSpinner } from "../../LoadingSpinner";
import { SearchResultList } from "./SearchResultList";
import { ErrorMessage, NoResultsMessage, SearchResultsList } from "./GlobalSearchStyledComponents";
import { GlobalSearchResults } from "../utils";
import { FormattedMessage } from "react-intl";

interface SearchResultProps {
  searchTerm: string;
  isLoading: boolean;
  error: string | null;
  searchResults: GlobalSearchResults;
}

export const SearchResults: FC<SearchResultProps> = ({ searchTerm, isLoading, error, searchResults }) => {
  const hasSearchResults = Object.values(searchResults).some((result) => result.length > 0);
  const shouldDisplayContainer = isLoading || !!error || !isEmpty(searchTerm) || hasSearchResults;

  let content;
  if (isLoading) {
    content = <LoadingSpinner center size="4x" />;
  } else if (error) {
    content = (
      <ErrorMessage appearance="DANGER" textAlign="center">
        {error}
      </ErrorMessage>
    );
  } else if (hasSearchResults) {
    content = <SearchResultList searchResults={searchResults} />;
  } else if (!isEmpty(searchTerm)) {
    content = (
      <NoResultsMessage>
        <FormattedMessage
          id="globalSearch.noSearchResults"
          defaultMessage="No results found for {searchTerm}"
          values={{ searchTerm }}
        />
      </NoResultsMessage>
    );
  } else {
    content = null;
  }

  return shouldDisplayContainer ? <SearchResultsList>{content}</SearchResultsList> : null;
};
