import { fetchQuery, graphql } from "react-relay";
import { Notification } from "common/clients/notifications/Notification/Notification";
import { FlexportUserNotificationQuery } from "../common/relay/__generated__/FlexportUserNotificationQuery.graphql";
import { NotificationTargetType } from "../common/clients/notifications/Notification/NotificationTargetType";
import { NotificationType } from "../common/clients/notifications/Notification/NotificationType";
import { environment } from "../common/relay/RelayEnvironment";

export const fetchNotificationsQuery = graphql`
  query FlexportUserNotificationQuery($before: Int, $after: Int, $last: Int) {
    notifications: userNotifications(initialFetch: false, before: $before, last: $last, after: $after) {
      dbid
      slug
      referenceId
      createdAt
      link
      objectName
    }
  }
`;

const ShipmentMessageLinkRegex = /\/inbounds\/[0-9]+\/shipment-details\/((problems\/[0-9]+)|messages)/;

const isSMBMessageNotification = (fpNotification: any): boolean => {
  return (
    (fpNotification.slug === "shipment_message_reply" ||
      fpNotification.slug === "shipment_message" ||
      fpNotification.slug === "shipment_message_mention") &&
    fpNotification.link &&
    ShipmentMessageLinkRegex.test(fpNotification.link)
  );
};

export const smbNotificationTransformer = (fpNotification: any, sellerId: string): Notification => {
  const commonNotificationDetails = {
    acknowledged: false,
    createdAt: fpNotification.createdAt,
    id: fpNotification.dbid,
    sellerId,
    updatedAt: fpNotification.createdAt,
    viewed: false,
    notificationTargetId: "problems",
    notificationTargetType: NotificationTargetType.InboundTargetType,
    type: NotificationType.SMBNotificationType,
  };
  let overrideNotificationDetails = {};
  if (isSMBMessageNotification(fpNotification)) {
    overrideNotificationDetails = {
      notificationTargetType: NotificationTargetType.SMBProblemDetailsTargetType,
      notificationTargetId: fpNotification.link.match(ShipmentMessageLinkRegex)[0],
      notificationTargetName: fpNotification.referenceId ? `'FLEX-${fpNotification.referenceId}'` : "",
      type: NotificationType.SMBMessageNotificationType,
    };
  }
  return {
    ...commonNotificationDetails,
    ...overrideNotificationDetails,
  };
};

export const fetchSmbNotifications = async (
  sellerId: string,
  limit: number,
  lastId?: number
): Promise<Notification[]> => {
  const data = await fetchQuery<FlexportUserNotificationQuery>(environment, fetchNotificationsQuery, {
    last: limit,
    before: lastId,
    after: lastId ? undefined : 0,
  }).toPromise();
  return data?.notifications?.map((x) => smbNotificationTransformer(x, sellerId)) ?? [];
};
