/**
 * Entry point for configuring and registering new Conditional Pricing Rates
 *
 * For questions, please contact joel@deliverr.com
 */

import { ConditionalPricingRegistry } from "./ConditionalPricingRegistry";
import { ConditionalPricingBuilder } from "./ConditionalPricingBuilder";

const FILE_NAME = "2023_Rates.xlsx";
const STANDARD_RATE_CARD_URL = `https://s3.amazonaws.com/static.deliverr.com/pricing/${FILE_NAME}`;

export const conditionalPricingRegistry = new ConditionalPricingRegistry();

export const buildDeliverr2023Pricing = (referenceDate: Date) => {
  return new ConditionalPricingBuilder(
    referenceDate,
    {
      id: "conditionalPricingRegistry.deliverr2023Message",
      defaultMessage: "Flexport pricing will change on {baseDate} to reflect 2023 rates.",
    },
    {
      id: "conditionalPricingRegistry.deliverr2023Label",
      defaultMessage: "Toggle to preview upcoming prices.",
    },
    new Date("2023-01-20") // pre-fast tag discount reference date
  )
    .setTitleDescriptor({
      id: "conditionalPricingRegistry.deliverr2023Title",
      defaultMessage: "Flexport 2023 Pricing",
    })
    .setUrl(STANDARD_RATE_CARD_URL)
    .build();
};

// Define new Conditional Pricings here
export function registerConditionalPricing() {
  // Register new pricings here (at the end)!
}
