import { getOffsetDateWeekdays } from "./getOffsetDateWeekdays";
import { isWeekend } from "date-fns";

/**
 * Get the date of the closest weekday
 * @param baseDate First date to check if it's a weekday
 * @param useFutureDates Boolean - use future dates when true, past dates when false.
 */
export const getClosestWeekdayDate = (baseDate = new Date(), useFutureDates = true): Date => {
  return !isWeekend(baseDate) ? baseDate : getOffsetDateWeekdays(useFutureDates ? 1 : -1, baseDate);
};
