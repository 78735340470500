import styled from "@emotion/styled";
import { Box, ThemeProps, Title } from "common/components/ui";
import { TextAlign } from "common/components/ui/shared";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { SkuSelectorLearnMoreModal } from "./SkuSelectorLearnMoreModal";
import { BorderlessButton } from "common/components/BorderlessButton";
import { LocalizedText } from "common/components/LocalizedText";
import { CargoSkuSwitch } from "./CargoSkuSwitch";

const StyledContainer = styled.section<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S3};
  border: 1px solid ${theme.colors.NEUTRAL[80]};
  display: flex;
  justify-content: space-between;
`
);

const StyledBox = styled(Box)<ThemeProps>`
  align-self: center;
`;

export const DtcCargoSkuSelector = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <StyledContainer>
        <Box paddingLeft="S3">
          <Title as="h4" displayAs="h5" textAlign={TextAlign.start} uppercase>
            <FormattedMessage
              id="createShipment.cargoDetail.dtc.skuSelector.title"
              defaultMessage="How are your boxes packed?  {link}"
              values={{
                link: (
                  <BorderlessButton onClick={() => setIsModalOpen(true)}>
                    <LocalizedText
                      textAlign={TextAlign.start}
                      as="p"
                      size="bodyLarge"
                      bold
                      appearance="PRIMARY"
                      message={{
                        id: "createShipment.cargoDetail.dtc.skuSelector.learnMore",
                        defaultMessage: "Learn more",
                      }}
                    />
                  </BorderlessButton>
                ),
              }}
            />
          </Title>
        </Box>
        <StyledBox paddingRight="S3">
          <CargoSkuSwitch />
        </StyledBox>
        <SkuSelectorLearnMoreModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      </StyledContainer>
    </>
  );
};
