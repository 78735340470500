// These are seller facing order errors.
export enum OrderError {
  BAD_ADDRESS = "BAD_ADDRESS",
  BAD_ADDRESS_DDP = "BAD_ADDRESS_DDP",
  BAD_PRODUCT_INFO = "BAD_PRODUCT_INFO",
  CANNOT_SHIP_HAZMAT = "CANNOT_SHIP_HAZMAT",
  DDU_NOT_ALLOWED = "DDU_NOT_ALLOWED",
  DSKU_NEVER_INBOUNDED = "DSKU_NEVER_INBOUNDED",
  INTERNATIONAL_ADDRESS = "INTERNATIONAL_ADDRESS",
  MISSING_CUSTOMS_INFO = "MISSING_CUSTOMS_INFO",
  NON_PLT_ORDER_NOT_SUPPORTED = "NON_PLT_ORDER_NOT_SUPPORTED",
  OVERSIZED_DIMS = "OVERSIZED_DIMS",
  PARTIALLY_SHIPPED = "PARTIALLY_SHIPPED",
  PARTIAL_FULFILL_FAILED = "PARTIAL_FULFILL_FAILED",
  PROMISE_TIME_EXPIRED = "PROMISE_TIME_EXPIRED",
  SHIPPING_METHOD_NOT_SUPPORTED_DDP = "SHIPPING_METHOD_NOT_SUPPORTED_DDP",
  STOCKOUT = "STOCKOUT",
  STOCKOUT_AT_INGESTION = "STOCKOUT_AT_INGESTION",
  UNKNOWN = "UNKNOWN",
  UNSUPPORTED_DESTINATION = "UNSUPPORTED_DESTINATION",
  WAREHOUSE_ERR = "WAREHOUSE_ERR",
}
