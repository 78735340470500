import React, { FC } from "react";
import { Text, Button, ThemeProps } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";

import { NonComplianceSection } from "../NonComplianceStyles";

interface NonComplianceDismissSectionProps {
  onDismiss: () => void;
  isOnDismissLoading?: boolean;
}

const PaddedSection = styled.div<ThemeProps>(
  ({ theme }) =>
    `
      /* accordion side padding plus the width of the number icon plus the icon right margin */
      padding: 0 calc(${theme.spacing.S7} + ${theme.spacing.S6} + ${theme.spacing.S4}) ${theme.spacing.S7};
      @media (max-width: ${theme.breakpoints.SM}){
        padding: 0 ${theme.spacing.S5} ${theme.spacing.S5};
      }
    `
);

export const NonComplianceDismissSection: FC<NonComplianceDismissSectionProps> = ({
  onDismiss,
  isOnDismissLoading,
}) => (
  <PaddedSection>
    <NonComplianceSection>
      <Text>
        <FormattedMessage
          id="nonCompliance.noChargeMessage"
          defaultMessage="Flexport will continue to investigate and you will not be charged."
        />
      </Text>
    </NonComplianceSection>
    <NonComplianceSection>
      <Button onClick={onDismiss} disabled={isOnDismissLoading}>
        <FormattedMessage id="submit" defaultMessage="Submit" />
      </Button>
    </NonComplianceSection>
  </PaddedSection>
);
