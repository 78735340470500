import { SPThunkAction } from "common/ReduxUtils";
import log from "Logger";
import { StorageInboundDetailActionTypes } from "../StorageInboundDetailStore.types";
import { sellerProblemsViewClient } from "Clients";

export const getStorageInboundOrderProblems =
  (shippingPlanId: number): SPThunkAction =>
  async (dispatch) => {
    try {
      const storageInboundOrderProblems = await sellerProblemsViewClient
        .getDetailedSellerProblems([], [shippingPlanId])
        .then((res) => res.data);
      dispatch({
        type: StorageInboundDetailActionTypes.STORAGE_GET_PROBLEMS,
        storageInboundOrderProblems,
      });
    } catch (err) {
      log.error({ err }, "error getting storage inbound order problems");
    }
  };
