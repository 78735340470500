import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { selectTransferCreate } from "./TransferCreateSelectors";
import { TransferCreateState } from "./TransferCreateState";
import { AugmentedInvalidOrderItem } from "../steps/common/replenishment/details/invalid-items/ReplenishmentInvalidOrderItems";
import { filter, map } from "lodash";
import { InvalidOrderItemReason } from "@deliverr/replenishment-client";

export const selectAugmentedInvalidOrderItems = createSelector<
  RootState,
  TransferCreateState,
  AugmentedInvalidOrderItem[]
>(selectTransferCreate, (transferCreate) => {
  const { replenishmentOrder, unifiedProductResponseCache } = transferCreate;
  return map(
    filter(replenishmentOrder?.invalidOrderItems, ({ reason }) => reason !== InvalidOrderItemReason.OUT_OF_STOCK),
    (item) => {
      return {
        ...item,
        resolvedUnitsPerPack: item.resolvedDsku ? unifiedProductResponseCache?.[item.resolvedDsku]?.caseQty : undefined,
      };
    }
  );
});
