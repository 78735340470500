import React from "react";
import { useIntl } from "react-intl";

export const useShareReturnLabel = () => {
  const { formatMessage } = useIntl();
  const [showShareLabelModal, setShowShareLabelModal] = React.useState(false);

  const title = formatMessage({
    id: "returns.returnsDetail.shareLabelModal.title",
    defaultMessage: "Share Return Label",
  });
  const description = formatMessage({
    id: "returns.returnsDetail.shareLabelModal.description",
    defaultMessage:
      "Email the label to your customer to get the return started. You won't be charged until your customer uses the return label.",
  });

  const shareLabelSuccess = () => {
    setShowShareLabelModal(false);
  };

  return {
    title,
    description,
    showShareLabelModal,
    setShowShareLabelModal,
    shareLabelSuccess,
  };
};
