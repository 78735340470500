import { createSelector } from "reselect";
import { getLoadedShipment } from "./getLoadedShipment";
import { getProductDetailsCache } from "../productDetailsCache/getProductDetailsCache";

// does the currently loaded shipment contain any products (dsku) with hazmatInformation in productDetailsCache?
// battery products may not be a part of every shipment in a plan
// this is (initially) used to determine whether to show batteries packaging illustrations in inbounds flow
export const getShipmentHasHazmatProducts = createSelector(
  getLoadedShipment,
  getProductDetailsCache,
  (loadedShipment, productDetailsCache) => {
    const shipmentItemsDskus = loadedShipment.items?.map((item) => item.dsku);
    const itemsWithHazmat = Object.keys(productDetailsCache)?.filter(
      (product) => productDetailsCache[product].hazmatInformation
    );
    const hasBatteries = shipmentItemsDskus?.some((item) => itemsWithHazmat?.includes(item));
    return hasBatteries;
  }
);
