import { ReferralProgramData } from "common/clients/referralPrograms/ReferralProgramData/ReferralProgramData";
import { handleSimpleReducerUpdates, ReducerDictionary, createReducer } from "common/ReduxUtils";
import { ReferralActionTypes } from "./actions/ReferralActionTypes";

export interface ReferralState {
  programData?: ReferralProgramData;
}

export const initialReferralState: ReferralState = {};

export const reducers: ReducerDictionary<ReferralState> = {
  ...handleSimpleReducerUpdates([ReferralActionTypes.REFERRAL_PROGRAM_DATA_RECEIVED]),
};

export const referralReducer = createReducer<ReferralState>(initialReferralState, reducers);
