import { useDispatch } from "react-redux";
import { useStorageInboundNavigation } from "../navigation/useStorageInboundNavigation";
import { useInboundAddressForm } from "inbounds/steps/address/InboundAddressForm";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { updateStorageInboundOrder } from "../store/actions/updateStorageInboundOrder";

/** @deprecated - will be replaced by the new IPB flow */
export const useStorageFromAddress = () => {
  const dispatch = useDispatch();
  const { goToNextStep, goToPreviousStep } = useStorageInboundNavigation();

  const {
    addresses,
    isSelectLoading,
    fromAddress,
    handleAddressSelectChange,
    handleAddressInputChange,
    validate,
  } = useInboundAddressForm();

  const onPreviousClick = () => {
    goToPreviousStep();
  };

  const onAddressSubmit = async () => {
    const isValid = validate();
    if (!isValid) {
      return;
    }
    dispatch(addLoader(InboundLoaderId.transition));
    if (await dispatch(updateStorageInboundOrder())) {
      goToNextStep();
    }
    dispatch(clearLoader(InboundLoaderId.transition));
  };

  return {
    addresses,
    isSelectLoading,
    fromAddress: fromAddress ?? undefined,
    onAddressSubmit,
    handleAddressSelectChange,
    handleAddressInputChange,
    onPreviousClick,
  };
};
