import React from "react";
import { replenishmentClient } from "Clients";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { retry } from "common/utils/Retry";
import { logError } from "Logger";
import { toast } from "react-toastify";
import { transferCreateSetCarrierAccounts } from "transfers/create/actions";
import { SPThunkAction } from "common/ReduxUtils";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { ReplenishmentCarrierAccountLoader } from "../ReplenishmentCarrierAccountLoader";

const RETRIES = 3;

export const fetchReplenishmentCarrierAccounts =
  (sellerId: string): SPThunkAction<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch(addLoader(ReplenishmentCarrierAccountLoader.FetchCarrierLoader));
      await retry(
        async () => {
          const carrierAccounts = (await replenishmentClient.getCarrierAccountsBySellerId(sellerId)).value;
          dispatch(transferCreateSetCarrierAccounts(carrierAccounts));
        },
        { retries: RETRIES }
      );
    } catch (err) {
      logError({ fn: "fetchCarrierAccounts", sellerId }, err, "Error fetching carrier accounts");
      toast.error(
        <FormattedMessage
          id="replenishment.fetchCarrierAccounts.error"
          defaultMessage="Failed to fetch carrier accounts"
        />,
        {
          autoClose: false,
          toastId: "fetchCarrierAccounts",
        }
      );
    } finally {
      dispatch(clearLoader(ReplenishmentCarrierAccountLoader.FetchCarrierLoader));
    }
  };
