import { createSelector } from "reselect";
import { getLoaders } from "common/components/WithLoader/LoadingSelectors";
import InboundLoaderId from "inbounds/InboundLoaderId";

export const SHIPMENT_ACTION_LOADERS = [
  InboundLoaderId.confirmEstimate,
  InboundLoaderId.fetchingQuotes,
  InboundLoaderId.finalizingShipment,
  InboundLoaderId.updateShippingOption,
];

export const selectIsShipmentActionLoading = createSelector(getLoaders, (loaders) =>
  SHIPMENT_ACTION_LOADERS.some((loaderId) => loaders.includes(loaderId))
);
