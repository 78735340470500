import { palletValidationErrorMessagesWithValue } from "freight/steps/setup/steps/cargoPalletDetails/CargoPalletTable/CargoPalletValidationMap";
import { CargoPalletValidations } from "freight/steps/setup/steps/cargoPalletDetails/CargoPalletTable/CargoPalletValidations";
import { MessageDescriptor } from "react-intl";
import { createSelector } from "reselect";
import { getStorageInboundCreate } from "storage/inbounds/create/store/selector/getStorageInboundCreate";

export const getStorageCargoPalletValidationErrorMessages = createSelector(
  getStorageInboundCreate,
  ({
    palletConfigurations,
  }): Record<CargoPalletValidations, { messageDescriptor: MessageDescriptor; values?: Record<string, unknown> }> =>
    palletValidationErrorMessagesWithValue
);
