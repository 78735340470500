import { FormattedMessage, useIntl } from "react-intl";
import { Box, DefaultTheme, Icon, Notification, Stack, Text, defaultTheme } from "common/components/ui";
import React, { FC, ReactElement, useEffect } from "react";
import { WholesaleItem, WholesaleShipmentType, WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { FlexRadioTileGroup } from "transfers/create/steps/fba/shipping/FlexRadioTileGroup";
import { wholesaleCreateLabels } from "../../wholesaleCreate.labels";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import { CurrencyFormat } from "common/components/Currency";
import styled from "@emotion/styled";
import { useWholesaleLabelsStep } from "../useWholesaleLabelsStep";
import { TooltipWithIcon } from "common/components/icons/TooltipWithIcon";
import { ExternalLink } from "common/components/ExternalLink";
import { ShippingDocumentsDropZone } from "./ShippingDocumentsDropZone";
import { getHelpCenterArticleUrl } from "common/support/helpCenter";
import { StorageProductData } from "../../../../common/clients/storage/StorageProduct/StorageProductData";

interface CargoTypeProps {
  shipmentType?: WholesaleShipmentType;
  shippingMethod: WholesaleShippingMethod;
  onCargoTypeChange: (value: WholesaleShipmentType) => void;
  palletEstimatedTotal?: number | null;
  parcelEstimatedTotal?: number | null;
  isInternational?: boolean | false;
  productsCaseInfo: { [dsku: string]: StorageProductData };
  shipmentItems: WholesaleItem[];
  freightDisableReason: ReactElement | null;
}

const PARCEL_MAX_ITEMS_COUNT = 49;
const PARCEL_MAX_WEIGHT_PER_PACK = 150;

const DropzoneContainer = styled.div`
  width: 356px;
  padding-top: 10px;
`;

const InfoIconContainer = styled.span<{}, DefaultTheme>(
  ({ theme }) => `
  margin-left: ${theme.spacing.S1};
`
);

const LearnMoreLink = styled(ExternalLink)<{}, DefaultTheme>(
  ({ theme }) => `
    display: inline-flex;
    align-items: center;
    gap: 5px;
    color: ${theme.colors.NEUTRAL["00"]};;
    text-decoration: underline;
  `
);

const EstimationInProgress = () => {
  return (
    <>
      <LoadingSpinner /> <FormattedMessage {...wholesaleCreateLabels.steps.shipping.estimationInProgress} />
    </>
  );
};

const useFormatDollars = () => {
  const { formatNumber } = useIntl();
  return (amount: number) =>
    formatNumber(amount, {
      style: "currency",
      currency: CurrencyFormat.USD,
      maximumFractionDigits: 2,
    });
};

const isValidParcelCaseCount = (
  shipmentItems: WholesaleItem[],
  productsCaseInfo: { [dsku: string]: StorageProductData }
) => {
  return (
    shipmentItems.reduce((acc, item) => acc + calculateCaseCount(item, productsCaseInfo), 0) < PARCEL_MAX_ITEMS_COUNT
  );
};

const calculateCaseCount = (item: WholesaleItem, productsCaseInfo: { [dsku: string]: StorageProductData }): number => {
  const caseQty = productsCaseInfo[item.dsku]?.caseQty;
  return caseQty ? item.qty / caseQty : 0;
};

const isValidParcelWeightPerPack = (shipmentItems: { [dsku: string]: StorageProductData }) => {
  return !Object.values(shipmentItems).some((item) => item.caseWeight > PARCEL_MAX_WEIGHT_PER_PACK);
};

const SmallParcelsContent: FC<
  { isDeliverr: boolean; validForParcel: boolean } & Pick<CargoTypeProps, "parcelEstimatedTotal">
> = ({ isDeliverr, validForParcel, parcelEstimatedTotal }) => {
  const formattedRoundDollars = useFormatDollars();

  if (!isDeliverr || parcelEstimatedTotal === null) {
    return null;
  }
  if (!validForParcel) {
    return <FormattedMessage {...wholesaleCreateLabels.steps.shipping.cargoOptions.parcel.errMSg} />;
  }
  if (parcelEstimatedTotal && parcelEstimatedTotal > 0) {
    return (
      <FormattedMessage
        {...wholesaleCreateLabels.steps.shipping.cargoOptions.parcel.descriptionXEstimatedTotal}
        values={{
          estimatedTotal: formattedRoundDollars(parcelEstimatedTotal),
        }}
      />
    );
  }
  return <EstimationInProgress />;
};

const FreightContent: FC<
  { isDeliverr: boolean } & Pick<CargoTypeProps, "palletEstimatedTotal" | "isInternational" | "freightDisableReason">
> = ({ isDeliverr, palletEstimatedTotal, isInternational, freightDisableReason }) => {
  const formattedRoundDollars = useFormatDollars();

  if (isInternational && isDeliverr) {
    return <FormattedMessage {...wholesaleCreateLabels.steps.shipping.cargoOptions.pallet.disableddDescription} />;
  }

  if (!isDeliverr) {
    return null;
  }

  if (freightDisableReason) {
    return freightDisableReason;
  }

  if (palletEstimatedTotal === null) {
    return (
      <Notification appearance="PRIMARY">
        <FormattedMessage {...wholesaleCreateLabels.steps.shipping.palletNoEstimate} />
      </Notification>
    );
  }

  if (palletEstimatedTotal && palletEstimatedTotal > 0) {
    return (
      <FormattedMessage
        {...wholesaleCreateLabels.steps.shipping.cargoOptions.pallet.descriptionXEstimatedTotal}
        values={{
          estimatedTotal: formattedRoundDollars(palletEstimatedTotal),
        }}
      />
    );
  }
  return <EstimationInProgress />;
};

export const CargoType: FC<CargoTypeProps> = ({
  shipmentType,
  shippingMethod,
  onCargoTypeChange,
  palletEstimatedTotal,
  parcelEstimatedTotal,
  isInternational,
  productsCaseInfo,
  shipmentItems,
  freightDisableReason,
}) => {
  const { formatMessage } = useIntl();
  const {
    onShippingLabelFileChange,
    isUploadingLabel,
    shippingLabelFilename,
    commercialInvoiceFilename,
    onShippingLabelFileClear,
    onCommercialIncvoiceFileChange,
    onCommercialIncvoiceFileClear,
  } = useWholesaleLabelsStep();

  const isDeliverr = shippingMethod === WholesaleShippingMethod.DELIVERR;

  const isValidForParcel = () => {
    return (
      !isDeliverr ||
      (isValidParcelCaseCount(shipmentItems, productsCaseInfo) && isValidParcelWeightPerPack(productsCaseInfo))
    );
  };

  useEffect(() => {
    if (!isDeliverr) {
      return;
    }
    if (
      parcelEstimatedTotal === null &&
      palletEstimatedTotal === null &&
      shipmentType !== WholesaleShipmentType.PARTIAL_PALLET
    ) {
      onCargoTypeChange(WholesaleShipmentType.PARTIAL_PALLET);
    }
    if (
      shipmentType === WholesaleShipmentType.PARCEL &&
      parcelEstimatedTotal === null &&
      palletEstimatedTotal !== null
    ) {
      onCargoTypeChange(WholesaleShipmentType.PARTIAL_PALLET);
    } else if (
      shipmentType === WholesaleShipmentType.PARTIAL_PALLET &&
      palletEstimatedTotal === null &&
      parcelEstimatedTotal !== null
    ) {
      onCargoTypeChange(WholesaleShipmentType.PARCEL);
    }
  }, [isDeliverr, shipmentType, parcelEstimatedTotal, palletEstimatedTotal, onCargoTypeChange]);

  const hasContactInfoLine =
    shippingMethod === WholesaleShippingMethod.SELLER && shipmentType === WholesaleShipmentType.PARTIAL_PALLET;
  const hasLabelsAndProducts =
    shippingMethod === WholesaleShippingMethod.SELLER && shipmentType === WholesaleShipmentType.PARCEL;

  const shouldShowShipmentTypeOptions = !isDeliverr || parcelEstimatedTotal !== null || palletEstimatedTotal !== null;

  return (
    <Box width="80%" paddingBottom="S6">
      {shouldShowShipmentTypeOptions && (
        <Box paddingBottom="S5">
          <Text bold>
            <FormattedMessage {...wholesaleCreateLabels.steps.shipping.selectCargoType} />
          </Text>
        </Box>
      )}

      {isDeliverr && parcelEstimatedTotal === null && palletEstimatedTotal !== null && (
        <Box paddingBottom="S5">
          <Notification appearance="PRIMARY">
            <FormattedMessage {...wholesaleCreateLabels.steps.shipping.parcelNotAvailable} />
          </Notification>
        </Box>
      )}

      {!shouldShowShipmentTypeOptions && (
        <Box paddingBottom="S5">
          <Notification appearance="PRIMARY">
            <FormattedMessage {...wholesaleCreateLabels.steps.shipping.palletNoEstimate} />
          </Notification>
        </Box>
      )}

      {shouldShowShipmentTypeOptions && (
        <FlexRadioTileGroup
          direction="HORIZONTAL"
          options={[
            {
              value: WholesaleShipmentType.PARTIAL_PALLET,
              label: formatMessage(wholesaleCreateLabels.steps.shipping.cargoOptions.pallet.label),
              disabled: !!(isInternational && isDeliverr) || !!freightDisableReason,
              content: (
                <FreightContent
                  isDeliverr={isDeliverr}
                  palletEstimatedTotal={palletEstimatedTotal}
                  isInternational={isInternational}
                  freightDisableReason={freightDisableReason}
                />
              ),
            },
            {
              value: WholesaleShipmentType.PARCEL,
              label: formatMessage(wholesaleCreateLabels.steps.shipping.cargoOptions.parcel.label),
              disabled: !isValidForParcel(),
              content: (
                <SmallParcelsContent
                  isDeliverr={isDeliverr}
                  validForParcel={isValidForParcel()}
                  parcelEstimatedTotal={parcelEstimatedTotal}
                />
              ),
            },
          ]}
          currentValue={shipmentType}
          onChange={(evt) => {
            onCargoTypeChange(evt.currentTarget.value as WholesaleShipmentType);
          }}
        />
      )}

      {hasContactInfoLine && (
        <Box paddingTop="S6">
          <Text appearance="INFO">
            <FormattedMessage {...wholesaleCreateLabels.steps.shipping.contactInfo} />
          </Text>
        </Box>
      )}

      {hasLabelsAndProducts && (
        <Box paddingTop="S7">
          <Text bold size="body">
            <FormattedMessage {...wholesaleCreateLabels.steps.shipping.addDocuments} />
          </Text>
          <Stack direction="HORIZONTAL" gap="S6">
            <Box paddingTop="S4">
              <Text size="label">
                <FormattedMessage {...wholesaleCreateLabels.steps.shipping.addLabels} />
              </Text>
              <DropzoneContainer>
                <ShippingDocumentsDropZone
                  id="dropzone-wholesale-shipping-labels"
                  acceptedFileTypes={["pdf"]}
                  onChange={onShippingLabelFileChange}
                  onFileClear={onShippingLabelFileClear}
                  loading={isUploadingLabel?.SHIPPING_LABELS}
                  value={shippingLabelFilename ? { fileName: shippingLabelFilename } : undefined}
                />
              </DropzoneContainer>
            </Box>
            {isInternational && (
              <Box paddingTop="S4">
                <Text size="label">
                  <FormattedMessage {...wholesaleCreateLabels.steps.shipping.addCommercialInvoice} />
                  <InfoIconContainer>
                    <TooltipWithIcon
                      placement="top"
                      message={
                        <FormattedMessage
                          {...wholesaleCreateLabels.steps.shipping.commercialInvoiceToolTip}
                          values={{
                            learnMoreLink: (
                              <LearnMoreLink
                                href={getHelpCenterArticleUrl(
                                  "17361010729111-What-is-a-commercial-invoice-for-international-shipping-"
                                )}
                              >
                                <FormattedMessage
                                  id="damagedProductBlade.shipmentDamages"
                                  defaultMessage="Learn more"
                                />
                              </LearnMoreLink>
                            ),
                          }}
                        />
                      }
                    >
                      <Icon type="question-circle-alt" color={defaultTheme.colors.NEUTRAL[300]} />
                    </TooltipWithIcon>
                  </InfoIconContainer>
                </Text>
                <DropzoneContainer>
                  <ShippingDocumentsDropZone
                    id="dropzone-wholesale-comm-invoice"
                    acceptedFileTypes={["pdf"]}
                    onChange={onCommercialIncvoiceFileChange}
                    onFileClear={onCommercialIncvoiceFileClear}
                    loading={isUploadingLabel?.COMMERCIAL_INVOICE}
                    value={commercialInvoiceFilename ? { fileName: commercialInvoiceFilename } : undefined}
                  />
                </DropzoneContainer>
              </Box>
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
};
