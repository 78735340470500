import { Box, Button, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import Spinner from "common/components/Spinner";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SelectService } from "./selectService/SelectService";
import { useCalculateFreightRate } from "./useCalculateFreightRate";

const StyledBox = styled(Box)(
  () => `
  text-align: center;
  width:100%;
`
);
const InnerDiv = styled.div`
  display: inline-block;
`;

const SpinnerContainer = styled.div<ThemeProps>(
  ({ theme }) => `
width: ${theme.spacing.S6};
`
);

export const CalculateFreightRate: React.FC = () => {
  const {
    handleCalculateRateClick,
    isQuoteLoading,
    handleQuoteSelection,
    quoteResponse,
    isCalculateRateDisabled,
  } = useCalculateFreightRate();
  return (
    <StyledBox>
      <InnerDiv>
        {isQuoteLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : quoteResponse ? (
          <SelectService handleQuoteSelection={handleQuoteSelection} quoteResponse={quoteResponse} />
        ) : (
          <Button disabled={isCalculateRateDisabled} onClick={handleCalculateRateClick}>
            <FormattedMessage defaultMessage="Calculate rate" id="storage.selectShipping.steps.calculateFreightRate" />
          </Button>
        )}
      </InnerDiv>
    </StyledBox>
  );
};
