import { Accordion, Box } from "common/components/ui";
import { InboundFooter } from "inbounds/steps/InboundFooter";
import { InstructionCardInfo } from "inbounds/steps/ship/view/cards/InstructionCard";
import { InstructionCardList } from "inbounds/steps/ship/view/cards/InstructionCardList";
import React from "react";
import { useIntl } from "react-intl";
import { addShipmentDetailStep } from "./steps/addShipmentDetails/addShipmentDetailStep";
import { selectCarrierStep } from "./steps/selectCarrier/selectCarrierStep";
import { StorageSelectShippingHeader } from "./StorageSelectShippingHeader";
import { useStorageSelectShipping } from "./useStorageSelectShipping";

export const StorageSelectShipping: React.ComponentType = () => {
  const {
    onNextClick,
    onPreviousClick,
    skuCount,
    units,
    fromAddress,
    isNextDisabled,
    isOpen,
    toggleOpen,
  } = useStorageSelectShipping();
  const { formatMessage } = useIntl();

  const steps = [selectCarrierStep, addShipmentDetailStep].map((step, index) => {
    return {
      id: step.id as string,
      content: <step.component />,
      title: formatMessage(step.title),
      isDefaultOpen: true,
      isDisabled: false,
      cardSectionClassName: "stepCardSection",
      className: "stepCard",
    } as InstructionCardInfo;
  });
  return (
    <Box minHeight="36.25rem" minWidth="62.5rem">
      <Accordion
        indicator
        divider
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        headerContent={<StorageSelectShippingHeader skuCount={skuCount} units={units} fromAddress={fromAddress} />}
        disabled
        noBodyPadding
      >
        <InstructionCardList
          ordinalOptions={{ enumeration: "numeral" }}
          isCollapsible
          shouldRemoveSpaceBetweenCards
          isDefaultOpen={false}
          items={steps}
        />
      </Accordion>
      <InboundFooter
        onButtonClick={onNextClick}
        onPreviousButtonClick={onPreviousClick}
        disabled={isNextDisabled}
        manualLoader
      />
    </Box>
  );
};
