import { ReducerDictionary, createReducer } from "common/ReduxUtils";
import {
  EntityError,
  OperationStatus,
  ShippingPartner,
  ShippingType,
  TransportationOption,
} from "@deliverr/replenishment-client";
import { TransportationOptionsActionTypes } from "./TransportationOptionActionTypes";
import { reduce } from "lodash";

export interface ShipmentTransportationOption {
  shippingType: ShippingType;
  isSupported?: boolean;
  error?: EntityError;
  transportationOption: TransportationOption;
}

export interface TransportationOptionSummary {
  operationStatus: OperationStatus;
  operationId?: number;
  errors: EntityError[];
  transportationOptions: {
    [temporaryShipmentId: number]: ShipmentTransportationOption[];
  };
}

export interface ShipmentTransportationConfiguration {
  shippingType?: ShippingType;
  shippingPartner?: ShippingPartner;
}

export interface TransportationOptionsState {
  transportationEstimates: {
    [placementOptionId: number]: {
      [K in ShippingPartner]?: TransportationOptionSummary;
    };
  };
  selectedTransportationConfigurations: {
    [placementOptionId: number]: {
      [temporaryShipmentId: number]: ShipmentTransportationConfiguration;
    };
  };
}

export const transportationOptionsInitialState: TransportationOptionsState = {
  transportationEstimates: {},
  selectedTransportationConfigurations: {},
};

const reducers: ReducerDictionary<TransportationOptionsState> = {
  [TransportationOptionsActionTypes.TRANSPORTATION_OPTIONS_FETCH_SUCCESS]: (state, action) => ({
    transportationEstimates: {
      ...state.transportationEstimates,
      [action.placementOptionId]: {
        ...state.transportationEstimates[action.placementOptionId],
        [action.shippingPartner]: {
          operationStatus: action.estimateOperationResponse.operationStatus,
          operationId: action.estimateOperationResponse.operationId,
          transportationOptions: action.estimateOperationResponse.transportationOptions,
          errors: action.estimateOperationResponse.errors,
        },
      },
    },
    selectedTransportationConfigurations: state.selectedTransportationConfigurations,
  }),
  [TransportationOptionsActionTypes.SET_TRANSPORTATION_CONFIGURATION]: (state, action) => ({
    transportationEstimates: state.transportationEstimates,
    selectedTransportationConfigurations: {
      ...state.selectedTransportationConfigurations,
      [action.placementOptionId]: {
        ...state.selectedTransportationConfigurations[action.placementOptionId],
        [action.temporaryShipmentId]: {
          shippingType: action.transportationConfiguration.shippingType,
          shippingPartner: action.transportationConfiguration.shippingPartner,
        },
      },
    },
  }),
  [TransportationOptionsActionTypes.TRANSPORTATION_OPTIONS_RESET_PLACEMENT_STATE]: (state, action) => ({
    transportationEstimates: {
      ...state.transportationEstimates,
      [action.placementOptionId]: {
        ...state.transportationEstimates[action.placementOptionId],
        [action.shippingPartner]: {
          ...state.transportationEstimates[action.shippingPartner],
          operationStatus: OperationStatus.CREATED,
          operationId: undefined,
          transportationOptions: {},
          errors: [],
        },
      },
    },
    selectedTransportationConfigurations: {
      ...state.selectedTransportationConfigurations,
      [action.placementOptionId]: reduce(
        state.selectedTransportationConfigurations[action.placementOptionId],
        (acc, value, key) => {
          acc[key] =
            value.shippingPartner === action.shippingPartner
              ? {
                  shippingType: undefined,
                  shippingPartner: value.shippingPartner,
                }
              : value;
          return acc;
        },
        {}
      ),
    },
  }),
  [TransportationOptionsActionTypes.TRANSPORTATION_OPTIONS_RESET_STATE]: () => transportationOptionsInitialState,
};

export const transportationOptionsReducer = createReducer<TransportationOptionsState>(
  transportationOptionsInitialState,
  reducers
);
