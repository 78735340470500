/* eslint-disable @typescript-eslint/no-floating-promises */
import { DistributionChannel, ShippingPartner, ShippingType } from "@deliverr/replenishment-client";
import { WholesaleShipmentType, WholesaleShippingMethod } from "@deliverr/wholesale-client";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";
import {
  selectIsMultiShipmentWithFeatureOn,
  selectTransferCreate,
} from "transfers/create/store/TransferCreateSelectors";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";
import { Estimation } from "./Estimation";
import { ReferenceIdNotification } from "./ReferenceIdNotification";
import { getShippingEstimation, setShippingEstimationState } from "./getShippingEstimation";
import { transferCreateChooseShippingConfig } from "transfers/create/actions";
import { getReplenishmentShippingPartnerFromWholesaleType } from "../utils/getReplenishmentShippingPartnerFromWholesaleType";
import { getReplenishmentShippingTypeFromWholesaleType } from "../utils/getReplenishmentShippingTypeFromWholesaleType";
import { flexportTransportValidator } from "./flexportTransportValidator";
import { ValidationTypes } from "./ValidationTypes";
import { isInternationalDestination } from "../utils/isInternationalDestination";
import { FeatureName, useFeatureOn } from "common/Split";
import { getSellerId } from "common/user/UserSelectors";
import { fetchReplenishmentCarrierAccounts } from "./carrier-account/actions/fetchReplenishmentCarrierAccounts";
import { isEmpty } from "lodash";

export const shippingPartnerToWholesaleShippingMethod: Record<ShippingPartner, WholesaleShippingMethod> = {
  [ShippingPartner.DELIVERR]: WholesaleShippingMethod.DELIVERR,
  [ShippingPartner.SELLER]: WholesaleShippingMethod.SELLER,
  [ShippingPartner.FBA]: WholesaleShippingMethod.SELLER,
  [ShippingPartner.WFS]: WholesaleShippingMethod.SELLER,
  [ShippingPartner.COLLECT]: WholesaleShippingMethod.COLLECT,
};

export const shipmentTypeToWholesaleShipmentType: Record<ShippingType, WholesaleShipmentType> = {
  [ShippingType.LTL]: WholesaleShipmentType.PARTIAL_PALLET,
  [ShippingType.FTL]: WholesaleShipmentType.PARTIAL_PALLET,
  [ShippingType.PARCEL]: WholesaleShipmentType.PARCEL,
};

export type EstimateMap = Partial<Record<ShippingPartner, Partial<Record<ShippingType, Estimation>>>>;

export const useReplenishmentOrderShippingStep = () => {
  const dispatch = useDispatch();
  const {
    shippingMethod,
    cargoType,
    destinationType,
    replenishmentOrder,
    shipmentShippingMethodMap,
    destinationAddress,
    selectedCarrierAccount,
    carrierAccounts,
  } = useSelector(selectTransferCreate);
  const orderId = replenishmentOrder?.orderId;
  const shipments = replenishmentOrder?.shipments ?? [];
  const isDeliverrMethod = shippingMethod === WholesaleShippingMethod.DELIVERR;
  const [shippingEstimation, setShippingEstimation] = useState<EstimateMap>(
    getInitialShippingEstimationState(destinationType)
  );
  const isMultiShipmentWithFeatureOn = useSelector(selectIsMultiShipmentWithFeatureOn);
  const isSelectCarrierEnable = useFeatureOn(FeatureName.WholesaleCarrierAccount);
  const isDestinationWholesale = destinationType === TransferCreationTypes.Wholesale;
  const isInternationalDestinationCountry = !!isInternationalDestination(destinationAddress?.country);
  const sellerId = useSelector(getSellerId);

  const getIsNextDisabled = () => {
    if (shippingMethod === WholesaleShippingMethod.COLLECT && isEmpty(selectedCarrierAccount)) {
      return true;
    }
    if (!isMultiShipmentWithFeatureOn) {
      if (!shippingMethod || !cargoType) {
        return true;
      }
      const shippingPartner = getReplenishmentShippingPartnerFromWholesaleType(shippingMethod, destinationType);
      const shippingType = getReplenishmentShippingTypeFromWholesaleType(cargoType);
      return !!shippingEstimation?.[shippingPartner]?.[shippingType]?.hasError;
    } else {
      return (
        !shippingMethod ||
        Object.values(shipmentShippingMethodMap[shippingMethod]).filter(Boolean).length < shipments.length
      );
    }
  };

  const onChangeShippingMethod = (value: WholesaleShippingMethod) => {
    dispatch(transferCreateChooseShippingConfig({ shippingMethod: value }));
  };

  const isNextDisabled = getIsNextDisabled();

  const retryShippingEstimation = async (
    retryShippingMethod: WholesaleShippingMethod,
    retryDestinationType: TransferCreationTypes,
    shippingType: ShippingType
  ) => {
    const shippingPartner = getReplenishmentShippingPartnerFromWholesaleType(
      retryShippingMethod,
      retryDestinationType!
    );
    if (shippingPartner && shippingType) {
      await getShippingEstimation(shippingPartner, shippingType, orderId!, setShippingEstimation);
    }
  };

  const shouldShowLabelProvider =
    isSelectCarrierEnable &&
    replenishmentOrder?.distributionChannel === DistributionChannel.WHOLESALE &&
    (shippingMethod === WholesaleShippingMethod.SELLER || shippingMethod === WholesaleShippingMethod.COLLECT) &&
    cargoType === WholesaleShipmentType.PARCEL;

  useMount(async () => {
    if (isEmpty(carrierAccounts)) {
      await dispatch(fetchReplenishmentCarrierAccounts(sellerId));
    }
    if (Object.keys(shippingEstimation).length === 1) {
      dispatch(
        transferCreateChooseShippingConfig({
          shippingMethod: shippingPartnerToWholesaleShippingMethod[Object.keys(shippingEstimation)[0]],
        })
      );
    }

    let validationTypes = [] as ValidationTypes[];

    switch (destinationType) {
      case TransferCreationTypes.Fba: {
        validationTypes = [ValidationTypes.TOTAL_NUM_OF_BOXES_FBA, ValidationTypes.INTERNATIONAL_ORDER];
        break;
      }
      case TransferCreationTypes.Wholesale: {
        validationTypes = [ValidationTypes.INTERNATIONAL_ORDER, ValidationTypes.EACHES_PICKING];
        break;
      }
      case TransferCreationTypes.FbaIntegration: {
        validationTypes = [ValidationTypes.FBA_PALLET_COUNT_CHECK, ValidationTypes.FBA_INVALID_DIMS];
        break;
      }
    }

    const shipmentValidationError =
      validationTypes.length > 0 ? await dispatch(flexportTransportValidator(validationTypes)) : {};
    const freightShipmentValidationEstimation = shipmentValidationError?.[ShippingPartner.DELIVERR]?.[ShippingType.LTL];
    const parcelShipmentValidationEstimation =
      shipmentValidationError?.[ShippingPartner.DELIVERR]?.[ShippingType.PARCEL];
    const fbaFreightShipmentValidationEstimation = shipmentValidationError?.[ShippingPartner.FBA]?.[ShippingType.LTL];
    const fbaParcelShipmentValidationEstimation = shipmentValidationError?.[ShippingPartner.FBA]?.[ShippingType.PARCEL];
    if (parcelShipmentValidationEstimation?.hasError || !!parcelShipmentValidationEstimation?.showMessage) {
      setShippingEstimation((state) =>
        setShippingEstimationState(
          state,
          ShippingPartner.DELIVERR,
          ShippingType.PARCEL,
          parcelShipmentValidationEstimation
        )
      );
    } else {
      getShippingEstimation(ShippingPartner.DELIVERR, ShippingType.PARCEL, orderId!, setShippingEstimation);
    }

    if (freightShipmentValidationEstimation?.hasError || !!freightShipmentValidationEstimation?.showMessage) {
      setShippingEstimation((state) =>
        setShippingEstimationState(
          state,
          ShippingPartner.DELIVERR,
          ShippingType.LTL,
          freightShipmentValidationEstimation
        )
      );
    } else {
      getShippingEstimation(ShippingPartner.DELIVERR, ShippingType.LTL, orderId!, setShippingEstimation);
    }

    if (destinationType === TransferCreationTypes.FbaIntegration) {
      if (fbaParcelShipmentValidationEstimation?.hasError) {
        setShippingEstimation((state) =>
          setShippingEstimationState(
            state,
            ShippingPartner.FBA,
            ShippingType.PARCEL,
            fbaParcelShipmentValidationEstimation
          )
        );
        if (fbaFreightShipmentValidationEstimation?.hasError) {
          setShippingEstimation((state) =>
            setShippingEstimationState(
              state,
              ShippingPartner.FBA,
              ShippingType.LTL,
              fbaFreightShipmentValidationEstimation
            )
          );
        } else {
          getShippingEstimation(ShippingPartner.FBA, ShippingType.LTL, orderId!, setShippingEstimation);
        }
      } else {
        getShippingEstimation(ShippingPartner.FBA, ShippingType.PARCEL, orderId!, setShippingEstimation).finally(() => {
          if (fbaFreightShipmentValidationEstimation?.hasError) {
            setShippingEstimation((state) =>
              setShippingEstimationState(
                state,
                ShippingPartner.FBA,
                ShippingType.LTL,
                fbaFreightShipmentValidationEstimation
              )
            );
          } else {
            getShippingEstimation(ShippingPartner.FBA, ShippingType.LTL, orderId!, setShippingEstimation);
          }
        });
      }
    }
  });

  return {
    destinationAddress,
    shippingMethod,
    cargoType,
    isDeliverrMethod,
    shippingEstimation,
    destinationType,
    retryShippingEstimation,
    isNextDisabled,
    isDestinationWholesale,
    isInternationalDestinationCountry,
    onChangeShippingMethod,
    shouldShowLabelProvider,
  };
};

const getDefaultEstimation = () => {
  return {
    isPending: true,
    hasError: false,
    retryable: true,
  } as Estimation;
};

const getInitialShippingEstimationState = (destinationType?: TransferCreationTypes) => {
  const estimates: EstimateMap = {
    [ShippingPartner.DELIVERR]: {
      [ShippingType.LTL]: {
        ...getDefaultEstimation(),
        bottomNotification:
          destinationType === TransferCreationTypes.FbaIntegration ? <ReferenceIdNotification /> : undefined,
      },
      [ShippingType.PARCEL]: getDefaultEstimation(),
    },
  };
  if (destinationType === TransferCreationTypes.FbaIntegration) {
    estimates[ShippingPartner.FBA] = {
      [ShippingType.LTL]: getDefaultEstimation(),
      [ShippingType.PARCEL]: getDefaultEstimation(),
    };
  }

  if (destinationType === TransferCreationTypes.Fba || destinationType === TransferCreationTypes.Wholesale) {
    estimates[ShippingPartner.SELLER] = {
      [ShippingType.LTL]: { ...getDefaultEstimation(), hasError: false, isPending: false, hideEstimation: true },
      [ShippingType.PARCEL]: { ...getDefaultEstimation(), hasError: false, isPending: false, hideEstimation: true },
    };
  }
  if (destinationType === TransferCreationTypes.Wholesale) {
    estimates[ShippingPartner.COLLECT] = {
      [ShippingType.LTL]: { ...getDefaultEstimation(), hasError: false, isPending: false, hideEstimation: true },
      [ShippingType.PARCEL]: { ...getDefaultEstimation(), hasError: false, isPending: false, hideEstimation: true },
    };
  }
  return estimates;
};
