import React from "react";
import { ShowWarningForProductFn } from "common/components/ProductChooser/ShowWarningForProductFn";
import { productIsValidForFBASmallParcel } from "./productIsValidForFBASmallParcel";
import { FBAParcelValidationErrorMessage } from "./FBAParcelValidationErrorMessage";

export const integratedFbaValidations: ShowWarningForProductFn = (product) => {
  return {
    showError: !productIsValidForFBASmallParcel(product),
    message: <FBAParcelValidationErrorMessage />,
  };
};
