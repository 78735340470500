import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { InboundShipmentReceiptEta } from "./InboundShipmentReceiptEta";

export type InboundEstimationClientConfig = ApiClientConfig;

export class InboundEstimationClient {
  private apiClient: ApiClient;

  constructor(
    { baseURL }: InboundEstimationClientConfig = { baseURL: mustBeDefined(process.env.INBOUND_ESTIMATION_SERVICE_URL) }
  ) {
    this.apiClient = new ApiClient({
      baseURL,
    });
  }

  async getShipmentEta(shipmentId: number): Promise<InboundShipmentReceiptEta | undefined> {
    return await this.apiClient.get({
      url: `/v1/geteta/${shipmentId}`,
      authentication: isAuthenticated,
    });
  }
}
