import { FastTagType } from "common/clients/fastTag/FastTagType";
import { FAST_TAG_CONNECT } from "tags/model/FastTagOperation";
import { linearStepCount } from "tags/model/StepCount/linearStepCount";
import { facebookInitialState, facebookReducer } from "./state/FacebookReducer";
import { adSetsSyncPage } from "./pages/AdSetsSync/adSetsSyncPage";
import { adsSettingsPage } from "./pages/AdsSettings/adsSettingsPage";
import { configureAdsPage } from "./pages/ConfigureAds/configureAdsPage";
import { connectAccountPage } from "./pages/ConnectAccount/connectAccountPage";
import { connectShopifyPage } from "./pages/ConnectShopify/connectShopifyPage";
import { connectStartPage } from "./pages/ConnectStart/connectStartPage";
import { savedAudienceWarningPage } from "./pages/SavedAudienceWarning/savedAudienceWarningPage";
import { getPreviousPageFromLinear } from "tags/model/FastTagPage/getPreviousPageFromLinear";
import { getNextPageFromLinear } from "tags/model/FastTagPage/getNextPageFromLinear";

const pages = [
  connectStartPage,
  connectShopifyPage,
  connectAccountPage,
  adsSettingsPage,
  savedAudienceWarningPage,
  configureAdsPage,
  adSetsSyncPage,
];

export const facebookConnectFlow = {
  fastTag: FastTagType.FACEBOOK,
  operation: FAST_TAG_CONNECT,
  entry: connectStartPage,
  initialState: facebookInitialState,
  reducer: facebookReducer,
  pages,
  getStepCount: linearStepCount(pages),
  getPreviousPage: getPreviousPageFromLinear(pages),
  getNextPage: getNextPageFromLinear(pages),
} as const;
