import classNames from "classnames";
import React, { FC } from "react";

import { Logo, LogoColor } from "../Logo";

import cls from "./DeliverrBadge.less";

export enum BadgeColor {
  darkBlue = "darkBlue",
}

export interface DeliverrBadgeProps {
  diameter: string;
  logoColor: LogoColor;
  backgroundColor: LogoColor | BadgeColor;
  circle?: boolean;
  className?: string;
}

export const DeliverrBadge: FC<DeliverrBadgeProps> = (props: DeliverrBadgeProps) => {
  const { diameter, logoColor, backgroundColor, circle, className } = props;
  return (
    <div
      style={{ width: diameter, height: diameter }}
      className={classNames(className, cls[backgroundColor], cls.logoContainer, { [cls.circle]: circle })}
    >
      <Logo color={logoColor} fullLogo={false} />
    </div>
  );
};
