import styled from "@emotion/styled";
import React, { HTMLAttributes } from "react";
import { ContentElement, Size, DefaultTheme } from "../shared";

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  /** Html element of Card */
  as?: keyof typeof ContentElement;
  /** Set width to content, default is block */
  inline?: boolean;
  /** Padding of wrapper */
  size?: keyof typeof Size | "STANDARD" | "NONE";
  /** Title for standard sizing */
  header?: JSX.Element | string;
}

const standardCardPadding = "40px";

const CardHeader = styled.div``;
const CardBody = styled.div``;

const StyledCard = styled.div<CardProps, DefaultTheme>(({ inline, size = Size.MD, header, theme }) => {
  const sizes = {
    NONE: 0,
    [Size.SM]: theme.spacing.S4,
    [Size.MD]: theme.spacing.S5,
    [Size.LG]: theme.spacing.S6,
    STANDARD: header
      ? `0 ${standardCardPadding} ${theme.spacing.S6} ${standardCardPadding}`
      : `${theme.spacing.S6} ${standardCardPadding}`,
  };
  return `
    background: ${theme.colors.NEUTRAL["00"]};
    box-shadow: ${theme.shadow.S1};
    display: ${inline ? "inline-block" : "block"};
    ${
      header
        ? `
      ${CardBody} {
        padding: ${sizes.STANDARD};
      }
      ${CardHeader} {
        padding: ${theme.spacing.S5} ${standardCardPadding};
      }
    `
        : `
      padding: ${sizes[size]};
    `
    }

  `;
});

export const Card: React.FC<CardProps> = ({ children, ...props }) => (
  <StyledCard {...props}>
    {props.header ? (
      <>
        <CardHeader>{props.header}</CardHeader>
        <CardBody>{children}</CardBody>
      </>
    ) : (
      children
    )}
  </StyledCard>
);
