import { SPThunkAction } from "common/ReduxUtils";
import { head } from "lodash/fp";
import { getSortedShipments } from "inbounds/steps/InboundStepSelectors";
import { InboundShipmentActionTypes } from "inbounds/store/actions/shipment/InboundShipmentActionTypes";

export const updateShipmentId = (shipmentId?: number): SPThunkAction => (dispatch, getState) => {
  dispatch({
    type: InboundShipmentActionTypes.UPDATE_SHIPMENT_ID,
    shipmentId: shipmentId ?? head(getSortedShipments(getState()))!.id,
  });
};
