import React from "react";
import { SessionRecordingPrivacy } from "./SessionRecordingPrivacy";

export interface SessionRecordingProps {
  privacy: SessionRecordingPrivacy;
}

/**
 * Controls session recording (i.e. FullStory, Datadog Session Replay) settings for children.
 * "exclude" will completely hide an element, prefer "mask" for sensitive inputs
 */

const DD_CLASSNAME_MAP: Record<SessionRecordingPrivacy, string> = {
  unmask: "dd-privacy-allow",
  exclude: "dd-privacy-hidden",
  mask: "dd-privacy-mask",
};

export const SessionRecording: React.FC<SessionRecordingProps> = ({ children, privacy }) => (
  <div className={`fs-${privacy} ${DD_CLASSNAME_MAP[privacy]}`}>{children}</div>
);
