import { DomesticDestinationType, OriginType, UnitSystem } from "inbounds/createShipment/CreateShipmentTypes";

/**
 * IMPORTANT: Changing any of the values in this file will affect our dashboards
 * You can ADD events or context values
 */

export const EVENT_PREFIX = "smb_create_booking";

/**
 * This context should hold values persisted in Redux only and they should not be nested objects
 */
export interface SmbBookingCreationContext {
  clientFid?: string;
  isReturningUser?: boolean;
  shippingPlanId?: number;
  isInternationalOrigin?: boolean;
  isFlexportCapitalSelected?: boolean;
  isCustomsBondSelected?: boolean;
  isInsuranceSelected?: boolean;
  distributionType?: string;
  amazonDesinationType?: string;
  amazonFacilityCode?: string;
  freightServiceTitle?: string;
  amazonPrepType?: string;
  isRevampedOriginFeatureOn?: boolean;
}

/**
 * The following utilities are used to track the start and end of a function
 * The scope ensures only expected functions are tracked and prefixes them for consistency
 * Do not call trackSmbBookingCreationEvent and logStart with the same fn name or it will log twice
 * should use logStart AND logSuccess AND logError
 **/
export const SmbBookingCreationTimedEvent = {
  CreateBooking: `${EVENT_PREFIX}.timed.create_booking`,
  UpdateBooking: `${EVENT_PREFIX}.timed.update_booking`,
  SubmitBooking: `${EVENT_PREFIX}.timed.submit_booking`,
  LoadBooking: `${EVENT_PREFIX}.timed.load_booking`,
  FetchQuote: `${EVENT_PREFIX}.timed.fetch_booking_quote`,
};

/**
 * Most actions are tracked in DataDog automatically and do not need custom triggers
 * For tracked click events, you can provide custom names to clickable elements
 * https://docs.datadoghq.com/real_user_monitoring/browser/tracking_user_actions/
 */

export type SmbBookingClickNextEvent =
  | "next.origin"
  | "next.supplier"
  | "next.fulfillment_network"
  | "next.direct_destination"
  | "next.cargo_details"
  | "next.domestic_fulfillment_network"
  | "next.domestic_fulfillment_destination";

// TODO: @gxdesign - remove deprecated events
// https://flexport.atlassian.net/browse/SMBIPB-765
export type SmbBookingCreationEvent =
  | SmbBookingClickNextEvent
  | "click_create_inbound"
  | "click_save_for_later"
  | "create_booking" // deprecated
  | "submit_booking" // deprecated
  | "booking_quote" // deprecated
  | "origin_search_step" // deprecated
  | "supplier_select_step" // deprecated
  | "fulfillment_select_step" // deprecated
  | "destination_search_step" // deprecated
  | "cargo_details_step" // deprecated
  | "price_view_step" // deprecated
  | "no_freight_services"
  | "click_next" // deprecated
  | "init_international_booking"
  | "init_domestic_booking"
  | "view_freight_service_list"
  | "change_freight_service"
  | "view_legacy_user_notification"
  | "use_own_transportation"
  | "estimate_pallet_count"
  | "view_charge_breakdown"
  | "collapse_checkout_section"
  | "temp.select_intl_origin"
  | "email_verification_modal_shown"
  | "view_by_unit_switch_clicked";

type FulfillmentNetwork = "FLEXPORT" | "AMAZON" | "FLEXPORT_AND_AMAZON" | "DTC" | "FREIGHT_ONLY";

export type ClickNextProperties<EventType extends SmbBookingCreationEvent> = EventType extends "next.origin"
  ? {
      originType: OriginType;
    }
  : EventType extends "next.fulfillment_network"
  ? {
      fulfillmentType: FulfillmentNetwork;
    }
  : EventType extends "next.direct_destination"
  ? { isLiftGateRequired: string; isDestinationPort: string }
  : EventType extends "next.cargo_details"
  ? {
      unitSystem: UnitSystem;
      totalBoxes: number;
      totalUnits: number;
      totalSkus: number;
      totalPallets: number;
      totalWeight: number;
      totalVolume: number;
      hasDangerousGoods: string;
    }
  : EventType extends "next.domestic_fulfillment_network"
  ? {
      fulfillmentType: FulfillmentNetwork;
    }
  : EventType extends "next.domestic_fulfillment_destination"
  ? {
      destinationType: DomesticDestinationType;
    }
  : {};
