import { AmazonErrorCode as AmazonReplenishmentErrorCode } from "@deliverr/replenishment-client";
import { defineMessages } from "react-intl";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";

const FbaOrderCreationErrorDescriptions = {
  orderErrors: defineMessages({
    [AmazonReplenishmentErrorCode.CAPACITY_EXCEEDED]: {
      id: "replenishment.fba.order.error.capacityExceeded",
      defaultMessage: "Shipment has reached restock limit set by Amazon",
    },
    [AmazonReplenishmentErrorCode.AMAZON_CONTRACT_NOT_SIGNED]: {
      id: "replenishment.fba.order.error.contractNotSigned",
      defaultMessage: "You have not accepted Amazon's Partnered Carrier Program terms and conditions",
    },
    [AmazonReplenishmentErrorCode.CARRIER_CONTRACT_NOT_SIGNED]: {
      id: "replenishment.fba.order.carrierContractNotSigned",
      defaultMessage: "You have not accepted the carrier's terms and conditions in Amazon",
    },
    [AmazonReplenishmentErrorCode.SELLER_IS_BLOCKED_ON_INBOUNDING_SHIPMENTS]: {
      id: "replenishment.fba.order.sellerBlocked",
      defaultMessage: "Your FBA account is blocked from inbounding shipments",
    },
    [AmazonReplenishmentErrorCode.QUANTITY_IS_NOT_DIVISIBLE_BY_QTY_IN_CASE]: {
      id: "replenishment.fba.order.sellerBlocked",
      defaultMessage: "The quantity to ship is not divisible by the quantity per case for some products",
    },
    [AmazonReplenishmentErrorCode.SELLER_IS_BLOCKED_BY_CORD_MANAGAER]: {
      id: "replenishment.fba.order.sellerBlocked",
      defaultMessage: "Your FBA account is blocked by Amazon",
    },
    [AmazonReplenishmentErrorCode.SHIPMENT_UPDATE_NOT_ALLOWED]: {
      id: "replenishment.fba.order.shipmentUpdateNotAllowed",
      defaultMessage: "Updates to the shipment are not permitted by Amazon",
    },
    [AmazonReplenishmentErrorCode.SHIPMENT_STATUS_CHANGE_NOT_ALLOWED]: {
      id: "replenishment.fba.order.shipmentStatusChangeNotAllowed",
      defaultMessage: "Status updates for this Amazon shipment are restricted",
    },
    [AmazonReplenishmentErrorCode.CANNOT_CONFIRM_TRANSPORT_CONTENT]: {
      id: "replenishment.fba.orderItem.cannotConfirmTransportContent",
      defaultMessage: "Cannot confirm transport content for this shipment",
    },
    [AmazonReplenishmentErrorCode.CANNOT_ESTIMATE_TRANSPORT_CONTENT]: {
      id: "replenishment.fba.orderItem.cannotEstimateTransportContent",
      defaultMessage: "Cannot estimate transport content for this shipment",
    },
    [AmazonReplenishmentErrorCode.CANNOT_VOID_TRANSPORT_CONTENT]: {
      id: "replenishment.fba.orderItem.cannotVoidTransportContent",
      defaultMessage: "Cannot void transport content for this shipment",
    },
  }),
  itemErrors: defineMessages({
    [AmazonReplenishmentErrorCode.ITEM_WITH_NO_VALID_FC]: {
      id: "replenishment.fba.orderItem.error.noValidFC",
      defaultMessage: "Item has no eligible FBA Fulfillment Center as it is hazmat",
    },
    [AmazonReplenishmentErrorCode.NO_EXTERNAL_IDENTIFIER_FOR_EXISTING_MAP]: {
      id: "replenishment.fba.orderItem.error.noExternalIdentifier",
      defaultMessage: "Item is missing a UPC on Seller Central",
    },
    [AmazonReplenishmentErrorCode.ITEM_UNDER_HAZMAT_REVIEW]: {
      id: "replenishment.fba.orderItem.error.hazmatReview",
      defaultMessage: "Hazmat item under review by Amazon",
    },
    [AmazonReplenishmentErrorCode.EXTERNAL_ID_MAPS_MULTIPLE_IAIDS]: {
      id: "replenishment.fba.orderItem.error.multipleIADS",
      defaultMessage: "UPC of item is assigned to multiple ASINs",
    },
    [AmazonReplenishmentErrorCode.UNKNOWN_SKU]: {
      id: "replenishment.fba.orderItem.error.unknownSku",
      defaultMessage: "Item has reached capacity limit set by Amazon",
    },
    [AmazonReplenishmentErrorCode.CAPACITY_EXCEEDED]: {
      id: "replenishment.fba.orderItem.error.capacityExceeded",
      defaultMessage: "Item has reached capacity limit set by Amazon",
    },
    [AmazonReplenishmentErrorCode.INVALID_MSKU_REMAP]: {
      id: "replenishment.fba.orderItem.error.invalidMskuRemap",
      defaultMessage: "Msku of item doesn't match with Amazon",
    },
    [AmazonReplenishmentErrorCode.ITEM_BLOCKED_BY_GATING]: {
      id: "replenishment.fba.orderItem.error.blockedItem",
      defaultMessage: "You are restricted from selling this brand or item on Amazon due to brand or category gating",
    },
    [AmazonReplenishmentErrorCode.MISSING_ASIN]: {
      id: "replenishment.fba.orderItem.error.blockedItem",
      defaultMessage: "One of your items is missing an ASIN",
    },
    [AmazonReplenishmentErrorCode.NOT_IN_PRODUCT_CATALOG]: {
      id: "replenishment.fba.orderItem.error.notInCatalogue",
      defaultMessage: "Item is currently not active in your FBA catalog",
    },
    [AmazonReplenishmentErrorCode.MISSING_DIMENSIONS]: {
      id: "replenishment.fba.orderItem.error.missingDimensions",
      defaultMessage: "Item is missing dimensions in Amazon",
    },
    [AmazonReplenishmentErrorCode.EXCLUDED_FROM_COMMINGLING]: {
      id: "replenishment.fba.orderItem.error.excludedFromCommingling",
      defaultMessage: "Item is not eligible for stickerless commingling",
    },
    [AmazonReplenishmentErrorCode.ITEM_STRANDED]: {
      id: "replenishment.fba.orderItem.error.itemStranded",
      defaultMessage: "Item has been marked as stranded by Amazon",
    },
    [AmazonReplenishmentErrorCode.LTSF_RESTRICTION]: {
      id: "replenishment.fba.orderItem.error.ltsfRestriction",
      defaultMessage: "Amazon restricts sending more units due to recent cleanup of aged inventory for item(s)",
    },
    [AmazonReplenishmentErrorCode.MELTABLE_PRODUCT_RESTRICTION]: {
      id: "replenishment.fba.orderItem.error.meltableProductRestriction",
      defaultMessage: "Amazon restricts sending 'meltable' units at this time of year",
    },
    [AmazonReplenishmentErrorCode.FAILED_TO_REVIEW_PACKAGE_REASON]: {
      id: "replenishment.fba.orderItem.error.packageReasonReviewFailure",
      defaultMessage: "Matrix parent cannot be sent to FBA",
    },
    [AmazonReplenishmentErrorCode.EXP_DATED_ITEM_HAS_EXISTING_STICKERLESS_MAP]: {
      id: "replenishment.fba.orderItem.error.existingStickerlessMap",
      defaultMessage: "Item is marked as a commingled product, but it is not commingle-able",
    },
    [AmazonReplenishmentErrorCode.LABEL_PREP_OWNER_INCOMPATIBILITY_ERROR]: {
      id: "replenishment.fba.orderItem.error.labelIncompatibilityError",
      defaultMessage: "Not enough product information for Amazon Label",
    },
    [AmazonReplenishmentErrorCode.OVER_ASIN_GUIDANCE]: {
      id: "replenishment.fba.orderItem.error.asinGuidance",
      defaultMessage: "You are already at the maximum inventory allowed for this product",
    },
    [AmazonReplenishmentErrorCode.SUPPLY_CATEGORY_UNAVAILABLE]: {
      id: "replenishment.fba.orderItem.error.supplyCategoryUnavailable",
      defaultMessage: "Item is enrolled in UNO and cannot be used for MCF",
    },
    [AmazonReplenishmentErrorCode.EXTERNAL_ID_MAPS_MULTIPLE_IAIDS_FOR_EXISTING_MAP]: {
      id: "replenishment.fba.orderItem.multipleIADSForExistingMap",
      defaultMessage: "Item has a new FNSKU and can no longer be posted under the existing one",
    },
    [AmazonReplenishmentErrorCode.INVALID_PRODUCTS]: {
      id: "replenishment.fba.orderItem.multipleIADSForExistingMap",
      defaultMessage: "Item was not found in your amazon catalogue",
    },
    [AmazonReplenishmentErrorCode.HAZMAT_ITEM_INELIGIBLE]: {
      id: "replenishment.fba.orderItem.hazmatItemIneligible",
      defaultMessage:
        "Item is Hazmat and needs to be applied for Hazmat in Amazon seller central. When the item is approved by Amazon for Hazmat, it can be inbounded in FBA",
    },
    [AmazonReplenishmentErrorCode.EXTERNAL_ID_MAPS_WRONG_IAID_FOR_EXISTING_MAP]: {
      id: "replenishment.fba.orderItem.externalIdMapsWrongIaidForExistingMap",
      defaultMessage: "The UPC or FNSKU has been wrongly mapped to the ASIN in Amazon",
    },
    [AmazonReplenishmentErrorCode.EXCEEDED_UNITS_PER_ITEM_LIMIT]: {
      id: "replenishment.fba.orderItem.exceededUnitsPerItemLimit",
      defaultMessage: "Unit count for this item exceeds inbound limit in Amazon",
    },
    [AmazonReplenishmentErrorCode.BRAND_RETAIL_ONLY_MERCHANT]: {
      id: "replenishment.fba.orderItem.brandRetailOnlyMerchant",
      defaultMessage:
        "Item is configured for sale through Amazon Retail. Please reconfigure it to be sold via Amazon FBA",
    },
    [AmazonReplenishmentErrorCode.UNITS_PER_CASE_EXCEEDED]: {
      id: "replenishment.fba.orderItem.unitsPerCaseExceeded",
      defaultMessage: "Item case quantity exceeds the maximum allowed quantity of 150 units",
    },
    [AmazonReplenishmentErrorCode.ANDON_PULL_STRIKE_ONE]: {
      id: "replenishment.fba.orderItem.andonPullStrikeOne",
      defaultMessage:
        "The ASIN has been temporarily suspended due to a possible violation of FBA policies during delivery. Please check for an Andon Cord notification for the product on Seller Central that requires investigation.",
    },
    [AmazonReplenishmentErrorCode.MULTI_OPTIONS_NOT_ALLOWED]: {
      id: "replenishment.fba.orderItem.multiOptionsNotAllowed",
      defaultMessage: "Received a MULTI_OPTIONS_NOT_ALLOWED error for this product from Amazon",
    },
    [AmazonReplenishmentErrorCode.DUPLICATE_FNSKU]: {
      id: "replenishment.fba.orderItem.duplicateFnsku",
      defaultMessage: "The FNSKU is appears to be a duplicate of an existing product FNSKU in Amazon",
    },
    [AmazonReplenishmentErrorCode.FIOS_NO_PATHS_TO_CUSTOMER]: {
      id: "replenishment.fba.orderItem.fiosNoPathsToCustomer",
      defaultMessage: "No eligible Amazon fulfillment centers available to inbound the specified product(s)",
    },
  }),
};

export const ReplenishmentUnknownErrorDescription = defineMessages({
  UnknownError: {
    id: "replenishment.entity.error.unknown",
    defaultMessage: "Unknown error",
  },
});

export const ReplenishmentOrderCreationErrorDescriptions = {
  [TransferCreationTypes.Fba]: FbaOrderCreationErrorDescriptions,
  [TransferCreationTypes.FbaIntegration]: FbaOrderCreationErrorDescriptions,
};

export const ReplenishmentOrderErrorMessages = defineMessages({
  CardTitle: {
    id: "replenishment.order.failure.card.title",
    defaultMessage: "A shipping plan could not be generated",
  },
  CardDescription: {
    id: "replenishment.order.failure.card.description",
    defaultMessage: "Review and fix the following issues or remove the affected products, then try again.",
  },
  OrderErrorTitle: {
    id: "replenishment.order.failure.orderError.title",
    defaultMessage: "Shipment errors",
  },
  OrderItemErrorTitle: {
    id: "replenishment.order.failure.itemError.title",
    defaultMessage: "Products with errors",
  },
  ErrorResolutionDescription: {
    id: "replenishment.order.failure.resolution.description",
    defaultMessage: "A short description about how to resolve the issue. {learnMore}",
  },
  rawErrorMessage: {
    id: "replenishment.order.failure.rawErrorMessage",
    defaultMessage: "Error message received from channel: {rawErrorMessage}",
  },
});
