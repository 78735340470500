import React from "react";
import { Button, ButtonProps } from "common/components/ui";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { CreateInboundOptions, goToCreateInbound } from "./createShipment/store/actions/goToCreateInbound";
import { clearInbound } from "./createShipment/store/actions";
import { trackSmbBookingCreationEvent } from "./createShipment/common/analytics/trackSmbBookingCreationEvent";

type Options = Omit<CreateInboundOptions, "clearInbound">;
type ClickHandler = (options?: Options) => void;

interface RenderArgs {
  onClick: ClickHandler;
  defaultLabel: string;
}

interface Props extends ButtonProps {
  render?: (args: RenderArgs) => JSX.Element;
  options?: Options;
  label?: string | JSX.Element;
}

export const CreateInboundButton = ({ render, label, options: optionsProp, ...buttonProps }: Props) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const defaultLabel = formatMessage({
    id: "common.createInbound",
    defaultMessage: "Create inbound",
  });

  const handleClick: ClickHandler = (options) => {
    dispatch(clearInbound());
    trackSmbBookingCreationEvent("click_create_inbound");
    dispatch(goToCreateInbound(options));
  };

  /** can be passed a render function for custom components */
  if (render) {
    return render({
      onClick: handleClick,
      defaultLabel,
    });
  }

  /** otherwise can be styled as a Button, onClick can be overwritten
   * as goToCreateInbound can be imported and used in a custom click handler */
  return (
    <Button onClick={() => handleClick(optionsProp)} {...buttonProps}>
      {label ?? defaultLabel}
    </Button>
  );
};
