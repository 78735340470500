import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { mapError } from "../core/mapError";
import { CoverageApiResponse } from "../fastTag/CoverageApiResponse";
import { FastTagClient } from "../fastTag/FastTagClient";
import { ServiceLevelCoverage } from "../fastTag/ServiceLevelCoverage";
import { WalmartError } from "./WalmartError";

export type WalmartClientConfig = ApiClientConfig;

export class WalmartClient implements FastTagClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: WalmartClientConfig = { baseURL: mustBeDefined(process.env.WALMART_SERVICE_URL) }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`,
    });
  }

  async disableFastTag(sellerId: string, dsku: string): Promise<void> {
    try {
      await this.apiClient.put({
        url: `/seller/${sellerId}/item/${dsku}`,
        body: { isTwoDayEnabled: false },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [WalmartError.NOT_FOUND]);
    }
  }

  async enableFastTag(sellerId: string, dsku: string): Promise<void> {
    try {
      await this.apiClient.put({
        url: `/seller/${sellerId}/item/${dsku}`,
        body: { isTwoDayEnabled: true },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [WalmartError.NOT_FOUND]);
    }
  }

  async getDskuStateCoverage(dsku: string): Promise<CoverageApiResponse> {
    return await this.apiClient.get({
      url: `/coverage/${dsku}`,
      authentication: isAuthenticated,
    });
  }

  async getStateCoverageByServiceLevel(dsku: string): Promise<ServiceLevelCoverage> {
    try {
      return await this.apiClient.get({
        url: `/coverage/servicelevel/${dsku}`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async isFastTagEnabled(sellerId: string, dsku: string): Promise<boolean> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/item/${dsku}/active`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [WalmartError.NOT_FOUND]);
    }
  }

  async getLinkForDsku(sellerId: string, dsku: string): Promise<string> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/item/${dsku}/link`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [
        WalmartError.INVALID_API_KEY,
        WalmartError.NOT_FOUND,
        WalmartError.NOT_FOUND_IN_MARKETPLACE,
      ]);
    }
  }

  async updateLinkForDsku(
    sellerId: string,
    dsku: string,
    listingUrl?: string,
    productIdentifier?: string
  ): Promise<void> {
    try {
      await this.apiClient.put({
        url: `/seller/${sellerId}/item/${dsku}/listing`,
        body: {
          listingUrl,
          msku: productIdentifier,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [
        WalmartError.INVALID_URL,
        WalmartError.LISTING_IS_MISSING_SKU,
        WalmartError.MSKU_NOT_FOUND,
        WalmartError.MULTIPLE_VARIANTS,
      ]);
    }
  }

  async submitApiKeys(sellerId: string, consumerId: string, privateKey: string): Promise<void> {
    try {
      await this.apiClient.put({
        url: `/api-key`,
        body: { sellerId, consumerId, privateKey },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [
        WalmartError.INVALID_API_KEY,
        WalmartError.INVALID_API_PERMISSIONS,
        WalmartError.NON_DELIVERR_CREDENTIALS,
      ]);
    }
  }

  async deactivateSeller(sellerId: string): Promise<string> {
    return await this.apiClient.put({
      url: `/api-key/${sellerId}/deactivate`,
      body: {},
      authentication: isAuthenticated,
    });
  }

  async activateSeller(sellerId: string): Promise<void> {
    return await this.apiClient.put({
      url: `/api-key/${sellerId}/activate`,
      body: {},
      authentication: isAuthenticated,
    });
  }
}
