import { complement, difference, isEmpty, keys, pipe, pick, pickBy, trim } from "lodash/fp";
import { AddressInputRequiredField } from "common/components/AddressInput";
import { OrderRequestLocation } from "freight/store/FreightReducer";
import { FreightContact } from "freight/types/AddressTypes";
import { DeliverrAddress } from "@deliverr/commons-objects";
import { addressRequiredFieldsValidation } from "common/AddressUtils";
import { QuoteAddressMetadata } from "freight/store/freightQuoting/reducer/FreightQuotingReducer";

const isValidValue = (val) => isEmpty(trim(val));

const keysWithValues = pipe(pickBy(complement(isValidValue)), keys);

export const orderLocationRequiredFieldsValidation = (address: OrderRequestLocation) => {
  const requiredFields: AddressInputRequiredField[] = ["street1", "city", "state", "country", "zip"];
  const providedFields = pipe(pick(requiredFields), keysWithValues)(address) as AddressInputRequiredField[];
  return difference(requiredFields, providedFields);
};

export const validateContactDetails = (contact: Omit<FreightContact, "phoneExt">): boolean => {
  return Object.values(contact).every((value) => value !== "");
};

export const isQuoteAddressValid = (address: DeliverrAddress, addressMetadata: QuoteAddressMetadata) =>
  addressRequiredFieldsValidation(address, ["city", "state", "zip", "country"]).length === 0 &&
  !addressMetadata.isDeliverrWarehouse;

export const isAddressValid = (address: DeliverrAddress) =>
  addressRequiredFieldsValidation(address, ["street1", "city", "state", "zip", "country"]).length === 0;
