import { Product } from "@deliverr/commons-clients";

export const trustedDimSourceTypes: string[] = [
  "crossdock", // trusted by MG, but not lib-commons utils
  "warehouse",
  "averageregression",
  "manual",
  "amazonscraped",
  "seller",
];

export const productHasTrustedDims = ({ dimSourceType }: Product) =>
  trustedDimSourceTypes.includes(dimSourceType ?? "");
