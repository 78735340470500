import React from "react";
import { BoxReset, RadioTileGroup, Stack, Text } from "common/components/ui";
import styled from "@emotion/styled";
import { useSelectService } from "./useSelectService";
import { FormattedMessage } from "react-intl";
import { QuoteResponse } from "@deliverr/freight-client";
const StyledRadioTileGroup = styled(RadioTileGroup)`
  justify-content: space-between;

  label {
    width: 47%;
  }
`;

export interface SelectServiceProps {
  handleQuoteSelection: React.ChangeEventHandler<HTMLInputElement> | undefined;
  quoteResponse: QuoteResponse;
}

export const SelectService: React.FC<SelectServiceProps> = ({ handleQuoteSelection, quoteResponse }) => {
  const { quoteOptions, quoteValidDate, selectedQuote } = useSelectService(quoteResponse);

  return (
    <Stack direction="VERTICAL">
      <BoxReset marginBottom={"S2"}>
        <Text as="header" bold>
          <FormattedMessage defaultMessage="Select Service" id="storage.inbound.selectService.title" />
        </Text>
      </BoxReset>

      <StyledRadioTileGroup currentValue={selectedQuote} onChange={handleQuoteSelection} options={quoteOptions} />

      <BoxReset marginTop={"S4"}>
        <Text appearance="INFO" size="caption">
          <FormattedMessage
            defaultMessage="Confirm your shipment by {quoteValidDate} to guarantee this rate. Your final invoice amount may change due to additional carrier pickup fees or unexpected cargo quantities, weights, and dimensions."
            id="storage.inbound.selectService.footer"
            values={{ quoteValidDate }}
          />
        </Text>
      </BoxReset>
    </Stack>
  );
};
