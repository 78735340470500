import { OrderStatus } from "common/clients/orders/DeliverrOrder/OrderStatus";
import { PillColors } from "common/components/ui";
import { isBadAddress } from "order/detail/OrderEvents/helpers/isBadAddress";
import { OrderPageItem } from "order/listV2/OrderListReducer";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { OrderTag } from "@deliverr/commons-objects";

type StatusTypes =
  | "INVALID_ADDRESS"
  | OrderStatus.CANCELLED
  | "PROCESSING"
  | "IN_PROGRESS"
  | "IN_TRANSIT"
  | OrderStatus.DELIVERED
  | "DISPOSED";

const statusMessages: Record<StatusTypes, MessageDescriptor> = defineMessages({
  INVALID_ADDRESS: {
    id: "OrderList.status.INVALID_ADDRESS",
    defaultMessage: "INVALID ADDRESS",
  },
  [OrderStatus.CANCELLED]: {
    id: "OrderList.status.CANCELLED",
    defaultMessage: "CANCELLED",
  },
  PROCESSING: {
    id: "OrderList.status.PROCESSING",
    defaultMessage: "PROCESSING",
  },
  IN_PROGRESS: {
    id: "OrderList.status.IN_PROGESS",
    defaultMessage: "IN PROGRESS",
  },
  IN_TRANSIT: {
    id: "OrderList.status.IN_TRANSIT",
    defaultMessage: "IN TRANSIT",
  },
  [OrderStatus.DELIVERED]: {
    id: "OrderList.status.DELIVERED",
    defaultMessage: "DELIVERED",
  },
  DISPOSED: {
    id: "OrderList.status.DISPOSED",
    defaultMessage: "DISPOSED",
  },
});

export const useOrderListStatus = (order: OrderPageItem) => {
  const { formatMessage } = useIntl();
  const { status, cancelSource, orderIngestionTime, orderDetails, tags } = order;
  const orderIsDisposal = tags.includes(OrderTag.INVENTORY_DISPOSAL);

  if (status === OrderStatus.CANCELLED) {
    return {
      status: formatMessage(statusMessages[OrderStatus.CANCELLED]),
      color: cancelSource === "SELLER" ? PillColors.GRAY : PillColors.RED,
    };
  }

  if (orderIsDisposal && status === OrderStatus.DELIVERED) {
    return {
      status: formatMessage(statusMessages.DISPOSED),
      color: PillColors.GREEN,
    };
  }

  if (status === OrderStatus.DELIVERED) {
    return {
      status: formatMessage(statusMessages[OrderStatus.DELIVERED]),
      color: PillColors.GREEN,
    };
  }

  if (isBadAddress(order)) {
    return {
      status: formatMessage(statusMessages.INVALID_ADDRESS),
      color: PillColors.RED,
    };
  }

  if (orderIsDisposal && status === OrderStatus.SHIPPED) {
    return {
      status: formatMessage(statusMessages.IN_PROGRESS),
      color: PillColors.BLUE,
    };
  }

  if (status === OrderStatus.SHIPPED) {
    return {
      status: formatMessage(statusMessages.IN_TRANSIT),
      color: orderDetails?.isDeliveredLate ? PillColors.YELLOW : PillColors.BLUE,
    };
  }

  if (orderIsDisposal && orderIngestionTime) {
    return {
      status: formatMessage(statusMessages.PROCESSING),
      color: PillColors.GRAY,
    };
  }

  if (orderIngestionTime) {
    return {
      status: formatMessage(statusMessages.PROCESSING),
      color: orderDetails?.isShippedLate ? PillColors.YELLOW : PillColors.GRAY,
    };
  }

  return {
    status: undefined,
    color: undefined,
  };
};
