import React, { FC } from "react";
import { Icon, Stack, defaultTheme, Text, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import { MissingBoxesAlertStatus } from "@deliverr/commons-clients";
import { FormattedMessage } from "react-intl";

import { isFinalShortShipStatus } from "./isFinalShortShipStatus";
import { isCreatedShortShip } from "../../utils";
import { CreatedSection, SHORT_SHIP_STATUS_SPECIFIC_LABELS } from "./";

const SectionWrapper = styled.div<ThemeProps>(
  ({ theme }) => `
  padding-top:  ${theme.spacing.S4};
`
);

const IconCircle = styled.div<ThemeProps>(
  ({ theme }) => `
  border-radius: 50%;
  background-color: ${theme.colors.YELLOW[100]};
  padding: ${theme.spacing.S4};

  svg {
    width: 28px!important; //override icon width to make background circle round
  }
`
);

const CenterText = styled(Text)`
  text-align: center;
`;

export interface StatusSectionProps {
  status: MissingBoxesAlertStatus;
}

export const StatusSection: FC<StatusSectionProps> = ({ status }) => (
  <SectionWrapper>
    {isFinalShortShipStatus(status) ? (
      <Stack center>
        <Icon type="check-circle" size="3x" color={defaultTheme.colors.GREEN[300]} />
        <CenterText size="bodyLarge" bold>
          <FormattedMessage {...SHORT_SHIP_STATUS_SPECIFIC_LABELS[status].header} />
        </CenterText>
        <CenterText>
          <FormattedMessage {...SHORT_SHIP_STATUS_SPECIFIC_LABELS[status].message} />
        </CenterText>
      </Stack>
    ) : isCreatedShortShip(status) ? (
      <CreatedSection />
    ) : (
      <Stack center>
        <IconCircle>
          <Icon type="hourglass-half-lt" size="2x" color={defaultTheme.colors.ORANGE[400]} />
        </IconCircle>
        <CenterText size="bodyLarge" bold>
          <FormattedMessage {...SHORT_SHIP_STATUS_SPECIFIC_LABELS[status].header} />
        </CenterText>
        <CenterText>
          <FormattedMessage {...SHORT_SHIP_STATUS_SPECIFIC_LABELS[status].message} />
        </CenterText>
      </Stack>
    )}
  </SectionWrapper>
);
