import { combineReducers } from "redux";

import { OrderStatus } from "freight/types";
import { CargoPalletDetailsState } from "freight/types/CargoPalletTypes";
import { cargoPalletDetailsReducer } from "freight/store/cargoPalletDetails/reducer/CargoPalletDetailsReducer";

import {
  FreightAccordionSteps,
  freightBookingAccordionStepsReducer,
} from "freight/store/accordionSteps/reducer/FreightBookingAccordionStepsReducer";
import { createReducer, ReducerDictionary } from "common/ReduxUtils";
import { FreightBookingActionTypes } from "../actions/FreightBookingActionTypes";
import { freightOrderIdReducer } from "freight/store/confirmShipment/reducer/OrderIdReducer";
import { Quote } from "freight/types/Quote";
import { freightGeneratedOrdersReducer, FreightGeneratedOrdersState } from "./freightGeneratedOrderReducer";
import { PickupDetailsState } from "freight/types/PickupTypes";
import { pickupDetailsReducer } from "freight/store/pickupDetails/reducer/FreightPickupDetailsReducer";
import { DeliveryDetailsState } from "freight/types/DeliveryTypes";
import { deliveryDetailsReducer } from "freight/store/deliveryDetails/reducer/FreightDeliveryDetailsReducer";
import { DrayageDetailsState } from "freight/types/DrayageDetailsTypes";
import { drayageDetailsReducer } from "freight/store/drayageDetails/reducer/DrayageDetailsReducer";
import { DrayageCargoDetailsState } from "freight/types/DrayageCargoDetailsTypes";
import { drayageCargoDetailsReducer } from "freight/store/drayageCargoDetails/reducer/DrayageCargoDetailsReducer";
import { FbaDeliveryDetailsState } from "freight/types/FbaDeliveryTypes";
import { fbaDeliveryDetailsReducer } from "freight/store/fbaDeliveryDetails/reducer/FreightFbaDeliveryDetailsReducer";

export interface FreightBookingState {
  freightPickupDetails: PickupDetailsState;
  freightDeliveryDetails: DeliveryDetailsState;
  cargoPalletDetails: CargoPalletDetailsState;
  drayageDetails: DrayageDetailsState;
  drayageCargoDetails: DrayageCargoDetailsState;
  freightFbaDeliveryDetails: FbaDeliveryDetailsState;
  bookingSteps: FreightAccordionSteps;
  status: OrderStatus;
  quote: Quote | null;
  freightOrderId: number | null;
  generatedOrders: FreightGeneratedOrdersState;
}

const statusReducer: ReducerDictionary<FreightBookingState["status"]> = {
  [FreightBookingActionTypes.SET_BOOKING_STATUS]: (state, action) => {
    return action.status;
  },
  [FreightBookingActionTypes.RESET_FREIGHT_BOOKING_STATE]: () => OrderStatus.DRAFT,
};

const quoteReducer: ReducerDictionary<FreightBookingState["quote"]> = {
  [FreightBookingActionTypes.SET_FREIGHT_BOOKING_QUOTE]: (state, action) => {
    return action.quote;
  },
  [FreightBookingActionTypes.RESET_FREIGHT_BOOKING_STATE]: () => null,
  [FreightBookingActionTypes.FREIGHT_BOOKING_COMPLETED]: () => null,
};

export const freightBookingReducer = combineReducers<FreightBookingState>({
  freightPickupDetails: pickupDetailsReducer,
  freightDeliveryDetails: deliveryDetailsReducer,
  cargoPalletDetails: cargoPalletDetailsReducer,
  drayageDetails: drayageDetailsReducer,
  drayageCargoDetails: drayageCargoDetailsReducer,
  freightFbaDeliveryDetails: fbaDeliveryDetailsReducer,
  bookingSteps: freightBookingAccordionStepsReducer,
  status: createReducer<OrderStatus>(OrderStatus.DRAFT, statusReducer),
  quote: createReducer<Quote | null>(null, quoteReducer),
  freightOrderId: freightOrderIdReducer,
  generatedOrders: freightGeneratedOrdersReducer,
});
