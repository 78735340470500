import styled from "@emotion/styled";
import { ThemeProps } from "common/components/ui";
import HtmlToReact from "common/components/HtmlToReact";
import { ellipsis } from "common/components/ui/shared";

export const NavbarItemNameContainer = styled.div`
  display: inline-flex;
  align-items: center;

  &.editable {
    cursor: pointer;
    visibility: visible;

    &.hidden {
      visibility: hidden;
      transition: all 20s ease-in-out;
    }
  }
`;

export const NavbarItemNameInput = styled.input<ThemeProps>(
  ({ theme }) => `
    background-color: ${theme.colors.NEUTRAL["00"]};
    border-radius: ${theme.border.radius.R2};
    border: 1px solid ${theme.colors.NEUTRAL[200]};
    color: ${theme.colors.NEUTRAL[500]};;
    font-size: ${theme.font.size.F2};
    font-weight: ${theme.font.weight.REGULAR};
    font-family: ${theme.font.family.STANDARD};
    height: 32px;
    padding: 0 ${theme.spacing.S2};
    width: 200px;
`
);

export const NavbarItemName = styled.span<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL[500]};
    font-family: ${theme.font.family.TITLE};
    font-size: ${theme.font.size.F4};
    font-weight: ${theme.font.weight.BOLD};
    ${ellipsis("18rem")}; // TODO: Export from Deliverr UI
  `
);

export const ClickableIconContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    background-color:${theme.colors.BLUE[300]};
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin-left: ${theme.spacing.S2};
    font-size: 10px;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `
);

export const PromoIcon = styled(HtmlToReact)<ThemeProps>(
  ({ theme }) => `
    width: 20px;
    height: 20px;
    margin-left: ${theme.spacing.S2};
  `
);
