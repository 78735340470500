import { Pill, PillColors } from "common/components/ui";
import React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { transfersShipmentCreateLabels } from "../transfersShipmentCreate.labels";
import { LabelWithPill } from "./LabelWithPill";

export const ConnectDestinationLabel: React.FunctionComponent<{ isConnected: boolean; label: MessageDescriptor }> = ({
  isConnected,
  label,
}) => {
  return (
    <LabelWithPill>
      <FormattedMessage {...label} />
      {isConnected && (
        <Pill color={PillColors.GREEN}>
          <FormattedMessage {...transfersShipmentCreateLabels.steps.destination.connectedPill} />
        </Pill>
      )}
    </LabelWithPill>
  );
};
