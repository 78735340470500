import styled from "@emotion/styled";

export const IconContainer = styled.div`
  margin-right: 10px;
  flex-shrink: 0;
  height: 20px;
  width: 20px;
`;

export const PrepPlanNameWrapper = styled.div`
  display: flex;
`;
