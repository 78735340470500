import { IconV2Types } from "common/components/ui/IconV2";
import { Appearance } from "common/components/ui/shared";
import React, { FC } from "react";
import { Link, Route } from "react-router-dom";
import { useSidebarContext } from "./SidebarContext";
import { SidebarItemContainer } from "./SidebarItemContainer";
import { SidebarItemContent } from "./SidebarItemContent";

export interface SidebarPageItemProps {
  path: string | string[];
  navigationPath?: string;
  exact?: boolean;
  title: string | JSX.Element;
  svg?: string;
  icon?: IconV2Types;
  logo?: React.ReactNode;
  secondary?: boolean;
  attentionIcon?: Appearance;
  testId?: string;
  active?: boolean;
  itemCount?: number;
}

/**
 * Link to a page. Styled as active if the given route (or sub-route) is a match for the current route.
 */
export const SidebarPageItem: FC<SidebarPageItemProps> = ({
  path,
  navigationPath,
  exact,
  title,
  svg,
  icon,
  logo,
  secondary,
  attentionIcon,
  testId,
  active: activeProp,
  itemCount,
}) => {
  const { mode, onItemClick } = useSidebarContext();

  return (
    <Route path={path} exact={exact ?? false} strict={false}>
      {({ match }) => {
        const isActive = activeProp ?? !!match;
        return (
          <SidebarItemContainer active={isActive} mode={mode}>
            <Link
              aria-current={isActive ? "page" : undefined}
              to={navigationPath ?? (path as string)}
              data-testid={testId}
              onClick={onItemClick}
            >
              <SidebarItemContent
                svg={svg}
                icon={icon}
                logo={logo}
                description={title}
                secondary={secondary}
                active={isActive}
                attentionIcon={attentionIcon}
                itemCount={itemCount}
              />
            </Link>
          </SidebarItemContainer>
        );
      }}
    </Route>
  );
};
