import { createReducer, ReducerDictionary } from "common/ReduxUtils";
import produce from "immer";
import { DestinationOptions } from "returns/seller/store/ReturnsSellerStore.types";
import { ReturnsOnboardState, ReturnsOnboardTypes } from "./ReturnsOnboardStore.types";

export const returnsOnboardInitialState: ReturnsOnboardState = {
  stepId: 1,
  destination: { addressType: "NEW", country: "US" } as DestinationOptions,
};

const reducers: ReducerDictionary<ReturnsOnboardState> = {
  [ReturnsOnboardTypes.RETURNS_ONBOARD_SET_DESTINATION_TYPE]: (state, { destinationType }) => ({
    ...state,
    destinationType,
  }),
  [ReturnsOnboardTypes.RETURNS_ONBOARD_SET_SOP]: (state, { sopId }) => ({
    ...state,
    sopId,
  }),

  [ReturnsOnboardTypes.RESET_RETURNS_ONBOARD]: () => ({ ...returnsOnboardInitialState }),
  [ReturnsOnboardTypes.RETURNS_ONBOARD_ADD_ADDRESS]: (state, { newSellerAddress }) => {
    const newState = produce(state, (draft) => {
      draft.destination = newSellerAddress;
    });
    return newState;
  },
  [ReturnsOnboardTypes.RETURNS_GOTO_ONBOARD_STEP]: (state, { stepId }) => ({
    ...state,
    stepId,
  }),
  [ReturnsOnboardTypes.RETURNS_SOP_UPDATE_SUCCESS]: (state, { sopDetails }) => ({
    ...state,
    sopDetails,
    errorMessage: undefined,
  }),
  [ReturnsOnboardTypes.RETURNS_SOP_UPDATE_FAILURE]: (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }),
  [ReturnsOnboardTypes.RETURNS_ONBOARD_QC_ONLY]: (state, { stepId }) => ({
    ...state,
    stepId,
    forceShowOnboard: true,
  }),
};

export const returnsOnboardReducer = createReducer<ReturnsOnboardState>(returnsOnboardInitialState, reducers);
