import { createActionCreator } from "common/ReduxUtils";
import { ReplenishmentOrderDetailsActionTypes } from "./ReplenishmentOrderDetailsActionTypes";
import { NonComplianceResponse, OrderResponse, ShipmentHistory } from "@deliverr/replenishment-client";
import { Packs } from "common/clients/storage/Packs/Packs";
import { OrderCostsCharges } from "transfers/detail/store/replenishment/ReplenishmentOrderDetailsState";
import { ShipmentAttachmentData } from "./ReplenishmentOrderDetailsState";

export const replenishmentOrderDeatilsSetReplenishmentOrder = createActionCreator<OrderResponse>(
  ReplenishmentOrderDetailsActionTypes.REPLENISHMENT_SET_REPLENISHMENT_ORDER,
  "replenishmentOrder"
);

export const replenishmentOrderDeatilsSetProducts = createActionCreator<Packs>(
  ReplenishmentOrderDetailsActionTypes.REPLENISHMENT_SET_PRODUCTS,
  "products"
);
export const replenishmentOrderDeatilsSetShipmentHistoryMap = createActionCreator<{
  [shipment: string]: ShipmentHistory;
}>(ReplenishmentOrderDetailsActionTypes.REPLENISHMENT_SET_SHIPMENT_HISTORY, "shipmentHistoryMap");

export const replenishmentOrderDeatilsSetShipmentAttachmentDataMap = createActionCreator<{
  [shipment: string]: ShipmentAttachmentData;
}>(ReplenishmentOrderDetailsActionTypes.REPLENISHMENT_SET_SHIPMENT_ATTACHMENTS, "shipmentAttachmentDataMap");

export const resetReplenishmentOrderDeatils = createActionCreator(
  ReplenishmentOrderDetailsActionTypes.REPLENISHMENT_RESET_REPLENISHMENT_ORDER
);

export const replenishmentOrderDetailsSetCosts = createActionCreator<OrderCostsCharges>(
  ReplenishmentOrderDetailsActionTypes.REPLENISHMENT_SET_ORDER_COSTS,
  "replenishmentOrderCosts"
);

export const setReplenishmentOrderNonCompliances = createActionCreator<NonComplianceResponse[]>(
  ReplenishmentOrderDetailsActionTypes.REPLENISHMENT_SET_ORDER_NON_COMPLIANCES,
  "nonCompliances"
);

export const updateReplenishmentOrderNonCompliance = createActionCreator<NonComplianceResponse>(
  ReplenishmentOrderDetailsActionTypes.REPLENISHMENT_UPDATE_NON_COMPLIANCE,
  "nonCompliance"
);
