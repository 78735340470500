import AlgoliaService from "common/list/AlgoliaService";

export const DEFAULT_WHOLESALE_LIST_PAGE_SIZE = 25;

export const algoliaWholesaleShipmentsService = AlgoliaService.get({
  indexName: process.env.ALGOLIA_INDEX_TRANSFERS!,
  searchConfig: {
    hitsPerPage: DEFAULT_WHOLESALE_LIST_PAGE_SIZE,
  },
  highlightMatches: false,
});
