import React, { FC } from "react";
import { GENERIC_ISSUE_BLADE_ID } from "../bladeIds";
import { GenericIssueBlade } from "common/components/ProblemBlade/GenericIssueBlade";
import { nonComplianceInternalRoutes } from "inbounds/non-compliance/NonComplianceRoutes";
import { usePreventScrollToTopNavigation } from "common/hooks/usePreventScrollToTopNavigation";
import { ProblemRowData } from "common/components/ProblemsTable/types";

interface GenericIssueNonComplianceBladeProps {
  problem: ProblemRowData;
}

export const GenericIssueNonComplianceBlade: FC<GenericIssueNonComplianceBladeProps> = ({ problem }) => {
  const navigate = usePreventScrollToTopNavigation();

  const resetBladeState = () => {
    navigate(nonComplianceInternalRoutes.summary);
  };

  return (
    <GenericIssueBlade
      id={GENERIC_ISSUE_BLADE_ID}
      problem={problem}
      onCloseClick={resetBladeState}
      isBladeOpen={true}
    />
  );
};
