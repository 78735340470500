import React, { useEffect, useMemo, useState } from "react";
import { OptionProps } from "@deliverr/react-select-v1";
import { capitalize } from "lodash";
import { useMount } from "react-use";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import logger from "Logger";
import { IconV2 } from "common/components/ui";
import { fetchAvailableToKitQty } from "common/components/ProductSearch/Kit/fetchAvailableToKitQty";
import { saveSearchedKittedProductDetails } from "common/components/ProductChooser/ProductChooserActions";
import { ProductSearchResultItem } from "../ProductSearchResult";
import { ProductErrorMessages } from "../ProductErrors";
import { getProductChooserKittedDSKUDetails } from "common/components/ProductChooser/ProductChooserSelector";

export const useProductSearchOption = (props: OptionProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [availableToKitQty, setAvailableToKitQty] = useState<number>(0);
  const [isLoadingQty, setIsLoadingQty] = useState<boolean>(false);
  const kittedProductDetails = useSelector(getProductChooserKittedDSKUDetails);
  const {
    isDisabled: isDisabledProp,
    data,
    selectProps: { showAvailability, isReserveStorage, showOnlyPacks, shouldShowKitHint },
  } = props;
  const [isDisabled, setIsDisabled] = useState<boolean>(isDisabledProp);
  const {
    error,
    availableQty,
    storageAvailableQty,
    descriptionHtml,
    msku,
    barcode,
    hazmatInformation,
    isKit,
    caseQty,
  } = data as ProductSearchResultItem;
  const shouldFetchKitQty = isReserveStorage && shouldShowKitHint && isKit && caseQty === 1;

  const qtyOrError = useMemo(() => {
    if (isLoadingQty) {
      setIsDisabled(true);
      return formatMessage(
        { id: "productSearch.loadingQty", defaultMessage: "{icon}" },
        { icon: <IconV2 type="clock" /> }
      );
    }

    if (error) {
      return formatMessage(ProductErrorMessages[error], {
        status: capitalize(hazmatInformation?.status ?? ""),
      });
    } else {
      return shouldFetchKitQty
        ? formatMessage(
            { id: "productSearch.availableQuantity", defaultMessage: "{availableToKitQty} potential new kits" },
            { availableToKitQty }
          )
        : formatMessage(
            { id: "productSearch.availableQuantity", defaultMessage: "{qty} available" },
            { qty: isReserveStorage ? storageAvailableQty : availableQty }
          );
    }
  }, [
    availableQty,
    availableToKitQty,
    error,
    formatMessage,
    hazmatInformation?.status,
    isLoadingQty,
    isReserveStorage,
    shouldFetchKitQty,
    storageAvailableQty,
  ]);

  const appendDescriptionHtml = (() => {
    if (shouldShowKitHint && isKit) {
      return isReserveStorage
        ? caseQty === 1
          ? formatMessage({ id: "productSearch.availableKit", defaultMessage: "Available to Kit" })
          : formatMessage({ id: "productSearch.finishedKit", defaultMessage: "Finished Kit" })
        : formatMessage({ id: "productSearch.kit", defaultMessage: "Kit" });
    }
    return "";
  })();

  const updatedDescriptionHtml = appendDescriptionHtml
    ? `${descriptionHtml} (${appendDescriptionHtml ?? ""})`
    : descriptionHtml;

  // Fetch available to kit qty for the product if it is a kit and has case pack qty 1
  useMount(async () => {
    if (shouldFetchKitQty && !kittedProductDetails?.[data.dsku]) {
      const ctx = { fn: "useProductSearchOption.fetchAvailableToKitQty", ...data };
      setIsLoadingQty(true);
      try {
        const { potentialInventory, kitComponents, availableInventoryMap } = await fetchAvailableToKitQty(
          data as ProductSearchResultItem
        );
        dispatch(
          saveSearchedKittedProductDetails(data.dsku, {
            kitComponents,
            availableInventoryMap,
            availableToKit: potentialInventory,
          })
        );
        if (potentialInventory !== undefined) {
          setAvailableToKitQty(potentialInventory);
        }
      } catch (err) {
        logger.error({ ...ctx, err }, "Error fetching Kit quantity");
      } finally {
        setIsLoadingQty(false);
      }
    } else if (kittedProductDetails?.[data.dsku]) {
      setAvailableToKitQty(kittedProductDetails[data.dsku].availableToKit);
    }
  });

  useEffect(() => {
    if (availableToKitQty > 0 && !error) {
      // If availableToKitQty is greater than 0 or if it is has no error, enable the option
      setIsDisabled(false);
    }
  }, [availableToKitQty, error]);

  return {
    qtyOrError,
    updatedDescriptionHtml,
    msku,
    barcode,
    showOnlyPacks,
    isDisabled,
    showAvailability,
    productData: data,
  };
};
