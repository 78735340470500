import React from "react";
import { getSidebarLogo } from "../../../../common/SidebarLogos";
import { ElevioArticleId, ElevioBorderlessButton } from "../../../../common/elevio";
import { FormattedMessage } from "react-intl";
import { fbaIntegrationCommonLabels } from "../../../../wholesale/common/FbaIntegrationCommon.labels";

const helpCenterLabels = fbaIntegrationCommonLabels.helpCenter;
const WalmartSvg = () => getSidebarLogo("WALMART");

export const WalmartLogo = () => (
  <div style={{ width: "60px" }}>
    <WalmartSvg />
  </div>
);

export function getWfsHelpCenterLink() {
  return (
    // Update article id
    <ElevioBorderlessButton articleId={ElevioArticleId.WalmartDirect}>
      <FormattedMessage {...helpCenterLabels.learnMore} />
    </ElevioBorderlessButton>
  );
}
