import React from "react";
import styled from "@emotion/styled";
import { Card } from "common/components/Card";
import { StorageShipmentSummary } from "../StorageShipmentSummary/StorageShipmentSummary";
import { StorageShipmentHeader } from "../StorageShipmentHeader/StorageShipmentHeader";
import { ThemeProps } from "common/components/ui";
import { UnconnectedShipmentAddressDisplay } from "inbounds/steps/ship/ShipmentAddressDisplay";
import { useStorageShipmentDetail } from "./useStorageShipmentDetail";
import { CollapsableStorageShipmentAddressDisplay } from "./CollapsableStorageShipmentAddressDisplay";
import { StorageShipmentAvailabilityDisplay } from "./StorageShipmentAvailabilityDisplay";

const HeaderContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  position: relative;
  width: 100%;
  border-bottom: ${theme.border.width.B1} solid ${theme.colors.NEUTRAL[80]};
`
);

const HeaderSection = styled.div<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S5} ${theme.spacing.S7};
  width: 100%;
`
);

const ShipmentSummaryContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  width: 100%;
  line-height: ${theme.font.lineHeight.LH3};
  border-bottom: ${theme.border.width.B1} solid ${theme.colors.NEUTRAL[80]};
`
);

const ShipmentHeaderCard = styled(Card)`
  width: auto;
`;

export const StorageShipmentDetail: React.FC = () => {
  const { shipmentId, status, from, to, storageWarehouse, shouldShowAvailableUnits } = useStorageShipmentDetail();

  return (
    <ShipmentHeaderCard>
      <HeaderContainer>
        <StorageShipmentHeader shipmentId={shipmentId} status={status} />
      </HeaderContainer>
      <ShipmentSummaryContainer>
        <StorageShipmentSummary />
        {shouldShowAvailableUnits ? (
          <HeaderSection>
            <StorageShipmentAvailabilityDisplay />
          </HeaderSection>
        ) : (
          <UnconnectedShipmentAddressDisplay
            fromAddress={from}
            toAddress={to}
            toProvider={storageWarehouse?.provider}
          />
        )}
      </ShipmentSummaryContainer>
      {shouldShowAvailableUnits ? (
        <CollapsableStorageShipmentAddressDisplay
          fromAddress={from}
          toAddress={to}
          toProvider={storageWarehouse?.provider}
        />
      ) : (
        <></>
      )}
    </ShipmentHeaderCard>
  );
};
