import React, { FC } from "react";
import { LoaderButtonProps, LoaderButton, ButtonType } from "common/components/WithLoader/LoaderButton";

export interface ShipmentDetailCardButtonProps extends LoaderButtonProps {
  label: React.ReactNode;
}

export const ShipmentDetailCardButton: FC<ShipmentDetailCardButtonProps> = (props) => {
  const { label, ...loaderButtonProps } = props;
  return (
    <LoaderButton buttonType={ButtonType.PrimaryReversed} {...loaderButtonProps}>
      {label}
    </LoaderButton>
  );
};
