import { CrossdockInboundQuote } from "common/clients/crossdock/CrossdockInboundQuote/CrossdockInboundQuote";
import { ceilDecimal } from "common/utils/number";

export interface CrossdockIntermediateValues {
  undiscountedTotal: number;
  undiscountedUnitCost: number;
  totalWithEstimatedShipping: number;
  crossdockSavings: number;
}

export const calculateIntermediateValues = (quote?: CrossdockInboundQuote): CrossdockIntermediateValues | undefined => {
  if (!quote) {
    return;
  }

  const { totalDiscount, itemQty, amountCrossdock, amountDirect, totalCost } = quote;

  const undiscountedTotal = totalCost + totalDiscount;
  const undiscountedUnitCost = ceilDecimal(undiscountedTotal / itemQty, 3)!;
  const totalWithEstimatedShipping = totalCost + amountCrossdock;

  const crossdockSavings = amountDirect - totalWithEstimatedShipping;

  return {
    undiscountedTotal,
    undiscountedUnitCost,
    totalWithEstimatedShipping,
    crossdockSavings,
  };
};
