import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import {
  compositeStream,
  conditionalStream,
  consoleStream,
  datadogErrorTrackingStream,
  datadogLoggerStream,
  LogContext,
  logger,
  minimumLevelStream,
  setGlobalContext,
  setStream,
} from "@deliverr/ui-logging";
import { isDevEnv } from "common/Config";
import { getIsValidDataDogEnv } from "common/utils/thirdParty/DataDog/DataDog";
import { DatadogFnLogTimer } from "common/utils/thirdParty/DataDog/DatadogFnLogTimer";
import { loadFullStoryIfNotLoaded } from "common/utils/thirdParty/FullStory";
import store from "store";

// Somehow or another Seller Portal is reliant on importing store in this file.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const localStore = store;

export const setLogUser = (user: { email?: string; sellerId?: string }) => {
  setGlobalContext({
    user: {
      ...user,
      get fullStorySession() {
        // https://app.fullstory.com/ui/C8DBK/session/<logUser.fullStory>
        loadFullStoryIfNotLoaded();
        return (window?.FS?.getCurrentSession && encodeURIComponent(window.FS.getCurrentSession() || "")) || "";
      },
    },
  });
};

setStream(
  compositeStream(
    conditionalStream(() => isDevEnv)(minimumLevelStream("info")(consoleStream)),
    conditionalStream(getIsValidDataDogEnv)(
      compositeStream(datadogLoggerStream(datadogLogs.logger), datadogErrorTrackingStream(datadogRum))
    )
  )
);

// send custom actions to DataDog
const datadogFnTimer = new DatadogFnLogTimer();

export type ErrorlessLogContext = LogContext & { err?: never; error?: never };

const logStart = (ctx: ErrorlessLogContext): ErrorlessLogContext => {
  datadogFnTimer.start(ctx);
  logger.info(ctx, `starting ${ctx.fn}`);
  return ctx;
};

const logSuccess = (ctx: LogContext, message: string): void => {
  datadogFnTimer.success(ctx);
  logger.info(ctx, message);
};

const logError = (ctx: ErrorlessLogContext, err: Error | unknown | string, message?: string): void => {
  datadogFnTimer.error(ctx);
  logger.error(ctx, err, message);
  // eslint-disable-next-line no-console
  console.error(err);
};

export { logStart, logSuccess, logError };
export default logger;
