import { Button, Input, ThemeProps, Title } from "common/components/ui";
import React, { FC } from "react";
import { Card } from "common/components/Card";
import { FormattedMessage, useIntl } from "react-intl";
import { RmtCardProps, rmtConfigProp } from "../../RmtUtils";
import styled from "@emotion/styled";
import { ReturnsRmtConnectHelp } from "../RmtHelp/ReturnsRmtHelp";
import { useSetupRmtCard } from "./useSetupRmtCard";
import { ElevioArticleId } from "common/elevio";
import SetupRmtCardAmazon from "./SetupRmtCardAmazon";

const StyledHeader = styled.div<ThemeProps>(
  ({ theme }) => `
  text-align: center;
  margin-top: ${theme.spacing.S3};
  margin-bottom: ${theme.spacing.S7};
  `
);

const StyledButton = styled(Button)<ThemeProps>(
  ({ theme }) => `
  margin-right: ${theme.spacing.S5};
  margin-left: ${theme.spacing.S5};
`
);

const FooterContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.S4} 0;
  box-shadow: inset 0 1px 0 0 ${theme.colors.NEUTRAL[60]};
  border-radius: ${theme.border.radius.R0} ${theme.border.radius.R2};
  width: 100%;
`
);

const MainContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing.S7};
  margin-bottom: ${theme.spacing.S9};
  width: 100%;
`
);

const StyledCard = styled(Card)<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${theme.spacing.S7} auto;
  box-shadow: ${theme.shadow.S1};
  border-radius: ${theme.border.radius.R4};
`
);
// TODO: percentage on label was not working
const StyledInput = styled(Input)<ThemeProps>(
  ({ theme }) => `
  width: 500px;
  margin-bottom: ${theme.spacing.S3};
`
);

const Logo = styled.div(`
  line-height: 1;

  > svg {
    height: 3rem;
    width: 3rem;
  }
  `);

export const SetupRmtCard: FC<Partial<RmtCardProps>> = ({ rmt }) => {
  const {
    onBackClicked,
    sellerRmtId,
    onConnectClicked,
    isLoading,
    setRmtToken,
    isValidToken,
    isConnectDisabled,
    validateRmtToken,
  } = useSetupRmtCard(rmt);

  const roundedLogo = rmtConfigProp(sellerRmtId, "roundedLogo");
  const rmtName = rmtConfigProp(sellerRmtId, "name");
  const connectRmtElevioArticleId = rmtConfigProp(sellerRmtId, "connectRmtElevioArticleId") as ElevioArticleId;

  const { formatMessage } = useIntl();

  return sellerRmtId === "amazon" ? (
    <SetupRmtCardAmazon rmt={rmt} />
  ) : (
    <StyledCard>
      <MainContainer data-testid="returns-rmt-setup-step">
        <StyledHeader>
          <Logo>{roundedLogo}</Logo>
          <Title as="h4" displayAs="h4" data-testid="returns-rmt-setup-title">
            <FormattedMessage
              id="returns.rmt.setup.title"
              defaultMessage="Connect {rmtName}"
              values={{
                rmtName,
              }}
            />
          </Title>
        </StyledHeader>
        <StyledInput
          data-testid="returns-rmt-setup-token-input"
          label={<FormattedMessage id="returns.rmt.setupRmt.apiToken.label" defaultMessage={"API Key"} />}
          type="string"
          min={0}
          placeholder={formatMessage({
            id: "returns.rmt.setuprmt.apiToken.placeholder",
            defaultMessage: "Enter API Key",
          })}
          onChange={(e) => {
            setRmtToken(e.target.value);
            validateRmtToken(e.target.value);
          }}
          hasError={!isValidToken}
          errorMessage={<FormattedMessage id="API token cannot be empty or with whitespaces" defaultMessage="" />}
        />
        <ReturnsRmtConnectHelp articleId={connectRmtElevioArticleId} marginTop />
      </MainContainer>

      <FooterContainer>
        <StyledButton data-testid="returns-rmt-setup-back" secondary onClick={onBackClicked}>
          <FormattedMessage id="returns.rmt.setup.back.button" defaultMessage="Back" />
        </StyledButton>
        <StyledButton
          data-testid="returns-rmt-setup-connect-button"
          onClick={onConnectClicked}
          loading={isLoading}
          disabled={isConnectDisabled}
        >
          <FormattedMessage
            id="returns.rmt.setup.connect.button"
            defaultMessage="Connect {rmtName}"
            values={{ rmtName }}
          />
        </StyledButton>
      </FooterContainer>
    </StyledCard>
  );
};
