import { ReceivingInfo } from "@deliverr/legacy-inbound-client";
import { sumBy } from "lodash/fp";
import { StorageShipmentUnitBreakdown } from "./StorageShipmentUnitBreakdown";

export const useStorageShipmentUnitData = (receivingInfo: ReceivingInfo | undefined): StorageShipmentUnitBreakdown => {
  const expectedQty: number = sumBy("expectedQty", receivingInfo?.items);
  const receivedQty: number = sumBy("receivedQty", receivingInfo?.items);
  const damagedQty: number = sumBy("damagedQty", receivingInfo?.items);

  const discrepancyQty: number = expectedQty - receivedQty - damagedQty;

  return {
    expectedQty,
    receivedQty,
    damagedQty,
    discrepancyQty,
  };
};
