import { freightApiAdapter } from "common/clients/instances";
import logger, { logError, logStart, logSuccess } from "Logger";
import { SPThunkAction } from "common/ReduxUtils";
import { setShipmentFreightInfo } from "./setShipmentFreightInfo";
import { FreightTrackingInfoSaveData } from "common/clients/transportation/FreightTrackingInfo/FreightTrackingInfoSaveData";
import { FreightTrackingInfoExternalIdType } from "common/clients/transportation/FreightTrackingInfo/FreightTrackingInfoExternalIdType";
import { getLoadedPlannedShipment } from "inbounds/steps/InboundStepSelectors";
import { notifyUserOfError } from "common/ErrorToast";
import { FreightShipmentBaseInfo } from "inbounds/InboundTypes";
import { getSellerId } from "common/user/UserSelectors";
import { extractFreightShipmentInfo } from "inbounds/steps/ship/InboundUtils";
import { getIsFreightExternal } from "inbounds/utils/shippingMethodUtils";

export const createFreightTrackingInfo =
  (freightInfo: FreightShipmentBaseInfo): SPThunkAction =>
  async (dispatch, getState) => {
    const rootState = getState();

    const { id: shipmentId, shippingMethod } = getLoadedPlannedShipment(rootState);
    const sellerId = getSellerId(rootState);
    let ctx = { fn: "createFreightTrackingInfo", sellerId, shipmentId, shippingMethod };

    if (!getIsFreightExternal(shippingMethod)) {
      logger.warn(ctx, "Cannot create FreightTrackingInfo for a non-freight shipment");
      return;
    }

    ctx = { ...ctx, ...freightInfo };

    if (!freightInfo) {
      logger.warn({ ...ctx }, "Cannot create FreightTrackingInfo without data.");
      return;
    }

    logStart(ctx);

    // Prepare for save
    const freightInfoSaveData: FreightTrackingInfoSaveData = {
      externalId: String(shipmentId),
      externalIdType: FreightTrackingInfoExternalIdType.INBOUND_SHIPMENT,
      ...extractFreightShipmentInfo(freightInfo), // clean-up falsy values
    };

    try {
      const resolvedFreightInfo = await freightApiAdapter.createFreightTrackingInfo(sellerId, freightInfoSaveData);
      dispatch(
        setShipmentFreightInfo({
          ...resolvedFreightInfo,
          saveSource: "CREATE",
        })
      );
      logSuccess(ctx, "successfully created freight tracking information");
    } catch (err) {
      logError(ctx, err);
      notifyUserOfError({
        err,
        explanation: `An error occurred while creating freight information for this shipment.`,
        toastId: "createFreightTrackingInfo",
      });
    }
  };
