import { useSelector } from "react-redux";

import { RelabeledBoxProblemRow } from "common/components/ProblemsTable/types";
import { getReceivingInfo } from "inbounds/steps/ship/view/ViewShipmentSelectors";
import { useEstimatedTotalChargeTable } from "common/components/ProblemBlade/components/EstimatedTotalChargeTable";
import { RELABELED_BOX_ESTIMATED_TOTAL_CHARGE_TABLE_LABELS } from "./labels";
import { useRelabeledBoxContentsTable } from "./useRelabeledBoxContentsTable";
import { SelectedShipmentProblem } from "../../context";

const TOTAL = 0;
const PER_UNIT_CHARGE = 1.75;

export const useShipmentProblemRelabeledBoxBlade = (
  shipmentProblem: RelabeledBoxProblemRow,
  hideShipmentProblemBlade: () => void,
  setSelectedProblem: (problem: SelectedShipmentProblem | undefined) => void
) => {
  const { items } = useSelector(getReceivingInfo);

  const estimateTotalChargeTableProps = useEstimatedTotalChargeTable({
    units: shipmentProblem.boxCount,
    total: TOTAL, // hard-coding for now per MG's request
    perUnitCharge: PER_UNIT_CHARGE, // hard-coding for now per MG's request
    labels: RELABELED_BOX_ESTIMATED_TOTAL_CHARGE_TABLE_LABELS,
  });

  const resetBladeState = () => {
    hideShipmentProblemBlade();
    setSelectedProblem(undefined);
  };

  const relabeledBoxContentsTableProps = useRelabeledBoxContentsTable({ shipmentProblem, items });

  return {
    estimateTotalChargeTableProps,
    relabeledBoxContentsTableProps,
    resetBladeState,
  };
};
