import { SortDir } from "common/models/SortDir";
import { DistributionChannel } from "@deliverr/replenishment-client";
import { ReplenishmentIndexRow } from "../ReplenishmentIndexRow";
import { ReplenishmentDestinationType } from "./ReplenishmentDestinationType";
import { ReplenishmentListStatus } from "../ReplenishmentListStatuses";

export interface ReplenishmentListState {
  pageItems?: Array<ReplenishmentIndexRow>;
  searchTerm: string;
  sort: {
    fieldName: string;
    direction: SortDir;
  };
  isError?: boolean;
  pageNum: number;
  nbPages?: number;
  searchFilters: ReplenishmentListSearchFilters;
  showChannelReplenishmentBanner: Record<DistributionChannel.FBA, boolean>;
}

export interface ReplenishmentListSearchFilters {
  orderStatuses: ReplenishmentListStatus[];
  destinationType: ReplenishmentDestinationType;
  tabFilter: ReplenishmentOrderListTabFilter;
}

export enum ReplenishmentOrderListTabFilter {
  ALL_ORDERS = "ALL_ORDERS",
  HAS_PROBLEM = "HAS_PROBLEM",
}
