import { Box, Text } from "common/components/ui";
import React, { FC } from "react";
import { InvalidOrderItemDto, InvalidOrderItemResolution } from "@deliverr/replenishment-client";
import { SelectedProductData } from "common/components/ProductChooser";
import { invalidOrderItemLabel } from "../invalidOrderItemLabel";
import { ProductCasePackSelect } from "common/components/ProductCasePackSelect";
import { ProductCasePack } from "common/clients/product/ProductCasePack";
import { LoadingSpinner } from "dashboard/common";
import { useInvalidCaseConfigResolution } from "./useInvalidCaseConfigResolution";

interface InvalidCaseConfigResolutionProps {
  invalidItem: InvalidOrderItemDto;
  selectedProduct?: SelectedProductData;
  setSelectedProduct: (product: SelectedProductData | undefined) => void;
  setResolution: (resolution: InvalidOrderItemResolution | undefined) => void;
  selectedProductCasePack?: ProductCasePack;
}

export const InvalidCaseConfigResolution: FC<InvalidCaseConfigResolutionProps> = ({
  invalidItem,
  selectedProduct,
  setSelectedProduct,
  setResolution,
}) => {
  const {
    formatMessage,
    setSelectedProductCasePack,
    selectedProductCasePack,
    product,
    productCasePacks,
    isSelectedProductLoading,
  } = useInvalidCaseConfigResolution({
    invalidItem,
    selectedProduct,
    setSelectedProduct,
    setResolution,
  });

  return (
    <>
      {isSelectedProductLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Text size="bodyLarge" bold>
            {formatMessage(invalidOrderItemLabel.blade.unknownItemCaseConfig)}
          </Text>

          <Box paddingTop={"S2"}>
            <ProductCasePackSelect
              product={product!}
              productCasePacks={productCasePacks}
              selectedCasePack={selectedProductCasePack}
              onSelectCasePack={setSelectedProductCasePack}
              noMargin
              showAvailableStorageInventory
              showProductCasePackErrors
              maximumSelectableCaseQty={invalidItem.requestedTotalUnits}
            />
          </Box>
        </>
      )}
    </>
  );
};
