import React from "react";
import { Text } from "common/components/ui";
import { CardTable, createCellObject } from "inbounds/steps/ship/view/cards/CardTable";
import { FormattedMessage } from "react-intl";
import { StyledHeader } from "transfers/detail/ui/CardStyledHeader";
import { getStorageProductDsku } from "storage/utils/getStorageProductDsku";
import { ProductDetailData } from "inbounds/steps/ship/view/cards/ProductDetailData";
import { TextAlign } from "common/components/ui/shared";
import { ShipmentResponse } from "@deliverr/replenishment-client";
import { Products } from "common/utils/product/Products";
import { shipmentContentsLabels } from "transfers/detail/shipmentContents.labels";

export const ShipmentContents: React.FC<{
  products: Products;
  shipment?: ShipmentResponse;
}> = ({ products, shipment }) => {
  const headers = [
    createCellObject(
      <StyledHeader bold size="caption">
        <FormattedMessage {...shipmentContentsLabels.productName} />
      </StyledHeader>,
      "left",
      `replenishment-shipment-detail-contents-product`
    ),
    createCellObject(
      <StyledHeader bold size="caption" textAlign={TextAlign.end}>
        <FormattedMessage {...shipmentContentsLabels.units} />
      </StyledHeader>,
      "right",
      `replenishment-shipment-detail-contents-units`
    ),
  ];

  const tableRows = React.useMemo(() => {
    return (
      shipment?.shipmentItems?.map((shipmentItem) => {
        let { dsku, unitsPerPack } = shipmentItem;
        const product = products[dsku];
        const skuDetails = product.productCasePacks?.find((casePack) => casePack.quantity === unitsPerPack);
        const productDetails = { ...product, ...skuDetails, dsku, msku: product.msku };
        dsku = getStorageProductDsku(product) ?? dsku;

        const productSKUCell = <ProductDetailData product={productDetails} shouldShowPackInformation />;

        const qtyCell = <Text textAlign={TextAlign.end}>{shipmentItem.totalUnits}</Text>;

        return [
          createCellObject(productSKUCell, "left", `replenishment-shipment-details-contents-product-${dsku}`),
          createCellObject(qtyCell, "right", `replenishment-shipment-details-contents-units-${dsku}`),
        ];
      }) ?? []
    );
  }, [products, shipment]);

  return <CardTable headerCells={headers} rows={tableRows} />;
};
