import * as React from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import styled from "@emotion/styled";
import { DefaultTheme, Input } from "common/components/ui";

/**
 * IMPORTANT! To access the formatted or float value, pass onValueChange instead of onChange.
 * If using onChange, you may need to format the target value, i.e. Number(e.target.value).
 * https://www.npmjs.com/package/react-number-format
 *
 * When using in React Hook Form, the field props will provide an onChange, but not a value, making it uncontrolled.
 * You need to use a controller (Controller or useController) and pass getInputRef and the field value.
 */

type InputSize = "small" | "medium" | "large";

const SIZE_MAP = {
  small: "50px",
  medium: "70px",
  large: "90px",
};

const StyledInput = styled(Input)<{ inputSize: InputSize }, DefaultTheme>(
  ({ theme, inputSize }) => `
    width: ${SIZE_MAP[inputSize]};
    padding-right: ${theme.spacing.S3};
    -moz-appearance: textfield;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    padding: 0 ${theme.spacing.S3};
  `
);

export interface NumberInputProps extends NumberFormatProps {
  isUncontrolled?: boolean;
  shouldShowInitialZero?: boolean;
  inputSize?: InputSize;
}

/**
 * The value prop defaults to "" to protect against accidentally uncontrolled inputs.
 * Use isUncontrolled to intentionally pass undefined. Pass getInputRef if controlled via a ref.
 */

export const getNumberInputDisplayValue = (
  shouldShowInitialZero: NumberInputProps["shouldShowInitialZero"],
  value: NumberInputProps["value"],
  hasInput: boolean
) => {
  const shouldHideValue = !shouldShowInitialZero && value === 0 && !hasInput;
  return shouldHideValue ? "" : value;
};

export const NumberInput: React.FC<NumberInputProps> = ({
  allowNegative = false,
  name,
  value = "",
  onChange,
  isUncontrolled,
  shouldShowInitialZero,
  inputSize = "small",
  ...props
}) => {
  /**
   * because we have many cases where we have a default or fallback value of zero but want to show a
   * placeholder value initially, we hide the zero value until the user provides an input.
   */
  const hasInputRef = React.useRef(false);

  const displayValue = getNumberInputDisplayValue(shouldShowInitialZero, value, hasInputRef.current);

  const handleChange = (e) => {
    hasInputRef.current = true;
    onChange?.(e);
  };

  return (
    <NumberFormat
      hideIcon
      name={name}
      value={isUncontrolled ? undefined : displayValue}
      customInput={StyledInput}
      allowNegative={allowNegative}
      onChange={handleChange}
      inputSize={inputSize}
      data-dd-privacy="allow"
      {...props}
    />
  );
};
