import { defineMessages } from "react-intl";

export const createShipmentLabels = {
  steps: {
    originSearch: defineMessages({
      title: {
        id: "inbounds.createShipment.steps.originSearch.title",
        defaultMessage: "Where are you shipping your inventory from?",
      },
      subtitle: {
        id: "inbounds.createShipment.steps.originSearch.subtitle",
        defaultMessage:
          "Provide the location where you are responsible for supplier pick-up to ensure accurate pricing.",
      },
    }),
    ownTransportationAddress: defineMessages({
      title: {
        id: "createShipment.steps.ownTransportationAddress.title",
        defaultMessage: "Select origin",
      },
    }),
    supplierSelect: defineMessages({
      title: {
        id: "inbounds.createShipment.steps.supplierSelect.title",
        defaultMessage: "Tell us about your supplier",
      },
      subtitle: {
        id: "inbounds.createShipment.steps.supplierSelect.subtitle",
        defaultMessage:
          "We will be in direct communication with your supplier to ensure your shipment is handled properly. ",
      },
    }),
    destinationSelect: defineMessages({
      title: {
        id: "inbounds.createShipment.steps.destinationSelect.title",
        defaultMessage: "How do you want orders fulfilled?",
      },
      subtitle: {
        id: "inbounds.createShipment.steps.destinationSelect.subtitle",
        defaultMessage: "Select all that apply.",
      },
    }),
    destinationSearch: defineMessages({
      title: {
        id: "inbounds.createShipment.steps.destinationSearch.title",
        defaultMessage: "Where do you want to send your shipment?",
      },
      subtitle: {
        id: "inbounds.createShipment.steps.destinationSearch.subtitle",
        defaultMessage: "Provide the destination.",
      },
    }),
    reserveStorageFcSelect: defineMessages({
      title: {
        id: "inbounds.createShipment.steps.reserveStorageFcSelect.title",
        defaultMessage: "Select a Reserve Storage Location",
      },
    }),
    priceView: defineMessages({
      title: {
        id: "inbounds.createShipment.steps.priceView.title",
        defaultMessage: "Price view",
      },
    }),
    cargoDetails: defineMessages({
      title: {
        id: "inbounds.createShipment.steps.cargoDetails.title",
        defaultMessage: "What are you sending?",
      },
    }),
    // domestic
    domesticFulfillmentSelect: defineMessages({
      title: {
        id: "inbounds.createShipment.steps.domesticFulfillmentSelect.title",
        defaultMessage: "How do you want orders fulfilled?",
      },
      subtitle: {
        id: "inbounds.createShipment.steps.domesticFulfillmentSelect.subtitle",
        defaultMessage: "Select all that apply.",
      },
    }),
    domesticDestinationSelect: defineMessages({
      title: {
        id: "inbounds.createShipment.steps.domesticDestinationSelect.title",
        defaultMessage: "Where do you want to send your shipment?",
      },
      subtitle: {
        id: "inbounds.createShipment.steps.domesticDestinationSelect.subtitle",
        defaultMessage: "Select one.",
      },
    }),
  },
};
