/**
 * validates that the day of the month exists in the month
 * e.g. 30th of february will return false
 */
export const isDayOfMonthValid = (date: string) => {
  const dateObj = new Date(date);
  const [year, month, day] = date.split("-").map((val) => parseInt(val));

  return dateObj.getUTCFullYear() === year && dateObj.getUTCMonth() + 1 === month && dateObj.getUTCDate() === day;
};
