import styled from "@emotion/styled";
import { Dropzone, Notification, Text, ThemeProps } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import {
  UseReplenishmentShippingDetailsLabelsProps,
  useReplenishmentShippingDetailsLabels,
} from "./useReplenishmentShippingDetailsLabels";
import { WholesaleShippingMethod } from "@deliverr/wholesale-client";

const Space = styled.div<ThemeProps>(
  ({ theme }) => `
  padding-bottom: ${theme.spacing.S4};
`
);

export interface ReplenishmentShippingDetailsLabelsProps extends UseReplenishmentShippingDetailsLabelsProps {
  shouldShowBoxLabelHint?: boolean;
  shouldShowCommercialInvoiceHint?: boolean;
}
export const ReplenishmentShippingDetailsLabels: FC<ReplenishmentShippingDetailsLabelsProps> = ({
  shouldShowBoxLabelHint,
  shouldShowCommercialInvoiceHint,
  setIsUploadingLabel,
  isUploadingLabel,
}) => {
  const { formatMessage } = useIntl();
  const {
    isSellerCaseLabel,
    shippingMethod,
    boxLabelFilename,
    palletLabelFilename,
    onBoxFileChange,
    onPalletFileChange,
    onBoxFileClear,
    onPalletFileClear,
    onShippingFileChange,
    onShippingFileClear,
    shippingLabelFilename,
    isCommercialInvoiceRequired,
    onCommercialInvoiceFileChange,
    onCommercialInvoiceFileClear,
    commercialInvoiceFileName,
    isWholesaleOrder,
  } = useReplenishmentShippingDetailsLabels({ setIsUploadingLabel, isUploadingLabel });

  const transfersLabels = transfersShipmentCreateLabels.steps;

  return (
    <>
      <Notification
        appearance="PRIMARY"
        content={{
          title: formatMessage(transfersLabels.shipmentDetails.labelFormat),
        }}
      />
      <Space />
      <Dropzone
        data-testid="box-label-dropzone"
        isMandatory={!isWholesaleOrder}
        id={"transfer-upload-label-case"}
        acceptedFileTypes={["pdf"]}
        onChange={onBoxFileChange}
        onFileClear={onBoxFileClear}
        loading={isUploadingLabel?.CASE_LABELS}
        value={boxLabelFilename ? { fileName: boxLabelFilename } : undefined}
        label={formatMessage(transfersLabels.shipmentDetails.boxFile.label)}
        {...(shouldShowBoxLabelHint && !boxLabelFilename
          ? { errorText: formatMessage(transfersLabels.shipmentDetails.boxFile.requiredHint) }
          : {})}
      />

      <Space />
      {isCommercialInvoiceRequired && (
        <Dropzone
          data-testid="commercial-invoice-dropzone"
          id={"transfer-upload-commercial-invoice-label"}
          isMandatory
          acceptedFileTypes={["pdf"]}
          onChange={onCommercialInvoiceFileChange}
          onFileClear={onCommercialInvoiceFileClear}
          loading={isUploadingLabel?.COMMERCIAL_INVOICE}
          value={commercialInvoiceFileName ? { fileName: commercialInvoiceFileName } : undefined}
          label={formatMessage(transfersLabels.shipmentDetails.commercialInvoice.label)}
          {...(shouldShowCommercialInvoiceHint && !commercialInvoiceFileName
            ? { errorText: formatMessage(transfersLabels.shipmentDetails.commercialInvoice.requiredHint) }
            : {})}
        />
      )}

      <Space />

      {shippingMethod !== WholesaleShippingMethod.COLLECT &&
        (isSellerCaseLabel ? (
          <Dropzone
            id={"transfer-upload-shipping-label"}
            acceptedFileTypes={["pdf"]}
            onChange={onShippingFileChange}
            onFileClear={onShippingFileClear}
            loading={isUploadingLabel?.SHIPPING_LABELS}
            value={shippingLabelFilename ? { fileName: shippingLabelFilename } : undefined}
            label={formatMessage(transfersLabels.shipmentDetails.shippingFile.label)}
          />
        ) : (
          <Dropzone
            id={"transfer-upload-label-pallet"}
            acceptedFileTypes={["pdf"]}
            onChange={onPalletFileChange}
            onFileClear={onPalletFileClear}
            loading={isUploadingLabel?.PALLET_LABELS}
            value={palletLabelFilename ? { fileName: palletLabelFilename } : undefined}
            label={formatMessage(transfersLabels.shipmentDetails.palletFile.label)}
          />
        ))}
      {!isWholesaleOrder && (
        <>
          <Space />
          <Text size="caption">
            <FormattedMessage {...transfersLabels.shipmentDetails.amazonAddThisLater} />
          </Text>
        </>
      )}
    </>
  );
};
