export enum DimensionsError {
  INCOMPLETE = "INCOMPLETE",
  SIOC = "SIOC", // Shipment in Own Container: items larger than this must be sent in ready to ship
  OVER_DIMENSIONS = "OVER_DIMENSIONS",
  OVER_WEIGHT = "OVER_WEIGHT",
  OVER_DIMENSIONS_AND_WEIGHT = "OVER_DIMENSIONS_AND_WEIGHT",
  STORAGE_OVER_DIMENSIONS = "STORAGE_OVER_DIMENSIONS",
  STORAGE_OVER_WEIGHT = "STORAGE_OVER_WEIGHT",
  STORAGE_OVER_DIMENSIONS_AND_WEIGHT = "STORAGE_OVER_DIMENSIONS_AND_WEIGHT",
  INVALID_DIMENSIONS_AND_WEIGHT = "INVALID_DIMENSIONS_AND_WEIGHT",
}
