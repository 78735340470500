import { getSellerId } from "common/user/UserSelectors";
import { Path } from "paths";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useEffectOnce } from "react-use";
import { syncAdSets } from "tags/facebook/common/actions/syncAdSets";
import { FacebookState } from "tags/facebook/connect/state/FacebookReducer";
import { FacebookEditFlowState } from "tags/facebook/edit/state/FacebookEditFlowState";
import { FastTagPageComponentProps } from "tags/model/FastTagPage";
import { adSetSyncStatus as getAdSetSyncStatus } from "../state/adSetsSyncStatus";
import { adSets as getAdSets } from "../state/adSets";
import { groupBy } from "lodash/fp";
import { useState } from "react";

export const useAdSetsSync = ({ state }: FastTagPageComponentProps<FacebookState | FacebookEditFlowState>) => {
  const sellerId = useSelector(getSellerId);
  const adSetSyncStatus = getAdSetSyncStatus(state);
  const adSets = getAdSets(state);
  const dispatch = useDispatch();
  const history = useHistory();
  const groupedAdSets = groupBy("dsku", adSets);
  const [accordianOpen, setAccordianOpen] = useState<Record<string, boolean>>({});

  const isOpen = (dsku: string) => accordianOpen[dsku] ?? false;

  const toggleOpen = (dsku: string) => () => {
    setAccordianOpen({
      ...accordianOpen,
      [dsku]: !isOpen(dsku),
    });
  };

  useEffectOnce(() => {
    dispatch(syncAdSets(sellerId));
  });

  const onDoneClick = () => {
    history.push(Path.fastTagConnect);
  };

  return {
    adSets,
    adSetSyncStatus,
    groupedAdSets,
    onDoneClick,
    isOpen,
    toggleOpen,
  };
};
