import "common/utils/polyfills";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import React, { Fragment } from "react";
import { render } from "react-dom";
import { IntlProvider } from "react-intl-redux";
import { Provider as ReduxProvider } from "react-redux";
import { loadElevioIfNotLoaded } from "common/utils/thirdParty/Elevio";
import { loadChameleon } from "common/utils/thirdParty/Chameleon";

// Note this must come before the App import
// TODO import this from App.less, but need to prevent CSS module rewriting
/* eslint:disable:no-import-side-effect */
import "assets/css/light-bootstrap-dashboard.css";

// This import is apparently essential in having imports resolved in correct order.
// Reader is urged to try removing this import to see if that is still the case.
import "common/Split";

import { enforceSSO, signOut } from "common/utils/SSO";
import { Path } from "paths";
import addTokenCheck from "./app/common/user/TokenCheck";
import { addIcons } from "./app/FontAwesomeLibrary";
import store from "./app/store";
import { DefaultThemeProvider } from "common/components/DefaultThemeProvider";
import { initDataDog } from "common/utils/thirdParty/DataDog/DataDog";
import { ErrorBoundary } from "common/components/ErrorBoundary/ErrorBoundary";

/* eslint-disable-next-line @typescript-eslint/no-floating-promises */
(async () => {
  // send old login routes to login.deliverr
  const { pathname } = window.location;
  if (
    (pathname.startsWith(Path.login) && pathname !== Path.loginRedirect) ||
    pathname.startsWith(Path.onboardingSignup)
  ) {
    await signOut();
  }

  addIcons();
  addTokenCheck();

  const renderApp = (Component) =>
    // eslint-disable-next-line react/no-render-return-value
    render(
      <ErrorBoundary name="RootErrorBoundary" errorDisplay={<Fragment />}>
        <ReduxProvider store={store}>
          <IntlProvider>
            <DefaultThemeProvider>
              <MuiThemeProvider>
                <Component />
              </MuiThemeProvider>
            </DefaultThemeProvider>
          </IntlProvider>
        </ReduxProvider>
      </ErrorBoundary>,
      document.getElementById("content")
    );

  initDataDog();

  // To investigate:
  // Loading Elevio any later than this causes ElevioLauncherMargin to not take effect
  loadElevioIfNotLoaded();

  loadChameleon();

  await enforceSSO();

  const { App } = await import("./app/App");

  renderApp(App);

  if (module.hot) {
    module.hot.accept("./app/App", () => renderApp(App));
  }
})();
