import { transfersShipmentCreateLabels } from "../transfersShipmentCreate.labels";
import React from "react";
import { ManagedStep } from "common/components/StepContainer";
import { Box, RadioTileGroup, Stack } from "common/components/ui";
import { TextAlign } from "common/components/ui/shared";
import { useTransferPickDestinationStep } from "./useTransferPickDestinationStep";
import { ManualFbaMessage } from "./ManualFbaMessage";

export const TransferPickDestinationStep = () => {
  const {
    isPendingNext,
    setPendingNext,
    isNextDisabled,
    isLoading,
    transferPickDestinationOptions,
    onChangeDestination,
    onManualTransferClick,
    formatMessage,
    selectedDestination,
  } = useTransferPickDestinationStep();

  return (
    <ManagedStep
      data-testid="create-transfer-destination-step"
      title={formatMessage(transfersShipmentCreateLabels.steps.destination.title)}
      isNextDisabled={isNextDisabled}
      nextData={() => {
        setPendingNext(true);
        return selectedDestination;
      }}
      isLoading={isLoading || isPendingNext}
    >
      <Box width="80%" paddingRight="S8" paddingLeft="S8">
        <Stack center direction="VERTICAL">
          <RadioTileGroup
            direction="VERTICAL"
            currentValue={selectedDestination}
            onChange={onChangeDestination}
            options={transferPickDestinationOptions}
          />

          <Box width="55%">
            <ManualFbaMessage onManualClick={onManualTransferClick} align={TextAlign.start} />
          </Box>
        </Stack>
      </Box>
    </ManagedStep>
  );
};
