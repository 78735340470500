import React, { FC } from "react";
import { OnboardingStepLayoutBase } from "./OnboardingStepLayoutBase";
import { OnboardingFooterGrid, OnboardingFooterGridProps } from "./OnboardingFooterGrid";

interface OnboardingStepLayoutProps extends OnboardingFooterGridProps {
  body: JSX.Element;
}

export const OnboardingStepLayout: FC<OnboardingStepLayoutProps> = ({
  body,
  footerLeft,
  footerRight,
  footerCenter,
}) => {
  const hasFooter = footerLeft ?? footerRight ?? footerCenter;

  const footerContent = hasFooter ? (
    <OnboardingFooterGrid footerLeft={footerLeft} footerRight={footerRight} footerCenter={footerCenter} />
  ) : undefined;

  return <OnboardingStepLayoutBase body={body} footerContent={footerContent} />;
};
