import { OrderCostsResponse, ShipmentEstimate } from "@deliverr/replenishment-client";
import { logError } from "Logger";
import { chain, sumBy } from "lodash";
import { replenishmentClient } from "Clients";
import { SPThunkAction } from "common/ReduxUtils";
import { ChargeRow } from "transfers/create/actions/replenishmentOrder/shipping/ChargeRow";
import { feeDescriptionMessages } from "transfers/create/actions/replenishmentOrder/shipping/feeDescriptionMessages";
import { replenishmentOrderDetailsSetCosts } from "./ReplenishmentOrderDetailsActions";

const convertToChargeRow = (charges: ShipmentEstimate[]): ChargeRow[] => {
  return chain(charges)
    .flatMap((quotes) => quotes.chargeBreakdown)
    .filter((quotes) => !quotes.isExcluded)
    .groupBy((breakdown) => breakdown.chargeType)
    .map((val, key) => {
      const totalCost = sumBy(val, (chargesRecord) => chargesRecord.totalCost);
      const row: ChargeRow = {
        feeDescription: feeDescriptionMessages[key],
        chargeType: val[0].chargeType,
        unitCost: val[0].unitCost,
        unitOfMeasure: val[0].unitOfMeasure,
        unitCount: sumBy(val, (chargesRecord) => chargesRecord.unitCount),
        totalCost,
      };

      return row ?? [];
    })
    .value();
};

export const fetchAndSetOrderCosts =
  (orderId: string): SPThunkAction<Promise<void>> =>
  async (dispatch) => {
    try {
      const { costActuals, costEstimates }: OrderCostsResponse = (await replenishmentClient.getOrderCosts(orderId))
        .value;

      const actualCharges: ChargeRow[] = convertToChargeRow(costActuals);
      const estimatedCharges: ChargeRow[] = convertToChargeRow(costEstimates);

      dispatch(replenishmentOrderDetailsSetCosts({ actualCharges, estimatedCharges }));
    } catch (err) {
      logError({ fn: "fetchAndSetOrderCosts" }, orderId, err);
    }
  };
