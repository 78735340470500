import { FreightBookingSetupStepType } from "freight/steps/setup/FreightBookingSetupTypes";

export const freightBookingAccordionStepsInitialState: Record<FreightBookingSetupStepType, boolean> = {
  [FreightBookingSetupStepType.PICKUP_DETAILS]: false,
  [FreightBookingSetupStepType.DELIVERY_DETAILS]: false,
  [FreightBookingSetupStepType.DRAYAGE_DETAILS]: false,
  [FreightBookingSetupStepType.DRAYAGE_CARGO_DETAILS]: false,
  [FreightBookingSetupStepType.FREIGHT_INSTRUCTIONS]: false,
  [FreightBookingSetupStepType.CARGO_PALLET_DETAILS]: false,
  [FreightBookingSetupStepType.CONFIRM_SHIPMENT]: false,
  [FreightBookingSetupStepType.FBA_DELIVERY_DETAILS]: false,
};
