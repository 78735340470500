import React, { FC } from "react";
import { DefaultTheme, Icon, IconsProps, Stack, Text } from "common/components/ui";
import { Direction, ThemeProps } from "common/components/ui/shared";
import styled from "@emotion/styled";
import { InboundFreightPalletSetup } from "@deliverr/legacy-inbound-client";
import {
  LTL_MAX_PALLET_LENGTH,
  LTL_MAX_PALLET_HEIGHT,
  LTL_MAXIMUM_PALLETS,
} from "@deliverr/commons-clients/lib/transportation/legacy-copy-pasta";
import { FormattedMessage } from "react-intl";

import { LtlInputNames } from "../../../../inbounds/steps/ship/ltl/util/inputNames";
import { EditableNumberField } from "common/components/EditableNumberField";

const Cell = styled.td<Record<string, unknown>, DefaultTheme>(
  ({ theme }) =>
    `
    display: inline-block;
    min-width: 120px;
    padding: ${theme.spacing.S4} ${theme.spacing.S2};

    label {
      height: 40px;
      margin: 0;
    }

    &:first-of-type {
      width: 270px;
      padding-left: 0;
    }
  `
);

const DimensionsText = styled(Text)<{}, DefaultTheme>`
  margin-right: ${({ theme }) => `${theme.spacing.S2}`};
`;

const RemoveRowIcon = styled(Icon)<IconsProps, DefaultTheme>`
  color: ${({ theme }) => `${theme.colors.NEUTRAL[200]}`};
  cursor: pointer;
`;

const CenteredStack = styled(Stack)`
  align-items: center;
`;

const StyledEditableField = styled(EditableNumberField)<ThemeProps>(
  ({ theme }) => `
    &[disabled] {
      background-color: ${theme.colors.NEUTRAL[40]};
      color: ${theme.colors.NEUTRAL[300]};
    }
  `
);

interface FreightPalletRowProps {
  hasChangesLocked?: boolean;
  hasPalletsLocked?: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>, maxDigits?: number) => void;
  index: number;
  hideRemoveButton?: boolean;
  pallet: InboundFreightPalletSetup;
  removeRow: (index: number) => any;
}

export const FreightPalletRow: FC<FreightPalletRowProps> = ({
  hasChangesLocked,
  hasPalletsLocked,
  handleInputChange,
  hideRemoveButton,
  index,
  pallet,
  removeRow,
}) => {
  const { width, length, height, identicalPallets, weight } = pallet;

  return (
    <tr data-testid="palletConfig">
      <Cell>
        <CenteredStack direction={Direction.HORIZONTAL} gap="S2">
          <StyledEditableField
            decimalScale={0}
            disabled={hasChangesLocked}
            max={LTL_MAX_PALLET_LENGTH}
            name={LtlInputNames.PALLET_WIDTH}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
            value={width || ""}
          />
          <Text>
            <FormattedMessage id="inbounds.ltl.byDimension" defaultMessage="x" />
          </Text>
          <StyledEditableField
            decimalScale={0}
            disabled={hasChangesLocked}
            max={LTL_MAX_PALLET_LENGTH}
            name={LtlInputNames.PALLET_LENGTH}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
            value={length || ""}
          />
          <Text>
            <FormattedMessage id="inbounds.ltl.byDimension" defaultMessage="x" />
          </Text>
          <StyledEditableField
            decimalScale={0}
            disabled={hasChangesLocked}
            max={LTL_MAX_PALLET_HEIGHT}
            name={LtlInputNames.PALLET_HEIGHT}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
            value={height || ""}
          />
        </CenteredStack>
      </Cell>
      <Cell>
        <StyledEditableField
          decimalScale={0}
          disabled={hasChangesLocked}
          name={LtlInputNames.PALLET_WEIGHT}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
          value={weight || ""}
        />
      </Cell>
      <Cell>
        <StyledEditableField
          decimalScale={0}
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          disabled={hasChangesLocked || hasPalletsLocked}
          max={LTL_MAXIMUM_PALLETS}
          name={LtlInputNames.IDENTICAL_PALLETS}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
          value={identicalPallets || ""}
        />
      </Cell>
      <Cell>
        <DimensionsText as="span">{weight * identicalPallets}</DimensionsText>
      </Cell>
      {!hasChangesLocked && !hideRemoveButton && (
        <Cell>
          <RemoveRowIcon type="times-circle" size="lg" onClick={() => removeRow(index)} />
        </Cell>
      )}
    </tr>
  );
};
