import { InboundPrepRequestData } from "@deliverr/legacy-inbound-client";
import { createActionCreator } from "common/ReduxUtils";
import { PrepActionTypes } from "prep/store/PrepActionTypes";

/**
 * @param prepRequest the current InboundPrepRequest
 */
export const setPrepRequest = createActionCreator<InboundPrepRequestData | undefined>(
  PrepActionTypes.SET_PREP_REQUEST,
  "prepRequest"
);
