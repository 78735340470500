import { isDebuggable } from "common/Config";
import { applyMiddleware, createStore, Middleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { initialState, rootReducer, RootState } from "RootReducer";

const middleware: Middleware<{}, RootState>[] = [thunk];

if (process.env.ENV === "development") {
  middleware.push(
    createLogger({
      collapsed: true,
      diff: true,
      timestamp: false,
    })
  );
}

const middlewareCallstack = applyMiddleware(...middleware);
const middlewareSetup = isDebuggable ? composeWithDevTools(middlewareCallstack) : middlewareCallstack;

export const makeStore = (initState: RootState) => createStore(rootReducer, initState, middlewareSetup);
const store = makeStore(initialState);

export default store;
