import { Step } from "common/components/StepContainer";
import { RootState } from "RootReducer";
import { TransferPickProductStep } from "./steps/fba";
import { TransferPickStorageWarehouseStep } from "./steps/fba/TransferPickStorageWarehouseStep";
import { TransferSelectProductQuantityStep } from "./steps/fba/TransferSelectProductQuantityStep";
import { transferCreateChooseOriginWarehouse, transferCreateChooseShippingConfig } from "./actions";
import { selectTransferCreate } from "./store/TransferCreateSelectors";
import { fetchStorageProductsCaseInfo } from "./store/fetchStorageProductsCaseInfo";
import { SharedConfirmedStep } from "common/wholesale-common/components/SharedConfirmedStep";
import { transfersShipmentCreateLabels } from "./transfersShipmentCreate.labels";
import { createOrUpdateReplenishmentOrder } from "./actions/replenishmentOrder/createOrUpdateReplenishmentOrder";
import { submitReplenishmentOrder } from "./actions/replenishmentOrder/submitReplenishmentOrder";
import { shippingPartnerToWholesaleShippingMethod } from "./steps/common/replenishment/useReplenishmentOrderShippingStep";
import { OrderStatus, ShippingPartner } from "@deliverr/replenishment-client";
import { WholesaleShipmentType } from "@deliverr/wholesale-client";
import { ReplenishmentOrderConfirmation } from "./steps/common/replenishment/confirm/ReplenishmentOrderConfirmation";
import { FeatureName, getFeatureSelector } from "common/Split";
import { TransferSelectPrep } from "prep/create/TransferSelectPrep";
import { resetSearchedProductDetails } from "common/components/ProductChooser/ProductChooserActions";

export enum EcomV2Step {
  SelectProduct = "ecom/v2/selectProduct",
  SelectStorageWarehouse = "ecom/v2/selectStorageWarehouse",
  SelectProductQuantity = "ecom/v2/selectProductQuantity",
  SelectPrep = "ecom/v2/selectPrep",
  Confirm = "ecom/v2/confirm",
  Confirmed = "ecom/v2/confirmed",
}
export const ecomV2Steps: (dispatch, getState: () => RootState) => Record<EcomV2Step, Step> = (dispatch, getState) => ({
  [EcomV2Step.SelectProduct]: {
    component: TransferPickProductStep,
    next: async () => {
      const { availableInventory, selectedProducts } = selectTransferCreate(getState());
      await dispatch(fetchStorageProductsCaseInfo(Object.keys(selectedProducts)));

      let nextStep = EcomV2Step.SelectStorageWarehouse;
      if (availableInventory.length === 1) {
        dispatch(transferCreateChooseOriginWarehouse(availableInventory[0]));
        nextStep = EcomV2Step.SelectProductQuantity;
      }

      if (await createOrUpdateReplenishmentOrder(dispatch, getState, false)) {
        return nextStep;
      } else {
        return EcomV2Step.SelectProduct;
      }
    },
  },
  [EcomV2Step.SelectStorageWarehouse]: {
    component: TransferPickStorageWarehouseStep,
    next: async () => {
      if (await createOrUpdateReplenishmentOrder(dispatch, getState, false)) {
        return EcomV2Step.SelectProductQuantity;
      } else {
        return EcomV2Step.SelectStorageWarehouse;
      }
    },
  },
  [EcomV2Step.SelectProductQuantity]: {
    component: TransferSelectProductQuantityStep,
    next: async () => {
      const state = getState();
      const isEcomPrepOn = getFeatureSelector(FeatureName.EcomPrep)(state);
      // hardcoding shippingPartner as Deliverr and transport as LTL for Ecom
      dispatch(
        transferCreateChooseShippingConfig({
          shippingMethod: shippingPartnerToWholesaleShippingMethod[ShippingPartner.DELIVERR],
          shipmentType: WholesaleShipmentType.PARTIAL_PALLET,
        })
      );

      if (await createOrUpdateReplenishmentOrder(dispatch, getState, false)) {
        if (isEcomPrepOn) {
          return EcomV2Step.SelectPrep;
        }
        return EcomV2Step.Confirm;
      } else {
        return EcomV2Step.SelectProductQuantity;
      }
    },
  },
  [EcomV2Step.SelectPrep]: {
    component: TransferSelectPrep,
    next: async () => {
      if (await createOrUpdateReplenishmentOrder(dispatch, getState, false)) {
        return EcomV2Step.Confirm;
      } else {
        return EcomV2Step.SelectPrep;
      }
    },
  },
  [EcomV2Step.Confirm]: {
    component: ReplenishmentOrderConfirmation,
    next: async () => {
      const { replenishmentOrder } = selectTransferCreate(getState());
      // If create succeeds and submit fails order will behave like draft
      // but it will open directly on confirm step

      let isSubmitted = false;

      if (replenishmentOrder?.orderStatus === OrderStatus.DRAFT) {
        isSubmitted =
          (await createOrUpdateReplenishmentOrder(dispatch, getState, true)) &&
          (await submitReplenishmentOrder(dispatch, getState));
      } else if (replenishmentOrder?.orderStatus === OrderStatus.CREATED) {
        isSubmitted = await submitReplenishmentOrder(dispatch, getState);
      }
      if (isSubmitted) {
        dispatch(resetSearchedProductDetails());
        return EcomV2Step.Confirmed;
      }
      return;
    },
  },
  [EcomV2Step.Confirmed]: {
    component: SharedConfirmedStep(transfersShipmentCreateLabels),
    hasNoBack: true,
  },
});
