// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/** Flexport colors */\n/** Design system colors as of 8/27/21 **/\n.ConfirmUnsyncModal__modalContainer--CMqR6 {\n  padding: 40px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  gap: 20px;\n}\n.ConfirmUnsyncModal__buttons--Ek78n {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  gap: 20px;\n}\n.ConfirmUnsyncModal__body--krLhm {\n  font-size: 16px;\n  margin: 0;\n}\n.ConfirmUnsyncModal__confirmUnsyncIcon--Hb55L {\n  color: #e3a81e;\n  margin-right: 8px;\n}\n.ConfirmUnsyncModal__title--KsYH1 {\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/app/tags/facebook/edit/pages/UnsyncAdSets/ConfirmUnsyncModal.less"],"names":[],"mappings":"AAAA,qBAAqB;AACrB,yCAAyC;AACzC;EACE,aAAA;EACA,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,4BAAA;EAAA,6BAAA;MAAA,0BAAA;UAAA,sBAAA;EACA,SAAA;AACF;AAEA;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,qBAAA;MAAA,kBAAA;UAAA,yBAAA;EACA,SAAA;AAAF;AAGA;EACE,eAAA;EACA,SAAA;AADF;AAIA;EACE,cAAA;EACA,iBAAA;AAFF;AAKA;EACE,SAAA;AAHF","sourcesContent":["@import \"~app/common/styles/colors\";\n\n.modalContainer {\n  padding: 40px;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.buttons {\n  display: flex;\n  justify-content: flex-end;\n  gap: 20px;\n}\n\n.body {\n  font-size: 16px;\n  margin: 0;\n}\n\n.confirmUnsyncIcon {\n  color: @darkYellow;\n  margin-right: 8px;\n}\n\n.title {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": "ConfirmUnsyncModal__modalContainer--CMqR6",
	"buttons": "ConfirmUnsyncModal__buttons--Ek78n",
	"body": "ConfirmUnsyncModal__body--krLhm",
	"confirmUnsyncIcon": "ConfirmUnsyncModal__confirmUnsyncIcon--Hb55L",
	"title": "ConfirmUnsyncModal__title--KsYH1"
};
export default ___CSS_LOADER_EXPORT___;
