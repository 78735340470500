import { OrderListActionTypes } from "../OrderListActionTypes";
import history from "BrowserHistory";
import { Path } from "paths";

export const setCurrentPage = (page: number) => async (dispatch) => {
  dispatch({
    type: OrderListActionTypes.SET_CURRENT_PAGE,
    page,
  });
  if (page === 0) {
    history.push(`${Path.orderList}`);
  }
};

export const setCurrentPageRoute = (page: number) => history.push(`${Path.orderList}?page=${page + 1}`);
