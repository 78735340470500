import { useEffect, useState } from "react";
import { useUnmount } from "react-use";

/**
 * This hook starts (continues) an active polling mechanism, at
 * a given interval, until the specified condition is fulfilled or
 * the maximum number of retries has been reached.
 *
 * The main use case is when a screen must display a spinner while
 * waiting for some changing remote data (obtained by GET)
 * to reach a given value.
 *
 * Typically, `retryAction()` which is the code you provide to perform
 * the data fetching, will invoke a dispatch action.
 * At the end of each interval, the internal number of retries is updated,
 * which triggers an update of the hook's host (hence, if needed, the
 * beginning of the next timeout).
 *
 * The host component can safely re-render apart from the end of an interval,
 * because the hook keeps track of the currently running timer, thus avoiding
 * to trigger a new one in parallel.
 *
 * @param pollingDelay Polling interval, in milliseconds
 * @param shouldContinue Boolean value controlling the end of polling
 * @param retryAction An arbitrary function to be triggered at each interval.
 *   This code is what does actually the polling/fetching.
 * @param maxRetries
 * @param startPolling optional flag to support starting polling conditionally
 */
export const usePolling = (
  pollingDelay: number,
  shouldContinue: boolean,
  retryAction: () => void,
  maxRetries = -1,
  startPolling = true
) => {
  const [timerId, setTimerId] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [retries, setRetries] = useState(0);
  const [isFinished, setFinished] = useState(false);

  useEffect(() => {
    if (startPolling && shouldContinue && !isFinished) {
      if (!timerId && (maxRetries < 0 || retries < maxRetries - 1)) {
        setTimerId(
          setTimeout(() => {
            retryAction();
            setTimerId(null);
            setRetries((x) => x + 1);
          }, pollingDelay)
        );
      }
    } else if (timerId) {
      clearTimeout(timerId);
      setFinished(true);
      setTimerId(null);
    }
  }, [startPolling, shouldContinue, isFinished, retryAction, pollingDelay, timerId, retries, maxRetries]);

  useUnmount(() => {
    if (timerId) {
      clearTimeout(timerId);
    }
  });
  const resetStates = () => {
    setFinished(false);
    setRetries(0);
  };

  return { retries, resetStates };
};
