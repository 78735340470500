import { defineMessages, MessageDescriptor } from "react-intl";

import { RelabeledBoxContentsColumn } from "./useRelabeledBoxContentsTable";
import { EstimatedTotalChargeColumn } from "common/components/ProblemBlade/components/EstimatedTotalChargeTable/types";

export const RELABELED_BOX_ESTIMATED_TOTAL_CHARGE_TABLE_LABELS: Record<
  keyof EstimatedTotalChargeColumn,
  MessageDescriptor
> = defineMessages({
  units: {
    id: "inbounds.shipmentProblems.relabeledBox.estimateTotalChargeTable.units",
    defaultMessage: "Boxes",
  },
  perUnitCharge: {
    id: "inbounds.shipmentProblems.relabeledBox.estimateTotalChargeTable.charge",
    defaultMessage: "Charge per box",
  },
  totalCharge: {
    id: "inbounds.shipmentProblems.relabeledBox.estimateTotalChargeTable.totalCharge",
    defaultMessage: "Subtotal",
  },
  totalReimbursement: {
    id: "inbounds.shipmentProblems.relabeledBox.estimateTotalTable.totalReimbursement",
    defaultMessage: "Total reimbursement",
  },
});

export const RELABELED_BOX_DSKUS_TABLE_LABELS: Record<
  keyof RelabeledBoxContentsColumn,
  MessageDescriptor
> = defineMessages({
  dskuDetail: {
    id: "sku",
    defaultMessage: "SKU",
  },
  qty: {
    id: "inbounds.shipmentProblems.relabeledBoxBlade.dskusTable.expectedQty",
    defaultMessage: "Units",
  },
});
