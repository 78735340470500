import styled from "@emotion/styled";
import HtmlToReact from "common/components/HtmlToReact";
import { LocalizedText } from "common/components/LocalizedText";
import { Box, Button, Grid, MODAL_BODY_PADDING, Modal, Title } from "common/components/ui";
import { TextAlign, ThemeProps } from "common/components/ui/shared";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Divider } from "../CargoDetailsStep";

export const ModalFooterContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  justify-content: end;
  margin: 0 -${MODAL_BODY_PADDING} -${MODAL_BODY_PADDING} -${MODAL_BODY_PADDING};
  padding: 16px ${MODAL_BODY_PADDING};
  border-top: 1px solid ${theme.colors.NEUTRAL[60]};
`
);

const ImgContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  padding: 0;

  svg {
    max-height: 100%;
    max-width: 100%;
  }
`
);

export const SkuSelectorLearnMoreModal = ({ isModalOpen, setIsModalOpen }) => (
  <>
    <Modal hasCloseButton={false} show={isModalOpen}>
      <Title as="h3" displayAs="h4" textAlign={TextAlign.start}>
        <FormattedMessage
          id="createShipment.cargoDetail.dtc.skuSelector.learnMoreModal.title"
          defaultMessage="What’s Single SKU vs. Mixed SKU packing?"
        />
      </Title>
      <Grid columns="3fr 1fr">
        <Box>
          <Box paddingTop="S2" paddingBottom="S2">
            <LocalizedText
              textAlign={TextAlign.start}
              as="p"
              size="bodyLarge"
              bold
              message={{
                id: "createShipment.cargoDetail.dtc.skuSelector.learnMoreModal.singleSku.title",
                defaultMessage: "Single SKU packing",
              }}
            />
          </Box>
          <LocalizedText
            textAlign={TextAlign.start}
            as="p"
            size="body"
            appearance="INFO"
            message={{
              id: "createShipment.cargoDetail.dtc.skuSelector.learnMoreModal.singleSku.description",
              defaultMessage:
                "Each box must contain units of the same SKU. This packing option allows for faster and more accurate receiving.",
            }}
          />
        </Box>
        <ImgContainer>
          <HtmlToReact html={require("./assets/singleSku.svg")} />
        </ImgContainer>
      </Grid>
      <Divider />
      <Grid columns="3fr 1fr">
        <Box>
          <Box paddingTop="S2" paddingBottom="S2">
            <LocalizedText
              textAlign={TextAlign.start}
              as="p"
              size="bodyLarge"
              bold
              message={{
                id: "createShipment.cargoDetail.dtc.skuSelector.learnMoreModal.multiSku.title",
                defaultMessage: "Mixed SKU packing ",
              }}
            />
          </Box>
          <LocalizedText
            textAlign={TextAlign.start}
            as="p"
            size="body"
            appearance="INFO"
            message={{
              id: "createShipment.cargoDetail.dtc.skuSelector.learnMoreModal.multiSku.description",
              defaultMessage:
                "Each box can contain units of different SKUs. This packing option allows for more flexibility, but may take longer to count and receive.",
            }}
          />
        </Box>
        <ImgContainer>
          <HtmlToReact html={require("./assets/mixedSku.svg")} />
        </ImgContainer>
      </Grid>
      <Box marginTop="S5">
        <ModalFooterContainer>
          <Button onClick={() => setIsModalOpen(false)}>
            <FormattedMessage id="supplierSelect.SupplierInputModal.save" defaultMessage="Got it" />
          </Button>
        </ModalFooterContainer>
      </Box>
    </Modal>
  </>
);
