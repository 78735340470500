// 16 24 sizing

import {
  IconDefinition as IconDefinitionFreeSolid,
  IconName,
  faArchive,
  faArrowLeft,
  faArrowCircleLeft,
  faArrowRight,
  faArrowCircleRight,
  faBarcode,
  faBars,
  faBell,
  faBox,
  faBoxOpen,
  faCamera,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faClone,
  faCodeBranch,
  faCubes,
  faDownload,
  faEye,
  faEyeSlash,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileInvoice,
  faFlag,
  faGift,
  faInfo,
  faInfoCircle,
  faLink,
  faLock,
  faMinus,
  faMinusSquare,
  faPallet,
  faPencilAlt,
  faPlus,
  faPlusCircle,
  faPrint,
  faRocket,
  faQrcode,
  faQuestionCircle,
  faRedoAlt,
  faSyncAlt,
  faTimes,
  faTimesCircle,
  faTruck,
  faUndo,
  faUnlink,
  faUserCircle,
  faUserLock,
  faWrench,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import {
  IconDefinition as IconDefinitionProRegular,
  faDollyFlatbedAlt,
  faBarcodeRead,
  faBarcodeScan,
  faPalletAlt,
  faScanner,
  faCircle as faSpinnerCircle,
  faChartPie,
  faSpinnerThird,
  faArrowToTop,
  faFileAlt,
  faSearch,
  faSignOut,
  faListAlt,
  faEllipsisH,
  faBook,
  faPollH,
  faQuestionCircle as faQuestionCircleAlt,
  faUmbrellaBeach,
  faFileContract,
  faRadiation,
  faExclamationTriangle as faExclamationTriangleAlt,
  faClock,
  faTag,
  faInfoCircle as faInfoCircleAlt,
} from "@deliverr/pro-regular-svg-icons";
import {
  IconDefinition as IconDefinitionProSolid,
  faClipboardListCheck as faClipboardListCheckSd,
  faPen,
  faSack,
  faHome,
} from "@deliverr/pro-solid-svg-icons";
import {
  IconDefinition as IconDefinitionProLight,
  faBoxOpen as faBoxOpenLt,
  faCog as faCogLt,
  faHandPaper as faHandPaperLt,
  faTimesOctagon as faTimesOctagonLt,
  faTruck as faTruckLt,
  faFileUpload as faFileUploadLt,
  faFile as faFileLt,
  faHourglassHalf as faHourglassHalfLt,
  faSlidersV as faSliderVLt,
  faSquare as faSquareLt,
  faPen as faPenLt,
  faPlusCircle as faPlusCircleLt,
} from "@deliverr/pro-light-svg-icons";
import { IconDefinition as IconDefinitionProDuotone, faUsdCircle, faCube } from "@deliverr/pro-duotone-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import * as React from "react";

type IconDefinition =
  | IconDefinitionFreeSolid
  | IconDefinitionProSolid
  | IconDefinitionProRegular
  | IconDefinitionProLight
  | IconDefinitionProDuotone;

type ProLightIconTypes =
  | "box-open-lt"
  | "cog-lt"
  | "file-lt"
  | "file-upload-lt"
  | "hand-paper-lt"
  | "hourglass-half-lt"
  | "times-octagon-lt"
  | "truck-lt"
  | "slider-v-lt"
  | "square-lt"
  | "pen-lt"
  | "plus-circle-lt";
type ProSolidIconTypes =
  | "clipboard-list-check-sd"
  | "pen"
  | "question-circle-alt"
  | "sack"
  | "spinner-circle"
  | "exclamation-triangle-alt"
  | "info-circle-alt";
type ProDuotoneIconTypes = "dollar-circle";

export type IconTypes =
  | Extract<
      IconName,
      | "archive"
      | "arrow-left"
      | "arrow-circle-left"
      | "arrow-right"
      | "arrow-circle-right"
      | "arrow-to-top"
      | "barcode"
      | "barcode-read"
      | "barcode-scan"
      | "bars"
      | "bell"
      | "book"
      | "box"
      | "box-open"
      | "camera"
      | "caret-down"
      | "caret-up"
      | "chart-pie"
      | "check"
      | "check-circle"
      | "check-square"
      | "chevron-down"
      | "chevron-left"
      | "chevron-right"
      | "chevron-up"
      | "circle"
      | "circle-notch"
      | "clock"
      | "clone"
      | "code-branch"
      | "cube"
      | "cubes"
      | "dolly-flatbed-alt"
      | "download"
      | "ellipsis-h"
      | "exclamation"
      | "exclamation-circle"
      | "exclamation-triangle"
      | "external-link-alt"
      | "eye"
      | "eye-slash"
      | "file-alt"
      | "file-contract"
      | "file-invoice"
      | "file-lt"
      | "file-upload-lt"
      | "flag"
      | "gift"
      | "globe"
      | "home"
      | "info"
      | "info-circle"
      | "link"
      | "list-alt"
      | "lock"
      | "minus"
      | "minus-square"
      | "pallet"
      | "pallet-alt"
      | "pencil-alt"
      | "plus"
      | "plus-circle"
      | "poll-h"
      | "print"
      | "qrcode"
      | "question-circle"
      | "radiation"
      | "redo-alt"
      | "rocket"
      | "scanner"
      | "search"
      | "sign-out"
      | "spinner-third"
      | "square-lt"
      | "sync-alt"
      | "tag"
      | "times"
      | "times-circle"
      | "truck"
      | "umbrella-beach"
      | "undo"
      | "unlink"
      | "user-circle"
      | "user-lock"
      | "wrench"
      | "pen-lt"
    >
  | ProLightIconTypes
  | ProSolidIconTypes
  | ProDuotoneIconTypes;

export const IconMap: Record<IconTypes, IconDefinition> = {
  archive: faArchive,
  "arrow-left": faArrowLeft,
  "arrow-circle-left": faArrowCircleLeft,
  "arrow-right": faArrowRight,
  "arrow-circle-right": faArrowCircleRight,
  "arrow-to-top": faArrowToTop,
  barcode: faBarcode,
  "barcode-read": faBarcodeRead,
  "barcode-scan": faBarcodeScan,
  bars: faBars,
  bell: faBell,
  book: faBook,
  box: faBox,
  "box-open": faBoxOpen,
  "box-open-lt": faBoxOpenLt,
  camera: faCamera,
  "caret-down": faCaretDown,
  "caret-up": faCaretUp,
  "chart-pie": faChartPie,
  check: faCheck,
  "check-circle": faCheckCircle,
  "check-square": faCheckSquare,
  "chevron-down": faChevronDown,
  "chevron-left": faChevronLeft,
  "chevron-right": faChevronRight,
  "chevron-up": faChevronUp,
  circle: faCircle,
  "circle-notch": faCircleNotch,
  "clipboard-list-check-sd": faClipboardListCheckSd,
  clock: faClock,
  clone: faClone,
  "code-branch": faCodeBranch,
  "cog-lt": faCogLt,
  cube: faCube,
  cubes: faCubes,
  "dollar-circle": faUsdCircle,
  "dolly-flatbed-alt": faDollyFlatbedAlt,
  download: faDownload,
  "ellipsis-h": faEllipsisH,
  exclamation: faExclamation,
  "exclamation-circle": faExclamationCircle,
  "exclamation-triangle": faExclamationTriangle,
  "exclamation-triangle-alt": faExclamationTriangleAlt,
  "external-link-alt": faExternalLinkAlt,
  eye: faEye,
  "eye-slash": faEyeSlash,
  "file-alt": faFileAlt,
  "file-contract": faFileContract,
  "file-invoice": faFileInvoice,
  "file-lt": faFileLt,
  "file-upload-lt": faFileUploadLt,
  flag: faFlag,
  gift: faGift,
  globe: faGlobe,
  "hand-paper-lt": faHandPaperLt,
  "hourglass-half-lt": faHourglassHalfLt,
  home: faHome,
  info: faInfo,
  "info-circle": faInfoCircle,
  "info-circle-alt": faInfoCircleAlt,
  link: faLink,
  "list-alt": faListAlt,
  lock: faLock,
  minus: faMinus,
  "minus-square": faMinusSquare,
  plus: faPlus,
  pallet: faPallet,
  "pallet-alt": faPalletAlt,
  pen: faPen,
  "pen-lt": faPenLt,
  "pencil-alt": faPencilAlt,
  "plus-circle": faPlusCircle,
  "plus-circle-lt": faPlusCircleLt,
  "poll-h": faPollH,
  print: faPrint,
  qrcode: faQrcode,
  "question-circle": faQuestionCircle,
  "question-circle-alt": faQuestionCircleAlt,
  radiation: faRadiation,
  "redo-alt": faRedoAlt,
  rocket: faRocket,
  sack: faSack,
  scanner: faScanner,
  search: faSearch,
  "sign-out": faSignOut,
  "slider-v-lt": faSliderVLt,
  "spinner-circle": faSpinnerCircle,
  "spinner-third": faSpinnerThird,
  "square-lt": faSquareLt,
  "sync-alt": faSyncAlt,
  tag: faTag,
  times: faTimes,
  "times-circle": faTimesCircle,
  "times-octagon-lt": faTimesOctagonLt,
  truck: faTruck,
  "truck-lt": faTruckLt,
  "umbrella-beach": faUmbrellaBeach,
  undo: faUndo,
  unlink: faUnlink,
  "user-circle": faUserCircle,
  "user-lock": faUserLock,
  wrench: faWrench,
};

export interface IconsProps extends Pick<FontAwesomeIconProps, Exclude<keyof FontAwesomeIconProps, "icon">> {
  type: IconTypes;
}

export const Icon: React.FC<IconsProps> = ({ type, ...iconProps }) => (
  <FontAwesomeIcon {...iconProps} icon={IconMap[type]} />
);
