import styled from "@emotion/styled";
import { ThemeProps } from "common/components/ui";

export const Divider = styled.hr<ThemeProps>(
  ({ theme }) => `
    border-width: ${theme.border.width.B1} 0 0 0;
    border-color: ${theme.colors.NEUTRAL[60]};
    border-style: solid;
    margin: 0;
  `
);
