import React, { FC } from "react";
import styled from "@emotion/styled";
import { ThemeProps } from "common/components/ui/shared";
import { BorderIcon, Button, Title } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import { useConnectedRmtCard } from "./useConnectedRmtCard";
import { CopyAddressContainer } from "../CopyAddressContainer/CopyAddressContainer";
import { Card } from "common/components/Card";
import { RmtCardProps, rmtConfigProp } from "../../RmtUtils";

const StyledHeader = styled.div<ThemeProps>(
  ({ theme }) => `
  text-align: center;
  margin-top: ${theme.spacing.S3};
  margin-bottom: ${theme.spacing.S7};
  `
);

const StyledButton = styled(Button)<ThemeProps>(
  ({ theme }) => `
  margin-right: ${theme.spacing.S4};
  `
);

const StyledText = styled.div<ThemeProps>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S4};
  margin-bottom: ${theme.spacing.S6};
  `
);

const FooterContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  justify-content: end;
  padding: ${theme.spacing.S4} 0;
  box-shadow: inset 0 1px 0 0 ${theme.colors.NEUTRAL[60]};
  border-radius: ${theme.border.radius.R0} ${theme.border.radius.R2};
  width: 100%;
`
);

const MainContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing.S7};
  margin-bottom: ${theme.spacing.S9};
  width: 100%;
`
);

const StyledCard = styled(Card)<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${theme.spacing.S7} auto;
  box-shadow: ${theme.shadow.S1};
  border-radius: ${theme.border.radius.R4};
`
);

const StyledDiv = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: space-evenly;
  padding: 0;
  margin-bottom: ${theme.spacing.S7};
  justify-content: center;
  width: 50%;
`
);

const StyledBorderIcon = styled(BorderIcon)<ThemeProps>(
  ({ theme }) => `
  margin: ${theme.spacing.S4} 0;
`
);

export const ConnectedRmtCard: FC<RmtCardProps> = ({ rmt }) => {
  const { onDoneClicked, address } = useConnectedRmtCard();
  const rmtName = rmtConfigProp(rmt.rmtId, "name");
  return (
    <StyledCard>
      <MainContainer data-testid="returns-rmt-connected-card">
        <StyledHeader>
          <StyledBorderIcon appearance="SUCCESS" iconType="check" size="XXL" />
          <Title as="h4" displayAs="h4" data-testid="returns-rmt-connected-card-title">
            <FormattedMessage
              id="returns.rmt.connected.title"
              defaultMessage="Connected successfully. You're almost done."
            />
          </Title>
          <StyledText>
            <FormattedMessage
              id="returns.rmt.connected.message"
              defaultMessage={`Update your return destination in ${rmtName} to the address below.`}
            />
          </StyledText>
        </StyledHeader>
        <StyledDiv>
          <CopyAddressContainer address={address} />
        </StyledDiv>
      </MainContainer>

      <FooterContainer>
        <StyledButton data-testid="returns-rmt-connected-card-done-button" onClick={onDoneClicked}>
          <FormattedMessage id="returns.rmt.connected.doneForNow" defaultMessage="Done" />
        </StyledButton>
      </FooterContainer>
    </StyledCard>
  );
};
