import { isNil } from "lodash/fp";
import { HazmatInformationStatus } from "@deliverr/commons-clients";
import { isDimsConfirmed } from "common/utils/product/isDimsConfirmed";
import { hasBarcodes } from "common/utils/product/hasBarcodes";
import { ProductListItem } from "inventory/list/InventoryListReducer";
import { defineMessages, MessageDescriptor } from "react-intl";
import { FBA_MAX_QUANTITY_IN_CASE } from "common/constants/fbaValidations.const";
import { isFNSKUOrASINBarcode } from "transfers/utils/isFNSKUBarcode";
import { find } from "lodash";

export enum ProductError {
  OnlyKitCanBeSelected = "OnlyKitCanBeSelected",
  OnlyNonKitCanBeSelected = "OnlyNonKitCanBeSelected",
  AlreadySelected = "AlreadySelected",
  OutOfStock = "OutOfStock",
  InvalidDimensions = "InvalidDimensions",
  Hazmat = "Hazmat",
  NoBarcode = "NoBarcode",
  MissingAsin = "MissingAsin",
  MissingWalmartChannelId = "MissingWalmartChannelId",
  LargeCasePack = "LargeCasePack",
  PackVariantSelected = "PackVariantSelected",
  MaximumSelectableCaseQty = "MaximumSelectableCaseQty",
}

export const ProductErrorMessages: Record<ProductError, MessageDescriptor> = defineMessages({
  [ProductError.OnlyKitCanBeSelected]: {
    id: "ProductSearch.onlyKitCanBeSelected",
    defaultMessage: "Non-Kitted Product",
  },
  [ProductError.OnlyNonKitCanBeSelected]: {
    id: "ProductSearch.onlyKitCanBeSelected",
    defaultMessage: "Kitted Product",
  },
  [ProductError.AlreadySelected]: {
    id: "ProductSearch.alreadySelected",
    defaultMessage: "Already Selected",
  },
  [ProductError.OutOfStock]: {
    id: "ProductSearch.zeroAvailable",
    defaultMessage: "Out of stock",
  },
  [ProductError.InvalidDimensions]: {
    id: "ProductSearch.invalidDimensions",
    defaultMessage: "Invalid Dimensions",
  },
  [ProductError.Hazmat]: {
    id: "ProductSearch.hazmat",
    defaultMessage: "Hazmat {status}",
  },
  [ProductError.NoBarcode]: {
    id: "ProductSearch.noBarcode",
    defaultMessage: "No Barcodes",
  },
  [ProductError.MissingAsin]: {
    id: "ProductSearch.missingAsin",
    defaultMessage: "Missing ASIN",
  },
  [ProductError.MissingWalmartChannelId]: {
    id: "ProductSearch.missingWalmartChannelId",
    defaultMessage: "Not synced with Walmart",
  },
  [ProductError.LargeCasePack]: {
    id: "ProductSearch.largeCasePack",
    defaultMessage: "Large Case Pack",
  },
  [ProductError.PackVariantSelected]: {
    id: "ProductSearch.packVariantSelected",
    defaultMessage: "Only one case pack configuration can be added per order",
  },
  [ProductError.MaximumSelectableCaseQty]: {
    id: "ProductSearch.maximumSelectableCaseQty",
    defaultMessage: "Case quantity of pack exceeds requested inventory",
  },
});

export interface ProductErrorPredicateParams {
  product: ProductListItem;
  selectedSkus?: string[];
  allowZeroInventorySelection?: boolean;
  isDimsRequired?: boolean;
  isBarcodeRequired?: boolean;
  isReserveStorage?: boolean;
  withWalmartChannelIdOnly?: boolean;
  applyFbaConditions?: boolean;
  isIntegratedFBA?: boolean;
  showOnlyPacks?: boolean;
  selectedPackSkus?: string[];
  storageMaximumSelectableCaseQty?: number;
  excludedPackOfSkusForStorage?: string[];
  shouldShowKitHint?: boolean;
  selectedKitSkus?: boolean;
}

const ProductErrorPredicates: Record<ProductError, (params: ProductErrorPredicateParams) => boolean> = {
  [ProductError.AlreadySelected]: ({ product, selectedSkus }) => !!selectedSkus?.includes(product.raw.dsku),
  [ProductError.LargeCasePack]: ({ product, applyFbaConditions, isIntegratedFBA }) =>
    !!applyFbaConditions && !!product.caseQty && !!isIntegratedFBA && product.caseQty > FBA_MAX_QUANTITY_IN_CASE,
  [ProductError.OutOfStock]: ({ product, allowZeroInventorySelection, isReserveStorage, shouldShowKitHint }) =>
    !allowZeroInventorySelection &&
    (isReserveStorage
      ? (!product.raw.storageOnHandQty || product.raw.storageOnHandQty <= 0) &&
        !(shouldShowKitHint && product.isKit && product.caseQty === 1)
      : !product.raw.onHandQty || product.raw.onHandQty <= 0),
  [ProductError.InvalidDimensions]: ({ product, isDimsRequired }) =>
    Boolean(isDimsRequired) && !isDimsConfirmed(product.raw.dimSourceType),
  [ProductError.Hazmat]: ({ product }) =>
    !!product.hazmatInformation?.status && product.hazmatInformation.status !== HazmatInformationStatus.APPROVED,
  [ProductError.NoBarcode]: ({ product, isBarcodeRequired }) =>
    Boolean(isBarcodeRequired) && !hasBarcodes(product.raw.barcodes),
  [ProductError.MissingWalmartChannelId]: ({ product, withWalmartChannelIdOnly }) =>
    !!withWalmartChannelIdOnly &&
    !product?.productAliases?.find(
      (productAlias) => productAlias.channelId === "WALMARTDIRECT" && productAlias.channelProductId
    ),
  [ProductError.MissingAsin]: ({ product, applyFbaConditions, isIntegratedFBA }) =>
    !!isIntegratedFBA && !!applyFbaConditions && !product.asin && isNil(find(product?.barcodes, isFNSKUOrASINBarcode)),
  [ProductError.PackVariantSelected]: ({ product, selectedPackSkus, showOnlyPacks, excludedPackOfSkusForStorage }) =>
    !!showOnlyPacks &&
    (!!selectedPackSkus?.includes(product.raw.packOf) || !!excludedPackOfSkusForStorage?.includes(product.raw.packOf)),
  [ProductError.MaximumSelectableCaseQty]: ({ product, showOnlyPacks, storageMaximumSelectableCaseQty }) =>
    !!showOnlyPacks &&
    !isNil(storageMaximumSelectableCaseQty) &&
    !isNaN(storageMaximumSelectableCaseQty) &&
    storageMaximumSelectableCaseQty < product.caseQty!,
  [ProductError.OnlyKitCanBeSelected]: ({ product, selectedSkus, selectedKitSkus, isReserveStorage }) =>
    !!isReserveStorage && !!selectedSkus && selectedSkus.length > 0 && !!selectedKitSkus && !product.isKit,
  [ProductError.OnlyNonKitCanBeSelected]: ({ product, selectedSkus, selectedKitSkus, isReserveStorage }) =>
    !!isReserveStorage && !!selectedSkus && selectedSkus.length > 0 && !selectedKitSkus && !!product.isKit,
};

export const getProductError = (params: ProductErrorPredicateParams): ProductError | undefined =>
  Object.keys(ProductErrorPredicates).find((err) => ProductErrorPredicates[err](params)) as ProductError | undefined;
