import { useEffect, useMemo } from "react";
import { useAsync } from "react-use";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";
import { sellerProblemsViewClient } from "Clients";
import log from "Logger";
import { SellerProblemType } from "@deliverr/legacy-inbound-client";

export const useFetchMissingBarcodeCase = ({ caseId }: { caseId?: string }) => {
  const { formatMessage } = useIntl();

  const ctx = useMemo(() => ({ fn: "useFetchMissingBarcodeCase", caseId }), [caseId]);
  const {
    value,
    loading: isLoading,
    error,
  } = useAsync(async () => {
    if (!caseId) {
      return;
    }
    log.info({ ...ctx }, "getting missing barcode case");
    return await sellerProblemsViewClient
      .getSellerProblemOverviewBySellerProblemIdAndType(caseId, SellerProblemType.MISSING_BARCODE)
      .then((res) => res.data);
  }, [caseId]);

  useEffect(() => {
    if (error) {
      log.warn({ ...ctx, error }, "error fetching missing barcode case");
      toast.error(
        formatMessage({
          id: "nonCompliance.product.fetchError",
          defaultMessage: "There was an issue fetching your shipment problem",
        })
      );
    }
  }, [ctx, error, formatMessage, caseId]);

  return {
    value,
    isLoading,
    error,
  };
};
