import React, { FC } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Text } from "common/components/ui";

import RouteParser from "common/RouteParser";
import { Path } from "paths";
import { ProductListItem } from "inventory/list/InventoryListReducer";
import { TextWithBottomMargin } from "..";
import { removeAllEmTags } from "common/StringUtils";

interface NonComplianceProductSummaryProps {
  product: ProductListItem;
}

export const NonComplianceProductSummary: FC<NonComplianceProductSummaryProps> = ({
  product: { name, dsku, msku },
}) => (
  <>
    <TextWithBottomMargin bold>
      <FormattedMessage id="inbounds.nonCompliance.nonCompliantProduct" defaultMessage="Non-compliant product" />
    </TextWithBottomMargin>
    <TextWithBottomMargin>{removeAllEmTags(name)}</TextWithBottomMargin>
    <Link to={new RouteParser(Path.inventoryDetail).parse({ dsku })}>
      <Text bold>{removeAllEmTags(msku)}</Text>
    </Link>
  </>
);
