import { PrepJobSourceType, WholesaleOrderOrigin } from "@deliverr/prep-service-client";
import { Text } from "common/components/ui";
import { DynamicRoutes } from "paths";
import { PREP_LIST_LABELS } from "prep/list/PrepList.labels";
import React, { FC, ReactNode } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

interface SourceLinkProps {
  sourceId: string;
  sourceType: PrepJobSourceType;
  wholesaleOrderId?: string | null;
  wholesaleOrderOrigin?: WholesaleOrderOrigin | null;
  text?: ReactNode;
  hideSubText?: boolean;
}

export const SourceLink: FC<SourceLinkProps> = ({
  sourceId,
  sourceType,
  wholesaleOrderOrigin,
  wholesaleOrderId,
  text,
  hideSubText,
}) => {
  const { formatMessage } = useIntl();
  let link;
  let message;

  if (sourceType === PrepJobSourceType.SHIPPING_PLAN) {
    link = (
      <Link to={DynamicRoutes.inboundsShippingPlanRedirect.parse({ shippingPlanId: sourceId })}>
        {text ?? sourceId}
      </Link>
    );
    message = formatMessage({ ...PREP_LIST_LABELS.sourceLink.shippingPlan });
  } else if (sourceType === PrepJobSourceType.WHOLESALE_SHIPMENT && wholesaleOrderOrigin && wholesaleOrderId) {
    if (wholesaleOrderOrigin === WholesaleOrderOrigin.WHOLESALE) {
      link = <Link to={DynamicRoutes.wholesaleDetail.parse({ orderId: wholesaleOrderId })}>{text ?? sourceId}</Link>;
      message = formatMessage({ ...PREP_LIST_LABELS.sourceLink.wholesaleShipment });
    } else if (wholesaleOrderOrigin === WholesaleOrderOrigin.REPLENISHMENT) {
      link = (
        <Link to={DynamicRoutes.replenishmentDetails.parse({ orderId: wholesaleOrderId })}>{text ?? sourceId}</Link>
      );
      message = formatMessage({ ...PREP_LIST_LABELS.sourceLink.replenishment });
    }
  } else if (sourceType === PrepJobSourceType.EXTERNAL_TASK) {
    link = <Text>{formatMessage({ ...PREP_LIST_LABELS.sourceLink.emptySourceId })}</Text>;
    message = formatMessage({ ...PREP_LIST_LABELS.sourceLink.externalTask });
  }

  return link ? (
    <>
      {link}
      {!hideSubText && message && (
        <Text appearance="INFO" size="caption">
          {message}
        </Text>
      )}
    </>
  ) : (
    <Text>{text ?? sourceId}</Text>
  );
};
