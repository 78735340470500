import React, { FC } from "react";
import { StepManager } from "./StepManager";
import { StepManagerContext } from "./StepManagerContext";

export const MultiStepWizard: FC<{ stepManager: StepManager }> = ({ stepManager }) => {
  const StepComponent = stepManager.currentStep.component;
  return (
    <StepManagerContext.Provider value={stepManager}>
      <StepComponent />
    </StepManagerContext.Provider>
  );
};
