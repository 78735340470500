import { SPThunkAction } from "common/ReduxUtils";
import { logStart, logError } from "Logger";
import history from "BrowserHistory";
import { generatePath } from "react-router-dom";
import { Path } from "paths";
import { setRedirectToInboundFailed } from "./TransferShipmentDetailAction";

export const redirectToInbounds = (inboundShippingPlanId?: string, inboundShipmentId?: string): SPThunkAction => async (
  dispatch
) => {
  const ctx = logStart({ fn: "redirectToInbounds", inboundShippingPlanId });
  try {
    if (inboundShippingPlanId && inboundShipmentId) {
      history.push(
        generatePath(Path.inboundsShip, { shippingPlanId: inboundShippingPlanId, shipmentId: inboundShipmentId })
      );
    }
  } catch (err) {
    logError(ctx, err, "Error redirecting to inbound");
    dispatch(setRedirectToInboundFailed());
  }
};
