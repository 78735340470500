import { FLEXPORT_TRACKING_PREFIX } from "inbounds/steps/ship/view/FlexportAutomatedTracking/constants";

export const validateFlexportID = (value: string): string | undefined => {
  const hasFlexIdPrefix = value.toLowerCase().startsWith(FLEXPORT_TRACKING_PREFIX.toLowerCase());

  if (!hasFlexIdPrefix) {
    return FLEXPORT_TRACKING_PREFIX;
  }

  const onlyNumbersRegex = /^[0-9]*$/;
  const flexSuffix = value.slice(FLEXPORT_TRACKING_PREFIX.length);
  if (flexSuffix.match(onlyNumbersRegex)) {
    return value;
  }

  return undefined;
};
