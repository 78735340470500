import React from "react";
import { Notification, Anchor } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import { FREIGHT_EMAIL_ID } from "common/utils/constants/EmailIdConstants";

export const LimitedAccessAddressNotification: React.ComponentType = () => {
  return (
    <Notification
      appearance="PRIMARY"
      content={{
        title: (
          <FormattedMessage
            defaultMessage="Limited access locations are not yet supported"
            id="inbounds.limitedAccessAddressNotification.title"
          />
        ),
        description: (
          <FormattedMessage
            defaultMessage="Automated booking is not yet supported for limited access locations. Please contact us directly to book your shipment."
            id="inbounds.limitedAccessAddressNotification.content"
          />
        ),
      }}
      append={
        <Anchor href={`mailto:${FREIGHT_EMAIL_ID}`}>
          <FormattedMessage defaultMessage="Contact us" id="inbounds.limitedAccessAddressNotification.contactUs" />
        </Anchor>
      }
    />
  );
};
