import { Dropzone, DropzoneProps } from "common/components/ui";
import React, { FC } from "react";

export const ShippingDocumentsDropZone: FC<DropzoneProps> = ({
  id,
  value,
  onChange,
  onFileClear,
  loading,
  acceptedFileTypes,
}) => {
  return (
    <Dropzone
      id={id}
      acceptedFileTypes={acceptedFileTypes}
      onChange={onChange}
      onFileClear={onFileClear}
      loading={loading}
      value={value}
      dropzoneProps={{ maxFiles: 1 }}
    ></Dropzone>
  );
};
