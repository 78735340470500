import React from "react";
import { AttachmentType } from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { logError } from "Logger";
import { ERROR_TOAST_AUTO_CLOSE_TIME } from "common/utils/toasts/toasts";
import { capitalize } from "lodash";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

export const markShipmentAttachmentsAsUploaded = async (
  shipmentId: string,
  attachmentTypes: AttachmentType[]
): Promise<void> => {
  const ctx = { fn: "markShipmentAttachmentsAsUploaded" };

  await Promise.all([
    ...attachmentTypes.map(async (attachmentType) => {
      try {
        const data = await replenishmentClient.markAttachmentAsUploaded(shipmentId, attachmentType);
        return data;
      } catch (e) {
        logError(ctx, e);
        toast.error(
          <FormattedMessage
            id="transfers.create.actions.replenishmentOrder.updateShipmentExternalIds.markAttachmentAsUploadedError"
            defaultMessage="Failed to upload attachment for type {attachmentType}"
            values={{
              attachmentType: capitalize(attachmentType.replace("_", " ")),
            }}
          />,
          {
            autoClose: ERROR_TOAST_AUTO_CLOSE_TIME,
          }
        );
        return undefined;
      }
    }),
  ]);
};
