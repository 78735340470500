import { notifyUserOfError } from "common/ErrorToast";
import { Thunk } from "common/ReduxUtils";
import updateUser from "common/user/UpdateUser";
import { updateLocalUser } from "common/user/UserActions";
import log from "Logger";

export const updateResultsPerPage: Thunk = (page: string, size: number) => async (dispatch, getState) => {
  const ctx = { fn: "updateResultsPerPage", page, size };
  log.info(ctx, "updatingResultsPerPage");

  const {
    user: { resultsPerPage: oldResultsPerPage },
  } = getState();
  const newResultsPerPage = {
    ...oldResultsPerPage,
    [page]: size,
  };

  try {
    await updateUser({ resultsPerPage: JSON.stringify(newResultsPerPage) });
    dispatch(updateLocalUser({ resultsPerPage: newResultsPerPage }));
  } catch (err) {
    log.error({ ...ctx, err }, "error updating results per page");
    notifyUserOfError({ err, toastId: "updateResultsPerPageError" });
  }
};
