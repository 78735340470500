import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { useInboundFreightTrackingInfo } from "inbounds/steps/ship/view/cards/useInboundFreightTrackingInfo";
import { updateFreightTrackingInfo } from "inbounds/store/actions/freight/updateFreightTrackingInfo";
import { getLoadedShipmentId } from "inbounds/store/selectors/shipments";
import { FLEXPORT_TRACKING_PREFIX } from "inbounds/steps/ship/view/FlexportAutomatedTracking/constants";

export function useAddReceivingInfoForm(isFlexport = false) {
  const { bolId, proId, updateInboundFreightTrackingInfo, hasReceivingInfoChanged } = useInboundFreightTrackingInfo();

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const shipmentId = useSelector(getLoadedShipmentId);

  const setBolId = (val: string) => {
    updateInboundFreightTrackingInfo({ bolId: val });
  };

  const setProId = (val: string) => {
    if (isFlexport) {
      const validated = validateFlexportID(val);
      if (validated) {
        updateInboundFreightTrackingInfo({ proId: validated });
      }
    } else {
      updateInboundFreightTrackingInfo({ proId: val });
    }
  };

  const onSubmit = async () => {
    await dispatch(updateFreightTrackingInfo(shipmentId!, { bolId, proId }));
  };

  const validateFlexportID = (value: string): string | undefined => {
    const hasFlexIdPrefix = value.toLowerCase().startsWith(FLEXPORT_TRACKING_PREFIX.toLowerCase());

    if (!hasFlexIdPrefix) {
      return FLEXPORT_TRACKING_PREFIX;
    }

    const onlyNumbersRegex = /^[0-9]*$/;
    const [, flexSuffix] = value.split(FLEXPORT_TRACKING_PREFIX);
    if (flexSuffix.match(onlyNumbersRegex)) {
      return value;
    }

    return undefined;
  };

  const bolIdPlaceholder = formatMessage({
    id: "inbounds.ltlExternalConfirmation.addReceivingInfoForm.bolIdPlaceholder",
    defaultMessage: "Input your BOL #",
  });

  const proIdPlaceholder = formatMessage({
    id: "inbounds.ltlExternalConfirmation.addReceivingInfoForm.proIdPlaceholder",
    defaultMessage: "Input your PRO #",
  });

  return {
    bolId,
    setBolId,
    proId,
    setProId,
    bolIdPlaceholder,
    proIdPlaceholder,
    submitDisabled: !hasReceivingInfoChanged,
    onSubmit,
  };
}
