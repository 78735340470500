import { DistributionChannel, ShippingPartner, ShippingType } from "@deliverr/replenishment-client";
import { MessageDescriptor } from "react-intl";

const fbaDestination = {
  id: "replenishment.shipmentDetails.fbaDestination",
  defaultMessage: "Amazon FBA",
};

const wfsDestination = {
  id: "replenishment.shipmentDetails.wfsDestination",
  defaultMessage: "Walmart WFS",
};

const ecommDestination = {
  id: "replenishment.shipmentDetails.wfsDestination",
  defaultMessage: "Ecomm",
};

const wholesaleDestination = {
  id: "replenishment.shipmentDetails.wholesaleDestination",
  defaultMessage: "Wholesale",
};

export const replenishmentOrderDestinationMap: Record<DistributionChannel, MessageDescriptor> = {
  [DistributionChannel.FBA]: fbaDestination,
  [DistributionChannel.FBA_V3]: fbaDestination,
  [DistributionChannel.FBA_MANUAL]: fbaDestination,
  [DistributionChannel.WFS]: wfsDestination,
  [DistributionChannel.ECOM_VIA_CROSSDOCK]: ecommDestination,
  [DistributionChannel.ECOM_DIRECT_TO_FC]: ecommDestination,
  [DistributionChannel.ECOM_VIA_HUB]: ecommDestination,
  [DistributionChannel.WHOLESALE]: wholesaleDestination,
};

const freightMessage = {
  id: "transfers.create.steps.shipping.options.type.freight",
  defaultMessage: "LTL/FTL",
};

const parcelMessage = {
  id: "transfers.create.steps.shipping.options.type.parcel",
  defaultMessage: "Small parcel",
};

export const replenishmentOrderShipmentTypeMap: Record<ShippingType, MessageDescriptor> = {
  [ShippingType.PARCEL]: parcelMessage,
  [ShippingType.LTL]: freightMessage,
  [ShippingType.FTL]: freightMessage,
};

const deliverrMessage = {
  id: "transfers.create.steps.shipping.options.method.deliverr",
  defaultMessage: "Flexport",
};

const fbaMessage = {
  id: "transfers.create.steps.shipping.options.method.amazon",
  defaultMessage: "Amazon",
};

const wfsMessage = {
  id: "transfers.create.steps.shipping.options.method.walmart",
  defaultMessage: "Walmart",
};

const sellerMessage = {
  id: "transfers.create.steps.shipping.options.method.seller",
  defaultMessage: "Your carrier",
};

export const replenishmentOrderShipmentPartnerMap: Record<ShippingPartner, MessageDescriptor> = {
  [ShippingPartner.DELIVERR]: deliverrMessage,
  [ShippingPartner.FBA]: fbaMessage,
  [ShippingPartner.WFS]: wfsMessage,
  [ShippingPartner.SELLER]: sellerMessage,
  [ShippingPartner.COLLECT]: sellerMessage,
};
