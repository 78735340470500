import { Dictionary } from "lodash";
import { fromPairs, omit, zip } from "lodash/fp";

export interface ById<T> {
  ids: number[];
  byId: Dictionary<T>;
}

export const emptyById = { byId: {}, ids: [] };

export const getItemsFromById = <T>(original: ById<T>): T[] => original.ids.map((id) => original.byId[id]);

export const addToById = <T>(id: number, item: T, original: ById<T>): ById<T> => {
  const { ids, byId } = original;
  return {
    ids: [id, ...ids],
    byId: { ...byId, [id]: item },
  };
};

export const addAllToById = <T>(ids: number[], items: T[], original: ById<T> = emptyById) => ({
  byId: { ...original.byId, ...fromPairs(zip(ids, items)) },
  ids: [...original.ids, ...ids],
});

export const updateInById = <T>(updateItem: (item: T) => T, id: number, original: ById<T>): ById<T> => {
  const { ids, byId } = original;
  return {
    ids,
    byId: { ...byId, [id]: updateItem(original.byId[id]) },
  };
};

export const removeFromById = <T>(id: number, original: ById<T>): ById<T> => {
  const { ids, byId } = original;
  return {
    ids: ids.filter((currentId) => currentId !== id),
    byId: omit(id, byId),
  };
};
