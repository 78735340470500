import { InvalidOrderItemDto } from "@deliverr/replenishment-client";

export interface AugmentedOutOfStockOrderItem extends InvalidOrderItemDto {
  inventoryStatus?: InvalidOrderItemInventoryStatus;
  availableQty?: number;
}

export enum InvalidOrderItemInventoryStatus {
  OUT_OF_STOCK = 1,
  PARTIAL_STOCK = 2,
  IN_STOCK = 3,
}
