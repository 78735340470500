import React from "react";
import { FormattedMessage } from "react-intl";
import { BoxReset, Notification } from "common/components/ui";
import { replenishmentPickProductErrorMessage } from "./replenishment/errors/pick-products-errors/ReplenishmentPickProductErrorMessages";
import { ReplenishmentUnknownErrorCode } from "./replenishment/errors/ReplenishmentErrorCodes";
import { useSelector } from "react-redux";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { isEmpty } from "lodash";
import { ReplenishmentError } from "transfers/create/store";
import { getIsLoading } from "common/components/WithLoader/LoadingSelectors";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { DskuQty } from "common/clients/nonCompliance/BoxLabelIncidentDsku/DskuQty";

export const getErrorCode = (replenishmentError: ReplenishmentError | undefined) => {
  if (replenishmentError?.errors?.[0]) {
    return replenishmentError?.errors?.[0].code ?? ReplenishmentUnknownErrorCode;
  }
  return undefined;
};

export const useReplenishmentAvailableInventoryErrorNotification = (dskuQuantities: DskuQty[]) => {
  const { replenishmentError } = useSelector(selectTransferCreate);
  const isAvailableWarehouseLoading = useSelector(getIsLoading)(CreateTransferLoader.FetchStorageInventoryLoader);
  const shouldShowErrorNotification =
    replenishmentError?.errors?.[0] && !isAvailableWarehouseLoading && !isEmpty(dskuQuantities);
  const errorCode = getErrorCode(replenishmentError);

  return {
    errorCode,
    shouldShowErrorNotification,
    isAvailableWarehouseLoading,
  };
};

export const ReplenishmentAvailableInventoryErrorNotification = ({ errorCode }: { errorCode?: string }) => {
  return (
    <BoxReset marginBottom={"S5"}>
      <Notification
        appearance="DANGER"
        data-testid={"transfers-pick-product-error"}
        content={{
          title: (
            <FormattedMessage
              {...replenishmentPickProductErrorMessage[errorCode ?? ReplenishmentUnknownErrorCode].title}
            />
          ),
          description: (
            <FormattedMessage
              {...replenishmentPickProductErrorMessage[errorCode ?? ReplenishmentUnknownErrorCode].description}
            />
          ),
        }}
      />
    </BoxReset>
  );
};
