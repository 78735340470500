import React from "react";
import styled from "@emotion/styled";
import { useTable, Column, TableProps } from "react-table";
import { DefaultTheme } from "common/components/ui/shared/theme";

export interface RootTableProps extends TableProps {
  hasAggregatedRow?: boolean; // does the table have a final row comprised of aggregated data
}

export interface BasicTableProps<T extends Record<string, unknown>> extends RootTableProps {
  columns: Column<T>[]; // Column configurations for the table
  data: T[]; // The rows / data to populate the table body with
  hideHeaders?: (keyof T)[]; // header labels you wish to hide if any (specified by field key)
}

const RootTable = styled.table<RootTableProps, DefaultTheme>(
  ({ hasAggregatedRow, theme }) => `
  tbody tr {
    td {
      padding: ${theme.spacing.S1} 0 ${theme.spacing.S1};
    }
    /* add special styling (spacing and divider) for aggregated row */
    ${
      !hasAggregatedRow
        ? ""
        : `
          :last-child {
            border-top: ${theme.border.width.B1} ${theme.border.type};
            td {
              padding-top: ${theme.spacing.S2};
            }
          }

          :nth-last-of-type(2) td {
            padding-bottom: ${theme.spacing.S2};
          }
        `
    }
  }
`
);

const ColumnHeader = styled.th<{ isHidden?: boolean; ignoreHeaderWidth?: boolean }, DefaultTheme>(
  ({ isHidden, ignoreHeaderWidth, theme }) => `
    visibility: ${isHidden ? "hidden" : "visible"};
    ${ignoreHeaderWidth ? `display: none;` : ""}
    padding-bottom: ${theme.spacing.S2};
  `
);

export function BasicTable<T extends Record<string, unknown>>({
  columns,
  data,
  hasAggregatedRow,
  className,
  hideHeaders,
}: BasicTableProps<T>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<T>({ columns, data });

  return (
    <RootTable {...getTableProps()} hasAggregatedRow={hasAggregatedRow} className={className}>
      <thead>
        {headerGroups.map((headerGroup, headerRowIndex) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={`header-row-${String(headerRowIndex)}`}>
            {headerGroup.headers.map((column, headerIndex) => (
              <ColumnHeader
                {...column.getHeaderProps()}
                isHidden={hideHeaders?.includes(column.id)}
                key={`th-${String(headerIndex)}`}
              >
                {column.render("Header")}
              </ColumnHeader>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          // Prepare the row for display (supplied by react-table.useTable)
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={`row-${String(rowIndex)}`}>
              {row.cells.map((cell, cellIndex) => (
                <td {...cell.getCellProps()} key={`cell-${String(cellIndex)}-row-${String(rowIndex)}`}>
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </RootTable>
  );
}
