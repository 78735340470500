import { useHistory, useParams } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { useProblemsTableColumns } from "common/components/ProblemsTable/useProblemsTableColumns";
import useSWR from "swr";
import log from "Logger";
import { useSelector } from "react-redux";
import { getSellerId } from "common/user/UserSelectors";
import { productClient, sellerProblemsViewClient } from "Clients";
import * as spvUtils from "./spvUtils";
import { compact, isNil } from "lodash/fp";
import { ProblemRowData } from "common/components/ProblemsTable/types";
import { getNonComplianceFlowRoute } from "./spvUtils/getNonComplianceFlowRoute";
import { usePreventScrollToTopNavigation } from "common/hooks/usePreventScrollToTopNavigation";
import { nonComplianceDynamicRoutes } from "../NonComplianceRoutes";
import { SellerProblemsDetailView, SellerProblemStatus, SellerProblemType } from "@deliverr/legacy-inbound-client";
import { ProductCollection } from "common/models";
import { ProblemType } from "common/problems/ProblemType";
import { DynamicRoutes } from "paths";

export interface NonComplianceSpvListRouteParams {
  nonCompliantId?: string;
  nonComplianceType?: SellerProblemType;
}

const SUPPORTED_SELLER_PROBLEM_TYPES = [
  SellerProblemType.DAMAGED_PRODUCT,
  SellerProblemType.MISSING_BARCODE,
  SellerProblemType.UNKNOWN_BARCODE,
  SellerProblemType.UNEXPECTED_SKU,
  SellerProblemType.MISSING_CONTAINER_LABEL,
  SellerProblemType.ILLEGIBLE_LOT,
  SellerProblemType.ILLEGIBLE_EXPIRATION,
  SellerProblemType.EXPIRED_PRODUCT,
  SellerProblemType.OTHER_NON_COMPLIANCE,
  SellerProblemType.OVER_RECEIVED_UNITS,
  SellerProblemType.SHORT_SHIP,
  SellerProblemType.FLEXPORT_EXCEPTION,
];

const getSellerProblemRow = (issue: SellerProblemsDetailView, productCollection?: ProductCollection) => {
  if (!issue.isLegacyCase) {
    return spvUtils.getNonComplianceRowsFromGenericIssueCases(issue, productCollection);
  }
  switch (issue.type) {
    case SellerProblemType.DAMAGED_PRODUCT:
      return spvUtils.getNonComplianceRowsFromDamagedProductCases(issue, productCollection);
    case SellerProblemType.MISSING_BARCODE:
      return spvUtils.getNonComplianceRowsFromMissingBarcodeCases(issue, productCollection);
    case SellerProblemType.UNEXPECTED_SKU:
      return spvUtils.getNonComplianceRowsFromUnexpectedBarcodeCases(issue, productCollection);
    case SellerProblemType.UNKNOWN_BARCODE:
      return spvUtils.getNonComplianceRowsFromNonComplianceProducts(issue, productCollection);
    case SellerProblemType.FLEXPORT_EXCEPTION:
      return spvUtils.getNonComplianceRowsFromOperationalException(issue, productCollection);
    default:
      log.warn(issue, `Unsupported SellerProblemType in useNonComplianceSpvList`);
      return undefined;
  }
};

export const useNonComplianceSpvList = (pageSize: number, pageNumber: number) => {
  const history = useHistory();
  const navigate = usePreventScrollToTopNavigation();
  const { columns } = useProblemsTableColumns();
  const sellerId = useSelector(getSellerId);
  const { nonCompliantId, nonComplianceType } = useParams<NonComplianceSpvListRouteParams>();
  const [activeCaseFallback, setActiveCaseFallback] = useState<SellerProblemsDetailView | undefined>();
  const [productCollection, setProductCollection] = useState<ProductCollection>();

  const { data, error, isLoading } = useSWR(
    [sellerId, pageSize, pageNumber],
    async ([sId, ps, pn]) => {
      const problems = await sellerProblemsViewClient
        .searchSellerProblems({
          sellerId: sId,
          pageSize: ps,
          pageNumber: pn,
          includedSellerProblemTypes: SUPPORTED_SELLER_PROBLEM_TYPES,
        })
        .then((res) => res.data);
      const products = await productClient.getUnifiedProducts(
        problems?.results.filter((p) => p.dsku).map((p) => p.dsku) as string[]
      );
      setProductCollection(products);
      return problems;
    },
    {
      errorRetryCount: 3,
      revalidateOnFocus: false,
      onError: (err) => {
        log.warn({ sellerId, err }, "error fetching seller problems");
      },
    }
  );

  const rows = useMemo(() => {
    if (data) {
      return compact(data.results.map((issue) => getSellerProblemRow(issue, productCollection)));
    }
    return [];
  }, [data]);

  const activeCase = useMemo(() => {
    const activeRow = rows.find(({ id, status }) => id === nonCompliantId && status !== SellerProblemStatus.CREATED);

    if (activeRow) {
      return activeRow;
    }

    if (
      activeCaseFallback &&
      (nonCompliantId === activeCaseFallback?.sellerProblemId?.toString() ||
        nonCompliantId === activeCaseFallback?.issueId)
    ) {
      return getSellerProblemRow(activeCaseFallback, productCollection);
    }
    return undefined;
  }, [rows, nonCompliantId, activeCaseFallback]);

  useEffect(() => {
    if (!activeCase && (data ?? error) && nonCompliantId !== undefined) {
      const fetchActiveCaseFallback = async () => {
        const activeCaseView = await sellerProblemsViewClient
          .getSellerProblemsDetailView(nonCompliantId, nonComplianceType as SellerProblemType)
          .then((res) => res.data);
        setActiveCaseFallback(activeCaseView);
      };

      fetchActiveCaseFallback().catch((err) => {
        log.warn({ nonCompliantId, err }, `Unable to find detailed view for problem id`);
      });
    }
  }, [activeCase, data, error, nonCompliantId]);

  const onRowClick = async (row: ProblemRowData) => {
    if (row.problemType === ProblemType.FLEXPORT_SHIP) {
      const path = DynamicRoutes.inboundsSmbShipmentProblem.parse({
        shippingPlanId: row.shippingPlanId,
        problemId: row.id,
      });
      history.push(path);
    } else if (row.status === SellerProblemStatus.ACTION_NEEDED && !isNil(row.id)) {
      const route = getNonComplianceFlowRoute(row.problemType);
      if (route !== null) {
        history.push(
          route.parse({
            nonCompliantId: row.id,
          })
        );
      }
    } else {
      navigate(
        nonComplianceDynamicRoutes.viewCompletedCase.parse({
          nonCompliantId: row.id,
          nonComplianceType: row.problemType,
        })
      );
    }
  };

  return {
    columns,
    rows,
    totalPages: data ? Math.ceil(data.totalResults / pageSize) : undefined,
    onRowClick,
    isLoading,
    activeCase,
  };
};
