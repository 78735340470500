import { StorageWarehouseSellerView } from "./StorageWarehouseSellerView";

export const getStorageWarehouseOptions = (warehouses: StorageWarehouseSellerView[]): StorageWarehouseSellerView[] => {
  const bestWarehouses = new Array<StorageWarehouseSellerView>();
  const warehousesWithPalletsSet = new Set();

  warehouses.forEach((warehouse) => {
    if (warehouse.hasAvailablePalletCapacity) {
      bestWarehouses.push(warehouse);
    }
    if (warehouse.palletCount > 0 && !warehouse.hasAvailablePalletCapacity) {
      warehousesWithPalletsSet.add(warehouse);
    }
  });

  bestWarehouses.forEach((warehouse) => warehousesWithPalletsSet.add(warehouse));

  const warehousesWithHigestScore = Array.from(warehousesWithPalletsSet.values()) as StorageWarehouseSellerView[];
  warehousesWithHigestScore.sort((warehouse1, warehouse2) => warehouse2.score - warehouse1.score);

  return warehousesWithHigestScore;
};
