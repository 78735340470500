import { MissingBoxesAlertStatus } from "@deliverr/commons-clients";

export const isFinalShortShipStatus = (
  status: MissingBoxesAlertStatus
): status is
  | MissingBoxesAlertStatus.REIMBURSED
  | MissingBoxesAlertStatus.SHORT_SHIP_CONFIRMED
  | MissingBoxesAlertStatus.ARRIVED_RESOLVED =>
  [
    MissingBoxesAlertStatus.REIMBURSED,
    MissingBoxesAlertStatus.SHORT_SHIP_CONFIRMED,
    MissingBoxesAlertStatus.ARRIVED_RESOLVED,
  ].includes(status);
