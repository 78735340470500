import { useState } from "react";
import { isEmpty } from "lodash";
import { useDebounce } from "react-use";
import { logError } from "Logger";
import { GlobalSearchResults, searchIndexes } from "../utils";
import { useIntl } from "react-intl";

export enum GlobalSearchLoader {
  SearchResults = "globalSearch.searchResults",
}
export const useGlobalSearch = () => {
  const { formatMessage } = useIntl();
  const [shouldShowSearchBar, setShouldShowSearchBar] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<GlobalSearchResults>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const openSearchBar = () => setShouldShowSearchBar(true);
  const closeSearchBar = () => {
    setShouldShowSearchBar(false);
    setSearchTerm("");
    setSearchResults({});
    setError("");
    setLoading(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    setLoading(true);
    if (isEmpty(value)) {
      setSearchResults({});
      setLoading(false);
      setError("");
    }
  };

  const fetchSearchResults = async (query: string) => {
    if (isEmpty(query)) {
      return;
    }
    setSearchResults({});
    setError("");
    try {
      const response = await searchIndexes(query);
      setSearchResults(response);
    } catch (err) {
      logError({ fn: "fetchSearchResults", query }, err);
      setError(formatMessage({ id: "fetchError", defaultMessage: "Failed to fetch search results" }));
    } finally {
      setLoading(false);
    }
  };

  useDebounce(
    async () => {
      if (searchTerm) {
        await fetchSearchResults(searchTerm);
      }
    },
    1000,
    [searchTerm]
  );

  return {
    shouldShowSearchBar,
    searchTerm,
    searchResults,
    isLoading,
    error,
    openSearchBar,
    closeSearchBar,
    handleInputChange,
  };
};
