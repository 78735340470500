import React, { FC } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import { isNil } from "lodash/fp";
import { DimensionField } from "./DimensionField";
import { DimensionsFormValues } from "../DimensionsFormValues";
import { NumberInput } from "common/components/NumberInput";

interface DimensionInputProps {
  dsku: string;
  fieldName: DimensionField;
  showError?: boolean;
  registerOptions?: RegisterOptions;
  placeholder?: string;
}

export const DimensionInput: FC<DimensionInputProps> = ({
  dsku,
  fieldName,
  showError,
  registerOptions,
  placeholder,
}) => {
  const {
    formState: { errors },
    register,
    setValue,
  } = useFormContext<DimensionsFormValues>();

  const fieldKey = `${dsku}.${fieldName}`;
  const { ref, onChange, ...fieldProps } = register(fieldKey, registerOptions);
  const handleValueChange = ({ floatValue }) => setValue(fieldKey, floatValue);

  return (
    <NumberInput
      {...fieldProps}
      hasError={showError && !isNil(errors[fieldKey])}
      placeholder={placeholder}
      decimalScale={2}
      getInputRef={ref}
      onValueChange={handleValueChange}
      isUncontrolled
    />
  );
};
