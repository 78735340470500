import styled from "@emotion/styled";
import { DefaultTheme } from "common/components/ui";
import { Title } from "common/components/ui/Title";

export const Content = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    padding: 0 ${theme.spacing.S8};
  `
);

export const StepsContainer = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    padding-top: ${theme.spacing.S5};
  `
);

export const StyledTitle = styled(Title)<{}, DefaultTheme>(
  ({ theme }) => `
    margin: 0;
    letter-spacing: normal;
  `
);
export const AccordionContainer = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    &:not(:last-of-type) {
      border-bottom: 1px solid ${theme.colors.NEUTRAL["80"]};
    }
  `
);
