export enum BulkUploadContent {
  IMPORT_PRODUCTS = "IMPORT_PRODUCTS",
  DOWNLOAD_TEMPLATE = "DOWNLOAD_TEMPLATE",
  GET_HELP = "GET_HELP",
  PRODUCTS_IMPORTED_SUCCESSFULLY = "PRODUCTS_IMPORTED_SUCCESSFULLY",
  PRODUCT_REPLACEMENT_WARNING = "PRODUCT_REPLACEMENT_WARNING",
  MAKE_FOLLOWING_CHANGES = "MAKE_FOLLOWING_CHANGES",
  SHOW_ALL = "SHOW_ALL",
  DOWNLOAD_REPORT = "DOWNLOAD_REPORT",
  FILE_TYPE = "FILE_TYPE",
  NOTE = "NOTE",
  IMPORTING_PRODUCTS = "IMPORTING_PRODUCTS",
  DOWNLOAD_TEMPLATE_LINK = "DOWNLOAD_TEMPLATE_LINK",
  UPLOAD_FILE = "UPLOAD_FILE",
}
