import React, { FC } from "react";
import { Text } from "common/components/ui";
import { WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { EstimateMap } from "./useReplenishmentOrderShippingStep";
import { getReplenishmentShippingPartnerFromWholesaleType } from "../utils/getReplenishmentShippingPartnerFromWholesaleType";
import { Amount } from "../../fba/shipping/AmazonShipmentType";
import { getReplenishmentShippingTypeFromWholesaleType } from "../utils/getReplenishmentShippingTypeFromWholesaleType";

export interface MultipleShipmentShippingEstimatedTotalProps {
  shippingMethod: WholesaleShippingMethod;
  shippingEstimation: EstimateMap;
}

export const MultipleShipmentShippingEstimatedTotal: FC<MultipleShipmentShippingEstimatedTotalProps> = ({
  shippingMethod,
  shippingEstimation,
}) => {
  const { destinationType, shipmentShippingMethodMap, replenishmentOrder } = useSelector(selectTransferCreate);
  const shipments = replenishmentOrder?.shipments ?? [];
  const shipmentsShippingMethodSelected = shipmentShippingMethodMap[shippingMethod];
  const estimation =
    shippingEstimation[getReplenishmentShippingPartnerFromWholesaleType(shippingMethod, destinationType!)];
  let estimatedTotal;
  if (Object.values(shipmentsShippingMethodSelected).filter(Boolean).length < shipments.length) {
    estimatedTotal = <FormattedMessage id="transfers.create.steps.shipping.multiple.tbd" defaultMessage="TBD" />;
  } else {
    const totalEstimatedCost = shipments.reduce((acc, { shipmentId }) => {
      const selectedShippingType = getReplenishmentShippingTypeFromWholesaleType(
        shipmentsShippingMethodSelected[shipmentId]
      );
      const estimatedCost =
        estimation?.[selectedShippingType]?.shipmentEstimationBreakdown?.[shipmentId]?.totalEstimatedCost ?? 0;
      return estimatedCost + acc;
    }, 0);
    estimatedTotal = <Amount value={totalEstimatedCost} />;
  }
  return (
    <Text bold>
      <FormattedMessage
        id="transfers.create.steps.shipping.multiple.estimatedTotal"
        defaultMessage="Estimated total: {estimatedTotal}"
        values={{ estimatedTotal }}
      />
    </Text>
  );
};
