import React from "react";
import { Pill } from "common/components/ui";
import { OrderPageItem } from "order/listV2/OrderListReducer";
import { useOrderListStatus } from "./useOrderListStatus";

interface OwnProps {
  order: OrderPageItem;
}

export const OrderListStatus = ({ order }: OwnProps) => {
  const { status, color } = useOrderListStatus(order);
  return status ? <Pill color={color}>{status}</Pill> : null;
};
