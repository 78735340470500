// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WithLoader__spinner--gVw4Q {\n  display: block;\n  margin: auto;\n}\n.WithLoader__hidden--uKiIA {\n  visibility: hidden;\n}\n.WithLoader__loaderTextSpinner--fRw55 {\n  margin-right: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/app/common/components/WithLoader/WithLoader.less"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,YAAA;AADF;AAIA;EACE,kBAAA;AAFF;AAKA;EACE,iBAAA;AAHF","sourcesContent":["@import \"~app/common/styles/center\";\n\n.spinner {\n  display: block;\n  margin: auto;\n}\n\n.hidden {\n  visibility: hidden;\n}\n\n.loaderTextSpinner {\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "WithLoader__spinner--gVw4Q",
	"hidden": "WithLoader__hidden--uKiIA",
	"loaderTextSpinner": "WithLoader__loaderTextSpinner--fRw55"
};
export default ___CSS_LOADER_EXPORT___;
