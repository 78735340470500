import { LotInformationError } from "inbounds/steps/lot-information/types/LotInformationError";

// exported for tests only
export const INVALID_ERROR = LotInformationError.LOT_NUMBER_INVALID;

export const validateLotNumber = (lotNumber: string) => {
  // must contain alphanumeric characters, and allows spaces, dashes, and forward slashes
  const pattern = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9_/ -]*$/;
  const isValid = pattern.test(lotNumber);
  return isValid ? undefined : INVALID_ERROR;
};
