import React from "react";
import styled from "@emotion/styled";

import { Box } from "common/components/ui";
import { TextAlign } from "common/components/ui/shared";
import { LocalizedText } from "common/components/LocalizedText";

const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NoProductsSelected = () => {
  return (
    <Container>
      <LocalizedText
        textAlign={TextAlign.center}
        bold
        size="body"
        message={{
          id: "createShipment.cargoDetails.noProductsSelected.title",
          defaultMessage: "Add products",
        }}
      />
      <Box marginTop="S1">
        <LocalizedText
          as="span"
          size="caption"
          message={{
            id: "createShipment.cargoDetails.noProductsSelected.noProducts",
            defaultMessage: "Don't have your product details right now? Use 'By shipment total volume and weight'",
          }}
        />
      </Box>
      <LocalizedText
        textAlign={TextAlign.center}
        as="p"
        size="caption"
        appearance="INFO"
        message={{
          id: "createShipment.cargoDetails.noProductsSelected.addLater",
          defaultMessage: "You can add your product details after placing a booking.",
        }}
      />
    </Container>
  );
};
