import { WarehousePublic } from "@deliverr/business-types";
import { Dictionary } from "lodash";
import { keyBy } from "lodash/fp";

import { DeliverrActionTypes } from "common/deliverr/DeliverrActions";
import { createReducer, ReducerDictionary } from "common/ReduxUtils";

export interface DeliverrState {
  warehouses: Dictionary<WarehousePublic>;
}

export const deliverrInitialState: DeliverrState = {
  warehouses: {},
};

const reducers: ReducerDictionary<DeliverrState> = {
  [DeliverrActionTypes.LOAD_WAREHOUSES]: (state, { warehouses }) => ({
    ...state,
    warehouses: {
      ...state.warehouses,
      ...keyBy("id", warehouses),
    },
  }),
};

export const deliverrReducer = createReducer<DeliverrState>(deliverrInitialState, reducers);
