import React, { FC } from "react";
import { logError } from "Logger";
import { ProblemRowData, RelabeledBoxProblemRow, ShortShipProblemRow } from "common/components/ProblemsTable/types";
import { ProblemType } from "common/problems/ProblemType";
import { Dash } from "../Dash";
import { BoxCountMessage } from "./BoxCountMessage";
import { UnitCountMessage } from "./UnitCountMessage";

export const ProblemUnitsCell: FC<ProblemRowData> = (problem) => {
  const { units, problemType } = problem;

  const getCell = () => {
    switch (problemType) {
      case ProblemType.RELABELED_BOX:
        return <BoxCountMessage boxCount={(problem as RelabeledBoxProblemRow).boxCount} />;
      case ProblemType.SHORT_SHIP_BOXES:
        return <BoxCountMessage boxCount={(problem as ShortShipProblemRow).summary.missingCdskus.length} />;
      case ProblemType.SHORT_SHIP_UNITS:
      case ProblemType.UNKNOWN_BARCODE:
      case ProblemType.MISSING_BARCODE:
      case ProblemType.UNEXPECTED_BARCODE:
      case ProblemType.UNEXPECTED_SKU:
      case ProblemType.MISMATCH_CASE_QTY:
      case ProblemType.DAMAGED_PRODUCT:
      case ProblemType.MISSING_CONTAINER_LABEL:
      case ProblemType.ILLEGIBLE_LOT:
      case ProblemType.ILLEGIBLE_EXPIRATION:
      case ProblemType.EXPIRED_PRODUCT:
      case ProblemType.OTHER_NON_COMPLIANCE:
      case ProblemType.OVER_RECEIVED_UNITS:
        return <UnitCountMessage unitCount={units} />;
      default:
        logError({ fn: "ProblemUnitsCell.getCell", ...problem }, "Unexpected ProblemType");
        return null;
    }
  };

  return units ? <>{getCell()}</> : <Dash />;
};
