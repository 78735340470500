import { useContext } from "react";
import { CreateShipmentContext } from "./CreateShipmentContext";

export const useCreateShipmentContext = () => {
  const context = useContext(CreateShipmentContext);
  if (!context) {
    throw new Error("No CreateShipmentContext.Provider found when calling useCreateShipmentContext.");
  }
  return context;
};
