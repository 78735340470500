import { DeliverrAddress } from "@deliverr/commons-objects";
import { Box, Grid, Text, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import Address from "common/components/Address";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { CARRIER_TYPE_MESSAGES } from "../../selectShipping/steps/selectCarrier/CarrierTypeMessages";
import { CarrierType, ContainerSize, ShipmentType } from "@deliverr/storage-client";

const StyledAddress = styled.div<ThemeProps>(
  ({ theme }) => `
        flex-grow: 1;
        white-space: pre-line;
        height: fit-content;

        .address {
          color: ${theme.colors.NEUTRAL[500]};
        }
      `
);
const StyledProvider = styled.p<ThemeProps>(
  ({ theme }) => `
      margin-bottom: 0;
      color: ${theme.colors.NEUTRAL[500]};
      `
);

interface ShipmentDetailProps {
  skuCount: number;
  caseCount: number;
  unitCount: number;
  warehouseProvider?: string;
  toAddress?: DeliverrAddress;
  numOfPallets?: number;
  cargoType?: ShipmentType;
  containerSize?: ContainerSize;
  fromAddress?: DeliverrAddress;
  freightType?: string;
  carrierType?: CarrierType;
}

export const ShipmentDetail: FC<ShipmentDetailProps> = ({
  skuCount,
  unitCount,
  caseCount,
  toAddress,
  warehouseProvider,
  fromAddress,
  carrierType,
}) => {
  const carrierTitle = carrierType && <FormattedMessage {...CARRIER_TYPE_MESSAGES[carrierType].carrierName} />;

  return (
    <Box width="80%" marginTop="S7" marginBottom="S7">
      <Grid columns={"1fr 1fr 1fr 1fr"} gap="S7">
        <Box>
          <Text bold size="caption">
            <FormattedMessage id="storage.inbounds.create.confirmShipment.content" defaultMessage="Contents" />
          </Text>
          <Text size="body">
            <FormattedMessage
              id="storage.inbounds.create.confirmShipment.content.detail"
              defaultMessage="{skuCount} SKUs {separator} {caseCount} cases {separator} {unitCount} units"
              values={{
                skuCount,
                caseCount,
                unitCount,
                separator: <br />,
              }}
            />
          </Text>
        </Box>
        <Box>
          <Text bold size="caption">
            <FormattedMessage id="storage.inbounds.create.confirmShipment.shippingTo" defaultMessage="Shipping to" />
          </Text>
          <StyledAddress>
            {warehouseProvider && (
              <StyledProvider>
                <FormattedMessage
                  id={"storage.inbounds.create.confirmShipment.shippingTo.provider"}
                  defaultMessage={"{provider} c/o Flexport"}
                  values={{ provider: warehouseProvider }}
                />
              </StyledProvider>
            )}
            {toAddress && <Address address={toAddress} hideName />}
          </StyledAddress>
        </Box>
        {
          <>
            <Box>
              <Text bold size="caption">
                <FormattedMessage
                  id="storage.inbounds.create.confirmShipment.shippingFrom"
                  defaultMessage="Shipping from"
                />
              </Text>
              <StyledAddress>{fromAddress && <Address address={fromAddress} hideName minimalIntl />}</StyledAddress>
            </Box>
            <Box>
              <Text bold size="caption">
                <FormattedMessage id="storage.inbounds.create.confirmShipment.carrier" defaultMessage="Carrier" />
              </Text>
              <Text size="body">{carrierTitle}</Text>
            </Box>
          </>
        }
      </Grid>
    </Box>
  );
};
