import { SPThunkAction, createActionCreator } from "common/ReduxUtils";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";

import { ReturnLoader } from "returns/ReturnsLoader";
import { ReturnsLabelActionTypes } from "./ReturnsLabelStore.types";
import { defineMessages } from "react-intl";
import { returnsClient } from "Clients";

const errorMessage = defineMessages({
  networkError: {
    id: "returns.selectProduct.error",
    defaultMessage: "Network error. Please refresh the page",
  },
});

export const resetReturnLabel = createActionCreator(ReturnsLabelActionTypes.RETURN_LABEL_RESET);

export const getReturnItem =
  (returnId: string): SPThunkAction =>
  async (dispatch) => {
    try {
      dispatch(addLoader(ReturnLoader.ReturnsLabelLoader));
      const returnItem = await returnsClient.getReturnById(returnId);
      dispatch({
        type: ReturnsLabelActionTypes.RETURN_INFO_SUCCESS,
        returnItem: returnItem?.value,
        error: undefined,
      });
    } catch (error) {
      dispatch({
        type: ReturnsLabelActionTypes.RETURN_INFO_FAIL,
        error: errorMessage.networkError,
      });
    } finally {
      dispatch(clearLoader(ReturnLoader.ReturnsLabelLoader));
    }
  };

export const sendShippingLabelEmail =
  (returnId: string, buyerEmail: string): SPThunkAction =>
  async (dispatch) => {
    try {
      await returnsClient.shareLabel({ returnId, buyerEmail });
      dispatch({
        type: ReturnsLabelActionTypes.SHARE_RETURN_LABEL_SUCCESS,
        shareLabelStatus: true,
        email: buyerEmail,
      });
    } catch (error) {
      dispatch({
        type: ReturnsLabelActionTypes.SHARE_RETURN_LABEL_FAIL,
        error: errorMessage.networkError,
      });
    }
  };
