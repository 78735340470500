import { ParcelTrackingEvent } from "@deliverr/parcel-client";
import { ParcelEvent } from "../events/types/ParcelEvent";

export const convertParcelTrackingEventToParcelEvent = (parcelTrackingEvents: ParcelTrackingEvent[]): ParcelEvent[] => {
  return parcelTrackingEvents.map((event) => ({
    type: event.eventType,
    time: event.eventTime,
    message: event.message,
    location: event.location,
    shippingMethod: event.shippingMethod,
  }));
};
