import React from "react";
import { UpdateCarrierAccountRequest } from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { SPThunkAction } from "common/ReduxUtils";
import { logError } from "Logger";
import { batch } from "react-redux";
import { transferCreateSetSelectedCarrierAccount, transferCreateUpdateCarrierAccount } from "transfers/create/actions";
import { ReplenishmentCarrierAccountLoader } from "../ReplenishmentCarrierAccountLoader";
import { toast } from "react-toastify";
import { FormattedMessage } from "common/components/ui/FormattedMessage";

export const updateReplenishmentCarrierAccount =
  (carrierAccountId: number, updateCarrierAccountRequest: UpdateCarrierAccountRequest): SPThunkAction<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch(addLoader(ReplenishmentCarrierAccountLoader.CarrierModalButtonLoader));
      const updatedCarrierAccount = (
        await replenishmentClient.updateCarrierAccountById(carrierAccountId, updateCarrierAccountRequest)
      ).value;
      batch(() => {
        dispatch(transferCreateUpdateCarrierAccount(updatedCarrierAccount));
        dispatch(transferCreateSetSelectedCarrierAccount(updatedCarrierAccount));
      });
    } catch (err) {
      logError(
        { fn: "updateReplenishmentCarrierAccount", updateCarrierAccountRequest },
        err,
        "Error updating carrier account"
      );
      toast.error(
        err.message ?? (
          <FormattedMessage
            id="updateReplenishmentCarrierAccount.handleSave.error"
            defaultMessage="An error occurred while updating the carrier account. Please try again."
          />
        )
      );
    } finally {
      dispatch(clearLoader(ReplenishmentCarrierAccountLoader.CarrierModalButtonLoader));
    }
  };
