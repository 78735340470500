import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { stopPropagation } from "common/EventUtils";
import {
  ClickableIconContainer,
  NavbarItemName,
  NavbarItemNameContainer,
  NavbarItemNameInput,
} from "./NavbarEditableItem.styles";
import cx from "classnames/dedupe";
import { useNavbarEditableItem } from "./useNavbarEditableItem";

interface TestIdProps {
  nameWrapper: string;
  nameInput: string;
}

export interface NavbarEditableItemProps {
  displayItemValue: string;
  handleSubmit: (editableItemValue: string) => void;
  testId?: TestIdProps;
  isLoading?: boolean;
}

export const NavbarEditableItem: FC<NavbarEditableItemProps> = ({
  displayItemValue,
  handleSubmit,
  testId,
  isLoading,
}) => {
  const { isEditing, editableItemValue, handleFormSubmit, handleInputBlur, handleItemClick, handleInputChange } =
    useNavbarEditableItem({ displayItemValue, handleSubmit });

  return isEditing ? (
    <NavbarItemNameContainer>
      <form onClick={stopPropagation} onSubmit={handleFormSubmit}>
        <NavbarItemNameInput
          data-testid={testId?.nameInput}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          type="text"
          value={editableItemValue}
          autoFocus
        />
        <ClickableIconContainer>
          <FontAwesomeIcon icon="check" />
        </ClickableIconContainer>
      </form>
    </NavbarItemNameContainer>
  ) : (
    <NavbarItemNameContainer
      data-testid={testId?.nameWrapper}
      onClick={handleItemClick}
      // we hide the container to not display a misaligned edit icon while the display name is loaded
      className={cx("editable", { hidden: !isLoading && !displayItemValue })}
    >
      {isLoading ? (
        <FontAwesomeIcon icon="circle-notch" spin size="sm" />
      ) : (
        <>
          <NavbarItemName title={editableItemValue}>{editableItemValue}</NavbarItemName>
          <ClickableIconContainer>
            <FontAwesomeIcon icon="pencil-alt" />
          </ClickableIconContainer>
        </>
      )}
    </NavbarItemNameContainer>
  );
};
