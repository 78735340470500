import { useHistory } from "react-router-dom";

/**
 * When opening or closing a view on the same page via a route change (e.g. a modal or blade),
 * we want to prevent the default scroll to top behavior, so the user doesn't lose their spot on the page.
 */
export const usePreventScrollToTopNavigation = () => {
  const history = useHistory();

  const navigate = (path: string, state?: { [x: string]: any }) =>
    history.push(path, { ...state, preventScrollToTop: true });

  return navigate;
};
