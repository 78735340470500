import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getSelectedProducts } from "storage/inbounds/create/store/selector/getSelectedProducts";
import { useShipmentTotalsValidation } from "./useShipmentTotalsValidation";
import { SingleSkuPackageCollection } from "inbounds/createShipment/CreateShipmentTypes";
import { hasInvalidSingleSkuPackages } from "./hasInvalidSingleSkuPackages";
import { selectHasInvalidSelectedProducts } from "storage/inbounds/create/store/selector/selectHasInvalidSelectedProducts";

export const ERROR_MESSAGES = defineMessages({
  missingProducts: {
    defaultMessage: "Please select at least one product.",
    id: "inbounds.createShipment.cargoDetails.missingProducts",
  },
  missingCasePacks: {
    defaultMessage: "Please enter case pack templates for all products.",
    id: "inbounds.createShipment.cargoDetails.missingCasePacks",
  },
  missingTotals: {
    defaultMessage: "Please enter all shipment totals.",
    id: "inbounds.createShipment.cargoDetails.missingTotals",
  },
  missingDtcPackages: {
    defaultMessage: "Please enter all package information.",
    id: "inbounds.createShipment.cargoDetails.missingDtcPackages",
  },
});

export const useProductChooserValidation = (
  isStepValidated: boolean,
  isIncompletedPackages: boolean,
  singleSkuPackages: SingleSkuPackageCollection,
  isSingleSkuDtcBooking: boolean = false,
  isIntlDtc: boolean
) => {
  const { formatMessage } = useIntl();
  const { missingTotalsError } = useShipmentTotalsValidation({ omitPallets: true });
  const isCasePackRequired = isSingleSkuDtcBooking || !isIntlDtc;
  const hasSelectedProducts = useSelector(getSelectedProducts).length > 0;

  const missingProductsError =
    !hasSelectedProducts && isStepValidated ? formatMessage(ERROR_MESSAGES.missingProducts) : undefined;

  const hasInvalidSelectedProducts = useSelector(selectHasInvalidSelectedProducts);
  const hasInvalidProducts = isSingleSkuDtcBooking
    ? hasInvalidSingleSkuPackages(singleSkuPackages)
    : hasInvalidSelectedProducts;
  const missingCasePacksError =
    hasInvalidProducts && isStepValidated && isCasePackRequired
      ? formatMessage(ERROR_MESSAGES.missingCasePacks)
      : undefined;

  const missingDtcPackagesError =
    isIntlDtc && isIncompletedPackages ? formatMessage(ERROR_MESSAGES.missingDtcPackages) : undefined;

  const currentError = missingProductsError ?? missingCasePacksError ?? missingTotalsError ?? missingDtcPackagesError;

  return {
    hasSelectedProducts,
    hasError: !!currentError,
    errorMessage: currentError,
  };
};
