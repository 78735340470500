import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { ManagedStep } from "common/components/StepContainer";
import { AddressInput } from "common/components/AddressInput";
import { wholesaleCreateLabels } from "../wholesaleCreate.labels";
import { emptyAddress } from "common/AddressUtils";
import { selectWholesaleCreate } from "../store/WholesaleCreateSelectors";
import { useSelector } from "react-redux";
import { Box, Input, ThemeProps, Notification } from "common/components/ui";
import styled from "@emotion/styled";
import { useQueryAddressValidity } from "./useQueryAddressValidity";
import { isValidCompany, safeIsValidFreightPhoneNumber } from "common/wholesale-common/utils/wholesale-utils";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { FeatureName, useFeatureOn } from "common/Split";
import { emailRegexPattern } from "common/regex";

const HorizontalLine = styled.div<ThemeProps>(
  ({ theme }) => `
  border-top: solid 1px ${theme.colors.NEUTRAL["100"]};
  margin-top: ${theme.spacing.S4};
  padding-top: ${theme.spacing.S5};
`
);

export const WholesaleSelectAddressStep = () => {
  const { formatMessage } = useIntl();

  const { destinationAddress, poNumber } = useSelector(selectWholesaleCreate); // Temp till we rollout fully to Replenishment
  const { destinationAddress: destinationAddressV2, poNumber: poNumberV2 } = useSelector(selectTransferCreate);
  const isNewListPage = useFeatureOn(FeatureName.WholesaleViewV2);
  let defaultDestAddress = destinationAddress ?? emptyAddress;
  if (isNewListPage && destinationAddressV2) {
    defaultDestAddress = destinationAddressV2;
  }
  const [address, setAddress] = useState(defaultDestAddress);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [inputPoNumber, setPoNumber] = useState(isNewListPage ? poNumberV2 : poNumber);
  const { isValidAddress, isAddressValidationLoading } = useQueryAddressValidity(address);
  const isValidCompanyName = useMemo(() => isValidCompany(address.company), [address.company]);
  const isValidEmail = useMemo(() => (address.email ? emailRegexPattern.test(address.email) : true), [address.email]);

  return (
    <ManagedStep
      data-testid="create-wholesale-address-step"
      title={formatMessage({ ...wholesaleCreateLabels.steps.address.title })}
      isNextDisabled={isAddressValidationLoading || !isValidAddress || !isValidCompanyName || !isValidEmail}
      nextData={() => ({
        address,
        poNumber: inputPoNumber,
      })}
    >
      <Box>
        <AddressInput
          onChange={setAddress}
          address={address}
          countryAsSelect
          countryOptions="ALL"
          showName={false}
          enableAddressAutocomplete
          hideCompany={false}
          showPhone={true}
          showEmail
          disableCountrySelect={false}
          onPhoneNumberChange={(phoneNumber: string) => {
            setIsValidPhoneNumber(safeIsValidFreightPhoneNumber(phoneNumber));
          }}
          isValidPhoneNumber={isValidPhoneNumber}
        />

        {address !== emptyAddress && !isValidAddress && (
          <Notification
            appearance="DANGER"
            content={{
              description: formatMessage({ ...wholesaleCreateLabels.steps.address.validationError }),
            }}
          />
        )}

        {address !== emptyAddress && isValidAddress && !isValidCompanyName && (
          <Notification
            appearance="DANGER"
            content={{
              description: formatMessage({ ...wholesaleCreateLabels.steps.address.companyNameError }),
            }}
          />
        )}

        <HorizontalLine />
        <Input
          placeholder={formatMessage({ ...wholesaleCreateLabels.steps.address.poNumberPlaceholder })}
          value={inputPoNumber ?? ""}
          onChange={(evt) => setPoNumber(evt.currentTarget.value)}
        />
      </Box>
    </ManagedStep>
  );
};
