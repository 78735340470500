import React, { FC } from "react";
import styled from "@emotion/styled";
import { ThemeProps } from "../shared";
import { Text } from "../Text";
import { ButtonIcon } from "../ButtonIcon";
import { MiddleEllipsis } from "../MiddleEllipsis";
import { Container, FullWidthContainer, DropzoneLoading, DropzoneProps } from "./DropzoneUtils";
import { FilePreview } from "./FilePreview";
import { Stack } from "../Stack";
import { Grid } from "../Grid";
import { isMobile } from "../shared/helpers/utils";
import { Anchor } from "../Anchor";
import { HelpText } from "../FormGroup";

export const FileContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: ${theme.border.radius.R4};
  border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[100]};
  overflow: hidden;
`
);

const FileInner = styled(Grid)`
  align-items: center;
`;

const FileDesc = styled.div<ThemeProps>(
  ({ theme }) => `
  width: 100%;
  padding: ${theme.spacing.S5} ${theme.spacing.S5} ${theme.spacing.S5} 0;
  font-size: ${isMobile(theme) ? theme.font.size.F4 : theme.font.size.F3};
  word-break: break-all;
`
);

const ProgressBar = styled.div<ThemeProps<{ percent: number }>>(
  ({ percent, theme }) => `
    width: 100%;
    height: ${theme.spacing.S2};
    border-radius: ${theme.border.radius.R4};
    background: linear-gradient(90deg, ${theme.colors.GREEN[300]} ${percent}%, ${theme.colors.NEUTRAL[60]} ${percent}%);
    margin-top: ${theme.spacing.S3};
  `
);

// How many characters to show at the end of an ellipsis if length is too long for available space.
const END_CHAR_LENGTH = 7;

const Content = ({ progressPercent, loading, onFileClear }) => {
  if (progressPercent !== undefined) {
    return <Text>{progressPercent}%</Text>;
  }
  if (loading) {
    return <DropzoneLoading />;
  }
  return <ButtonIcon size="SM" iconType="times" aria-label="Clear" appearance="INFO" onClick={onFileClear} />;
};

const AnchorOrText: FC<Pick<Required<DropzoneProps>["value"], "externalUrl">> = ({ externalUrl, children }) => {
  return externalUrl ? <Anchor href={externalUrl}>{children}</Anchor> : <>{children}</>;
};

export const DropzoneView = ({
  label,
  isMandatory,
  helpText,
  loading,
  onFileClear,
  value,
  progressPercent,
  fullWidth,
}) => {
  const Wrapper = fullWidth ? FullWidthContainer : Container;
  return (
    <Stack gap="S2">
      {label && (
        <Text>
          {label}
          {isMandatory && (
            <Text as="span" appearance="DANGER">
              &nbsp; *
            </Text>
          )}
        </Text>
      )}
      <Wrapper data-testid="dropzoneview-wrapper">
        <FileContainer>
          <FilePreview url={value.url} />
          <FileDesc>
            <FileInner columns="1fr auto" fullWidth>
              <AnchorOrText externalUrl={value.externalUrl}>
                <MiddleEllipsis text={value.fileName} endCharLength={END_CHAR_LENGTH} fontSize="14px" />
              </AnchorOrText>
              <Content loading={loading} progressPercent={progressPercent} onFileClear={onFileClear} />
            </FileInner>
            {progressPercent !== undefined && <ProgressBar percent={progressPercent} />}
          </FileDesc>
        </FileContainer>
      </Wrapper>
      {helpText && <HelpText>{helpText}</HelpText>}
    </Stack>
  );
};
