import { InvalidOrderItemDto } from "@deliverr/replenishment-client";
import { selectAnyIsLoading } from "common/store/selectors/selectAnyIsLoading";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import {
  areAllItemsRemovedFromInvalidItemOrder,
  areAllItemsResolvedFromInvalidItemOrder,
} from "./InvalidItemOrderUtils";
import { selectAugmentedInvalidOrderItems } from "transfers/create/store/selectAugmentedInvalidOrderItems";

export const useReplenishmentInvalidOrderItems = () => {
  const { formatMessage } = useIntl();
  const [isBladeOpen, setBladeOpen] = useState(false);
  const [invalidItem, setInvalidItem] = useState<InvalidOrderItemDto | undefined>(undefined);

  const { replenishmentOrder, unifiedProductResponseCache } = useSelector(selectTransferCreate);
  const isLoading = useSelector(selectAnyIsLoading)(CreateTransferLoader.CreateTransferOrderLoader);
  const augmentedInvalidOrderItems = useSelector(selectAugmentedInvalidOrderItems);

  const areAllOrderItemsRemoved = areAllItemsRemovedFromInvalidItemOrder(replenishmentOrder!);
  const areAllOrderItemsResolved = areAllItemsResolvedFromInvalidItemOrder(replenishmentOrder!);
  const isNextDisabled = !areAllOrderItemsResolved || areAllOrderItemsRemoved || isLoading;

  const onRowClick: (item: InvalidOrderItemDto) => void = (item) => {
    setInvalidItem(item);
    setBladeOpen(true);
  };
  return {
    formatMessage,
    isBladeOpen,
    invalidItem,
    setBladeOpen,
    setInvalidItem,
    replenishmentOrder,
    unifiedProductResponseCache,
    areAllOrderItemsRemoved,
    isLoading,
    onRowClick,
    isNextDisabled,
    augmentedInvalidOrderItems,
  };
};
