import {
  WholesaleItem,
  WholesaleOrderDTO,
  WholesaleShipmentAttributeResponse,
  WholesaleShipmentDTO,
  WholesaleShipmentType,
  WholesaleShippingMethod,
  WholesaleSingleShipmentCreationRequestDTO,
} from "@deliverr/wholesale-client";
import { StorageWarehouseInventory } from "common/clients/storage/StorageWarehouseInventory";
import { SelectedProductData } from "common/components/ProductChooser";
import { filter, isEmpty } from "lodash";
import { mapProductsToMultiPackSkuInventory } from "storage/packUtils/mapProductsToMultiPackSkuInventory";
import { mapProductsToOrderItems } from "transfers/create/steps/common/mapProductsToOrderItems";

export const createWholesaleOrderShipment = (
  wholesaleOrder: WholesaleOrderDTO,
  selectedProductsQty: SelectedProductData[],
  shippingMethod?: WholesaleShippingMethod,
  cargoType?: WholesaleShipmentType,
  fbaShipmentId?: string,
  fbaReferenceId?: string,
  originStorageWarehouse?: StorageWarehouseInventory,
  isMultiPackEnabled?: boolean
): WholesaleSingleShipmentCreationRequestDTO => {
  if (isEmpty(wholesaleOrder.shipments)) {
    return {} as WholesaleSingleShipmentCreationRequestDTO;
  }

  let shipmentItems: WholesaleItem[];

  if (isMultiPackEnabled) {
    const inventoryBreakdown = mapProductsToMultiPackSkuInventory(
      selectedProductsQty,
      originStorageWarehouse?.inventoryBreakdown ?? {}
    );
    shipmentItems = inventoryBreakdown.map(mapProductsToOrderItems);
  } else {
    shipmentItems = selectedProductsQty.map(mapProductsToOrderItems);
  }

  const shipmentResponse = wholesaleOrder.shipments![0];
  const shipment: WholesaleSingleShipmentCreationRequestDTO = {
    originWarehouseId: shipmentResponse.originWarehouseId,
    shipmentItems,
    warehouseOrderType: shipmentResponse.warehouseOrderType,
    shipmentType: cargoType ?? shipmentResponse.shipmentType,
    inputDestinationAddress: wholesaleOrder.inputDestination,
    carrierInfo: {
      ...shipmentResponse.carrierInfo,
      shippingMethod: shippingMethod ?? WholesaleShippingMethod.DELIVERR,
    },
  };

  return {
    ...shipment,
    externalAttributes: getShipmentExternalAttributes(shipmentResponse, fbaShipmentId, fbaReferenceId),
  };
};

const getShipmentExternalAttributes = (
  shipment: WholesaleShipmentDTO,
  fbaShipmentId?: string,
  fbaReferenceId?: string
): WholesaleShipmentAttributeResponse[] => {
  const attributes = filter(
    shipment.externalAttributes,
    (attr) => attr.attributeName !== "shipmentId" && attr.attributeName !== "referenceId"
  );

  if (fbaShipmentId) {
    attributes.push({
      attributeName: "shipmentId",
      attributeValue: fbaShipmentId,
    });
  }

  if (fbaReferenceId) {
    attributes.push({
      attributeName: "referenceId",
      attributeValue: fbaReferenceId,
    });
  }
  return attributes;
};
