import { Currency, CurrencyFormat } from "common/components/Currency";
import { DefaultTheme, Divider, Icon, IconsProps, defaultTheme } from "common/components/ui";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";
import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import { ReturnItemMinimal } from "@deliverr/returns-client";
import { ReturnsFee } from "./ReturnsFee";
import { TooltipWithIcon } from "common/components/icons/TooltipWithIcon";
import { getNumberOfItems } from "returns/utils/returnsCost";
import { isNil } from "lodash";
import { isQcEnabled } from "returns/utils/qcDetails";
import styled from "@emotion/styled";

const TooltipContainer = styled.div`
  width: 11rem;
`;

const SpacedIcon = styled(Icon)<IconsProps, DefaultTheme>(
  ({ theme }) => `
  margin-left: ${theme.spacing.S1};
`
);

export const TooltipLineItem = styled.div`
  display: flex;
  justify-content: space-between;
`;
interface ReturnsCostProps {
  shippingFeeCharged?: number;
  inspectionFeeCharged?: number;
  estimatedTotalCost?: number;
  qcId?: string;
  rmtId?: string;
  returnItems?: ReturnItemMinimal[];
  estimatedLabelCost?: number;
}

const getInspectionRate = (returnItems?: ReturnItemMinimal[]) =>
  returnItems && returnItems.length > 0 ? returnItems[0].inspectionRate : null;

export const ReturnsCost: FC<ReturnsCostProps> = ({
  estimatedTotalCost,
  inspectionFeeCharged,
  shippingFeeCharged,
  qcId = "Self",
  rmtId = "deliverr",
  estimatedLabelCost,
  returnItems,
}) => {
  const totalReturnItems = getNumberOfItems(returnItems);
  const inspectionRate = getInspectionRate(returnItems);
  const isQcOpted = isQcEnabled(qcId);
  const isCharged = isQcOpted ? !isNil(inspectionFeeCharged) : !isNil(shippingFeeCharged);
  const totalFee = isCharged ? (inspectionFeeCharged ?? 0) + (shippingFeeCharged ?? 0) : estimatedTotalCost;
  return (
    <>
      <Currency currency={CurrencyFormat.USD} value={totalFee} />
      {
        <TooltipWithIcon
          placement="top"
          message={
            <TooltipContainer>
              {isQcOpted ? (
                isCharged ? (
                  <>
                    {!isNil(shippingFeeCharged) ? (
                      <ReturnsFee
                        returnsFee={shippingFeeCharged}
                        messageDescriptor={{
                          id: "returns.returnsList.returnsFee.shippingFeeChargedAndQcOpted",
                          defaultMessage: "Return label",
                        }}
                      />
                    ) : null}
                    {!isNil(inspectionFeeCharged) ? (
                      <ReturnsFee
                        returnsFee={inspectionFeeCharged}
                        messageDescriptor={{
                          id: "returns.returnsList.returnsFee.inspectionFeeChargedAndQcOpted",
                          defaultMessage: "Inspection fee",
                        }}
                      />
                    ) : null}
                    <Divider />
                    {
                      <ReturnsFee
                        returnsFee={(inspectionFeeCharged ?? 0) + (shippingFeeCharged ?? 0)}
                        messageDescriptor={{
                          id: "returns.returnsList.returnsFee.totalFeeChargedAndQcOpted",
                          defaultMessage: "Total",
                        }}
                      />
                    }
                  </>
                ) : (
                  <>
                    {!isNil(estimatedLabelCost) && estimatedLabelCost > 0 ? (
                      <ReturnsFee
                        returnsFee={estimatedLabelCost}
                        messageDescriptor={{
                          id: "returns.returnsList.returnsFee.estimatedShippingFee",
                          defaultMessage: "Return label",
                        }}
                      />
                    ) : null}
                    {!isNil(inspectionRate) ? (
                      <ReturnsFee
                        returnsFee={inspectionRate * totalReturnItems}
                        messageDescriptor={{
                          id: "returns.returnsList.returnsFee.estimatedInspectionFee",
                          defaultMessage: "Inspection fee",
                        }}
                      />
                    ) : null}

                    {!isNil(inspectionRate) ? <Divider /> : null}
                    {
                      <ReturnsFee
                        returnsFee={estimatedTotalCost ?? 0}
                        messageDescriptor={{
                          id: "returns.returnsList.returnsFee.estimatedTotalCost",
                          defaultMessage: "Estimated total",
                        }}
                      />
                    }
                    <br />
                    {rmtId === "deliverr" ? (
                      <>
                        <FormattedMessage
                          id={"returns.returnsList.qcWithLabelText"}
                          defaultMessage={
                            "You will be charged for the label when it is scanned by the carrier. The inspection fee will be charged once inspection is complete for each unit."
                          }
                        />
                        <ElevioBorderlessButton articleId={ElevioArticleId.ReturnsPricingPolicy}>
                          <FormattedMessage id="helpCenter" defaultMessage="Learn more" />
                        </ElevioBorderlessButton>
                      </>
                    ) : (
                      <>
                        <FormattedMessage
                          id={"returns.returnsList.qcWithoutLabelText"}
                          defaultMessage={
                            "The inspection fee will be charged once inspection is complete for each unit."
                          }
                        />
                        <ElevioBorderlessButton articleId={ElevioArticleId.ReturnsPricingPolicy}>
                          <FormattedMessage id="helpCenter" defaultMessage="Learn more" />
                        </ElevioBorderlessButton>
                      </>
                    )}
                  </>
                )
              ) : (
                <>
                  <FormattedMessage
                    id={"returns.returnsList.noQcFeeText"}
                    defaultMessage={"You will be charged for the label when it is scanned by the carrier."}
                  />
                  <ElevioBorderlessButton articleId={ElevioArticleId.ReturnsPricingPolicy}>
                    <FormattedMessage id="helpCenter" defaultMessage="Learn more" />
                  </ElevioBorderlessButton>
                </>
              )}
            </TooltipContainer>
          }
        >
          <SpacedIcon type="info-circle-alt" color={defaultTheme.colors.NEUTRAL[200]} />
        </TooltipWithIcon>
      }
    </>
  );
};
