import { BasicTableColumn, useMultiTableAggregator } from "common/components/BasicTable";
import { CostTableSubTableConfig } from "./CostTableTypes";

export interface UseCostTableAggregatorProps {
  subTables: CostTableSubTableConfig[];
  hideAggregatedRows?: boolean;
}

/**
 * Given an array of sub table props, aggregates the data within them
 * and outputs the the newly aggregated tables and the total aggregation between each table
 */
export const useCostTableAggregator = ({ subTables, hideAggregatedRows }: UseCostTableAggregatorProps) => {
  const tablesForAgg = subTables.map(({ data, aggregatorOptions }) => {
    const { totalRowLabel, ...options } = aggregatorOptions;

    return {
      rows: data,
      options: {
        ...options,
        keys: ["amount"],
        initialData: { rowLabel: totalRowLabel },
      },
    };
  });

  const { aggregatedTableData, totalAggregation } = useMultiTableAggregator<BasicTableColumn>(tablesForAgg, "amount");

  // assemble aggregated data with table configs
  // hide aggregated subtotal rows for Metro P&D table
  const aggregatedSubTables = aggregatedTableData.map(({ rows }, tableIndex) => ({
    ...subTables[tableIndex],
    data: hideAggregatedRows ? rows.slice(0, -1) : rows,
  }));

  return {
    subTables: aggregatedSubTables,
    totalAggregation,
  };
};
