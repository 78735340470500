import React from "react";
import { connect } from "react-redux";
import Select from "@deliverr/react-select-v1";

import { updateResultsPerPage } from "common/components/lists/Table/ResultsPerPageActions";
import { Thunk } from "common/ReduxUtils";
import { RootState } from "RootReducer";

interface ReduxProps {
  size: number;
}

interface DispatchProps {
  updateResultsPerPage: Thunk;
}

interface OwnProps {
  page: string;
  maxSize: 10 | 25 | 50 | 100 | 150 | 200;
  className?: string;
}

class UnconnectedResultsPerPageSelector extends React.Component<ReduxProps & DispatchProps & OwnProps, {}> {
  public SIZES = [10, 25, 50, 100, 150, 200];

  public realSizes = this.SIZES.filter((size) => size <= this.props.maxSize);

  public onChange = async ({ value: resultsPerPage }) =>
    this.props.updateResultsPerPage(this.props.page, resultsPerPage);

  public render() {
    return (
      <Select
        searchable={false}
        className={this.props.className}
        clearable={false}
        options={this.realSizes.map((size) => ({
          value: size,
          label: `${size} results per page`,
        }))}
        value={this.props.size}
        onChange={this.onChange}
      />
    );
  }
}

export const ResultsPerPageSelector = connect<ReduxProps, DispatchProps, OwnProps>(
  (state: RootState, { page }): ReduxProps => ({
    size: state.user.resultsPerPage[page] || 25,
  }),
  {
    updateResultsPerPage,
  }
)(UnconnectedResultsPerPageSelector);
