import { noop } from "lodash";
import { createContext } from "react";
import { SellerProblemOverview, ShippingPlan } from "@deliverr/legacy-inbound-client";
import { ProductListItem } from "inventory/list/InventoryListReducer";
import { useStepMetadata } from "inbounds/hooks/useStepMetadata";

export interface UnknownBarcodeCaseContextData {
  ncProduct?: SellerProblemOverview;
  isNCProductLoading: boolean;
  shippingPlan?: ShippingPlan;
  isRecognizedProduct: boolean;
  setIsRecognizedProduct: (changeEvent: React.ChangeEvent<HTMLInputElement>) => void;
  foundProduct?: ProductListItem;
  onProductSelect: (inventoryListItem?: ProductListItem) => void;
  onDismiss: () => void;
  isOnDismissLoading: boolean;
  onContinue: () => void;
  onSubmit: () => void;
  isOnSubmitLoading: boolean;
  stepMetadata: ReturnType<typeof useStepMetadata>;
}

export const INITIAL_UNKNOWN_BARCODE_CASE_CONTEXT: UnknownBarcodeCaseContextData = {
  isNCProductLoading: true,
  onProductSelect: noop,
  onDismiss: noop,
  isOnDismissLoading: false,
  onContinue: noop,
  onSubmit: noop,
  isOnSubmitLoading: false,
  isRecognizedProduct: true,
  setIsRecognizedProduct: noop,
  stepMetadata: {
    stepAccordions: [],
    setStepIsValid: noop,
    openSteps: {},
    toggleOpen: noop,
  },
};

export const UnknownBarcodeCaseContext = createContext<UnknownBarcodeCaseContextData>(
  INITIAL_UNKNOWN_BARCODE_CASE_CONTEXT
);
