import { commonMsgs } from "inbounds/utils/commonMsgs";
import { fetchCasePackTotalCaseQty } from "prep/store/selectors/fetchCasePackTotalCaseQty";
import { selectPrepCreationSource } from "prep/store/selectors/selectPrepCreationSource";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

export const usePrepBoxQuantities = () => {
  const { formatMessage } = useIntl();
  const source = useSelector(selectPrepCreationSource);
  const numEstimatedBoxes = useSelector(fetchCasePackTotalCaseQty(source));

  const hasCasePacksForEstimate = numEstimatedBoxes > 0;

  const boxesCountPluralized = formatMessage(
    hasCasePacksForEstimate ? commonMsgs.box : commonMsgs.valueToBeDetermined,
    {
      boxesQty: numEstimatedBoxes,
    }
  );

  return {
    numEstimatedBoxes,
    boxesCountPluralized,
    hasCasePacksForEstimate,
  };
};
