import React from "react";
import { ControlledNumberInput } from "common/components/reactHookForm";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { FormattedMessage } from "react-intl";
import { Direction, ThemeProps } from "common/components/ui/shared";
import { Stack } from "common/components/ui";
import { FieldArrayWithId } from "react-hook-form";
import { CreateShipmentFormState } from "inbounds/createShipment/store";
import styled from "@emotion/styled";
import { DtcMixedSkuInputDimValues, getInputName } from "./DtcMixedSkuInputValues";

const StyledContainer = styled.section<ThemeProps>(
  ({ theme }) => `
  align-self: center;
  padding-top: ${theme.spacing.S4};
  margin-top: ${theme.spacing.S1};
`
);

const NumberOfBoxesInputLabel = () => (
  <FormattedMessage id="createShipment.dtc.cargoDetailStep.mixedSku.boxWeight" defaultMessage="Box weight" />
);

export const DtcMixedSkuBoxWeightInput = ({
  pkg,
  index,
}: {
  pkg: FieldArrayWithId<CreateShipmentFormState, CreateShipmentInputName.MIXED_SKU_PACKAGES, "id">;
  index: number;
}) => (
  <Stack direction={Direction.HORIZONTAL}>
    <ControlledNumberInput
      name={getInputName(index, DtcMixedSkuInputDimValues.WEIGHT)}
      label={<NumberOfBoxesInputLabel />}
      decimalScale={2}
      min={0}
      inputSize="medium"
      placeholder="weight"
      defaultValue={pkg.weight}
      required
    />{" "}
    <StyledContainer>
      <FormattedMessage id="createShipment.dtc.cargoDetailStep.mixedSku.boxWeightUnit" defaultMessage="lb" />
    </StyledContainer>
  </Stack>
);
