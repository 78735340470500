import React from "react";

/** Use this in react-intl's values prop to markup i18n text
 * Example:
 * <FormattedMessage
 *   id="inventory.something"
 *   defaultMessage="There are <b>not</b> {quantity} boxes."
 *   values={reactIntlRichTextEnhancer({ quantity: 12 })}
 * />
 * pass an empty object if there are no additional values
 */
export const reactIntlRichTextEnhancer = (values: Record<string, React.ReactNode> = {}) => ({
  ...values,
  b: (text: string) => <b>{text}</b>,
});
