import { userClient } from "common/clients/instances";
import { IntegrationSetupContextValue } from "common/components/channel-integration";
import { getItemFromLocalStorageAndParse, LocalStorageKey } from "common/LocalStorage";
import { logError, logStart } from "Logger";
import { Path } from "paths";

export const ONBOARDING_SURVEY_NAME = "onboarding";
export const ONBOARDING_SURVEY_VERSION = 1;

export const getOnboardingFirstStepPath = async (createCatalogPath: string): Promise<string> => {
  const ctx = logStart({ fn: "getOnboardingFirstStepPath" });
  try {
    await userClient.getSurveyResponse(ONBOARDING_SURVEY_NAME, ONBOARDING_SURVEY_VERSION);
  } catch (err) {
    if (err.code === "SURVEY_NOT_FOUND") {
      return Path.onboardingSurvey;
    }
    logError(ctx, err, "error fetching onboarding survey");
  }
  return createCatalogPath;
};

export const getIntegrationSetupContextFromLocalStorage = (): IntegrationSetupContextValue | undefined =>
  getItemFromLocalStorageAndParse<IntegrationSetupContextValue>(LocalStorageKey.IntegrationSetupContext);
