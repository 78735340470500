import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { LoadingSpinner } from "../LoadingSpinner";
import { FormGroup, StyledInput, extractFormProps } from "../ui";
import {
  LoadingSpinnerContainer,
  placesAutoCompleteComponentStyles,
  PlacesAutocompleteContainer,
} from "./AddressInput.styles";

interface PlacesAutocompleteProps extends React.ComponentProps<typeof PlacesAutocomplete> {
  isLoading?: boolean;
}

const IntlAutocompleteItem = ({ suggestion }) =>
  !(suggestion.includes("US") || suggestion.includes("United States")) ? (
    <div>
      <i className="fa fa-map-marker" />
      {suggestion}
    </div>
  ) : null;

const PlacesAutocompleteStyleForwarder = ({ className, isLoading, ...props }: PlacesAutocompleteProps) => {
  const [formProps, inputProps] = extractFormProps(props);
  return (
    <FormGroup {...formProps}>
      <PlacesAutocompleteContainer>
        {isLoading && (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}
        <PlacesAutocomplete
          styles={placesAutoCompleteComponentStyles}
          classNames={{
            input: className,
          }}
          autocompleteItem={props.isIntl === true ? IntlAutocompleteItem : undefined}
          {...inputProps}
        />
      </PlacesAutocompleteContainer>
    </FormGroup>
  );
};

// passes the className with Input styles
export const StyledPlacesAutocomplete = StyledInput.withComponent(
  PlacesAutocompleteStyleForwarder as any
) as React.FC<PlacesAutocompleteProps>;
