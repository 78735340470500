import { Product } from "@deliverr/commons-clients";

export const hasValidDimensionsAndWeight = (product?: Partial<Product>) => {
  const length = product?.length ?? 0;
  const width = product?.width ?? 0;
  const height = product?.height ?? 0;
  const weight = product?.weight ?? 0;

  return length > 1 && width > 1 && height > 1 && weight > 0;
};
