import React, { FC } from "react";
import { DefaultTheme, SelectComponents, Text, Icon, defaultTheme } from "common/components/ui";
import { MenuListProps } from "@deliverr/react-select-v1";
import { css } from "emotion";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";

const CreateProductOption = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    border-top: thin solid ${theme.colors.NEUTRAL[40]};
    text-align: left;

    > div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  `
);
const Help = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    border-top: thin solid ${theme.colors.NEUTRAL[40]};
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
  `
);

export const ProductSearchMenuList: FC<MenuListProps> = ({ children, ...props }) => {
  const optionClassName = css(props?.getStyles("option", props) || {});
  const inputValue = props.selectProps.inputValue as string;

  const handleCreateProduct = () => {
    props?.selectProps?.onMenuClose();
    props?.selectProps?.onSelectCreateProduct(inputValue);
  };

  return (
    <>
      <SelectComponents.MenuList {...props}>
        {children}
        {!props?.selectProps?.shouldHideCreateProduct && (
          <CreateProductOption className={optionClassName} onClick={handleCreateProduct}>
            <div>
              <Icon type="plus" />
              <span>
                <FormattedMessage
                  id="productSearch.createProduct"
                  defaultMessage="Create ‘{inputValue}’ as a new product"
                  values={{ inputValue }}
                />
              </span>
            </div>
          </CreateProductOption>
        )}
      </SelectComponents.MenuList>
      <Help>
        <Icon type="question-circle" color={defaultTheme.colors.NEUTRAL[300]} />
        <Text as="span" appearance="INFO">
          <FormattedMessage
            id="productSearch.cantFindSKU"
            defaultMessage="Can't find your SKU? {link}"
            values={{
              link: (
                <ElevioBorderlessButton articleId={ElevioArticleId.NewProduct}>
                  <FormattedMessage id="productSearch.visitHelpCenter" defaultMessage="Visit the Help Center" />
                </ElevioBorderlessButton>
              ),
            }}
          />
        </Text>
      </Help>
    </>
  );
};
