import { DestinationOptions } from "returns/seller/store/ReturnsSellerStore.types";
import { MessageDescriptor } from "react-intl";
import { SopMinimal } from "@deliverr/returns-client";

export enum ReturnsOnboardTypes {
  RESET_RETURNS_ONBOARD = "RESET_RETURNS_ONBOARD",
  RETURNS_ONBOARD_ADD_ADDRESS = "RETURNS_ONBOARD_ADD_ADDRESS",
  RETURNS_GOTO_ONBOARD_STEP = "RETURNS_GOTO_ONBOARD_STEP",
  RETURNS_ONBOARD_SET_DESTINATION_TYPE = "RETURNS_DESTINATION_TYPE",
  RETURNS_ONBOARD_SET_SOP = "RETURNS_SET_SOP",
  RETURNS_SOP_UPDATE_SUCCESS = "RETURNS_SOP_UPDATE_SUCCESS",
  RETURNS_SOP_UPDATE_FAILURE = "RETURNS_SOP_UPDATE_FAILURE",
  RETURNS_ONBOARD_QC_ONLY = "RETURNS_ONBOARD_QC_ONLY",
}

export enum ReturnsDestinationType {
  SELF = "SELF",
  DELIVERR = "DELIVERR",
}

export interface ReturnsOnboardState {
  stepId: number;
  destination: DestinationOptions;
  destinationType?: ReturnsDestinationType;
  sopId?: string;
  sopDetails?: SopMinimal[];
  forceShowOnboard?: boolean;
  errorMessage?: MessageDescriptor;
}
