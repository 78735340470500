import { batch } from "react-redux";
import { CustomsInformation } from "@deliverr/commons-clients";
import { SPThunkAction } from "common/ReduxUtils";
import { logError, logStart } from "Logger";
import { productClient } from "Clients";
import { notifyUserOfError } from "common/ErrorToast";
import { hasCustomsInformation } from "InternationalShipping/InternationalShippingHelpers";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";

export enum CustomsActionTypes {
  GET_PRODUCT_CUSTOMS = "GET_PRODUCT_CUSTOMS",
  APPLY_TO_ALL = "APPLY_TO_ALL",
  UPDATE_PRODUCT_CUSTOMS = "UPDATE_PRODUCT_CUSTOMS",
  SAVE_PRODUCT_CUSTOMS = "SAVE_PRODUCT_CUSTOMS",
  UPDATE_SAVED_PRODUCT_CUSTOMS = "UPDATE_SAVED_PRODUCT_CUSTOMS",
}

export const getProductsCustomsLoaderId = "getProductsCustoms";

export const getProductsCustoms =
  (dskus: string[]): SPThunkAction =>
  async (dispatch) => {
    const ctx = logStart({ fn: "getProductsCustoms" });
    try {
      dispatch(addLoader(getProductsCustomsLoaderId));
      const products = await productClient.getProducts(dskus, {
        includeCustomsInformation: true,
      });
      if (!hasCustomsInformation(products)) {
        batch(() => {
          dispatch({ type: CustomsActionTypes.GET_PRODUCT_CUSTOMS, products });
        });
      }
    } catch (err) {
      notifyUserOfError({ err });
      logError(ctx, err);
    } finally {
      dispatch(clearLoader(getProductsCustomsLoaderId));
    }
  };

export const applyToAll =
  (prop: string, value: string): SPThunkAction =>
  (dispatch) =>
    dispatch({ type: CustomsActionTypes.APPLY_TO_ALL, prop, value });

export const updateCustomsProducts =
  (customsInformation: CustomsInformation): SPThunkAction =>
  (dispatch) =>
    dispatch({ type: CustomsActionTypes.UPDATE_PRODUCT_CUSTOMS, customsInformation });

export const saveCustomsProducts =
  (customsInformation: CustomsInformation): SPThunkAction =>
  async (dispatch) => {
    const ctx = logStart({ fn: "saveCustomsProducts" });

    try {
      dispatch(addLoader(customsInformation.dsku));
      await productClient.createCustomsInformation(customsInformation);
      batch(() => {
        dispatch({ type: CustomsActionTypes.SAVE_PRODUCT_CUSTOMS, dsku: customsInformation.dsku });
      });
    } catch (err) {
      logError(ctx, err);
      notifyUserOfError({ err: err.error, toastId: "saveCustomsProducts" });
    } finally {
      dispatch(clearLoader(customsInformation.dsku));
    }
  };

export const updateSavedCustomsProducts =
  (customsInformation: CustomsInformation): SPThunkAction =>
  async (dispatch) => {
    const ctx = logStart({ fn: "updateSavedCustomsProducts", customsInformation });

    try {
      dispatch(addLoader(customsInformation.dsku));
      await productClient.updateCustomsInformation(customsInformation.dsku, customsInformation);
      dispatch({ type: CustomsActionTypes.UPDATE_SAVED_PRODUCT_CUSTOMS });
    } catch (err) {
      notifyUserOfError({ err: err.error, toastId: "updateSavedCustomsProducts" });
      logError(ctx, err);
    } finally {
      dispatch(clearLoader(customsInformation.dsku));
    }
  };
