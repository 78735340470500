import { SellerRmtMinimal } from "@deliverr/returns-client";
import { useAsync } from "react-use";
import { useIntegrationOAuthToken } from "onboarding/ListingTool/tools/common/useIntegrationOAuthToken";

interface UseIntegrationOAuthCallbackProps {
  handleCreateIntegration: (token: string, rmt: SellerRmtMinimal | undefined) => void;
  rmt: SellerRmtMinimal | undefined;
}

export const useIntegrationOAuthCallback = ({ handleCreateIntegration, rmt }: UseIntegrationOAuthCallbackProps) => {
  const { token } = useIntegrationOAuthToken();

  useAsync(async () => {
    if (!token) {
      return;
    }
    await handleCreateIntegration(token, rmt);
  });
};
