import { Appearance, Box, Notification } from "../../../../common/components/ui";
import { FormattedMessage } from "react-intl";
import React from "react";
import { STORAGE_INBOUND_MAX_CASE_LONGEST_IN } from "storage/inbounds/CaseDimensionsConstants";

export const StorageWarehouseCaseDimensionWarning = () => (
  <Box marginBottom={"S7"} width="70%">
    <Notification
      appearance={Appearance.WARNING}
      content={{
        title: (
          <FormattedMessage
            id="storage.inbounds.storageDestination.warehouseDimensionWarning"
            defaultMessage={
              "Some facilities are not available as height of the case is greater than {STORAGE_INBOUND_MAX_CASE_LONGEST_IN} inches"
            }
            values={{ STORAGE_INBOUND_MAX_CASE_LONGEST_IN }}
          />
        ),
      }}
    />
  </Box>
);
