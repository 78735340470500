import { includes } from "lodash/fp";
import * as Cookie from "js-cookie";
import { DynamicRoutes } from "paths";
import { ListingSolution } from "common/clients/onboarding/ListingSolution";
import { getItemFromLocalStorageAndParse, LocalStorageKey, setItemInLocalStorage } from "common/LocalStorage";

export const setInProgressIntegration = (channel: string) => {
  const isValidChannel = Object.values(ListingSolution).includes(channel as ListingSolution);

  if (isValidChannel) {
    setItemInLocalStorage(LocalStorageKey.OnboardingChannel, channel);
  }
};

export const storeRedirectOnboardedUrl = () => {
  const url = window.location.href;
  if (includes("listing-tool-setup", url)) {
    const channelPathWithToken = url.split("/onboarding/listing-tool-setup/")[1];
    if (channelPathWithToken) {
      const [channel, token] = channelPathWithToken.split("/");
      Cookie.set("dlvrIntegrationToken", token);
      setInProgressIntegration(channel);
    }
  }
  return;
};

export const redirectToInitialUrl = () => {
  const dlvrIntegrationToken = Cookie.get("dlvrIntegrationToken");
  const channel = getItemFromLocalStorageAndParse<string>(LocalStorageKey.OnboardingChannel);

  if (dlvrIntegrationToken && channel) {
    window.location.replace(DynamicRoutes.onboardingSetupPageWithToken.parse({ channel, token: dlvrIntegrationToken }));
    Cookie.remove("dlvrIntegrationToken");
  }
};
