import { SortDir } from "common/models";
import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { ParcelListActionTypes } from "./ParcelListActionTypes";
import { ParcelListStatusFilter, ParcelPageItem } from "parcel/screens/list/types";
import produce from "immer";
import { some } from "lodash/fp";

export type ParcelListTab = "ALL" | "LATE";
export type ParcelListDateFilter = "LAST_30" | "LAST_60" | "LAST_90";

export interface ParcelListState {
  currentSearchTerm: string;
  currentPageItems: ParcelPageItem[];
  searchInfo?: {
    totalHits: number;
    currentPage: number;
    itemsPerPage: number;
    totalPages: number;
  };
  sort: {
    fieldName: string;
    direction: SortDir;
  };
  filters: {
    status: ParcelListStatusFilter;
    date: ParcelListDateFilter;
    category: ParcelListTab;
  };
  selectedRows: Record<number, boolean>;
  pageChecked: boolean;
  isAnyRowSelected: boolean;
}

export const parcelListInitialState: ParcelListState = {
  currentSearchTerm: "",
  currentPageItems: [],
  sort: {
    fieldName: "createdAt",
    direction: SortDir.DESC,
  },
  filters: {
    status: "ALL_STATUS",
    date: "LAST_90",
    category: "ALL",
  },
  selectedRows: {},
  pageChecked: false,
  isAnyRowSelected: false,
};

export const reducers: ReducerDictionary<ParcelListState> = {
  ...handleSimpleReducerUpdates([
    ParcelListActionTypes.SEARCH_PARCELS_SUCCESS,
    ParcelListActionTypes.SEARCH_PARCELS_ERROR,
    ParcelListActionTypes.SET_CURRENT_PARCEL_SEARCH_TERM,
    ParcelListActionTypes.SET_PARCELS_SORT,
  ]),
  [ParcelListActionTypes.SET_CURRENT_PARCEL_PAGE]: (state, { page }) =>
    produce(state, (draft) => {
      if (draft.searchInfo) {
        draft.searchInfo.currentPage = page;
        draft.selectedRows = {};
        draft.pageChecked = false;
      }
    }),
  [ParcelListActionTypes.SET_PARCEL_STATUS_FILTER]: (state, { status }) =>
    produce(state, (draft) => {
      draft.filters.status = status;
      draft.selectedRows = {};
    }),
  [ParcelListActionTypes.SET_PARCEL_DATE_FILTER]: (state, { value }) =>
    produce(state, (draft) => {
      draft.filters.date = value;
      draft.selectedRows = {};
    }),
  [ParcelListActionTypes.SELECT_PARCEL_ROW]: (state, { index, isChecked }) =>
    produce(state, (draft) => {
      draft.selectedRows[index] = isChecked;
      draft.isAnyRowSelected = some(Boolean, draft.selectedRows);
      draft.pageChecked =
        Object.values(draft.selectedRows).every((value) => value) &&
        Object.keys(draft.selectedRows).length === Object.keys(draft.currentPageItems).length;
    }),
  [ParcelListActionTypes.UPDATE_FLEX_ID_FOR_PARCELIDS]: (state, { flexId, parcelIds }) =>
    produce(state, (draft) => {
      const parcelIdsSet = new Set(parcelIds.map(String));
      draft.currentPageItems.forEach((item) => {
        if (parcelIdsSet.has(item.parcelId)) {
          item.flexId = flexId;
        }
      });
    }),
  [ParcelListActionTypes.REMOVE_PARCELS_FROM_LIST]: (state, { parcelIds }) =>
    produce(state, (draft) => {
      const parcelIdsSet = new Set(parcelIds);
      draft.currentPageItems = draft.currentPageItems.filter((item) => !parcelIdsSet.has(item.parcelId));
    }),
  [ParcelListActionTypes.REMOVE_CSV_PARCELS_FROM_LIST]: (state, { parcelIds, trackingCodes }) =>
    produce(state, (draft) => {
      draft.currentPageItems = draft.currentPageItems.filter(
        (item) => !parcelIds.includes(item.parcelId) && !trackingCodes.includes(item.trackingCode)
      );
    }),
  [ParcelListActionTypes.SET_PARCEL_SELECTED_ROWS]: (state) =>
    produce(state, (draft) => {
      draft.isAnyRowSelected = some(Boolean, draft.selectedRows);
      draft.selectedRows = draft.currentPageItems.reduce((acc, _, i) => ({ ...acc, [i]: !draft.isAnyRowSelected }), {});
      draft.pageChecked =
        Object.values(draft.selectedRows).every((value) => value) &&
        Object.keys(draft.selectedRows).length === Object.keys(draft.currentPageItems).length;
    }),
};

export const parcelListReducer = createReducer<ParcelListState>(parcelListInitialState, reducers);
