import { DefaultTheme, IconV2, IconV2Props, IconV2Types, defaultTheme } from "common/components/ui";
import React, { FC } from "react";

import { ReturnStatus } from "@deliverr/returns-client";
import styled from "@emotion/styled";

type IconConfigProps = Record<ReturnStatus, IconV2Props>;

const IconWrapper = styled.div<
  {
    backgroundColor?: string;
    padding: string;
  },
  DefaultTheme
>(
  ({ theme, backgroundColor, padding }) => `
  background-color: ${backgroundColor};
  margin-right: ${theme.spacing.S2};
  padding: ${padding};
  border-radius: 50%;
  text-align: center;
  width: 48px;
  height: 48px;
  & > svg {
    min-height: 24px;
  }
`
);

const checkIcon: IconV2Props = {
  type: "check-circle",
  color: defaultTheme.colors.NEUTRAL["00"],
};

const getIcon = (iconName: IconV2Types) => ({
  type: iconName,
  color: defaultTheme.colors.NEUTRAL[80],
});

const IconConfig: IconConfigProps = {
  [ReturnStatus.CREATED]: getIcon("home"),
  [ReturnStatus.CARRIER_SHIPPED]: getIcon("truck"),
  [ReturnStatus.CARRIER_DELIVERED]: getIcon("package"),
  [ReturnStatus.ARRIVED]: getIcon("package"),
  [ReturnStatus.PROCESSED]: checkIcon,
  [ReturnStatus.RETURNED_TO_CUSTOMER]: getIcon("close"),
  [ReturnStatus.CANCELLED]: getIcon("trash"),
  [ReturnStatus.CLOSED]: getIcon("archive"),
  [ReturnStatus.AUTO_CANCELLED]: getIcon("trash"),
};

export const ReturnStatusIcon: FC<{ status?: ReturnStatus }> = ({ status }) => {
  const getProps = () => {
    if (!status || !IconConfig[status]) {
      return getIcon("home");
    }
    return IconConfig[status];
  };
  const { type, color } = getProps();
  const isCheckIcon = type === "check-circle";
  const iconColor = isCheckIcon ? defaultTheme.colors.NEUTRAL[300] : defaultTheme.colors.NEUTRAL[500];
  const iconSize = isCheckIcon ? "3x" : "lg";
  const iconPadding = isCheckIcon ? "0" : defaultTheme.spacing.S3;

  return (
    <IconWrapper backgroundColor={color} padding={iconPadding}>
      <IconV2 color={iconColor} type={type} size={iconSize} data-testid="return_status_icon" />
    </IconWrapper>
  );
};
