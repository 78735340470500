import { DamageType } from "@deliverr/commons-clients/lib/non-compliance/damages/DamagedProductUnified";
import { ProblemType } from "common/problems/ProblemType";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { BoxCountMessage } from "../BoxCountMessage";
import {
  ProblemRowData,
  RelabeledBoxProblemRow,
  ShortShipProblemRow,
  UnknownBarcodeProblemRow,
  UnexpectedBarcodeProblemRow,
  MissingBarcodeProblemRow,
  DamagedProductProblemRow,
  OperationalException,
} from "../types";
export const ProblemTypeCaption: FC<{ problemRow: ProblemRowData }> = ({ problemRow }) => {
  switch (problemRow.problemType) {
    case ProblemType.RELABELED_BOX:
      return <BoxCountMessage boxCount={(problemRow as RelabeledBoxProblemRow).boxCount} />;
    case ProblemType.SHORT_SHIP_BOXES:
      return <BoxCountMessage boxCount={(problemRow as ShortShipProblemRow).summary.missingCdskus.length} />;
    case ProblemType.UNKNOWN_BARCODE:
      return <>{(problemRow as UnknownBarcodeProblemRow).barcode}</>;
    case ProblemType.FLEXPORT_SHIP:
      return <>{(problemRow as OperationalException).exceptionTypeName}</>;
    case ProblemType.UNEXPECTED_BARCODE:
      return <>{(problemRow as UnexpectedBarcodeProblemRow).barcode}</>;
    case ProblemType.MISSING_BARCODE:
      return (
        <>
          {(problemRow as MissingBarcodeProblemRow).barcode ?? (
            <FormattedMessage id="inbounds.shipmentProblems.caption.unknownProduct" defaultMessage="Unknown product" />
          )}
        </>
      );
    case ProblemType.DAMAGED_PRODUCT:
      return (problemRow as DamagedProductProblemRow).damageType === DamageType.EXPIRED ? (
        <FormattedMessage
          id="inbounds.shipmentProblems.caption.expiredUnits"
          defaultMessage="Units past sellable date"
        />
      ) : (
        <FormattedMessage
          id="inbounds.shipmentProblems.caption.damagedUnits"
          defaultMessage="Units in unsellable condition"
        />
      );
    case ProblemType.MISMATCH_CASE_QTY:
      return (
        <FormattedMessage
          id="inbounds.shipmentProblems.caption.mismatchCaseQty"
          defaultMessage="Unexpected units per box"
        />
      );
    case ProblemType.UNEXPECTED_SKU:
      return (
        <FormattedMessage
          id="inbounds.shipmentProblems.caption.unexpectedSku"
          defaultMessage="Unexpected units received"
        />
      );
    default:
      return null;
  }
};
