import { createSelector } from "reselect";
import { InboundBookingRequestV2, ShipmentSteps } from "inbounds/createShipment/CreateShipmentTypes";
import { selectBookingCreateRequest } from "./selectBookingCreateRequest";
import { RootState } from "RootReducer";
import { selectBookingSupplierRequest } from "./selectBookingSupplierRequest";
import { selectBookingDestinationRequest } from "./selectBookingDestinationRequest";
import { selectBookingItemRequest } from "./selectBookingItemRequest";
import { selectBookingSubmitRequest } from "./selectBookingSubmitRequest";
import { selectBookingRequestByStep } from "./selectBookingRequestByStep";

export const selectBookingRequestByStepV2 = (currentStep: ShipmentSteps, shouldSubmit: boolean) =>
  createSelector(
    (state: RootState) => state,
    (state) => {
      let request: InboundBookingRequestV2 | undefined;
      switch (currentStep) {
        case ShipmentSteps.OriginSearch:
          request = selectBookingCreateRequest()(state);
          break;
        case ShipmentSteps.SupplierSelect:
          request = selectBookingSupplierRequest()(state);
          break;
        case ShipmentSteps.DestinationSelect:
        case ShipmentSteps.DestinationSearch:
        case ShipmentSteps.ReserveStorageFcSelect:
          request = selectBookingDestinationRequest()(state);
          break;
        case ShipmentSteps.CargoDetails:
          request = selectBookingItemRequest()(state);
          break;
        case ShipmentSteps.PriceView:
          request = selectBookingSubmitRequest()(state);
          break;
        default:
          request = selectBookingRequestByStep(currentStep, shouldSubmit)(state);
          break;
      }
      return request;
    }
  );
