import { InboundPackage } from "@deliverr/legacy-inbound-client";
import { createSelector } from "reselect";
import { selectBoxesByShipment } from "../plan/selectBoxesByShipment";
import { getLoadedShipmentId } from "./getLoadedShipmentId";

export const selectLoadedShipmentBoxes = createSelector(
  getLoadedShipmentId,
  selectBoxesByShipment,
  (loadedShipmentId, packagesByShipment): InboundPackage[] => {
    if (!loadedShipmentId) {
      return [];
    }
    return packagesByShipment[loadedShipmentId];
  }
);
