import { ShipmentInstructionsStepId, ShipmentInstructionsStepType } from "../ShipmentInstructionsStep.types";

import { PickupAppointmentDetail } from "./PickupAppointmentDetail";
import { shipmentInstructionStepLabels } from "../shipmentInstructionStepLabels";

export const pickupAppointmentDetailStep: ShipmentInstructionsStepType = {
  id: ShipmentInstructionsStepId.PICKUP_DETAILS,
  title: shipmentInstructionStepLabels.prepareForPickup,
  component: PickupAppointmentDetail,
};
