import React, { FC } from "react";
import { IconButton, IconV2 } from "../../ui";
import { useIntl } from "react-intl";
import { SearchBox, SearchInput } from "./GlobalSearchStyledComponents";

interface GlobalSearchInputProps {
  searchTerm: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
}

export const GlobalSearchInput: FC<GlobalSearchInputProps> = ({ searchTerm, onChange, onClose }) => {
  const { formatMessage } = useIntl();

  return (
    <SearchBox>
      <IconV2 type="search" aria-label={formatMessage({ id: "navbar.searchIcon", defaultMessage: "Search Icon" })} />
      <SearchInput
        type="text"
        value={searchTerm}
        onChange={onChange}
        placeholder={formatMessage({ id: "navbar.searchPlaceholder", defaultMessage: "Search..." })}
        aria-label={formatMessage({ id: "navbar.searchInput", defaultMessage: "Search Input" })}
        autoFocus
      />
      <IconButton
        onClick={onClose}
        aria-label={formatMessage({ id: "navbar.closeSearch", defaultMessage: "Close Search" })}
      >
        <IconV2 type="close" />
      </IconButton>
    </SearchBox>
  );
};
