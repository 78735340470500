import React from "react";
import { BaseSelect, SelectProps } from "./BaseSelect";
import { CustomOption, CustomSingleValue, CustomMenuList } from "./MultiSelectCustomComponents";
import { useMultiSelect } from "./useMultiSelect";

export const MultiSelect: React.FC<SelectProps> = (props) => {
  const { inputValue, toggleAll, indeterminantState, handleInputChange, updateSelectedItem } = useMultiSelect(props);

  // react-select doesn't play too well with the multiselect we wanted,
  // so we have several custom elements
  const customComponents = {
    ...props.customComponents,
    Option: CustomOption,
    SingleValue: CustomSingleValue,
    MenuList: CustomMenuList,
  };

  return (
    <BaseSelect
      {...props}
      toggleAll={toggleAll}
      indeterminantState={indeterminantState}
      placeholder="None"
      onChange={updateSelectedItem}
      closeMenuOnSelect={false}
      customComponents={customComponents}
      onInputChange={handleInputChange}
      inputValue={inputValue}
    />
  );
};
