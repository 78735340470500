import classNames from "classnames/dedupe";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { isTrue } from "common/utils/helpers";
import history from "BrowserHistory";
import { ButtonType, LoaderButton, ButtonSize } from "common/components/WithLoader/LoaderButton";
import { ActionCreator } from "common/ReduxUtils";
import log from "Logger";
import { RootState } from "RootReducer";
import { disableNext, Enable } from "tags/FastTagsActions";
import FastTagsLoaderNames from "tags/FastTagsLoaderNames";

import cls from "./FastTagsNav.less";

interface ReduxProps {
  nextButtonEnabled: { [page: string]: boolean };
  pageTransitionEnabled: { [page: string]: boolean };
}

interface DispatchProps {
  disableNext: ActionCreator;
}

export interface OwnProps {
  onNext?: () => any;
  successProp?: string; // determines if on next succeeded
  nextPage?: string;
  previousPage?: string;
  enableNextProp?: string;
  nextButtonText?: JSX.Element;
  previousButtonText?: JSX.Element;
  altButtonContainer?: JSX.Element;
  nextButtonClassName?: string;
  previousButtonClassName?: string;
  className?: string;
  nextDisabled?: boolean;
  showNextButton?: boolean;
}

const UnconnectedFastTagsNav: FC<ReduxProps & DispatchProps & OwnProps> = ({
  enableNextProp,
  nextButtonEnabled,
  nextPage,
  onNext,
  pageTransitionEnabled,
  previousPage,
  successProp,
  nextDisabled,
  className,
  previousButtonClassName,
  previousButtonText,
  altButtonContainer,
  showNextButton,
  nextButtonClassName,
  nextButtonText,
  disableNext: disableNextAction,
}) => {
  const updatePage = (path: string) => history.push(path);

  const onPrevious = () => {
    if (previousPage) {
      log.info({ fn: "FastTagsNav.onPrevious", previousPage }, "moving to previous egd step");
      updatePage(previousPage);
    }
  };

  const onNextClicked = async () => {
    const ctx = { fn: "FastTagsNav.onNext" };

    if (!nextPage) {
      throw new Error("no next page");
    }

    if (onNext) {
      log.info(ctx, "executing onNext before moving to next page");
      await onNext();
    } else {
      log.info({ ...ctx, nextPage }, "moving to next page");
      updatePage(nextPage);
    }
  };

  if (nextPage && successProp && pageTransitionEnabled[successProp]) {
    updatePage(nextPage);
    disableNextAction(Enable.Transition, successProp);
  }

  return (
    <div className={classNames(cls.step, className)}>
      <div className={cls.navSection}>
        <div className={cls.leftNav}>
          {previousPage && (
            <button
              type="button"
              className={classNames(`btn btn-primary-reversed`, previousButtonClassName)}
              onClick={onPrevious}
            >
              {previousButtonText ?? <FormattedMessage id={"FastTagsNav.previous"} defaultMessage={"Previous"} />}
            </button>
          )}
        </div>
        <div className={classNames(cls.rightNav, { [cls.hidden]: !nextPage })}>
          {altButtonContainer}
          {(isTrue(showNextButton) || showNextButton === undefined) && (
            <LoaderButton
              disabled={isTrue(nextDisabled) || Boolean(enableNextProp && !nextButtonEnabled[enableNextProp])}
              buttonClass={classNames(cls.nextButton, nextButtonClassName)}
              buttonType={ButtonType.Primary}
              name={FastTagsLoaderNames.Next}
              onClick={onNextClicked}
              size={ButtonSize.lg}
            >
              {nextButtonText ??
                (onNext ? (
                  <FormattedMessage id={"FastTagsNav.verify"} defaultMessage={"Verify"} />
                ) : (
                  <FormattedMessage id={"FastTagsNav.next"} defaultMessage={"Next"} />
                ))}
            </LoaderButton>
          )}
        </div>
      </div>
    </div>
  );
};

export const FastTagsNav = connect<ReduxProps, DispatchProps, OwnProps>(
  (state: RootState): ReduxProps => ({
    pageTransitionEnabled: state.tags.pageTransitionEnabled,
    nextButtonEnabled: state.tags.nextButtonEnabled,
  }),
  {
    disableNext,
  }
)(UnconnectedFastTagsNav);
