import { emptyAddress } from "common/AddressUtils";
import { PickupDetailsState } from "freight/types/PickupTypes";

export const pickupDetailsInitialState: PickupDetailsState = {
  address: { ...emptyAddress, country: "" },
  cargoReadyDate: null,
  facilityOperatingHours: undefined,
  pickupReferenceNumber: null,
  customerReferenceNumber: null,
  pickupInstructions: null,
  contact: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phoneExt: "",
  },
  isAppointmentRequired: undefined,
};
