import { DeliverrAddress } from "@deliverr/commons-objects";
import React from "react";
import { capitalizeWordHtml } from "common/StringUtils";
import { flowRight, join, map, split, trim } from "lodash/fp";

const capitalizeField = flowRight(trim, join(" "), map(capitalizeWordHtml), split(" "));

export const addressOption = (destination: DeliverrAddress) => {
  const emptyAddress = "";
  const {
    street1 = emptyAddress,
    street2 = emptyAddress,
    city = emptyAddress,
    state = emptyAddress,
    zip = emptyAddress,
  } = destination;

  return (
    <>
      <div>{`${capitalizeField(street1)}, ${street2}`}</div>
      <div>{`${capitalizeField(city)}, ${state} ${zip}`}</div>
    </>
  );
};
