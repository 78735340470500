import { openArticle, openSupportTicket } from "./Elevio";

export const useElevio = (articleId: string, onClick?: React.MouseEventHandler<HTMLButtonElement>) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (onClick !== undefined) {
      onClick(event);
    }

    articleId === "open-ticket" ? openSupportTicket() : openArticle(articleId);
  };

  return { handleClick };
};
