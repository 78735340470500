import { defineMessages } from "react-intl";

export const PRODUCT_CHOOSER_LABELS = defineMessages({
  selectedProduct: {
    kitComponent: {
      showHeader: {
        id: "inbounds.selectedProduct.kitComponent.showHeader",
        defaultMessage: "Show {qty} components",
      },
      hideHeader: {
        id: "productChooser.selectedProduct.kitComponent.hideHeader",
        defaultMessage: "Hide {qty} components",
      },
      details: {
        id: "productChooser.selectedProduct.kitComponent.details",
        defaultMessage: "{units, plural, one {# unit} other {# units}} of {componentName}",
      },
    },
  },
});
