import { ReducerDictionary, handleSimpleReducerUpdates, createReducer } from "common/ReduxUtils";
import { FbaIntegrationActionTypes } from "./FbaIntegrationActionTypes";
import { FbaIntegrationState } from "./FbaIntegrationState";

export const FbaIntegrationInitialStep = "authorize";

export const fbaIntegrationInitialState: FbaIntegrationState = {
  stack: [FbaIntegrationInitialStep],
};

const reducers: ReducerDictionary<FbaIntegrationState> = {
  ...handleSimpleReducerUpdates([
    FbaIntegrationActionTypes.FBA_INTEGRATION_SET_ERROR,
    FbaIntegrationActionTypes.FBA_INTEGRATION_SET_LOADING,
    FbaIntegrationActionTypes.FBA_INTEGRATION_SET_AUTH_STATUS,
    FbaIntegrationActionTypes.FBA_INTEGRATION_SET_AMAZON_ERRORS,
  ]),
};

export const fbaIntegrationReducer = createReducer<FbaIntegrationState>(fbaIntegrationInitialState, reducers);
