import React from "react";
import { FormattedMessage } from "react-intl";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";

export const FBAParcelValidationErrorMessage: React.FunctionComponent<{}> = () => {
  return (
    <FormattedMessage
      id="transfers.create.fba.parcelValidation"
      defaultMessage="This product exceeds Amazon's requirements for small parcel delivery and must ship via freight. {learnMore}."
      values={{
        learnMore: <ElevioLearnMoreButton articleId={ElevioArticleId.ShippingRestrictionsForFbaInbounds} />,
      }}
    />
  );
};
