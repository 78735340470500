import React from "react";
import { Pill } from "common/components/ui";
import { useParcelListStatus } from "parcel/screens/list/ParcelListTable/ParcelListStatus/useParcelListStatus";
import { ParcelPageItem } from "parcel/screens/list/types";

interface ParcelListStatusProps {
  parcel: ParcelPageItem;
}

export const ParcelListStatus = ({ parcel }: ParcelListStatusProps) => {
  const { status, color } = useParcelListStatus(parcel);
  return status ? <Pill color={color}>{status}</Pill> : null;
};
