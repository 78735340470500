import { validate as validateUPC } from "barcoder";
import { isString } from "lodash/fp";
import { BarcodeEditCollection } from "../../../../barcodes/BarcodeEditReducer";

export enum BarcodeFormat {
  DSKU = "dsku",
  UPC = "upc",
  FNSKU = "fnsku",
  PONUM = "ponum", // PO# / AsnId
}

export enum BarcodeFormatErrors {
  UNKNOWN = "",
}

const barcodeFormatToLength: Record<BarcodeFormat, RegExp> = {
  [BarcodeFormat.DSKU]: /^.{11}$/, // D + 10 chars
  [BarcodeFormat.FNSKU]: /^.{10}$/, // X or F + 9 chars
  [BarcodeFormat.UPC]: /^.{12,14}$/,
  [BarcodeFormat.PONUM]: /^.{1,}$/,
};

const barcodePatterns: Record<BarcodeFormat, RegExp> = {
  [BarcodeFormat.DSKU]: /^D\w{10}$/, // starts w/ "D", followed by 10 alphanumeric characters
  [BarcodeFormat.FNSKU]: /^B\w{9}$|^X00\w{7}$/, // starts w/ "B" (asin) followed by 9 or "X00" (fnsku) followed by 7, alphanumeric characters
  [BarcodeFormat.UPC]: /^\d{12,14}$/, // 12-14 numeric characters (12 upc, 13 ean/upc, 14 upc)
  [BarcodeFormat.PONUM]: /^\d{1,}$/, // one or more numeric characters
};

export const getBarcodeFormat = (barcode?: string): BarcodeFormat | BarcodeFormatErrors | undefined => {
  if (!barcode) {
    return BarcodeFormatErrors.UNKNOWN;
  }

  const matchingPattern = Object.entries(barcodePatterns).find(([_, regex]) => Boolean(barcode.match(regex)));

  return matchingPattern && (matchingPattern[0] as BarcodeFormat);
};

export function isValidBarcodeLength(format: BarcodeFormat, barcode: string): boolean {
  return Boolean(barcode.match(barcodeFormatToLength[format]));
}

export const validateBarcode = (format: BarcodeFormat, barcode: string): boolean => {
  if (!isString(barcode) || barcode.match(barcodePatterns[format]) === null) {
    return false;
  }

  if (BarcodeFormat.UPC === format) {
    // somehow .d.ts files are not working in jest
    return validateUPC(barcode);
  }

  return true;
};

export const limitInputBarcodeLength = (format: BarcodeFormat, barcode: string): string => {
  const maxLengthIndex = {
    [BarcodeFormat.DSKU]: 11,
    [BarcodeFormat.FNSKU]: 10,
    [BarcodeFormat.UPC]: 14,
  }[format];
  return barcode.slice(0, maxLengthIndex);
};

const BARCODE_FEE_PER_SKU = 56;
const BARCODE_FEE_PER_UNIT = 0.25;
export const calculateBarcodeFee = (numOfSkus: number, numOfUnits: number): number =>
  numOfSkus * BARCODE_FEE_PER_SKU + numOfUnits * BARCODE_FEE_PER_UNIT;

export const deliverrBarcodeFormatToJsBarcodeFormat = (barcode: string, format: BarcodeFormat): string =>
  format === BarcodeFormat.UPC ? (barcode.length === 12 || barcode.length === 14 ? "UPC" : "EAN13") : "CODE128";

export const isAsin = (barcode: string = "") => {
  return barcode.match(/^B\w{9}$/) !== null;
};

export const isUSEAN = (barcode: string = "") => {
  return barcode.match(/^0\d{12}$/) !== null;
};

export const isUSGTIN14 = (barcode: string = "") => {
  return barcode.match(/^00\d{12}$/) !== null;
};

export const areBarcodesEquivalent = (barcode1: string, barcode2: string): boolean => {
  return barcode1.replace(/^0+/, "") === barcode2.replace(/^0+/, "");
};

export const getActiveUniqueBarcodesCount = (barcodes: BarcodeEditCollection): number => {
  const uniqueBarcodes = new Set<string>();

  barcodes.forEach(({ value, valid }) => {
    if (!valid) {
      return;
    }

    const cleanedValue = value.replace(/^0+/, "").toUpperCase();

    const isEquivalentFound = Array.from(uniqueBarcodes).some((barcode) =>
      areBarcodesEquivalent(barcode, cleanedValue)
    );

    if (!isEquivalentFound) {
      uniqueBarcodes.add(cleanedValue);
    }
  });

  return uniqueBarcodes.size;
};

export const transformToBarcodeEditCollection = (barcodes: string[] | undefined): BarcodeEditCollection =>
  (barcodes ?? []).map((value) => ({
    value,
    locked: true,
    format: getBarcodeFormat(value),
    valid: true,
  }));
