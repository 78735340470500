import React from "react";
import { useMeasure } from "react-use";

let ctx: CanvasRenderingContext2D | null = null;
interface MiddleEllipsisProps {
  text: string;
  endCharLength?: number;
  fontSize?: string;
}

const removeChar = (text: string, maxLength: number, context: CanvasRenderingContext2D): string => {
  const { width } = context.measureText(text);
  return width > maxLength ? removeChar(text.slice(0, text.length - 1), maxLength, context) : text;
};

const CHAR_AT_END = 3;

export const MiddleEllipsis: React.FC<MiddleEllipsisProps> = ({
  text,
  endCharLength = CHAR_AT_END,
  fontSize = "14px",
}) => {
  const [ref, { width: divElWidth }] = useMeasure<HTMLDivElement>();
  const [isEllipsis, setIsEllipsis] = React.useState<boolean>(false);
  const ellipsisText = React.useRef<string>("");
  React.useEffect(() => {
    if (!ctx) {
      const canvas = document.createElement("canvas");
      ctx = canvas.getContext("2d");
    }

    if (ctx && divElWidth && text) {
      ctx.font = `${fontSize} lato`;
      const { width: textWidth } = ctx.measureText(text);
      if (textWidth > divElWidth) {
        setIsEllipsis(true);
        const preEllipsisText = text.slice(0, text.length - endCharLength);
        const postEllipsisText = text.slice(-endCharLength);
        const ellipsisWithPostEllipsisText = `...${postEllipsisText}`;
        const { width: ellipsisWithPostEllipsisTextWidth } = ctx.measureText(ellipsisWithPostEllipsisText);
        // don't call removeChar if ellipsisWithPostEllipsisTextWidth is bigger than divElWidth
        if (ellipsisWithPostEllipsisTextWidth > divElWidth) {
          ellipsisText.current = ellipsisWithPostEllipsisText;
        } else {
          const preEllipsisTextCorrected = removeChar(
            preEllipsisText,
            divElWidth - ellipsisWithPostEllipsisTextWidth,
            ctx
          );
          ellipsisText.current = `${preEllipsisTextCorrected}${ellipsisWithPostEllipsisText}`;
        }
      }
    }
  }, [text, divElWidth]);

  return <div ref={ref}>{isEllipsis ? ellipsisText.current : text}</div>;
};
