import history from "BrowserHistory";
import { Path } from "paths";
import { ParcelListActionTypes } from "parcel/screens/list/ParcelListActionTypes";

/**
 * Sets the current page and updates the route if the page is 1.
 * @param {number} page - The current page number.
 */
export const setCurrentPage = (page: number) => async (dispatch) => {
  dispatch({
    type: ParcelListActionTypes.SET_CURRENT_PARCEL_PAGE,
    page,
  });
  if (page === 1) {
    history.push(`${Path.parcel}`);
  }
};

/**
 * Updates the route based on the provided page number.
 * @param {number} page - The current page number.
 */
export const setCurrentPageRoute = (page: number) => history.push(`${Path.parcel}?page=${page + 1}`);
