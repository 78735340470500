import { Box, RadioTileGroup, Stack, Notification } from "common/components/ui";
import styled from "@emotion/styled";
import React from "react";
import { useStorageCargoType } from "./useStorageCargoType";
import { StoragePalletizedInput } from "./palletized/StoragePalletizedInput";
import { StorageFloorLoadedContainerInput } from "./floorLoadedContainer/StorageFloorLoadedContainerInput";
import { StoragePalletTable } from "../selectShipping/steps/addShipmentDetails/storagePalletTable/StoragePalletTable";
import { StorageFreightPackingInstruction } from "../selectShipping/steps/storageFreightPackingInstruction/StorageFreightPackingInstruction";
import { FormattedMessage } from "react-intl";
import { CarrierType, ShipmentType } from "@deliverr/storage-client";

const StyledRadioTileGroup = styled(RadioTileGroup)`
  justify-content: space-between;

  label {
    width: 47%;
  }
`;

export const StorageCargoType: React.ComponentType = () => {
  const { carrierType, cargoTypeOptions, selectedCargoType, onCargoTypeChange } = useStorageCargoType();

  const enableFreightCarrier = carrierType === CarrierType.DELIVERR;
  const shipmentRadioTile = (
    <Box width="90%">
      <StyledRadioTileGroup
        currentValue={selectedCargoType}
        direction="HORIZONTAL"
        name="cargoTypeRadioGroup"
        options={cargoTypeOptions}
        onChange={onCargoTypeChange}
        block
      />
      {selectedCargoType === ShipmentType.PALLETIZED ? (
        <>
          {enableFreightCarrier && (
            <Stack>
              <StorageFreightPackingInstruction />
              <Notification
                appearance="PRIMARY"
                content={{
                  description: (
                    <FormattedMessage
                      defaultMessage="Pallet labels are provided after you review and confirm this shipment."
                      id="storage.cargo.freight.palletLabels"
                    />
                  ),
                }}
              />
            </Stack>
          )}
          <StoragePalletizedInput />
          {enableFreightCarrier && <StoragePalletTable />}
        </>
      ) : selectedCargoType === ShipmentType.FLOOR_LOADED_CONTAINER ? (
        <StorageFloorLoadedContainerInput />
      ) : null}
    </Box>
  );
  return (
    <Box minHeight="36.25rem" minWidth="62.5rem">
      {shipmentRadioTile}
    </Box>
  );
};
