import {
  wholesaleCreateSetError,
  wholesaleCreateSetLabelFilename,
  wholesaleCreateSetUploading,
} from "./WholesaleCreateActions";
import { selectWholesaleCreate } from "../store/WholesaleCreateSelectors";
import { sharedCreateUploadWholesaleLabelFile } from "common/wholesale-common/sharedCreateUploadWholesaleLabelFile";

export const uploadWholesaleLabelFile = sharedCreateUploadWholesaleLabelFile({
  selector: selectWholesaleCreate,
  setError: wholesaleCreateSetError,
  setFilename: wholesaleCreateSetLabelFilename,
  setUploading: wholesaleCreateSetUploading,
});
