import { SearchOptions } from "@deliverr/logistics-search-client";
import { SearchRequest } from "../SearchService";
import { OpenSearchService } from "common/search/services/OpenSearchService";

export class ClaimUploadsSearchService extends OpenSearchService {
  protected buildSearchOptions(request: SearchRequest): SearchOptions {
    const searchOptions = super.buildSearchOptions(request);
    const shouldQueries: any[] = [];

    if (request.searchTerm) {
      shouldQueries.push({
        query_string: {
          query: `filename: *${request.searchTerm}*`,
          analyze_wildcard: true,
        },
      });
    }

    return {
      ...searchOptions,
      query: {
        bool: {
          should: shouldQueries,
          minimum_should_match: 1,
        },
      },
      hydrate: true,
    };
  }
}
