import { toCognito } from "common/user/CognitoConverter";
import { UserData } from "common/user/UserReducer";
import { getAuth, getUser } from "common/utils/SSO";
import store from "store";
import { CognitoLegacyActions } from "./CognitoReducer";

const updateUser = async (userData: UserData, bypassCache: boolean = false): Promise<string> => {
  const session = await getAuth();
  const user = await getUser();
  const result = await session.updateUserAttributes(user, toCognito({ ...userData, updatedAt: String(Date.now()) }));

  store.dispatch({
    type: CognitoLegacyActions.COGNITO_UPDATE_USER_ATTRIBUTES,
    user: await getUser({ bypassCache }),
  });

  return result;
};

export default updateUser;
