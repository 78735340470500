import React, { FC } from "react";
import styled from "@emotion/styled";
import { DefaultTheme, Icon } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import { wholesaleCreateLabels } from "wholesale/create/wholesaleCreate.labels";
import { CarrierAccountModalMode } from "./modals/CarrierAccountModal";
import { CarrierAccount } from "@deliverr/replenishment-client";

const StyledButton = styled.button<{}, DefaultTheme>(
  ({ theme }) => `
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.NEUTRAL["20"]};
    color: ${theme.colors.BLUE["300"]};
    border: ${theme.border.width.B1} solid ${theme.colors.NEUTRAL[80]};
    font-size: ${theme.font.size.F2};
    font-weight: ${theme.font.weight.BOLD};
    padding: 0;
  `
);

const PlusIcon = styled(Icon)<{}, DefaultTheme>(
  ({ theme }) => `
  margin-right: ${theme.spacing.S2};
  width: ${theme.spacing.S4};
`
);

interface AddCarrierAccountButtonProps {
  onClick: (modalMode: CarrierAccountModalMode, carrierAccount?: CarrierAccount) => void;
}

export const AddCarrierAccountButton: FC<AddCarrierAccountButtonProps> = ({ onClick }) => (
  <StyledButton onClick={() => onClick(CarrierAccountModalMode.CREATE)}>
    <PlusIcon type="plus" />
    <FormattedMessage {...wholesaleCreateLabels.steps.shipping.labelProvider.addCarrierAccount} />
  </StyledButton>
);
