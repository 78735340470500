import { EventHandler, SyntheticEvent } from "react";

export const preventDefault = (e: SyntheticEvent<any>) => e.preventDefault();
export const stopPropagation = (e: SyntheticEvent<any>) => e.stopPropagation();

export const handleIfExists =
  <E extends SyntheticEvent>(eventHandler?: EventHandler<E>, disabled?: boolean) =>
  (e: E) => {
    if (eventHandler && !disabled) {
      eventHandler(e);
    }
  };
