import { PalletDetails } from "@deliverr/storage-client";
import { createActionCreator } from "common/ReduxUtils";
import { StorageCargoPalletDetailsActionTypes } from "./StorageCargoPalletDetailsActionTypes";

export const addPalletLineItem = createActionCreator<PalletDetails>(
  StorageCargoPalletDetailsActionTypes.STORAGE_ADD_NEW_PALLET_LINE_ITEM,
  "palletLineItem"
);

export const removePalletLineItem = createActionCreator<number>(
  StorageCargoPalletDetailsActionTypes.STORAGE_REMOVE_PALLET_LINE_ITEM,
  "index"
);

export const updatePalletLineItem = createActionCreator<number, string, number>(
  StorageCargoPalletDetailsActionTypes.STORAGE_UPDATE_PALLET_LINE_ITEM,
  "index",
  "name", // name comes from the input name attribute ("height, weight, identicalPallets")
  "value"
);
