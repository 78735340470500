import { getFreightListActiveView, getFreightOrderItems, getFreightQuoteRequestItems } from "freight/FreightSelectors";
import { toggleListView } from "freight/store/freightList/actions/FreightListActions";
import {
  fetchAndLoadFreightQuoteRequest,
  setFreightQuotingBladeOpen,
} from "freight/store/freightQuoting/actions/FreightQuotingActions";
import { Path } from "paths";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { FreightBookingListItem, isItemAnOrder } from "./FreightBookingListItem";
import { FreightQuoteListItem } from "./FreightQuotesTable/FreightQuoteListItem";

export enum ListType {
  ORDERS = "Orders",
  QUOTES = "Quotes",
}
export const useFreightBookingList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const activeListView = useSelector(getFreightListActiveView);
  const freightOrderItems = useSelector(getFreightOrderItems);
  const freightQuoteRequestItems = useSelector(getFreightQuoteRequestItems);

  useEffectOnce(() => {
    dispatch(toggleListView(freightOrderItems.length ? ListType.ORDERS : ListType.QUOTES));
  });

  const itemPath = ({ id }: FreightBookingListItem) => generatePath(Path.freightOrderDetail, { orderId: id });

  const onOrderItemClick = (item: FreightBookingListItem) => {
    if (isItemAnOrder(item)) {
      history.push(itemPath(item));
    }
  };

  const onQuoteItemClick = (item: FreightQuoteListItem) => {
    dispatch(fetchAndLoadFreightQuoteRequest(item.id));
  };

  const onQuoteOrderClick = () => {
    dispatch(setFreightQuotingBladeOpen(true));
  };

  const updateActiveListView = (value) => {
    dispatch(toggleListView(value));
  };

  return {
    freightOrderItems,
    freightQuoteRequestItems,
    onOrderItemClick,
    onQuoteItemClick,
    onQuoteOrderClick,
    activeListView,
    updateActiveListView,
  };
};
