import { GraphQLSingularResponse } from "relay-runtime";

interface Operation {
  name: string;
}

interface ErrorExtra {
  partialResponse?: GraphQLSingularResponse;
  body?: string;
}

interface Extension {
  serviceName: string;
}

export type ResponseError = string | { field: unknown; message: string; extensions?: Extension } | Error;

type ErrorLevel = "Transport" | "Backend" | "Application";

export const USER_DEACTIVATED_MESSAGE = "User has been deactivated";

export default class GraphQLError extends Error {
  level: ErrorLevel;
  messages: ReadonlyArray<string>;
  errors: ReadonlyArray<ResponseError>;
  operation: Operation;
  variables: object;
  requestId: string | null;
  extra?: ErrorExtra;
  response: GraphQLSingularResponse | null = null;
  sentryEventId: string | null = null;

  constructor(
    level: ErrorLevel,
    errors: ReadonlyArray<ResponseError>,
    operation: Operation,
    variables: object,
    // eslint-disable-next-line default-param-last
    requestId: string | null = null,
    extra?: ErrorExtra
  ) {
    let messages = errors.map(stringifyErrorIsh);

    if (level === "Transport") {
      if (errors.length === 1 && errors[0] instanceof TypeError) {
        messages = ["Please check if you are connected to the internet"];
      }
    }

    super(messages.join("\n"));
    this.level = level;
    this.errors = errors;
    this.operation = operation;
    this.variables = variables;
    this.requestId = requestId;
    this.extra = extra;

    this.messages = messages;
  }

  debugMessage(): string {
    let errorInfo: string | undefined;
    if (this.errors.length === 1) {
      errorInfo = stringifyErrorIsh(this.errors[0]);
    } else {
      errorInfo = `${this.operation.name} ${this.errors.length} errors`;
    }

    return `GraphQL ${this.level} Error ${errorInfo}`;
  }
}

function stringifyErrorIsh(error: ResponseError) {
  if (typeof error === "string") {
    return error;
  }

  if ("field" in error && typeof error.field === "string" && error.field.length > 0) {
    return `${error.field} ${error.message}`;
  }

  return error.message;
}
