import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { defaultTheme } from "common/components/ui/shared";
import { Notification } from "common/components/ui/Notification";
import { Button } from "common/components/ui/Button";

import styled from "@emotion/styled";

export interface NonComplianceNotificationProps {
  count: number;
  onResolveClick: () => void;
}

const NonComplianceNotificationWrapper = styled.div`
  margin-bottom: ${defaultTheme.spacing.S4};
`;

const CountLink = styled.a`
  color: ${defaultTheme.colors.RED[300]};
  cursor: pointer;
  font-weight: bold;
`;

export const NonComplianceNotification: FC<NonComplianceNotificationProps> = React.memo(({ count, onResolveClick }) => {
  const { formatMessage } = useIntl();
  return (
    <NonComplianceNotificationWrapper>
      <Notification
        old
        appearance="DANGER"
        showIcon
        content={{
          title: formatMessage({
            defaultMessage: "Urgent Action Needed",
            id: "NonComplianceNotification.title",
          }),
          description: (
            <FormattedMessage
              defaultMessage={`We have received {productCount}. Please identify the products to complete the inbound.`}
              id="NonComplianceNotification.Body"
              tagName="div"
              values={{
                productCount: (
                  <CountLink onClick={onResolveClick}>
                    <FormattedMessage
                      defaultMessage={`${count} non-compliant products`}
                      id="NonComplianceNotification.Count"
                    />
                  </CountLink>
                ),
              }}
            />
          ),
        }}
        append={
          <Button appearance="DANGER" onClick={onResolveClick}>
            <FormattedMessage defaultMessage={`Resolve Non-Compliance`} id="NonComplianceNotification.Resolve" />
          </Button>
        }
      />
    </NonComplianceNotificationWrapper>
  );
});
