import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";

export enum DtcMixedSkuInputDimValues {
  LENGTH = "length",
  WIDTH = "width",
  HEIGHT = "height",
  WEIGHT = "weight",
  NUMBER_OF_BOXES = "numberOfPkgs",
}

export const getInputName = (index: number, dtcMixedSkuInputValuesName: DtcMixedSkuInputDimValues) =>
  `${CreateShipmentInputName.MIXED_SKU_PACKAGES}[${index}].${dtcMixedSkuInputValuesName}`;
