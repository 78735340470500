import React from "react";
import { useController } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { useSimpleFieldValidators } from "common/hooks/reactHookForm/useSimpleFieldValidators";
import { Box, defaultTheme, FormGroup, ThemeProps, Radio } from "common/components/ui";
import { InfoIcon } from "common/components/icons";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";
import { reactIntlRichTextEnhancer } from "common/components/reactIntlRichTextEnhancer";
import styled from "@emotion/styled";
import { DangerousGoodsWarning } from "../validationNotifications";
import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";

const INPUT_NAME = CreateShipmentInputName.HAS_DANGEROUS_GOODS;

const RadioContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    display: inline-grid;
    justify-content: start;
    gap: ${theme.spacing.S1};
  `
);

interface DangerousGoodsSelectorProps {
  value: boolean;
  onChange: (dangerousGoods: boolean) => void;
}

export enum DangerousGoodsError {
  NOT_DECLARED = "NOT_DECLARED",
}

const dangerousGoodsRadioLabel = (
  <FormattedMessage
    id="inbounds.createShipment.dangerousGoods.dangerousGoodsRadioLabel"
    defaultMessage="Are your products classified as fully regulated dangerous goods? {tooltip}"
    values={{
      tooltip: (
        <InfoIcon
          messageWidth={200}
          wrap
          placement={"top"}
          message={
            <FormattedMessage
              id="inbounds.createShipment.dangerousGoods.toggleLabel.tooltip"
              defaultMessage="Examples of potentially dangerous goods are batteries, magnetized materials, dry ice, vehicles, explosives, gases, aerosols, flammable materials, peroxides, oxidizers, toxic substances, radioactive materials, and acids. {elevioLearnMoreButton}"
              values={{
                elevioLearnMoreButton: <ElevioLearnMoreButton articleId={ElevioArticleId.DangerousGoods} asLink />,
              }}
            />
          }
        />
      ),
    }}
  />
);

const DANGEROUS_GOODS_OPTIONS = [
  {
    key: "hasDangerousGoods",
    value: true,
    label: (
      <FormattedMessage
        id="inbounds.createShipment.dangerousGoods.hasDangerousGoods"
        defaultMessage="<b>Yes</b>, my products are classified as fully regulated dangerous goods."
        values={reactIntlRichTextEnhancer()}
      />
    ),
  },
  {
    key: "noDangerousGoods",
    value: false,
    label: (
      <FormattedMessage
        id="inbounds.createShipment.dangerousGoods.noDangerousGoods"
        defaultMessage="<b>No</b>, my products are NOT classified as fully regulated dangerous goods."
        values={reactIntlRichTextEnhancer()}
      />
    ),
  },
];

const UnconnectedDangerousGoodsSelector = ({ value, onChange }: DangerousGoodsSelectorProps) => {
  return (
    <RadioContainer>
      {DANGEROUS_GOODS_OPTIONS.map((option) => (
        <Radio
          key={option.key}
          name={INPUT_NAME}
          label={option.label}
          value={option.key}
          onChange={() => onChange(option.value)}
          checked={value === option.value}
          labelStyles={`color: ${defaultTheme.colors.NEUTRAL["500"]}`}
        />
      ))}
    </RadioContainer>
  );
};

const DangerousGoodsSelector = () => {
  const { field } = useController({ name: INPUT_NAME });
  return <UnconnectedDangerousGoodsSelector value={field.value} onChange={field.onChange} />;
};

export const DangerousGoodsRadioGroup = () => {
  const { formatMessage } = useIntl();
  const { validateRequired } = useSimpleFieldValidators();
  const { watch } = useCreateShipmentFormContext();
  const hasDangerousGoods = watch(INPUT_NAME);

  // register field
  const { fieldState } = useController({
    name: INPUT_NAME,
    rules: {
      validate: {
        // using the built-in "required" rule would return an error when the value is false
        [DangerousGoodsError.NOT_DECLARED]: validateRequired(
          formatMessage({
            id: "reactHookForm.validators.required",
            defaultMessage: "Please declare whether or not your shipment contains dangerous goods.",
          })
        ),
      },
    },
  });
  return (
    <FormGroup
      as="div"
      label={dangerousGoodsRadioLabel}
      hasError={!!fieldState.error}
      errorMessage={fieldState.error?.message}
    >
      <DangerousGoodsSelector />
      {hasDangerousGoods !== null && (
        <Box paddingTop="S5">
          <DangerousGoodsWarning />
        </Box>
      )}
    </FormGroup>
  );
};
