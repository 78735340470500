import { FreightPalletTable } from "common/components/FreightPalletTable";
import { ValidationErrorsNotification } from "common/components/FreightPalletTable/ValidationErrorsNotification";
import React from "react";
import { STORAGE_MAX_FTL_CARGO_WEIGHT } from "../../../SelectShippingConstants";
import { StorageTotalCargoWeight } from "../StorageTotalCargoWeight/StorageTotalCargoWeight";
import { useStoragePalletTable } from "./useStoragePalletTable";

export const StoragePalletTable: React.FC = () => {
  const {
    isPalletTableDisabled,
    addPalletConfig,
    palletConfigurations,
    removePalletConfig,
    updatePalletConfig,
    hasChangesLocked,
    hasMaxConfig,
    hasPalletsLocked,
    palletSum,
    weightSum,
    palletDimsCompliant,
    palletDimsViolations,
    palletValidationMap,
    totalCargoWeight,
    onTotalCargoWeightUpdate,
    isTotalCargoWeightError,
  } = useStoragePalletTable();

  const storageTotalCargoWeightElement = (
    <StorageTotalCargoWeight
      isTotalCargoWeightError={isTotalCargoWeightError}
      totalCargoWeight={totalCargoWeight ?? STORAGE_MAX_FTL_CARGO_WEIGHT}
      onTotalCargoWeightUpdate={onTotalCargoWeightUpdate}
    />
  );
  const freightPalletTableElement = (
    <>
      <FreightPalletTable
        palletSum={palletSum}
        weightSum={weightSum}
        hasChangesLocked={hasChangesLocked}
        hasMaxConfig={hasMaxConfig}
        hasPalletsLocked={hasPalletsLocked}
        palletConfigurations={palletConfigurations}
        addPalletConfig={addPalletConfig}
        removePalletConfig={removePalletConfig}
        updatePalletConfig={updatePalletConfig}
      />
      {!palletDimsCompliant && palletDimsViolations?.length && (
        <ValidationErrorsNotification palletValidationMap={palletValidationMap} errors={palletDimsViolations} />
      )}
    </>
  );

  return isPalletTableDisabled ? storageTotalCargoWeightElement : freightPalletTableElement;
};
