import { ThemeProps, Card, Text } from "common/components/ui";
import styled from "@emotion/styled";

export const InboundStepContainer = styled.div`
  height: 700px;
`;

export const InboundStepCard = styled(Card)<ThemeProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InboundStepTitle = styled.h1<ThemeProps>(
  ({ theme }) => `
    font-family: Montserrat, sans-serif;
    font-weight: ${theme.font.weight.BOLD};
    font-size: ${theme.font.size.F4};
    margin-top: ${theme.spacing.S7};
    margin-bottom: ${theme.spacing.S4};
  `
);

export const InboundStepDescription = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S5};
    text-align: center;
    max-width: 520px;
  `
);

export const InboundStepContent = styled.div<ThemeProps>(
  ({ theme }) => `
    width: 760px;
    margin-bottom: ${theme.spacing.S7};
  `
);
