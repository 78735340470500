import { toast } from "common/components/ui";

export const flexportSearchParamsHelper = (): void => {
  const flexportConnected = new URLSearchParams(window.location.search).get("flexportConnected");

  if (flexportConnected === "false") {
    toast.error("Unable to connect Flexport account", {
      autoClose: 5000,
      toastId: "flexportConnectErrorToast",
    });
  }
};
