import { WholesaleShipmentStatus } from "@deliverr/wholesale-client";

class ShipmentStatusMapping {
  shouldShowShipBy? = false;
  shouldShowShippedAt? = false;
  isCancellable? = false;
}

export const mapWholesaleShipmentStatusUiConfigs: Record<WholesaleShipmentStatus, ShipmentStatusMapping> = {
  [WholesaleShipmentStatus.CANCELLED]: {},
  [WholesaleShipmentStatus.DELIVERED]: { shouldShowShippedAt: true },
  [WholesaleShipmentStatus.DRAFT]: { isCancellable: true },
  [WholesaleShipmentStatus.ERROR]: { isCancellable: true },
  [WholesaleShipmentStatus.FREIGHT_ORDERED]: { shouldShowShipBy: true },
  [WholesaleShipmentStatus.IN_TRANSIT]: { shouldShowShippedAt: true },
  [WholesaleShipmentStatus.PICKING_STARTED]: { shouldShowShipBy: true },
  [WholesaleShipmentStatus.READY_TO_SHIP]: { shouldShowShipBy: true },
  [WholesaleShipmentStatus.SHIPPED]: { shouldShowShippedAt: true },
  [WholesaleShipmentStatus.SUBMITTED]: { shouldShowShipBy: true, isCancellable: true },
  [WholesaleShipmentStatus.SCHEDULED]: { shouldShowShipBy: true, isCancellable: true },
};
