export enum ContentElement {
  section = "section",
  article = "article",
  header = "header",
  footer = "footer",
  div = "div",
  span = "span",
  p = "p",
  li = "li",
}

export enum HeaderElement {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
}

export enum ListElement {
  ol = "ol",
  li = "li",
}
