import { updateReplenishmentOrder } from "./updateReplenishmentOrder";
import { transferCreateSetReplenishmentOrder } from "../TransferCreateActions";
import { populateReplenishmentOrderWithPacks } from "storage/packUtils/populateReplenishmentOrderWithPacks";
import { OrderResponse } from "@deliverr/replenishment-client";

export const updateAndSetReplenishmentOrder = async (
  dispatch,
  orderId: string,
  submitDraft: boolean,
  createDraftOrderInChannel?: boolean
) => {
  const updatedReplenishmentOrderResponse: OrderResponse = await dispatch(
    updateReplenishmentOrder(orderId, submitDraft, createDraftOrderInChannel)
  );
  const replenishmentOrderWithPacks = await dispatch(
    populateReplenishmentOrderWithPacks(updatedReplenishmentOrderResponse)
  );
  await dispatch(transferCreateSetReplenishmentOrder(replenishmentOrderWithPacks));
};
