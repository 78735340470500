import { transfersShipmentCreateLabels } from "../../transfersShipmentCreate.labels";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ManagedStep, useStepManager } from "common/components/StepContainer";
import { Box, IconV2 } from "common/components/ui";
import { useFBACreateStatusMonitor } from "./useFBACreateStatusMonitor";
import { MonitorStatus } from "./MonitorStatus";

export const FbaWaitForShipmentCreation = () => {
  const { formatMessage } = useIntl();
  const { stepManager } = useStepManager();
  const { monitorStatus } = useFBACreateStatusMonitor();

  const canProceed = monitorStatus !== MonitorStatus.POLLING;
  const isTimeout = monitorStatus === MonitorStatus.TIMEOUT;

  useEffect(() => {
    if (canProceed) {
      stepManager.next({ isTimeout });
    }
  }, [stepManager, canProceed, isTimeout]);

  return (
    <ManagedStep
      title={formatMessage(transfersShipmentCreateLabels.steps.fbaIntegration.waitForShipmentCreation.title)}
      subtitle={
        <Box maxWidth="32rem">
          <FormattedMessage {...transfersShipmentCreateLabels.steps.fbaIntegration.waitForShipmentCreation.subtitle} />
        </Box>
      }
      header={
        <div>
          <IconV2 type="loading" size="large" spin data-testid="loading-spinner" />
        </div>
      }
      isNextDisabled={!canProceed}
      nextData={async () => {
        return { isTimeout };
      }}
    />
  );
};
