import { isEmpty } from "lodash/fp";
import { toast } from "common/components/ui";
import { useState, useCallback } from "react";
import { useController } from "react-hook-form";
import { logError, logStart } from "Logger";
import { defineMessages, useIntl } from "react-intl";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";
import {
  usePlaceSearchOriginQuery,
  usePlaceSearchOriginQuery$data,
} from "common/relay/__generated__/usePlaceSearchOriginQuery.graphql";
import {
  usePlaceSearchDestinationQuery,
  usePlaceSearchDestinationQuery$data,
} from "common/relay/__generated__/usePlaceSearchDestinationQuery.graphql";
import debounce from "lodash/debounce";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm/useCreateShipmentForm.types";
import { PlaceType } from "inbounds/createShipment/CreateShipmentTypes";
import { createShipmentInitialFormState } from "inbounds/createShipment/store";
import { InputActionMeta } from "react-select";

const originSearchQuery = graphql`
  query usePlaceSearchOriginQuery($query: String, $isAddressOnly: Boolean) {
    originSearch(query: $query, isAddressOnly: $isAddressOnly) {
      cities {
        address
        streetAddress
        city
        state
        country
        zipCode
        latitude
        longitude
        countryCode
      }
      ports {
        city {
          city
          country
          countryCode
        }
        nearbyPorts {
          abbr
          fid
        }
        distanceKm
      }
    }
  }
`;

export const destinationSearchQuery = graphql`
  query usePlaceSearchDestinationQuery($query: String, $isAddressOnly: Boolean) {
    destinationSearch(query: $query, isAddressOnly: $isAddressOnly) {
      cities {
        address
        streetAddress
        city
        state
        country
        zipCode
        latitude
        longitude
        countryCode
      }
      ports {
        city {
          city
          state
          countryCode
        }
        nearbyPorts {
          abbr
          fid
        }
        distanceKm
      }
    }
  }
`;

interface OptionTitle {
  title: string;
  description: string;
}

interface OptionGroup {
  options: PlaceType[] | undefined;
  label: OptionTitle;
}

const formattedCityLabel = (
  city:
    | NonNullable<usePlaceSearchOriginQuery$data["originSearch"]["cities"]>[0]
    | NonNullable<usePlaceSearchDestinationQuery$data["destinationSearch"]["cities"]>[0]
) => {
  return `${city?.city}, ${city?.country ?? city?.state}${city?.zipCode ? `, ${city?.zipCode}` : ""}`;
};

const formattedAddressValue = (
  address:
    | NonNullable<usePlaceSearchOriginQuery$data["originSearch"]["cities"]>[0]
    | NonNullable<usePlaceSearchDestinationQuery$data["destinationSearch"]["cities"]>[0]
) => {
  return {
    name: address?.address ?? "",
    street1: address?.streetAddress ?? "",
    city: address?.city ?? "",
    country: address?.country ?? "",
    state: address?.state ?? "",
    zip: address?.zipCode ?? "",
    latLng:
      address?.longitude && address?.latitude
        ? {
            lat: address?.latitude ?? null,
            lng: address?.longitude ?? null,
          }
        : undefined,
    countryCode: address?.countryCode ?? "",
  };
};

const formattedPortLabel = (
  port:
    | NonNullable<usePlaceSearchOriginQuery$data["originSearch"]["ports"]>[0]
    | NonNullable<usePlaceSearchDestinationQuery$data["destinationSearch"]["ports"]>[0]
) => {
  if (!port) {
    return "";
  }
  const stateOrCountry = "state" in port.city ? port.city?.state : port.city?.country;
  return `All ports near ${port.city?.city}, ${stateOrCountry} (${port.nearbyPorts
    ?.map((nearbyPort) => nearbyPort?.abbr)
    .filter((abbr) => !isEmpty(abbr))
    .join(", ")})`;
};

const formattedPortValue = (
  port:
    | NonNullable<usePlaceSearchOriginQuery$data["originSearch"]["ports"]>[0]
    | NonNullable<usePlaceSearchDestinationQuery$data["destinationSearch"]["ports"]>[0]
) => {
  return (
    port?.nearbyPorts?.map((nearByPort) => {
      return {
        abbr: nearByPort?.abbr ?? "",
        fid: nearByPort?.fid ?? "",
      };
    }) ?? []
  );
};

export const formattedCityOption = (
  cities:
    | usePlaceSearchOriginQuery$data["originSearch"]["cities"]
    | usePlaceSearchDestinationQuery$data["destinationSearch"]["cities"]
) => {
  return cities?.slice(0, 4).map((city) => {
    return {
      value: {
        address: formattedAddressValue(city),
        ports: [],
        distanceKm: 0,
      },
      label: city?.address ?? formattedCityLabel(city),
    };
  });
};

const formattedPortOption = (
  ports:
    | usePlaceSearchOriginQuery$data["originSearch"]["ports"]
    | usePlaceSearchDestinationQuery$data["destinationSearch"]["ports"]
) => {
  return ports?.slice(0, 4).map((port) => {
    return {
      value: {
        ports: formattedPortValue(port),
        address: formattedAddressValue(port?.city),
        distanceKm: port?.distanceKm,
      },
      label: formattedPortLabel(port),
    };
  });
};

const PLACE_SEARCH_GROUP_LABELS = {
  cities: defineMessages({
    title: { id: "placeSearch.locationTitle", defaultMessage: "Location" },
    description: { id: "placeSearch.citiesDescription", defaultMessage: "Includes trucking" },
  }),
  ports: defineMessages({
    title: { id: "placeSearch.portsTitle", defaultMessage: "Ports " },
    description: {
      id: "placeSearch.portsDescription",
      defaultMessage: "Does not include trucking",
    },
  }),
};

const usePlaceSearchToDropdown = (isPortOnly?: boolean) => {
  const { formatMessage } = useIntl();
  const placeSearchToDropDown = useCallback(
    (
      placeSearch:
        | usePlaceSearchOriginQuery$data["originSearch"]
        | usePlaceSearchDestinationQuery$data["destinationSearch"]
    ): OptionGroup[] => {
      const cityOptions = {
        label: {
          title: formatMessage(PLACE_SEARCH_GROUP_LABELS.cities.title),
          description: formatMessage(PLACE_SEARCH_GROUP_LABELS.cities.description),
        },
        options: formattedCityOption(placeSearch?.cities),
      };
      const portOptions = {
        label: {
          title: formatMessage(PLACE_SEARCH_GROUP_LABELS.ports.title),
          description: formatMessage(PLACE_SEARCH_GROUP_LABELS.ports.description),
        },
        options: formattedPortOption(placeSearch?.ports),
      };
      return isPortOnly ? [portOptions] : [cityOptions, portOptions];
    },
    [formatMessage, isPortOnly]
  );

  return placeSearchToDropDown;
};

export const usePlaceSearch = (
  type: CreateShipmentInputName.ORIGIN | CreateShipmentInputName.DESTINATION,
  isAddressReturningUserOnly: boolean,
  isPortOnly?: boolean
) => {
  const { formatMessage } = useIntl();
  const isOriginSearch = type === CreateShipmentInputName.ORIGIN;
  const environment = useRelayEnvironment();
  const ctx = isOriginSearch ? logStart({ fn: "getOriginSearch" }) : logStart({ fn: "getDestinationSearch" });
  const { field } = useController({ name: type });
  const placeOption = field.value;
  const [isSelected, setIsSelected] = useState<boolean>(placeOption !== null);
  const placeSearchToDropDown = usePlaceSearchToDropdown(isPortOnly);
  const [inputValue, setInputValue] = useState(field.value?.label);

  const handleSelect = (value: PlaceType) => {
    if (value !== null) {
      field.onChange(value);
      setInputValue(value.label);
    } else {
      field.onChange({
        value: {
          address: createShipmentInitialFormState.origin.value.address,
          ports: [],
        },
        label: "",
      });
    }
    setIsSelected(true);
  };

  const handleInputChange = (value: string, { action }: InputActionMeta) => {
    if (action === "input-change") {
      field.onChange({
        value: {
          address: createShipmentInitialFormState.origin.value.address,
          ports: [],
        },
        label: isEmpty(value.trim()) ? "" : value,
      });
      setInputValue(value);
      setIsSelected(false);
    }
  };

  const isMenuOpen = placeOption.label.length !== 0 && !isSelected;

  const handleBlur = () => {
    setIsSelected(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const originLoadOptions = useCallback(
    debounce((input: string, callback: (options: readonly OptionGroup[]) => void) => {
      fetchQuery<usePlaceSearchOriginQuery>(environment, originSearchQuery, {
        query: input,
        isAddressOnly: isAddressReturningUserOnly,
      }).subscribe({
        next: ({ originSearch }) => {
          callback(placeSearchToDropDown(originSearch));
        },
        start: () => {},
        error: (error) => {
          logError(ctx, error);
          toast.error(formatMessage({ id: "originSearch.error", defaultMessage: "Error searching for origins" }), {
            toastId: "originSearch.error",
          });
        },
      });
    }, Number(process.env.DEFAULT_SEARCH_THROTTLE_MS)),
    [placeSearchToDropDown, environment, originSearchQuery, isAddressReturningUserOnly]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const destinationLoadOptions = useCallback(
    debounce((input: string, callback: (options: OptionGroup[]) => void) => {
      fetchQuery<usePlaceSearchDestinationQuery>(environment, destinationSearchQuery, {
        query: input,
        isAddressOnly: isAddressReturningUserOnly,
      }).subscribe({
        next: ({ destinationSearch }) => {
          callback(placeSearchToDropDown(destinationSearch));
        },
        start: () => {},
        error: (error) => {
          logError(ctx, error);
          toast.error(
            formatMessage({ id: "destinationSearch.error", defaultMessage: "Error searching for destinations" }),
            {
              toastId: "destinationSearch.error",
            }
          );
        },
      });
    }, Number(process.env.DEFAULT_SEARCH_THROTTLE_MS)),
    [placeSearchToDropDown, environment, destinationSearchQuery, isAddressReturningUserOnly]
  );

  const loadOptions = isOriginSearch ? originLoadOptions : destinationLoadOptions;

  return { inputValue, placeOption, handleSelect, isMenuOpen, handleInputChange, handleBlur, loadOptions };
};
