import React, { FC } from "react";
import { NavbarEditableItem } from "common/components/NavbarEditableItem";
import { useStorageNavbarShippingPlanName } from "./useStorageNavbarShippingPlanName";

export const StorageNavbarShippingPlanName: FC = () => {
  const { displayName, isLoading, handleSubmit } = useStorageNavbarShippingPlanName();

  return (
    <NavbarEditableItem
      displayItemValue={displayName}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      testId={{
        nameInput: "StorageNavbarShippingPlanName__nameInput",
        nameWrapper: "StorageNavbarShippingPlanName__nameWrapper",
      }}
    />
  );
};
