import React, { FC } from "react";

import { ProblemType } from "common/problems/ProblemType";
import {
  DamagedProductNonComplianceRow,
  MissingBarcodeNonComplianceRow,
  UnexpectedBarcodeNonComplianceRow,
  UnexpectedSkuNonComplianceRow,
  UnknownBarcodeNonComplianceRow,
} from "../types";
import { UnknownBarcodeNonComplianceBlade } from "./unknown-barcode";
import { MissingBarcodeNonComplianceBlade } from "./missing-barcode";
import { UnexpectedBarcodeNonComplianceBlade } from "./unexpected-barcode";
import { DamagedProductNonComplianceBlade } from "./damaged-product/DamagedProductNonComplianceBlade";
import { ProblemRowData } from "common/components/ProblemsTable/types";
import { UnexpectedSkuNonComplianceBlade } from "./unexpected-sku/UnexpectedSkuNonComplianceBlade";
import { MismatchCaseQtyNonComplianceBlade } from "./mismatch-case-qty/MismatchCaseQtyNonComplianceBlade";
import { GenericIssueNonComplianceBlade } from "./generic-issue/GenericIssueNonComplianceBlade";

const getBladeByNCCaseType = (nonComplianceCase: ProblemRowData) => {
  if (!nonComplianceCase.isLegacyCase) {
    return <GenericIssueNonComplianceBlade problem={nonComplianceCase} />;
  }
  switch (nonComplianceCase.problemType) {
    case ProblemType.UNKNOWN_BARCODE:
      return (
        <UnknownBarcodeNonComplianceBlade nonComplianceCase={nonComplianceCase as UnknownBarcodeNonComplianceRow} />
      );
    case ProblemType.MISSING_BARCODE:
      return (
        <MissingBarcodeNonComplianceBlade nonComplianceCase={nonComplianceCase as MissingBarcodeNonComplianceRow} />
      );
    case ProblemType.UNEXPECTED_BARCODE:
      return (
        <UnexpectedBarcodeNonComplianceBlade
          nonComplianceCase={nonComplianceCase as UnexpectedBarcodeNonComplianceRow}
        />
      );
    case ProblemType.DAMAGED_PRODUCT:
      return (
        <DamagedProductNonComplianceBlade nonComplianceCase={nonComplianceCase as DamagedProductNonComplianceRow} />
      );
    case ProblemType.UNEXPECTED_SKU:
      return <UnexpectedSkuNonComplianceBlade nonComplianceCase={nonComplianceCase as UnexpectedSkuNonComplianceRow} />;
    case ProblemType.MISMATCH_CASE_QTY:
      return (
        <MismatchCaseQtyNonComplianceBlade nonComplianceCase={nonComplianceCase as UnexpectedSkuNonComplianceRow} />
      );
    default:
      return null;
  }
};

interface NonComplianceListBladeProps {
  nonComplianceCase?: ProblemRowData;
}

export const NonComplianceListBlade: FC<NonComplianceListBladeProps> = ({ nonComplianceCase }) =>
  nonComplianceCase ? getBladeByNCCaseType(nonComplianceCase) : null;
