import { RootState } from "RootReducer";
import { FlowEnabledStepMap } from "./FlowEnabledStepMap";

export const getEnabledFlowSteps = <FlowStepType extends string>(
  flowStepType: { [key: string]: FlowStepType },
  flowEnabledStepMap: FlowEnabledStepMap<FlowStepType>,
  state: RootState
) => {
  const flowSteps = Object.values(flowStepType);
  return flowSteps.filter((step) => flowEnabledStepMap[step]?.(state));
};
