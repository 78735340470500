import { BarcodeFormat, BarcodeFormatErrors } from "common/components/ui/utils/BarcodeUtils";
import React from "react";
import { FormattedMessage } from "react-intl";

export const BARCODE_OPTIONS = {
  font: "Courier",
  fontSize: 24,
  width: 3,
  height: 48,
};

export const BARCODE_LIMIT = 5;

export const barcodeFormatToDisplay = {
  [BarcodeFormat.DSKU]: <FormattedMessage id={"BarcodeInput.dsku"} defaultMessage={"Flexport (DSKU)"} />,
  [BarcodeFormat.UPC]: <FormattedMessage id={"BarcodeInput.upc"} defaultMessage={"Manufacturer (UPC/EAN/ISBN)"} />,
  [BarcodeFormat.FNSKU]: <FormattedMessage id={"BarcodeInput.fnsku"} defaultMessage={"Amazon (FNSKU/ASIN)"} />,
  [BarcodeFormatErrors.UNKNOWN]: <FormattedMessage id={"BarcodeInput.custom"} defaultMessage={"Custom"} />,
};

export const unknownBarcodeDisplay = <FormattedMessage id={"BarcodeInput.other"} defaultMessage={"Other"} />;
