import { connectStartPage } from "../connect/pages/ConnectStart/connectStartPage";
import { RECONFIGURE_FACEBOOK, SYNC_AD_SETS, UNSYNC_AD_SETS } from "./FacebookEditAction";
import { syncAdsWarningPage } from "./pages/SyncAdsWarning/syncAdsWarningPage";
import { unsyncAdSetsPage } from "./pages/UnsyncAdSets/unsyncAdSetsPage";

export const actionToPage = {
  [UNSYNC_AD_SETS]: unsyncAdSetsPage,
  [SYNC_AD_SETS]: syncAdsWarningPage,
  [RECONFIGURE_FACEBOOK]: connectStartPage,
};
