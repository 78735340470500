import { RootState } from "RootReducer";
import { fetchPrimaryPaymentSource } from "billing/BillingActions";
import { FeatureName, useFeatureOn } from "common/Split";
import { getShouldShowCheckoutModalOnClick } from "inbounds/steps/ship/ShipmentSetupSelectors";
import { useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";

export type PaymentType = "CARD" | "BANK_ACCOUNT" | "PAYPAL";
export type CardBrand = "visa" | "mastercard" | "american_express" | "discover";

export const usePrimaryPaymentSource = () => {
  const dispatch = useDispatch();

  const { loaded, data } = useSelector((state: RootState) => state.billing.primaryPaymentSource);
  const { card, paypal, bank_account } = data;
  let paymentType: PaymentType | undefined;
  let lastDigits: string | undefined;
  let email: string | undefined;
  let brand: string | undefined;
  if (card) {
    paymentType = "CARD";
    lastDigits = card.last4;
    brand = card.brand;
  } else if (paypal) {
    paymentType = "PAYPAL";
    email = paypal.email;
  } else if (bank_account) {
    paymentType = "BANK_ACCOUNT";
    lastDigits = bank_account.last4;
  }
  const isCreditCardFeeEnabled = useFeatureOn(FeatureName.SmbCreditCardFee);
  const hasCreditCardFee = isCreditCardFeeEnabled && (paymentType === "CARD" || paymentType === "PAYPAL");
  const hasNotCreatedChargebeeAccount = useSelector(getShouldShowCheckoutModalOnClick);

  useMount(() => {
    if (!hasNotCreatedChargebeeAccount) {
      dispatch(fetchPrimaryPaymentSource());
    }
  });

  return { loaded, isCreditCardFeeEnabled, hasCreditCardFee, paymentType, lastDigits, email, brand };
};
