import { ListingSolution } from "common/clients/onboarding/ListingSolution";

import { Stack, Button, Title, LocalizedMessage, DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import { ElevioArticleId, openArticle } from "common/elevio";
import { listingConfigProp } from "common/ListingSolutionUtils";
import { DuplicateConnectionNotice } from "onboarding/ListingTool/DuplicateConnectionNotice";
import { OnboardingStepLayout } from "onboarding/components";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { TextAlign } from "common/components/ui/shared";
import { FeatureName, isFeatureOn } from "common/Split";
import { useLoader } from "common/components/WithLoader/useLoader";

export interface IntegrationSetupFormProps {
  channel: ListingSolution;
  onSubmit?: () => void;
  children?: React.ReactNode;
  isSubmitDisabled?: boolean;
  submitButtonLabel?: string;
  hasPotentialDoubleConnect?: boolean;
  title?: LocalizedMessage;
  showHelp?: boolean;
  isSubmitting?: boolean;
  hideInstructions?: boolean;
}

const Logo = styled.div`
  text-align: center;

  svg {
    width: 3rem;
    height: 3rem;
  }
`;

const Body = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    margin: auto;

    @media (min-width: ${theme.breakpoints.MD}) {
      width: 500px;
    }
  `
);

const NoMarginTitle = styled(Title)`
  margin-top: 0;
`;

export const IntegrationSetupForm: FC<IntegrationSetupFormProps> = ({
  children,
  onSubmit,
  channel,
  hasPotentialDoubleConnect,
  submitButtonLabel,
  title,
  isSubmitDisabled,
  isSubmitting,
  showHelp = true,
}) => {
  const channelName = listingConfigProp(channel, "name") as string;
  const articleId = listingConfigProp(channel, "helpArticleId") as ElevioArticleId;
  const logo = listingConfigProp(channel, "logo");
  const isListingToolLoaderActive = useLoader("listingTool");
  const isFormSubmitting = isSubmitting ?? isListingToolLoaderActive;

  const connectMessage = (
    <FormattedMessage
      id="onboarding.integrationSetup.connectChannel"
      defaultMessage="Connect {channelName}"
      values={{ channelName }}
    />
  );

  const heapId = isFeatureOn(FeatureName.PrimarySalesChannelBanner)
    ? `integration_setup_submit-${channelName}-banner`
    : `integration_setup_submit-${channelName}`;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit?.();
      }}
    >
      <OnboardingStepLayout
        body={
          <Body>
            <Stack gap="S5">
              <Logo>{logo}</Logo>
              <div>
                <NoMarginTitle as="h2" displayAs="h3" textAlign={TextAlign.center}>
                  {title ?? connectMessage}
                </NoMarginTitle>
              </div>
              {children && (
                <div>
                  <Stack gap="S4">{children}</Stack>
                </div>
              )}
              {hasPotentialDoubleConnect && <DuplicateConnectionNotice />}
            </Stack>
          </Body>
        }
        footerLeft={
          showHelp ? (
            <Button secondary onClick={() => openArticle(articleId)}>
              <FormattedMessage id="onboarding.integrationSetup.getHelp" defaultMessage="Get help" />
            </Button>
          ) : undefined
        }
        footerRight={
          onSubmit ?? isSubmitting ? (
            <Button
              disabled={isSubmitDisabled}
              data-testid="integration-setup-submit-button"
              data-heapid={heapId}
              type="submit"
              loading={isFormSubmitting}
            >
              {submitButtonLabel ?? connectMessage}
            </Button>
          ) : undefined
        }
      />
    </form>
  );
};
