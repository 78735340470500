import { createActionCreator } from "common/ReduxUtils";
import { InboundActionTypes } from "inbounds/store/InboundActionTypes";
import { InboundStep } from "inbounds/InboundTypes";

/**
 * this is used to persist the step when saving the inbound to local storage. The value is NOT used to return the seller to this step, but to assess whether the
 * inbound has fallen out of date.
 * See InboundLoadActions > savedInboundIsOutOfDate
 */

export const setCurrentStep = createActionCreator<InboundStep>(InboundActionTypes.SET_CURRENT_STEP, "step");
