export enum BulkUploadError {
  FILE_BAD_EXTENSION = "FILE_BAD_EXTENSION",
  FILE_TOO_BIG = "FILE_TOO_BIG",
  SHEET_OVERFILLED = "SHEET_OVERFILLED",
  SHEET_NOT_FOUND = "SHEET_NOT_FOUND",
  SHEET_EMPTY = "SHEET_EMPTY",
  SHEET_COLUMN_EXTRANEOUS = "SHEET_COLUMN_EXTRANEOUS",
  SHEET_ROW_EXTRANEOUS_REPLENISHMENT = "SHEET_ROW_EXTRANEOUS_REPLENISHMENT",
  SHEET_ROW_EXTRANEOUS = "SHEET_ROW_EXTRANEOUS",
  VALUE_BAD_STRING = "VALUE_BAD_STRING",
  VALUE_BAD_WHOLE_NUMBER = "VALUE_BAD_WHOLE_NUMBER",
  VALUE_BAD_NUMBER = "VALUE_BAD_NUMBER",
  VALUE_MISSING = "VALUE_MISSING",
  PRODUCT_DIMS_OVERSIZED = "PRODUCT_DIMS_OVERSIZED",
  PRODUCT_DOES_NOT_EXIST = "PRODUCT_DOES_NOT_EXIST",
  MSKU_DUPLICATE = "MSKU_DUPLICATE",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
  UNABLE_TO_IMPORT_PRODUCTS = "UNABLE_TO_IMPORT_PRODUCTS",
  UNABLE_TO_IMPORT_PRODUCTS_TRY_AGAIN = "UNABLE_TO_IMPORT_PRODUCTS_TRY_AGAIN",
  UNABLE_TO_IMPORT_PRODUCTS_LINK = "UNABLE_TO_IMPORT_PRODUCTS_LINK",
  SHEET_TYPE_MISMATCH = "SHEET_TYPE_MISMATCH",
  CASE_PACKED_TEMPLATE = "CASE_PACKED_TEMPLATE",
  INDIVIDUAL_TEMPLATE = "INDIVIDUAL_TEMPLATE",
  MSKU_DSKU_MISMATCH = "MSKU_DSKU_MISMATCH",
  MSKU_CASE_PACK_MISMATCH = "MSKU_CASE_PACK_MISMATCH",
  DSKU_CASE_PACK_MISMATCH = "DSKU_CASE_PACK_MISMATCH",
  PRODUCT_ASIN_MISSING = "PRODUCT_ASIN_MISSING",
  LARGE_CASE_PACK = "LARGE_CASE_PACK",
  MISSING_IDENTIFIER = "MISSING_IDENTIFIER",
  INVENTORY_NOT_AVAILABLE = "INVENTORY_NOT_AVAILABLE",
  PRODUCT_INVENTORY_UNAVAILABLE = "PRODUCT_INVENTORY_UNAVAILABLE",
  BOX_BAD_WEIGHT = "BOX_BAD_WEIGHT",
  BOX_BAD_DIMENSIONS = "BOX_BAD_DIMENSIONS",
}
