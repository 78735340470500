import React, { FC } from "react";
import { useSelector } from "react-redux";

import { UnknownBarcodeProblemRow } from "common/components/ProblemsTable/types";
import { UnknownBarcodeBlade } from "common/components/ProblemBlade/UnknownBarcodeBlade";
import { SHIPMENT_PROBLEMS_UNKNOWN_BARCODE_BLADE_ID } from "../blades";
import { getShippingPlan } from "inbounds/steps/InboundStepSelectors";
import { useFetchNCProduct } from "inbounds/hooks";
import { SelectedShipmentProblem } from "../../context";

export interface ShipmentProblemUnknownBarcodeBladeProps {
  shipmentProblem: UnknownBarcodeProblemRow;
  hideShipmentProblemBlade: () => void;
  setSelectedProblem: (problem: SelectedShipmentProblem | undefined) => void;
  isShipmentProblemBladeOpen: boolean;
}

export const ShipmentProblemUnknownBarcodeBlade: FC<ShipmentProblemUnknownBarcodeBladeProps> = ({
  shipmentProblem,
  hideShipmentProblemBlade,
  setSelectedProblem,
  isShipmentProblemBladeOpen,
}) => {
  const { id: sellerProblemId, mappedSkus } = shipmentProblem;
  const { value: ncProduct } = useFetchNCProduct({ sellerProblemId });

  const { name: shippingPlanName, id: shippingPlanId } = useSelector(getShippingPlan);

  const resetBladeState = () => {
    hideShipmentProblemBlade();
    setSelectedProblem(undefined);
  };

  return (
    <UnknownBarcodeBlade
      id={SHIPMENT_PROBLEMS_UNKNOWN_BARCODE_BLADE_ID}
      shipmentProblem={shipmentProblem}
      onShippingPlanClick={resetBladeState}
      shippingPlanId={shippingPlanId}
      shippingPlanName={shippingPlanName}
      onCloseClick={resetBladeState}
      isBladeOpen={isShipmentProblemBladeOpen}
      product={mappedSkus[0]}
      images={ncProduct?.rawPhotoKeys ?? []}
    />
  );
};
