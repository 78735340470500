export enum InboundShipmentActionTypes {
  SET_BOX_ARRANGEMENT = "SET_BOX_ARRANGEMENT",
  ATTEMPT_CONFIRM_BOXES = "ATTEMPT_CONFIRM_BOXES",
  CONFIRM_BOXES = "CONFIRM_BOXES",
  ROLLBACK_CONFIRM_BOXES = "ROLLBACK_CONFIRM_BOXES",
  SAVE_PACKAGES_SUCCESS = "SAVE_PACKAGES_SUCCESS",
  COST_ESTIMATE_COMPLETE = "COST_ESTIMATE_COMPLETE",
  COST_ESTIMATE_ERROR = "COST_ESTIMATE_ERROR",
  LOAD_ASNS = "LOAD_ASNS",
  SET_PACKAGES_FOR_SHIPMENT = "SET_PACKAGES_FOR_SHIPMENT",
  UPDATE_SHIPMENT = "UPDATE_SHIPMENT",
  UPDATE_SHIPMENT_ID = "UPDATE_SHIPMENT_ID",
  CLEAR_RATES = "CLEAR_RATES",
  REPLACE_SHIPMENT = "REPLACE_SHIPMENT",
  SET_PALLET_COMPLIANCE = "SET_PALLET_COMPLIANCE",
  CONFIRM_PALLETS = "CONFIRM_PALLETS",
  ATTEMPT_SAVE_BOX_CONFIGURATIONS = "ATTEMPT_SAVE_BOX_CONFIGURATIONS",
  SAVE_BOX_CONFIGURATIONS = "SAVE_BOX_CONFIGURATIONS",
  ROLLBACK_SAVE_BOX_CONFIGURATIONS = "ROLLBACK_SAVE_BOX_CONFIGURATIONS",
}
