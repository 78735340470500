import { CurrencyFormat, DEFAULT_CURRENCY } from "common/components/Currency";
import { truncateDecimal } from "common/utils/number";
import { FormatNumberOptions, useIntl } from "react-intl";

export type CurrencyValue = string | JSX.Element;

export interface UseCurrencyValueOptions {
  currency?: CurrencyFormat;
  maximumFractionDigits?: number;
  fallback?: CurrencyValue;
  truncate?: boolean;
  truncateNonFractional?: boolean;
}

const DEFAULT_OPTIONS: UseCurrencyValueOptions = {
  currency: DEFAULT_CURRENCY,
  maximumFractionDigits: 2,
  fallback: "",
};

export const useCurrencyValue = ({
  currency,
  maximumFractionDigits,
  fallback,
  truncate,
  truncateNonFractional,
} = DEFAULT_OPTIONS) => {
  const { formatNumber } = useIntl();

  const currencyOptions: FormatNumberOptions = {
    style: "currency",
    currencyDisplay: "symbol",
    currency: currency ?? DEFAULT_OPTIONS.currency,
    maximumFractionDigits: maximumFractionDigits ?? DEFAULT_OPTIONS.maximumFractionDigits,
  };

  return (value?: number): CurrencyValue => {
    const isNonFractional = value !== undefined && value % 1 === 0;
    const actualMaxFractionalDigits =
      truncateNonFractional && isNonFractional ? 0 : currencyOptions.maximumFractionDigits;
    const shouldTruncate = truncate ?? truncateNonFractional;
    const transformedVal = shouldTruncate ? truncateDecimal(value, actualMaxFractionalDigits) : value;
    return transformedVal !== undefined
      ? formatNumber(transformedVal, {
          ...currencyOptions,
          maximumFractionDigits: actualMaxFractionalDigits,
        })
      : fallback ?? DEFAULT_OPTIONS.fallback!;
  };
};
