import React from "react";
import styled from "@emotion/styled";
import { CheckableTile, CheckableTileProps, Grid } from "common/components/ui";
import HtmlToReact from "common/components/HtmlToReact";

const ImgWrapper = styled.figure`
  float: right;
  width: 60px;
  height: 60px;
`;

interface OptionProps extends Omit<CheckableTileProps, "id" | "type" | "imgContent" | "block" | "reverse"> {
  imgHtml?: string;
  id: string;
}

interface CheckableTileSelectorProps extends Pick<CheckableTileProps, "reverse" | "type" | "height"> {
  options: OptionProps[];
}

export const CheckableTileSelector = ({ options, reverse, type, height }: CheckableTileSelectorProps) => {
  return (
    <Grid columns="1fr 1fr">
      {options.map((option) => (
        <CheckableTile
          optionId={`option-${option.id}`}
          key={option.id}
          label={option.label}
          content={option.content}
          imgContent={
            option.imgHtml ? (
              <ImgWrapper>
                <HtmlToReact html={option.imgHtml} />
              </ImgWrapper>
            ) : undefined
          }
          checked={option.checked}
          onChange={option.onChange}
          type={type}
          reverse={reverse}
          disabled={option.disabled}
          height={height}
          block
        />
      ))}
    </Grid>
  );
};
