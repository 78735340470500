import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { FbaDeliveryDetailsState } from "freight/types/FbaDeliveryTypes";
import { FbaDeliveryDetailsActionTypes } from "../actions/FbaDeliveryDetailsActionTypes";
import { fbaDeliveryDetailsInitialState } from "./freightFbaDeliveryDetailsInitialState";
import { FreightBookingActionTypes } from "freight/store/freightBooking/actions/FreightBookingActionTypes";

const reducer: ReducerDictionary<FbaDeliveryDetailsState> = {
  ...handleSimpleReducerUpdates([
    FbaDeliveryDetailsActionTypes.SET_DELIVERY_ADDRESS,
    FbaDeliveryDetailsActionTypes.SET_SUPPORTED_FIELDS,
    FbaDeliveryDetailsActionTypes.SET_AMAZON_REFERENCE_ID,
    FbaDeliveryDetailsActionTypes.SET_AMAZON_SHIPMENT_ID,
  ]),
  [FreightBookingActionTypes.RESET_FREIGHT_BOOKING_STATE]: () => fbaDeliveryDetailsInitialState,
};
export const fbaDeliveryDetailsReducer = createReducer<FbaDeliveryDetailsState>(
  fbaDeliveryDetailsInitialState,
  reducer
);
