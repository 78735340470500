import { Product } from "@deliverr/commons-clients";
import { ATP } from "common/clients/inventory/ATP/ATP";
import React, { HtmlHTMLAttributes } from "react";
import cls from "./BarcodeDetails.less";
import { ProductCategoryLabel } from "common/components/ProductCategoryLabel";
import { getProductSku } from "inbounds/steps/ship/utils/getProductSku";

interface OwnProps extends HtmlHTMLAttributes<HTMLTableCellElement> {
  product: Product & Partial<ATP>;
}

const BarcodeDetails = ({ product, ...props }: OwnProps) => {
  return (
    <td {...props}>
      <div className={cls.productName}>{product.name}</div>
      <div className={cls.productDetails}>
        <span className={cls.detail}>{getProductSku(product)}</span>
        <span className={cls.detail}>
          <ProductCategoryLabel product={product} />
        </span>
      </div>
    </td>
  );
};

export default BarcodeDetails;
