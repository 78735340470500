import { BarcodeResponse, Product } from "@deliverr/commons-clients";
import {
  BarcodeFormat,
  BarcodeFormatErrors,
  getActiveUniqueBarcodesCount,
  transformToBarcodeEditCollection,
} from "common/components/ui/utils/BarcodeUtils";
import { BARCODE_LIMIT, barcodeFormatToDisplay, unknownBarcodeDisplay } from "common/BarcodeOptions";
import { find } from "lodash";
import { isEmpty, map, pipe, reject } from "lodash/fp";
import { FormEvent, SyntheticEvent, useEffect, useMemo, useReducer } from "react";
import { getBarcodesDraft, updateBarcodesDraft } from "./BarcodeDraft";
import {
  BarcodeActionTypes,
  barcodeDefaultState,
  BarcodeEditCollection,
  BarcodeEditDraftAction,
  barcodeEditReducer,
} from "./BarcodeEditReducer";
import { createDuplicatedBarcodeResponse } from "./serializer";
import { useBarcodeValidateService } from "./useBarcodeValidateService";
import { SelectOption } from "common/utils/types";
import { FeatureName, useFeatureOn } from "common/Split";
import { ProductBarcode } from "inbounds/InboundTypes";
import { useMount } from "react-use";

export interface BarcodeEditChangeResult {
  [dsku: string]: BarcodeEditCollection;
}

export interface BarcodeEditComponentProps {
  product: Product;
  onChange: (barcodeCollection: BarcodeEditChangeResult) => void;
  serviceError?: BarcodeResponse[] | boolean;
  preAddBarcode?: boolean;
  pageDuplicatedBarcodes?: string[];
  plannedBarcodes?: ProductBarcode[];
}

export default function useBarcodeEdit(props: BarcodeEditComponentProps) {
  const allowsCustomBarcodes = useFeatureOn(FeatureName.CustomBarcodes);
  const [state, dispatch] = useReducer(barcodeEditReducer, barcodeDefaultState);
  const hasSelectedDSKU = state.barcodesCollection && find(state.barcodesCollection, ["format", BarcodeFormat.DSKU]);
  const { validationResult, runServiceValidation } = useBarcodeValidateService();

  const barcodeOptions = useMemo<SelectOption<BarcodeFormat | BarcodeFormatErrors>[]>(() => {
    const options: SelectOption<BarcodeFormat | BarcodeFormatErrors>[] = Object.values(BarcodeFormat)
      .filter((format) => !isEmpty(format) && format !== BarcodeFormat.PONUM)
      .map((format) => ({
        value: format,
        label: barcodeFormatToDisplay[format],
      }));

    if (allowsCustomBarcodes) {
      options.push({ value: BarcodeFormatErrors.UNKNOWN, label: unknownBarcodeDisplay });
    }

    return options;
  }, [allowsCustomBarcodes]);

  const validateBarcode = (index: number) =>
    dispatch({
      type: BarcodeActionTypes.VALIDATE_BARCODE,
      index,
    });

  const handleAddBarcode = (event: SyntheticEvent) => {
    event.preventDefault();
    dispatch({ type: BarcodeActionTypes.ADD_NEW_BARCODE });
  };

  const handleRemoveBarcode = (index: number) => (event: SyntheticEvent) => {
    event.preventDefault();
    dispatch({
      type: BarcodeActionTypes.REMOVE_BARCODE,
      index,
    });
  };

  const checkDuplicatesAcrossProducts = async () => {
    // check for duplicates across products
    await runServiceValidation(
      pipe<BarcodeEditCollection, any, string[]>(reject("locked"), map("value"))(state.barcodesCollection!)
    );
  };

  const handleBarcodeValidation = (index: number) => async (event: FormEvent<HTMLInputElement>) => {
    // we just ignore empty fields and add filled ones for better usability
    // error message for this case doesn't make sense since block users from adding barcodes
    validateBarcode(index);

    await checkDuplicatesAcrossProducts();
  };

  const handleBarcodeChange = (index: number) => (event: FormEvent<HTMLInputElement>) => {
    const { value: barcode } = event.currentTarget;
    dispatch({
      type: BarcodeActionTypes.UPDATE_BARCODE,
      barcode,
      index,
    });
  };

  const handleBarcodeFormatChange =
    (index: number) =>
    async ({ value }: { value: string }) => {
      dispatch({
        type: BarcodeActionTypes.UPDATE_BARCODE_FORMAT,
        barcodeFormat: value,
        dsku: props.product.dsku,
        index,
      });

      if (value === BarcodeFormat.DSKU) {
        // set DSKU as valid since it always has value
        validateBarcode(index);
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      } else if (state.barcodesCollection && state.barcodesCollection[index].value.length) {
        validateBarcode(index);
        await checkDuplicatesAcrossProducts();
      }
    };

  useEffect(() => {
    const { barcodes } = props.product;
    const barcodeEditCollection = transformToBarcodeEditCollection(barcodes);
    if (barcodes) {
      dispatch({ type: BarcodeActionTypes.LOAD_BARCODES, barcodes });
    }

    const barcodeDraft = getBarcodesDraft(props.product.dsku);

    const draftActionPayload: BarcodeEditDraftAction = {
      type: BarcodeActionTypes.LOAD_BARCODES_DRAFT,
      barcodeDraft,
    };
    dispatch(draftActionPayload);

    if (
      ((!barcodes && isEmpty(barcodeDraft)) || props.preAddBarcode) &&
      getActiveUniqueBarcodesCount([...barcodeEditCollection, ...(barcodeDraft ?? [])]) < BARCODE_LIMIT
    ) {
      dispatch({ type: BarcodeActionTypes.ADD_NEW_BARCODE });
    }
  }, [props.product, props.preAddBarcode]);

  useMount(() => {
    props.plannedBarcodes?.forEach((plannedBarcode) => {
      dispatch({ type: BarcodeActionTypes.ADD_PLANNED_BARCODE, barcode: plannedBarcode });
      dispatch({ type: BarcodeActionTypes.VALIDATE_BARCODE, barcode: plannedBarcode.barcode });
    });
  });

  useEffect(() => {
    if (state.barcodesCollection) {
      props.onChange({ [props.product.dsku]: state.barcodesCollection });
      updateBarcodesDraft(props.product.dsku, state.barcodesCollection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.barcodesCollection]);

  useEffect(() => {
    if (props.serviceError) {
      dispatch({
        type: BarcodeActionTypes.BARCODE_SERVICE_ERROR,
        response: props.serviceError,
      });
    }
  }, [props.serviceError]);

  useEffect(() => {
    if (validationResult?.value?.length) {
      dispatch({
        type: BarcodeActionTypes.BARCODE_SERVICE_ERROR,
        response: createDuplicatedBarcodeResponse(validationResult.value),
      });
    }
  }, [validationResult]);

  return {
    barcodeOptions,
    hasSelectedDSKU,
    handleBarcodeChange,
    handleRemoveBarcode,
    handleBarcodeValidation,
    handleAddBarcode,
    handleBarcodeFormatChange,
    ...state,
  };
}
