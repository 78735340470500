import React from "react";
import { Checkbox, CheckboxProps } from "../Checkbox";
import { getNextState, IndeterminateCheckboxState } from "./indeterminateCheckboxState";

export interface IndeterminateCheckboxProps extends Omit<CheckboxProps, "checked" | "indeterminate" | "onChange"> {
  state: IndeterminateCheckboxState;
  onChange?: (state: IndeterminateCheckboxState) => void;
}

export const IndeterminateCheckbox: React.FC<IndeterminateCheckboxProps> = ({ state, onChange, ...otherProps }) => (
  <Checkbox
    checked={state === IndeterminateCheckboxState.ALL_SELECTED}
    indeterminate={state === IndeterminateCheckboxState.PARTIALLY_SELECTED}
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    onChange={() => onChange && onChange(getNextState(state))}
    {...otherProps}
  />
);
