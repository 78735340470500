export enum NotificationType {
  OutboundInvalidAddressType = "OutboundInvalidAddressType",
  InboundNonCompliantProductsType = "InboundNonCompliantProductsType",
  InboundDiscrepanciesType = "InboundDiscrepanciesType",
  BulkUploadSuccessType = "BulkUploadSuccessType",
  BulkUploadFailedType = "BulkUploadFailedType",
  BulkUploadPartialType = "BulkUploadPartialType",
  SMBNotificationType = "SMBNotificationType",
  SMBMessageNotificationType = "SMBMessageNotificationType",
}
