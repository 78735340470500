import cx from "classnames";
import { Icon, Input, Select } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ExternalLink } from "common/components/ExternalLink";
import { CopyToClipboard } from "common/components/CopyToClipboard";
import { getHelpCenterArticleUrl } from "common/support/helpCenter";
import { FastTagsNav } from "tags/base/FastTagsNav";
import { FastTagPageComponentProps } from "tags/model/FastTagPage";
import { CreateMoreAdSetsModal } from "./CreateMoreAdSetsModal";
import { useConfigureAds } from "./useConfigureAds";

import sharedCls from "../facebook.less";
import cls from "./ConfigureAds.less";
import { FacebookState } from "tags/facebook/connect/state/FacebookReducer";

export type ConfigureAdsProps = FastTagPageComponentProps<FacebookState>;

export const ConfigureAds: FC<ConfigureAdsProps> = (props) => {
  const {
    adSetTitle,
    isCopySnippetSeen,
    deliverySpeedOptions,
    isValidConfiguration,
    nextPath,
    onDeliverySpeedChange,
    onNextClick,
    onProductChange,
    previousPath,
    product,
    selectedDeliverySpeed,
  } = useConfigureAds(props);

  const { formatMessage } = useIntl();

  return (
    <div className={sharedCls.container}>
      <div className={sharedCls.inner}>
        <h4 className={cx(sharedCls.title, sharedCls.fullTitle)}>
          <FormattedMessage
            id="FacebookFastTag.ConfigureTargetedAds.Title"
            defaultMessage="Select product and delivery speed"
          />
        </h4>
        <p className={cx(sharedCls.description, sharedCls.boundedWidth)}>
          <FormattedMessage
            id="FacebookFastTag.ConfigureTargetedAds.Description"
            defaultMessage="Enter the DSKU of the Flexport product you’re currently advertising in your ad set and choose a delivery speed."
          />
        </p>
        <div className={cx(cls.selectContainer, sharedCls.boundedWidth)}>
          <Input
            label={
              <FormattedMessage
                id="FacebookFastTag.ConfigureTargetedAds.Product.Label"
                defaultMessage="Product (DSKU)"
              />
            }
            placeholder={formatMessage({
              id: "FacebookFastTag.ConfigureTargetedAds.Product.Placeholder",
              defaultMessage: "D0123456789",
            })}
            onChange={onProductChange}
            required
            pattern="^D[A-Z0-9]{10}$"
          />

          <Select
            label={
              <FormattedMessage
                id="FacebookFastTag.ConfigureTargetedAds.Delive@rySpeed.Label"
                defaultMessage="Delivery Speed"
              />
            }
            clearable={false}
            isDisabled={false}
            isSearchable={false}
            placeholder={
              <FormattedMessage
                id="FacebookFastTag.ConfigureTargetedAds.Product.Placeholder"
                defaultMessage="Select delivery speed"
              />
            }
            value={selectedDeliverySpeed}
            options={deliverySpeedOptions}
            onChange={onDeliverySpeedChange}
          />
        </div>
      </div>
      {(isValidConfiguration || isCopySnippetSeen) && (
        <div className={cx(cls.adSetTitleContainer)}>
          <h4 className={cx(sharedCls.title, sharedCls.fullTitle)}>
            <FormattedMessage id="FacebookFastTag.ConfigureAds.Title" defaultMessage="Copy and paste this snippet" />
          </h4>
          <p className={sharedCls.description}>
            <FormattedMessage
              id="FacebookFastTag.ConfigureAds.Description"
              defaultMessage="Copy and paste this snippet anywhere in the ad set title for "
            />{" "}
            {product || (
              <FormattedMessage
                id="FacebookFastTag.ConfigureTargetedAds.Product.PlaceholderTitle"
                defaultMessage="your product"
              />
            )}
            .{" "}
            <ExternalLink href={getHelpCenterArticleUrl("4406614450711")}>
              <FormattedMessage id="LearnMore" defaultMessage="Learn more" />
            </ExternalLink>
          </p>
          <div className={cls.copyAdSetTitleContainer}>
            <label className={cls.adSetTitle}>{adSetTitle}</label>
            <CopyToClipboard className={cls.copyAdSetTitle} textToCopy={adSetTitle} disabled={!isValidConfiguration}>
              <FormattedMessage id="FacebookFastTag.CopySnippetText" defaultMessage="Copy Snippet" />
            </CopyToClipboard>
          </div>
          <ExternalLink
            className={sharedCls.facebookAdsManagerLink}
            href="https://www.facebook.com/ads/manager/accounts"
          >
            <FormattedMessage
              id="FacebookFastTag.FacebookAdsManagerLinkText"
              defaultMessage="Open Facebook Ads Manager"
            />
            <Icon className={sharedCls.facebookAdsManagerLinkIcon} type="external-link-alt" />
          </ExternalLink>
        </div>
      )}
      <CreateMoreAdSetsModal nextPath={nextPath} />
      <FastTagsNav
        className={sharedCls.nav}
        nextPage={nextPath}
        previousPage={previousPath}
        nextButtonText={<FormattedMessage id="next" defaultMessage={"Next"} />}
        previousButtonText={<FormattedMessage id="back" defaultMessage={"Back"} />}
        nextButtonClassName={sharedCls.actionButton}
        previousButtonClassName={sharedCls.actionButton}
        onNext={onNextClick}
      />
    </div>
  );
};
