import React from "react";
import { useIntl } from "react-intl";
import { CellProps, Column } from "react-table";

import { MappedSkuPair, RelabeledBoxProblemRow } from "common/components/ProblemsTable/types";
import { SKULink } from "common/components/ProblemBlade/components/SKULink";
import { RELABELED_BOX_DSKUS_TABLE_LABELS } from "./labels";
import { ReceivingInfoItem } from "@deliverr/legacy-inbound-client";

export interface RelabeledBoxContentsColumn extends Record<string, unknown> {
  dskuDetail: MappedSkuPair;
  receivedQty: number;
}

export const useRelabeledBoxContentsTable = ({
  shipmentProblem,
  items,
}: {
  shipmentProblem: RelabeledBoxProblemRow;
  items: ReceivingInfoItem[];
}) => {
  const { formatNumber, formatMessage } = useIntl();

  const NumberCell = ({ value }: CellProps<RelabeledBoxContentsColumn>) => formatNumber(value);

  const SkuCell = ({ value }: CellProps<RelabeledBoxContentsColumn>) => (
    <SKULink dsku={value.dsku}>{value.msku}</SKULink>
  );

  const columns: Column<RelabeledBoxContentsColumn>[] = [
    {
      id: "dskuDetail",
      accessor: "dskuDetail",
      Header: formatMessage(RELABELED_BOX_DSKUS_TABLE_LABELS.dskuDetail),
      Cell: SkuCell,
    },
    {
      id: "receivedQty",
      accessor: "receivedQty",
      Header: formatMessage(RELABELED_BOX_DSKUS_TABLE_LABELS.qty),
      Cell: NumberCell,
    },
  ];

  const data: RelabeledBoxContentsColumn[] = Object.values(shipmentProblem.mappedSkus).map((dskuDetail) => {
    const receivedQty = items.find((item) => item.dsku === dskuDetail.dsku)?.receivedQty ?? 0;

    return {
      dskuDetail,
      receivedQty,
    };
  });

  return {
    data,
    columns,
  };
};
