import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import Address from "common/components/Address";
import { useReturnsDetail } from "../useReturnsDetail";

import { DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import { getShippingMethodLabel } from "returns/utils/getShippingMethodLabel";
import { getSalesChannel } from "returns/utils/getSalesChannel";
import { generatePath, Link } from "react-router-dom";
import { Path } from "paths";

type UseReturnsDetailType = ReturnType<typeof useReturnsDetail>;
type OwnProps = Pick<UseReturnsDetailType, "returnsDetail">;

const ReturnAddressSummary = styled.dl(
  () => `
    padding: 20px 40px;
    border-top: 1px solid rgb(215, 220, 230);
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 32px;
  `
);

const InfoLabel = styled.dt<{}, DefaultTheme>(
  ({ theme }) => `
    display: block;
    font-size: ${theme.font.size.F1};
    font-weight: ${theme.font.weight.BOLD};
    color: ${theme.colors.NEUTRAL[200]};
    margin-bottom: ${theme.spacing.S1};
  `
);

const AddressInfo = styled.dd`
  white-space: pre-line;
`;

export const ReturnAddressInformation: FC<OwnProps> = ({ returnsDetail }) => {
  const label = returnsDetail.packages?.[0]?.label;
  const trackingCode = label?.trackingCode;
  const trackingUrl = label?.trackingUrl ?? label?.labelUrl;
  const carrier = getShippingMethodLabel(label?.shippingMethod ?? label?.carrier);
  const marketplace = getSalesChannel(returnsDetail.channelId ?? "");
  const orderId = returnsDetail.fulfillmentOrderId;
  const channelOrderId = returnsDetail.channelOrderId;
  return (
    <ReturnAddressSummary>
      <div>
        <InfoLabel>
          <FormattedMessage id={"returns.returnAddressInformation.from"} defaultMessage={"From"} />
        </InfoLabel>
        <AddressInfo>
          <Address address={returnsDetail.from} />
        </AddressInfo>
      </div>
      <div>
        <InfoLabel>
          <FormattedMessage id={"returns.returnAddressInformation.destination"} defaultMessage={"Return to"} />
        </InfoLabel>
        <AddressInfo>
          <Address address={returnsDetail.to} />
        </AddressInfo>
      </div>
      {((orderId && channelOrderId) ?? marketplace) && (
        <div>
          <InfoLabel>
            <FormattedMessage id={"returns.returnAddressInformation.orderSource"} defaultMessage={"Order source"} />
          </InfoLabel>
          <div>
            {marketplace.name} <br />
            {orderId && channelOrderId && (
              <Link to={generatePath(Path.orderDetail, { orderId })}>#{channelOrderId}</Link>
            )}
          </div>
        </div>
      )}
      {label && (
        <div>
          <InfoLabel>
            <FormattedMessage id={"returns.returnAddressInformation.shippingService"} defaultMessage={"Service"} />
          </InfoLabel>
          <span>{carrier}</span>
          {trackingCode && trackingUrl ? (
            <div>
              <a href={trackingUrl} target="_blank" rel="noopener noreferrer">
                {trackingCode}
              </a>
            </div>
          ) : (
            <div>{trackingCode}</div>
          )}
        </div>
      )}
    </ReturnAddressSummary>
  );
};
