import cn from "classnames/dedupe";
import React, { FC } from "react";
import Select from "@deliverr/react-select-v1";

import cls from "./CarrierSelect.less";
import { useCarrierSelect } from "./useCarrierSelect";
import { CarrierSelectOptions } from "./CarrierSelectUtil";

export interface CarrierSelectProps extends CarrierSelectOptions {
  className?: string;
}

export const CarrierSelect: FC<CarrierSelectProps> = (props) => {
  const { className, ...selectOptions } = props;
  const { handleSearch, placeholder, selectedOption, onChange, options } = useCarrierSelect(selectOptions);

  return (
    <Select
      id="carrierSelect"
      name="carrierSelect"
      className={cn(cls.carrierSelect, className)}
      onBlurResetsInput={false}
      onInputChange={handleSearch}
      onChange={onChange}
      options={options}
      value={selectedOption}
      placeholder={placeholder}
    />
  );
};
