import React from "react";
import { CreateCarrierAccountRequest } from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { SPThunkAction } from "common/ReduxUtils";
import { logError } from "Logger";
import { batch } from "react-redux";
import { toast } from "react-toastify";
import { transferCreateAddCarrierAccount, transferCreateSetSelectedCarrierAccount } from "transfers/create/actions";
import { ReplenishmentCarrierAccountLoader } from "../ReplenishmentCarrierAccountLoader";
import { FormattedMessage } from "common/components/ui/FormattedMessage";

export const createReplenishmentCarrierAccount =
  (createCarrierAccountRequest: CreateCarrierAccountRequest): SPThunkAction<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch(addLoader(ReplenishmentCarrierAccountLoader.CarrierModalButtonLoader));
      const newCarrierAccount = (await replenishmentClient.createCarrierAccount(createCarrierAccountRequest)).value;
      batch(() => {
        dispatch(transferCreateAddCarrierAccount(newCarrierAccount));
        dispatch(transferCreateSetSelectedCarrierAccount(newCarrierAccount));
      });
    } catch (err) {
      logError(
        { fn: "createReplenishmentCarrierAccount", createCarrierAccountRequest },
        err,
        "Error creating carrier account"
      );
      toast.error(
        err.message ?? (
          <FormattedMessage
            id="createReplenishmentCarrierAccount.handleSave.error"
            defaultMessage="An error occurred while creating the carrier account. Please try again."
          />
        )
      );
    } finally {
      dispatch(clearLoader(ReplenishmentCarrierAccountLoader.CarrierModalButtonLoader));
    }
  };
