export enum IconSize {
  I1 = "I1",
  I2 = "I2",
  I3 = "I3",
  I4 = "I4",
  I5 = "I5",
  I6 = "I6",
  I7 = "I7",
  I8 = "I8",
}

export const iconSize: Record<IconSize, string> = {
  [IconSize.I1]: "0.5rem",
  [IconSize.I2]: "0.625rem",
  [IconSize.I3]: "0.75rem",
  [IconSize.I4]: "0.875rem",
  [IconSize.I5]: "1rem",
  [IconSize.I6]: "1.375rem",
  [IconSize.I7]: "1.625rem",
  [IconSize.I8]: "1.875rem",
};
