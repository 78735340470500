import { SellerReportType } from "common/clients/reporting/Report/SellerReportType";

export const InventoryReportTypes = [SellerReportType.InventoryLevels, SellerReportType.InventoryAge];

export const InventoryLevelsLedgerReportTypes = [SellerReportType.InventoryLevelsUsingLedger];

export const ReportsWithMonthSelect = [SellerReportType.InboundsMonthly];

export const ReportsWithLimitedMonthSelect = [
  SellerReportType.InventoryEcommerceTransactionHistory,
  SellerReportType.InventoryEcommerceMonthlyReconciliation,
  SellerReportType.InventoryEcommerceMonthlyReconciliationUsingLedger,
];

export const ReportsWithDateRange = [
  SellerReportType.InboundPackages,
  SellerReportType.Returns,
  SellerReportType.ReturnItems,
  SellerReportType.Orders,
  SellerReportType.OrderPackages,
  SellerReportType.ClaimsSubmitted,
  SellerReportType.ReserveStorageOrdersAndTransfers,
  SellerReportType.ReplenishmentItemizedReport,
  SellerReportType.Parcel,
  SellerReportType.ParcelTracking,
];

export const ReportsWithSingleDateSelect = [SellerReportType.ReserveStoragePalletInventory];

export const LedgerReportsWithSingleDateSelect = [
  SellerReportType.InventoryDetailReserveStorageUsingLedger,
  SellerReportType.InventoryLotTrackingFEFOAllProductsUsingLedger,
  SellerReportType.InventoryLotTrackingFEFOSingleProductUsingLedger,
  SellerReportType.InventoryLevelsUsingLedger,
];

// Reports with lower date bound on selectable date / date range
export const ReportsWithMinDate: { [key in SellerReportType]?: Date } = {
  [SellerReportType.ReserveStoragePalletInventory]: new Date("2023-04-01"),
  [SellerReportType.InventoryDetailReserveStorageUsingLedger]: new Date("2024-10-25"),
  [SellerReportType.ReplenishmentItemizedReport]: new Date("2024-01-01"),
  [SellerReportType.Parcel]: new Date("2023-10-14"),
  [SellerReportType.InventoryLevelsUsingLedger]: new Date("2024-10-25"),
  [SellerReportType.InventoryLotTrackingFEFOAllProductsUsingLedger]: new Date("2024-10-25"),
  [SellerReportType.InventoryLotTrackingFEFOSingleProductUsingLedger]: new Date("2024-10-25"),
};

export const ReportsWithProduct = [
  SellerReportType.InventoryEcommerceTransactionHistory,
  SellerReportType.InventoryLotTrackingFEFOSingleProduct,
  SellerReportType.InventoryLotTrackingFEFOSingleProductUsingLedger,
];

export const InventoryReportsUsingLedger = [
  SellerReportType.InventoryEcommerceMonthlyReconciliationUsingLedger,
  SellerReportType.InventoryLevelsUsingLedger,
  SellerReportType.InventoryLotTrackingFEFOAllProductsUsingLedger,
  SellerReportType.InventoryLotTrackingFEFOSingleProductUsingLedger,
  SellerReportType.InventoryDetailReserveStorageUsingLedger,
  SellerReportType.InventoryHourlyVisibilityReport,
];

// Temporary mapping to control the order of the inventory reports in the dropdown
// This won't be needed once the legacy reports are removed
export const InventoryReportTypePositionMap = {
  [SellerReportType.InventoryEcommerceMonthlyReconciliationUsingLedger]: 1,
  [SellerReportType.InventoryLotTrackingFEFOAllProductsUsingLedger]: 2,
  [SellerReportType.InventoryLotTrackingFEFOSingleProductUsingLedger]: 3,
  [SellerReportType.InventoryDetailReserveStorageUsingLedger]: 4,
  [SellerReportType.InventoryLevelsUsingLedger]: 5,
  [SellerReportType.InventoryEcommerceMonthlyReconciliation]: 6,
  [SellerReportType.InventoryEcommerceTransactionHistory]: 7,
  [SellerReportType.InventoryLevels]: 8,
  [SellerReportType.InventoryLotTrackingFEFOAllProducts]: 9,
  [SellerReportType.InventoryLotTrackingFEFOSingleProduct]: 10,
  [SellerReportType.ReserveStoragePalletInventory]: 11,
  [SellerReportType.InventoryHourlyVisibilityReport]: 12,
};

export const composeIsReportType = (reportTypes: SellerReportType[]) => (reportType?: SellerReportType) =>
  reportType !== undefined && reportTypes.includes(reportType);

export const isInventoryReport = composeIsReportType(InventoryReportTypes);

export const isInventoryLevelsLedgerReport = composeIsReportType(InventoryLevelsLedgerReportTypes);

export const isReportWithMonthSelect = composeIsReportType(ReportsWithMonthSelect);

export const isReportWithDateRange = composeIsReportType(ReportsWithDateRange);

export const isReportWithProduct = composeIsReportType(ReportsWithProduct);

export const isReportWithLimitedMonthSelect = composeIsReportType(ReportsWithLimitedMonthSelect);

export const isReportWithSingleDateSelect = composeIsReportType(
  ReportsWithSingleDateSelect.concat(LedgerReportsWithSingleDateSelect)
);

export const isLedgerReportWithSingleDateSelect = composeIsReportType(LedgerReportsWithSingleDateSelect);

export const isInventoryReportUsingLedger = composeIsReportType(InventoryReportsUsingLedger);
