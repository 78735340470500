import React, { FC } from "react";
import DismissableAlert from "./DismissableAlert";

interface WarningAlertProps {
  appendContent?: React.ReactNode;
  children?: React.ReactNode;
  description?: string;
  title?: string;
}

export const WarningAlert: FC<WarningAlertProps> = ({ appendContent, children, description, title }) => (
  <DismissableAlert alertType="WARNING" title={title} description={description} appendContent={appendContent}>
    {children}
  </DismissableAlert>
);
