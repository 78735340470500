import React, { FC } from "react";
import { CellProps, Column } from "react-table";
import { useIntl } from "react-intl";

import { EstimatedChargeSection } from "common/components/ProblemBlade/components/EstimatedChargeSection";
import { EstimatedTotalChargeTable } from "common/components/ProblemBlade/components/EstimatedTotalChargeTable";
import { useCurrencyValue } from "common/components/Currency";

const DAMAGE_REIMBURSEMENT_RETAIL_PERCENTAGE = 0.4;

interface DamagedProductCostTableProps {
  units: number;
  retailUnitPrice?: number;
  reimbursementUnitCost: number;
}

interface DamagedProductTableColumn
  extends Pick<DamagedProductCostTableProps, "units" | "retailUnitPrice">,
    Record<string, unknown> {
  percentReimbursed: number;
  totalReimbursement: number;
}

export const DamagedProductCostTable: FC<DamagedProductCostTableProps> = ({
  units,
  retailUnitPrice,
  reimbursementUnitCost,
}) => {
  const { formatNumber, formatMessage } = useIntl();
  const formatCurrency = useCurrencyValue();

  const NumberCell = ({ value }: CellProps<DamagedProductTableColumn>) => formatNumber(value);
  const CurrencyCell = ({ value }: CellProps<DamagedProductTableColumn>) => (value ? formatCurrency(value) : "-");
  const PercentageCell = ({ value }: CellProps<DamagedProductTableColumn>) => formatNumber(value, { style: "percent" });

  const columns: Column<DamagedProductTableColumn>[] = [
    {
      id: "units",
      accessor: "units",
      Header: formatMessage({
        id: "common.qty",
        defaultMessage: "Qty",
      }),
      Cell: NumberCell,
    },
    {
      id: "totalReimbursement",
      accessor: "totalReimbursement",
      Header: formatMessage({
        id: "nonCompliance.damagedProductCostTable.total",
        defaultMessage: "Total",
      }),
      Cell: CurrencyCell,
    },
  ];

  // if the retail price times the reimbursement percentage does not match the actual reimbursement amount, do not display the column
  const shouldDisplayRetailUnitPrice =
    retailUnitPrice &&
    Math.round(retailUnitPrice * DAMAGE_REIMBURSEMENT_RETAIL_PERCENTAGE * 100) / 100 === reimbursementUnitCost;

  if (shouldDisplayRetailUnitPrice) {
    columns.splice(
      1,
      0,
      {
        id: "retailUnitPrice",
        accessor: "retailUnitPrice",
        Header: formatMessage({
          id: "nonCompliance.damagedProductCostTable.retailCost",
          defaultMessage: "Retail cost",
        }),
        Cell: CurrencyCell,
      },
      {
        id: "percentReimbursed",
        accessor: "percentReimbursed",
        Header: formatMessage({
          id: "nonCompliance.damagedProductCostTable.percentReimbursed",
          defaultMessage: "% Reimbursed",
        }),
        Cell: PercentageCell,
      }
    );
  }

  const totalReimbursement = units * reimbursementUnitCost;

  const data: DamagedProductTableColumn[] = [
    {
      units,
      retailUnitPrice,
      totalReimbursement,
      percentReimbursed: DAMAGE_REIMBURSEMENT_RETAIL_PERCENTAGE,
    },
  ];

  return (
    <EstimatedChargeSection
      value={totalReimbursement}
      label={formatMessage({
        id: "nonCompliance.damagedProductCostTable.totalReimbursement",
        defaultMessage: "Total reimbursement",
      })}
    >
      <EstimatedTotalChargeTable columns={columns} data={data} />
    </EstimatedChargeSection>
  );
};
