import { SPThunkAction } from "common/ReduxUtils";
import { logError } from "Logger";
import { batch } from "react-redux";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { getIsLoading } from "../components/WithLoader/LoadingSelectors";
import { AnyAction } from "redux";
import { WholesaleOrderDTO } from "@deliverr/wholesale-client";
import { WholesaleCreateState } from "../../wholesale/create/store/WholesaleCreateState";
import { RootState } from "../../RootReducer";
import { getWholesaleOrderWithPacks } from "storage/packUtils/getWholesaleorderWithPacks";
import { WholesaleErrorType } from "./utils/WholesaleErrorTypes";

export const sharedLoadWholesaleOrderAction = ({
  loaderId,
  setError,
  setValue,
  selector,
}: {
  loaderId: string;
  setError: (errorType: string) => AnyAction;
  setValue: (order: WholesaleOrderDTO) => AnyAction;
  selector: (state: RootState) => WholesaleCreateState;
}) => (): SPThunkAction => async (dispatch, getState) => {
  const state = getState();
  if (getIsLoading(state)(loaderId)) {
    return;
  }
  const { wholesaleOrder } = selector(state);

  batch(() => {
    dispatch(addLoader(loaderId));
    dispatch(setError(WholesaleErrorType.NONE));
  });

  try {
    const response = await dispatch(getWholesaleOrderWithPacks(wholesaleOrder?.id as string));
    batch(() => {
      dispatch(setValue(response));
      dispatch(clearLoader(loaderId));
    });
  } catch (err) {
    batch(() => {
      dispatch(setError(WholesaleErrorType.UNTYPED));
    });
    logError({ fn: "loadWholesaleOrderAction" }, err);
  } finally {
    dispatch(clearLoader(loaderId));
  }
};
