import { useEffect, useRef } from "react";

/**
 * Prevent scrolling body when using wheel to scroll within a different scrollable area.
 * Returns a ref to be used on the scrollable area.
 */
export const usePreventBodyScroll = <T extends Element>() => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const el = ref.current;

    if (!el) {
      return;
    }

    function listener(this: HTMLDivElement, ev: WheelEvent) {
      if (ev.deltaY > 0) {
        if (this.scrollHeight - this.scrollTop <= this.clientHeight) {
          ev.preventDefault();
        }
      } else {
        if (this.scrollTop === 0) {
          ev.preventDefault();
        }
      }
    }

    el.addEventListener("wheel", listener, { passive: false });

    return () => el.removeEventListener("wheel", listener);
  }, []);

  return { ref };
};
