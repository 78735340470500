import { Product } from "@deliverr/commons-clients";
import { WarehousePublic } from "@deliverr/business-types";
import {
  Asn,
  InboundPackageSummary,
  InboundShipment,
  InboundShipmentItem,
  ShippingPlanDispersalMethod,
} from "@deliverr/legacy-inbound-client";
import { Dictionary } from "lodash";
import {
  compact,
  flatten,
  flowRight,
  fromPairs,
  get,
  groupBy,
  isEqual,
  map,
  mapValues,
  sortBy,
  sumBy,
  uniqBy,
  flow,
  values,
  some,
} from "lodash/fp";
import { createSelector } from "reselect";
import {
  ProductPrepCategory,
  ProductPrepPackagingType,
} from "@deliverr/commons-clients/lib/product/ProductPreparation";

import { ById, getItemsFromById } from "common/ById";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { InboundState } from "inbounds/InboundReducer";
import {
  DraftShippingPlan,
  DraftShippingPlanItem,
  PlannedShipment,
  ShipmentItemDetail,
  ShippingPlanItemDetail,
} from "inbounds/InboundTypes";
import { MAX_SKU_QTY, packageHasZeroUnits, tooManySkusInPackage } from "inbounds/InboundValidationUtils";
import { isConfirmedShipmentStatus } from "inbounds/ShipmentStatus";
import BoxArrangement from "inbounds/steps/ship/BoxArrangement";
import { RootState } from "RootReducer";
import { getDispersalMethodFromFlag, isShipToOneDispersalMethod } from "./ship/InboundUtils";
import { getLoadedShipment } from "inbounds/store/selectors/shipments/getLoadedShipment";
import { getProductDetailsCache } from "inbounds/store/selectors/productDetailsCache";
import { getIsSpdDeliverr, getIsSpdExternal } from "inbounds/utils/shippingMethodUtils";
import { getIsMetro } from "inbounds/utils/shippingMethodUtils/getIsMetro";
import { INBOUND_PKG_MAX_SKUS_PER_BOX } from "inbounds/constants/packageConstants";
import { selectLoadedShipmentItems } from "inbounds/store/selectors/shipments/selectLoadedShipmentItems";
import { selectPlanItems } from "inbounds/store/selectors/plan/selectPlanItems";
import { getNonEmptyShipmentItems } from "../store/util/getNonEmptyShipmentItems";
import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";
import { BoxErrorType } from "./ship/BoxPackingArrangement/table/BoxError";
import { ProductCollection } from "common/models";

export const getInboundState = ({ inbound }: RootState) => inbound;

export const getLoadedPlannedShipment = createSelector(
  (state: RootState) => state.inbound.loadedShipmentId,
  (state: RootState) => state.inbound.plannedShipments,
  (loadedShipmentId: number, plannedShipments: ById<PlannedShipment>) => plannedShipments.byId[loadedShipmentId]
);

export const getShippingPlan = createSelector(get("inbound"), (inbound: InboundState) => inbound.plan);

export const getShippingPlanId = createSelector(getShippingPlan, (shippingPlan: DraftShippingPlan) => shippingPlan.id);

const getShipmentItemDetails = (
  shipmentItems: InboundShipmentItem[],
  productDetailsCache: { [dsku: string]: Product }
): ShipmentItemDetail[] =>
  shipmentItems.map(({ shipmentId, dsku, qty }) => {
    const { name, msku } = productDetailsCache[dsku];
    return { shipmentId, dsku, name, msku, qty };
  });

export const getPlanItemDetails = createSelector(
  selectPlanItems,
  getProductDetailsCache,
  (planItems, productDetailsCache) => {
    const itemsDetails = map<DraftShippingPlanItem>(({ qty, dsku }: DraftShippingPlanItem): ShippingPlanItemDetail => {
      const { name, msku, productPreparation, customsInformation, brandedPackaging } = productDetailsCache[dsku];
      return {
        dsku,
        name,
        msku,
        qty,
        customsInformation,
        brandedPackaging,
        // prevent BP triggering category assignment by providing default of OTHER
        category: brandedPackaging ? ProductPrepCategory.OTHER : productPreparation?.category,
        packagingType: productPreparation?.packagingType,
      };
    }, planItems) as unknown as ShippingPlanItemDetail[];
    return sortBy("dsku", itemsDetails);
  }
);

export const getShippingPlanBulkUploadSessionId = (state: RootState) => state.inbound.plan.sessionUuid;
export const getBulkUploadSessionId = (state: RootState) => state.inbound.bulkUploadSessionId;

export const getProductCategories = createSelector(getPlanItemDetails, (itemDetails) =>
  itemDetails.map(({ category }) => category)
);

export const getPlanPackagingTypes = createSelector(getPlanItemDetails, (itemDetails) => {
  const packagingTypes: Partial<Record<ProductPrepPackagingType, boolean>> = {};
  itemDetails.forEach(({ packagingType }) => {
    if (packagingType) {
      packagingTypes[packagingType] = true;
    }
  });
  return packagingTypes;
});

export const getLoadedShipmentItemDetails = createSelector(
  selectLoadedShipmentItems,
  get("inbound.productDetailsCache"),
  getShipmentItemDetails
);

export const getAllShipmentItemDetails = createSelector(
  get("inbound.shipments"),
  get("inbound.productDetailsCache"),
  (
    shipments: ById<InboundShipment>,
    productDetailsCache: { [dsku: string]: Product }
  ): Dictionary<ShipmentItemDetail[]> =>
    mapValues(
      (shipment) => getShipmentItemDetails(getNonEmptyShipmentItems(shipment), productDetailsCache),
      shipments.byId
    )
);

export const getLoadedShipmentSkuCount = createSelector(
  selectLoadedShipmentItems,
  flowRight<InboundShipmentItem[], number, InboundShipmentItem[]>(get("length"), uniqBy<InboundShipmentItem>("dsku"))
);

/**
 * based on the number of SKUs in the shipment and the max number of unique SKUs per box,
 * return the minimum number of boxes to use
 */
export const getMinMultiSkuBoxCount = createSelector(getLoadedShipmentSkuCount, (skuCount) =>
  Math.ceil(skuCount / INBOUND_PKG_MAX_SKUS_PER_BOX)
);

// When they reduce the # of packages, we keep the truncated packages so they can be restored
export const getLoadedShipmentPackages = createSelector(getLoadedPlannedShipment, ({ packages, packageCount }) =>
  packages.slice(0, packageCount)
);

export const getBoxWeights = createSelector(getLoadedShipmentPackages, (packages) =>
  packages.map(({ weight }) => weight)
);

export const getBoxErrors = createSelector(
  getLoadedPlannedShipment,
  getLoadedShipmentPackages,
  ({ boxConfirmAttempted }, packages): BoxErrorType[] =>
    compact(
      packages.map((pkg) => {
        if (boxConfirmAttempted) {
          if (tooManySkusInPackage(pkg)) {
            return BoxErrorType.TOO_MANY_SKUS;
          }
          if (packageHasZeroUnits(pkg)) {
            return BoxErrorType.ZERO_UNITS;
          }
        }
        return undefined;
      })
    )
);

export const getLoadedShipmentBoxTotalBySku = createSelector(
  getLoadedPlannedShipment,
  ({ packages, packageCount, identicalPackageCounts }) => {
    const dskuToPackageItems = groupBy(
      "dsku",
      flatten(
        packages.slice(0, packageCount).map(({ items }, index) => {
          return items.map((item) => {
            return {
              ...item,
              qty: item.qty * identicalPackageCounts[index],
            };
          });
        })
      )
    );
    return mapValues(sumBy("qty"), dskuToPackageItems);
  }
);

export const getQtyByShipmentItemPerBox = createSelector(getLoadedShipmentPackages, (packages) => {
  const quantities: { [dsku: string]: { [boxIndex: number]: number } } = {};

  packages.forEach((box, boxIndex) => {
    box.items.forEach(({ dsku, qty }) => {
      if (!quantities[dsku]) {
        quantities[dsku] = {};
      }
      quantities[dsku][boxIndex] = qty;
    });
  });

  return quantities;
});

export const getIsPlannedShipmentIndividualDeliverr = createSelector<RootState, PlannedShipment, boolean>(
  getLoadedPlannedShipment,
  ({ shippingMethod }) => getIsSpdDeliverr(shippingMethod)
);

export const getIsPlannedShipmentIndividualPersonal = createSelector<RootState, PlannedShipment, boolean>(
  getLoadedPlannedShipment,
  ({ shippingMethod }) => getIsSpdExternal(shippingMethod)
);

export const getIsPlannedShipmentMetroDeliverr = createSelector<RootState, PlannedShipment, boolean>(
  getLoadedPlannedShipment,
  ({ shippingMethod }) => getIsMetro(shippingMethod)
);

export const getShippingPlanExists = createSelector(get("inbound.plan.id"), Boolean);

export const getInboundIsLoaded = createSelector(get("inbound.plan.name"), (name: string) => name !== "");

export const getLoadedShipmentWarehouse = createSelector(
  getLoadedShipment,
  get("deliverr.warehouses"),
  ({ warehouseId }, warehouses: WarehousePublic[]) => warehouses[warehouseId]
);

export const getUseCasePackModified = (state: RootState) => {
  if (state.inbound.oldState === undefined) {
    return false;
  }
  return state.inbound.oldState.plan.useCasePack !== state.inbound.plan.useCasePack;
};

export const getPlanItemsHaveBeenModified = (state: RootState) => {
  if (state.inbound.oldState === undefined) {
    return false;
  }
  return !isEqual(state.inbound.oldState.planItems.byId, state.inbound.planItems.byId);
};

export const getShipmentIdToShipmentNumber = createSelector(
  get("inbound.shipments.ids"),
  (shipmentIds: number[]): Dictionary<number> => fromPairs(shipmentIds.slice().map((id, i) => [id, i + 1]))
);

export const getShipmentIdToWarehouse = createSelector(
  get("inbound.shipments"),
  get("deliverr.warehouses"),
  (shipments: ById<InboundShipment>, warehouses: Dictionary<WarehousePublic>) =>
    mapValues((shipment) => warehouses[shipment.warehouseId], shipments.byId)
);

export const getLoadedAsns = createSelector<RootState, number, Dictionary<Asn[]>, Asn[]>(
  get("inbound.loadedShipmentId"),
  get("inbound.asns"),
  (shipmentId, asns) => asns[shipmentId]
);

export const shipmentContainsOneUnitPerBox = createSelector<RootState, PlannedShipment, boolean>(
  getLoadedPlannedShipment,
  ({ boxArrangement, packages }) =>
    boxArrangement === BoxArrangement.OneSKUPerBox && packages.some((pkg) => pkg.items[0].qty === 1)
);

export const getSortedShipments = createSelector<RootState, ById<InboundShipment>, InboundShipment[]>(
  (state) => state.inbound.shipments,
  (shipments) =>
    sortBy(
      ({ warehouseId, crossDockWarehouseId }) => !(crossDockWarehouseId && warehouseId === crossDockWarehouseId),
      getItemsFromById(shipments)
    )
);

export const getLoadedShipmentNumber = createSelector<RootState, InboundShipment[], number | undefined, number>(
  getSortedShipments,
  (state) => state.inbound.loadedShipmentId,
  (shipments, loadedShipmentId) => shipments.findIndex(({ id }) => id === loadedShipmentId) + 1
);

export const getShipmentsConfirmed = createSelector<RootState, Dictionary<InboundShipment>, Dictionary<boolean>>(
  (state) => state.inbound.shipments.byId,
  (shipments) => mapValues(({ status }) => isConfirmedShipmentStatus(status), shipments)
);

export const getLoadedShipmentConfirmed = createSelector<RootState, number | undefined, Dictionary<boolean>, boolean>(
  (state) => state.inbound.loadedShipmentId,
  getShipmentsConfirmed,
  (loadedShipmentId, shipmentIdToShipmentConfirmed) =>
    Boolean(loadedShipmentId && shipmentIdToShipmentConfirmed[loadedShipmentId])
);

export const getPreviousStepsNoLongerModifiable = createSelector<RootState, Dictionary<boolean>, boolean>(
  getShipmentsConfirmed,
  (shipmentsConfirmed) => Object.values(shipmentsConfirmed).some((shipmentConfirmed) => shipmentConfirmed)
);

export const getPlannedPackages = createSelector(getInboundState, ({ plannedPackages }) => plannedPackages);

export const getPlannedBarcodes = createSelector(getInboundState, ({ plannedBarcodes }) => plannedBarcodes);

export const getBoxesLocked = createSelector<RootState, boolean, PlannedShipment, InboundShipment, boolean>(
  getLoadedShipmentConfirmed,
  getLoadedPlannedShipment,
  getLoadedShipment,
  (loadedShipmentConfirmed, { boxesConfirmed }, { status }) =>
    loadedShipmentConfirmed || boxesConfirmed || status === InboundShipmentStatus.PACKAGES_ADDED
);

export const getBoxesEditable = createSelector<RootState, boolean, InboundPackageSummary[], InboundShipment[], boolean>(
  getBoxesLocked,
  getPlannedPackages,
  getSortedShipments,
  (boxesLocked, plannedPackages, shipments) => !boxesLocked && (shipments.length > 1 || plannedPackages.length <= 1)
);

export const getProductsLocked = createSelector<RootState, InboundPackageSummary[], boolean>(
  getPlannedPackages,
  (plannedPackages) => plannedPackages.length > 0
);

export const getShouldShowBulkUploadBanner = createSelector<RootState, boolean, InboundShipment[], boolean>(
  getBoxesLocked,
  getSortedShipments,
  (boxesLocked, shipments) => shipments.length === 1 && !boxesLocked
);

export const getIsTransitioning = createSelector<RootState, string[], boolean>(
  (state) => state.loading.loaders,
  (loaders) => loaders.includes(InboundLoaderId.transition)
);

export const getCrossDockWarehouse = createSelector<
  RootState,
  Dictionary<WarehousePublic>,
  InboundShipment,
  WarehousePublic
>(
  (state) => state.deliverr.warehouses,
  getLoadedShipment,
  (warehousesById, { crossDockWarehouseId }) => warehousesById[crossDockWarehouseId!]
);

export const getShipmentsConfirmedCount = createSelector<RootState, number[], Dictionary<boolean>, number>(
  (state) => state.inbound.shipments.ids,
  getShipmentsConfirmed,
  (shipmentIds, shipmentIdToShipmentConfirmed) =>
    sumBy((shipmentId) => {
      const isConfirmed = shipmentIdToShipmentConfirmed[shipmentId];
      return isConfirmed ? 1 : 0;
    }, shipmentIds)
);

export const getInboundDispersalMethod = createSelector<
  RootState,
  ShippingPlanDispersalMethod | undefined,
  boolean | undefined,
  ShippingPlanDispersalMethod | undefined
>(
  (state) => state.inbound.dispersalMethod,
  (state) => state.inbound.isRedistributions,
  (dispersalMethod, isRedistributions) => dispersalMethod ?? getDispersalMethodFromFlag(isRedistributions)
);

export const getInboundIsShipToOne = (state: RootState) => isShipToOneDispersalMethod(getInboundDispersalMethod(state));

/**
 * Determine if a Shipping Plan has at least one PlanItem with a SKU count over the
 * permissible limit for a Direct Shipping Plan.
 * @param state
 */
export const getIsOverSkuPerDskuLimit = (state: RootState) => {
  const itemIds = state.inbound.planItems.ids;
  return itemIds.some((id) => state.inbound.planItems.byId[id]?.qty > MAX_SKU_QTY);
};

/**
 * Get the total quantity of all units on a ShippingPlan
 * @param state
 */
export const getTotalUnitQuantity = (state: RootState) => {
  const itemIds = state.inbound.planItems.ids;
  return itemIds.reduce((sum, id) => sum + (state.inbound.planItems.byId[id]?.qty ?? 0), 0);
};

export const getIsFinalUnconfirmedShipment = createSelector<RootState, number, number, boolean, boolean>(
  (state) => state.inbound.shipments.ids.length,
  getShipmentsConfirmedCount,
  getLoadedShipmentConfirmed,
  (numOfShipments, numOfConfirmedShipments, loadedShipmentConfirmed) =>
    (numOfConfirmedShipments === numOfShipments - 1 && !loadedShipmentConfirmed) ||
    numOfConfirmedShipments === numOfShipments
);

export const getNextIncompleteShipmentId = createSelector<RootState, number[], Dictionary<boolean>, number | undefined>(
  (state) => state.inbound.shipments.ids,
  getShipmentsConfirmed,
  (shipmentIds, shipmentIdToShipmentConfirmed) =>
    shipmentIds.find((shipmentId) => !shipmentIdToShipmentConfirmed[shipmentId])
);

export const getAllShipmentsConfirmed = createSelector<RootState, number, number, boolean>(
  (state) => state.inbound.shipments.ids.length,
  getShipmentsConfirmedCount,
  (shipmentsConfirmedCount, shipmentsCount) => shipmentsConfirmedCount === shipmentsCount
);

export const getIsKittedProductSelected = createSelector<RootState, ProductCollection, boolean>(
  getProductDetailsCache,
  (productDetailsCache) =>
    flow(
      values,
      some((product) => product.isKit ?? false)
    )(productDetailsCache)
);

export const getIsNonKittedProductSelected = createSelector<RootState, ProductCollection, boolean>(
  getProductDetailsCache,
  (productDetailsCache) =>
    flow(
      values,
      some((product) => !product.isKit)
    )(productDetailsCache)
);
