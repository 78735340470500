import { useScript } from "common/hooks/useScript";
import { MouseEvent, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "RootReducer";

export interface UseSupportChatLauncher {
  onClick: (event: MouseEvent) => void;
}

export const useZendeskChatLauncher = (onLaunch?: () => void): UseSupportChatLauncher => {
  const { email } = useSelector((state: RootState) => state.user);
  const sellerId = useSelector((state: RootState) => state.organization.sellerId);

  const attributes = useMemo(
    () => ({
      id: "ze-snippet",
      type: "application/javascript",
      hide: "true",
      "data-builder-preview": "false",
      "data-ft-Embed-Script-Language": "en",
      "data-ft-Email": email,
      "data-ft-Seller-ID": sellerId,
      "data-ft-URL": "sellerportal.deliverr.com",
    }),
    [email, sellerId]
  );

  const ZENDESK_SCRIPT_URL = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.ZENDESK_API_KEY}`;

  useScript(ZENDESK_SCRIPT_URL, true, attributes);

  const onClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    onLaunch?.();

    window.zE("webWidget", "open");
  };

  return {
    onClick,
  };
};
