import styled from "@emotion/styled";
import { ThemeProps, Title, Text } from "common/components/ui";
import { css } from "emotion";
import { InfoIcon } from "common/components/icons";

export const NegativeMarginContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    margin: -${theme.spacing.S5} -${theme.spacing.S7};
  `
);

export const ConfirmationDetailsBox = styled.div<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S5} ${theme.spacing.S7} ${theme.spacing.S5} ${theme.spacing.S7};
  :not(:first-of-type) {
      border-left: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
    }
  `
);

export const ConfirmationDetailsValue = styled(Title)<ThemeProps>(
  ({ theme }) => `
     margin-top: ${theme.spacing.S2};
     margin-bottom: 0;
  `
);

export const infoIconTooltipClass = css`
  width: 280px;
`;

export const StyledInfoIcon = styled(InfoIcon)<ThemeProps>`
  margin-left: ${({ theme }) => theme.spacing.S2};
`;

export const CancellationContainer = styled.div<ThemeProps>`
  padding: ${({ theme }) => `${theme.spacing.S4} 0`};
`;

export const StyledText = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-bottom: 0 !important;
  `
);

export const SpacedText = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S4};
  `
);

export const SectionContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    padding: ${theme.spacing.S5} ${theme.spacing.S7};
    border-top: ${`${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]}`};
  `
);

export const BorderedCardSection = styled.div<ThemeProps>(
  ({ theme }) => `
    border-top: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL["80"]};
  `
);

export const Row = styled.div`
  display: flex;
`;

export const SpacedConfirmationCard = styled.div<ThemeProps>(
  ({ theme }) => `
     margin-top:${theme.spacing.S5};
  `
);

export const AccordionHeader = styled(Title)<ThemeProps>(
  ({ theme }) => `
    margin: 0;
    letter-spacing: 0;
    font-size: ${theme.font.size.F2};
    font-weight: ${theme.font.weight.BOLD};
    text-transform: uppercase;
  `
);
