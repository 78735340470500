import { OrderHistory, ShipmentHistory } from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { keyBy } from "lodash";
import { replenishmentOrderDeatilsSetShipmentHistoryMap } from "transfers/detail/store/replenishment/ReplenishmentOrderDetailsActions";

export const getAndSetReplenishmentOrderHistoryState = async (orderId: string, dispatch): Promise<void> => {
  const orderHistory: OrderHistory = (await replenishmentClient.getOrderTrackingHistory(orderId)).value;
  const shipmentHistoryMap: { [shipment: string]: ShipmentHistory } = keyBy(orderHistory.shipmentHistory, "shipmentId");
  await dispatch(replenishmentOrderDeatilsSetShipmentHistoryMap(shipmentHistoryMap));
};
