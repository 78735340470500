import { batch } from "react-redux";
import { OrderListActionTypes } from "../OrderListActionTypes";
import { setCurrentPage } from "./setCurrentPage";

export const setCurrentSearchTerm = (searchTerm: string) => async (dispatch) =>
  batch(() => {
    dispatch({
      type: OrderListActionTypes.SET_CURRENT_SEARCH_TERM,
      currentSearchTerm: searchTerm,
    });
    dispatch(setCurrentPage(0));
  });
