import cn from "classnames/dedupe";
import React, { FC } from "react";

import cls from "./EmphasizedText.less";

export interface EmphasizedTextProps {
  className?: string;
}

export const EmphasizedText: FC<React.PropsWithChildren<EmphasizedTextProps>> = (props) => {
  const { className, children } = props;

  return children ? <span className={cn(cls.emphasizedText, className)}>{children}</span> : null;
};
