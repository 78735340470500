import * as React from "react";

export const icon = (viewbox) => (content) => {
  function Icon(props) {
    return (
      <svg viewBox={viewbox} width="1em" height="1em" {...props}>
        {content}
      </svg>
    );
  }

  return React.memo(Icon);
};

export const unicon = icon("0 0 24 24");
