import { WholesaleOrderDTO, WholesaleShipmentDTO } from "@deliverr/wholesale-client";
import { all, some } from "lodash/fp";
import { CreateTransferLoader } from "../../CreateTransferLoader.types";
import { CreateWholesaleLoader } from "../../../../wholesale/create/CreateWholesaleLoader.types";
import { usePolling } from "../../../../common/hooks/usePolling";
import { useDispatch } from "react-redux";
import { SPThunkAction } from "../../../../common/ReduxUtils";

const shipmentHasShipmentId = (shipment: WholesaleShipmentDTO) =>
  some((attr) => attr.attributeName === "shipmentId", shipment.externalAttributes);

const isValidFbaShipment = (shipment: WholesaleShipmentDTO) =>
  shipmentHasShipmentId(shipment) && !!shipment.validatedDestinationAddress;

const orderHasAllValidFbaShipments = (order: WholesaleOrderDTO) => all(isValidFbaShipment, order.shipments);

export const useEstimationsStatus = (
  wholesaleOrder: WholesaleOrderDTO | undefined,
  loaderId: CreateTransferLoader.CreateTransferOrderLoader | CreateWholesaleLoader.CreateWholesaleOrderLoader,
  retryAction: () => SPThunkAction
) => {
  const dispatch = useDispatch();
  const isAutomatedFBAReady = Boolean(
    wholesaleOrder?.createdAutomatically &&
      wholesaleOrder?.shipments &&
      (wholesaleOrder.shipments.length ?? 0) > 0 &&
      orderHasAllValidFbaShipments(wholesaleOrder)
  );

  const validatedDestinationAddress = wholesaleOrder?.shipments?.[0]?.validatedDestinationAddress ?? undefined;

  const {
    freightEstimation,
    parcelEstimation,
    parcelEstimationThirdParty,
    freightEstimationThirdParty,
    processingFeeEstimation,
    estimationsFailed,
    estimationsPending,
  } = wholesaleOrder?.estimations ?? {};

  const hasPendingEstimation = !!estimationsPending?.length;

  const hasFailedEstimation = !!estimationsFailed?.length;
  const hasEstimations = wholesaleOrder?.estimations !== undefined;
  const isThirdPartyParcelPending = hasEstimations && parcelEstimationThirdParty === -1;
  const isThirdPartyFreightPending = hasEstimations && freightEstimationThirdParty === -1;
  const isDeliverrParcelPending = hasEstimations && parcelEstimation === -1;
  const isDeliverrFreightPending = hasEstimations && freightEstimation === -1;
  const isThirdPartyParcelError = hasEstimations && parcelEstimationThirdParty === undefined;
  const isThirdPartyFreightError = hasEstimations && freightEstimationThirdParty === undefined;

  const deliverrPalletEstimation = freightEstimation;
  const deliverrParcelEstimation = parcelEstimation;

  const hasError: boolean = (wholesaleOrder?.errors ?? []).length > 0;

  usePolling(
    4000,
    hasPendingEstimation || ((wholesaleOrder?.createdAutomatically ?? false) && !isAutomatedFBAReady),
    () => dispatch(retryAction())
  );

  return {
    isAutomatedFBAReady,
    validatedDestinationAddress,
    deliverrPalletEstimation,
    deliverrParcelEstimation,
    thirdPartyParcelEstimation: parcelEstimationThirdParty,
    thirdPartyFreightEstimation: freightEstimationThirdParty,
    isThirdPartyFreightPending,
    isThirdPartyParcelPending,
    processingFeeEstimation,
    hasPendingEstimation,
    hasFailedEstimation,
    isThirdPartyParcelError,
    isThirdPartyFreightError,
    isDeliverrParcelPending,
    isDeliverrFreightPending,
    hasError,
  };
};
