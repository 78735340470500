import { ProductChooserProps } from "common/components/ProductChooser";
import { Box, Notification } from "common/components/ui";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";
import { hasInvalidSingleSkuPackages } from "inbounds/createShipment/steps/CargoDetailsStep/hasInvalidSingleSkuPackages";
import { isNil, some } from "lodash";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

export const UnsetPackConfigNotification: FC<
  Pick<
    ProductChooserProps,
    "selectedProducts" | "selectedProductCasePacks" | "isDtcSingleSkuBooking" | "singleSkuPackages"
  >
> = (props) => {
  const { selectedProducts, selectedProductCasePacks, isDtcSingleSkuBooking, singleSkuPackages } = props;
  const isAnyPackConfigUnselected = isDtcSingleSkuBooking
    ? hasInvalidSingleSkuPackages(singleSkuPackages ?? {})
    : some(
        selectedProducts?.map(
          (product) => !product.arePacksLoading && isNil(selectedProductCasePacks?.[product.dsku])
        ) ?? []
      );

  return isAnyPackConfigUnselected ? (
    <Box marginBottom="S4" marginTop="S4">
      <Notification
        data-testid="storage-inbound-unset-packs-notification"
        appearance="PRIMARY"
        append={
          <ElevioLearnMoreButton articleId={ElevioArticleId.ReserveStorageInboundPackSelection}>
            <FormattedMessage id="storage.inbound.unsetPackConfig.notification.help" defaultMessage="Learn more" />
          </ElevioLearnMoreButton>
        }
        content={{
          title: (
            <FormattedMessage
              id="storage.inbound.unsetPackConfig.notification.title"
              defaultMessage="Add a case pack template for each product."
            />
          ),
          description: (
            <FormattedMessage
              id="storage.inbound.unsetPackConfig.notification.description"
              defaultMessage="We'll save this information for future inbounds."
            />
          ),
        }}
      ></Notification>
    </Box>
  ) : null;
};
