import React, { ReactNode, useState } from "react";
import { Notification, NotificationAppearance } from "common/components/ui/Notification";

interface Props {
  alertType: NotificationAppearance;
  appendContent?: ReactNode;
  children?: ReactNode;
  description?: ReactNode;
  title?: ReactNode;
  onClose?: () => void;
  className?: string;
}

const DismissableAlert = ({ alertType, appendContent, children, description, title, onClose, className }: Props) => {
  const [display, setDisplay] = useState(true);
  const handleClick = () => {
    setDisplay(false);
    onClose?.();
  };

  if (display) {
    return (
      <Notification
        appearance={alertType}
        content={{
          title,
          description,
        }}
        append={appendContent}
        onClose={handleClick}
        className={className}
      >
        {children}
      </Notification>
    );
  } else {
    return null;
  }
};

export default DismissableAlert;
