import { useSelector } from "react-redux";
import { UnexpectedBarcodeProblemRow } from "common/components/ProblemsTable/types";
import { getShippingPlan } from "inbounds/steps/InboundStepSelectors";
import { SelectedShipmentProblem } from "../../context";

export const useShipmentProblemUnexpectedBarcodeBlade = (
  shipmentProblem: UnexpectedBarcodeProblemRow,
  hideShipmentProblemBlade: () => void,
  setSelectedProblem: (problem: SelectedShipmentProblem | undefined) => void
) => {
  const { mappedSkus } = shipmentProblem;

  const { name: shippingPlanName, id: shippingPlanId } = useSelector(getShippingPlan);

  const resetBladeState = () => {
    hideShipmentProblemBlade();
    setSelectedProblem(undefined);
  };

  return {
    resetBladeState,
    shippingPlanId,
    shippingPlanName,
    mappedSkus,
  };
};
