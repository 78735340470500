import { productClient } from "Clients";
import { logError } from "Logger";
import { useAsyncFn } from "react-use";

export function useBarcodeValidateService() {
  const [validationResult, runServiceValidation] = useAsyncFn(async (barcodes: string[]) => {
    if (barcodes.length) {
      try {
        const { existing } = await productClient.validateBarcodes(barcodes);

        return await Promise.resolve(existing);
      } catch (err) {
        logError({ fn: "useBarcodeValidateService" }, err);

        throw err;
      }
    }

    return [];
  });

  return { validationResult, runServiceValidation };
}
