import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Text } from "common/components/ui";

export const ShortShipLearnMore: FC = () => (
  <Text>
    <FormattedMessage
      id="inbounds.shipmentProblemShortShipBlade.learnMore"
      defaultMessage="Learn more about {link}."
      values={{
        link: (
          <ElevioBorderlessButton articleId={ElevioArticleId.ShortShippedBoxes}>
            <FormattedMessage
              id="inbounds.shipmentProblemShortShipBlade.learnMore.link"
              defaultMessage="short-shipped boxes"
            />
          </ElevioBorderlessButton>
        ),
      }}
    />
  </Text>
);
