import { useDispatch, useSelector } from "react-redux";
import { useMount, useUnmount } from "react-use";
import { getReturnsForOrder, resetOrderReturnsList } from "./store/OrderReturnsListActions";
import { useParams } from "react-router-dom";
import { RootState } from "RootReducer";

export const useOrderReturnsList = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams<{ orderId: string }>();
  const orderReturnsList = useSelector((state: RootState) => state.orderReturns.orderReturnsList);

  useMount(() => {
    dispatch(getReturnsForOrder(orderId));
  });

  useUnmount(() => {
    dispatch(resetOrderReturnsList());
  });

  return {
    orderId,
    orderReturnsList,
  };
};
