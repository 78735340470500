import cx from "classnames";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import facebookConnectAccountGraphic from "assets/img/tags/facebook/facebook-connect-account.svg";
import HtmlToReact from "common/components/HtmlToReact";
import { Path } from "paths";
import { FastTagsNav } from "tags/base/FastTagsNav";
import { FacebookLoginButton } from "tags/facebook/common/FacebookLoginButton/FacebookLoginButton";
import { LoginStatus } from "tags/facebook/model/Auth";
import { useConnectAccount } from "./useConnectAccount";

import cls from "./ConnectAccount.less";
import sharedCls from "../../../common/facebook.less";

export const ConnectAccount: FC = () => {
  const { profile, hasValidPermissions, loginStatus } = useConnectAccount();

  return (
    <div className={sharedCls.container}>
      <div className={cx(sharedCls.inner, sharedCls.body)}>
        <div className={sharedCls.bodyContent}>
          <h4 className={sharedCls.title}>
            <FormattedMessage
              id="FacebookFastTag.ConnectAccountTitle"
              defaultMessage={"Connect Flexport and Facebook"}
            />
          </h4>
          <p className={sharedCls.text}>
            <FormattedMessage
              id="FacebookFastTag.ConnectAccountMessage1"
              defaultMessage={
                "We require you to connect to Facebook to get your Facebook ads information. It’s usually connected to your personal Facebook account. You must be an {adminUser} on the Facebook Business account you wish Flexport to integrate with."
              }
              values={{
                adminUser: <span className={cls.adminUser}>admin user</span>,
              }}
            />
          </p>
          <div className={cls.loginStatus}>
            {loginStatus !== LoginStatus.Unknown && <FacebookLoginButton />}

            {loginStatus === LoginStatus.LoggedIn && profile && (
              <div className={cls.profile}>
                <img src={profile.picture} className={cls.profilePicture} />
                <FormattedMessage
                  id="FacebookFastTag.ProfileConnectedMessage"
                  defaultMessage="Connected as {name}"
                  values={{ name: profile.name }}
                />
              </div>
            )}
          </div>
        </div>
        <HtmlToReact html={facebookConnectAccountGraphic} />
      </div>

      <FastTagsNav
        className={sharedCls.nav}
        nextPage={Path.facebookAdsSettings}
        nextDisabled={!hasValidPermissions}
        previousPage={Path.facebookConnectShopify}
        nextButtonText={<FormattedMessage id="next" defaultMessage={"Next"} />}
        previousButtonText={<FormattedMessage id="back" defaultMessage={"Back"} />}
        nextButtonClassName={sharedCls.actionButton}
        previousButtonClassName={sharedCls.actionButton}
      />
    </div>
  );
};
