import React, { FC } from "react";

import { getNCStatusLabel } from "inbounds/NCShipmentSummaryDataStatus";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { Pill, PillColors } from "common/components/ui";
import { ProblemType } from "common/problems/ProblemType";
import { SellerProblemStatus } from "@deliverr/legacy-inbound-client";

type NCStatusPillColorMap = Record<SellerProblemStatus, PillColors>;
const DEFAULT_NC_PILL_COLORS: NCStatusPillColorMap = {
  [SellerProblemStatus.RESOLVED]: PillColors.GREEN,
  [SellerProblemStatus.CREATED]: PillColors.RED,
  [SellerProblemStatus.ACTION_NEEDED]: PillColors.RED,
  [SellerProblemStatus.SUPPORT_CONTACTED]: PillColors.YELLOW,
};

interface NCStatusPillProps {
  className?: string;
  status: SellerProblemStatus;
  shipmentProblemType?: ProblemType;
}

const StatusPill = styled(Pill)`
  line-height: 17px;
  white-space: nowrap;
`;

export const NCStatusPill: FC<NCStatusPillProps> = ({ className, status, shipmentProblemType }) => (
  <StatusPill className={className} color={DEFAULT_NC_PILL_COLORS[status]}>
    <FormattedMessage {...getNCStatusLabel(status, shipmentProblemType)} />
  </StatusPill>
);
