import { Box, Button, Modal, Title } from "common/components/ui";
import React from "react";
import { ModalFooterContainer } from "../../priceViewStep/selectShipping/selectShippingFlow/components/ModalFooterContainer";
import { FormattedMessage } from "react-intl";
import { LocalizedText } from "common/components/LocalizedText";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";
import { useAssignProductCategoriesModal } from "./useAssignProductCategoriesModal";
import { UnconnectedAssignProductCategoriesContent } from "inbounds/steps/categories";
import styled from "@emotion/styled";
import { Product } from "@deliverr/commons-clients";
import { ProductCollection } from "common/models";

const StyledBox = styled(Box)`
  height: 600px;
`;

export const AssignProductCategoriesModal = ({
  productsWithNoCategory,
  productDetails,
  showModal,
  setShowModal,
}: {
  productsWithNoCategory: Product[];
  productDetails: ProductCollection;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    onSave,
    onClose,
    allRowsSelected,
    anyRowsSelected,
    categorySelectOptions,
    itemDetails,
    productsSelected,
    selectedRows,
    setCategoryForSelectedItems,
    setItemCategory,
    toggleAllRows,
    toggleRow,
    hasMissingCategories,
  } = useAssignProductCategoriesModal(productsWithNoCategory, productDetails, setShowModal);

  return (
    <Modal hasCloseButton={false} onClose={onSave} show={showModal} width="800px" displayOverflow>
      <StyledBox>
        <Title displayAs="h4">
          <FormattedMessage
            id="inbounds.createShipment.dtc.cargoDetailstep.productCategoryModal.title"
            defaultMessage="Assign product categories"
          />
        </Title>
        <LocalizedText
          appearance="INFO"
          as="div"
          message={{
            id: "inbounds.createShipment.dtc.cargoDetailstep.productCategoryModal.description",
            defaultMessage:
              "Select product category for all the products without a category assigned previously. Any required packaging must be applied to each sellable unit to ensure they can be fulfilled without non-compliance or damages. Learn more about {link}.",
            values: {
              link: (
                <ElevioBorderlessButton
                  key={ElevioArticleId.ProductCategories}
                  articleId={ElevioArticleId.ProductCategories}
                >
                  <FormattedMessage
                    id="inbounds.createShipment.dtc.cargoDetailstep.productCategoryModal.description.link"
                    defaultMessage="product categories"
                  />
                </ElevioBorderlessButton>
              ),
            },
          }}
        />
        <UnconnectedAssignProductCategoriesContent
          allRowsSelected={allRowsSelected}
          anyRowsSelected={anyRowsSelected}
          categorySelectOptions={categorySelectOptions}
          itemDetails={itemDetails}
          productsSelected={productsSelected}
          selectedRows={selectedRows}
          setCategoryForSelectedItems={setCategoryForSelectedItems}
          setItemCategory={setItemCategory}
          toggleAllRows={toggleAllRows}
          toggleRow={toggleRow}
        />
      </StyledBox>
      <Box marginTop="S5">
        <ModalFooterContainer>
          <Button secondary onClick={onClose}>
            <FormattedMessage id="createShipment.cargoDetail.productCategoriesModal.cancel" defaultMessage="Cancel" />
          </Button>
          <Button onClick={onSave} disabled={hasMissingCategories}>
            <FormattedMessage id="createShipment.cargoDetail.productCategoriesModal.save" defaultMessage="Save" />
          </Button>
        </ModalFooterContainer>
      </Box>
    </Modal>
  );
};
