import {
  SmbClientOrderContainerSize,
  SmbClientOrderContainerType,
  SmbClientOrderIncoterm,
  SmbClientOrderServiceMode,
  SmbClientOrderBatteriesPackingType,
} from "@deliverr/commons-clients";
import { BookingAddress, InboundBookingRequest, InboundBookingType } from "@deliverr/legacy-inbound-client";
import { subLocalTimeZoneOffset } from "common/date/subLocalTimeZoneOffset";
import { FeatureName, getFeatureSelector } from "common/Split";
import { getSellerId } from "common/user/UserSelectors";
import { DEFAULT_INTERNATIONAL_WAREHOUSE_ADDRESS } from "inbounds/constants/addressConstant";
import { AmazonDestinationType, CargoInputType } from "inbounds/createShipment/CreateShipmentTypes";
import {
  selectAirShipmentCargoDetails,
  selectBookingProducts,
  selectCargoInputType,
  selectCreateShipmentForm,
  selectDerivedBookingType,
  selectPlaceNames,
} from "inbounds/createShipment/store/selectors";
import {
  placeToBookingAddress,
  mapAmazonAddressToBookingAddress,
  mapDtcPackageCollectionToPackageSummary,
  mapPackagesummaryToInboundPackageSummary,
} from "inbounds/createShipment/store/utils";
import isEmpty from "lodash/isEmpty";
import pickBy from "lodash/pickBy";
import { createSelector } from "reselect";
import { CreateShipmentFormState } from "../CreateShipmentState";
import { selectReserveStorage } from "./selectReserveStorage";
import { selectInboundDtcPrepOption } from "./selectInboundDtcPrepOption";

const HAS_NO_STORAGE_REQUEST = Object.freeze([InboundBookingType.INTL_DIRECT_P2P, InboundBookingType.INTL_DTC]);

const isPortFid = (fid: string) => {
  return fid.includes("port");
};

const mapLithiumBatteryPackaginToEnum = (input: CreateShipmentFormState) => {
  const resultArray: SmbClientOrderBatteriesPackingType[] = [];
  if (input.lithiumBatteryPackaging.packedEquipment) {
    resultArray.push(SmbClientOrderBatteriesPackingType.PACKED_WITH_EQUIPMENT);
  }
  if (input.lithiumBatteryPackaging.inEquipment) {
    resultArray.push(SmbClientOrderBatteriesPackingType.CONTAINED_IN_EQUIPMENT);
  }
  if (input.lithiumBatteryPackaging.inVehicle) {
    resultArray.push(SmbClientOrderBatteriesPackingType.CONTAINED_IN_BATTERY_POWERED_VEHICLE);
  }
  if (input.lithiumBatteryPackaging.packedLoose) {
    resultArray.push(SmbClientOrderBatteriesPackingType.PACKED_LOOSE);
  }
  return resultArray;
};

/** Stripping the local timezone offset will ensure the NIS can correctly save in short format (yyyy-MM-dd) */
const prepareDate = (date: Date | null) => {
  return date ? subLocalTimeZoneOffset(date) : undefined;
};
/**
 * This selector maps all current state to all possible booking request fields.
 * Since state contains defaults, its important to use selectBookingRequestFoStep to ensure
 * only fields relevant to the current step are included.
 *
 * If step inputs change, make sure to update the relevant step in selectBookingRequestForStep
 */

export const selectBookingRequest = (shouldSubmit: boolean) =>
  createSelector(
    selectCreateShipmentForm,
    getSellerId,
    selectDerivedBookingType,
    selectBookingProducts,
    selectAirShipmentCargoDetails,
    getFeatureSelector(FeatureName.SmbOfferingTrace),
    selectPlaceNames,
    selectCargoInputType,
    getFeatureSelector(FeatureName.IntlInboundRsSelection),
    selectReserveStorage,
    selectInboundDtcPrepOption,
    (
      formValues,
      sellerId,
      bookingType,
      bookingProductsValue,
      airShipmentCargoDetails,
      shouldSendOfferLog,
      finalizedPlaceNames,
      cargoInputType,
      isRsSelectionFeatureOn,
      reserveStorage,
      dtcPrepOption
    ) => {
      // Origin
      const originIsPort = formValues.origin.value.ports.length !== 0;
      const from = placeToBookingAddress(formValues.origin, finalizedPlaceNames.originName, undefined, originIsPort);
      const originPortGrouping = formValues.origin.value.ports;
      const originFid = formValues.freightService?.originFid ?? "";
      const originAddressFid = isPortFid(originFid) ? undefined : originFid;
      const originPortFid = isPortFid(originFid) ? originFid : undefined;

      // Supplier
      const supplier = isEmpty(formValues.supplier.companyName) ? undefined : formValues.supplier;

      // Destination
      const fbaWarehouseId =
        formValues.amazonDestinationType === AmazonDestinationType.SINGLE ? formValues.amazonAddress?.code : undefined;
      const fbaWarehouseAddress = mapAmazonAddressToBookingAddress(formValues.amazonAddress);
      const reserveStorageWarehouseAddress =
        isRsSelectionFeatureOn && formValues.destinationSelect.isByFlexport && !formValues.destinationSelect.isByAmazon
          ? reserveStorage.address
            ? ({ ...reserveStorage.address, latLng: undefined } as BookingAddress)
            : undefined
          : ({ ...DEFAULT_INTERNATIONAL_WAREHOUSE_ADDRESS, latLng: undefined } as BookingAddress);
      const destinationIsPort = formValues.destination.value.ports.length !== 0;
      const isLiftgateRequired = destinationIsPort ? false : formValues.isLiftgateRequired ?? false;
      const to = {
        fbaWarehouseId: fbaWarehouseId ?? undefined,
        fbaWarehouseAddress:
          (isEmpty(formValues.destination.value.address.city)
            ? fbaWarehouseAddress
            : placeToBookingAddress(formValues.destination, finalizedPlaceNames.destinationName, isLiftgateRequired)) ??
          undefined,
        reserveStorageWarehouseAddress,
        warehouseId: !HAS_NO_STORAGE_REQUEST.includes(bookingType)
          ? reserveStorage?.warehouseId ?? undefined
          : undefined,
      };
      const destinationPortGrouping = formValues.destination.value.ports;
      const destinationFid = formValues.freightService?.destinationFid ?? "";
      const destinationPortFid = isPortFid(destinationFid) ? destinationFid : undefined;
      const destinationAddressFid = isPortFid(destinationFid) ? undefined : destinationFid;
      const targetDeliveryDate = prepareDate(formValues.deliveryDate.date);
      const flexibleDeliveryDate = formValues.deliveryDate.isFlexible;

      // Cargo
      const {
        pallets: palletCount,
        volume: cargoTotalVolume,
        weight: cargoTotalWeight,
        boxes: cargoTotalBoxes,
        units: cargoTotalItems,
      } = pickBy(formValues.shipmentTotals, (value) => value > 0);
      const cargoUnitSystem = formValues.unitSystem;
      const isCargoMixedSku = formValues.isCargoMixedSku;
      // we get an error when submitting an empty array for bookingProducts
      // TODO: @gxdesign - update once API supports empty array for clearing products
      // https://flexport.atlassian.net/browse/SMB-3353
      const bookingProducts =
        cargoInputType === CargoInputType.PRODUCTS && bookingProductsValue.length > 0 ? bookingProductsValue : [];
      const dangerousGoods = formValues.dangerousGoods.hasNoDangerousGoods
        ? false
        : formValues.hasDangerousGoods ?? undefined;
      const lithiumBatteries = formValues.dangerousGoods.hasLithiumBatteries;
      const nonLithiumBatteries = formValues.dangerousGoods.hasNonLithiumBatteries;
      const otherDangerousGoods = formValues.dangerousGoods.hasOther;
      const lithiumBatteryPackaging = formValues.dangerousGoods.hasLithiumBatteries
        ? mapLithiumBatteryPackaginToEnum(formValues)
        : undefined;
      const cargoReadyDate = prepareDate(formValues.cargoReadyDate);

      const packages =
        bookingType === InboundBookingType.INTL_DTC && cargoInputType === CargoInputType.PRODUCTS
          ? isCargoMixedSku
            ? mapPackagesummaryToInboundPackageSummary(formValues.mixedSkuPackages)
            : mapPackagesummaryToInboundPackageSummary(
                mapDtcPackageCollectionToPackageSummary(formValues.singleSkuPackages)
              )
          : [];

      // Booking
      const offeringTitle = formValues.freightService?.title ?? undefined;
      const includeCustomsBond = formValues.hasAnnualCustomsBond;
      const addFlexportInsurance = formValues.hasCargoInsurance;
      const quoteFids = formValues.freightService?.offeringFids
        ? formValues.freightService?.offeringFids
        : formValues.freightService?.quoteId
        ? [formValues.freightService.quoteId]
        : undefined;
      const offerings =
        quoteFids && shouldSendOfferLog
          ? [...quoteFids, `SMB_OFFERING_TRACE${JSON.stringify(formValues.freightService)}`]
          : quoteFids;
      const serviceModes = formValues.freightService?.mode
        ? [formValues.freightService.mode as SmbClientOrderServiceMode]
        : [];
      const onceanFreightPriceDetails = formValues.freightService?.priceDetails?.oceanFreight ?? [];
      const containerSize =
        onceanFreightPriceDetails.length > 0
          ? (onceanFreightPriceDetails[0].quantityType as SmbClientOrderContainerSize)
          : undefined;
      const containers = [
        {
          size: containerSize ?? SmbClientOrderContainerSize.TWENTY_FT,
          type: SmbClientOrderContainerType.DRY,
          count: onceanFreightPriceDetails[0]?.quantity ?? 1,
        },
      ];
      const incoterm = formValues.freightService?.incoTerms?.[0] ?? SmbClientOrderIncoterm.FOB;
      const arrivalExpectedAt = formValues.freightService?.receiveDate ?? undefined;
      const insuranceCargoValueCents =
        formValues.hasCargoInsurance && formValues.insuranceInfo.totalCargoValue !== 0
          ? Math.ceil(formValues.insuranceInfo.totalCargoValue * 100)
          : undefined;
      const insurancePremiumCents =
        formValues.hasCargoInsurance && formValues.insuranceInfo.insurancePremiumCents !== 0
          ? formValues.insuranceInfo.insurancePremiumCents
          : undefined;
      const totalCommercialValue =
        formValues.hasCargoInsurance && formValues.insuranceInfo.totalCargoValue !== 0
          ? Math.ceil(formValues.insuranceInfo.totalCargoValue)
          : undefined;
      // TODO(ylu1): temporary solution to unblock booking, will check with client-order the units of this value
      const applyForFlexportCapital = formValues.flexportCapitalSelected;
      const amazonPrepOption = formValues.amazonPrepType ?? undefined;
      const bookingRequest: InboundBookingRequest = {
        bookingType,
        from,
        to,
        originIsPort,
        originPortGrouping,
        destinationIsPort,
        destinationPortGrouping,
        sellerId,
        palletCount,
        cargoTotalWeight,
        cargoTotalVolume,
        cargoTotalBoxes,
        cargoTotalItems,
        cargoUnitSystem,
        totalCommercialValue,
        dangerousGoods,
        lithiumBatteries,
        nonLithiumBatteries,
        otherDangerousGoods,
        lithiumBatteryPackaging,
        cargoReadyDate,
        targetDeliveryDate,
        flexibleDeliveryDate,
        arrivalExpectedAt,
        bookingProducts,
        packages,
        airShipmentCargoDetails,
        includeCustomsBond,
        addFlexportInsurance,
        offerings,
        supplier,
        originAddressFid,
        originPortFid,
        destinationPortFid,
        destinationAddressFid,
        serviceModes,
        containers,
        incoterm,
        insuranceCargoValueCents,
        insurancePremiumCents,
        offeringTitle,
        applyForFlexportCapital,
        submit: shouldSubmit,
        // TODO - Remove any after Inbound client import PrepOption from business types
        prepOption: amazonPrepOption as any,
        dtcPrepOption,
      };

      return bookingRequest;
    }
  );
