import produce from "immer";

import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { FacebookConnectActionTypes } from "../actions/FacebookConnectActionTypes";
import { Auth, isLoginSuccessful, LoginStatus } from "../../model/Auth";
import { SellerRegistrationState } from "../../model/Completion";
import { getProfileInfo, ProfileInfo } from "../../model/Profile";
import { SetupMethod } from "../../model/SetupMethod";
import { WithAdSetSyncState } from "tags/facebook/common/state/adSetsReducer";
import { FacebookActionTypes } from "tags/facebook/common/actions/FacebookActionTypes";
import { FacebookAdAccount } from "common/clients/facebook/FacebookAdAccount";
import { FacebookPixel } from "common/clients/facebook/FacebookPixel";
import { FacebookPage } from "common/clients/facebook/FacebookPage";
import { ShopifyError } from "common/clients/shopify/ShopifyError";

export type FacebookState = {
  loginStatus: LoginStatus;
  activationError?: ShopifyError;
  activeAdAccountId?: FacebookAdAccount["adAccountId"];
  activeSetupMethod: SetupMethod | null;
  activePageId?: FacebookPage["pageId"];
  activePixelId?: FacebookPixel["id"];
  adAccounts: FacebookAdAccount[] | null;
  auth: Auth;
  hasValidPermissions: boolean | null;
  pages: FacebookPage[] | null;
  pixels: FacebookPixel[] | null;
  profile?: ProfileInfo;
  sellerRegistration: SellerRegistrationState;
} & WithAdSetSyncState;

export const facebookLoginStatusUnknown = null;

export const facebookNotLoggedIn: Auth = {
  authResponse: null,
  status: "unknown",
};

export const facebookInitialState: FacebookState = {
  loginStatus: LoginStatus.Unknown,
  auth: facebookNotLoggedIn,
  adAccounts: null,
  activeSetupMethod: null,
  pages: null,
  hasValidPermissions: null,
  sellerRegistration: "incomplete",
  pixels: null,
  adSets: null,
};

export const reducers: ReducerDictionary<FacebookState> = {
  ...handleSimpleReducerUpdates([
    FacebookConnectActionTypes.FACEBOOK_AD_ACCOUNTS_RECEIVED,
    FacebookConnectActionTypes.FACEBOOK_PAGES_RECEIVED,
    FacebookConnectActionTypes.FACEBOOK_PERMISSIONS_CHECKED,
    FacebookConnectActionTypes.FACEBOOK_PIXELS_RECEIVED,
  ]),
  [FacebookConnectActionTypes.FACEBOOK_LOGIN_STATUS_RECEIVED]: (state, { auth, newLogin }) =>
    produce(state, (draftState) => {
      if (isLoginSuccessful(auth)) {
        if (newLogin) {
          draftState = Object.assign(draftState, facebookInitialState);
        }
        draftState.loginStatus = LoginStatus.LoggedIn;
      } else {
        draftState.loginStatus = LoginStatus.LoggedOut;
        draftState.profile = undefined;
        draftState.hasValidPermissions = null;
      }

      draftState.auth = auth;
    }),
  [FacebookConnectActionTypes.FACEBOOK_PAGE_SELECTED]: (state, { pageId }) =>
    produce(state, (draftState) => {
      draftState.activePageId = pageId;
    }),
  [FacebookConnectActionTypes.FACEBOOK_AD_ACCOUNT_SELECTED]: (state, { adAccountId }) =>
    produce(state, (draftState) => {
      draftState.activeAdAccountId = adAccountId;
    }),
  [FacebookConnectActionTypes.FACEBOOK_SELLER_REGISTRATION_COMPLETE]: (state) =>
    produce(state, (draftState) => {
      draftState.sellerRegistration = "complete";
    }),
  [FacebookConnectActionTypes.FACEBOOK_PROFILE_INFO_RECIEVED]: (state, { profileInfo: profileInfoResponse }) =>
    produce(state, (draftState) => {
      draftState.profile = getProfileInfo(profileInfoResponse);
    }),
  [FacebookConnectActionTypes.FACEBOOK_PIXEL_SELECTED]: (state, { pixelId }) =>
    produce(state, (draftState) => {
      draftState.activePixelId = pixelId;
    }),
  [FacebookActionTypes.FACEBOOK_ERROR_SYNCING_AD_SETS]: (state) =>
    produce(state, (draftState) => {
      draftState.adSets = "error";
    }),
  [FacebookActionTypes.FACEBOOK_START_SYNC_AD_SETS]: (state) =>
    produce(state, (draftstate) => {
      draftstate.adSets = null;
    }),
  [FacebookActionTypes.FACEBOOK_REFRESH_SELLER_COMPLETED]: (state) =>
    produce(state, (draftstate) => {
      draftstate.adSets = "syncing";
    }),
  [FacebookActionTypes.FACEBOOK_AD_SETS_RECEIVED]: (state, { adSets }) =>
    produce(state, (draftState) => {
      draftState.adSets = adSets;
    }),
};

export const facebookReducer = createReducer<FacebookState>(facebookInitialState, reducers);
