import history from "BrowserHistory";
import { batch } from "react-redux";
import { SPThunkAction } from "common/ReduxUtils";
import { Path } from "paths";
import { logError, logStart } from "Logger";
import { hideModal } from "common/components/Modal/ModalActions";
import { internationalClient, productClient } from "Clients";
import { inventoryClient, sellerClient } from "common/clients/instances";
import { updateLocalUser } from "common/user/UserActions";
import { notifyUserOfError } from "common/ErrorToast";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { toast } from "common/components/ui";

import { CustomsInformation } from "@deliverr/commons-clients";
import { CountryCode } from "@deliverr/commons-objects";
import { DDPPreference } from "@deliverr/international-client/lib/shared/SellerSettings";
import { internationalShippingIntegrationMessages } from "./InternationalShippingMessages";
import { FormattedMessage } from "react-intl";
import React from "react";

export enum InternationalShippingActionTypes {
  GET_INTERNATIONAL_SHIPPING_PRODUCTS = "GET_INTERNATIONAL_SHIPPING_PRODUCTS",
  UPDATE_INTERNATIONAL_SHIPPING_PRODUCT = "UPDATE_INTERNATIONAL_SHIPPING_PRODUCT",
  SAVE_INTERNATIONAL_SHIPPING_PRODUCT = "SAVE_INTERNATIONAL_SHIPPING_PRODUCT",
  UPDATE_INTERNATIONAL_SHIPPING_PRODUCTS = "UPDATE_INTERNATIONAL_SHIPPING_PRODUCTS",
  UPDATE_CREATED_CUSTOMS_INFORMATION = "UPDATE_CREATED_CUSTOMS_INFORMATION",
  CLEAR_INTERNATIONAL_SHIPPING_PRODUCTS = "CLEAR_INTERNATIONAL_SHIPPING_PRODUCTS",
}

export const internationalShippingOptOutModalId = "internationalShippingOptOut";
export const internationalShippingOptInButtonId = "customsInformationOptInButton";
export const getInternationalShippingLoaderId = "getInternationalShipping";
export const internationalShippingIntegrationLoaderId = "internationalShippingIntegration";

export const getInternationalSellerSettings = (): SPThunkAction => async (dispatch, getState) => {
  const ctx = { fn: "getInternationalSellerSettings" };
  const {
    user: { sellerId },
  } = getState();

  try {
    dispatch(addLoader(internationalShippingIntegrationLoaderId));
    const response = await internationalClient.getSellerSettings(sellerId);
    if (!response) {
      dispatch(
        updateLocalUser({
          internationalDDPPreference: DDPPreference.NONE,
        })
      );
      return;
    }
    dispatch(
      updateLocalUser({
        internationalDDPPreference: response!.ddpPreference,
        internationalMerchantGUID: response!.merchantOfRecordAccountId,
      })
    );
  } catch (err) {
    toast.error(<FormattedMessage {...internationalShippingIntegrationMessages.integrationError} />, {
      autoClose: 5000,
      toastId: "getInternationalSellerSettingsError",
    });
    logError(ctx, err);
  } finally {
    dispatch(clearLoader(internationalShippingIntegrationLoaderId));
  }
};

export const internationalShippingOptIn =
  (selectedOption, merchantGuid?): SPThunkAction =>
  async (dispatch, getState) => {
    const ctx = logStart({ fn: "internationalShippingOptIn" });
    const {
      user: { sellerId },
    } = getState();
    try {
      dispatch(addLoader(internationalShippingOptInButtonId));
      await internationalClient.createOrUpdateSellerSettings(sellerId, {
        ddpPreference: selectedOption,
        merchantOfRecordAccountId: merchantGuid,
      });
      await sellerClient.createOrUpdateSettings(sellerId, { sellerId, isInternationalShippingEnabled: true });
      dispatch(
        updateLocalUser({
          isInternationalShippingEnabled: true,
          internationalDDPPreference: selectedOption,
          internationalMerchantGUID: merchantGuid,
        })
      );
      history.push(Path.settingsInternationalShipping);
    } catch (err) {
      notifyUserOfError({ err: err.error, toastId: "internationalShippingOptIn" });
      logError(ctx, err);
    } finally {
      dispatch(getInternationalSellerSettings());
      dispatch(clearLoader(internationalShippingOptInButtonId));
    }
  };

export const internationalShippingOptOut = (): SPThunkAction => async (dispatch, getState) => {
  const ctx = logStart({ fn: "internationalShippingOptOut" });
  const {
    user: { sellerId },
  } = getState();
  try {
    await sellerClient.createOrUpdateSettings(sellerId, { sellerId, isInternationalShippingEnabled: false });
    batch(() => {
      dispatch(
        updateLocalUser({ isInternationalShippingEnabled: false, internationalDDPPreference: DDPPreference.NONE })
      );
      dispatch(hideModal(internationalShippingOptOutModalId));
    });
  } catch (err) {
    notifyUserOfError({ err: err.error, toastId: "internationalShippingOptOut" });
    logError(ctx, err);
  }
};

export const getInternationalShippingProducts = (): SPThunkAction => async (dispatch, getState) => {
  const ctx = logStart({ fn: "getInternationalShippingProducts" });
  const {
    user: { sellerId },
  } = getState();
  try {
    dispatch(addLoader(getInternationalShippingLoaderId));
    const availableSkus = await inventoryClient.getAvailableSkusForSeller(sellerId);
    const products = await productClient.getProducts(availableSkus, { includeCustomsInformation: true });
    dispatch({
      type: InternationalShippingActionTypes.GET_INTERNATIONAL_SHIPPING_PRODUCTS,
      products,
    });
  } catch (err) {
    notifyUserOfError({ err: err.error, toastId: "getInternationalShippingProducts" });
    logError(ctx, err);
  } finally {
    dispatch(clearLoader(getInternationalShippingLoaderId));
  }
};

export const updateInternationalShippingProduct =
  (customsInformation: CustomsInformation): SPThunkAction =>
  (dispatch) =>
    dispatch({
      type: InternationalShippingActionTypes.UPDATE_INTERNATIONAL_SHIPPING_PRODUCT,
      customsInformation,
    });

export const saveInternationalShippingProduct =
  (customsInformation: CustomsInformation): SPThunkAction =>
  async (dispatch) => {
    const ctx = logStart({ fn: "saveInternationalShippingProduct" });

    try {
      dispatch(addLoader(customsInformation.dsku));
      await productClient.createCustomsInformation(customsInformation);
      dispatch({
        type: InternationalShippingActionTypes.SAVE_INTERNATIONAL_SHIPPING_PRODUCT,
        dsku: customsInformation.dsku,
      });
    } catch (err) {
      logError(ctx, err);
      notifyUserOfError({ err: err.error, toastId: "saveInternationalShippingProduct" });
    } finally {
      dispatch(clearLoader(customsInformation.dsku));
    }
  };

export const applyToAllInternationalShipping =
  (prop: string, value: CountryCode | string): SPThunkAction =>
  (dispatch) =>
    dispatch({
      type: InternationalShippingActionTypes.UPDATE_INTERNATIONAL_SHIPPING_PRODUCTS,
      prop,
      value,
    });

export const updateCreatedInternationalShippingProduct =
  (customsInformation: CustomsInformation): SPThunkAction =>
  async (dispatch) => {
    const ctx = logStart({ fn: "updatedSavedInternationalShippingProduct", customsInformation });

    try {
      dispatch(addLoader(customsInformation.dsku));
      await productClient.updateCustomsInformation(customsInformation.dsku, customsInformation);
      dispatch({ type: InternationalShippingActionTypes.UPDATE_CREATED_CUSTOMS_INFORMATION });
    } catch (err) {
      notifyUserOfError({ err: err.error, toastId: "updatedSavedInternationalShippingProduct" });
      logError(ctx, err);
    } finally {
      dispatch(clearLoader(customsInformation.dsku));
    }
  };

export const clearInternationalShippingAdmin = (): SPThunkAction => (dispatch) =>
  dispatch({
    type: InternationalShippingActionTypes.CLEAR_INTERNATIONAL_SHIPPING_PRODUCTS,
    products: {},
  });
