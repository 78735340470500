import { SellerProblemType, SellerProblemsDetailView } from "@deliverr/legacy-inbound-client";
import { ProblemType } from "common/problems/ProblemType";
import { ProductCollection } from "common/models";
import { getMappedSkuPairs } from "inbounds/steps/ship/view/cards/ShipmentProblems/spvUtils/getMappedSkuPairs";
import { ProblemRowData } from "common/components/ProblemsTable/types";

const SellerProblemTypeToProblemTypeMap: Record<SellerProblemType, ProblemType> = {
  [SellerProblemType.UNKNOWN_BARCODE]: ProblemType.UNKNOWN_BARCODE,
  [SellerProblemType.MISSING_BARCODE]: ProblemType.MISSING_BARCODE,
  [SellerProblemType.DAMAGED_PRODUCT]: ProblemType.DAMAGED_PRODUCT,
  [SellerProblemType.UNEXPECTED_SKU]: ProblemType.UNEXPECTED_SKU,
  [SellerProblemType.UNEXPECTED_SKU_V2]: ProblemType.UNEXPECTED_SKU,
  [SellerProblemType.INVALID_LOT_EXPIRY]: ProblemType.ILLEGIBLE_EXPIRATION,
  [SellerProblemType.MISSING_BOXES]: ProblemType.MISSING_CONTAINER_LABEL,
  [SellerProblemType.OTHER_NON_COMPLIANCE]: ProblemType.OTHER_NON_COMPLIANCE,
  [SellerProblemType.RELABELLED_BOXES]: ProblemType.RELABELED_BOX,
  [SellerProblemType.FLEXPORT_EXCEPTION]: ProblemType.FLEXPORT_SHIP,
  [SellerProblemType.MISSING_CONTAINER_LABEL]: ProblemType.MISSING_CONTAINER_LABEL,
  [SellerProblemType.ILLEGIBLE_LOT]: ProblemType.ILLEGIBLE_LOT,
  [SellerProblemType.ILLEGIBLE_EXPIRATION]: ProblemType.ILLEGIBLE_EXPIRATION,
  [SellerProblemType.EXPIRED_PRODUCT]: ProblemType.EXPIRED_PRODUCT,
  [SellerProblemType.OVER_RECEIVED_UNITS]: ProblemType.OVER_RECEIVED_UNITS,
  [SellerProblemType.SHORT_SHIP]: ProblemType.SHORT_SHIP_UNITS,
};

export const getNonComplianceRowsFromGenericIssueCases = (
  genericIssue: SellerProblemsDetailView,
  productCollection?: ProductCollection
): ProblemRowData => {
  const {
    sellerProblemId,
    status,
    updatedAt,
    dsku,
    shippingPlanId,
    shippingPlanName,
    reportedUnits,
    receivedUnits,
    rawImages,
    issueId,
    isLegacyCase,
    affectedCdskus,
    createdAt,
  } = genericIssue;

  return {
    id: sellerProblemId?.toString() ?? issueId!,
    isLegacyCase,
    problemType: SellerProblemTypeToProblemTypeMap[genericIssue.type],
    shippingPlanId,
    shippingPlanName,
    createdAt,
    updatedAt,
    status,
    units: reportedUnits ?? receivedUnits,
    mappedSkus: productCollection
      ? getMappedSkuPairs(genericIssue, productCollection)
      : dsku
      ? [{ dsku, msku: "", productName: "" }]
      : [],
    photos: rawImages!,
    affectedCdskus,
    // This is from a half baked feature but if we don't specify it, it'll look like the user needs to take action
    // based on the logic in getIsUnacknowledgedProblemRow
    acknowledgedAt: new Date(),
  };
};
