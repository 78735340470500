import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { BrandedPackagingLabel } from "common/components/messages/BrandedPackagingLabel";
import { productCategoriesContent } from "common/content/productCategoriesContent";
import { isNil } from "lodash/fp";
import { Product } from "@deliverr/commons-clients";

interface ProductCategoryLabelProps {
  product: Pick<Product, "productPreparation" | "brandedPackaging">;
}

export const ProductCategoryLabel: FC<ProductCategoryLabelProps> = ({ product }) => {
  const category = product.productPreparation?.category;
  // Display a default for branded packaging
  return product.brandedPackaging ? (
    <BrandedPackagingLabel />
  ) : isNil(category) ? null : (
    <FormattedMessage {...productCategoriesContent[category]} />
  );
};
