import { transfersShipmentCreateLabels } from "../../transfersShipmentCreate.labels";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ManagedStep, useStepManager } from "common/components/StepContainer";
import { AmazonLogo, getFbaHelpCenterLink } from "./CommonFbaIntegrationComponents";
import { Box, Stack } from "common/components/ui";
import { useSwitchToManualFba } from "./useSwitchToManualFba";
import { StyledAnchor } from "../../../ui/StyledAnchor";
import { generatePath, useHistory } from "react-router-dom";
import { Path } from "../../../../paths";
import { ListingSolution } from "../../../../common/clients/onboarding/ListingSolution";

export const FbaOAuthAuthorizationStep = () => {
  const { formatMessage } = useIntl();
  const { stepManager } = useStepManager();
  const history = useHistory();

  const onManualTransferClick = useSwitchToManualFba();

  return (
    <ManagedStep
      data-testid="create-transfer-fba-forwarding-oauth"
      header={<AmazonLogo />}
      title={formatMessage(transfersShipmentCreateLabels.steps.fbaIntegration.oauth.title)}
      nextCaption={formatMessage(transfersShipmentCreateLabels.steps.fbaIntegration.connect)}
      secondaryButton={formatMessage(transfersShipmentCreateLabels.steps.fbaIntegration.skip)}
      onClickSecondary={() => stepManager.next(-1)}
      subtitle={
        <Stack center direction="VERTICAL">
          <Box width={"58%"}>
            <div>
              <FormattedMessage
                {...transfersShipmentCreateLabels.steps.fbaIntegration.oauth.subtitle}
                values={{
                  learnMore: getFbaHelpCenterLink(),
                }}
              />
            </div>
            <div>
              <FormattedMessage
                {...transfersShipmentCreateLabels.steps.fbaIntegration.oauth.amazonFreight}
                values={{
                  manualFbaStepLink: (text) => <StyledAnchor onClick={onManualTransferClick}>{text}</StyledAnchor>,
                }}
              />
            </div>
          </Box>
        </Stack>
      }
      nextData={async () => {
        history.push(
          `${generatePath(Path.channelsIntegrationSetup, {
            channel: ListingSolution.AMAZON,
          })}?state=FBA`
        );
        return 0;
      }}
    ></ManagedStep>
  );
};
