import styled from "@emotion/styled";
import { HTMLAttributes } from "react";
import { Space, DefaultTheme, Responsive, getResponsiveStyles } from "../shared";
import { getGap } from "./getGap";
import { getColumns } from "./getColumns";

export interface GridProps extends HTMLAttributes<HTMLDivElement> {
  /** Accepts any values for grid-template-column */
  columns?: Responsive<string>;
  /** Values for grid-gap */
  gap?: Responsive<keyof typeof Space | null>;
  /** Align items */
  alignItems?: string;
  /** Sets grid to inline-grid */
  inline?: boolean;
  /** Sets container to 100% width */
  fullWidth?: boolean;
}

export const Grid = styled.div<GridProps, DefaultTheme>(
  ({ columns, gap, alignItems = "initial", inline, fullWidth, theme }) => {
    return `
      align-items: ${alignItems};
      display: ${inline ? "inline-grid" : "grid"};
      ${getResponsiveStyles("grid-gap", gap, getGap(theme))}
      ${getResponsiveStyles("grid-template-columns", columns, getColumns)}
      ${fullWidth ? `width: 100%` : ``};
    `;
  }
);
