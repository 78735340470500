import { DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import { sidebarTheme } from "./SidebarTheme";

export const SidebarDivider = styled.hr<{}, DefaultTheme>(
  ({ theme }) => `
    margin: ${sidebarTheme.sectionMargin} 0;
    border-color: ${theme.colors.NEUTRAL[400]};
  `
);
