import { ProductPrepCategory } from "@deliverr/commons-clients";
import { createSelector } from "reselect";

import { getProductCategories } from "inbounds/steps/InboundStepSelectors";
import { getHasHazmat } from "../productDetailsCache";

export const getShowPackagingRequirements = createSelector(
  getProductCategories,
  getHasHazmat,
  (categories, hasHazmat) =>
    categories.some((category) => category !== undefined && category !== ProductPrepCategory.OTHER) || hasHazmat
);
