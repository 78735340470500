import React, { FC } from "react";
import { Box, Text } from "common/components/ui";
import { TextAlign } from "common/components/ui/shared";
import { FormattedMessage } from "react-intl";
import { transfersShipmentCreateLabels } from "../../transfersShipmentCreate.labels";
import styled from "@emotion/styled";
import { CostTable, CostTableProps } from "common/components/CostTable";

const CenteredBox = styled(Box)`
  margin-inline: auto;
`;

export const TransferConfirmPricingSummary: FC<CostTableProps & { shouldHideTitle?: boolean }> = ({
  shouldHideTitle,
  ...costTableProps
}) => {
  return (
    <>
      {!shouldHideTitle && (
        <Box marginBottom="S2" marginTop="S7" data-testid="pricing-summary">
          <Text bold textAlign={TextAlign.start}>
            <FormattedMessage {...transfersShipmentCreateLabels.steps.confirm.pricingSummary} />
          </Text>
        </Box>
      )}
      <CenteredBox>
        <CostTable data-testid="confirm-pricing" {...costTableProps} />
      </CenteredBox>
    </>
  );
};
