import React from "react";
import { LocalizedText } from "common/components/LocalizedText";
import { Box } from "common/components/ui";
import { DangerousGoodsChecklist } from "./DangerousGoodsChecklist";
import { useSmbAccelerateFeatureOn } from "common/featureFlags";
import { FeatureName } from "common/Split";
import styled from "@emotion/styled";
import { OldDangerousGoodsToggle } from "./OldDangerousGoodsToggle";

const SectionContainer = styled.div`
  label {
    margin-bottom: 0;
  }
`;

export const DangerousGoodsSection = () => {
  const isLithiumBatteriesWorkflowOn = useSmbAccelerateFeatureOn(FeatureName.SmbAccelerateLithiumBatteriesWorkflow);

  return (
    <SectionContainer>
      <LocalizedText
        bold
        as="div"
        size="bodyLarge"
        message={{
          id: "inbounds.createShipment.dangerousGoods.sectionTitle",
          defaultMessage: "Dangerous Goods",
        }}
      />
      {isLithiumBatteriesWorkflowOn ? (
        <Box marginTop="S6">
          <DangerousGoodsChecklist />
        </Box>
      ) : (
        <Box marginTop="S4">
          <OldDangerousGoodsToggle />
        </Box>
      )}
    </SectionContainer>
  );
};
