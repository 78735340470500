export enum GoogleShoppingError {
  DUPLICATE_MERCHANT_ID = "DUPLICATE_MERCHANT_ID",
  DUPLICATE_WAREHOUSE_COMBINATION = "DUPLICATE_WAREHOUSE_COMBINATION",
  DUPLICATE_WAREHOUSE_SERVICE_LEVEL = "DUPLICATE_WAREHOUSE_SERVICE_LEVEL",
  INVALID_FEED_ID = "INVALID_FEED_ID",
  INVALID_URL = "INVALID_URL",
  INVALID_ZIP = "INVALID_ZIP",
  MISSING_MERCHANT_ID = "MISSING_MERCHANT_ID",
  NO_SELLER_FAST_TAGS = "NO_SELLER_FAST_TAGS",
  NOT_AN_ADMIN = "NOT_AN_ADMIN",
  NOT_FOUND_IN_MARKETPLACE = "NOT_FOUND_IN_MARKETPLACE",
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  REFRESH_ALREADY_IN_PROGRESS = "REFRESH_ALREADY_IN_PROGRESS",
  SELLER_NOT_FOUND = "SELLER_NOT_FOUND",
  TOO_MANY_WAREHOUSE_COMBINATIONS = "TOO_MANY_WAREHOUSE_COMBINATIONS",
  TOO_MANY_WAREHOUSE_SERVICE_LEVELS = "TOO_MANY_WAREHOUSE_SERVICE_LEVELS",
  UNAUTHORIZED = "UNAUTHORIZED",
  UNKNOWN_GOOGLE_API_CLIENT_ERROR = "UNKNOWN_GOOGLE_API_CLIENT_ERROR",
}
