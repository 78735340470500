import {
  EntityError,
  EntityName,
  ShippingEstimationResponse,
  ShippingPartner,
  ShippingType,
} from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { keyBy, sumBy } from "lodash";
import React from "react";
import { Estimation } from "./Estimation";
import { FormattedMessage } from "react-intl";
import { EstimateMap } from "./useReplenishmentOrderShippingStep";

export const getShippingEstimation = async (
  internalShippingMethod: ShippingPartner,
  shippingType: ShippingType,
  orderId: string,
  setShippingEstimation: React.Dispatch<React.SetStateAction<EstimateMap>>
) => {
  try {
    setShippingEstimation((state) => {
      return setShippingEstimationState(state, internalShippingMethod, shippingType, {
        isPending: true,
        hasError: false,
        disabled: true,
      });
    });
    const shippingEstimations: ShippingEstimationResponse[] = (
      await replenishmentClient.getShippingEstimates(orderId!, internalShippingMethod, shippingType)
    ).value.shippingEstimates;

    setShippingEstimation((state) => {
      return setShippingEstimationState(state, internalShippingMethod, shippingType, {
        hasError: false,
        isPending: false,
        disabled: false,
        estimation: sumBy(
          shippingEstimations,
          (shippingEstimation) =>
            shippingEstimation.totalEstimatedCost ?? (shippingEstimation as any).transportationCost ?? 0
        ),
        errorMessage: undefined,
        shipmentEstimationBreakdown: keyBy(shippingEstimations, (shippingEstimation) => shippingEstimation.shipmentId),
      });
    });
  } catch (err) {
    setShippingEstimation((state) => {
      return setShippingEstimationState(state, internalShippingMethod, shippingType, {
        hasError: true,
        isPending: false,
        disabled: true,
        errorMessage: (
          <FormattedMessage
            id={"BarcodePage.valueColHead"}
            defaultMessage="{msg}"
            values={{
              msg: err?.response?.data?.error?.message ?? "Unknown error",
            }}
          />
        ),
        shipmentErrorMessageBreakdown: getErrorMessageByshipmentId(err?.response?.data?.error),
      });
    });
  }
};

const getErrorMessageByshipmentId = (error: EntityError) => {
  let shipmentErrorMessageBreakdown: { [shipmentId: string]: React.ReactNode } = {};
  if (error?.entity === EntityName.SHIPMENT && error?.entityId) {
    shipmentErrorMessageBreakdown[error.entityId] = error.message;
  }
  error?.errors?.forEach((entityError) => {
    const shipmentToErrorMessage = getErrorMessageByshipmentId(entityError);
    shipmentErrorMessageBreakdown = {
      ...shipmentErrorMessageBreakdown,
      ...shipmentToErrorMessage,
    };
  });
  return shipmentErrorMessageBreakdown;
};

export const setShippingEstimationState = (
  state: EstimateMap,
  shippingMethod: ShippingPartner,
  shippingType: ShippingType,
  newEstimate: Partial<Estimation>
): EstimateMap => {
  return {
    ...state,
    [shippingMethod]: {
      ...state[shippingMethod],
      [shippingType]: {
        ...state[shippingMethod]?.[shippingType],
        ...newEstimate,
      } as Estimation,
    },
  };
};
