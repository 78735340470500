import { FastTagType } from "common/clients/fastTag/FastTagType";
import { createSelector } from "reselect";
import { fastTagFlows } from "tags/FastTagsSelectors";
import { FAST_TAG_EDIT } from "tags/model/FastTagOperation";
import { FacebookEditFlowState } from "./FacebookEditFlowState";

export const facebookEditFlow = createSelector(
  fastTagFlows,
  (flows) => flows[FastTagType.FACEBOOK][FAST_TAG_EDIT] as FacebookEditFlowState
);
