import * as React from "react";
import { icon } from "../../common";

export default icon("0 0 24 24")(
  <>
    <path d="M14.0768 1.62615C14.0768 1.1142 13.6612 0.714233 13.1656 0.714233H8.99324C8.48168 0.714233 8.08203 1.13019 8.08203 1.62615V2.98602H14.0768V1.62615Z" />
    <path d="M11.1029 6.90572C11.3107 6.90572 11.5186 6.93772 11.7264 6.98571L17.1297 8.26559V5.76983C17.1297 5.25787 16.714 4.85791 16.2185 4.85791H5.98738C5.47582 4.85791 5.07617 5.27387 5.07617 5.76983V8.2336L10.4955 6.98571C10.7033 6.93772 10.9111 6.92172 11.1029 6.92172V6.90572Z" />
    <path d="M19.4332 16.1848L21.1278 12.1852C21.2237 11.9292 21.2237 11.6573 21.0958 11.4173C20.9679 11.1773 20.7601 11.0013 20.4883 10.9373L12.0317 8.93751V15.6409C12.0317 16.1528 11.616 16.5528 11.1205 16.5528C10.6249 16.5528 10.2093 16.1368 10.2093 15.6409V8.92151L1.78459 10.8573C1.52881 10.9213 1.30501 11.0813 1.17712 11.3213C1.04923 11.5613 1.03324 11.8332 1.14515 12.0892L2.77573 16.1848C2.87164 16.4248 3.06348 16.6168 3.30327 16.6968C3.46313 16.7608 5.909 17.6567 10.3851 17.6567C12.6711 17.6567 15.5007 17.4167 18.7938 16.7128C19.0815 16.6488 19.3213 16.4568 19.4492 16.1688L19.4332 16.1848Z" />
    <path d="M12.3186 20.18C12.0788 20.244 11.839 20.324 11.5992 20.388C11.0557 20.516 10.4802 20.5 9.87273 20.34L9.45709 20.228C8.7537 20.0201 7.9544 19.7801 7.05918 19.9241C6.67551 19.9881 6.29184 20.1001 5.92417 20.212C5.92417 20.212 5.90818 20.212 5.89219 20.212C5.49254 20.34 5.10887 20.452 4.75718 20.452C3.94189 20.484 3.14259 20.212 2.03955 19.6681C1.59194 19.4441 1.03242 19.6201 0.808617 20.0841C0.584812 20.532 0.776645 21.092 1.22426 21.3159C2.07152 21.7319 3.33442 22.2919 4.74119 22.2919H4.77317C5.39662 22.2919 5.97212 22.1159 6.46769 21.9559C6.75544 21.8599 7.04319 21.7799 7.34693 21.7319C7.8425 21.6519 8.37004 21.8119 8.92955 21.9879L9.39315 22.1159C9.9047 22.2599 10.3843 22.3239 10.8639 22.3239C11.2475 22.3239 11.6312 22.2759 12.0149 22.1799C12.3026 22.1159 12.5744 22.0359 12.8461 21.9399C13.1978 21.8279 13.5655 21.7159 13.9332 21.6679C14.4288 21.6039 14.9723 21.7959 15.5638 21.9879C17.6739 22.7238 19.5763 21.9399 20.8072 21.2999C21.2548 21.06 21.4307 20.516 21.1909 20.0681C20.9511 19.6201 20.4076 19.4441 19.9599 19.6841C18.4093 20.484 17.2743 20.66 16.1393 20.26C15.9954 20.212 15.8196 20.1641 15.6597 20.1161C15.0682 19.9241 14.4128 19.7481 13.6934 19.8601C13.2298 19.9241 12.7662 20.0521 12.3186 20.196V20.18Z" />
  </>
);
