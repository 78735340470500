import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";
import { useFetchFlexportUserQuery$data } from "../relay/__generated__/useFetchFlexportUserQuery.graphql";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Subscription } from "relay-runtime";
import { logError } from "../../Logger";
import { useSPSelector } from "../ReduxUtils";
import { getFlexportUser } from "./FlexportUserSelectors";
import { getIsLinkedToFlexport } from "common/user/UserSelectors";
import { useIsFlexportCompanyExisting } from "./useIsFlexportCompanyExisting";
import { useIsUserConnectedWithFlexport } from "./useIsUserConnectedWithFlexport";

const flexportUserQuery = graphql`
  query useFetchFlexportUserQuery {
    current_user {
      dbid
      name
      first_name
      last_name
      email
      admin
      company {
        client {
          fid
        }
        primary_entity {
          dbid
          display_smb_onboarding
          completed_smb_company_details_onboarding
          submitted_smb_company_details_onboarding
          completed_smb_authorization_onboarding
          submitted_smb_authorization_onboarding
        }
      }
      impersonatedUser {
        fid
        name
        email
        company {
          client {
            fid
          }
          primary_entity {
            dbid
            display_smb_onboarding
            completed_smb_company_details_onboarding
            submitted_smb_company_details_onboarding
            completed_smb_authorization_onboarding
            submitted_smb_authorization_onboarding
          }
        }
      }
    }
  }
`;

export interface FlexportUser {
  readonly dbid: number | undefined;
  readonly name: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly admin: boolean | null;
  readonly companyEntityId: number | undefined;
  readonly clientFid: string | undefined;
  readonly displaySmbOnboarding: boolean | null;
  readonly completedSmbCompanyDetailsOnboarding: boolean | null;
  readonly submittedSmbCompanyDetailsOnboarding: boolean | null;
  readonly completedSmbAuthorizationOnboarding: boolean | null;
  readonly submittedSmbAuthorizationOnboarding: boolean | null;
  readonly isFlexportCompanyExisting?: boolean | null;
  readonly isUserConnectedWithFlexport?: boolean | null;
}

const flattenFlexportUser = (flexportUserQueryResult: useFetchFlexportUserQuery$data): FlexportUser => {
  const user = flexportUserQueryResult.current_user;
  const impersonatedUser = flexportUserQueryResult.current_user.impersonatedUser;
  const company = impersonatedUser ? impersonatedUser.company : user.company;

  return {
    dbid: user.dbid,
    name: user.name,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    admin: user.admin,

    // Read impersonated data if it exists
    companyEntityId: company.primary_entity.dbid,
    clientFid: company.client?.fid,
    displaySmbOnboarding: company.primary_entity.display_smb_onboarding,
    completedSmbCompanyDetailsOnboarding: company.primary_entity.completed_smb_company_details_onboarding,
    submittedSmbCompanyDetailsOnboarding: company.primary_entity.submitted_smb_company_details_onboarding,
    completedSmbAuthorizationOnboarding: company.primary_entity.completed_smb_authorization_onboarding,
    submittedSmbAuthorizationOnboarding: company.primary_entity.submitted_smb_authorization_onboarding,
  };
};

export const useFetchFlexportUser = (): FlexportUser => {
  const [subscription, setSubscription] = useState<Subscription | undefined>();
  const dispatch = useDispatch();
  const environment = useRelayEnvironment();

  const isLinkedToFlexport = useSelector(getIsLinkedToFlexport);
  const flexportUser = useSPSelector(getFlexportUser);

  const { isFlexportCompanyExisting } = useIsFlexportCompanyExisting();
  const { isUserConnectedWithFlexport } = useIsUserConnectedWithFlexport();

  useEffect(() => {
    if (
      (!flexportUser.dbid || !flexportUser.name || !flexportUser.email || !flexportUser.companyEntityId) &&
      !subscription &&
      isLinkedToFlexport
    ) {
      setSubscription(
        fetchQuery(environment, flexportUserQuery, {}).subscribe({
          next: (data: useFetchFlexportUserQuery$data) => {
            dispatch({
              type: "SET_FLEXPORT_USER",
              ...flattenFlexportUser(data),
            });
          },
          error: (error) => {
            logError({ fn: "useFetchFlexportUser" }, error, "Error fetching flexportUser");
          },
        })
      );
    }
    return () => {
      subscription?.unsubscribe();
      setSubscription(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flexportUser, isLinkedToFlexport]);

  return {
    dbid: flexportUser.dbid,
    name: flexportUser.name,
    firstName: flexportUser.firstName,
    lastName: flexportUser.lastName,
    email: flexportUser.email,
    admin: flexportUser.admin,
    companyEntityId: flexportUser.companyEntityId,
    clientFid: flexportUser.clientFid,
    displaySmbOnboarding: flexportUser.displaySmbOnboarding,
    completedSmbCompanyDetailsOnboarding: flexportUser.completedSmbCompanyDetailsOnboarding,
    submittedSmbCompanyDetailsOnboarding: flexportUser.submittedSmbCompanyDetailsOnboarding,
    completedSmbAuthorizationOnboarding: flexportUser.completedSmbAuthorizationOnboarding,
    submittedSmbAuthorizationOnboarding: flexportUser.submittedSmbAuthorizationOnboarding,
    isFlexportCompanyExisting: isFlexportCompanyExisting ?? flexportUser.isFlexportCompanyExisting ?? undefined,
    isUserConnectedWithFlexport: isUserConnectedWithFlexport ?? flexportUser.isUserConnectedWithFlexport ?? undefined,
  };
};
