import { AxiosRequestConfig } from "axios";
import produce from "immer";
import { Authentication } from "./authentication";
import { getBearerToken } from "./getBearerToken";

export const withAuthentication = ({ authentication }: Partial<Authentication>) => async (
  config: AxiosRequestConfig
) => {
  if (authentication) {
    const bearerToken = await getBearerToken();

    return produce(config, (draft) => {
      if (draft.headers === undefined) {
        draft.headers = {};
      }
      draft.headers.Authorization = `Bearer ${bearerToken}`;
    });
  }

  return config;
};
