import { createSelector } from "reselect";
import { selectPlanProductDetails } from "../productDetailsCache";
import { getProductIsLotTrackingEnabled } from "common/utils/product/getProductIsLotTrackingEnabled";
import { fetchKitComponents } from "inbounds/store/util/fetchKitComponents";

export const selectPlanItemsWithLotTrackingEnabled = createSelector(selectPlanProductDetails, (planItems) =>
  planItems.filter(getProductIsLotTrackingEnabled)
);

export const selectKittedComponentItemsWithLotTrackingEnabled = createSelector(
  selectPlanItemsWithLotTrackingEnabled,
  fetchKitComponents
);
