import { Product } from "@deliverr/commons-clients";
import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";
import { useFieldArray } from "react-hook-form";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { useStorageInboundProductChooser } from "../StorageInboundProductChooser/useStorageInboundProductChooser";
import { SingleSkuPackageCollection } from "inbounds/createShipment/CreateShipmentTypes";
import { omit } from "lodash";

export const useIntlDtcInboundProductChooser = (
  isDtcMixedSkuBooking?: boolean,
  index?: number,
  singleSkuPackages?: SingleSkuPackageCollection
) => {
  const connectedProps = useStorageInboundProductChooser();

  const { control, setValue, unregister, register } = useCreateShipmentFormContext();
  const {
    fields: mixedSkuPackage,
    append: appendMixedSkuPackage,
    remove: removeMixedSkuPackage,
  } = useFieldArray({
    control,
    name: `${CreateShipmentInputName.MIXED_SKU_PACKAGES}.${index ?? 0}.items`,
  });
  const addProduct = (dsku: string, product: Product) => {
    if (isDtcMixedSkuBooking) {
      appendMixedSkuPackage({
        dsku,
      });
    } else {
      register(`${CreateShipmentInputName.SINGLE_SKU_PACKAGES}.${dsku}`);
      setValue(`${CreateShipmentInputName.SINGLE_SKU_PACKAGES}.${dsku}`, [
        {
          casePackDsku: "",
          numberOfPkgs: 0,
          items: [
            {
              dsku,
              qty: 0,
            },
          ],
        },
      ]);
    }
    return connectedProps.addProduct(dsku, product);
  };

  const removeProduct = (dsku: string, itemIndex?: number) => {
    if (isDtcMixedSkuBooking) {
      removeMixedSkuPackage(itemIndex);
    } else {
      unregister(`${CreateShipmentInputName.SINGLE_SKU_PACKAGES}.${dsku}`);
      setValue(CreateShipmentInputName.SINGLE_SKU_PACKAGES, omit(singleSkuPackages, dsku));
    }
  };

  const onCreateProductSuccess = async (product: Product) => {
    await addProduct(product.dsku, product);
    window.open(`/inventory/${product.dsku}`, "_blank");
  };

  return {
    ...connectedProps,
    addProduct,
    removeProduct,
    onCreateProductSuccess,
    mixedSkuPackage,
    shouldShowFixCasePackWarning: connectedProps.shouldShowFixCasePackWarning && !isDtcMixedSkuBooking,
    showCasePackSelectionDropdown: connectedProps.showCasePackSelectionDropdown && !isDtcMixedSkuBooking,
  };
};
