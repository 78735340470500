import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ManagedStep } from "common/components/StepContainer";
import { Box } from "common/components/ui";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import { SummaryErrorNotification } from "common/wholesale-common/components/SummaryErrorNotification";
import { wholesaleCreateLabels } from "../wholesaleCreate.labels";
import { useAutoRefreshWholesaleSummary } from "./useAutoRefreshWholesaleSummary";
import { WholesaleShipmentType, WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { TransferConfirmPricingSummary } from "../../../transfers/create/steps/common/TransferConfirmPricingSummary";
import { useWholesalePricingSummary } from "./useWholesalePricingSummary";
import { useWholesaleEstimationsStatus } from "./useWholesaleEstimationsStatus";
import { SharedConfirmShipmentSummary } from "../../../common/wholesale-common/components/SharedConfirmShipmentSummary";

const labels = wholesaleCreateLabels.steps;

export const ConfirmStep = () => {
  const { formatMessage } = useIntl();
  const {
    numOfSkus,
    numOfCases,
    numOfUnits,
    originStorageWarehouse,
    toAddress,
    wholesaleOrder,
    shippingMethod,
    cargoType,
    isEstimating,
    hasFailedEstimating,
    hasPendingEstimations,
    shipBy,
  } = useAutoRefreshWholesaleSummary();

  const [isConfirmClicked, setIsConfirmClicked] = useState(false);

  const { hasFailedEstimation } = useWholesaleEstimationsStatus();

  const costTableProps = useWholesalePricingSummary(wholesaleOrder!);

  const fromAddress = originStorageWarehouse?.warehouse.address;

  const shippingPartner =
    shippingMethod === WholesaleShippingMethod.DELIVERR
      ? formatMessage(labels.confirm.shippingDeliverr)
      : formatMessage(labels.confirm.shippingSeller);

  const shippingLabel = hasFailedEstimating
    ? shippingPartner
    : formatMessage(labels.confirm.shippingMethod, {
        cargo:
          cargoType === WholesaleShipmentType.PARTIAL_PALLET
            ? formatMessage(labels.confirm.shippingPallet)
            : formatMessage(labels.confirm.shippingParcel),
        partner: shippingPartner,
      });

  return (
    <ManagedStep
      title={formatMessage(labels.confirm.title)}
      nextCaption={formatMessage(labels.confirm.nextButtonCaption)}
      isNextDisabled={isConfirmClicked}
      isLoading={isConfirmClicked}
      nextData={async () => setIsConfirmClicked(true)}
    >
      <SharedConfirmShipmentSummary
        shipBy={shipBy}
        numOfSkus={numOfSkus}
        numOfCases={numOfCases}
        numOfUnits={numOfUnits}
        fromAddress={
          <>
            <FormattedMessage {...labels.confirm.reserveStorage} />
            {fromAddress && (
              <>
                {fromAddress.name && (
                  <>
                    <br />
                    {fromAddress.name}
                  </>
                )}
                <br />
                {fromAddress.street1}
                {fromAddress.street2 && (
                  <>
                    <br />
                    {fromAddress.street2}
                  </>
                )}
                <br />
                {fromAddress.city}, {fromAddress.state} {fromAddress.zip}
              </>
            )}
          </>
        }
        toAddress={
          <>
            {toAddress?.name}
            <br />
            {toAddress?.street1}
            <br />
            {toAddress?.city}, {toAddress?.state} {toAddress?.zip}
          </>
        }
        shipping={shippingLabel}
      />

      <Box width="80%">
        {hasFailedEstimation ? (
          <Box marginBottom="S2" marginTop="S7">
            <SummaryErrorNotification orderId={wholesaleOrder?.id} />
          </Box>
        ) : (
          <>
            {isEstimating ? (
              <Box marginBottom="S2" marginTop="S7">
                <LoadingSpinner center size="4x" />
              </Box>
            ) : (
              !hasPendingEstimations && <TransferConfirmPricingSummary {...costTableProps} />
            )}
          </>
        )}
      </Box>
    </ManagedStep>
  );
};
