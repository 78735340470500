import { PriceItem } from "@deliverr/billing-pricer-client";

export const verifyProductProps = (dimensions: PriceItem[]) =>
  dimensions.reduce((acc: PriceItem[] | undefined, dimension: PriceItem) => {
    const { dsku, length, width, height, weight, hazmatIds, minimumPackagingType, qty = 1 } = dimension;
    if (!length || !width || !weight || !height) {
      acc = undefined;
    }
    const item: PriceItem = { dsku, length, width, height, weight, hazmatIds, minimumPackagingType, qty };
    acc?.push(item);
    return acc;
  }, []);
