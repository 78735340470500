import { BarcodeInputStep } from "inbounds/steps/barcode/BarcodeInputStep";
import React, { FC } from "react";
import { useStorageAddProductBarcode } from "./useStorageAddProductBarcode";

export const StorageAddProductBarcode: FC = () => {
  const { goToNextStep, goToPreviousStep, productDetailsCache } = useStorageAddProductBarcode();
  return (
    <BarcodeInputStep
      productDetailsCache={productDetailsCache}
      goToNextStep={goToNextStep}
      goToPreviousStep={goToPreviousStep}
    />
  );
};
