import { ThemeProps, Card, Text, DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";

interface PrepStepTitleProps {
  removeTopMargin?: boolean;
}

export const PrepStepContainer = styled.div`
  height: 700px;
`;

export const PrepStepCard = styled(Card)<ThemeProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PrepStepTitle = styled.h1<PrepStepTitleProps, DefaultTheme>(
  ({ removeTopMargin, theme }) => `
    font-family: Montserrat, sans-serif;
    font-weight: ${theme.font.weight.BOLD};
    font-size: ${theme.font.size.F4};
    margin-top: ${removeTopMargin ? 0 : theme.spacing.S7};
    margin-bottom: ${theme.spacing.S4};
  `
);

export const PrepStepDescription = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S5};
    text-align: center;
    max-width: 520px;
  `
);

export const PrepStepContent = styled.div<ThemeProps>(
  ({ theme }) => `
    width: 760px;
    margin-bottom: ${theme.spacing.S7};
  `
);
