import { useState } from "react";
import { FlowStep } from "./AccordionFlowTypes";
import { StepCompletionMap, useAccordionStepValidator } from "./useAccordionStepValidator";

export interface AccordionFlowProps {
  steps: FlowStep[];
  stepCompletionMap: StepCompletionMap;
}

export function useAccordionFlow({ steps, stepCompletionMap }: AccordionFlowProps) {
  const [openSteps, setOpenSteps] = useState<Record<string, boolean>>({});

  const stepsValidation = useAccordionStepValidator({ steps, stepCompletionMap });

  const firstInvalidIndex: number = Object.values(stepsValidation).findIndex((step) => !step?.isValid);

  const toggleOpen = (i) => {
    setOpenSteps({ ...openSteps, [i]: !openSteps[i] });
  };

  return {
    stepsValidation,
    firstInvalidIndex,
    openSteps,
    toggleOpen,
  };
}
