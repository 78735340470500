import { isNil } from "lodash/fp";
import React, { FC } from "react";
import { SelectComponentsConfig } from "@deliverr/react-select-v1";
import { NewProductModal } from "../NewProductModal";
import { SelectedProductData } from "../ProductChooser";
import {
  ProductSearchLoading,
  ProductSearchMenuList,
  ProductSearchNoOptions,
  ProductSearchOption,
} from "./select-components";
import { useProductSearch } from "./useProductSearch";

import styled from "@emotion/styled";
import { ThemeProps, SelectAsync, LocalizedMessage } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import { CreateProductSuccessHandler } from "../NewProductForm/useNewProductForm";

const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;

  label {
    flex: 1;
    margin-bottom: 0;
  }
`;

const StyledSelectAsync = styled(SelectAsync)`
  label {
    margin-bottom: 0;
  }
`;

const StyledOr = styled.span<ThemeProps>(
  ({ theme }) => `
    display: block;
    margin-left: ${theme.spacing.S5};
    margin-right: ${theme.spacing.S5};
    margin-bottom: 0;
    margin-top: 0;
    color: ${theme.colors.NEUTRAL[300]};
`
);

export interface ProductSearchProps {
  allowZeroInventorySelection?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  excludedDsku?: string;
  hideHiddenSKUs?: boolean;
  bulkUploadButton?: React.ReactElement;
  id?: string;
  inputId?: string;
  isDimsRequired?: boolean;
  isBarcodeRequired?: boolean;
  onSelectProduct: (inventoryListItem: SelectedProductData) => void;
  placeholder?: LocalizedMessage;
  selectedProducts?: SelectedProductData[];
  showAvailability?: boolean;
  shouldSortDropdownList?: boolean;
  shouldHideCreateProduct?: boolean;
  isReserveStorage?: boolean;
  shouldIncludeBrandedPackaging?: boolean;
  withWalmartChannelIdOnly?: boolean;
  applyFbaConditions?: boolean;
  isIntegratedFBA?: boolean;
  showOnlyPacks?: boolean;
  isOrder?: boolean;
  onCreateProductSuccess?: CreateProductSuccessHandler;
  storageMaximumSelectableCaseQty?: number;
  excludedPackOfSkusForStorage?: string[];
  customDisabledProductSkus?: string[];
  shouldShowKitHint?: boolean;
}

const customComponents: SelectComponentsConfig<any> = {
  LoadingMessage: ProductSearchLoading,
  LoadingIndicator: null,
  MenuList: ProductSearchMenuList,
  NoOptionsMessage: ProductSearchNoOptions,
  Option: ProductSearchOption,
};

export const ProductSearch: FC<ProductSearchProps> = ({
  autoFocus = false,
  disabled = false,
  excludedDsku,
  showAvailability = true,
  allowZeroInventorySelection = false,
  id = "product_search_bar",
  isDimsRequired = false,
  hideHiddenSKUs = false,
  isBarcodeRequired = false,
  selectedProducts,
  onSelectProduct,
  placeholder,
  inputId,
  bulkUploadButton,
  shouldSortDropdownList = false,
  shouldHideCreateProduct,
  isReserveStorage,
  shouldIncludeBrandedPackaging = false,
  showOnlyPacks = false,
  withWalmartChannelIdOnly,
  applyFbaConditions,
  isIntegratedFBA,
  isOrder,
  onCreateProductSuccess,
  storageMaximumSelectableCaseQty,
  excludedPackOfSkusForStorage,
  customDisabledProductSkus,
  shouldShowKitHint,
}) => {
  const {
    handleChange,
    handleInputChange,
    isMenuOpen,
    loadOptions,
    newProductSearchTerm,
    isNewProductModalOpen,
    openNewProductModal,
    closeNewProductModal,
    getIsOptionDisabled,
  } = useProductSearch({
    selectedProducts,
    allowZeroInventorySelection,
    isDimsRequired,
    hideHiddenSKUs,
    onSelectProduct,
    isBarcodeRequired,
    excludedDsku,
    isReserveStorage,
    shouldSortDropdownList,
    shouldIncludeBrandedPackaging,
    showOnlyPacks,
    withWalmartChannelIdOnly,
    applyFbaConditions,
    isIntegratedFBA,
    isOrder,
    storageMaximumSelectableCaseQty,
    excludedPackOfSkusForStorage,
    customDisabledProductSkus,
    shouldShowKitHint,
  });

  return (
    <>
      <NewProductModal
        defaultValues={{
          name: newProductSearchTerm,
          sku: newProductSearchTerm,
        }}
        isOpen={isNewProductModalOpen}
        onClose={closeNewProductModal}
        onSuccess={onCreateProductSuccess}
      />
      <SearchBarWrapper>
        <StyledSelectAsync
          autoFocus={autoFocus}
          captureMenuScroll={false}
          backspaceRemovesValue={false}
          classNamePrefix="product_search" // For cypress tests
          customComponents={customComponents}
          isDisabled={disabled}
          id={id}
          inputId={inputId}
          isClearable
          isOptionDisabled={getIsOptionDisabled}
          onInputChange={handleInputChange}
          loadOptions={loadOptions}
          maxMenuHeight={240}
          menuIsOpen={isMenuOpen}
          onChange={handleChange}
          shouldHideCreateProduct={shouldHideCreateProduct}
          onSelectCreateProduct={openNewProductModal}
          placeholder={
            placeholder ?? <FormattedMessage id="productSearch.placeholder" defaultMessage="Search and add products" />
          }
          showAvailability={showAvailability}
          value={null} // Prevents value from being set internally, so input is cleared when an option is selected
          isReserveStorage={isReserveStorage}
          showOnlyPacks={showOnlyPacks}
          shouldShowKitHint={shouldShowKitHint}
        />
        {!isNil(bulkUploadButton) && (
          <>
            <StyledOr>
              <FormattedMessage id="or" defaultMessage="or" />
            </StyledOr>
            {bulkUploadButton}
          </>
        )}
      </SearchBarWrapper>
    </>
  );
};
