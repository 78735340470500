/* eslint-disable @typescript-eslint/promise-function-async */
import React, { useState, useEffect } from "react";
import { ThemeProps, Text, Icon, defaultTheme, Box } from "common/components/ui";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { SpacedText } from "../cards/confirmation/sharedComponents";
import { useSelector } from "react-redux";
import { getSellerId, getLogisticsAccountId } from "common/user/UserSelectors";
import { organizationClient } from "common/clients/instances";
import { logError } from "Logger";

const InstructionBox = styled.div<ThemeProps>(
  ({ theme }) => `
    background: ${theme.colors.NEUTRAL[20]};
    border: ${theme.border.width.B1} solid ${theme.colors.NEUTRAL[80]};
    padding: ${theme.spacing.S5} ${theme.spacing.S4};
  `
);

const StyledLink = styled.a<ThemeProps>(
  ({ theme }) => `
    cursor: pointer;
    font-weight: ${theme.font.weight.BOLD};
  `
);

const IconWithText = styled.div<ThemeProps>(
  ({ theme }) => `
    display: flex;
    gap: ${theme.spacing.S2};
    align-items: center;
  `
);

export const FlexportAutomatedTrackingInstructions = () => {
  const [isFlexportAccountConnected, setIsFlexportAccountConnected] = useState<boolean>(false);
  const [isFirstConnection, setIsFirstConnection] = useState<boolean>(true);
  const [authorizationLink, setauthorizationLink] = useState<string>("");
  const sellerId = useSelector(getSellerId);
  const logisticsAccountId = useSelector(getLogisticsAccountId);

  useEffect(() => {
    const getFlexportConnectionStatus = async () => {
      const flexportAccountData = await organizationClient.getIsFlexportAccountConnected(sellerId, logisticsAccountId);

      if (flexportAccountData.connected) {
        setIsFlexportAccountConnected(flexportAccountData.connected);
      }

      if (flexportAccountData.authorizationUrl) {
        setauthorizationLink(flexportAccountData.authorizationUrl);
      }

      if (flexportAccountData.firstConnection !== null) {
        setIsFirstConnection(flexportAccountData.firstConnection);
      }
    };

    getFlexportConnectionStatus().catch((e) => {
      logError({ fn: "getFlexportConnectionStatus" }, e);
    });
  }, [sellerId, logisticsAccountId]);

  const authenticateMarkup = (
    <>
      <Box marginBottom="S4">
        <FormattedMessage
          id="FlexportAutomatedInstructionCard.pleaseConnect"
          defaultMessage="Please connect Shopify to your Flexport account in order to receive tracking updates automatically."
        />
      </Box>

      <StyledLink href={authorizationLink} data-testid="view-authentication-link">
        <FormattedMessage
          id="FlexportAutomatedInstructionCard.viewInstructions"
          defaultMessage="Connect Flexport account"
        />
      </StyledLink>
    </>
  );

  const reAuthenticateMarkup = (
    <>
      <Box marginBottom="S4">
        <FormattedMessage
          id="FlexportAutomatedInstructionCard.pleaseConnect"
          defaultMessage="Please re-connect Shopify to your Flexport account in order to receive tracking updates automatically."
        />
      </Box>

      <StyledLink href={authorizationLink} data-testid="view-reauthentication-link">
        <FormattedMessage
          id="FlexportAutomatedInstructionCard.viewInstructions"
          defaultMessage="Re-connect Flexport account"
        />
      </StyledLink>
    </>
  );

  const connectedMarkup = (
    <>
      <IconWithText>
        <Icon color={defaultTheme.colors.GREEN[300]} type="check-circle" size="2x" />
        <Text>
          <FormattedMessage
            id="FlexportAutomatedInstructionCard.isConnected"
            defaultMessage="We have connected to your Flexport account and will receive tracking updates automatically. "
          />
        </Text>
      </IconWithText>
    </>
  );

  const notConnectedMarkup = () => {
    if (isFirstConnection) {
      return authenticateMarkup;
    }
    return reAuthenticateMarkup;
  };

  return (
    <InstructionBox>
      <SpacedText bold>
        <FormattedMessage id="FlexportAutomatedInstructionCard.title" defaultMessage="AUTOMATED TRACKING" />
      </SpacedText>
      {isFlexportAccountConnected ? connectedMarkup : notConnectedMarkup()}
    </InstructionBox>
  );
};
