import { Button } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { transferHeaderLabels } from "./transferHeaderLabels";

interface DuplicateOrderButtonProps {
  onDuplicate: () => void;
}

export const DuplicateOrderButton: FC<DuplicateOrderButtonProps> = ({ onDuplicate }) => {
  return (
    <Button onClick={onDuplicate} data-testid="duplicate-order-button" secondary>
      <FormattedMessage {...transferHeaderLabels.duplicateOrder} />
    </Button>
  );
};
