import React from "react";
import { FormattedMessage } from "react-intl";

export enum FreightBookingActionError {
  REQUEST_FREIGHT_QUOTE_ERROR = "REQUEST_FREIGHT_QUOTE_ERROR",
  REQUEST_FREIGHT_BOOKING_ERROR = "REQUEST_FREIGHT_BOOKING_ERROR",
  REQUEST_FREIGHT_QUOTE_REQUEST_ERROR = "REQUEST_FREIGHT_QUOTE_REQUEST_ERROR",
}

export const FreightBookingActionErrors: Record<FreightBookingActionError, JSX.Element> = {
  [FreightBookingActionError.REQUEST_FREIGHT_QUOTE_ERROR]: (
    <FormattedMessage
      id={"freight.errorToast.requestQuoteError"}
      defaultMessage="There was an issue fetching your quotes"
    />
  ),
  [FreightBookingActionError.REQUEST_FREIGHT_BOOKING_ERROR]: (
    <FormattedMessage
      id={"freight.errorToast.requestBookingError"}
      defaultMessage="There was an issue fetching your bookings"
    />
  ),
  [FreightBookingActionError.REQUEST_FREIGHT_QUOTE_REQUEST_ERROR]: (
    <FormattedMessage
      id={"freight.errorToast.requestQuoteRequestError"}
      defaultMessage="There was an issue fetching your quote"
    />
  ),
};
