import React, { FC } from "react";
import { useAsyncFn } from "react-use";
import { Box, Text, Anchor } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";

const CreateDisputeLink = styled(Anchor)`
  cursor: pointer;
`;

interface DamagedProductBladeFooterProps {
  onDisputeClick: () => Promise<any>;
  hasDisputes: boolean;
  isExpired: boolean;
}

export const DamagedProductBladeFooter: FC<DamagedProductBladeFooterProps> = ({
  onDisputeClick,
  hasDisputes,
  isExpired,
}) => {
  const [{ loading: isLoading }, onClick] = useAsyncFn(onDisputeClick, [onDisputeClick]);

  return (
    <Box paddingTop="S3" paddingBottom="S3">
      <Text>
        {isLoading ? (
          <FormattedMessage id="damagedProductBlade.footer.loadingMessage" defaultMessage="Submitting a ticket..." />
        ) : hasDisputes || isExpired ? (
          <FormattedMessage
            id="damagedProductBlade.footer.submitTicketMessage"
            defaultMessage="Need more information? {submitTicketLink}"
            values={{
              submitTicketLink: (
                <CreateDisputeLink onClick={onClick}>
                  <FormattedMessage id="damagedProductBlade.footer.submitTicketLink" defaultMessage="Submit a ticket" />
                </CreateDisputeLink>
              ),
            }}
          />
        ) : (
          <FormattedMessage
            id="damagedProductBlade.footer.disputeResolutionMessage"
            defaultMessage="Did we get it wrong? {disputeResolutionLink}"
            values={{
              disputeResolutionLink: (
                <CreateDisputeLink onClick={onClick}>
                  <FormattedMessage
                    id="damagedProductBlade.footer.disputeResolutionLink"
                    defaultMessage="Dispute resolution"
                  />
                </CreateDisputeLink>
              ),
            }}
          />
        )}
      </Text>
    </Box>
  );
};
