import React from "react";
import { getRoundedLogo } from "common/RoundedLogos";

/* eslint sort-keys: "error" */

const logoWrapper = (children) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    {children}
  </svg>
);

// Rounded logos used by only integrations/fast-tags in sidebar.
const sidebarLogos: { [key: string]: JSX.Element } = {
  EBAY: (
    <g fill="none">
      <rect width="60" height="60" rx="10" fill="#3831D4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7698 30.03C39.7698 30.03 40.1799 33.56 36.1999 33.51C36.1999 33.51 33.9799 33.51 33.8499 31.77C33.7099 29.91 37.2598 30.03 37.2598 30.03H39.7698ZM31.6098 31.14C31.1598 34.02 33.7699 34.91 35.8198 34.9C37.8598 34.89 39.0298 34.29 39.8998 33.21L39.9898 34.55L42.0198 34.54C42.0198 34.54 41.9699 33.79 41.9699 32.31C41.9799 30.83 42.0199 29.51 41.9699 28.57C41.9099 27.49 42.0198 24.88 37.2598 24.69C37.2598 24.69 32.6999 24.28 32.2199 27.62H34.5498C34.5498 27.62 34.7299 26.09 37.0999 26.14C39.3399 26.18 39.7498 27.26 39.7398 28.59C39.7398 28.59 37.8198 28.59 37.1798 28.6C36.0298 28.6 31.9798 28.72 31.6098 31.14Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3601 26.1099C17.5001 26.1099 17.5001 28.8599 17.5001 28.8599H11.0502C11.0502 28.8499 11.2101 26.1099 14.3601 26.1099ZM14.2802 34.8599C19.2702 34.8599 19.5101 31.7799 19.5101 31.7799L17.2202 31.7899C17.2202 31.7899 16.7702 33.4799 14.2902 33.4399C11.1002 33.3799 10.9802 30.3499 10.9802 30.3499H19.8202C19.8202 30.3499 19.8302 30.2699 19.8302 30.1499V29.5299C19.7502 27.9599 19.0302 24.6899 14.4202 24.6799C8.94018 24.6799 8.72013 28.8699 8.75013 29.9599C8.75013 29.9499 8.48016 34.8599 14.2802 34.8599Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8495 26.1501C29.3995 26.0601 29.2794 29.8501 29.2794 29.8501C29.2794 29.8501 29.3395 33.3601 25.8495 33.4001C22.3695 33.4401 22.4194 29.7701 22.4194 29.7701C22.4194 29.7701 22.3095 26.2401 25.8495 26.1501ZM20.2394 30.1501L20.2494 32.3201C20.2494 32.3201 20.2394 33.6501 20.1494 34.5301H22.2994L22.3894 33.2401C22.3894 33.2401 23.3895 34.8601 26.3195 34.8601C29.2495 34.8601 31.5594 33.0901 31.6194 29.8101C31.6794 26.5301 29.1194 24.7001 26.3694 24.6801C23.6094 24.6601 22.4294 26.2801 22.4294 26.2801V20.8601H20.2195L20.2494 29.5201C20.2494 29.7901 20.2494 30.0001 20.2394 30.1501Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1494 28.47L20.1594 29.1C20.1894 28.96 20.1994 28.74 20.1494 28.47Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.8097 32.1099L43.0197 24.6699H40.4297L45.5797 34.2499L43.5897 37.9399H46.0997L52.9597 24.6699H50.6196L46.8097 32.1099Z"
        fill="white"
      />
    </g>
  ),
  FACEBOOK: (
    <g fill="none">
      <rect width="60" height="60" rx="10" fill="#305FDE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8699 44.6001H32.33V29.6001H36.4299L37.2399 24.9101H32.33V21.5101C32.33 20.4101 33.0399 19.2601 34.0499 19.2601H36.84V14.5701H33.4199V14.5901C28.0699 14.7801 26.9699 17.8801 26.8699 21.1401L26.8599 24.9001H24.1299V29.5901H26.8599V44.6001H26.8699Z"
        fill="white"
      />
    </g>
  ),
  GOOGLE: (
    <g fill="none">
      <rect width="60" height="60" rx="10" fill="#305FDE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.3102 14.66C48.3102 12.91 46.9302 11.53 45.1902 11.53H33.0702C31.8202 11.53 30.4502 12.03 29.5702 12.91L12.9502 29.82C11.2002 31.57 11.2002 34.2 12.9502 35.96L24.4502 47.48C25.8302 48.86 28.2002 48.86 29.7002 47.48L47.3202 29.82C48.0702 29.07 48.4402 28.19 48.4402 27.19L48.3102 14.66ZM43.3102 21.17C42.0602 22.42 40.1902 22.42 39.0602 21.17C37.9402 19.92 37.8102 18.04 39.0602 16.91C40.3102 15.78 42.1802 15.66 43.3102 16.91C44.4402 18.17 44.4402 20.04 43.3102 21.17ZM30.8102 22.17C27.1902 22.04 23.9302 25.18 23.8102 28.81C23.5602 31.69 25.5602 34.57 28.3102 35.7C31.0602 36.7 34.5602 35.95 36.3102 33.57C37.5602 32.07 37.6902 30.06 37.5602 28.18C35.3102 28.18 33.0602 28.18 30.8102 28.18C30.8102 28.93 30.8102 29.81 30.8102 30.56C31.5602 30.56 32.4302 30.56 33.3102 30.56C33.8102 30.56 34.3102 30.56 34.9302 30.56C34.3102 33.57 30.3102 34.44 28.1802 32.56C25.9302 30.81 26.0602 27.17 28.4302 25.55C30.0502 24.3 32.3102 24.55 33.9302 25.68C34.5502 25.05 35.1802 24.43 35.6802 23.93C34.3102 22.8 32.6902 22.17 30.8102 22.17Z"
        fill="white"
      />
    </g>
  ),
};

export const getSidebarLogo = (integrationName: string): JSX.Element => {
  const logo = sidebarLogos[integrationName];
  if (!logo) {
    return getRoundedLogo(integrationName);
  }

  return logoWrapper(logo);
};
