import { DefaultTheme, IconV2, IconV2Types, defaultTheme } from "common/components/ui";
import React, { FC } from "react";

import { RefundStatus } from "@deliverr/returns-client";
import styled from "@emotion/styled";

type IconConfigProps = Record<string, IconV2Types>;
const IconWrapper = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
  margin-right: ${theme.spacing.S2};
  padding-top: ${theme.spacing.S1};
`
);

const refundStatusIcon: IconConfigProps = {
  [RefundStatus.REFUNDED]: "check-circle",
  [RefundStatus.REFUND_NOT_INITIATED]: "info-circle",
  [RefundStatus.REFUND_INITIATED]: "check-circle",
  [RefundStatus.NOT_REFUNDED]: "exclamation-circle",
};

export const RefundStatusIcon: FC<{ refundStatus: RefundStatus }> = ({ refundStatus }) => {
  const type = refundStatusIcon[refundStatus];
  const isCheckIcon = type === "check-circle";
  const iconColor = isCheckIcon ? defaultTheme.colors.GREEN[200] : defaultTheme.colors.NEUTRAL[300];
  const iconSize = "lg";

  return (
    <IconWrapper>
      <IconV2 color={iconColor} type={type} size={iconSize} data-testid="refund-status-icon" />
    </IconWrapper>
  );
};
