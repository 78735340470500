import { useMemo, useState } from "react";
import { produce } from "immer";
import { sortBy } from "lodash/fp";

import {
  FlowStepMetadataMap,
  FlowStepMetadata,
  StepAccordionSpec,
  StepAccordionMetadata,
} from "common/components/AccordionFlow/AccordionFlowTypes";
import { getAccordionMetadataMap } from "inbounds/utils/getAccordionMetadataMap";

export interface UseStepMetadataSpec {
  stepAccordions: StepAccordionMetadata[];
  setStepIsValid: (step: { stepKey: string; isValid: boolean }) => void;
  openSteps: Record<string, boolean>;
  toggleOpen: (string) => void;
}

export const useStepMetadata = (steps: StepAccordionSpec[]): UseStepMetadataSpec => {
  const [accordionMetadataMap, setAccordionMetadataMap] = useState<FlowStepMetadataMap>(getAccordionMetadataMap(steps));
  const [openSteps, setOpenSteps] = useState<Record<string, boolean>>(() => {
    const initialStepState = {};
    if (steps[0]) {
      initialStepState[steps[0].id] = true;
    }
    return initialStepState;
  });

  const setStepIsValid = ({ stepKey, isValid }: { stepKey: string; isValid: boolean }) => {
    if (accordionMetadataMap[stepKey]) {
      setAccordionMetadataMap((prevMetadataMap) =>
        produce(prevMetadataMap, (draft) => {
          draft[stepKey].isValid = isValid;
        })
      );
    }
  };

  const toggleOpen = (key: string) => {
    setOpenSteps((prevSteps) => ({ ...prevSteps, [key]: !prevSteps[key] }));
  };

  const stepAccordions: StepAccordionMetadata[] = useMemo(
    () =>
      // the component will iterate through each StepAccordionMetadata to render the accordions
      steps.map((step, idx) => {
        const sortedSteps = sortBy<FlowStepMetadata>(({ index }) => index, Object.values(accordionMetadataMap));
        // A step is disabled if any of its preceding steps are not valid
        const disabled = !sortedSteps.slice(0, idx).every(({ isValid }) => isValid);
        const firstInvalidStep = sortedSteps.filter(({ isValid }) => !isValid)?.[0];
        // A step is open by default if it's not disabled and is the first invalid step
        const isDefaultOpen = !disabled && idx === firstInvalidStep?.index;

        return {
          ...step,
          disabled,
          isDefaultOpen,
        };
      }),
    [steps, accordionMetadataMap]
  );

  return {
    stepAccordions,
    setStepIsValid,
    openSteps,
    toggleOpen,
  };
};
