export enum FreightListActionTypes {
  // BOOKING LIST ACTIONS
  FREIGHT_ORDERS_REQUESTED = "FREIGHT_ORDERS_REQUESTED",
  FREIGHT_ORDERS_RECEIVED = "FREIGHT_ORDERS_RECEIVED",
  // QUOTE REQUEST ACTIONS
  FREIGHT_QUOTE_REQUESTS_REQUESTED = "FREIGHT_QUOTE_REQUESTS_REQUESTED",
  FREIGHT_QUOTE_REQUESTS_RECEIVED = "FREIGHT_QUOTE_REQUESTS_RECEIVED",
  FREIGHT_QUOTE_REQUESTS_GENERATED = "FREIGHT_QUOTE_REQUESTS_GENERATED",
  // LIST CONTROLS
  TOGGLE_LIST_VIEW = "TOGGLE_LIST_VIEW",
}

export const freightOrdersLoaderId = "getFreightOrders";
export const freightQuotesLoaderId = "getFreightQuotes";
export const freightOrderDetailLoaderId = "getFreightOrderDetail";
export const freightOrderTrackingLoaderId = "getFreightOrderTracking";
export const generateFreightBookingLoaderId = "generateFreightBooking";
