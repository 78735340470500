import { organizationClient } from "common/clients/instances";
import { SecuredApiKeyResponse } from "common/clients/seller/Organization/SecuredApiKeyResponse";
import { logError, logStart, logSuccess } from "Logger";

const CLOCK_SKEW_MS = 300000; // 5 minutes

export class AlgoliaSearchKeyManager {
  private cache:
    | {
        organizationId: string;
        apiKey: string;
        validUntil: Date;
      }
    | undefined;

  private pending:
    | {
        organizationId: string;
        request: Promise<SecuredApiKeyResponse>;
      }
    | undefined;

  public async getAlgoliaSearchKey(organizationId: string, now = new Date()): Promise<string> {
    if (
      this.cache &&
      this.cache.organizationId === organizationId &&
      this.cache.validUntil.getTime() - CLOCK_SKEW_MS > now.getTime()
    ) {
      return this.cache.apiKey;
    }

    // organizationId check handles edge case if fetching algolia search key while an admin is switching sellers
    if (!this.pending || this.pending.organizationId !== organizationId) {
      const ctx = logStart({ fn: "getAlgoliaSearchKey", organizationId });

      const request = organizationClient
        .getAlgoliaSearchKey(organizationId)
        .then((response) => {
          logSuccess(ctx, "successfully retrieved new Algolia search key");
          return response;
        })
        .catch((err) => {
          logError(ctx, err);
          throw err;
        });

      this.pending = {
        organizationId,
        request,
      };
    }

    const { apiKey, validUntil } = await this.pending.request;
    this.pending = undefined;

    this.cache = {
      organizationId,
      apiKey,
      validUntil: new Date(validUntil),
    };

    return this.cache.apiKey;
  }
}

export const algoliaSearchKeyManager = new AlgoliaSearchKeyManager();
