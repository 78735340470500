import React, { FC } from "react";
import { Pill, PillColors } from "common/components/Pill";
import { PrepJobResponseStatus } from "@deliverr/prep-service-client";
import { useIntl } from "react-intl";
import { PREP_STATUS_PILL_LABEL } from "prep/PrepStatusPill.label";

const prepStatusToPillColor: Record<PrepJobResponseStatus, PillColors> = {
  [PrepJobResponseStatus.INITIALIZED]: PillColors.GRAY,
  [PrepJobResponseStatus.ARRIVED]: PillColors.BLUE,
  [PrepJobResponseStatus.RECEIVED]: PillColors.BLUE,
  [PrepJobResponseStatus.IN_PROGRESS]: PillColors.PURPLE,
  [PrepJobResponseStatus.COMPLETED]: PillColors.GREEN,
  [PrepJobResponseStatus.BILLED]: PillColors.GREEN,
  [PrepJobResponseStatus.ARCHIVED]: PillColors.RED,
};

interface PrepStatusPillProps {
  prepStatus: PrepJobResponseStatus;
}

export const PrepStatusPill: FC<PrepStatusPillProps> = ({ prepStatus }) => {
  const { formatMessage } = useIntl();

  const pillColor = prepStatusToPillColor[prepStatus] ?? PillColors.GRAY;
  const message = PREP_STATUS_PILL_LABEL[prepStatus] ? formatMessage(PREP_STATUS_PILL_LABEL[prepStatus]) : prepStatus;
  return <Pill color={pillColor}>{message}</Pill>;
};
