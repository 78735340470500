import { createSelector } from "reselect";
import { selectBookingRequest } from "./selectBookingRequest";
import { BookingDestinationRequest } from "@deliverr/legacy-inbound-client";

export const selectBookingDestinationRequest = () =>
  createSelector(selectBookingRequest(false), (bookingRequest) => {
    const {
      bookingType,
      flexibleDeliveryDate,
      targetDeliveryDate,
      destinationIsPort,
      destinationPortGrouping,
      destinationPortFid,
      destinationAddressFid,
      to,
    } = bookingRequest;
    const bookingDestinationRequest: BookingDestinationRequest = {
      bookingType: bookingType!,
      flexibleDeliveryDate,
      targetDeliveryDate,
      destinationIsPort,
      destinationPortGrouping,
      destinationPortFid,
      destinationAddressFid,
      to,
    };
    return bookingDestinationRequest;
  });
