import { Product } from "@deliverr/commons-clients";
import { fromPairs } from "lodash/fp";
import { SPThunkAction } from "common/ReduxUtils";
import { generateHumanReadableId } from "common/utils/readable/GenerateHumanReadableId";
import { DraftShippingPlan } from "inbounds/InboundTypes";
import log from "Logger";
import { InboundActionTypes } from "../../InboundActionTypes";
import { addProducts } from "../../../InboundActions";
import { goToCreateInbound } from "inbounds/createShipment/store/actions/goToCreateInbound";
import { clearInbound } from "inbounds/createShipment/store/actions";

interface Options {
  selectedProducts?: Product[];
  name?: string;
  useCasePack?: boolean;
  dskuToQty?: { [dsku: string]: { caseQty: number; qty: number } };
  skipRedirect?: boolean;
  skipClearInbound?: boolean;
}

/* Create in-memory draft plan that won't be saved to the backend until products are selected */
export const createEcommFulfillmentInbound = ({
  selectedProducts,
  name,
  useCasePack,
  dskuToQty,
  skipRedirect,
  skipClearInbound,
}: Options = {}): SPThunkAction => async (dispatch) => {
  const ctx = { fn: "createEcommFulfillmentInbound" };
  log.info({ ...ctx, selectedProducts, useCasePack, dskuToQty, name }, "creating new inbound");

  const plan: Partial<DraftShippingPlan> = {
    name: name ?? generateHumanReadableId(),
    useCasePack: useCasePack !== false,
  };

  if (!skipClearInbound) {
    dispatch(clearInbound());
  }

  await dispatch({
    type: InboundActionTypes.CREATE_SHIPPING_PLAN,
    plan,
    products: selectedProducts ? fromPairs(selectedProducts.map((product) => [product.dsku, product])) : {},
  });

  if (selectedProducts) {
    await dispatch(addProducts(selectedProducts, dskuToQty ?? {}));
  }

  if (!skipRedirect) {
    dispatch(goToCreateInbound({ routeToProducts: true }));
  }
};
