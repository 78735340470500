type BoxDimensionKey = "width" | "length" | "height" | "weight";

export enum BoxDimension {
  Width = "width",
  Length = "length",
  Height = "height",
  Weight = "weight",
}

export type BoxDimensions = Partial<{ [dim in BoxDimensionKey]: number }>;
