import { wholesaleClient } from "Clients";
import { WholesaleShippingMethod, WholesaleShipmentPatchDTO } from "@deliverr/wholesale-client";
import { logError } from "Logger";
import { addLoader, clearLoader } from "../../../common/components/WithLoader/LoadingActions";
import { batch } from "react-redux";
import { selectWholesaleCreate } from "../store/WholesaleCreateSelectors";
import { wholesaleCreateSetError, wholesaleCreateSetWholesaleOrder } from "./WholesaleCreateActions";
import { CreateWholesaleLoader } from "../CreateWholesaleLoader.types";
import { getWholesaleOrderWithPacks } from "storage/packUtils/getWholesaleorderWithPacks";
import { WholesaleErrorType } from "../../../common/wholesale-common/utils/WholesaleErrorTypes";

export const patchWholesaleOrder = async (dispatch, getState): Promise<boolean> => {
  const { wholesaleOrder } = selectWholesaleCreate(getState());
  if (!wholesaleOrder?.id) {
    return false;
  }
  batch(() => {
    dispatch(addLoader(CreateWholesaleLoader.CreateWholesaleOrderLoader));
    dispatch(wholesaleCreateSetError(WholesaleErrorType.NONE));
  });

  try {
    await patchAllShipmentsOfWholesaleOrder(getState);
    const response = await dispatch(getWholesaleOrderWithPacks(wholesaleOrder?.id as string));
    batch(() => {
      dispatch(wholesaleCreateSetWholesaleOrder(response));
      dispatch(clearLoader(CreateWholesaleLoader.CreateWholesaleOrderLoader));
    });
    return true;
  } catch (err) {
    batch(() => {
      dispatch(clearLoader(CreateWholesaleLoader.CreateWholesaleOrderLoader));
      dispatch(wholesaleCreateSetError(WholesaleErrorType.UNTYPED));
    });
    logError({ fn: "patchWholesaleOrder" }, err);
    return false;
  }
};

const patchAllShipmentsOfWholesaleOrder = async (getState): Promise<boolean> => {
  const { wholesaleOrder, shippingMethod, cargoType, shipBy } = selectWholesaleCreate(getState());
  if (!wholesaleOrder?.shipments || !wholesaleOrder.shipments.length) {
    return false;
  }
  for (const shipmentInstruction of wholesaleOrder.shipments) {
    const patchRequest: WholesaleShipmentPatchDTO = {
      shipmentType: cargoType,
      shippingMethod: shippingMethod ?? WholesaleShippingMethod.DELIVERR,
      shipBy,
    };
    await wholesaleClient.patchShipment(shipmentInstruction.wholesaleOrderId, shipmentInstruction.id, patchRequest);
  }
  return true;
};
