export enum ShopifyError {
  ALREADY_HAS_SHOP = "ALREADY_HAS_SHOP",
  APP_NOT_INSTALLED = "APP_NOT_INSTALLED",
  DUPLICATE_MERCHANT_ID = "DUPLICATE_MERCHANT_ID",
  DUPLICATE_SELLER = "DUPLICATE_SELLER",
  INELIGIBLE_SHOPIFY_ACCOUNT = "INELIGIBLE_SHOPIFY_ACCOUNT",
  INVALID_HMAC = "INVALID_HMAC",
  INVALID_HOSTNAME = "INVALID_HOSTNAME",
  INVALID_MAIN_ASSET = "INVALID_MAIN_ASSET",
  INVALID_MERCHANT_ID = "INVALID_MERCHANT_ID",
  INVALID_NONCE = "INVALID_NONCE",
  INVALID_SETTINGS_ASSET = "INVALID_SETTINGS_ASSET",
  INVALID_TEMPLATE_ASSET = "INVALID_TEMPLATE_ASSET",
  INVALID_URL = "INVALID_URL",
  MISSING_SCOPES = "MISSING_SCOPES",
  MULTIPLE_VARIANTS = "MULTIPLE_VARIANTS",
  NO_SHOP_FROM_CLIENT = "NO_SHOP_FROM_CLIENT",
  NOT_AN_ADMIN = "NOT_AN_ADMIN",
  NOT_FOUND = "NOT_FOUND",
  NOT_FOUND_IN_MARKETPLACE = "NOT_FOUND_IN_MARKETPLACE",
  REFRESH_ALREADY_IN_PROGRESS = "REFRESH_ALREADY_IN_PROGRESS",
  UNAUTHORIZED = "UNAUTHORIZED",
}
