import React from "react";
import { Box } from "common/components/ui/Box";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { StorageWarehouseSellerView } from "storage/inbounds/create/selectDestinationV2/StorageWarehouseSellerView";

export const getWarehouseAddress = (
  warehouse: StorageWarehouseSellerView,
  hasSameZipCode: boolean,
  isRecommended = false
) => {
  const inventoryAvailabiltyMessage = (
    <FormattedMessage
      id="storage.inbounds.storageDestination.inventoryAvailabilty.message"
      defaultMessage={"You have inventory available at this location"}
    />
  );
  const inventoryAvailabiltyForRecommededWarehouseMessage = (
    <FormattedMessage
      id="storage.inbounds.storageDestination.inventoryAvailabilty.RecommededWarehouse.message"
      defaultMessage={"You have inventory at this location"}
    />
  );

  if (hasSameZipCode) {
    return (
      <Box>
        {warehouse.address.street1}
        {warehouse.address.street2?.trim() ? `, ${warehouse.address.street2}` : ""}
        {warehouse.address.street1?.trim() ? <br /> : undefined}
        {`${warehouse.address.city}, ${warehouse.address.state} ${warehouse.address.zip}`}
        {warehouse.palletCount > 0 && (
          <>
            <br /> {isRecommended ? inventoryAvailabiltyForRecommededWarehouseMessage : inventoryAvailabiltyMessage}
          </>
        )}
      </Box>
    );
  } else {
    return (
      <Box>
        {`${warehouse.address.city}, ${warehouse.address.state} ${warehouse.address.zip}`}
        {warehouse.palletCount > 0 && (
          <>
            <br /> {isRecommended ? inventoryAvailabiltyForRecommededWarehouseMessage : inventoryAvailabiltyMessage}
          </>
        )}
      </Box>
    );
  }
};
