import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { ProblemsTable } from "common/components/ProblemsTable";
import { useShipmentProblems } from "./useShipmentProblems";
import { DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import { useProblemsTableColumns } from "common/components/ProblemsTable/useProblemsTableColumns";
import { ShipmentProblemBlade } from "./blades/ShipmentProblemBlade";
import { ShipmentDetailCard, ShipmentDetailCardProps } from "inbounds/steps/ship/view/cards/ShipmentDetailCard";

const StyledShipmentDetailCard = styled(ShipmentDetailCard)<ShipmentDetailCardProps, DefaultTheme>(
  ({ theme }) => `
  th:first-of-type, td:first-of-type {
    padding-left: ${theme.spacing.S5};
  }
`
);

/** Do not show this card if there are no shipment problems */
export const ShipmentProblemsCard: FC = () => {
  const { rows, onRowClick, isDataLoading, selectedProblemCase } = useShipmentProblems();
  const { columns } = useProblemsTableColumns();

  return rows.length > 0 ? (
    <>
      <ShipmentProblemBlade shipmentProblem={selectedProblemCase} />
      <StyledShipmentDetailCard
        header={<FormattedMessage id="inbounds.viewShipment.shipmentProblemsCard.title" defaultMessage="PROBLEMS" />}
      >
        <ProblemsTable columns={columns} rows={rows} onRowClick={onRowClick} isDataLoading={isDataLoading} />
      </StyledShipmentDetailCard>
    </>
  ) : null;
};
