import React, { FC } from "react";
import { ProductPhotoSection, ProductPhotoSectionProps } from "../ProductPhotoSection";
import { useFetchProblemPhotoUrls } from "common/hooks/useFetchProblemPhotoUrls";

type NonComplianceProductPhotoSectionProps = ProductPhotoSectionProps;

export const NonComplianceProductPhotoSection: FC<NonComplianceProductPhotoSectionProps> = ({ photos, ...props }) => {
  const { photoUrls } = useFetchProblemPhotoUrls(photos);

  return <ProductPhotoSection photos={photoUrls} {...props} />;
};
