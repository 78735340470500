import { ApiError, DeliverrError } from "@deliverr/errors";
import { AxiosError } from "axios";
import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";

export type S3FileHandlerClientConfig = ApiClientConfig;

export class S3FileHandlerClient {
  private apiClient: ApiClient;

  constructor(
    { baseURL }: S3FileHandlerClientConfig = { baseURL: mustBeDefined(process.env.NON_COMPLIANCE_SERVICE_URL) }
  ) {
    this.apiClient = new ApiClient({ baseURL: `${baseURL}/v1` });
  }

  async getS3File(filePath: string): Promise<string> {
    try {
      return await this.apiClient.get({
        url: "/non-compliance/s3/download",
        params: { filePath },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw await this.handleError("getS3File", err);
    }
  }

  private handleError(functionName: string, err: AxiosError): never {
    if (!err.response) {
      throw new DeliverrError({
        code: ApiError.UNKNOWN_ERROR,
        functionName,
        payload: err,
      });
    }

    if (err.response?.status === 504) {
      throw new DeliverrError({
        code: ApiError.NETWORK_ERROR,
        functionName,
        payload: err,
      });
    }

    throw new DeliverrError({
      code: err.response?.data?.code,
      subcode: err.response?.data?.error?.subcode,
      functionName,
      payload: err,
    });
  }
}
