import React, { FC } from "react";
import styled from "@emotion/styled";
import { Button, ThemeProps } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { nonComplianceInternalRoutes as routes } from "inbounds/non-compliance";

const FooterContainer = styled.div<ThemeProps>(
  ({ theme }) =>
    `
      border-top: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL["80"]};
      padding: ${theme.spacing.S4} ${theme.spacing.S5};
      display: flex;
      justify-content: space-between;
      align-items: center;
    `
);

export const NonComplianceFooter: FC = () => (
  <FooterContainer>
    <Link to={routes.summary}>
      <Button secondary>
        <FormattedMessage id="nonCompliance.footer.returnButton" defaultMessage="Return to list" />
      </Button>
    </Link>
  </FooterContainer>
);
