import { CrossdockInboundQuote } from "common/clients/crossdock/CrossdockInboundQuote/CrossdockInboundQuote";
import { getCrossdockQuoteRequest } from "inbounds/crossdock/store/selectors/quote/getCrossdockQuoteRequest";
import { useSelector } from "react-redux";
import { generateCrossdockInboundQuote } from "../../store/actions";
import { getCrossdockInboundQuote } from "../../store/selectors";
import { useCrossdockDimsAndWeightsFallbacks } from "../useCrossdockDimsAndWeightsFallbacks";
import { useSPDispatch } from "common/ReduxUtils";

export const useCreateInboundQuote = () => {
  const dispatch = useSPDispatch();
  const currentQuote = useSelector(getCrossdockInboundQuote);
  const dimsFallbacks = useCrossdockDimsAndWeightsFallbacks();
  const buildRequest = useSelector(getCrossdockQuoteRequest);

  const createQuote = async (): Promise<CrossdockInboundQuote | undefined> => {
    return await dispatch(generateCrossdockInboundQuote(buildRequest(dimsFallbacks), currentQuote));
  };

  return {
    createQuote,
  };
};
