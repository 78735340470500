import { createSelector } from "reselect";
import { RootState } from "RootReducer";

/**
 * List of checks for each filter.
 * Each function in the array checks a specific filter condition and
 * returns `true` if the filter is active (not set to its default or neutral value).
 */
export const isStatusFilterActive = (filters: any) => filters.status !== "ALL_STATUS";
export const isDateFilterActive = (filters: any) => !["LAST_90", "ALL"].includes(filters.date);

const filterChecks = [isStatusFilterActive, isDateFilterActive];

/**
 * Selector to determine if any filters are active in the parcel list.
 * @param {RootState} state - The entire redux state tree.
 * @returns {boolean} - Returns `true` if any filters are active, otherwise returns `false`.
 */
export const selectHasActiveFilters = createSelector(
  (state: RootState) => state.parcelList.filters,
  (filters) => filterChecks.some((check) => check(filters))
);
