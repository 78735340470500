import React, { FC } from "react";
import {
  DamagedProductUnified,
  DamageFault,
} from "@deliverr/commons-clients/lib/non-compliance/damages/DamagedProductUnified";
import { FormattedDate, FormattedMessage } from "react-intl";

interface DamagedProductBladeDescriptionProps
  extends Pick<DamagedProductUnified, "damageFault" | "disputeOpenedAt" | "disputeClosedAt"> {
  isPending: boolean;
  hasDisputes: boolean;
  hasReimbursementCost: boolean;
  hasAutoResolvedDispute: boolean;
  isExpired: boolean;
}

export const DamagedProductBladeDescription: FC<DamagedProductBladeDescriptionProps> = ({
  isPending,
  damageFault,
  hasDisputes,
  hasReimbursementCost,
  disputeOpenedAt,
  disputeClosedAt,
  hasAutoResolvedDispute,
  isExpired,
}) => {
  const isDeliverrFault = damageFault === DamageFault.DELIVERR;

  if (isPending && !hasDisputes && isDeliverrFault) {
    return (
      <FormattedMessage
        id="damagedProductBlade.description.pendingInformation"
        defaultMessage="Support will contact you to collect more information for your reimbursement."
      />
    );
  }
  if (isPending && hasDisputes) {
    return (
      <FormattedMessage
        id="damagedProductBlade.description.reviewingDispute"
        defaultMessage="We have received your dispute on {disputeOpenedAt}. Flexport's support team will reach out to you within 48 hours."
        values={{
          disputeOpenedAt: (
            <FormattedDate value={new Date(disputeOpenedAt!)} year="numeric" month="long" day="numeric" />
          ),
        }}
      />
    );
  }
  if (hasDisputes && isExpired) {
    return (
      <FormattedMessage
        id="damagedProductBlade.description.disputeResolved.expiredUnits"
        defaultMessage="Your dispute was resolved on {disputeClosedAt}. Expired units were determined not to be reimbursed."
        values={{
          disputeClosedAt: (
            <FormattedDate value={new Date(disputeClosedAt!)} year="numeric" month="long" day="numeric" />
          ),
        }}
      />
    );
  }
  if (hasDisputes && !isDeliverrFault) {
    return (
      <FormattedMessage
        id="damagedProductBlade.description.disputeResolved.sellerCaused"
        defaultMessage="Your dispute was resolved on {disputeClosedAt}. Units damaged by seller carrier mishandling are not reimbursed by Flexport. Contact your carrier for potential resolutions or reimbursements."
        values={{
          disputeClosedAt: (
            <FormattedDate value={new Date(disputeClosedAt!)} year="numeric" month="long" day="numeric" />
          ),
        }}
      />
    );
  }
  if (hasDisputes && !!hasAutoResolvedDispute) {
    return (
      <FormattedMessage
        id="damagedProductBlade.description.disputeResolved.autoResolved"
        defaultMessage="This case has been automatically resolved due to inactivity after 60 business days."
      />
    );
  }
  if (hasDisputes) {
    return (
      <FormattedMessage
        id="damagedProductBlade.description.disputeResolved.deliverrCaused"
        defaultMessage="Your dispute was resolved on {disputeClosedAt}. Your reimbursement has been updated. This will show up as credit in your account in 1-2 billing cycles."
        values={{
          disputeClosedAt: (
            <FormattedDate value={new Date(disputeClosedAt!)} year="numeric" month="long" day="numeric" />
          ),
        }}
      />
    );
  }
  if (isExpired) {
    return (
      <FormattedMessage
        id="damagedProductBlade.description.expiredUnits"
        defaultMessage="Expired units found in inventory are not reimbursed by Flexport."
      />
    );
  }
  if (damageFault === DamageFault.SELLER) {
    return (
      <FormattedMessage
        id="damagedProductBlade.description.sellerCaused"
        defaultMessage="Units damaged by seller carrier mishandling are not reimbursed by Flexport. Contact your carrier for potential resolutions or reimbursements."
      />
    );
  }
  if (hasReimbursementCost) {
    return (
      <FormattedMessage
        id="damagedProductBlade.description.reimbursementUpdated"
        defaultMessage="Your reimbursement has been updated. This will show up as credit in your account in 1-2 billing cycles."
      />
    );
  }
  return null;
};
