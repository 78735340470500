export enum BoxErrorType {
  TOO_MANY_BOXED = "TOO_MANY_BOXED",
  TOO_FEW_BOXED = "TOO_FEW_BOXED",
  UNDER_WEIGHT = "UNDER_WEIGHT",
  OVER_WEIGHT = "OVER_WEIGHT",
  UNDER_DIMENSIONS = "UNDER_DIMENSIONS",
  OVER_DIMENSIONS = "OVER_DIMENSIONS",
  EMPTY_FIELDS = "EMPTY_FIELDS",
  ZERO_UNITS = "ZERO_UNITS",
  TOO_MANY_SKUS = "TOO_MANY_SKUS",
}
