import { RootState } from "RootReducer";
import { FlowEnabledStepMap } from "./FlowEnabledStepMap";
import { first } from "lodash/fp";

export const getNextEnabledFlowStep = <FlowStepType extends string>(
  currentStep: FlowStepType | undefined,
  flowStepType: { [key: string]: FlowStepType },
  flowEnabledStepMap: FlowEnabledStepMap<FlowStepType>,
  state: RootState
) => {
  const flowSteps = Object.values(flowStepType);
  const currentStepIndex = flowSteps.findIndex((step) => step === currentStep);
  const nextSteps = flowSteps.slice(currentStepIndex + 1);

  return currentStepIndex === -1 ? undefined : first(nextSteps.filter((step) => flowEnabledStepMap[step]?.(state)));
};
