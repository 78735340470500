import {
  InvalidOrderItemReason,
  InvalidOrderItemResolution,
  InvalidOrderItemResolutionRequest,
} from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { setInvalidOrderItems } from "./TransferCreateActions";
import { selectTransferCreate } from "../store/TransferCreateSelectors";
import { toast } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import React from "react";
import { isNil, keyBy } from "lodash";

export const resolveOutOfStockOrderItems = async (dispatch, getState, partialFill?: boolean): Promise<boolean> => {
  const { replenishmentOrder, originStorageWarehouse } = selectTransferCreate(getState());
  const orderId = replenishmentOrder?.orderId;
  const invalidItems = replenishmentOrder?.invalidOrderItems ?? [];
  const dskuQuantities = keyBy(originStorageWarehouse?.dskuQuantities ?? [], ({ dsku }) => dsku);

  const updatedInvalidItems = invalidItems.map((item) => {
    return {
      ...item,
      resolution:
        item.reason === InvalidOrderItemReason.OUT_OF_STOCK && isNil(item.resolution)
          ? InvalidOrderItemResolution.MODIFIED
          : item.resolution,
    };
  });
  const invalidItemRequests: InvalidOrderItemResolutionRequest[] = invalidItems
    .filter((item) => item.reason === InvalidOrderItemReason.OUT_OF_STOCK)
    .map((item) => {
      const availableQty = dskuQuantities?.[item.resolvedDsku!]?.qty ?? 0;
      const qtyToFulfill =
        item.resolution === InvalidOrderItemResolution.REMOVED
          ? 0
          : partialFill
          ? Math.min(availableQty, item.requestedTotalUnits)
          : item.requestedTotalUnits;
      return {
        id: item.id!,
        resolvedTotalUnits: qtyToFulfill,
        resolvedDsku: item.resolvedDsku ?? undefined,
        resolution: isNil(item.resolution) ? InvalidOrderItemResolution.MODIFIED : item.resolution,
      };
    });
  try {
    await replenishmentClient.invalidItemResolutionBulk(orderId!, {
      resolveInvalidItems: invalidItemRequests,
    });
    dispatch(setInvalidOrderItems(updatedInvalidItems));
    return true;
  } catch (err) {
    toast.error(
      <FormattedMessage
        id="replenishment.invalidorder.resolve.error"
        defaultMessage="Error submitting out of stock item(s). Please try again later"
      />,
      {
        autoClose: 5000,
        toastId: "getSalesChannels",
      }
    );
  }
  return false;
};
