import { createActionCreator, ActionCreator, SPThunkAction } from "common/ReduxUtils";

export enum LoadingActionTypes {
  ADD_LOADER = "ADD_LOADER",
  CLEAR_LOADER = "CLEAR_LOADER",
}

export const addLoader = createActionCreator<string>(LoadingActionTypes.ADD_LOADER, "name");
export const clearLoader = createActionCreator<string>(LoadingActionTypes.CLEAR_LOADER, "name");

export const withLoader =
  (name: string, action: ActionCreator) =>
  (...params: any[]): SPThunkAction =>
  async (dispatch) => {
    dispatch(addLoader(name));
    await dispatch(action(...params));
    dispatch(clearLoader(name));
  };
