import React, { FC } from "react";
import { Stack } from "common/components/ui";
import styled from "@emotion/styled";

import { Lightbox, useLightboxState } from "common/components/Lightbox";
import { ProductPhoto } from "inbounds/non-compliance/components";

export interface ProductPhotoSectionProps {
  galleryId?: string | number;
  photos: string[];
  isSmall?: boolean;
}

const ScaleImgContainer = styled.div(
  `
  img {
    max-width: 100%;
  }
`
);

export const ProductPhotoSection: FC<ProductPhotoSectionProps> = ({ galleryId = 0, photos, isSmall }) => {
  const { lightboxState, toggleLightbox } = useLightboxState();

  return (
    <>
      <Stack gap={isSmall ? "S2" : "S5"} direction="HORIZONTAL">
        {photos.map((src, photoIdx) => (
          <ProductPhoto key={photoIdx} isSmall={isSmall} src={src} onClick={() => toggleLightbox(photoIdx)} />
        ))}
      </Stack>
      <ScaleImgContainer>
        <Lightbox key={galleryId} sources={photos} state={lightboxState} />
      </ScaleImgContainer>
    </>
  );
};
