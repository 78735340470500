import { FreightPalletLabelLanguage, FreightPalletLabelPaperSize, PaperSize } from "@deliverr/commons-objects";
import { FreightQuote, FreightQuotePlanItem, FreightQuoteServiceLabel } from "FreightQuote/FreightQuoteSummary/store";
import { FreightQuotePlanService, FreightQuoteRequestOptions } from "FreightQuote/FreightQuoteTool/store";
import { mapError } from "../core/mapError";
import { FreightTrackingInfo } from "./FreightTrackingInfo/FreightTrackingInfo";
import { FreightTrackingInfoExternalIdType } from "./FreightTrackingInfo/FreightTrackingInfoExternalIdType";
import { FreightTrackingInfoSaveData } from "./FreightTrackingInfo/FreightTrackingInfoSaveData";
import { freightClient } from "Clients";
import logger, { logError, logStart } from "Logger";
import {
  FreightMode,
  FreightQuoteRequest,
  FreightService,
  FreightServiceRequestBase,
  FreightTrackingInfo as FreightClientFreightTrackingInfo,
  FreightTrackingInfoData as FreightClientFreightTrackingInfoData,
  FreightTrackingInfoExternalIdType as FreightClientFreightTrackingInfoExternalIdType,
  FreightPlanResponse,
  FreightServiceCost,
  SupportedLanguage,
} from "@deliverr/freight-client";
import { FreightShipmentInfoFlags } from "inbounds/InboundTypes";

export class FreightApiAdapter {
  async createFreightTrackingInfo(
    sellerId: string,
    freightInfoSaveData: FreightTrackingInfoSaveData
  ): Promise<FreightTrackingInfo> {
    const ctx = logStart({ fn: "FreightApiAdapter.createFreightTrackingInfo" });
    try {
      logger.info({ ...ctx, sellerId, freightInfoSaveData }, "Calling FreightClient.createFreightTrackingInfo");
      const data: FreightClientFreightTrackingInfoData = {
        ...freightInfoSaveData,
        externalIdType: this.convertToFreightClientFreightTrackingInfoExternalIdType(
          freightInfoSaveData.externalIdType
        ),
      };
      const freightTrackingInfo: FreightClientFreightTrackingInfo = await freightClient.createFreightTrackingInfo(
        sellerId,
        data
      );

      logger.info(
        { ...ctx, freightTrackingInfo },
        "Received response from FreightClient. Converting to a FreightTrackingInfo"
      );
      const convertedFreightTrackingInfo: FreightTrackingInfo = {
        ...freightTrackingInfo,
        externalIdType: this.convertToFreightTrackingInfoExternalIdType(freightTrackingInfo.externalIdType),
      };

      logger.info({ ...ctx, convertedFreightTrackingInfo }, "Converted FreightTrackingInfo");
      return convertedFreightTrackingInfo;
    } catch (error) {
      logError(ctx, error, "Error calling FreightClient.createFreightTrackingInfo");
      throw mapError(error);
    }
  }

  async getFreightTrackingInfo(
    sellerId: string,
    externalId: string,
    externalIdType: FreightTrackingInfoExternalIdType
  ): Promise<FreightTrackingInfo | undefined> {
    const ctx = logStart({ fn: "FreightApiAdapter.getFreightTrackingInfo", sellerId, externalId, externalIdType });
    try {
      logger.info(ctx, "Calling FreightClient.getFreightTrackingInfo");
      const freightTrackingInfo: FreightClientFreightTrackingInfo = await freightClient.getFreightTrackingInfo(
        sellerId,
        this.convertToFreightClientFreightTrackingInfoExternalIdType(externalIdType),
        externalId
      );

      logger.info(
        { ...ctx, freightTrackingInfo },
        "Received response from FreightClient. Converting to a FreightTrackingInfo"
      );
      const convertedFreightTrackingInfo = {
        ...freightTrackingInfo,
        externalIdType: this.convertToFreightTrackingInfoExternalIdType(freightTrackingInfo.externalIdType),
      };

      logger.info({ ...ctx, convertedFreightTrackingInfo }, "Converted FreightTrackingInfo");
      return convertedFreightTrackingInfo;
    } catch (error) {
      if (error.response?.status === 404) {
        logger.info(ctx, "FreightTrackingInfo not found");
        return undefined;
      }
      logError(ctx, error, "Error calling FreightClient.getFreightTrackingInfo");
      throw mapError(error);
    }
  }

  async updateFreightTrackingInfo(
    sellerId: string,
    updateData: FreightTrackingInfoSaveData & FreightShipmentInfoFlags
  ): Promise<void> {
    const ctx = logStart({ fn: "FreightApiAdapter.updateFreightTrackingInfo", sellerId, updateData });
    try {
      logger.info(ctx, "Calling FreightClient.updateOrCreateFreightTrackingInfo");
      const { hasConfirmedPalletCompliance, hasConfirmedAppointment, ...data } = updateData;
      await freightClient.updateOrCreateFreightTrackingInfo(sellerId, data);
    } catch (error) {
      logError(ctx, error, "Error calling FreightClient.updateOrCreateFreightTrackingInfo");
      throw mapError(error);
    }
  }

  async getBolPdfLinkV2(bolId: string): Promise<string | undefined> {
    const ctx = logStart({ fn: "FreightApiAdapter.getBolPdfLinkV2", bolId });
    try {
      logger.info(ctx, "Calling FreightClient.getBolPdfUrl");
      const bolUrl = await freightClient.getBolPdfUrl(bolId);

      logger.info({ ...ctx, bolUrl }, "Received response from FreightClient");
      return bolUrl;
    } catch (error) {
      if (error.response?.status === 404) {
        logger.info(ctx, "BOL URL not found");
        return undefined;
      }
      logError(ctx, error, "Error calling FreightClient.getBolPdfUrl");
      throw mapError(error);
    }
  }

  async generateFreightQuote(freightQuoteRequest: FreightQuoteRequestOptions): Promise<{ id: string }> {
    const ctx = logStart({ fn: "FreightApiAdapter.generateFreightQuote", freightQuoteRequest });
    try {
      logger.info(ctx, "Converting request to FreightClient's FreightQuoteRequest");
      const clientFreightQuoteRequest: FreightQuoteRequest = {
        ...freightQuoteRequest,
        plan: this.convertFreightQuotePlanServiceToFreightServiceRequestBase(freightQuoteRequest.plan),
      };

      logger.info({ ...ctx, clientFreightQuoteRequest }, "Calling FreightClient.generateFreightQuote");
      const quoteResponse = await freightClient.generateFreightQuote(clientFreightQuoteRequest);

      logger.info({ ...ctx, quoteResponse }, "Received response from FreightClient");
      return { id: String(quoteResponse.id) };
    } catch (error) {
      logError(ctx, error, "Error calling FreightClient.generateFreightQuote");
      throw mapError(error);
    }
  }

  async getFreightQuote(freightQuoteSummaryId: string): Promise<FreightQuote> {
    const ctx = logStart({ fn: "FreightApiAdapter.getFreightQuote", freightQuoteSummaryId });
    try {
      logger.info(ctx, "Calling FreightClient.getPlan");
      const freightPlanResponse: FreightPlanResponse = await freightClient.getPlan(Number(freightQuoteSummaryId));

      logger.info(
        { ...ctx, freightPlanResponse },
        "Received response from FreightClient. Converting to a FreightQuote"
      );
      const freightQuote = this.convertFreightPlanResponseToFreightQuote(freightPlanResponse);

      logger.info({ ...ctx, freightQuote }, "Converted FreightQuote");
      return freightQuote;
    } catch (error) {
      logError(ctx, error, "Error calling FreightClient.getPlan");
      throw mapError(error);
    }
  }

  async getPublicFreightQuote(freightQuoteSummaryId: string): Promise<FreightQuote> {
    const ctx = logStart({ fn: "FreightApiAdapter.getPublicFreightQuote", freightQuoteSummaryId });
    try {
      logger.info(ctx, "Calling FreightClient.getPublicPlan");
      const freightPlanResponse: FreightPlanResponse = await freightClient.getPublicPlan(freightQuoteSummaryId);

      logger.info(
        { ...ctx, freightPlanResponse },
        "Received response from FreightClient. Converting to a FreightQuote"
      );
      const freightQuote = this.convertFreightPlanResponseToFreightQuote(freightPlanResponse);

      logger.info({ ...ctx, freightQuote }, "Converted FreightQuote");
      return freightQuote;
    } catch (error) {
      logError(ctx, error, "Error calling FreightClient.getPublicPlan");
      throw mapError(error);
    }
  }

  async generateFreightQuotePublicId(freightQuoteId: string): Promise<string> {
    const ctx = logStart({ fn: "FreightApiAdapter.generateFreightQuotePublicId", freightQuoteId });
    try {
      logger.info(ctx, "Calling FreightClient.generateFreightPlanHashId");
      const freightQuotePublicId = await freightClient.generateFreightPlanHashId(Number(freightQuoteId));

      logger.info({ ...ctx, freightQuotePublicId }, "Received response from FreightClient");
      return freightQuotePublicId;
    } catch (error) {
      logError(ctx, error, "Error calling FreightClient.generateFreightPlanHashId");
      throw mapError(error);
    }
  }

  async getFreightPalletLabels(
    shippingPlanId: number,
    sellerId: string,
    paperSize?: FreightPalletLabelPaperSize,
    language?: FreightPalletLabelLanguage
  ): Promise<Buffer> {
    const ctx = logStart({
      fn: "FreightApiAdapter.getFreightPalletLabels",
      shippingPlanId,
      sellerId,
      paperSize,
      language,
    });
    try {
      logger.info(ctx, "Calling FreightClient.generatePalletLabels");
      const pdf = (await freightClient.generatePalletLabels(
        sellerId,
        shippingPlanId,
        language ?? SupportedLanguage.ENGLISH,
        paperSize ?? PaperSize.LETTER
      )) as Buffer;

      logger.info(ctx, "Received response from FreightClient");
      return pdf;
    } catch (error) {
      logError(ctx, error, "Error calling FreightClient.generatePalletLabels");
      throw mapError(error);
    }
  }

  private convertFreightQuotePlanServiceToFreightServiceRequestBase = (
    freightQuotePlanServices: FreightQuotePlanService[]
  ): FreightServiceRequestBase[] => {
    const compatibleServices: FreightServiceRequestBase[] = [];

    for (const freightQuotePlanService of freightQuotePlanServices) {
      if (freightQuotePlanService.service === FreightMode.DRAYAGE) {
        compatibleServices.push({ service: FreightService.DRAYAGE });
      } else if (freightQuotePlanService.service === FreightMode.FTL) {
        compatibleServices.push({ service: FreightService.FTL });
      } else if (freightQuotePlanService.service === FreightMode.TRANSLOAD) {
        compatibleServices.push({ service: FreightService.TRANSLOAD });
      }
    }
    return compatibleServices;
  };

  private convertToFreightClientFreightTrackingInfoExternalIdType(
    externalIdType: FreightTrackingInfoExternalIdType
  ): FreightClientFreightTrackingInfoExternalIdType {
    switch (externalIdType) {
      case FreightTrackingInfoExternalIdType.FREIGHT_SHIPMENT:
        return FreightClientFreightTrackingInfoExternalIdType.FREIGHT_SHIPMENT;
      case FreightTrackingInfoExternalIdType.INBOUND_SHIPMENT:
        return FreightClientFreightTrackingInfoExternalIdType.INBOUND_SHIPMENT;
      default:
        throw new Error(`Found unsupported FreightTrackingInfoExternalIdType ${externalIdType}`);
    }
  }

  private convertToFreightTrackingInfoExternalIdType(
    externalIdType: FreightClientFreightTrackingInfoExternalIdType
  ): FreightTrackingInfoExternalIdType {
    switch (externalIdType) {
      case FreightClientFreightTrackingInfoExternalIdType.FREIGHT_SHIPMENT:
        return FreightTrackingInfoExternalIdType.FREIGHT_SHIPMENT;
      case FreightClientFreightTrackingInfoExternalIdType.INBOUND_SHIPMENT:
        return FreightTrackingInfoExternalIdType.INBOUND_SHIPMENT;
      default:
        throw new Error(`Found unsupported FreightClientFreightTrackingInfoExternalIdType ${externalIdType}`);
    }
  }

  private convertFreightPlanResponseToFreightQuote(freightPlanResponse: FreightPlanResponse): FreightQuote {
    const freightQuoteSummaryPlan: FreightQuotePlanItem[] = freightPlanResponse.plan.map(
      (serviceCost: FreightServiceCost) => {
        let service: FreightQuoteServiceLabel;

        switch (serviceCost.service) {
          case FreightService.DRAYAGE:
            service = FreightQuoteServiceLabel.DRAYAGE;
            break;
          case FreightService.FTL:
            service = FreightQuoteServiceLabel.FTL;
            break;
          case FreightService.TRANSLOAD:
            service = FreightQuoteServiceLabel.TRANSLOAD;
            break;
          default:
            throw new Error(
              `Found an unsupported FreightService type ${serviceCost.service} when mapping to FreightQuote`
            );
        }
        return {
          ...serviceCost,
          quantity: 0, // there's no quantity in the response, even transportation doesn't return quantity
          service,
        };
      }
    );

    return {
      ...freightPlanResponse,
      id: freightPlanResponse.id.toString(),
      sellerId: freightPlanResponse.sellerId ?? "",
      createdAt: freightPlanResponse.createdAt.toString(),
      plan: freightQuoteSummaryPlan,
    };
  }
}
