import { DeliverrError } from "@deliverr/errors";
import { PrepJobResponse, SimplePrepType } from "@deliverr/prep-service-client";
import { inboundClient } from "Clients";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { SPThunkAction } from "common/ReduxUtils";
import { getSellerId } from "common/user/UserSelectors";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { PrepUIErrorCode } from "prep/PrepUIErrorCode";
import { setPrepJob, setPrepRequest } from "prep/store";
import { mapPrepJobResponseToPrepRequest } from "prep/util";
import { getShippingPlanId } from "inbounds/steps/InboundStepSelectors";
import { logError, logStart, logSuccess } from "Logger";

/**
 * @deprecated
 * Upserts only Simple Prep Types for the loaded Shipping Plan.
 * If simplePrepTypes are not passed, it will remove the existing Simple Prep Types.
 */
export const updateSimplePrep =
  (simplePrepTypes: SimplePrepType[] = []): SPThunkAction =>
  async (dispatch, getState) => {
    const sellerId = getSellerId(getState());
    const shippingPlanId = getShippingPlanId(getState());

    const ctx = logStart({ fn: updateSimplePrep.name, sellerId, shippingPlanId, simplePrepTypes });

    try {
      dispatch(addLoader(InboundLoaderId.transition));

      const updatedPrepJob = (await inboundClient.updateSimplePrepForShippingPlan({
        sellerId,
        shippingPlanId,
        requestedPrepTypes: simplePrepTypes,
      })) as PrepJobResponse; // TODO: PREP-3874 Remove use of InboundClient response
      if (updatedPrepJob) {
        dispatch(setPrepJob(updatedPrepJob));
        // TODO: remove this once we are only using PrepJob
        // https://shopifylogistics.atlassian.net/browse/PREP-3874
        dispatch(setPrepRequest(mapPrepJobResponseToPrepRequest(updatedPrepJob)));
      } else {
        dispatch(setPrepJob(undefined));
        dispatch(setPrepRequest(undefined));
      }
      logSuccess(ctx, "Updated Simple Prep successfully.");
    } catch (err) {
      logError(ctx, err);
      throw new DeliverrError({
        code:
          simplePrepTypes.length > 0
            ? PrepUIErrorCode.FAILED_TO_REQUEST_SIMPLE_PREP
            : PrepUIErrorCode.FAILED_TO_REMOVE_SIMPLE_PREP,
      });
    } finally {
      dispatch(clearLoader(InboundLoaderId.transition));
    }
  };
