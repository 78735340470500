import React from "react";
import styled from "@emotion/styled";

import HtmlToReact from "common/components/HtmlToReact";
import PalletInstructionsSvg from "assets/img/freight/pallet-instructions.svg";
import { DefaultTheme, ThemeProps } from "common/components/ui";
import { useStorageFreightPackingInstruction } from "./useStorageFreightPackingInstruction";

const PalletsContainer = styled.figure<{}, DefaultTheme>(
  ({ theme: { colors } }) => `
      color: ${colors.NEUTRAL["300"]};
      position: relative;
      height: 22.188rem;
      width: 51.484rem;
      margin: auto;
    `
);

const Description = styled.p<ThemeProps>(
  ({ theme: { spacing } }) => `
  margin-top: ${spacing.S4};
   font-weight: bold; 
    margin-bottom: ${spacing.S6};
  `
);

const LabeledPalletsElement = styled(HtmlToReact)`
  margin: auto;
`;

const ShrinkWrapLabel = styled.figcaption`
  width: 149px;
  top: 16px;
`;

const LabelsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0px;
  width: 830px;
  height: 312.53px;

  figcaption {
    font-size: 14px;
    line-height: 17px;
    position: absolute;
  }
`;

const WoodenPalletsLabel = styled.figcaption`
  width: 139.11px;
  left: 315px;
  top: 255px;
`;

const MaxWeightLabel = styled.figcaption`
  width: 149px;
  left: 358px;
  top: 37px;
`;

const MaxHeightLabel = styled.figcaption`
  width: 111.28px;
  right: 17px;
  top: 51px;
`;

export const StorageFreightPackingInstruction: React.FC = () => {
  const {
    stepDescription,
    shrinkWrapLabel,
    woodenPalletsLabel,
    maxHeightLabel,
    maxWeightLabel,
  } = useStorageFreightPackingInstruction();
  return (
    <div>
      <Description>{stepDescription}</Description>
      <PalletsContainer>
        <LabeledPalletsElement html={PalletInstructionsSvg} />
        <LabelsContainer>
          <ShrinkWrapLabel>{shrinkWrapLabel}</ShrinkWrapLabel>
          <WoodenPalletsLabel>{woodenPalletsLabel}</WoodenPalletsLabel>
          <MaxWeightLabel>{maxWeightLabel}</MaxWeightLabel>
          <MaxHeightLabel>{maxHeightLabel}</MaxHeightLabel>
        </LabelsContainer>
      </PalletsContainer>
    </div>
  );
};
