import React, { FC } from "react";
import { useSelector } from "react-redux";

import { MissingBarcodeProblemRow } from "common/components/ProblemsTable/types";
import { MissingBarcodeBlade } from "common/components/ProblemBlade/MissingBarcodeBlade";
import { SHIPMENT_PROBLEMS_MISSING_BARCODE_BLADE_ID } from "../blades";
import { getShippingPlan } from "inbounds/steps/InboundStepSelectors";
import { SelectedShipmentProblem } from "../../context";

export interface ShipmentProblemMissingBarcodeBladeProps {
  shipmentProblem: MissingBarcodeProblemRow;
  hideShipmentProblemBlade: () => void;
  setSelectedProblem: (problem: SelectedShipmentProblem | undefined) => void;
  isShipmentProblemBladeOpen: boolean;
}

export const ShipmentProblemMissingBarcodeBlade: FC<ShipmentProblemMissingBarcodeBladeProps> = ({
  shipmentProblem,
  hideShipmentProblemBlade,
  setSelectedProblem,
  isShipmentProblemBladeOpen,
}) => {
  const { photos, mappedSkus } = shipmentProblem;

  const { name: shippingPlanName, id: shippingPlanId } = useSelector(getShippingPlan);

  const resetBladeState = () => {
    hideShipmentProblemBlade();
    setSelectedProblem(undefined);
  };

  return (
    <MissingBarcodeBlade
      id={SHIPMENT_PROBLEMS_MISSING_BARCODE_BLADE_ID}
      shipmentProblem={shipmentProblem}
      onShippingPlanClick={resetBladeState}
      shippingPlanId={shippingPlanId}
      shippingPlanName={shippingPlanName}
      onCloseClick={resetBladeState}
      isBladeOpen={isShipmentProblemBladeOpen}
      product={mappedSkus[0]}
      photos={photos}
    />
  );
};
