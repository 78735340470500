import { Path } from "paths";
import { toast } from "common/components/ui";
import { logError, logStart } from "Logger";
import { useSelector, useStore } from "react-redux";
import { FlowEnabledStepMap } from "./FlowEnabledStepMap";
import { goToStepPath } from "./goToStepPath";
import { NavigationErrorMessage } from "inbounds/store/actions/navigation/NavigationErrorMessage";
import { getNextEnabledFlowStep } from "inbounds/navigation/getNextEnabledFlowStep";
import { getPreviousEnabledFlowStep } from "inbounds/navigation/getPreviousEnabledFlowStep";
import { getCurrentStep } from "inbounds/navigation/getCurrentStep";
import { selectIpbFeatureOn } from "inbounds/createShipment/store/selectors/selectIpbFeatureOn";
import { goToCreateShipmentStep } from "inbounds/createShipment/routing/goToCreateShipmentStep";
import { useSPDispatch } from "common/ReduxUtils";

export const useNavigation = <FlowStepType extends string>(
  flowStepType: { [key: string]: FlowStepType },
  stepToPathMap: Record<FlowStepType, Path>,
  flowEnabledStepMap: FlowEnabledStepMap<FlowStepType>
) => {
  const dispatch = useSPDispatch();
  const { getState } = useStore();
  const isIPB = useSelector(selectIpbFeatureOn);

  // we fetch state when the transition function is called to ensure the step is calculated with current state
  const getPreviousStep = () => {
    // current step updates in the outer scope may not yet be available when this is called, so
    // we use getCurrentStep instead of relying on updates from useCurrentStep
    const current = getCurrentStep<FlowStepType>(flowStepType, stepToPathMap);
    return getPreviousEnabledFlowStep<FlowStepType>(current, flowStepType, flowEnabledStepMap, getState());
  };

  const getNextStep = () => {
    const currentStep = getCurrentStep<FlowStepType>(flowStepType, stepToPathMap);
    return getNextEnabledFlowStep<FlowStepType>(currentStep, flowStepType, flowEnabledStepMap, getState());
  };

  const goToPreviousStep = () => {
    const ctx = logStart({ fn: "goToPreviousStep" });
    const previousStep = getPreviousStep();

    if (previousStep === undefined) {
      if (isIPB) {
        dispatch(goToCreateShipmentStep({ shouldUseLastStep: true }));
        return;
      }
      logError(ctx, "Previous inbound step does not exist");
      toast.error(NavigationErrorMessage);
      return;
    }

    goToStepPath(stepToPathMap[previousStep]);
  };

  const goToNextStep = () => {
    const ctx = logStart({ fn: "goToNextStep" });
    const nextStep = getNextStep();

    if (nextStep === undefined) {
      logError(ctx, "Next inbound step does not exist");
      toast.error(NavigationErrorMessage);
      return;
    }

    goToStepPath(stepToPathMap[nextStep]);
  };

  return {
    goToPreviousStep,
    goToNextStep,
    getPreviousStep,
    getNextStep,
  };
};
