import Tooltip from "rc-tooltip";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

export const ConfirmedByDeliverrTooltip: FC = ({ children }) => {
  return (
    <Tooltip
      trigger={["click"]}
      placement="bottom"
      overlay={<FormattedMessage id={"EstimateCost.tooltips.notEditable"} defaultMessage={"Confirmed by Flexport"} />}
    >
      {children}
    </Tooltip>
  );
};
