import axios, { AxiosInstance, AxiosResponse } from "axios";
import { stringify } from "qs";
import { partialRight } from "lodash";
import { ApiClientConfig } from "./ApiClientConfig";
import { Authentication } from "./authentication";
import { withAuthentication } from "./withAuthentication";
import { Call } from "./Call";
import { Body } from "./Body";
import { WithQueryParams } from "./WithQueryParams";
import { ResponseType } from "./ResponseType";

export type GetInput<P> = Call & Partial<Authentication & WithQueryParams<P> & ResponseType>;
export type PostInput<P, B> = Call & Partial<Authentication & WithQueryParams<P> & Body<B>>;
export type DeleteInput<P, B> = Call & Partial<Authentication & WithQueryParams<P> & Body<B>>;
export type PutInput<P, B> = Call & Partial<Authentication & WithQueryParams<P> & Body<B>>;
export type PatchInput<P, B> = Call & Partial<Authentication & WithQueryParams<P> & Body<B>>;

export class ApiClient {
  private axios: AxiosInstance;

  constructor({ baseURL, headers }: ApiClientConfig) {
    this.axios = axios.create({
      baseURL,
      headers,
      paramsSerializer: partialRight(stringify, { arrayFormat: "repeat" }),
    });
  }

  async get<ReturnType, ParamsType = undefined>({
    url,
    params,
    authentication,
    responseType,
  }: GetInput<ParamsType>): Promise<ReturnType> {
    const config = {
      url,
      params,
      responseType,
    };

    const response = await this.axios.request<ReturnType>(await withAuthentication({ authentication })(config));

    return response.data;
  }

  async patch<ReturnType, ParamsType = undefined, BodyType = undefined>({
    url,
    body: data,
    params,
    authentication,
  }: PatchInput<ParamsType, BodyType>): Promise<ReturnType> {
    const config = {
      method: "PATCH" as const,
      url,
      params,
      data,
    };

    const response = await this.axios.request<ReturnType>(await withAuthentication({ authentication })(config));

    return response.data;
  }

  async post<ReturnType, ParamsType = undefined, BodyType = undefined>({
    url,
    body: data,
    params,
    authentication,
  }: PostInput<ParamsType, BodyType>): Promise<ReturnType> {
    const config = {
      method: "POST" as const,
      url,
      params,
      data,
    };

    const response = await this.axios.request<ReturnType>(await withAuthentication({ authentication })(config));

    return response.data;
  }

  async delete<ReturnType, ParamsType = undefined, BodyType = undefined>({
    url,
    params,
    body: data,
    authentication,
  }: DeleteInput<ParamsType, BodyType>): Promise<ReturnType> {
    const config = {
      method: "DELETE" as const,
      url,
      data,
      params,
    };

    const response = await this.axios.request<ReturnType>(await withAuthentication({ authentication })(config));

    return response.data;
  }

  async put<ReturnType, ParamsType = undefined, BodyType = undefined>({
    url,
    body: data,
    params,
    authentication,
  }: PutInput<ParamsType, BodyType>): Promise<ReturnType> {
    const config = {
      method: "PUT" as const,
      url,
      data,
      params,
    };

    const response = await this.axios.request<ReturnType>(await withAuthentication({ authentication })(config));

    return response.data;
  }

  /**
   * For now, since the ApiClient axios http requests have some issues (return only data part but not the whole response, made the ApiResponse type not work properly),
   * we simply add this method to get the whole response. This is a temporary solution and should be replaced once the ApiClient is fixed.
   *
   */
  async getWithWholeResponse<ReturnType, ParamsType = undefined>({
    url,
    params,
    authentication,
    responseType,
  }: GetInput<ParamsType>): Promise<AxiosResponse<ReturnType>> {
    const config = {
      url,
      params,
      responseType,
    };

    const response = await this.axios.request<ReturnType>(await withAuthentication({ authentication })(config));

    return response;
  }

  async putWithWholeResponse<ReturnType, ParamsType = undefined, BodyType = undefined>({
    url,
    body: data,
    params,
    authentication,
  }: PutInput<ParamsType, BodyType>): Promise<AxiosResponse<ReturnType>> {
    const config = {
      method: "PUT" as const,
      url,
      data,
      params,
    };

    const response = await this.axios.request<ReturnType>(await withAuthentication({ authentication })(config));

    return response;
  }

  async postWithWholeResponse<ReturnType, ParamsType = undefined, BodyType = undefined>({
    url,
    body: data,
    params,
    authentication,
  }: PostInput<ParamsType, BodyType>): Promise<AxiosResponse<ReturnType>> {
    const config = {
      method: "POST" as const,
      url,
      data,
      params,
    };

    return await this.axios.request<ReturnType>(await withAuthentication({ authentication })(config));
  }
}
