import React, { InputHTMLAttributes } from "react";
import styled from "@emotion/styled";

import { CheckableTile, CheckableTileProps, CheckableTileType } from "../CheckableTile";
import { Direction, DefaultTheme } from "../shared";

export type CheckableTileGroupType = CheckableTileType;
export type CheckableTileGroupValue = InputHTMLAttributes<HTMLInputElement>["value"];

export type CheckableTileGroupOptionProps = Omit<CheckableTileProps, "type">;

export interface CheckableTileGroupProps
  extends Pick<CheckableTileProps, "onChange" | "name" | "block" | "disabled" | "name" | "className" | "reverse"> {
  direction?: keyof typeof Direction;
  options: CheckableTileGroupOptionProps[];
  currentValues: CheckableTileGroupValue[];
  header?: string | JSX.Element;
  showHeader?: boolean;
  type: CheckableTileGroupType;
  tileClassName?: string;
  tileWidth?: string;
  iconType?: "circle" | "square";
}

const StyledFieldset = styled.fieldset<
  Pick<CheckableTileGroupProps, "direction" | "block" | "tileWidth">,
  DefaultTheme
>(
  ({ direction, theme, block, tileWidth }) => `
    display: flex;
    flex-direction: ${direction === "HORIZONTAL" ? "row" : "column"};
    flex-wrap: wrap;
    margin: -${theme.spacing.S2} -${theme.spacing.S3};
    > label {
      margin: ${theme.spacing.S2} ${theme.spacing.S3};
      width: ${tileWidth ?? (block ? "initial" : direction === Direction.HORIZONTAL ? "252px" : "378px")}

    }
`
);

const StyledLegend = styled.legend<Pick<CheckableTileGroupProps, "showHeader">, DefaultTheme>(
  ({ showHeader, theme }) =>
    showHeader
      ? `
      margin-left: ${theme.spacing.S3};
      font-size: ${theme.font.size.F3};
      `
      : `
      position: absolute;
      left: -10000px;
      top: auto;
      width: 1px;
      height: 1px;
      overflow: hidden;
    `
);

export const CheckableTileGroup: React.FC<CheckableTileGroupProps> = ({
  name,
  header,
  showHeader,
  currentValues,
  block,
  direction = Direction.HORIZONTAL,
  options,
  onChange,
  disabled,
  className,
  type,
  tileClassName,
  reverse,
  tileWidth,
  iconType,
}) => (
  <StyledFieldset
    direction={direction}
    name={name}
    block={block}
    data-testid="tile_group_container"
    className={className}
    tileWidth={tileWidth}
  >
    <StyledLegend showHeader={showHeader}>{header ?? name}</StyledLegend>
    {options.map((option, i) => {
      const isChildSelected = currentValues.includes(option.value);
      const id = `${name}-${option.value}`;
      return (
        <CheckableTile
          {...option}
          key={id}
          id={id}
          name={name}
          block={block}
          onChange={onChange}
          checked={isChildSelected}
          tabIndex={isChildSelected || (i === 0 && currentValues.length === 0) ? 0 : -1}
          // Can disable individual options or the entire group
          disabled={option.disabled ?? disabled}
          type={type}
          className={tileClassName}
          reverse={reverse}
          iconType={iconType}
        />
      );
    })}
  </StyledFieldset>
);
