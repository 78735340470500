import { AllCountryList, InternationalRateCountryList, USCountryList } from "./Countries";
import React, { FC } from "react";
import { Select, SelectProps } from "common/components/ui";

import { CountryCode } from "@deliverr/commons-objects";
import { FormattedMessage } from "react-intl";

export type SelectableCountries = "ALL" | "US" | "INTERNATIONAL_RATES";
export const CountryListMap = {
  ALL: AllCountryList,
  US: USCountryList,
  INTERNATIONAL_RATES: InternationalRateCountryList,
};

export const getCountryOptions = (selectableCountries: SelectableCountries) => {
  const countryList = CountryListMap[selectableCountries];
  return Object.entries(countryList).map(([value, label]: [CountryCode, string]) => ({ value, label }));
};

interface OwnProps extends SelectProps {
  id?: string;
  onChange: (value: CountryCode) => void;
  className?: string;
  selectableCountries?: SelectableCountries;
  disabled?: boolean;
}

export const CountrySelect: FC<OwnProps> = ({
  id,
  name,
  onChange,
  value,
  placeholder,
  searchable = true,
  className,
  selectableCountries = "ALL",
  disabled = false,
  controlHeight,
  ...props
}) => {
  const options = getCountryOptions(selectableCountries);
  const selectedValue = value
    ? options.find((option) => option.value === value) ?? options.find((option) => option.label === value)
    : undefined;
  return (
    <Select
      id={id}
      name={name ?? "countrySelect"}
      onChange={(option) => onChange(option.value)}
      value={selectedValue}
      options={options}
      isSearchable={searchable}
      isClearable={false}
      className={className}
      placeholder={placeholder ?? <FormattedMessage id="SelectCountry" defaultMessage="Country" />}
      disabled={disabled}
      controlHeight={controlHeight}
      {...props}
    />
  );
};
