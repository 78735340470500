import React from "react";
import { Product } from "@deliverr/commons-clients";
import { SellerProblemsDetailView } from "@deliverr/legacy-inbound-client";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { Appearance, Box, Text, ThemeProps } from "common/components/ui";
import { getShowAdminFeatures } from "common/user/UserSelectors";
import cls from "./ViewShipmentCard.less";
import { ProductLink } from "common/components/ProductLink";
import { getProductSku } from "inbounds/steps/ship/utils/getProductSku";
import { getFormattedExpirationDate } from "inbounds/utils/shippingPlanItem";
import { DraftShippingPlanItem } from "inbounds/InboundTypes";
import { PackInfo } from "common/components/ProductSearch/Packs/components/PackInfo";
import { InputError } from "common/components/InputError";
import { useNonComplianceMessage } from "./useNonComplianceMessage";
import { AdminProductAndCasePackDskuDisplay } from "common/wholesale-common/components/AdminProductAndCasePackDskuDisplay";

interface ProductDetailProps {
  product: Product;
  planItem?: Pick<DraftShippingPlanItem, "expirationDate" | "expirationDateHasDay" | "lotNumber">;
  shouldShowLotInformation?: boolean;
  shouldShowPackInformation?: boolean;
  problem?: SellerProblemsDetailView;
  showStorageProductAndCaseDskuForAdmin?: boolean;
}

const StyledSKU = styled.span`
  word-break: break-all;
`;

const LightText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
`
);

const ProductSKU = ({
  product,
  showStorageProductAndCaseDskuForAdmin,
}: Pick<ProductDetailProps, "product" | "showStorageProductAndCaseDskuForAdmin">) => {
  const shouldShowAdminFeatures = useSelector(getShowAdminFeatures);
  return (
    <Box marginTop={"S1"}>
      <Text appearance="INFO" size="caption">
        <StyledSKU>{getProductSku(product)}</StyledSKU>
      </Text>
      {shouldShowAdminFeatures &&
        (showStorageProductAndCaseDskuForAdmin ? (
          <AdminProductAndCasePackDskuDisplay product={product} />
        ) : (
          <div className={cls.adminText}>{product.dsku}</div>
        ))}
    </Box>
  );
};

export const ProductDetailData = ({
  product,
  planItem,
  shouldShowLotInformation,
  shouldShowPackInformation,
  problem,
  showStorageProductAndCaseDskuForAdmin,
}: ProductDetailProps) => {
  const nonComplianceMessage = useNonComplianceMessage(problem);

  return (
    <div>
      <ProductLink product={product}>
        <Text bold appearance="PRIMARY" as="span">
          {product.name}
        </Text>
      </ProductLink>

      <ProductSKU product={product} showStorageProductAndCaseDskuForAdmin={showStorageProductAndCaseDskuForAdmin} />

      {shouldShowPackInformation && (
        <LightText appearance="INFO" size="caption">
          <PackInfo product={product} />
        </LightText>
      )}

      {shouldShowLotInformation && (
        <>
          {planItem?.lotNumber && (
            <Text appearance="INFO" size="caption">
              <FormattedMessage
                id="inbounds.productDetail.lot"
                defaultMessage="Lot {lotNumber}"
                values={{ lotNumber: planItem.lotNumber }}
              />
            </Text>
          )}
          {planItem?.expirationDate && (
            <Text appearance="INFO" size="caption">
              <FormattedMessage
                id="inbounds.productDetail.expirationDate"
                defaultMessage="Exp. {expirationDate}"
                values={{ expirationDate: getFormattedExpirationDate(planItem) }}
              />
            </Text>
          )}
        </>
      )}

      {nonComplianceMessage && <InputError appearance={Appearance.WARNING} message={nonComplianceMessage} />}
    </div>
  );
};
