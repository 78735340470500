import { unitedStatesStrings } from "common/AddressUtils";
import { toUpper, trim } from "lodash";

export const isInternationalDestination = (country?: string): boolean | undefined => {
  if (!country) {
    return;
  }

  return !unitedStatesStrings.includes(trim(toUpper(country)));
};
