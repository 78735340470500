import { isString } from "lodash/fp";
import React, { ReactNode } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { toast } from "common/components/ui";

const defaultExplanation = (
  <FormattedMessage
    id={"defaultExplanation"}
    defaultMessage={
      "Oops. Something went wrong. " +
      "Please try your last action again after a few moments. " +
      "If the problem persists, please file a support ticket using the help icon"
    }
  />
);

interface NotifyErrorParams {
  err?: any;
  explanation?: ReactNode;
  toastId?: string | number;
  translation?: MessageDescriptor;
}

interface NotifySuccessParams {
  toastId: string | number;
  translation: MessageDescriptor;
}

// handles legacy notifyUserOfError code
// should refactor once errors are standardized.
const getErrorMessage = (err) => {
  if (!err) {
    return;
  }
  if (err.payload) {
    return isString(err.payload.data) ? err.payload.data : isString(err.payload) ? err.payload : undefined;
  }
  return err.message || undefined;
};

const getTranslation = (translation?: MessageDescriptor) =>
  translation && <FormattedMessage id={translation.id} defaultMessage={translation.defaultMessage} />;

/**
 * @deprecated
 * We are moving away from notifyUserOfError as we now have access to translations through redux hooks.
 * ```
 * const { formatMessage } = useIntl();
 * toast.error(
 *   formatMessage({ id: "...", defaultMessage: "..." }),
 *   { autoClose: 5000, toastId: "..." }
 * );
 * ```
 */
export const notifyUserOfError = ({ err, explanation, toastId, translation }: NotifyErrorParams): void => {
  const message = getTranslation(translation) ?? (getErrorMessage(err) || explanation || defaultExplanation);
  toast.error(message, {
    autoClose: 5000,
    toastId,
  });
};

/**
 * @deprecated
 * We are moving away from notifyUserOfSuccess as we now have access to translations through redux hooks.
 * ```
 * const { formatMessage } = useIntl();
 * toast.success(
 *   formatMessage({ id: "...", defaultMessage: "..." }),
 *   { autoClose: 5000, toastId: "..." }
 * );
 * ```
 */
export const notifyUserOfSuccess = ({ toastId, translation }: NotifySuccessParams): void => {
  const message = getTranslation(translation);
  toast.success(message as JSX.Element, {
    autoClose: 5000,
    toastId,
  });
};

export const showShippoApiErrorToast = () =>
  toast.error(
    "Our label buying service is experiencing intermittent outages. " +
      "Please try to repurchase labels after 1 hour. " +
      "If you are still unable to purchase labels, please file a support ticket using the 'Help' icon. " +
      "We are sorry for the inconvenience.",
    {
      autoClose: 5000,
      toastId: "shipppApiErrorToast",
    }
  );

export const showInvalidAddressToast = () => {
  toast.error(
    "We can't confirm your ship-from address. " +
      "Return to the ship-from address page and look for our guide on " +
      "how to avoid common ship-from address mistakes.",
    {
      autoClose: 5000,
      toastId: "invalidAddressToast",
    }
  );
};
