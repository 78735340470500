import { PlannedShipment } from "inbounds/InboundTypes";
import { getLoadedPlannedShipment } from "inbounds/steps/InboundStepSelectors";
import { getIsSpd } from "inbounds/utils/shippingMethodUtils";
import { createSelector } from "reselect";
import { selectIsForwardingPlan } from "inbounds/store/selectors/plan/selectIsForwardingPlan";
import { selectIsOneNodeHubSupportedMerchant } from "common/user/UserSelectors";

export const selectIsBclPrepEnabledForFlow = createSelector(
  getLoadedPlannedShipment,
  selectIsForwardingPlan,
  selectIsOneNodeHubSupportedMerchant,
  (plannedShipment: PlannedShipment, isForwardingPlan: boolean, isOneNodeHubSupportedMerchant: boolean) =>
    plannedShipment && !getIsSpd(plannedShipment.shippingMethod) && (isForwardingPlan || isOneNodeHubSupportedMerchant)
);
