import * as React from "react";
import { BOX_ERROR_LABELS } from "./BoxError.labels";
import { InputError } from "common/components/InputError";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { BoxErrorType } from "./BoxError.types";
import { ThemeProps } from "common/components/ui";

const StyledInputError = styled(InputError)<ThemeProps>(
  ({ theme }) => ` 
    white-space: pre-line;
    margin-top: ${theme.spacing.S1};
`
);

interface BoxErrorProps {
  error: BoxErrorType;
}

export const BoxError: React.FC<BoxErrorProps> = ({ error }) => {
  const message = <FormattedMessage {...BOX_ERROR_LABELS[error]} />;

  return <StyledInputError appearance={"DANGER"} message={message} />;
};
