import { apiTokenScopeMessages } from "settings/api-tokens/common/apiTokenScopeMessages";

export const reformatScopesForRenderingByPermissions = (scopes: string[]) => {
  const uniqueScopes = {};
  scopes.forEach((scope) => {
    const scopeSplit = scope.split("_");
    const [permission, type] = scopeSplit;
    const apiName = scopeSplit.slice(2).join(" ");

    // normal scopes for client use
    if (type === "logistics" && apiTokenScopeMessages[apiName]) {
      if (!uniqueScopes[apiName]) {
        uniqueScopes[apiName] = permission;
      } else if (uniqueScopes[apiName] !== permission) {
        uniqueScopes[apiName] = "";
      }
    } else {
      // likely admin use or an unregistered scope. Return/use scope as is.
      uniqueScopes[scope] = "";
    }
  });
  return uniqueScopes;
};
