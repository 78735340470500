import { FastTagType } from "common/clients/fastTag/FastTagType";
import { combineReducers } from "redux";
import { facebookConnectFlow } from "./facebook/connect/facebookConnectFlow";
import { FastTagFlow } from "./model/FastTagFlow";
import { FastTagOperation, FAST_TAG_CONNECT } from "./model/FastTagOperation";
import { fastTagFlows } from "./model/flows";

export interface FastTagFlowsState {
  [FastTagType.FACEBOOK]: {
    [FAST_TAG_CONNECT]: ReturnType<typeof facebookConnectFlow["reducer"]>;
  };
}

// needed to type any since reduce struggles to account for intermediate state and final state when typing
export const fastTagFlowsInitialState: FastTagFlowsState = Object.keys(fastTagFlows).reduce<any>(
  (combinedFastTags, fastTag) => {
    return {
      ...combinedFastTags,
      [fastTag]: Object.entries(fastTagFlows[fastTag]).reduce(
        (combinedFlows, [operation, flow]: [FastTagOperation, FastTagFlow]) => {
          return {
            ...combinedFlows,
            [operation]: flow.initialState,
          };
        },
        {}
      ),
    };
  },
  {}
);

export const fastTagFlowsReducer = combineReducers<FastTagFlowsState>(
  Object.keys(fastTagFlows).reduce<any>((combinedFastTags, fastTag) => {
    return {
      ...combinedFastTags,
      [fastTag]: combineReducers(
        Object.entries(fastTagFlows[fastTag]).reduce(
          (combinedFlows, [operation, flow]: [FastTagOperation, FastTagFlow]) => {
            return {
              ...combinedFlows,
              [operation]: flow.reducer,
            };
          },
          {}
        )
      ),
    };
  }, {})
);
