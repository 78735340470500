import cn from "classnames/dedupe";
import React, { FC } from "react";
import { InstructionCardInfo, InstructionCard } from "./InstructionCard";
import { ListBaseProps, OrdinalLabelOptions } from "common/components/InstructionList/InstructionList";
import { Collapsible } from "common/components/CollapsibleSection";
import { PillColors } from "common/components/Pill";
import cls from "./InstructionCardList.less";

export interface InstructionCardListProps extends ListBaseProps<InstructionCardInfo>, Collapsible {
  shouldRemoveSpaceBetweenCards?: boolean;
  cardContentClassName?: string;
  buttonBarClassName?: string;
}

const DEFAULT_ORDINAL_OPTIONS: OrdinalLabelOptions = { enumeration: "none" };

export const InstructionCardList: FC<InstructionCardListProps> = (props) => {
  const {
    className,
    items,
    ordinalOptions,
    isCollapsible: isCollapsibleList,
    isDefaultOpen: isDefaultOpenList,
    shouldRemoveSpaceBetweenCards,
    cardContentClassName: allCardContentClassName,
    buttonBarClassName: allCardButtonBarClassName,
  } = props;
  return (
    <div className={cn(className, { [cls.gaplessCards]: shouldRemoveSpaceBetweenCards })}>
      {items.map((item, itemIndex) => {
        const {
          isCollapsible,
          isDefaultOpen,
          className: itemClassName,
          cardContentClassName,
          buttonBarClassName,
          isDisabled,
          ...itemProps
        } = item;

        const cardOrdinalOptions: OrdinalLabelOptions = {
          ...(ordinalOptions ?? DEFAULT_ORDINAL_OPTIONS),
          color: isDisabled ? PillColors.DARK_GRAY : undefined,
        };

        return (
          <InstructionCard
            key={`instructionCard${itemIndex}`}
            itemIndex={itemIndex}
            ordinalOptions={cardOrdinalOptions}
            isCollapsible={isCollapsible ?? isCollapsibleList}
            isDefaultOpen={isDefaultOpen ?? isDefaultOpenList}
            className={cn(itemClassName)}
            cardContentClassName={cn(allCardContentClassName, cardContentClassName)}
            buttonBarClassName={cn(allCardButtonBarClassName, buttonBarClassName)}
            isDisabled={isDisabled}
            {...itemProps}
          />
        );
      })}
    </div>
  );
};
