import React, { FC } from "react";
import { Route } from "react-router-dom";

import { UnknownBarcodeCaseContextProvider } from "./context";
import { nonComplianceInternalRoutes as routes } from "inbounds/non-compliance/NonComplianceRoutes";
import { UnknownBarcodeCase } from "./UnknownBarcodeCase";
import { UnknownBarcodeSuccess } from "./UnknownBarcodeSuccess";

export const UnknownBarcodeRoutes: FC = () => (
  <UnknownBarcodeCaseContextProvider>
    <Route
      exact
      key="NonCompliance.UnknownBarcode"
      path={routes.unknownBarcode}
      render={() => <UnknownBarcodeCase />}
    />
    <Route
      key="NonCompliance.UnknownBarcodeSuccess"
      path={routes.unknownBarcodeSuccess}
      render={() => <UnknownBarcodeSuccess />}
    />
  </UnknownBarcodeCaseContextProvider>
);
