import React, { FC, useState } from "react";
import { FormattedMessage, FormattedPlural } from "react-intl";
import { Anchor, Stack, Text, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import { Direction, useAboveSmall } from "common/components/ui/shared";
import { isNil } from "lodash";

import { UnexpectedSkuProblemRow } from "common/components/ProblemsTable/types";
import { ProblemBaseBlade, ProblemBladeProps } from "common/components/ProblemBlade/ProblemBaseBlade";
import { ProblemType } from "common/problems/ProblemType";
import { ProblemBladeStatusSection } from "common/components/ProblemBlade/components/ProblemBladeStatusSection";
import { DetailItemSection } from "common/components/ProblemBlade/components/DetailItemSection";
import { ProblemDetailItem } from "common/components/ProblemBlade/components/ProblemDetailItem";
import { SKULink } from "common/components/ProblemBlade/components/SKULink";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";
import { PROBLEM_TYPE_LABEL_MAP } from "common/problems/problemTypeLabelMap";
import { ShippingPlanNameDisplay } from "common/components/ShippingPlanNameDisplay";
import { MismatchCaseQtyBladeMessages } from "./MismatchCaseQtyBladeMessages";
import { getCasePackConfiguration } from "storage/inbounds/utils/getCasePackConfiguration";
import { Product, ProductCasePack } from "@deliverr/legacy-inbound-client/lib/legacy-copy-pasta/product";

export interface MismatchCaseQtyBladeProps extends ProblemBladeProps {
  shipmentProblem: UnexpectedSkuProblemRow;
  onShippingPlanClick: () => void;
  productDetails?: { siblingCasePacks: ProductCasePack[]; parentProduct: Product; product: Product };
  shippingPlanId?: number;
  shippingPlanName?: string;
}

const StatusSectionWithMargin = styled(ProblemBladeStatusSection)<ThemeProps>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S5};
  `
);

const StyledStack = styled(Stack)`
  width: 100%;
`;

const SpacedProblemDetailItem = styled(ProblemDetailItem)<ThemeProps>(
  ({ theme }) => `
  min-width: 50%;
  display: inline-block;

  dt {
    font-weight: ${theme.font.weight.BOLD};
  }
`
);

const DetailItemWithoutMinWidth = styled(SpacedProblemDetailItem)`
  min-width: 0;
`;

const LearnMoreText = styled(Text)`
  margin: 0;
`;

const MismatchCaseQtyStack = styled(Stack)<ThemeProps<{ skuContainerVertical: boolean }>>(
  ({ theme, skuContainerVertical }) => `
    position: relative;
    margin-top: ${theme.spacing.S3};
    margin-bottom: ${theme.spacing.S3};
    align-items: ${skuContainerVertical ? "flex-start" : "center"};
  `
);

const ReceivedQuantity = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-top: 0;
    margin-bottom: ${theme.spacing.S4};
  `
);

const PackConfiguration = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-top: 0;
    margin-bottom: ${theme.spacing.S4};
  `
);

const LightText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
  `
);

const ShippingPlanLink = styled(Anchor)`
  cursor: pointer;
`;

export const MismatchCaseQtyBlade: FC<MismatchCaseQtyBladeProps> = ({
  id,
  shipmentProblem,
  onCloseClick,
  onShippingPlanClick,
  isBladeOpen,
  productDetails,
  shippingPlanId,
  shippingPlanName,
}) => {
  const { status, updatedAt, units } = shipmentProblem;
  const { msku, dsku, name } = productDetails?.parentProduct ?? {};
  const [isSkuContainerVertical, setIsSkuContainerVertical] = useState(false);
  const isMobile = useAboveSmall();
  const { caseQty, length, width, height, weight } = getCasePackConfiguration(productDetails);

  const checkSkuContainerPosition = (el: HTMLDivElement | null) => {
    if (isNil(el)) {
      return;
    }

    setIsSkuContainerVertical(el.offsetTop >= el.offsetHeight);
  };

  return (
    <ProblemBaseBlade
      id={id}
      title={PROBLEM_TYPE_LABEL_MAP[ProblemType.MISMATCH_CASE_QTY]}
      onCloseClick={onCloseClick}
      isBladeOpen={isBladeOpen}
      updatedAt={updatedAt}
    >
      <StatusSectionWithMargin
        status={status}
        messageOverride={<FormattedMessage {...MismatchCaseQtyBladeMessages.STATUS_MESSAGE} />}
        headerOverride={<FormattedMessage {...MismatchCaseQtyBladeMessages.STATUS} />}
      />
      <StyledStack>
        <DetailItemSection>
          <StyledStack gap="S4">
            <Text size="bodyLarge" as="span">
              <FormattedMessage {...MismatchCaseQtyBladeMessages.DETAILS_TITLE} />
            </Text>
            <SpacedProblemDetailItem
              label={
                <FormattedMessage id="inbounds.mismatchCaseQtyBlade.shippingPlan" defaultMessage="Shipping plan" />
              }
              value={
                <ShippingPlanLink onClick={onShippingPlanClick}>
                  <ShippingPlanNameDisplay shippingPlanId={shippingPlanId} shippingPlanName={shippingPlanName} />
                </ShippingPlanLink>
              }
            />
            {name && (
              <SpacedProblemDetailItem
                label={<FormattedMessage {...MismatchCaseQtyBladeMessages.NONCOMPLIANT_PRODUCT} />}
                value={<SKULink dsku={dsku!}>{msku}</SKULink>}
              >
                {name}
              </SpacedProblemDetailItem>
            )}
            <MismatchCaseQtyStack
              skuContainerVertical={isSkuContainerVertical}
              direction={isMobile || isSkuContainerVertical ? Direction.VERTICAL : Direction.HORIZONTAL}
            >
              {productDetails?.product && (
                <DetailItemWithoutMinWidth
                  label={<FormattedMessage {...MismatchCaseQtyBladeMessages.PACKING_CONFIGURATION_RECEIVED} />}
                  value={
                    <PackConfiguration>
                      <FormattedMessage
                        {...MismatchCaseQtyBladeMessages.CASE_PACK_CONFIGURATION_WITH_UNITS}
                        values={{
                          unitCount: productDetails?.product.caseQty,
                          units: (
                            <FormattedPlural
                              value={productDetails?.product.caseQty ?? 0}
                              one={<FormattedMessage id="units.one" defaultMessage="unit" />}
                              other={<FormattedMessage id="units.plural" defaultMessage="units" />}
                            />
                          ),
                          configuration: (
                            <LightText>
                              {
                                <FormattedMessage
                                  {...MismatchCaseQtyBladeMessages.CASE_PACK_CONFIGURATION}
                                  values={{
                                    length: productDetails?.product.length,
                                    width: productDetails?.product.width,
                                    height: productDetails?.product.height,
                                    weight: productDetails?.product.weight,
                                  }}
                                />
                              }
                            </LightText>
                          ),
                        }}
                      />
                    </PackConfiguration>
                  }
                />
              )}
              {productDetails?.siblingCasePacks && productDetails?.siblingCasePacks.length <= 1 && caseQty && (
                <>
                  <DetailItemWithoutMinWidth
                    ref={(el) => checkSkuContainerPosition(el)}
                    label={<FormattedMessage {...MismatchCaseQtyBladeMessages.PACKING_CONFIGURATION_EXPECTED} />}
                    value={
                      <PackConfiguration>
                        <FormattedMessage
                          {...MismatchCaseQtyBladeMessages.CASE_PACK_CONFIGURATION_WITH_UNITS}
                          values={{
                            unitCount: caseQty,
                            units: (
                              <FormattedPlural
                                value={caseQty ?? 0}
                                one={<FormattedMessage id="units.one" defaultMessage="unit" />}
                                other={<FormattedMessage id="units.plural" defaultMessage="units" />}
                              />
                            ),
                            configuration:
                              length && width && height && weight ? (
                                <LightText>
                                  <FormattedMessage
                                    {...MismatchCaseQtyBladeMessages.CASE_PACK_CONFIGURATION}
                                    values={{
                                      length,
                                      width,
                                      height,
                                      weight,
                                    }}
                                  />
                                </LightText>
                              ) : (
                                <LightText>-</LightText>
                              ),
                          }}
                        />
                      </PackConfiguration>
                    }
                  />
                </>
              )}
            </MismatchCaseQtyStack>

            <ReceivedQuantity>
              <FormattedMessage
                {...MismatchCaseQtyBladeMessages.QUANTITY_RECEIVED_WITH_UNITS}
                values={{
                  quantityReceived: (
                    <strong>
                      {<FormattedMessage {...MismatchCaseQtyBladeMessages.QUANTITY_RECEIVED} />} <br />{" "}
                    </strong>
                  ),
                  unitCount: units,
                  units: (
                    <FormattedPlural
                      value={units ?? 0}
                      one={<FormattedMessage id="units.one" defaultMessage="unit" />}
                      other={<FormattedMessage id="units.plural" defaultMessage="units" />}
                    />
                  ),
                }}
              />
            </ReceivedQuantity>
            <LearnMoreText>
              <FormattedMessage
                {...MismatchCaseQtyBladeMessages.LEARN_MORE}
                values={{
                  unexpectedBarcodes: (
                    <ElevioBorderlessButton articleId={ElevioArticleId.ReserveStorageNonCompliance}>
                      <Text bold>
                        <FormattedMessage {...MismatchCaseQtyBladeMessages.MISMATCH_CASE_QTY_LINK} />
                      </Text>
                    </ElevioBorderlessButton>
                  ),
                }}
              />
            </LearnMoreText>
          </StyledStack>
        </DetailItemSection>
      </StyledStack>
    </ProblemBaseBlade>
  );
};
