import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";

export const useSelectShipmentShipByDate = () => {
  const { wholesaleOrder, validShipBy } = useSelector(selectTransferCreate);
  const shipments = wholesaleOrder?.shipments ?? [];
  const shipBy = shipments[0]?.shipBy;

  const [selectedShipByDate, setShipByDate] = useState<Date | string | undefined>(
    shipBy ?? validShipBy?.calculatedASAPShipBy
  );
  const [hasShipByDateChanged, setHasShipByDateChanged] = useState(false);

  const onShipByChange = (value: Date) => {
    setShipByDate(value);
    setHasShipByDateChanged(true);
  };

  useEffect(() => {
    if (!selectedShipByDate) {
      setShipByDate(shipBy ?? validShipBy?.calculatedASAPShipBy);
    }
  }, [validShipBy?.calculatedASAPShipBy, shipBy, selectedShipByDate, setShipByDate]);

  return {
    selectedShipByDate,
    onShipByChange,
    hasShipByDateChanged,
    validShipBy,
    shipBy,
  };
};
