import { useCallback, useState } from "react";

import { LightboxState } from "./Lightbox";

export const useLightboxState = () => {
  const [lightboxState, setLightboxState] = useState<LightboxState>({
    slideIdx: 0,
    toggler: false,
  });

  const toggleLightbox = useCallback((slideIdx = 0) => {
    setLightboxState((prevState) => ({
      slideIdx,
      toggler: !prevState.toggler,
    }));
  }, []);

  return {
    lightboxState,
    toggleLightbox,
  };
};
