import { SelectedProductData } from "common/components/ProductChooser/SelectedProductData";
import { getIsLoading } from "common/components/WithLoader/LoadingSelectors";
import { isInteger, some } from "lodash/fp";
import { batch, useDispatch, useSelector } from "react-redux";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { useCallback, useEffect } from "react";
import { selectWholesaleSelectedProductsQty } from "../store/selectWholesaleSelectedProductsQty";
import { wholesaleCreateUpdateProductNumberOfCases } from "../actions/WholesaleCreateActions";

export const useSelectProductQuantityStep = () => {
  const dispatch = useDispatch();
  const selectedProductsQty = useSelector(selectWholesaleSelectedProductsQty);
  const isNextDisabled = some((selectedProduct: SelectedProductData) => !selectedProduct.qty, selectedProductsQty);
  const isLoading = useSelector(getIsLoading)(CreateTransferLoader.FetchStorageToEcomRatesLoader);

  const updateNumberOfCases = useCallback(
    (dsku: string, numberOfCases: number) => {
      return dispatch(wholesaleCreateUpdateProductNumberOfCases(dsku, isInteger(numberOfCases) ? numberOfCases : 0));
    },
    [dispatch]
  );

  useEffect(() => {
    batch(() => {
      selectedProductsQty.forEach((selectedProduct) => {
        if (selectedProduct.qty === undefined) {
          // set initial number of cases to maximum available
          const caseQty = selectedProduct.caseQty ?? 1;
          const availableQty = selectedProduct.storageOnHandQty ?? 0;
          const maxNumberOfCases = Math.floor(availableQty / caseQty);
          updateNumberOfCases(selectedProduct.dsku, maxNumberOfCases);
        }
      });
    });
  }, [selectedProductsQty, updateNumberOfCases]);

  return {
    selectedProductsQty,
    updateNumberOfCases,
    isNextDisabled,
    isLoading,
  };
};
