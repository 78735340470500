import { PrepOption } from "@deliverr/business-types";
import { isEmpty } from "lodash";

export const isPrepSelected = (prepOptions: PrepOption[], prepOption?: PrepOption) => {
  const hasMultipleOptions = prepOptions.length > 1;
  const hasSingleValidOption = prepOptions.length === 1 && prepOptions[0] !== PrepOption.SHIPPING_ONLY;

  return !isEmpty(prepOptions)
    ? hasMultipleOptions || hasSingleValidOption
    : !!(prepOption && prepOption !== PrepOption.SHIPPING_ONLY);
};
