import React, { FC } from "react";

import { UnexpectedBarcodeNonComplianceRow } from "../../types";
import { UnexpectedBarcodeBlade } from "common/components/ProblemBlade/UnexpectedBarcodeBlade";
import { UNEXPECTED_BARCODE_NON_COMPLIANCE_BLADE_ID } from "../bladeIds";
import { useUnexpectedBarcodeNonComplianceBlade } from "./useUnexpectedBarcodeNonComplianceBlade";

interface UnexpectedBarcodeNonComplianceBladeProps {
  nonComplianceCase: UnexpectedBarcodeNonComplianceRow;
}

export const UnexpectedBarcodeNonComplianceBlade: FC<UnexpectedBarcodeNonComplianceBladeProps> = ({
  nonComplianceCase,
}) => {
  const {
    onShippingPlanClick,
    shippingPlanId,
    shippingPlanName,
    resetBladeState,
    skuPair,
  } = useUnexpectedBarcodeNonComplianceBlade(nonComplianceCase);

  return (
    <UnexpectedBarcodeBlade
      id={UNEXPECTED_BARCODE_NON_COMPLIANCE_BLADE_ID}
      shipmentProblem={nonComplianceCase}
      onShippingPlanClick={onShippingPlanClick}
      shippingPlanId={shippingPlanId}
      shippingPlanName={shippingPlanName}
      onCloseClick={resetBladeState}
      isBladeOpen
      product={skuPair}
    />
  );
};
