import React, { FC } from "react";
import styled from "@emotion/styled";
import { ButtonIcon, Text, ThemeProps } from "common/components/ui";
import { useIntl } from "react-intl";

import { ProductListItem } from "inventory/list/InventoryListReducer";
import { removeAllEmTags } from "common/StringUtils";

interface ProductSearchSelectionProps {
  selectedProduct: ProductListItem;
  onClear: () => void;
}

const SelectionContainer = styled.div<ThemeProps>(
  ({ theme }) =>
    `
    padding: ${theme.spacing.S4};
    border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL["80"]};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
);

const SelectionInfoContainer = styled.div<ThemeProps>(
  ({ theme }) =>
    `
      padding-right: ${theme.spacing.S4};
    `
);

const ProductName = styled(Text)<ThemeProps>(
  ({ theme }) =>
    `
    word-break: break-all;
    margin-bottom: ${theme.spacing.S1};
  `
);

const ProductPropertyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ProductProperty = styled(Text)<ThemeProps>(
  ({ theme }) =>
    `
    &:not(:last-of-type) {
      margin-right: ${theme.spacing.S4};
    }
  `
);

const ClearButton = styled(ButtonIcon)`
  flex-shrink: 0;
`;

export const ProductSearchSelection: FC<ProductSearchSelectionProps> = ({
  selectedProduct: { name, msku, onHandQty },
  onClear,
}) => {
  const { formatMessage } = useIntl();

  return (
    <SelectionContainer>
      <SelectionInfoContainer>
        <ProductName bold as="div">
          {removeAllEmTags(name)}
        </ProductName>
        <ProductPropertyContainer>
          <ProductProperty as="span" appearance="INFO" size="caption">
            {removeAllEmTags(msku)}
          </ProductProperty>
          <ProductProperty as="span" appearance="INFO" size="caption">
            {formatMessage(
              {
                id: "productSearchSelection.unitsAvailable",
                defaultMessage: "{qty} available",
              },
              { qty: onHandQty ?? 0 }
            )}
          </ProductProperty>
        </ProductPropertyContainer>
      </SelectionInfoContainer>
      <ClearButton
        data-testid="clear_button"
        appearance="INFO"
        size="SM"
        onClick={onClear}
        aria-label={formatMessage({ id: "clearButtonAriaLabel", defaultMessage: "clear" })}
        iconType="times"
      />
    </SelectionContainer>
  );
};
