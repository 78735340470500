import isPast from "date-fns/isPast";
import { useIntl } from "react-intl";

export const useSimpleFieldValidators = () => {
  const { formatMessage } = useIntl();
  const requiredErrorMessage = formatMessage({
    id: "reactHookForm.validators.required",
    defaultMessage: "Required.",
  });

  const validateRequired = (customMessage?: string) => (value: any) => {
    if (value === null || value === undefined || (typeof value === "string" && value.trim() === "")) {
      return customMessage ?? requiredErrorMessage;
    }
    return undefined;
  };

  // if value can be zero, use validateRequired instead, or custom validator
  const validateRequiredNumber = (customMessage?: string) => (value: any) => {
    if (!value) {
      return customMessage ?? requiredErrorMessage;
    }
    return undefined;
  };

  const validateGreaterThan = (min: number, customMessage?: string) => (value: number) => {
    if (value < min) {
      return (
        customMessage ??
        formatMessage(
          {
            id: "reactHookForm.validators.greaterThan",
            defaultMessage: "Cannot be less than {min}.",
          },
          { min }
        )
      );
    }
    return undefined;
  };

  const validateLessThan = (max: number, customMessage?: string) => (value: number) => {
    if (value > max) {
      return (
        customMessage ??
        formatMessage(
          {
            id: "reactHookForm.validators.lessThan",
            defaultMessage: "Cannot be greater than {max}.",
          },
          { max }
        )
      );
    }
    return undefined;
  };

  const validateFutureDate = (customMessage?: string) => (value: Date | string) => {
    if (isPast(new Date(value))) {
      return (
        customMessage ??
        formatMessage({
          id: "reactHookForm.validators.futureDate",
          defaultMessage: "Cannot be in the past.",
        })
      );
    }
    return undefined;
  };

  return {
    validateRequired,
    validateRequiredNumber,
    validateGreaterThan,
    validateLessThan,
    validateFutureDate,
  };
};
