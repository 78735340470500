import React, { FC } from "react";
import store from "store";
import { NavBar } from "common/components/NavBar";
import { Path } from "paths";
import { CreateStorageInboundNavBarTitle } from "./CreateStorageInboundNavBarTitle";
import { getEnabledFlowSteps } from "inbounds/navigation/getEnabledFlowSteps";
import { storageInboundEnabledStepMap } from "storage/inbounds/create/navigation/storageInboundEnabledStepMap";
import { StorageInboundStep } from "storage/inbounds/create/StorageInboundStep";
import { useCurrentStorageInboundStep } from "storage/inbounds/create/navigation/useCurrentStorageInboundStep";
import { NavBarProgressRenderer } from "common/components/NavBarProgress/NavBarProgressRenderer";

export const StorageInboundProgressNavBar: FC = () => {
  const state = store.getState();
  const enabledInboundSteps = getEnabledFlowSteps<StorageInboundStep>(
    StorageInboundStep,
    storageInboundEnabledStepMap,
    state
  );
  const currentStep = useCurrentStorageInboundStep();

  return (
    <NavBar
      breadcrumbs={[
        {
          pageName: "Inbounds",
          path: { pathname: Path.inboundsList },
        },
        {
          pageName: "Create Reserve Storage inbound",
          component: <CreateStorageInboundNavBarTitle />,
        },
      ]}
    >
      <NavBarProgressRenderer steps={enabledInboundSteps} currentStep={currentStep} />
    </NavBar>
  );
};
