export enum Carriers {
  AXLEHIRE = "AXLEHIRE",
  BETTERTRUCKS = "BETTERTRUCKS",
  CDL = "CDL",
  DHL = "DHL",
  DHL_EXPRESS = "DHL_EXPRESS",
  DOORDASH = "DOORDASH",
  FEDEX = "FEDEX",
  FREIGHT = "FREIGHT",
  GLS = "GLS",
  LASERSHIP = "LASERSHIP",
  LSO = "LSO",
  ONTRAC = "ONTRAC",
  OTHER = "OTHER",
  PASSPORT = "PASSPORT",
  PCF = "PCF",
  SANDBOX = "SANDBOX",
  TFORCE = "TFORCE",
  UDS = "UDS",
  UPS = "UPS",
  USPS = "USPS",
  VEHO = "VEHO",
}

export const carrierNames: Record<Carriers, string> = {
  [Carriers.AXLEHIRE]: "AxleHire",
  [Carriers.BETTERTRUCKS]: "BetterTrucks",
  [Carriers.CDL]: "CDL",
  [Carriers.DHL]: "DHL",
  [Carriers.DHL_EXPRESS]: "DHL Express",
  [Carriers.DOORDASH]: "DoorDash",
  [Carriers.FEDEX]: "FedEx",
  [Carriers.FREIGHT]: "GlobalTranz",
  [Carriers.GLS]: "GLS",
  [Carriers.LASERSHIP]: "LaserShip",
  [Carriers.LSO]: "LSO",
  [Carriers.ONTRAC]: "OnTrac",
  [Carriers.OTHER]: "Other",
  [Carriers.PASSPORT]: "Passport",
  [Carriers.PCF]: "PCF",
  [Carriers.SANDBOX]: "SANDBOX",
  [Carriers.TFORCE]: "TForce",
  [Carriers.UDS]: "UDS",
  [Carriers.UPS]: "UPS",
  [Carriers.USPS]: "USPS",
  [Carriers.VEHO]: "Veho",
};

const AXLEHIRE_TRACKING_CODE_URL = "https://recipient.axlehire.com/";
const BETTERTRUCKS_TRACKING_CODE_URL = "https://tracking.bettertrucks.com/Tracking?ShipmentId=";
const CDL_TRACKING_CODE_URL = "https://ship.cdldelivers.com/Xcelerator/Tracking/Tracking?orderId=";
const DHL_EXPRESS_TRACKING_CODE_URL = "https://www.dhl.com/us-en/home/tracking.html?tracking-id=";
const DHL_TRACKING_CODE_URL = "https://webtrack.dhlecs.com/?trackingnumber=";
const FEDEX_TRACING_CODE_URL = "https://www.fedex.com/apps/fedextrack/?action=track&cntry_code=us&trackingnumber=";
const GLOBALTRANZ_TRACKING_CODE_URL = "https://carrierrate.globaltranz.com/PublicTrack/PublicTrack/#/Home";
const GLS_TRACKING_CODE_URL = "https://www.gls-us.com/track-and-trace?TrackingNumbers=";
const LASERSHIP_TRACKING_CODE_URL = "https://www.lasership.com/track.php?track_number_input=";
const LSO_TRACKING_CODE_URL = "https://www.lso.com/tracking?airbillnos=";
const ONTRAC_TRACKING_CODE_URL = "https://www.ontrac.com/trackingresults.asp?tracking_number=";
const PASSPORT_TRACKING_CODE_URL = "https://track.passportshipping.com/";
const PCF_TRACKING_CODE_URL = "https://pcfcorp.com/track-package/?trackingNumber=";
const TFORCE_TRACKING_CODE_URL = "https://direct.tfesg.com/finalmiletrack/Track?trackingNumber=";
const UDS_TRACKING_CODE_URL = "https://www.uniteddeliveryservice.com/track/barcode/";
const UPS_TRACKING_CODE_URL = "https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=";
const USPS_TRACKING_CODE_URL = "https://tools.usps.com/go/TrackConfirmAction?tLabels=";
const VEHO_TRACKING_CODE_URL = "https://track.shipveho.com/#/barcode/";

/**
 * DELIVERR_TRACKING_PORTAL_CARRIERS do not provide carrier tracking web pages.
 * The Deliverr Tracking Portal is used as the carrier tracking webpage for DELIVERR_TRACKING_PORTAL_CARRIERS.
 */
const DELIVERR_TRACKING_PORTAL_CARRIERS = [Carriers.DOORDASH, Carriers.SANDBOX];
export const DELIVERR_TRACKING_PORTAL_URL = "https://tracker.flexport.com/";

export const CARRIER_TRACKING_CODE_URLS: Record<Exclude<Carriers, Carriers.OTHER>, string> = {
  [Carriers.AXLEHIRE]: AXLEHIRE_TRACKING_CODE_URL,
  [Carriers.BETTERTRUCKS]: BETTERTRUCKS_TRACKING_CODE_URL,
  [Carriers.CDL]: CDL_TRACKING_CODE_URL,
  [Carriers.DHL]: DHL_TRACKING_CODE_URL,
  [Carriers.DHL_EXPRESS]: DHL_EXPRESS_TRACKING_CODE_URL,
  [Carriers.DOORDASH]: DELIVERR_TRACKING_PORTAL_URL,
  [Carriers.FEDEX]: FEDEX_TRACING_CODE_URL,
  [Carriers.FREIGHT]: GLOBALTRANZ_TRACKING_CODE_URL,
  [Carriers.GLS]: GLS_TRACKING_CODE_URL,
  [Carriers.LASERSHIP]: LASERSHIP_TRACKING_CODE_URL,
  [Carriers.LSO]: LSO_TRACKING_CODE_URL,
  [Carriers.ONTRAC]: ONTRAC_TRACKING_CODE_URL,
  [Carriers.PASSPORT]: PASSPORT_TRACKING_CODE_URL,
  [Carriers.PCF]: PCF_TRACKING_CODE_URL,
  [Carriers.SANDBOX]: DELIVERR_TRACKING_PORTAL_URL,
  [Carriers.TFORCE]: TFORCE_TRACKING_CODE_URL,
  [Carriers.UDS]: UDS_TRACKING_CODE_URL,
  [Carriers.UPS]: UPS_TRACKING_CODE_URL,
  [Carriers.USPS]: USPS_TRACKING_CODE_URL,
  [Carriers.VEHO]: VEHO_TRACKING_CODE_URL,
};

export const getBuyerPortalOrderTrackingUrl = (orderTrackingId: string): string => {
  return DELIVERR_TRACKING_PORTAL_URL + orderTrackingId;
};

/**
 * This function is used to generate a tracking url for a package in buyer portal.
 * - If orderTrackingId exists and there's a single package - DELIVERR_TRACKING_PORTAL_URL + orderTrackingId
 * - If orderTrackingId exists and there's multiple packages - DELIVERR_TRACKING_PORTAL_URL + orderTrackingId + "/tracking/" + trackingCode
 * - If for whatever reason orderTrackingId doesn't exist - DELIVERR_TRACKING_PORTAL_URL + trackingCode
 */
export const getBuyerPortalPackageTrackingUrl = (
  trackingCode: string,
  totalPackages: number,
  orderTrackingId?: string
): string => {
  return orderTrackingId
    ? getBuyerPortalOrderTrackingUrl(orderTrackingId) + (totalPackages > 1 ? "/tracking/" + trackingCode : "")
    : DELIVERR_TRACKING_PORTAL_URL + trackingCode;
};

export const getTrackingCodeUrl = (
  carrier: Carriers | string,
  trackingCode: string,
  orderTrackingId?: string,
  deliverrTrackingPortalCarriers = DELIVERR_TRACKING_PORTAL_CARRIERS
): string => {
  const carrierCode = carrier.toUpperCase() as Carriers;
  if (carrierCode === Carriers.FREIGHT) {
    return CARRIER_TRACKING_CODE_URLS[carrierCode];
  }

  const carrierTrackingCodeUrl: string = CARRIER_TRACKING_CODE_URLS[carrierCode];
  return orderTrackingId && deliverrTrackingPortalCarriers.includes(carrierCode)
    ? carrierTrackingCodeUrl + orderTrackingId
    : carrierTrackingCodeUrl + trackingCode;
};

export const getCarrier = (shippingMethod: string): Carriers => {
  const carrier = shippingMethod.toUpperCase().split(".");
  const carrierCode =
    carrier[0] === "DHL" ? (carrier[1] === "EXPRESS" ? Carriers.DHL_EXPRESS : Carriers.DHL) : carrier[0];

  return Carriers[carrierCode] || Carriers.OTHER;
};
