import { createSelector } from "reselect";
import { selectPlanItemsWithFefoEnabled } from "../plan/selectPlanItemsWithFefoEnabled";
import { selectPlanItemsWithLotTrackingEnabled } from "../plan/selectPlanItemsWithLotTrackingEnabled";
import { FeatureName, isFeatureOn } from "common/Split";

export const selectShowLotInformation = createSelector(
  selectPlanItemsWithFefoEnabled,
  selectPlanItemsWithLotTrackingEnabled,
  (fefoEnabledPlanItems, lotTrackingEnabledPlanItems) =>
    (fefoEnabledPlanItems.length > 0 || lotTrackingEnabledPlanItems.length > 0) &&
    isFeatureOn(FeatureName.FefoExpirationDate) &&
    !isFeatureOn(FeatureName.SkipAddingLotExpiry)
);
