import React from "react";
import { FormattedMessage } from "react-intl";

import { StepAccordionSpec } from "common/components/AccordionFlow/AccordionFlowTypes";
import { MissingBarcodeIdentify, MissingBarcodeSelect } from "./steps";

export enum MissingBarcodeSteps {
  IDENTIFY_PRODUCT = "IDENTIFY_PRODUCT",
  SELECT_BARCODE = "SELECT_BARCODE",
  REVIEW_CHARGES = "REVIEW_CHARGES",
}

export const MISSING_BARCODE_STEPS: StepAccordionSpec[] = [
  {
    id: MissingBarcodeSteps.IDENTIFY_PRODUCT,
    title: (
      <FormattedMessage id="nonCompliance.resolutionSteps.identifyProductTitle" defaultMessage="Identify Product" />
    ),
    content: <MissingBarcodeIdentify />,
  },
  {
    id: MissingBarcodeSteps.SELECT_BARCODE,
    title: <FormattedMessage id="nonCompliance.resolutionSteps.selectBarcodeTitle" defaultMessage="Select Barcode" />,
    content: <MissingBarcodeSelect />,
  },
];
