import React from "react";
import { CollapsibleSection } from "common/components/CollapsibleSection";
import { FormattedMessage } from "react-intl";
import { Box, Text } from "common/components/ui";
import cls from "inbounds/steps/ship/CollapsableShipmentAddressDisplay.less";
import { Timeline } from "common/components/Timeline/Timeline";
import { StorageInboundTimelineData, useStorageInboundStatusHistory } from "./useStorageInboundStatusHistory";
import { StorageInboundResponse } from "@deliverr/storage-client";

export const StorageInboundStatusHistory = ({
  storageInboundOrder,
}: {
  storageInboundOrder: StorageInboundResponse;
}) => {
  const { phases, calculateCurrentPhase, timelineData } = useStorageInboundStatusHistory({
    storageInbound: storageInboundOrder,
  });
  return (
    <CollapsibleSection
      classNameCollapsed={cls.shipmentDetailedButton}
      classNameExpanded={cls.shipmentDetails}
      collapsedContent={
        <Text bold>
          <FormattedMessage id="storage.inbound.statusHistory" defaultMessage="Inbound status history" />
        </Text>
      }
    >
      <Box marginLeft={"S7"}>
        <Timeline<StorageInboundTimelineData>
          phases={phases}
          vertical
          data={timelineData}
          calculateCurrentPhase={calculateCurrentPhase}
        />
      </Box>
    </CollapsibleSection>
  );
};
