import { PillColors } from "common/components/ui";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { ParcelListStatusType, ParcelPageItem } from "parcel/screens/list/types";

const statusMessages: Record<ParcelListStatusType, MessageDescriptor> = defineMessages({
  [ParcelListStatusType.CREATED]: {
    id: "parcel.list.status.CREATED",
    defaultMessage: "Created",
  },
  [ParcelListStatusType.DELIVERED]: {
    id: "parcel.list.status.DELIVERED",
    defaultMessage: "Delivered",
  },
  [ParcelListStatusType.IN_TRANSIT]: {
    id: "parcel.list.status.IN_TRANSIT",
    defaultMessage: "In Transit",
  },
  [ParcelListStatusType.RECEIVED]: {
    id: "parcel.list.status.RECEIVED",
    defaultMessage: "Received",
  },
  [ParcelListStatusType.RETURN_COMPLETED]: {
    id: "parcel.list.status.RETURN_COMPLETED",
    defaultMessage: "Return Completed",
  },
  [ParcelListStatusType.RETURN_INITIATED]: {
    id: "parcel.list.status.RETURN_INITIATED",
    defaultMessage: "Return Initiated",
  },
  [ParcelListStatusType.RETURN_IN_TRANSIT]: {
    id: "parcel.list.status.RETURN_IN_TRANSIT",
    defaultMessage: "Return In Transit",
  },
  [ParcelListStatusType.OUT_FOR_RETURN]: {
    id: "parcel.list.status.OUT_FOR_RETURN",
    defaultMessage: "Out for Return",
  },
});

export const useParcelListStatus = (parcel: ParcelPageItem) => {
  const { formatMessage } = useIntl();
  const { status } = parcel;

  switch (status) {
    case ParcelListStatusType.CREATED:
      return {
        status: formatMessage(statusMessages[ParcelListStatusType.CREATED]),
        color: PillColors.GRAY,
      };
    case ParcelListStatusType.IN_TRANSIT:
      return {
        status: formatMessage(statusMessages[ParcelListStatusType.IN_TRANSIT]),
        color: PillColors.BLUE,
      };
    case ParcelListStatusType.DELIVERED:
      return {
        status: formatMessage(statusMessages[ParcelListStatusType.DELIVERED]),
        color: PillColors.GREEN,
      };
    case ParcelListStatusType.RECEIVED:
      return {
        status: formatMessage(statusMessages[ParcelListStatusType.RECEIVED]),
        color: PillColors.PURPLE,
      };
    case ParcelListStatusType.RETURN_COMPLETED:
      return {
        status: formatMessage(statusMessages[ParcelListStatusType.RETURN_COMPLETED]),
        color: PillColors.GREEN,
      };
    case ParcelListStatusType.RETURN_INITIATED:
      return {
        status: formatMessage(statusMessages[ParcelListStatusType.RETURN_INITIATED]),
        color: PillColors.YELLOW,
      };
    case ParcelListStatusType.RETURN_IN_TRANSIT:
      return {
        status: formatMessage(statusMessages[ParcelListStatusType.RETURN_IN_TRANSIT]),
        color: PillColors.BLUE,
      };
    case ParcelListStatusType.OUT_FOR_RETURN:
      return {
        status: formatMessage(statusMessages[ParcelListStatusType.OUT_FOR_RETURN]),
        color: PillColors.PURPLE,
      };
    default:
      return {
        status: undefined,
        color: undefined,
      };
  }
};
