import { selectShowLotInformation } from "inbounds/store/selectors/steps/selectShowLotInformation";
import { getShowAssignCategories } from "inbounds/store/selectors/steps/getShowAssignCategories";
import { getShowPackagingRequirements } from "inbounds/store/selectors/steps/getShowPackagingRequirements";
import { getShowCustoms } from "inbounds/store/selectors/steps/getShowCustoms";
import { getInboundIsShipToOne, getShippingPlanExists } from "inbounds/steps/InboundStepSelectors";
import { isEmpty, mapValues, merge } from "lodash/fp";
import { getPlanItemsWithMissingDims } from "inbounds/store/selectors/plan/getPlanItemsWithMissingDims";
import { InboundStep } from "inbounds/InboundTypes";
import { RootState } from "RootReducer";
import { selectHasAllSpecifiedPrepTypes } from "inbounds/store/selectors/boxLabels/selectHasAllSpecifiedPrepTypes";
import { PrepType } from "@deliverr/prep-service-client";
import { selectIpbFeatureOn } from "inbounds/createShipment/store/selectors/selectIpbFeatureOn";
import { getShowDistributeSelect } from "inbounds/store/selectors/steps/getShowDistributeSelect";
import { getShowBarcodeInput } from "../../store/selectors/steps/getShowBarcodeInput";

const shouldShowInboundTypeStep = (state) => !selectIpbFeatureOn(state);
const shouldShowAddressStep = (state) => !selectIpbFeatureOn(state);
const shouldShowSelectProducts = (state) => !getShippingPlanExists(state);
const shouldShowShippingPlanProducts = (state) => getShippingPlanExists(state);
const getShouldShowLotInformation = (state) => selectShowLotInformation(state);
const getShouldShowAssignCategories = (state) => getShowAssignCategories(state);
const getShouldShowPackagingRequirements = (state) => getShowPackagingRequirements(state);
const getShouldShowCustoms = (state) => getShowCustoms(state);
const getShouldShowDimsCollection = (state) =>
  !getInboundIsShipToOne(state) && !isEmpty(getPlanItemsWithMissingDims(state));
const selectShouldShowBarcodeInput = (state: RootState) => getShowBarcodeInput(state);
const selectShouldShowBarcodePrint = (state: RootState) =>
  selectShouldShowBarcodeInput(state) && !selectHasAllSpecifiedPrepTypes(state)(PrepType.SKU_LABELS);
const selectShouldShowDistributeSelect = (state: RootState) => getShowDistributeSelect(state);

const defaultEvaluator = () => true;

const defaultStepMap = {
  ...(mapValues(() => defaultEvaluator, InboundStep) as Record<InboundStep, () => boolean>),
};

export const fulfillmentInboundEnabledStepMap = merge(defaultStepMap, {
  [InboundStep.SELECT_INBOUND_TYPE]: shouldShowInboundTypeStep,
  [InboundStep.FROM_ADDRESS]: shouldShowAddressStep,
  [InboundStep.SELECT_PRODUCTS]: shouldShowSelectProducts,
  [InboundStep.SHIPPING_PLAN_PRODUCTS]: shouldShowShippingPlanProducts,
  [InboundStep.LOT_INFORMATION]: getShouldShowLotInformation,
  [InboundStep.ASSIGN_CATEGORIES]: getShouldShowAssignCategories,
  [InboundStep.PACKAGING_REQUIREMENTS]: getShouldShowPackagingRequirements,
  [InboundStep.CUSTOMS]: getShouldShowCustoms,
  [InboundStep.ADD_DIMENSIONS]: getShouldShowDimsCollection,
  [InboundStep.BARCODE_INPUT]: selectShouldShowBarcodeInput,
  [InboundStep.BARCODE_PRINT]: selectShouldShowBarcodePrint,
  [InboundStep.DISTRIBUTE_SELECT]: selectShouldShowDistributeSelect,
});
