import { createActionCreator } from "common/ReduxUtils";
import { ProductChooserKittedProductResponse } from "./ProductChooserReducer";

export enum ProductChooserActionTypes {
  SAVE_SEARCHED_KITTED_PRODUCT_DETAILS = "SAVE_SEARCHED_KITTED_PRODUCT_DETAILS",
  RESET_SEARCHED_PRODUCT_DETAILS = "RESET_SEARCHED_PRODUCT_DETAILS",
}

export const saveSearchedKittedProductDetails = createActionCreator<string, ProductChooserKittedProductResponse>(
  ProductChooserActionTypes.SAVE_SEARCHED_KITTED_PRODUCT_DETAILS,
  "dsku",
  "kittedProductDetails"
);

export const resetSearchedProductDetails = createActionCreator(
  ProductChooserActionTypes.RESET_SEARCHED_PRODUCT_DETAILS
);
