import { FastTagPage } from "../FastTagPage";
import { StepCount } from "./StepCount";
import { UNKNOWN_COUNT } from "./UnknownCount";

export const linearStepCount = (pages: FastTagPage[]) => (page: FastTagPage): StepCount => {
  const currentPage = pages.findIndex(({ id }) => page.id === id) + 1;

  if (currentPage > 0) {
    const currentStep = pages.slice(0, currentPage).filter(({ type }) => type === "step").length;
    const remainingSteps = pages.slice(currentPage).filter(({ type }) => type === "step").length;
    return {
      currentStep,
      remainingSteps,
    };
  } else {
    return {
      currentStep: UNKNOWN_COUNT,
      remainingSteps: UNKNOWN_COUNT,
    };
  }
};
