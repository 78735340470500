import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import {
  StorageRecommendationsSellerConfig,
  StorageSellerChannelRecommendationConfig,
} from "./RSIPJit/StorageSellerRecommendationConfig";
import { RSIPResponseWrapper } from "./RSIPResponseWrapper";
import { Optional } from "utility-types";
import { DistributionChannel } from "./models/DistributionChannel";

export type RSIPClientConfig = ApiClientConfig;

const defaultHeaders = { "X-Request-Caller": "seller-portal" };

export class RSIPClient {
  private apiClient: ApiClient;

  constructor(
    { baseURL, headers }: RSIPClientConfig = {
      baseURL: mustBeDefined(process.env.RSIP_SERVICE_URL),
      headers: defaultHeaders,
    }
  ) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}`,
      headers,
    });
  }

  public async getSellerRecommendationConfig(
    sellerId: string,
    distributionChannel?: DistributionChannel
  ): Promise<RSIPResponseWrapper<StorageSellerChannelRecommendationConfig>> {
    return await this.apiClient.get({
      url: `v2/rsip/seller/config/${sellerId}?distributionChannel=${distributionChannel}`,
      authentication: isAuthenticated,
    });
  }

  public async createOrUpdateSellerRecommendationConfig(
    sellerId: string,
    config: Optional<StorageSellerChannelRecommendationConfig, "shouldAutoConfirmTransfer">
  ): Promise<RSIPResponseWrapper<StorageSellerChannelRecommendationConfig>> {
    return await this.apiClient.put({
      url: `v2/rsip/seller/config/${sellerId}`,
      body: config,
      authentication: isAuthenticated,
    });
  }

  public async getStorageRecommendationSellerConfig(
    sellerId: string
  ): Promise<RSIPResponseWrapper<StorageRecommendationsSellerConfig>> {
    return await this.apiClient.get({
      url: `v1/rsip/seller/config/storage/${sellerId}`,
      authentication: isAuthenticated,
    });
  }

  public async createOrUpdateStorageRecommendationSellerConfig(
    sellerId: string,
    updateConfig: StorageRecommendationsSellerConfig
  ): Promise<RSIPResponseWrapper<StorageRecommendationsSellerConfig>> {
    return await this.apiClient.put({
      url: `v1/rsip/seller/config/storage/${sellerId}`,
      body: updateConfig,
      authentication: isAuthenticated,
    });
  }
}
