import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Grid, Button, Input, Modal, ThemeProps, Stack, Text, Title, Box } from "common/components/ui";
import { BoxDimension, BoxDimensions } from "common/components/EstimatedCosts/BoxDimensions";
import styled from "@emotion/styled";
import { useUpdateDimensionsModal } from "./useUpdateDimensionsModal";
import { ExternalLink } from "common/components/ExternalLink";
import { getHelpCenterArticleUrl } from "common/support/helpCenter";

interface UpdateDimensionsModalProps {
  dsku: string;
  productName: string;
  dimensions: Partial<BoxDimensions>;
  updateProductDims: (dsku: string, dims: BoxDimensions) => void;
  showUpdateDimensionsModal: boolean;
  setShowUpdateDimensionsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const DimensionsInput = styled(Input)<ThemeProps>(
  ({ theme }) => `
    width: 48px;
    text-align: center;
    padding-right: ${theme.spacing.S3};
    -moz-appearance: textfield;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `
);

const VerticallyCenteredStack = styled(Stack)`
  align-items: center;
`;

const CenteredTitle = styled(Title)`
  text-align: center;
`;

export const UpdateDimensionsModal: FC<UpdateDimensionsModalProps> = ({
  dsku,
  productName,
  dimensions,
  updateProductDims,
  showUpdateDimensionsModal,
  setShowUpdateDimensionsModal,
}) => {
  const { isLoading, saveUpdateDimensionsModal, closeUpdateDimensionsModal, onInputDimensionsChange } =
    useUpdateDimensionsModal(dsku, updateProductDims, setShowUpdateDimensionsModal);
  return (
    <Modal show={showUpdateDimensionsModal} hasCloseButton={true} onClose={closeUpdateDimensionsModal}>
      <Stack gap="S5">
        <CenteredTitle as="h4" displayAs="h4">
          <FormattedMessage id="inventory.updateDimensionsModal.title" defaultMessage="Add dimensions and weight" />
        </CenteredTitle>
        <div>
          <Text>
            <FormattedMessage
              id="inventory.updateDimensionsModal.description"
              defaultMessage="Add dimensions and weights for {productName} to estimate the fulfillment fee. Learn more about <a>product measurements</a>."
              values={{
                productName: (
                  <Text as="span" bold>
                    {productName}
                  </Text>
                ),
                a: (text) => (
                  <ExternalLink href={getHelpCenterArticleUrl("115003488233-Product-Dimensional-Requirements")}>
                    <Text as="span" bold>
                      {text}
                    </Text>
                  </ExternalLink>
                ),
              }}
            />
          </Text>
        </div>
        <div>
          <Stack direction="HORIZONTAL" gap="S5">
            <div>
              <Stack gap="S2">
                <div>
                  <Text>
                    <FormattedMessage
                      id="inventory.updateDimensionsModal.dimensions"
                      defaultMessage="Dimensions (in)"
                    />
                  </Text>
                </div>
                <div>
                  <VerticallyCenteredStack direction="HORIZONTAL" gap="S2">
                    <DimensionsInput
                      type="number"
                      placeholder="L"
                      defaultValue={dimensions[BoxDimension.Length]?.toString() ?? ""}
                      onChange={(e) => onInputDimensionsChange({ [BoxDimension.Length]: parseFloat(e.target.value) })}
                    />
                    <div>x</div>
                    <DimensionsInput
                      type="number"
                      placeholder="W"
                      defaultValue={dimensions[BoxDimension.Width]?.toString() ?? ""}
                      onChange={(e) => onInputDimensionsChange({ [BoxDimension.Width]: parseFloat(e.target.value) })}
                    />
                    <div>x</div>
                    <DimensionsInput
                      type="number"
                      placeholder="H"
                      defaultValue={dimensions[BoxDimension.Height]?.toString() ?? ""}
                      onChange={(e) => onInputDimensionsChange({ [BoxDimension.Height]: parseFloat(e.target.value) })}
                    />
                  </VerticallyCenteredStack>
                </div>
              </Stack>
            </div>
            <div>
              <Stack gap="S2">
                <div>
                  <Text>
                    <FormattedMessage id="inventory.updateDimensionsModal.weight" defaultMessage="Weight (lbs)" />
                  </Text>
                </div>
                <DimensionsInput
                  type="number"
                  placeholder="lb"
                  defaultValue={dimensions[BoxDimension.Weight]?.toString()}
                  onChange={(e) => onInputDimensionsChange({ [BoxDimension.Weight]: parseFloat(e.target.value) })}
                />
              </Stack>
            </div>
          </Stack>
        </div>
        <Box paddingTop="S4">
          <Grid columns="1fr 1fr">
            <Button secondary onClick={closeUpdateDimensionsModal}>
              <FormattedMessage id="inventory.bundle.updateDimensionsModal.cancel" defaultMessage="Cancel" />
            </Button>
            <Button loading={isLoading} onClick={saveUpdateDimensionsModal}>
              <FormattedMessage id="inventory.bundle.updateDimensionsModal.save" defaultMessage="Save" />
            </Button>
          </Grid>
        </Box>
      </Stack>
    </Modal>
  );
};
