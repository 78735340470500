import produce from "immer";
import { AnyAction, Reducer } from "redux";
import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { BillingActionTypes, CREDITS_PER_PAGE } from "./BillingActions";
import { BillingSummary } from "common/clients/billing/BillingSummary";
import { CustomerInvoiceHistory } from "common/clients/billing/CustomerInvoiceHistory/CustomerInvoiceHistory";
import { Credit } from "@deliverr/billing-client";
import { PaymentSource } from "common/clients/billing/PaymentSource";
import { ContraRevenueInvoiceHistory } from "common/clients/billing/Credit/ContraRevenueInvoiceHistory";

export interface BillingReducerState {
  session?: any;
  summary: {
    loading: boolean;
    error: boolean;
    loaded: boolean;
    data: BillingSummary;
  };
  invoices: {
    loading: boolean;
    error: boolean;
    data: CustomerInvoiceHistory[];
    currentPage: number;
  };
  credits: {
    nextPage: number;
    hasNextPage: boolean;
    loading: boolean;
    error: boolean;
    data: Credit[];
  };
  primaryPaymentSource: {
    loading: boolean;
    error: boolean;
    loaded: boolean;
    data: PaymentSource;
  };
  contraRevenue: {
    loading: boolean;
    error: boolean;
    loaded: boolean;
    data: ContraRevenueInvoiceHistory;
  };
}

export const billingInitialState: BillingReducerState = {
  summary: {
    loading: false,
    error: false,
    loaded: false,
    data: {
      dueNow: 0,
      creditBalance: 0,
      hasCreditHistory: false,
      nextInvoiceDate: undefined,
      totalInvoiceCount: 0,
      unpaidInvoiceCount: 0,
      sellerId: "",
    },
  },
  invoices: {
    loading: false,
    error: false,
    data: [],
    currentPage: 0,
  },
  credits: {
    nextPage: 0,
    hasNextPage: true,
    loading: false,
    error: false,
    data: [],
  },
  primaryPaymentSource: {
    loading: false,
    error: false,
    loaded: false,
    data: {},
  },
  contraRevenue: {
    loading: false,
    error: false,
    loaded: false,
    data: {
      creditNotesAppliedToInvoice: [],
      creditsAppliedToInvoice: [],
    },
  },
};

const reducers: ReducerDictionary<BillingReducerState> = {
  ...handleSimpleReducerUpdates(["SET_CHARGEBEE_SESSION"]),
  [BillingActionTypes.FETCH_BILLING_SUMMARY_START]: (state) =>
    produce(state, (draft) => {
      draft.summary.loading = true;
    }),
  [BillingActionTypes.FETCH_BILLING_SUMMARY_SUCCESS]: (state, { payload }) =>
    produce(state, (draft) => {
      draft.summary.loading = false;
      draft.summary.error = false;
      draft.summary.loaded = true;
      draft.summary.data = payload;
    }),
  [BillingActionTypes.FETCH_BILLING_SUMMARY_ERROR]: (state) =>
    produce(state, (draft) => {
      draft.summary.loading = false;
      draft.summary.error = true;
    }),
  [BillingActionTypes.FETCH_INVOICES_START]: (state) =>
    produce(state, (draft) => {
      draft.invoices.loading = true;
    }),
  [BillingActionTypes.FETCH_INVOICES_SUCCESS]: (state, { payload: { invoices, page } }) =>
    produce(state, (draft) => {
      draft.invoices.loading = false;
      draft.invoices.error = false;
      draft.invoices.data = invoices;
      draft.invoices.currentPage = page;
    }),
  [BillingActionTypes.FETCH_INVOICES_ERROR]: (state) =>
    produce(state, (draft) => {
      draft.invoices.loading = false;
      draft.invoices.error = true;
    }),
  [BillingActionTypes.FETCH_CREDITS_START]: (state) =>
    produce(state, (draft) => {
      draft.credits.loading = true;
    }),
  [BillingActionTypes.FETCH_CREDITS_SUCCESS]: (state, { payload }) =>
    produce(state, (draft) => {
      const credits = payload as Credit[];

      draft.credits.data.push(...credits);
      draft.credits.nextPage += 1;
      draft.credits.hasNextPage = credits.length === CREDITS_PER_PAGE;
      draft.credits.loading = false;
      draft.credits.error = false;
    }),
  [BillingActionTypes.FETCH_CREDITS_ERROR]: (state) =>
    produce(state, (draft) => {
      draft.credits.loading = false;
      draft.credits.error = true;
    }),
  [BillingActionTypes.RESET_BILLING]: () => billingInitialState,
  [BillingActionTypes.FETCH_PRIMARY_PAYMENT_SOURCE_START]: (state) =>
    produce(state, (draft) => {
      draft.primaryPaymentSource.loading = true;
    }),
  [BillingActionTypes.FETCH_PRIMARY_PAYMENT_SOURCE_SUCCESS]: (state, { payload }) =>
    produce(state, (draft) => {
      draft.primaryPaymentSource.loading = false;
      draft.primaryPaymentSource.error = false;
      draft.primaryPaymentSource.loaded = true;
      draft.primaryPaymentSource.data = payload;
    }),
  [BillingActionTypes.FETCH_PRIMARY_PAYMENT_SOURCE_ERROR]: (state) =>
    produce(state, (draft) => {
      draft.primaryPaymentSource.loading = false;
      draft.primaryPaymentSource.error = true;
    }),
  [BillingActionTypes.FETCH_CONTRA_REVENUE_START]: (state) =>
    produce(state, (draft) => {
      draft.contraRevenue.loading = true;
      draft.contraRevenue.data = {
        creditsAppliedToInvoice: [],
        creditNotesAppliedToInvoice: [],
      };
    }),
  [BillingActionTypes.FETCH_CONTRA_REVENUE_SUCCESS]: (state, { payload }) =>
    produce(state, (draft) => {
      draft.contraRevenue.loading = false;
      draft.contraRevenue.error = false;
      draft.contraRevenue.loaded = true;
      draft.contraRevenue.data = payload;
    }),
  [BillingActionTypes.FETCH_CONTRA_REVENUE_ERROR]: (state) =>
    produce(state, (draft) => {
      draft.contraRevenue.loading = false;
      draft.contraRevenue.error = true;
    }),
};

export const billingReducer: Reducer<BillingReducerState, AnyAction> = createReducer<BillingReducerState>(
  billingInitialState,
  reducers
);
