import { isNil } from "lodash";
import { useParams } from "react-router-dom";
import { MappedSkuPair } from "common/components/ProblemsTable/types";
import RouteParser from "common/RouteParser";
import { useFetchProduct } from "inbounds/hooks";
import { nonComplianceInternalRoutes } from "inbounds/non-compliance/NonComplianceRoutes";
import { Path } from "paths";
import { UnexpectedBarcodeNonComplianceRow } from "../../types";
import { usePreventScrollToTopNavigation } from "common/hooks/usePreventScrollToTopNavigation";

export const useUnexpectedBarcodeNonComplianceBlade = (nonComplianceCase: UnexpectedBarcodeNonComplianceRow) => {
  const navigate = usePreventScrollToTopNavigation();
  const { nonCompliantId } = useParams<{ nonComplianceType?: string; nonCompliantId?: string }>();

  const { shippingPlanId, shippingPlanName, mappedSkus } = nonComplianceCase;

  const { value: product } = useFetchProduct({ dsku: mappedSkus[0]?.dsku });

  const skuPair: MappedSkuPair | undefined = product && {
    dsku: product.dsku,
    msku: product.msku,
    productName: product.name,
  };

  const onShippingPlanClick = () => {
    window.open(new RouteParser(Path.inboundsShippingPlanRedirect).parse({ shippingPlanId }), "_blank");
  };

  const resetBladeState = () => {
    if (!isNil(nonCompliantId) && nonCompliantId.length > 0) {
      navigate(nonComplianceInternalRoutes.summary);
    }
  };

  return {
    onShippingPlanClick,
    resetBladeState,
    shippingPlanId,
    shippingPlanName,
    skuPair,
  };
};
