import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { CellProps, Column } from "react-table";
import { MissingBoxesAlertSummary } from "@deliverr/commons-clients";
import { PackageItemDskuDetails } from "@deliverr/legacy-inbound-client";

import { getLoadedShipmentItemsByDsku } from "inbounds/store/selectors/shipments";
import { SHORT_SHIP_DSKUS_TABLE_LABELS } from "./labels";
import { SKULink } from "common/components/ProblemBlade/components/SKULink";

export interface ShortShipDskusColumn extends Record<string, unknown> {
  dskuDetail: PackageItemDskuDetails;
  arrivedQty: string;
  discrepancyQty: number;
}

export const useShortShipDskusTable = ({ summary }: { summary: MissingBoxesAlertSummary }) => {
  const { formatNumber, formatMessage } = useIntl();

  const shipmentItemsByDsku = useSelector(getLoadedShipmentItemsByDsku);

  const FractionCell = ({ value }: CellProps<ShortShipDskusColumn>) =>
    formatMessage({ id: "inbounds.shipmentProblems.shortShipBlade.dskusTable.arrivedQtyValue", defaultMessage: value });
  const NumberCell = ({ value }: CellProps<ShortShipDskusColumn>) => formatNumber(value);

  const columns: Column<ShortShipDskusColumn>[] = [
    {
      id: "dskuDetail",
      accessor: "dskuDetail",
      Header: formatMessage(SHORT_SHIP_DSKUS_TABLE_LABELS.dskuDetail),
      Cell: ({ value }) => <SKULink dsku={value.dsku}>{value.msku}</SKULink>,
    },
    {
      id: "arrivedQty",
      accessor: "arrivedQty",
      Header: formatMessage(SHORT_SHIP_DSKUS_TABLE_LABELS.arrivedQty),
      Cell: FractionCell,
    },
    {
      id: "discrepancyQty",
      accessor: "discrepancyQty",
      Header: formatMessage(SHORT_SHIP_DSKUS_TABLE_LABELS.discrepancyQty),
      Cell: NumberCell,
    },
  ];

  const data: ShortShipDskusColumn[] = Object.values(summary.productSummary).map((dskuDetail) => {
    const expectedQty = shipmentItemsByDsku[dskuDetail.dsku]?.qty;
    const missingQty = dskuDetail.qty;

    return {
      dskuDetail,
      arrivedQty: `${expectedQty - missingQty}/${expectedQty}`,
      discrepancyQty: -missingQty,
    };
  });

  return {
    data,
    columns,
  };
};
