import React from "react";
import { ShipmentResponse, ShippingType } from "@deliverr/replenishment-client";
import { shippingPartnerMessage } from "./shippingPartnerMessage.labels";
import { FormattedMessage, useIntl } from "react-intl";
import { shipmentSummaryLabels } from "transfers/detail/shipmentSummary.labels";
import { Text } from "common/components/ui";

interface ShippingMethodAndTypeDetailProps {
  shipment: ShipmentResponse;
  useShortDescription?: boolean;
  bold?: boolean;
}

export const ShippingMethodAndTypeDetail: React.FC<ShippingMethodAndTypeDetailProps> = ({
  shipment,
  useShortDescription,
  bold,
}) => {
  const { formatMessage } = useIntl();

  const shippingMethod = formatMessage({
    ...(shippingPartnerMessage[shipment?.shippingPartner ?? "UNKNOWN"] ?? shipmentSummaryLabels.methodUnknown),
  });
  const shipmentType =
    shipment?.shippingType === ShippingType.PARCEL
      ? formatMessage({ ...shipmentSummaryLabels.shipmentTypeParcel })
      : formatMessage({ ...shipmentSummaryLabels.shipmentTypePalletized });

  return (
    <Text bold={bold}>
      <FormattedMessage
        {...(useShortDescription
          ? shipmentSummaryLabels.shippingMethodShortDescription
          : shipmentSummaryLabels.shippingMethod)}
        values={{ shippingMethod, shipmentType }}
      />
    </Text>
  );
};
