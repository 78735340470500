export enum Marketplace {
  DELIVERR = "DELIVERR",
  AMAZON = "AMAZON",
  GOOGLE = "GOOGLE",
  SHOPIFY = "SHOPIFY",
  WALMART = "WALMART",
  WISH = "WISH",
  EBAY = "EBAY",
  FACEBOOK = "FACEBOOK",
  BIGCOMMERCE = "BIGCOMMERCE",
  WOOCOMMERCE = "WOOCOMMERCE",
  ETSY = "ETSY",
  MAGENTO = "MAGENTO",
  TARGET = "TARGET",
  NEWEGG = "NEWEGG",
  INSTAGRAM = "INSTAGRAM",
  ARTEZA = "ARTEZA",
  OVERSTOCK = "OVERSTOCK",
  JCPENNEY = "JCPENNEY",
  JANE = "JANE",
  AIRSHOP = "AIRSHOP",
  KROGER = "KROGER",
  SKUPREME = "SKUPREME",
  SHEIN = "SHEIN",
  UNKNOWN = "UNKNOWN",
}
