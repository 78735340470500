import React, { FC } from "react";
import { ProblemRowData } from "common/components/ProblemsTable/types";
import { GenericIssueBlade } from "common/components/ProblemBlade/GenericIssueBlade";
import { SHIPMENT_PROBLEMS_GENERIC_ISSUE_BLADE_ID } from "../blades";
import { SelectedShipmentProblem } from "../../context";

export interface ShipmentProblemGenericIssueBladeProps {
  shipmentProblem: ProblemRowData;
  hideShipmentProblemBlade: () => void;
  setSelectedProblem: (problem: SelectedShipmentProblem | undefined) => void;
  isShipmentProblemBladeOpen: boolean;
}

export const ShipmentProblemGenericIssueBlade: FC<ShipmentProblemGenericIssueBladeProps> = ({
  shipmentProblem,
  hideShipmentProblemBlade,
  setSelectedProblem,
  isShipmentProblemBladeOpen,
}) => {
  const resetBladeState = () => {
    hideShipmentProblemBlade();
    setSelectedProblem(undefined);
  };

  return (
    <GenericIssueBlade
      id={SHIPMENT_PROBLEMS_GENERIC_ISSUE_BLADE_ID}
      problem={shipmentProblem}
      onCloseClick={resetBladeState}
      isBladeOpen={isShipmentProblemBladeOpen}
    />
  );
};
