import React from "react";
import styled from "@emotion/styled";
import { ThemeProps, Text } from "common/components/ui";
import { FormattedMessage, MessageDescriptor, defineMessages } from "react-intl";
import { InvalidOrderItemDto } from "@deliverr/replenishment-client";
import { entries, isEmpty } from "lodash";
import { invalidOrderItemLabel } from "transfers/create/steps/common/replenishment/details/invalid-items/invalidOrderItemLabel";

const invalidItemDetailsMessages: Record<keyof InvalidOrderItemDto["identifier"], MessageDescriptor> = defineMessages({
  buyerPartNumber: {
    id: "replenishment.sps.item.details.buyerPartNumber",
    defaultMessage: "Buyer Part Number: {buyerPartNumber}",
  },
  vendorPartNumber: {
    id: "replenishment.sps.item.details.vendorPartNumber",
    defaultMessage: "Vendor Part Number: {vendorPartNumber}",
  },
  consumerPackageCode: {
    id: "replenishment.sps.item.details.consumerPackageCode",
    defaultMessage: "Consumer Package Code: {consumerPackageCode}",
  },
  gtin: {
    id: "replenishment.sps.item.details.gtin",
    defaultMessage: "GTIN: {gtin}",
  },
  upcCaseCode: {
    id: "replenishment.sps.item.details.upcCaseCode",
    defaultMessage: "UPC Case Code: {upcCaseCode}",
  },
  modelNumber: {
    id: "replenishment.sps.item.details.modelNumber",
    defaultMessage: "Model Number: {modelNumber}",
  },
  lineSequenceNumber: {
    id: "replenishment.sps.item.details.lineSequenceNumber",
    defaultMessage: "Line Sequence Number: {lineSequenceNumber}",
  },
});

const SummaryContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    min-width: 30%;
    padding: ${theme.spacing.S1} ${theme.spacing.S1};
    font-weight: ${theme.font.weight.REGULAR};
    color: ${theme.colors.NEUTRAL[500]};
    font-family: ${theme.font.family.STANDARD};
    font-size: ${theme.font.size.F2};
    line-height: ${theme.font.lineHeight.LH3};
  `
);

const LightText = styled.span<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
`
);

const StyledTitle = styled(Text)<ThemeProps>(
  ({ theme }) => `
    font-weight: ${theme.font.weight.BOLD};
    font-family: ${theme.font.family.STANDARD};
    font-size: ${theme.font.size.F3};
    padding-bottom: ${theme.spacing.S2};
  `
);

interface ReplenishmentInvalidItemDetailsProps {
  invalidItem: InvalidOrderItemDto;
}

export const ReplenishmentInvalidItemDetails: React.FC<ReplenishmentInvalidItemDetailsProps> = ({ invalidItem }) => {
  return (
    <SummaryContainer data-testid="replenishment-invalid-item-summary">
      <StyledTitle>
        <FormattedMessage id="replenishment.invalid.item.title" defaultMessage="Item Details" />
      </StyledTitle>

      {invalidItem.requestedPackOf && (
        <Text bold>
          <FormattedMessage
            {...invalidOrderItemLabel.blade.requestedUnitsPerPack}
            values={{
              requestedUnitsPerPack: <LightText>{invalidItem.requestedUnitsPerPack}</LightText>,
            }}
          />
        </Text>
      )}

      {invalidItem.requestedTotalUnits && (
        <Text bold>
          <FormattedMessage
            {...invalidOrderItemLabel.blade.requestedTotalUnits}
            values={{
              requestedTotalUnits: <LightText>{invalidItem.requestedTotalUnits}</LightText>,
            }}
          />
        </Text>
      )}

      {entries(invalidItem.identifier).map(([key, value]) => {
        if (!isEmpty(value)) {
          return (
            <div key={key}>
              <FormattedMessage
                {...invalidItemDetailsMessages[key]}
                values={{ [key]: <LightText>{value}</LightText> }}
              />
            </div>
          );
        }
        return null;
      })}
    </SummaryContainer>
  );
};
