import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { WarningAlert } from "common/components/DismissableAlert";
import { Card } from "common/components/Card";
import Content from "common/components/Content";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";
import { InboundFooter } from "inbounds/steps/InboundFooter";
import { useFromAddressStep } from "./useFromAddressStep";

import cls from "./FromAddress.less";
import { DeliverrAddress } from "@deliverr/commons-objects";
import { InboundShipment } from "@deliverr/legacy-inbound-client";
import { ById } from "common/ById";
import { InboundAddressForm } from "./InboundAddressForm";

export interface FromAddressRendererProps {
  addresses: DeliverrAddress[];
  shipments?: ById<InboundShipment>;
  isSelectLoading: boolean;
  fromAddress: DeliverrAddress;
  onNextClick: () => void;
  handleAddressSelectChange: ({ value }) => void;
  handleAddressInputChange: (address: DeliverrAddress) => void;
  onPreviousClick?: () => void;
  titleOverride?: JSX.Element;
  bodyOverride?: JSX.Element;
  addressFooter?: JSX.Element;
}

/**
 * @deprecated - will be replaced by OriginSearchStep in createShipment, new IPB flow
 */
export const FromAddressRenderer: FC<FromAddressRendererProps> = ({
  addresses,
  shipments,
  isSelectLoading,
  fromAddress,
  onNextClick,
  handleAddressSelectChange,
  handleAddressInputChange,
  onPreviousClick,
  titleOverride,
  bodyOverride,
  addressFooter,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Card className={cls.card}>
      <Content
        title={
          titleOverride ?? (
            <div className={cls.titleContainer}>
              <FormattedMessage id="inbounds.fromAddress.title" defaultMessage="Select origin" />
            </div>
          )
        }
        body={
          bodyOverride ?? (
            <div className={cls.bodyContainer}>
              <p>
                <FormattedMessage
                  id={"inbounds.fromAddress.body"}
                  defaultMessage={
                    "Enter the address where you'll be shipping your inventory from. " +
                    "You can select a previously used address or enter a new one."
                  }
                />
              </p>
              <p>
                <FormattedMessage
                  id="inbounds.fromAddress.bodyCommonErrors"
                  defaultMessage={"Learn how to {link}."}
                  values={{
                    link: (
                      <ElevioBorderlessButton articleId={ElevioArticleId.ShipFromMistakes}>
                        <FormattedMessage
                          id="inbounds.fromAddress.avoidCommonMistakesLink"
                          defaultMessage="avoid common ship-from address mistakes"
                        />
                      </ElevioBorderlessButton>
                    ),
                  }}
                />
              </p>
            </div>
          )
        }
        after={
          <div className={cls.addressContainer}>
            <InboundAddressForm
              fromAddress={fromAddress}
              handleAddressInputChange={handleAddressInputChange}
              handleAddressSelectChange={handleAddressSelectChange}
              addresses={addresses}
              isSelectLoading={isSelectLoading}
            />
            {addressFooter}
          </div>
        }
      />

      <div className={cls.warningContainer}>
        {shipments && shipments?.ids?.length > 0 && (
          <WarningAlert
            title={formatMessage({ id: "inbounds.fromAddress.warning.title", defaultMessage: "Warning" })}
            description={formatMessage({
              id: "inbounds.fromAddress.warning.description",
              defaultMessage: "Major address modifications will result in a new shipping plan.",
            })}
          />
        )}
      </div>
      <InboundFooter onButtonClick={onNextClick} onPreviousButtonClick={onPreviousClick} manualLoader />
    </Card>
  );
};

export const FromAddress: FC = () => {
  const props = useFromAddressStep();

  return <FromAddressRenderer {...props} />;
};
