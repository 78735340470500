import React from "react";
import { transfersShipmentCreateLabels } from "../../../transfers/create/transfersShipmentCreate.labels";
import { useIntl } from "react-intl";
import { ManagedStep } from "common/components/StepContainer";
import { Box } from "common/components/ui";
import { AddAmazonProductBarcodesTable } from "./AddAmazonProductBarcodesTable";
import { useTransferAddAmazonBarcodesForUnitPrep } from "./useTransferAddAmazonBarcodesForUnitPrep";
import DismissableAlert from "common/components/DismissableAlert/DismissableAlert";

export const TransferAddAmazonBarcodesForUnitPrep = () => {
  const { formatMessage } = useIntl();
  const {
    isNextDisabled,
    isNextLoading,
    products,
    barcodeMap,
    onBarcodeValueChange,
    submitBarcodeAndValidateNextClick,
  } = useTransferAddAmazonBarcodesForUnitPrep();

  return (
    <ManagedStep
      data-testid="transfer-add-amazon-barcodes-for-unit-prep"
      title={formatMessage(transfersShipmentCreateLabels.steps.addAmazonBarcodeUnitPrep.title)}
      subtitle={formatMessage(transfersShipmentCreateLabels.steps.addAmazonBarcodeUnitPrep.subtitle)}
      isNextDisabled={isNextDisabled}
      isLoading={isNextLoading}
      validate={submitBarcodeAndValidateNextClick}
    >
      <Box width="85%">
        <Box marginBottom={"S5"}>
          <DismissableAlert
            alertType="WARNING"
            description={formatMessage(transfersShipmentCreateLabels.steps.addAmazonBarcodeUnitPrep.infoMessage)}
          />
        </Box>
        <AddAmazonProductBarcodesTable
          products={products}
          barcodeMap={barcodeMap}
          onBarcodeValueChange={onBarcodeValueChange}
        />
      </Box>
    </ManagedStep>
  );
};
