import { LoadingSpinner } from "common/components/LoadingSpinner";
import React, { FC } from "react";
import { ConnectedRmtCard } from "./components/ConnectedRmt/ConnectedRmtCard";
import { ManageRmtCard } from "./components/ManageRmt/ManageRmtCard";
import { SetupRmtCard } from "./components/SetupRmt/SetupRmtCard";
import { RmtStepId } from "./store/RmtReducer";
import { useReturnsRmtContainer } from "./useReturnsRmtContainer";

export const ReturnsRmtContainer: FC = () => {
  const { sellerRmts, stepId, rmt } = useReturnsRmtContainer();

  if (!sellerRmts.loaded) {
    return <LoadingSpinner center />;
  }

  switch (stepId) {
    case RmtStepId.CONNECTED_RMT:
      return <ConnectedRmtCard rmt={rmt!} />;
    case RmtStepId.MANAGE_RMT:
      return <ManageRmtCard rmt={rmt!} />;
    case RmtStepId.SETUP_RMT:
      return <SetupRmtCard rmt={rmt} />;
    default:
      return <></>;
  }
};
