import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import { Product } from "@deliverr/commons-clients";
import { ReturnItemMinimal } from "@deliverr/returns-client";
import ReturnOrderItem from "./ReturnOrderItem";
import { ReturnsQcItem } from "./store/ReturnsDetailStore.types";
import cls from "./ReturnsDetail.less";

interface ReturnsItemDetailsProps {
  returnsItems: ReturnItemMinimal[];
  returnsQcItems: ReturnsQcItem[];
  returnItemDetails: { [dsku: string]: Product };
  areQcItemsPresent: boolean;
}

export const ReturnsItemDetails: FC<ReturnsItemDetailsProps> = ({
  returnsQcItems,
  returnsItems,
  returnItemDetails,
  areQcItemsPresent,
}) => {
  return (
    <div className={cls.cardTable}>
      <table>
        <thead>
          <tr>
            <th className={cls.colW2}>
              <FormattedMessage id={"returns.returnsDetail.productName"} defaultMessage={"Product Name"} />
            </th>
            <th className={cls.colNumeric}>
              <FormattedMessage id={"returns.returnsDetail.returnsItemQuantity"} defaultMessage={"Quantity"} />
            </th>
            {returnsQcItems.length > 0 && (
              <th>
                <FormattedMessage
                  id={"returns.returnsDetail.returnsItemQcStatus"}
                  defaultMessage={"Inspection Status"}
                />
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {returnsQcItems.length === 0
            ? returnsItems.map((item, i) => (
                <ReturnOrderItem
                  item={{
                    areQcItemsPresent,
                    ...returnItemDetails[item.dsku],
                    quantity: item.quantity,
                    dsku: item.dsku,
                  }}
                  key={i}
                />
              ))
            : returnsQcItems?.map((item, i) => (
                <ReturnOrderItem
                  item={{
                    areQcItemsPresent: returnsQcItems.length > 0,
                    ...returnItemDetails[item.dsku],
                    quantity: item.quantity,
                    status: item.status,
                    isUnexpected: item.isUnexpected,
                    ...item,
                  }}
                  key={`${item.dsku}-${i}`}
                />
              ))}
        </tbody>
      </table>
    </div>
  );
};
