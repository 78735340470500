import { DefaultTheme } from "common/components/ui";
import { Blade } from "common/components/ui/Blade";
import { Title } from "common/components/ui/Title";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Virtuoso } from "react-virtuoso";
import { NotificationDetail } from "../NotificationDetail/NotificationDetail";
import { NotificationsSpinner } from "../NotificationsSpinner";
import { useNotificationsBlade } from "./useNotificationsBlade";
import { NotificationHeader } from "../NotificationHeader/NotificationHeader";

const BladeTitle = styled(Title)<{}, DefaultTheme>(
  ({ theme }) => `
    margin: 0;
    letter-spacing: ${theme.font.letterSpacing.LS1};
  `
);

export interface NotificationsProps {
  isOpen: boolean;
  onClose: () => void;
}

export const NotificationsBlade: FC<NotificationsProps> = ({ isOpen, onClose }) => {
  const { notifications, isError, isSuccess, fetchNotifications } = useNotificationsBlade();

  return (
    <Blade
      headerContent={
        <BladeTitle as="h3" displayAs="h4">
          <FormattedMessage id="notifications.title" defaultMessage="Notifications" />
        </BladeTitle>
      }
      trigger={isOpen}
      onClose={onClose}
    >
      <Virtuoso
        data={notifications}
        endReached={fetchNotifications}
        atBottomStateChange={(isReached) => {
          if (isReached && isError) {
            fetchNotifications();
          }
        }}
        itemContent={(_, notification) => (
          <NotificationDetail notification={notification} onCallToActionClick={onClose} />
        )}
        components={{
          Header: NotificationHeader,
          Footer: () => (isSuccess ? null : <NotificationsSpinner />),
        }}
      />
    </Blade>
  );
};
