import {
  IngestionChannel,
  InvalidOrderItemReason,
  InvalidOrderItemResolution,
  OrderResponse,
} from "@deliverr/replenishment-client";
import { DskuQty } from "common/clients/nonCompliance/BoxLabelIncidentDsku/DskuQty";
import { every, filter, isEmpty, map } from "lodash";
import { find } from "lodash/fp";

export const getSkuQtiesForInvalidItemOrder = (replenishmentOrder: OrderResponse): DskuQty[] => {
  const orderItemSkuQties: DskuQty[] = map(replenishmentOrder?.orderItems, (item) => ({
    dsku: item.dsku,
    qty: item.totalUnits,
  }));

  const invalidOrderItemSkuQties: DskuQty[] = filter(
    replenishmentOrder?.invalidOrderItems,
    (item) => item.resolution === InvalidOrderItemResolution.MODIFIED
  ).map((item) => ({
    dsku: item.resolvedDsku!,
    qty: item.resolvedTotalUnits!,
  }));

  return [...orderItemSkuQties, ...invalidOrderItemSkuQties];
};

// The assumption here if OUT_OF_STOCK problem is present, all other problems are resolved
export const isInvalidItemOrder = (replenishmentOrder: OrderResponse): boolean =>
  replenishmentOrder?.ingestionChannel === IngestionChannel.SPS &&
  (replenishmentOrder.invalidOrderItems?.length ?? 0) > 0 &&
  !find((a) => a.reason === InvalidOrderItemReason.OUT_OF_STOCK, replenishmentOrder?.invalidOrderItems ?? []);

export const areAllItemsResolvedFromInvalidItemOrder = (replenishmentOrder: OrderResponse) =>
  every(replenishmentOrder?.invalidOrderItems, (item) => item.resolution);

export const areAllItemsRemovedFromInvalidItemOrder = (replenishmentOrder: OrderResponse) =>
  isEmpty(replenishmentOrder?.orderItems) &&
  every(replenishmentOrder?.invalidOrderItems, (item) => item.resolution === InvalidOrderItemResolution.REMOVED);
