import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStorageInboundCreate } from "../store/selector/getStorageInboundCreate";
import { useStorageInboundNavigation } from "../navigation/useStorageInboundNavigation";
import { useMount } from "react-use";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { StorageDestinationTypeV2 } from "./StorageDestinationTypeV2.types";
import { setDestinationType } from "./actions/setDestinationType";
import { setDestinationStorageWarehouse } from "./actions/setDestinationStorageWarehouse";
import { StorageWarehouseOption } from "./StorageWarehouseOption.types";
import { updateStorageInboundOrder } from "../store/actions/updateStorageInboundOrder";
import { STORAGE_NON_CD_LOCATIONS } from "storage/inbounds/StorageNonCDLocationsConstants";
import { StorageWarehouseSellerView } from "./StorageWarehouseSellerView";
import { getStorageWarehouseOptions } from "./getStorageWarehouseOptions";
import { getRecommendedWarehouse } from "./getRecommendedWarehouse";
import { getRankedStorageWarehousesForInbounds } from "./actions/getRankedStorageWarehousesForInbounds";
import { getSelectedProductCasePacks } from "storage/inbounds/create/store/selector/getSelectedCasePacks";
import { isWarehouseSupportingDims } from "inbounds/createShipment/steps/ReserveStorageFcSelectStep/useReserveStorageFcSelectStep";
import { FeatureName, useFeatureOn } from "common/Split";
export const useStorageDestinationV2 = () => {
  const dispatch = useDispatch();
  const { goToNextStep, goToPreviousStep } = useStorageInboundNavigation();
  const { destinationStorageWarehouse, destinationType, storageWarehouses } = useSelector(getStorageInboundCreate);

  const storageWarehouseToBeShown = getStorageWarehouseOptions(storageWarehouses as StorageWarehouseSellerView[]);
  const selectedProductCasePacks = useSelector(getSelectedProductCasePacks);
  const isDimensionOverrideFeatureEnabled = useFeatureOn(FeatureName.InboundRsDimRelaxation);
  const isAnyWarehouseDisabledDueToDims = (storageWarehouses as StorageWarehouseSellerView[]).some(
    (warehouse) => !isWarehouseSupportingDims(selectedProductCasePacks, warehouse, isDimensionOverrideFeatureEnabled)
  );

  const totalPallets = (storageWarehouses as StorageWarehouseSellerView[]).reduce(
    (accumulator, currentValue) => accumulator + currentValue.palletCount,
    0
  );

  const recommendedWarehouse = getRecommendedWarehouse(
    storageWarehouseToBeShown,
    selectedProductCasePacks,
    isDimensionOverrideFeatureEnabled
  );

  const [selectedWarehouseOption, setSelectedWarehouseOption] = useState<StorageWarehouseOption | undefined>(
    destinationStorageWarehouse
      ? {
          value: destinationStorageWarehouse as StorageWarehouseSellerView,
          label: `${destinationStorageWarehouse.address.city}, ${destinationStorageWarehouse.address.state}`,
        }
      : undefined
  );

  useMount(async () => {
    dispatch(setDestinationType(StorageDestinationTypeV2.RECOMMENDED_WAREHOUSE));
    await dispatch(getRankedStorageWarehousesForInbounds(false));
  });

  useEffect(() => {
    if (destinationType === StorageDestinationTypeV2.RECOMMENDED_WAREHOUSE) {
      dispatch(setDestinationStorageWarehouse(recommendedWarehouse as StorageWarehouseSellerView));
    }
  }, [destinationType, dispatch, recommendedWarehouse]);

  const isNextDisabled = !destinationStorageWarehouse;
  const shouldShowNonCDLocationWarning =
    destinationStorageWarehouse?.id && STORAGE_NON_CD_LOCATIONS.includes(destinationStorageWarehouse?.id);

  const shouldShowNewWarehouseInboundWarning = !!(
    recommendedWarehouse &&
    totalPallets > 0 &&
    recommendedWarehouse.palletCount === 0
  );

  const onDestinationTypeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDestinationType = ev.target.value as StorageDestinationTypeV2;
    dispatch(setDestinationType(selectedDestinationType));
    if (selectedDestinationType === StorageDestinationTypeV2.RECOMMENDED_WAREHOUSE) {
      dispatch(setDestinationStorageWarehouse(recommendedWarehouse));
    } else if (selectedDestinationType === StorageDestinationTypeV2.SPECIFIC_WAREHOUSE) {
      dispatch(setDestinationStorageWarehouse(selectedWarehouseOption?.value));
    }
  };

  const onStorageWarehouseSelect = (selectedOption: StorageWarehouseOption) => {
    setSelectedWarehouseOption(selectedOption);
    dispatch(setDestinationStorageWarehouse(selectedOption.value));
  };

  const onNextClick = async () => {
    dispatch(addLoader(InboundLoaderId.transition));
    if (await dispatch(updateStorageInboundOrder())) {
      await goToNextStep();
    }
    dispatch(clearLoader(InboundLoaderId.transition));
  };

  const onPreviousClick = () => {
    goToPreviousStep();
  };

  return {
    onNextClick,
    onPreviousClick,
    destinationType,
    recommendedWarehouse,
    storageWarehouseToBeShown,
    onDestinationTypeChange,
    isNextDisabled,
    onStorageWarehouseSelect,
    selectedWarehouseOption,
    shouldShowNonCDLocationWarning,
    shouldShowNewWarehouseInboundWarning,
    isAnyWarehouseDisabledDueToDims,
  };
};
