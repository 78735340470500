import { wholesaleClient } from "Clients";
import { DeliverrAddress } from "@deliverr/commons-objects";
import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { logError } from "Logger";
import { isEmpty } from "lodash";

export const useQueryAddressValidity = (address: DeliverrAddress) => {
  const [addressValidation, setAddressValidation] = useState({ isValid: true, lastTriggerTime: 0 });
  const [isAddressValidationLoading, setIsAddressValidationLoading] = useState(false);

  const isAddressValid = (addr: DeliverrAddress): boolean => {
    const requiredFields = [addr.street1, addr.city, addr.state, addr.zip, addr.country];
    const areRequiredFieldsPresent = !requiredFields.some(isEmpty);
    return areRequiredFieldsPresent;
  };

  useEffect(() => {
    setIsAddressValidationLoading(true);
  }, [address.street1, address.street2, address.city, address.state, address.country, address.zip]);

  useDebounce(
    async () => {
      const triggerTime = new Date().getTime();

      try {
        const isValidAddress = isAddressValid(address) && (await wholesaleClient.validateAddress(address)).data.isValid;
        setAddressValidation((prevState) => ({
          isValid: triggerTime > prevState.lastTriggerTime ? isValidAddress : prevState.isValid,
          lastTriggerTime: triggerTime > prevState.lastTriggerTime ? triggerTime : prevState.lastTriggerTime,
        }));
      } catch (error) {
        logError({ fn: "validateAddress" }, error, "error validating address");
        setAddressValidation((prevState) => ({
          isValid: triggerTime > prevState.lastTriggerTime ? false : prevState.isValid,
          lastTriggerTime: triggerTime > prevState.lastTriggerTime ? triggerTime : prevState.lastTriggerTime,
        }));
      } finally {
        setIsAddressValidationLoading(false);
      }
    },
    800,
    [address.street1, address.street2, address.city, address.state, address.country, address.zip]
  );

  return { isValidAddress: addressValidation.isValid, isAddressValidationLoading };
};
