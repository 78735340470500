import { Text, Icon, DefaultTheme, Modal, Dropzone } from "common/components/ui";
import { Appearance } from "common/components/ui/shared";
import { useTheme } from "emotion-theming";
import { isNil } from "lodash";
import React, { FC, ReactNode } from "react";
import { useIntl } from "react-intl";
import { BULK_UPLOAD_ERROR_MESSAGES } from "./BulkUploadErrorMessages";
import { BULK_UPLOAD_CONTENT_MESSAGES } from "./BulkUploadContentMessages";
import { BulkUploadValidationError, useBulkUploadModal } from "./useBulkUploadModal";
import { useBulkUploadModalContent } from "./useBulkUploadModalContent";
import { ElevioArticleId } from "common/elevio";
import {
  CenteredTitle,
  ContentContainer,
  DropzoneContainer,
  ErrorListContainer,
  ErrorListItem,
  FlexButton,
  FlexElevioButton,
  IconContainer,
  ModalContainer,
  NotificationWithMargin,
  ReportLink,
  ShowAllLink,
  StyledStack,
  SubtitleText,
  TextContainer,
} from "./BulkUploadModalStyledComponents";
import { Product } from "@deliverr/commons-clients";
import { ShippingPlanItemLotExpirationRaw } from "@deliverr/legacy-inbound-client";
import { CasePackDefaultData } from "common/clients/inbound/bulkUpload/CasePackDefaultData";
import { BulkUploadTemplateType } from "./BulkUploadTemplateType";
import { SUPPORTED_NUMBER_OF_SKUS, SUPPORTED_NUMBER_OF_SKUS_FBA } from "./BulkUploadConstants";
import { DistributionChannel } from "@deliverr/replenishment-client";

export interface BulkUploadModalProps {
  casePacked: boolean;
  show: boolean;
  isEachesOrder?: boolean;
  closeModal: () => void;
  templateType?: BulkUploadTemplateType;
  distributionChannel?: DistributionChannel;
  handleBulkUploadSuccess?: (
    products: (Product & { qty: number })[],
    caseDimensions?: CasePackDefaultData[],
    lotTracking?: { [dsku: string]: ShippingPlanItemLotExpirationRaw }
  ) => Promise<void>;
  handleProductImportFailure?: (err: any, setValidationErrors: (errors: BulkUploadValidationError[]) => void) => void;
  prefixChildren?: ReactNode;
}

export const BulkUploadModal: FC<BulkUploadModalProps> = ({
  casePacked,
  show,
  isEachesOrder,
  closeModal,
  templateType = BulkUploadTemplateType.DEFAULT,
  distributionChannel,
  handleBulkUploadSuccess,
  handleProductImportFailure,
  prefixChildren,
}) => {
  const theme = useTheme<DefaultTheme>();
  const { formatMessage } = useIntl();

  const {
    file,
    uploadFileState,
    hasRequestError,
    validationErrors,
    isFileTooBig,
    planHasProducts,
    prepareFile,
    scrollableErrors,
    setScrollableErrors,
    containerRef,
    updateDisplayShowAllLink,
    initialContainerHeight,
    displayShowAllLink,
    suppressWarningNotification,
    clearFile,
    getDownloadErrorsUrl,
  } = useBulkUploadModal({
    closeModal,
    showModal: show,
    casePacked,
    templateType,
    distributionChannel,
    isEachesOrder,
    handleBulkUploadSuccess,
    handleProductImportFailure,
  });

  if (!casePacked) {
    templateType = BulkUploadTemplateType.INDIVIDUAL;
  }

  const { getTitleContent, getSubtitleContent } = useBulkUploadModalContent({ templateType });

  const hasSomeError = !isNil(validationErrors) || hasRequestError;

  const getArticleId = (() => {
    switch (templateType) {
      case BulkUploadTemplateType.DEFAULT:
        return ElevioArticleId.StorageInboundsImportProducts;
      case BulkUploadTemplateType.REPLENISHMENT:
      case BulkUploadTemplateType.REPLENISHMENT_EACHES:
        return ElevioArticleId.StorageInboundsImportProducts; // Update this ID when the article is ready
      default:
        return ElevioArticleId.InboundsImportProducts;
    }
  })();

  return (
    <Modal show={show} onClose={closeModal} hasCloseButton>
      <ModalContainer>
        <ContentContainer
          scrollableErrors={scrollableErrors}
          ref={containerRef as any}
          minHeight={scrollableErrors ? initialContainerHeight.current ?? 0 : 0}
        >
          {uploadFileState.loading && (
            <CenteredTitle as="h4" displayAs="h4">
              {formatMessage(BULK_UPLOAD_CONTENT_MESSAGES.IMPORTING_PRODUCTS)}
            </CenteredTitle>
          )}
          {!uploadFileState.loading && (
            <TextContainer>
              {hasSomeError && (
                <IconContainer>
                  <Icon size="3x" type="exclamation-circle" color={theme.colors.RED[300]} />
                </IconContainer>
              )}
              <CenteredTitle as="h4" displayAs="h4">
                {getTitleContent(hasSomeError)}
              </CenteredTitle>
              {isFileTooBig && (
                <NotificationWithMargin appearance={Appearance.DANGER}>
                  {formatMessage(BULK_UPLOAD_ERROR_MESSAGES.FILE_TOO_BIG)}
                </NotificationWithMargin>
              )}
              {planHasProducts && !suppressWarningNotification && (
                <NotificationWithMargin appearance={Appearance.WARNING}>
                  {formatMessage(BULK_UPLOAD_CONTENT_MESSAGES.PRODUCT_REPLACEMENT_WARNING)}
                </NotificationWithMargin>
              )}
              <SubtitleText>{getSubtitleContent(hasRequestError, validationErrors)}</SubtitleText>
              {!hasRequestError && !validationErrors && (
                <Text>
                  {formatMessage(BULK_UPLOAD_CONTENT_MESSAGES.NOTE, {
                    supportedNumberOfSKUs: ([
                      BulkUploadTemplateType.REPLENISHMENT,
                      BulkUploadTemplateType.REPLENISHMENT_EACHES,
                    ].includes(templateType)
                      ? SUPPORTED_NUMBER_OF_SKUS_FBA
                      : SUPPORTED_NUMBER_OF_SKUS
                    ).toLocaleString(),
                  })}
                </Text>
              )}
              {validationErrors != null && (
                <>
                  <ErrorListContainer
                    scrollableErrors={scrollableErrors}
                    ref={(container) => updateDisplayShowAllLink(container)}
                  >
                    <ul>
                      {validationErrors?.map(({ error, cells, isWholeSheetError }) => (
                        <ErrorListItem key={error}>
                          {formatMessage(BULK_UPLOAD_ERROR_MESSAGES[error], {
                            detectedTemplate: casePacked
                              ? formatMessage(BULK_UPLOAD_ERROR_MESSAGES.INDIVIDUAL_TEMPLATE)
                              : formatMessage(BULK_UPLOAD_ERROR_MESSAGES.CASE_PACKED_TEMPLATE),
                            expectedTemplate: casePacked
                              ? formatMessage(BULK_UPLOAD_ERROR_MESSAGES.CASE_PACKED_TEMPLATE)
                              : formatMessage(BULK_UPLOAD_ERROR_MESSAGES.INDIVIDUAL_TEMPLATE),
                          })}
                          {!isWholeSheetError &&
                            `: [${formatMessage({ id: "cells", defaultMessage: "Cells" })}] ${cells.join(", ")}`}
                        </ErrorListItem>
                      ))}
                    </ul>
                  </ErrorListContainer>
                  {!scrollableErrors && displayShowAllLink && (
                    <ShowAllLink onClick={() => setScrollableErrors(true)}>
                      {formatMessage(BULK_UPLOAD_CONTENT_MESSAGES.SHOW_ALL, { errorCount: validationErrors.length })}
                    </ShowAllLink>
                  )}
                  {scrollableErrors && (
                    <ReportLink href={getDownloadErrorsUrl()} download="BulkUploadErrors.csv" target="_blank">
                      {formatMessage(BULK_UPLOAD_CONTENT_MESSAGES.DOWNLOAD_REPORT)}
                    </ReportLink>
                  )}
                </>
              )}
            </TextContainer>
          )}
          {prefixChildren}
          <DropzoneContainer>
            <Dropzone
              id="inbound-bulk-upload-dropzone"
              acceptedFileTypes={["csv"]}
              helpText={formatMessage(BULK_UPLOAD_CONTENT_MESSAGES.FILE_TYPE)}
              onChange={prepareFile as any}
              onFileClear={clearFile}
              value={
                file
                  ? {
                      fileName: file?.name,
                      url: undefined,
                    }
                  : undefined
              }
            />
          </DropzoneContainer>
        </ContentContainer>
        <StyledStack direction="HORIZONTAL">
          <FlexElevioButton articleId={getArticleId}>
            {formatMessage(BULK_UPLOAD_CONTENT_MESSAGES.GET_HELP)}
          </FlexElevioButton>
          <FlexButton loading={uploadFileState.loading} disabled={isNil(file)}>
            {formatMessage(BULK_UPLOAD_CONTENT_MESSAGES.IMPORT_PRODUCTS)}
          </FlexButton>
        </StyledStack>
      </ModalContainer>
    </Modal>
  );
};
