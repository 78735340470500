import styled from "@emotion/styled";
import React from "react";
import { transitionFast } from "../shared/helpers/css";
import { DefaultTheme } from "../shared";

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Label for the checkbox */
  label?: string | JSX.Element;
  indeterminate?: boolean;
  /** Label styles */
  labelStyles?: string;
  isRound?: boolean;
}

const DASH_WIDTH = 10;
const DASH_HEIGHT = 1.33;

const filledCheckboxStyles = (theme: DefaultTheme) => `
  border-color: ${theme.colors.BLUE[300]};
  background-color: ${theme.colors.BLUE[300]};
  :disabled {
    background-color: ${theme.colors.NEUTRAL[100]};
    border-color: ${theme.colors.NEUTRAL[100]};
    :hover {
      background-color: ${theme.colors.NEUTRAL[100]};
      border-color: ${theme.colors.NEUTRAL[100]};
    }
  }
  :hover {
    border-color: ${theme.colors.BLUE[400]};
    background-color: ${theme.colors.BLUE[400]};
  }
`;
const StyledCheckbox = styled.input<CheckboxProps, DefaultTheme>(({ indeterminate, theme, isRound }) => {
  const checkWidth = "1px";
  return `
    appearance: none;
    cursor: pointer;
    background-color: ${theme.colors.NEUTRAL["00"]};
    border: ${theme.border.width.B1} ${theme.border.type};
    border-color: ${theme.colors.NEUTRAL[100]};
    border-radius: ${isRound ? "50%" : theme.border.radius.R2};
    width: ${theme.input.selectionHeight};
    height: ${theme.input.selectionHeight};
    font-size: ${theme.font.size.F3};
    outline: 0;
    margin: 0;
    position: relative;
    ${transitionFast()}
    :focus {
      box-shadow: ${theme.input.focus.BLUE};
    }
    :disabled {
      background-color: ${theme.colors.NEUTRAL[40]};
      border-color: ${theme.colors.NEUTRAL[100]};
      cursor: not-allowed;
      :hover {
        background-color: ${theme.colors.NEUTRAL[40]};
      }
    }
    :hover {
      background-color: ${theme.colors.NEUTRAL[40]};
    }

    ${
      indeterminate
        ? `
        ${filledCheckboxStyles(theme)}

        ::after {
          content: '';
          background-color: white;
          position: absolute;
          width: ${DASH_WIDTH}px;
          height: ${DASH_HEIGHT}px;
          left: calc(50% - ${DASH_WIDTH}px/2);
          top: calc(50% - ${DASH_HEIGHT}px/2);
        }`
        : ""
    }

    :checked {
      ${filledCheckboxStyles(theme)}
    }

    :checked {
      :after,
      :before {
        content: '';
        background-color: white;
        z-index: 1;
        position: absolute;
      }
      :after {
        width: ${checkWidth};
        height: 0.6em;
        transform: translate(0.5em, 0.1em) rotate(45deg);
      }
      :before {
        width: ${checkWidth};
        height: 0.3em;
        transform: translate(0.2em, 0.37em) rotate(-45deg);
      }
    }
  `;
});

export const sharedCheckboxGrid = ({ theme, disabled }: { theme: DefaultTheme; disabled?: boolean }) => `
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: ${theme.spacing.S2};
  cursor: ${disabled ? `not-allowed` : `pointer`};
  font-weight: ${theme.font.weight.REGULAR};
`;

const Label = styled.label<{ disabled?: boolean; labelStyles?: string }, DefaultTheme>(
  ({ disabled, labelStyles, theme }) => {
    return `
    ${sharedCheckboxGrid({ theme, disabled })}
    color: ${theme.colors.NEUTRAL[300]};
    white-space: nowrap;
    ${labelStyles};
  `;
  }
);

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const ele = <StyledCheckbox type="checkbox" {...props} />;
  if (props.label) {
    return (
      <Label disabled={props.disabled} labelStyles={props.labelStyles}>
        {ele}
        <span>{props.label}</span>
      </Label>
    );
  }
  return ele;
};
