import { useMemo } from "react";
import { getAggregatedRow } from "./getAggregatedRow";
import { TableAggregationOptions } from "./TableAggregationOptions";

export interface UseTableAggregatorProps<T extends Record<string, unknown>> {
  rows: T[];
  options: TableAggregationOptions<T>;
}

/**
 * Hook for aggregating row data based on the options provided and returning all rows.
 */
export function useTableAggregator<T extends Record<string, unknown>>({ rows, options }: UseTableAggregatorProps<T>) {
  const aggregateRow = useMemo(() => getAggregatedRow(rows, options), [rows, options]);

  return {
    rows: aggregateRow ? [...rows, aggregateRow] : rows,
  };
}

export function useMultiTableAggregator<T extends Record<string, unknown>>(
  aggregators: UseTableAggregatorProps<T>[],
  cumulativeAggregationKey?: keyof T
) {
  const aggRows: T[] = [];
  const aggregatedTableData = useMemo(
    () =>
      aggregators.map(({ rows, options }) => {
        const aggRow = getAggregatedRow(rows, options);

        // Add the aggregated row (if any) from each sub table
        if (aggRow) {
          aggRows.push(aggRow);
        }

        return { rows: aggRow ? [...rows, aggRow] : rows };
      }),
    [aggregators, aggRows]
  );

  let totalAggregation: number | undefined;
  if (aggRows.length > 0 && !!cumulativeAggregationKey) {
    const calculatedValue = getAggregatedRow<T>(aggRows, { keys: [cumulativeAggregationKey] })?.[
      cumulativeAggregationKey
    ];
    totalAggregation = typeof calculatedValue === "number" ? calculatedValue : undefined;
  }

  return {
    aggregatedTableData,
    totalAggregation,
  };
}
