import { CarrierAccount } from "@deliverr/replenishment-client";
import { WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { isEmpty } from "lodash";

/**
 * Check if this is the "COLLECT" option. An extra check for an empty account number is required
 * because the seller can set the displayName to "COLLECT".
 */
export const isCollectCarrierAccount = (carrierAccount: CarrierAccount): boolean => {
  return carrierAccount.displayName === WholesaleShippingMethod.COLLECT && isEmpty(carrierAccount.accountNumber);
};
