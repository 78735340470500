import React, { FC } from "react";
import { sumBy } from "lodash";
import { FormattedMessage } from "react-intl";
import { WholesaleShipmentType } from "@deliverr/wholesale-client";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { Amount } from "../../fba/shipping/AmazonShipmentType";
import { Estimation } from "./Estimation";
import { OrderItemDto } from "@deliverr/replenishment-client";

export interface SelectSingleShipmentShippingTypeContentProps {
  shipmentType: WholesaleShipmentType;
  shipmentTypeEstimate: Estimation;
  shipmentItems?: OrderItemDto[];
}

export const SelectSingleShipmentShippingTypeContent: FC<SelectSingleShipmentShippingTypeContentProps> = ({
  shipmentType,
  shipmentTypeEstimate,
  shipmentItems,
}) => {
  const content = (
    <FormattedMessage
      {...transfersShipmentCreateLabels.steps.shipping.cargoOptions.estimatedShippingCost}
      values={{
        formattedAmount: <Amount value={shipmentTypeEstimate.estimation ?? 0} />,
      }}
    />
  );

  if (shipmentType === WholesaleShipmentType.PARCEL) {
    const numOfBoxes = shipmentItems
      ? sumBy(Object.values(shipmentItems), (item) => item.totalUnits / item.unitsPerPack ?? 0)
      : 0;
    if (numOfBoxes > 0) {
      return (
        <FormattedMessage
          {...transfersShipmentCreateLabels.steps.shipping.cargoOptions.parcel.estimatedParcelShippingCost}
          values={{
            numOfBoxes,
            formattedAmount: <Amount value={shipmentTypeEstimate.estimation ?? 0} />,
          }}
        />
      );
    }
  } else {
    const numOfPallets = shipmentTypeEstimate.shipmentEstimationBreakdown
      ? sumBy(
          Object.values(shipmentTypeEstimate.shipmentEstimationBreakdown),
          (shipmentEstimation) => shipmentEstimation?.palletQty ?? 0
        )
      : 0;
    if (numOfPallets > 0) {
      return (
        <FormattedMessage
          {...transfersShipmentCreateLabels.steps.shipping.cargoOptions.pallet.estimatedPalletShippingCost}
          values={{
            numOfPallets,
            formattedAmount: <Amount value={shipmentTypeEstimate.estimation ?? 0} />,
          }}
        />
      );
    }
  }
  return content;
};
