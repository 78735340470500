import { RootState } from "RootReducer";
import { emptyAddress } from "common/AddressUtils";

const selectWarehouse = (warehouseId?: string | null) => (root: RootState) =>
  warehouseId ? root.deliverr.warehouses[warehouseId] : undefined;

export const selectWarehouseAddress = (warehouseId?: string | null) => (root: RootState) => {
  const warehouse = selectWarehouse(warehouseId)(root);
  return {
    originAddress: warehouse?.outboundAddress ?? emptyAddress,
    originWarehouseEmail: warehouse?.inboundContactEmail,
  };
};

export const selectWholesaleOrderDetail = (root: RootState) => root.wholesaleOrderDetail;
