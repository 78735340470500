import { updateProductCache } from "inbounds/InboundActions";
import { getProductDetailsCache } from "inbounds/store/selectors/productDetailsCache";
import { useDispatch, useSelector } from "react-redux";
import { useStorageInboundNavigation } from "../navigation/useStorageInboundNavigation";
import { getStorageInboundCreate } from "../store/selector/getStorageInboundCreate";

export const useStorageAddProductBarcode = () => {
  const dispatch = useDispatch();
  const { goToPreviousStep, goToNextStep } = useStorageInboundNavigation();
  const { productDetails } = useSelector(getStorageInboundCreate);
  const productDetailsCache = useSelector(getProductDetailsCache);

  const goToNextStepAndUpdateCache = async () => {
    await dispatch(updateProductCache(Object.keys(productDetails)));
    goToNextStep();
  };
  return {
    goToNextStep: goToNextStepAndUpdateCache,
    goToPreviousStep,
    productDetailsCache: { ...productDetails, ...productDetailsCache },
  };
};
