import { Product } from "@deliverr/commons-clients";
import { ProductDetailData } from "inbounds/steps/ship/view/cards/ProductDetailData";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useAsync } from "react-use";
import { fetchMultiPackProductsByDskus } from "storage/utils/fetchMultiPackProductsByDskus";

export const ResolvedProductDetails: React.FunctionComponent<{
  dsku?: string | null;
  shouldShowPackInformation?: boolean;
}> = ({ dsku, shouldShowPackInformation = true }) => {
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const dispatch = useDispatch();

  useAsync(async () => {
    if (dsku) {
      const data = await fetchMultiPackProductsByDskus([dsku], dispatch);
      setProduct(data[dsku]);
    } else {
      setProduct(undefined);
    }
  }, [dsku, dispatch]);

  return (
    <>
      {product ? (
        <ProductDetailData
          product={product}
          showStorageProductAndCaseDskuForAdmin
          shouldShowPackInformation={shouldShowPackInformation}
        />
      ) : (
        <FormattedMessage id="resolved.product.details.unavailable" defaultMessage="N/A" />
      )}
    </>
  );
};
