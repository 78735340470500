import * as React from "react";
import styled from "@emotion/styled";
import { IconV2, IconV2Props } from "../IconV2";
import { ContentElement, DefaultTheme, ThemeProps } from "../shared";
import { isMobile } from "../shared/helpers/utils";

export const extractFormProps = (props) => {
  const { label, hasError, errorMessage, helpText, required, isOptional, as, formGroupStyles, ...remainderProps } =
    props;
  const formProps = { label, hasError, errorMessage, helpText, required, isOptional, as, formGroupStyles };
  const otherProps = { ...remainderProps, hasError, required };
  return [formProps, otherProps];
};

export type FormGroupProps<T extends {}> = T & {
  /** Sets label */
  label?: string | JSX.Element;
  /** Triggers error styling */
  hasError?: boolean;
  /** Sets error message, is visible when hasError is true */
  errorMessage?: string | JSX.Element;
  /** Sets help text */
  helpText?: string | JSX.Element;
  /** Sets required astricks */
  required?: boolean;
  /** Sets optional tag */
  isOptional?: boolean;
  /** Sets parent container element, default = label */
  as?: keyof typeof ContentElement;
  /** Width grows or shrinks according to selection. For Select's only */
  flexWidth?: boolean;
  /** Styles to be applied to formGroup container */
  formGroupStyles?: string;
};

const Container = styled.label<{ as?: keyof typeof ContentElement; flexWidth?: boolean }>`
  display: ${({ flexWidth }) => (flexWidth ? "inline-block" : "block")};
`;

const LabelContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  position: relative;
  display: block;
  height: ${isMobile(theme) ? `calc((${theme.font.lineHeight.LH2} / 2))` : "inherit"};
`
);

const labelPadding = "3px";
const Label = styled.span<FormGroupProps<{}>, DefaultTheme>(
  ({ hasError, theme }) => `
  display: inline-block;
  background-color: ${isMobile(theme) ? "#fff" : "inherit"};
  border-radius: ${theme.border.radius.R5};
  padding: ${isMobile(theme) ? `0 ${labelPadding}` : `0 0 ${theme.spacing.S2} 0`};
  position: relative;
  z-index: ${isMobile(theme) ? `1` : `0`};
  white-space: nowrap;
  margin-left: ${isMobile(theme) ? `calc(${theme.spacing.S4} - ${labelPadding})` : "0"};
  font-size: ${isMobile(theme) ? theme.font.size.F3 : theme.font.size.F2};
  line-height: ${theme.font.lineHeight.LH2};
  color: ${theme.colors.NEUTRAL[500]};
`
);

const Optional = styled.span<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
`
);

const Asterisks = styled.span<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.RED[300]};
  margin-left: 1px;
`
);

export const HelpText = styled.p<ThemeProps>(
  ({ theme }) => `
  margin: ${theme.spacing.S2} 0 0 0;
  font-size: ${isMobile(theme) ? theme.font.size.F2 : theme.font.size.F1};
  color: ${theme.colors.NEUTRAL[300]};
`
);

export const ErrorContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  ${isMobile(theme) ? `line-height: ${theme.font.lineHeight.LH1};` : ""}
  margin: ${theme.spacing.S2} 0 0 0;
  font-size: ${isMobile(theme) ? theme.font.size.F2 : theme.font.size.F1};
  color: ${theme.colors.RED[400]};
`
);

export const StyledErrorIcon = styled(IconV2)<IconV2Props, DefaultTheme>`
  height: 14px;
  margin-left: -4px;
  margin-right: ${({ theme }) => theme.spacing.S1};
  ${({ theme }) => (isMobile(theme) ? "margin-top: 3px;" : "")}
`;

const ChildrenContainer = styled.div<ThemeProps>`
  display: grid;
  align-items: center;
  min-height: ${({ theme }) => theme.input.height};
`;

export const FormGroup: React.FC<FormGroupProps<{}>> = ({
  label,
  required,
  helpText,
  hasError,
  errorMessage,
  isOptional,
  children,
  as,
  flexWidth,
  formGroupStyles,
}) => (
  <Container as={as} flexWidth={flexWidth} className={formGroupStyles}>
    {label && (
      <LabelContainer>
        <Label hasError={hasError} className="formGroup_label">
          {label}
          {required && <Asterisks> *</Asterisks>}
          {isOptional && <Optional> (optional)</Optional>}
        </Label>
      </LabelContainer>
    )}
    <ChildrenContainer>{children}</ChildrenContainer>
    {hasError && errorMessage && (
      <ErrorContainer>
        <StyledErrorIcon type="exclamation-circle" /> {errorMessage}
      </ErrorContainer>
    )}
    {helpText && <HelpText>{helpText}</HelpText>}
  </Container>
);
