import { BarcodeResponseSubcode } from "@deliverr/commons-clients";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { barcodeGenericError, barcodeServiceErrors } from "./BarcodePageErrors";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";

interface BarcodeEditComponentProps {
  barcodeError: {
    code?: BarcodeResponseSubcode;
  };
}

export const BarcodeEditComponentError: FC<BarcodeEditComponentProps> = ({ barcodeError: { code } }) => {
  if (!code || !barcodeServiceErrors[code]) {
    return barcodeGenericError;
  }

  if (code === BarcodeResponseSubcode.DUPLICATE) {
    return (
      <FormattedMessage
        id="barcode.error.duplicateWithDsku"
        defaultMessage="This barcode is currently being used by another product in Flexport. Please re-label the product and re-submit with another barcode. Need help with re-labeling? Use our <prepLink>prep services</prepLink>."
        values={{
          prepLink: (chunks) => (
            <ElevioBorderlessButton asLink articleId={ElevioArticleId.SimplePrep}>
              {chunks}
            </ElevioBorderlessButton>
          ),
        }}
      />
    );
  }

  return <FormattedMessage {...barcodeServiceErrors[code]} />;
};
