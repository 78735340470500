import { ProductCasePackCollection } from "./../create/selectProductCasePacks/ProductCasePackCollection.types";
import { LotInformationFormValues } from "inbounds/steps/lot-information/types/LotInformationFormValues";
import { CaseDimensionCollection } from "../create/dimensions/CaseDimensionCollection.types";
import { StorageInboundProductItem } from "../create/store/StorageInboundProductItem.types";

export const selectedProductsToStorageItemMapper = (
  selectedProductsValues: StorageInboundProductItem[],
  selectedProductsCaseDimensions?: CaseDimensionCollection,
  selectedProductsLotInformation?: LotInformationFormValues,
  selectedProductCasePacks?: ProductCasePackCollection,
  mapSkuToCasePackSku?: boolean
) => {
  return selectedProductsValues.map((storageInboundProductItem) => {
    const dsku = storageInboundProductItem.dsku;
    const caseDimensionCollection = selectedProductsCaseDimensions?.[dsku];
    const lotInformationCollection = selectedProductsLotInformation?.[dsku];
    return {
      dsku: mapSkuToCasePackSku ? selectedProductCasePacks![dsku].dsku : dsku,
      caseQty: storageInboundProductItem.caseQty,
      qty: storageInboundProductItem.qty,
      caseWidth: caseDimensionCollection?.width && Number(caseDimensionCollection?.width),
      caseHeight: caseDimensionCollection?.height && Number(caseDimensionCollection?.height),
      caseLength: caseDimensionCollection?.length && Number(caseDimensionCollection?.length),
      caseWeight: caseDimensionCollection?.weight && Number(caseDimensionCollection?.weight),
      expirationDate: lotInformationCollection?.expirationDate,
      expirationDateHasDay: lotInformationCollection?.hasFullExpirationDate.value,
      lotNumber: lotInformationCollection?.lotNumber,
    };
  });
};
