import {
  transferCreateSetError,
  transferCreateSetLabelFilename,
  transferCreateSetUploading,
} from "./TransferCreateActions";
import { selectTransferCreate } from "../store/TransferCreateSelectors";
import { sharedCreateUploadWholesaleLabelFile } from "common/wholesale-common/sharedCreateUploadWholesaleLabelFile";

export const uploadTransferLabelFile = sharedCreateUploadWholesaleLabelFile({
  selector: selectTransferCreate,
  setError: transferCreateSetError,
  setFilename: transferCreateSetLabelFilename,
  setUploading: transferCreateSetUploading,
});
