import { StorageWarehouseSellerView } from "./StorageWarehouseSellerView";
import { isStorageWarehouseDisabled } from "inbounds/createShipment/steps/ReserveStorageFcSelectStep/useReserveStorageFcSelectStep";
import { ProductCasePackCollection } from "../selectProductCasePacks/ProductCasePackCollection.types";

export const getRecommendedWarehouse = (
  storageWarehouseToBeShown: StorageWarehouseSellerView[],
  selectedProductCasePacks: ProductCasePackCollection,
  isDimensionOverrideFeatureEnabled?: boolean
) => {
  return storageWarehouseToBeShown?.find(
    (warehouse) => !isStorageWarehouseDisabled(selectedProductCasePacks, warehouse, isDimensionOverrideFeatureEnabled)
  ) as StorageWarehouseSellerView;
};
