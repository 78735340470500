import React, { FC } from "react";
import { Accordion } from "common/components/ui";

import {
  NonComplianceStepContainer,
  NonComplianceStepContent,
} from "inbounds/non-compliance/components/NonComplianceStyles";
import { useStepMetadata } from "inbounds/hooks";
import { AccordionContainer, StyledTitle } from "common/components/AccordionFlow";

export const NonComplianceFlowSteps: FC<ReturnType<typeof useStepMetadata>> = ({
  stepAccordions,
  openSteps,
  toggleOpen,
}) => (
  <NonComplianceStepContainer>
    {stepAccordions.map(({ id, title, disabled, isDefaultOpen, content }, stepIndex) => (
      <AccordionContainer key={id}>
        <Accordion
          isOpen={openSteps[id] ?? isDefaultOpen}
          disabled={disabled}
          headerContent={
            <StyledTitle as="h4" displayAs="h4">
              {title}
            </StyledTitle>
          }
          indicator
          counter={stepIndex + 1}
          toggleOpen={() => toggleOpen(id)}
        >
          <NonComplianceStepContent>{content}</NonComplianceStepContent>
        </Accordion>
      </AccordionContainer>
    ))}
  </NonComplianceStepContainer>
);
