import { MismatchCaseQtyBlade } from "common/components/ProblemBlade/MismatchCaseQtyBlade/MismatchCaseQtyBlade";
import React, { FC } from "react";

import { UnexpectedSkuNonComplianceRow } from "../../types";
import { MISMATCH_CASE_QTY_NON_COMPLIANCE_BLADE_ID } from "../bladeIds";
import { useMismatchCaseQtyNonComplianceBlade } from "./useMismatchCaseQtyNonComplianceBlade";

interface MismatchCaseQtyNonComplianceBladeProps {
  nonComplianceCase: UnexpectedSkuNonComplianceRow;
}

export const MismatchCaseQtyNonComplianceBlade: FC<MismatchCaseQtyNonComplianceBladeProps> = ({
  nonComplianceCase,
}) => {
  const {
    onShippingPlanClick,
    shippingPlanId,
    shippingPlanName,
    resetBladeState,
    product,
  } = useMismatchCaseQtyNonComplianceBlade(nonComplianceCase);

  return (
    <MismatchCaseQtyBlade
      id={MISMATCH_CASE_QTY_NON_COMPLIANCE_BLADE_ID}
      shipmentProblem={nonComplianceCase}
      onShippingPlanClick={onShippingPlanClick}
      shippingPlanId={shippingPlanId}
      shippingPlanName={shippingPlanName}
      onCloseClick={resetBladeState}
      isBladeOpen
      productDetails={product}
    />
  );
};
