import {
  BookingAddress,
  BookingDestinationRequest,
  BookingDestinationResponse,
  BookingItemRequest,
  BookingItemResponse,
  BookingSupplierResponse,
  CreateBookingRequest,
  CreateBookingResponse,
  InboundBooking,
  InboundBookingCargoTotalUnit,
  InboundBookingRequest,
  InboundPackageSummary,
  SubmitBookingRequest,
  SubmitBookingResponse,
} from "@deliverr/legacy-inbound-client";
import { SmbBookingSupplierInput } from "@deliverr/legacy-inbound-client/lib/legacy-copy-pasta";
import { WarehouseLocation } from "@deliverr/replenishment-client";

export enum ShipmentSteps {
  // steps that lead to international checkout
  OriginSearch = "originSearch",
  SupplierSelect = "supplierSelect",
  DestinationSelect = "destinationSelect",
  ReserveStorageFcSelect = "reserveStorageFcSelect",
  DestinationSearch = "destinationSearch",
  CargoDetails = "cargoDetails",
  PackagingRequirements = "packagingRequirements",
  PriceView = "priceView",
  // non-international rate steps
  OwnTransportationAddressStep = "ownTransportationAddressStep",
  DomesticFulfillmentSelect = "domesticFulfillmentSelect",
  DomesticDestinationSelect = "domesticDestinationSelect",
}

export const INTERNATIONAL_RATE_STEPS = [
  ShipmentSteps.OriginSearch,
  ShipmentSteps.SupplierSelect,
  ShipmentSteps.DestinationSelect,
  ShipmentSteps.ReserveStorageFcSelect,
  ShipmentSteps.DestinationSearch,
  ShipmentSteps.CargoDetails,
  ShipmentSteps.PackagingRequirements,
  ShipmentSteps.PriceView,
] as const;

export type InternationalRateSteps = (typeof INTERNATIONAL_RATE_STEPS)[number];

export type InboundBookingRequestV2 =
  | InboundBookingRequest
  | CreateBookingRequest
  | SmbBookingSupplierInput
  | BookingDestinationRequest
  | BookingItemRequest
  | SubmitBookingRequest;

export type InboundBookingV2 =
  | InboundBooking
  | CreateBookingResponse
  | BookingSupplierResponse
  | BookingDestinationResponse
  | BookingItemResponse
  | SubmitBookingResponse;

export { InboundBookingCargoTotalUnit as UnitSystem };

export enum DistributionType {
  REPLENISH_ON_DEMAND = "REPLENISH_ON_DEMAND",
  FORWARD_TO_AMAZON = "FORWARD_TO_AMAZON",
  FORWARD_TO_AMAZON_WITH_UNIT_PREP = "FORWARD_TO_AMAZON_WITH_UNIT_PREP",
  DIRECT_TO_AMAZON = "DIRECT_TO_AMAZON",
}

export enum UnitPrepService {
  UNIT_BARCODING = "UNIT_BARCODING",
  BAGGING_PROTECTION = "BAGGING_PROTECTION",
  DAMAGE_PROTECTION = "DAMAGE_PROTECTION",
}

export enum OriginType {
  DOMESTIC = "DOMESTIC",
  INTERNATIONAL = "INTERNATIONAL",
}

export enum DomesticDestinationType {
  RESERVE_STORAGE = "RESERVE_STORAGE",
  DTC = "DTC",
}

export interface PlaceType {
  label: string;
  value: {
    address: BookingAddress;
    ports: Port[];
    distanceKm: number | undefined;
  };
}

export interface Port {
  abbr: string;
  fid: string;
}

export interface City {
  address: string | undefined;
  streetAddress: string | undefined;
  city: string | undefined;
  state: string | undefined;
  country: string | undefined;
  zipCode: string | undefined;
  latitude: number | null | undefined;
  longitude: number | null | undefined;
  countryCode: string | undefined;
}

export enum AmazonDestinationType {
  SINGLE = "SINGLE",
  MULTIPLE = "MULTIPLE",
  AUTOMATED = "AUTOMATED",
  UNKNOWN = "UNKNOWN",
}

export interface AmazonAddress extends WarehouseLocation {
  latitude: number | null;
  longitude: number | null;
  phone: string;
  email: string;
}

export enum CargoInsuranceType {
  RESTRICTED_PRODUCTS = "RESTRICTED_PRODUCTS",
  NO_RESTRICTED_PRODUCTS = "NO_RESTRICTED_PRODUCTS",
}

export interface FulfillmentTypes {
  isByFlexport: boolean;
  isByAmazon: boolean;
}

export interface PackageSummary extends InboundPackageSummary {
  casePackDsku?: string;
}

export interface SingleSkuPackageCollection {
  [dsku: string]: PackageSummary[];
}

export interface DtcPrepOption {
  boxLabelingUnitsSelected: boolean;
  unitPrepService: UnitPrepService | null;
}

export enum CargoInputType {
  PRODUCTS = "products",
  TOTALS = "totals",
}
