import { DefaultTheme, defaultTheme } from "common/components/ui";
import React, { FC } from "react";

import { ReturnStatus } from "@deliverr/returns-client";
import styled from "@emotion/styled";

interface StatusProps {
  width: string;
  color: string;
}

export const colors = {
  default: defaultTheme.colors.BLUE[300],
  background: "#D7DCE6",
};

export const widths = {
  start: "5%",
  oneFourth: "25%",
  mid: "50%",
  threeFourth: "75%",
  completed: "100%",
};

const Bar = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
  border-radius: ${theme.border.radius.R4};
  height: 8px;
  max-width: 380px;
  width: 100%;
  background-color: ${colors.background};
  margin-top: ${theme.spacing.S5};
`
);

const InnerBar = styled.div<StatusProps, DefaultTheme>(
  ({ theme, width, color }) => `
  height: 100%;
  width: ${width};
  border-radius: ${theme.border.radius.R4};
  background-color: ${color};
`
);

const getStatusBar = (width: string, color = colors.default) => ({
  width,
  color,
});

export const barConfig: Record<ReturnStatus, StatusProps | undefined> = {
  [ReturnStatus.CREATED]: getStatusBar(widths.start),
  [ReturnStatus.CARRIER_SHIPPED]: getStatusBar(widths.oneFourth),
  [ReturnStatus.CARRIER_DELIVERED]: getStatusBar(widths.mid),
  [ReturnStatus.ARRIVED]: getStatusBar(widths.threeFourth),
  [ReturnStatus.PROCESSED]: getStatusBar(widths.completed),
  [ReturnStatus.RETURNED_TO_CUSTOMER]: undefined,
  [ReturnStatus.CANCELLED]: undefined,
  [ReturnStatus.CLOSED]: undefined,
  [ReturnStatus.AUTO_CANCELLED]: undefined,
};

export const ReturnStatusBar: FC<{ status?: ReturnStatus }> = ({ status }) => {
  const config = status ? barConfig[status] : getStatusBar(widths.start);

  return config ? (
    <Bar>
      <InnerBar width={config.width} color={config.color} data-testid="return_status_bar" />
    </Bar>
  ) : null;
};
