import styled from "@emotion/styled";
import { ButtonMenu } from "common/components/ui/ButtonMenu";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Anchor, Text, ThemeProps } from "common/components/ui";
import { reject, isEmpty } from "lodash";
import { ShipmentActionsMenuLabels } from "common/wholesale-common/components/ShipmentActionsMenuLabels";
import { sharedShipmentHeaderLabels } from "common/wholesale-common/components/sharedShipmentHeader.labels";
import { AttachmentType, OrderStatus } from "@deliverr/replenishment-client";
import { AttachmentData } from "transfers/detail/store/replenishment/ReplenishmentOrderDetailsState";
const PositionedButtonMenu = styled(ButtonMenu)<ThemeProps>(
  ({ theme }) => `
    label {
      margin-bottom: 0;
      text-transform: none;
      font-family: ${theme.font.family.STANDARD}
    }
`
);

enum ShipmentDetailActionsType {
  CANCEL_ORDER = "CANCEL_ORDER",
  DUPLICATE_ORDER = "DUPLICATE_ORDER",
  PARTIAL_FILL_ORDER = "PARTIAL_FILL_ORDER",
}

const sharedLabels = sharedShipmentHeaderLabels.actions;

interface Props {
  status?: OrderStatus;
  attachments?: AttachmentData[];
  onCancel: () => void;
  onDuplicate?: () => void;
  isCancellable?: boolean;
  isWholesaleOrder?: boolean;
  onPartialFill?: () => void;
  isPartiallyFulfillable?: boolean;
}

export const ShipmentDetailActionsMenu =
  (labels: ShipmentActionsMenuLabels): FC<Props> =>
  ({
    attachments,
    onCancel,
    onDuplicate,
    isCancellable,
    isWholesaleOrder = false,
    isPartiallyFulfillable = false,
    onPartialFill,
  }) => {
    const findAttachmentUrl = (attachmentType: AttachmentType) =>
      attachments
        ?.filter((attachment) => attachment.status !== "URL_GENERATED")
        ?.find((attachment) => attachment.attachmentType === attachmentType)?.downloadUrl;

    const onMenuChange = (eventData) => {
      const actionType = eventData.value as ShipmentDetailActionsType;
      switch (actionType) {
        case ShipmentDetailActionsType.CANCEL_ORDER:
          onCancel();
          break;
        case ShipmentDetailActionsType.DUPLICATE_ORDER:
          onDuplicate?.();
          break;
        case ShipmentDetailActionsType.PARTIAL_FILL_ORDER:
          onPartialFill?.();
          break;
      }
    };

    const boxLabelUrl = findAttachmentUrl(AttachmentType.CASE_LABELS);
    const palletLabelUrl = findAttachmentUrl(AttachmentType.PALLET_LABELS);
    const shippingLabelUrl = findAttachmentUrl(AttachmentType.SHIPPING_LABELS);
    const signedBOLUrl = findAttachmentUrl(AttachmentType.SIGNED_BOL);
    const BOLUrl = findAttachmentUrl(AttachmentType.BOL);
    const packingListUrl = findAttachmentUrl(AttachmentType.PACKING_LIST);
    const commercialInvoiceUrl = findAttachmentUrl(AttachmentType.COMMERCIAL_INVOICE);

    const options = [
      isPartiallyFulfillable && {
        label: (
          <Text
            bold={true}
            style={{ cursor: "pointer", color: "initial" }}
            data-testid="outbound-action-button-partialFill"
          >
            <FormattedMessage {...labels.partialFillOrder} />
          </Text>
        ),
        value: ShipmentDetailActionsType.PARTIAL_FILL_ORDER,
      },
      isCancellable && {
        label: (
          <Text
            appearance="DANGER"
            bold={true}
            style={{ cursor: "pointer" }}
            data-testid="outbound-action-button-cancel"
          >
            <FormattedMessage {...(isWholesaleOrder ? labels.cancelOrder : labels.cancelTransfer)} />
          </Text>
        ),
        value: ShipmentDetailActionsType.CANCEL_ORDER,
      },
      !!boxLabelUrl && {
        label: (
          <Anchor href={boxLabelUrl} download>
            <FormattedMessage {...sharedLabels.downloadBoxLabel} />
          </Anchor>
        ),
      },
      !!palletLabelUrl && {
        label: (
          <Anchor href={palletLabelUrl} download>
            <FormattedMessage {...sharedLabels.downloadPalletLabel} />
          </Anchor>
        ),
      },
      !!shippingLabelUrl && {
        label: (
          <Anchor href={shippingLabelUrl} download>
            <FormattedMessage {...sharedLabels.downloadShippingLabel} />
          </Anchor>
        ),
      },
      !!signedBOLUrl && {
        label: (
          <Anchor href={signedBOLUrl} download>
            <FormattedMessage {...sharedLabels.downloadSignedBOL} />
          </Anchor>
        ),
      },
      !!BOLUrl && {
        label: (
          <Anchor href={BOLUrl} download>
            <FormattedMessage {...sharedLabels.downloadBOL} />
          </Anchor>
        ),
      },
      !!packingListUrl && {
        label: (
          <Anchor href={packingListUrl} download>
            <FormattedMessage {...sharedLabels.downloadPackingList} />
          </Anchor>
        ),
      },
      !!commercialInvoiceUrl && {
        label: (
          <Anchor href={commercialInvoiceUrl} download>
            <FormattedMessage {...sharedLabels.downloadCommercialInvoice} />
          </Anchor>
        ),
      },
    ];

    return (
      <div data-testid="transfer-shipment-actions-menu">
        <PositionedButtonMenu
          label={<FormattedMessage {...sharedLabels.title} />}
          options={reject(options, isEmpty)}
          onChange={onMenuChange}
        />
      </div>
    );
  };
