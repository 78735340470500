import styled from "@emotion/styled";
import { Box, LocalizedMessage, Text, ThemeProps, Title } from "common/components/ui";
import React from "react";

export interface ShipmentConfirmationTileProps {
  header: LocalizedMessage;
  content: React.ReactNode;
  footer?: React.ReactNode;
  "data-testid"?: string;
  className?: string;
}

const TileHeader = styled(Text)<ThemeProps>(
  ({ theme }) => `
      font-size: ${theme.font.size.F3};
      line-height: ${theme.font.lineHeight.LH3};
      margin-top: 0;
    `
);
const TileContent = styled(Title)<ThemeProps>(
  ({ theme }) => `
      margin-top: 0;
      margin-bottom: 0;
      line-height: ${theme.font.lineHeight.LH6};
    `
);

const StyledInfoContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S5} ${theme.spacing.S7} ${theme.spacing.S5} ${theme.spacing.S7};
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  gap: ${theme.spacing.S1};

  &:not(:first-of-type) {
    border-left: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
  }
`
);

export const ShipmentConfirmationTile: React.FC<ShipmentConfirmationTileProps> = ({
  header,
  content,
  footer,
  "data-testid": dataTestId,
  className,
}) => {
  return (
    <StyledInfoContainer data-testid={dataTestId} className={className}>
      <TileHeader as="header">{header}</TileHeader>
      <TileContent displayAs="h2" as="h2">
        {content}
      </TileContent>
      <Box marginBottom={null} marginTop={null}>
        {footer}
      </Box>
    </StyledInfoContainer>
  );
};
