import { ParcelListActionTypes } from "parcel/screens/list/ParcelListActionTypes";
import { searchParcels } from "parcel/screens/list/actions/searchParcels";
import { batch } from "react-redux";

export const setParcelSort = (sort) => async (dispatch) =>
  batch(() => {
    dispatch({
      type: ParcelListActionTypes.SET_PARCELS_SORT,
      sort,
    });
    dispatch(searchParcels());
  });
