import { MessageDescriptor, defineMessages } from "react-intl";
import { CarrierType, ShipmentType } from "@deliverr/storage-client";

interface CargoTypeMessages {
  label: MessageDescriptor;
  content?: MessageDescriptor;
}

const boxesWillArriveOnPalletLabel = defineMessages({
  label: {
    id: "storage.inbounds.cargoType.palletized.label",
    defaultMessage: "Palletized",
  },
  content: {
    id: "storage.inbounds.cargoType.palletized.content",
    defaultMessage: "Boxes will arrive on pallets",
  },
});

export const CARGO_TYPE_MESSAGES: Record<ShipmentType, Record<CarrierType, CargoTypeMessages>> = {
  [ShipmentType.PALLETIZED]: {
    [CarrierType.OTHER]: boxesWillArriveOnPalletLabel,
    [CarrierType.DELIVERR]: boxesWillArriveOnPalletLabel,
  },
  [ShipmentType.FLOOR_LOADED_CONTAINER]: {
    [CarrierType.OTHER]: defineMessages({
      label: {
        id: "storage.inbounds.cargoType.floorLoadedContainer.other.label",
        defaultMessage: "Floor loaded",
      },
      content: {
        id: "storage.inbounds.cargoType.floorLoadedContainer.other.content",
        defaultMessage: "Boxes will arrive unpalletized",
      },
    }),
    [CarrierType.DELIVERR]: defineMessages({
      label: {
        id: "storage.inbounds.cargoType.floorLoadedContainer.deliverr.label",
        defaultMessage: "Floor loaded",
      },
    }),
  },
};
