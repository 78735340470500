import React, { FC } from "react";
import { Container } from "common/components/Container";
import Spinner from "common/components/Spinner/Spinner";
import cls from "./ReturnsDetail.less";
import { useReturnsDetail } from "./useReturnsDetail";
import { ReturnsDetailHeader } from "./ReturnHeader";
import { ReturnAddressInformation } from "./ReturnAddressInformation";
import { ReturnsDetailStatus } from "./ReturnsDetailStatus";
import { ReturnsItemDetails } from "./ReturnsItemDetails";

const ReturnsDetail: FC = () => {
  const { returnsDetail, returnItemDetails, isLoading, returnsQcItems, areQcItemsPresent } = useReturnsDetail();
  return (
    <Container>
      {isLoading ? (
        <div className={cls.loading}>
          <Spinner />
        </div>
      ) : (
        <div className={cls.detailHeader}>
          <ReturnsDetailHeader />
          <ReturnsDetailStatus />
          <ReturnAddressInformation returnsDetail={returnsDetail} />
          <ReturnsItemDetails
            returnsQcItems={returnsQcItems}
            returnsItems={returnsDetail.items}
            returnItemDetails={returnItemDetails}
            areQcItemsPresent={areQcItemsPresent}
          />
        </div>
      )}
    </Container>
  );
};

export default ReturnsDetail;
