import React, { FC, useState } from "react";
import { FormattedMessage, FormattedPlural } from "react-intl";
import { Anchor, Stack, Text, ThemeProps, useMobile } from "common/components/ui";
import styled from "@emotion/styled";
import { Direction } from "common/components/ui/shared";
import { isNil } from "lodash";

import { MappedSkuPair, UnexpectedSkuProblemRow } from "common/components/ProblemsTable/types";
import { ProblemBaseBlade, ProblemBladeProps } from "common/components/ProblemBlade/ProblemBaseBlade";
import { ProblemType } from "common/problems/ProblemType";
import { ProblemBladeStatusSection } from "common/components/ProblemBlade/components/ProblemBladeStatusSection";
import { DetailItemSection } from "common/components/ProblemBlade/components/DetailItemSection";
import { ProblemDetailItem } from "common/components/ProblemBlade/components/ProblemDetailItem";
import { SKULink } from "common/components/ProblemBlade/components/SKULink";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";
import { SupportChatLauncher } from "common/components/SupportChatLauncher";
import { PROBLEM_TYPE_LABEL_MAP } from "common/problems/problemTypeLabelMap";
import { ShippingPlanNameDisplay } from "common/components/ShippingPlanNameDisplay";
import { UnexpectedSkuBladeMessages } from "./UnexpctedSkuBladeMessages";

export interface UnexpectedSkuBladeProps extends ProblemBladeProps {
  shipmentProblem: UnexpectedSkuProblemRow;
  onShippingPlanClick: () => void;
  product?: MappedSkuPair;
  shippingPlanId?: number;
  shippingPlanName?: string;
}

const StatusSectionWithMargin = styled(ProblemBladeStatusSection)<ThemeProps>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S5};
  `
);

const StyledStack = styled(Stack)`
  width: 100%;
`;

const SpacedProblemDetailItem = styled(ProblemDetailItem)<ThemeProps>(
  ({ theme }) => `
  min-width: 50%;
  display: inline-block;

  dt {
    font-weight: ${theme.font.weight.BOLD};
  }
`
);

const NotRightSkuText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S4};
  margin-bottom: ${theme.spacing.S2};
`
);

const DetailItemWithoutMinWidth = styled(SpacedProblemDetailItem)`
  min-width: 0;
`;

const LearnMoreText = styled(Text)`
  margin: 0;
`;

const UnexpectedSkuStack = styled(Stack)<ThemeProps<{ skuContainerVertical: boolean }>>(
  ({ theme, skuContainerVertical }) => `
    position: relative;
    margin-top: ${theme.spacing.S3};
    margin-bottom: ${theme.spacing.S3};
    align-items: ${skuContainerVertical ? "flex-start" : "center"};
  `
);

const ReceivedQuantity = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-top: 0;
    margin-bottom: ${theme.spacing.S4};
  `
);

const ShippingPlanLink = styled(Anchor)`
  cursor: pointer;
`;

export const UnexpectedSkuBlade: FC<UnexpectedSkuBladeProps> = ({
  id,
  shipmentProblem,
  onCloseClick,
  onShippingPlanClick,
  isBladeOpen,
  product,
  shippingPlanId,
  shippingPlanName,
}) => {
  const { status, updatedAt, units } = shipmentProblem;
  const { msku, dsku, productName } = product ?? {};
  const [isSkuContainerVertical, setIsSkuContainerVertical] = useState(false);
  const isMobile = useMobile();

  const checkSkuContainerPosition = (el: HTMLDivElement | null) => {
    if (isNil(el)) {
      return;
    }

    setIsSkuContainerVertical(el.offsetTop >= el.offsetHeight);
  };

  return (
    <ProblemBaseBlade
      id={id}
      title={PROBLEM_TYPE_LABEL_MAP[ProblemType.UNEXPECTED_SKU]}
      onCloseClick={onCloseClick}
      isBladeOpen={isBladeOpen}
      updatedAt={updatedAt}
    >
      <StatusSectionWithMargin
        status={status}
        messageOverride={<FormattedMessage {...UnexpectedSkuBladeMessages.STATUS_MESSAGE} />}
        headerOverride={<FormattedMessage {...UnexpectedSkuBladeMessages.STATUS} />}
      />
      <StyledStack>
        <DetailItemSection>
          <StyledStack gap="S4">
            <Text size="bodyLarge" as="span">
              <FormattedMessage {...UnexpectedSkuBladeMessages.DETAILS_TITLE} />
            </Text>
            <SpacedProblemDetailItem
              label={<FormattedMessage id="inbounds.unexpectedSkuBlade.shippingPlan" defaultMessage="Shipping plan" />}
              value={
                <>
                  <ShippingPlanLink onClick={onShippingPlanClick}>
                    <ShippingPlanNameDisplay shippingPlanId={shippingPlanId} shippingPlanName={shippingPlanName} />
                  </ShippingPlanLink>
                </>
              }
            />
            <UnexpectedSkuStack
              skuContainerVertical={isSkuContainerVertical}
              direction={isMobile || isSkuContainerVertical ? Direction.VERTICAL : Direction.HORIZONTAL}
            >
              {!isNil(dsku) && !isNil(msku) && (
                <>
                  <DetailItemWithoutMinWidth
                    label={<FormattedMessage {...UnexpectedSkuBladeMessages.BARCODE} />}
                    value={<SKULink dsku={dsku}>{dsku}</SKULink>}
                  />
                </>
              )}
              {!isNil(dsku) && !isNil(msku) && (
                <>
                  <DetailItemWithoutMinWidth
                    ref={(el) => checkSkuContainerPosition(el)}
                    label={<FormattedMessage {...UnexpectedSkuBladeMessages.VERIFIED_SKU} />}
                    value={<SKULink dsku={dsku}>{msku}</SKULink>}
                  />
                </>
              )}
            </UnexpectedSkuStack>
            {productName && (
              <SpacedProblemDetailItem
                label={<FormattedMessage {...UnexpectedSkuBladeMessages.NONCOMPLIANT_PRODUCT} />}
                value={productName}
              />
            )}
            <ReceivedQuantity>
              <FormattedMessage
                {...UnexpectedSkuBladeMessages.QUANTITY_RECEIVED_WITH_UNITS}
                values={{
                  quantityReceived: (
                    <strong>
                      {<FormattedMessage {...UnexpectedSkuBladeMessages.QUANTITY_RECEIVED} />} <br />{" "}
                    </strong>
                  ),
                  unitCount: units,
                  units: (
                    <FormattedPlural
                      value={units ?? 0}
                      one={<FormattedMessage id="units.one" defaultMessage="unit" />}
                      other={<FormattedMessage id="units.plural" defaultMessage="units" />}
                    />
                  ),
                }}
              />
            </ReceivedQuantity>
            <NotRightSkuText>
              <FormattedMessage
                {...UnexpectedSkuBladeMessages.CONTACT_SUPPORT}
                values={{
                  contactSupport: (
                    <SupportChatLauncher renderAsLink>
                      <Text bold>
                        <FormattedMessage {...UnexpectedSkuBladeMessages.CONTACT_SUPPORT_LINK} />
                      </Text>
                    </SupportChatLauncher>
                  ),
                }}
              />
            </NotRightSkuText>
            <LearnMoreText>
              <FormattedMessage
                {...UnexpectedSkuBladeMessages.LEARN_MORE}
                values={{
                  unexpectedBarcodes: (
                    <ElevioBorderlessButton articleId={ElevioArticleId.ReserveStorageNonCompliance}>
                      <Text bold>
                        <FormattedMessage {...UnexpectedSkuBladeMessages.UNEXPECTED_BARCODES_LINK} />
                      </Text>
                    </ElevioBorderlessButton>
                  ),
                }}
              />
            </LearnMoreText>
          </StyledStack>
        </DetailItemSection>
      </StyledStack>
    </ProblemBaseBlade>
  );
};
