import { DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import { isEmpty } from "lodash/fp";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import OrderReturnsList from "./OrderReturnsList";
import { useOrderReturnsList } from "./useOrderReturnsList";

const InfoLabel = styled.dt<{}, DefaultTheme>(
  ({ theme }) => `
    display: block;
    font-size: ${theme.font.size.F1};
    font-weight: ${theme.font.weight.BOLD};
    color: ${theme.colors.NEUTRAL[200]};
    margin-bottom: ${theme.spacing.S1};
  `
);

const OrderReturnsListContainer: FC = () => {
  const { orderId, orderReturnsList } = useOrderReturnsList();

  return !isEmpty(orderReturnsList) ? (
    <div>
      <InfoLabel>
        <FormattedMessage id={"returns.orderReturnsList.title"} defaultMessage={"Returns"} />
      </InfoLabel>
      <dd>
        <OrderReturnsList orderId={orderId} orderReturnsList={orderReturnsList} />
      </dd>
    </div>
  ) : null;
};

export default OrderReturnsListContainer;
