// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/** Flexport colors */\n/** Design system colors as of 8/27/21 **/\n.transferStatusTimeline__transferStatusDetailsButton--hVsgw {\n  background-color: #ffffff;\n  color: #2d8ced;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  padding-top: 18px;\n  padding-bottom: 18px;\n  cursor: pointer;\n  width: 100%;\n}\n.transferStatusTimeline__transferStatusDetails--pY1g1 {\n  background-color: #ffffff;\n  width: 100%;\n}\n.transferStatusTimeline__transferStatusFreightLink--Wrg5H {\n  margin-left: auto;\n}\n.transferStatusTimeline__transferStatusFreightLabel--KVi_R {\n  margin-right: 0.5em;\n  color: black;\n}\n", "",{"version":3,"sources":["webpack://./src/app/transfers/detail/ui/transferStatusTimeline.less"],"names":[],"mappings":"AAAA,qBAAqB;AACrB,yCAAyC;AACzC;EACE,yBAAA;EACA,cAAA;EACA,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,iBAAA;EACA,oBAAA;EACA,eAAA;EACA,WAAA;AACF;AAEA;EACE,yBAAA;EACA,WAAA;AAAF;AAGA;EACE,iBAAA;AADF;AAIA;EACE,mBAAA;EACA,YAAA;AAFF","sourcesContent":["@import \"~app/common/styles/colors\";\n\n.transferStatusDetailsButton {\n  background-color: @white;\n  color: @lightBlue;\n  display: flex;\n  padding-top: 18px;\n  padding-bottom: 18px;\n  cursor: pointer;\n  width: 100%;\n}\n\n.transferStatusDetails {\n  background-color: @white;\n  width: 100%;\n}\n\n.transferStatusFreightLink {\n  margin-left: auto;\n}\n\n.transferStatusFreightLabel {\n  margin-right: 0.5em;\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transferStatusDetailsButton": "transferStatusTimeline__transferStatusDetailsButton--hVsgw",
	"transferStatusDetails": "transferStatusTimeline__transferStatusDetails--pY1g1",
	"transferStatusFreightLink": "transferStatusTimeline__transferStatusFreightLink--Wrg5H",
	"transferStatusFreightLabel": "transferStatusTimeline__transferStatusFreightLabel--KVi_R"
};
export default ___CSS_LOADER_EXPORT___;
