import produce from "immer";

import { createReducer, ReducerDictionary } from "common/ReduxUtils";
import { FreightBookingSetupStepType } from "freight/steps/setup/FreightBookingSetupTypes";
import { FreightBookingAccordionStepsActionTypes } from "../actions/FreightBookingAccordionStepsActionTypes";
import { freightBookingAccordionStepsInitialState } from "./freightBookingAccordionStepsInitialState";
import { FreightBookingActionTypes } from "freight/store/freightBooking/actions/FreightBookingActionTypes";

export type FreightAccordionSteps = {
  [key in FreightBookingSetupStepType]: boolean;
};
const reducers: ReducerDictionary<Record<FreightBookingSetupStepType, boolean>> = {
  [FreightBookingAccordionStepsActionTypes.CONFIRM_STEP]: (state, action) => {
    return produce(state, (draft) => {
      draft[action.step] = true;
    });
  },
  [FreightBookingAccordionStepsActionTypes.UNCONFIRM_STEP]: (state, action) => {
    return produce(state, (draft) => {
      draft[action.step] = false;
    });
  },
  [FreightBookingActionTypes.FREIGHT_BOOKING_COMPLETED]: () => freightBookingAccordionStepsInitialState,
  [FreightBookingActionTypes.RESET_FREIGHT_BOOKING_STATE]: () => freightBookingAccordionStepsInitialState,
};

export const freightBookingAccordionStepsReducer = createReducer<FreightAccordionSteps>(
  freightBookingAccordionStepsInitialState,
  reducers
);
