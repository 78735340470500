import { FastTagState } from "common/clients/fastTag/FastTagState";
import { FastTagType } from "common/clients/fastTag/FastTagType";
import { SalesChannel } from "common/clients/onboarding/SalesChannel/SalesChannel";
import { onboardingClientV2 } from "common/clients/instances";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { toast } from "common/components/ui";
import { SPThunkAction } from "common/ReduxUtils";
import { retry } from "common/utils/Retry";
import log from "Logger";
import { SalesChannelsActionTypes, SalesChannelsState } from "./ChannelsReducers";
import { FormattedMessage } from "react-intl";
import { ChannelsErrorMessages } from "./ChannelsMessages";
import React from "react";

export const salesChannelRetryCalls = 2;
export const salesChannelRetryFactor = 1;
export const salesChannelRetryTimeout = 5000;

export const salesChannelLoaderId = "getSalesChannels";

export const setSalesChannels = (channels: SalesChannelsState) => ({
  type: SalesChannelsActionTypes.SET_SALES_CHANNELS,
  ...channels,
});

export const deactivateSalesChannels = (salesChannel: SalesChannel | undefined) => ({
  type: SalesChannelsActionTypes.DEACTIVATE_SALES_CHANNEL,
  salesChannel,
});

export const activateSalesChannels = (salesChannel: SalesChannel | undefined) => ({
  type: SalesChannelsActionTypes.ACTIVATE_SALES_CHANNEL,
  salesChannel,
});

const removeDeactivatedFacebookFastTags = (channel: SalesChannel): SalesChannel => {
  return {
    ...channel,
    availableFastTags: channel.availableFastTags.filter(
      (tag) => !(tag.name === FastTagType.FACEBOOK && tag.state === FastTagState.DEACTIVATED)
    ),
  };
};

export const getSalesChannels = (): SPThunkAction<Promise<void>> => async (dispatch, getState) => {
  const ctx = { fn: "getSalesChannels" };
  log.info(ctx, "getting sales channels");

  const {
    user: { sellerId },
  } = getState();

  dispatch(addLoader(salesChannelLoaderId));
  try {
    // eslint-disable-next-line @typescript-eslint/return-await
    const res = await retry(async () => onboardingClientV2.getSellerSalesChannels(sellerId), {
      retries: salesChannelRetryCalls,
      factor: salesChannelRetryFactor,
      minTimeout: salesChannelRetryTimeout,
    });
    if (res) {
      const { sellerId: _, ...channels } = res;

      channels.salesChannels = channels.salesChannels?.map(removeDeactivatedFacebookFastTags);
      channels.recommendedChannels = channels.recommendedChannels?.map(removeDeactivatedFacebookFastTags);
      channels.directChannels = channels.directChannels?.map(removeDeactivatedFacebookFastTags);
      channels.indirectChannels = channels.indirectChannels?.map(removeDeactivatedFacebookFastTags);

      dispatch(setSalesChannels(channels));
    }
  } catch (err) {
    log.error({ ...ctx, err }, "error getting sales channels");
    toast.error(<FormattedMessage {...ChannelsErrorMessages.getSalesChannels} />, {
      autoClose: 5000,
      toastId: "getSalesChannels",
    });
  } finally {
    dispatch(clearLoader(salesChannelLoaderId));
  }
};

export const deactivateSalesChannel =
  (slsUuid: string, hideDisconnectModal: () => void): SPThunkAction<Promise<void>> =>
  async (dispatch) => {
    const ctx = { fn: "disconnectSalesChannel" };
    log.info(ctx, "disconnecting sales channels");

    try {
      const salesChannel = await onboardingClientV2.deactivateSellerIntegration(slsUuid);
      dispatch(deactivateSalesChannels(salesChannel));
    } catch (err) {
      log.error({ ...ctx, err }, "error deactivating sales channel");
      toast.error(<FormattedMessage {...ChannelsErrorMessages.deactivateSalesChannel} />, {
        autoClose: 5000,
        toastId: "deactivateSalesChannel",
      });
    } finally {
      hideDisconnectModal();
    }
  };

export const activateSalesChannel =
  (slsUuid: string): SPThunkAction<Promise<void>> =>
  async (dispatch) => {
    const ctx = { fn: "activateSalesChannel" };
    log.info(ctx, "activating sales channels");

    try {
      const salesChannel = await onboardingClientV2.activateSellerIntegration(slsUuid);
      dispatch(activateSalesChannels(salesChannel));
    } catch (err) {
      log.error({ ...ctx, err }, "error activating sales channel");
      toast.error(<FormattedMessage {...ChannelsErrorMessages.activateSalesChannel} />, {
        autoClose: 5000,
        toastId: "activateSalesChannel",
      });
    }
  };
