import { ReducerDictionary, createReducer } from "common/ReduxUtils";
import { ReplenishmentNonComplianceActionTypes } from "./ReplenishmentNonComplianceAction";

export interface ReplenishmentNonComplianceState {
  replenishmentNonCompliantOrdersCount: number;
  wholesaleNonCompliantOrdersCount: number;
}

export const replenishmentNonComplianceInitialState: ReplenishmentNonComplianceState = {
  replenishmentNonCompliantOrdersCount: 0,
  wholesaleNonCompliantOrdersCount: 0,
};

const reducers: ReducerDictionary<ReplenishmentNonComplianceState> = {
  [ReplenishmentNonComplianceActionTypes.REPLENISHMENT_SET_NON_COMPLIANT_ORDERS_COUNT]: (state, action) => ({
    replenishmentNonCompliantOrdersCount: action.unresolvedNonCompliancesCount.replenishmentOrderCount,
    wholesaleNonCompliantOrdersCount: action.unresolvedNonCompliancesCount.wholesaleOrderCount,
  }),
};

export const replenishmentNonComplianceReducer = createReducer<ReplenishmentNonComplianceState>(
  replenishmentNonComplianceInitialState,
  reducers
);
