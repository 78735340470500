import React, { Children, FC } from "react";

import cn from "classnames";

import cls from "./Tooltip.less";

export enum TooltipSkin {
  ERROR = "error",
  PRIMARY = "primary",
}

export interface TooltipWrapperProps {
  className?: string;
}

export interface TooltipProps {
  active: boolean;
  className?: {
    content?: string;
    arrow?: string;
  };
  skin?: TooltipSkin;
  arrowReverse?: boolean;
}

export const TooltipWrapper: FC<TooltipWrapperProps> = ({ children, className }) => {
  return <div className={cn(cls.wrapper, className)}>{Children.toArray(children)}</div>;
};

export const Tooltip: FC<TooltipProps> = ({
  children,
  active,
  className = Object.create(null),
  skin,
  arrowReverse,
}) => {
  return active ? (
    <div
      className={cn(cls.content, className.content, {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        [cls[`skin-${skin}`]]: skin,
      })}
      data-testid="tooltip"
    >
      <svg
        x="0"
        y="0"
        className={cn(cls.arrow, { [cls["arrow-reverse"]]: arrowReverse }, className.arrow)}
        viewBox="0 0 255 255"
      >
        <path d="M0 0L127.5 127.5 255 0z" className="fill-current"></path>
      </svg>
      <div className={cls.text}>{React.Children.toArray(children)}</div>
    </div>
  ) : null;
};
