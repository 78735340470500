import { isNil } from "lodash/fp";
import { matchPath, useHistory } from "react-router-dom";

// <T extends Enum> does not exist in Typescript,
// so we make do with <T extends string>
export const useCurrentStep = <FlowStepType extends string>(
  // this type is the actual enum, but we pass it like this to allow
  // Object.values() to be called
  flowStepType: { [key: string]: FlowStepType },
  stepToPathMap: Record<FlowStepType, string>
) => {
  const history = useHistory();

  const flowSteps = Object.values(flowStepType);

  const currentStep = flowSteps.find(
    // look up the step that matches the current path
    (step) => !isNil(matchPath(history.location.pathname, { path: stepToPathMap[step] }))
  );

  return currentStep;
};
