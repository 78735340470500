import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

interface ShippingPlanNameDisplayProps {
  shippingPlanId?: number;
  shippingPlanName?: string;
}

export const ShippingPlanNameDisplay: FC<ShippingPlanNameDisplayProps> = ({ shippingPlanId, shippingPlanName }) =>
  shippingPlanName ? (
    <>{shippingPlanName}</>
  ) : (
    <FormattedMessage
      id="common.shippingPlanNameDisplay.shippingPlanId"
      defaultMessage="Shipping plan #{shippingPlanId}"
      values={{ shippingPlanId }}
    />
  );
