import styled from "@emotion/styled";
import { Card, DefaultTheme } from "common/components/ui";
import { CSSProperties } from "react";

export const CardContainer = styled.div`
  height: 700px;
  width: 62.5rem;
`;

export const StyledCard = styled(Card)<{ alignItems?: CSSProperties["alignItems"]; theme: DefaultTheme }>(
  ({ theme, alignItems }) => `
  display: flex;
  flex-direction: column;
  align-items: ${alignItems ?? "center"};
  padding: ${theme.spacing.S7} ${theme.spacing.S5};
  min-height: 32rem;
`
);

export * from "./Step";
export * from "./StepHeader";
export * from "./StepCard";
export * from "./ManagedStep";
export * from "./StepManager";
export * from "./useStepStack";
export * from "./MultiStepWizard";
export * from "./useStepManager";
