import { FlowStepBase } from "common/components/AccordionFlow/AccordionFlowTypes";

export interface FreightBookingSetupStep extends FlowStepBase {
  stepType: FreightBookingSetupStepType;
}

export enum FreightBookingSetupStepType {
  PICKUP_DETAILS = "PICKUP_DETAILS",
  DELIVERY_DETAILS = "DELIVERY_DETAILS",
  FREIGHT_INSTRUCTIONS = "FREIGHT_INSTRUCTIONS",
  CARGO_PALLET_DETAILS = "CARGO_PALLET_DETAILS",
  CONFIRM_SHIPMENT = "CONFIRM_SHIPMENT",
  DRAYAGE_DETAILS = "DRAYAGE_DETAILS",
  DRAYAGE_CARGO_DETAILS = "DRAYAGE_CARGO_DETAILS",
  FBA_DELIVERY_DETAILS = "FBA_DELIVERY_DETAILS",
}
