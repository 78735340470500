import styled from "@emotion/styled";
import HtmlToReact from "common/components/HtmlToReact";
import { ThemeProps, Text } from "common/components/ui";

export const SearchBox = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  background-color: ${theme.colors.NEUTRAL["00"]};
  border-radius: 1.5rem;
  border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL["100"]};
  width: 20rem;
  height: 2.5rem;
  padding: ${theme.spacing.S1} ${theme.spacing.S2};
  z-index: ${theme.zindex.Z6};
  @media (max-width: ${theme.breakpoints.SM}) {
    width: 15rem;
  }
`
);

export const SearchInput = styled.input<ThemeProps>(
  ({ theme }) => `
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 0 ${theme.spacing.S2};
  background-color: ${theme.colors.NEUTRAL["00"]};
`
);

export const SearchResultsList = styled.ul<ThemeProps>(
  ({ theme }) => `
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.NEUTRAL["00"]};
  border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL["100"]};
  border-radius: 1rem;
  margin-top: ${theme.spacing.S1};
  max-height: 25rem;
  overflow-y: auto;
  padding: 0;
  list-style: none;
  width: 20rem;
  z-index: ${theme.zindex.Z6};
  box-shadow: ${theme.shadow.S3};
  li:last-child {
    border-bottom: none;
  }
  @media (max-width: ${theme.breakpoints.SM}) {
    width: 15rem;
  }
`
);

export const SearchListItemRow = styled.li<ThemeProps>(
  ({ theme }) => `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing.S2} ${theme.spacing.S3};
  transition: background-color 0.3s, box-shadow 0.3s;
  &:hover {
    background-color: ${theme.colors.NEUTRAL["60"]};
    box-shadow: ${theme.shadow.S1};
    cursor: pointer;
  }
`
);

export const SearchListItem = styled.li<ThemeProps>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S1};
  border-bottom: 1px solid ${theme.colors.NEUTRAL["80"]};
`
);

export const ListItemHeading = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: ${theme.spacing.S1};
  padding: ${theme.spacing.S2};
`
);

export const StyledH5 = styled.h6<ThemeProps>(
  ({ theme }) => `
  font-size: ${theme.font.size.F1};
  font-weight: ${theme.font.weight.BOLD};
  margin: 0;
  color: ${theme.colors.NEUTRAL["300"]};
`
);

export const SearchListItemContent = styled.ul<ThemeProps>(
  ({ theme }) => `
  list-style-type: none;
  margin: 0;
  padding: 0;
`
);

export const TextContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  flex-grow: 1;
`
);

export const TitleText = styled(HtmlToReact)<ThemeProps>(
  ({ theme }) => `
  font-size: ${theme.font.size.F2};
  font-weight: ${theme.font.weight.BOLD};
  margin: 0;
  color: ${theme.colors.NEUTRAL["500"]};
  margin-bottom: ${theme.spacing.S1};
`
);

export const SubtitleText = styled.p<ThemeProps>(
  ({ theme }) => `
  font-size: ${theme.font.size.F1};
  margin: 0;
  color: ${theme.colors.NEUTRAL["400"]};
`
);

export const NoResultsMessage = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing.S6};
  text-align: center;
  color: ${theme.colors.NEUTRAL["500"]};
  font-size: ${theme.font.size.F2};
  background-color: ${theme.colors.NEUTRAL["00"]};
  border-radius: 8px;
  margin-top: 1rem;
  box-shadow: ${theme.shadow.S1};
`
);

export const ErrorMessage = styled(Text)<ThemeProps>(
  ({ theme }) => `
    padding: ${theme.spacing.S4};
  `
);
