import { sumBy } from "lodash/fp";
import { useDispatch, useSelector } from "react-redux";
import { selectWholesaleCreate } from "../store/WholesaleCreateSelectors";
import { WholesaleShipmentType, WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { loadWholesaleOrderAction } from "../actions/loadWholesaleOrder";
import { usePolling } from "common/hooks/usePolling";

export const useAutoRefreshWholesaleSummary = () => {
  const dispatch = useDispatch();
  const {
    selectedProducts,
    originStorageWarehouse,
    wholesaleOrder,
    errorType,
    shippingMethod,
    cargoType,
  } = useSelector(selectWholesaleCreate);

  const shipment = wholesaleOrder?.shipments ? wholesaleOrder.shipments[0] : undefined;

  const estimateFreightCost = shipment?.carrierInfo?.deliverrFreightQuoteInfo?.estimateFreightCost;
  const estimateParcelCost = shipment?.carrierInfo?.deliverrFreightQuoteInfo?.estimateParcelCost;

  const isEstimatingFreightCost = estimateFreightCost === undefined || estimateFreightCost === -1;
  const isEstimatingParcelCost = estimateParcelCost === undefined || estimateParcelCost === -1;
  const isDeliverrCarrier = shipment?.carrierInfo?.shippingMethod === WholesaleShippingMethod.DELIVERR;
  const isPallet = shipment?.shipmentType === WholesaleShipmentType.PARTIAL_PALLET;

  const isEstimating =
    !shipment?.costEstimate?.processingFeeDetails ||
    (isDeliverrCarrier && (isPallet ? isEstimatingFreightCost : isEstimatingParcelCost));
  const hasFailedEstimating = (wholesaleOrder?.estimations.estimationsFailed.length ?? 0) > 0;
  const hasPendingEstimations = (wholesaleOrder?.estimations.estimationsPending.length ?? 0) > 0;

  const numOfSkus = Object.keys(selectedProducts).length;
  const numOfUnits = sumBy(({ qty }) => qty, Object.values(selectedProducts));
  const numOfCases = sumBy(({ qty, caseQty }) => qty / (caseQty ?? 1), Object.values(selectedProducts));

  usePolling(3000, isEstimating, () => dispatch(loadWholesaleOrderAction()));

  return {
    numOfCases,
    numOfSkus,
    numOfUnits,
    originStorageWarehouse,
    toAddress: wholesaleOrder?.inputDestination,
    wholesaleOrder,
    isError: errorType ?? false,
    shippingMethod,
    cargoType,
    isEstimating,
    hasFailedEstimating,
    hasPendingEstimations,
    shipBy: shipment?.shipBy,
  };
};
