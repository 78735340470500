import { InboundBookingType, InboundSource } from "@deliverr/legacy-inbound-client";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { useHeapEvent } from "common/hooks/useHeapEvent";
import { GenericHeapInfo } from "common/types/GenericHeapInfo";
import { ShipmentDetailsHeapEvent } from "common/types/ShipmentDetailsHeapEvent";
import { goToCreateShipmentStep } from "inbounds/createShipment/routing/goToCreateShipmentStep";
import { clearInbound } from "inbounds/createShipment/store/actions";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { InboundSearchResult } from "inbounds/InboundTypes";
import { loadInbound } from "inbounds/steps/InboundLoadActions";
import { getIsInternationalFreightInbound } from "inbounds/utils/shippingPlan/getIsInternationalFreightInbound";
import { DynamicRoutes, Path } from "paths";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";
import { loadStorageInboundCreateDraft } from "storage/inbounds/create/store/actions/loadStorageInboundCreateDraft";

export const useUnconnectedInboundList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const heapEvent = useHeapEvent();
  const getRowKey = (shippingPlanId: number, shipmentId?: number) =>
    `${InboundLoaderId.rowButton}-${shippingPlanId}-${shipmentId ?? ""}`;
  const onRowClick = useCallback(
    async (row: InboundSearchResult) => {
      const { shippingPlanId, shipmentId, inboundSource, bookingType } = row;
      const rowKey = getRowKey(shippingPlanId, shipmentId);
      dispatch(addLoader(rowKey));
      dispatch(clearInbound());
      const isInternationalFreightInbound = getIsInternationalFreightInbound(
        bookingType as InboundBookingType,
        row.shippingPlanSkuCount
      );

      if (isInternationalFreightInbound && Boolean(row.bookingId)) {
        const info: GenericHeapInfo = {
          pageName: "shipment_details",
        };
        heapEvent("shipmentDetails.shipmentDetailsView" as ShipmentDetailsHeapEvent, info);
        history.push(DynamicRoutes.inboundsSmbShipmentDetails.parse({ shippingPlanId }));
      } else if (isInternationalFreightInbound) {
        dispatch(goToCreateShipmentStep({ shippingPlanId }));
      } else if (inboundSource !== InboundSource.STORAGE) {
        await dispatch(loadInbound(shippingPlanId, shipmentId));
      } else if (!row.shipmentStatus) {
        dispatch(loadStorageInboundCreateDraft(shippingPlanId));
      } else {
        history.push(generatePath(Path.inboundsStorageDetail, { shippingPlanId }));
      }
      dispatch(clearLoader(rowKey));
    },
    [dispatch, history, heapEvent]
  );
  return { onRowClick, getRowKey };
};
