import styled from "@emotion/styled";
import { TEXT_COLOR, ERROR_COLOR } from "../Input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/plain.css";
import { DefaultTheme } from "../shared";
import { transitionFast } from "../shared/helpers/css";
import { isMobile } from "../shared/helpers/utils";

export const StyledPhoneInput = styled(PhoneInput)<{ hasError?: boolean; disabled?: boolean }, DefaultTheme>(({
  hasError,
  disabled,
  theme,
}) => {
  const mobile = isMobile(theme);
  const flagWidth = "62px";

  return `
      font-family: ${theme.font.family.STANDARD};

      .form-control {
        ${transitionFast()}
        color: ${TEXT_COLOR(theme)};
        border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
        line-height: ${theme.font.lineHeight.LH2};
        height: ${theme.input.height};
        width: 100%;
        ${
          mobile
            ? `
              font-size: ${theme.font.size.F3};
              border-radius: ${theme.border.radius.R3};
              padding:
                ${theme.spacing.S3}
                ${theme.spacing.S4}
                ${theme.spacing.S3}
                calc(${flagWidth} + (${theme.border.width.B1} * 2) + ${theme.spacing.S4});
            `
            : `
              font-size: ${theme.font.size.F2};
              border-radius: ${theme.border.radius.R2};
              padding:
                ${theme.spacing.S2}
                ${theme.spacing.S3}
                ${theme.spacing.S2}
                calc(${flagWidth} + (${theme.border.width.B1} * 2) + ${theme.spacing.S3});
            `
        }

        &:focus {
          border-color: ${theme.colors.BLUE[300]};
          box-shadow: ${theme.input.focus.BLUE};
        }

        ${
          hasError
            ? `
                border-color: ${ERROR_COLOR(theme)};

                &:focus {
                  border-color: ${ERROR_COLOR(theme)};
                  box-shadow: ${theme.input.focus.RED};
                }
              `
            : ""
        }

        &:disabled {
          color: ${theme.colors.NEUTRAL[300]};
          background-color: ${theme.colors.NEUTRAL[40]};
          border-color: ${theme.colors.NEUTRAL[100]};
        }
      }

      .flag-dropdown:before {
        ${transitionFast()}
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: -${theme.border.width.B1};
        border-right: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};

        &:focus-within, &:hover, &.open {
          border-right-color: transparent;
        }

        ${
          hasError
            ? `
              border-color: ${ERROR_COLOR(theme)};
            `
            : ""
        }

        ${
          disabled
            ? `
              border-color: ${theme.colors.NEUTRAL[100]};
            `
            : ""
        }
      }

      .flag-dropdown {
        &:focus-within, &:hover, &.open {
          &:before {
            border-color: transparent;
          }
        }
      }

      .flag-dropdown {
        ${transitionFast()}
        border: ${theme.border.width.B1} ${theme.border.type} transparent;

        ${
          mobile
            ? `
              border-radius: ${theme.border.radius.R3} 0 0 ${theme.border.radius.R3};
            `
            : `
              border-radius: ${theme.border.radius.R2} 0 0 ${theme.border.radius.R2};
            `
        }

        &:focus-within, &:hover, &.open {
          border-color: ${theme.colors.BLUE[300]};
        }

        &:focus-within, &.open {
          box-shadow: ${theme.input.focus.BLUE};
        }

        &.open {
          z-index: auto;
        }

        ${
          hasError
            ? `
                border-color: ${ERROR_COLOR(theme)};

                &:focus-within, &:hover, &.open {
                  border-color: ${ERROR_COLOR(theme)};
                }

                &:focus-within, &.open {
                  box-shadow: ${theme.input.focus.RED};
                }
              `
            : ""
        }

        ${
          disabled
            ? `
              color: ${theme.colors.NEUTRAL[300]};
              background-color: ${theme.colors.NEUTRAL[40]};
              border-color: ${theme.colors.NEUTRAL[100]};

              &:focus-within, &:hover, &.open {
                border-color: ${theme.colors.NEUTRAL[100]};
              }

              &:focus-within, &.open {
                box-shadow: none;
              }
            `
            : ""
        }
      }

      .selected-flag {
        ${transitionFast()}
        background-color: ${theme.colors.NEUTRAL["00"]};
        width: ${flagWidth};
        padding-left: ${theme.spacing.S4};

        .arrow {
          left: 26px;
          border-top-color: ${theme.colors.NEUTRAL[300]};

          &.up {
            border-top: none;
            border-bottom-color: ${theme.colors.NEUTRAL[300]};
          }
        }

        &:hover, &:focus, &.open {
          background-color: transparent;

          .arrow {
            border-top-color: ${theme.colors.NEUTRAL[500]};

            &.up {
              border-bottom-color: ${theme.colors.NEUTRAL[500]};
            }
          }
        }

        ${
          disabled
            ? `
              background-color: ${theme.colors.NEUTRAL[40]};
              cursor: not-allowed;

              &:hover, &:focus, &.open {
                .arrow {
                  border-top-color: ${theme.colors.NEUTRAL[300]};

                  &.up {
                    border-bottom-color: ${theme.colors.NEUTRAL[300]};
                  }
                }
              }
            `
            : ""
        }
      }

      .country-list {
        margin-top: ${theme.spacing.S1};
        border-color: ${theme.colors.NEUTRAL[80]};
        border-radius: ${mobile ? theme.border.radius.R3 : theme.border.radius.R2};
        padding: ${theme.spacing.S1};
        z-index: ${theme.zindex.Z3};

        > .country {
          padding: ${theme.spacing.S3};

          &.highlight, &:hover {
            background-color: ${theme.colors.NEUTRAL[40]};
          }
        }

        > .divider {
          border-bottom-color: ${theme.colors.NEUTRAL[80]};
        }
      }
    `;
});
