import { toast } from "common/components/ui";
import { logError, logStart } from "Logger";
import React from "react";
import { FormattedMessage } from "react-intl";
import debounce from "lodash/debounce";
import { DeliverrAddress } from "@deliverr/commons-objects";
import { fetchAddress } from "./fetchAddress";

const search = async (
  searchQuery: string,
  addresses: DeliverrAddress[],
  pageNum: number = 0
): Promise<DeliverrAddress[]> => {
  try {
    const { uniqueAddresses, totalPages } = await fetchAddress(searchQuery, pageNum);

    addresses = [...addresses, ...uniqueAddresses];

    // If we have more than 5 unique addresses or we have reached the last page, return the current addresses
    if (addresses.length > 5 || totalPages <= pageNum + 1) {
      return addresses;
    }

    // Otherwise, recursively call the search function with the next page
    return await search(searchQuery, addresses, pageNum + 1);
  } catch (error) {
    logError({ fn: "loadOptionsAddressSearch", query: searchQuery }, error, "error searching for addresses");
    toast.error(<FormattedMessage id="addressSearch.error" defaultMessage="Error searching for addresses" />, {
      toastId: "addressSearch.error",
    });
    return [];
  }
};

export const loadOptions = debounce((query: string, callback: (results: DeliverrAddress[]) => void) => {
  const addresses: DeliverrAddress[] = [];
  logStart({ fn: "loadOptionsAddressSearch", query });

  void search(query, addresses).then(callback);
}, Number(process.env.DEFAULT_SEARCH_THROTTLE_MS));
