import { WholesaleShipmentType, WholesaleShippingMethod } from "@deliverr/wholesale-client";
import React, { FC } from "react";
import { Box, IconV2, Text } from "common/components/ui";
import { FormattedMessage, useIntl } from "react-intl";
import { transfersShipmentCreateLabels } from "../../../transfersShipmentCreate.labels";
import { FlexRadioTileGroup } from "./FlexRadioTileGroup";
import { Currency, CurrencyFormat } from "common/components/Currency";
import { GetParcelContent } from "./GetParcelContent";

interface Props {
  shippingMethod: WholesaleShippingMethod;
  shipmentType?: WholesaleShipmentType;
  palletEstimation?: number;
  parcelEstimation?: number;
  isPendingPallet: boolean;
  isPendingParcel: boolean;
  showParcelEstimationError: boolean;
  onChange: (value: WholesaleShipmentType) => void;
}

export const InlineSpinner = () => <IconV2 type="loading" spin data-testid="loading-spinner" />;

export const Amount = ({ value }: { value: number }) => (
  <Currency currency={CurrencyFormat.USD} value={value} maximumFractionDigits={0} />
);

const DeliverrPalletOptionDescription: FC<Pick<Props, "isPendingPallet" | "palletEstimation" | "parcelEstimation">> = ({
  isPendingPallet,
  palletEstimation,
  parcelEstimation,
}) => {
  return isPendingPallet ? (
    <div>
      <InlineSpinner />
      <FormattedMessage {...transfersShipmentCreateLabels.steps.shipping.cargoOptions.generatingQuote} />
    </div>
  ) : palletEstimation !== undefined && parcelEstimation !== undefined ? (
    <FormattedMessage
      {...transfersShipmentCreateLabels.steps.shipping.cargoOptions.estimatedShippingCost}
      values={{
        formattedAmount: <Amount value={palletEstimation} />,
      }}
    />
  ) : null;
};

export const AmazonShipmentType: FC<Props> = ({
  shippingMethod,
  onChange,
  shipmentType,
  isPendingPallet,
  isPendingParcel,
  palletEstimation,
  parcelEstimation,
  showParcelEstimationError,
}) => {
  const { formatMessage } = useIntl();

  const isAmazonMethod = shippingMethod === WholesaleShippingMethod.SELLER;

  return (
    <Box width="80%" paddingBottom="S6" data-testid="shipment-type-selector">
      <Box paddingBottom="S5">
        <Text bold>
          <FormattedMessage {...transfersShipmentCreateLabels.steps.shipping.selectShippingMethod} />
        </Text>
      </Box>
      <FlexRadioTileGroup
        direction="HORIZONTAL"
        options={[
          {
            value: WholesaleShipmentType.PARTIAL_PALLET,
            label: formatMessage(transfersShipmentCreateLabels.steps.shipping.cargoOptions.pallet.fbaAutoLabel),
            disabled: false,
            content:
              shippingMethod === WholesaleShippingMethod.DELIVERR ? (
                <DeliverrPalletOptionDescription
                  isPendingPallet={isPendingPallet}
                  palletEstimation={palletEstimation}
                  parcelEstimation={parcelEstimation}
                />
              ) : (
                <FormattedMessage
                  {...transfersShipmentCreateLabels.steps.shipping.cargoOptions.pallet
                    .amazonFreightEstimatedDescriptionMessage}
                />
              ),
          },
          {
            value: WholesaleShipmentType.PARCEL,
            label: formatMessage(transfersShipmentCreateLabels.steps.shipping.cargoOptions.parcel.fbaAutoLabel),
            disabled:
              (parcelEstimation === undefined && palletEstimation !== undefined) ||
              (showParcelEstimationError && isAmazonMethod),
            content: (
              <GetParcelContent
                isPendingParcel={isPendingParcel}
                parcelEstimation={parcelEstimation}
                showParcelEstimationError={showParcelEstimationError}
                isAmazonMethod={isAmazonMethod}
              />
            ),
          },
        ]}
        currentValue={shipmentType}
        onChange={(evt) => {
          onChange(evt.currentTarget.value as WholesaleShipmentType);
        }}
      />
      {isAmazonMethod && (
        <Box paddingTop="S2">
          <Text size="caption" as="footer" appearance="INFO">
            <FormattedMessage
              {...transfersShipmentCreateLabels.steps.shipping.cargoOptions.pallet.amazonFreightEstimatedCaptionMessage}
            />
          </Text>
        </Box>
      )}
    </Box>
  );
};
