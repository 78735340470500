import { IBrowserSettings } from "@splitsoftware/splitio/types/splitio";
import { mustBeDefined } from "common/utils/mustBeDefined";
import { SPLIT_LOCAL_STORAGE_PREFIX } from "./localStoragePrefix";
import { FeatureName } from "./FeatureName";

// avoid slow connections not loading features properly. the default is 1.5s
const SPLIT_READY_TIMEOUT_SECONDS = 15;

export const getSplitConfig = (sellerId: string): IBrowserSettings => ({
  core: {
    authorizationKey: mustBeDefined(process.env.SPLIT_AUTHORIZATION_KEY),
    key: sellerId,
  },
  startup: {
    readyTimeout: SPLIT_READY_TIMEOUT_SECONDS,
  },
  storage: {
    type: "LOCALSTORAGE",
    prefix: SPLIT_LOCAL_STORAGE_PREFIX,
  },
  sync: {
    splitFilters: [
      {
        type: "byName",
        values: Object.values(FeatureName),
      },
    ],
  },
});
