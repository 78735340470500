import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { ShippingOptionZone } from "common/clients/onboarding/ShippingOptionZone";
import { uniqueId } from "lodash/fp";
import { ShippingOptionActionTypes } from "./ShippingOptionActions";

export interface ShippingOptionMappingState {
  shippingOptionZones: ShippingOptionZone[];
  hasUnsavedChanges: boolean;
}

export const shippingOptionInitialState: ShippingOptionMappingState = {
  shippingOptionZones: [],
  hasUnsavedChanges: false,
};

export function getZone(
  id: number,
  state: ShippingOptionMappingState
): [ShippingOptionZone, number, ShippingOptionZone[]] {
  const allZones = [...state.shippingOptionZones];
  const zoneIndex = allZones.findIndex((x) => x.id === id);
  const zone = allZones[zoneIndex];
  return [zone!, zoneIndex, allZones];
}

export const reducers: ReducerDictionary<ShippingOptionMappingState> = {
  ...handleSimpleReducerUpdates([
    ShippingOptionActionTypes.CREATE_SHIPPING_OPTIONS_SUCCESS,
    ShippingOptionActionTypes.FETCH_SHIPPING_OPTIONS_SUCCESS,
    ShippingOptionActionTypes.UPDATE_SHIPPING_OPTIONS_SUCCESS,
  ]),
  [ShippingOptionActionTypes.SHIPPING_OPTION_ADD]: (state, action) => {
    const [zone, zoneIndex, allZones] = getZone(action.zoneId, state);
    const updatedZone = {
      ...zone!,
      shippingOptions: [...zone!.shippingOptions, { id: Number(uniqueId("")), name: "", requiredDeliveryInDays: 7 }],
    };
    return {
      ...state,
      hasUnsavedChanges: true,
      shippingOptionZones: Object.assign([], allZones, { [zoneIndex]: updatedZone }),
    };
  },
  [ShippingOptionActionTypes.SHIPPING_OPTION_REMOVE]: (state, action) => {
    const { optionId } = action;
    const [zone, zoneIndex, allZones] = getZone(action.zoneId, state);
    const updatedZone = {
      ...zone!,
      shippingOptions: zone!.shippingOptions.filter((x) => x.id !== optionId),
    };
    return {
      ...state,
      hasUnsavedChanges: true,
      shippingOptionZones: Object.assign([], allZones, { [zoneIndex]: updatedZone }),
    };
  },
  [ShippingOptionActionTypes.SHIPPING_OPTION_UPDATE]: (state, action) => {
    const { optionId, name, requiredDeliveryInDays } = action;
    const [zone, zoneIndex, allZones] = getZone(action.zoneId, state);
    const updatedZone = {
      ...zone!,
      shippingOptions: [
        ...zone!.shippingOptions.map((x) => (x.id === optionId ? { id: optionId, name, requiredDeliveryInDays } : x)),
      ],
    };
    return {
      ...state,
      hasUnsavedChanges: true,
      shippingOptionZones: Object.assign([], allZones, { [zoneIndex]: updatedZone }),
    };
  },
};

export const shippingOptionReducer = createReducer<ShippingOptionMappingState>(shippingOptionInitialState, reducers);
