import produce from "immer";
import { concat, pipe, uniq } from "lodash/fp";
import { AnyAction, Reducer } from "redux";
import { Notification } from "common/clients/notifications/Notification/Notification";
import { createReducer, ReducerDictionary } from "common/ReduxUtils";
import { NOTIFICATIONS_PER_PAGE, NotificationsActionTypes } from "./NotificationsActions";

export interface NotificationsState {
  byId: Record<number, Notification>;
  sortedIds: number[];
  dlvrPaginationParams: PaginationParams;
  smbPaginationParams: PaginationParams;
  nextPage: number;
  hasNextPage: boolean;
  loading: boolean;
  error: boolean;
  viewed: boolean;
}

export const notificationsInitialState: NotificationsState = {
  byId: {},
  sortedIds: [],
  nextPage: 0,
  hasNextPage: true,
  dlvrPaginationParams: {
    offset: 0,
    hasNextPage: true,
    hasError: false,
    reserveList: [],
  },
  smbPaginationParams: {
    offset: 0,
    hasNextPage: true,
    hasError: false,
    reserveList: [],
  },
  loading: false,
  error: false,
  viewed: false,
};

export interface PaginationParams {
  offset: number;
  hasNextPage: boolean;
  hasError: boolean;
  reserveList: Notification[];
  lastId?: number;
}

const deduplicate = pipe<number[], number[], number[], number[]>(concat, uniq);

const reducers: ReducerDictionary<NotificationsState> = {
  [NotificationsActionTypes.FETCH_NOTIFICATIONS_START]: (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  [NotificationsActionTypes.FETCH_NOTIFICATIONS_SUCCESS]: (
    state,
    { payload, dlvrPaginationParams, smbPaginationParams }
  ) =>
    produce(state, (draft) => {
      const notifications = payload as Notification[];

      for (const notification of notifications) {
        draft.byId[notification.id] = notification;
      }

      // Deduplicate in case notifications changed since last page loaded,
      // resulting in an already seen notification being seen again.
      draft.sortedIds = deduplicate(
        draft.sortedIds,
        notifications.map((x) => x.id)
      );

      draft.nextPage += 1;
      draft.hasNextPage = notifications.length === NOTIFICATIONS_PER_PAGE;
      draft.loading = false;
      draft.error = false;

      if (dlvrPaginationParams) {
        draft.dlvrPaginationParams = {
          ...dlvrPaginationParams,
        };
        draft.error = dlvrPaginationParams.hasError;
      }
      if (smbPaginationParams) {
        draft.smbPaginationParams = {
          ...smbPaginationParams,
        };
        draft.error = draft.error || smbPaginationParams.hasError;
      }
    }),
  [NotificationsActionTypes.FETCH_NOTIFICATIONS_ERROR]: (state) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = true;
    }),
  [NotificationsActionTypes.VIEW_NOTIFICATIONS]: (state) =>
    produce(state, (draft) => {
      draft.viewed = true;
    }),
  [NotificationsActionTypes.RESET_NOTIFICATIONS]: () => notificationsInitialState,
};

export const notificationsReducer: Reducer<NotificationsState, AnyAction> = createReducer<NotificationsState>(
  notificationsInitialState,
  reducers
);
