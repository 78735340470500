export enum ReplenishmentListActionTypes {
  REPLENISHMENT_LIST_GET_LOADING = "REPLENISHMENT_LIST_GET_LOADING",
  REPLENISHMENT_LIST_GET_SUCCESS = "REPLENISHMENT_LIST_GET_SUCCESS",
  REPLENISHMENT_LIST_GET_ERROR = "REPLENISHMENT_LIST_GET_ERROR",
  REPLENISHMENT_LIST_CHANGE_PAGE_NUM = "REPLENISHMENT_LIST_CHANGE_PAGE_NUM",
  REPLENISHMENT_LIST_CHANGE_SEARCH_TERM = "REPLENISHMENT_LIST_CHANGE_SEARCH_TERM",
  REPLENISHMENT_LIST_CHANGE_DESTINATION_FILTERS = "REPLENISHMENT_LIST_CHANGE_DESTINATION_SEARCH_FILTERS",
  REPLENISHMENT_LIST_CHANGE_STATUS_FILTERS = "REPLENISHMENT_LIST_CHANGE_STATUS_SEARCH_FILTERS",
  DELETE_REPLENISHMENT_ORDER_SUCCESS = "DELETE_REPLENISHMENT_ORDER_SUCCESS",
  REPLENISHMENT_LIST_SET_TAB_FILTER = "REPLENISHMENT_LIST_SET_TAB_FILTER",
  UNSET_SHOW_CHANNEL_REPLENISHMENT_BANNER = "UNSET_SHOW_CHANNEL_REPLENISHMENT_BANNER",
}
