import { useStepStack } from "./useStepStack";
import { Step } from "./Step";

export interface StepManager {
  currentStep: Step;
  hasBack: () => boolean;
  isDone: () => boolean;
  next: (value?) => void;
  back: () => void;
  reassess: (newNextStep?: string) => void;
  replace: (newStep: string) => void;
}

export interface StepManagerHandlers {
  done?: () => void;
  onStackChanged?: (newStack: string[]) => void;
  beforeStackChange?: (nextStep: string | string[]) => void;
}
export const stepManagerFactory: (
  stepStack: ReturnType<typeof useStepStack>,
  handlers?: StepManagerHandlers
) => StepManager = (stepStack, handlers) => {
  const { currentStep, pushStep, popStep, size, revert, replace, stepStack: steps } = stepStack;

  return {
    currentStep,
    hasBack() {
      return size === 1 ? !!currentStep.back : !currentStep.hasNoBack;
    },
    isDone() {
      return !currentStep.next;
    },
    async next(value: any) {
      if (currentStep.next) {
        const nextStep = await currentStep.next(value, stepStack, handlers);
        if (nextStep) {
          handlers?.beforeStackChange?.(nextStep);
          const newStack = pushStep(nextStep);
          handlers?.onStackChanged?.(newStack);
        }
      } else {
        await handlers?.done?.();
      }
    },
    reassess(newNextStep?: string) {
      const reassessedStep = currentStep.reassess?.();
      if (reassessedStep) {
        revert(reassessedStep);
      } else if (newNextStep) {
        handlers?.beforeStackChange?.(newNextStep);
        const newStack = pushStep(newNextStep);
        handlers?.onStackChanged?.(newStack);
      }
    },
    back() {
      if (size === 1) {
        currentStep.back?.();
        return;
      }
      handlers?.beforeStackChange?.(steps[size - 2]);
      const newStack = popStep();
      handlers?.onStackChanged?.(newStack);
    },
    replace(newStep: string) {
      const newStack = replace(newStep);
      handlers?.onStackChanged?.(newStack);
    },
  };
};
