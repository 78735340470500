import { Appearance } from "common/components/ui/shared";
import React, { FC } from "react";
import { Notification } from "common/clients/notifications/Notification/Notification";
import { NotificationItem } from "../NotificationItem/NotificationItem";
import { useNotificationDetail } from "./useNotificationDetail";

export interface NotificationDetailProps {
  notification: Notification;
  onCallToActionClick: () => void;
}

export const NotificationDetail: FC<NotificationDetailProps> = ({ notification, onCallToActionClick }) => {
  const { date, message, callToActionMessage, callToActionPath } = useNotificationDetail(notification);

  if (!message) {
    return null;
  }

  return (
    <NotificationItem
      date={date}
      title={message}
      iconType="exclamation-circle"
      iconAppearance={Appearance.DANGER}
      callToActionPath={callToActionPath}
      callToActionMessage={callToActionMessage}
      onCallToActionClick={onCallToActionClick}
    />
  );
};
