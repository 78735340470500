import { createActionCreator } from "common/ReduxUtils";
import { CreateShipmentFormState, CreateShipmentMetaState } from "../CreateShipmentState";
import { CreateShipmentActionTypes, CreateShipmentFormActionTypes } from "../CreateShipmentActionTypes";

/**
 * This file is for simple store updates that can be used with handleSimpleReducerUpdates.
 * Do not include any data transformations or api calls here, use a seperate file for complex updates.
 * */

export const setOrigin = createActionCreator<CreateShipmentFormState["origin"]>(
  CreateShipmentFormActionTypes.SET_ORIGIN,
  "origin"
);

export const setIsLiftgateRequired = createActionCreator<CreateShipmentFormState["isLiftgateRequired"]>(
  CreateShipmentFormActionTypes.SET_IS_LIFTGATE_REQUIRED,
  "isLiftgateRequired"
);

export const setDeliveryDate = createActionCreator<CreateShipmentFormState["deliveryDate"]>(
  CreateShipmentFormActionTypes.SET_DELIVERY_DATE,
  "deliveryDate"
);

export const setUnitSystem = createActionCreator<CreateShipmentFormState["unitSystem"]>(
  CreateShipmentFormActionTypes.SET_UNIT_SYSTEM,
  "unitSystem"
);

export const setShipmentTotals = createActionCreator<CreateShipmentFormState["shipmentTotals"]>(
  CreateShipmentFormActionTypes.SET_SHIPMENT_TOTALS,
  "shipmentTotals"
);

export const setHasDangerousGoods = createActionCreator<CreateShipmentFormState["hasDangerousGoods"]>(
  CreateShipmentFormActionTypes.SET_HAS_DANGEROUS_GOODS,
  "hasDangerousGoods"
);

export const setDangerousGoods = createActionCreator<CreateShipmentFormState["dangerousGoods"]>(
  CreateShipmentFormActionTypes.SET_DANGEROUS_GOODS,
  "dangerousGoods"
);

export const setCargoReadyDate = createActionCreator<CreateShipmentFormState["cargoReadyDate"]>(
  CreateShipmentFormActionTypes.SET_CARGO_READY_DATE,
  "cargoReadyDate"
);

export const setAmazonDestinationType = createActionCreator<CreateShipmentFormState["amazonDestinationType"]>(
  CreateShipmentFormActionTypes.SET_AMAZON_DESTINATION_TYPE,
  "amazonDestinationType"
);

export const setAmazonAddress = createActionCreator<CreateShipmentFormState["amazonAddress"]>(
  CreateShipmentFormActionTypes.SET_AMAZON_ADDRESS,
  "amazonAddress"
);

export const setReserveStorage = createActionCreator<CreateShipmentFormState["reserveStorage"]>(
  CreateShipmentFormActionTypes.SET_RESERVE_STORAGE,
  "reserveStorage"
);

export const setCrossdockWarehouse = createActionCreator<CreateShipmentFormState["crossdockWarehouse"]>(
  CreateShipmentFormActionTypes.SET_CROSSDOCK_WAREHOUSE,
  "crossdockWarehouse"
);

export const setFreightService = createActionCreator<CreateShipmentFormState["freightService"]>(
  CreateShipmentFormActionTypes.SET_FREIGHT_SERVICE,
  "freightService"
);

export const setOriginType = createActionCreator<CreateShipmentFormState["originType"]>(
  CreateShipmentFormActionTypes.SET_ORIGIN_TYPE,
  "originType"
);

export const setDomesticDestinationType = createActionCreator<CreateShipmentFormState["domesticDestinationType"]>(
  CreateShipmentFormActionTypes.SET_DOMESTIC_DESTINATION_TYPE,
  "domesticDestinationType"
);

export const setDistributionType = createActionCreator<CreateShipmentFormState["distributionType"]>(
  CreateShipmentFormActionTypes.SET_DISTRIBUTION_TYPE,
  "distributionType"
);

export const setSupplier = createActionCreator<CreateShipmentFormState["supplier"]>(
  CreateShipmentFormActionTypes.SET_SUPPLIER,
  "supplier"
);

export const setOriginFacilityName = createActionCreator<CreateShipmentFormState["originFacilityName"]>(
  CreateShipmentFormActionTypes.SET_ORIGIN_FACILITY_NAME,
  "originFacilityName"
);

export const setDestinationFacilityName = createActionCreator<CreateShipmentFormState["destinationFacilityName"]>(
  CreateShipmentFormActionTypes.SET_DESTINATION_FACILITY_NAME,
  "destinationFacilityName"
);

export const setDestinationAddressFid = createActionCreator<CreateShipmentFormState["destinationAddressFid"]>(
  CreateShipmentFormActionTypes.SET_DESTINATION_ADDRESS_FID,
  "destinationAddressFid"
);

export const setHasCargoInsurance = createActionCreator<CreateShipmentFormState["hasCargoInsurance"]>(
  CreateShipmentFormActionTypes.SET_HAS_CARGO_INSURANCE,
  "hasCargoInsurance"
);

export const setAmazonPrepType = createActionCreator<CreateShipmentFormState["amazonPrepType"]>(
  CreateShipmentFormActionTypes.SET_AMAZON_PREP_TYPE,
  "amazonPrepType"
);

export const setInsuranceInfo = createActionCreator<CreateShipmentFormState["insuranceInfo"]>(
  CreateShipmentFormActionTypes.SET_INSURANCE_INFO,
  "insuranceInfo"
);

export const setBookingProducts = createActionCreator<CreateShipmentFormState["bookingProducts"]>(
  CreateShipmentFormActionTypes.SET_BOOKING_PRODUCTS,
  "bookingProducts"
);

export const setAirShipmentCargoDetails = createActionCreator<CreateShipmentFormState["airShipmentCargoDetails"]>(
  CreateShipmentFormActionTypes.SET_AIR_SHIPMENT_CARGO_DETAILS,
  "airShipmentCargoDetails"
);

export const setHasAnnualCustomsBond = createActionCreator<CreateShipmentFormState["hasAnnualCustomsBond"]>(
  CreateShipmentFormActionTypes.SET_HAS_ANNUAL_CUSTOMS_BOND,
  "hasAnnualCustomsBond"
);

export const setFlexportCapitalSelected = createActionCreator<CreateShipmentFormState["flexportCapitalSelected"]>(
  CreateShipmentFormActionTypes.SET_FLEXPORT_CAPITAL_SELECTED,
  "flexportCapitalSelected"
);

export const setLithiumBatteryPackaging = createActionCreator<CreateShipmentFormState["lithiumBatteryPackaging"]>(
  CreateShipmentFormActionTypes.SET_LITHIUM_BATTERY_PACKAGING,
  "lithiumBatteryPackaging"
);

export const setIsOriginPort = createActionCreator<CreateShipmentFormState["isOriginPort"]>(
  CreateShipmentFormActionTypes.SET_IS_ORIGIN_PORT,
  "isOriginPort"
);

export const setCargoInputType = createActionCreator<CreateShipmentFormState["cargoInputType"]>(
  CreateShipmentFormActionTypes.SET_CARGO_INPUT_TYPE,
  "cargoInputType"
);

export const setIsCargoMixedSku = createActionCreator<CreateShipmentFormState["isCargoMixedSku"]>(
  CreateShipmentFormActionTypes.SET_IS_CARGO_MIXED_SKU,
  "isCargoMixedSku"
);

export const setDtcPrepOption = createActionCreator<CreateShipmentFormState["dtcPrepOption"]>(
  CreateShipmentFormActionTypes.SET_DTC_PREP_OPTION,
  "dtcPrepOption"
);

export const setMixedSkuPackages = createActionCreator<CreateShipmentFormState["mixedSkuPackages"]>(
  CreateShipmentFormActionTypes.SET_MIXED_SKU_PACKAGES,
  "mixedSkuPackages"
);

// META STATE - PLACE FORM STATE UPDATES ABOVE THIS LINE
export const setStepStack = createActionCreator<CreateShipmentMetaState["stepStack"]>(
  CreateShipmentActionTypes.SET_STEP_STACK,
  "stepStack"
);

export const setShippingPlanId = createActionCreator<CreateShipmentMetaState["shippingPlanId"]>(
  CreateShipmentActionTypes.SET_SHIPPING_PLAN_ID,
  "shippingPlanId"
);

export const setIsSaving = createActionCreator<CreateShipmentMetaState["isSaving"]>(
  CreateShipmentActionTypes.SET_IS_SAVING,
  "isSaving"
);

export const hideIpbDevTools = createActionCreator<CreateShipmentMetaState["isIpbDevToolsHidden"]>(
  CreateShipmentActionTypes.HIDE_IPB_DEV_TOOLS,
  "isIpbDevToolsHidden"
);

export const setIsLoading = createActionCreator<CreateShipmentMetaState["isLoading"]>(
  CreateShipmentActionTypes.SET_IS_LOADING,
  "isLoading"
);

export const setIsReturningUser = createActionCreator<CreateShipmentMetaState["isReturningUser"]>(
  CreateShipmentActionTypes.SET_IS_RETURNING_USER,
  "isReturningUser"
);
