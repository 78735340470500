export function loadChameleon() {
  const chameleon = require("@chamaeleonidae/chmln");

  chameleon.init(process.env.CHAMELEON_IO_TOKEN);
}

export function identifyUserInChameleon(user) {
  const chameleon = require("@chamaeleonidae/chmln");

  chameleon.identify(user.sellerId, {
    email: user.email,
    name: user.name,
  });
}
