import { RootState } from "RootReducer";
import { PlannedShipment } from "inbounds/InboundTypes";
import { getLoadedPlannedShipment } from "inbounds/steps/InboundStepSelectors";
import { createSelector } from "reselect";
import { selectIsForwardingPlan } from "../plan/selectIsForwardingPlan";
import { selectCanDownloadPrepPalletLabels } from "./selectCanDownloadPrepPalletLabels";
import { getIsFreightDeliverr } from "inbounds/utils/shippingMethodUtils";

export const selectCanDownloadPalletLabels = createSelector<RootState, boolean, PlannedShipment, boolean, boolean>(
  selectIsForwardingPlan,
  getLoadedPlannedShipment,
  selectCanDownloadPrepPalletLabels,
  (isForwarding, { shippingMethod }, canDownloadPrepPalletLabels) => {
    return isForwarding && (getIsFreightDeliverr(shippingMethod) || canDownloadPrepPalletLabels);
  }
);
