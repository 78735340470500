import React, { FC, useEffect, useState } from "react";
import { SidebarItemContainer } from "./SidebarItemContainer";
import { SidebarItemContent } from "./SidebarItemContent";
import { useSidebarContext } from "./SidebarContext";
import { SidebarList } from "./SidebarList";

export interface SidebarSectionProps {
  title: string;
  defaultExpanded?: boolean;
}

/**
 * Expandable section of sidebar items with clickable header. Unless otherwise specified, expanded by default on desktop and collapsed by default on mobile.
 */
export const SidebarSection: FC<SidebarSectionProps> = ({ title, children, defaultExpanded = true }) => {
  const { mode } = useSidebarContext();
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  useEffect(() => setIsExpanded(mode === "DESKTOP" && defaultExpanded), [defaultExpanded, mode]);

  const onHeaderClick = () => setIsExpanded((expanded) => !expanded);

  const headerItem = (
    <SidebarItemContainer mode={mode} active={false}>
      <button onClick={onHeaderClick} aria-expanded={isExpanded}>
        <SidebarItemContent description={title} icon={isExpanded ? "angle-down" : "angle-right"} />
      </button>
    </SidebarItemContainer>
  );

  return (
    <SidebarList>
      {headerItem}
      {isExpanded && children}
    </SidebarList>
  );
};
