import React from "react";
import styled from "@emotion/styled";
import { DropzoneProps as ReactDropzoneProps } from "react-dropzone";
import { defaultTheme, ThemeProps } from "../shared";
import { Icon } from "../Icon";
import { LocalizedMessage } from "../types";

export type FileTypes = "image" | "csv" | "doc" | "pdf" | "video";

export interface UploadFileValue {
  id?: string;
  url?: string; // Should be the url from S3 or file storing service.
  fileName?: string;
  externalUrl?: string;
}

export interface DropzoneProps {
  id: string;
  isMandatory?: boolean;
  acceptedFileTypes: FileTypes[];
  onChange: ReactDropzoneProps["onDrop"];
  onFileClear: () => Promise<void>;
  loading?: boolean;
  label?: LocalizedMessage;
  helpText?: LocalizedMessage;
  value?: UploadFileValue;
  progressPercent?: number;
  dropzoneProps?: ReactDropzoneProps;
  errorText?: LocalizedMessage;
  fullWidth?: boolean;
}

export const FILE_TYPE_NAMES: Record<FileTypes, string> = {
  image: "image/jpg, image/png, image/jpeg, image/gif, image/bmp, image/webp",
  csv: "text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  doc: "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  pdf: "application/pdf",
  video: "video/mp4, video/quicktime",
};

export const FILE_TYPE_OPTIONS: Record<FileTypes, string[]> = {
  image: ["jpg", "jpeg", "png", "gif", "bmp", "webp"],
  csv: ["csv", "xlsx"],
  doc: ["docx", "doc", "dot"],
  pdf: ["pdf"],
  video: ["mp4", "mov"],
};

export const getFileTypes = (fileTypes: FileTypes[]) => fileTypes.map((type) => FILE_TYPE_NAMES[type]).join(",");

export const INPUT_FILE_DROP_DIMS = "88px";
export const INPUT_FILE_DROP_WIDTH = "440px";

export const Container = styled.div<ThemeProps>`
  height: ${INPUT_FILE_DROP_DIMS};
  max-width: ${INPUT_FILE_DROP_WIDTH};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.NEUTRAL["00"]};
`;

export const FullWidthContainer = styled.div<ThemeProps>`
  height: ${INPUT_FILE_DROP_DIMS};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.NEUTRAL["00"]};
`;

const loadingSvgSize = "24px";
export const LoadingContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: ${loadingSvgSize};
  border-radius: ${theme.border.radius.R2};
  border: ${theme.border.width.B1} dashed ${theme.colors.NEUTRAL[100]};
`
);

export const DragDropArea = styled.div<ThemeProps<{ isActive: boolean; hasError: boolean }>>(
  ({ isActive, hasError, theme }) => `
  width: 100%;
  height: 100%;
  border-radius: ${theme.border.radius.R2};
  border-width: ${isActive || hasError ? theme.border.width.B2 : theme.border.width.B1};
  border-color: ${hasError ? theme.colors.RED[300] : isActive ? theme.colors.BLUE[300] : theme.colors.NEUTRAL[100]};
  border-style: dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing.S5};

  :hover {
    cursor: pointer;
  }

  :focus {
    outline: 0;
  }
`
);

export const DropzoneLabel = styled.label<ThemeProps>(
  ({ theme }) => `
  font-size: ${theme.font.size.F2};
  display: block;
`
);

export const DropzoneLoading = () => (
  <Icon type="spinner-third" spin aria-label="Loading" color={defaultTheme.colors.NEUTRAL[200]} />
);
