import { NonComplianceStatus } from "@deliverr/replenishment-client";
import { ReplenishmentNonComplianceLabels } from "./ReplenishmentNonComplianceLabels";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import Pill, { PillColors } from "common/components/Pill";
import styled from "@emotion/styled";

const StatusPill = styled(Pill)`
  line-height: 1.7rem;
  white-space: nowrap;
`;

interface ReplenishmentNonComplianceStatusPillProps {
  status: NonComplianceStatus;
}

const getNonComplianceStatusPillColor = (status: NonComplianceStatus) => {
  switch (status) {
    case NonComplianceStatus.CREATED:
      return PillColors.RED;
    case NonComplianceStatus.RESOLVED:
      return PillColors.GREEN;
    case NonComplianceStatus.CANCELLED:
      return PillColors.GRAY;
    case NonComplianceStatus.PENDING_VERIFICATION:
      return PillColors.YELLOW;
    default:
      return PillColors.GRAY;
  }
};

export const ReplenishmentNonComplianceStatusPill: FC<ReplenishmentNonComplianceStatusPillProps> = ({ status }) => {
  return (
    <div data-testid="replenishment-nc-status-pill">
      <StatusPill color={getNonComplianceStatusPillColor(status)}>
        <FormattedMessage
          {...(ReplenishmentNonComplianceLabels.statuses[status ?? "UNKNOWN"] ??
            ReplenishmentNonComplianceLabels.statuses.UNKNOWN)}
        />
      </StatusPill>
    </div>
  );
};
