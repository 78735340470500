import React from "react";
import { useIntl } from "react-intl";
import { Column } from "react-table";

import { CurrencyFormat } from "common/components/Currency";
import { BasicTableColumn, useRenderMoneyCell } from "common/components/BasicTable";

export interface UseBasicTableColumnsProps {
  /* The title of the first column that will be shown for all tables */
  tableTitle: string | JSX.Element;
  currency?: CurrencyFormat;
}

export const useCostTableColumns = ({ tableTitle }: UseBasicTableColumnsProps) => {
  const { formatMessage } = useIntl();
  const renderMoneyCell = useRenderMoneyCell();
  const renderMoneyPerUnitCell = useRenderMoneyCell({ maximumFractionDigits: 3 });
  const renderMoneyDiscountCell = useRenderMoneyCell({ isDiscounted: true });

  const columns: Column<BasicTableColumn>[] = [
    {
      Header: tableTitle,
      accessor: "rowLabel",
    },
    {
      Header: formatMessage({ id: "inbounds.costTable.quantity", defaultMessage: "Quantity" }),
      accessor: "quantity",
    },
    {
      Header: formatMessage({ id: "inbounds.costTable.unitCost", defaultMessage: "Unit cost" }),
      accessor: "unitCost",
      Cell: renderMoneyPerUnitCell,
    },
    {
      accessor: "undiscountedTotal",
    },
    {
      Header: formatMessage({ id: "inbounds.costTable.amount", defaultMessage: "Amount" }),
      accessor: "amount",
      Cell: ({ row }) => {
        const rowTotal = row.cells[4];
        return (
          <>
            {row.original.undiscountedTotal &&
              // if there is a discount, display the original total with line-through
              renderMoneyDiscountCell(row.cells[3])}
            {
              // allow for handling of non-numerical placeholders
              typeof rowTotal.value !== "number" ? rowTotal.value : renderMoneyCell(rowTotal)
            }
          </>
        );
      },
    },
  ];

  return columns;
};
