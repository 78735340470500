import React, { FC } from "react";
import { DamageFault } from "@deliverr/commons-clients/lib/non-compliance/damages/DamagedProductUnified";
import { FormattedMessage } from "react-intl";

interface DamagedProductBladeTitleProps {
  isPending: boolean;
  damageFault: DamageFault;
  hasDisputes: boolean;
  hasReimbursementCost: boolean;
  isExpired: boolean;
}

export const DamagedProductBladeTitle: FC<DamagedProductBladeTitleProps> = ({
  isPending,
  damageFault,
  hasDisputes,
  hasReimbursementCost,
  isExpired,
}) => {
  if (isPending && !hasDisputes) {
    return <FormattedMessage id="damagedProductBlade.title.resolutionPending" defaultMessage="Resolution pending" />;
  }
  if (isPending) {
    return <FormattedMessage id="damagedProductBlade.title.reviewingDispute" defaultMessage="Reviewing dispute" />;
  }
  if (hasDisputes) {
    return <FormattedMessage id="damagedProductBlade.title.disputeResolved" defaultMessage="Dispute resolved" />;
  }
  if (isExpired) {
    return <FormattedMessage id="damagedProductBlade.title.expiredUnits" defaultMessage="Expired units" />;
  }
  if (damageFault === DamageFault.SELLER) {
    return (
      <FormattedMessage
        id="damagedProductBlade.title.sellerCaused"
        defaultMessage="Damages caused by seller's carrier"
      />
    );
  }
  if (hasReimbursementCost) {
    return (
      <FormattedMessage id="damagedProductBlade.title.reimbursementUpdated" defaultMessage="Reimbursement updated" />
    );
  }
  return null;
};
