import { PrepCombo, PrepType } from "@deliverr/prep-service-client";
import { SuperPrepType } from "prep/types";
import { MessageDescriptor, defineMessages } from "react-intl";

export const PREP_TYPE_LABELS: Record<SuperPrepType, MessageDescriptor> = defineMessages({
  [PrepType.SKU_LABELS]: {
    id: "prep.prepType.skuLabels",
    defaultMessage: "Unit barcoding",
  },
  [PrepCombo.SKU_LABELS_RS]: {
    id: "prep.prepType.skuLabelsRs",
    defaultMessage: "Unit barcoding",
  },
  [PrepType.BUBBLE_WRAPPING]: {
    id: "prep.prepType.bubbleWrapping",
    defaultMessage: "Bubble wrapping units",
  },
  [PrepType.BOX_BUILDING]: {
    id: "prep.prepType.boxBuilding",
    defaultMessage: "Boxing building",
  },
  [PrepType.BAGGING]: {
    id: "prep.prepType.bagging",
    defaultMessage: "Bagging",
  },
  [PrepType.BOX_CONTENT_LABELS]: {
    id: "prep.prepType.boxContentsLabeling",
    defaultMessage: "Box contents labeling",
  },
  [PrepType.BUBBLE_MAILER]: {
    id: "prep.prepType.bubbleMailer",
    defaultMessage: "Bubble mail units",
  },
  [PrepCombo.DAMAGE_PROTECTION]: {
    id: "prep.prepType.prepCombo.damageProtection",
    defaultMessage: "Damage protection",
  },
  [PrepCombo.DAMAGE_PROTECTION_RS]: {
    id: "prep.prepType.prepCombo.damageProtectionRs",
    defaultMessage: "Damage protection",
  },
  [PrepCombo.BAGGING_PROTECTION]: {
    id: "prep.prepType.prepCombo.baggingProtection",
    defaultMessage: "Bagging protection",
  },
  [PrepCombo.BAGGING_PROTECTION_RS]: {
    id: "prep.prepType.prepCombo.baggingProtectionRs",
    defaultMessage: "Bagging protection",
  },
  [PrepType.FLOOR_LOADED_CONTAINER]: {
    id: "prep.prepType.floorLoadedContainer",
    defaultMessage: "Container unloading",
  },
  [PrepType.KITTING]: {
    id: "prep.prepType.kitting",
    defaultMessage: "Kitting",
  },
  // TODO: these are not yet supported, and may be label changes when they become used in Seller Portal
  [PrepType.BARCODE_TRANSLATION]: {
    id: "prep.prepType.barcodeTranslation",
    defaultMessage: "Translating barcodes",
  },
  [PrepType.QUALITY_CHECK]: {
    id: "prep.prepType.qualityCheck",
    defaultMessage: "Checking quality",
  },
  [PrepType.CARTON_LABEL]: {
    id: "prep.prepType.cartonLabel",
    defaultMessage: "Labeling cartons",
  },
  [PrepType.PALLET_LABEL]: {
    id: "prep.prepType.palletLabel",
    defaultMessage: "Labeling pallets",
  },
  [PrepType.CUSTOM_SKU_LABEL]: {
    id: "prep.prepType.customSkuLabel",
    defaultMessage: "Custom unit barcoding",
  },
  [PrepType.UNIT_PROTECTION]: {
    id: "prep.prepType.unitProtection",
    defaultMessage: "Unit Protection",
  },
  [PrepType.COMBO_BOX_CONTENT_LABEL]: {
    id: "prep.prepType.comboBoxContentLabel",
    defaultMessage: "Box contents labeling",
  },
  [PrepCombo.FBA_FREIGHT_STANDARD]: {
    id: "prep.prepType.prepCombo.fbaFreightStandard",
    defaultMessage: "FBA Freight",
  },
  [PrepCombo.FBA_PARCEL_STANDARD]: {
    id: "prep.prepType.prepCombo.fbaParcelStandard",
    defaultMessage: "FBA Parcel",
  },
  [PrepCombo.B2B_FREIGHT_STANDARD]: {
    id: "prep.prepType.prepCombo.b2bFreightStandard",
    defaultMessage: "B2B Freight",
  },
  [PrepCombo.B2B_PARCEL_STANDARD]: {
    id: "prep.prepType.prepCombo.b2bParcelStandard",
    defaultMessage: "B2B Parcel",
  },
});
