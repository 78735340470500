import { facebookClient } from "common/clients/instances";
import { logError, logStart } from "Logger";
import { adSetsReceived } from "./adSetsReceived";
import { errorSyncingAdSets } from "./errorSyncingAdSets";
import { FacebookActionTypes } from "./FacebookActionTypes";
import { refreshSellerCompleted } from "./refreshSellerCompleted";

export const syncAdSetsAction = { type: FacebookActionTypes.FACEBOOK_START_SYNC_AD_SETS } as const;

export const syncAdSets = (sellerId: string) => async (dispatch) => {
  const ctx = logStart({ fn: "syncAdSets", sellerId });

  try {
    dispatch(syncAdSetsAction);
    const adSets = await facebookClient.syncSellerCreatedAdSets(sellerId);
    try {
      await facebookClient.refreshSeller(sellerId);
      dispatch(refreshSellerCompleted());
    } catch (err) {
      logError(ctx, err);
    }
    dispatch(adSetsReceived(adSets));
  } catch (err) {
    logError(ctx, err);
    dispatch(errorSyncingAdSets(err));
  }
};
