import { productClient } from "Clients";
import { SPThunkAction } from "common/ReduxUtils";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { ProductCollection } from "common/models";
import { filter, isEmpty } from "lodash";
import { transferCreateGetUnifiedProduct } from "transfers/create/actions";

export const UnifiedProductsLoaderId = "getUnifiedProductsLoader";

// move this to action
export const getUnifiedProducts =
  (dskus: string[]): SPThunkAction<Promise<ProductCollection>> =>
  async (dispatch, getState) => {
    dispatch(addLoader(UnifiedProductsLoaderId));
    const cachedProductDetails = getState().transferCreate.unifiedProductResponseCache ?? {};

    try {
      const remainingDsku = filter(dskus, (dsku) => !cachedProductDetails[dsku]);
      if (!isEmpty(remainingDsku)) {
        const unifiedResponse = await productClient.getUnifiedProducts(remainingDsku, {
          includeProductCasePacks: true,
        });

        const dskuProductDetails = buildResponse(dskus, cachedProductDetails, unifiedResponse);

        dispatch(transferCreateGetUnifiedProduct({ ...cachedProductDetails, ...dskuProductDetails }));
        return dskuProductDetails;
      }
      return buildResponse(dskus, cachedProductDetails, {});
    } finally {
      dispatch(clearLoader(UnifiedProductsLoaderId));
    }
  };

const buildResponse = (
  dskus: string[],
  cachedProductDetails: ProductCollection,
  unifiedResponse: ProductCollection
) => {
  return dskus.reduce((acc, dsku) => {
    if (!acc[dsku]) {
      acc[dsku] = cachedProductDetails[dsku];
    }
    return acc;
  }, unifiedResponse);
};
