import { RootState } from "RootReducer";

export const getLoaders = (state: RootState): string[] => state.loading.loaders;

/**
 * @deprecated
 * Please use `selectAnyIsLoading` instead
 */
export const getIsLoading = (state: RootState) => {
  return (loaderId: string): boolean => getLoaders(state).includes(loaderId);
};
