import React from "react";
import styled from "@emotion/styled";
import { Card, ThemeProps } from "common/components/ui";
import { ReplenishmentOrderSummary } from "./ReplenishmentOrderSummary";
import { ReplenishmentShipmentAddressDisplay } from "./ReplenishmentShipmentAddressDisplay";
import { useSelector } from "react-redux";
import { selectReplenishmentOrderDetails } from "transfers/detail/store/replenishment/selectReplenishmentOrderDetails";
import { ReplenishmentOrderDetailsHeader } from "./ReplenishmentOrderDetailsHeader";
import { ReplenishmentOrderCostDetailsWithActuals } from "transfers/detail/ReplenishmentOrderCostDetailsWithActuals";

export const SummaryContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    display: flex;
    width: 100%;
    line-height: ${theme.font.lineHeight.LH3};
`
);

interface Props {
  onViewMoreClickonShipmentsModal?: (e) => void;
}

export const SummaryCard: React.FC<Props> = ({ children }) => {
  const { replenishmentOrder, replenishmentOrderCosts, nonCompliances } = useSelector(selectReplenishmentOrderDetails);

  return replenishmentOrder ? (
    <Card size={"NONE"}>
      <ReplenishmentOrderDetailsHeader replenishmentOrder={replenishmentOrder} nonCompliances={nonCompliances} />
      <SummaryContainer>
        <ReplenishmentOrderSummary replenishmentOrder={replenishmentOrder} />
        <ReplenishmentShipmentAddressDisplay
          distributionChannel={replenishmentOrder.distributionChannel}
          shipments={replenishmentOrder.shipments}
          originWarehouseId={replenishmentOrder.originWarehouseId}
        />
        {replenishmentOrderCosts && (
          <ReplenishmentOrderCostDetailsWithActuals
            replenishmentOrderCosts={replenishmentOrderCosts}
            orderStatus={replenishmentOrder.orderStatus}
            shippingMethod={replenishmentOrder.shipments?.[0]?.shippingPartner}
          />
        )}
      </SummaryContainer>
      {children}
    </Card>
  ) : (
    <></>
  );
};
