import React from "react";
import styled from "@emotion/styled";
import { Text, ThemeProps } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import { shipmentSummaryLabels } from "transfers/detail/shipmentSummary.labels";
import { useReplenishmentOrderSummary } from "./useReplenishmentOrderSummary";
import { ShippingMethodAndTypeDetail } from "./ShippingMethodAndTypeDetail";
import { OrderResponse } from "@deliverr/replenishment-client";

const SummaryContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    padding: ${theme.spacing.S5} ${theme.spacing.S7};
    font-size: ${theme.font.size.F2};
    border-right: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
    white-space: nowrap;
  `
);

export const ReplenishmentOrderSummary: React.FC<{
  replenishmentOrder: OrderResponse;
  shouldHideSkusAndUnits?: boolean;
}> = ({ replenishmentOrder, shouldHideSkusAndUnits }) => {
  const {
    numSkus,
    numUnits,
    poNumber,
    submitTimestamp,
    isMultiShipment,
    isWholesaleOrder,
    formattedShipByDate,
    shipment,
  } = useReplenishmentOrderSummary(replenishmentOrder);

  const idLabel = isWholesaleOrder ? shipmentSummaryLabels.orderId : shipmentSummaryLabels.transferId;

  return (
    <SummaryContainer data-testid="replenishment-shipment-summary">
      {!shouldHideSkusAndUnits ? (
        <div data-testid="summary-item-count">
          <FormattedMessage {...shipmentSummaryLabels.skusAndUnits} values={{ numSkus, numUnits }} />
        </div>
      ) : null}
      <div>
        <FormattedMessage {...idLabel} values={{ orderId: replenishmentOrder?.orderId }} />
      </div>
      {poNumber && (
        <div>
          <FormattedMessage {...shipmentSummaryLabels.poNumber} values={{ poNumber }} />
        </div>
      )}
      {isWholesaleOrder && formattedShipByDate && (
        <Text>
          <FormattedMessage {...shipmentSummaryLabels.shipBy} /> {formattedShipByDate}
        </Text>
      )}
      {!isMultiShipment && shipment && <ShippingMethodAndTypeDetail shipment={shipment} />}
      <div data-testid="summary-submit-timestamp">
        <FormattedMessage {...shipmentSummaryLabels.placed} values={{ submitTimestamp }} />
      </div>
    </SummaryContainer>
  );
};
