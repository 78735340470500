/**
 * Returns true when all dimensions are positive.
 * Returns false when any dimensions are missing or 0.
 */
export const hasDimensions = (product?: { length?: number | null; width?: number | null; height?: number | null }) => {
  const length = product?.length ?? 0;
  const width = product?.width ?? 0;
  const height = product?.height ?? 0;

  return length > 0 && width > 0 && height > 0;
};
