import { Button } from "common/components/ui";
import cx from "classnames";
import React, { FC } from "react";
import { ElevioButtonProps } from "./ElevioButtonProps";
import { useElevio } from "./useElevio";

export const ElevioButton: FC<ElevioButtonProps> = ({ articleId, className, id, onClick, children }) => {
  const { handleClick } = useElevio(articleId, onClick);
  return (
    <Button id={id} className={cx(className)} onClick={handleClick} secondary>
      {children}
    </Button>
  );
};
