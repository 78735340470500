import { Box, Grid, Text } from "common/components/ui";
import React from "react";
import { FormattedMessage } from "react-intl";

export interface CargoDetailSectionProps {
  cargoReadyDate?: string;
  estimatedArrivalDate?: string;
}

export const CargoDetailSection: React.FC<CargoDetailSectionProps> = ({ cargoReadyDate, estimatedArrivalDate }) => {
  return (
    <Box width="80%">
      <Grid columns="1fr 3fr" gap="S1">
        {cargoReadyDate && (
          <Box>
            <Text bold size="caption">
              <FormattedMessage
                id="storage.inbounds.create.confirmShipment.cargoReadyDate.title"
                defaultMessage="Cargo ready date"
              />
            </Text>
            <Text size="body">
              <FormattedMessage
                id="storage.inbounds.create.confirmShipment.content.cargoReadyDate"
                defaultMessage="{cargoReadyDate}"
                values={{
                  cargoReadyDate,
                }}
              />
            </Text>
          </Box>
        )}
        {estimatedArrivalDate && (
          <Box>
            <Text bold size="caption">
              <FormattedMessage
                id="storage.inbounds.create.confirmShipment.estimatedArrivalDate.title"
                defaultMessage="Estimated arrival date"
              />
            </Text>
            <Text size="body">
              <FormattedMessage
                id="storage.inbounds.create.confirmShipment.content.cargoReadyDate"
                defaultMessage="{estimatedArrivalDate}"
                values={{
                  estimatedArrivalDate,
                }}
              />
            </Text>
          </Box>
        )}
      </Grid>
    </Box>
  );
};
