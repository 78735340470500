import { ManagedStep } from "common/components/StepContainer";
import { AmazonAddress } from "inbounds/createShipment/CreateShipmentTypes";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { AmazonAddressInput } from "./shipment-details/AmazonAddressInput";

export const AmazonPickFacilityStep = () => {
  const { formatMessage } = useIntl();
  const { destinationAddress } = useSelector(selectTransferCreate);
  const [address, setAddress] = useState(destinationAddress);

  const onChange = (newAddress) => {
    setAddress(newAddress);
  };

  const isFilled = () => {
    return (
      address &&
      !(!address.country?.trim() || !address.zip?.trim() || !address.city?.trim() || !address.street1?.trim())
    );
  };

  return (
    <ManagedStep
      data-testid="replenishment-pick-facility"
      title={formatMessage({ ...transfersShipmentCreateLabels.steps.facility.title })}
      isNextDisabled={!isFilled()}
      css={{ width: "80%" }}
      nextData={() => address}
    >
      <AmazonAddressInput onChangeCallback={onChange} selectedAddress={address as AmazonAddress} />
    </ManagedStep>
  );
};
