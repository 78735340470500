import classNames from "classnames/dedupe";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { LocalizedMessage, Stack } from "common/components/ui";
import { InfoIcon } from "common/components/icons/InfoIcon";
import { ButtonSize, ButtonType, LoaderButton, LoaderButtonProps } from "common/components/WithLoader/LoaderButton";
import { ActionCreator } from "common/ReduxUtils";
import { saveOldState } from "inbounds/InboundActions";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { RootState } from "RootReducer";
import cls from "./InboundFooter.less";
import { datadogRum } from "@datadog/browser-rum";
import { getPreviousStepsNoLongerModifiable } from "./InboundStepSelectors";
import { selectIsShipmentActionLoading } from "inbounds/store/selectors/shipments/selectIsShipmentActionLoading";

interface ReduxProps {
  isPreviousButtonDisabled: boolean;
}

interface DispatchProps {
  saveOldState: ActionCreator;
}

interface FooterButtonProps {
  label: LocalizedMessage;
  disabled?: boolean;
  id?: string;
  onClick: () => void;
}

interface InboundFooterProps extends Pick<LoaderButtonProps, "manualLoader" | "loaderText"> {
  buttonClassName?: string;
  buttonText?: LocalizedMessage;
  disabled?: boolean;
  hidePreviousButton?: boolean;
  hideNextButton?: boolean;
  onButtonClick?: () => void;
  onPreviousButtonClick?: () => void;
  // additional actions that render to the left of the submit button
  additionalButtons?: FooterButtonProps[];
  key?: string;
  buttonProps?: {};
}

class UnconnectedInboundFooter extends React.Component<ReduxProps & DispatchProps & InboundFooterProps> {
  public onNextClick = () => {
    if (this.props.onButtonClick) {
      // add datadog view timing
      datadogRum.addTiming("inbound_footer_next_click");
      this.props.onButtonClick();
    }
  };

  public onPreviousClick = () => {
    if (!this.props.isPreviousButtonDisabled && this.props.onPreviousButtonClick) {
      this.props.onPreviousButtonClick();
      this.props.saveOldState();
    }
  };

  public render() {
    const {
      buttonClassName,
      buttonText,
      disabled,
      hidePreviousButton,
      hideNextButton = false,
      manualLoader,
      isPreviousButtonDisabled,
      additionalButtons,
      loaderText,
      key,
      buttonProps,
    } = this.props;

    return (
      <div className={cls.footerContainer}>
        <div className={cls.footer}>
          <div>
            {!hidePreviousButton && (
              <LoaderButton
                id="inbounds-previous-button"
                buttonClass={classNames(cls.previousButton, {
                  [cls.footerButtonDisabled]: isPreviousButtonDisabled,
                })}
                disabled={isPreviousButtonDisabled}
                buttonType={ButtonType.PrimaryReversed}
                name={InboundLoaderId.transition}
                size={ButtonSize.lg}
                manualLoader={manualLoader}
                onClick={this.onPreviousClick}
                hideSpinner
              >
                <FormattedMessage id="inbounds.inboundFooter.previous" defaultMessage="Previous" />
              </LoaderButton>
            )}
            {isPreviousButtonDisabled && (
              <InfoIcon
                message={
                  <FormattedMessage
                    id="inbounds.inboundFooter.previousDisabledMessage"
                    defaultMessage="Once you accept charges when shipping on Flexport's rates or confirm box
                      configurations when shipping on your own rates, you may no longer alter your previously completed steps on any shipment"
                  />
                }
                placement={"top"}
                tooltipProps={{
                  overlayClassName: cls.previousDisabledMessageTooltip,
                }}
              />
            )}
          </div>

          <Stack direction="HORIZONTAL" gap="S2">
            {additionalButtons?.map(({ label, disabled: buttonDisabled, id, onClick }) => (
              <LoaderButton
                key={label}
                buttonClass={classNames(
                  {
                    [cls.footerButtonDisabled]: buttonDisabled,
                  },
                  buttonDisabled ? "" : "enabled"
                )}
                buttonType={ButtonType.PrimaryReversed}
                name={InboundLoaderId.transition}
                size={ButtonSize.lg}
                onClick={onClick}
                manualLoader={manualLoader}
                hideSpinner
                disabled={buttonDisabled}
                id={id}
              >
                {label}
              </LoaderButton>
            ))}
            {!hideNextButton && (
              <LoaderButton
                buttonClass={classNames(buttonClassName ?? cls.nextButton, disabled ? "" : "enabled")}
                buttonType={ButtonType.Primary}
                name={InboundLoaderId.transition}
                size={ButtonSize.lg}
                onClick={this.onNextClick}
                manualLoader={manualLoader}
                disabled={disabled}
                loaderText={loaderText}
                id="inbounds-next-button"
                key={key}
                buttonProps={buttonProps}
              >
                {buttonText ?? <FormattedMessage id="inbounds.inboundFooter.next" defaultMessage="Next" />}
              </LoaderButton>
            )}
          </Stack>
        </div>
        <div className={cls.footerPadding}></div>
      </div>
    );
  }
}

export const InboundFooter: React.FC<InboundFooterProps> = connect<ReduxProps, DispatchProps, InboundFooterProps>(
  (state: RootState): ReduxProps => ({
    isPreviousButtonDisabled: getPreviousStepsNoLongerModifiable(state) || selectIsShipmentActionLoading(state),
  }),
  {
    saveOldState,
  }
)(UnconnectedInboundFooter);
