import { CrossdockInboundQuoteRequest } from "common/clients/crossdock/CrossdockInboundQuote/CrossdockInboundQuoteRequest";
import { getSellerId } from "common/user/UserSelectors";
import {
  FallbackDimsAndWeights,
  getShippingPlanItemTotals,
} from "inbounds/store/selectors/plan/getShippingPlanItemTotals";
import { RootState } from "RootReducer";

/**
 * Selector that returns a function to build a CrossdockInboundQuoteRequest
 */
export const getCrossdockQuoteRequest = (state: RootState) => (
  untrustedDimsFallbacks?: FallbackDimsAndWeights
): CrossdockInboundQuoteRequest => {
  const sellerId = getSellerId(state);
  const { fromAddress, plan } = state.inbound;
  const { totalVolume, totalWeight, totalItemQty: itemQty } = getShippingPlanItemTotals(state)(untrustedDimsFallbacks);

  return {
    sellerId,
    shippingPlanId: plan.id,
    fromZip: fromAddress?.zip,
    totalVolume,
    totalWeight,
    itemQty,
  };
};
