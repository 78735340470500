import { Contact, OrderRequestLocation, PickupDetails } from "@deliverr/freight-client";

export interface FreightAddressDetailsState extends Omit<PickupDetails, "appointment" | "contact"> {
  locationType?: LocationType;
  limitedAccessLocation?: LimitedAccessLocation;
  savedAddresses: OrderRequestLocation[];
  contact: FreightContact;
}

export enum LocationType {
  COMMERCIAL = "COMMERCIAL",
  RESIDENTIAL = "RESIDENTIAL",
}

export enum LimitedAccessLocation {
  NONE = "NONE",
  AIRPORT = "AIRPORT",
  MILITARY_BASE = "MILITARY_BASE",
}

export interface FreightContact extends Contact {
  phoneExt?: string;
}

export enum AddressInputNames {
  LOCATION_TYPE = "LOCATION_TYPE",
  LIMITED_ACCESS_LOCATION = "LIMITED_ACCESS_LOCATION",
  CONTACT_FIRST_NAME = "CONTACT_FIRST_NAME",
  CONTACT_LAST_NAME = "CONTACT_LAST_NAME",
  CONTACT_EMAIL = "CONTACT_EMAIL",
  CONTACT_PHONE = "CONTACT_PHONE",
  CONTACT_PHONE_EXT = "CONTACT_PHONE_EXT",
  COMPANY_NAME = "COMPANY_NAME",
}

export interface AddressInputValues {
  [AddressInputNames.CONTACT_EMAIL]: string;
  [AddressInputNames.CONTACT_FIRST_NAME]: string;
  [AddressInputNames.CONTACT_LAST_NAME]: string;
  [AddressInputNames.CONTACT_PHONE]: string;
  [AddressInputNames.CONTACT_PHONE_EXT]: string;
  [AddressInputNames.LOCATION_TYPE]: { label: string; value: LocationType } | undefined;
  [AddressInputNames.LIMITED_ACCESS_LOCATION]: { label: string; value: LimitedAccessLocation } | undefined;
  [AddressInputNames.COMPANY_NAME]?: string;
}
