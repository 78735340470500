import React from "react";
import { format, isFuture, isPast } from "date-fns";
import { CommaDate, CommaDateNoYear } from "common/date/DateFormat";
import { FormattedMessage } from "react-intl";
import { StorageWarehouseDiscountData } from "storage/inbounds/create/selectDestinationV2/StorageWarehouseDiscount";

export const getStorageWarehouseDiscountString = (discount?: StorageWarehouseDiscountData) => {
  if (!discount) {
    return null;
  }
  const todaysDate = new Date();
  const discountStartDate = new Date(discount.discountStartDate);
  const discountEndDate = new Date(discount.discountEndDate);
  const isDiscountActive = isPast(discountStartDate) && isFuture(discountEndDate);
  const isSameYear = todaysDate.getFullYear() === discountEndDate.getFullYear();
  const dateStr = format(discountEndDate, isSameYear ? CommaDateNoYear : CommaDate);
  return isDiscountActive ? (
    <FormattedMessage
      id="storage.inbounds.storageDestination.discount.message"
      defaultMessage={"{discountPercentage}% off storage fees until {discountEndDateStr}"}
      values={{ discountPercentage: discount.discountPercentage, discountEndDateStr: dateStr }}
    />
  ) : null;
};
