import { defineMessages } from "react-intl";

export const selectShippingStepLabels = defineMessages({
  selectCarrier: {
    id: "storage.inbounds.create.selectShipping.selectCarrier",
    defaultMessage: "Select carrier",
  },
  addPickupDetails: {
    id: "storage.inbounds.create.selectShipping.addPickupDetails",
    defaultMessage: "Add additional pickup details",
  },
  addShipmentDetails: {
    id: "storage.inbounds.create.selectShipping.addShipmentDetails",
    defaultMessage: "Add shipment details",
  },
  selectService: {
    id: "storage.inbounds.create.selectShipping.selectService",
    defaultMessage: "Select a service",
  },
});
