export enum WalmartError {
  API_KEYS_NOT_FOUND = "API_KEYS_NOT_FOUND",
  INVALID_API_KEY = "INVALID_API_KEY",
  INVALID_API_PERMISSIONS = "INVALID_API_PERMISSIONS",
  INVALID_ORDER_ID = "INVALID_ORDER_ID",
  INVALID_URL = "INVALID_URL",
  LISTING_IS_MISSING_SKU = "LISTING_IS_MISSING_SKU",
  MSKU_NOT_FOUND = "MSKU_NOT_FOUND",
  MULTIPLE_VARIANTS = "MULTIPLE_VARIANTS",
  NON_DELIVERR_CREDENTIALS = "NON_DELIVERR_CREDENTIALS",
  NOT_FOUND = "NOT_FOUND",
  NOT_FOUND_IN_MARKETPLACE = "NOT_FOUND_IN_MARKETPLACE",
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
}
