import { storageClient } from "common/clients/instances";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { SPThunkAction } from "common/ReduxUtils";
import { keyBy } from "lodash/fp";
import { logError } from "Logger";
import { CreateTransferLoader } from "../CreateTransferLoader.types";
import { setSelectedProductsCaseInfo } from "./setSelectedProductsCaseInfo";

export const fetchStorageProductsCaseInfo = (dskus: string[]): SPThunkAction => async (dispatch) => {
  dispatch(addLoader(CreateTransferLoader.FetchStorageProductsCaseInfoLoader));
  try {
    if (dskus.length > 0) {
      const selectedProductsCaseInfo = (await storageClient.getCaseConfig(dskus))?.value;
      const selectedProductsCaseInfoMap = keyBy((caseInfo) => caseInfo.dsku, selectedProductsCaseInfo);
      await dispatch(setSelectedProductsCaseInfo(selectedProductsCaseInfoMap));
    }
  } catch (err) {
    logError({ fn: "fetchStorageProductsCaseInfo" }, err);
  } finally {
    dispatch(clearLoader(CreateTransferLoader.FetchStorageProductsCaseInfoLoader));
  }
};
