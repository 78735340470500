import React from "react";
import { CurrencyFormat, DEFAULT_CURRENCY } from "./CurrencyTypes";
import { Select } from "common/components/ui";

interface CurrencySelectorProps {
  value: string;
  onChange: (selectedOption: any) => void;
}

export const CurrencySelector: React.FC<CurrencySelectorProps> = ({ value, onChange }) => {
  const currencyOptions = Object.entries(CurrencyFormat).map(([key, val]) => {
    return { label: key, value: val };
  });

  const getSelectedCurrencyOption = (currencyValue: string) => {
    return currencyOptions.find((option) => option.value === currencyValue);
  };

  return (
    <Select
      id="financing.form.currency.type"
      name="financing.currency"
      placeholder={DEFAULT_CURRENCY}
      options={currencyOptions}
      value={getSelectedCurrencyOption(value)}
      onChange={onChange}
    />
  );
};
