import { Box, Text, defaultTheme } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { ElevioLearnMoreButton } from "common/elevio/ElevioLearnMoreButton";
import { ElevioArticleId } from "common/elevio";
import { isEmpty, isNil } from "lodash";
import {
  LightDivider,
  ReplenishmentErrorContainer,
  ReplenishmentErrorTitle,
} from "./ReplenishmentOrderCreationErrorStyles";
import { ReplenishmentOrderErrorMessages } from "./ReplenishmentErrorMessages";
import { ReplenishmentUnknownErrorCode } from "./ReplenishmentErrorCodes";
import { InfoTooltip } from "dashboard/common";
import styled from "@emotion/styled";

const FlexDiv = styled.div(`
  display: flex;
  padding-bottom: 0.25rem;
`);

interface OrderErrorProps {
  articleId?: ElevioArticleId;
  showTitle?: boolean;
  orderErrorDescriptions: {
    orderErrorDescription: string;
    errorCode: string;
    rawErrorMessage?: string;
  }[];
}

export const ReplenishmentOrderError: FC<OrderErrorProps> = ({ orderErrorDescriptions, articleId, showTitle }) => {
  return !isEmpty(orderErrorDescriptions) ? (
    <ReplenishmentErrorContainer>
      {showTitle ? (
        <ReplenishmentErrorTitle>
          <FormattedMessage {...ReplenishmentOrderErrorMessages.OrderErrorTitle} />
        </ReplenishmentErrorTitle>
      ) : null}

      <LightDivider />

      {orderErrorDescriptions.map((orderError, index) => (
        <div data-testid="replenishment-order-error-description" key={`order_error_${index}`}>
          <ErrorDescriptionWithTooltip
            errorDescription={orderError.orderErrorDescription}
            rawErrorMessage={orderError.rawErrorMessage}
            errorCode={orderError.errorCode}
            articleId={articleId}
          />

          <LightDivider />
        </div>
      ))}
    </ReplenishmentErrorContainer>
  ) : null;
};

export const ErrorDescriptionWithTooltip: FC<{
  errorDescription: string;
  rawErrorMessage?: string;
  errorCode: string;
  articleId?: ElevioArticleId;
}> = ({ errorDescription, rawErrorMessage, errorCode, articleId }) => {
  return (
    <>
      <FlexDiv>
        {!isEmpty(rawErrorMessage) && (
          <Box paddingRight={"S1"}>
            <InfoTooltip
              message={
                <FormattedMessage
                  {...ReplenishmentOrderErrorMessages.rawErrorMessage}
                  values={{
                    rawErrorMessage,
                  }}
                />
              }
              width={500}
              infoIconColor={defaultTheme.colors.RED[300]}
            />
          </Box>
        )}

        <Box width={"80%"} paddingLeft={"S1"}>
          <Text bold>{errorDescription}</Text>
        </Box>
      </FlexDiv>

      <Box paddingLeft={"S5"}>
        {errorCode !== ReplenishmentUnknownErrorCode && !isNil(articleId) ? (
          <FormattedMessage
            {...ReplenishmentOrderErrorMessages.ErrorResolutionDescription}
            values={{
              learnMore: <ElevioLearnMoreButton articleId={articleId} />,
            }}
          />
        ) : null}
      </Box>
    </>
  );
};
