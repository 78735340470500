import { defineMessages, MessageDescriptor } from "react-intl";

export const UnexpectedBarcodeBladeMessages: Record<string, MessageDescriptor> = defineMessages({
  STATUS_MESSAGE: {
    id: "inbounds.unexpectedBarcodeBlade.statusMessage",
    defaultMessage:
      "Flexport detected an unexpected barcode in your shipping plan. This SKU was identified and received.",
  },
  DETAILS_TITLE: {
    id: "inbounds.unexpectedBarcodeBlade.detailsTitle",
    defaultMessage: "Details",
  },
  DETAILS: {
    id: "inbounds.unexpectedBarcodeBlade.details",
    defaultMessage: "Flexport identified the unexpected barcode on this shipping plan as the following SKU.",
  },
  BARCODE: {
    id: "inbounds.unexpectedBarcodeBlade.barcode",
    defaultMessage: "Unexpected barcode",
  },
  VERIFIED_SKU: {
    id: "inbounds.unexpectedBarcodeBlade.verifiedSku",
    defaultMessage: "SKU",
  },
  NONCOMPLIANT_PRODUCT: {
    id: "inbounds.unexpectedBarcodeBlade.nonCompliantProduct",
    defaultMessage: "Product",
  },
  CONTACT_SUPPORT: {
    id: "inbounds.unexpectedBarcodeBlade.contactSupport",
    defaultMessage: "Not the right SKU? {contactSupport}",
  },
  CONTACT_SUPPORT_LINK: {
    id: "inbounds.unexpectedBarcodeBlade.contactSupportLink",
    defaultMessage: "Contact support",
  },
  LEARN_MORE: {
    id: "inbounds.unexpectedBarcodeBlade.learnMore",
    defaultMessage: "Learn more about {unexpectedBarcodes}.",
  },
  UNEXPECTED_BARCODES_LINK: {
    id: "inbounds.unexpectedBarcodeBlade.unexpectedBarcodesLink",
    defaultMessage: "unexpected barcodes",
  },
  BARCODE_FOUND_IN_BOX: {
    id: "inbounds.unexpectedBarcodeBlade.barcodeFoundInBox",
    defaultMessage: "This barcode was found in box {cdsku}.",
  },
  QUANTITY_RECEIVED: {
    id: "inbounds.unexpectedBarcodeBlade.quantityReceived",
    defaultMessage: "Quantity received:",
  },
  QUANTITY_RECEIVED_WITH_UNITS: {
    id: "inbounds.unexpectedBarcodeBlade.quantityReceivedWithUnits",
    defaultMessage: "{quantityReceived} {unitCount} {units}",
  },
});
