import { DeliverrAddress } from "@deliverr/commons-objects";
import { getNonEmptyString } from "common/utils/string/getNonEmptyString";

// need to set nullable fields to undefined instead of passing empty strings for backend validation
export const parseAddress = (address: DeliverrAddress) => ({
  ...address,
  company: getNonEmptyString(address.company, undefined),
  street2: getNonEmptyString(address.street2, undefined),
  phone: getNonEmptyString(address.phone, undefined),
  email: getNonEmptyString(address.email, undefined),
});
