import Content from "common/components/Content";
import { HorizontalLineTable } from "common/components/HorizontalLineTable";
import Modal from "common/components/Modal";
import { YesOrNo } from "common/components/YesOrNo";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";
import InboundModalId from "inbounds/InboundModalId";
import { BarcodeConfirmModalBarcode } from "inbounds/steps/barcode/confirm/BarcodeConfirmModalBarcode";
import { BarcodeConfirmModalDetails } from "inbounds/steps/barcode/confirm/BarcodeConfirmModalDetails";
import React from "react";
import { FormattedMessage } from "react-intl";

import { SerializedBarcodes } from "barcodes/serializer";
import { useModal } from "common/hooks/useModal";
import { ProductCollection } from "common/models/Product";
import cls from "./BarcodeConfirmModal.less";

interface BarcodeConfirmModalProps {
  productDetailsCache: ProductCollection;
  barcodeValues: SerializedBarcodes;
  onYes: () => void;
}

export const BarcodeConfirmModal: React.ComponentType<BarcodeConfirmModalProps> = ({
  onYes,
  productDetailsCache,
  barcodeValues,
}) => {
  const { hideModal } = useModal(InboundModalId.BarcodeConfirm);

  return (
    <Modal id={InboundModalId.BarcodeConfirm} className={cls.modal} size={"md"} hasCloseButton={false}>
      <Content
        className={cls.content}
        title={
          <FormattedMessage id="BarcodeConfirmModal.title" defaultMessage={"Is your barcode information correct?"} />
        }
        body={
          <div>
            <p className={cls.bodyLine}>
              <FormattedMessage
                id="BarcodeConfirmModal.bodyLineOne"
                defaultMessage={
                  "Careful! You are permanently adding barcodes to products, and each product is limited to just 5 barcodes."
                }
              />
            </p>
            <p className={cls.bodyLine}>
              <FormattedMessage
                id="BarcodeConfirmModal.bodyLineTwo"
                defaultMessage={
                  "Products inbounded with incorrect barcodes will incur receiving delays and are assessed {noncomplianceFees}."
                }
                values={{
                  noncomplianceFees: (
                    <ElevioBorderlessButton articleId={ElevioArticleId.DeliverrFees}>
                      <FormattedMessage
                        id="BarcodeConfirmModal.noncomplianceFees"
                        defaultMessage={"noncompliance fees"}
                      />
                    </ElevioBorderlessButton>
                  ),
                }}
              />
            </p>
          </div>
        }
        after={
          <div className={cls.after}>
            <div className={cls.tableContainer}>
              <HorizontalLineTable
                headers={[
                  <FormattedMessage
                    key="BarcodeConfirmModal.product"
                    id="BarcodeConfirmModal.product"
                    defaultMessage={"Product"}
                  />,
                  <FormattedMessage
                    key="BarcodeConfirmModal.expectedProductBarcode"
                    id="BarcodeConfirmModal.expectedProductBarcode"
                    defaultMessage={"Added barcode"}
                  />,
                ]}
                rows={Object.keys(productDetailsCache).map((dsku) =>
                  barcodeValues?.[dsku]
                    ? [
                        <BarcodeConfirmModalDetails key={`details-${dsku}`} product={productDetailsCache[dsku]} />,
                        <BarcodeConfirmModalBarcode key={`barcode-${dsku}`} barcodes={barcodeValues[dsku]} />,
                      ]
                    : []
                )}
                className={cls.barcodeTable}
              />
            </div>
            <YesOrNo
              orientation={"horizontal"}
              useReversedNoButton={true}
              yesText={
                <FormattedMessage id="BarcodeConfirmModal.yes" defaultMessage={"Yes, my barcodes are correct."} />
              }
              noText={
                <FormattedMessage id="BarcodeConfirmModal.no" defaultMessage={"No, I need to fix my barcodes."} />
              }
              onYes={onYes}
              onNo={hideModal}
            />
          </div>
        }
      />
    </Modal>
  );
};
