export enum InventoryDetailActionTypes {
  GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS",
  GET_PRODUCT_DETAIL_ERROR = "GET_PRODUCT_DETAIL_ERROR",
  GET_INVENTORY_BY_WAREHOUSE_START = "GET_INVENTORY_BY_WAREHOUSE_START",
  GET_INVENTORY_BY_WAREHOUSE_SUCCESS = "GET_INVENTORY_BY_WAREHOUSE_SUCCESS",
  GET_INVENTORY_BY_WAREHOUSE_ERROR = "GET_INVENTORY_BY_WAREHOUSE_ERROR",
  GET_FEE_PREVIEW = "GET_FEE_PREVIEW",
  UPDATE_ESTIMATED_COSTS = "UPDATE_ESTIMATED_COSTS",
  CREATE_WAREHOUSE_MARKERS = "CREATE_WAREHOUSE_MARKERS",
  RESET_INVENTORY_DETAIL = "RESET_INVENTORY_DETAIL",
  SET_SELECTED_UNITS = "SET_SELECTED_UNITS",
  UPDATE_MSKU_SUCCESS = "UPDATE_MSKU_SUCCESS",
  UPDATE_HAZMAT_SUCCESS = "UPDATE_HAZMAT_SUCCESS",
  SET_SELECTED_SERVICE_LEVEL = "SET_SELECTED_SERVICE_LEVEL",
  SET_SELECTED_COUNTRY_CODE = "SET_SELECTED_COUNTRY_CODE",
  UPDATE_IS_FEFO_ENABLED = "UPDATE_IS_FEFO_ENABLED",
  UPDATE_IS_RECALL_MANAGEMENT_ENABLED = "UPDATE_IS_RECALL_MANAGEMENT_ENABLED",
  SET_KIT_INSTRUCTIONS = "SET_KIT_INSTRUCTIONS",
  SET_KIT_TEXT_INSTRUCTIONS = "SET_KIT_TEXT_INSTRUCTIONS",
  KIT_INSTRUCTIONS_LOADING = "KIT_INSTRUCTIONS_LOADING",
}
