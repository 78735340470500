import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, FormGroup } from "common/components/ui";
import { CheckableTileSelector } from "inbounds/createShipment/common/CheckableTileSelector";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";
import { InfoIcon } from "common/components/icons";
import { DangerousGoodsNotAllowedError } from "../validationNotifications";
import { DangerousGoodsError, useDangerousGoodsToggle } from "./useDangerousGoodsToggle";

const toggleLabel = (
  <FormattedMessage
    id="inbounds.createShipment.dangerousGoods.toggleLabel"
    defaultMessage="Declare if any of the products in this shipment require stringent regulations and special handing services due to being dangerous goods. {tooltip}"
    values={{
      tooltip: (
        <InfoIcon
          messageWidth={200}
          wrap
          placement={"top"}
          message={
            <FormattedMessage
              id="inbounds.createShipment.dangerousGoods.toggleLabel.tooltip"
              defaultMessage="Examples of potentially dangerous goods are batteries, magnetized materials, dry ice, vehicles, explosives, gases, aerosols, flammable materials, peroxides, oxidizers, toxic substances, radioactive materials, and acids. {elevioLearnMoreButton}"
              values={{
                elevioLearnMoreButton: <ElevioLearnMoreButton articleId={ElevioArticleId.DangerousGoods} asLink />,
              }}
            />
          }
        />
      ),
    }}
  />
);

export const OldDangerousGoodsToggle = () => {
  const { options, error } = useDangerousGoodsToggle();
  return (
    <FormGroup label={toggleLabel} hasError={!!error} errorMessage={error?.message}>
      <Box marginTop="S4">
        <CheckableTileSelector options={options} type="radio" />
        {error?.type === DangerousGoodsError.NOT_ALLOWED && (
          <Box marginTop="S4">
            <DangerousGoodsNotAllowedError />
          </Box>
        )}
      </Box>
    </FormGroup>
  );
};
