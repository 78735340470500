import { ValidShipByDTO, WholesaleShipmentType } from "@deliverr/wholesale-client";
import { Box } from "common/components/ui";
import { ShipBy } from "common/wholesale-common/components/ShipBy";
import React, { FC } from "react";
import { wholesaleCreateLabels } from "wholesale/create/wholesaleCreate.labels";

export interface SelectShipmentShipByDateProps {
  cargoType?: WholesaleShipmentType;
  onShipByChange: (date: Date) => void;
  selectedShipByDate?: Date | string;
  hasShipByDateChanged: boolean;
  validShipBy?: ValidShipByDTO;
  shipBy?: string | null;
}

export const SelectShipmentShipByDate: FC<SelectShipmentShipByDateProps> = ({
  cargoType,
  hasShipByDateChanged,
  onShipByChange,
  selectedShipByDate,
  shipBy,
  validShipBy,
}) => {
  const { selectShipBy } = wholesaleCreateLabels.steps.shipping;

  return (
    <Box width="95%" paddingBottom="S6">
      <ShipBy
        date={parseDate(selectedShipByDate)}
        minDate={parseDate(validShipBy?.allowedScheduleRange?.startDate)}
        maxDate={parseDate(validShipBy?.allowedScheduleRange?.endDate)}
        onDateChange={onShipByChange}
        label={selectShipBy.label}
        toolTip={selectShipBy.toolTip}
        suffixMessage={hasShipByDateChanged || Boolean(shipBy) ? undefined : selectShipBy.suffixMessage}
        selectedCargoType={cargoType}
      />
    </Box>
  );
};

const parseDate = (dateString: Date | string | undefined) => (dateString ? new Date(dateString) : undefined);
