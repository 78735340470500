import { flatten, groupBy, mapValues, map, property, pipe } from "lodash/fp";
import { createSelector } from "reselect";

import { getLoadedShipmentPackages } from "inbounds/steps/InboundStepSelectors";

// Helper functions for processing packages
const mapPkgItems = map(({ items }) => items);
const mapQtys = map(property("qty"));
const groupByDsku = groupBy("dsku");

/**
 * Returns a dictionary mapping DSKU to an array of quantities of items per box for that DSKU.
 */
export const getLoadedShipmentBoxQtyBySku = createSelector(getLoadedShipmentPackages, (packages) => {
  const dskuToPackageItems = pipe(mapPkgItems, flatten, groupByDsku)(packages);
  return mapValues(mapQtys, dskuToPackageItems);
});
