import { createSelector } from "reselect";
import { getSelectedProducts } from "./getSelectedProducts";
import { FeatureName, getFeatureSelector } from "common/Split";
import isEmpty from "lodash/isEmpty";
import { isTotalUnitsEmpty } from "storage/inbounds/utils/isTotalUnitsEmpty";
import { getSelectedProductsWithUnsetCasePacks } from "./getSelectedProductsWithUnsetCasePacks";

export const selectHasInvalidSelectedProducts = createSelector(
  getSelectedProducts,
  getSelectedProductsWithUnsetCasePacks,
  // passing state to getFeatureSelector this way prevents odd error "cannot read propert getFeatureSelector of undefined"
  (state) => getFeatureSelector(FeatureName.StorageMultiCasePack)(state),
  (selectedProducts, selectedProductsWithUnsetCasePacks, isStorageMultiCasePackOn) => {
    const isAnyProductCasePackUnset = isStorageMultiCasePackOn && !isEmpty(selectedProductsWithUnsetCasePacks);
    return isEmpty(selectedProducts) || isTotalUnitsEmpty(selectedProducts) || isAnyProductCasePackUnset;
  }
);
