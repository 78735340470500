import {
  freightQuoteSummaryInitialState,
  FreightQuoteSummaryState,
} from "FreightQuote/FreightQuoteSummary/store/FreightQuoteSummaryState";
import { freightQuoteFormInitialState, FreightQuoteFormState } from "../FreightQuoteTool/store/FreightQuoteFormState";

export interface FreightQuoteState {
  freightQuoteForm: FreightQuoteFormState;
  freightQuoteId: string;
  freightQuoteSummary: FreightQuoteSummaryState;
}

export const freightQuoteInitialState: FreightQuoteState = {
  freightQuoteForm: freightQuoteFormInitialState,
  freightQuoteId: "",
  freightQuoteSummary: freightQuoteSummaryInitialState,
};
