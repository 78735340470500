import { DefaultTheme, LocalizedMessage, Text } from "common/components/ui";
import { IconV2 } from "common/components/ui/IconV2";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

interface BackButtonProps {
  to: string;
  label?: LocalizedMessage;
}

const StyledLink = styled(Link)<{}, DefaultTheme>(
  ({ theme }) => `
    display: inline-flex;
    align-items: center;
    gap: ${theme.spacing.S1};
  `
);

export const BackButton: FC<BackButtonProps> = ({ to, label }) => (
  <StyledLink to={to}>
    <IconV2 type="arrow-left" />
    <Text bold size="bodyLarge">
      {label ?? <FormattedMessage id="channels.back" defaultMessage="Go Back" />}
    </Text>
  </StyledLink>
);
