import React from "react";

import cls from "./HorizontalLineTable.less";

interface OwnProps {
  headers: JSX.Element[];
  rows: JSX.Element[][];
  className?: string;
}

export class HorizontalLineTable extends React.Component<OwnProps, {}> {
  public render() {
    return (
      <table className={this.props.className}>
        <thead>
          <tr className={cls.row}>
            {this.props.headers.map((header, i) => (
              <th className={cls.header} key={i}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {this.props.rows.map((row, i) => (
            <tr className={cls.row} key={i}>
              {row.map((cell, j) => (
                <td key={`${i}-${j}`}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
