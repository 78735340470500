import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { defaultTheme, Icon, Stack, Text, ThemeProps, Title } from "common/components/ui";
import styled from "@emotion/styled";
import { TextAlign } from "common/components/ui/shared";

interface NonComplianceConfirmationHeaderProps {
  dismissed?: boolean;
}

const IconContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${theme.colors.GREEN[100]};
    padding: ${theme.spacing.S4};
  `
);

const CenteredStack = styled(Stack)<ThemeProps>(
  ({ theme }) => `
    padding-bottom: ${theme.spacing.S4};
    border-bottom: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[60]};
    & > * {
      margin: 0 0 ${theme.spacing.S4};
      text-align: center;
    }
  `
);

export const NonComplianceConfirmationHeader: FC<NonComplianceConfirmationHeaderProps> = ({ dismissed }) => (
  <CenteredStack center>
    <IconContainer>
      <Icon type="check" size="1x" color={defaultTheme.colors.GREEN[300]} />
    </IconContainer>
    <Title displayAs="h4">
      <FormattedMessage id="nonCompliance.success.header" defaultMessage="Submission Successful" />
    </Title>
    <Text textAlign={TextAlign.center}>
      {dismissed ? (
        <FormattedMessage
          id="nonCompliance.success.dismissedDescription"
          defaultMessage="Flexport will continue to investigate and you will not be charged. This will be removed from your Problems list."
        />
      ) : (
        <FormattedMessage
          id="nonCompliance.success.description"
          defaultMessage="Your product will be received as soon as possible. This may take up to 3 weeks."
        />
      )}
    </Text>
  </CenteredStack>
);
