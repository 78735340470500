import { InboundBookingType } from "@deliverr/legacy-inbound-client";
import { AmazonDestinationType, DistributionType } from "inbounds/createShipment/CreateShipmentTypes";

interface ParseBookingTypeResult {
  isByFlexport: boolean;
  isByAmazon: boolean;
  isP2P: boolean;
  isDTCNetwork: boolean;
  distributionType: DistributionType | null;
  amazonDestinationType: AmazonDestinationType | null;
}

export const BOOKING_TYPE_TO_SELECTIONS: Record<InboundBookingType, ParseBookingTypeResult> = {
  [InboundBookingType.INTL_STORAGE_STORAGE]: {
    isByAmazon: true,
    isByFlexport: true,
    isP2P: false,
    isDTCNetwork: false,
    distributionType: null,
    amazonDestinationType: null,
  },
  [InboundBookingType.INTL_STORAGE_DTCSTORAGE]: {
    isByAmazon: false,
    isByFlexport: true,
    isP2P: false,
    isDTCNetwork: false,
    distributionType: null,
    amazonDestinationType: null,
  },
  [InboundBookingType.INTL_STORAGE_FBASTORAGE]: {
    isByAmazon: true,
    isByFlexport: false,
    isP2P: false,
    isDTCNetwork: false,
    distributionType: DistributionType.REPLENISH_ON_DEMAND,
    amazonDestinationType: null,
  },
  [InboundBookingType.INTL_STORAGE_FBASINGLE]: {
    isByAmazon: true,
    isByFlexport: false,
    isP2P: false,
    isDTCNetwork: false,
    distributionType: DistributionType.FORWARD_TO_AMAZON,
    amazonDestinationType: AmazonDestinationType.SINGLE,
  },
  [InboundBookingType.INTL_STORAGE_FBAMULTI]: {
    isByAmazon: true,
    isByFlexport: false,
    isP2P: false,
    isDTCNetwork: false,
    distributionType: DistributionType.FORWARD_TO_AMAZON,
    amazonDestinationType: AmazonDestinationType.MULTIPLE,
  },
  [InboundBookingType.INTL_STORAGE_FBAUNKOWN]: {
    isByAmazon: true,
    isByFlexport: false,
    isP2P: false,
    isDTCNetwork: false,
    distributionType: DistributionType.FORWARD_TO_AMAZON,
    amazonDestinationType: AmazonDestinationType.UNKNOWN,
  },
  [InboundBookingType.INTL_STORAGE_FBAAUTOMATED]: {
    isByAmazon: true,
    isByFlexport: false,
    isP2P: false,
    isDTCNetwork: false,
    distributionType: DistributionType.FORWARD_TO_AMAZON,
    amazonDestinationType: AmazonDestinationType.AUTOMATED,
  },
  [InboundBookingType.INTL_DIRECT_FBASINGLE]: {
    isByAmazon: true,
    isByFlexport: false,
    isP2P: false,
    isDTCNetwork: false,
    distributionType: DistributionType.DIRECT_TO_AMAZON,
    amazonDestinationType: AmazonDestinationType.SINGLE,
  },
  [InboundBookingType.INTL_DIRECT_FBAMULTI]: {
    isByAmazon: true,
    isByFlexport: false,
    isP2P: false,
    isDTCNetwork: false,
    distributionType: DistributionType.DIRECT_TO_AMAZON,
    amazonDestinationType: AmazonDestinationType.MULTIPLE,
  },
  [InboundBookingType.INTL_DIRECT_FBAUNKNOWN]: {
    isByAmazon: true,
    isByFlexport: false,
    isP2P: false,
    isDTCNetwork: false,
    distributionType: DistributionType.DIRECT_TO_AMAZON,
    amazonDestinationType: AmazonDestinationType.UNKNOWN,
  },
  [InboundBookingType.INTL_DIRECT_P2P]: {
    isByAmazon: false,
    isByFlexport: false,
    isP2P: true,
    isDTCNetwork: false,
    distributionType: null,
    amazonDestinationType: null,
  },
  [InboundBookingType.INTL_DTC]: {
    isByAmazon: false,
    isByFlexport: false,
    isP2P: false,
    isDTCNetwork: true,
    distributionType: null,
    amazonDestinationType: null,
  },
};

export const parseBookingType = (bookingType: InboundBookingType | undefined) =>
  bookingType
    ? BOOKING_TYPE_TO_SELECTIONS[bookingType]
    : {
        isByFlexport: false,
        isByAmazon: false,
        isP2P: false,
        isDTCNetwork: false,
        distributionType: null,
        amazonDestinationType: null,
      };
