import { addDays } from "date-fns";

// DATES
export const EARLIEST_PICKUP_OFFSET = 1;
export const LATEST_PICKUP_OFFSET = 14;
export const EARLIEST_PICKUP_DATE = addDays(new Date(), EARLIEST_PICKUP_OFFSET);
export const LATEST_PICKUP_DATE = addDays(new Date(), LATEST_PICKUP_OFFSET);

// PALLETS
export const LTL_PALLET_INITIAL_WIDTH = 48;
export const LTL_PALLET_INITIAL_LENGTH = 40;

// CONFIGS
export const LTL_TOAST_CONFIG = { autoClose: 5000 };

export enum LtlInstructionsCard {
  PACKING_LIST = "PACKING_LIST",
  FREIGHT_PALLETS = "FREIGHT_PALLETS",
  PICKUP_DETAILS = "PICKUP_DETAILS",
  SHIPPING_OPTION = "SHIPPING_OPTION",
  REVIEW_QUOTE = "REVIEW_QUOTE",
  BILL_OF_LADING = "BILL_OF_LADING",
  SHIPMENT_CONFIRMATION = "SHIPMENT_CONFIRMATION",
  FREIGHT_INSTRUCTIONS = "FREIGHT_INSTRUCTIONS",
}
