import { SPThunkAction } from "common/ReduxUtils";
import { logError } from "Logger";
import { keyBy } from "lodash";
import { FormattedMessage } from "react-intl";
import React from "react";
import {
  setArePacksLoading,
  setProductCasePackOptions,
  setSelectedCasePackOption,
} from "../SelectProductCasePackActions";
import { removeStorageProduct, updateCaseQty } from "../../selectProducts/SelectStorageProductsActions";
import { batch } from "react-redux";
import { toast } from "common/components/ui";
import { getUnifiedProducts } from "common/wholesale-common/getUnifiedProducts";
import { ProductCollection } from "common/models";

export const getProductCasePacks = (dsku: string, isStorageMultiCasePackOn?: boolean): SPThunkAction => async (
  dispatch
) => {
  try {
    dispatch(setArePacksLoading(dsku, true));
    const unifiedProducts: ProductCollection = await dispatch(getUnifiedProducts([dsku]));

    const productCasePacks = unifiedProducts?.[dsku]?.productCasePacks ?? [];

    if (productCasePacks.length) {
      const productCasePacksByDsku = keyBy(productCasePacks, (casePack) => casePack.dsku);
      const selectedProductCasePack = productCasePacks.find((pack) => pack.isDefaultPack) ?? productCasePacks?.[0];

      batch(() => {
        if (isStorageMultiCasePackOn) {
          dispatch(setProductCasePackOptions(dsku, productCasePacksByDsku));
          dispatch(setSelectedCasePackOption(dsku, selectedProductCasePack));
        }
        dispatch(updateCaseQty(selectedProductCasePack.packOf, selectedProductCasePack.quantity));
      });
    }
  } catch (err) {
    dispatch(removeStorageProduct(dsku));
    logError({ err, dsku }, "error getting product case packs for dsku");
    toast.error(
      <FormattedMessage
        id={"storage.inbounds.getProductCasePacks.error"}
        defaultMessage={"Network error, please try again!"}
      />
    );
  } finally {
    dispatch(setArePacksLoading(dsku, false));
  }
};
