import { DeliverrAddress } from "@deliverr/commons-objects";

export enum FreightQuoteServiceLabel {
  DRAYAGE = "Drayage",
  FTL = "Full-truckload service",
  TRANSLOAD = "Transload",
}

export interface FreightQuotePlanItem {
  service: FreightQuoteServiceLabel;
  totalCost: number;
  unitCost: number;
  quantity: number;
}

export interface FreightQuote {
  id: string;
  hashId?: string;
  sellerId: string;
  internalUserId?: string;
  to: DeliverrAddress;
  from: DeliverrAddress;
  numberOfTrucks: number;
  createdAt: string;
  plan: FreightQuotePlanItem[];
}

export interface FreightQuoteSummaryState {
  quote: FreightQuote;
  quoteId: string;
  isLoading: boolean;
}

export const freightQuoteSample: FreightQuote = {
  id: "123",
  hashId: "7N1aMj3bWm",
  sellerId: "gweentoysinc",
  internalUserId: "mshaked@deliverr.com",
  to: {
    name: "",
    street1: "8 Milburn Park",
    city: "Evanston",
    zip: "60201",
    state: "IL",
    country: "United States",
  },
  from: {
    name: "",
    street1: "910 Union Street",
    city: "Brooklyn",
    zip: "11215",
    state: "NY",
    country: "United States",
  },
  numberOfTrucks: 2,
  createdAt: new Date().toString(),
  plan: [
    {
      service: FreightQuoteServiceLabel.FTL,
      totalCost: 16000,
      unitCost: 8000,
      quantity: 2,
    },
    {
      service: FreightQuoteServiceLabel.DRAYAGE,
      totalCost: 4000,
      unitCost: 2000,
      quantity: 2,
    },
    {
      service: FreightQuoteServiceLabel.TRANSLOAD,
      totalCost: 4000,
      unitCost: 2000,
      quantity: 2,
    },
  ],
};

export const freightQuoteSummaryInitialState: FreightQuoteSummaryState = {
  quoteId: "",
  quote: {
    id: "",
    sellerId: "",
    internalUserId: "",
    to: {
      name: "",
      street1: "",
      city: "",
      zip: "",
      state: "",
      country: "United States",
    },
    from: {
      name: "",
      street1: "",
      city: "",
      zip: "",
      state: "",
      country: "United States",
    },
    numberOfTrucks: 0,
    createdAt: "",
    plan: [],
  },
  isLoading: false,
};
