import styled from "@emotion/styled";
import React from "react";
import { isMobile } from "../shared/helpers/utils";
import { ThemeProps, HeaderElement, DefaultTheme, TextAlign } from "../shared";

export type TitleElements = Exclude<keyof typeof HeaderElement, "h6">;

export interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  as?: any;
  /** Style of title */
  displayAs?: keyof typeof HeaderElement;
  /** Sets the title to uppercase */
  uppercase?: boolean;
  textAlign?: TextAlign;
}

const setSize = ({ displayAs, theme }: ThemeProps<TitleProps>) => {
  const sizes: Record<TitleElements, string> = {
    [HeaderElement.h1]: `
      font-size: ${theme.font.size.F7};
      letter-spacing: ${theme.font.letterSpacing.LS4};
      line-height: ${theme.font.lineHeight.LH7};
    `,
    [HeaderElement.h2]: `
      font-size: ${theme.font.size.F6};
      letter-spacing: ${theme.font.letterSpacing.LS3};
      line-height: ${theme.font.lineHeight.LH6};
    `,
    [HeaderElement.h3]: `
      font-size: ${theme.font.size.F5};
      letter-spacing: ${theme.font.letterSpacing.LS0};
      line-height: ${theme.font.lineHeight.LH5};
    `,
    [HeaderElement.h4]: `
      font-size: ${theme.font.size.F4};
      letter-spacing: ${theme.font.letterSpacing.LS0};
      line-height: ${theme.font.lineHeight.LH4};
    `,
    [HeaderElement.h5]: `
      font-size: ${theme.font.size.F3};
      letter-spacing: ${theme.font.letterSpacing.LS4};
      line-height: ${theme.font.lineHeight.LH3};
      font-weight: ${isMobile(theme) ? theme.font.weight.BOLD : theme.font.weight.BLACK};
    `,
  };
  return sizes[displayAs as HeaderElement];
};

export const Title = styled.h1<TitleProps, DefaultTheme>(
  ({ displayAs = HeaderElement.h1, uppercase, textAlign = TextAlign.start, theme }) => `
  font-family: ${theme.font.family.TITLE};
  font-weight: ${theme.font.weight.BOLD};
  text-transform: ${uppercase ? `uppercase` : `none`};
  ${setSize({ displayAs, theme })}
  text-align: ${textAlign};
`
);
