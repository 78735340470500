import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import produce from "immer";
import { WholesaleCreateInitialStep } from "../steps/createWholesaleSteps";
import { WholesaleCreateActionTypes } from "./WholesaleCreateActionTypes";
import { WholesaleCreateState } from "./WholesaleCreateState";
import { StorageProductData } from "@deliverr/storage-client";

export const wholesaleOrderCreateInitialState: WholesaleCreateState = {
  stack: [WholesaleCreateInitialStep],
  selectedProducts: {},
  availableInventory: [],
  selectedProductsCaseInfo: {},
  selectedProductsLotNumbers: {},
};

const reducers: ReducerDictionary<WholesaleCreateState> = {
  ...handleSimpleReducerUpdates([
    WholesaleCreateActionTypes.WHOLESALE_SELECT_DESTINATION_ADDRESS,
    WholesaleCreateActionTypes.WHOLESALE_SET_PO_NUMBER,
    WholesaleCreateActionTypes.WHOLESALE_SET_AVAILABLE_INVENTORY,
    WholesaleCreateActionTypes.WHOLESALE_CREATE_CHOOSE_ORIGIN_STORAGE_WAREHOUSE,
    WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_WHOLESALE_ORDER,
    WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_ERROR,
    WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_NOTES,
    WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_VALID_SHIP_BY,
  ]),
  [WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_LABEL_FILENAME]: (state, { label, file }) =>
    produce(state, (draft) => {
      draft.labelFiles = { ...draft.labelFiles, [label]: file };
    }),
  [WholesaleCreateActionTypes.WHOLESALE_CREATE_ADD_PRODUCT]: (state, { dsku, product }) => {
    return produce(state, (draft) => {
      draft.selectedProducts[dsku] = product;
    });
  },
  [WholesaleCreateActionTypes.WHOLESALE_CREATE_REMOVE_PRODUCT]: (state, { dsku }) => {
    return produce(state, (draft) => {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete draft.selectedProducts[dsku];
    });
  },
  [WholesaleCreateActionTypes.WHOLESALE_CREATE_RESET]: () => wholesaleOrderCreateInitialState,
  [WholesaleCreateActionTypes.WHOLESALE_CREATE_UPDATE_NUMBER_OF_CASES]: (state, { dsku, numberOfCases }) => {
    return produce(state, (draft) => {
      const product = draft.selectedProducts[dsku];
      product.numberOfCases = numberOfCases;
      product.qty = numberOfCases * (product.caseQty ?? 1);
    });
  },
  [WholesaleCreateActionTypes.WHOLESALE_CREATE_CHOOSE_SHIPPING_CONFIG]: (
    state,
    { shippingConfig: { shippingMethod, cargoType, shipBy } }
  ) =>
    produce(state, (draft) => {
      draft.shippingMethod = shippingMethod;
      draft.cargoType = cargoType;
      draft.shipBy = shipBy;
    }),
  [WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_UPLOADING]: (state, { attachmentType, value }) =>
    produce(state, (draft) => {
      if (!draft.isUploadingLabel) {
        draft.isUploadingLabel = {};
      }
      Object.assign(draft.isUploadingLabel, { [attachmentType]: value });
    }),
  [WholesaleCreateActionTypes.WHOLESALE_SET_SELECTED_PRODUCTS_CASE_INFO]: (state, { selectedProductsCaseInfo }) => {
    return produce(state, (draft) => {
      Object.keys(selectedProductsCaseInfo).forEach((dsku) => {
        if (selectedProductsCaseInfo[dsku]) {
          const selectedProductCaseInfo: StorageProductData = selectedProductsCaseInfo[dsku];
          const selectedProduct = draft.selectedProducts[dsku];
          draft.selectedProductsCaseInfo[dsku] = {
            ...draft.selectedProductsCaseInfo[dsku],
            caseHeight: selectedProductCaseInfo.caseHeight,
            caseLength: selectedProductCaseInfo.caseLength,
            caseWidth: selectedProductCaseInfo.caseWidth,
            caseWeight: selectedProductCaseInfo.caseWeight,
            weightUnit: selectedProductCaseInfo.weightUnit,
            lengthUnit: selectedProductCaseInfo.lengthUnit,
            caseQty: selectedProductCaseInfo.caseQty,
          };
          selectedProduct.caseQty = selectedProductCaseInfo.caseQty ?? selectedProduct.caseQty;
          selectedProduct.numberOfCases = (selectedProduct.qty ?? 0) / (selectedProduct.caseQty ?? 1);
        }
      });
    });
  },
};

export const wholesaleOrderCreateReducer = createReducer<WholesaleCreateState>(
  wholesaleOrderCreateInitialState,
  reducers
);
