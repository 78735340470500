import { defineMessages } from "react-intl";
import { ServiceNames } from "../utils";

export const GlobalSearchListItemHeading = defineMessages({
  [ServiceNames.REPLENISHMENT]: {
    id: "globalSearch.listItemHeading.replenishment",
    defaultMessage: "Replenishments",
  },
  [ServiceNames.INBOUND]: {
    id: "globalSearch.listItemHeading.inbound",
    defaultMessage: "Inbounds",
  },
  [ServiceNames.PREP]: {
    id: "globalSearch.listItemHeading.prep",
    defaultMessage: "Prep Jobs",
  },
  [ServiceNames.PARCEL]: {
    id: "globalSearch.listItemHeading.parcel",
    defaultMessage: "Parcels",
  },
  [ServiceNames.WHOLESALE]: {
    id: "globalSearch.listItemHeading.wholesale",
    defaultMessage: "Wholesale Orders",
  },
  [ServiceNames.INVENTORY]: {
    id: "globalSearch.listItemHeading.inventory",
    defaultMessage: "Inventory",
  },
  [ServiceNames.ECOM]: {
    id: "globalSearch.listItemHeading.ecom",
    defaultMessage: "Ecommerce Orders",
  },
});
