import { ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import HtmlToReact from "common/components/HtmlToReact";
import React from "react";
import { FormattedMessage } from "react-intl";
import { packingInstructionsLabels } from "./packingInstructions.labels";
import LabelledCaseSvg from "assets/img/inbounds/packing-instructions-flc.svg";

const StyledFigure = styled.figure<ThemeProps>(
  ({ theme }) => `
    position: relative;
    margin: ${theme.spacing.S6} auto 0 auto;
    height: 355px;
    width: 830px;
  `
);

const StyledHtmlToReact = styled(HtmlToReact)<ThemeProps>(
  ({ theme }) => `
    margin: auto ${theme.spacing.S9} auto ;
    width: 75%;
  `
);

const LabelsContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    position: absolute;
    top: 0;
    left: 0;
    width: 830px;
    height: 312.53px;
    figcaption {
      font-size: ${theme.font.size.F2};
      line-height: ${theme.font.lineHeight.LH2};
      position: absolute;
    };
  `
);

const FigCaptionCasePack = styled.figcaption`
  width: 154px;
  left: 0;
  top: 4px;
`;

export const CasePackingInstructions: React.FC = () => {
  return (
    <StyledFigure>
      <StyledHtmlToReact key="storage.inbounds.detail.casePackingInstructions" html={LabelledCaseSvg} />
      <LabelsContainer>
        <FigCaptionCasePack>
          <FormattedMessage {...packingInstructionsLabels.casePackLabel} />
        </FigCaptionCasePack>
      </LabelsContainer>
    </StyledFigure>
  );
};
