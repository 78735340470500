import { ChannelAttributesCode } from "common/clients/onboarding/SalesChannel/ChannelAttributesCode";
import { SalesChannel } from "common/clients/onboarding/SalesChannel/SalesChannel";
import { find } from "lodash/fp";

export const getChannelAttribute = (channel: SalesChannel, code: ChannelAttributesCode) =>
  find({ code }, channel.channelAttributesArray);

export const useChannelAttributes = (channel: SalesChannel) => {
  const fulfillmentChannels = getChannelAttribute(channel, ChannelAttributesCode.BC_CHANNEL);
  const safetyStock = getChannelAttribute(channel, ChannelAttributesCode.SAFETY_STOCK);
  const shopifySettings = getChannelAttribute(channel, ChannelAttributesCode.SHOPIFY_SETTINGS);
  const orderIngestion = getChannelAttribute(channel, ChannelAttributesCode.ORDER_INGESTION);
  const amazonSettings = getChannelAttribute(channel, ChannelAttributesCode.AMAZON_SETTINGS);
  const canShowOrderIngestion = !!shopifySettings;

  return {
    fulfillmentChannels,
    safetyStock,
    shopifySettings,
    orderIngestion,
    amazonSettings,
    canShowOrderIngestion,
  };
};
