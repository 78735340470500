import { StorageItem } from "@deliverr/storage-client";
import { StorageProductData } from "common/clients/storage/StorageProduct/StorageProductData";

export const mapStorageOutboundItemToSelectedProductsCaseInfo = (
  storageOutboundItems?: StorageItem[]
): { [dsku: string]: StorageProductData } => {
  return (storageOutboundItems ?? []).reduce((map, { dsku, caseQty }) => {
    map[dsku] = {
      dsku,
      caseQty,
    };
    return map;
  }, {});
};
