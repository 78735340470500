import { DeliverrAddress } from "@deliverr/commons-objects";

export const getDefaultDeliverrAddress = (): DeliverrAddress => {
  return {
    name: "",
    street1: "",
    city: "",
    zip: "",
    state: "",
    country: "",
  };
};
