import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Button, DefaultTheme, RadioTileGroup, Text, TextProps } from "common/components/ui";
import styled from "@emotion/styled";
import { MissingBoxesAlertStatus } from "@deliverr/commons-clients";

import { SHIPMENT_PROBLEMS_SHORT_SHIP_BLADE_ID } from "../blades";
import { ProblemRowData, ShortShipProblemRow } from "common/components/ProblemsTable/types";
import { useShipmentProblemShortShipBlade } from "./useShipmentProblemShortShipBlade";
import { ProblemBaseBlade } from "common/components/ProblemBlade/ProblemBaseBlade";
import { StatusSection } from "./StatusSection";
import { SHORT_SHIP_STATUS_SPECIFIC_LABELS } from "./labels";
import { ShortShipLearnMore } from "./ShortShipLearnMore";
import {
  ProblemBladeTableContainer,
  ProblemBladeTable,
} from "common/components/ProblemBlade/components/ProblemBladeTable";
import { PROBLEM_TYPE_LABEL_MAP } from "common/problems/problemTypeLabelMap";
import { ProblemType } from "common/problems/ProblemType";
import { SelectedShipmentProblem } from "../../context";

const Footer = styled.div<{ defaultButtons?: boolean }, DefaultTheme>`
  display: flex;
  ${({ defaultButtons }) => (defaultButtons ? "justify-content: flex-end" : "")};
`;

const PromptText = styled(Text)<TextProps, DefaultTheme>(
  ({ theme }) => `
  padding-bottom: ${theme.spacing.S4};
`
);

const HorizontalRadioTileGroup = styled(RadioTileGroup)`
  flex-direction: row;

  & label {
    flex-grow: 1;
  }
`;
export interface ShipmentProblemShortShipBladeProps {
  shipmentProblem: ShortShipProblemRow;
  refetch: (...args: any[]) => Promise<any>;
  hideShipmentProblemBlade: () => void;
  setSelectedProblem: (problem: SelectedShipmentProblem | undefined) => void;
  isShipmentProblemBladeOpen: boolean;
  selectedProblemCase: ProblemRowData | undefined;
}

export const ShipmentProblemShortShipBlade: FC<ShipmentProblemShortShipBladeProps> = ({
  shipmentProblem,
  refetch,
  isShipmentProblemBladeOpen,
  selectedProblemCase,
  hideShipmentProblemBlade,
  setSelectedProblem,
}) => {
  const {
    packageTableProps,
    dskuTableProps,
    shortShipOption,
    radioOptions,
    showRadioSelectors,
    showDefaultBladeButton,
    handleSelect,
    activeButtonLabels,
    resetBladeState,
    handleSubmitClick,
    shouldShowFooter,
  } = useShipmentProblemShortShipBlade(shipmentProblem, refetch, hideShipmentProblemBlade, setSelectedProblem);
  const BUTTON_TEXT = showDefaultBladeButton ? activeButtonLabels[0] : activeButtonLabels[1];
  const handleClick = showDefaultBladeButton ? resetBladeState : handleSubmitClick;

  return (
    <ProblemBaseBlade
      id={SHIPMENT_PROBLEMS_SHORT_SHIP_BLADE_ID}
      title={PROBLEM_TYPE_LABEL_MAP[ProblemType.SHORT_SHIP_BOXES]}
      footer={
        shouldShowFooter ? (
          <Footer defaultButtons>
            <Button onClick={handleClick}>{BUTTON_TEXT}</Button>
          </Footer>
        ) : undefined
      }
      onCloseClick={resetBladeState}
      isBladeOpen={isShipmentProblemBladeOpen}
      updatedAt={selectedProblemCase?.updatedAt}
    >
      <StatusSection status={shipmentProblem.summary.status} />
      {packageTableProps.data.length ? (
        <ProblemBladeTableContainer>
          <Text>
            <FormattedMessage
              id="inbounds.shipmentProblemShortShipBlade.boxDiscrepancyTable"
              defaultMessage="Box Discrepancy"
            />
          </Text>
          <ProblemBladeTable {...packageTableProps} />
        </ProblemBladeTableContainer>
      ) : null}
      {dskuTableProps.data.length ? (
        <ProblemBladeTableContainer>
          <Text>
            <FormattedMessage
              id="inbounds.shipmentProblemShortShipBlade.unitDiscrepancyTable"
              defaultMessage="Unit Discrepancy"
            />
          </Text>
          <ProblemBladeTable {...dskuTableProps} />
        </ProblemBladeTableContainer>
      ) : null}
      {showRadioSelectors && (
        <div>
          <PromptText>
            <FormattedMessage {...SHORT_SHIP_STATUS_SPECIFIC_LABELS[MissingBoxesAlertStatus.CREATED].prompt} />
          </PromptText>
          <HorizontalRadioTileGroup
            name="inbounds.shipmentProblemShortShipBlade.confirmation"
            currentValue={shortShipOption}
            block
            onChange={handleSelect}
            direction="VERTICAL"
            options={radioOptions}
          />
        </div>
      )}
      <ShortShipLearnMore />
    </ProblemBaseBlade>
  );
};
