import { InboundRateErrorCode } from "@deliverr/legacy-inbound-client";
import { toast } from "common/components/ui";

import { FREIGHT_SERVER_ERROR_MESSAGES } from "inbounds/store/actions/freight/freightServerErrorMessages";
import { LTL_TOAST_CONFIG } from "./constants";

export const handleLtlErrors = (err: string | InboundRateErrorCode, purchasingError?: boolean) => {
  // either provide the corresponding mapped error message
  // a purchasing error if thats where the error occurred, or unexpected
  const message = FREIGHT_SERVER_ERROR_MESSAGES[err]
    ? FREIGHT_SERVER_ERROR_MESSAGES[err]
    : purchasingError
    ? FREIGHT_SERVER_ERROR_MESSAGES.GENERIC_PURCHASING_ERROR
    : FREIGHT_SERVER_ERROR_MESSAGES.UNEXPECTED_ERROR;

  return toast.error(message, LTL_TOAST_CONFIG);
};
