import { DetailedSelectOption } from "./../DetailedSelect/DetailedSelect";
import { useIntl } from "react-intl";
import { ProductLotNumberSelectMessages } from "./ProductLotNumberSelectMessages";
import { ATPDetails } from "common/clients/inventory/ATP/ATPDetails";

interface UseProductLotNumberSelectProps {
  productLotNumbers: ATPDetails[];
  selectedLotNumber?: ATPDetails;
  onSelectLotNumber?: (lotNumber?: string) => void;
}

export const useProductLotNumberSelect = ({
  productLotNumbers,
  selectedLotNumber,
  onSelectLotNumber,
}: UseProductLotNumberSelectProps) => {
  const { formatMessage } = useIntl();

  const fefoLotOption: DetailedSelectOption = {
    value: "",
    label: formatMessage(ProductLotNumberSelectMessages.fefoLabel),
    subtitle: "",
  };

  const selectionOptions: DetailedSelectOption[] = [
    fefoLotOption,
    ...(productLotNumbers
      ?.filter(({ totalPickableQty, lotNumber }) => totalPickableQty > 0 && lotNumber)
      ?.map((atpDetails) => ({
        value: atpDetails.lotNumber ?? "",
        label: atpDetails.lotNumber ?? "",
        subtitle: formatMessage(
          atpDetails.expirationDate
            ? ProductLotNumberSelectMessages.lotNumberWithExpiryDesc
            : ProductLotNumberSelectMessages.lotNumberWithoutExpiryDesc,
          {
            availableQty: atpDetails.totalPickableQty,
            expirationDate: atpDetails.expirationDate,
          }
        ),
      })) ?? []),
  ];

  const selectedOption: DetailedSelectOption | undefined = selectedLotNumber
    ? selectionOptions.find((option) => option.value === selectedLotNumber.lotNumber)
    : fefoLotOption;

  const onLotNumberChange = (option: DetailedSelectOption) => {
    onSelectLotNumber?.(option?.value);
  };

  return {
    selectionOptions,
    selectedOption,
    onLotNumberChange,
  };
};
