import * as React from "react";
import { useCallback } from "react";
import { SessionRecording, SessionRecordingPrivacy } from "../SessionRecording";
import { extractFormProps, FormGroup, FormGroupProps } from "../FormGroup";
import { StyledPhoneInput } from "./StyledPhoneInput";

// Given that react-phone-input-2 was difficult to customize and has some other weird edges
// in its developer experience, I've opted to edit the list of props to pass down to the
// component to make it easier to switch to a different component in the future. This
// means that if there is some functionality in react-phone-input-2 that is needed in an
// application, but which is not exposed here, we will need to make a change here first.

export interface PhoneNumberInputProps extends FormGroupProps<{}> {
  initialCountry?: string;
  value?: string;
  onlyCountries?: string[];
  preferredCountries?: string[];
  excludeCountries?: string[];
  inputProps?: object;
  disableCountrySelect?: boolean;
  disabled?: boolean;

  /**
   * Privacy of the input for session recordings. The form group surrounding the input
   * is recorded, regardless of this setting. The default is "exclude" as phone numbers
   * are often sensitive information. If you want to record, opt in by setting this value.
   */
  privacy?: SessionRecordingPrivacy;

  parseValue?: (value: string) => ParsedPhoneNumber;
  onChange?: (value: string, parsedValue: ParsedPhoneNumber, event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onEnterKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export interface ParsedPhoneNumber {
  isValid: boolean;
  value: string;
}

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = (props) => {
  const [
    formProps,
    {
      hasError,
      required,
      disabled,
      initialCountry,
      privacy = "exclude",
      parseValue,
      onChange,
      onFocus,
      onBlur,
      onClick,
      disableCountrySelect,
      ...phoneInputProps
    },
  ] = extractFormProps(props);

  const handleChange = useCallback(
    (value, data, event, formattedValue) => {
      const rawValue = formattedValue ?? "";
      onChange?.(rawValue, parseValue?.(rawValue) ?? { isValid: true, value: rawValue }, event);
    },
    [onChange]
  );
  const handleFocus = useCallback((event, data) => onFocus?.(event), [onFocus]);
  const handleBlur = useCallback((event, data) => onBlur?.(event), [onBlur]);
  const handleClick = useCallback((event, data) => onClick?.(event), [onClick]);

  return (
    <FormGroup {...formProps}>
      <SessionRecording privacy={privacy}>
        <StyledPhoneInput
          hasError={hasError}
          disabled={disabled}
          masks={{ cn: "... .... ...." }}
          preserveOrder={["preferredCountries", "onlyCountries"]}
          country={initialCountry}
          countryCodeEditable={false}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClick={handleClick}
          disableDropdown={disableCountrySelect}
          {...phoneInputProps}
        />
      </SessionRecording>
    </FormGroup>
  );
};
