import { PillColors } from "../../common/components/Pill";

export enum FlexportShipmentStatus {
  NEED_PACKAGE_DETAILS = "NEED_PACKAGE_DETAILS",
  READY_FOR_WAREHOUSE = "READY_FOR_WAREHOUSE",
  IN_TRANSIT = "IN_TRANSIT",
  IN_PREP = "IN_PREP",
  RECEIVING = "RECEIVING",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
  IN_PROGRESS = "IN_PROGRESS",
  ACTION_NEEDED = "ACTION_NEEDED",
}

export const flexportStatusPillColors: Record<FlexportShipmentStatus, PillColors> = {
  [FlexportShipmentStatus.NEED_PACKAGE_DETAILS]: PillColors.GRAY,
  [FlexportShipmentStatus.READY_FOR_WAREHOUSE]: PillColors.BLUE,
  [FlexportShipmentStatus.IN_TRANSIT]: PillColors.BLUE,
  [FlexportShipmentStatus.IN_PREP]: PillColors.PURPLE,
  [FlexportShipmentStatus.RECEIVING]: PillColors.PURPLE,
  [FlexportShipmentStatus.COMPLETED]: PillColors.GREEN,
  [FlexportShipmentStatus.CANCELED]: PillColors.GRAY,
  [FlexportShipmentStatus.IN_PROGRESS]: PillColors.BLUE,
  [FlexportShipmentStatus.ACTION_NEEDED]: PillColors.RED,
};
