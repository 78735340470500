import { CountryCode, PackagingType } from "@deliverr/commons-objects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/dedupe";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { isDimsEditable } from "common/utils/product/isDimsConfirmed";
import { Currency, CurrencyFormat } from "common/components/Currency";
import { BoxDimension, BoxDimensions } from "common/components/EstimatedCosts/BoxDimensions";
import { ConfirmedByDeliverrTooltip } from "common/components/EstimatedCosts/ConfirmedByDeliverrTooltip";
import { costEstimatorModalId } from "common/components/EstimatedCosts/useCostEstimatorModal";
import { setCostEstimatorProduct } from "common/components/EstimatedCosts/CostEstimatorModalActions";
import { showModal } from "common/components/Modal/ModalActions";
import { ItemizedPrice, ServiceLevel } from "@deliverr/billing-pricer-client";
import { UnId } from "common/clients/core/UnId";

import cls from "./EstimatedCost.less";

interface OwnProps {
  dsku: string; // This is necessary in case the modal is used
  productName: string;
  dimensions?: BoxDimensions;
  dimSourceType?: string;
  estimatedCosts?: ItemizedPrice;
  serviceLevel: ServiceLevel;
  hazmatInfo: UnId[];
  shippingSpec?: PackagingType;
  countryCode?: CountryCode;
}

export const getProductDimensions = (product): BoxDimensions => ({
  [BoxDimension.Width]: product.width,
  [BoxDimension.Length]: product.length,
  [BoxDimension.Height]: product.height,
  [BoxDimension.Weight]: product.weight,
});

const EstimatedCost: FC<OwnProps> = ({
  dsku,
  productName,
  dimensions,
  dimSourceType,
  estimatedCosts,
  serviceLevel,
  hazmatInfo,
  shippingSpec,
  countryCode,
}) => {
  const dispatch = useDispatch();
  const cost = estimatedCosts?.totalPrice;
  const isEditable = isDimsEditable(dimSourceType);

  const openEstimateModal = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(showModal(costEstimatorModalId));
    dispatch(
      setCostEstimatorProduct(dsku, productName, dimensions, hazmatInfo, shippingSpec, serviceLevel, countryCode)
    );
  };

  const onClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
  };

  const costText = cost && (
    <span onClick={isEditable ? openEstimateModal : undefined}>
      <Currency currency={CurrencyFormat.USD} value={cost} />
      <div className={cls.iconOverlay}>
        <div className={`${cls.iconContainer}`}>
          {isEditable ? (
            <FontAwesomeIcon className={cls.icon} icon="pencil-alt" />
          ) : (
            <FontAwesomeIcon className={cls.icon} icon="lock" />
          )}
        </div>
      </div>
    </span>
  );

  return (
    <span
      className={classNames(cls.cost, {
        [cls.editableCost]: isEditable,
      })}
      onClick={onClick}
    >
      {costText ? (
        isEditable ? (
          costText
        ) : (
          <ConfirmedByDeliverrTooltip>{costText}</ConfirmedByDeliverrTooltip>
        )
      ) : (
        <a onClick={openEstimateModal}>
          <FormattedMessage id={"EstimateCostsLink.text"} defaultMessage={"Estimate"} />
        </a>
      )}
    </span>
  );
};

export default EstimatedCost;
