import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { AttachmentType } from "@deliverr/wholesale-client";
import { selectWholesaleCreate } from "../store/WholesaleCreateSelectors";
import { uploadWholesaleLabelFile } from "../actions/uploadWholesaleLabelFile";
import { wholesaleClearUploadedLabelFile } from "../actions/wholesaleClearUploadedLabelFile";
import { UploadAttachmentType } from "../../../transfers/create/actions";

export const useWholesaleLabelsStep = () => {
  const dispatch = useDispatch();

  const { errorType, wholesaleOrder, isUploadingLabel, labelFiles, notes, cargoType } = useSelector(
    selectWholesaleCreate
  );

  const {
    [AttachmentType.CASE_LABELS]: boxLabelFilename,
    [AttachmentType.PALLET_LABELS]: palletLabelFilename,
    [AttachmentType.PACKING_SLIP]: packingLabelFilename,
    [AttachmentType.VENDOR_GUIDELINES]: guidelinesFilename,
    [AttachmentType.SHIPPING_LABELS]: shippingLabelFilename,
    [AttachmentType.COMMERCIAL_INVOICE]: commercialInvoiceFilename,
  } = labelFiles ?? {};

  const onUploadLabel = useCallback(
    (file: File, attachmentType: AttachmentType) => {
      dispatch(uploadWholesaleLabelFile(file, attachmentType));
    },
    [dispatch]
  );

  const onFileClear = useCallback(
    (attachmentType: UploadAttachmentType) => {
      dispatch(wholesaleClearUploadedLabelFile(attachmentType));
    },
    [dispatch]
  );

  const onPalletFileChange = useCallback((files: File[]) => onUploadLabel(files[0], AttachmentType.PALLET_LABELS), [
    onUploadLabel,
  ]);

  const onGuidelinesFileChange = useCallback(
    (files: File[]) => onUploadLabel(files[0], AttachmentType.VENDOR_GUIDELINES),
    [onUploadLabel]
  );

  const onShippingLabelFileChange = useCallback(
    (files: File[]) => onUploadLabel(files[0], AttachmentType.SHIPPING_LABELS),
    [onUploadLabel]
  );

  const onCommercialIncvoiceFileChange = useCallback(
    (files: File[]) => onUploadLabel(files[0], AttachmentType.COMMERCIAL_INVOICE),
    [onUploadLabel]
  );

  const onPackingSlipFileChange = useCallback((files: File[]) => onUploadLabel(files[0], AttachmentType.PACKING_SLIP), [
    onUploadLabel,
  ]);

  const onBoxFileChange = useCallback((files: File[]) => onUploadLabel(files[0], AttachmentType.CASE_LABELS), [
    onUploadLabel,
  ]);

  const onPalletFileClear = useCallback(async () => onFileClear(AttachmentType.PALLET_LABELS), [onFileClear]);

  const onGuidelinesFileClear = useCallback(async () => onFileClear(AttachmentType.VENDOR_GUIDELINES), [onFileClear]);

  const onShippingLabelFileClear = useCallback(async () => onFileClear(AttachmentType.SHIPPING_LABELS), [onFileClear]);

  const onCommercialIncvoiceFileClear = useCallback(async () => onFileClear(AttachmentType.COMMERCIAL_INVOICE), [
    onFileClear,
  ]);

  const onPackingSlipFileClear = useCallback(async () => onFileClear(AttachmentType.PACKING_SLIP), [onFileClear]);

  const onBoxFileClear = useCallback(async () => onFileClear(AttachmentType.CASE_LABELS), [onFileClear]);

  return {
    errorType,
    cargoType,
    notes,
    wholesaleOrder,
    isUploadingLabel,
    boxLabelFilename,
    palletLabelFilename,
    packingLabelFilename,
    guidelinesFilename,
    onPalletFileChange,
    onBoxFileChange,
    onGuidelinesFileChange,
    onPackingSlipFileChange,
    onShippingLabelFileChange,
    onCommercialIncvoiceFileChange,
    onPalletFileClear,
    onBoxFileClear,
    onGuidelinesFileClear,
    onPackingSlipFileClear,
    onShippingLabelFileClear,
    onCommercialIncvoiceFileClear,
    shippingLabelFilename,
    commercialInvoiceFilename,
  };
};
