export enum InboundShipmentStatus {
  NEED_PACKAGE_DETAILS = "NEED_PACKAGE_DETAILS",
  PACKAGES_ADDED = "PACKAGES_ADDED",
  PACKAGES_FINAL = "PACKAGES_FINAL",
  READY_FOR_WAREHOUSE = "READY_FOR_WAREHOUSE",
  IN_TRANSIT = "IN_TRANSIT",
  ARRIVED = "ARRIVED",
  CROSS_DOCKING = "CROSS_DOCKING",
  IN_PREP = "IN_PREP",
  FORWARDING = "FORWARDING",
  RECEIVING = "RECEIVING",
  COMPLETED = "COMPLETED",
}
