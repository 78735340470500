import React from "react";
import { defineMessages, FormattedNumber, MessageDescriptor } from "react-intl";
import { createSelector } from "reselect";
import { CargoPalletValidations } from "./CargoPalletValidations";
import {
  LTL_MAX_INDIVIDUAL_PALLET_HEIGHT,
  LTL_MAX_INDIVIDUAL_PALLET_LENGTH,
  LTL_MAX_INDIVIDUAL_PALLET_WEIGHT,
  LTL_MAX_INDIVIDUAL_PALLET_WIDTH,
  LTL_MAX_TOTAL_PALLET_WEIGHT,
  LTL_MIN_TOTAL_PALLET_WEIGHT,
} from "freight/constants/cargoPalletConstants";
import { getFreightCargoPalletDetails } from "freight/FreightSelectors";

const PALLET_VALIDATION_ERROR_MESSAGES: Record<CargoPalletValidations, MessageDescriptor> = defineMessages({
  [CargoPalletValidations.PALLET_QTY]: {
    id: "cargoPallet.ltl.maxQtyError",
    defaultMessage: "Number of pallets in the configurations doesn't match the initial total number of pallets",
  },
  [CargoPalletValidations.OVER_MAX_PALLET_TOTAL_WEIGHT]: {
    id: "cargoPallet.ltl.maxTotalWeightError",
    defaultMessage: "The maximum total weight can't be more than {maxTotalPalletWeight} lbs.",
  },
  [CargoPalletValidations.UNDER_MINIMUM_TOTAL_WEIGHT]: {
    id: "cargoPallet.ltl.minWeightError",
    defaultMessage: "The minimum total weight can't be less than {minWeight} lbs.",
  },
  [CargoPalletValidations.PALLETS_OVER_MAX_WEIGHT]: {
    id: "cargoPallet.ltl.maxPalletWeightError",
    defaultMessage: "The maximum individual pallet weight can't be more than {maxPerPalletWeight} lbs.",
  },
  [CargoPalletValidations.PALLETS_OVER_MAX_WIDTH]: {
    id: "cargoPallet.ltl.maxWidthError",
    defaultMessage: "The maximum pallet width can't be more than {maxWidth} inches.",
  },
  [CargoPalletValidations.PALLETS_OVER_MAX_LENGTH]: {
    id: "cargoPallet.ltl.maxLengthError",
    defaultMessage: "The maximum pallet length can't be more than {maxLength} inches.",
  },
  [CargoPalletValidations.PALLETS_OVER_MAX_HEIGHT]: {
    id: "cargoPallet.ltl.maxHeightError",
    defaultMessage: "The maximum pallet height can't be more than {maxHeight} inches.",
  },
});

export const palletValidationErrorMessagesWithValue = defineMessages({
  [CargoPalletValidations.PALLET_QTY]: {
    messageDescriptor: PALLET_VALIDATION_ERROR_MESSAGES[CargoPalletValidations.PALLET_QTY],
  },
  [CargoPalletValidations.OVER_MAX_PALLET_TOTAL_WEIGHT]: {
    messageDescriptor: PALLET_VALIDATION_ERROR_MESSAGES[CargoPalletValidations.OVER_MAX_PALLET_TOTAL_WEIGHT],
    values: { maxTotalPalletWeight: <FormattedNumber value={LTL_MAX_TOTAL_PALLET_WEIGHT} /> },
  },
  [CargoPalletValidations.PALLETS_OVER_MAX_WEIGHT]: {
    messageDescriptor: PALLET_VALIDATION_ERROR_MESSAGES[CargoPalletValidations.PALLETS_OVER_MAX_WEIGHT],
    values: { maxPerPalletWeight: <FormattedNumber value={LTL_MAX_INDIVIDUAL_PALLET_WEIGHT} /> },
  },
  [CargoPalletValidations.PALLETS_OVER_MAX_WIDTH]: {
    messageDescriptor: PALLET_VALIDATION_ERROR_MESSAGES[CargoPalletValidations.PALLETS_OVER_MAX_WIDTH],
    values: { maxWidth: LTL_MAX_INDIVIDUAL_PALLET_WIDTH },
  },
  [CargoPalletValidations.PALLETS_OVER_MAX_LENGTH]: {
    messageDescriptor: PALLET_VALIDATION_ERROR_MESSAGES[CargoPalletValidations.PALLETS_OVER_MAX_LENGTH],
    values: { maxLength: LTL_MAX_INDIVIDUAL_PALLET_LENGTH },
  },
  [CargoPalletValidations.PALLETS_OVER_MAX_HEIGHT]: {
    messageDescriptor: PALLET_VALIDATION_ERROR_MESSAGES[CargoPalletValidations.PALLETS_OVER_MAX_HEIGHT],
    values: { maxHeight: LTL_MAX_INDIVIDUAL_PALLET_HEIGHT },
  },
  [CargoPalletValidations.UNDER_MINIMUM_TOTAL_WEIGHT]: {
    messageDescriptor: PALLET_VALIDATION_ERROR_MESSAGES[CargoPalletValidations.UNDER_MINIMUM_TOTAL_WEIGHT],
    values: { minWeight: LTL_MIN_TOTAL_PALLET_WEIGHT },
  },
});

export const getCargoPalletValidationErrorMessages = createSelector(
  getFreightCargoPalletDetails,
  ({
    totalPalletCount,
  }): Record<CargoPalletValidations, { messageDescriptor: MessageDescriptor; values?: Record<string, unknown> }> =>
    palletValidationErrorMessagesWithValue
);
