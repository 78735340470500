import { Text, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import { usePrepCostConfigs } from "prep/hooks";
import React from "react";
import { FormattedMessage } from "react-intl";
import { PREP_TILE_GROUP_LABELS } from "./PrepTileGroup.labels";
import { SimpleSellerSelfServePrepType } from "prep/types";
import { PrepType } from "@deliverr/prep-service-client";
import { KittingPriceSummaryModal } from "../KittingPriceSummaryModal";

export interface PrepTileContentProps {
  prepType: SimpleSellerSelfServePrepType;
  itemCount: number;
}

const TextContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  p:not(:last-child) {
    padding-bottom: ${theme.spacing.S2};
  }
`
);

const PricingTextContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing.S2};
`
);

const ModalContainer = styled.div`
  z-index: 10;
`;

export const PrepTileContent: React.FC<PrepTileContentProps> = ({ prepType, itemCount }) => {
  const { getPrepTypeCostValues } = usePrepCostConfigs();

  return (
    <TextContainer>
      <Text>
        <FormattedMessage {...PREP_TILE_GROUP_LABELS[prepType].description} />
      </Text>
      <PricingTextContainer>
        <Text bold>
          <FormattedMessage
            {...PREP_TILE_GROUP_LABELS[prepType].pricing}
            values={getPrepTypeCostValues(prepType, itemCount)}
          />
        </Text>
        {prepType === PrepType.KITTING && (
          <ModalContainer>
            <KittingPriceSummaryModal />
          </ModalContainer>
        )}
      </PricingTextContainer>
    </TextContainer>
  );
};
