import history from "BrowserHistory";
import { LocationDescriptorObject } from "history";
import React, { AnchorHTMLAttributes, DetailedHTMLProps, FC, MouseEvent } from "react";

export type HistoryLinkProps = DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
  href: string | LocationDescriptorObject;
};

// a <Link> version that works out of react-router context
// also avoid withRouter invariant context issues
export const HistoryLink: FC<HistoryLinkProps> = (props) => {
  const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (props.onClick) {
      props.onClick(event);
    }

    history.push(props.href);
  };

  return <a {...props} onClick={onClick} href={(props.href as LocationDescriptorObject).pathname ?? props.href} />;
};
