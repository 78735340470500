import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { DefaultTheme, Icon, IconsProps, LocalizedMessage } from "common/components/ui";

import { Currency, DEFAULT_CURRENCY } from "common/components/Currency";
import { TooltipWithIcon } from "common/components/icons/TooltipWithIcon";
import { ChargePerUnitTooltip } from "./ChargePerUnitTooltip";
import { DetailItemSection } from "./DetailItemSection";
import { BigTextProblemDetailItem } from "./ProblemDetailItem";

interface EstimatedChargeSectionProps {
  value: number;
  label?: LocalizedMessage;
}

const PaddedIcon = styled(Icon)<IconsProps, DefaultTheme>(
  ({ theme }) => `
  margin-left: ${theme.spacing.S1};
`
);

export const EstimatedChargeSection: FC<EstimatedChargeSectionProps> = ({ value, label, children }) => (
  <DetailItemSection darken>
    <BigTextProblemDetailItem
      label={
        <>
          {label ?? (
            <>
              <FormattedMessage id="inbounds.estimatedChargeSection.chargePerUnit" defaultMessage="Charge per unit" />
              <TooltipWithIcon message={<ChargePerUnitTooltip />} placement="top">
                <PaddedIcon type="question-circle-alt" size="sm" />
              </TooltipWithIcon>
            </>
          )}
        </>
      }
      value={<Currency value={value} currency={DEFAULT_CURRENCY} />}
      showIcon
    />
    {children}
  </DetailItemSection>
);
