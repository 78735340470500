import React from "react";
import { inboundClient } from "Clients";
import { SPThunkAction } from "common/ReduxUtils";
import { logError, logStart, logSuccess } from "Logger";
import { batch } from "react-redux";
import history from "BrowserHistory";
import { DeliverrError } from "@deliverr/errors";
import { CreateShipmentFormState } from "../../CreateShipmentState";
import { fetchStorageProductDetails } from "./fetchStorageProductDetails";
import { setPrepopulatedFormState } from "../setPrepopulatedFormState";
import { setDtcPrepOption, setIsLoading, setShippingPlanId } from "../simpleUpdateActions";
import {
  getOriginState,
  getSupplierState,
  getDestinationState,
  getP2PDestinationState,
  getCargoDetailsState,
  getWarehouseSelectState,
} from "./stepStates";
import { defineMessages, FormattedMessage } from "react-intl";
import { toast } from "common/components/ui";
import { setStorageInboundCreateDraft } from "storage/inbounds/create/store/actions/setStorageInboundCreateDraft";
import { DynamicRoutes, Path } from "paths";
import { getCheckoutState } from "./stepStates/getCheckoutState";
import { unmappedFields } from "./unmappedFields";
import { setSavedFreightServiceTitle } from "../setSavedFreightServiceTitle";
import { InboundBooking } from "@deliverr/legacy-inbound-client";
import { SmbBookingCreationTimedEvent } from "inbounds/createShipment/common/analytics/analytics.types";
import { inboundDtcPrepToDtcPrepOption } from "inbounds/utils/dtcPrepUtils";

enum LoadDraftError {
  BookingRequestError = "BookingRequestError",
  StorageProductDetailsError = "StorageProductDetailsError",
}

const ERROR_MESSAGES = defineMessages({
  [LoadDraftError.BookingRequestError]: {
    defaultMessage: "There was an error while loading your shipment.",
    id: "createShipment.loadBookingDraft.bookingRequestError",
  },
  [LoadDraftError.StorageProductDetailsError]: {
    defaultMessage: "There was an error while loading your product selections.",
    id: "createShipment.loadBookingDraft.storageProductDetailsError",
  },
});

export const loadBookingDraft =
  (shippingPlanId: number, isSmbAccelerateFbaUnitPrepOn: boolean): SPThunkAction<Promise<InboundBooking | undefined>> =>
  async (dispatch) => {
    const ctx = logStart({ fn: SmbBookingCreationTimedEvent.LoadBooking, shippingPlanId });
    dispatch(setIsLoading(true));

    const handleError = (error: LoadDraftError) => {
      toast.error(<FormattedMessage {...ERROR_MESSAGES[error]} />, {
        autoClose: 5000,
        toastId: "loadBookingDraft.error",
      });

      /** if the booking request failed, route to the inbounds list */
      if (error === LoadDraftError.BookingRequestError) {
        history.replace(Path.inboundsList);
        return;
      }
    };

    try {
      /** fetch the booking */
      const response = await inboundClient.getBooking(shippingPlanId).catch(() => {
        throw new DeliverrError({ code: LoadDraftError.BookingRequestError });
      });

      /** redirect to details if already submitted */
      if (response.submit) {
        history.push(DynamicRoutes.inboundsSmbShipmentDetails.parse({ shippingPlanId }));
        return;
      }

      /** derive IPB Redux state  */
      const formState: CreateShipmentFormState = {
        ...unmappedFields,
        ...getOriginState(response),
        ...getSupplierState(response),
        ...getDestinationState(response),
        ...getWarehouseSelectState(response),
        ...getP2PDestinationState(response),
        ...getCargoDetailsState(response),
        ...getCheckoutState(response, isSmbAccelerateFbaUnitPrepOn),
      };

      /** hydrate products state in storage draft store  */
      const storageProductDetails = await fetchStorageProductDetails(response).catch(() => {
        handleError(LoadDraftError.StorageProductDetailsError);
      });
      const { productDetails, selectedProducts, selectedProductsCasePacks, intlDtcSelectedProductsCasePackDskus } =
        storageProductDetails ?? {};

      if (formState.singleSkuPackages && intlDtcSelectedProductsCasePackDskus) {
        Object.keys(formState.singleSkuPackages).map(
          (productDsku) =>
            (formState.singleSkuPackages[productDsku] = formState.singleSkuPackages[productDsku].map((pkg, index) => {
              return {
                ...pkg,
                casePackDsku: intlDtcSelectedProductsCasePackDskus[productDsku]?.[pkg.items?.[0]?.qty],
              };
            }))
        );
      }
      const { dtcPrepOption } = response;

      batch(() => {
        storageProductDetails &&
          dispatch(setStorageInboundCreateDraft({ productDetails, selectedProducts, selectedProductsCasePacks }));
        dispatch(setPrepopulatedFormState(formState));
        dispatch(setShippingPlanId(shippingPlanId));
        dispatch(setSavedFreightServiceTitle(response.offeringTitle ?? null));
        dtcPrepOption && dispatch(setDtcPrepOption(inboundDtcPrepToDtcPrepOption(dtcPrepOption)));
      });
      logSuccess(ctx, "successfully loaded booking");
      return response;
    } catch (error) {
      logError(ctx, error);
      handleError(error.code);
      return;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
