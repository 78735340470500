import { Box, Input } from "common/components/ui";
import React, { FC } from "react";
import { fbaIntegrationCommonLabels } from "./FbaIntegrationCommon.labels";
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import { Responsive, Space } from "../../common/components/ui/shared";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";

const transfersLabels = transfersShipmentCreateLabels.steps;

export interface ReferenceIdProps {
  onReferenceIdChange: (e) => void;
  currentFbaReferenceId: string | undefined;
  label: MessageDescriptor;
  fbaShipmentId?: string;
  paddingTop?: Responsive<keyof typeof Space | null>;
  width?: string;
  shouldShowFbaRefHint?: boolean;
}

export const FbaIntegrationAddReferenceIdBox: FC<ReferenceIdProps> = ({
  onReferenceIdChange,
  currentFbaReferenceId,
  label,
  fbaShipmentId,
  paddingTop,
  width,
  shouldShowFbaRefHint = false,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Box paddingTop={paddingTop} width={width}>
      <Input
        data-testid="confirmed-amazon-reference-id"
        label={<FormattedMessage {...label} values={{ fbaShipmentId }} />}
        value={currentFbaReferenceId ?? ""}
        placeholder={formatMessage(fbaIntegrationCommonLabels.referenceId.placeholder)}
        onChange={onReferenceIdChange}
        hasError={shouldShowFbaRefHint}
        errorMessage={formatMessage(transfersLabels.shipmentDetails.referenceId.validateAttribute)}
      />
    </Box>
  );
};
