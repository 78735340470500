import { OrderError } from "common/clients/orders/DeliverrOrder/OrderError";
import { OrderStatus } from "common/clients/orders/DeliverrOrder/OrderStatus";
import { OrderListStatusFilter } from "../OrderListReducer";

const STATUSES = Object.values(OrderStatus);

const ERROR_STATUS_STRING = `status:${OrderStatus.ERROR}`;
const CREATED_STATUS_STRING = `status:${OrderStatus.CREATED}`;
const CANCELLED_STATUS_STRING = `status:${OrderStatus.CANCELLED}`;
const SHIPPED_STATUS_STRING = `status:${OrderStatus.SHIPPED}`;

// Instructions on how to combine and construct filters:
// https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/in-depth/combining-boolean-operators/#caveats
const algoliaFilters = {
  [OrderError.BAD_ADDRESS]: `${ERROR_STATUS_STRING} AND error:${OrderError.BAD_ADDRESS}`,
  [OrderError.DSKU_NEVER_INBOUNDED]: `(${ERROR_STATUS_STRING} OR ${CANCELLED_STATUS_STRING}) AND error:${OrderError.DSKU_NEVER_INBOUNDED}`,
  [OrderError.STOCKOUT]: `(${ERROR_STATUS_STRING} OR ${CANCELLED_STATUS_STRING}) AND (error:${OrderError.STOCKOUT} OR error:${OrderError.STOCKOUT_AT_INGESTION})`,
  [OrderStatus.CREATED]: `(${CREATED_STATUS_STRING} OR ${ERROR_STATUS_STRING}) AND (${CREATED_STATUS_STRING} OR error:${OrderError.UNKNOWN})`,
  LATE: `orderDetails.isEstimatedToShipLate=1 OR orderDetails.isEstimatedToDeliverLate=1`,
  IN_TRANSIT: `${SHIPPED_STATUS_STRING}`,
};

export const statusToAlgoliaFilter = (statusFilter?: OrderListStatusFilter): string[] => {
  if (!statusFilter || statusFilter === "ALL_STATUS") {
    return [];
  }

  if (algoliaFilters[statusFilter]) {
    return [algoliaFilters[statusFilter]];
  }

  if (STATUSES.includes(statusFilter as OrderStatus)) {
    return [`status:${statusFilter}`];
  }

  return [];
};
