import React from "react";
import { useController } from "react-hook-form";
import styled from "@emotion/styled";

import { Radio, Stack, defaultTheme } from "common/components/ui";
import { UnitSystem } from "inbounds/createShipment/CreateShipmentTypes";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";

interface UnitSystemSelectorProps {
  value: UnitSystem;
  onChange: (unitSystem: UnitSystem) => void;
}

const UNIT_SYSTEM_OPTIONS = [
  {
    label: "Imperial",
    value: UnitSystem.CFT_LB,
  },
  {
    label: "Metric",
    value: UnitSystem.CBM_KG,
  },
];

const StyledStack = styled(Stack)`
  label {
    margin-bottom: 0;
  }
}`;

const UnconnectedUnitSystemSelector = ({ value, onChange }: UnitSystemSelectorProps) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <StyledStack direction="HORIZONTAL" alignItems="center">
      {UNIT_SYSTEM_OPTIONS.map((option) => (
        <Radio
          key={option.value}
          name={CreateShipmentInputName.UNIT_SYSTEM}
          label={option.label}
          value={option.value}
          onChange={handleChange}
          checked={value === option.value}
          labelStyles={`color: ${defaultTheme.colors.NEUTRAL["500"]}`}
        />
      ))}
    </StyledStack>
  );
};

export const UnitSystemSelector = () => {
  const { field } = useController({ name: CreateShipmentInputName.UNIT_SYSTEM });

  return <UnconnectedUnitSystemSelector value={field.value} onChange={field.onChange} />;
};
