import React, { FC } from "react";

import { Appearance } from "common/components/ui/shared";
import { FormattedMessage } from "react-intl";
import { NotificationItem } from "../NotificationItem/NotificationItem";

export const GetFasterInternationalShipping: FC = () => (
  <NotificationItem
    title={
      <FormattedMessage
        id="notifications.banners.getFasterInternationalShipping.title"
        defaultMessage="Get faster international shipping"
      />
    }
    description={
      <FormattedMessage
        id="notifications.banners.getFasterInternationalShipping.description"
        defaultMessage="Activate Managed Markets to collect duties at checkout and get 2-8 day international shipping."
      />
    }
    iconType="info-circle"
    iconAppearance={Appearance.PRIMARY}
    backgroundAppearance={Appearance.PRIMARY}
    callToActionExternalPath="https://help.shopify.com/en/manual/markets/markets-pro"
    callToActionMessage={
      <FormattedMessage
        id="notifications.banners.getFasterInternationalShipping.learnMore"
        defaultMessage="Learn more"
      />
    }
  />
);
