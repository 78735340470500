import { RootState } from "RootReducer";
import { PrepCreationSource } from "prep/types/PrepCreationSource";
import { selectPlanKitProductDetails } from "inbounds/store/selectors/productDetailsCache/selectPlanKitProductDetails";
import { selectTransferKitProductDetails } from "transfers/create/steps/common/replenishment/prep/selectTransferKitProductDetails";

interface ProductDetails {
  dsku: string;
  name: string;
  totalComponentQuantity: number;
  qty: number;
}

export const fetchProductDetails =
  (source: PrepCreationSource) =>
  (state: RootState): ProductDetails[] => {
    if (source === PrepCreationSource.INBOUNDS) {
      return selectPlanKitProductDetails(state);
    } else if (source === PrepCreationSource.REPLENISHMENT) {
      return selectTransferKitProductDetails(state);
    }
    return [];
  };
