import { FastTagState } from "common/clients/fastTag/FastTagState";
import { FastTagType } from "common/clients/fastTag/FastTagType";
import { activeFastTags } from "channels/ChannelsSelectors";
import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { MultipleShopFastTag } from "./model/multipleShop/MultipleShopFastTagProgram";
import { head } from "lodash/fp";

export const getIsShopifyFastTagsActivated = createSelector<RootState, FastTagType[], boolean>(
  activeFastTags,
  (activatedFastTags) => activatedFastTags.includes(FastTagType.SHOPIFY)
);

export const fastTagFlows = (root: RootState) => root.fastTagFlows;

export const getFastTagsState = (root: RootState) => root.tags;

export const getShopConnectionStatuses = createSelector(getFastTagsState, (fastTags) => fastTags.shopConnectionStatus);

export const getDefaultShopName = createSelector(
  getShopConnectionStatuses,
  (_, program) => program,
  (shopConnectionStatuses, program) =>
    head(
      shopConnectionStatuses[program as MultipleShopFastTag]?.filter((shop) => shop.state === FastTagState.ACTIVATED)
    )?.name
);
