import React, { FC } from "react";
import { FormProvider } from "react-hook-form";
import { Card } from "common/components/Card";
import { InboundStepContainer, InboundStepContent } from "inbounds/steps/InboundStepElements";
import { LotInformationHeader } from "inbounds/steps/lot-information/LotInformationHeader";
import { LotInformationTable } from "inbounds/steps/lot-information/LotInformationTable";
import { InboundFooter } from "inbounds/steps/InboundFooter";
import { useStorageLotInformation } from "./useStorageLotInformation";
import { useStorageLotInformationProducts } from "./useStorageLotInformationProducts";

export const StorageLotInformation: FC = () => {
  const { products, hasFefoEnabledProducts, hasLotTrackingEnabledProducts } = useStorageLotInformationProducts();
  const { onNextClick, onPreviousClick, formHandler } = useStorageLotInformation();
  return (
    <FormProvider {...formHandler}>
      <InboundStepContainer>
        <Card>
          <LotInformationHeader
            hasFefoEnabledProducts={hasFefoEnabledProducts}
            hasLotTrackingEnabledProducts={hasLotTrackingEnabledProducts}
          />
          <InboundStepContent>
            <LotInformationTable
              products={products}
              hasFefoEnabledProducts={hasFefoEnabledProducts}
              hasLotTrackingEnabledProducts={hasLotTrackingEnabledProducts}
            />
          </InboundStepContent>
          <InboundFooter onButtonClick={onNextClick} onPreviousButtonClick={onPreviousClick} manualLoader />
        </Card>
      </InboundStepContainer>
    </FormProvider>
  );
};
