import React, { FC } from "react";
import { NavBarButton } from "../../NavBarButton";
import { useIntl } from "react-intl";
import { GlobalSearchInput } from "./GlobalSearchInput";
import { SearchResults } from "./SearchResults";
import { useGlobalSearch } from "../hooks";
import { Box } from "common/components/ui";

export const GlobalSearch: FC = () => {
  const { formatMessage } = useIntl();
  const {
    shouldShowSearchBar,
    searchTerm,
    searchResults,
    isLoading,
    error,
    handleInputChange,
    openSearchBar,
    closeSearchBar,
  } = useGlobalSearch();

  return (
    <Box>
      {shouldShowSearchBar ? (
        <>
          <GlobalSearchInput searchTerm={searchTerm} onChange={handleInputChange} onClose={closeSearchBar} />
          <SearchResults searchTerm={searchTerm} isLoading={isLoading} error={error} searchResults={searchResults} />
        </>
      ) : (
        <NavBarButton
          iconType="search"
          aria-label={formatMessage({ id: "navbar.globalSearch", defaultMessage: "Open Search Box" })}
          onClick={openSearchBar}
        />
      )}
    </Box>
  );
};
