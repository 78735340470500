import { inboundClient } from "Clients";
import { ShippingPlanRenameParams } from "inbounds/InboundTypes";
import log from "Logger";
import { updatePlan } from "inbounds/store/actions/shippingPlan/updatePlan";

/**
 * Updates the name of the current shipping plan.
 * There are 2 possible scenarios here:
 * - The shipping plan exists, in which case we send the update request to the API as well as updating locally
 * - The shipping plan have not been saved yet, in which case we will only update our local copy
 *     (The only situation where this occurs is when a seller renames a SP in the very first step of creation)
 * @param params
 */
export const renameShippingPlan = (params: ShippingPlanRenameParams) => async (dispatch) => {
  const { name, sellerId, shippingPlanId } = params;

  const ctx = { fn: "renameShippingPlan", name, sellerId };
  log.info(ctx, "rename shipping plan");
  try {
    if (shippingPlanId) {
      await inboundClient.renameShippingPlan(sellerId, shippingPlanId, name);
    }
    dispatch(updatePlan({ name }));
  } catch (err) {
    log.error({ ...ctx, err }, "error renaming shipping plan");
  }
};
