import React, { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ManagedStep } from "common/components/StepContainer";
import { Appearance, BorderIcon, Box, Notification, Stack, Text, ThemeProps } from "common/components/ui";
import { transfersShipmentCreateLabels } from "../../transfersShipmentCreate.labels";
import { useSelector } from "react-redux";
import { selectTransferCreate } from "../../store/TransferCreateSelectors";
import styled from "@emotion/styled";
import { fbaIntegrationCommonLabels } from "../../../../wholesale/common/FbaIntegrationCommon.labels";
import { FbaIntegrationViewShipmentLink } from "../../../../wholesale/common/FbaIntegrationViewShipmentLink";
import { FbaIntegrationAddReferenceIdBox } from "../../../../wholesale/common/FbaIntegrationAddReferenceIdBox";
import { extractFbaShipmentId } from "../../../utils/extractFbaShipmentId";
import { getIsDeliverrFreight } from "../../../../common/wholesale-common/utils/wholesale-utils";
import { WholesaleShipmentDTO } from "@deliverr/wholesale-client";
import { getConcatenatedFbaShipmentIdsFromWholesaleOrder } from "../../../utils/fbaIntegration";

const transferCreationLabels = transfersShipmentCreateLabels.steps.fbaIntegration.confirmed;
const referenceIdLabels = fbaIntegrationCommonLabels.referenceId;

const Space = styled.div<ThemeProps>(
  ({ theme }) => `
  padding-bottom: ${theme.spacing.S3};
  padding-top: ${theme.spacing.S3};
`
);

export const ReferenceIdBox: FC<{
  refIdSetter: (shipmentId: string, referenceId: string) => void;
  currentFbaReferenceId: string | undefined;
  shipment: WholesaleShipmentDTO;
  mapDep: Map<string, string>;
}> = ({ refIdSetter, currentFbaReferenceId, shipment, mapDep }) => {
  const fbaShipmentId = extractFbaShipmentId(shipment);

  const onReferenceIdChange = useCallback(
    (evt) => {
      const value = evt.target.value;
      refIdSetter(shipment.id, value);
    },
    [shipment, mapDep]
  );

  if (!fbaShipmentId) {
    return <></>;
  }
  return (
    <Space>
      <FbaIntegrationAddReferenceIdBox
        onReferenceIdChange={onReferenceIdChange}
        currentFbaReferenceId={currentFbaReferenceId}
        label={fbaIntegrationCommonLabels.referenceId.labelWithShipmentId}
        fbaShipmentId={fbaShipmentId}
      />
      <FbaIntegrationViewShipmentLink fbaShipmentId={fbaShipmentId} hideIcon={true} />
    </Space>
  );
};

export const FbaIntegrationConfirmed = () => {
  const { formatMessage } = useIntl();
  const { wholesaleOrder } = useSelector(selectTransferCreate);

  const [refIdMap, setRefIdMap] = useState(new Map<string, string>());

  function setRefIdForShipment(shipmentId, referenceId) {
    setRefIdMap(new Map([...refIdMap, [shipmentId, referenceId]]));
  }

  function getCurrRefIdForShipment(shipmentId): string | undefined {
    return refIdMap.get(shipmentId);
  }

  const isDeliverrFreight = getIsDeliverrFreight(wholesaleOrder);
  const numShipments = wholesaleOrder?.shipments?.length ?? 0;

  const singleShipment = numShipments > 0 ? wholesaleOrder?.shipments?.[0] : undefined;
  const singleFbaShipmentId = singleShipment ? extractFbaShipmentId(singleShipment) : undefined ?? "";

  const subtitle =
    numShipments > 1 ? (
      <FormattedMessage
        {...transferCreationLabels.subtitleMultiShipment}
        values={{
          shipmentIdList: getConcatenatedFbaShipmentIdsFromWholesaleOrder(wholesaleOrder) ?? "",
        }}
      />
    ) : (
      <FormattedMessage
        {...transferCreationLabels.subtitleSingleShipment}
        values={{
          fbaShipmentId: singleFbaShipmentId,
        }}
      />
    );

  return (
    <ManagedStep
      data-testid="fba-integration-confirmed-step"
      title={formatMessage({ ...transferCreationLabels.title })}
      subtitle={subtitle}
      header={<BorderIcon appearance={Appearance.SUCCESS} iconType="check" size="XXL" />}
      isDone
      nextCaption={formatMessage(transferCreationLabels.confirmButtonCaption)}
      nextData={async () => {
        return { isDeliverrFreight, fbaReferenceIdMap: refIdMap };
      }}
    >
      {isDeliverrFreight && (
        <Box maxWidth="60%" marginBottom="S4" padding={"S4"}>
          <Space>
            <Notification
              appearance="WARNING"
              showIcon={true}
              content={{
                title: <FormattedMessage {...referenceIdLabels.warningTitle} />,
                description: (
                  <div>
                    <FormattedMessage {...referenceIdLabels.warningSubtitle} />
                  </div>
                ),
              }}
            />
          </Space>
          <Stack center>
            <Stack justifyContent="flex-start" direction="VERTICAL">
              <>
                {wholesaleOrder?.shipments?.map((shipment) => (
                  <ReferenceIdBox
                    key={`${shipment.id}`}
                    shipment={shipment}
                    currentFbaReferenceId={getCurrRefIdForShipment(shipment.id)}
                    refIdSetter={setRefIdForShipment}
                    mapDep={refIdMap}
                  />
                ))}
              </>
              <Text size="caption" appearance="INFO">
                {formatMessage(fbaIntegrationCommonLabels.referenceId.comment)}
              </Text>
            </Stack>
          </Stack>
        </Box>
      )}
    </ManagedStep>
  );
};
