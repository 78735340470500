import { useSelector } from "react-redux";
import { uniqBy } from "lodash/fp";
import { selectStorageProductsWithFefoEnabled } from "../store/selector/selectStorageProductsWithFefoEnabled";
import { selectStorageProductsWithLotTrackingEnabled } from "../store/selector/selectStorageProductsWithLotTrackingEnabled";

export const useStorageLotInformationProducts = () => {
  const storageProductsWithFefoEnabled = useSelector(selectStorageProductsWithFefoEnabled);
  const storageProductsWithLotTrackingEnabled = useSelector(selectStorageProductsWithLotTrackingEnabled);
  const products = uniqBy(
    ({ dsku }) => dsku,
    [...storageProductsWithFefoEnabled, ...storageProductsWithLotTrackingEnabled]
  );
  const hasFefoEnabledProducts = !!storageProductsWithFefoEnabled.length;
  const hasLotTrackingEnabledProducts = !!storageProductsWithLotTrackingEnabled.length;

  return {
    products,
    hasFefoEnabledProducts,
    hasLotTrackingEnabledProducts,
  };
};
