import { useModal } from "common/hooks/useModal";
import { useHistory } from "react-router";

export const createMoreAdSetsModalId = "create-more-ad-sets-modal";

export const useCreateMoreAdSetsModal = (nextPath: string) => {
  const { showModal, hideModal } = useModal(createMoreAdSetsModalId);
  const history = useHistory();

  const onDoneClick = () => {
    hideModal();
    history.push(nextPath);
  };

  const onContinueSetupClick = () => {
    hideModal();
  };

  return {
    showModal,
    hideModal,
    onDoneClick,
    onContinueSetupClick,
    modalId: createMoreAdSetsModalId,
  };
};
