import { ShipmentStatusGrouping, SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT } from "inbounds/ShipmentStatus";
import { MessageDescriptor } from "react-intl";
import { createSelector } from "reselect";
import { RootState } from "RootReducer";

export const getShippingPlanType = (state: RootState) => state.inboundList.searchFilters.shippingPlanType;
export const getDiscrepancyType = (state: RootState) => state.inboundList.searchFilters.discrepancyStatus;

export const getShipmentStatuses = createSelector<
  RootState,
  ShipmentStatusGrouping[],
  { value: ShipmentStatusGrouping; label: MessageDescriptor }[]
>(
  (state) => state.inboundList.searchFilters.shipmentStatuses,
  (shipmentStatuses) =>
    shipmentStatuses.map((status) => ({
      value: status,
      label: SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT[status],
    }))
);

export const selectActionNeededShipments = createSelector<RootState, Set<number> | undefined, number[]>(
  (state) => state.inboundList.actionNeededShipments,
  (shipments) => (shipments == null ? [] : Array.from(shipments))
);

export const selectTotalSearchHits = createSelector<RootState, number | undefined, number>(
  (state) => state.inboundList.searchInfo?.totalHits,
  (hits) => hits ?? 0
);

export const getResultsPerPage = (state: RootState) => state.user.resultsPerPage.inboundList;
export const getSearchFilters = (state: RootState) => state.inboundList.searchFilters;
export const getFiltersBladeOpen = (state: RootState) => state.inboundList.filtersBladeOpen;
