import { isNil } from "lodash";
import { useParams } from "react-router-dom";
import RouteParser from "common/RouteParser";
import { nonComplianceInternalRoutes } from "inbounds/non-compliance/NonComplianceRoutes";
import { Path } from "paths";
import { UnexpectedSkuNonComplianceRow } from "../../types";
import { usePreventScrollToTopNavigation } from "common/hooks/usePreventScrollToTopNavigation";
import { useGetAllRelatedProductsAndCasePacks } from "storage/hooks/useGetAllRelatedProductsAndCasePacks";

export const useMismatchCaseQtyNonComplianceBlade = (nonComplianceCase: UnexpectedSkuNonComplianceRow) => {
  const navigate = usePreventScrollToTopNavigation();
  const { nonCompliantId } = useParams<{ nonComplianceType?: string; nonCompliantId?: string }>();

  const { shippingPlanId, shippingPlanName, sellerAsnLabel, barcode } = nonComplianceCase;

  const { value: product } = useGetAllRelatedProductsAndCasePacks({ dsku: barcode, asnId: sellerAsnLabel });

  const onShippingPlanClick = () => {
    window.open(new RouteParser(Path.inboundsStorageDetail).parse({ shippingPlanId }), "_blank");
  };

  const resetBladeState = () => {
    if (!isNil(nonCompliantId) && nonCompliantId.length > 0) {
      navigate(nonComplianceInternalRoutes.summary);
    }
  };

  return {
    onShippingPlanClick,
    resetBladeState,
    shippingPlanId,
    shippingPlanName,
    product,
  };
};
