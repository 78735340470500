import React from "react";

import { FormattedMessage } from "react-intl";

export const SaveNewInboundError = (
  <FormattedMessage
    id="inbounds.errors.saveNewInboundError"
    defaultMessage="There was an error creating your inbound. Please try again later."
  />
);
