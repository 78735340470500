import { ProductCasePackData } from "@deliverr/commons-clients/lib/product/ProductCasePack";
import React, { FC } from "react";
import Modal from "common/components/Modal";
import { NewProductCasePackForm } from "../NewProductCasePackForm";
import { NewProductCasePackFormValues } from "../NewProductCasePackForm/useNewProductCasePackForm";
import styled from "@emotion/styled";
import { ThemeProps } from "common/components/ui";
import { ProductDisplayData } from "../ProductCasePackSelect/ProductCasePackSelect.types";

const StyledModal = styled(Modal)<ThemeProps>(
  ({ theme }) => `
  .modal-dialog {
    width: 480px;
  }
  .modal-content {
    display: flex;
    justify-content: center;
    padding: ${theme.spacing.S9} ${theme.spacing.S7} ${theme.spacing.S7} ${theme.spacing.S7};
  }
`
);

export const getNewCasePackModalIdForSku = (dsku: string) => `new_pack_modal_${dsku}`;

interface NewProductCasePackModalProps {
  product: ProductDisplayData;
  isIntlDtc?: boolean;
  onClose?: () => void;
  defaultFormValues?: NewProductCasePackFormValues;
  onNewCasePackFormSubmit?: (productCasePackData: ProductCasePackData) => void;
}

export const NewProductCasePackModal: FC<NewProductCasePackModalProps> = ({
  product,
  isIntlDtc,
  onClose,
  defaultFormValues,
  onNewCasePackFormSubmit,
}) => (
  <StyledModal id={getNewCasePackModalIdForSku(product.dsku)} size="md" hasCloseButton={true} onClose={onClose}>
    <NewProductCasePackForm
      isIntlDtc={isIntlDtc}
      product={product}
      defaultFormValues={defaultFormValues}
      onCancel={onClose}
      onNewCasePackFormSubmit={onNewCasePackFormSubmit}
    />
  </StyledModal>
);
