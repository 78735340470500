import { FormattedMessage, useIntl } from "react-intl";
import { Box, Text } from "common/components/ui";
import { transfersShipmentCreateLabels } from "../../../transfersShipmentCreate.labels";
import { WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";
import React, { FC } from "react";
import { FlexRadioTileGroup } from "./FlexRadioTileGroup";

interface AmazonShippingMethodProps {
  value?: WholesaleShippingMethod;
  onChange: (value: WholesaleShippingMethod) => void;
}

export const AmazonShippingMethod: FC<AmazonShippingMethodProps> = ({ value, onChange }) => {
  const { formatMessage } = useIntl();

  return (
    <Box width="80%" paddingBottom="S6" data-testid="shipping-method-selector">
      <Box paddingBottom="S5">
        <Text bold>
          <FormattedMessage {...transfersShipmentCreateLabels.steps.shipping.selectCarrier} />
        </Text>
      </Box>
      <FlexRadioTileGroup
        direction="HORIZONTAL"
        options={[
          {
            value: WholesaleShippingMethod.DELIVERR,
            label: formatMessage(transfersShipmentCreateLabels.steps.shipping.options.deliverr.label),
            content: (
              <FormattedMessage
                {...transfersShipmentCreateLabels.steps.shipping.options.deliverr.fbaIntegratedDescription}
              />
            ),
          },
          {
            value: WholesaleShippingMethod.SELLER,
            label: formatMessage(transfersShipmentCreateLabels.steps.shipping.options.amazon.label),
            content: (
              <>
                {formatMessage(transfersShipmentCreateLabels.steps.shipping.options.amazon.description, {
                  learnMore: <ElevioLearnMoreButton articleId={ElevioArticleId.TransferCreateShippingYourCarrier} />,
                })}
              </>
            ),
          },
        ]}
        currentValue={value}
        onChange={(evt) => {
          onChange(evt.currentTarget.value as WholesaleShippingMethod);
        }}
      />
    </Box>
  );
};
