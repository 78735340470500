import React from "react";
import { Box, Text, Input, BoxReset, Radio } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import { useStorageFloorLoadedContainerInput } from "./useStorageFloorLoadedContainerInput";

export const StorageFloorLoadedContainerInput: React.ComponentType = () => {
  const {
    containerId,
    containerSize,
    containerSizeOptions,
    onContainerIdUpdate,
    onContainerSizeSelect,
  } = useStorageFloorLoadedContainerInput();
  return (
    <Box marginTop="S7">
      <Text bold>
        <FormattedMessage
          id="storage.inbounds.cargoType.floorLoadedContainerSize"
          defaultMessage="Select floor load size"
        />
      </Text>
      <Box marginTop="S4">
        {containerSizeOptions.map(({ label, value }) => (
          <Radio
            key={value}
            label={label}
            value={value}
            checked={containerSize === value}
            onChange={onContainerSizeSelect}
          />
        ))}
      </Box>
      <BoxReset marginTop="S7">
        <Text bold>
          <FormattedMessage
            id="storage.inbounds.cargoType.floorLoadedContainerId"
            defaultMessage="Add container ID (optional)"
          />
        </Text>
      </BoxReset>
      <BoxReset width="25rem" marginTop="S4">
        <Input name="containerId" value={containerId} onChange={onContainerIdUpdate} />
      </BoxReset>
      <BoxReset width="25rem" marginTop="S2">
        <Text appearance="INFO" size="caption">
          <FormattedMessage
            id="storage.inbounds.cargoType.floorLoadedContainerDescription"
            defaultMessage="Providing the container ID helps ensure your shipment is received on time. You can add this later."
          />
        </Text>
      </BoxReset>
    </Box>
  );
};
