import { SearchOrdersProps, searchOrders } from "./searchOrders";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";

import { OrderListActionTypes } from "../OrderListActionTypes";
import { RootState } from "RootReducer";
import { Thunk } from "common/ReduxUtils";
import { searchImportErrors } from "./searchImportErrors";
import { setSummaryFilter } from "./setSummaryFilter";

export const searchOrdersAndImportErrors: Thunk =
  ({
    page,
    isOrdersLogisticsSearchOn,
    isOrderIngestErrorsLogisticsSearchOn,
    useOrdersLogisticsSearchV2,
  }: SearchOrdersProps = {}) =>
  async (dispatch, getState: () => RootState) => {
    const {
      orderListV2: {
        filters: { summary },
      },
    } = getState();
    dispatch(addLoader(OrderListActionTypes.LOAD_ORDER_LIST));
    await Promise.all([
      dispatch(searchOrders({ page, isOrdersLogisticsSearchOn, useOrdersLogisticsSearchV2 })),
      dispatch(searchImportErrors({ page, isOrderIngestErrorsLogisticsSearchOn })),
    ]);

    const {
      orderListV2: {
        searchInfo: { totalHits: ordersCount } = {},
        importErrors: { searchInfo: { totalHits: importErrorsCount } = {} },
      },
    } = getState();

    if (ordersCount === 0 && importErrorsCount && importErrorsCount > 0 && summary === "ALL_SUMMARY") {
      dispatch(setSummaryFilter("IMPORT_ERRORS"));
    }

    dispatch(clearLoader(OrderListActionTypes.LOAD_ORDER_LIST));
  };
