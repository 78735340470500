import React, { FC } from "react";
import { SelectComponentOption, ThemeProps } from "common/components/ui";
import { OptionProps } from "@deliverr/react-select-v1";
import styled from "@emotion/styled";
import { css } from "emotion";
import cx from "classnames";
import { formatAddress } from "../Address";

const OptionContent = styled.div<{ isDisabled: boolean } & ThemeProps>(
  ({ theme, isDisabled }) => `
    display: flex;
    flex-direction: column;
    ${isDisabled ? `color: ${theme.colors.NEUTRAL[200]};` : ""}
  `
);

const notAllowedCursor = css("cursor: not-allowed !important;");

const AddressLine = styled.div<ThemeProps>(
  ({ theme }) => `
  font-size: ${theme.font.size.F2};
  line-height: ${theme.spacing.S4};
`
);

export const AddressSearchOption: FC<OptionProps> = (props) => {
  const { isDisabled, data } = props;

  const addressString = formatAddress({ address: data, hideCompanyPrefix: false, hidePhone: false });

  return (
    <SelectComponentOption {...props} className={cx({ [notAllowedCursor]: isDisabled })}>
      <OptionContent isDisabled={isDisabled}>
        <AddressLine>{addressString}</AddressLine>
      </OptionContent>
    </SelectComponentOption>
  );
};
