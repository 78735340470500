import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";

export enum LogoColor {
  dark = "dark",
  light = "light",
}

const logoFilenames: {
  [colorType: string]: { [logoType: string]: string };
} = Object.keys(LogoColor).reduce(
  (fileNames, color) => ({
    ...fileNames,
    [color]: {
      full: `logo-${color}.png`,
      logoOnly: `mark-${color}.png`,
    },
  }),
  {}
);

interface OwnProps {
  color: LogoColor;
  fullLogo: boolean;
  classNames?: string;
  height?: string;
}

const LogoWithoutIntl = ({ color, fullLogo, classNames, height, intl }: OwnProps & WrappedComponentProps) => {
  const logoFilename = logoFilenames[color][fullLogo ? "full" : "logoOnly"];

  return (
    <img
      style={{ height }}
      className={classNames}
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      src={require(`assets/img/logos/${logoFilename}`).default}
      alt={intl.formatMessage({
        id: "common.flexportLogoAlt",
        defaultMessage: "Flexport logo",
      })}
    />
  );
};

export const Logo = injectIntl(LogoWithoutIntl);
