// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoTooltip__tooltip-content--C71gN {\n  bottom: 110%;\n}\n.InfoTooltip__tooltip-wrapper--JF3Ku {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/app/dashboard/common/InfoTooltip/InfoTooltip.less"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAEA;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;AAAF","sourcesContent":[".tooltip-content {\n  bottom: 110%;\n}\n\n.tooltip-wrapper {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip-content": "InfoTooltip__tooltip-content--C71gN",
	"tooltip-wrapper": "InfoTooltip__tooltip-wrapper--JF3Ku"
};
export default ___CSS_LOADER_EXPORT___;
