import { CustomsActionTypes } from "./CustomsActions";
import { ProductsWithCustoms } from "InternationalShipping/InternationalShippingTypes";
import { AnyAction } from "redux";
import { Dictionary } from "lodash";
import { applyToAll, deserializeProductRes } from "InternationalShipping/InternationalShippingHelpers";
import { InboundState } from "inbounds/InboundReducer";
import { ReducerState } from "common/ReduxUtils";

export const customsInitialState: ProductsWithCustoms = {};

export const customsReducers: Dictionary<ReducerState<InboundState, AnyAction>> = {
  [CustomsActionTypes.GET_PRODUCT_CUSTOMS]: (state, { products }) => ({
    ...state,
    customs: deserializeProductRes(products),
  }),
  [CustomsActionTypes.UPDATE_PRODUCT_CUSTOMS]: (state, { customsInformation }) => ({
    ...state,
    customs: {
      ...state.customs,
      [customsInformation.dsku]: {
        ...state.customs![customsInformation.dsku],
        customsInformation: {
          ...state.customs![customsInformation.dsku].customsInformation,
          ...customsInformation,
        },
      },
    },
  }),
  [CustomsActionTypes.APPLY_TO_ALL]: (state, { prop, value }) => ({
    ...state,
    customs: applyToAll(prop, value)(state.customs),
  }),
  [CustomsActionTypes.SAVE_PRODUCT_CUSTOMS]: (state, { dsku }) => ({
    ...state,
    customs: {
      ...state.customs,
      [dsku]: {
        ...state.customs![dsku],
        saved: true,
      },
    },
  }),
};
