import { Appearance, Direction } from "common/components/ui/shared";
/* eslint-disable @typescript-eslint/promise-function-async */
import { BorderIcon, Stack, Text, ThemeProps } from "common/components/ui";
import React, { FC } from "react";

import { BorderlessButton } from "common/components/BorderlessButton";
import { FormattedMessage } from "react-intl";
import { LightText } from "returns/create/step/ReturnsText";
import { ReturnAddress } from "@deliverr/returns-client";
import { addressOption } from "returns/create/step/AddressOption";
import styled from "@emotion/styled";
import { useCopyAddressContainer } from "./useCopyAddressContainer";

interface CopyAddressContainerProps {
  address?: ReturnAddress;
}

const CenteredHorizontalStack = styled(Stack)<ThemeProps>(
  ({ theme }) => `
    justify-content: inherit;
    box-shadow: inset 0 -1px 0 0 ${theme.colors.NEUTRAL[60]};
    padding:${theme.spacing.S4} 0 ${theme.spacing.S4} 0;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    `
);

const AddressStack = styled(Stack)(`
  width: 62%;
`);

const SquareIcon = styled(BorderIcon)<ThemeProps>(
  ({ theme }) => `
  border-radius: 0;
  color: ${theme.colors.NEUTRAL[200]};
  background: ${theme.colors.NEUTRAL[60]};
`
);

export const CopyAddressContainer: FC<CopyAddressContainerProps> = ({ address }) => {
  const { onCopyClick } = useCopyAddressContainer();
  return (
    <CenteredHorizontalStack direction={Direction.HORIZONTAL} gap="S3" data-testid="returns-policy-address-item">
      <SquareIcon appearance={Appearance.INFO} iconType="location" />
      {address && (
        <AddressStack gap={null}>
          <Text bold>{address?.name}</Text>
          <LightText>{addressOption(address)}</LightText>
        </AddressStack>
      )}

      <BorderlessButton onClick={() => onCopyClick(address)}>
        <Text appearance="PRIMARY" bold>
          <FormattedMessage id={"returns.returnPolicy.qcAddressModal.copyAddress"} defaultMessage={"Copy address"} />
        </Text>
      </BorderlessButton>
    </CenteredHorizontalStack>
  );
};
