import React from "react";
import { Stack, Notification } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { LinkButton } from "common/components/LinkButton";
import { OriginType, ShipmentSteps } from "inbounds/createShipment/CreateShipmentTypes";
import { setOriginType } from "inbounds/createShipment/store/actions";
import { useSPDispatch } from "common/ReduxUtils";
import { goToCreateShipmentStep } from "inbounds/createShipment/routing/goToCreateShipmentStep";

const StyledStack = styled(Stack)`
  margin: inherit;
  width: inherit;
`;

const InternationalOriginLinkButton = () => {
  const dispatch = useSPDispatch();
  return (
    <LinkButton
      onClick={async () => {
        dispatch(setOriginType(OriginType.INTERNATIONAL));
        dispatch(
          goToCreateShipmentStep({
            step: ShipmentSteps.OriginSearch,
          })
        );
      }}
    >
      <FormattedMessage
        id="storage.cargo.freight.internationalAddress.link"
        defaultMessage="go back and select 'International origin'"
      />
    </LinkButton>
  );
};

export const StorageFreightInternationalAddressNotification: React.ComponentType = () => {
  return (
    <Notification
      appearance="PRIMARY"
      content={{
        title: (
          <FormattedMessage
            defaultMessage="You can now book Flexport freight for your international inbound shipments!"
            id="storage.cargo.freight.internationalAddress.title"
          />
        ),
        description: (
          <StyledStack gap={"S3"} direction="HORIZONTAL">
            <FormattedMessage
              defaultMessage="To use Flexport's international freight service, {link}."
              id="storage.cargo.freight.internationalAddress.content"
              values={{
                link: <InternationalOriginLinkButton />,
              }}
            />
          </StyledStack>
        ),
      }}
    />
  );
};
