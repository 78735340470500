import { last } from "lodash/fp";
import { ParcelEvent } from "parcel/screens/details/events/types";
import { ParcelDetailState } from "parcel/screens/details/ParcelDetailReducer";
import { convertParcelTrackingEventToParcelEvent } from "./convertParcelTrackingEventToParcelEvent";
import { ParcelTrackingEventTypes } from "@deliverr/parcel-client";

export const deserializeParcelEvents = (parcel: ParcelDetailState) => {
  const events: ParcelEvent[] = [];
  const { labelTime, sortationArrivalTime, facilityTime, deliveryTime, attemptedDeliveryTime } = parcel;

  // fn to create the event context
  const createEventContext = () => ({
    parcel,
    events,
    currentEvent: last(events),
  });

  if (labelTime instanceof Date) {
    events.push({
      type: ParcelTrackingEventTypes.Created,
      time: labelTime,
    });
  }
  if (sortationArrivalTime) {
    events.push({
      type: ParcelTrackingEventTypes.ArrivedAtSortCenter,
      time: sortationArrivalTime,
    });
  }
  if (facilityTime) {
    events.push({
      type: ParcelTrackingEventTypes.InTransitByCarrier,
      time: facilityTime,
    });
  }
  if (attemptedDeliveryTime) {
    events.push({
      type: ParcelTrackingEventTypes.DeliveryFailed,
      time: attemptedDeliveryTime,
    });
  }
  if (deliveryTime) {
    events.push({
      type: ParcelTrackingEventTypes.Delivered,
      time: deliveryTime,
    });
  }
  return createEventContext();
};

export const deserializeParcelTrackingEvents = (parcel: ParcelDetailState) => {
  const { parcelTrackingData } = parcel;
  if (!parcelTrackingData) {
    return undefined;
  }
  const events: ParcelEvent[] = convertParcelTrackingEventToParcelEvent(parcelTrackingData.trackingHistory);
  return {
    parcel,
    events,
    currentEvent: last(events),
  };
};
