import React from "react";
import { ControlledNumberInput } from "common/components/reactHookForm";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { defineMessages, FormattedMessage } from "react-intl";
import { InfoIcon } from "common/components/icons";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";

export const PALLETS_INPUT_NAME = CreateShipmentInputName.TOTAL_PALLETS;

export const PALLETS_INPUT_MESSAGES = defineMessages({
  label: {
    id: "createShipment.shipmentTotals.pallets.label",
    defaultMessage: "Pallets {tooltip}",
  },
  tooltip: {
    id: "createShipment.shipmentTotals.pallets.tooltip",
    defaultMessage:
      "Loose cargo will be palletized on arrival to a Flexport facility. Pallet count is used only to estimate Flexport fulfillment and storage rates. {learnMore}",
    values: {
      learnMore: <ElevioLearnMoreButton articleId={ElevioArticleId.Pallets} asLink />,
    },
  },
});

const Tooltip = () => (
  <InfoIcon
    messageWidth={200}
    wrap
    placement={"top"}
    message={<FormattedMessage {...PALLETS_INPUT_MESSAGES.tooltip} />}
  />
);

export const PalletsInputLabel = () => (
  <FormattedMessage
    {...PALLETS_INPUT_MESSAGES.label}
    values={{
      tooltip: <Tooltip />,
    }}
  />
);

export const PalletsInput = () => (
  <ControlledNumberInput
    name={PALLETS_INPUT_NAME}
    label={<PalletsInputLabel />}
    decimalScale={0}
    inputSize="medium"
    required
  />
);
