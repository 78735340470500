import { ReducerDictionary, createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { EntityError, OperationStatus, PlacementOptionDto } from "@deliverr/replenishment-client";
import { PlacementOptionsActionTypes } from "./PlacementOptionsActionTypes";
import { map } from "lodash";

export interface PlacementOptionsState {
  placementOptions: PlacementOptionDto[];
  placementOptionsFetchOperationStatus: OperationStatus;
  placementOptionsFetchOperationId?: number;
  placementOptionsErrors: EntityError[];
  selectedPlacementOptionId?: number;
}

export const placementOptionsInitialState: PlacementOptionsState = {
  placementOptions: [],
  placementOptionsFetchOperationStatus: OperationStatus.CREATED,
  placementOptionsFetchOperationId: undefined,
  placementOptionsErrors: [],
  selectedPlacementOptionId: undefined,
};

const reducers: ReducerDictionary<PlacementOptionsState> = {
  ...handleSimpleReducerUpdates([PlacementOptionsActionTypes.PLACEMENT_OPTIONS_SET_SELECTED_PLACEMENT_OPTION_ID]),
  [PlacementOptionsActionTypes.PLACEMENT_OPTIONS_FETCH_SUCCESS]: (state, action) => ({
    selectedPlacementOptionId: state.selectedPlacementOptionId,
    placementOptions: action.placementOptions,
    placementOptionsFetchOperationStatus: action.operationStatus,
    placementOptionsFetchOperationId: action.operationId,
    placementOptionsErrors: [],
  }),
  [PlacementOptionsActionTypes.PLACEMENT_OPTIONS_FETCH_ERROR]: (_state, action) => ({
    placementOptions: [],
    placementOptionsFetchOperationStatus: action.operationStatus,
    placementOptionsFetchOperationId: action.operationId,
    placementOptionsErrors: action.errors,
    selectedPlacementOptionId: undefined,
  }),
  [PlacementOptionsActionTypes.PLACEMENT_OPTIONS_RESET_STATE]: () => placementOptionsInitialState,
  [PlacementOptionsActionTypes.PLACEMENT_OPTIONS_RESET_SINGLE_PLACEMENT_OPTION]: (state, action) => ({
    ...state,
    placementOptions: map(state.placementOptions, (placementOption) => {
      return placementOption.id === action.placementOptionId
        ? {
            ...placementOption,
            shipmentList: {
              operationSummary: {
                ...placementOption.shipmentList.operationSummary,
                operationStatus: OperationStatus.CREATED,
              },
              temporaryShipments: map(placementOption.shipmentList.temporaryShipments, (shipment) => ({
                id: shipment.id,
                temporaryShipmentId: shipment.temporaryShipmentId,
                createdAt: shipment.createdAt,
                updatedAt: shipment.updatedAt,
                placementOptionId: shipment.placementOptionId,
              })),
            },
          }
        : placementOption;
    }),
  }),
};

export const placementOptionsReducer = createReducer<PlacementOptionsState>(placementOptionsInitialState, reducers);
