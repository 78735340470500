import { DeliverrAddress } from "@deliverr/commons-objects";
import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { StorageLabelRequest } from "./StorageLabelRequest/StorageLabelRequest";
import { WarehousePublic } from "@deliverr/business-types";
import { EstimatePalletRequest } from "./EstimatePalletRequest/EstimatePalletRequest";
import { StorageLabelResponse } from "./StorageLabelResponse";
import { StorageResponseWrapper } from "./StorageResponseWrapper";
import { DskuQty } from "../nonCompliance/BoxLabelIncidentDsku/DskuQty";
import { StorageWarehouseInventory } from "./StorageWarehouseInventory";
import { StorageTransferOrderRequest } from "./StorageTransferOrderRequest/StorageTransferOrderRequest";
import { StorageTransferOrderResponse } from "./StorageTransferOrderResponse/StorageTransferOrderResponse";
import { StorageProductData } from "./StorageProduct/StorageProductData";
import { StorageTransferOrderSubmitRequest } from "./StorageTransferOrderSubmitRequest/StorageTransferOrderSubmitRequest";
import { StorageInboundSubmitRequest } from "./StorageInboundRequest/StorageInboundSubmitRequest";
import { StorageSellerConfig } from "./StorageJit/StorageSellerConfig";
import { JITReplenishmentStrategy } from "./StorageJit/JITReplenishmentStrategy";
import { FtlQuoteRequest, LtlQuoteRequest, QuoteResponse } from "@deliverr/freight-client";
import {
  StorageInboundBulkUploadRequest,
  StorageInboundBulkUploadResponse,
} from "./BulkUpload/StorageInboundBulkUploadModels";
import { StorageWarehouseSellerView } from "storage/inbounds/create/selectDestinationV2/StorageWarehouseSellerView";
import { StorageOrderBasedOnBookingTypeResponse } from "./StorageOrderBasedOnBookingType/StorageOrderBasedOnBookingTypeResponse";
import { StorageOrderBasedOnBookingTypeRequest } from "./StorageOrderBasedOnBookingType/StorageOrderBasedOnBookingTypeRequest";
import {
  RateCard,
  StorageInboundRequest,
  StorageInboundResponse,
  StorageToEcomRateCard,
  StorageToEcomRateRequest,
} from "@deliverr/storage-client";

export type StorageClientConfig = ApiClientConfig;

const defaultHeaders = { "X-Request-Caller": "seller-portal" };

export class StorageClient {
  private apiClient: ApiClient;

  constructor(
    { baseURL, headers }: StorageClientConfig = {
      baseURL: mustBeDefined(process.env.STORAGE_SERVICE_URL),
      headers: defaultHeaders,
    }
  ) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`,
      headers,
    });
  }

  async archiveStorageInbound(sellerId: string, shippingPlanId: number): Promise<void> {
    await this.apiClient.delete({
      url: `/storage/inbounds?sellerId=${sellerId}&shippingPlanId=${shippingPlanId}`,
      authentication: isAuthenticated,
    });
  }

  async estimatePalletCount(estimatePalletRequest: EstimatePalletRequest): Promise<StorageResponseWrapper<number>> {
    return await this.apiClient.post({
      url: `/storage/estimate/pallet-count`,
      body: estimatePalletRequest,
      authentication: isAuthenticated,
    });
  }

  async createStorageInbound(
    storageInboundRequest: Partial<StorageInboundRequest>
  ): Promise<StorageResponseWrapper<Partial<StorageInboundResponse>>> {
    return await this.apiClient.post({
      url: `/storage/inbounds`,
      body: storageInboundRequest,
      authentication: isAuthenticated,
    });
  }

  async submitStorageInbound(
    storageInboundSubmitRequest: StorageInboundSubmitRequest
  ): Promise<StorageResponseWrapper<StorageInboundResponse>> {
    return await this.apiClient.post({
      url: `/storage/inbounds/submit`,
      body: storageInboundSubmitRequest,
      authentication: isAuthenticated,
    });
  }

  async updateStorageInbound(
    storageInboundOrderId: number,
    storageInboundRequest: Partial<StorageInboundRequest>
  ): Promise<StorageResponseWrapper<Partial<StorageInboundResponse>>> {
    return await this.apiClient.put({
      url: `/storage/inbounds/${storageInboundOrderId}`,
      body: storageInboundRequest,
      authentication: isAuthenticated,
    });
  }

  async getNearestStorageWarehouse(
    address: DeliverrAddress
  ): Promise<StorageResponseWrapper<WarehousePublic> | undefined> {
    return await this.apiClient.post({
      url: `/storage/warehouses/nearest`,
      body: address,
      authentication: isAuthenticated,
    });
  }

  async getStorageWarehouses(): Promise<StorageResponseWrapper<WarehousePublic[]> | undefined> {
    return await this.apiClient.get({
      url: `/storage/warehouses`,
      authentication: isAuthenticated,
    });
  }

  async getStorageWarehousesForInbounds(
    sellerId: string
  ): Promise<StorageResponseWrapper<StorageWarehouseSellerView[]> | undefined> {
    return await this.apiClient.get({
      url: `/storage/warehouses/inbounds/${sellerId}`,
      authentication: isAuthenticated,
    });
  }

  async getRankedStorageWarehousesForInbounds(
    sellerId: string,
    address: DeliverrAddress
  ): Promise<StorageResponseWrapper<StorageWarehouseSellerView[]> | undefined> {
    return await this.apiClient.post({
      url: `/storage/warehouses/inbounds/${sellerId}/withNearestWarehouse`,
      body: address,
      authentication: isAuthenticated,
    });
  }

  async patchStorageInbound(
    sellerId: string,
    storageInboundOrderId: number,
    storageInboundOrderRequest: Partial<StorageInboundRequest>
  ): Promise<void> {
    return await this.apiClient.patch({
      url: `/storage/inbounds?sellerId=${sellerId}&storageInboundOrderId=${storageInboundOrderId}`,
      body: storageInboundOrderRequest,
      authentication: isAuthenticated,
    });
  }

  async generateLabels(
    generateLabelRequest: StorageLabelRequest
  ): Promise<StorageResponseWrapper<StorageLabelResponse>> {
    return await this.apiClient.post({
      url: `/storage/labels`,
      body: generateLabelRequest,
      authentication: isAuthenticated,
    });
  }

  async getStorageInboundByShippingPlanId(
    sellerId: string,
    shippingPlanId: number
  ): Promise<StorageResponseWrapper<StorageInboundResponse> | undefined> {
    return await this.apiClient.get({
      url: `/storage/inbounds?sellerId=${sellerId}&shippingPlanId=${shippingPlanId}`,
      authentication: isAuthenticated,
    });
  }

  async getRates(): Promise<StorageResponseWrapper<RateCard[]> | undefined> {
    return await this.apiClient.get({ url: `/storage/rates`, authentication: isAuthenticated });
  }

  async validateAndReturnInventoryAvailableInEachStorageWarehouse(
    dskuQuantities: DskuQty[]
  ): Promise<StorageResponseWrapper<StorageWarehouseInventory[]> | undefined> {
    return await this.apiClient.post({
      url: `/storage/inventory/validate`,
      body: dskuQuantities,
      authentication: isAuthenticated,
    });
  }

  async getUnifiedAvailablePackInventoryByWarehouse(
    dskuQuantities: DskuQty[],
    isEachPickOrder: boolean = false
  ): Promise<StorageResponseWrapper<StorageWarehouseInventory[]> | undefined> {
    return await this.apiClient.post({
      url: `/storage/inventory/unified?isEachPickOrder=${isEachPickOrder}`,
      body: dskuQuantities,
      authentication: isAuthenticated,
    });
  }

  public async createStorageTransferOrder(
    storageTransferOrderRequest: Partial<StorageTransferOrderRequest>
  ): Promise<StorageResponseWrapper<Partial<StorageTransferOrderResponse>>> {
    return await this.apiClient.post({
      url: `/storage/transfer`,
      body: storageTransferOrderRequest,
      authentication: isAuthenticated,
    });
  }

  public async updateStorageTransferOrder(
    storageTransferOrderRequest: Partial<StorageTransferOrderRequest>,
    storageTransferOrderId: number
  ): Promise<StorageResponseWrapper<Partial<StorageTransferOrderResponse>>> {
    return await this.apiClient.put({
      url: `/storage/transfer/${storageTransferOrderId}`,
      body: storageTransferOrderRequest,
      authentication: isAuthenticated,
    });
  }

  public async submitStorageTransferOrder(
    storageTransferOrderSubmitRequest: StorageTransferOrderSubmitRequest
  ): Promise<StorageResponseWrapper<void>> {
    return await this.apiClient.post({
      url: `/storage/transfer/submit`,
      body: storageTransferOrderSubmitRequest,
      authentication: isAuthenticated,
    });
  }

  public async getStorageTransferOrder(
    sellerId: string,
    storageTransferOrderId: string
  ): Promise<StorageResponseWrapper<StorageTransferOrderResponse>> {
    return await this.apiClient.get({
      url: `/storage/transfer?storageTransferOrderId=${storageTransferOrderId}&sellerId=${sellerId}`,
      authentication: isAuthenticated,
    });
  }

  public async getStorageTransferOrderByInboundShipmentId(
    sellerId: string,
    inboundShipmentId: number
  ): Promise<StorageResponseWrapper<StorageTransferOrderResponse>> {
    return await this.apiClient.get({
      url: `/storage/transfer?inboundShipmentId=${inboundShipmentId}&sellerId=${sellerId}`,
      authentication: isAuthenticated,
    });
  }

  public async archiveStorageTransferOrder(sellerId: string, storageTransferOrderId: number): Promise<void> {
    await this.apiClient.delete({
      url: `/storage/transfer?sellerId=${sellerId}&storageTransferOrderId=${storageTransferOrderId}`,
      authentication: isAuthenticated,
    });
  }

  async cancelStorageTransferOrder(sellerId: string, storageTransferOrderId: number): Promise<void> {
    await this.apiClient.post({
      url: `/storage/transfer/cancel?sellerId=${sellerId}&storageTransferOrderId=${storageTransferOrderId}`,
      authentication: isAuthenticated,
    });
  }

  public async getStorageToEcomRates(
    storageToEcomRateRequest?: StorageToEcomRateRequest
  ): Promise<StorageResponseWrapper<StorageToEcomRateCard> | undefined> {
    return await this.apiClient.post({
      url: `/storage/rates/transfer`,
      body: storageToEcomRateRequest ?? {},
      authentication: isAuthenticated,
    });
  }

  public async getCaseConfig(dsku: string[]): Promise<StorageResponseWrapper<StorageProductData[]> | undefined> {
    const dskuParamValue = (dsku ?? []).map((orig) => `"${orig}"`).join(",");
    return await this.apiClient.get({
      url: `/storage/product/case-config?dsku=[${dskuParamValue}]`,
      authentication: isAuthenticated,
    });
  }

  public async getSellerStorageConfig(sellerId: string): Promise<StorageResponseWrapper<StorageSellerConfig>> {
    return await this.apiClient.get({
      url: `/storage/seller/config/${sellerId}`,
      authentication: isAuthenticated,
    });
  }

  public async createOrUpdateStorageSellerConfig(
    sellerId: string,
    updateConfig: Partial<StorageSellerConfig>
  ): Promise<StorageResponseWrapper<StorageSellerConfig>> {
    return await this.apiClient.put({
      url: `/storage/seller/config/${sellerId}`,
      body: updateConfig,
      authentication: isAuthenticated,
    });
  }

  public async getStorageRecommendationStrategies(): Promise<StorageResponseWrapper<JITReplenishmentStrategy[]>> {
    return await this.apiClient.get({
      url: `/storage/jit/strategy`,
      authentication: isAuthenticated,
    });
  }

  public async generateFtlQuote(quoteRequest: FtlQuoteRequest): Promise<StorageResponseWrapper<QuoteResponse>> {
    return await this.apiClient.post({
      url: `/storage/freight/ftl-quote`,
      body: { quoteRequest } ?? {},
      authentication: isAuthenticated,
    });
  }

  public async generateLtlQuote(quoteRequest: LtlQuoteRequest): Promise<StorageResponseWrapper<QuoteResponse>> {
    return await this.apiClient.post({
      url: `/storage/freight/ltl-quote`,
      body: { quoteRequest } ?? {},
      authentication: isAuthenticated,
    });
  }

  public async requestForInboundAppointment(
    emailRequest: { carrierEmailAddress: string },
    storageInboundOrderId
  ): Promise<void> {
    return await this.apiClient.post({
      url: `/storage/inbounds/${storageInboundOrderId}/request-appointment`,
      body: emailRequest,
      authentication: isAuthenticated,
    });
  }

  async processStorageInboundBulkUploads(
    storageInboundBulkUploadRequest: StorageInboundBulkUploadRequest
  ): Promise<StorageResponseWrapper<StorageInboundBulkUploadResponse[]>> {
    return await this.apiClient.post({
      url: `/storage/inbounds/bulk-upload`,
      body: storageInboundBulkUploadRequest,
      authentication: isAuthenticated,
    });
  }

  public async createReserveStorageOrderBasedOnBookingType(
    createRSOrderRequest: StorageOrderBasedOnBookingTypeRequest
  ): Promise<StorageResponseWrapper<StorageOrderBasedOnBookingTypeResponse>> {
    return await this.apiClient.post({
      url: "/storage/replenishment/order",
      body: createRSOrderRequest,
      authentication: isAuthenticated,
    });
  }
}
