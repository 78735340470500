import { useCallback, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMount } from "react-use";
import { shouldPreserveListState } from "common/RouteUtils";
import { batch, useDispatch, useSelector } from "react-redux";
import { RootState } from "RootReducer";
import { setCurrentPage, setCurrentPageRoute } from "../actions/setCurrentPage";
import { getIsMobile } from "common/user/UserSelectors";
import { searchParcels } from "parcel/screens/list/actions/searchParcels";
import { getIsParcelListLoading, getQueryParams, selectHasActiveFilters } from "./helpers";
import { setCurrentSearchTerm } from "parcel/screens/list/actions/setCurrentSearchTerm";
import { setParcelSort } from "parcel/screens/list/actions/setParcelSort";
import { Path } from "paths";
import { removeAllEmTags } from "common/StringUtils";
import { setParcelSelectedRows } from "../actions/setParcelSelectedRows";
import { onParcelRowSelect } from "../actions/onParcelRowSelect";
import { FeatureName, useFeatureOn } from "common/Split";

export const useParcelListTable = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { searchInfo, currentSearchTerm, currentPageItems, sort, selectedRows, pageChecked, isAnyRowSelected } =
    useSelector((state: RootState) => state.parcelList);
  const { resultsPerPage } = useSelector((state: RootState) => state.user);

  const isMobile = useSelector(getIsMobile);
  const isLoading = useSelector(getIsParcelListLoading);
  const isFiltered = useSelector(selectHasActiveFilters);
  const isParcelLinkFlexportShipmentOn = useFeatureOn(FeatureName.ParcelLinkFlexportShipment);
  const prevResultsPerPage = useRef(resultsPerPage);

  useMount(() => {
    if (!(shouldPreserveListState(location, history) && searchInfo)) {
      const { formattedPage, search } = getQueryParams(location);

      if (formattedPage) {
        setCurrentPageRoute(formattedPage - 1);
      }
      if (search) {
        dispatch(setCurrentSearchTerm(search));
        dispatch(searchParcels(formattedPage ? { page: formattedPage - 1 } : undefined));
      } else {
        dispatch(searchParcels(formattedPage ? { page: formattedPage - 1 } : undefined));
      }
    }
  });

  useEffect(() => {
    if (resultsPerPage !== prevResultsPerPage.current) {
      prevResultsPerPage.current = resultsPerPage;
      dispatch(searchParcels());
    }
  }, [resultsPerPage, prevResultsPerPage, dispatch]);

  const onPageChange = useCallback(
    (page: number) => {
      batch(() => {
        dispatch(setCurrentPage(page));
        dispatch(searchParcels());
      });
      setCurrentPageRoute(page);
    },
    [dispatch]
  );

  const onParcelSort = useCallback((sortBy) => dispatch(setParcelSort(sortBy)), [dispatch]);

  const itemPath = useCallback(
    ({ parcelId }) => Path.parcelDetail.replace(":parcelId", removeAllEmTags(`${parcelId}`)),
    []
  );

  const onItemClick = useCallback(
    (item) => {
      return history.push(itemPath(item));
    },
    [history, itemPath]
  );

  const onRowCheck = useCallback(
    (idx: number, isChecked: boolean) => {
      dispatch(onParcelRowSelect(idx, isChecked));
    },
    [dispatch]
  );

  const onPageCheck = useCallback(() => {
    dispatch(setParcelSelectedRows());
  }, [dispatch, currentPageItems, isAnyRowSelected]);

  return {
    onPageChange,
    onParcelSort,
    onItemClick,
    currentSearchTerm,
    currentPageItems,
    sort,
    searchInfo,
    isMobile,
    isLoading,
    isFiltered,
    selectedRows,
    onRowCheck,
    onPageCheck,
    isParcelLinkFlexportShipmentOn,
    pageChecked,
  };
};
