import { Product } from "@deliverr/commons-clients";

import { DimensionsFormValues } from "./DimensionsFormValues";

export const getDefaultDimensions = (products: Product[]): DimensionsFormValues =>
  products.reduce(
    (dict, { dsku, length, width, height, weight }) => ({
      ...dict,
      [dsku]: {
        length,
        width,
        height,
        weight,
      },
    }),
    {}
  );
