import { DefaultTheme, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import { BasicTable, RootTableProps } from "common/components/BasicTable";

export const ProblemBladeTableContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
    padding: ${theme.spacing.S5};  
  `
);

export interface ProblemBladeTableProps extends RootTableProps {
  fullWidth?: boolean;
}

const FIRST_COL_WIDTH = "150px";
const FULL_WIDTH_FIRST_COL_WIDTH = "60%";

export const ProblemBladeTable = styled(BasicTable)<ProblemBladeTableProps, DefaultTheme>(
  ({ theme, fullWidth }) => `
    position: relative;
    /* working with tables sucks. This is how to get a fixed width between columns that doesn't also add spacing outside */
    width: 100%;
    border-collapse: collapse;

    thead:after {
      content: "";
      position: absolute;
      width: 100%;
      border-bottom: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
    }

    th:not(:first-of-type), td:not(:first-of-type) {
      text-align: right;
    }

    th {
      font-size: ${theme.font.size.F1};
      font-weight: ${theme.font.weight.BOLD};
      color: ${theme.colors.NEUTRAL[500]};
      padding: ${theme.spacing.S2} 0;

      &:not(:first-of-type) {
        padding-left: ${theme.spacing.S2};
      }
    }

    td {
      padding: ${theme.spacing.S4} 0;
      &:not(:first-of-type) {
        padding-left: ${theme.spacing.S2};
      }
    }

    td, th {
      border: none;
      max-width: ${fullWidth ? FULL_WIDTH_FIRST_COL_WIDTH : FIRST_COL_WIDTH};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &:not(:first-of-type) {
        border-left-width: ${theme.spacing.S6};
      }
      
      &:first-of-type {
        width: ${fullWidth ? FULL_WIDTH_FIRST_COL_WIDTH : FIRST_COL_WIDTH};
      }
    }

    tbody tr:last-of-type td {
      padding-bottom: 0;
    }

    tbody tr:not(:first-of-type) {
      border: ${theme.border.width.B0} ${theme.border.type} transparent;
      background-clip: padding-box;
      border-top-width: ${theme.spacing.S1};
    }
  `
);
