import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { InboundListActionTypes } from "inbounds/list/InboundListActions";
import { InboundListState } from "./InboundListState";
import { inboundListInitialState } from "./inboundListInitialState";

const reducers: ReducerDictionary<InboundListState> = {
  ...handleSimpleReducerUpdates([
    InboundListActionTypes.SEARCH_INBOUNDS_START,
    InboundListActionTypes.SET_ACTION_NEEDED_SHIPMENTS,
    InboundListActionTypes.SET_ACTION_NEEDED_SHIPPING_PLANS,
    InboundListActionTypes.SET_FILTERS_BLADE_OPEN,
  ]),
  [InboundListActionTypes.SEARCH_INBOUNDS_SUCCESS]: (state, { currentPageItems, searchInfo }) => ({
    ...state,
    currentPageItems,
    searchLoading: false,
    searchInfo,
  }),
  [InboundListActionTypes.RESET_INBOUNDS_FILTERS]: (state) => ({
    ...state,
    searchTerm: "",
    searchFilters: { ...inboundListInitialState.searchFilters },
  }),
  [InboundListActionTypes.SET_SELECTED_ROW]: (state, action) => {
    const newSelectedRows = {
      ...state.selectedRows,
      [action.rowIx]: action.isSelected,
    };
    return {
      ...state,
      pageChecked: state.pageChecked && action.isSelected,
      selectionCount: Object.values(newSelectedRows).filter((isSelected) => isSelected).length,
      selectedRows: newSelectedRows,
    };
  },
  [InboundListActionTypes.SET_PAGE_SELECTED]: (state, { isSelected }) => ({
    ...state,
    pageChecked: isSelected,
    selectionCount: isSelected ? state.currentPageItems.length : 0,
    selectedRows: isSelected ? new Array(state.currentPageItems.length).fill(true) : {},
  }),

  [InboundListActionTypes.DUPLICATE_SHIPPING_PLANS_SUCCESS]: (state) => ({
    ...state,
    selectedRows: {},
  }),
  [InboundListActionTypes.DELETE_SHIPPING_PLANS_SUCCESS]: (state, { shippingPlanIds }) => ({
    ...state,
    currentPageItems: state.currentPageItems.filter((item) => !shippingPlanIds.includes(item.shippingPlanId)),
    selectedRows: {},
  }),
};

export const inboundListReducer = createReducer<InboundListState>({ ...inboundListInitialState }, reducers);
