import React, { FC, ReactNode } from "react";
import { TooltipWrapper, Tooltip, TooltipSkin } from "common/components/Tooltip";
import { BoxReset, Box, Text, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";

const DashboardTooltipContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    .tooltip-content {
      bottom: 120%;
    }
  `
);

export interface DashboardTooltipProps {
  active: boolean;
  message: ReactNode;
}

export const DashboardTooltip: FC<DashboardTooltipProps> = ({ active, message, children }) => (
  <DashboardTooltipContainer>
    <TooltipWrapper>
      {children}
      <Tooltip skin={TooltipSkin.PRIMARY} arrowReverse active={active} className={{ content: "tooltip-content" }}>
        <Box padding="S2">
          <BoxReset width="230px">
            <Text>{message}</Text>
          </BoxReset>
        </Box>
      </Tooltip>
    </TooltipWrapper>
  </DashboardTooltipContainer>
);
