import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { mapError } from "../core/mapError";
import { ReferralProgramData } from "./ReferralProgramData/ReferralProgramData";
import { ReferralProgramsError } from "./ReferralProgramsError";

export type ReferralProgramsClientConfig = ApiClientConfig;

export class ReferralProgramsClient {
  private apiClient: ApiClient;

  constructor(
    { baseURL }: ReferralProgramsClientConfig = { baseURL: mustBeDefined(process.env.REFERRAL_PROGRAMS_URL) }
  ) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1/`,
    });
  }

  async getReferralProgramData(sellerId: string): Promise<ReferralProgramData> {
    try {
      return await this.apiClient.get({
        url: `/participant/${sellerId}`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [ReferralProgramsError.PARTICIPANT_NOT_FOUND]);
    }
  }
}
