import { SearchOptions } from "@deliverr/logistics-search-client";
import { SearchRequest } from "../SearchService";
import { OpenSearchService } from "common/search/services/OpenSearchService";
import { QueryDslQueryContainer } from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";

export class OrderIngestErrorService extends OpenSearchService {
  protected buildSearchOptions(request: SearchRequest): SearchOptions {
    const searchOptions = super.buildSearchOptions(request);
    const boolShouldQueries: QueryDslQueryContainer | QueryDslQueryContainer[] =
      searchOptions?.query?.bool?.should ?? [];
    const shouldQueries: QueryDslQueryContainer[] = Array.isArray(boolShouldQueries)
      ? boolShouldQueries
      : [boolShouldQueries];

    if (request.searchTerm) {
      const searchTerm: string = request.searchTerm;
      shouldQueries.push({
        match: {
          "attributes.channelOrderId": `${searchTerm}`,
        },
      });
    }

    const additionalSearchOptions: Partial<SearchOptions> = {
      query: {
        bool: {
          ...(searchOptions?.query?.bool ?? {}),
          should: shouldQueries,
          minimum_should_match: shouldQueries.length > 0 ? 1 : 0,
        },
      },
    };
    if (this.config.searchConfig.highlightMatches && request.highlightMatches !== false) {
      additionalSearchOptions.highlight = {
        fields: {
          "attributes.channelOrderId": {},
        },
      };
      additionalSearchOptions.hydrate = true;
    }
    return {
      ...searchOptions,
      ...additionalSearchOptions,
    };
  }
}
