import cx from "classnames";
import { Accordion, Button, Icon, Title } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { SimpleLoader } from "common/components/WithLoader/WithLoader";
import Content from "common/components/Content";
import { ERRORED_WHILE_SYNCING, NONE, SUCCEEDED, SYNCING } from "tags/facebook/common/state/adSetsSyncStatus";
import { FastTagPageComponentProps } from "tags/model/FastTagPage";
import { FacebookState } from "tags/facebook/connect/state/FacebookReducer";
import { FacebookEditFlowState } from "tags/facebook/edit/state/FacebookEditFlowState";
import { useAdSetsSync } from "./useAdSetsSync";

import cls from "./AdSetsSync.less";
import sharedCls from "../facebook.less";

export const AdSetsSync: FC<FastTagPageComponentProps<FacebookState | FacebookEditFlowState>> = (props) => {
  const { adSetSyncStatus, onDoneClick, groupedAdSets, isOpen, toggleOpen } = useAdSetsSync(props);

  return (
    <div className={sharedCls.container}>
      <div className={sharedCls.inner}>
        {adSetSyncStatus === NONE && (
          <>
            <div>
              <SimpleLoader size="2x" />
            </div>
            <Title displayAs="h4">
              <FormattedMessage id="FacebookFastTag.AdSetsSync.Checking.Title" defaultMessage="Checking Your Ads" />
            </Title>
          </>
        )}
        {adSetSyncStatus === SYNCING && (
          <>
            <div>
              <SimpleLoader size="2x" />
            </div>
            <Title displayAs="h4">
              <FormattedMessage id="FacebookFastTag.AdSetsSync.Syncing.Title" defaultMessage="Syncing Ad Sets" />
            </Title>
          </>
        )}
        {adSetSyncStatus === SUCCEEDED && (
          <Content
            before={
              <div className={cx(sharedCls.completeIcon, sharedCls.largeCheckMarkContainer)}>
                <Icon type="check" size="lg" className={sharedCls.completeIconInner} />
              </div>
            }
            title={<FormattedMessage id="AllSet.title" defaultMessage="You're all set!" />}
            body={
              <div className={cx(sharedCls.boundedWidth, cls.syncedProductsContainer)}>
                {Object.keys(groupedAdSets).map((dsku) => {
                  const adSets = groupedAdSets[dsku];

                  return (
                    <div key={dsku} className={cls.syncedProducts}>
                      <Accordion
                        headerContent={
                          <div className={cls.productAccordion}>
                            <div className={cls.product}>{dsku}</div>
                            <p className={cls.adSetsFound}>
                              <FormattedMessage
                                id="FacebookFastTag.AdSetsSync.FoundAdSetsMessage"
                                defaultMessage="{adSetsFound} ad sets found"
                                values={{ adSetsFound: adSets.length }}
                              />
                            </p>
                          </div>
                        }
                        indicator
                        className={cls.productAdSets}
                        isOpen={isOpen(dsku)}
                        toggleOpen={toggleOpen(dsku)}
                      >
                        <div className={cls.adSets}>
                          {adSets.map((adSet) => (
                            <div key={adSet.id}>
                              {(adSet as any).name ?? (
                                <span className={cls.noName}>
                                  <FormattedMessage
                                    id="FacebookFastTag.AdSetsSync.UnnamedAdSet"
                                    defaultMessage="(unnamed ad set)"
                                  />
                                </span>
                              )}
                            </div>
                          ))}
                        </div>
                      </Accordion>
                    </div>
                  );
                })}
              </div>
            }
            after={
              <Button onClick={onDoneClick}>
                <FormattedMessage id="done" defaultMessage={`Done`} />
              </Button>
            }
          />
        )}
        {adSetSyncStatus === ERRORED_WHILE_SYNCING && (
          <Content
            before={
              <div className={cx(sharedCls.completeIcon, sharedCls.errorIconContainer)}>
                <Icon type="times" className={sharedCls.completeIconInner} />
              </div>
            }
            body={
              <FormattedMessage
                id="FacebookFastTag.AdSetsSync.ErrorText"
                defaultMessage="An error occurred while attempting to sync your ad sets. Please try again or contact support."
              />
            }
            after={
              <Button onClick={onDoneClick}>
                <FormattedMessage id="done" defaultMessage={`Done`} />
              </Button>
            }
          />
        )}
      </div>
    </div>
  );
};
