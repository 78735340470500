import { Card, CardProps, DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import React, { FC } from "react";

interface OnboardingStepLayoutProps {
  body: JSX.Element;
  footerContent?: JSX.Element;
}

const Body = styled(Card)<{}, DefaultTheme>(
  ({ theme }) => `
    padding: ${theme.spacing.S6} ${theme.spacing.S6};
  `
);

const Footer = styled(Card)<CardProps, DefaultTheme>(
  ({ theme }) => `
    border-top: solid thin ${theme.colors.NEUTRAL[60]};
  `
);

export const OnboardingStepLayoutBase: FC<OnboardingStepLayoutProps> = ({ body, footerContent }) => {
  return (
    <div>
      <Body>{body}</Body>
      {footerContent && <Footer size="SM">{footerContent}</Footer>}
    </div>
  );
};
