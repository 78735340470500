import React from "react";
import { StyledButtonIcon } from "../ButtonIcon";
import { Icon } from "../Icon";
import styled from "@emotion/styled";

interface FileInputIconProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Sets aria-label */
  label: string;
  /** Adds a spinner */
  loading?: boolean;
  /** Non-primary action */
  secondary?: boolean;
  /** Disable state */
  disabled?: boolean;
}

const AsLabel = StyledButtonIcon.withComponent("label");
const Label = styled(AsLabel)`
  margin: 0;
`;
const Input = styled.input`
  opacity: 0;
  position: absolute;
  width: 1px;
`;

export const FileInputIcon: React.FC<FileInputIconProps> = ({
  label,
  loading,
  onChange,
  secondary,
  disabled,
  ...props
}) => (
  <Label appearance="DEFAULT" secondary={secondary} disabled={disabled} aria-label={label} size="MD">
    {loading ? <Icon type="circle-notch" spin={true} /> : <Icon type={"arrow-to-top"} size="lg" />}
    <Input type="file" onChange={onChange} disabled={disabled} {...props} />
  </Label>
);
