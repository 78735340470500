import React, { FC, useMemo, useState } from "react";
import { map } from "lodash";
import { ShipmentResponse, ShippingType } from "@deliverr/replenishment-client";
import { WholesaleShipmentType, WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { Anchor, LightDivider, Text, ThemeProps } from "common/components/ui";
import { FormattedMessage, useIntl } from "react-intl";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { getReplenishmentShippingPartnerFromWholesaleType } from "../utils/getReplenishmentShippingPartnerFromWholesaleType";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { EstimateMap } from "./useReplenishmentOrderShippingStep";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";
import Table, { TableColumn, TableProps } from "common/components/lists/Table";
import { SelectMultipleShipmentRadioOption } from "./SelectMultipleShipmentRadioOption";
import { MultipleShipmentShippingEstimatedTotal } from "./MultipleShipmentShippingEstimatedTotal";
import { fbaIntegrationCommonLabels } from "wholesale/common/FbaIntegrationCommon.labels";
import { ViewShipmentDetailsModal } from "./ViewShipmentDetailsModal";
import { getShipmentItemsSummary } from "./getShipmentItemsSummary";

const StyledTable = styled(Table)<TableProps<ShipmentResponse>>`
  thead {
    display: none;
  }
`;

const StyledAnchor = styled(Anchor)<ThemeProps>(
  ({ theme }) => `
  cursor: pointer;
  font-weight: ${theme.font.weight.REGULAR};
  font-size: ${theme.font.size.F1};
`
);

export interface SelectMultipleShipmentShippingTypeProps {
  shippingEstimation: EstimateMap;
  shippingMethod: WholesaleShippingMethod;
  cargoType?: WholesaleShipmentType;
  retryShippingEstimation: (
    shippingMethod: WholesaleShippingMethod,
    destinationType: TransferCreationTypes,
    shippingType: ShippingType
  ) => Promise<void>;
}

export const SelectMultipleShipmentShippingType: FC<SelectMultipleShipmentShippingTypeProps> = ({
  retryShippingEstimation,
  shippingEstimation,
  shippingMethod,
}) => {
  const { destinationType, replenishmentOrder } = useSelector(selectTransferCreate);
  const [selectedShipment, setSelectedShipment] = useState<ShipmentResponse | undefined>();

  const shipments = replenishmentOrder?.shipments ?? [];

  const columns = useMemo<TableColumn<ShipmentResponse>[]>(
    () => [
      {
        header: "",
        width: "30%",
        renderCell: (shipment) => (
          <ConciseShipmentDetails shipment={shipment} setSelectedShipment={setSelectedShipment} />
        ),
      },
      ...map(
        shippingEstimation[getReplenishmentShippingPartnerFromWholesaleType(shippingMethod, destinationType!)],
        (estimation, key) => {
          const shippingType = key as ShippingType;
          return {
            header: "",
            width: "35%",
            renderCell: (shipment) => (
              <SelectMultipleShipmentRadioOption
                estimation={estimation}
                shipment={shipment}
                shippingMethod={shippingMethod}
                shippingType={shippingType}
                retryShippingEstimation={retryShippingEstimation}
              />
            ),
          } as TableColumn<ShipmentResponse>;
        }
      ),
    ],
    [shippingMethod, shippingEstimation, destinationType, retryShippingEstimation]
  );

  return (
    <>
      <ViewShipmentDetailsModal
        show={!!selectedShipment}
        onClose={() => setSelectedShipment(undefined)}
        shipment={selectedShipment}
      />
      <StyledTable
        columns={columns}
        items={shipments}
        page="0"
        onPageChange={() => {}}
        showCheckboxCondition={() => false}
        showAllSelectionCheckbox={false}
        allowRowSelection={false}
        hidePaginate
        hideHover
        hideBoxShadow
      />
      <LightDivider />
      <MultipleShipmentShippingEstimatedTotal shippingMethod={shippingMethod} shippingEstimation={shippingEstimation} />
    </>
  );
};

export const ConciseShipmentDetails: FC<{
  shipment: ShipmentResponse;
  setSelectedShipment: (shipment: ShipmentResponse) => void;
}> = ({ shipment, setSelectedShipment }) => {
  const { numOfCases, numOfSkus, numOfUnits } = getShipmentItemsSummary(shipment);
  const { formatMessage } = useIntl();
  const shipmentAddress = shipment.destinationWarehouseAddress;
  return (
    <>
      <Text bold>{`${shipmentAddress?.city}, ${shipmentAddress?.state}`}</Text>
      <Text size="caption" appearance="INFO">
        {formatMessage(transfersShipmentCreateLabels.steps.shipping.shipmentItemDesc, {
          numOfCases,
          numOfSkus,
          numOfUnits,
        })}
      </Text>
      <StyledAnchor data-testid="view-more-fba-shipments" onClick={() => setSelectedShipment(shipment)}>
        <FormattedMessage {...fbaIntegrationCommonLabels.shipmentDetails.viewDetails} />
      </StyledAnchor>
    </>
  );
};
