import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { NonComplianceActionTypes } from "./actions/NonComplianceActionTypes";

export interface NonComplianceState {
  unresolvedCaseCount: number;
}

export const nonComplianceInitialState: NonComplianceState = {
  unresolvedCaseCount: 0,
};

const reducers: ReducerDictionary<NonComplianceState> = {
  ...handleSimpleReducerUpdates([NonComplianceActionTypes.SET_NON_COMPLIANCE_UNRESOLVED_CASE_COUNT]),
};

export const nonComplianceReducer = createReducer(nonComplianceInitialState, reducers);
