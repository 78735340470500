import styled from "@emotion/styled";
import { Checkbox, sharedCheckboxGrid } from "../Checkbox";
import React from "react";
import { DefaultTheme, ThemeProps } from "../shared";
import { transitionFast } from "../shared/helpers/css";
import { useCheckboxTile } from "./useCheckboxTile";

export interface CheckboxTileProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string | JSX.Element;
  description?: string | JSX.Element;
}

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
const StyledCheckboxTile = styled.label<
  Pick<CheckboxTileProps, "disabled" | "checked"> & { focused: boolean },
  DefaultTheme
>(
  ({ disabled, checked, focused, theme }) => `
    ${sharedCheckboxGrid({ theme, disabled })};
    border-radius: ${theme.border.radius.R2};
    padding: ${theme.spacing.S4};
    border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
    box-sizing: border-box;
    cursor: ${disabled ? "not-allowed" : "pointer"};
    background-color: ${disabled ? theme.colors.NEUTRAL[20] : theme.colors.NEUTRAL["00"]};
    ${transitionFast("box-shadow, border-color")};
    ${!disabled && (checked || focused) ? `border: 1px solid ${theme.colors.BLUE[300]};` : ""}
    ${!disabled && focused ? `box-shadow: ${theme.input.focus.BLUE};` : ""}
	`
);
/* eslint-enable @typescript-eslint/prefer-nullish-coalescing */

const StyledCheckbox = styled(Checkbox)`
  grid-column: 1;
  &:focus {
    box-shadow: none;
  }
`;

const StyledDescription = styled.div<ThemeProps>(
  ({ theme }) => `
    grid-column: 2;
    color: ${theme.colors.NEUTRAL[300]};
    font-size: ${theme.font.size.F2};
  `
);

const StyledLabel = styled.span<{ disabled: boolean }, DefaultTheme>(
  ({ disabled, theme }) => `
    grid-column: 2;
    color: ${theme.colors.NEUTRAL[disabled ? 400 : 500]}
  `
);

export const CheckboxTile: React.FC<CheckboxTileProps> = ({
  description,
  label,
  disabled = false,
  checked: checkedFromProps = false,
  onChange,
  onFocus,
  onBlur,
  ...otherProps
}) => {
  const { checked, focused, handleFocus, handleBlur, handleChange } = useCheckboxTile({
    checkedFromProps,
    onFocus,
    onBlur,
    onChange,
  });

  return (
    <StyledCheckboxTile checked={checked} disabled={disabled} focused={focused}>
      <StyledCheckbox
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        checked={checked}
        disabled={disabled}
        {...otherProps}
      />
      <StyledLabel disabled={disabled}>{label}</StyledLabel>
      {description && <StyledDescription>{description}</StyledDescription>}
    </StyledCheckboxTile>
  );
};
