import React, { FC } from "react";
import { useCarrierAccountModal } from "../../hooks/useCarrierAccountModal";
import {
  CarrierAccount,
  CreateCarrierAccountRequest,
  UpdateCarrierAccountRequest,
} from "@deliverr/replenishment-client";
import { ReplenishmentCarrierAccountForm } from "./ReplenishmentCarrierAccountForm";
import { SPThunkAction } from "common/ReduxUtils";
import { DeleteCarrierAccountModalContent } from "./DeleteCarrierAccountModalContent";
import { StyledModal } from "./ModalStyles";

export const ReplenishmentCarrierAccountModalId = "ReplenishmentCarrierAccountModal";

export enum CarrierAccountModalMode {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export interface CarrierAccountModalProps {
  onClose: () => void;
  onCreateCarrierAccount: (createCarrierAccountRequest: CreateCarrierAccountRequest) => SPThunkAction<Promise<void>>;
  onUpdateCarrierAccount: (
    carrierAccountId: number,
    updateCarrierAccountRequest: UpdateCarrierAccountRequest
  ) => SPThunkAction<Promise<void>>;
  onDeleteCarrierAccount: (carrierAccountId: number, selectedCarrierAccountId?: number) => SPThunkAction<Promise<void>>;
  modalMode: CarrierAccountModalMode;
  carrierAccount?: CarrierAccount;
}

export const CarrierAccountModal: FC<CarrierAccountModalProps> = ({
  onClose,
  onCreateCarrierAccount,
  onUpdateCarrierAccount,
  onDeleteCarrierAccount,
  modalMode,
  carrierAccount,
}) => {
  const { isLoading, handleDelete } = useCarrierAccountModal(onDeleteCarrierAccount, onClose, carrierAccount);
  const isUpdateMode = modalMode === CarrierAccountModalMode.UPDATE;
  return (
    <StyledModal id={ReplenishmentCarrierAccountModalId}>
      {modalMode === CarrierAccountModalMode.DELETE ? (
        <DeleteCarrierAccountModalContent
          carrierAccount={carrierAccount}
          isLoading={isLoading}
          onClose={onClose}
          handleDelete={handleDelete}
        />
      ) : (
        <ReplenishmentCarrierAccountForm
          onCreateCarrierAccount={onCreateCarrierAccount}
          onUpdateCarrierAccount={onUpdateCarrierAccount}
          onClose={onClose}
          isUpdateMode={isUpdateMode}
          carrierAccount={carrierAccount}
        />
      )}
    </StyledModal>
  );
};
