import { carrierNetworkClient } from "Clients";
import { TrackingDetailResponse } from "common/clients/transportation/TrackingDetails";

export async function getTrackingDetailsWithTimeout(
  trackingCodes,
  timeoutMs
): Promise<PromiseSettledResult<TrackingDetailResponse>[]> {
  const timeoutPromise: Promise<[]> = new Promise((resolve) => setTimeout(() => resolve([]), timeoutMs));

  const trackingPromise = Promise.allSettled(
    trackingCodes.map(async (trackingCode) => await carrierNetworkClient.getTrackingDetail(trackingCode))
  );

  return await Promise.race([trackingPromise, timeoutPromise]);
}

export function formatDate(dateString) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(dateString);
  const monthName = months[date.getMonth()];
  const day = date.getDate();

  return `${monthName} ${day}`;
}
