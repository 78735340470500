import { useTheme } from "emotion-theming";
import styled from "@emotion/styled";
import React from "react";
import ReactSwitch, { ReactSwitchProps } from "react-switch";
import { Appearance, DefaultTheme } from "../shared";

export type SwitchAppearance = Extract<keyof typeof Appearance, "DEFAULT" | "SUCCESS">;

export interface SwitchProps extends ReactSwitchProps {
  appearance?: SwitchAppearance;
  label?: string | JSX.Element;
}

const StyledReactSwitch = styled(ReactSwitch)<SwitchProps>(
  ({ disabled }) => `
  opacity: 1 !important;
  .react-switch-bg,
  .react-switch-handle {
    cursor: ${disabled ? "not-allowed" : "pointer"} !important;
  }
`
);

const Label = styled.label<Pick<SwitchProps, "disabled">, DefaultTheme>(
  ({ disabled, theme }) => `
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: ${theme.spacing.S3};
  cursor: ${disabled ? `not-allowed` : `pointer`};
  color: ${theme.colors.NEUTRAL[300]};
`
);

export const Switch: React.FC<SwitchProps> = ({ appearance = Appearance.DEFAULT, label, ...props }) => {
  const theme = useTheme<DefaultTheme>();
  const color = theme.config.colorByAppearance[appearance];
  const onColor = props.disabled ? theme.colors[color][100] : theme.colors[color][300];
  const offColor = props.disabled ? theme.colors.NEUTRAL[100] : theme.colors.NEUTRAL[300];
  const ele = (
    <StyledReactSwitch
      uncheckedIcon={false}
      checkedIcon={false}
      width={30}
      height={16}
      onColor={onColor}
      offColor={offColor}
      {...props}
    />
  );
  return label ? (
    <Label disabled={props.disabled}>
      {ele}
      <span>{label}</span>
    </Label>
  ) : (
    ele
  );
};
