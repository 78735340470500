import React from "react";

export interface FormattedMessageProps {
  defaultMessage: string;
  id: string;
}
/** dummy FormattedMessage component - should be removed once we add localization (https://app.asana.com/0/1200686171370703/1200822447930878) */

export const FormattedMessage: React.FC<FormattedMessageProps> = ({ defaultMessage }) => {
  return <>{defaultMessage}</>;
};
