import styled from "@emotion/styled";
import { Text, ThemeProps } from "common/components/ui";
import React, { FC, PropsWithChildren, ReactNode } from "react";

const CardSection = styled.div<ThemeProps>(
  ({ theme }) => `
    padding: ${theme.spacing.S5} ${theme.spacing.S7};
    background-color: ${theme.colors.NEUTRAL["00"]};
  `
);

const CardBody = styled(CardSection)<ThemeProps>(
  () => `
    padding-top: 0;
  `
);

const CardContainer = styled.div<ThemeProps>(
  () => `
    display: flex;
    flex-direction: column;
  `
);

const CardHeader = styled.div<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL["500"]};
  display: flex;
  flex-direction: column;
  font-family: ${theme.font.family.TITLE};
  font-size: ${theme.font.size.F2}};
  font-weight: ${theme.font.weight.BOLD};
  justify-content: space-between;
  letter-spacing: 0;
`
);

const Title = styled.span<ThemeProps>(
  ({ theme }) => `
  flex: 1;
  line-height: ${theme.font.lineHeight.LH2};
  text-transform: uppercase;
`
);

interface ViewShipmentCardProps {
  title: ReactNode;
  subtitle?: ReactNode;
}

export const ViewShipmentCard: FC<PropsWithChildren<ViewShipmentCardProps>> = ({ children, title, subtitle }) => {
  return (
    <CardContainer>
      <CardSection>
        <CardHeader>
          <Title>{title}</Title>
          {subtitle && <Text>{subtitle}</Text>}
        </CardHeader>
      </CardSection>
      <CardBody>{children}</CardBody>
    </CardContainer>
  );
};
