import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";

export const useCargoSkuSwitch = () => {
  const { setValue, watch } = useCreateShipmentFormContext();
  const isMixedSkuCargo = watch(CreateShipmentInputName.IS_CARGO_MIXED_SKU);

  const setIsMixedSku = (isMixedSku: boolean) => {
    setValue(CreateShipmentInputName.IS_CARGO_MIXED_SKU, isMixedSku);
  };

  return {
    isMixedSkuCargo,
    setIsMixedSku,
  };
};
