import { DeliverrAddress, RequestForQuote } from "@deliverr/freight-client";
import { getLocationType } from "./getLocationType";
import { getFbaTradingPartner } from "./getFbaTradingPartner";
import { QuoteLocationTypes } from "freight/types/QuoteLocationTypes";

export interface QuoteRequestDetails {
  sellerId: string;
  pickupAddress: DeliverrAddress;
  pickupLocationType: QuoteLocationTypes | null;
  pickupLiftgateRequired: boolean;
  deliveryAddress: DeliverrAddress;
  deliveryLocationType: QuoteLocationTypes | null;
  deliveryLiftgateRequired: boolean;
  numberOfPallets: number;
}

export const formatQuoteRequest = ({
  sellerId,
  pickupAddress,
  pickupLocationType,
  pickupLiftgateRequired,
  deliveryAddress,
  deliveryLocationType,
  deliveryLiftgateRequired,
  numberOfPallets,
}: QuoteRequestDetails): RequestForQuote => ({
  sellerId,
  pickupDetails: {
    zip: pickupAddress.zip,
    locationType: getLocationType(pickupLocationType) ?? undefined,
    liftGateRequired: pickupLiftgateRequired,
    address: pickupAddress,
  },
  deliveryDetails: {
    zip: deliveryAddress.zip,
    locationType: getLocationType(deliveryLocationType) ?? undefined,
    liftGateRequired: deliveryLiftgateRequired,
    address: deliveryAddress,
    tradingPartner: getFbaTradingPartner(deliveryLocationType) ?? undefined,
  },
  quantity: numberOfPallets,
});
