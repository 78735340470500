import { createActionCreator } from "common/ReduxUtils";
import { MultipleShopFastTag } from "./model/multipleShop/MultipleShopFastTagProgram";
import { Shop } from "./model/Shop";

export interface ProgramProps {
  activationDates: Date | undefined;
  ableToReactivate?: boolean;
}

export enum FastTagsActionTypes {
  ENABLE_NEXT = "ENABLE_NEXT",
  DISABLE_NEXT = "DISABLE_NEXT",
  GET_STATUS = "GET_STATUS",
  UPDATE_LOCAL_TAGS = "UPDATE_LOCAL_TAGS",
  SHOP_CONNECTION_STATUS_RECEIVED = "SHOP_CONNECTION_STATUS_RECEIVED",
}

export enum Enable {
  NextButton = "NextButton",
  Transition = "Transition",
}

export const enableNext = createActionCreator<Enable, string>(
  FastTagsActionTypes.ENABLE_NEXT,
  "enableType",
  "propName"
);

export const disableNext = createActionCreator<Enable, string>(
  FastTagsActionTypes.DISABLE_NEXT,
  "enableType",
  "propName"
);

export const getStatus = createActionCreator<string, ProgramProps>(
  FastTagsActionTypes.GET_STATUS,
  "program",
  "programProps"
);

export const updateLocalTags = createActionCreator(FastTagsActionTypes.UPDATE_LOCAL_TAGS);

export const shopConnectionStatusReceived = createActionCreator<MultipleShopFastTag, Shop[]>(
  FastTagsActionTypes.SHOP_CONNECTION_STATUS_RECEIVED,
  "fastTag",
  "shops"
);
