import { useState, useRef, MouseEventHandler } from "react";

interface UseDashboardTooltip {
  active: boolean;
  onMouseOver: MouseEventHandler;
  onMouseOut: MouseEventHandler;
}

export const useDashboardTooltip = (
  timeout?: number,
  onMouseOverHandler?: (e: MouseEvent) => void,
  onMouseOutHandler?: (e: MouseEvent) => void
): UseDashboardTooltip => {
  const [active, setActive] = useState(false);
  const timer = useRef<NodeJS.Timer | undefined>();

  const onMouseOut = (e) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    if (timeout) {
      timer.current = setTimeout(() => setActive(false), timeout);
    } else {
      setActive(false);
    }

    onMouseOutHandler?.(e);
  };

  const onMouseOver = (e) => {
    setActive(true);
    onMouseOverHandler?.(e);
  };

  return {
    active,
    onMouseOver,
    onMouseOut,
  };
};
