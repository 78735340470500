import { defineMessages } from "react-intl";

export const commonMsgs = defineMessages({
  box: {
    id: "inbounds.commonMsgs.boxUnit",
    defaultMessage: `{boxesQty, plural,
                one {# box}
                other {# boxes}
              }`,
  },
  lb: {
    id: "inbounds.commonMsgs.lbUnit",
    defaultMessage: `{weight, plural,
                one {# lb}
                other {# lbs}
              }`,
  },
  chargeAmountAndUnits: {
    id: "inbounds.commonMsgs.chargeAmountAndUnitsMessage",
    defaultMessage: "{chargeAmount} ({units, plural, one {# unit} other {# units}})",
  },
  units: {
    id: "inbounds.commonMsgs.unitsMessage",
    defaultMessage: "{units, plural, one {# unit} other {# units}}",
  },
  perBox: {
    id: "inbounds.commonMsgs.perBox",
    defaultMessage: "{amount}/box",
  },
  perUnit: {
    id: "inbounds.commonMsgs.perUnit",
    defaultMessage: "{amount}/unit",
  },
  perPallet: {
    id: "inbounds.commonMsgs.perPallet",
    defaultMessage: "{amount}/pallet",
  },
  valueToBeDetermined: {
    id: "inbounds.commonMsgs.valueToBeDetermined",
    defaultMessage: "—",
  },
  learnMore: {
    id: "inbounds.commonMsgs.learnMore",
    defaultMessage: "Learn more",
  },
  containerWithLength: {
    id: "inbounds.commonMsgs.containerWithLength",
    defaultMessage: "{containerLengthInFeet}' container",
  },
  perContainer: {
    id: "inbounds.commonMsgs.perContainer",
    defaultMessage: "{amount}/container",
  },
});
