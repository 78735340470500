import { FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  estimateCosts,
  EstimatedCostType,
  setFieldValue,
} from "common/components/EstimatedCosts/CostEstimatorModalActions";
import { BoxDimension } from "./BoxDimensions";
import { hideModal } from "../Modal/ModalActions";
import { RootState } from "RootReducer";

export const costEstimatorModalId = "costEstimator";

export interface CostEstimatorModalHookProps {
  type: EstimatedCostType;
}

export const useCostEstimatorModal = ({ type }: CostEstimatorModalHookProps) => {
  const dispatch = useDispatch();

  const productName = useSelector((state: RootState) => state.costEstimatorModal.productName);
  const dimensions = useSelector((state: RootState) => state.costEstimatorModal.dimensions);
  const areAllInputsValid = useSelector((state: RootState) => state.costEstimatorModal.allInputsValid);

  const onInputChange = ({ currentTarget }: FormEvent<HTMLInputElement>) => {
    let numberValue = Number(currentTarget.value);
    if (isNaN(numberValue)) {
      numberValue = 0;
    }
    dispatch(setFieldValue(currentTarget.dataset.dimension as BoxDimension, numberValue));
  };

  const onEstimateClick = async () => {
    await dispatch(estimateCosts(type));
    dispatch(hideModal(costEstimatorModalId));
  };

  return {
    onInputChange,
    onEstimateClick,
    productName,
    dimensions,
    areAllInputsValid,
  };
};
