export enum TransferListActionTypes {
  TRANSFER_LIST_GET = "TRANSFER_LIST_GET",
  TRANSFER_LIST_GET_SUCCESS = "TRANSFER_LIST_GET_SUCCESS",
  TRANSFER_LIST_GET_ERROR = "TRANSFER_LIST_GET_ERROR",
  TRANSFER_LIST_CHANGE_PAGE_NUM = "TRANSFER_LIST_CHANGE_PAGE_NUM",
  TRANSFER_LIST_CHANGE_SEARCH_TERM = "TRANSFER_LIST_CHANGE_SEARCH_TERM",
  TRANSFER_LIST_CHANGE_DESTINATION_SEARCH_FILTERS = "TRANSFER_LIST_CHANGE_DESTINATION_SEARCH_FILTERS",
  TRANSFER_LIST_CHANGE_STATUS_SEARCH_FILTERS = "TRANSFER_LIST_CHANGE_STATUS_SEARCH_FILTERS",
  DELETE_TRANSFER_ORDER_SUCCESS = "DELETE_TRANSFER_ORDER_SUCCESS",
  TRANSFER_LIST_SET_TAB_FILTER = "TRANSFER_LIST_SET_TAB_FILTER",
  UNSET_SHOW_CHANNEL_REPLENISHMENT_BANNER = "UNSET_SHOW_CHANNEL_REPLENISHMENT_BANNER",
}
