import { ThemeProps, Title, Button, Stack, Text, Notification } from "common/components/ui";
import styled from "@emotion/styled";
import { ElevioButton } from "common/elevio/ElevioButton";

export const NotificationWithMargin = styled(Notification)<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S2};
  `
);

export const ReportLink = styled.a<ThemeProps>(
  ({ theme }) => `
    cursor: pointer;
    margin-top: ${theme.spacing.S5};
    font-weight: ${theme.font.weight.BOLD};
  `
);

export const CenteredTitle = styled(Title)<ThemeProps>(
  ({ theme }) => `
    text-align: center;
    margin-bottom: ${theme.spacing.S6};
    margin-top: ${theme.spacing.S4};
  `
);

export const IconContainer = styled.div`
  text-align: center;
`;

export const ErrorListItem = styled.li<ThemeProps>(
  ({ theme }) => `
      margin-top: ${theme.spacing.S1};
  `
);

export const ContentContainer = styled.div<{ scrollableErrors: boolean; minHeight: number }>(
  ({ minHeight, scrollableErrors }) => `
    flex: 1 0 ${scrollableErrors ? "0" : "auto"};
    display: flex;
    flex-flow: column;
    min-height: ${minHeight}px;
    overflow: ${scrollableErrors ? "auto" : "hidden"};
  `
);

export const SubtitleText = styled(Text)<ThemeProps>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S4};

    a {
      font-weight: ${theme.font.weight.BOLD};
    }
  `
);

export const ModalContainer = styled.div`
  display: flex;
  flex-flow: column;
`;

export const ErrorListContainer = styled.div<{ scrollableErrors: boolean }>(
  ({ scrollableErrors }) => `
    flex: 1 0 auto;
    overflow: hidden;
    max-height: ${scrollableErrors ? "auto" : "200px"};
  `
);

export const FlexElevioButton = styled(ElevioButton)`
  flex: 1;
`;

export const FlexButton = styled(Button)`
  flex: 1;
`;

export const StyledStack = styled(Stack)<ThemeProps>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S7};
  `
);

export const ShowAllLink = styled.a<ThemeProps>(
  ({ theme }) => `
    cursor: pointer;
    display: block;
    font-weight: ${theme.font.weight.BOLD};
    margin-top: ${theme.spacing.S2};
  `
);

export const TextContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S5};
  `
);

export const DropzoneContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S2};
  margin-bottom: ${theme.spacing.S2};
`
);
