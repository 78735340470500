import React from "react";
import { Route } from "react-router-dom";

import { NonComplianceChunk } from "chunks";
import { NavBar, NavBreadcrumb } from "common/components/NavBar";
import RouteParser from "common/RouteParser";
import { Path } from "paths";

const nonComplianceBaseRoute = Path.nonCompliance;

export const nonComplianceRoutes = [
  <Route component={NonComplianceChunk} key={"nonCompliancePage"} path={nonComplianceBaseRoute} />,
];

export const nonComplianceInternalRoutes = {
  summary: nonComplianceBaseRoute,
  unknownBarcode: `${nonComplianceBaseRoute}/unknown-barcode/:nonCompliantId`,
  unknownBarcodeSuccess: `${nonComplianceBaseRoute}/unknown-barcode/:nonCompliantId/success`,
  missingBarcode: `${nonComplianceBaseRoute}/missing-barcode/:nonCompliantId`,
  missingBarcodeSuccess: `${nonComplianceBaseRoute}/missing-barcode/:nonCompliantId/success`,
  viewCompletedCase: `${nonComplianceBaseRoute}/:nonComplianceType/:nonCompliantId`,
};

export const nonComplianceDynamicRoutes = {
  unknownBarcode: new RouteParser(nonComplianceInternalRoutes.unknownBarcode),
  unknownBarcodeSuccess: new RouteParser(nonComplianceInternalRoutes.unknownBarcodeSuccess),
  missingBarcode: new RouteParser(nonComplianceInternalRoutes.missingBarcode),
  missingBarcodeSuccess: new RouteParser(nonComplianceInternalRoutes.missingBarcodeSuccess),
  viewCompletedCase: new RouteParser(nonComplianceInternalRoutes.viewCompletedCase),
};

enum NonCompliancePathNames {
  INBOUNDS = "INBOUNDS",
  PROBLEMS = "PROBLEMS",
  UNKNOWN_BARCODE = "UNKNOWN_BARCODE",
  MISSING_BARCODE = "MISSING_BARCODE",
}

const nonComplianceBreadcrumbs: Record<NonCompliancePathNames, NavBreadcrumb> = {
  [NonCompliancePathNames.INBOUNDS]: { pageName: "Inbounds", path: Path.inboundsList },
  [NonCompliancePathNames.PROBLEMS]: { pageName: "Problems", path: nonComplianceBaseRoute },
  [NonCompliancePathNames.UNKNOWN_BARCODE]: { pageName: "Unknown Barcode" },
  [NonCompliancePathNames.MISSING_BARCODE]: { pageName: "Missing Barcode" },
};

export const nonComplianceHeaderRoutes = [
  <Route
    key={"unknownBarcodeNCHeader"}
    path={nonComplianceInternalRoutes.unknownBarcode}
    render={() => (
      <NavBar
        breadcrumbs={[
          nonComplianceBreadcrumbs.INBOUNDS,
          nonComplianceBreadcrumbs.PROBLEMS,
          nonComplianceBreadcrumbs.UNKNOWN_BARCODE,
        ]}
      />
    )}
  />,
  <Route
    key={"missingBarcodeNCHeader"}
    path={nonComplianceInternalRoutes.missingBarcode}
    render={() => (
      <NavBar
        breadcrumbs={[
          nonComplianceBreadcrumbs.INBOUNDS,
          nonComplianceBreadcrumbs.PROBLEMS,
          nonComplianceBreadcrumbs.MISSING_BARCODE,
        ]}
      />
    )}
  />,
  <Route
    key={"nonComplianceSummaryHeader"}
    path={nonComplianceInternalRoutes.summary}
    render={() => <NavBar breadcrumbs={[nonComplianceBreadcrumbs.INBOUNDS, nonComplianceBreadcrumbs.PROBLEMS]} />}
  />,
];
