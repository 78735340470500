import { Button, Icon, Title } from "common/components/ui";
import Modal from "common/components/Modal";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useConfirmUnsyncModal } from "./useConfirmUnsyncModal";

import cls from "./ConfirmUnsyncModal.less";

export const ConfirmUnsyncModal: FC = () => {
  const { modalId, onCancelClick, onContinueClick } = useConfirmUnsyncModal();

  return (
    <Modal id={modalId} size="md" hasCloseButton={false}>
      <div className={cls.modalContainer}>
        <Title displayAs="h4" className={cls.title}>
          <Icon className={cls.confirmUnsyncIcon} type="exclamation-triangle" />
          <FormattedMessage id="ConfirmUnsyncModal.Title" defaultMessage="Confirm Unsync" />
        </Title>
        <p className={cls.body}>
          <FormattedMessage
            id="ConfirmUnsyncModal.Description"
            defaultMessage="Are you sure you’ve removed all snippets you no longer want from your ads?"
          />
        </p>

        <div className={cls.buttons}>
          <Button secondary onClick={onCancelClick}>
            <FormattedMessage id="Cancel" defaultMessage="Cancel" />
          </Button>
          <Button onClick={onContinueClick}>
            <FormattedMessage id="Continue" defaultMessage="Continue" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
