import { RootState } from "RootReducer";
import { DeliverrLtlShipment } from "inbounds/InboundTypes";
import { FreightPalletValidations } from "inbounds/steps/ship/ltl/util/validations";
import { createSelector } from "reselect";
import { selectLoadedShipmentConfirmedBoxCount } from "../shipments";
import { getPalletTotalDims } from "./getPalletTotalDims";
import { getShipmentLtl } from "./getShipmentLtl";
import {
  FTL_PALLETS_PER_TRUCK,
  LTL_MAX_INDIVIDUAL_PALLET_HEIGHT,
  LTL_MAX_INDIVIDUAL_PALLET_LENGTH,
  LTL_MAX_INDIVIDUAL_PALLET_WEIGHT,
  LTL_MAX_INDIVIDUAL_PALLET_WIDTH,
  LTL_MAX_TOTAL_PALLET_WEIGHT,
  LTL_MIN_TOTAL_PALLET_WEIGHT,
} from "freight/constants/cargoPalletConstants";

/**
 * Checks for dimensions compliance on each individual pallet and on cumulative totals for weight, height and qty
 */
export const selectLtlPalletDimsViolations = createSelector<
  RootState,
  DeliverrLtlShipment,
  number[],
  number,
  FreightPalletValidations[]
>(
  getShipmentLtl,
  getPalletTotalDims, // [sum of all # of pallets, sum of all pallet weight * # of pallets]
  selectLoadedShipmentConfirmedBoxCount,
  ({ numberOfPallets, palletConfigurations }, dims, loadedShipmentConfirmedBoxCount) => {
    const [palletQtySum, palletWeightSum] = dims;
    const {
      PALLET_COUNT_NOT_MATCHING,
      OVER_MAX_PALLET_QTY,
      OVER_MAX_PALLET_TOTAL_WEIGHT,
      PALLETS_OVER_MAX_WIDTH,
      PALLETS_OVER_MAX_LENGTH,
      PALLETS_OVER_MAX_HEIGHT,
      PALLETS_OVER_MAX_WEIGHT,
      UNDER_MINIMUM_TOTAL_WEIGHT,
      MORE_PALLETS_THAN_BOXES,
    } = FreightPalletValidations;

    const validationMap: Record<FreightPalletValidations, boolean> = {
      [PALLET_COUNT_NOT_MATCHING]: palletQtySum > 0 && numberOfPallets > 0 && palletQtySum !== numberOfPallets,
      [OVER_MAX_PALLET_QTY]: Boolean(palletQtySum > FTL_PALLETS_PER_TRUCK),
      [OVER_MAX_PALLET_TOTAL_WEIGHT]: palletWeightSum > LTL_MAX_TOTAL_PALLET_WEIGHT,
      [UNDER_MINIMUM_TOTAL_WEIGHT]: palletWeightSum > 0 && palletWeightSum < LTL_MIN_TOTAL_PALLET_WEIGHT,
      [PALLETS_OVER_MAX_WIDTH]: palletConfigurations.some((pallet) => pallet.width > LTL_MAX_INDIVIDUAL_PALLET_WIDTH),
      [PALLETS_OVER_MAX_LENGTH]: palletConfigurations.some(
        (pallet) => pallet.length > LTL_MAX_INDIVIDUAL_PALLET_LENGTH
      ),
      [PALLETS_OVER_MAX_HEIGHT]: palletConfigurations.some(
        (pallet) => pallet.height > LTL_MAX_INDIVIDUAL_PALLET_HEIGHT
      ),
      [PALLETS_OVER_MAX_WEIGHT]: palletConfigurations.some(
        (pallet) => pallet.weight > LTL_MAX_INDIVIDUAL_PALLET_WEIGHT
      ),
      // with non-case-packed shipments with Prep, we may have no estimated box quantity
      [MORE_PALLETS_THAN_BOXES]: loadedShipmentConfirmedBoxCount > 0 && palletQtySum > loadedShipmentConfirmedBoxCount,
    };

    return Object.values(FreightPalletValidations).filter((violation) => !!validationMap[violation]);
  }
);
