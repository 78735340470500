import styled from "@emotion/styled";
import { DefaultTheme, Title, defaultTheme } from "common/components/ui";
import { Appearance } from "common/components/ui/shared";

export const ModifiedTitle = styled(Title)<
  { space: keyof (typeof defaultTheme)["spacing"] | null; appearance?: keyof typeof Appearance },
  DefaultTheme
>(
  ({ theme, space, appearance }) => `
    margin-bottom: ${space ? theme.spacing[space] : 0};
    margin-top: 0;
    color: ${appearance && theme.colors[theme.config.colorByAppearance[appearance]]["300"]};
  `
);
