import { MessageDescriptor, defineMessages } from "react-intl";
import { ApiTokenScope } from "./ApiTokenScope";

export const apiTokenScopeMessages: Record<ApiTokenScope, MessageDescriptor> = defineMessages({
  products: {
    id: "settings.apiTokens.scopeMessages.products",
    defaultMessage: "Products",
  },
  reports: {
    id: "settings.apiTokens.scopeMessages.reports",
    defaultMessage: "Reports",
  },
  bundles: {
    id: "settings.apiTokens.scopeMessages.bundles",
    defaultMessage: "Bundles",
  },
  orders: {
    id: "settings.apiTokens.scopeMessages.orders",
    defaultMessage: "Orders",
  },
  webhooks: {
    id: "settings.apiTokens.scopeMessages.webhooks",
    defaultMessage: "Webhooks",
  },
  parcels: {
    id: "settings.apiTokens.scopeMessages.parcels",
    defaultMessage: "Parcels",
  },
  returns: {
    id: "settings.apiTokens.scopeMessages.returns",
    defaultMessage: "Returns",
  },
  freight: {
    id: "settings.apiTokens.scopeMessages.freight",
    defaultMessage: "Freight",
  },
  events: {
    id: "settings.apiTokens.scopeMessages.events",
    defaultMessage: "Events",
  },
  inbounds: {
    id: "settings.apiTokens.scopeMessages.inbounds",
    defaultMessage: "Inbounds",
  },
  "orders b2b": {
    id: "settings.apiTokens.scopeMessages.replenishments",
    defaultMessage: "Outbounds",
  },
});
