import { FeatureName, isFeatureOn } from "common/Split";
import { createSelector } from "reselect";
import { selectStorageProductsWithFefoEnabled } from "./selectStorageProductsWithFefoEnabled";
import { selectStorageProductsWithLotTrackingEnabled } from "./selectStorageProductsWithLotTrackingEnabled";

export const selectShouldShowStorageLotInformation = createSelector(
  selectStorageProductsWithFefoEnabled,
  selectStorageProductsWithLotTrackingEnabled,
  (fefoEnabledStorageProducts, lotTrackingEnabledStorageProducts) =>
    isFeatureOn(FeatureName.ReserveStorageLotTracking) &&
    isFeatureOn(FeatureName.FefoExpirationDate) &&
    !isFeatureOn(FeatureName.SkipAddingLotExpiry) &&
    (fefoEnabledStorageProducts?.length > 0 || lotTrackingEnabledStorageProducts?.length > 0)
);
