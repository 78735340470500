import { onboardingClientV2 } from "common/clients/instances";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { ActionCreator, createActionCreator, SPThunkAction } from "common/ReduxUtils";
import { keyBy, mapValues, uniqueId } from "lodash/fp";
import log from "Logger";
import { toast } from "common/components/ui";
import { ShippingOptionZone } from "common/clients/onboarding/ShippingOptionZone";

export enum ShippingOptionActionTypes {
  CREATE_SHIPPING_OPTIONS_START = "CREATE_SHIPPING_OPTIONS_START",
  CREATE_SHIPPING_OPTIONS_SUCCESS = "CREATE_SHIPPING_OPTIONS_SUCCESS",
  CREATE_SHIPPING_OPTIONS_ERROR = "CREATE_SHIPPING_OPTIONS_ERROR",
  FETCH_SHIPPING_OPTIONS_START = "FETCH_SHIPPING_OPTIONS_START",
  FETCH_SHIPPING_OPTIONS_SUCCESS = "FETCH_SHIPPING_OPTIONS_SUCCESS",
  FETCH_SHIPPING_OPTIONS_ERROR = "FETCH_SHIPPING_OPTIONS_ERROR",
  UPDATE_SHIPPING_OPTIONS_START = "UPDATE_SHIPPING_OPTIONS_START",
  UPDATE_SHIPPING_OPTIONS_SUCCESS = "UPDATE_SHIPPING_OPTIONS_SUCCESS",
  UPDATE_SHIPPING_OPTIONS_ERROR = "UPDATE_SHIPPING_OPTIONS_ERROR",
  SHIPPING_OPTION_REMOVE = "SHIPPING_OPTION_REMOVE",
  SHIPPING_OPTION_ADD = "SHIPPING_OPTION_ADD",
  SHIPPING_OPTION_UPDATE = "SHIPPING_OPTION_UPDATE",
}

export const SHIPPING_MAPPING_LOADER_NAME = "shippingOptionMapping";

const withIds = (shippingOptionZones?: ShippingOptionZone[]) => {
  return shippingOptionZones!.map((zone) => ({
    ...zone,
    shippingOptions: zone.shippingOptions.map((option) => ({
      ...option,
      id: option.id ?? Number(uniqueId("")),
    })),
  }));
};

const sorted = (shippingOptionZones?: ShippingOptionZone[]) => {
  return shippingOptionZones!.map((zone) => ({
    ...zone,
    shippingOptions: zone.shippingOptions.sort((x, y) => y.requiredDeliveryInDays - x.requiredDeliveryInDays),
  }));
};

export const addShippingOption = createActionCreator<string>(ShippingOptionActionTypes.SHIPPING_OPTION_ADD, "zoneId");

export const removeShippingOption = createActionCreator<string, string>(
  ShippingOptionActionTypes.SHIPPING_OPTION_REMOVE,
  "zoneId",
  "optionId"
);

export const updateShippingOption: ActionCreator = (
  zoneId: string,
  optionId: string,
  name?: string,
  requiredDeliveryInDays?: number
) => ({
  type: ShippingOptionActionTypes.SHIPPING_OPTION_UPDATE,
  zoneId,
  optionId,
  requiredDeliveryInDays,
  name,
});

export const createShippingOptions = (): SPThunkAction => async (dispatch, getState) => {
  const ctx = { fn: "createShippingOptions", sellerId: "", slsUuid: "" };

  try {
    const {
      user: { sellerId },
      channels: { salesChannels },
    } = getState();
    ctx.sellerId = sellerId;
    log.info(ctx, "creating shipping options");

    dispatch({ type: SHIPPING_MAPPING_LOADER_NAME });
    dispatch({
      type: ShippingOptionActionTypes.CREATE_SHIPPING_OPTIONS_START,
    });

    if (salesChannels.length) {
      const { slsUuid } = salesChannels[0];
      ctx.slsUuid = slsUuid;
      const shippingOptionZones = await onboardingClientV2.createShippingOptionZones(slsUuid);

      dispatch({
        type: ShippingOptionActionTypes.CREATE_SHIPPING_OPTIONS_SUCCESS,
        shippingOptionZones: sorted(withIds(shippingOptionZones)),
      });
      log.info({ ...ctx, shippingOptionZones }, "successfully created shipping option zones");
    }
  } catch (err) {
    log.error({ ...ctx, err }, "error during create shipping options");
    dispatch({
      type: ShippingOptionActionTypes.CREATE_SHIPPING_OPTIONS_ERROR,
      err,
    });
    toast.error(err.code || err.title || err.message, {
      autoClose: 5000,
      toastId: "createShippingOptionsError",
    });
  } finally {
    dispatch({ type: SHIPPING_MAPPING_LOADER_NAME });
  }
};

export const getShippingOptions = (): SPThunkAction => async (dispatch, getState) => {
  const ctx = { fn: "getShippingOptions", sellerId: "", slsUuid: "" };

  try {
    const {
      user: { sellerId },
      channels: { salesChannels },
    } = getState();
    ctx.sellerId = sellerId;
    log.info(ctx, "getting shipping options");

    dispatch(addLoader(SHIPPING_MAPPING_LOADER_NAME));

    if (salesChannels.length) {
      const { slsUuid } = salesChannels[0];
      ctx.slsUuid = slsUuid;

      dispatch({
        type: ShippingOptionActionTypes.FETCH_SHIPPING_OPTIONS_START,
        slsUuid,
      });

      const shippingOptionZones = await onboardingClientV2.getShippingOptionZones(slsUuid);

      dispatch({
        type: ShippingOptionActionTypes.FETCH_SHIPPING_OPTIONS_SUCCESS,
        shippingOptionZones: sorted(withIds(shippingOptionZones)),
      });
      log.info({ ...ctx, shippingOptionZones, withIds }, "successfully retrieved shipping option zones");
    }
  } catch (err) {
    log.error({ ...ctx, err }, "error getting shipping option zones");
    dispatch({
      type: ShippingOptionActionTypes.FETCH_SHIPPING_OPTIONS_ERROR,
      err,
    });
    toast.error(err.code || err.title || err.message, {
      autoClose: 5000,
      toastId: "getShippingOptionZonesError",
    });
  } finally {
    dispatch(clearLoader(SHIPPING_MAPPING_LOADER_NAME));
  }
};

export const updateShippingOptions = (): SPThunkAction => async (dispatch, getState) => {
  const ctx = { fn: "updateShippingOptions", sellerId: "", slsUuid: "" };

  try {
    const {
      user: { sellerId },
      settings: {
        shippingOptionMapping: { shippingOptionZones },
      },
      channels: { salesChannels },
    } = getState();

    ctx.sellerId = sellerId;

    log.info({ ...ctx, shippingOptionZones }, "updating shipping options");

    dispatch(addLoader(SHIPPING_MAPPING_LOADER_NAME));
    dispatch({
      type: ShippingOptionActionTypes.UPDATE_SHIPPING_OPTIONS_START,
    });

    const areAnyEmptyNames = shippingOptionZones.some((zone) =>
      zone.shippingOptions.some((option) => option.name.trim() === "")
    );

    if (areAnyEmptyNames) {
      throw new Error("Cannot have an empty shipping option name.");
    }

    // transform zones to what the API expects zoneName -> optionName -> requiredDeliveryInDays
    const transformedZones = mapValues(
      (zone: ShippingOptionZone) => mapValues("requiredDeliveryInDays", keyBy("name", zone.shippingOptions)),
      keyBy("id", shippingOptionZones)
    );

    const { slsUuid } = salesChannels[0];
    ctx.slsUuid = slsUuid;

    const savedShippingOptionZones = await onboardingClientV2.updateShippingOptionZones(
      slsUuid,
      transformedZones as any
    );

    dispatch({
      type: ShippingOptionActionTypes.UPDATE_SHIPPING_OPTIONS_SUCCESS,
      shippingOptionZones: sorted(shippingOptionZones),
    });

    toast.info("Successfully updated shipping option mapping", {
      autoClose: 5000,
      toastId: "successfullyUpdatedShippingOptionMappingInfo",
    });

    log.info({ ...ctx, savedShippingOptionZones }, "successfully updated shipping option zones");
  } catch (err) {
    log.error({ ...ctx, err }, "error during update shipping options");
    dispatch({
      type: ShippingOptionActionTypes.UPDATE_SHIPPING_OPTIONS_ERROR,
      err,
    });
    toast.error(err.code || err.title || err.message, {
      autoClose: 5000,
      toastId: "updateShippingOptionsError",
    });
  } finally {
    dispatch(clearLoader(SHIPPING_MAPPING_LOADER_NAME));
  }
};
