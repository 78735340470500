import { ThemeProps, Text, TextProps } from "common/components/ui";
import styled from "@emotion/styled";

/** Text wrapper for values that should only be shown to admins in "admin gold" color */
export const AdminValue = styled(Text)<ThemeProps<TextProps>>(
  ({ theme }) => `
  color: ${theme.colors.ORANGE[400]};
  font-weight: ${theme.font.weight.BOLD};
`
);
