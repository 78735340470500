import { format, isWeekend } from "date-fns";
import { DashFullDate } from "./DateFormat";

// lib-commons/packages/dates/src/Holidays.ts
export const DEFAULT_HOLIDAYS = [
  // 2018
  "01-01-2018", // new year day - fixed
  "05-28-2018", // memorial - float
  "07-04-2018", // indep day - fixed
  "09-03-2018", // labor day - float
  "11-22-2018", // thanksgiving - float
  "12-25-2018", // christmas - fixed

  // 2019
  "01-01-2019", // new year day - fixed
  "05-27-2019", // memorial - float
  "07-04-2019", // indep day - fixed
  "09-02-2019", // labor day - float
  "11-28-2019", // thanksgiving - float
  "12-25-2019", // christmas - fixed

  // 2020
  "01-01-2020", // new year day - fixed
  "05-25-2020", // memorial - float
  "07-04-2020", // indep day - fixed
  "09-07-2020", // labor day - float
  "11-26-2020", // thanksgiving - float
  "12-25-2020", // christmas - fixed

  // 2021
  "01-01-2021", // new year day - fixed
  "05-31-2021", // memorial - float
  "07-04-2021", // indep day - fixed
  "07-05-2021", // indep observed - float
  "09-06-2021", // labor day - float
  "11-25-2021", // thanksgiving - float
  "12-25-2021", // christmas - fixed

  // 2022
  "01-01-2022", // new year day - fixed
  "05-30-2022", // memorial - float
  "07-04-2022", // indep day - fixed
  "09-05-2022", // labor day - float
  "11-24-2022", // thanksgiving - float
  "12-25-2022", // christmas - fixed

  // 2023
  "01-01-2023", // new year day - fixed
  "05-29-2023", // memorial - float
  "06-19-2023", // juneteenth - fixed
  "07-04-2023", // indep day - fixed
  "09-04-2023", // labor day - float
  "11-23-2023", // thanksgiving - float
  "12-25-2023", // christmas - fixed

  // 2024
  "01-01-2024", // new year day - fixed
  "05-27-2024", // memorial - float
  "06-19-2024", // juneteenth - fixed
  "07-04-2024", // indep day - fixed
  "09-02-2024", // labor day - float
  "11-28-2024", // thanksgiving - float
  "12-25-2024", // christmas - fixed

  // 2025
  "01-01-2025", // new year day - fixed
  "05-26-2025", // memorial - float
  "06-19-2025", // juneteenth - fixed
  "07-04-2025", // indep day - fixed
  "09-01-2025", // labor day - float
  "11-27-2025", // thanksgiving - float
  "12-25-2025", // christmas - fixed
];

const holidays = DEFAULT_HOLIDAYS;
export const isHolidayOrWeekend = (date: Date) => isWeekend(date) || holidays.includes(format(date, DashFullDate));
