import { useSelector } from "react-redux";
import { useAsyncFn } from "react-use";

import { facebookAccessToken, facebookUserId, isLoggedIntoFacebook } from "../../connect/state/FacebookSelectors";
import { useFacebookLogin } from "../../util/useFacebookLogin";
import { FastTagsLoginOptions, isLoginSuccessful } from "../../model/Auth";
import { useFacebookOnLogin } from "../../util/useFacebookOnLogin";

export const useFacebookLoginButton = () => {
  const isLoggedIn = useSelector(isLoggedIntoFacebook);
  const userId = useSelector(facebookUserId);
  const accessToken = useSelector(facebookAccessToken);
  const { login, logout } = useFacebookLogin();
  const { onSuccessfulLogin } = useFacebookOnLogin();

  const [, onLoginClick] = useAsyncFn(async () => {
    const authResponse = await login(FastTagsLoginOptions);

    if (isLoginSuccessful(authResponse)) {
      await onSuccessfulLogin(authResponse);
    }
  });

  const [, onReconnectClick] = useAsyncFn(async () => {
    await logout();

    const authResponse = await login(FastTagsLoginOptions);

    if (isLoginSuccessful(authResponse)) {
      await onSuccessfulLogin(authResponse);
    }
  });

  return {
    accessToken,
    isLoggedIn,
    onLoginClick,
    onReconnectClick,
    userId,
  };
};
