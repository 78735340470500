import React, { ComponentProps, FC } from "react";
import { FormattedMessage } from "react-intl";
import { Text, TextProps } from "common/components/ui";

type FormattedMessageProps = ComponentProps<typeof FormattedMessage>;

export interface TextRendererProps extends TextProps {
  message: FormattedMessageProps;
  // optionally pass values separately
  values?: FormattedMessageProps["values"];
}

/* allows passing FormattedMessage props to Text component to render a styled and localized message */
export const LocalizedText: FC<TextRendererProps> = ({ message, values, ...textProps }) => (
  <Text {...textProps}>
    <FormattedMessage values={values} {...message} />
  </Text>
);
