import { createActionCreator } from "common/ReduxUtils";

export enum NavBarProgressActionTypes {
  UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE",
}

export const updateCurrentPage = createActionCreator<string, string>(
  NavBarProgressActionTypes.UPDATE_CURRENT_PAGE,
  "pageType",
  "currentStep"
);
