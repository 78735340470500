import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { ApiResponse } from "./ApiResponse/ApiResponse";
import { handleSellerServiceError } from "./handleSellerServiceError";
import { UserRequest } from "./User/UserRequest";
import { AxiosResponse } from "axios";

export type UserClientConfig = ApiClientConfig;

export class UserClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: UserClientConfig = { baseURL: mustBeDefined(process.env.SELLER_SERVICE_URL) }) {
    this.apiClient = new ApiClient({ baseURL: `${baseURL}/v1` });
  }

  async getUsersFromPartialMatch(partialString: string): Promise<ApiResponse | undefined> {
    try {
      return await this.apiClient.get({
        url: "/user/partial_match",
        params: {
          q: partialString,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      handleSellerServiceError("getUserFromPartialMatch", err);
    }
  }

  async getUserFromOrganization(email: string): Promise<ApiResponse | undefined> {
    try {
      return await this.apiClient.get({
        url: "/user",
        params: { email },
        authentication: isAuthenticated,
      });
    } catch (err) {
      handleSellerServiceError("getUserFromOrganization", err);
    }
  }

  async getUserIntercomHash(email: string): Promise<ApiResponse | undefined> {
    try {
      return await this.apiClient.get({
        url: "/user/intercomHash",
        params: { email },
        authentication: isAuthenticated,
      });
    } catch (err) {
      handleSellerServiceError("getUserIntercomHash", err);
    }
  }

  async updateUser(email: string, update: UserRequest): Promise<void> {
    try {
      return await this.apiClient.put({
        url: `/user/update/${email}`,
        body: update,
        authentication: isAuthenticated,
      });
    } catch (err) {
      handleSellerServiceError("updateUser", err);
    }
  }

  async setSurveyResponse(
    surveyName: string,
    surveyVersion: number,
    questions: unknown[]
  ): Promise<ApiResponse | undefined> {
    try {
      return await this.apiClient.put({
        url: `/survey/${surveyName}/${surveyVersion}/response`,
        body: {
          questions,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      handleSellerServiceError("setSurveyResponse", err);
    }
  }

  async getSurveyResponse(surveyName: string, surveyVersion: number): Promise<ApiResponse | undefined> {
    try {
      return await this.apiClient.get({
        url: `/survey/${surveyName}/${surveyVersion}/response`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      handleSellerServiceError("getSurveyResponse", err);
    }
  }

  async deactivateUser(email: string): Promise<ApiResponse | undefined> {
    try {
      return await this.apiClient.delete({
        url: "/user/deactivate",
        params: { email },
        authentication: isAuthenticated,
      });
    } catch (err) {
      handleSellerServiceError("deactivateUser", err);
    }
  }

  async activateUser(email: string): Promise<ApiResponse | undefined> {
    try {
      return await this.apiClient.put({ url: "/user/activate", params: { email }, authentication: isAuthenticated });
    } catch (error) {
      handleSellerServiceError("activateUser", error);

      return;
    }
  }

  async deletePendingUser(email: string): Promise<ApiResponse | undefined> {
    try {
      return await this.apiClient.delete({ url: "/user/delete", params: { email }, authentication: isAuthenticated });
    } catch (error) {
      handleSellerServiceError("deletePendingUser", error);
    }
  }

  async getMfa(): Promise<ApiResponse | undefined> {
    try {
      return await this.apiClient.get({ url: `/user/mfa`, authentication: isAuthenticated });
    } catch (error) {
      handleSellerServiceError("getMfa", error);
    }
  }

  /**
   * For now, since the ApiClient axios http requests have some issues (return only data part but not the whole response, made the ApiResponse type not work properly),
   * we use axios directly here. It's a temporary solution and should be replaced once the ApiClient is fixed.
   *
   */
  async sendEmailVerification(email: string): Promise<AxiosResponse | undefined> {
    try {
      return await this.apiClient.putWithWholeResponse({
        url: `/user/${email}/sendEmailVerification`,
        authentication: isAuthenticated,
      });
    } catch (error) {
      handleSellerServiceError("sendEmailVerification", error);
    }
  }

  async sendAutoEmailVerification(email: string): Promise<AxiosResponse | undefined> {
    try {
      return await this.apiClient.postWithWholeResponse({
        url: `/user/${email}/sendAutoEmailVerification`,
        authentication: isAuthenticated,
      });
    } catch (error) {
      handleSellerServiceError("sendAutoEmailVerification", error);
    }
  }

  async getFlexportUser(email: string): Promise<AxiosResponse | undefined> {
    try {
      return await this.apiClient.getWithWholeResponse({
        url: `/flexportUser`,
        params: { email },
        authentication: isAuthenticated,
      });
    } catch (error) {
      handleSellerServiceError("getFlexportUser", error);
    }
  }

  public async setMfa(mfaMethods: string[], preferredMfa?: string): Promise<ApiResponse | undefined> {
    try {
      return await this.apiClient.put({
        url: `/user/mfa`,
        body: { mfaMethods, preferredMfa },
        authentication: isAuthenticated,
      });
    } catch (error) {
      handleSellerServiceError("setMfa", error);
    }
  }
}
