import { Pack } from "common/clients/storage/Packs/Pack";
import { getNumberOrZeroFromString } from "common/utils/string/getNumberOrZeroFromString";

export function extractPacksDims(product: Pick<Pack, "weight" | "width" | "height" | "length">) {
  return {
    weight: getNumberOrZeroFromString(product?.weight?.toString()),
    height: getNumberOrZeroFromString(product?.height?.toString()),
    length: getNumberOrZeroFromString(product?.length?.toString()),
    width: getNumberOrZeroFromString(product?.width?.toString()),
  };
}
