import { WholesaleOrderDTO, WholesaleShipmentDTO, WholesaleShipmentPatchDTO } from "@deliverr/wholesale-client";
import { wholesaleClient } from "../../Clients";
import { updateExternalAttributesIfNeeded } from "../../transfers/create/actions/patchWholesaleOrder";
import { SPThunkAction } from "../../common/ReduxUtils";

const patchFbaReferenceId = async (shipment: WholesaleShipmentDTO, fbaReferenceId: string, orderId: string) => {
  const patchRequest: WholesaleShipmentPatchDTO = {
    externalAttributes: updateExternalAttributesIfNeeded(shipment, undefined, fbaReferenceId),
  };
  await wholesaleClient.patchShipment(orderId, shipment.id, patchRequest);
};

export const updateFbaReferenceIdForAllShipmentInOrder = (
  fbaReferenceIdMap: Map<string, string>,
  wholesaleOrder: WholesaleOrderDTO
): SPThunkAction => async () => {
  await Promise.all(
    wholesaleOrder.shipments!.map(async (shipment) => {
      const fbaReferenceId = fbaReferenceIdMap.get(shipment.id);
      fbaReferenceId && (await patchFbaReferenceId(shipment, fbaReferenceId, wholesaleOrder.id));
    })
  );
};

export const updateFbaReferenceIdForShipment = (
  fbaReferenceId: string,
  shipment: WholesaleShipmentDTO,
  orderId: string
): SPThunkAction => async () => {
  await patchFbaReferenceId(shipment, fbaReferenceId, orderId);
};
