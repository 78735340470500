import { isEmpty } from "lodash/fp";
import { BarcodeEditCollection } from "./BarcodeEditReducer";

// Persists barcodes in session storage to avoid loosing them for large shipping plan creation
// why this is not in Redux? to avoid barcodes from being lost when seller portal is updated
// why this is not in localstorage? to avoid several issues we already have with it

const storagePrefix = "draft_barcodes";

export function updateBarcodesDraft(dsku: string, collection: BarcodeEditCollection) {
  const validDraftItems = collection.filter(
    (barcodeEditItem) => !barcodeEditItem.locked && !isEmpty(barcodeEditItem.value)
  );

  if (validDraftItems.length) {
    sessionStorage.setItem(`${storagePrefix}_${dsku}`, JSON.stringify(validDraftItems));
    return;
  }

  if (getBarcodesDraft(dsku)) {
    removeBarcodesDraft(dsku);
  }
}

export function getBarcodesDraft(dsku: string): BarcodeEditCollection | undefined {
  const collection = sessionStorage.getItem(`${storagePrefix}_${dsku}`);

  return collection && JSON.parse(collection);
}

export function removeBarcodesDraft(dsku: string) {
  sessionStorage.removeItem(`${storagePrefix}_${dsku}`);
}
