import { getContentType } from "./getContentType";

export const uploadFile = async (url: string, file: File) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/pdf");
  return await fetch(url, {
    method: "PUT",
    headers,
    body: file,
    mode: "cors",
  });
};

export const uploadSheet = async (url: string, file: File, fileExtension: string) => {
  const headers = new Headers();
  const acceptedContentType = getContentType(fileExtension);
  if (acceptedContentType) {
    headers.append("Content-Type", acceptedContentType);
    return await fetch(url, {
      method: "PUT",
      headers,
      body: file,
      mode: "cors",
    });
  } else {
    throw new Error(`Invalid file extension: ${fileExtension}`);
  }
};
