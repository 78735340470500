import { Product } from "@deliverr/commons-clients";
import { ServiceLevel } from "@deliverr/billing-pricer-client";

import { emptyAddress } from "common/AddressUtils";
import { Claim } from "common/clients/claim/Claim/Claim";
import { OrderStatus } from "common/clients/orders/DeliverrOrder/OrderStatus";
import { OrderSummary } from "common/clients/orders/OrderSummary";
import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { OrderDetailActionTypes } from "order/detail/OrderDetailActions";
import { RemovalMethods } from "order/new/ShippingMethods";
import { PackageTrackingDetail } from "./PackageCards/PackageTrackingDetails";

export interface OrderDetailState {
  itemDetails: { [dsku: string]: Product };
  order: OrderSummary & { currency: string };
  estimatedShipTime?: Date;
  serviceLevel?: ServiceLevel | RemovalMethods;
  isLoading: boolean;
  claim?: Claim;
  packageTrackingDetails: PackageTrackingDetail[];
}

export const orderDetailInitialState: OrderDetailState = {
  itemDetails: {},
  packageTrackingDetails: [],
  order: {
    cleanShippingAddress: emptyAddress,
    currency: "usd",
    errorCount: 0,
    cost: 6,
    id: "",
    items: [],
    marketplaceId: "manual",
    marketplaceOrderId: "",
    originalShippingAddress: emptyAddress,
    originalOrderCreationTime: new Date().toISOString(),
    // Typed as date, but is actually a string
    orderIngestionTime: new Date().toISOString() as unknown as Date,
    sellerId: "",
    status: OrderStatus.CREATED,
    shipments: [],
    warnings: [],
    tags: [],
    orderTrackingId: "",
  },
  isLoading: false,
};

const reducers: ReducerDictionary<OrderDetailState> = {
  ...handleSimpleReducerUpdates([
    OrderDetailActionTypes.GET_ITEM_DETAILS_SUCCESS,
    OrderDetailActionTypes.GET_PACKAGE_TRACKING_DETAILS,
  ]),
  [OrderDetailActionTypes.GET_ORDER]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [OrderDetailActionTypes.GET_ORDER_SUCCESS]: (state, action) => ({
    ...state,
    order: { ...state.order, ...action.order },
    estimatedShipTime: action.estimatedShipTime,
    serviceLevel: action.serviceLevel,
    isLoading: false,
    claim: action.claim,
  }),
  [OrderDetailActionTypes.RESET_ORDER]: (state) => ({
    ...state,
    ...orderDetailInitialState,
  }),
  [OrderDetailActionTypes.GET_ORDER_ERROR]: (state) => ({
    ...state,
    loading: false,
  }),
};

export const orderDetailReducer = createReducer<OrderDetailState>(orderDetailInitialState, reducers);
