import { Anchor, Box, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import React from "react";
import { FormattedMessage } from "react-intl";

const SendToAnotherEmailAnchor = styled(Anchor)<ThemeProps>(
  () => `
  cursor: pointer
  `
);

export interface SendToAnotherEmailScreenProps {
  carrierEmail: string;
  sendToAnotherEmailClick: () => void;
}

export const SendToAnotherEmailScreen: React.FC<SendToAnotherEmailScreenProps> = ({
  carrierEmail,
  sendToAnotherEmailClick,
}) => {
  return (
    <Box marginBottom={"S6"}>
      <Box marginBottom={"S4"}>
        <FormattedMessage
          id="storage.inbounds.detail.palletAppointmentInstructions.carrierScheduleAppointmentMessage"
          defaultMessage="An email was sent to your carrier at {email}. Without an appointment, your shipment will be turned away."
          values={{
            email: <b>{carrierEmail}</b>,
          }}
        />
      </Box>
      <SendToAnotherEmailAnchor onClick={sendToAnotherEmailClick}>
        <FormattedMessage
          id={"storage.inbounds.detail.sendToAnotherEmailLink"}
          defaultMessage={"Send to another email"}
        />
      </SendToAnotherEmailAnchor>
    </Box>
  );
};
