export enum ParcelListActionTypes {
  SEARCH_PARCELS_SUCCESS = "SEARCH_PARCELS_SUCCESS",
  SEARCH_PARCELS_ERROR = "SEARCH_PARCELS_ERROR",
  SET_PARCELS_SORT = "SET_PARCELS_SORT",
  SET_CURRENT_PARCEL_SEARCH_TERM = "SET_CURRENT_PARCEL_SEARCH_TERM",
  LOAD_PARCEL_LIST = "LOAD_PARCEL_LIST",
  SET_CURRENT_PARCEL_PAGE = "SET_CURRENT_PARCEL_PAGE",
  SET_PARCEL_DATE_FILTER = "SET_PARCEL_DATE_FILTER",
  SET_PARCEL_STATUS_FILTER = "SET_PARCEL_STATUS_FILTER",
  SET_PARCEL_TAB = "SET_PARCEL_TAB",
  SET_PARCEL_SELECTED_ROWS = "SET_PARCEL_SELECTED_ROWS",
  SET_PARCEL_IS_PAGE_SELECTED = "SET_PARCEL_IS_PAGE_SELECTED",
  SELECT_PARCEL_ROW = "SELECT_PARCEL_ROW",
  UPDATE_FLEX_ID_FOR_PARCELIDS = "UPDATE_FLEX_ID_FOR_PARCELIDS",
  REMOVE_PARCELS_FROM_LIST = "REMOVE_PARCELS_FROM_LIST",
  REMOVE_CSV_PARCELS_FROM_LIST = "REMOVE_CSV_PARCELS_FROM_LIST",
}
