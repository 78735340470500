import AlgoliaService from "common/list/AlgoliaService";

export const algoliaService = AlgoliaService.get({
  indexName: process.env.ALGOLIA_INDEX_ORDERS!,
  searchConfig: {
    hitsPerPage: 20,
    maxValuesPerFacet: 3,
  },
  highlightMatches: true,
});

export const algoliaServiceNoHighlight = AlgoliaService.get({
  indexName: process.env.ALGOLIA_INDEX_ORDERS!,
  searchConfig: {
    hitsPerPage: 20,
    maxValuesPerFacet: 3,
  },
  highlightMatches: false,
});

export const importErrorsAlgoliaService = AlgoliaService.get({
  indexName: process.env.ALGOLIA_INDEX_ORDER_IMPORT_ERRORS!,
  searchConfig: {
    hitsPerPage: 20,
    maxValuesPerFacet: 3,
  },
  highlightMatches: true,
});
