import { ReducerDictionary, createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";

import { SopMinimal } from "@deliverr/returns-client";

export interface ReturnsInspectionConfigState {
  sop: SopMinimal | undefined;
}

export enum ReturnsInspectionConfigActionTypes {
  FETCH_CONFIG_SUCCESS = "FETCH_CONFIG_SUCCESS",
  FETCH_CONFIG_FAILURE = "FETCH_CONFIG_FAILURE",
  RESET_CONFIG = "RESET_CONFIG",
}

export const returnsInspectionConfigInitialState = {
  sop: undefined,
};

const reducers: ReducerDictionary<ReturnsInspectionConfigState> = {
  ...handleSimpleReducerUpdates([
    ReturnsInspectionConfigActionTypes.FETCH_CONFIG_SUCCESS,
    ReturnsInspectionConfigActionTypes.FETCH_CONFIG_FAILURE,
  ]),

  [ReturnsInspectionConfigActionTypes.RESET_CONFIG]: () => ({
    ...returnsInspectionConfigInitialState,
  }),
};

export const returnsInspectionConfigReducer = createReducer<ReturnsInspectionConfigState>(
  returnsInspectionConfigInitialState,
  reducers
);
