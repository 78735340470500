import React, { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Stack, Text, ThemeProps } from "common/components/ui";
import { Product } from "@deliverr/commons-clients";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";

import { OnBlurContainer } from "common/components/OnBlurContainer";
import { DimensionsRowError } from "./DimensionsRowError";
import { DimensionsFormValues } from "../DimensionsFormValues";
import { DimensionMultiplySymbol } from "common/components/DimensionMultiplySymbol";
import { DimensionInput } from "./DimensionInput";
import { DimensionField } from "./DimensionField";
import {
  INBOUND_SPD_MAX_BOX_LONGEST,
  INBOUND_SPD_MAX_BOX_MIDDLE,
  INBOUND_SPD_MAX_BOX_SHORTEST,
  INBOUND_SPD_MAX_BOX_WEIGHT,
} from "inbounds/constants/spdConstants";
import { ProductDimensionsValidator } from "./ProductDimensionsValidator";

interface DimensionsRowProps {
  product: Product;
  getDimensionsError?: ProductDimensionsValidator;
  handleRowChange: (dsku: string, dimensions: Partial<Product>) => void;
  isDimensionOverrideFeatureEnabled?: boolean;
}

const DimensionInputCell = styled.td<{ hasError?: boolean }>(
  ({ hasError }) => `
    ${
      hasError &&
      `
      padding-bottom: 0 !important;
    `
    }
  `
);

const ErrorRow = styled.tr<ThemeProps>(
  ({ theme }) => `
    td {
      padding-top: ${theme.spacing.S2};
    }
  `
);

const CenteredFieldStack = styled(Stack)`
  align-items: center;
  flex-wrap: nowrap;
`;

export const DimensionsRow: FC<DimensionsRowProps> = ({
  product: { dsku, name, msku, caseQty },
  getDimensionsError,
  handleRowChange,
  isDimensionOverrideFeatureEnabled,
}) => {
  const [isRowTouched, setIsRowTouched] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const { watch } = useFormContext<DimensionsFormValues>();

  const rowFields = watch(dsku, {});
  const dimensionsError = getDimensionsError?.(rowFields, isDimensionOverrideFeatureEnabled);
  const hasDimensionsError = isRowTouched && dimensionsError !== undefined;

  const handleBlur = () => {
    setIsRowTouched(true);
    handleRowChange?.(dsku, rowFields);
  };

  return (
    <>
      <OnBlurContainer displayAs="tr" onBlur={handleBlur}>
        <DimensionInputCell hasError={hasDimensionsError}>
          <Stack gap="S1">
            <Text bold>{name}</Text>
            <Text size="caption" appearance="INFO">
              {msku}
              {caseQty
                ? formatMessage(
                    {
                      id: "inbounds.dimensionsRow.dimensionAndWeights.caseQty",
                      defaultMessage: " - {caseQty} {caseQty, plural, one {unit} other {units}} per box",
                    },
                    { caseQty }
                  )
                : ""}
            </Text>
          </Stack>
        </DimensionInputCell>

        <DimensionInputCell hasError={hasDimensionsError}>
          <CenteredFieldStack direction="HORIZONTAL" gap="S2">
            <DimensionInput
              dsku={dsku}
              fieldName={DimensionField.LENGTH}
              showError={isRowTouched}
              registerOptions={{ max: INBOUND_SPD_MAX_BOX_LONGEST }}
              placeholder={formatMessage({
                id: "inbounds.dimensionsRow.lengthFieldPlaceholder",
                defaultMessage: "L",
              })}
            />
            <div>
              <DimensionMultiplySymbol />
            </div>
            <DimensionInput
              dsku={dsku}
              fieldName={DimensionField.WIDTH}
              showError={isRowTouched}
              registerOptions={{ max: INBOUND_SPD_MAX_BOX_MIDDLE }}
              placeholder={formatMessage({
                id: "inbounds.dimensionsRow.widthFieldPlaceholder",
                defaultMessage: "W",
              })}
            />
            <div>
              <DimensionMultiplySymbol />
            </div>
            <DimensionInput
              dsku={dsku}
              fieldName={DimensionField.HEIGHT}
              showError={isRowTouched}
              registerOptions={{ max: INBOUND_SPD_MAX_BOX_SHORTEST }}
              placeholder={formatMessage({
                id: "inbounds.dimensionsRow.heightFieldPlaceholder",
                defaultMessage: "H",
              })}
            />
          </CenteredFieldStack>
        </DimensionInputCell>

        <DimensionInputCell hasError={hasDimensionsError}>
          <DimensionInput
            dsku={dsku}
            fieldName={DimensionField.WEIGHT}
            showError={isRowTouched}
            registerOptions={{ max: INBOUND_SPD_MAX_BOX_WEIGHT }}
            placeholder={formatMessage({
              id: "inbounds.dimensionsRow.weightFieldPlaceholder",
              defaultMessage: "lb",
            })}
          />
        </DimensionInputCell>
      </OnBlurContainer>
      {hasDimensionsError && (
        <ErrorRow>
          <td colSpan={3}>
            <DimensionsRowError error={dimensionsError!} />
          </td>
        </ErrorRow>
      )}
    </>
  );
};
