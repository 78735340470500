import { NotificationType } from "common/clients/notifications/Notification/NotificationType";

export const SupportedNotificationTypes = [
  NotificationType.InboundDiscrepanciesType,
  NotificationType.InboundNonCompliantProductsType,
  NotificationType.OutboundInvalidAddressType,
  NotificationType.BulkUploadSuccessType,
  NotificationType.BulkUploadFailedType,
  NotificationType.BulkUploadPartialType,
  NotificationType.SMBMessageNotificationType,
] as const;

export type SupportedNotificationType = (typeof SupportedNotificationTypes)[number];
