import { Product } from "@deliverr/commons-clients";
import { OrderResponse } from "@deliverr/replenishment-client";
import { forEach, isEmpty } from "lodash";
import { getDefaultPackFromProduct } from "storage/packUtils/getDefaultPackFromProduct";
import { mapReplenishItemsToPackSkus } from "storage/packUtils/mapReplenishItemsToPackSkus";

export function mapReplenishOrderSkusToPackSkus(
  replenishOrder: OrderResponse,
  products: { [dsku: string]: Product }
): OrderResponse {
  replenishOrder = {
    ...replenishOrder,
    orderItems: mapReplenishItemsToPackSkus(replenishOrder.orderItems ?? [], products),
  };

  // Map shipment items and parcel package quantities
  forEach(replenishOrder.shipments, (shipment) => {
    shipment = {
      ...shipment,
      shipmentItems: mapReplenishItemsToPackSkus(shipment.shipmentItems ?? [], products),
    };
  });
  return replenishOrder;
}

export const mapParcelPackageSkuToPackSku = (
  dskuToQtyMap?: { [key: string]: number },
  products?: { [dsku: string]: Product }
) => {
  if (isEmpty(dskuToQtyMap)) {
    return dskuToQtyMap;
  }

  return Object.entries(dskuToQtyMap!).reduce<{ [key: string]: number }>((agg, [dsku, qty]) => {
    const product = products?.[dsku];
    const packDsku = product && !product.packOf ? getDefaultPackFromProduct(product)?.dsku : dsku;
    const productSku = packDsku ?? dsku;
    agg[productSku] = (agg[productSku] ?? 0) + qty;

    return agg;
  }, {});
};
