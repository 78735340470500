import styled from "@emotion/styled";
import { BoxResetProps } from "../BoxReset";
import * as React from "react";
import { ContentElement, DefaultTheme, Responsive, Space } from "../shared";
import { getBoxStyles } from "./getBoxStyles";

export interface BoxProps extends Omit<BoxResetProps, "children"> {
  as?: keyof typeof ContentElement;
  padding?: Responsive<keyof typeof Space | null>;
  paddingTop?: Responsive<keyof typeof Space | null>;
  paddingBottom?: Responsive<keyof typeof Space | null>;
  paddingLeft?: Responsive<keyof typeof Space | null>;
  paddingRight?: Responsive<keyof typeof Space | null>;
}

// let styled components handle the "as" prop for us
const Container = styled.div<BoxProps, DefaultTheme>(
  ({ theme, ...styles }) => `
    ${getBoxStyles(theme, styles)}
  `
);

export const Box: React.FC<BoxProps> = ({ children, as = ContentElement.div, ...styles }) => (
  <Container as={as} {...styles}>
    {children}
  </Container>
);
