import React, { FC } from "react";
import { FormattedPlural, FormattedMessage, FormattedNumber } from "react-intl";

interface UnitCountMessageProps {
  unitCount?: number;
}

export const UnitCountMessage: FC<UnitCountMessageProps> = ({ unitCount = 0 }) => (
  <>
    <FormattedNumber value={unitCount} />{" "}
    <FormattedPlural
      value={unitCount}
      one={<FormattedMessage id="unit" defaultMessage="unit" />}
      other={<FormattedMessage id="units" defaultMessage="units" />}
    />
  </>
);
