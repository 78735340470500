import React from "react";
import { Box, Title, Text } from "common/components/ui";
import { FlexDiv } from "common/components/FlexDiv";
import { FormattedMessage } from "react-intl";
import { TextAlign } from "common/components/ui/shared";

export const StorageDestinationHeader: React.FC = () => (
  <Box marginBottom="S6">
    <FlexDiv justifyContent="center">
      <Title as="h3" displayAs="h4">
        <FormattedMessage id="storage.inbounds.storageDestination.title" defaultMessage="Select destination" />
      </Title>
    </FlexDiv>
    <Text textAlign={TextAlign.center}>
      <FormattedMessage
        id="storage.inbounds.storageDestination.description"
        defaultMessage="Select a Reserve Storage facility."
      />
    </Text>
  </Box>
);
