import { UnresolvedNonComplianceCountResponse } from "@deliverr/replenishment-client";
import { createActionCreator } from "common/ReduxUtils";

export enum ReplenishmentNonComplianceActionTypes {
  REPLENISHMENT_SET_NON_COMPLIANT_ORDERS_COUNT = "REPLENISHMENT_SET_NON_COMPLIANT_ORDERS_COUNT",
}

export const replenishmentNonComplianceSetNonCompliantOrdersCount =
  createActionCreator<UnresolvedNonComplianceCountResponse>(
    ReplenishmentNonComplianceActionTypes.REPLENISHMENT_SET_NON_COMPLIANT_ORDERS_COUNT,
    "unresolvedNonCompliancesCount"
  );
