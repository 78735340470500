import { MessageDescriptor } from "react-intl";
import { ReturnCreateStepId } from "../ReturnCreateStep.types";
import { ShippingLabelRate } from "@deliverr/returns-client";
import { SkuPair } from "@deliverr/commons-objects";

export enum ReturnsActionTypes {
  RESET_RETURN_FORM = "RESET_RETURN_FORM",
  ERROR_STEP_RETURN_FORM = "ERROR_STEP_RETURN_FORM",
  UPDATE_CREATE_RETURN_FORM = "UPDATE_CREATE_RETURN_FORM",
  RETURNS_CREATE_ENABLE_NEXT_STEP = "RETURNS_CREATE_ENABLE_NEXT_STEP",
  RETURNS_CREATE_SUBMIT = "RETURNS_CREATE_SUBMIT",
}

export type SelectedReturnItem = SkuPair & {
  selected: boolean;
  maxItems: number;
};

type ErrorType = Partial<Record<ReturnCreateStepId, MessageDescriptor>>;

export interface ReturnsCreateState {
  formState: {
    currentStep: number;
    errors: ErrorType;
  };
  hideSelectProductStep: boolean;
  orderCompletelyReturned: boolean;
  selectedReturnItems: Record<string, SelectedReturnItem>;
  selectedDestination: number;
  selectedShippingService: number;
  externalShipmentId?: string;
  externalPriceId?: string;
  selectedOrderId?: string;
  showOrderStep?: boolean;
  shippingServiceOptions: ShippingLabelRate[];
  isOrderFromMilitaryAddress?: boolean;
  isOrderFromOutsideUS?: boolean;
}
