import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Button, defaultTheme, Select, SelectProps, SelectOptionType, Text } from "common/components/ui";
import { getBarcodeFormat } from "common/components/ui/utils/BarcodeUtils";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { isNil } from "lodash/fp";

import { NonComplianceSection, TextWithBottomMargin } from "inbounds/non-compliance/components/NonComplianceStyles";
import { SimpleLoader } from "common/components/WithLoader/WithLoader";
import { removeAllEmTags } from "common/StringUtils";
import RouteParser from "common/RouteParser";
import { Path } from "paths";

export interface SelectBarcodeProps {
  onBarcodeSelect: (barcode: { value: string }) => void;
  selectedBarcode?: string;
  barcodes?: string[];
  loading?: boolean;
  onSubmit: () => void;
  isOnSubmitLoading?: boolean;
  dsku?: string;
}

const BarcodeSelect = styled(Select)<SelectProps>`
  max-width: 320px;
`;

export const SelectBarcode: FC<SelectBarcodeProps> = ({
  onBarcodeSelect,
  selectedBarcode,
  barcodes,
  loading,
  onSubmit,
  isOnSubmitLoading,
  dsku,
}) => {
  const { formatMessage } = useIntl();

  if (loading) {
    return <SimpleLoader />;
  }

  const hasBarcodes = !isNil(barcodes) && barcodes.length > 0;

  const barcodeOptions: SelectOptionType[] = hasBarcodes
    ? barcodes!.map((barcode) => ({
        value: barcode,
        label: formatMessage(
          { id: "nonCompliance.product.barcodeWithFormat", defaultMessage: "{barcode} ({barcodeFormat})" },
          {
            barcode: removeAllEmTags(barcode),
            barcodeFormat: getBarcodeFormat(barcode)?.toUpperCase(),
          }
        ),
      }))
    : [];

  return (
    <>
      {hasBarcodes ? (
        <NonComplianceSection>
          <TextWithBottomMargin margin={defaultTheme.spacing.S5}>
            {formatMessage({
              id: "nonCompliance.selectBarcodeStep.description",
              defaultMessage: "Choose the SKU barcode that our fulfillment center will use to relabel your product.",
            })}
          </TextWithBottomMargin>
          <TextWithBottomMargin margin={defaultTheme.spacing.S2}>
            {formatMessage({
              id: "barcode",
              defaultMessage: "Barcode",
            })}
          </TextWithBottomMargin>
          <BarcodeSelect
            onChange={onBarcodeSelect}
            value={barcodeOptions.find(({ value }) => value === selectedBarcode)}
            options={barcodeOptions}
            isSearchable={false}
          />
        </NonComplianceSection>
      ) : (
        <NonComplianceSection>
          <Text>
            {formatMessage(
              {
                id: "nonCompliance.selectBarcodeStep.noBarcodesMessage",
                defaultMessage: "The selected SKU has no barcodes. First {addBarcodeLink} label.",
              },
              {
                addBarcodeLink: (
                  <Link to={new RouteParser(Path.barcodePage).parse({ dsku })}>
                    <Text as="span" bold>
                      {formatMessage({
                        id: "nonCompliance.selectBarcodeStep.addBarcodeLink",
                        defaultMessage: "add a barcode",
                      })}
                    </Text>
                  </Link>
                ),
              }
            )}
          </Text>
        </NonComplianceSection>
      )}
      <NonComplianceSection>
        <Button
          data-testid="continue_button"
          onClick={onSubmit}
          disabled={!hasBarcodes || isOnSubmitLoading}
          loading={isOnSubmitLoading}
        >
          {formatMessage({
            id: "submit",
            defaultMessage: "Submit",
          })}
        </Button>
      </NonComplianceSection>
    </>
  );
};
