import { ProductPrepCategory } from "@deliverr/commons-clients/lib/product/ProductPreparation";
import { sortBy } from "lodash/fp";
import { ProductCollection } from "common/models";
import { ShippingPlanItemDetail } from "inbounds/InboundTypes";
import { useAssignProductCategories } from "inbounds/steps/categories/useAssignProductCategories";
import { Product } from "@deliverr/commons-clients";

const getPlanItemDetails = (bookingProductsWithNoCategories: Product[], productDetails: ProductCollection) => {
  const itemsDetails = bookingProductsWithNoCategories.map(({ dsku }: Product) => {
    const { name, msku, productPreparation, customsInformation, brandedPackaging } = productDetails[dsku];
    return {
      dsku,
      name,
      msku,
      customsInformation,
      brandedPackaging,
      // prevent BP triggering category assignment by providing default of OTHER
      category: brandedPackaging ? ProductPrepCategory.OTHER : productPreparation?.category,
      packagingType: productPreparation?.packagingType,
    };
  }) as unknown as ShippingPlanItemDetail[];
  return sortBy("dsku", itemsDetails);
};

export const useAssignProductCategoriesModal = (
  productsWithNoCategory: Product[],
  productDetails: ProductCollection,
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const planItemDetails = getPlanItemDetails(productsWithNoCategory, productDetails);
  const onClose = () => {
    setShowModal(false);
  };

  const {
    allRowsSelected,
    anyRowsSelected,
    categorySelectOptions,
    hasMissingCategories,
    itemDetails,
    productsSelected,
    selectedRows,
    setCategoryForSelectedItems,
    setItemCategory,
    toggleAllRows,
    toggleRow,
    onNextClick,
  } = useAssignProductCategories(planItemDetails, onClose);

  return {
    allRowsSelected,
    anyRowsSelected,
    categorySelectOptions,
    hasMissingCategories,
    itemDetails,
    productsSelected,
    selectedRows,
    setCategoryForSelectedItems,
    setItemCategory,
    onSave: onNextClick,
    onClose,
    toggleAllRows,
    toggleRow,
  };
};
