import React from "react";
import { InfoIcon } from "common/components/icons";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import { Checkbox, defaultTheme, FormGroup, ThemeProps } from "common/components/ui";
import { LITHIUM_BATTERIES_CHECKLIST } from "../useCargoDetailsStep";
import styled from "@emotion/styled";
import { useController } from "react-hook-form";
import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";
import { LocalizedText } from "common/components/LocalizedText";

const ChecklistContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    display: inline-grid;
    justify-content: start;
    gap: ${theme.spacing.S1};
  `
);

const TooltipIcon = ({ message }: { message: MessageDescriptor }) => (
  <InfoIcon
    messageWidth={200}
    wrap
    placement={"top"}
    message={<FormattedMessage id={message.id} defaultMessage={message.defaultMessage} />}
  />
);

const ChecklistHeader = (
  <LocalizedText
    appearance="INFO"
    message={{
      id: "inbounds.createShipment.lithiumBatteries.title",
      defaultMessage: "What type of battery are you shipping?",
    }}
  />
);

const LITHIUM_BATTERY_INPUTS = [
  CreateShipmentInputName.LITHIUM_BATTERIES_PACKED_LOOSE,
  CreateShipmentInputName.LITHIUM_BATTERIES_PACKED_EQUIPMENT,
  CreateShipmentInputName.LITHIUM_BATTERIES_IN_EQUIPMENT,
  CreateShipmentInputName.LITHIUM_BATTERIES_IN_VEHICLE,
];

const LITHIUM_BATTERY_INPUT_LABELS = {
  [CreateShipmentInputName.LITHIUM_BATTERIES_PACKED_LOOSE]: defineMessages({
    label: {
      id: "inbounds.createShipment.lithiumBatteries.packedLoose",
      defaultMessage: "Packed loose or power banks {tooltip}",
    },
    tooltip: {
      id: "inbounds.createShipment.lithiumBatteries.packedLoose.tooltip",
      defaultMessage: "Standalone batteries or those enclosed in power banks designed for portable charging",
    },
  }),
  [CreateShipmentInputName.LITHIUM_BATTERIES_PACKED_EQUIPMENT]: defineMessages({
    label: {
      id: "inbounds.createShipment.lithiumBatteries.packedEquipment",
      defaultMessage: "Pack with equipment {tooltip}",
    },
    tooltip: {
      id: "inbounds.createShipment.lithiumBatteries.packedEquipment.tooltip",
      defaultMessage: "Products packed with 2 or fewer spare lithium batteries in each retail package",
    },
  }),
  [CreateShipmentInputName.LITHIUM_BATTERIES_IN_VEHICLE]: defineMessages({
    label: {
      id: "inbounds.createShipment.lithiumBatteries.inVehicle",
      defaultMessage: "In battery powered vehicles {tooltip}",
    },
    tooltip: {
      id: "inbounds.createShipment.lithiumBatteries.inVehicle.tooltip",
      defaultMessage: "Products containing lithium batteries installed designed to transport people or cargo",
    },
  }),
  [CreateShipmentInputName.LITHIUM_BATTERIES_IN_EQUIPMENT]: defineMessages({
    label: {
      id: "inbounds.createShipment,lithiumBatteries.inEquipment",
      defaultMessage: "Contained in equipment {tooltip}",
    },
    tooltip: {
      id: "inbounds.createShipment.lithiumBatteries.inEquipment.tooltip",
      defaultMessage: "Products with lithium batteries integrated in to the device as an integral power source ",
    },
  }),
};

const CheckboxItem = ({ name }: { name: CreateShipmentInputName }) => {
  const { field } = useController({ name });
  const { label, tooltip } = LITHIUM_BATTERY_INPUT_LABELS[name];

  return (
    <Checkbox
      label={
        <FormattedMessage
          id={label.id}
          defaultMessage={label.defaultMessage}
          values={{ tooltip: <TooltipIcon message={tooltip} /> }}
        />
      }
      labelStyles={`color: ${defaultTheme.colors.NEUTRAL["500"]}`}
      checked={field.value}
      onChange={field.onChange}
    />
  );
};

export const LithiumBatteryPackagingChecklist = () => {
  const { formatMessage } = useIntl();
  const { watch } = useCreateShipmentFormContext();
  // checking the validation with watch allows us to hide the error
  // the validation that sets and clears the error from state only runs on Next
  const lithiumBatteryPackagingValues = watch("lithiumBatteryPackaging");
  const getHasSelections = (value: Record<string, boolean>) => Object.values(value).some((v) => v);

  const validateHasSelections = () => {
    return !getHasSelections(lithiumBatteryPackagingValues)
      ? formatMessage({
          id: "inbounds.createShipment.lithiumBatteries.selectionRequired",
          defaultMessage: "Please confirm what kind of lithium batteries you are shipping.",
        })
      : undefined;
  };

  // we use a general input for the checklist to validate on Next click whether any of the checkboxes are selected
  const { fieldState } = useController({
    name: LITHIUM_BATTERIES_CHECKLIST,
    rules: {
      validate: {
        required: validateHasSelections,
      },
    },
  });

  const hasSelections = getHasSelections(lithiumBatteryPackagingValues);
  return (
    <FormGroup
      as="div"
      label={ChecklistHeader}
      hasError={fieldState.error && !hasSelections}
      errorMessage={fieldState.error?.message}
    >
      <ChecklistContainer>
        {LITHIUM_BATTERY_INPUTS.map((inputName) => (
          <CheckboxItem key={inputName} name={inputName} />
        ))}
      </ChecklistContainer>
    </FormGroup>
  );
};
