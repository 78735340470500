import { sumBy } from "lodash/fp";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { selectTransferCreate } from "../../store/TransferCreateSelectors";
import { transferCreateChooseOriginWarehouse } from "../../actions";
import { getWarehouseAddressLabel } from "transfers/utils/getWarehouseAddressLabel";
import { getIsLoading } from "common/components/WithLoader/LoadingSelectors";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { STORAGE_NON_CD_LOCATIONS } from "storage/inbounds/StorageNonCDLocationsConstants";

export const useTransferPickStorageWarehouseStep = () => {
  const dispatch = useDispatch();
  const { originStorageWarehouse, availableInventory } = useSelector(selectTransferCreate);
  const { formatMessage } = useIntl();
  const selectedWarehouseOption = originStorageWarehouse?.warehouse?.id;

  const storageWarehouseOptions = availableInventory.map((storageWarehouseInventory) => ({
    value: storageWarehouseInventory.warehouse.id,
    label: getWarehouseAddressLabel(storageWarehouseInventory?.warehouse),
    content: formatMessage(transfersShipmentCreateLabels.steps.origin.options.productAvailable, {
      qty: sumBy((dskuQty) => dskuQty.qty ?? 0, storageWarehouseInventory.dskuQuantities),
    }),
  }));

  const onStorageWarehouseSelect = (evt) => {
    const selectedWarehouseId = evt.currentTarget.value;
    const selectedStorageWarehouseInventory = availableInventory.find(
      (warehouseInventory) => warehouseInventory.warehouse.id === selectedWarehouseId
    );
    dispatch(transferCreateChooseOriginWarehouse(selectedStorageWarehouseInventory));
  };

  const shouldShowNonCDLocationWarning =
    selectedWarehouseOption && STORAGE_NON_CD_LOCATIONS.includes(selectedWarehouseOption);

  const isNextLoading = useSelector(getIsLoading)(CreateTransferLoader.UpdateTransferOrderLoader);

  return {
    storageWarehouseOptions,
    onStorageWarehouseSelect,
    selectedWarehouseOption,
    isNextLoading,
    shouldShowNonCDLocationWarning,
  };
};
