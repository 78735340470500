import { CarrierType } from "@deliverr/storage-client";
import { MessageDescriptor, defineMessages } from "react-intl";

interface CarrierTypeMessages {
  label: MessageDescriptor;
  content: MessageDescriptor;
  carrierName: MessageDescriptor;
}

export const CARRIER_TYPE_MESSAGES: Record<CarrierType, CarrierTypeMessages> = {
  [CarrierType.DELIVERR]: defineMessages({
    label: {
      id: "storage.inbounds.create.carrierType.deliverr.label",
      defaultMessage: "Flexport partner",
    },
    content: {
      id: "storage.inbounds.create.carrierType.deliverr.content",
      defaultMessage: "Hassle free, transparent transportation",
    },
    carrierName: {
      id: "storage.inbounds.create.carrierType.deliverr.carrier",
      defaultMessage: "Flexport",
    },
  }),
  [CarrierType.OTHER]: defineMessages({
    label: {
      id: "storage.inbounds.create.carrierType.other.label",
      defaultMessage: "Your carrier",
    },
    content: {
      id: "storage.inbounds.create.carrierType.other.content",
      defaultMessage: "Use your own transportation",
    },
    carrierName: {
      id: "storage.inbounds.create.carrierType.other.carrier",
      defaultMessage: "Other",
    },
  }),
};
