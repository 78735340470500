import React from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { CellProps, Column } from "react-table";
import { Icon, LocalizedMessage } from "common/components/ui";

import { EstimatedTotalChargeColumn, ESTIMATED_TOTAL_CHARGE_TABLE_LABELS } from "./types";
import { useCurrencyValue } from "common/components/Currency";
import { TooltipWithIcon } from "common/components/icons/TooltipWithIcon";

interface EstimatedTotalChargeTableProps {
  type?: LocalizedMessage;
  perUnitCharge?: number;
  units?: number;
  total?: number; // override perUnitCharge * units as the total amount
  perUnitChargeTooltip?: JSX.Element;
  labels?: Record<keyof EstimatedTotalChargeColumn, MessageDescriptor>;
}

export const useEstimatedTotalChargeTable = ({
  type,
  perUnitCharge = 0,
  units = 0,
  total,
  perUnitChargeTooltip,
  labels,
}: EstimatedTotalChargeTableProps) => {
  const { formatNumber, formatMessage } = useIntl();
  const formatCurrency = useCurrencyValue();

  const TextCell = ({ value }: CellProps<EstimatedTotalChargeColumn>) => value;
  const NumberCell = ({ value }: CellProps<EstimatedTotalChargeColumn>) => formatNumber(value);
  const CurrencyCell = ({ value }: CellProps<EstimatedTotalChargeColumn>) => formatCurrency(value);

  const amount = total ?? perUnitCharge * units;
  const columnsLabels = labels ?? ESTIMATED_TOTAL_CHARGE_TABLE_LABELS;

  const columns: Column<EstimatedTotalChargeColumn>[] = [
    ...(type
      ? [
          {
            id: "type",
            accessor: "type",
            Header: formatMessage(ESTIMATED_TOTAL_CHARGE_TABLE_LABELS.type),
            Cell: TextCell,
          },
        ]
      : []),
    {
      id: "units",
      accessor: "units",
      Header: formatMessage(columnsLabels.units),
      Cell: NumberCell,
    },
    {
      id: "perUnitCharge",
      accessor: "perUnitCharge",
      Header: (
        <>
          {formatMessage(columnsLabels.perUnitCharge)}{" "}
          {perUnitChargeTooltip && (
            <TooltipWithIcon message={perUnitChargeTooltip} placement="top">
              <Icon type="question-circle-alt" size="sm" />
            </TooltipWithIcon>
          )}
        </>
      ),
      Cell: CurrencyCell,
    },
    {
      id: "amount",
      accessor: "amount",
      Header: amount >= 0 ? formatMessage(columnsLabels.totalCharge) : formatMessage(columnsLabels.totalReimbursement),
      Cell: CurrencyCell,
    },
  ];

  const data: EstimatedTotalChargeColumn[] = [
    {
      units,
      perUnitCharge,
      amount,
    },
  ];

  if (type) {
    data[0].type = type;
  }

  return {
    data,
    columns,
  };
};
