import { default as search, SearchClient } from "algoliasearch";
import helper from "algoliasearch-helper";
import { fromPairs, isArray, isEmpty, isPlainObject, keyBy, memoize } from "lodash/fp";
import { isProdEnv } from "common/Config";
import { Sort } from "common/models";
import { retry } from "common/utils/Retry";
import store from "store";
import { algoliaSearchKeyManager } from "./AlgoliaSearchKeyManager";
import { getSortByIndex } from "./SortByIndex";

const algoliaRetryCalls = 3;

interface SearchCredentials {
  sellerId: string;
  searchKey?: string;
  algoliaUser: string;
}

interface SearchConfig {
  hitsPerPage: number;
  disjunctiveFacets?: string[];
  maxValuesPerFacet?: number;
  highlightMatches?: boolean;
}

export interface ServiceConfig {
  indexName: string;
  searchConfig: SearchConfig;
  highlightMatches?: boolean;
}

export interface SearchResults {
  response: helper.SearchResults;
  hits: any[];
  params: helper.SearchParameters;
}

export type NumericFilter = [string, helper.SearchParameters.Operator | undefined, number | number[] | undefined];

const getNestedValues = (obj, propName) => {
  if (Object.prototype.hasOwnProperty.call(obj, propName)) {
    return obj[propName];
  }
  if (isPlainObject(obj)) {
    return fromPairs(Object.entries(obj).map(([key, val]) => [key, getNestedValues(val, propName)]));
  } else if (isArray(obj)) {
    return obj.map((arrayElement) => {
      return getNestedValues(arrayElement, propName);
    });
  }
};

class AlgoliaService {
  public static get: Algolia = (memoize as any).convert({ fixed: false })(
    (cfg: ServiceConfig) => new AlgoliaService(cfg),
    JSON.stringify
  );

  public config: ServiceConfig;
  public client: SearchClient;
  public helper?: helper.AlgoliaSearchHelper;
  public baseIndex: string;
  public highlightMatches: boolean = false;
  private currentSearchKey: string;

  public defaultSearchConfig: SearchConfig = {
    hitsPerPage: 5,
  };

  constructor(config: ServiceConfig) {
    this.config = config;
    this.highlightMatches = config.highlightMatches ?? false;
    this.baseIndex = config.indexName;
  }

  public clearCache() {
    this.helper?.clearCache();
    return this;
  }

  public async search(
    query: string,
    pageNum: number = 0,
    sortBy?: Sort,
    filters: string[] = [],
    numericFilter?: NumericFilter,
    resultsSize?: number,
    restrictSearchableAttributes?: string[],
    attributesToRetrieve?: string[]
  ): Promise<SearchResults> {
    let targetIndex = this.baseIndex;
    const credentials = await this.getCredentials();

    if (sortBy) {
      targetIndex = getSortByIndex(sortBy, targetIndex);
    }

    if (!credentials.searchKey) {
      throw new Error(`Search key missing for ${credentials.sellerId}`);
    }

    this.createClient(credentials.searchKey, credentials);

    if (!this.helper) {
      throw new Error(`Search client not created for ${credentials.sellerId}`);
    }

    if (numericFilter) {
      const [attribute, operator, value] = numericFilter;

      if (!operator || !value) {
        this.helper.removeNumericRefinement(attribute);
      } else {
        this.helper.addNumericRefinement(attribute, operator, value);
      }
    }

    const searchHelper = this.helper
      .setIndex(targetIndex)
      .setQuery(query)
      .setQueryParameter("filters", filters.join(" AND "))
      .setPage(pageNum);
    return await retry(
      async () =>
        await this.onSearchResults(
          await searchHelper.searchOnce({
            attributesToRetrieve,
            restrictSearchableAttributes,
            hitsPerPage: resultsSize,
          })
        ),
      {
        retries: algoliaRetryCalls,
      }
    );
  }

  public async searchByIds(
    ids: Array<number | string>,
    query: string = "",
    idField: string = "id",
    resultsSize?: number
  ) {
    const filter = isEmpty(ids)
      ? ""
      : `(${ids.map((id) => (typeof id === "number" ? `${idField}=${id}` : `${idField}:"${id}"`)).join(" OR ")})`;

    return await this.search(query, 0, undefined, [filter], undefined, resultsSize);
  }

  private processResults(hits) {
    return hits.map((hit) => ({
      ...hit,
      raw: { ...hit },
    }));
  }

  private async onSearchResults(response): Promise<SearchResults> {
    const { content, state } = response;
    // Important to clone hits here because Algolia will cache objects
    let hits = this.processResults(content.hits);
    if (state.query && this.highlightMatches) {
      // Copy highlighted HTML out into primary values
      hits = hits
        .filter((hit) => hit._highlightResult)
        .map((hit) => {
          return {
            ...hit,
            ...getNestedValues(hit._highlightResult, "value"),
          };
        });
    }
    return await Promise.resolve({
      response: content,
      hits,
      params: state,
    });
  }

  private async ensureClientInstantiated(): Promise<void> {
    const credentials = await this.getCredentials();

    const isCurrentSearchKeyValid = this.currentSearchKey === credentials.searchKey;
    if (this.client && isCurrentSearchKeyValid) {
      return;
    }

    if (!credentials.searchKey) {
      throw new Error(`Search key missing for ${credentials.sellerId}`);
    }
    this.createClient(credentials.searchKey, credentials);
  }

  public async getRecordByObjectId<T>(objectId: string) {
    await this.ensureClientInstantiated();
    return await this.client.initIndex(this.config.indexName).getObject<T>(objectId);
  }

  public async getAllRecordsByObjectIds<T>(objectIds: string[]) {
    await this.ensureClientInstantiated();
    const { results } = await this.client.initIndex(this.config.indexName).getObjects<T>(objectIds);
    return keyBy("objectID", results);
  }

  private async getCredentials(): Promise<SearchCredentials> {
    const {
      user: { sellerId },
    } = store.getState();

    const envPrefix = isProdEnv ? "prod" : "staging";
    const algoliaUser = `${envPrefix}-${sellerId}`;

    const searchKey = await algoliaSearchKeyManager.getAlgoliaSearchKey(sellerId);

    return {
      sellerId,
      searchKey,
      algoliaUser,
    };
  }

  private createClient(searchKey: string, credentials: SearchCredentials) {
    const headers = {
      "X-Algolia-User-ID": credentials.algoliaUser,
    };
    const appId = process.env.ALGOLIA_MCM_APP_ID;

    this.client = search(appId ?? "MISSING_APP_ID", searchKey ?? "MISSING_API_KEY", {
      headers,
    });
    this.currentSearchKey = searchKey;

    this.helper = helper(this.client, this.config.indexName, {
      ...this.defaultSearchConfig,
      ...this.config.searchConfig,
    });
  }
}

type Algolia = (cfg: ServiceConfig) => AlgoliaService;
export default AlgoliaService;
