import React from "react";

import {
  DamagedProductProblemRow,
  MissingBarcodeProblemRow,
  RelabeledBoxProblemRow,
  ProblemRowData,
  ShortShipProblemRow,
  UnexpectedBarcodeProblemRow,
  UnknownBarcodeProblemRow,
} from "common/components/ProblemsTable/types";
import {
  ShipmentProblemRelabeledBoxBlade,
  ShipmentProblemShortShipBlade,
  ShipmentProblemUnknownBarcodeBlade,
  ShipmentProblemMissingBarcodeBlade,
  ShipmentProblemGenericIssueBlade,
} from ".";
import { ProblemType } from "common/problems/ProblemType";
import { logError } from "Logger";
import { ShipmentProblemUnexpectedBarcodeBlade } from "./ShipmentProblemUnexpectedBarcodeBlade";
import { ShipmentProblemDamagedProductBlade } from "./ShipmentProblemDamagedProductBlade";
import { SelectedShipmentProblem } from "../context";

const getBladeByProblem = (problem: ProblemRowData, props: Omit<ShipmentProblemsBladeProps, "shipmentProblem">) => {
  const {
    damagedProductRefetch,
    missingBoxesRefetch,
    hideShipmentProblemBlade,
    setSelectedProblem,
    isShipmentProblemBladeOpen,
    selectedProblemCase,
  } = props;

  if (!problem.isLegacyCase) {
    return (
      <ShipmentProblemGenericIssueBlade
        shipmentProblem={problem}
        hideShipmentProblemBlade={hideShipmentProblemBlade}
        setSelectedProblem={setSelectedProblem}
        isShipmentProblemBladeOpen={isShipmentProblemBladeOpen}
      />
    );
  }

  switch (problem.problemType) {
    case ProblemType.RELABELED_BOX:
      return (
        <ShipmentProblemRelabeledBoxBlade
          shipmentProblem={problem as RelabeledBoxProblemRow}
          hideShipmentProblemBlade={hideShipmentProblemBlade}
          setSelectedProblem={setSelectedProblem}
          isShipmentProblemBladeOpen={isShipmentProblemBladeOpen}
          selectedProblemCase={selectedProblemCase}
        />
      );
    case ProblemType.UNKNOWN_BARCODE: {
      return (
        <ShipmentProblemUnknownBarcodeBlade
          shipmentProblem={problem as UnknownBarcodeProblemRow}
          hideShipmentProblemBlade={hideShipmentProblemBlade}
          setSelectedProblem={setSelectedProblem}
          isShipmentProblemBladeOpen={isShipmentProblemBladeOpen}
        />
      );
    }
    case ProblemType.MISSING_BARCODE:
      return (
        <ShipmentProblemMissingBarcodeBlade
          shipmentProblem={problem as MissingBarcodeProblemRow}
          hideShipmentProblemBlade={hideShipmentProblemBlade}
          setSelectedProblem={setSelectedProblem}
          isShipmentProblemBladeOpen={isShipmentProblemBladeOpen}
        />
      );
    case ProblemType.SHORT_SHIP_BOXES:
      return (
        <ShipmentProblemShortShipBlade
          shipmentProblem={problem as ShortShipProblemRow}
          refetch={missingBoxesRefetch}
          hideShipmentProblemBlade={hideShipmentProblemBlade}
          setSelectedProblem={setSelectedProblem}
          isShipmentProblemBladeOpen={isShipmentProblemBladeOpen}
          selectedProblemCase={selectedProblemCase}
        />
      );
    case ProblemType.UNEXPECTED_BARCODE:
      return (
        <ShipmentProblemUnexpectedBarcodeBlade
          shipmentProblem={problem as UnexpectedBarcodeProblemRow}
          hideShipmentProblemBlade={hideShipmentProblemBlade}
          setSelectedProblem={setSelectedProblem}
          isShipmentProblemBladeOpen={isShipmentProblemBladeOpen}
        />
      );
    case ProblemType.DAMAGED_PRODUCT:
      return (
        <ShipmentProblemDamagedProductBlade
          shipmentProblem={problem as DamagedProductProblemRow}
          refetch={damagedProductRefetch}
          hideShipmentProblemBlade={hideShipmentProblemBlade}
          setSelectedProblem={setSelectedProblem}
          isShipmentProblemBladeOpen={isShipmentProblemBladeOpen}
        />
      );
    default:
      logError({ fn: "getBladeByProblem", problem }, "Failed to get Blade for an unrecognized blade type");
      return null;
  }
};

export interface ShipmentProblemsBladeProps {
  shipmentProblem?: ProblemRowData;
  damagedProductRefetch: (...args: any[]) => Promise<any>;
  missingBoxesRefetch: (...args: any[]) => Promise<any>;
  hideShipmentProblemBlade: () => void;
  setSelectedProblem: (problem: SelectedShipmentProblem | undefined) => void;
  isShipmentProblemBladeOpen: boolean;
  selectedProblemCase: ProblemRowData | undefined;
}

export const ShipmentProblemBlade = (props: ShipmentProblemsBladeProps) => {
  const { shipmentProblem, ...rest } = props;
  return shipmentProblem ? getBladeByProblem(shipmentProblem, rest) : null;
};
