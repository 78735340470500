import { ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import TimePicker from "react-datepicker";
import React, { FC } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

// stylesheet needed for react-timepicker component
import "react-datepicker/dist/react-datepicker.css";
import { EARLIEST_PICKUP_TIME, LATEST_PICKUP_TIME } from "common/utils/constants/FacilityOperatingHoursConstants";

const TimePickerContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  li.react-datepicker__time-list-item {
    margin: ${theme.spacing.S1};
    color: ${theme.colors.NEUTRAL[500]} !important;
  }
  li.react-datepicker__time-list-item--disabled {
    color: ${theme.colors.NEUTRAL[200]} !important;
  }
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  .react-datepicker-popper {
    width: 100%;
  }
  .react-datepicker--time-only {
    width: 100%;
  }
  li.react-datepicker__time-list-item--selected {
    background-color: ${theme.colors.NEUTRAL[40]} !important;
    font-weight: normal !important;
    color: ${theme.colors.NEUTRAL[500]} !important;
  }
  input {
    color: ${theme.colors.NEUTRAL[500]} !important;
  }
  `
);

interface TimePickerProps {
  onChange: (e: any) => void;
  input: UseFormRegisterReturn;
  selected: any;
  minTime?: Date;
  maxTime?: Date;
}

export const StyledTimePicker: FC<TimePickerProps> = ({
  onChange,
  input,
  selected,
  minTime = EARLIEST_PICKUP_TIME,
  maxTime = LATEST_PICKUP_TIME,
}) => {
  return (
    <TimePickerContainer>
      <TimePicker
        {...input}
        selected={selected}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={60}
        dateFormat="h:mm aa"
        onChange={onChange}
        label="Time"
        minTime={minTime}
        maxTime={maxTime}
      />
    </TimePickerContainer>
  );
};
