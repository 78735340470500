import React from "react";
import { Pill, PillColors } from "common/components/ui";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { OrderStatus } from "@deliverr/replenishment-client";
import { replenishmentOrderLabels } from "./replenishmentOrder.labels";

const pillColorForUnknownStatus = PillColors.GRAY;
const pillDescriptionForUnknownStatus = replenishmentOrderLabels.status.unknown;

const pillColorMap: Record<OrderStatus, PillColors> = {
  [OrderStatus.CREATED]: PillColors.GRAY,
  [OrderStatus.DRAFT]: PillColors.GRAY,
  [OrderStatus.SCHEDULED]: PillColors.GRAY,
  [OrderStatus.SUBMITTED]: PillColors.BLUE,
  [OrderStatus.PROCESSING]: PillColors.BLUE,
  [OrderStatus.READY_TO_SHIP]: PillColors.BLUE,
  [OrderStatus.SHIPPED]: PillColors.GREEN,
  [OrderStatus.ARCHIVED]: PillColors.GRAY,
  [OrderStatus.CANCELLED]: PillColors.GRAY,
  [OrderStatus.ARRIVED]: PillColors.PURPLE,
  [OrderStatus.DELIVERED]: PillColors.GREEN,
  [OrderStatus.RECEIVING]: PillColors.PURPLE,
  [OrderStatus.COMPLETED]: PillColors.GREEN,
  [OrderStatus.ERROR]: PillColors.RED,
};

export const ReplenishmentOrderStatusLabels: Record<OrderStatus, MessageDescriptor> = {
  [OrderStatus.CREATED]: replenishmentOrderLabels.status.draft,
  [OrderStatus.DRAFT]: replenishmentOrderLabels.status.draft,
  [OrderStatus.SCHEDULED]: replenishmentOrderLabels.status.scheduled,
  [OrderStatus.SUBMITTED]: replenishmentOrderLabels.status.submitted,
  [OrderStatus.PROCESSING]: replenishmentOrderLabels.status.processing,
  [OrderStatus.READY_TO_SHIP]: replenishmentOrderLabels.status.readyToShip,
  [OrderStatus.SHIPPED]: replenishmentOrderLabels.status.shipped,
  [OrderStatus.DELIVERED]: replenishmentOrderLabels.status.delivered,
  [OrderStatus.ARRIVED]: replenishmentOrderLabels.status.arrived,
  [OrderStatus.RECEIVING]: replenishmentOrderLabels.status.receiving,
  [OrderStatus.COMPLETED]: replenishmentOrderLabels.status.completed,
  [OrderStatus.ARCHIVED]: replenishmentOrderLabels.status.archived,
  [OrderStatus.CANCELLED]: replenishmentOrderLabels.status.cancelled,
  [OrderStatus.ERROR]: replenishmentOrderLabels.status.error,
};

interface Props {
  status: OrderStatus;
}

export const ReplenishmentOrderStatusPill: React.FC<Props> = ({ status }) => {
  const color = status ? pillColorMap[status] ?? pillColorForUnknownStatus : pillColorForUnknownStatus;

  return (
    <>
      <Pill color={color}>
        <FormattedMessage {...(ReplenishmentOrderStatusLabels[status] ?? pillDescriptionForUnknownStatus)} />
      </Pill>
    </>
  );
};
