import Tooltip from "rc-tooltip";
import React from "react";

import { Product } from "@deliverr/commons-clients";
import cls from "./BarcodeConfirmModalDetails.less";

interface BarcodeConfirmModalDetailsProps {
  product: Product;
}

export const BarcodeConfirmModalDetails: React.ComponentType<BarcodeConfirmModalDetailsProps> = ({ product }) => (
  <div className={cls.itemDetails}>
    <Tooltip placement="right" trigger={["hover"]} overlay={<div className={cls.nameTooltip}>{product.name}</div>}>
      <div className={cls.primaryInfo}>{product.name}</div>
    </Tooltip>
    <div className={cls.subInfo}>{product.msku}</div>
  </div>
);
