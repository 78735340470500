import { batch, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  addPalletLineItem,
  removePalletLineItem,
  updatePalletLineItem,
} from "../actions/StorageCargoPalletDetailsActions";
import { getStorageInboundCreate } from "storage/inbounds/create/store/selector/getStorageInboundCreate";
import { LengthUnit, WeightUnit } from "@deliverr/commons-objects";
import { PalletDetails } from "@deliverr/storage-client";
import { InboundFreightPalletSetup } from "@deliverr/legacy-inbound-client";
import { sum, sumBy } from "lodash/fp";
import { getStorageCargoPalletValidationErrorMessages } from "../actions/getStorageCargoPalletValidationErrorMessages";
import {
  STORAGE_INBOUND_MAX_CASE_LONGEST_IN,
  STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
  STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
  STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS,
} from "storage/inbounds/CaseDimensionsConstants";
import { getStorageCargoDimsCompliance } from "../actions/getStorageCargoDimsCompliance";
import { setIsCalculateRateDisabled } from "../actions/setIsCalculateRateDisabled";
import { FREIGHT_LTL_PALLET_LIMIT } from "storage/inbounds/create/cargoType/useStorageCargoType";
import { STORAGE_MAX_FTL_PALLETS } from "storage/inbounds/create/cargoType/CargoTypeConstants";
import { STORAGE_MAX_FTL_CARGO_WEIGHT } from "../../../SelectShippingConstants";
import { setTotalCargoWeight } from "../actions/setTotalCargoWeight";

export const useStoragePalletTable = () => {
  const { numOfPallets, palletConfigurations, totalCargoWeight } = useSelector(getStorageInboundCreate);

  const [hasMaxConfig, setHasMaxConfig] = useState<boolean>(false);
  const [hasPalletsLocked, setHasPalletsLocked] = useState<boolean>(false);
  const [palletSum, setPalletSum] = useState<number>(0);
  const [weightSum, setWeightSum] = useState<number>(0);
  const [isTotalCargoWeightError, setIsTotalCargoWeightError] = useState<boolean>(false);

  const complianceValidations = useSelector(getStorageCargoDimsCompliance);
  const { palletDimsCompliant, palletDimsViolations } = complianceValidations;
  const palletValidationMap = useSelector(getStorageCargoPalletValidationErrorMessages);

  const dispatch = useDispatch();

  useEffect(() => {
    const isValidNumOfPallets = numOfPallets && numOfPallets <= STORAGE_MAX_FTL_PALLETS;
    const isFtl = numOfPallets && numOfPallets > FREIGHT_LTL_PALLET_LIMIT;

    const isCalculateRateDisabled = !isValidNumOfPallets || (isFtl ? isTotalCargoWeightError : !palletDimsCompliant);
    dispatch(setIsCalculateRateDisabled(isCalculateRateDisabled));
  }, [palletDimsCompliant, numOfPallets, isTotalCargoWeightError]);

  useEffect(() => {
    const currentPalletCount = sumBy("palletCount", palletConfigurations);
    const currentWeightSum = sum(
      palletConfigurations?.map(
        (palletConfiguration) => (palletConfiguration.palletCount ?? 0) * (palletConfiguration.weight ?? 0)
      )
    );

    batch(() => {
      setPalletSum(currentPalletCount);
      setWeightSum(currentWeightSum);

      setHasMaxConfig(currentPalletCount >= (numOfPallets ?? 1));
      setHasPalletsLocked(currentPalletCount === (numOfPallets ?? 1));
    });
  }, [palletConfigurations, numOfPallets]);

  const transformToInboundFreightPalletSetup = (
    palletDetails: Partial<PalletDetails>
  ): Partial<InboundFreightPalletSetup> => {
    const { palletCount, lengthUnit, ...partialPalletDetails } = palletDetails;
    return { ...partialPalletDetails, identicalPallets: palletCount, dimensionUnit: lengthUnit };
  };

  const newPalletConfigurationItem = {
    palletCount: 1,
    length: STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
    width: STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
    height: STORAGE_INBOUND_MAX_CASE_LONGEST_IN,
    weight: STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS,
    lengthUnit: "in" as LengthUnit,
    weightUnit: "lb" as WeightUnit,
  };

  const onTotalCargoWeightUpdate = (event): void => {
    dispatch(setTotalCargoWeight(parseInt(event.target.value)));
    setIsTotalCargoWeightError(parseInt(event.target.value) > STORAGE_MAX_FTL_CARGO_WEIGHT);
  };

  return {
    palletSum,
    weightSum,
    palletConfigurations: palletConfigurations?.map((palletConfiguration) =>
      transformToInboundFreightPalletSetup(palletConfiguration)
    ) ?? [transformToInboundFreightPalletSetup(newPalletConfigurationItem)],
    hasChangesLocked: false,
    hasMaxConfig,
    hasPalletsLocked,
    addPalletConfig: () => addPalletLineItem(newPalletConfigurationItem),
    removePalletConfig: removePalletLineItem,
    updatePalletConfig: updatePalletLineItem,
    palletDimsCompliant,
    palletDimsViolations,
    palletValidationMap,
    totalCargoWeight,
    onTotalCargoWeightUpdate,
    isTotalCargoWeightError,
    isPalletTableDisabled: (numOfPallets ?? 1) > FREIGHT_LTL_PALLET_LIMIT,
  };
};
