import { DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import { LocationDescriptor } from "history";
import { generate } from "shortid";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { NotificationsButton } from "notifications/NotificationsButton/NotificationsButton";
import { AccountMenu } from "../AccountMenu";
import { HistoryLink } from "../HistoryLink";
import { SidebarState, sidebarTheme, useSidebarContext } from "../Sidebar";
import { Breadcrumb } from "./Breadcrumb";
import { useNavBar } from "./useNavBar";

import cls from "./NavBar.less";
import { AdminOnly, DebugDisplay } from "common/components/Admin";
import { GlobalSearch } from "../GlobalSearch";

const NavbarContainer = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    box-shadow: rgba(0, 0, 50, 0.12) 0px 1px 4px;
    background-color: white;
    height: 4.5rem;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: ${theme.spacing.S3};
  `
);

const Breadcrumbs = styled.div<{ state: SidebarState }, DefaultTheme>(({ state, theme }) => {
  const marginLeft = state === "PINNED_OPEN" ? theme.spacing.S6 : sidebarTheme.openTarget.width;

  return `
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-left: calc(${marginLeft} - ${theme.spacing.S4});
    transition: margin-left ${sidebarTheme.transitionDuration};
  `;
});

const Children = styled.span`
  display: inline-block;
`;

const NavBarMenu = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    display: flex;
    gap: ${theme.spacing.S1};
    align-items: center;
    margin-right: ${theme.spacing.S4};

    @media (min-width: ${theme.breakpoints.MD}) {
      margin-right: ${theme.spacing.S6};
    }
  `
);

export interface NavBreadcrumb {
  pageName: string;
  component?: any;
  path?: string | LocationDescriptor;
}

export interface NavBarProps {
  breadcrumbs: NavBreadcrumb[];
  isAdminRoute?: boolean;
}

export const NavBar: FC<NavBarProps> = ({ breadcrumbs, children, isAdminRoute = false }) => {
  const { currentOrganizationUser, isGlobalSearchEnable } = useNavBar();
  const { state } = useSidebarContext();

  return (
    <NavbarContainer>
      <Breadcrumbs className={cls.breadcrumbs} state={state}>
        <Breadcrumb>
          {breadcrumbs.map((breadcrumb) => (
            <Breadcrumb.Item key={generate()} isAdminRoute={isAdminRoute}>
              {breadcrumb.component || (
                <HistoryLink href={(breadcrumb.path as string) || location.pathname}>
                  <FormattedMessage
                    id={`NavBar.breadcrumb.${breadcrumb.pageName}`}
                    defaultMessage={breadcrumb.pageName}
                  />
                </HistoryLink>
              )}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <Children>{children}</Children>
      </Breadcrumbs>
      <NavBarMenu>
        {isGlobalSearchEnable && <GlobalSearch />}
        <NotificationsButton />
        <AccountMenu user={currentOrganizationUser} />
        <AdminOnly>
          <DebugDisplay />
        </AdminOnly>
      </NavBarMenu>
    </NavbarContainer>
  );
};

export const linkWithState = (path: string, state: any) => ({
  pathname: path,
  state,
});
