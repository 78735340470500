export enum IndeterminateCheckboxState {
  ALL_SELECTED = "ALL_SELECTED",
  NONE_SELECTED = "NONE_SELECTED",
  PARTIALLY_SELECTED = "PARTIALLY_SELECTED",
}

export const getNextState = (currentState: IndeterminateCheckboxState): IndeterminateCheckboxState => {
  if (currentState !== IndeterminateCheckboxState.ALL_SELECTED) {
    return IndeterminateCheckboxState.ALL_SELECTED;
  }

  return IndeterminateCheckboxState.NONE_SELECTED;
};
