import { BorderIcon, SelectComponents, Stack, StackProps, Text } from "common/components/ui";
import { NoOptionsMessageProps } from "@deliverr/react-select-v1";
import { css } from "emotion";
import cx from "classnames";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { DefaultTheme, TextAlign } from "common/components/ui/shared";
import { isEmpty } from "lodash";
import styled from "@emotion/styled";

const noPadding = css("padding: 0 !important;"); // Override react-select styling

const StyledStack = styled(Stack)<StackProps, DefaultTheme>(
  ({ theme }) => `
    padding: ${theme.spacing.S5} 0;
  `
);

export const SearchNoOptions: FC<NoOptionsMessageProps> = ({ children, ...props }) => {
  const inputValue = props.selectProps.inputValue as string;
  const hasSearched = !isEmpty(inputValue.trim());

  return (
    <SelectComponents.NoOptionsMessage {...props} className={cx({ [noPadding]: !hasSearched })}>
      {hasSearched && (
        <StyledStack center>
          <BorderIcon iconType="search" />
          <div>
            <Text as="div" textAlign={TextAlign.center} appearance="INFO">
              <FormattedMessage id="searchNoOptions.noResults" defaultMessage="No results" />
            </Text>
            <Text as="div" textAlign={TextAlign.center} size="caption">
              <FormattedMessage id="searchNoOptions.tryAgain" defaultMessage="Try searching for something else." />
            </Text>
          </div>
        </StyledStack>
      )}
    </SelectComponents.NoOptionsMessage>
  );
};
