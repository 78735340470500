import React, { FC } from "react";
import { Route } from "react-router-dom";

import { MissingBarcodeCaseContextProvider } from "./context";
import { nonComplianceInternalRoutes as routes } from "inbounds/non-compliance/NonComplianceRoutes";
import { MissingBarcodeCase } from "./MissingBarcodeCase";
import { MissingBarcodeSuccess } from "./MissingBarcodeSuccess";

export const MissingBarcodeRoutes: FC = () => (
  <MissingBarcodeCaseContextProvider>
    <Route
      exact
      key="NonCompliance.MissingBarcode"
      path={routes.missingBarcode}
      render={() => <MissingBarcodeCase />}
    />
    <Route
      key="NonCompliance.MissingBarcodeSuccess"
      path={routes.missingBarcodeSuccess}
      render={() => <MissingBarcodeSuccess />}
    />
  </MissingBarcodeCaseContextProvider>
);
