import React, { FC } from "react";
import {
  defaultTheme,
  DefaultTheme,
  IconV2,
  IconV2Props,
  LocalizedMessage,
  ThemeProps,
  Text,
  Appearance,
} from "common/components/ui";
import styled from "@emotion/styled";
import { StringEnumToUnion } from "common/types/StringEnumToUnion";

interface InputErrorProps {
  appearance?: StringEnumToUnion<Appearance, "WARNING" | "DANGER">;
  message: LocalizedMessage;
  className?: string;
  isBold?: boolean;
}

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Message = styled.div<{ isWarning: boolean }, DefaultTheme>(
  ({ isWarning, theme }) => `
    color: ${isWarning ? theme.colors.ORANGE[400] : theme.colors.RED[400]};
  `
);

const IconWithMargin = styled(IconV2)<ThemeProps, IconV2Props>(
  ({ theme }) => `
    margin-right: ${theme.spacing.S1};
    margin-left: -${theme.spacing.S1};
    flex-shrink: 0;
    height: ${theme.iconSize.I4};
  `
);

export const InputError: FC<InputErrorProps> = ({ appearance = "DANGER", message, className, isBold }) => {
  const isWarning = appearance === "WARNING";

  return (
    <MessageContainer className={className}>
      {isWarning ? (
        <IconWithMargin
          data-testid="warning-icon"
          color={defaultTheme.colors.ORANGE[400]}
          type={"exclamation-triangle"}
        />
      ) : (
        <IconWithMargin data-testid="error-icon" color={defaultTheme.colors.RED[400]} type={"exclamation-circle"} />
      )}
      <Message isWarning={isWarning}>
        <Text bold={isBold} size="caption">
          {message}
        </Text>
      </Message>
    </MessageContainer>
  );
};
