import React, { FC } from "react";
import styled from "@emotion/styled";
import { FormattedDate, FormattedMessage } from "react-intl";
import {
  Blade,
  DefaultTheme,
  ThemeProps,
  LocalizedMessage,
  Text,
  Title,
  TitleProps,
  defaultTheme,
} from "common/components/ui";
import { useMedia } from "react-use";

const BladeContent = styled.div<ThemeProps>(
  ({ theme }) => `
  margin: ${theme.spacing.S5};

  & > * {
    margin-bottom: ${theme.spacing.S6};
  }
`
);

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SpacedTitle = styled(Title)<TitleProps, DefaultTheme>(
  ({ theme }) => `
    margin: ${theme.spacing.S4} 0;
    letter-spacing: ${theme.font.letterSpacing.LS1};
  `
);

export interface ProblemBladeProps {
  id: string;
  isBladeOpen: boolean;
  onCloseClick: () => void;
}

interface ProblemBaseBladeProps extends ProblemBladeProps {
  title: LocalizedMessage;
  footer?: JSX.Element;
  updatedAt?: Date;
  width?: string;
}

export const ProblemBaseBlade: FC<React.PropsWithChildren<ProblemBaseBladeProps>> = (props) => {
  const isMobile = useMedia(`(max-width: ${defaultTheme.breakpoints.SM})`);
  const DateText = styled(Text)`
    ${isMobile ? "display: none;" : ""}
  `;

  const { children, title, footer, isBladeOpen, onCloseClick, updatedAt } = props;

  return (
    <Blade
      trigger={isBladeOpen}
      onClose={onCloseClick}
      width={props.width}
      headerContent={
        <TitleContainer>
          <SpacedTitle as="h3" displayAs="h4">
            {title}
          </SpacedTitle>
          {updatedAt && (
            <DateText appearance="INFO" size="caption">
              <FormattedMessage
                id="inbounds.ProblemBaseBlade.updated"
                defaultMessage="Updated {updatedAt}"
                values={{
                  updatedAt: <FormattedDate value={new Date(updatedAt)} year="numeric" month="long" day="numeric" />,
                }}
              />
            </DateText>
          )}
        </TitleContainer>
      }
      footer={footer}
    >
      <BladeContent>{children}</BladeContent>
    </Blade>
  );
};
