export const INTERNAL_DOMAINS = ["@deliverr.com", "@shopify.com", "@flexport.com"];

interface Options {
  isTestAccount?: boolean;
  email: string;
  isLoose?: boolean;
}

/**
 * WARNING! Pattern matching is not a reliable way to determine if an account is a test account.
 * Do not use this evaluation for allowing a user to bypass any requirements, and take care if using to
 * display or hide UI elements. This is only intended to be used for analytics and logging purposes and warning
 * actual admin users during impersonation.
 */

export const getIsTestSeller = ({ email, isTestAccount, isLoose }: Options): boolean => {
  if (isTestAccount) {
    return true;
  }

  // Unless isLoose is true, do not perform a pattern match on the email address
  if (!isLoose) {
    return false;
  }

  return INTERNAL_DOMAINS.some((suffix) => email.endsWith(suffix));
};
