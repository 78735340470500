import loadGoogleMapsAPI from "load-google-maps-api";

let apisLoadingPromise;
export const loadGoogleMaps = async () => {
  if (window.google) {
    return;
  }

  if (apisLoadingPromise) {
    return apisLoadingPromise;
  }

  return (apisLoadingPromise = loadGoogleMapsAPI({
    key: process.env.GOOGLE_API_KEY,
    libraries: ["places"],
    language: "en",
  }));
};
