import { useSPSelector } from "common/ReduxUtils";
import { selectIsTestSeller } from "organization/OrganizationSelectors";
import { SelectIsTestSellerOptions } from "organization/OrganizationSelectors/selectIsTestSeller";

/**
 * this evaluatuion is also available as a selector or utility function
 * the valuec in this hook is to ensure that the selector is correctly invoked by
 * using useSPSelector, which ensures correct typing
 */

export const useIsTestSeller = ({ isLoose }: SelectIsTestSellerOptions = {}) => {
  return useSPSelector(selectIsTestSeller({ isLoose }));
};
