import React, { FC } from "react";
import { useOrderReturnsList } from "./useOrderReturnsList";
import { DynamicRoutes } from "paths";
import { returnTitles } from "returns/detail/ReturnsDetailStatus";
import { Link } from "react-router-dom";
import { toStartCase } from "../utils/stringUtil";

type OrderReturnsListProps = Pick<ReturnType<typeof useOrderReturnsList>, "orderId" | "orderReturnsList">;

const OrderReturnsList: FC<OrderReturnsListProps> = ({ orderId, orderReturnsList }) => (
  <>
    {orderReturnsList.map((orderReturnItem, index) => (
      <div key={orderReturnItem.id}>
        <Link
          data-testid={`returns-detail-${index}`}
          to={DynamicRoutes.returnsDetail.parse({ orderId, returnId: orderReturnItem.id })}
        >
          {`${orderReturnItem.id} ( ${
            returnTitles[orderReturnItem?.status]
              ? returnTitles[orderReturnItem.status].defaultMessage
              : toStartCase(orderReturnItem.status)
          } )`}
        </Link>
      </div>
    ))}
  </>
);

export default OrderReturnsList;
