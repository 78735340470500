import React, { FC } from "react";

import { useEditShipmentQuantity } from "./useEditShipmentQuantity";
import { EditableNumberField } from "common/components/EditableNumberField";

interface EditShipmentQuantityProps {
  dsku: string;
}

export const EditShipmentQuantity: FC<EditShipmentQuantityProps> = ({ dsku }) => {
  const { quantity, handleInputChange, handleSubmit } = useEditShipmentQuantity(dsku);

  return (
    <form onBlur={handleSubmit} onSubmit={handleSubmit}>
      <EditableNumberField onChange={handleInputChange} value={quantity} />
    </form>
  );
};
