import { dateToOpenSearchFilter } from "./dateToOpenSearchFilter";
import { serviceToOpenSearchFilter } from "./serviceToOpenSearchFilter";
import { statusToOpenSearchFilter } from "./statusToOpenSearchFilter";
import { OrderTag } from "@deliverr/commons-objects";
import { OrderListDateFilter, OrderListServiceFilter, OrderListStatusFilter } from "../OrderListReducer";
import { QueryDslQueryContainer } from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";
import { SupportedOrderImportErrorTypes } from "../OrderImportErrorsTable/SupportedOrderImportErrorTypes";

export interface GetOpenSearchFiltersProps {
  status: OrderListStatusFilter;
  serviceLevel: OrderListServiceFilter;
  date: OrderListDateFilter;
  isAdmin?: boolean;
}

const noDistributionOrderFilter: QueryDslQueryContainer = {
  bool: {
    must_not: {
      match: {
        tags: `${OrderTag.DISTRIBUTION_ORDER}`,
      },
    },
  },
};

export const getCustomizedOpenSearchFilters = ({
  status,
  serviceLevel,
  date,
  isAdmin = false,
}: GetOpenSearchFiltersProps): QueryDslQueryContainer[] => {
  const orderStatusFilters = statusToOpenSearchFilter(status);
  const orderServiceFilters = serviceToOpenSearchFilter(serviceLevel);
  const orderDateFilters = dateToOpenSearchFilter(date);
  // admins should be able to view distribution orders
  const distributionFilter: QueryDslQueryContainer[] = isAdmin ? [] : [noDistributionOrderFilter];

  const filters = [...orderStatusFilters, ...orderServiceFilters, ...orderDateFilters, ...distributionFilter];
  if (filters.length < 1) {
    return [];
  }
  return [
    {
      bool: {
        filter: [...orderStatusFilters, ...orderServiceFilters, ...orderDateFilters, ...distributionFilter],
      },
    },
  ];
};

export const getOrderImportErrorCustomizedFilters = (): QueryDslQueryContainer[] => {
  return [
    {
      bool: {
        filter: {
          terms: {
            errorType: SupportedOrderImportErrorTypes.map((error) => `${error}`),
          },
        },
      },
    },
  ];
};
