import React from "react";
import { LocalizedText } from "common/components/LocalizedText";

export const ShipmentTotalsLabel = () => (
  <LocalizedText
    bold
    size="bodyLarge"
    as="span"
    message={{
      id: "inbound.createShipment.cargoDetailsStep.shipmentTotalsSection.title",
      defaultMessage: "Shipment totals",
    }}
  />
);
