import { createSelector } from "reselect";

import { selectPlanProductDetails } from "../productDetailsCache";
import { getProductIsFefoEnabled } from "common/utils/product/getProductIsFefoEnabled";
import { fetchKitComponents } from "inbounds/store/util/fetchKitComponents";

export const selectPlanItemsWithFefoEnabled = createSelector(selectPlanProductDetails, (planItems) =>
  planItems.filter(getProductIsFefoEnabled)
);

export const selectKittedComponentItemsWithFefoEnabled = createSelector(
  selectPlanItemsWithFefoEnabled,
  fetchKitComponents
);
