import { Product } from "@deliverr/commons-clients";
import { ProductCollection } from "common/models";
import { getProductDetailsCache } from "inbounds/store/selectors/productDetailsCache";
import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { StorageInboundCreateState } from "../StorageInboundCreateState.types";
import { StorageInboundProductItem } from "../StorageInboundProductItem.types";
import { getStorageInboundCreate } from "./getStorageInboundCreate";

export const getSelectedProducts = createSelector<
  RootState,
  StorageInboundCreateState,
  ProductCollection,
  (StorageInboundProductItem & Product)[]
>(getStorageInboundCreate, getProductDetailsCache, (storageInboundCreate, productDetailsCache) => {
  const { selectedProducts, productDetails } = storageInboundCreate;
  return Object.keys(selectedProducts).map((dsku) => ({
    ...(productDetailsCache[dsku] ?? {}),
    ...productDetails[dsku],
    ...selectedProducts[dsku],
  }));
});
