import history from "BrowserHistory";
import { Path } from "paths";

export const goToStepPath = (destinationPath: Path, replace?: boolean) => {
  if (replace) {
    history.replace(destinationPath);
  } else {
    history.push(destinationPath);
  }
};
