import * as React from "react";
import styled from "@emotion/styled";

import AngleDown from "./assets/unicons/line/AngleDown";
import AngleLeft from "./assets/unicons/line/AngleLeft";
import AngleRight from "./assets/unicons/line/AngleRight";
import AngleUp from "./assets/unicons/line/AngleUp";
import Archive from "./assets/unicons/line/Archive";
import ArrowCircleLeft from "./assets/unicons/line/ArrowCircleLeft";
import ArrowCircleRight from "./assets/unicons/line/ArrowCircleRight";
import ArrowDown from "./assets/unicons/line/ArrowDown";
import ArrowGrowth from "./assets/unicons/line/ArrowGrowth";
import ArrowLeft from "./assets/unicons/line/ArrowLeft";
import ArrowResizeDiagonal from "./assets/unicons/line/ArrowResizeDiagonal";
import ArrowRight from "./assets/unicons/line/ArrowRight";
import ArrowUp from "./assets/unicons/line/ArrowUp";
import Bars from "./assets/unicons/line/Bars";
import Bell from "./assets/unicons/line/Bell";
import BoltAlt from "./assets/unicons/line/BoltAlt";
import BookAlt from "./assets/unicons/line/BookAlt";
import Box from "./assets/unicons/line/Box";
import Calendar from "./assets/unicons/line/Calendar";
import Camera from "./assets/unicons/line/Camera";
import CameraPlus from "./assets/unicons/line/CameraPlus";
import Check from "./assets/unicons/line/Check";
import CheckCircle from "./assets/unicons/line/CheckCircle";
import ClipboardNotes from "./assets/unicons/line/ClipboardNotes";
import ClockEight from "./assets/unicons/line/ClockEight";
import CodeBranch from "./assets/unicons/line/CodeBranch";
import Cog from "./assets/unicons/line/Cog";
import CommentLock from "./assets/unicons/line/CommentLock";
import Comments from "./assets/unicons/line/Comments";
import Copy from "./assets/unicons/line/Copy";
import CreditCard from "./assets/unicons/line/CreditCard";
import DollarSign from "./assets/unicons/line/DollarSign";
import DownloadAlt from "./assets/unicons/line/DownloadAlt";
import EllipsisH from "./assets/unicons/line/EllipsisH";
import Estate from "./assets/unicons/line/Estate";
import Exchange from "./assets/unicons/line/Exchange";
import ExclamationCircle from "./assets/unicons/line/ExclamationCircle";
import ExclamationTriangle from "./assets/unicons/line/ExclamationTriangle";
import ExternalLinkAlt from "./assets/unicons/line/ExternalLinkAlt";
import Eye from "./assets/unicons/line/Eye";
import EyeSlash from "./assets/unicons/line/EyeSlash";
import File from "./assets/unicons/line/File";
import FileAlt from "./assets/unicons/line/FileAlt";
import FileContract from "./assets/unicons/line/FileContract";
import FilePdf from "./assets/unicons/line/FilePdf";
import FileUploadAlt from "./assets/unicons/line/FileUploadAlt";
import Gift from "./assets/unicons/line/Gift";
import Globe from "./assets/unicons/line/Globe";
import History from "./assets/unicons/line/History";
import Hourglass from "./assets/unicons/line/Hourglass";
import InfoCircle from "./assets/unicons/line/InfoCircle";
import LinkAlt from "./assets/unicons/line/LinkAlt";
import LinkBroken from "./assets/unicons/line/LinkBroken";
import ListUiAlt from "./assets/unicons/line/ListUiAlt";
import Lots from "./assets/unicons/line/Lots";
import MapMarker from "./assets/unicons/line/MapMarker";
import Minus from "./assets/unicons/line/Minus";
import Navigator from "./assets/unicons/line/Navigator";
import Package from "./assets/unicons/line/Package";
import Padlock from "./assets/unicons/line/Padlock";
import Pen from "./assets/unicons/line/Pen";
import PlaneFly from "./assets/unicons/line/PlaneFly";
import Plus from "./assets/unicons/line/Plus";
import PlusCircle from "./assets/unicons/line/PlusCircle";
import Print from "./assets/unicons/line/Print";
import QrcodeScan from "./assets/unicons/line/QrcodeScan";
import QuestionCircle from "./assets/unicons/line/QuestionCircle";
import Receipt from "./assets/unicons/line/Receipt";
import Redo from "./assets/unicons/line/Redo";
import Rocket from "./assets/unicons/line/Rocket";
import Search from "./assets/unicons/line/Search";
import ShoppingBag from "./assets/unicons/line/ShoppingBag";
import Signout from "./assets/unicons/line/Signout";
import SignOutAlt from "./assets/unicons/line/SignOutAlt";
import SlidersV from "./assets/unicons/line/SlidersV";
import Spinner from "./assets/unicons/line/Spinner";
import Share from "./assets/unicons/line/Share";
import Star from "./assets/unicons/line/Star";
import Sync from "./assets/unicons/line/Sync";
import Times from "./assets/unicons/line/Times";
import TopArrowToTop from "./assets/unicons/line/TopArrowToTop";
import Trash from "./assets/unicons/line/Trash";
import TruckLoading from "./assets/unicons/line/TruckLoading";
import Umbrella from "./assets/unicons/line/Umbrella";
import University from "./assets/unicons/line/University";
import UserCircle from "./assets/unicons/line/UserCircle";
import Video from "./assets/unicons/line/Video";

import BarsLeft from "./assets/custom/BarsLeft";
import BarsRight from "./assets/custom/BarsRight";
import Inventory from "./assets/custom/Inventory";
import Returns from "./assets/custom/Returns";
import Truck from "./assets/custom/Truck";
import SmbShip from "./assets/custom/SmbShip";
import SmbPlane from "./assets/custom/SmbPlane";
import SmbTruck from "./assets/custom/SmbTruck";
import SmbCustoms from "./assets/custom/SmbCustoms";
import Ship from "./assets/unicons/Ship";

export type IconV2Types =
  | "angle-down"
  | "angle-left"
  | "angle-right"
  | "angle-up"
  | "archive"
  | "arrow-circle-left"
  | "arrow-circle-right"
  | "arrow-down"
  | "arrow-left"
  | "arrow-resize-diagonal"
  | "arrow-right"
  | "arrow-up"
  | "arrow-to-top"
  | "bank"
  | "bars"
  | "bars-left"
  | "bars-right"
  | "bell"
  | "bolt"
  | "book"
  | "box"
  | "calendar"
  | "camera"
  | "camera-plus"
  | "chat"
  | "check"
  | "check-circle"
  | "clipboard"
  | "clock"
  | "close"
  | "code-branch"
  | "comment-lock"
  | "copy"
  | "credit-card"
  | "document"
  | "dollar-sign"
  | "dolly"
  | "download"
  | "ellipsis-h"
  | "exchange"
  | "exclamation-circle"
  | "exclamation-triangle"
  | "external-link"
  | "eye"
  | "eye-slash"
  | "file"
  | "file-contract"
  | "file-pdf"
  | "file-upload"
  | "filter"
  | "gift"
  | "globe"
  | "growth"
  | "history"
  | "hourglass"
  | "home"
  | "inbounds"
  | "info-circle"
  | "inventory"
  | "invoice"
  | "launch"
  | "link"
  | "link-broken"
  | "list-alt"
  | "loading"
  | "location"
  | "lock"
  | "lots"
  | "minus"
  | "navigator"
  | "package"
  | "pen"
  | "plane-fly"
  | "plus"
  | "plus-circle"
  | "print"
  | "question-circle"
  | "redo"
  | "returns"
  | "scan"
  | "search"
  | "settings"
  | "share"
  | "ship"
  | "shopping-bag"
  | "sign-out"
  | "smb-plane"
  | "smb-ship"
  | "smb-truck"
  | "smb-customs"
  | "star"
  | "sync"
  | "trash"
  | "truck"
  | "umbrella"
  | "user-circle"
  | "video";

export const IconV2Map: Record<IconV2Types, React.ComponentType> = {
  "angle-down": AngleDown,
  "angle-left": AngleLeft,
  "angle-right": AngleRight,
  "angle-up": AngleUp,
  archive: Archive,
  "arrow-circle-left": ArrowCircleLeft,
  "arrow-circle-right": ArrowCircleRight,
  "arrow-down": ArrowDown,
  "arrow-left": ArrowLeft,
  "arrow-resize-diagonal": ArrowResizeDiagonal,
  "arrow-right": ArrowRight,
  "arrow-to-top": TopArrowToTop,
  "arrow-up": ArrowUp,
  bank: University,
  bars: Bars,
  "bars-left": BarsLeft,
  "bars-right": BarsRight,
  bell: Bell,
  bolt: BoltAlt,
  book: BookAlt,
  box: Box,
  calendar: Calendar,
  camera: Camera,
  "camera-plus": CameraPlus,
  chat: Comments,
  check: Check,
  "check-circle": CheckCircle,
  clipboard: ClipboardNotes,
  clock: ClockEight,
  close: Times,
  "code-branch": CodeBranch,
  "comment-lock": CommentLock,
  copy: Copy,
  "credit-card": CreditCard,
  document: FileAlt,
  "dollar-sign": DollarSign,
  dolly: TruckLoading,
  download: DownloadAlt,
  "ellipsis-h": EllipsisH,
  exchange: Exchange,
  "exclamation-circle": ExclamationCircle,
  "exclamation-triangle": ExclamationTriangle,
  "external-link": ExternalLinkAlt,
  eye: Eye,
  "eye-slash": EyeSlash,
  file: File,
  "file-contract": FileContract,
  "file-pdf": FilePdf,
  "file-upload": FileUploadAlt,
  filter: SlidersV,
  gift: Gift,
  globe: Globe,
  growth: ArrowGrowth,
  history: History,
  hourglass: Hourglass,
  home: Estate,
  inbounds: SignOutAlt,
  "info-circle": InfoCircle,
  inventory: Inventory,
  invoice: Receipt,
  launch: Rocket,
  link: LinkAlt,
  "link-broken": LinkBroken,
  "list-alt": ListUiAlt,
  loading: Spinner,
  location: MapMarker,
  lock: Padlock,
  lots: Lots,
  minus: Minus,
  navigator: Navigator,
  package: Package,
  pen: Pen,
  "plane-fly": PlaneFly,
  plus: Plus,
  "plus-circle": PlusCircle,
  print: Print,
  "question-circle": QuestionCircle,
  redo: Redo,
  returns: Returns,
  scan: QrcodeScan,
  search: Search,
  settings: Cog,
  share: Share,
  ship: Ship,
  "shopping-bag": ShoppingBag,
  "sign-out": Signout,
  "smb-plane": SmbPlane,
  "smb-ship": SmbShip,
  "smb-truck": SmbTruck,
  "smb-customs": SmbCustoms,
  star: Star,
  sync: Sync,
  trash: Trash,
  truck: Truck,
  umbrella: Umbrella,
  "user-circle": UserCircle,
  video: Video,
};

/**
 * support for fontawesome icon sizes will be deprecated in the near future once dependency on fontawesome is removed
 * going forward, we will only be supporting 16 & 24px icon sizes
 */
export enum IconSizes {
  "xs" = "xs",
  "sm" = "sm",
  "md" = "md",
  "lg" = "lg",
  "2x" = "2x",
  "3x" = "3x",
  "4x" = "4x",
  "5x" = "5x",
  "6x" = "6x",
  "7x" = "7x",
  "8x" = "8x",
  "9x" = "9x",
  "10x" = "10x",
  "small" = "small",
  "medium" = "medium",
  "large" = "large",
}

const IconSize = {
  [IconSizes.xs]: "0.75em",
  [IconSizes.sm]: "0.875em",
  [IconSizes.md]: "1em",
  [IconSizes.lg]: "1.33em",
  [IconSizes["2x"]]: "2em",
  [IconSizes["3x"]]: "3em",
  [IconSizes["4x"]]: "4em",
  [IconSizes["5x"]]: "5em",
  [IconSizes["6x"]]: "6em",
  [IconSizes["7x"]]: "7em",
  [IconSizes["8x"]]: "8em",
  [IconSizes["9x"]]: "9em",
  [IconSizes["10x"]]: "10em",
  [IconSizes.small]: "1rem",
  [IconSizes.medium]: "1.25rem",
  [IconSizes.large]: "1.5rem",
};

const VerticalAlign = {
  [IconSizes.medium]: "-0.25rem",
  [IconSizes.large]: "-0.5rem",
};

export interface IconV2Props extends React.SVGProps<SVGElement> {
  type: IconV2Types;
  size?: IconSizes | string;
  spin?: boolean;
  inverse?: boolean;
  color?: string;
  // old props from fontawesome, do not have mappings
  fixedWidth?: boolean;
  transform?: string;
}

const StyledIcon = (icon, { spin, inverse }) =>
  styled(icon)<Omit<IconV2Props, "type">>(
    ({ size = IconSizes.medium, color }) => `
  display: inline;
  fill: ${color ?? "currentColor"};
  font-size: ${IconSize[size]};
  ${VerticalAlign[size] ? `vertical-align: ${VerticalAlign[size]};` : ""}
  filter: invert(${inverse ? 1 : 0});
  ${
    spin
      ? `
    animation: spin 2s infinite linear;
    @keyframes spin {
      from {transform:rotate(0deg);}
      to {transform:rotate(360deg);}
    }`
      : ""
  }
`
  );

export const IconV2: React.FC<IconV2Props> = ({ type, spin, inverse, ...rest }) => {
  const Unicon = IconV2Map[type];
  const Icon = StyledIcon(Unicon, { spin, inverse });
  return <Icon {...rest} />;
};
