import { OrderListDateFilter } from "../OrderListReducer";

export const dateToAlgoliaFilter = (dateType: OrderListDateFilter) => {
  if (dateType === "OLDER_THAN_ONE_WEEK") {
    const d = new Date();
    return [`originalOrderCreationTimeUnix < ${Math.floor(d.setDate(d.getDate() - 7))}`];
  }
  if (dateType === "LAST_MONTH") {
    const d = new Date();
    return [`originalOrderCreationTimeUnix > ${Math.floor(d.setDate(d.getDate() - 31))}`];
  }
  return [];
};
