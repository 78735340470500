import { format } from "date-fns-tz";
import { useState } from "react";
import { DatePickerProps } from "./DatePicker";
import { useIntl } from "react-intl";
import { SlashDate } from "common/date/DateFormat";
import { subLocalTimeZoneOffset } from "common/date/subLocalTimeZoneOffset";

const DEFAULT_DATE_FORMAT = SlashDate;

type UseDatePickerProps = Pick<DatePickerProps, "onDateChange" | "format" | "adjustUTCOffset">;

export function useDatePicker({ format: dateFormat, onDateChange, adjustUTCOffset }: UseDatePickerProps) {
  const { formatMessage } = useIntl();
  const [visible, setVisible] = useState<boolean>(false);

  const handleSelect = (date: any) => {
    if (date) {
      onDateChange(adjustUTCOffset ? subLocalTimeZoneOffset(date) : date);
      setVisible(false);
    } else {
      onDateChange(date);
    }
  };

  const handleVisibleChange = (isVisible: boolean) => setVisible(isVisible);

  const formatDate = (subject: Date | undefined) => subject && format(subject, dateFormat ?? DEFAULT_DATE_FORMAT);

  const placeholder = formatMessage({ id: "DatePicker.placeholder", defaultMessage: "Select a date" });

  return {
    visible,
    handleSelect,
    handleVisibleChange,
    formatDate,
    placeholder,
  };
}
