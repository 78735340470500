import React from "react";
import styled from "@emotion/styled";
import { defaultTheme, DefaultTheme, ThemeProps } from "../shared";
import { Icon } from "../Icon";
import { Card } from "../Card";
import { isMobile } from "../shared/helpers/utils";

export interface AccordianProps extends React.HTMLAttributes<HTMLDivElement> {
  /** What appears in the clickable area */
  headerContent: JSX.Element | string;
  /** Toggles indicator on the right */
  indicator?: boolean;
  /** Toggles HR between header and content */
  divider?: boolean;
  /** Sets the state for the accordion */
  isOpen: boolean;
  /** Toggles the state for the accordion open/close */
  toggleOpen: () => void;
  /** Displays a number in the title */
  counter?: number;
  /** Disabled the component from being clicked */
  disabled?: boolean;
  /** Remove padding from the accordion body */
  noBodyPadding?: boolean;
}

const ICON_SIZE = "16px";
const CARD_PADDING = "40px";
const COUNTER_SIZE = "32px";

const Header = styled.button<{ isOpen?: boolean; indicator?: boolean; divider?: boolean }, DefaultTheme>(
  ({ isOpen, indicator, divider, theme }) => `
  appearance: none;
  position: relative;
  width: 100%;
  background-color: ${theme.colors.NEUTRAL["00"]};
  border: 0;
  outline: 0;
  z-index: 2;
  display: grid;
  align-items: center;
  grid-gap: ${theme.spacing.S3};
  grid-template-columns: 1fr ${indicator ? ICON_SIZE : ""};
  text-align: left;
  cursor: pointer;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  padding: ${isMobile(theme) ? theme.spacing.S4 : `${theme.spacing.S5} ${CARD_PADDING}`};
  ${
    isOpen
      ? `
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  `
      : ``
  };

  :disabled {
    cursor: not-allowed;
  }
`
);

const BodyContainer = styled.div<{ divider?: boolean; noPadding?: boolean }, DefaultTheme>(
  ({ divider, theme, noPadding }) => {
    const desktopPaddingTop = !divider ? theme.spacing.S2 : theme.spacing.S5;

    let padding: string = `${desktopPaddingTop} ${CARD_PADDING} ${theme.spacing.S6}`;

    if (noPadding) {
      padding = "0";
    } else if (isMobile(theme)) {
      padding = theme.spacing.S4;
    }

    return `
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
      padding: ${padding};
      z-index: 1;
    `;
  }
);

const IconContainer = styled.div`
  svg {
    font-size: ${ICON_SIZE};
  }
`;

const Hr = styled.hr<ThemeProps>(({ theme }) => {
  const padding = isMobile(theme) ? theme.spacing.S4 : "0px";
  return `
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    ${theme.colors.NEUTRAL["00"]} ${padding},
    ${theme.colors.NEUTRAL[80]} ${padding} calc(100% - ${padding}),
    ${theme.colors.NEUTRAL["00"]} ${padding}
  );
`;
});

const Counter = styled.div<{ disabled?: boolean }, DefaultTheme>(
  ({ disabled, theme }) => `
  min-width: ${COUNTER_SIZE};
  height: ${COUNTER_SIZE};
  border-radius: 50%;
  background-color: ${disabled ? theme.colors.NEUTRAL[300] : theme.colors.BLUE[300]};
  color: ${theme.colors.NEUTRAL["00"]};
  margin-right: ${theme.spacing.S4};
  font-weight: ${theme.font.weight.BLACK};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`
);

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Accordion: React.FC<AccordianProps> = ({
  headerContent,
  isOpen,
  toggleOpen,
  indicator,
  divider,
  counter,
  disabled,
  children,
  noBodyPadding,
}) => {
  return (
    <Card size="NONE" aria-expanded={isOpen}>
      <Header
        isOpen={isOpen}
        onClick={toggleOpen}
        indicator={indicator}
        divider={divider}
        disabled={disabled}
        data-testid="collapsible_button"
      >
        <TitleContainer>
          {counter && <Counter disabled={disabled}>{counter}</Counter>}
          {headerContent}
        </TitleContainer>
        {indicator && !disabled && (
          <IconContainer>
            <Icon color={defaultTheme.colors.NEUTRAL[300]} type={isOpen ? "chevron-up" : "chevron-down"} />
          </IconContainer>
        )}
      </Header>
      {isOpen && (
        <>
          {divider && <Hr />}
          <BodyContainer divider={divider} noPadding={noBodyPadding}>
            {children}
          </BodyContainer>
        </>
      )}
    </Card>
  );
};
