import cx from "classnames";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { ExternalLink } from "common/components/ExternalLink";
import { Path } from "paths";
import { FastTagsNav } from "tags/base/FastTagsNav";
import facebookConnectShopifyGraphic from "assets/img/tags/facebook/facebook-connect-shopify.svg";

import sharedCls from "../../../common/facebook.less";
import HtmlToReact from "common/components/HtmlToReact";

export const ConnectShopify: FC = () => (
  <div className={sharedCls.container}>
    <div className={cx(sharedCls.inner, sharedCls.body)}>
      <div className={sharedCls.bodyContent}>
        <h4 className={sharedCls.title}>
          <FormattedMessage
            id="FacebookFastTag.ConnectShopifyTitle"
            defaultMessage={"Connect your Shopify store to Facebook"}
          />
        </h4>
        <p className={sharedCls.text}>
          <FormattedMessage
            id="FacebookFastTag.ConnectShopifyMessage1"
            defaultMessage={"If you have already added Facebook as a Shopify integration you can press “next”."}
          />
        </p>
        <p className={sharedCls.text}>
          <FormattedMessage
            id="FacebookFastTag.ConnectShopifyMessage2"
            defaultMessage="If you have not connected Facebook as a Shopify integration, please {guideLink} to get your facebook pixel connected to your Shopify store."
            values={{
              guideLink: (
                <ExternalLink className={sharedCls.link} href="https://www.facebook.com/business/help/646757258759189">
                  <FormattedMessage
                    id="FacebookFastTag.ConnectShopifyMessage2GuideLink"
                    defaultMessage="follow this guide"
                  />
                </ExternalLink>
              ),
            }}
          />
        </p>
      </div>
      <HtmlToReact html={facebookConnectShopifyGraphic} />
    </div>

    <FastTagsNav
      className={sharedCls.nav}
      nextPage={Path.facebookConnectAccount}
      previousPage={Path.facebookStart}
      nextButtonText={<FormattedMessage id="next" defaultMessage={"Next"} />}
      previousButtonText={<FormattedMessage id="back" defaultMessage={"Back"} />}
      nextButtonClassName={sharedCls.actionButton}
      previousButtonClassName={sharedCls.actionButton}
    />
  </div>
);
