import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";

import WithLoader from "common/components/WithLoader";
import { ActionCreator } from "common/ReduxUtils";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { loadInbound } from "inbounds/steps/InboundLoadActions";
import { getShippingPlanId } from "inbounds/steps/InboundStepSelectors";
import { RootState } from "RootReducer";

interface ReduxProps {
  loadedShippingPlanId: number;
}

interface DispatchProps {
  loadInbound: ActionCreator;
}

interface LoadInboundProps {
  forceReload: boolean;
}

interface State {
  checkedForLoadedInbound: boolean;
}

class UnconnectedLoadInbound extends React.Component<
  ReduxProps & DispatchProps & LoadInboundProps & RouteComponentProps<any>,
  State
> {
  public state = { checkedForLoadedInbound: false };

  public async componentDidMount() {
    const {
      forceReload,
      loadedShippingPlanId,
      match: { params },
    } = this.props;
    const routeShippingPlanId = Number(params.shippingPlanId);

    if (routeShippingPlanId !== loadedShippingPlanId || forceReload) {
      await this.props.loadInbound(routeShippingPlanId, params.shipmentId && Number(params.shipmentId), true);
    }

    this.setState({ checkedForLoadedInbound: true });
  }

  public render() {
    return (
      <WithLoader name={InboundLoaderId.loadingInbound} size={"lg"}>
        {this.state.checkedForLoadedInbound && this.props.children}
      </WithLoader>
    );
  }
}

export const LoadInbound = withRouter<LoadInboundProps & RouteComponentProps<any>, any>(
  connect(
    (state: RootState) => ({
      loadedShippingPlanId: getShippingPlanId(state),
    }),
    { loadInbound }
  )(UnconnectedLoadInbound)
);
