import React, { useState } from "react";
import styled from "@emotion/styled";
import { FormattedMessage, useIntl } from "react-intl";
import { OrderStatus, ShippingPartner } from "@deliverr/replenishment-client";
import { Text, ThemeProps } from "common/components/ui";
import { Currency, CurrencyFormat } from "common/components/Currency";
import { StyledAnchor } from "transfers/ui/StyledAnchor";
import { useReplenishmentOrderPricingSummary } from "../create/steps/common/replenishment/confirm/useReplenishmentOrderPricingSummary";
import { ModalPricingSummary } from "./ModalPricingSummary";
import { OrderCostsCharges } from "./store/replenishment/ReplenishmentOrderDetailsState";
import { replenishmentOrderDetailLabels } from "transfers/create/steps/common/replenishment/replenishmentOrderDetails.labels";

const CostContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    min-width: 25%;
    padding: ${theme.spacing.S5} ${theme.spacing.S7};
    font-size: ${theme.font.size.F2};
    border-left: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
    white-space: nowrap;
  `
);

export const ReplenishmentOrderCostDetailsWithActuals: React.FC<{
  replenishmentOrderCosts: OrderCostsCharges;
  orderStatus: OrderStatus;
  shippingMethod?: ShippingPartner;
}> = ({ replenishmentOrderCosts, orderStatus, shippingMethod }) => {
  const { formatMessage } = useIntl();
  // Update this to use orderIsAfter after being exposed from RP
  const shouldShowTotalActualCost =
    [OrderStatus.ARRIVED, OrderStatus.RECEIVING, OrderStatus.COMPLETED].includes(orderStatus) ||
    (shippingMethod === ShippingPartner.SELLER && orderStatus === OrderStatus.SHIPPED);
  const predefinedAggregatorTotalValue = !shouldShowTotalActualCost
    ? formatMessage(replenishmentOrderDetailLabels.order.costs.tbdCost)
    : undefined;
  const actualCostTableProps = useReplenishmentOrderPricingSummary(
    { charges: replenishmentOrderCosts?.actualCharges },
    predefinedAggregatorTotalValue
  );
  const estimatedCostTableProps = useReplenishmentOrderPricingSummary({
    charges: replenishmentOrderCosts?.estimatedCharges,
  });
  const [shouldShowCostModal, setShowCostModal] = useState(false);

  const hideCostModal = () => {
    setShowCostModal(false);
  };

  const onShowCostModal = () => {
    setShowCostModal(true);
  };

  const totalEstimation =
    Number(
      shouldShowTotalActualCost ? actualCostTableProps.totalAggregation : estimatedCostTableProps.totalAggregation
    ) ?? 0;

  const formattedTotalCost =
    totalEstimation > 0 ? <Currency value={totalEstimation} currency={CurrencyFormat.USD} /> : "NA";

  return (
    <>
      <ModalPricingSummary
        show={shouldShowCostModal}
        onClose={hideCostModal}
        estimatedCostTableProps={estimatedCostTableProps}
        actualCostTableProps={actualCostTableProps}
      />
      <CostContainer>
        <div>
          <Text size="body" bold>
            {shouldShowTotalActualCost ? (
              <FormattedMessage {...replenishmentOrderDetailLabels.order.costs.actualCostTitle} />
            ) : (
              <FormattedMessage {...replenishmentOrderDetailLabels.order.costs.estimatedCostTitle} />
            )}
          </Text>
          {formattedTotalCost}
          <br />
          <StyledAnchor data-testid="replenishment-view-cost-details" onClick={onShowCostModal}>
            <FormattedMessage {...replenishmentOrderDetailLabels.order.costs.viewDetails} />
          </StyledAnchor>
        </div>
      </CostContainer>
    </>
  );
};
