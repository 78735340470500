import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { StorageInboundCreateState } from "../StorageInboundCreateState.types";
import { getStorageInboundCreate } from "./getStorageInboundCreate";
import { ShipmentType } from "@deliverr/storage-client";

export const getNumOfPallets = createSelector<RootState, StorageInboundCreateState, number>(
  getStorageInboundCreate,
  (storageInboundCreate) => {
    const { cargoType, numOfPallets, estimatedNumOfPallets } = storageInboundCreate;
    return (cargoType === ShipmentType.PALLETIZED ? numOfPallets : estimatedNumOfPallets) ?? 1;
  }
);
