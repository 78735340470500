import { useEffect, useState } from "react";
import { removeAllEmTags } from "common/StringUtils";

export const useNavbarEditableItem = ({ displayItemValue, handleSubmit }) => {
  const sanitizedItemValue = removeAllEmTags(displayItemValue);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editableItemValue, setEditableItemValue] = useState<string>(sanitizedItemValue);

  const handleInputBlur = () => {
    handleSubmit?.(editableItemValue);
    setIsEditing(false);
  };

  const handleItemClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setEditableItemValue(sanitizedItemValue);
    setIsEditing(true);
    // Suggestion: Add a callback below in case the user wants to do something after they click the name
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleSubmit?.(editableItemValue);
    setIsEditing(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setEditableItemValue(event.target.value);
    }
  };

  useEffect(() => {
    if (sanitizedItemValue) {
      setEditableItemValue(sanitizedItemValue);
    }
  }, [sanitizedItemValue]);

  return {
    isEditing,
    editableItemValue,
    handleInputBlur,
    handleItemClick,
    handleFormSubmit,
    handleInputChange,
  };
};
