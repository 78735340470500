import log from "Logger";

// from https://developers.heap.io/reference/adduserproperties
export const addHeapUserProperties = (properties: Record<string, string | number>) => {
  const addUserProperties = (window as any).heap?.addUserProperties?.bind((window as any).heap);

  if (!addUserProperties) {
    log.warn({ fn: "addHeapUserProperties" }, "heap addUserProperties not available");
    return;
  }

  addUserProperties(properties);
};
