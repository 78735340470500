import { createReducer, ReducerDictionary } from "common/ReduxUtils";
import { ErrorsActionTypes, BusinessErrorCode } from "./ErrorsActions";

interface BusinessError {
  code: BusinessErrorCode;
  data?: { [K: string]: unknown };
}

export interface ErrorsState {
  errors: BusinessError[];
}

export const errorsInitialState: ErrorsState = {
  errors: [],
};

const reducers: ReducerDictionary<ErrorsState> = {
  [ErrorsActionTypes.SET_ERROR]: (state, { code, data }) => ({
    errors: [...state.errors.filter((err) => err.code !== code), { code, data }],
  }),
  [ErrorsActionTypes.CLEAR_ERROR]: (state, { code }) => ({ errors: state.errors.filter((err) => err.code !== code) }),
};

export const errorsReducer = createReducer<ErrorsState>(errorsInitialState, reducers);
