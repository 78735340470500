import { wholesaleClient } from "Clients";
import {
  Channel,
  ExternalDestinationType,
  ValidShipByDTO,
  WholesaleOrderUpsertRequestDTO,
  WholesaleShippingMethod,
  WholesaleSingleShipmentCreationRequestDTO,
} from "@deliverr/wholesale-client";
import { logError } from "Logger";
import { selectWholesaleCreate } from "../store/WholesaleCreateSelectors";
import { selectWholesaleSelectedProductsQty } from "../store/selectWholesaleSelectedProductsQty";
import {
  wholesaleCreateSetError,
  wholesaleCreateSetValidShipBy,
  wholesaleCreateSetWholesaleOrder,
} from "./WholesaleCreateActions";
import { addLoader, clearLoader } from "../../../common/components/WithLoader/LoadingActions";
import { CreateWholesaleLoader } from "../CreateWholesaleLoader.types";
import { batch } from "react-redux";
import { updateWholesaleOrder } from "./updateWholesaleOrder";
import { mapProductsToOrderItems } from "../../../transfers/create/steps/common/mapProductsToOrderItems";
import { mapProductsToMultiPackSkuInventory } from "storage/packUtils/mapProductsToMultiPackSkuInventory";
import { FeatureName, isFeatureOn } from "common/Split";
import { getWholesaleOrderWithPacks } from "storage/packUtils/getWholesaleorderWithPacks";
import { WholesaleErrorType } from "../../../common/wholesale-common/utils/WholesaleErrorTypes";
import { isInternationalAddress } from "../../../common/AddressUtils";
import { WarehouseOrderType } from "@deliverr/business-types";

export const createOrUpdateWholesaleOrder = async (dispatch, getState): Promise<boolean> => {
  const { wholesaleOrder, originStorageWarehouse, destinationAddress, shippingMethod, cargoType, notes, poNumber } =
    selectWholesaleCreate(getState());
  const selectedProductsQty = selectWholesaleSelectedProductsQty(getState());
  const isMultiPackEnabled = isFeatureOn(FeatureName.StorageMultiCasePack);
  const isInternationalOrder = isInternationalAddress(destinationAddress!);

  batch(() => {
    dispatch(addLoader(CreateWholesaleLoader.CreateWholesaleOrderLoader));
    dispatch(wholesaleCreateSetError(WholesaleErrorType.NONE));
  });

  try {
    if (wholesaleOrder?.id) {
      await updateWholesaleOrder(dispatch, getState);
      const response = await dispatch(getWholesaleOrderWithPacks(wholesaleOrder?.id as string));
      const { id: shipmentId } = response!.shipments![0];
      const validShipBy: ValidShipByDTO = (await wholesaleClient.getValidShipBy(shipmentId)).data;

      batch(() => {
        dispatch(wholesaleCreateSetWholesaleOrder(response));
        dispatch(wholesaleCreateSetValidShipBy(validShipBy));
        dispatch(clearLoader(CreateWholesaleLoader.CreateWholesaleOrderLoader));
      });
    } else {
      let shipmentItems;

      if (isMultiPackEnabled) {
        const inventoryBreakdown = mapProductsToMultiPackSkuInventory(
          selectedProductsQty,
          originStorageWarehouse?.inventoryBreakdown ?? {}
        );
        shipmentItems = inventoryBreakdown.map(mapProductsToOrderItems);
      } else {
        shipmentItems = selectedProductsQty.map(mapProductsToOrderItems);
      }

      const shipment: WholesaleSingleShipmentCreationRequestDTO = {
        originWarehouseId: originStorageWarehouse!.warehouse.id,
        inputDestinationAddress: destinationAddress,
        shipmentItems,
        warehouseOrderType: WarehouseOrderType.WHOLESALE,
        carrierInfo: {
          shippingMethod:
            shippingMethod ?? isInternationalOrder ? WholesaleShippingMethod.SELLER : WholesaleShippingMethod.DELIVERR,
        },
        shipmentType: cargoType,
        notes,
      };
      const createOrderRequest: WholesaleOrderUpsertRequestDTO = {
        shipments: [shipment],
        externalDestinationType: ExternalDestinationType.WHOLESALE,
        channel: Channel.WHOLESALE_UI,
        externalId: poNumber,
      };

      const response = (await wholesaleClient.createOrderAndShipments("", createOrderRequest)).data;
      const { id: shipmentId } = response!.shipments![0];
      const validShipBy: ValidShipByDTO = (await wholesaleClient.getValidShipBy(shipmentId)).data;
      batch(() => {
        dispatch(wholesaleCreateSetWholesaleOrder(response));
        dispatch(wholesaleCreateSetValidShipBy(validShipBy));
        dispatch(clearLoader(CreateWholesaleLoader.CreateWholesaleOrderLoader));
      });
    }
    return true;
  } catch (err) {
    batch(() => {
      dispatch(clearLoader(CreateWholesaleLoader.CreateWholesaleOrderLoader));
      dispatch(wholesaleCreateSetError(WholesaleErrorType.UNTYPED));
    });
    logError({ fn: "createOrUpdateWholesaleOrder" }, err);
    return false;
  }
};
