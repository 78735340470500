import { FastTagType } from "common/clients/fastTag/FastTagType";
import { facebookConnectFlow } from "tags/facebook/connect/facebookConnectFlow";
import { facebookEditFlow } from "tags/facebook/edit/FacebookEditFlow";
import { FastTagFlow } from "./FastTagFlow";
import { FastTagOperation, FAST_TAG_CONNECT, FAST_TAG_EDIT } from "./FastTagOperation";

type FastTagOperationFlowMap = Partial<Record<FastTagOperation, FastTagFlow>>;

export const fastTagFlows = {
  [FastTagType.FACEBOOK]: {
    [FAST_TAG_CONNECT]: facebookConnectFlow,
    [FAST_TAG_EDIT]: facebookEditFlow,
  } as FastTagOperationFlowMap,
} as const;
