import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import produce from "immer";
import { ReturnsActionTypes, ReturnsCreateState } from "./ReturnsCreateStore.types";

export const returnsCreateInitialState: ReturnsCreateState = {
  formState: { currentStep: 0, errors: {} },
  selectedReturnItems: {},
  selectedDestination: 0,
  selectedShippingService: 0,
  selectedOrderId: undefined,
  hideSelectProductStep: false,
  orderCompletelyReturned: false,
  shippingServiceOptions: [],
};

const reducers: ReducerDictionary<ReturnsCreateState> = {
  ...handleSimpleReducerUpdates([ReturnsActionTypes.UPDATE_CREATE_RETURN_FORM]),

  [ReturnsActionTypes.RESET_RETURN_FORM]: () => ({ ...returnsCreateInitialState }),

  [ReturnsActionTypes.ERROR_STEP_RETURN_FORM]: (state, { stepId, error }) =>
    produce(state, (draft) => {
      draft.formState.errors[stepId] = error;
    }),

  [ReturnsActionTypes.RETURNS_CREATE_ENABLE_NEXT_STEP]: (state, { counter }) =>
    produce(state, (draft) => {
      draft.formState.currentStep = (counter as number) + 1;
    }),
};

export const returnsCreateReducer = createReducer<ReturnsCreateState>(returnsCreateInitialState, reducers);
