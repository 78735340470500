import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { AdminActionTypes } from "./AdminActions";

export interface AdminState {
  currentSearchTerm: string;
  currentPageItems: any[];
  showAdminFeatures: boolean;
  showArchivedShippingPlans: boolean;
  email?: string;
  onboardingStage?: {};
  dealStage?: string;
  federatedType?: string;
  listingTool?: string;
}

export const adminInitialState: AdminState = {
  currentSearchTerm: "",
  currentPageItems: [],
  showAdminFeatures: true,
  showArchivedShippingPlans: false,
};

const reducers: ReducerDictionary<AdminState> = {
  ...handleSimpleReducerUpdates([
    AdminActionTypes.SET_USER_PROPS,
    AdminActionTypes.SET_SHOW_ADMIN_TEXT,
    AdminActionTypes.SET_SHOW_ARCHIVED_SHIPPING_PLANS,
  ]),
  [AdminActionTypes.SEARCH_SELLER_SUCCESS]: (state, action) => {
    const { topOrganizations, topUsers } = action;

    const organizationItems = topOrganizations.map(({ sellerId, logisticsAccountId, shopDomain }) => ({
      key: logisticsAccountId,
      sellerId,
      line1: shopDomain,
      line2: logisticsAccountId,
    }));

    const userItems = topUsers.map(({ sellerId, userId, email }) => ({
      key: userId,
      sellerId,
      email,
      line1: sellerId,
      line2: email,
    }));

    return {
      ...state,
      currentPageItems: [...organizationItems, ...userItems],
    };
  },
  [AdminActionTypes.RESET_USER_PROPS]: (state) => adminInitialState,
};

export const adminReducer = createReducer<AdminState>(adminInitialState, reducers);
