import { DeliverrAddress } from "@deliverr/commons-objects";
import {
  __,
  complement,
  includes,
  isEmpty,
  isEqual,
  keys,
  pipe,
  propOr,
  toUpper,
  trim,
  pickBy,
  pick,
  difference,
} from "lodash/fp";
import { AddressInputRequiredField } from "./components/AddressInput";

export const emptyAddress = {
  name: "",
  company: "",
  street1: "",
  street2: "",
  city: "",
  state: "",
  country: "US",
  zip: "",
  email: "",
};

export const emptyAddressWithPhone = {
  ...emptyAddress,
  phone: "",
};

export const isEmptyAddress = isEqual(emptyAddress);

export const unitedStatesStrings = ["US", "USA", "UNITED STATES", "U.S.", "U.S.A.", "U.S", "U.S.A"];

export const isInternationalAddress = pipe<DeliverrAddress, string, string, string, boolean>(
  propOr("", "country"),
  trim,
  toUpper,
  complement(includes(__, unitedStatesStrings))
);

export const isNotDeliverrRatesAddress = pipe<DeliverrAddress, string, string, string, boolean>(
  propOr("", "country"),
  trim,
  toUpper,
  complement(includes(__, [...unitedStatesStrings, "CA", "CANADA"]))
);

// from lib-commons/utils
export const doesAddressContainPOBox = (address): boolean => {
  return /p\.?\s{0,3}o\.?\s{0,3}box\s{0,3}\d/.test([address.street1, address.street2].join(" ").toLowerCase());
};

const isValidValue = (val) => isEmpty(trim(val));

const keysWithValues = pipe(pickBy(complement(isValidValue)), keys);

export const addressRequiredFieldsValidation = (
  address: DeliverrAddress,
  requiredFields: AddressInputRequiredField[] = ["name", "street1", "city", "state", "country", "zip"]
) => {
  const providedFields = pipe(pick(requiredFields), keysWithValues)(address) as AddressInputRequiredField[];
  return difference(requiredFields, providedFields);
};

export const isZipcodeModified = (newAddress: DeliverrAddress, oldAddress: DeliverrAddress) => {
  return (
    newAddress.zip !== oldAddress.zip ||
    newAddress.state !== oldAddress.state ||
    newAddress.city !== oldAddress.city ||
    newAddress.country !== oldAddress.country
  );
};
