import { uniqBy } from "lodash";
import { DskuQty } from "@deliverr/commons-clients";
import { SPThunkAction } from "common/ReduxUtils";
import { SelectedProductData } from "common/components/ProductChooser";
import { getProductChooserKittedDSKUDetails } from "common/components/ProductChooser/ProductChooserSelector";
import { getAvailableInventoryInEachStorageWarehouse } from "./getAvailableInventoryInEachStorageWarehouse";
import { getDSKUQuantitiesForKittedProduct } from "./getDSKUQuantitiesForKittedProduct";
import { transferCreateAddProduct, transferCreateSetKitComponentOrderItems } from "../actions";

export const addKittedProduct =
  (dskuQuantities: DskuQty[], product: SelectedProductData, includeInventoryBreakdown?: boolean): SPThunkAction =>
  async (dispatch, getState) => {
    if (!product.isKit || !product.packOf) {
      throw new Error("Product is not a kit or does not have a packOf value");
    }
    const state = getState();
    const productDetails = getProductChooserKittedDSKUDetails(state);
    const updatedDskuQuantities = getDSKUQuantitiesForKittedProduct(dskuQuantities, productDetails);
    const kittedProduct = productDetails?.[product.dsku];
    if (kittedProduct) {
      const dskuWithComponentQuantities = uniqBy(
        [
          ...updatedDskuQuantities,
          ...(Object.values(kittedProduct.availableInventoryMap)?.map((component) => ({ dsku: component.dsku })) ?? []),
        ],
        "dsku"
      );
      dispatch(
        getAvailableInventoryInEachStorageWarehouse(dskuWithComponentQuantities, includeInventoryBreakdown, true)
      );
      dispatch(transferCreateAddProduct(product.dsku, product));
      for (const component of kittedProduct.kitComponents) {
        dispatch(
          transferCreateSetKitComponentOrderItems({
            dsku: component.dsku,
            packOf: component.packOf,
            unitsPerPack: component.caseQty,
            totalUnits: 0,
          })
        );
      }
    }
  };
