import React, { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useSPDispatch } from "common/ReduxUtils";
import { useSelector, useStore } from "react-redux";
import { selectCreateShipmentMeta, selectBookingRequestByStep, selectCurrentStep } from "../store/selectors";
import { saveBooking } from "../store/actions/saveBooking";
import { setIsSaving, setShippingPlanId } from "../store/actions";
import { useIntl } from "react-intl";
import { toast } from "common/components/ui";
import { CreateShipmentContext, CreateShipmentContextValue } from "./CreateShipmentContext";
import { selectIpbDraftsFeatureOn } from "../store/selectors/selectIpbDraftsFeatureOn";
import { LoadingFallback } from "./LoadingFallback";
import { CreateShipmentFormContextProvider } from "./CreateShipmentFormContextProvider";
import { useMount, useUnmount } from "react-use";
import { loadBookingDraft } from "../store/actions/loadBookingDraft";
import { useSmbAccelerateFeatureOn } from "common/featureFlags";
import { FeatureName, getFeatureSelector } from "common/Split";
import { useSmbBookingContext } from "../common/analytics/useSmbBookingContext";
import { trackSmbBookingCreationEvent } from "../common/analytics/trackSmbBookingCreationEvent";
import { useStepBookingRequestCache } from "./useStepBookingRequestCache";
import { selectBookingRequestByStepV2 } from "../store/selectors/selectBookingRequestByStepV2";
import { selectCreatedByV1 } from "../store/selectors/selectCreatedByV1";

/**
 * This context provider is only for handling creating, updating and loading an in-progress shipment.
 */

export const CreateShipmentContextProvider: FC = ({ children }) => {
  const { formatMessage } = useIntl();
  const dispatch = useSPDispatch();
  const { getState } = useStore();
  const { isSaving, isLoading } = useSelector(selectCreateShipmentMeta);
  const isIpbDraftsFeatureOn = useSelector(selectIpbDraftsFeatureOn);
  const params = useParams<{ shippingPlanId?: string }>();
  const shippingPlanId = params.shippingPlanId ? Number(params.shippingPlanId) : undefined;
  const isSmbAccelerateFbaUnitPrepOn = useSmbAccelerateFeatureOn(FeatureName.SmbAccelerateFbaUnitPrep);
  const { cacheRequest, getResponseFromCache, populateCacheFromDraft, hasPendingChanges } =
    useStepBookingRequestCache();

  /** the useSmbBookingContext hook populates the DataDog RUM context with user input */
  useSmbBookingContext();

  useMount(async () => {
    if (shippingPlanId) {
      const savedBooking = await dispatch(loadBookingDraft(shippingPlanId, isSmbAccelerateFbaUnitPrepOn));
      savedBooking && populateCacheFromDraft(savedBooking);
    }
  });

  useUnmount(() => {
    dispatch(setShippingPlanId(null));
  });

  /** will create a booking or update one if shippingPlanId is populated */
  const handleSaveBooking: CreateShipmentContextValue["saveBooking"] = useCallback(
    async (shouldSubmit: boolean = false) => {
      const state = getState();
      const currentStep = selectCurrentStep(state);
      const isIpbBookingV2FeatureOn = getFeatureSelector(FeatureName.SmbIpbBookingV2)(state);
      const isCreatedByV1 = selectCreatedByV1(state);
      const shouldUseBookingV2 = isIpbBookingV2FeatureOn && !isCreatedByV1;
      const request = shouldUseBookingV2
        ? selectBookingRequestByStepV2(currentStep, shouldSubmit)(state)
        : selectBookingRequestByStep(currentStep, shouldSubmit)(state);

      /** we do a comparison to the last request to prevent unnecessary calls  */
      const cachedResponse = request && getResponseFromCache(request);

      /** if the request hasnt changed, then return the last response */
      if (cachedResponse) {
        return cachedResponse;
      }

      dispatch(setIsSaving(true));

      try {
        const booking = await dispatch(saveBooking(shouldSubmit));

        /** cache the request and response */
        request && cacheRequest(currentStep, request, booking);
        return booking;
      } finally {
        dispatch(setIsSaving(false));
      }
    },
    [dispatch, getState, getResponseFromCache, cacheRequest]
  );

  const handleSaveStepError = useCallback(
    (err) => {
      err.then((error) => {
        toast.error(
          formatMessage(
            {
              id: "inbound.createShipment.saveBooking.error",
              defaultMessage: "There was an error saving your inbound. Try again later. {error}",
            },
            { error: error.message }
          ),
          {
            toastId: "inbound.createShipment.saveBooking.error",
          }
        );
      });
    },
    [formatMessage]
  );

  /** only use this for wrapping a step's submitData function, this is similar to dispatchThenSaveInbound */
  /** note that this will not submit a booking unless the drafts feature is on  */
  const composeSaveStep: CreateShipmentContextValue["composeSaveStep"] = useCallback(
    (stepSubmitter, optionsArg = {}) =>
      async () => {
        dispatch(setIsSaving(true));
        const saveStepOptions = typeof optionsArg === "function" ? optionsArg() : optionsArg;
        const { shouldSkipSaveBooking, canCreateBooking } = saveStepOptions;
        const canUpdateBooking = !!shippingPlanId;
        const canSaveBooking = isIpbDraftsFeatureOn && !shouldSkipSaveBooking && (canUpdateBooking || canCreateBooking);
        try {
          const stepReturn = await stepSubmitter();

          if (canSaveBooking) {
            await handleSaveBooking(false);
          }

          trackSmbBookingCreationEvent("click_next");
          /** returns the step's submitData return value to be used by the stepManager */
          return stepReturn;
        } catch (err) {
          handleSaveStepError(err);
          throw err;
        } finally {
          dispatch(setIsSaving(false));
        }
      },
    [dispatch, handleSaveBooking, handleSaveStepError, isIpbDraftsFeatureOn, shippingPlanId]
  );

  return (
    <CreateShipmentContext.Provider
      value={{ isSaving, composeSaveStep, saveBooking: handleSaveBooking, hasPendingChanges }}
    >
      {isLoading ? (
        <LoadingFallback />
      ) : (
        <CreateShipmentFormContextProvider>{children}</CreateShipmentFormContextProvider>
      )}
    </CreateShipmentContext.Provider>
  );
};
