import produce from "immer";
import { InboundState } from "inbounds/InboundReducer";
import { AnyAction } from "redux";

export interface EditShipmentQuantityAction extends AnyAction {
  shipmentId: number;
  dsku: string;
  quantity: number;
}

export const updateShipmentQuantityReducer = (
  state: InboundState,
  { shipmentId, dsku, quantity }: AnyAction
): InboundState => {
  return produce(state, (draft) => {
    const items = draft.receivingInfo[shipmentId]!.items;
    const itemIndex = items.findIndex((item) => item.dsku === dsku);

    items[itemIndex]!.expectedQty = quantity;
  });
};
