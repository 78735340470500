import { PriceItem } from "@deliverr/billing-pricer-client";
import { any, isNil } from "lodash/fp";

export const verifyMultipleProductProps = (dimensions: PriceItem[]) =>
  dimensions.reduce((acc: (PriceItem | undefined)[], dimension: PriceItem) => {
    const { dsku, length, width, height, weight, hazmatIds, minimumPackagingType, qty = 1 } = dimension;
    if (any(isNil)([length, width, height, weight])) {
      acc.push(undefined);
      return acc;
    }
    const item: PriceItem = { dsku, length, width, height, weight, hazmatIds, minimumPackagingType, qty };
    acc?.push(item);
    return acc;
  }, []);
