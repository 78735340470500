import { toast } from "common/components/ui";
import { formatAddress } from "common/components/Address";
import { defineMessages, useIntl } from "react-intl";

export const copyMessage = defineMessages({
  success: {
    id: "returns.copy.success",
    defaultMessage: "Copied",
  },
});

export const useCopyAddressContainer = () => {
  const { formatMessage } = useIntl();

  const onCopyClick = async (address) => {
    await navigator.clipboard.writeText(address ? formatAddress({ address }) : "");
    toast.success(formatMessage(copyMessage.success));
  };

  return { onCopyClick };
};
