import styled from "@emotion/styled";
import { Divider } from "./Divider";
import { ThemeProps } from "../../shared";

export const LightDivider = styled(Divider)<ThemeProps>(
  ({ theme }) => `
    border-color: ${theme.colors.NEUTRAL["60"]};
    margin: -${theme.spacing.S4} 0 ${theme.spacing.S4} 0;
    border-width: ${theme.border.width.B1} 0 0 0;
`
);
