import React from "react";
import { Box, Card, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import { InboundFooter } from "inbounds/steps/InboundFooter";
import { useStorageDestinationV2 } from "./useStorageDestinationV2";
import { StorageDestinationHeader } from "./StorageDestinationHeader";
import { StorageNonCDLocationWarning } from "./StorageNonCDLocationWarning";
import { StorageNewWarehouseInboundWarning } from "./StorageNewWarehouseInboundWarning";
import { StorageFacilitySelect } from "inbounds/components/StorageFacilitySelect";
import { StorageWarehouseCaseDimensionWarning } from "./StorageWarehouseCaseDimensionWarning";

const StyledCard = styled(Card)<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing.S7} ${theme.spacing.S5};
  height: 100%;
`
);

export const StorageDestinationV2: React.ComponentType = () => {
  const {
    onNextClick,
    onPreviousClick,
    onDestinationTypeChange,
    destinationType,
    recommendedWarehouse,
    storageWarehouseToBeShown,
    isNextDisabled,
    onStorageWarehouseSelect,
    selectedWarehouseOption,
    shouldShowNonCDLocationWarning,
    shouldShowNewWarehouseInboundWarning,
    isAnyWarehouseDisabledDueToDims,
  } = useStorageDestinationV2();

  return (
    <Box minHeight="36.25rem" minWidth="62.5rem">
      <StyledCard>
        <StorageDestinationHeader />
        {shouldShowNonCDLocationWarning && <StorageNonCDLocationWarning />}
        {shouldShowNewWarehouseInboundWarning && <StorageNewWarehouseInboundWarning />}
        {isAnyWarehouseDisabledDueToDims && <StorageWarehouseCaseDimensionWarning />}
        <StorageFacilitySelect
          recommendedWarehouse={recommendedWarehouse}
          storageWarehouseToBeShown={storageWarehouseToBeShown}
          onFacilityTypeChange={onDestinationTypeChange}
          destinationType={destinationType}
          onStorageWarehouseSelect={onStorageWarehouseSelect}
          selectedWarehouseOption={selectedWarehouseOption}
        />
      </StyledCard>
      <InboundFooter
        onButtonClick={onNextClick}
        onPreviousButtonClick={onPreviousClick}
        disabled={isNextDisabled}
        manualLoader
      />
    </Box>
  );
};
