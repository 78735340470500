import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faAngleRight,
  faArrowUp,
  faBars,
  faCalendar,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleNotch,
  faCommentDots,
  faCopy,
  faCreditCard,
  faDownload,
  faEdit,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faGift,
  faHourglassEnd,
  faInfoCircle,
  faKey,
  faLink,
  faLock,
  faMapMarkerAlt,
  faMobile,
  faPen,
  faPencilAlt,
  faPlus,
  faPlusCircle,
  faPrint,
  faQuestionCircle,
  faStar,
  faSyncAlt,
  faTimes,
  faTimesCircle,
  faUndo,
  faUnlink,
  faVoteYea,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFacebook } from "@fortawesome/free-brands-svg-icons";

/**
 * Import icons here, so we don't have to import them individually in each file
 * https://github.com/FortAwesome/react-fontawesome#usage
 */

export const addIcons = () => {
  library.add(
    faAngleDown,
    faAngleRight,
    faArrowUp,
    faBars,
    faTwitter,
    faFacebook,
    faCalendar,
    faCaretDown,
    faCaretUp,
    faCheck,
    faTimes,
    faCheckCircle,
    faCheckSquare,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faCircleNotch,
    faCommentDots,
    faCopy,
    faCreditCard,
    faDownload,
    faEdit,
    faEnvelope,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLinkAlt,
    faGift,
    faInfoCircle,
    faKey,
    faLink,
    faLock,
    faMapMarkerAlt,
    faMobile,
    faPen,
    faPencilAlt,
    faPlus,
    faPlusCircle,
    faPrint,
    faQuestionCircle,
    faStar,
    faSyncAlt,
    faTimesCircle,
    faVoteYea,
    faHourglassEnd,
    faWrench,
    faUnlink,
    faUndo
  );
};
