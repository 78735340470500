import { SHIPMENT_STATUS_GROUPINGS } from "inbounds/ShipmentStatus";
import {
  InboundListSearchFilters,
  ShippingPlanType,
  DiscrepancyStatusType,
} from "./InboundListFilters/InboundListFilterTypes";

export const inboundListFiltersInitialState: InboundListSearchFilters = {
  shipmentStatuses: SHIPMENT_STATUS_GROUPINGS,
  shippingPlanType: ShippingPlanType.ALL_TYPES,
  discrepancyStatus: DiscrepancyStatusType.ALL_STATES,
};
