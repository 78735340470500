import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useMount } from "react-use";

import { convertTimeWindowTo24Hours, TimeWindowDates } from "../../utils/time/convertTimeWindowToString";
import { FacilityOperatingHoursInputNames } from "./facilityOperatingHoursTypes";
import { facilityOperatingHoursMessages } from "./facilityOperatingHoursMessages";
import { FacilityOperatingHoursProps } from "./FacilityOperatingHours";
import {
  LATEST_PICKUP_TIME,
  MINIMUM_PICKUP_WINDOW_HOURS,
} from "common/utils/constants/FacilityOperatingHoursConstants";
import { differenceInHours, set } from "date-fns";

export const useFacilityOperatingHours = ({
  updateHours,
  facilityOperatingHours,
  hasMinRange = false,
  isOptional = true,
  title,
}: FacilityOperatingHoursProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const {
    setError,
    clearErrors,
    formState: { errors },
    register,
  } = useFormContext();

  const [facilityOperatingHoursValue, setFacilityOperatingHoursValue] = React.useState<{
    startTime: Date | null;
    endTime: Date | null;
  }>({
    startTime: null,
    endTime: null,
  });

  useMount(() => {
    if (facilityOperatingHours?.startTime) {
      const newStartTime = new Date();
      const newEndTime = new Date();
      newStartTime.setHours(parseInt(facilityOperatingHours.startTime), 0);
      newEndTime.setHours(parseInt(facilityOperatingHours.endTime), 0);
      setFacilityOperatingHoursValue({
        startTime: newStartTime,
        endTime: newEndTime,
      });
    }
  });

  useEffect(() => {
    if (
      hasMinRange &&
      !!facilityOperatingHoursValue?.startTime &&
      !!facilityOperatingHoursValue?.endTime &&
      facilityOperatingHoursValue.endTime.getHours() - facilityOperatingHoursValue.startTime.getHours() <
        MINIMUM_PICKUP_WINDOW_HOURS
    ) {
      setError(FacilityOperatingHoursInputNames.OPERATING_HOURS, {
        type: "string",
        message: formatMessage(facilityOperatingHoursMessages.errorNotMinRange, {
          MIN_HOUR_RANGE: MINIMUM_PICKUP_WINDOW_HOURS,
        }),
      });
    } else {
      clearErrors(FacilityOperatingHoursInputNames.OPERATING_HOURS);
    }
  }, [facilityOperatingHoursValue, hasMinRange, setError, formatMessage, clearErrors]);

  const facilityOperatingHoursFormGroupProps = {
    errorMessage: formatMessage(facilityOperatingHoursMessages.errorNotMinRange, {
      MIN_HOUR_RANGE: MINIMUM_PICKUP_WINDOW_HOURS,
    }),
    hasError: !!errors[FacilityOperatingHoursInputNames.OPERATING_HOURS],
    label: title ?? formatMessage(facilityOperatingHoursMessages.label),
    isOptional,
    helpText: `${formatMessage(facilityOperatingHoursMessages.helpText)}${
      hasMinRange
        ? ". " + formatMessage(facilityOperatingHoursMessages.minRange, { MIN_HOUR_RANGE: MINIMUM_PICKUP_WINDOW_HOURS })
        : ""
    }`,
  };

  const facilityOperatingHoursStartInput = register(FacilityOperatingHoursInputNames.OPERATING_HOURS_START);
  const facilityOperatingHoursEndInput = register(FacilityOperatingHoursInputNames.OPERATING_HOURS_END);

  const handleFacilityOperatingHoursStartChange = async (time: Date) => {
    const newWindow = {
      ...facilityOperatingHoursValue,
      startTime: time,
    };

    if (
      !facilityOperatingHoursValue.endTime ||
      differenceInHours(facilityOperatingHoursValue.endTime, time) < MINIMUM_PICKUP_WINDOW_HOURS
    ) {
      const suggestedEndTime = set(new Date(), {
        hours: time.getHours() + MINIMUM_PICKUP_WINDOW_HOURS,
        minutes: 0,
        seconds: 0,
      });
      newWindow.endTime =
        suggestedEndTime.getHours() <= LATEST_PICKUP_TIME.getHours() ? suggestedEndTime : LATEST_PICKUP_TIME;
    }

    setFacilityOperatingHoursValue(newWindow);
    if (!time) {
      return clearErrors(FacilityOperatingHoursInputNames.OPERATING_HOURS_START);
    }
    dispatch(updateHours(convertTimeWindowTo24Hours(newWindow as TimeWindowDates)));

    await Promise.all([
      facilityOperatingHoursStartInput.onChange({
        target: { name: FacilityOperatingHoursInputNames.OPERATING_HOURS_START, value: time },
      }),
      facilityOperatingHoursEndInput.onChange({
        target: { name: FacilityOperatingHoursInputNames.OPERATING_HOURS_END, value: newWindow.endTime },
      }),
    ]);
  };

  const handleFacilityOperatingHoursEndChange = async (time: Date) => {
    const newWindow = {
      ...facilityOperatingHoursValue,
      endTime: time,
    };
    setFacilityOperatingHoursValue(newWindow);
    if (!time) {
      return clearErrors(FacilityOperatingHoursInputNames.OPERATING_HOURS_START);
    }
    // Need to do manual validation on time range since TimePicker components are not playing well with FormGroup as div
    if (facilityOperatingHoursValue?.startTime) {
      dispatch(updateHours(convertTimeWindowTo24Hours(newWindow as TimeWindowDates)));
    }
    await facilityOperatingHoursEndInput.onChange({
      target: { name: FacilityOperatingHoursInputNames.OPERATING_HOURS_END, value: time },
    });
  };

  return {
    handleFacilityOperatingHoursStartChange,
    handleFacilityOperatingHoursEndChange,
    facilityOperatingHoursFormGroupProps,
    facilityOperatingHoursEnd: facilityOperatingHoursValue?.endTime,
    facilityOperatingHoursStart: facilityOperatingHoursValue?.startTime,
    facilityOperatingHoursStartInput,
    facilityOperatingHoursEndInput,
  };
};
