import produce from "immer";
import { Dictionary } from "lodash";
import { AnyAction } from "redux";

import { createReducer, ReducerState } from "common/ReduxUtils";
import { FreightQuoteFormActionTypes } from "FreightQuote/FreightQuoteTool/actions/FreightQuoteFormActions";
import { freightQuoteInitialState, FreightQuoteState } from "FreightQuote/store/FreightQuoteState";
import { FreightQuoteSummaryActionTypes } from "FreightQuote/FreightQuoteSummary/actions/FreightQuoteSummaryActions";

export const reducers: Dictionary<ReducerState<FreightQuoteState, AnyAction>> = {
  [FreightQuoteFormActionTypes.UPDATE_FREIGHT_QUOTE_FORM]: (state, { name, value }) => {
    return produce(state, (draft) => {
      draft.freightQuoteForm.form[name] = value;
      draft.freightQuoteForm.isLoading = false;
    });
  },
  [FreightQuoteFormActionTypes.REQUEST_FREIGHT_QUOTE]: (state) => {
    return produce(state, (draft) => {
      draft.freightQuoteForm.isLoading = true;
    });
  },
  [FreightQuoteFormActionTypes.REQUEST_FREIGHT_QUOTE_SUCCESS]: (state, { freightQuoteId }) => {
    return produce(state, (draft) => {
      draft.freightQuoteSummary.quoteId = freightQuoteId;
    });
  },
  [FreightQuoteFormActionTypes.REQUEST_FREIGHT_QUOTE_ERROR]: (state, { reason }) => {
    return produce(state, (draft) => {
      draft.freightQuoteForm.error = reason;
    });
  },
  [FreightQuoteSummaryActionTypes.GET_FREIGHT_QUOTE_SUMMARY]: (state) => {
    return produce(state, (draft) => {
      draft.freightQuoteSummary.isLoading = true;
    });
  },
  [FreightQuoteSummaryActionTypes.GET_FREIGHT_QUOTE_SUMMARY_SUCCESS]: (state, { freightQuoteSummary }) => {
    return produce(state, (draft) => {
      draft.freightQuoteSummary.quote = freightQuoteSummary;
      draft.freightQuoteSummary.isLoading = false;
    });
  },
  [FreightQuoteSummaryActionTypes.GENERATE_FREIGHT_QUOTE_PUBLIC_ID_SUCCESS]: (state, { freightQuotePublicId }) => {
    return produce(state, (draft) => {
      draft.freightQuoteSummary.quote.hashId = freightQuotePublicId;
    });
  },
};

export const freightQuoteReducers = createReducer(freightQuoteInitialState, reducers);
