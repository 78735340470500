import { UnitSystem } from "inbounds/createShipment/CreateShipmentTypes";
import { StorageProductWithCasePack } from "storage/inbounds/create/store/selector/getSelectedProductsCaseDimensions";

const LB_TO_KG = 0.453592;

export const calcCasePackTotalWeight = (product: StorageProductWithCasePack, unitSystem: UnitSystem) => {
  const { weight, numberOfCases } = product;
  const caseWeightLb = weight ?? 0; // pounds
  const caseWeight = unitSystem === UnitSystem.CBM_KG ? caseWeightLb * LB_TO_KG : caseWeightLb;
  return Math.round(caseWeight * numberOfCases * 100) / 100;
};
