import { DefaultTheme, Card, Title } from "common/components/ui";
import styled from "@emotion/styled";

export const ResponsiveCard = styled(Card)<{}, DefaultTheme>(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.SM}) {
      padding: ${theme.spacing.S5}
    }
  `
);

export const ConnectionActions = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
      display: flex;
      gap: ${theme.spacing.S5};
      height: 2.5rem;
  
      @media (min-width: ${theme.breakpoints.MD}) {
        gap: ${theme.spacing.S6};
      }
    `
);

export const RmtLogo = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    svg {
      width: 2.5rem;
      height: 2.5rem;

      @media (min-width: ${theme.breakpoints.MD}) {
        width: 3.5rem;
        height: 3.5rem;
      }
    }
  `
);

export const RmtName = styled(Title)<{}, DefaultTheme>(
  ({ theme }) => `
    line-height: 1;
    margin: 0;

    @media (max-width: ${theme.breakpoints.SM}) {
      font-size: ${theme.font.size.F5};
    }
  `
);

export const RmtHeaderInfoSection = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    display: grid;
    grid: auto / auto 1fr;
    gap: ${theme.spacing.S5} ${theme.spacing.S3};

    @media (min-width: ${theme.breakpoints.MD}) {
      gap: ${theme.spacing.S2} ${theme.spacing.S5};
    }
  `
);
