import { DefaultTheme, IconV2 } from "common/components/ui";
import styled from "@emotion/styled";
import React, { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useHover } from "react-use";
import { Logo, LogoColor } from "common/components/Logo";
import { usePreventBodyScroll } from "common/hooks/usePreventBodyScroll";
import { SidebarState, useSidebarContext } from "./SidebarContext";
import { sidebarTheme } from "./SidebarTheme";

const sidebarMenuId = "sidebar-menu";

/**
 * Target in the top left of the app that the user can hover over to open the hover menu
 * in desktop mode or click on to open the modal menu in mobile mode. It is visible whenever
 * the sidebar is not pinned open.
 */
const OpenTarget = styled.div<{ state: SidebarState }, DefaultTheme>(({ state }) => {
  // When going from pinned to hidden, we want the button within to appear to slide into place from right to left.
  // But as it does, the menu will slide under the mouse pointer, triggering the menu to open on hover.
  // So, as a hack, we delay making the hover target visibile until is no longer under the mouse pointer.
  const delay = state === "HIDDEN" ? `calc(${sidebarTheme.transitionDuration} / 2)` : "0s";

  // Use sticky positioning to allow the target to appear in the top left of the app, in the navbar,
  // when the sidebar is hidden. For semantic/accessibility reasons, we'll put this button inside the <nav>
  // element in Sidebar.
  return `
    position: ${["HIDDEN", "HOVER_OPEN"].includes(state) ? "sticky" : "fixed"}};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    top: 0;
    left: 0;
    height: ${sidebarTheme.openTarget.height};
    width: ${sidebarTheme.openTarget.width};
    transform: ${state === "PINNED_OPEN" ? `translateX(${sidebarTheme.menu.width})` : "none"};
    visibility: ${["PINNED_OPEN", "MODAL_OPEN"].includes(state) ? "hidden" : "visible"};
    transition:
      transform ${sidebarTheme.transitionDuration},
      visibility 0s ${delay};
  `;
});

/**
 * Open button that appears centered within the open target.
 */
const OpenButton = styled.button<{}, DefaultTheme>(
  ({ theme }) => `
    display: block;
    padding: 0;
    color: ${theme.colors.NEUTRAL[400]};
    background-color: transparent;
    height: ${sidebarTheme.toggleButton.height};
    width: ${sidebarTheme.toggleButton.width};
    border: ${theme.border.width.B2} solid transparent;
    border-radius: ${theme.border.radius.R2};

    &:focus {
      outline: 0;
      border-color: ${theme.colors.BLUE[200]};

      &:not(:focus-visible) {
        border-color: transparent;
      }
    }

    &:hover {
      color: ${theme.colors.NEUTRAL[500]};
      background-color: ${theme.colors.NEUTRAL[40]};
    }
  `
);

/**
 * Hide button that appears in top right corner of sidebar.
 */
const HideButton = styled.button<{ state: SidebarState }, DefaultTheme>(
  ({ state, theme }) => `
    display: block;
    padding: 0;
    color: ${theme.colors.NEUTRAL[200]};
    background-color: transparent;
    height: ${sidebarTheme.toggleButton.height};
    width: ${sidebarTheme.toggleButton.width};
    border: ${theme.border.width.B2} solid transparent;
    border-radius: ${sidebarTheme.item.borderRadius};
    visibility: ${state === "HOVER_OPEN" ? "hidden" : "visible"};
    transition: visibility 0s ${state === "HIDDEN" ? sidebarTheme.transitionDuration : "0s"};

    &:focus {
      outline: 0;
      border-color: ${theme.colors.BLUE[200]};

      &:not(:focus-visible) {
        border-color: transparent;
      }
    }

    &:hover {
      color: ${theme.colors.NEUTRAL["00"]};
      background-color: ${sidebarTheme.item.hoverBackgroundColor};
    }
  `
);

/**
 * Modal overlay that appears when the sidebar is open in mobile mode.
 */
const ModalOverlay = styled.div<{ state: SidebarState }, DefaultTheme>(({ state, theme }) => {
  // When going from open with modal overlay to hidden, delay transitioning height/width to 0 so we can transition opacity.
  const delay = state === "MODAL_OPEN" ? "0s" : sidebarTheme.transitionDuration;

  return `
    position: fixed;
    z-index: 4;
    background: ${theme.colors.NEUTRAL[500]};
    opacity: ${state === "MODAL_OPEN" ? sidebarTheme.modalOverlay.opacity : "0"};
    height: ${state === "MODAL_OPEN" ? "100%" : "0"};
    width: ${state === "MODAL_OPEN" ? "100%" : "0"};
    transition:
      opacity ${sidebarTheme.transitionDuration},
      height 0s ${delay},
      width 0s ${delay};
  `;
});

/**
 * Container for sidebar menu. This container is taller than the sidebar menu itself, as
 * as there is spacing above and below the menu when in the hover open state.
 */
const MenuContainer = styled.div<{ state: SidebarState }, DefaultTheme>(({ state }) => {
  // When going to hidden state:
  // - Delay transitioning visibility so we can do slide out animation.
  // - Delay transitioning height to preserve height of previous state while sliding out.
  const delay = state === "HIDDEN" ? sidebarTheme.transitionDuration : "0s";

  return `
    position: sticky;
    z-index: 4;
    left: 0;
    width: ${sidebarTheme.menu.width};
    transform: ${state === "HIDDEN" ? `translateX(-${sidebarTheme.menu.width})` : "none"};
    top: 0;
    height: 100%;
    padding: ${state === "HOVER_OPEN" ? sidebarTheme.menu.hoverOpen.margin : "0"};
    visibility: ${state === "HIDDEN" ? "hidden" : "visible"};
    transition:
      transform ${sidebarTheme.transitionDuration},
      top 0s ${delay},
      height 0s ${delay},
      padding-top 0s ${delay},
      padding-bottom 0s ${delay},
      visibility 0s ${delay};
  `;
});

/**
 * The sidebar menu.
 */
const Menu = styled.div<{ state: SidebarState }, DefaultTheme>(({ state }) => {
  // When going to hidden state:
  // - Delay transitioning border radius to preserve border radius of previous state while sliding out.
  const delay = state === "HIDDEN" ? sidebarTheme.transitionDuration : "0s";

  return `
    position: absolute;
    display: flex;
    flex-direction: column;
    overflow-y: overlay;
    height: 100%;
    width: 100%;
    padding: ${sidebarTheme.menu.padding};
    background: ${sidebarTheme.menu.background};
    border-radius: ${state === "HOVER_OPEN" ? sidebarTheme.menu.hoverOpen.borderRadius : "0"};
    transition: border-radius 0s ${delay};
  `;
});

/**
 * Header containing Deliverr logo (in an anchor tag) and hide button. We're using the same height as the
 * open target and navbar and vertically centering to keep everything vertically aligned across the top.
 */
const Header = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${sidebarTheme.openTarget.height};
    
    > a {
      display: inline-block;
      user-select: none;
      padding: ${theme.spacing.S2};
      border: ${theme.border.width.B2} solid transparent;
      border-radius: ${sidebarTheme.item.borderRadius};

      &:focus {
        outline: 0;
        border-color: ${theme.colors.BLUE[200]};

        &:not(:focus-visible) {
          border-color: transparent;
        }
      }
    }
  `
);

const FlexGrow = styled.div`
  flex-grow: 1;
`;

export interface SidebarProps {
  rootPath: string;
  content: React.ReactNode;
  footer: React.ReactNode;
}

export const Sidebar: FC<SidebarProps> = ({ rootPath, content, footer }) => {
  const { formatMessage } = useIntl();
  const {
    mode,
    state,
    isExpanded,
    onOpenTargetClick,
    onOpenButtonClick,
    onHideButtonClick,
    onModalOverlayClick,
    onItemClick,
    onHoverChange,
  } = useSidebarContext();

  const openButtonIcon =
    mode === "MOBILE" ? <IconV2 type="bars" size="large" /> : <IconV2 type="bars-right" size="large" />;

  const openTarget = (
    <OpenTarget state={state} onClick={onOpenTargetClick}>
      <OpenButton
        aria-label={formatMessage({
          id: "sidebar.openNavigation",
          defaultMessage: "Open Navigation",
        })}
        aria-controls={sidebarMenuId}
        aria-expanded={isExpanded}
        onClick={onOpenButtonClick}
      >
        {openButtonIcon}
      </OpenButton>
    </OpenTarget>
  );

  const modalOverlay = <ModalOverlay state={state} onClick={onModalOverlayClick} />;

  const hideButtonIcon =
    mode === "MOBILE" ? <IconV2 type="close" size="large" /> : <IconV2 type="bars-left" size="large" />;

  const header = (
    <Header>
      <Link
        // Vertical alignment is a bit off, but we should replace logo with SVG version anyway.
        style={{ marginBottom: "2px" }}
        to={rootPath}
        onClick={onItemClick}
      >
        <Logo color={LogoColor.light} fullLogo={true} height={sidebarTheme.logo.height} />
      </Link>
      <HideButton
        state={state}
        aria-label={formatMessage({
          id: "sidebar.closeNavigation",
          defaultMessage: "Close Navigation",
        })}
        onClick={onHideButtonClick}
      >
        {hideButtonIcon}
      </HideButton>
    </Header>
  );

  const { ref: menuRef } = usePreventBodyScroll<HTMLDivElement>();

  const menu = (
    <MenuContainer state={state}>
      <Menu
        ref={menuRef}
        id={sidebarMenuId}
        state={state}
        // Prevent some instances of body scrolling when using touch to scroll.
        // This isn't a complete answer. See:
        // https://stackoverflow.com/questions/37586286/prevent-overflow-rubberband-scrolling-on-ios
        onTouchMove={(e) => e.stopPropagation()}
        onScroll={(e) => e.stopPropagation()}
      >
        <div>{header}</div>
        {content}
        <FlexGrow />
        {footer}
      </Menu>
    </MenuContainer>
  );

  const [hoverableOpenTarget, isOpenTargetHovered] = useHover(openTarget);
  const [hoverableMenu, isMenuHovered] = useHover(menu);

  useEffect(() => {
    onHoverChange({ isOpenTargetHovered, isMenuHovered });
  }, [onHoverChange, isOpenTargetHovered, isMenuHovered]);

  return (
    <nav>
      {hoverableOpenTarget}
      {modalOverlay}
      {hoverableMenu}
    </nav>
  );
};
