import { isEmpty } from "lodash";

/**
 * Function to determine whether a product has barcodes
 * @param barcodes array of strings
 * @returns false if barcodes is empty, undefined or null and true otherwise
 */
export const hasBarcodes = (barcodes: string[]) => {
  return !isEmpty(barcodes);
};
