import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";
import {
  Channel,
  DeliverrAddress,
  ExternalDestinationType,
  WarehouseOrderType,
  WholesaleOrderDTO,
  WholesaleOrderStatus,
  WholesaleShipmentDTO,
  WholesaleShipmentStatus,
} from "@deliverr/wholesale-client";
import { TransferListItem } from "./store";
import { StorageTransferSource } from "@deliverr/storage-client";
import { DistributionChannel, NonComplianceStatus, Source } from "@deliverr/replenishment-client";

type WholesaleApiData = Pick<WholesaleOrderDTO, "orderItems" | "externalId" | "errors">;

export interface AlgoliaWholesaleRow extends WholesaleApiData {
  name?: string;
  addressAsString: string;
  id: string;
  createdAt: string;
  status: string;
  originWarehouseId?: string;
  numberOfItems?: number;
  createdAutomatically?: boolean;
  source: Source;
  channel?: Channel;
  externalDestinationType?: ExternalDestinationType;
  originWarehouseAddress?: DeliverrAddress;
  orderType: WarehouseOrderType;
  numberOfBoxes?: number;
  numberOfPallets?: number;
  numberOfSkus?: number;
  inboundStatus?: InboundShipmentStatus;
  inboundShippingPlanId?: string;
  inboundShipmentId?: string;
  inboundAsnId?: string;
  shipments?: {
    [shipmentId: string]: {
      originWarehouseId?: string;
      originWarehouseAddress?: DeliverrAddress;
      status: WholesaleShipmentStatus;
      validatedDestinationAddress?: DeliverrAddress | null;
      shipBy?: string | null;
      externalAttributes?: WholesaleShipmentDTO["externalAttributes"];
      destinationWarehouseAddress?: DeliverrAddress;
    };
  };
  validatedDestinationAddress?: DeliverrAddress | null;
  transferSource?: StorageTransferSource;
  version?: string;
  updatedAt: string;
  distributionChannel?: DistributionChannel;
  nonComplianceTypeAndStatus?: string[];
  nonComplianceStatus?: NonComplianceStatus;
}

export const shipmentListItemResponseToTransferListItem = (record: AlgoliaWholesaleRow): TransferListItem => {
  const firstShipment = record.shipments?.[Object.keys(record.shipments)[0]];

  return {
    shipmentId: record.id,
    externalDestinationId: record.externalId,
    createdAutomatically: record.createdAutomatically,
    channel: record.channel,
    externalDestinationType: record.externalDestinationType,
    origin: {
      id: record.originWarehouseId ?? firstShipment?.originWarehouseId ?? "",
      address: record.originWarehouseAddress ?? firstShipment?.originWarehouseAddress,
    },
    shipments: Object.values(record.shipments ?? {}).map((shipment) => ({
      status: shipment.status,
      destinationAddress: shipment.validatedDestinationAddress ?? shipment.destinationWarehouseAddress,
      shipBy: shipment.shipBy,
      externalAttributes: shipment.externalAttributes,
    })),
    destination: record.validatedDestinationAddress,
    status: record.status as WholesaleOrderStatus,
    source: record.source,
    expectedUnits: record.numberOfItems ?? 0,
    createdTime: new Date(record.createdAt),
    orderType: record.orderType,
    items: record.orderItems,
    numberOfBoxes: record.numberOfBoxes ?? 0,
    numberOfPallets: record.numberOfPallets ?? 0,
    numberOfSkus: record.numberOfSkus ?? 0,
    inboundStatus: record.inboundStatus,
    name: record.name,
    inboundShippingPlanId: record.inboundShippingPlanId,
    inboundShipmentId: record.inboundShipmentId,
    inboundAsnId: record.inboundAsnId,
    errors: record.errors,
    transferSource: record.transferSource,
    version: record.version,
    updatedTime: new Date(record.updatedAt),
    distributionChannel: record.distributionChannel,
    nonComplianceStatus: record.nonComplianceStatus,
    nonComplianceTypeAndStatus: record.nonComplianceTypeAndStatus,
  };
};
