import classNames from "classnames/dedupe";
import React, { FC } from "react";
import styled from "@emotion/styled";
import { DefaultTheme } from "common/components/ui";

interface BorderlessButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement> | (() => void);
  className?: string;
  id?: string;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
}

const StyledButton = styled.button<{}, DefaultTheme>`
  border: none;
  background: none;
  padding: 0;
`;

/**
 * Text that triggers an action and is not a link
 */
export const BorderlessButton: FC<BorderlessButtonProps> = ({ onClick, className, id, children, onBlur }) => {
  return (
    <StyledButton id={id} className={classNames(className)} onClick={onClick} type="button" onBlur={onBlur}>
      {children}
    </StyledButton>
  );
};
