import {
  DistributionChannel,
  OrderResponse,
  OrderStatus,
  ShipmentResponse,
  ShipmentStatus,
  ShippingPartner,
  ShippingType,
} from "@deliverr/replenishment-client";
import { isOutOfStockItemUnremoved, isOutOfStockOrder } from "./details/out-of-stock-items/OutOfStockOrderUtils";

export const allowCancelStatus: OrderStatus[] = [OrderStatus.DRAFT, OrderStatus.CREATED, OrderStatus.SUBMITTED];

export const checkIsCancellable = (replenishmentOrder: OrderResponse) =>
  allowCancelStatus.includes(replenishmentOrder.orderStatus);

export const checkIsPartiallyFulfillable = (replenishmentOrder: OrderResponse, warehouseId: string | undefined) =>
  replenishmentOrder.orderStatus === OrderStatus.DRAFT &&
  isOutOfStockOrder(replenishmentOrder) &&
  (!!replenishmentOrder.orderItems?.length || replenishmentOrder?.invalidOrderItems?.some(isOutOfStockItemUnremoved)) &&
  !!warehouseId;

export const shouldShowShipmentInstructions = (shipment: ShipmentResponse, order: OrderResponse) => {
  return (
    (order.distributionChannel === DistributionChannel.FBA_MANUAL ||
      order.distributionChannel === DistributionChannel.WHOLESALE) &&
    shipment.shippingPartner === ShippingPartner.SELLER &&
    (shipment.shippingType === ShippingType.FTL || shipment.shippingType === ShippingType.LTL) &&
    ([ShipmentStatus.CREATED, ShipmentStatus.SUBMITTED] as ShipmentStatus[]).includes(shipment.status)
  );
};

export const shouldShowBookFreight = (shipment: ShipmentResponse, order: OrderResponse) => {
  return (
    (order.distributionChannel === DistributionChannel.FBA_MANUAL ||
      order.distributionChannel === DistributionChannel.WHOLESALE) &&
    shipment.shippingPartner === ShippingPartner.SELLER &&
    (shipment.shippingType === ShippingType.FTL || shipment.shippingType === ShippingType.LTL) &&
    shipment.status === ShipmentStatus.READY_TO_SHIP
  );
};
