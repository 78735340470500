import { WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { Box, Text } from "common/components/ui";
import { map } from "lodash";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { transferCreateChooseShippingConfig } from "../../../actions";
import { transfersShipmentCreateLabels } from "../../../transfersShipmentCreate.labels";
import { FlexRadioTileGroup } from "../../fba/shipping/FlexRadioTileGroup";
import { shippingPartnerMessages } from "./ReplenishmentOrderShippingTileMessages";
import { EstimateMap } from "./useReplenishmentOrderShippingStep";

export const ShipmentPartnerTiles: React.FunctionComponent<{
  shippingEstimation: EstimateMap;
  shippingMethod: string | undefined;
}> = (props) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const onChangeShippingMethod = (value: WholesaleShippingMethod) => {
    dispatch(transferCreateChooseShippingConfig({ shippingMethod: value }));
  };
  return (
    <Box width="95%" paddingBottom="S6" data-testid="shipping-method-selector">
      <Box paddingBottom="S5">
        <Text bold>
          <FormattedMessage {...transfersShipmentCreateLabels.steps.shipping.selectCarrier} />
        </Text>
      </Box>
      <FlexRadioTileGroup
        direction="HORIZONTAL"
        options={map(props.shippingEstimation, (_value, key) => {
          const shippingPartnerMessage = shippingPartnerMessages[key];
          return {
            value: shippingPartnerMessage.value,
            label: formatMessage(shippingPartnerMessage.label),
            content: formatMessage(shippingPartnerMessage.content),
          };
        })}
        currentValue={props.shippingMethod}
        onChange={(evt) => {
          onChangeShippingMethod(evt.currentTarget.value as WholesaleShippingMethod);
        }}
      />
    </Box>
  );
};
