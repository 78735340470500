import React, { useEffect, useState } from "react";
import { FormGroup, Text } from "common/components/ui";
import { defineMessages, useIntl } from "react-intl";
import { PalletsInputLabel, PALLETS_INPUT_NAME } from "./PalletsInput";
import { ControlledNumberInput } from "common/components/reactHookForm";
import { LinkButton } from "common/components/LinkButton";
import { useEstimatePalletCount } from "./useEstimatePalletCount";
import { FieldError } from "react-hook-form";
import { useSimpleFieldValidators } from "common/hooks/reactHookForm/useSimpleFieldValidators";
import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";

const ESTIMATOR_MESSAGES = defineMessages({
  helpText: {
    id: "inbounds.createShipment.shipmentTotals.pallets.helpText",
    defaultMessage: "Don't know how many pallets or sending loose cargo? {estimateButton}.",
  },
  estimateButton: {
    id: "inbounds.createShipment.shipmentTotals.pallets.estimateButton",
    defaultMessage: "Estimate count",
  },
});

const InputLabel = () => (
  <Text bold size="bodyLarge">
    <PalletsInputLabel />
  </Text>
);

export const EstimatablePalletsInput = () => {
  const { formatMessage } = useIntl();
  const { validateRequiredNumber, validateLessThan } = useSimpleFieldValidators();
  const estimatePalletCount = useEstimatePalletCount();
  const { watch, trigger, clearErrors } = useCreateShipmentFormContext();
  const [totalBoxes, totalPallets] = watch([
    CreateShipmentInputName.TOTAL_BOXES,
    CreateShipmentInputName.TOTAL_PALLETS,
  ]);
  // the current RHF version does not provide an appropriate way to watch the error state from context
  const [error, setError] = useState<FieldError>();
  const lessThanErrorMessage = formatMessage({
    id: "inbounds.createShipment.shipmentTotals.pallets.required",
    defaultMessage: "Pallet count should not be more than box count.",
  });

  useEffect(() => {
    if (totalBoxes >= totalPallets) {
      clearErrors(CreateShipmentInputName.TOTAL_PALLETS);
    } else {
      void trigger(CreateShipmentInputName.TOTAL_PALLETS);
    }
  }, [totalBoxes, clearErrors]);

  const estimateButton = (
    <LinkButton onClick={estimatePalletCount}>{formatMessage(ESTIMATOR_MESSAGES.estimateButton)}</LinkButton>
  );

  const estimatorHelpText = (
    <>
      {formatMessage(ESTIMATOR_MESSAGES.helpText, {
        estimateButton,
      })}
    </>
  );

  return (
    <FormGroup label={<InputLabel />} helpText={estimatorHelpText} hasError={!!error} errorMessage={error?.message}>
      <ControlledNumberInput
        name={PALLETS_INPUT_NAME}
        decimalScale={0}
        inputSize="medium"
        min={1}
        onError={setError}
        validate={{
          required: validateRequiredNumber(
            formatMessage({
              id: "inbounds.createShipment.shipmentTotals.pallets.required",
              defaultMessage: "Please enter an estimated number of pallets for this shipment.",
            })
          ),
          lessThan: validateLessThan(totalBoxes, lessThanErrorMessage),
        }}
        dependantValues={totalBoxes}
      />
    </FormGroup>
  );
};
