import { getBoxesLocked, getLoadedPlannedShipment } from "inbounds/steps/InboundStepSelectors";
import { getIsSpdDeliverr } from "inbounds/utils/shippingMethodUtils";
import { createSelector } from "reselect";

export const selectAreBoxDimensionsLocked = createSelector(
  getBoxesLocked,
  getLoadedPlannedShipment,
  (hasBoxesLocked, { estimatedRates, shippingMethod }) =>
    // dimensions aren't required for non-Flexport rates
    !getIsSpdDeliverr(shippingMethod) || (hasBoxesLocked && !!estimatedRates)
);
