import { DefaultTheme, Icon, IconsProps, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import React, { forwardRef } from "react";

type LocalizedLabel = string | JSX.Element | React.ReactNodeArray;
const LabeledItemWrapper = styled.div<ThemeProps>(
  ({ theme }) => `
  dt {
    color: ${theme.colors.NEUTRAL[500]};
    font-weight: ${theme.font.weight.REGULAR};
    & + dd {
      margin-top: ${theme.spacing.S1};
    }
  }
`
);

const PositionedIconContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    position: absolute;
    top: ${theme.spacing.S5};
    right: ${theme.spacing.S5};
    height: ${theme.spacing.S8};
    width: ${theme.spacing.S8};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${theme.colors.INDIGO[100]};
  `
);

const ColoredIcon = styled(Icon)<IconsProps, DefaultTheme>(
  ({ theme }) => `
  .fa-primary {
    color: ${theme.colors.INDIGO[300]};
  }

  .fa-secondary {
    color: ${theme.colors.INDIGO[100]};
  }
`
);

const StyledValue = styled.dd`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledLabel = styled.dt`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface ProblemLabeledItem {
  label?: LocalizedLabel;
  value?: LocalizedLabel;
  className?: string;
  showIcon?: boolean;
}

export const ProblemDetailItem = forwardRef<HTMLDivElement, React.PropsWithChildren<ProblemLabeledItem>>(
  ({ label, value, className, showIcon = false, children }, ref) => (
    <LabeledItemWrapper className={className} ref={ref}>
      {label && <StyledLabel>{label}</StyledLabel>}
      {value && <StyledValue>{value}</StyledValue>}
      {showIcon && (
        <PositionedIconContainer>
          <ColoredIcon type="dollar-circle" size="2x" />
        </PositionedIconContainer>
      )}
      {children}
    </LabeledItemWrapper>
  )
);

export const BigTextProblemDetailItem = styled(ProblemDetailItem)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[500]};
  margin-bottom: ${theme.spacing.S2};
  
  dd {    
      font-size:  ${theme.font.size.F6};
      font-weight: ${theme.font.weight.BOLD};
      font-family: ${theme.font.family.TITLE};
    }
  `
);
