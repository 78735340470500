import { inventoryClient } from "common/clients/instances";
import { ATPMap } from "common/clients/inventory/ATP/ATPMap";
import { ProductCollection } from "common/models";
import { chain, reduce, values } from "lodash";
import { aggregateATP } from "./aggregateATP";
import { ATP } from "common/clients/inventory/ATP/ATP";
import { getDefaultPackFromProduct } from "./getDefaultPackFromProduct";

export const fetchMultiPackProductATP = async (dskus: string[], draftProducts: ProductCollection): Promise<ATPMap> => {
  const packOfDskus = chain(values(draftProducts))
    .map(({ packOf }) => packOf)
    .compact()
    .uniq()
    .value();
  const combinedDskus = [...dskus, ...packOfDskus];
  const productAtps = await inventoryClient.getATP(combinedDskus, undefined, "STORAGE");

  return reduce(
    dskus,
    (acc, dsku) => {
      const parentDsku = draftProducts[dsku]?.packOf;
      if (parentDsku) {
        // This assumes draftProducts will contains both parent and case pack DSKU
        const defaultPack = getDefaultPackFromProduct(draftProducts[parentDsku]);
        if (defaultPack?.dsku === dsku) {
          // aggregate parent and case pack ATP only when current DSKU is a default case pack DSKU
          acc[dsku] = aggregateATP(productAtps?.[parentDsku], productAtps[dsku]) as Readonly<ATP>;
          return acc;
        }
      }
      acc[dsku] = productAtps[dsku];
      return acc;
    },
    {} as ATPMap
  );
};
