import React, { InputHTMLAttributes } from "react";
import styled from "@emotion/styled";

import { RadioTile, RadioTileProps } from "../RadioTile";
import { Direction, DefaultTheme } from "../shared";

export interface RadioTileGroupProps
  extends Pick<RadioTileProps, "onChange" | "name" | "block" | "disabled" | "name" | "className"> {
  direction?: keyof typeof Direction;
  options: RadioTileProps[];
  currentValue?: InputHTMLAttributes<HTMLInputElement>["value"];
  header?: string | JSX.Element;
  showHeader?: boolean;
}

const StyledFieldset = styled.fieldset<Pick<RadioTileGroupProps, "direction" | "block">, DefaultTheme>(
  ({ direction, theme, block }) => `
    display: flex;
    flex-direction: ${direction === "HORIZONTAL" ? "row" : "column"};
    flex-wrap: wrap;
    margin: -${theme.spacing.S2} -${theme.spacing.S3};
    > label {
      margin: ${theme.spacing.S2} ${theme.spacing.S3};
      width: ${block ? "initial" : direction === Direction.HORIZONTAL ? "252px" : "378px"}
    }
`
);

const StyledLegend = styled.legend<Pick<RadioTileGroupProps, "showHeader">, DefaultTheme>(({ showHeader, theme }) =>
  showHeader
    ? `
      margin-left: ${theme.spacing.S3};
      font-size: ${theme.font.size.F3};
      `
    : `
      position: absolute;
      left: -10000px;
      top: auto;
      width: 1px;
      height: 1px;
      overflow: hidden;
    `
);

export const RadioTileGroup: React.FC<RadioTileGroupProps> = ({
  name,
  header,
  showHeader,
  currentValue,
  block,
  direction = Direction.HORIZONTAL,
  options,
  onChange,
  disabled,
  className,
}) => (
  <StyledFieldset
    direction={direction}
    name={name}
    block={block}
    data-testid="radio_group_container"
    className={className}
  >
    <StyledLegend showHeader={showHeader}>{header ?? name}</StyledLegend>
    {options.map((option, i) => {
      const isChildSelected = option.value === currentValue;
      return (
        <RadioTile
          {...option}
          key={`${name}-${option.value}`}
          name={name}
          block={block}
          onChange={onChange}
          checked={isChildSelected}
          tabIndex={isChildSelected || (i === 0 && currentValue === undefined) ? 0 : -1}
          // Can disable individual options or the entire group
          disabled={option.disabled ?? disabled}
        />
      );
    })}
  </StyledFieldset>
);
