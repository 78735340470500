import { useDispatch, useSelector } from "react-redux";
import { SellerProblemType } from "@deliverr/commons-clients/lib/non-compliance/SellerProblemsView";
import { useCallback, useEffect, useState } from "react";
import { getStorageInboundDetail } from "../store";
import { getUnifiedProducts } from "common/wholesale-common/getUnifiedProducts";

export const useFetchUnexpectedSkuCases = () => {
  const dispatch = useDispatch();
  const { storageInboundOrderProblems } = useSelector(getStorageInboundDetail);
  const [unexpectedSkuProblems, setUnexpectedSkuProblems] = useState(
    storageInboundOrderProblems.filter((problem) => problem.type === SellerProblemType.UNEXPECTED_SKU)
  );

  const fetchUnexpectedSkuCases = useCallback(async () => {
    const dskus = storageInboundOrderProblems.map((sellerProblemsDetailView) => sellerProblemsDetailView.dsku!);
    const products = await dispatch(getUnifiedProducts(dskus));

    const newUnexpectedSkuProblems = storageInboundOrderProblems.map((sellerProblemsDetailView) => ({
      ...sellerProblemsDetailView,
      dsku: products[sellerProblemsDetailView.dsku!]?.packOf
        ? products[sellerProblemsDetailView.dsku!]?.packOf
        : sellerProblemsDetailView.dsku,
    }));
    setUnexpectedSkuProblems(newUnexpectedSkuProblems);
  }, [dispatch, storageInboundOrderProblems]);

  useEffect(() => {
    void fetchUnexpectedSkuCases();
  }, [fetchUnexpectedSkuCases]);

  return {
    data: unexpectedSkuProblems,
    isLoading: !unexpectedSkuProblems,
    error: new Error("Failed to fetch unexpected Sku cases"),
    refetch: fetchUnexpectedSkuCases,
  };
};
