export enum Shadow {
  "S1" = "S1",
  "S2" = "S2",
  "S3" = "S3",
  "S4" = "S4",
  "S5" = "S5",
}

export const shadow: Record<Shadow, string> = {
  [Shadow.S1]: "0 1px 2px rgba(0, 1, 0, 0.13)",
  [Shadow.S2]: "0 1px 4px rgba(0, 1, 0, 0.13)",
  [Shadow.S3]: "0 2px 6px rgba(20, 48, 84, 0.2)",
  [Shadow.S4]: "0px 2px 4px 0px rgba(0, 0, 0, 0.05)",
  [Shadow.S5]: "0px 0px 2px 0px rgba(0, 0, 0, 0.05)",
};
