import { transfersShipmentCreateLabels } from "../../transfersShipmentCreate.labels";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ManagedStep } from "common/components/StepContainer";

import { generatePath } from "react-router-dom";
import history from "BrowserHistory";
import { WalmartLogo, getWfsHelpCenterLink } from "./WalmartLogo";
import { Path } from "paths";
import { ListingSolution } from "common/clients/onboarding/ListingSolution";
import { useWalmartChannelIntegrationStatus } from "./useWalmartChannelIntegrationStatus";

export const WfsAuthorizationStep = () => {
  const { formatMessage } = useIntl();

  const { walmartChannel, isWalmartConnected } = useWalmartChannelIntegrationStatus();
  const slsUuid = walmartChannel?.slsUuid;

  return (
    <ManagedStep
      data-testid="create-transfer-wfs-auth"
      header={<WalmartLogo />}
      title={formatMessage(transfersShipmentCreateLabels.steps.wfsIntegration.oauth.title)}
      nextCaption={formatMessage(transfersShipmentCreateLabels.steps.wfsIntegration.next)}
      secondaryButton={formatMessage(transfersShipmentCreateLabels.steps.wfsIntegration.connect)}
      isNextDisabled={!isWalmartConnected}
      onClickSecondary={() => {
        slsUuid
          ? history.push(generatePath(Path.channelsEdit, { slsUuid }))
          : history.push(generatePath(Path.channelsIntegrationSetup, { channel: ListingSolution.WALMARTDIRECT }));
      }}
      subtitle={
        <div>
          <FormattedMessage
            {...transfersShipmentCreateLabels.steps.wfsIntegration.oauth.subtitle1}
            values={{
              learnMore: getWfsHelpCenterLink(),
              br: <br />,
            }}
          />
        </div>
      }
    ></ManagedStep>
  );
};
