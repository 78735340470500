import { FacebookAdSet } from "common/clients/facebook/FacebookAdSet/FacebookAdSet";
import { Reducer } from "redux";
import { adSetsReceived } from "../actions/adSetsReceived";
import { errorSyncingAdSets } from "../actions/errorSyncingAdSets";
import { FacebookActionTypes } from "../actions/FacebookActionTypes";
import { refreshSellerCompleted } from "../actions/refreshSellerCompleted";
import { syncAdSetsAction } from "../actions/syncAdSets";

export const initialAdSets: AdSetSyncState = null;

export type AdSetSyncState = FacebookAdSet[] | null | "syncing" | "error";

export interface WithAdSetSyncState {
  adSets: AdSetSyncState;
}

export type AdSetSyncAction =
  | ReturnType<typeof errorSyncingAdSets | typeof adSetsReceived | typeof refreshSellerCompleted>
  | typeof syncAdSetsAction;

export const adSetsReducer: Reducer<AdSetSyncState, AdSetSyncAction> = (state = initialAdSets, action) => {
  switch (action.type) {
    case FacebookActionTypes.FACEBOOK_ERROR_SYNCING_AD_SETS:
      return "error";
    case FacebookActionTypes.FACEBOOK_START_SYNC_AD_SETS:
      return null;
    case FacebookActionTypes.FACEBOOK_REFRESH_SELLER_COMPLETED:
      return "syncing";
    case FacebookActionTypes.FACEBOOK_AD_SETS_RECEIVED:
      return action.adSets;
    default:
      return state;
  }
};
