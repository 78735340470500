import React from "react";
import { isPrimitive } from "utility-types";

import logger from "Logger";
import { ListItemProps } from "./InstructionListItem";
import { InstructionListOrdinal } from "./InstructionListOrdinal";

const LOWER_CASE_A_CODE = 97;

interface OrdinalDisplayInfo extends ListItemProps {
  icon?: React.ReactNode;
}

export const getOrdinalDisplay = (info: OrdinalDisplayInfo): React.ReactNode | undefined => {
  const {
    icon,
    itemIndex,
    ordinalOptions: { enumeration, ...ordinalProps },
  } = info;
  let displayValue;

  if (enumeration === "none") {
    // icon should ideally be undefined, but return it in the event of accidental "none"
    return icon;
  }

  if (icon === undefined || icon === null) {
    displayValue =
      enumeration === "alpha"
        ? String.fromCharCode(LOWER_CASE_A_CODE + itemIndex) // get alphabet letter
        : itemIndex + 1; // zero-based, so add 1
  } else if (isPrimitive(icon)) {
    displayValue = icon;
  } else if (typeof icon === "function") {
    displayValue = icon(itemIndex);
  } else if (React.isValidElement(icon)) {
    // Return the non-null, non-primitive label immediately
    return icon;
  } else {
    logger.warn({ fn: "InstructionList.getOrdinalDisplay", icon: typeof icon }, "Could not resolve ordinal label.");
    displayValue = itemIndex + 1; // zero-based, so add 1
  }

  return <InstructionListOrdinal {...ordinalProps}>{displayValue}</InstructionListOrdinal>;
};
