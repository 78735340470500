import { OrderResponse } from "@deliverr/replenishment-client";
import { SPThunkAction } from "common/ReduxUtils";
import { FeatureName, isFeatureOn } from "common/Split";
import { fetchMultiPackProductsByDskus } from "storage/utils/fetchMultiPackProductsByDskus";
import { mapReplenishOrderSkusToPackSkus } from "transfers/utils/mapReplenishOrderSkusToPackSkus";

export const populateReplenishmentOrderWithPacks = (
  replenishmentOrder: OrderResponse,
  mergeProductPacks: boolean = false
): SPThunkAction<Promise<OrderResponse>> => async (dispatch) => {
  const isMultiCasePackEnabled = isFeatureOn(FeatureName.StorageMultiCasePack);

  return isMultiCasePackEnabled || mergeProductPacks
    ? await handleMultiCasePack(dispatch, replenishmentOrder)
    : replenishmentOrder;
};

const handleMultiCasePack = async (dispatch, replenishmentOrder: OrderResponse) => {
  const replenishmentOrderItems = replenishmentOrder.orderItems ?? [];
  const orderSkus = (replenishmentOrderItems ?? []).map((item) => item.dsku);

  const unifiedProducts = await fetchMultiPackProductsByDskus(orderSkus, dispatch);

  return mapReplenishOrderSkusToPackSkus(replenishmentOrder, unifiedProducts);
};
