import React from "react";
import { FormattedMessage } from "react-intl";
import { AMAZON_MAX_NUMBER_OF_PALLETS } from "common/constants/fbaValidations.const";
import { transferCreateSetShipmentValidationError } from "transfers/create/actions";
import { ShippingPartner, ShippingType } from "@deliverr/replenishment-client";
import { replenishmentShipmentCreateLabels } from "../../common/replenishment/replenishmentShipmentCreate.labels";

export const estimatedPalletCountFbaIntegrationValidation = (dispatch, estimatedPalletCounts?: number[]) => {
  const labels = replenishmentShipmentCreateLabels.steps.shipping.cargoOptions;

  const defaultTransportValidationErrorState = {
    hasError: true,
    isPending: false,
    disabled: true,
    retryable: false,
  };

  if (estimatedPalletCounts?.some((estimatedPalletCount) => estimatedPalletCount > AMAZON_MAX_NUMBER_OF_PALLETS)) {
    const error = {
      ...defaultTransportValidationErrorState,
      errorMessage: (
        <FormattedMessage
          {...labels.pallet.invalidEstimatedPalletCountForAmazon}
          values={{ numOfPallets: AMAZON_MAX_NUMBER_OF_PALLETS }}
        />
      ),
    };
    dispatch(transferCreateSetShipmentValidationError(ShippingPartner.FBA, ShippingType.LTL, error));
  }
};
