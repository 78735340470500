import React, { FC } from "react";
import styled from "@emotion/styled";
import { defaultTheme, DefaultTheme, Icon } from "common/components/ui";

const IconCircle = styled.div<{ backgroundColor: string }, DefaultTheme>(
  ({ backgroundColor, theme }) => `
  border-radius: 50%;
  background-color: ${backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
`
);

export const PendingIcon: FC = () => (
  <IconCircle backgroundColor={defaultTheme.colors.YELLOW[100]}>
    <Icon type={"hourglass-half-lt"} size="2x" color={defaultTheme.colors.ORANGE[400]} />
  </IconCircle>
);

export const CompletedIcon: FC = () => (
  <IconCircle backgroundColor={defaultTheme.colors.GREEN[100]}>
    <Icon type={"check"} size="1x" fixedWidth color={defaultTheme.colors.GREEN[300]} />
  </IconCircle>
);
