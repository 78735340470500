import React from "react";
import { Notification } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";
import { Appearance } from "common/components/ui/shared";

export const DuplicateConnectionNotice = () => (
  <Notification
    appearance={Appearance.WARNING}
    content={{
      description: (
        <FormattedMessage
          id="onboarding.listingTools.doubleConnectWarning"
          defaultMessage="Make sure that you are not connecting to the same marketplace twice through two different integrations. This may result in duplicate orders."
        />
      ),
    }}
    append={<ElevioLearnMoreButton articleId={ElevioArticleId.DoubleChannelConnection} />}
  />
);
