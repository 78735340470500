import { FC } from "react";
import { MessageDescriptor } from "react-intl";

export enum SelectShippingStepId {
  SELECT_CARRIER = "SELECT_CARRIER",
  ADD_PICKUP_DETAILS = "ADD_PICKUP_DETAILS",
  ADD_SHIPMENT_DETAILS = "ADD_SHIPMENT_DETAILS",
  SELECT_SERVICE = "SELECT_SERVICE",
}

export interface SelectShippingStepType {
  id: SelectShippingStepId;
  title: MessageDescriptor;
  component: FC;
}
