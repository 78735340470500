import { CreateShipmentFormState } from "../../CreateShipmentState";

/**
 * The following fields are not submitted to the booking request, and so they cannot be rehydrated
 * If you need to add a field here temporarily until there is backend support (ex. work is behind a feature flag),
 * please add a comment referencing a Jira task for removing the field from this file and updating the mapping appropriately.
 *
 * Note that the createShipment state includes a "meta" reducer, which can be used for state that should not be persisted.
 */

type UnmappedFields = Pick<CreateShipmentFormState, "domesticDestinationType" | "isOwnTransportation">;

export const unmappedFields: UnmappedFields = {
  domesticDestinationType: null,
  isOwnTransportation: false,
};
