import { Button, ButtonProps, DefaultTheme, Text, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { SIMPLE_PREP_PAGE_LABELS } from "./SimplePrepPage.labels";
import { PREP_REQUEST_FORM_LINK } from "prep/prep.const";
import { PrepStepTitle } from "prep/components/PrepElements";

export const StyledSection = styled.section<ThemeProps>(
  ({ theme }) => `
  background-color: ${theme.colors.NEUTRAL["20"]};
  border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL["80"]};
  padding: ${theme.spacing.S5};
`
);

export const StyledTitle = styled(PrepStepTitle)<ThemeProps>(
  ({ theme }) => `
  margin: 0;
  margin-bottom: ${theme.spacing.S1};
`
);

const StyledButton = styled(Button)<ButtonProps, DefaultTheme>(
  ({ theme }) => `
    display: inline-flex;
    margin-top: ${theme.spacing.S4};
    padding: ${theme.spacing.S2} ${theme.spacing.S4};
    align-items: center;
  `
);

const PositionedIcon = styled(FontAwesomeIcon)<ThemeProps>(
  ({ theme }) => `
    margin-left: ${theme.spacing.S2};  
  `
);

export const PrepCustomizedServicesSection: FC = () => {
  const handleClick = () => window.open(PREP_REQUEST_FORM_LINK, "_blank", "width=500,height=622");

  return (
    <StyledSection>
      <StyledTitle>
        <FormattedMessage {...SIMPLE_PREP_PAGE_LABELS.customizedServicesTitle} />
      </StyledTitle>
      <Text>
        <FormattedMessage {...SIMPLE_PREP_PAGE_LABELS.customizedServicesDescription} />
      </Text>
      <StyledButton onClick={handleClick} secondary>
        <FormattedMessage {...SIMPLE_PREP_PAGE_LABELS.formLink} />
        <PositionedIcon icon="external-link-alt" />
      </StyledButton>
    </StyledSection>
  );
};
