import { ShortMonthDateYear } from "common/date/DateFormat";
import { format } from "date-fns-tz";
import { useIntl } from "react-intl";
import { CellProps, Column } from "react-table";
import { MissingBoxesAlertSummary } from "@deliverr/commons-clients";

import { SHORT_SHIP_PACKAGES_TABLE_LABELS } from "./labels";
import { fromLooseDate } from "common/date/LooseDate";

export interface ShortShipPackagesColumn extends Record<string, unknown> {
  dateReceived: Date;
  arrivedQty: string;
  discrepancyQty: number;
}

export const useShortShipPackagesTable = ({ summary }: { summary: MissingBoxesAlertSummary }) => {
  const { formatMessage, formatNumber } = useIntl();
  const { createdAt, expectedBoxQty, arrivedBoxQty } = summary;

  const FractionCell = ({ value }: CellProps<ShortShipPackagesColumn>) =>
    formatMessage({
      id: "inbounds.shipmentProblems.shortShipBlade.packagesTable.arrivedQtyValue",
      defaultMessage: value,
    });
  const NumberCell = ({ value }: CellProps<ShortShipPackagesColumn>) => formatNumber(value);

  const columns: Column<ShortShipPackagesColumn>[] = [
    {
      id: "dateReceived",
      accessor: "dateReceived",
      Header: formatMessage(SHORT_SHIP_PACKAGES_TABLE_LABELS.alertSent),
      Cell: ({ value }) => format(fromLooseDate(value), ShortMonthDateYear),
    },
    {
      id: "arrivedQty",
      accessor: "arrivedQty",
      Header: formatMessage(SHORT_SHIP_PACKAGES_TABLE_LABELS.arrivedQty),
      Cell: FractionCell,
    },
    {
      id: "discrepancyQty",
      accessor: "discrepancyQty",
      Header: formatMessage(SHORT_SHIP_PACKAGES_TABLE_LABELS.discrepancyQty),
      Cell: NumberCell,
    },
  ];

  const data: ShortShipPackagesColumn[] = [
    {
      dateReceived: createdAt,
      arrivedQty: `${arrivedBoxQty}/${expectedBoxQty}`,
      discrepancyQty: arrivedBoxQty - expectedBoxQty,
    },
  ];

  return {
    data,
    columns,
  };
};
