import React from "react";
import { useAdvancedAppointmentSchedulingDetail } from "./useAdvancedAppointmentSchedulingDetail";
import { CarrierEmailInputScreen } from "./CarrierEmailInputScreen";
import { SendToAnotherEmailScreen } from "./SendToAnotherEmailScreen";

export const AdvancedAppointmentSchedulingDetail: React.FC = () => {
  const {
    onSubmit,
    showAppointmentEmailInputScreen,
    carrierEmail,
    notifyCarrierDisabled,
    sendToAnotherEmailClick,
    emailInputPlaceHolder,
    onCarrierEmailChange,
    handleBlur,
    isError,
    handleFocus,
  } = useAdvancedAppointmentSchedulingDetail();

  const carrierEmailInputProps = {
    onSubmit,
    showAppointmentEmailInputScreen,
    carrierEmail,
    notifyCarrierDisabled,
    emailInputPlaceHolder,
    onCarrierEmailChange,
    handleBlur,
    isError,
    handleFocus,
  };

  const sendToAnotherEmailScreenProps = {
    carrierEmail,
    sendToAnotherEmailClick,
  };

  return showAppointmentEmailInputScreen ? (
    <CarrierEmailInputScreen {...carrierEmailInputProps} />
  ) : (
    <SendToAnotherEmailScreen {...sendToAnotherEmailScreenProps} />
  );
};
