import { ListingSolution } from "common/clients/onboarding/ListingSolution";
import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { OnboardingActionTypes } from "onboarding/OnboardingActions";

export interface OnboardingState {
  currentStep: number;
  isOnboarding: boolean;
  signupError?: string;
  listingTool?: ListingSolution;
  isChecklistBladeOpen?: boolean;
}

export const onboardingInitialState: OnboardingState = {
  currentStep: 0,
  isOnboarding: true,
  listingTool: undefined,
  isChecklistBladeOpen: false,
};

const reducers: ReducerDictionary<OnboardingState> = {
  ...handleSimpleReducerUpdates([
    OnboardingActionTypes.SIGNUP_ERROR,
    OnboardingActionTypes.SIGNUP_SELECT,
    OnboardingActionTypes.SET_IS_CHECKLIST_BLADE_OPEN,
  ]),
  [OnboardingActionTypes.SIGNUP_SUCCESS]: (state) => ({
    ...state,
    signupComplete: true,
  }),
};

export const onboardingReducer = createReducer<OnboardingState>(onboardingInitialState, reducers);
