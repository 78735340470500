import asyncRetry from "async-retry";

declare const RETRIES: number;
declare const RETRY_FACTOR: number;
declare const RETRY_MIN_TIMEOUT: number;

const RETRY_DEFAULTS: asyncRetry.Options = {
  retries: RETRIES,
  factor: RETRY_FACTOR,
  minTimeout: RETRY_MIN_TIMEOUT,
};

// specify shared defaults and handle tests without timing out
export const retry = async <T>(fn: asyncRetry.RetryFunction<T>, options?: asyncRetry.Options): Promise<T> =>
  await asyncRetry<T>(fn, { ...RETRY_DEFAULTS, ...options });
