import React from "react";
import { SPThunkAction } from "common/ReduxUtils";
import { logError } from "Logger";
import { StorageInboundDetailActionTypes } from "../StorageInboundDetailStore.types";
import { toast } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import { renameShippingPlan } from "inbounds/store/actions/shippingPlan/renameShippingPlan";
import { StorageInboundResponse } from "@deliverr/storage-client";

export const updateStorageInboundOrder =
  (storageInboundOrder: StorageInboundResponse): SPThunkAction =>
  async (dispatch) => {
    const { shippingPlanName = "", sellerId, shippingPlanId } = storageInboundOrder;
    try {
      await dispatch(
        renameShippingPlan({
          name: shippingPlanName,
          sellerId,
          shippingPlanId,
        })
      );
      dispatch({
        type: StorageInboundDetailActionTypes.STORAGE_UPDATE_ITEM_DETAILS_SUCCESS,
        storageInboundOrder,
      });
    } catch (err) {
      logError({ fn: "updateStorageInboundOrder", sellerId, shippingPlanId }, err);
      dispatch({ type: StorageInboundDetailActionTypes.STORAGE_UPDATE_ITEM_DETAILS_ERROR, err });
      toast.error(
        <FormattedMessage
          id={"storage.inbounds.updateStorageInboundOrder.error"}
          defaultMessage={"Update failed, please try again!"}
        />
      );
    }
  };
