import { FormattedMessage, MessageDescriptor } from "react-intl";
import React, { FC } from "react";

import { RefundStatus } from "@deliverr/returns-client";
import { Title } from "common/components/ui";
import styled from "@emotion/styled";

const StyledTitle = styled(Title)`
  margin: 0;
`;

const refundedTitle = {
  id: "returns.returnsDetail.returnRefundStatusTitle.refunded",
  defaultMessage: "Refunded",
};

const notRefundedTitle = {
  id: "returns.returnsDetail.returnRefundStatusTitle.notRefunded",
  defaultMessage: "Not Refunded",
};

const notInitiatedTitle = {
  id: "returns.returnsDetail.returnRefundStatusTitle.notInitiated",
  defaultMessage: "Not Initiated",
};

const refundInitiatedTitle = {
  id: "returns.returnsDetail.returnRefundStatusTitle.refundInitiated",
  defaultMessage: "Processing refund",
};

export const refundStatusTitle: Record<string, MessageDescriptor> = {
  [RefundStatus.REFUNDED]: refundedTitle,
  [RefundStatus.REFUND_NOT_INITIATED]: notInitiatedTitle,
  [RefundStatus.REFUND_INITIATED]: refundInitiatedTitle,
  [RefundStatus.NOT_REFUNDED]: notRefundedTitle,
};

export const RefundStatusTitle: FC<{ refundStatus: RefundStatus }> = ({ refundStatus }) => {
  const { id, defaultMessage } = refundStatusTitle[refundStatus];
  return (
    <div>
      <StyledTitle as="h4" displayAs="h4" data-testid="refund-status-details-title">
        <FormattedMessage id={id} defaultMessage={defaultMessage} />
      </StyledTitle>
    </div>
  );
};
