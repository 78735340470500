import { UnconnectedViewShipmentSummary } from "inbounds/steps/ship/view/ViewShipmentSummary";
import React from "react";
import { useStorageShipmentSummary } from "./useStorageShipmentSummary";

export const StorageShipmentSummary: React.FC = () => {
  const {
    numOfSkus,
    numOfUnits,
    poNumber,
    adminViewPONumbers,
    createdAt,
    loadedShipmentId,
    shippingMethod,
    shouldShowAdminFeatures,
  } = useStorageShipmentSummary();

  return (
    <UnconnectedViewShipmentSummary
      numOfSkus={numOfSkus}
      numOfUnits={numOfUnits}
      poNumber={poNumber}
      adminViewPONumbers={adminViewPONumbers}
      createdAt={createdAt}
      loadedShipmentId={loadedShipmentId}
      shippingMethod={shippingMethod}
      shouldShowAdminFeatures={shouldShowAdminFeatures}
    />
  );
};
