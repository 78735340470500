import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Input, Stack, Text, Button, Box, BoxReset } from "common/components/ui";
import { useAddContainerId } from "./useAddContainerId";
import { LoadingSpinner } from "common/components/LoadingSpinner";

export const AddContainerId: React.FC = () => {
  const {
    updateContainerId,
    onUpdateClick,
    containerId,
    updateContainerIdState,
    isUpdateDisabled,
  } = useAddContainerId();
  const { formatMessage } = useIntl();
  const placeholderText = formatMessage({
    id: "storage.inbounds.detail.shipment.instruction.containerId.placeholder",
    defaultMessage: "Container ID (optional)",
  });

  return (
    <Stack direction="VERTICAL">
      <Box>
        <BoxReset width="22rem">
          <Input placeholder={placeholderText} value={containerId} onChange={updateContainerId} />
        </BoxReset>
        <BoxReset marginBottom={"S4"}>
          <Text size="caption">
            <FormattedMessage
              id={"storage.inbounds.detail.shipment.instruction.containerId.text"}
              defaultMessage="Container IDs help Flexport receive your inventory up to 3 days earlier."
            />
          </Text>
        </BoxReset>

        <Button secondary onClick={onUpdateClick} disabled={isUpdateDisabled}>
          {updateContainerIdState.loading ? (
            <LoadingSpinner />
          ) : (
            <FormattedMessage
              id="storage.inbounds.detail.shipment.instruction.containerId.update"
              defaultMessage="Update"
            />
          )}
        </Button>
      </Box>
    </Stack>
  );
};
