import { transfersShipmentCreateLabels } from "../../transfersShipmentCreate.labels";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ManagedStep } from "common/components/StepContainer";
import { Appearance, BorderIcon, Box } from "common/components/ui";
import { selectFbaIntegration } from "../../../fbaIntegration/store/FbaIntegrationSelectors";
import { useSelector } from "react-redux";
import { AmazonErrorCode } from "@deliverr/replenishment-client";
import { AmazonErrorCodeErrorsLabels, AmazonErrorLabels } from "../../../fbaIntegration/AmazonErrorCodeErrorsLabels";
import { ErrorInfo } from "transfers/fbaIntegration/store/FbaIntegrationState";

const ERROR_SPECIFIC_URLS = new Map<AmazonErrorCode, string>([
  [AmazonErrorCode.MISSING_DIMENSIONS, "https://sellercentral.amazon.com/help/hub/reference/G37G73BJXHF75ACH"],
  [AmazonErrorCode.ITEM_STRANDED, "https://sellercentral.amazon.com/inventoryplanning/stranded-inventory"],
]);

const getPresentableErrorInfo = (errorInfos: ErrorInfo[] | undefined): ErrorInfo | undefined => {
  return (errorInfos ?? []).find((errorInfo) => AmazonErrorCodeErrorsLabels[errorInfo.errorCode] !== undefined);
};

function processAdditionalInfo(errorInfo?: ErrorInfo): string {
  let originalInfo = errorInfo?.additionalInfo ?? "";
  // TODO: removed after this is fixed https://github.com/deliverr/wholesale-forwarding/issues/181
  originalInfo = originalInfo.replace(/"/g, "");
  return originalInfo;
}

export const FbaShipmentCreationError = () => {
  const { formatMessage } = useIntl();
  const { amazonErrors } = useSelector(selectFbaIntegration);
  const errorInfo: ErrorInfo | undefined = getPresentableErrorInfo(amazonErrors);
  const specificErrorMessage = errorInfo
    ? AmazonErrorCodeErrorsLabels[errorInfo.errorCode] ?? AmazonErrorLabels.default
    : AmazonErrorLabels.default;
  const additionalInfo = processAdditionalInfo(errorInfo);
  const externalLink = errorInfo ? ERROR_SPECIFIC_URLS.get(errorInfo.errorCode) : undefined;

  return (
    <ManagedStep
      title={formatMessage(transfersShipmentCreateLabels.steps.fbaIntegration.shipmentCreationErrors.title)}
      subtitle={
        <Box maxWidth="32rem">
          <FormattedMessage
            {...transfersShipmentCreateLabels.steps.fbaIntegration.shipmentCreationErrors.subtitle}
            values={{
              errorMessage: (
                <FormattedMessage
                  {...specificErrorMessage}
                  values={{
                    tryAgain: <FormattedMessage {...AmazonErrorLabels.tryAgain} />,
                    additionalInfo,
                    a: (text) => {
                      return (
                        <a href={externalLink} target="_blank noreferrer">
                          {text}
                        </a>
                      );
                    },
                  }}
                />
              ),
            }}
          />
        </Box>
      }
      header={<BorderIcon appearance={Appearance.DANGER} iconType="exclamation-triangle" size="XXL" />}
      isDone={true}
      hasBack={false}
      isNextDisabled={false}
    />
  );
};
