import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAsyncFn } from "react-use";
import { createSelector } from "reselect";

import { notifyUserOfError } from "common/ErrorToast";
import { getSellerId } from "common/user/UserSelectors";
import { logError } from "Logger";
import {
  adAccountSelected,
  getAdAccounts as getFacebookAdAccounts,
} from "tags/facebook/connect/actions/FacebookActions";
import { adAccounts as facebookAdAccounts, facebookState } from "tags/facebook/connect/state/FacebookSelectors";

export const useAdAccountsSetting = () => {
  const dispatch = useDispatch();
  const sellerId = useSelector(getSellerId);
  const adAccounts = useSelector(facebookAdAccounts);
  const activeAdAccountId = useSelector(createSelector(facebookState, (facebook) => facebook.activeAdAccountId));

  const adAccountOptions = useMemo(
    () =>
      adAccounts?.map(({ businessName, adAccountId }) => ({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        label: businessName ? `${businessName} (${adAccountId})` : adAccountId,
        value: adAccountId,
      })),
    [adAccounts]
  );
  const selectedAdAccount = useMemo(() => adAccountOptions?.find(({ value }) => value === activeAdAccountId), [
    activeAdAccountId,
    adAccountOptions,
  ]);

  const getAdAccounts = async (userId: string, accessToken: string) => {
    try {
      await dispatch(getFacebookAdAccounts(sellerId, userId, accessToken));
    } catch (err) {
      logError({ fn: "FacebookFastTag.AdSettings.getAdAccounts" }, err);

      notifyUserOfError({
        toastId: "facebookGetAdAccountsError",
        translation: {
          id: "FacebookFastTag.GetAdAccountsError",
          defaultMessage: "An error occured while trying to get your Facebook ad accounts",
        },
      });
    }
  };

  const [, onAdAccountSelected] = useAsyncFn(async (option) => {
    const { value: adAccountId } = option;

    dispatch(adAccountSelected(adAccountId));
  });

  return {
    activeAdAccountId,
    adAccounts,
    adAccountOptions,
    getAdAccounts,
    onAdAccountSelected,
    selectedAdAccount,
  };
};
