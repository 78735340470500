import React from "react";
import { ControlledNumberInput } from "common/components/reactHookForm";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { defineMessages, FormattedMessage } from "react-intl";
import { InfoIcon } from "common/components/icons";

const INPUT_NAME = CreateShipmentInputName.TOTAL_BOXES;

const MESSAGES = defineMessages({
  label: {
    id: "createShipment.shipmentTotals.boxes.label",
    defaultMessage: "Boxes {tooltip}",
  },
  tooltip: {
    id: "createShipment.shipmentTotals.boxes.tooltip",
    defaultMessage:
      "Box count is used to provide an accurate price. You can get this info from your supplier or provide an estimate.",
  },
});

const Tooltip = () => (
  <InfoIcon messageWidth={200} wrap placement={"top"} message={<FormattedMessage {...MESSAGES.tooltip} />} />
);

export const BoxesInputLabel = () => (
  <FormattedMessage
    {...MESSAGES.label}
    values={{
      tooltip: <Tooltip />,
    }}
  />
);

export const BoxesInput = () => (
  <ControlledNumberInput name={INPUT_NAME} label={<BoxesInputLabel />} decimalScale={0} inputSize="medium" required />
);
