import { createReducer, ReducerDictionary, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { StorageSellerConfig } from "common/clients/storage/StorageJit/StorageSellerConfig";
import { JITReplenishmentStrategy } from "common/clients/storage/StorageJit/JITReplenishmentStrategy";
import { InventoryActionTypes } from "./InventoryActions.types";
import { ReplenishmentStrategy } from "common/clients/storage/StorageJit/ReplenishmentStrategy";
import { StorageRecommendationSellerConfig } from "common/clients/storage/StorageJit/StorageRecommendationSellerConfig";
import { defaultLeadTimeWeeks, defaultReplenishMonths } from "./JITRecommendationsConfigOptions";
import { StorageSellerChannelRecommendationConfig } from "common/clients/rsip/RSIPJit/StorageSellerRecommendationConfig";

interface StorageChannelRecommendationConfigState {
  sellerFBAConfig: Omit<StorageSellerChannelRecommendationConfig, "distributionChannel">;
  sellerWFSConfig: Omit<StorageSellerChannelRecommendationConfig, "shouldAutoConfirmTransfer" | "distributionChannel">;
}

export interface InventoryState {
  jitConfig: StorageSellerConfig;
  replenishmentStrategies: JITReplenishmentStrategy[];
  recommnendationSellerConfig: StorageChannelRecommendationConfigState;
  storageRecommendationSellerConfig: StorageRecommendationSellerConfig;
}

export const initialInventoryState: InventoryState = {
  jitConfig: {
    isJitEnabled: false,
    replenishmentStrategy: ReplenishmentStrategy.DYNAMIC,
    shouldAutoConfirmTransfer: false,
    autoConfirmAfterHours: 0,
    autoConfirmUpdatedAt: null,
  },
  replenishmentStrategies: [],
  recommnendationSellerConfig: {
    sellerFBAConfig: { isActive: false, shouldAutoConfirmTransfer: false, autoConfirmUpdatedAt: null },
    sellerWFSConfig: { isActive: false },
  },
  storageRecommendationSellerConfig: {
    isEnabled: false,
    leadTimeWeeks: defaultLeadTimeWeeks,
    replenishMonths: defaultReplenishMonths,
  },
};

const reducers: ReducerDictionary<InventoryState> = {
  ...handleSimpleReducerUpdates([
    InventoryActionTypes.SET_JIT_REPLENISHMENT_STRATEGIES,
    InventoryActionTypes.SET_STORAGE_JIT_CONFIG,
    InventoryActionTypes.UPDATE_STORAGE_JIT_CONFIG,
  ]),
};

export const inventoryReducer = createReducer<InventoryState>(initialInventoryState, reducers);
