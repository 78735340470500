import { FreightMode } from "@deliverr/freight-client";
import { FreightPallet } from "./FreightPallet";

export interface CargoPalletDetailsState {
  totalPalletCount: number | undefined;
  totalPalletWeight: number | undefined;
  palletLineItems: FreightPallet[];
  type?: FreightMode;
}

export enum CargoPalletInputNames {
  TOTAL_PALLET_COUNT = "TOTAL_PALLET_COUNT",
  TOTAL_PALLET_WEIGHT = "TOTAL_PALLET_WEIGHT",
}

export interface CargoPalletInputValues {
  [CargoPalletInputNames.TOTAL_PALLET_COUNT]: number;
  [CargoPalletInputNames.TOTAL_PALLET_WEIGHT]: number;
}
