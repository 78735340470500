import * as React from "react";
import { icon } from "../../common";

export default icon("0 0 24 24")(
  <>
    <g clipPath="url(#clip0_10443_31002)">
      <path d="M0.688742 22.7199H12.7152C13.1038 22.7199 13.404 22.4385 13.404 22.0743C13.404 21.71 13.1038 21.4286 12.7152 21.4286H0.688742C0.300221 21.4286 0 21.71 0 22.0743C0 22.4385 0.300221 22.7199 0.688742 22.7199Z" />
      <path d="M23.7531 6.89691C23.2586 6.05256 22.1107 5.75456 21.21 6.21812L15.4882 9.21474L4.38001 6.21812C3.83255 6.06912 3.21445 6.33401 2.98487 6.83069C2.77295 7.31081 2.93189 7.87371 3.35573 8.18827L9.44845 12.3604L6.74646 13.751C6.12836 14.0656 5.42196 14.1649 4.73322 14.0159L1.16589 13.2378C0.671405 13.1219 0.176924 13.4034 0.0356434 13.8504C-0.0703169 14.1815 0.0533034 14.5292 0.335864 14.7775L2.31379 16.4331C2.57869 16.6483 2.89657 16.7642 3.24977 16.7642H8.24756C8.4948 16.7642 8.72439 16.7145 8.93631 16.5987L23.029 9.28096C23.9297 8.8174 24.2476 7.74126 23.7531 6.89691Z" />
    </g>
    <defs>
      <clipPath id="clip0_10443_31002">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </>
);
