/* eslint-disable @typescript-eslint/promise-function-async */
import { createLoadable } from "chunks";

export const HazmatApproval = createLoadable(
  () => import(/* webpackChunkName: "MsdsApproval" */ "./HazmatApproval/HazmatApproval")
);

export const DebugDisplay = createLoadable(
  () => import(/* webpackChunkName: "DebugDisplay" */ "./DebugDisplay/DebugDisplay")
);
