// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BarcodeConfirmModal__modal--OaJX0 .modal-dialog {\n  width: 750px;\n  min-height: 540px;\n}\n.BarcodeConfirmModal__content--Xtl__ {\n  padding: 40px;\n  padding-top: 20px;\n}\n.BarcodeConfirmModal__bodyLine--T8abv {\n  width: 460px;\n  margin-bottom: 15px;\n}\n.BarcodeConfirmModal__tableContainer--fH4y1 {\n  max-height: 320px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  margin-bottom: 40px;\n}\n.BarcodeConfirmModal__barcodeTable--x8_8n {\n  width: 650px;\n  text-align: left;\n}\n.BarcodeConfirmModal__barcodeTable--x8_8n th:first-child,\n.BarcodeConfirmModal__barcodeTable--x8_8n td:first-child {\n  -ms-flex-preferred-size: 65%;\n      flex-basis: 65%;\n}\n.BarcodeConfirmModal__barcodeTable--x8_8n th:nth-child(2),\n.BarcodeConfirmModal__barcodeTable--x8_8n td:nth-child(2) {\n  -ms-flex-preferred-size: 35%;\n      flex-basis: 35%;\n  margin-left: 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/app/inbounds/steps/barcode/confirm/BarcodeConfirmModal.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;AACF;AAEA;EACE,aAAA;EACA,iBAAA;AAAF;AAGA;EACE,YAAA;EACA,mBAAA;AADF;AAIA;EACE,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AAFF;AAKA;EACE,YAAA;EACA,gBAAA;AAHF;AAOI;;EACE,4BAAA;MAAA,eAAA;AAJN;AAOI;;EACE,4BAAA;MAAA,eAAA;EACA,iBAAA;AAJN","sourcesContent":[".modal :global(.modal-dialog) {\n  width: 750px;\n  min-height: 540px;\n}\n\n.content {\n  padding: 40px;\n  padding-top: 20px;\n}\n\n.bodyLine {\n  width: 460px;\n  margin-bottom: 15px;\n}\n\n.tableContainer {\n  max-height: 320px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  margin-bottom: 40px;\n}\n\n.barcodeTable {\n  width: 650px;\n  text-align: left;\n\n  & th,\n  td {\n    &:first-child {\n      flex-basis: 65%;\n    }\n\n    &:nth-child(2) {\n      flex-basis: 35%;\n      margin-left: 15px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "BarcodeConfirmModal__modal--OaJX0",
	"content": "BarcodeConfirmModal__content--Xtl__",
	"bodyLine": "BarcodeConfirmModal__bodyLine--T8abv",
	"tableContainer": "BarcodeConfirmModal__tableContainer--fH4y1",
	"barcodeTable": "BarcodeConfirmModal__barcodeTable--x8_8n"
};
export default ___CSS_LOADER_EXPORT___;
