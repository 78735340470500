import { ApiError, DeliverrError } from "@deliverr/errors";
import { AxiosError } from "axios";
import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { Settings } from "./Settings/Settings";
import { SettingsExceptions } from "./SettingsExceptions";

export type SellerClientConfig = ApiClientConfig;

export class SellerClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: SellerClientConfig = { baseURL: mustBeDefined(process.env.SELLER_SERVICE_URL) }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`,
    });
  }

  async getSettings(sellerId: string): Promise<Settings> {
    try {
      return await this.apiClient.get({
        url: `/settings/${sellerId}`,
        authentication: isAuthenticated,
      });
    } catch (error) {
      this.handleSellerError("getSettings", error);
    }
  }

  async createOrUpdateSettings(sellerId: string, settings: Settings): Promise<void> {
    try {
      await this.apiClient.put({
        url: `/settings/${sellerId}`,
        body: settings,
        authentication: isAuthenticated,
      });
    } catch (error) {
      this.handleSellerError("createOrUpdateSettings", error);
    }
  }

  private handleSellerError(functionName: string, err: AxiosError): never {
    if (!err.response) {
      const unknownError = new DeliverrError({
        code: ApiError.UNKNOWN_ERROR,
        functionName,
        payload: err,
      });
      throw unknownError;
    }

    switch (err.response.status) {
      case 400:
        throw new DeliverrError({
          code: SettingsExceptions.INVALID_SETTINGS,
          functionName,
        });
      case 403:
        throw new DeliverrError({
          code: SettingsExceptions.UNAUTHORIZED_ACCESS,
          functionName,
        });
      case 504:
        throw new DeliverrError({
          code: ApiError.NETWORK_ERROR,
          functionName,
        });
      default:
        throw new DeliverrError({
          code: ApiError.UNKNOWN_ERROR,
          functionName,
          payload: err,
        });
    }
  }

  private handleSellerServiceError(functionName: string, err: AxiosError) {
    if (!err.response) {
      throw new DeliverrError({
        code: ApiError.UNKNOWN_ERROR,
        functionName,
        payload: err,
      });
    }

    if (err.response.data.subcode) {
      throw new DeliverrError({
        code: err.response.data.subcode,
        functionName,
      });
    } else {
      throw new DeliverrError({
        code: err.response.data.subcode,
        functionName,
        payload: err,
      });
    }
  }
}
