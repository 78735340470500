import { InputError } from "common/components/InputError";
import { Pack } from "common/clients/storage/Packs/Pack";
import { SelectedProductData } from "common/components/ProductChooser/SelectedProductData";
import { removeAllEmTags } from "common/StringUtils";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { extractPacksDims } from "storage/packUtils/extractPacksDims";
import { getNumberOrZeroFromString } from "common/utils/string/getNumberOrZeroFromString";
import { Appearance } from "common/components/ui";
import { validateWeightAndDims } from "storage/packUtils/validateWeightAndDims";

interface PackInfoProps {
  product: Pick<Pack, "caseQty" | "weight" | "width" | "height" | "length" | "packName">;
  checkCaseConfigValidation?: boolean;
}

export const PackInfo: FC<PackInfoProps> = ({ product, checkCaseConfigValidation }) => {
  const packDims = extractPacksDims(product);

  return product ? (
    <>
      {product.packName ? (
        <FormattedMessage
          id="productSearchOption.packWithName.caseQty"
          defaultMessage="{packName} · {height} x {width} x {length} in · {weight, plural, one {# lb} other {# lbs}}"
          values={{
            ...packDims,
            packName: removeAllEmTags(product.packName),
          }}
        />
      ) : (
        <FormattedMessage
          id="productSearchOption.packWithoutName.caseQty"
          defaultMessage="{caseQty, plural, one {# unit} other {# units}} per box · {height} x {width} x {length} in · {weight, plural, one {# lb} other {# lbs}}"
          values={{
            ...packDims,
            caseQty: getNumberOrZeroFromString(product.caseQty?.toString()),
          }}
        />
      )}
      {checkCaseConfigValidation && validateWeightAndDims(packDims) && (
        <>
          <br />
          <InputError
            appearance={Appearance.DANGER}
            message={
              <FormattedMessage
                id="inbounds.selectedProduct.caseConfig.invalid.support"
                defaultMessage="Case dimensions and weight is invalid. Contact Support"
              />
            }
          />
        </>
      )}
    </>
  ) : null;
};

export const getPackDimsDescription = (product: SelectedProductData) =>
  `${product.height} x ${product.width} x ${product.length} in · ${product.weight} lbs`;
