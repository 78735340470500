import {
  FBA_MAX_CASE_DIMENSION_IN,
  FBA_MAX_CASE_WEIGHT_LB,
  FBA_MAX_QUANTITY_IN_CASE,
} from "common/constants/fbaValidations.const";
import { ProductListItem } from "inventory/list/InventoryListReducer";

export const isLargeCasePackForFBA = ({ caseQty, length, width, height, weight }: Partial<ProductListItem>) =>
  (!!caseQty && caseQty > FBA_MAX_QUANTITY_IN_CASE) ||
  (!!length && length > FBA_MAX_CASE_DIMENSION_IN) ||
  (!!width && width > FBA_MAX_CASE_DIMENSION_IN) ||
  (!!height && height > FBA_MAX_CASE_DIMENSION_IN) ||
  (!!weight && weight > FBA_MAX_CASE_WEIGHT_LB);
