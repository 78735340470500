import { SellerProblemStatus } from "@deliverr/legacy-inbound-client";
import { defineMessages } from "react-intl";

export const PROBLEM_BLADE_STATUS_LABELS = defineMessages({
  [SellerProblemStatus.CREATED]: {
    header: {
      id: "inbounds.problemBladeStatusSection.created.header",
      defaultMessage: "Submission received",
    },
    message: {
      id: "inbounds.problemBladeStatusSection.created.message",
      defaultMessage:
        "This product will be received by the fulfillment center as soon as possible. This may take up to 3 weeks.",
    },
  },
  [SellerProblemStatus.ACTION_NEEDED]: {
    header: {
      id: "inbounds.problemBladeStatusSection.actionNeeded.header",
      defaultMessage: "Submission received",
    },
    message: {
      id: "inbounds.problemBladeStatusSection.actionNeeded.message",
      defaultMessage:
        "This product will be received by the fulfillment center as soon as possible. This may take up to 3 weeks.",
    },
  },
  [SellerProblemStatus.SUPPORT_CONTACTED]: {
    header: {
      id: "inbounds.problemBladeStatusSection.supportContacted.header",
      defaultMessage: "Submission received",
    },
    message: {
      id: "inbounds.problemBladeStatusSection.supportContacted.message",
      defaultMessage: "It may take up to 3 weeks for units to become available for sale.",
    },
  },
  [SellerProblemStatus.RESOLVED]: {
    header: {
      id: "inbounds.problemBladeStatusSection.resolved.header",
      defaultMessage: "Products will be received",
    },
    message: {
      id: "inbounds.problemBladeStatusSection.resolved.message",
      defaultMessage: "These units will be available for sale at fulfillment centers.",
    },
  },
});
