import { defineMessages } from "react-intl";
import { ShipmentActionsMenuLabels } from "../../common/wholesale-common/components/ShipmentActionsMenuLabels";
import { ErrorModalLabels } from "common/wholesale-common/components/ErrorModalLabels";

export const transferHeaderLabels: ShipmentActionsMenuLabels = defineMessages({
  title: {
    id: "transfers.shipment.header.title",
    defaultMessage: "Transfer shipment",
  },
  cancelTransfer: {
    id: "transfers.shipment.header.cancelTransfer",
    defaultMessage: "Cancel Transfer",
  },
  cancelOrder: {
    id: "transfers.shipment.header.cancelOrder",
    defaultMessage: "Cancel Order",
  },
  partialFillOrder: {
    id: "transfers.shipment.header.partialFulfilOrder",
    defaultMessage: "Partially Fill Order",
  },
  cancelShipmentWarningText: {
    id: "transfers.shipment.header.cancelShipmentWarningText",
    defaultMessage: "Are you sure you want to cancel transfer {transferId}? This can't be undone.",
  },
  cancelShipmentWarningDeny: {
    id: "transfers.shipment.header.cancelShipmentWarningDeny",
    defaultMessage: "Keep Transfer",
  },
  shipBySeller: {
    id: "transfers.shipment.header.shipBySeller",
    defaultMessage: "Estimated to ship by",
  },
  shipByFlexport: {
    id: "transfers.shipment.header.shipByFlexport",
    defaultMessage: "Shipping by",
  },
  shippedOn: {
    id: "transfers.shipment.header.shippedOn",
    defaultMessage: "Shipped on",
  },
  duplicateOrder: {
    id: "transfers.shipment.header.duplicateOrder",
    defaultMessage: "Duplicate",
  },
});

export const transferCancelErrorLabels: ErrorModalLabels = defineMessages({
  title: {
    id: "transfers.cancel.title",
    defaultMessage: "Could not cancel with Amazon",
  },
  description: {
    id: "transfers.cancel.description",
    defaultMessage:
      "The transfer was cancelled with Flexport but could not be cancelled with Amazon. You can try again, or go to Amazon Seller Central to cancel.",
  },
  buttonDescription: {
    id: "transfers.cancel.buttonDescription",
    defaultMessage: "Done",
  },
});

export const cancelShipmentOutOfWindowWarningText = {
  id: "transfers.shipment.header.cancelShipmentWarningText",
  defaultMessage:
    "Are you sure you want to cancel transfer {transferId}? This can't be undone. Because this transfer was confirmed more than 24 hours ago, Amazon will not refund any shipping charges.",
};
