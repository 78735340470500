import React from "react";
import { IconV2 } from "common/components/ui";
import styled from "@emotion/styled";

const StyledDiv = styled.div`
  margin-top: 1rem;
`;

export const LoadingSpinner = () => (
  <StyledDiv data-testid="loading-spinner">
    <IconV2 type="loading" spin={true} />
  </StyledDiv>
);
