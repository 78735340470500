import cn from "classnames/dedupe";
import React, { FC } from "react";

import cls from "./InstructionListOrdinal.less";
import { PillColors } from "../Pill";

export interface InstructionListOrdinalProps {
  color?: PillColors;
  className?: string;
  outline?: boolean; // outlined with darker color.
}

export const InstructionListOrdinal: FC<React.PropsWithChildren<InstructionListOrdinalProps>> = (props) => {
  const { className, color, outline, children } = props;

  return (
    <span
      className={cn(
        cls.ordinal,
        {
          [cls.outline]: outline,
          [cls.ordinalColor]: !color,
          [cls[`pill${String(color)}`]]: !!color,
        },
        className
      )}
    >
      {children}
    </span>
  );
};
