import { WarehousePublic } from "@deliverr/business-types";
import { PalletDetails } from "@deliverr/freight-client";
import { isEmpty } from "lodash/fp";
import { getIsCargoTypeNextButtonDisabled } from "storage/inbounds/utils/getIsCargoTypeNextButtonDisabled";
import { FREIGHT_LTL_PALLET_LIMIT } from "../cargoType/useStorageCargoType";
import { CarrierType, ContainerSize, FreightOrderDetails, ShipmentType } from "@deliverr/storage-client";

export const getIsSelectShippingNextButtonDisabled = (
  carrierType?: CarrierType,
  cargoType?: ShipmentType,
  containerSize?: ContainerSize,
  numOfPallets?: number,
  destinationStorageWarehouse?: WarehousePublic,
  freightOrderDetails?: Partial<FreightOrderDetails>,
  palletConfigurations?: Partial<PalletDetails>[]
) => {
  const isCargoTypeNextButtonDisabled = getIsCargoTypeNextButtonDisabled(
    cargoType,
    containerSize,
    numOfPallets,
    destinationStorageWarehouse
  );
  return carrierType === CarrierType.OTHER
    ? isCargoTypeNextButtonDisabled
    : isCargoTypeNextButtonDisabled ||
        !freightOrderDetails?.quoteId ||
        !!(numOfPallets && numOfPallets <= FREIGHT_LTL_PALLET_LIMIT && isEmpty(palletConfigurations));
};
