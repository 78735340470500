import React, { ElementType, FC, FocusEvent } from "react";

interface OnBlurContainerProps {
  onBlur: (e?: FocusEvent) => void;
  displayAs?: ElementType;
}

export const OnBlurContainer: FC<OnBlurContainerProps> = ({ displayAs, children, onBlur, ...props }) => {
  const handleBlur = (e: FocusEvent) => {
    // only calls onBlur if blurring out of the container
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      onBlur(e);
    }
  };

  const Container = displayAs ?? "div";

  return (
    <Container {...props} onBlur={handleBlur}>
      {children}
    </Container>
  );
};
