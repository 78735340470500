import { defineMessages } from "react-intl";

export const KITTING_COST_TABLE_LABELS = defineMessages({
  modalButtonText: {
    id: "inbounds.prep.kittingCostTable.modalButtonText",
    defaultMessage: "See Price Summary",
  },
  modalTitle: {
    id: "inbounds.prep.kittingCostTable.modalTitle",
    defaultMessage: "Kitting Prep Price Summary",
  },
  modalDoneButtonText: {
    id: "inbounds.prep.kittingCostTable.modalDoneButtonText",
    defaultMessage: "Done",
  },
  tableTitle: {
    id: "inbounds.prep.kittingCostTable.tableTitle",
    defaultMessage: "Kit SKUS",
  },
  totalCostLabel: {
    id: "inbounds.prep.kittingCostTable.totalCostLabel",
    defaultMessage: "Total Kitting costs",
  },
  kittingPrepClarification: {
    id: "inbounds.prep.kittingCostTable.kittingPrepClarification",
    defaultMessage: "Prep costs would be billed on actuals post job completion.",
  },
});
