import styled from "@emotion/styled";
import { Appearance, Button, Stack, Text, ThemeProps, Title } from "common/components/ui";
import React from "react";
import { FormattedMessage } from "react-intl";
import { StyledCloseButton } from "../ui/StyledCloseButton";
import { CancelModalProps } from "./CancelModalProps";
import { CancelTransferActionItems } from "./CancelTransferActionItems";

export const CancelTransferModalBody = styled(Stack)<ThemeProps>(
  ({ theme }) => `
  margin: ${theme.spacing.S3};
`
);

export const CancelConfirmationContent: React.FunctionComponent<CancelModalProps> = (props) => {
  return (
    <CancelTransferModalBody center gap="S5">
      <StyledCloseButton iconType="close" appearance={Appearance.INFO} onClick={props.onClose} />
      <Title as="h3" displayAs="h4">
        <FormattedMessage {...props.shipmentActionsMenuLabels.cancelOrder} />
      </Title>
      <Text as="p" data-testid="cancel_transfer_warning">
        <FormattedMessage
          {...props.shipmentActionsMenuLabels.cancelShipmentWarningText}
          values={{
            transferId: props.transferId,
          }}
        />
      </Text>

      <CancelTransferActionItems columns="1fr 1fr" fullWidth>
        <Button secondary block onClick={props.onClose}>
          <FormattedMessage {...props.shipmentActionsMenuLabels.cancelShipmentWarningDeny} />
        </Button>
        <Button
          name={"cancelTransfer"}
          appearance={Appearance.DANGER}
          onClick={props.handleCancel}
          data-testid="cancel_transfer"
        >
          <FormattedMessage {...props.shipmentActionsMenuLabels.cancelOrder} />
        </Button>
      </CancelTransferActionItems>
    </CancelTransferModalBody>
  );
};
