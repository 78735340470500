import { storageClient } from "common/clients/instances";
import { SPThunkAction } from "common/ReduxUtils";
import { getSellerId } from "common/user/UserSelectors";
import { multiply } from "lodash/fp";
import { logError } from "Logger";
import { getStorageInboundCreate } from "../selector/getStorageInboundCreate";
import { PalletSize, ShipmentType, StorageInboundRequest } from "@deliverr/storage-client";
import { getStorageInboundRates } from "storage/inbounds/utils/getStorageInboundRates";
import { selectFromAddress } from "../selector/selectFromAddress";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { CreateStorageInboundLoader } from "../../CreateStorageInboundLoader.types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "common/components/ui";
import { selectedProductsToStorageItemMapper } from "storage/inbounds/utils/selectedProductsToStorageItemMapper";
import { getNumOfPallets } from "../selector/getNumOfPallets";
import { FeatureName, isFeatureOn } from "common/Split";
import { FREIGHT_LTL_PALLET_LIMIT } from "../../cargoType/useStorageCargoType";
import {
  STORAGE_INBOUND_MAX_CASE_LONGEST_IN,
  STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
  STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
  STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS,
} from "storage/inbounds/CaseDimensionsConstants";
import { LengthUnit, WeightUnit } from "@deliverr/commons-objects";

export const updateStorageInboundOrder = (): SPThunkAction => async (dispatch, getState) => {
  dispatch(addLoader(CreateStorageInboundLoader.UpdateStorageInboundLoader));
  const isStorageMultiCasePackOn = isFeatureOn(FeatureName.StorageMultiCasePack);

  const {
    cargoType,
    selectedProducts,
    isLimitedAccess,
    isResidential,
    carrierType,
    containerSize,
    containerId,
    destinationStorageWarehouse,
    selectedProductsCaseDimensions,
    selectedProductsLotInformation,
    selectedProductsCasePacks,
    storageInboundOrderId,
    palletConfigurations,
    freightOrderDetails,
    totalCargoWeight,
  } = getStorageInboundCreate(getState());
  const numOfPallets = getNumOfPallets(getState());

  const sellerId = getSellerId(getState());
  const fromAddress = selectFromAddress(getState());

  const selectedProductsValues = selectedProducts && Object.values(selectedProducts);
  const storageItems = selectedProductsToStorageItemMapper(
    selectedProductsValues,
    selectedProductsCaseDimensions,
    selectedProductsLotInformation,
    selectedProductsCasePacks,
    isStorageMultiCasePackOn
  );

  const { containerUnloadingFeeBySize, palletInboundFeeBySize, storageFeePerMonth } = getStorageInboundRates();

  const estimatedInboundFee =
    cargoType === ShipmentType.FLOOR_LOADED_CONTAINER && containerSize
      ? containerUnloadingFeeBySize[containerSize]?.fee
      : multiply(numOfPallets, palletInboundFeeBySize[PalletSize.STANDARD_60IN]?.fee ?? 0);

  const estimatedStorageFee = multiply(numOfPallets, storageFeePerMonth);

  const storageInboundRequest: Partial<StorageInboundRequest> = {
    to: destinationStorageWarehouse?.address,
    from: fromAddress,
    shipmentType: cargoType,
    sellerId,
    warehouseId: destinationStorageWarehouse?.id,
    storageItems,
    estimatedInboundFee,
    estimatedStorageFee,
    palletCount: numOfPallets,
    containerId,
    palletConfigurations,
    carrierType,
    freightOrderDetails: {
      ...freightOrderDetails,
      pickupDetails: {
        ...freightOrderDetails?.pickupDetails,
        isLimitedAccess,
        location: { ...freightOrderDetails?.pickupDetails?.location, isResidential },
      },
    },
    ...(cargoType === ShipmentType.FLOOR_LOADED_CONTAINER && { containerSize }),
  };

  try {
    if (numOfPallets > FREIGHT_LTL_PALLET_LIMIT) {
      const storageInboundRequestWithFtlPalletConfig = {
        ...storageInboundRequest,
        palletConfigurations: [
          {
            palletCount: numOfPallets,
            weight: totalCargoWeight ?? STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS,
            length: STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
            width: STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
            height: STORAGE_INBOUND_MAX_CASE_LONGEST_IN,
            lengthUnit: "in" as LengthUnit,
            weightUnit: "lb" as WeightUnit,
          },
        ],
      };
      await storageClient.updateStorageInbound(storageInboundOrderId!, storageInboundRequestWithFtlPalletConfig);
    } else {
      await storageClient.updateStorageInbound(storageInboundOrderId!, storageInboundRequest);
    }
  } catch (err) {
    logError({ fn: "updateStorageInboundOrder" }, err);
    toast.error(
      <FormattedMessage
        id="storage.inbounds.create.updateStorageInboundOrder"
        defaultMessage="Update shipment failed."
      />,
      {
        autoClose: 5000,
        toastId: "updateStorageInboundOrder",
      }
    );
    return false;
  } finally {
    dispatch(clearLoader(CreateStorageInboundLoader.UpdateStorageInboundLoader));
  }
  return true;
};
