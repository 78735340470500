import React, { FC } from "react";
import { useSelector } from "react-redux";

import { DamagedProductNonComplianceRow } from "../../types";
import { DamagedProductBlade } from "common/components/ProblemBlade/DamagedProductBlade";
import { useFetchProduct } from "inbounds/hooks";
import { nonComplianceInternalRoutes } from "inbounds/non-compliance/NonComplianceRoutes";
import RouteParser from "common/RouteParser";
import { Path } from "paths";
import { sellerProblemsViewClient } from "Clients";
import { getSellerId } from "common/user/UserSelectors";
import { logError, logStart } from "Logger";
import { SHIPMENT_PROBLEMS_DAMAGED_PRODUCT_BLADE_ID } from "inbounds/steps/ship/view/cards/ShipmentProblems/blades";
import { MappedSkuPair } from "common/components/ProblemsTable/types";
import { usePreventScrollToTopNavigation } from "common/hooks/usePreventScrollToTopNavigation";

interface DamagedProductNonComplianceBladeProps {
  nonComplianceCase: DamagedProductNonComplianceRow;
}

export const DamagedProductNonComplianceBlade: FC<DamagedProductNonComplianceBladeProps> = ({ nonComplianceCase }) => {
  const navigate = usePreventScrollToTopNavigation();
  const sellerId = useSelector(getSellerId);

  const { id, shippingPlanId, shippingPlanName, mappedSkus } = nonComplianceCase;

  const { value: product } = useFetchProduct({ dsku: mappedSkus[0]?.dsku });

  const skuPair: MappedSkuPair | undefined = product && {
    dsku: product.dsku,
    msku: product.msku,
    productName: product.name,
  };

  const onShippingPlanClick = () => {
    window.open(new RouteParser(Path.inboundsShippingPlanRedirect).parse({ shippingPlanId }), "_blank");
  };

  const onCloseClick = () => {
    navigate(nonComplianceInternalRoutes.summary);
  };

  const onDisputeDamagedProductClick = async () => {
    const ctx = logStart({ fn: "onDisputeDamagedProductClick", sellerId, id });
    try {
      await sellerProblemsViewClient.disputeDamagedProduct(id);
    } catch (err) {
      logError(ctx, err);
      return;
    }
  };

  return (
    <DamagedProductBlade
      id={SHIPMENT_PROBLEMS_DAMAGED_PRODUCT_BLADE_ID}
      shipmentProblem={nonComplianceCase}
      onShippingPlanClick={onShippingPlanClick}
      onCloseClick={onCloseClick}
      isBladeOpen
      product={skuPair}
      onDisputeClick={onDisputeDamagedProductClick}
      shippingPlanId={shippingPlanId}
      shippingPlanName={shippingPlanName}
    />
  );
};
