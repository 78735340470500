import { isProdEnv } from "common/Config";
import log from "Logger";

const googleTagManagerDataLayer = (window as any).dataLayer;

export function sendGTMData(payload: object) {
  if (isProdEnv && googleTagManagerDataLayer) {
    try {
      googleTagManagerDataLayer.push(payload);
    } catch (error) {
      log.error({ fn: "sendGTMData" }, error);
    }
  }
}

export const track = (event: string, isAdmin?: boolean): void => {
  if (process.env.ENV !== "production" || isAdmin) {
    return;
  }

  // FB Pixel Tracking
  try {
    const windowFb = (window as any).fbq;
    if (windowFb) {
      windowFb("trackCustom", event);
    }
  } catch (error) {
    log.error({ fn: "track" }, error);
  }
  // GA/GTM Event Tracking
  sendGTMData({
    event,
    category: event,
  });
};
