import React from "react";
import { ManagedStep } from "common/components/StepContainer";
import { UnconnectedSimplePrepPage } from "prep/create/SimplePrepPage/SimplePrepPage";
import { PrepCreationSource } from "prep/types/PrepCreationSource";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { SimpleSellerSelfServePrepType } from "prep/types";
import { useTransferSelectPrep } from "./useTransferSelectPrep";
import { noop } from "lodash";
import { useSimplePrepPage } from "./SimplePrepPage/useSimplePrepPage";
import { useIntl } from "react-intl";

export const TransferSelectPrep = () => {
  const { formatMessage } = useIntl();
  const {
    isNextDisabled,
    isSecondaryButtonDisabled,
    shouldShowKittingService,
    selectedPrepTypes,
    handleSkipPrepClick,
    totalEstimatedCost,
    selectedKitting,
    handleSelectPrepType,
  } = useTransferSelectPrep();
  const { itemCount, options } = useSimplePrepPage(
    PrepCreationSource.REPLENISHMENT,
    selectedPrepTypes[0] as SimpleSellerSelfServePrepType
  );

  return (
    <ManagedStep
      isNextDisabled={isNextDisabled}
      data-testid="transfers-select-prep"
      secondaryButton={formatMessage(transfersShipmentCreateLabels.steps.prepServices.skip)}
      onClickSecondary={handleSkipPrepClick}
      isSecondaryButtonDisabled={isSecondaryButtonDisabled}
    >
      <UnconnectedSimplePrepPage
        selectedPrepTypes={selectedPrepTypes as SimpleSellerSelfServePrepType[]}
        selectedKitting={selectedKitting}
        handleSelectPrepType={handleSelectPrepType}
        handleSelectKitting={noop}
        itemCount={itemCount}
        totalEstimatedCost={totalEstimatedCost}
        options={options}
        source={PrepCreationSource.REPLENISHMENT}
        shouldShowKittingService={shouldShowKittingService}
        isKittingServiceDisabled={shouldShowKittingService}
        isPrepKittingEnabled={!!shouldShowKittingService}
      />
    </ManagedStep>
  );
};
