import { Path } from "paths";
import { InboundStep } from "inbounds/InboundTypes";

export const fulfillmentInboundStepToPath: Record<InboundStep, Path> = {
  [InboundStep.SELECT_INBOUND_TYPE]: Path.inboundsSelectDestinationType,
  [InboundStep.SELECT_PRODUCTS]: Path.inboundsSelectProducts,
  [InboundStep.SHIPPING_PLAN_PRODUCTS]: Path.inboundsShippingPlanProducts,
  [InboundStep.LOT_INFORMATION]: Path.inboundsLotInformation,
  [InboundStep.ASSIGN_CATEGORIES]: Path.inboundsAssignCategories,
  [InboundStep.PACKAGING_REQUIREMENTS]: Path.inboundsPackagingRequirements,
  [InboundStep.CUSTOMS]: Path.inboundsCustoms,
  [InboundStep.BARCODE_INPUT]: Path.inboundsBarcodeInput,
  [InboundStep.BARCODE_PRINT]: Path.inboundsBarcodePrint,
  [InboundStep.DELIVERR_PREP]: Path.inboundsDeliverrPrep,
  [InboundStep.FROM_ADDRESS]: Path.inboundsFromAddress,
  [InboundStep.DISTRIBUTE_SELECT]: Path.inboundsDistributeSelect,
  [InboundStep.ADD_DIMENSIONS]: Path.inboundsAddDimensions,
  [InboundStep.SHIP]: Path.inboundsShip,
  [InboundStep.SHIPMENT_CONFIRMED]: Path.inboundsShipmentConfirmed,
};
