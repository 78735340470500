import { defineMessages } from "react-intl";
import { FreightAddressTypes } from "./FreightAddressTypes";

export const freightAddressErrorMessages = defineMessages({
  [FreightAddressTypes.PICKUP]: {
    deliverrWarehouse: {
      id: "freight.pickupAddress.notification.deliverrWarehouseError.description",
      defaultMessage: "To transfer inventory from Reserve Storage, create a transfer instead.",
    },
  },
  [FreightAddressTypes.DELIVERY]: {
    deliverrWarehouse: {
      id: "freight.deliveryAddress.notification.deliverrWarehouseError.description",
      defaultMessage: "To send inventory to Flexport, create an inbound instead.",
    },
  },
});
