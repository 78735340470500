import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { FormattedMessage } from "react-intl";

import { CARRIER_TYPE_MESSAGES } from "./CarrierTypeMessages";
import { getStorageInboundCreate } from "storage/inbounds/create/store/selector/getStorageInboundCreate";
import { setCarrierType } from "../../actions/setCarrierType";
import { resetCargoType } from "storage/inbounds/create/cargoType/actions/resetCargoType";
import { Pill } from "common/components/ui";
import { CarrierType } from "@deliverr/storage-client";

export const useSelectCarrier = () => {
  const dispatch = useDispatch();

  const { carrierType } = useSelector(getStorageInboundCreate);

  const recommendedPill = (
    <Pill color="GREEN">
      <FormattedMessage id="storage.inbounds.shippingSelection.carrier.recommended" defaultMessage="Recommended" />
    </Pill>
  );

  const carrierTypeOptions = Object.values(CarrierType).map((carrierTypeValue) => {
    return {
      value: carrierTypeValue,
      label: (
        <>
          <FormattedMessage {...CARRIER_TYPE_MESSAGES[carrierTypeValue].label} /> <>&nbsp;&nbsp;</>
          {carrierTypeValue === CarrierType.DELIVERR && recommendedPill}
        </>
      ),
      content: <FormattedMessage {...CARRIER_TYPE_MESSAGES[carrierTypeValue].content} />,
    };
  });

  const onCarrierTypeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(resetCargoType());
    dispatch(setCarrierType(ev.target.value as CarrierType));
  };

  return {
    carrierTypeOptions,
    onCarrierTypeChange,
    selectedCarrierType: carrierType ?? CarrierType.DELIVERR,
  };
};
