import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { mapError } from "../core/mapError";
import { CoverageApiResponse } from "../fastTag/CoverageApiResponse";
import { FastTagClient } from "../fastTag/FastTagClient";
import { ServiceLevelCoverage } from "../fastTag/ServiceLevelCoverage";
import { SettingName } from "../websiteFastTag/ShippingSetting/SettingName";
import { ShippingSettingUpdate } from "../websiteFastTag/ShippingSetting/ShippingSettingUpdate";
import { ShopFastTagsAppInstallationStatus } from "./ShopFastTagAppInstallationStatus";
import { ShopifyAuthParams } from "./ShopifyAuthParams";
import { ShopifyConfiguration } from "./ShopifyConfiguration/ShopifyConfiguration";
import { ShopifyPageConfiguration } from "./ShopifyConfiguration/ShopifyPageConfiguration";
import { ShopifyError } from "./ShopifyError";
import { ShopifyPageType } from "./ShopifyPageType";
import { ShopifySeller } from "./ShopifySeller/ShopifySeller";
import { ShopifySellerConnectionStatus } from "./ShopifySellerConnectionStatus";
import { ShopifyStoreConfigurationSettings } from "./ShopifyStoreConfiguration/ShopifyStoreConfigurationSettings";
import { ShopifyTemplate } from "./ShopifyTemplate";

export type ShopifyClientConfig = ApiClientConfig;

export class ShopifyClient implements FastTagClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: ShopifyClientConfig = { baseURL: mustBeDefined(process.env.SHOPIFY_URL) }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`,
    });
  }

  async disableFastTag(sellerId: string, dsku: string): Promise<void> {
    try {
      await this.apiClient.put({
        url: `/seller/${sellerId}/fast-tag/disabled`,
        authentication: isAuthenticated,
        body: [dsku],
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async enableFastTag(sellerId: string, dsku: string): Promise<void> {
    try {
      await this.apiClient.put({
        url: `/seller/${sellerId}/fast-tag/enabled`,
        body: [dsku],
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async getDskuStateCoverage(dsku: string): Promise<CoverageApiResponse> {
    return await this.apiClient.get({
      url: `/dsku/${dsku}/coverage/twoday`,
      authentication: isAuthenticated,
    });
  }

  async getStateCoverageByServiceLevel(dsku: string): Promise<ServiceLevelCoverage> {
    try {
      return await this.apiClient.get({
        url: `/coverage/servicelevel/${dsku}`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async isFastTagEnabled(sellerId: string, dsku: string): Promise<boolean> {
    try {
      return await this.apiClient.get<boolean>({
        url: `/seller/${sellerId}/dsku/${dsku}/active`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async getLinkForDsku(dsku: string): Promise<string> {
    try {
      return await this.apiClient.get<string>({ url: `/dsku/${dsku}/link`, authentication: isAuthenticated });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND, ShopifyError.NOT_FOUND_IN_MARKETPLACE]);
    }
  }

  async updateLinkForDsku(
    sellerId: string,
    dsku: string,
    listingUrl?: string,
    productIdentifier?: number,
    shop?: string
  ): Promise<void> {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}/dsku/${dsku}/variant`,
        body: {
          shopifyVariantUrl: listingUrl,
          shopifyVariantId: productIdentifier,
          shop,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.INVALID_URL, ShopifyError.MULTIPLE_VARIANTS, ShopifyError.NOT_FOUND]);
    }
  }

  async getSellerConnectionStatus(sellerId: string): Promise<ShopifySellerConnectionStatus> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/connectionstatus`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async activateSeller(sellerId: string, shop: string): Promise<void> {
    try {
      await this.apiClient.post({
        url: `/seller/${sellerId}/shop/${shop}/activate`,
        body: undefined,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [
        ShopifyError.APP_NOT_INSTALLED,
        ShopifyError.INELIGIBLE_SHOPIFY_ACCOUNT,
        ShopifyError.NOT_FOUND,
      ]);
    }
  }

  async deactivateSeller(sellerId: string, shop: string): Promise<void> {
    try {
      await this.apiClient.delete({
        url: `/seller/${sellerId}/shop/${shop}`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }

  async registerSeller(sellerId: string, authParams: ShopifyAuthParams): Promise<void> {
    try {
      await this.apiClient.post({
        url: `/auth/seller/${sellerId}`,
        body: authParams,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [
        ShopifyError.ALREADY_HAS_SHOP,
        ShopifyError.DUPLICATE_SELLER,
        ShopifyError.INVALID_HMAC,
        ShopifyError.INVALID_HOSTNAME,
        ShopifyError.INVALID_NONCE,
        ShopifyError.MISSING_SCOPES,
      ]);
    }
  }

  async updateConfigurationForAllPages(
    sellerId: string,
    shop: string,
    config: Partial<ShopifyPageConfiguration>
  ): Promise<void> {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}/shop/${shop}/config`,
        body: config,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }

  async updatePageConfiguration(
    sellerId: string,
    shop: string,
    pageType: ShopifyPageType,
    config: Partial<ShopifyPageConfiguration>
  ): Promise<void> {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}/shop/${shop}/config/${pageType}`,
        body: config,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }

  async getSeller(sellerId: string): Promise<ShopifySeller> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }

  async getStoreConfiguration(sellerId: string, storeName: string): Promise<ShopifyStoreConfigurationSettings> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/store/${storeName}/configuration`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }

  async setStorePrices(
    sellerId: string,
    storeName: string,
    cartMinimum?: number,
    paidFastTagCost?: number
  ): Promise<void> {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}/store/${storeName}/prices`,
        body: {
          cartMinimum,
          paidFastTagCost,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async getConfiguration(shop: string): Promise<ShopifyConfiguration> {
    try {
      return await this.apiClient.get({
        url: `/config/${shop}`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }

  async isDeliverrAppEmbedEnabledForActiveTheme(sellerId: string, shop: string): Promise<boolean> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/shop/${shop}/themes/templates/deliverr-app-embed`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND, ShopifyError.INVALID_SETTINGS_ASSET]);
    }
  }

  async getHomepageLink(sellerId: string): Promise<string> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/homepage/link`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }

  async getHomepageLinks(sellerId: string): Promise<{ [shop: string]: string }> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/homepage/links`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }

  async getProductLinks(sellerId: string, shop?: string, limit = 1): Promise<string[]> {
    try {
      if (shop) {
        return await this.apiClient.get({
          url: `/seller/${sellerId}/shop/${shop}/products/links`,
          params: {
            limit,
          },
          authentication: isAuthenticated,
        });
      }

      return await this.apiClient.get({
        url: `/seller/${sellerId}/products/links`,
        params: {
          limit,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }

  async getTemplatesWithAppBlockSupport(sellerId: string, shop: string): Promise<ShopifyTemplate[]> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/shop/${shop}/themes/templates/app-block-support`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.INVALID_MAIN_ASSET, ShopifyError.INVALID_TEMPLATE_ASSET]);
    }
  }

  async generateShopifyAuthUrl(sellerId: string, shop: string): Promise<string> {
    try {
      return await this.apiClient.post({
        url: `/auth/seller/${sellerId}/shop/${shop}/url`,
        body: undefined,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async setStoreShippingSetting(
    sellerId: string,
    storeName = sellerId,
    settingName: SettingName,
    settingData: ShippingSettingUpdate
  ): Promise<void> {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}/store/${storeName}/shipping-setting/${settingName}`,
        body: settingData,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  async setStoreStandardBadgeStatus(
    sellerId: string,
    storeName = sellerId,
    standardBadgeStatus: boolean
  ): Promise<void> {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}/store/${storeName}/standard`,
        body: {
          standardBadgeStatus,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  /**
   * Gets an array of Shopify listings for a DSKU
   *
   * @throws NOT_FOUND                 if the DSKU isn't found in Deliverr's product service
   * @throws NOT_FOUND_IN_MARKETPLACE  if the DSKU is mapped to an MSKU or variant Id that isn't found in Shopify
   */
  async getDskuListings(
    sellerId: string,
    dsku: string,
    shop?: string
  ): Promise<{ id: string; sku: string; url: string }[]> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/dsku/${dsku}/listings`,
        params: { shop },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND, ShopifyError.NOT_FOUND_IN_MARKETPLACE]);
    }
  }

  async getShopFastTagAppInstallationStatus(
    sellerId: string,
    shop: string
  ): Promise<ShopFastTagsAppInstallationStatus> {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/shop/${shop}/app-installed`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }
}
