import styled from "@emotion/styled";
import { Text, DefaultTheme } from "common/components/ui";
import React, { FC } from "react";
import { CarrierAccount } from "@deliverr/replenishment-client";
import HtmlToReact from "common/components/HtmlToReact";
import { getCarrierLabel } from "../../hooks/useReplenishmentCarrierAccountForm";

interface CarrierAccountDetailsProps {
  carrierAccount: CarrierAccount;
}

export const TruncatedText = styled(Text)<{ marginBottom?: string }, DefaultTheme>(
  ({ theme, marginBottom = theme.spacing.S1 }) => `
    margin-bottom: ${marginBottom};
    width: 100%;
    max-width: 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
  `
);

export const CarrierAccountSelectDetails: FC<CarrierAccountDetailsProps> = ({ carrierAccount }) => (
  <div>
    <TruncatedText>
      <HtmlToReact as="span" html={carrierAccount.displayName} />
    </TruncatedText>
    <TruncatedText appearance="INFO" marginBottom="0">
      {getCarrierLabel(carrierAccount.carrier)}, {carrierAccount.accountNumber}
    </TruncatedText>
  </div>
);
