import { defineMessages } from "react-intl";

export const transfersListLabels = defineMessages({
  searchPlaceholder: {
    id: "transfers.list.searchPlaceholder",
    defaultMessage: "Search Transfers",
  },
  emptyStateTitle: {
    id: "transfers.emptyList.title",
    defaultMessage: "Put restocking on autopilot",
  },
  emptyStateMessage: {
    id: "transfers.emptyList.message",
    defaultMessage:
      "Store your inventory at a fraction of the cost, and get smart, timely product replenishment from Reserve Storage to Flexport, Amazon, and Walmart fulfillment centers.",
  },
  emptyStateTransferButton: {
    id: "transfers.emptyList.emptyStateTransferButton",
    defaultMessage: "Create Transfer",
  },
  emptyCreateInboundButton: {
    id: "transfers.emptyList.emptyStateTransferButton",
    defaultMessage: "Send inventory to reserve storage",
  },
  createTransferButton: {
    id: "transfers.emptyList.createTransfer",
    defaultMessage: "Create Transfer",
  },
  manageRecommendationSettingsButton: {
    id: "transfers.list.manageRecommendationSettingsButton",
    defaultMessage: "Manage recommendation settings",
  },
  recommendationTooltip: {
    id: "transfers.list.recommendationTooltip",
    defaultMessage: "This is recommended based on your replenishment preferences.<a>Manage preferences</a>",
  },
  recommendedTransferNotificationTitle: {
    id: "transfers.list.recommendedTransferNotificationTitle",
    defaultMessage: "You have a recommended transfer",
  },
  recommendedTransferNotificationDesc: {
    id: "transfers.list.recommendedTransferNotificationDesc",
    defaultMessage: "Review and confirm this transfer to avoid stocking out.",
  },
  emptyStateLookingToCreateOrder: {
    id: "transfers.emptyList.lookingToCreateOrder",
    defaultMessage: "Looking to create a wholesale order? {createOrder}",
  },
  emptyStateCreateOrderButton: {
    id: "transfers.emptyList.createOrder",
    defaultMessage: "Create an order",
  },
  emptyStateHaveQuestions: {
    id: "transfers.emptyList.haveQuestions",
    defaultMessage: "Have questions? {learnMore}",
  },
  tableCellShipmentId: {
    id: "transfers.list.cell.shipmentId",
    defaultMessage: "Transfer {shipmentId}",
  },
  tableCellCreationDate: {
    id: "transfers.list.cell.creationDate",
    defaultMessage: "Created {date}",
  },
  tableHeader: {
    shipment: {
      id: "transfers.list.header.shipment",
      defaultMessage: "Shipment",
    },
    poNumber: {
      id: "transfers.list.header.poNumber",
      defaultMessage: "PO#",
    },
    origin: {
      id: "transfers.list.header.origin",
      defaultMessage: "Origin",
    },
    destination: {
      id: "transfers.list.header.destination",
      defaultMessage: "Destination",
    },
    status: {
      id: "transfers.list.header.status",
      defaultMessage: "Status",
    },
    skus: {
      id: "transfers.list.header.skus",
      defaultMessage: "SKUs",
    },
    expectedUnits: {
      id: "transfers.list.header.expectedUnits",
      defaultMessage: "Expected units",
    },
  },
  loadingError: {
    id: "transfers.list.loadingError",
    defaultMessage: "An error occurred while loading the data.",
  },
  cancelError: {
    id: "transfers.list.cancelError",
    defaultMessage: "The order could not be cancelled. Please contact support",
  },
  originDeliverrReserve: {
    id: "transfers.list.origin.deliverrReserve",
    defaultMessage: "Flexport Reserve",
  },
  destinationDeliverrEcom: {
    id: "transfers.list.destination.deliverrEcom",
    defaultMessage: "Flexport Ecom",
  },
  destinationWalmart: {
    id: "transfers.list.destination.walmart",
    defaultMessage: "Walmart WFS",
  },
  destinationAmazonDraft: {
    id: "transfers.list.destination.amazonDraft",
    defaultMessage: "Amazon",
  },
  cancelOrderButton: {
    id: "transfers.list.order.cancel",
    defaultMessage: "Cancel Transfer",
  },
  deleteOrderButton: {
    id: "transfers.list.order.delete",
    defaultMessage: "Delete Transfer",
  },
  multiple: {
    id: "transfers.list.po.multiple",
    defaultMessage: "Multiple",
  },
});
