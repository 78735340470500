import { FacebookSellerRegistrationData } from "common/clients/facebook/FacebookSellerRegistrationData/FacebookSellerRegistrationData";
import { facebookClient } from "common/clients/instances";
import { facebookSellerRegistrationData } from "../state/FacebookSelectors";

export const registerSeller = (sellerId: string) => async (_, getState) => {
  const sellerRegistrationData = facebookSellerRegistrationData(getState()) as FacebookSellerRegistrationData;

  await facebookClient.registerSeller(sellerId, sellerRegistrationData);

  return;
};
