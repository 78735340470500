import {
  QueryDslBoolQuery,
  QueryDslQueryContainer,
} from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";
import { WholesaleIngestionChannel, WholesaleListSearchFilters } from "../store/WholesaleListState";
import { compact } from "lodash";
import { ReplenishmentListStatus } from "transfers/listV2/ReplenishmentListStatuses";
import { ReplenishmentOrderListTabFilter } from "transfers/listV2/store/ReplenishmentListState";
import {
  DistributionChannel,
  IngestionChannel,
  NonComplianceStatus,
  OrderStatus,
} from "@deliverr/replenishment-client";
import { SPS_CHANNELS } from "../WholesaleListIngestionChannelFilter";
import { OpenSearchInvalidOrderItemStatus } from "transfers/listV2/ReplenishmentListUtils";
import { escapeSpecialCharsOpenSearchQuery } from "common/StringUtils";

export const constructWholesaleListOpenSearchFilters = (
  searchTerm: string,
  { statuses, tabFilter, ingestionChannel }: WholesaleListSearchFilters,
  isWholesaleOrderHeldStateOn: boolean,
  shouldShowAdminFeatures: boolean
): QueryDslQueryContainer[] => {
  const escapedSearchTerm = escapeSpecialCharsOpenSearchQuery(searchTerm);
  const searchTermFilters = getSearchTermFilters(escapedSearchTerm);
  const statusFilter = getOrderStatusFilter(statuses, tabFilter);
  const ingestionChannelFilter = getIngestionChannelFilter(ingestionChannel);
  const tabFilterQuery = getTabFilter(tabFilter);
  const mustFilters = getMustFilters();
  const mustNotFilters = getMustNotFilters(ingestionChannel, shouldShowAdminFeatures, isWholesaleOrderHeldStateOn);

  const filters = compact([ingestionChannelFilter, statusFilter, tabFilterQuery]);

  return [
    {
      bool: {
        ...searchTermFilters,
        filter: filters,
        must: mustFilters,
        must_not: mustNotFilters,
      },
    },
  ];
};

const getSearchTermFilters = (searchTerm: string): QueryDslBoolQuery => {
  if (!searchTerm) {
    return {};
  }

  return {
    should: [
      {
        query_string: {
          query: `orderId:*${searchTerm}* OR distributionChannelOrderId:*${searchTerm}* OR name:*${searchTerm}*`,
          analyze_wildcard: true,
        },
      },
      {
        nested: {
          path: "shipments",
          query: {
            query_string: {
              query: `shipments.distributionChannelShipmentId:*${searchTerm}* OR shipments.poNumber:*${searchTerm}* OR shipments.shipmentId:*${searchTerm}*`,
              analyze_wildcard: true,
            },
          },
        },
      },
      {
        nested: {
          path: "orderItems",
          query: {
            term: {
              "orderItems.dsku": `${searchTerm}`,
            },
          },
        },
      },
      {
        nested: {
          path: "orderItems",
          query: {
            term: {
              "orderItems.packOf": `${searchTerm}`,
            },
          },
        },
      },
    ],
    minimum_should_match: 1,
  };
};

const getTabFilter = (tabFilter?: ReplenishmentOrderListTabFilter): QueryDslQueryContainer | null => {
  return tabFilter === ReplenishmentOrderListTabFilter.HAS_PROBLEM
    ? {
        bool: {
          should: [
            {
              term: {
                nonComplianceStatus: NonComplianceStatus.CREATED,
              },
            },
            {
              bool: {
                must: [
                  {
                    term: {
                      invalidOrderItemStatus: OpenSearchInvalidOrderItemStatus.ACTION_NEEDED,
                    },
                  },
                  {
                    term: {
                      status: OrderStatus.DRAFT,
                    },
                  },
                ],
              },
            },
          ],
          minimum_should_match: 1,
        },
      }
    : null;
};

const getIngestionChannelFilter = (
  ingestionChannel: WholesaleIngestionChannel
): QueryDslQueryContainer | undefined | null =>
  ingestionChannel === WholesaleIngestionChannel.SPS
    ? {
        terms: {
          ingestionChannel: SPS_CHANNELS,
        },
      }
    : null;

const getOrderStatusFilter = (
  orderStatuses: ReplenishmentListStatus[],
  tabFilter: ReplenishmentOrderListTabFilter
): QueryDslQueryContainer | null => {
  if (tabFilter === ReplenishmentOrderListTabFilter.HAS_PROBLEM) {
    return null;
  }

  return {
    terms: {
      // CREATED status behaves exactly like DRAFT for UI purposes
      status: orderStatuses.includes(OrderStatus.DRAFT) ? [...orderStatuses, OrderStatus.CREATED] : orderStatuses,
    },
  };
};

const getMustFilters = (): QueryDslQueryContainer[] => [
  {
    term: {
      distributionChannel: DistributionChannel.WHOLESALE,
    },
  },
];

const getMustNotFilters = (
  ingestionChannel: WholesaleIngestionChannel,
  shouldShowAdminFeatures: boolean,
  isWholesaleOrderHeldStateOn: boolean
): QueryDslQueryContainer[] => {
  const mustNotFilters: QueryDslQueryContainer[] = [
    {
      terms: {
        status: isWholesaleOrderHeldStateOn
          ? [OrderStatus.ARCHIVED, OrderStatus.ERROR]
          : [OrderStatus.ARCHIVED, OrderStatus.ERROR, OrderStatus.DRAFT, OrderStatus.CREATED],
      },
    },
  ];

  if (!shouldShowAdminFeatures) {
    mustNotFilters.push({
      term: {
        ingestionChannel: "SPS_TEST" as IngestionChannel,
      },
    });
  }

  if (ingestionChannel === WholesaleIngestionChannel.OTHERS) {
    mustNotFilters.push({
      terms: {
        ingestionChannel: SPS_CHANNELS,
      },
    });
  }

  return mustNotFilters;
};
