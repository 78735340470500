import React, { FC, ReactNode } from "react";
import { DefaultTheme, Stack, Text } from "common/components/ui";
import styled from "@emotion/styled";

import loading from "assets/img/icons/loading.gif";

export interface LoadingWithMessageProps {
  loadingMessage?: string | JSX.Element | ReactNode;
  secondaryMessage?: string | JSX.Element | ReactNode;
}

const Loading = styled.img`
  height: 85px;
  width: 85px;
`;

const StyledText = styled(Text)<{}, DefaultTheme>`
  text-align: center;
  margin: ${({ theme }) => `${theme.spacing.S4} auto`};
  max-width: 400px; /* used to force linebreaks because intl was being difficult */
`;

export const LoadingWithMessage: FC<LoadingWithMessageProps> = ({ loadingMessage, secondaryMessage }) => {
  return (
    <Stack center>
      {loadingMessage && (
        <StyledText as="p" bold size="label">
          {loadingMessage}
        </StyledText>
      )}
      <Loading src={loading} />
      {secondaryMessage && (
        <StyledText as="p" bold size="label">
          {secondaryMessage}
        </StyledText>
      )}
    </Stack>
  );
};
