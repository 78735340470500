import styled from "@emotion/styled";
import { HTMLAttributes } from "react";
import { colors } from "../shared/theme/color";
import { DefaultTheme } from "../shared";

export enum PillColors {
  GRAY = "GRAY",
  DARKGRAY = "DARKGRAY",
  BLUE = "BLUE",
  PURPLE = "PURPLE",
  ORANGE = "ORANGE",
  YELLOW = "YELLOW",
  GREEN = "GREEN",
  RED = "RED",
}

export interface PillProps extends HTMLAttributes<HTMLDivElement> {
  color?: keyof typeof PillColors;
}

const colorMap: Record<PillColors, { bg: string; text: string }> = {
  [PillColors.GRAY]: {
    text: colors.NEUTRAL["400"],
    bg: colors.NEUTRAL["40"],
  },
  [PillColors.DARKGRAY]: {
    text: colors.NEUTRAL["400"],
    bg: colors.NEUTRAL["60"],
  },
  [PillColors.BLUE]: {
    text: colors.BLUE["400"],
    bg: colors.BLUE["50"],
  },
  [PillColors.PURPLE]: {
    text: colors.PURPLE["400"],
    bg: colors.PURPLE["100"],
  },
  [PillColors.ORANGE]: {
    text: colors.ORANGE["400"],
    bg: colors.ORANGE["100"],
  },
  [PillColors.YELLOW]: {
    text: colors.YELLOW["500"],
    bg: colors.YELLOW["100"],
  },
  [PillColors.GREEN]: {
    text: colors.GREEN["400"],
    bg: colors.GREEN["100"],
  },
  [PillColors.RED]: {
    text: colors.RED["400"],
    bg: colors.RED["100"],
  },
};

const setColor = ({ color }: PillProps) => {
  const selectedColor = colorMap[color as PillColors];
  return `
    background-color: ${selectedColor.bg};
    border-color: ${selectedColor.bg};
    color: ${selectedColor.text};
  `;
};

export const Pill = styled.div<PillProps, DefaultTheme>(
  ({ color = "BLUE", theme }) => `
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 12px;
  border: 1px solid;
  padding: ${theme.spacing.S1} ${theme.spacing.S2};
  font-size: ${theme.font.size.F1};
  font-family: ${theme.font.family.STANDARD};
  font-weight: ${theme.font.weight.BOLD};
  ${setColor({ color })}
`
);
