import { defineMessages } from "react-intl";

export const PREP_TILE_COST_LABELS = defineMessages({
  standard: {
    id: "inbounds.prepTileCost.standard",
    defaultMessage: "{formattedItemCostWithGranularity} for {formattedTotalCost}",
  },
  minimum: {
    id: "inbounds.prepTileCost.minimum",
    defaultMessage: "Starts at {formattedItemCostWithGranularity} for {formattedTotalCost}",
  },
  total: {
    id: "inbounds.prepTileCost.total",
    defaultMessage: "Total Kitting Cost: {formattedTotalCost}",
  },
});
