export enum BorderWidth {
  B0 = "B0",
  B1 = "B1",
  B2 = "B2",
}

export enum BorderRadius {
  R0 = "R0",
  R2 = "R2",
  R3 = "R3",
  R4 = "R4",
  R5 = "R5",
  R6 = "R6",
}

const WIDTH: Record<BorderWidth, string> = {
  [BorderWidth.B0]: "0",
  [BorderWidth.B1]: "1px",
  [BorderWidth.B2]: "2px",
};

const RADIUS: Record<BorderRadius, string> = {
  [BorderRadius.R0]: "0",
  [BorderRadius.R2]: "2px",
  [BorderRadius.R3]: "3px",
  [BorderRadius.R4]: "4px",
  [BorderRadius.R5]: "8px",
  [BorderRadius.R6]: "12px",
};

export const border = {
  /**
   * @constant `B1` 1px
   * @constant `B2` 2px
   */
  width: WIDTH,
  type: "solid",
  /**
   * @constant `R2` 2px
   * @constant `R3` 3px
   * @constant `R4` 4px
   * @constant `R5` 8px
   * @constant `R6` 12px
   */
  radius: RADIUS,
};
