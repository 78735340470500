import styled from "@emotion/styled";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

const ChargePerUnitTooltipWrapper = styled.div`
  width: 216px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const ChargePerUnitTooltip: FC = () => (
  <ChargePerUnitTooltipWrapper>
    <FormattedMessage
      id="inbounds.shipmentProblemUnknownBarcodeModal.chargePerUnit.tooltip"
      defaultMessage="You will only be charged if the fulfillment center provides valid documentation and proof of the case."
    />
  </ChargePerUnitTooltipWrapper>
);
