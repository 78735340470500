import React from "react";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import { Box } from "common/components/ui";
import { CardContainer, StyledCard } from "common/components/StepContainer";

export const LoadingFallback = () => {
  return (
    <CardContainer>
      <StyledCard alignItems="center">
        <Box marginTop="S6" width="100%">
          <LoadingSpinner center size="4x" />
        </Box>
      </StyledCard>
    </CardContainer>
  );
};
