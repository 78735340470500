import { OrderTag } from "@deliverr/commons-objects";
import { RemovalMethods } from "order/new/ShippingMethods";
import { OrderListServiceFilter } from "../OrderListReducer";

export const serviceToAlgoliaFilter = (serviceFilter?: OrderListServiceFilter): string[] => {
  if (serviceFilter === "ALL_SERVICE") {
    return [];
  }

  if (serviceFilter === RemovalMethods.REMOVAL) {
    return [`tags:${OrderTag.INVENTORY_REMOVAL}`];
  }

  if (Array.isArray(serviceFilter)) {
    return [`(${serviceFilter.map((service) => `orderDetails.serviceLevel:"${service}"`).join(" OR ")})`];
  }

  return serviceFilter ? [`orderDetails.serviceLevel:"${serviceFilter}"`] : [];
};
