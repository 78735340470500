import { getCognitoUsername, getIsFederatedUser, getUserEmail } from "common/user/UserSelectors";
import { createSelector } from "reselect";
import { RootState } from "RootReducer";

export const selectCognitoUserId = createSelector(
  getIsFederatedUser,
  getCognitoUsername,
  getUserEmail,
  (isFederatedUser, username, email) => (isFederatedUser ? username! : email)
);

export const selectIsChecklistBladeOpen = (state: RootState) => state.onboarding.isChecklistBladeOpen ?? false;
