import React from "react";
import { ControlledNumberInput } from "common/components/reactHookForm";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { FormattedMessage } from "react-intl";
import { FieldArrayWithId } from "react-hook-form";
import { CreateShipmentFormState } from "inbounds/createShipment/store";
import { DtcMixedSkuInputDimValues, getInputName } from "./DtcMixedSkuInputValues";

const NumberOfBoxesInputLabel = () => (
  <FormattedMessage id="createShipment.dtc.cargoDetailStep.mixedSku.numberOfBoxes" defaultMessage="# of boxes" />
);

export const DtcMixedSkuNumberOfBoxesInput = ({
  pkg,
  index,
}: {
  pkg: FieldArrayWithId<CreateShipmentFormState, CreateShipmentInputName.MIXED_SKU_PACKAGES, "id">;
  index: number;
}) => (
  <ControlledNumberInput
    name={getInputName(index, DtcMixedSkuInputDimValues.NUMBER_OF_BOXES)}
    label={<NumberOfBoxesInputLabel />}
    decimalScale={0}
    min={0}
    inputSize="medium"
    defaultValue={pkg.numberOfPkgs}
    required
  />
);
