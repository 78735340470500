import { logError } from "Logger";
import { selectTransferCreate } from "../../store/TransferCreateSelectors";
import {
  transferCreateSetError,
  transferCreateSetReplenishmentError,
  transferCreateSetValidShipBy,
} from "../TransferCreateActions";
import { addLoader, clearLoader } from "../../../../common/components/WithLoader/LoadingActions";
import { CreateTransferLoader } from "../../CreateTransferLoader.types";
import { batch } from "react-redux";
import { WholesaleErrorType } from "../../../../common/wholesale-common/utils/WholesaleErrorTypes";
import { updateAndSetReplenishmentOrder } from "./updateAndSetReplenishmentOrder";
import { createAndSetReplenishmentOrder } from "./createAndSetReplenishmentOrder";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import React from "react";
import { toast } from "common/components/ui";
import { wholesaleClient } from "Clients";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";
import { ReplenishmentErrorCodes } from "common/constants/replenishment/ReplenishmentErrorCodes";

export const createOrUpdateReplenishmentOrder = async (
  dispatch,
  getState,
  submitDraft: boolean,
  createDraftOrderInChannel?: boolean
): Promise<boolean> => {
  const { replenishmentOrder, originStorageWarehouse, destinationType } = selectTransferCreate(getState());

  batch(() => {
    dispatch(addLoader(CreateTransferLoader.CreateTransferOrderLoader));
    dispatch(transferCreateSetError(WholesaleErrorType.NONE));
    dispatch(transferCreateSetReplenishmentError({ errors: [] }));
  });

  try {
    replenishmentOrder?.orderId
      ? await updateAndSetReplenishmentOrder(
          dispatch,
          replenishmentOrder?.orderId,
          submitDraft,
          createDraftOrderInChannel
        )
      : await createAndSetReplenishmentOrder(dispatch, submitDraft, createDraftOrderInChannel);
    if (destinationType === TransferCreationTypes.Wholesale && originStorageWarehouse?.warehouse?.id) {
      const shipBy = (await wholesaleClient.getValidShipByV2(originStorageWarehouse?.warehouse?.id)).data;
      dispatch(transferCreateSetValidShipBy(shipBy));
    }
    return true;
  } catch (err) {
    const errData = err?.response?.data;
    if (errData?.error?.code === ReplenishmentErrorCodes.INVALID_ORDER_ITEMS) {
      toast.error(
        <FormattedMessage
          id="replenishment.transfers.order.createOrUpdate.error"
          defaultMessage="Please remove out of stock products to continue"
        />,
        {
          autoClose: 5000,
          toastId: "ReplenishmentCreateUpdateOrderError",
        }
      );
    } else {
      toast.error(
        <FormattedMessage
          id="replenishment.transfers.order.createOrUpdate.error"
          defaultMessage="Failed to create or update replenishment order"
        />,
        {
          autoClose: 5000,
          toastId: "ReplenishmentCreateUpdateOrderError",
        }
      );
    }

    batch(() => {
      dispatch(transferCreateSetError(WholesaleErrorType.UNTYPED));
      dispatch(
        transferCreateSetReplenishmentError({ errors: err?.response?.data?.error ? [err.response.data.error] : [] })
      );
    });
    logError({ fn: "createOrUpdateWholesaleOrder" }, err);
    return false;
  } finally {
    dispatch(clearLoader(CreateTransferLoader.CreateTransferOrderLoader));
  }
};
