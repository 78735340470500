import React from "react";
import { FormattedMessage } from "react-intl";
import { transfersShipmentCreateLabels } from "../../../transfersShipmentCreate.labels";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";
import { InlineSpinner, Amount } from "./AmazonShipmentType";

export const GetParcelContent: React.FunctionComponent<{
  isPendingParcel: boolean;
  parcelEstimation: number | undefined;
  showParcelEstimationError: boolean;
  isAmazonMethod: boolean;
}> = (props) => {
  if (props.showParcelEstimationError && props.isAmazonMethod) {
    return (
      <FormattedMessage
        {...transfersShipmentCreateLabels.steps.shipping.parcelSizeEstimationError}
        values={{
          learnMore: <ElevioLearnMoreButton articleId={ElevioArticleId.ShippingRestrictionsForFbaInbounds} />,
        }}
      />
    );
  }
  return props.isPendingParcel ? (
    <div>
      <InlineSpinner />
      <FormattedMessage {...transfersShipmentCreateLabels.steps.shipping.cargoOptions.generatingQuote} />
    </div>
  ) : props.parcelEstimation !== undefined ? (
    <FormattedMessage
      {...transfersShipmentCreateLabels.steps.shipping.cargoOptions.estimatedShippingCost}
      values={{
        formattedAmount: <Amount value={props.parcelEstimation} />,
      }}
    />
  ) : (
    <></>
  );
};
