import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { getInstructionStepsForShipmentType } from "./step";
import { getStorageInboundDetail } from "../store";

export const useStorageShipmentInstructions = () => {
  const { storageInboundOrder } = useSelector(getStorageInboundDetail);
  const [isShipmentInstructionOpen, setIsShipmentInstructionOpen] = useState<boolean>(true);
  const toggleShipmentInstructionOpen = () => setIsShipmentInstructionOpen((state) => !state);

  const shipmentInstructionsSteps = useMemo(
    () => getInstructionStepsForShipmentType(storageInboundOrder.shipmentType, storageInboundOrder?.carrierType),
    [storageInboundOrder]
  );

  return {
    shipmentInstructionsSteps,
    toggleShipmentInstructionOpen,
    isShipmentInstructionOpen,
  };
};
