import { Box, Input, ThemeProps } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "@emotion/styled";
import { UseControllerReturn } from "react-hook-form";
import { NewProductCasePackFormValues } from "./useNewProductCasePackForm";
import { DimensionsRowError } from "inbounds/steps/dimensions/DimensionsAndWeightsTable/DimensionsRowError";
import { DimensionsError } from "inbounds/steps/dimensions/DimensionsAndWeightsTable/DimensionsError";

const FormInput = styled(Input)<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S3} !important;
  `
);

const FormContainer = styled.div(`
  display: flex;
`);

const WeightInput = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  align-items: baseline;
  padding: ${theme.spacing.S1} !important;
`
);

const DimensionInputs = styled(WeightInput)<ThemeProps>(
  ({ theme }) => `
  > label {
    margin: 0 ${theme.spacing.S2};
  }

  > label:first-child {
    margin-left: 0;
  }

`
);

interface CasePackMeasurementsProps {
  caseLength: UseControllerReturn<NewProductCasePackFormValues, "caseLength">;
  caseHeight: UseControllerReturn<NewProductCasePackFormValues, "caseHeight">;
  caseWeight: UseControllerReturn<NewProductCasePackFormValues, "caseWeight">;
  caseWidth: UseControllerReturn<NewProductCasePackFormValues, "caseWidth">;
  packDimensionsError?: DimensionsError;
}

export const CasePackMeasurements: FC<CasePackMeasurementsProps> = ({
  caseLength,
  caseHeight,
  caseWeight,
  caseWidth,
  packDimensionsError,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <FormContainer>
        <Box width="70%" padding-right="S2">
          <label>
            <FormattedMessage id={"product.productCasePack.dimensions"} defaultMessage="Dimensions (in) *" />
          </label>
          <DimensionInputs>
            <FormInput
              step="0.01"
              type="number"
              {...caseHeight.field}
              min={1}
              hasError={caseHeight.fieldState.invalid}
              placeholder={formatMessage({
                defaultMessage: "H",
                id: "product.productCasePack.height.placeholder",
              })}
            />
            x
            <FormInput
              type="number"
              step="0.01"
              {...caseWidth.field}
              min={1}
              hasError={caseWidth.fieldState.invalid}
              placeholder={formatMessage({
                defaultMessage: "W",
                id: "product.productCasePack.width.placeholder",
              })}
            />
            x
            <FormInput
              type="number"
              step="0.01"
              {...caseLength.field}
              min={1}
              hasError={caseLength.fieldState.invalid}
              placeholder={formatMessage({
                defaultMessage: "L",
                id: "product.productCasePack.length.placeholder",
              })}
            />
          </DimensionInputs>
        </Box>
        <Box width="30%">
          <label>
            <FormattedMessage id="product.productCasePack.weight" defaultMessage="Box weight (lbs) *" />
          </label>
          <WeightInput>
            <FormInput
              type="number"
              step="0.01"
              {...caseWeight.field}
              min={1}
              hasError={caseWeight.fieldState.invalid}
              placeholder={formatMessage({
                defaultMessage: "lbs",
                id: "product.productCasePack.weight.placeholder",
              })}
            />
          </WeightInput>
        </Box>
      </FormContainer>
      {packDimensionsError && <DimensionsRowError error={packDimensionsError} />}
    </>
  );
};
