import { createSelector } from "reselect";
import { sumBy } from "lodash/fp";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { getProductChooserKittedDSKUDetails } from "common/components/ProductChooser/ProductChooserSelector";

export const selectTransferKitProductDetails = createSelector(
  selectTransferCreate,
  getProductChooserKittedDSKUDetails,
  ({ selectedProducts }, productChooserKittedDSKUDetails) => {
    return Object.keys(selectedProducts).map((dsku) => {
      const product = selectedProducts[dsku];
      const kitComponents = productChooserKittedDSKUDetails?.[dsku]?.kitComponents;
      const totalComponentQuantity = sumBy("qty", kitComponents ?? []) ?? 0;
      return {
        dsku,
        name: product?.name ?? "Unknown",
        totalComponentQuantity,
        qty: selectedProducts[dsku]?.qty ?? 0,
      };
    });
  }
);
