import { PrepCombo, PrepType } from "@deliverr/prep-service-client";
import { SuperPrepType } from "prep/types";
import { MessageDescriptor } from "react-intl";

export const PREP_TYPE_DESCRIPTION_LABELS: Record<SuperPrepType, MessageDescriptor> = {
  [PrepType.SKU_LABELS]: {
    id: "inbounds.prep.skuLabels.description",
    defaultMessage: "Print and apply a barcode to each individual unit.",
  },
  [PrepCombo.SKU_LABELS_RS]: {
    id: "inbounds.prep.skuLabelsRs.description",
    defaultMessage: "Print and apply a barcode to each individual unit.",
  },
  [PrepType.BUBBLE_WRAPPING]: {
    id: "inbounds.prep.bubbleWrapping.description",
    defaultMessage: "Protect units in bubble wrap so they pass a 3' drop test.",
  },
  [PrepType.BOX_BUILDING]: {
    id: "inbounds.prep.boxBuilding.description",
    defaultMessage: "Place each unit inside a box for protection.",
  },
  [PrepType.BAGGING]: {
    id: "inbounds.prep.bagging.description",
    defaultMessage: "Place each unit inside a sealed polybag for protection.",
  },
  [PrepType.BOX_CONTENT_LABELS]: {
    id: "inbounds.prep.boxContentLabels.description",
    defaultMessage: "Flexport processes box contents and labels your boxes within 3 days of receiving.",
  },
  [PrepType.BUBBLE_MAILER]: {
    id: "inbounds.prep.bubbleMailer.description",
    defaultMessage: "Place each unit inside a sealed bubble mailer for protection.",
  },
  [PrepCombo.BAGGING_PROTECTION]: {
    id: "inbounds.prep.baggingProtection.description",
    defaultMessage: "Place each unit inside a sealed polybag for protection. Includes Unit Barcoding.",
  },
  [PrepCombo.BAGGING_PROTECTION_RS]: {
    id: "inbounds.prep.baggingProtectionRs.description",
    defaultMessage: "Place each unit inside a sealed polybag for protection. Includes Unit Barcoding.",
  },
  [PrepCombo.DAMAGE_PROTECTION]: {
    id: "inbounds.prep.damageProtection.description",
    defaultMessage: "Place each unit in a bubble mailer or bubble wrap for protection. Includes Unit Barcoding.",
  },
  [PrepCombo.DAMAGE_PROTECTION_RS]: {
    id: "inbounds.prep.damageProtectionRs.description",
    defaultMessage: "Place each unit in a bubble mailer or bubble wrap for protection. Includes Unit Barcoding.",
  },
  [PrepType.KITTING]: {
    id: "inbounds.prep.kitting.description",
    defaultMessage:
      "Place each component (e.g. sellable units, marketing inserts) into a Flexport or Merchant-provided box. Includes Box Content Labeling",
  },
  // TODO: labels below are not currently supported, so descriptions will need to be updated when added to seller portal
  [PrepType.BARCODE_TRANSLATION]: {
    id: "inbounds.prep.barcodeTranslation.description",
    defaultMessage: "Unsupported",
  },
  [PrepType.FLOOR_LOADED_CONTAINER]: {
    id: "inbounds.prep.floorLoadedContainer.description",
    defaultMessage: "Unsupported",
  },
  [PrepType.QUALITY_CHECK]: {
    id: "inbounds.prep.qualityCheck.description",
    defaultMessage: "Unsupported",
  },
  [PrepType.CUSTOM_SKU_LABEL]: {
    id: "inbounds.prep.customSkuLabel.description",
    defaultMessage: "Unsupported",
  },
  [PrepType.CARTON_LABEL]: {
    id: "inbounds.prep.cartonLabel.description",
    defaultMessage: "Unsupported",
  },
  [PrepType.PALLET_LABEL]: {
    id: "inbounds.prep.palletLabel.description",
    defaultMessage: "Unsupported",
  },
  [PrepType.UNIT_PROTECTION]: {
    id: "inbounds.prep.unitProtection.description",
    defaultMessage: "Unsupported",
  },
  [PrepType.COMBO_BOX_CONTENT_LABEL]: {
    id: "inbounds.prep.comboBoxContentLabel.description",
    defaultMessage: "Unsupported",
  },
  [PrepCombo.FBA_PARCEL_STANDARD]: {
    id: "inbounds.prep.fbaParcelStandard.description",
    defaultMessage: "Unsupported",
  },
  [PrepCombo.FBA_FREIGHT_STANDARD]: {
    id: "inbounds.prep.fbaFreightStandard.description",
    defaultMessage: "Unsupported",
  },
  [PrepCombo.B2B_PARCEL_STANDARD]: {
    id: "inbounds.prep.b2bParcelStandard.description",
    defaultMessage: "Unsupported",
  },
  [PrepCombo.B2B_FREIGHT_STANDARD]: {
    id: "inbounds.prep.b2bFreightStandard.description",
    defaultMessage: "Unsupported",
  },
};

export const FLEXPORT_PREP_TYPE_DESCRIPTION_LABELS: Record<SuperPrepType, MessageDescriptor> = {
  [PrepType.SKU_LABELS]: {
    id: "inbounds.prep.skuLabels.description.flexport",
    defaultMessage: "Flexport will print and apply a barcode to each individual unit.",
  },
  [PrepCombo.SKU_LABELS_RS]: {
    id: "inbounds.prep.skuLabelsRs.description.flexport",
    defaultMessage: "Flexport will print and apply a barcode to each individual unit.",
  },
  [PrepType.BUBBLE_WRAPPING]: {
    id: "inbounds.prep.bubbleWrapping.description.flexport",
    defaultMessage: "Flexport will protect units in bubble wrap so they pass a 3' drop test.",
  },
  [PrepType.BOX_BUILDING]: {
    id: "inbounds.prep.boxBuilding.description.flexport",
    defaultMessage: "Flexport will place each unit inside a box for protection.",
  },
  [PrepType.BAGGING]: {
    id: "inbounds.prep.bagging.description.flexport",
    defaultMessage: "Flexport will place each unit inside a sealed polybag for protection.",
  },
  [PrepType.BOX_CONTENT_LABELS]: {
    id: "inbounds.prep.boxContentLabels.description.flexport",
    defaultMessage: "Flexport will process box contents and labels your boxes within 3 days of receiving.",
  },
  [PrepType.BUBBLE_MAILER]: {
    id: "inbounds.prep.bubbleMailer.description.flexport",
    defaultMessage: "Flexport will place each unit inside a sealed bubble mailer for protection.",
  },
  [PrepCombo.BAGGING_PROTECTION]: {
    id: "inbounds.prep.baggingProtection.description.flexport",
    defaultMessage: "Flexport will place each unit inside a sealed polybag for protection. Includes Unit Barcoding.",
  },
  [PrepCombo.BAGGING_PROTECTION_RS]: {
    id: "inbounds.prep.baggingProtectionRs.description.flexport",
    defaultMessage: "Flexport will place each unit inside a sealed polybag for protection. Includes Unit Barcoding.",
  },
  [PrepCombo.DAMAGE_PROTECTION]: {
    id: "inbounds.prep.damageProtection.description.flexport",
    defaultMessage:
      "Flexport will place each unit in a bubble mailer or bubble wrap for protection. Includes Unit Barcoding.",
  },
  [PrepCombo.DAMAGE_PROTECTION_RS]: {
    id: "inbounds.prep.damageProtectionRs.description.flexport",
    defaultMessage:
      "Flexport will place each unit in a bubble mailer or bubble wrap for protection. Includes Unit Barcoding.",
  },
  [PrepType.FLOOR_LOADED_CONTAINER]: {
    id: "inbounds.prep.floorLoadedContainer.description.flexport",
    defaultMessage: "Flexport will unload your inventory from the container",
  },
  // TODO: labels below are not currently supported, so descriptions will need to be updated when added to seller portal
  [PrepType.BARCODE_TRANSLATION]: {
    id: "inbounds.prep.barcodeTranslation.description.flexport",
    defaultMessage: "Flexport will...",
  },
  [PrepType.KITTING]: {
    id: "inbounds.prep.kitting.description.flexport",
    defaultMessage: "Flexport will...",
  },
  [PrepType.QUALITY_CHECK]: {
    id: "inbounds.prep.qualityCheck.description.flexport",
    defaultMessage: "Flexport will...",
  },
  [PrepType.CUSTOM_SKU_LABEL]: {
    id: "inbounds.prep.customSkuLabel.description.flexport",
    defaultMessage: "Flexport will...",
  },
  [PrepType.CARTON_LABEL]: {
    id: "inbounds.prep.cartonLabel.description.flexport",
    defaultMessage: "Flexport will...",
  },
  [PrepType.PALLET_LABEL]: {
    id: "inbounds.prep.palletLabel.description.flexport",
    defaultMessage: "Flexport will...",
  },
  [PrepType.UNIT_PROTECTION]: {
    id: "inbounds.prep.unitProtection.description.flexport",
    defaultMessage: "Flexport will...",
  },
  [PrepType.COMBO_BOX_CONTENT_LABEL]: {
    id: "inbounds.prep.comboBoxContentLabel.description.flexport",
    defaultMessage: "Flexport will...",
  },
  [PrepCombo.FBA_PARCEL_STANDARD]: {
    id: "inbounds.prep.fbaParcelStandard.description.flexport",
    defaultMessage: "Flexport will...",
  },
  [PrepCombo.FBA_FREIGHT_STANDARD]: {
    id: "inbounds.prep.fbaFreightStandard.description.flexport",
    defaultMessage: "Flexport will...",
  },
  [PrepCombo.B2B_PARCEL_STANDARD]: {
    id: "inbounds.prep.b2bParcelStandard.description.flexport",
    defaultMessage: "Flexport will...",
  },
  [PrepCombo.B2B_FREIGHT_STANDARD]: {
    id: "inbounds.prep.b2bFreightStandard.description.flexport",
    defaultMessage: "Flexport will...",
  },
};
