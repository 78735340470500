import React, { FC, useEffect } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { Container } from "common/components/Container";
import { LoadInbound } from "inbounds/steps/LoadInbound";
import { isStorageInboundStepPath } from "./utils/isStorageInboundStepPath";
import { flexportSearchParamsHelper } from "./ship/view/FlexportAutomatedTracking/useFlexportOAuthAuthorization";
import { useSelector } from "react-redux";
import { selectHasFromAddress } from "inbounds/store/selectors/plan/selectHasFromAddress";
import { selectIpbFeatureOn } from "inbounds/createShipment/store/selectors/selectIpbFeatureOn";
import { useSPDispatch } from "common/ReduxUtils";
import { goToCreateInbound } from "inbounds/createShipment/store/actions";

export interface InboundStepContainerProps {
  component: JSX.Element;
  pathKey: string;
}

const StyledContainer = styled(Container)`
  padding-bottom: 4rem;
`;

export const InboundStepContainer: FC<InboundStepContainerProps> = ({ component, pathKey }) => {
  const { shippingPlanId } = useParams<{ shippingPlanId?: string }>();
  const hasFromAddress = useSelector(selectHasFromAddress);
  const isIpbFeatureOn = useSelector(selectIpbFeatureOn);
  const dispatch = useSPDispatch();

  useEffect(() => {
    flexportSearchParamsHelper();
  }, []);

  if (
    pathKey === "inboundsSelectProducts" ||
    pathKey === "inboundsSelectDestinationType" ||
    isStorageInboundStepPath(pathKey)
  ) {
    // prevent sellers from accessing these steps without going through the IPB steps
    if (!hasFromAddress && isIpbFeatureOn) {
      dispatch(goToCreateInbound());
      return null;
    }

    return <Container>{component}</Container>;
  }

  const minHeight = pathKey === "inboundsBarcodePrint" ? "800px" : undefined;

  // Force reload for path "/inbounds/:shippingPlanId" in order to
  // trigger a redirect to the appropriate inbound step.
  const forceReload = pathKey === "inboundsShippingPlanRedirect";

  // Use key on LoadInbound to force re-mount when shippingPlanId changes,
  // so that the user can navigate directly from one inbound to another.
  return (
    <StyledContainer minHeight={minHeight}>
      <LoadInbound key={shippingPlanId!} forceReload={forceReload}>
        {component}
      </LoadInbound>
    </StyledContainer>
  );
};
