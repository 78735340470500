import { NavBarProgressActionTypes } from "common/components/NavBarProgress/NavBarProgressActions";
import { createReducer, ReducerDictionary } from "common/ReduxUtils";

// page type represents the section of the sites (inbounds, egd), page is the specific page
export interface NavBarProgressState {
  currentSteps: {
    [pageType: string]: string;
  };
}

export const navBarProgressInitialState: NavBarProgressState = {
  currentSteps: {},
};

const reducers: ReducerDictionary<NavBarProgressState> = {
  [NavBarProgressActionTypes.UPDATE_CURRENT_PAGE]: (state, action) => ({
    ...state,
    currentSteps: {
      ...state.currentSteps,
      [action.pageType]: action.currentStep,
    },
  }),
};

export const navBarProgressReducer = createReducer<NavBarProgressState>(navBarProgressInitialState, reducers);
