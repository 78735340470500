import { ShippingPartner, ShippingType } from "@deliverr/replenishment-client";
import { WholesaleShipmentType, WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { MessageDescriptor } from "react-intl";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";

export const shippingPartnerMessages: Partial<Record<
  ShippingPartner,
  { value: string; label: MessageDescriptor; content: MessageDescriptor }
>> = {
  [ShippingPartner.DELIVERR]: {
    value: WholesaleShippingMethod.DELIVERR,
    label: transfersShipmentCreateLabels.steps.shipping.options.deliverr.label,
    content: transfersShipmentCreateLabels.steps.shipping.options.deliverr.fbaIntegratedDescription,
  },
  [ShippingPartner.FBA]: {
    value: WholesaleShippingMethod.SELLER,
    label: transfersShipmentCreateLabels.steps.shipping.options.amazon.label,
    content: transfersShipmentCreateLabels.steps.shipping.options.amazon.description,
  },
  [ShippingPartner.SELLER]: {
    value: WholesaleShippingMethod.SELLER,
    label: transfersShipmentCreateLabels.steps.shipping.options.seller.label,
    content: transfersShipmentCreateLabels.steps.shipping.options.seller.description,
  },
};

export const shippingTypeMessages: Partial<Record<
  ShippingType,
  { value: string; label: MessageDescriptor; content: MessageDescriptor }
>> = {
  [ShippingType.LTL]: {
    value: WholesaleShipmentType.PARTIAL_PALLET,
    label: transfersShipmentCreateLabels.steps.shipping.cargoOptions.pallet.fbaAutoLabel,
    content: transfersShipmentCreateLabels.steps.shipping.options.deliverr.fbaIntegratedDescription,
  },
  [ShippingType.PARCEL]: {
    value: WholesaleShipmentType.PARCEL,
    label: transfersShipmentCreateLabels.steps.shipping.cargoOptions.parcel.label,
    content: transfersShipmentCreateLabels.steps.shipping.options.deliverr.fbaIntegratedDescription,
  },
};
