import { trackHeapEvent } from "common/utils/heap/trackHeapEvent";
import { useCallback, useRef, useState } from "react";
import { StorageHeapEvent } from "storage/types/StorageHeapEvent";

export const useStorageRecommendationAlert = () => {
  const showStorageRecommendationAlertRef = useRef<boolean>(false);

  const [isStorageRecommendationAlert, setIsShowStorageRecommendationAlert] = useState<boolean>(false);

  const showStorageRecommendationAlert = useCallback(() => {
    if (!showStorageRecommendationAlertRef.current) {
      showStorageRecommendationAlertRef.current = true;
      setIsShowStorageRecommendationAlert(true);
      trackHeapEvent<StorageHeapEvent>("storage.recommendation.banner.show");
    }
  }, []);

  const hideStorageRecommendationAlert = () => {
    setIsShowStorageRecommendationAlert(false);
  };

  return {
    isStorageRecommendationAlertVisible: isStorageRecommendationAlert,
    showStorageRecommendationAlert,
    hideStorageRecommendationAlert,
  };
};
