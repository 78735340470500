import { crossdockClient } from "common/clients/instances";
import { SPThunkAction } from "common/ReduxUtils";
import { matches } from "lodash/fp";
import { setCrossdockInboundQuote } from "./setCrossdockInboundQuote";
import logger, { logError } from "Logger";
import { CrossdockInboundQuote } from "common/clients/crossdock/CrossdockInboundQuote/CrossdockInboundQuote";
import { CrossdockInboundQuoteRequest } from "common/clients/crossdock/CrossdockInboundQuote/CrossdockInboundQuoteRequest";
import { getShippingPlanId } from "inbounds/steps/InboundStepSelectors";

/**
 * Generate a new CrossdockInboundQuote. If a currentQuote is supplied that matches the
 * crossdockInboundQuoteRequest, no new quote will be created.
 */
export const generateCrossdockInboundQuote = (
  crossdockInboundQuoteRequest: CrossdockInboundQuoteRequest,
  currentQuote?: CrossdockInboundQuote
): SPThunkAction<Promise<CrossdockInboundQuote | undefined>> => async (dispatch, getState) => {
  const state = getState();
  const shippingPlanId = getShippingPlanId(state);
  // ensure the request has the planId
  const requestWithPlanId = {
    ...crossdockInboundQuoteRequest,
    shippingPlanId: crossdockInboundQuoteRequest.shippingPlanId ?? shippingPlanId,
  };

  const ctx = { fn: "generateCrossdockInboundQuote", requestWithPlanId };

  // don't generate a new quote if one already exists that matches the currently stored one
  if (currentQuote && matches(requestWithPlanId)(currentQuote)) {
    logger.info(ctx, "No changes to current quote request; thus no new quote is necessary");
    return;
  }

  try {
    const quote = await crossdockClient.createInboundQuote(requestWithPlanId);
    dispatch(setCrossdockInboundQuote(quote));

    return quote;
  } catch (err) {
    logError(ctx, err, "Unable to generate a Crossdock Inbound Quote");
    return;
  }
};
