import { IconV2 } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { fbaIntegrationCommonLabels } from "./FbaIntegrationCommon.labels";

export const FbaIntegrationViewShipmentLink: FC<{ fbaShipmentId: string; hideIcon?: boolean }> = ({
  fbaShipmentId,
  hideIcon,
}) => {
  const shipmentLink: string = `https://sellercentral.amazon.com/fba/inbound-shipment/summary/${fbaShipmentId}`;

  return (
    <div>
      {!hideIcon && <IconV2 type="external-link" />}
      <FormattedMessage
        {...fbaIntegrationCommonLabels.referenceId.warningLink}
        values={{
          a: (text) => {
            return (
              <a href={shipmentLink} target="_blank noreferrer">
                {text}
              </a>
            );
          },
        }}
      />
    </div>
  );
};
