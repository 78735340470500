import { ChangeEvent, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { FastTagServiceLevel } from "tags/model/FastTagServiceLevel";
import { ConfigureAdsProps } from "./ConfigureAds";

import { isValidDsku } from "./isValidDsku";
import { useCreateMoreAdSetsModal } from "./useCreateMoreAdSetsModal";
import { fastTagPagePath } from "tags/model/path/fastTagPagePath";
import { SelectProps } from "common/components/ui";
import { fastTagServiceLabel } from "tags/model/fastTagServiceLabel";

export type FacebookTargetedAdsServiceLevel = Exclude<FastTagServiceLevel, 3>;

export const deliverySpeedSnippet = {
  1: {
    id: "FacebookFastTag.ConfigureTargetedAds.1DayOptionLabel",
    defaultMessage: "NextDay",
  },
  2: {
    id: "FacebookFastTag.ConfigureTargetedAds.2DayOptionLabel",
    defaultMessage: "2-Day",
  },
};

const noDeliverySpeedSnippetTitle = {
  id: "FacebookFastTag.ConfigureTargetedAds.NoDeliverySpeedSnippetTitle",
  defaultMessage: "1_or_2-day",
};

const noDskuSnippetTitle = {
  id: "FacebookFastTag.ConfigureTargetedAds.NoDeliverySpeedSnippetTitle",
  defaultMessage: "DSKU",
};

interface DeliverySpeedOption {
  value: FacebookTargetedAdsServiceLevel;
  label: SelectProps["label"];
}

export type UseConfigureAdsHookProps = ConfigureAdsProps;

export const useConfigureAds = ({ flow, page, state }: ConfigureAdsProps) => {
  const nextPath = fastTagPagePath(flow.getNextPage(page, state));
  const previousPath = fastTagPagePath(flow.getPreviousPage(page));

  const { formatMessage } = useIntl();
  const [product, setProduct] = useState<string>("");
  const [deliverySpeed, setDeliverySpeed] = useState<number | undefined>(undefined);
  const { showModal } = useCreateMoreAdSetsModal(nextPath);

  const isValidConfiguration = isValidDsku(product) && deliverySpeed !== undefined;

  const deliverySpeedOptions: DeliverySpeedOption[] = [
    {
      value: 1,
      label: fastTagServiceLabel[1],
    },
    {
      value: 2,
      label: fastTagServiceLabel[2],
    },
  ];

  const deliverySpeedSnippetTitle = formatMessage(
    deliverySpeed ? deliverySpeedSnippet[deliverySpeed] : noDeliverySpeedSnippetTitle
  );

  const dskuSnippetTitle = product !== "" ? product : formatMessage(noDskuSnippetTitle);

  const adSetTitle = `Deliverr_${deliverySpeedSnippetTitle}_${dskuSnippetTitle}`;

  const selectedDeliverySpeed = deliverySpeedOptions.find(({ value }) => value === deliverySpeed);

  const onDeliverySpeedChange = ({ value }: DeliverySpeedOption) => {
    setDeliverySpeed(value);
  };

  const onProductChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProduct(e.currentTarget.value);
  };

  const onNextClick = () => {
    showModal();
  };

  const [isCopySnippetSeen, setCopySnippetSeen] = useState<boolean>(false);

  useEffect(() => {
    if (isValidConfiguration && !isCopySnippetSeen) {
      setCopySnippetSeen(true);
    }
  }, [isValidConfiguration, isCopySnippetSeen]);

  return {
    adSetTitle,
    isCopySnippetSeen,
    deliverySpeed,
    deliverySpeedOptions,
    isValidConfiguration,
    nextPath,
    onDeliverySpeedChange,
    onNextClick,
    onProductChange,
    previousPath,
    product,
    selectedDeliverySpeed,
  };
};
