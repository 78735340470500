export enum ListingSolution {
  ACENDA = "acenda",
  AMAZON = "amazon",
  ARTEZA = "arteza",
  BIGCOMMERCE = "bigcommerce",
  CHANNEL_ADVISOR = "channel_advisor",
  DELIVERR_API = "deliverr_api",
  EBAY = "ebay",
  ECOMDASH = "ecomdash",
  ETSY = "etsy",
  FACEBOOK = "facebook",
  FLIEBER = "flieber",
  GEEKSELLER = "geekseller",
  GOOGLE = "google",
  JCPENNEY = "jcpenney",
  LINNWORKS = "linnworks",
  LISTINGMIRROR = "listingmirror",
  MIRAKL = "mirakl",
  MOBILITYECOMMERCE = "mobilityecommerce",
  PIPE17 = "pipe17",
  SELLBRITE = "sellbrite",
  SELLERACTIVE = "selleractive",
  SELLERCHAMP = "sellerchamp",
  SELLERCLOUD = "sellercloud",
  SHEIN = "shein",
  SHOPIFY = "shopify",
  SHOPIFY_V2 = "shopify_v2",
  SHOPIFY_V3 = "shopify_v3",
  SHOPIFY_FULFILLMENT_NETWORK = "shopify_fulfillment_network",
  SKUBANA = "skubana",
  SKUPREME = "skupreme",
  SOLIDCOMMERCE = "solidcommerce",
  SPSCOMMERCE = "spscommerce",
  STOREAUTOMATOR = "storeautomator",
  WALMARTDIRECT = "walmartdirect",
  WISH = "wish",
  WOOCOMMERCE = "woocommerce",
  ZENTAIL = "zentail",
}
