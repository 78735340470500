import React from "react";
import { FormattedMessage } from "react-intl";
import { CalendarFastTagServiceLevel } from "./FastTagServiceLevel";

export const fastTagServiceLabel = (
  isCalendarDay: Boolean = false
): Record<CalendarFastTagServiceLevel, React.ReactNode> => {
  if (isCalendarDay) {
    return {
      1: <FormattedMessage id="fastTag.serviceName.1" defaultMessage="Next calendar day" />,
      2: <FormattedMessage id="fastTag.serviceName.2" defaultMessage="2 calendar day" />,
      3: <FormattedMessage id="fastTag.serviceName.3" defaultMessage="3 calendar day" />,
      4: <FormattedMessage id="fastTag.serviceName.4" defaultMessage="4 calendar day" />,
      5: <FormattedMessage id="fastTag.serviceName.5" defaultMessage="5 calendar day" />,
    };
  }

  return {
    1: <FormattedMessage id="fastTag.serviceName.1" defaultMessage="Next business day" />,
    2: <FormattedMessage id="fastTag.serviceName.2" defaultMessage="2 business day" />,
    3: <FormattedMessage id="fastTag.serviceName.3" defaultMessage="3 business day" />,
    4: <FormattedMessage id="fastTag.serviceName.4" defaultMessage="4 business day" />,
    5: <FormattedMessage id="fastTag.serviceName.5" defaultMessage="5 business day" />,
  };
};
