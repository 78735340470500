import { loadWholesaleOrderAction } from "../../actions/loadWholesaleOrder";
import { CreateTransferLoader } from "../../CreateTransferLoader.types";
import { useSelector } from "react-redux";
import { getIsLoading } from "../../../../common/components/WithLoader/LoadingSelectors";
import { useEstimationsStatus } from "../common/useEstimationsStatus";
import { selectTransferCreate } from "../../store/TransferCreateSelectors";

export const useTransferEstimationsStatus = () => {
  const { wholesaleOrder } = useSelector(selectTransferCreate);
  const isCreateOrderLoading = useSelector(getIsLoading)(CreateTransferLoader.CreateTransferOrderLoader);
  return {
    ...useEstimationsStatus(wholesaleOrder, CreateTransferLoader.CreateTransferOrderLoader, loadWholesaleOrderAction),
    isCreateOrderLoading,
    wholesaleOrder,
  };
};
