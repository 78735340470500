import { registerChargebee, setCustomerDetails, setCheckoutCallbacks } from "Chargebee";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShouldShowCheckoutModalOnClick } from "../steps/ship/ShipmentSetupSelectors";

/**
 * Handles chargebee and billing initialization on mount
 * Returns a key and props needed for the chargebee onClick to attach to the checkout button
 */
export const useInboundBillingSetup = (checkoutCallback: () => void) => {
  const dispatch = useDispatch();
  const shouldShowCheckoutModal = useSelector(getShouldShowCheckoutModalOnClick);

  useEffect(() => {
    if (shouldShowCheckoutModal) {
      registerChargebee();
      setCustomerDetails();
      dispatch(setCheckoutCallbacks(checkoutCallback));
    }
  }, [dispatch, shouldShowCheckoutModal, checkoutCallback]);

  // the key change forces a remount to remove the Chargebee click handler that's added via a globally defined method
  const checkoutButtonKey = shouldShowCheckoutModal ? "chargebeeCheckoutButton" : "acceptChargesButton";
  const checkoutButtonProps = shouldShowCheckoutModal
    ? {
        "data-cb-type": "checkout",
        "data-cb-plan-id": "deliverr_2019",
      }
    : undefined;

  return {
    checkoutButtonKey,
    checkoutButtonProps,
  };
};
