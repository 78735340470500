import { removeAllEmTags } from "common/StringUtils";
import { ProductListItem } from "inventory/list/InventoryListReducer";
import { groupBy, isEmpty } from "lodash";
import { aggregatePackInventory } from "./aggregatePackInventory";

export const mapPacksToProductListItem = (products: ProductListItem[], packs: ProductListItem[]): ProductListItem[] => {
  const validPacks = packs.filter((pack) => !isEmpty(removeAllEmTags(pack?.packOf ?? "")));
  const packsByPackOf = groupBy(validPacks, (pack) => removeAllEmTags(pack.packOf!));

  return products.flatMap((product) => {
    const productPacks = packsByPackOf[removeAllEmTags(product.dsku)] ?? [];
    return productPacks.map((pack) => mergePackAndProductListItems(product, pack));
  });
};

const mergePackAndProductListItems = (product: ProductListItem, pack: ProductListItem): ProductListItem => {
  const packInventories = aggregatePackInventory(product, pack);
  const name = removeAllEmTags(product.name);
  const msku = product.msku ? removeAllEmTags(product.msku) : product.msku;
  const asin = product.asin ? removeAllEmTags(product.asin) : undefined;
  const productAliases = product.productAliases;
  const barcodes = product?.barcodes?.map((barcode) => removeAllEmTags(barcode));
  const unitsSoldLast30Days = product?.unitsSoldLast30Days;
  const productInventories = {
    onHandQty: product.onHandQty,
    unfilledOrderQty: product.unfilledOrderQty,
    nonPickableQty: product.nonPickableQty,
    unavailableQty: product.unavailableQty,
    inTransferQty: product.inTransferQty,
  };

  return {
    ...pack,
    name,
    productAliases,
    msku,
    asin,
    barcodes,
    raw: {
      ...pack.raw,
      name,
      msku,
      asin,
      productAliases,
      barcodes,
      ...packInventories,
      ...productInventories,
      unitsSoldLast30Days,
      isKit: product.isKit,
    },
    ...packInventories,
    ...productInventories,
    unitsSoldLast30Days,
    isKit: product.isKit,
  };
};
