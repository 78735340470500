import log from "Logger";

// from https://developers.heap.io/reference/track
export const trackHeapEvent = <EventType extends string>(
  event: EventType,
  properties?: Record<string | number, string | number>
) => {
  try {
    const track = (window as any).heap?.track;

    if (!track) {
      log.warn({ fn: "trackHeapEvent" }, "heap track not available");
      return;
    }
    track(event, properties);
  } catch (err) {
    log.info({ fn: "trackHeapEvent", err }, "Error in tracking heap event");
  }
};
