import React, { FC } from "react";
import { Text, SelectComponentOption, Grid, DefaultTheme } from "common/components/ui";
import { OptionProps } from "@deliverr/react-select-v1";
import styled from "@emotion/styled";
import HtmlToReact from "../../HtmlToReact";
import { getBarcodeFormat } from "common/components/ui/utils/BarcodeUtils";
import { css } from "emotion";
import cx from "classnames";
import { PackInfo } from "../Packs/components/PackInfo";
import { useProductSearchOption } from "./useProductSearchOption";

const Description = styled.div`
  em {
    font-style: normal;
    font-weight: bold;
  }
`;

const Sku = styled.div`
  min-width: 5rem;
`;

const OptionContent = styled.div<{ isDisabled: boolean }, DefaultTheme>(
  ({ theme, isDisabled }) => `
    * {
      ${isDisabled ? `color: ${theme.colors.NEUTRAL[200]};` : ""}
    }
  `
);

const notAllowedCursor = css("cursor: not-allowed !important;"); // Override default react-select styling

const getBarcodeWithFormat = (barcode: string) => {
  const format = getBarcodeFormat(barcode);
  if (!format) {
    return null;
  }

  return format.toUpperCase() + " " + barcode;
};

export const ProductSearchOption: FC<OptionProps> = (props) => {
  const {
    qtyOrError,
    updatedDescriptionHtml,
    msku,
    barcode,
    showOnlyPacks,
    isDisabled,
    showAvailability,
    productData,
  } = useProductSearchOption(props);

  return (
    <SelectComponentOption {...props} className={cx({ [notAllowedCursor]: isDisabled })}>
      <OptionContent isDisabled={isDisabled}>
        <Grid columns="1fr auto">
          <Description>
            <Text as="span">
              <HtmlToReact html={updatedDescriptionHtml} />
            </Text>
            <Sku>
              <Text size="caption" as="span">
                <HtmlToReact html={msku} />
                {barcode && getBarcodeWithFormat(barcode)}
                {showOnlyPacks && !productData.isKit && <PackInfo product={productData} />}
              </Text>
            </Sku>
          </Description>
          {showAvailability && (
            <div>
              <Text size="caption" as="span">
                {qtyOrError}
              </Text>
            </div>
          )}
        </Grid>
      </OptionContent>
    </SelectComponentOption>
  );
};
