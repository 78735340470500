import { useIntl } from "react-intl";
import { useWholesaleConfirmCostsColumns } from "wholesale/create/steps/useWholesaleConfirmCostsColumns";
import { wholesaleCreateLabels } from "wholesale/create/wholesaleCreate.labels";
import { ChargeRows } from "transfers/create/actions/replenishmentOrder/utils/help";
import { sumBy } from "lodash";
import { BasicTableColumn } from "common/components/BasicTable";
import { CostTableProps } from "common/components/CostTable";

export const useReplenishmentOrderPricingSummary = (
  replenishmentOrderEstimates?: ChargeRows,
  totalAggregationAltText?: string
): CostTableProps => {
  const { formatMessage } = useIntl();
  const aggregatorOptions = { totalRowLabel: formatMessage(wholesaleCreateLabels.steps.confirm.totalRowLabel) };
  const columns = useWholesaleConfirmCostsColumns();

  const data: (BasicTableColumn | undefined | 0)[] = [
    ...(replenishmentOrderEstimates?.charges.map(
      (charge): BasicTableColumn => ({
        rowLabel: formatMessage(charge.feeDescription),
        quantity: charge.unitCount,
        unitLabel: charge.unitOfMeasure,
        ...(charge.unitCost > 0 ? { unitCost: charge.unitCost } : {}),
        amount: charge.totalCost,
      })
    ) ?? []),
    {},
  ];

  const costTableProps: CostTableProps = {
    subTables: [
      {
        data: data as BasicTableColumn[],
        columns,
        aggregatorOptions,
      },
    ],
    totalAggregation: totalAggregationAltText ?? sumBy(replenishmentOrderEstimates?.charges, (x) => x.totalCost),
  };
  return costTableProps;
};
