import { Container } from "@deliverr/commons-objects";
import { storageClient } from "common/clients/instances";
import { SPThunkAction } from "common/ReduxUtils";
import { getStorageInboundCreate } from "../../store/selector/getStorageInboundCreate";
import { setNumOfPallets } from "./setNumOfPallets";
import { logError, logStart } from "Logger";
import { ItemInfoWithQuantity } from "common/clients/storage/EstimatePalletRequest/ItemInfoWithQuantity";
import { setEstimatedNumOfPallets } from "./setEstimatedNumOfPallets";
import { resetSelectedQuoteDetails } from "../../selectShipping/actions/resetSelectedQuoteDetails";

export const getEstimatedNumberOfPallets = (): SPThunkAction => async (dispatch, getState) => {
  const ctx = logStart({ fn: "getEstimatedNumberOfPallets" });
  const { selectedProducts, selectedProductsCaseDimensions, numOfPallets } = getStorageInboundCreate(getState());
  const caseInfo: ItemInfoWithQuantity[] = Object.keys(selectedProducts).map((dsku) => ({
    ...(selectedProductsCaseDimensions[dsku] as Container),
    qty: selectedProducts[dsku].numberOfCases,
  }));
  try {
    const estimatedPalletCount = (await storageClient.estimatePalletCount({ itemsInfo: caseInfo })).value;
    dispatch(setEstimatedNumOfPallets(estimatedPalletCount));
    if (numOfPallets !== estimatedPalletCount) {
      dispatch(resetSelectedQuoteDetails());
    }
    dispatch(setNumOfPallets(estimatedPalletCount));
  } catch (err) {
    logError(ctx, err, "error estimating number of pallets, defaulting to 1");
    dispatch(setEstimatedNumOfPallets(1));
    dispatch(setNumOfPallets(1));
  }
};
