import React from "react";
import { useIntl } from "react-intl";
import { ManagedStep } from "common/components/StepContainer/ManagedStep";
import { useWholesalePickStorageWarehouseStep } from "./useWholesalePickStorageWarehouseStep";
import { RadioTileGroup } from "common/components/ui";
import { wholesaleCreateLabels } from "../wholesaleCreate.labels";

export const WholesalePickStorageWarehouseStep = () => {
  const { formatMessage } = useIntl();
  const {
    storageWarehouseOptions,
    onStorageWarehouseSelect,
    selectedWarehouseOption,
    isNextLoading,
  } = useWholesalePickStorageWarehouseStep();
  return (
    <ManagedStep
      title={formatMessage(wholesaleCreateLabels.steps.origin.title)}
      subtitle={formatMessage(wholesaleCreateLabels.steps.origin.subtitle)}
      isNextDisabled={!selectedWarehouseOption}
      isLoading={isNextLoading}
    >
      <RadioTileGroup
        currentValue={selectedWarehouseOption}
        direction="VERTICAL"
        name="storageWarehouseOriginRadioGroup"
        options={storageWarehouseOptions}
        onChange={onStorageWarehouseSelect}
        block
      />
    </ManagedStep>
  );
};
