import { CarrierAccount } from "@deliverr/replenishment-client";
import { useDispatch, useSelector } from "react-redux";

import { SPThunkAction } from "common/ReduxUtils";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { ReplenishmentCarrierAccountLoader } from "../ReplenishmentCarrierAccountLoader";
import { useLoader } from "common/components/WithLoader/useLoader";
import { logError } from "Logger";

export const useCarrierAccountModal = (
  deleteCarrierAccount: (carrierAccountId: number, selectedCarrierAccountId?: number) => SPThunkAction<Promise<void>>,
  onClose: () => void,
  carrierAccount?: CarrierAccount
) => {
  const dispatch = useDispatch();
  const { selectedCarrierAccount } = useSelector(selectTransferCreate);
  const isLoading = useLoader(ReplenishmentCarrierAccountLoader.CarrierModalButtonLoader);

  const handleDelete = async (): Promise<void> => {
    try {
      await dispatch(deleteCarrierAccount(carrierAccount!.id, selectedCarrierAccount?.id));
    } catch (err) {
      logError({ fn: "useCarrierAccountModal.handleDelete", carrierAccount }, err, "Error deleting carrier account");
    } finally {
      onClose();
    }
  };

  return {
    isLoading,
    handleDelete,
  };
};
