import { ProductPrepCategory } from "@deliverr/commons-clients/lib/product/ProductPreparation";
import { productCategoriesContent } from "common/content/productCategoriesContent";
import { SelectOption } from "common/utils/types";
import { sortBy } from "lodash/fp";
import { useIntl } from "react-intl";

export const useProductCategoryOptions = () => {
  const { formatMessage } = useIntl();

  const options: SelectOption<ProductPrepCategory>[] = sortBy<SelectOption<ProductPrepCategory>>(
    // sort OTHER to the bottom
    [({ value }: SelectOption<ProductPrepCategory>) => value === ProductPrepCategory.OTHER],
    Object.keys(ProductPrepCategory).map((key) => {
      const value = ProductPrepCategory[key];
      return {
        value,
        label: formatMessage(productCategoriesContent[value]),
      };
    })
  );

  return {
    categorySelectOptions: options,
  };
};
