import * as React from "react";
import ReactSelect, { components, Props } from "react-select";
import { DefaultTheme } from "../shared";
import { SelectProps, selectStyles as baseSelectStyles } from "./BaseSelect";
import { useTheme } from "emotion-theming";
import { extractFormProps, FormGroup } from "../FormGroup";
import { IconV2 } from "../IconV2";

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    {props.selectProps.menuIsOpen ? <IconV2 type="angle-up" /> : <IconV2 type="angle-down" />}
  </components.DropdownIndicator>
);

const control = (theme: DefaultTheme) => (styles) => ({
  ...styles,
  appearance: "none",
  backgroundColor: "inherit",
  border: "none",
  boxShadow: "none",
  cursor: "pointer",
  fontSize: theme.font.size.F1,
  transition: "all .2s ease-in-out",
  minHeight: theme.font.size.F1,
  flexWrap: "nowrap",
});

const valueContainer = (theme: DefaultTheme) => (styles) => ({
  ...styles,
  padding: 0,
  borderBottom: `${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[300]}`,
  color: theme.colors.NEUTRAL[300],
});

const singleValue = (theme: DefaultTheme) => (styles) => ({
  color: theme.colors.NEUTRAL[300],
});

const dropdownIndicator = (theme: DefaultTheme) => (styles) => ({
  padding: 0,
  color: theme.colors.NEUTRAL[300],
});

const smallSelectStyles = (theme: DefaultTheme, hasError: boolean, flexWidth?: boolean): Props["styles"] => ({
  ...baseSelectStyles(theme, hasError, flexWidth, false),
  control: control(theme),
  valueContainer: valueContainer(theme),
  singleValue: singleValue(theme),
  dropdownIndicator: dropdownIndicator(theme),
});

export const SmallSelect: React.FC<SelectProps> = (props) => {
  const theme = useTheme<DefaultTheme>();
  const [formProps, { flexWidth, ...selectProps }] = extractFormProps(props);
  const customComponents = props.customComponents || {};
  return (
    <FormGroup {...formProps} flexWidth={flexWidth}>
      <ReactSelect
        menuPlacement="auto"
        components={{ DropdownIndicator, ...customComponents }}
        styles={smallSelectStyles(theme, formProps.hasError, flexWidth)}
        {...selectProps}
      />
    </FormGroup>
  );
};
