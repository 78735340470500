import { DefaultTheme } from "common/components/ui";
import { IconV2, IconV2Types } from "common/components/ui/IconV2";
import { Appearance, ThemeProps } from "common/components/ui/shared";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { Required } from "utility-types";

const StyledButton = styled.button<ThemeProps>(
  ({ theme }) => `
    position: relative;
    display: block;
    padding: 0;
    color: ${theme.colors.NEUTRAL[400]};
    background-color: transparent;
    height: 2.5rem;
    width: 2.5rem;
    border: ${theme.border.width.B2} solid transparent;
    border-radius: ${theme.border.radius.R2};

    &:focus {
      outline: 0;
      border-color: ${theme.colors.BLUE[200]};

      &:not(:focus-visible) {
        border-color: transparent;
      }
    }

    &:hover {
      color: ${theme.colors.NEUTRAL[500]};
      background-color: ${theme.colors.NEUTRAL[40]};
    }
  `
);

const Indicator = styled.div<{ appearance: Appearance }, DefaultTheme>(
  ({ theme, appearance }) => `
    position: absolute;
    top: 6px;
    right: 6px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${theme.colors[theme.config.colorByAppearance[appearance]][300]};
    border: 2px solid ${theme.colors.NEUTRAL["00"]};
  `
);

export interface NavBarButtonProps extends Required<React.ButtonHTMLAttributes<HTMLButtonElement>, "aria-label"> {
  iconType: IconV2Types;
  indicator?: Appearance;
}

export const NavBarButton: FC<NavBarButtonProps> = ({ iconType, indicator, ...buttonProps }) => {
  return (
    <StyledButton {...buttonProps}>
      <IconV2 type={iconType} size="large" />
      {indicator && <Indicator appearance={indicator} />}
    </StyledButton>
  );
};
