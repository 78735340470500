import { SkuCaseQuantity } from "common/clients/inbound/SkuQuantity/SkuCaseQuantity";
import { ProductCollection } from "common/models";
import { getUnifiedProducts } from "common/wholesale-common/getUnifiedProducts";

export const getSelectedProductsWithPackOf = async (
  dispatch,
  selectedProductsQty: (SkuCaseQuantity & { lotNumber?: string; expirationDate?: string })[]
) => {
  const dskus = selectedProductsQty.map((selectedProduct) => selectedProduct.dsku);
  const products: ProductCollection = await dispatch(getUnifiedProducts(dskus));
  const selectedProductsQtyWithPackOf = selectedProductsQty.map((selectedProduct) => {
    return {
      packOf: products[selectedProduct.dsku].packOf ?? selectedProduct.dsku,
      ...selectedProduct,
    };
  });
  return selectedProductsQtyWithPackOf;
};
