import log from "Logger";
import { RootState } from "RootReducer";
import { SPThunkAction } from "common/ReduxUtils";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { ListType } from "common/list";
import { searchServiceFor } from "common/search/Factory";
import { batch } from "react-redux";
import { SortDir } from "../../../common/models";
import { PrepListActionTypes } from "../../types/list/PrepListActionTypes";
import { searchPrepSuccess } from "./searchPrepSuccess";

export interface SearchPrepProps {
  page?: number;
}

export const searchPrep =
  ({ page }: SearchPrepProps = {}): SPThunkAction =>
  async (dispatch, getState: () => RootState) => {
    const { prepList, user } = getState();
    const { currentSearchTerm, searchInfo } = prepList;
    const { resultsPerPage } = user;

    const prepSearchService = searchServiceFor(ListType.Prep);

    const ctx = { fn: "searchPrep" };
    log.info(ctx, "Initiating prep search");

    dispatch(addLoader(PrepListActionTypes.LOAD_PREP_LIST));

    try {
      const searchParameters = {
        searchTerm: currentSearchTerm === "" ? "*" : currentSearchTerm,
        page: searchInfo?.currentPage ?? page ?? 0,
        pageSize: resultsPerPage.prepList,
        sort: { fieldName: "createdAt", direction: SortDir.DESC },
      };
      const { response, hits } = await prepSearchService.execute(searchParameters);
      log.info(ctx, "successfully retrieved prep jobs");

      batch(() => {
        dispatch(searchPrepSuccess(response, hits));
        dispatch(clearLoader(PrepListActionTypes.LOAD_PREP_LIST));
      });
    } catch (err) {
      log.error({ ...ctx, parcel: getState().parcelList }, "Error during prep search");
      dispatch({ type: PrepListActionTypes.SEARCH_PREP_ERROR });
    }
  };
