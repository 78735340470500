import React from "react";
import { LocalizedText } from "common/components/LocalizedText";

export const ProductSummaryLabel = () => (
  <LocalizedText
    bold
    size="bodyLarge"
    as="span"
    message={{
      id: "inbound.createShipment.cargoDetailsStep.shipmentTotalsSection.productSummary",
      defaultMessage: "Product Summary",
    }}
  />
);
