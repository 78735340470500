import { SPThunkAction } from "common/ReduxUtils";
import { storageClient } from "common/clients/instances";
import { StorageResponseWrapper } from "common/clients/storage/StorageResponseWrapper";
import { updateProductCache } from "inbounds/InboundActions";
import { selectedProductsToStorageItemMapper } from "storage/inbounds/utils/selectedProductsToStorageItemMapper";
import { FeatureName, getFeatureSelector } from "common/Split";
import { updateStorageInboundOrder } from "./updateStorageInboundOrder";
import { setStorageInboundOrderId } from "./setStorageInboundOrderId";
import { setStorageInboundShippingPlanId } from "./setStorageInboundShippingPlanId";
import { getSelectedProducts } from "../selector/getSelectedProducts";
import { getStorageInboundCreate } from "../selector/getStorageInboundCreate";
import { getSelectedProductCasePacks } from "../selector/getSelectedCasePacks";
import { getSellerId } from "common/user/UserSelectors";
import { logError, logStart, logSuccess } from "Logger";
import { selectFromAddress } from "../selector/selectFromAddress";
import { StorageInboundResponse } from "@deliverr/storage-client";

export const createStorageInbound =
  (isSmbAccelerateFlexPlusFeatureOn?: boolean): SPThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const { storageInboundOrderId } = getStorageInboundCreate(state);
    const selectedProducts = getSelectedProducts(state);
    const selectedProductCasePacks = getSelectedProductCasePacks(state);

    const isStorageMultiCasePackOn = getFeatureSelector(FeatureName.StorageMultiCasePack)(state);
    const fromAddress = selectFromAddress(state);

    const sellerId = getSellerId(state);
    const ctx = logStart({ fn: "createStorageInbound", sellerId, selectedProducts, selectedProductCasePacks });
    const additionalRequestParam: { isFlexPlus?: boolean } = {};

    try {
      if (storageInboundOrderId) {
        await dispatch(updateStorageInboundOrder());
      } else {
        const selectedProductsValues = selectedProducts && Object.values(selectedProducts);
        const storageItems = selectedProductsToStorageItemMapper(
          selectedProductsValues,
          undefined,
          undefined,
          selectedProductCasePacks,
          isStorageMultiCasePackOn
        );
        if (isSmbAccelerateFlexPlusFeatureOn) {
          additionalRequestParam.isFlexPlus = true;
        }
        const storageInboundResponse: StorageResponseWrapper<Partial<StorageInboundResponse>> =
          await storageClient.createStorageInbound({
            from: fromAddress,
            sellerId,
            storageItems,
            shouldSubmit: false,
            ...additionalRequestParam,
          });
        await dispatch(setStorageInboundOrderId(storageInboundResponse.value.id!));
        await dispatch(setStorageInboundShippingPlanId(storageInboundResponse.value.shippingPlanId!));
      }
      await dispatch(updateProductCache(selectedProducts.map(({ dsku }) => dsku)));
      logSuccess(ctx, "successfully created draft storage inbound");
    } catch (err) {
      logError(ctx, err);
      throw err;
    }
  };
