import { RootState } from "RootReducer";
import { FeatureName, getFeatureSelector } from "common/Split";
import { createSelector } from "reselect";
import { TransferCreateState } from "./TransferCreateState";
import { TransferCreationTypes } from "../transferCreationTypes";

export const selectTransferCreate = (root: RootState) => root.transferCreate;

export const selectIsMultiShipmentWithFeatureOn = createSelector<RootState, boolean, TransferCreateState, boolean>(
  (root: RootState) => getFeatureSelector(FeatureName.FbaMultiShipmentShipping)(root),
  selectTransferCreate,
  (isMultiShipmentShippingFeatureOn, transferCreateState) => {
    const { destinationType, replenishmentOrder } = transferCreateState;
    return (
      isMultiShipmentShippingFeatureOn &&
      destinationType === TransferCreationTypes.FbaIntegration &&
      (replenishmentOrder?.shipments?.length ?? 0) > 1
    );
  }
);
