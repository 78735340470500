import { productClient } from "Clients";
import { SPThunkAction } from "common/ReduxUtils";
import log from "Logger";
import { Product } from "@deliverr/commons-clients";
import { addStorageProductDetails } from "./SelectStorageProductsActions";

export const getStorageItemDetails = (products: Product[]): SPThunkAction => async (dispatch) => {
  try {
    const dskus = products.map((product) => product.dsku);
    const productDetails = await productClient.getUnifiedProducts(dskus);
    dispatch(addStorageProductDetails(productDetails));
  } catch (err) {
    log.error({ err }, "error getting storage item details");
  }
};
