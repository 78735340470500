import { Box, BoxReset, LocalizedMessage, Title } from "common/components/ui";
import { TextAlign } from "common/components/ui/shared";
import { Currency, CurrencyFormat } from "common/components/Currency";
import * as React from "react";

export interface LabeledCostProps {
  amount: number;
  label: LocalizedMessage;
  currency?: CurrencyFormat;
  textAlign?: TextAlign;
  maximumFractionDigits?: number;
}

export const LabeledCost: React.FC<LabeledCostProps> = ({
  amount,
  label,
  currency = CurrencyFormat.USD,
  maximumFractionDigits = 2,
  textAlign,
}) => {
  return (
    <Box>
      {label}
      <BoxReset marginBottom={null} marginTop="S1">
        <Title displayAs="h2" textAlign={textAlign}>
          <Currency value={amount} currency={currency} maximumFractionDigits={maximumFractionDigits} />
        </Title>
      </BoxReset>
    </Box>
  );
};
