import React from "react";
import styled from "@emotion/styled";

import { StorageInboundProductChooser } from "storage/inbounds/create/selectProducts/StorageInboundProductChooser/StorageInboundProductChooser";
import { Box, DefaultTheme, Grid, Stack, ThemeProps } from "common/components/ui";
import { ShipmentTotalsForm } from "./ShipmentTotalsForm";
import { UnitSystemSelector } from "./UnitSystemSelector";
import { ShipmentTotalsLabel } from "./ShipmentTotalsLabel";
import { NoProductsSelected } from "./NoProductsSelected";
import { LocalizedText } from "common/components/LocalizedText";
import { ElevioBorderlessButton, ElevioArticleId } from "common/elevio";
import { EstimatablePalletsInput } from "./PalletsInput";
import { useProductChooserValidation } from "./useProductChooserValidation";
import { ShipmentTotalsNotifications } from "./ShipmentTotalsNotifications";
import { InputError } from "common/components/InputError";
import { ProductSummaryLabel } from "./Dtc/ProductSummaryLabel";
import { ProductSummaryDetail } from "./Dtc/ProductSummaryDetail";
import { PackageSummary, SingleSkuPackageCollection } from "inbounds/createShipment/CreateShipmentTypes";
import { DtcMixedSkuBoxConfirgurations } from "./Dtc/DtcMixedSkuBoxConfigurations";
import { IntlDtcInboundProductChooser } from "storage/inbounds/create/selectProducts/IntlDtcInboundProductChooser/IntlDtcInboundProductChooser";
import { mapDtcPackageCollectionToPackageSummary } from "inbounds/createShipment/store/utils";

interface UnconnectedProps {
  hasSelectedProducts: boolean;
  isDtcSingleSkuBooking: boolean;
  hasError: boolean;
  errorMessage?: string;
  isIntlDtc: boolean;
  singleSkuPackages: SingleSkuPackageCollection;
  mixedSkuPackages: PackageSummary[];
}

interface Props {
  isStepValidated: boolean;
  isDtcSingleSkuBooking: boolean;
  isIntlDtc: boolean;
  singleSkuPackages: SingleSkuPackageCollection;
  mixedSkuPackages: PackageSummary[];
  isIncompletedPackages: boolean;
}

const ProductChooserContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S5} ${theme.spacing.S5} 0 ${theme.spacing.S5};
`
);

const Container = styled.div<Pick<UnconnectedProps, "hasError">, DefaultTheme>(
  ({ theme, hasError }) => `
    border: 1px solid ${hasError ? theme.colors.RED[400] : theme.colors.NEUTRAL["80"]};
`
);

// Stack introduces negative margins that shift the nested layout
const FlexContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: ${theme.spacing.S5};
`
);

const UnitSystemSelectorContainer = styled(Box)<ThemeProps>`
  display: flex;
  align-items: center;
`;

const StyledTotalsSection = styled(FlexContainer)<ThemeProps>(
  ({ theme }) => `
  background-color: ${theme.colors.NEUTRAL["40"]};
  border-top: 1px solid ${theme.colors.NEUTRAL["80"]};
  padding: ${theme.spacing.S5};
  justify-content: space-between;

  label {
    margin-bottom: 0;
  }
`
);

export const FlexportProductComplianceButton = () => (
  <ElevioBorderlessButton articleId={ElevioArticleId.RestrictedProductsFreight}>
    <LocalizedText
      message={{
        id: "inbounds.flexportProductComplianceButton",
        defaultMessage: "Flexport's Product Regulations",
      }}
    />
  </ElevioBorderlessButton>
);

const DtcShipmentTotalForm = () => {
  return (
    <Box>
      <ShipmentTotalsLabel />
      <Stack direction="HORIZONTAL">
        <UnitSystemSelectorContainer>
          <UnitSystemSelector />
        </UnitSystemSelectorContainer>
        <Box>
          <ShipmentTotalsForm isTotalUnitsReadOnly hasInlineInputs />
        </Box>
      </Stack>
    </Box>
  );
};

const DtcProductSummary = ({ packages }: { packages: PackageSummary[] }) => {
  return (
    <Stack>
      <ProductSummaryLabel />
      <ProductSummaryDetail packages={packages} />
    </Stack>
  );
};

export const DtcShipmentTotalsSection = ({ packages }: { packages: PackageSummary[] }) => (
  <StyledTotalsSection>
    <Grid columns="2fr 1fr" fullWidth>
      <Box marginLeft="S2">
        <DtcShipmentTotalForm />
      </Box>
      <Box>
        <DtcProductSummary packages={packages} />
      </Box>
    </Grid>
  </StyledTotalsSection>
);

const UnconnectedProductChooserSection = ({
  hasSelectedProducts,
  hasError,
  errorMessage,
  isDtcSingleSkuBooking,
  isIntlDtc,
  singleSkuPackages,
  mixedSkuPackages,
}: UnconnectedProps) => {
  return (
    <section>
      {isIntlDtc && !isDtcSingleSkuBooking ? (
        <DtcMixedSkuBoxConfirgurations mixedSkuPackages={mixedSkuPackages} />
      ) : (
        <Container hasError={hasError}>
          <ProductChooserContainer>
            {isIntlDtc && isDtcSingleSkuBooking ? (
              <IntlDtcInboundProductChooser
                isDtcSingleSkuBooking
                singleSkuPackages={singleSkuPackages}
                isIntlRs={!isIntlDtc}
              />
            ) : (
              <StorageInboundProductChooser isIntlRs={!isIntlDtc} />
            )}
          </ProductChooserContainer>
          {isIntlDtc ? (
            hasSelectedProducts ? (
              <DtcShipmentTotalsSection
                packages={
                  isDtcSingleSkuBooking ? mapDtcPackageCollectionToPackageSummary(singleSkuPackages) : mixedSkuPackages
                }
              />
            ) : (
              <Box paddingTop="S4"></Box>
            )
          ) : hasSelectedProducts ? (
            <StyledTotalsSection>
              <FlexContainer>
                <ShipmentTotalsLabel />
                <UnitSystemSelector />
              </FlexContainer>
              <ShipmentTotalsForm isTotalUnitsReadOnly hasInlineInputs />
            </StyledTotalsSection>
          ) : (
            <Box paddingTop="S5" paddingBottom="S7">
              <NoProductsSelected />
            </Box>
          )}
        </Container>
      )}
      {errorMessage && (
        <Box marginTop="S2">
          <InputError message={errorMessage} />
        </Box>
      )}
      <ShipmentTotalsNotifications omitPallets />
      {hasSelectedProducts && !isIntlDtc && (
        <Box marginTop="S7">
          <EstimatablePalletsInput />
        </Box>
      )}
    </section>
  );
};

export const ProductChooserSection = ({
  isStepValidated,
  isDtcSingleSkuBooking,
  isIntlDtc,
  singleSkuPackages,
  mixedSkuPackages,
  isIncompletedPackages,
}: Props) => {
  const { hasSelectedProducts, hasError, errorMessage } = useProductChooserValidation(
    isStepValidated,
    isIncompletedPackages,
    singleSkuPackages,
    isDtcSingleSkuBooking,
    isIntlDtc
  );

  return (
    <UnconnectedProductChooserSection
      isDtcSingleSkuBooking={isDtcSingleSkuBooking}
      hasSelectedProducts={hasSelectedProducts}
      hasError={hasError}
      errorMessage={errorMessage}
      isIntlDtc={isIntlDtc}
      singleSkuPackages={singleSkuPackages}
      mixedSkuPackages={mixedSkuPackages}
    />
  );
};
