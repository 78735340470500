import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { SPThunkAction } from "common/ReduxUtils";
import log from "Logger";
import { batch } from "react-redux";
import { RootState } from "RootReducer";
import { ParcelListActionTypes } from "parcel/screens/list/ParcelListActionTypes";
import { searchServiceFor } from "common/search/Factory";
import { ListType } from "common/list";
import { searchParcelSuccess } from "parcel/screens/list/actions/searchParcelSuccess";
import { isDateFilterActive, isStatusFilterActive } from "parcel/screens/list/ParcelListTable/helpers";

export interface SearchParcelsProps {
  page?: number;
}

export const searchParcels = ({ page }: SearchParcelsProps = {}): SPThunkAction => async (
  dispatch,
  getState: () => RootState
) => {
  const { parcelList, user } = getState();
  const { currentSearchTerm, searchInfo, filters, sort } = parcelList;
  const { resultsPerPage } = user;

  const parcelSearchService = searchServiceFor(ListType.Parcel);

  const ctx = { fn: "searchParcels" };
  log.info(ctx, "Initiating parcel search");

  dispatch(addLoader(ParcelListActionTypes.LOAD_PARCEL_LIST));

  const activeFilters: string[] = [];

  if (isStatusFilterActive(filters)) {
    activeFilters.push(`status=${filters.status}`);
  }

  if (isDateFilterActive(filters)) {
    activeFilters.push(`createdAt=>${filters.date}`);
  }

  try {
    const searchParameters = {
      searchTerm: currentSearchTerm,
      page: searchInfo?.currentPage ?? page ?? 0,
      pageSize: resultsPerPage.parcelList,
      filters: activeFilters,
      sort,
    };
    const { response, hits } = await parcelSearchService.execute(searchParameters);
    log.info(ctx, "successfully retrieved parcels");

    batch(() => {
      dispatch(searchParcelSuccess(response, hits));
      dispatch(clearLoader(ParcelListActionTypes.LOAD_PARCEL_LIST));
    });
  } catch (err) {
    log.error({ ...ctx, parcel: getState().parcelList }, "Error during parcel search");
    dispatch({ type: ParcelListActionTypes.SEARCH_PARCELS_ERROR });
  }
};
