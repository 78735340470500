import { Text, ThemeProps, Title } from "common/components/ui";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { NonComplianceCard } from "../NonComplianceStyles";

const EmptyListContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    height: 400px;
    padding: ${theme.spacing.S7};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `
);

const TitleWithMargin = styled(Title)<ThemeProps>(
  ({ theme }) =>
    `
      margin-top: 0;
      margin-bottom: ${theme.spacing.S2};
    `
);

export const NonComplianceEmptyList: FC = () => {
  return (
    <NonComplianceCard>
      <EmptyListContainer>
        <TitleWithMargin as="h1" displayAs="h3">
          <FormattedMessage
            id="inbounds.nonCompliance.nonComplianceEmptyList.title"
            defaultMessage="No problem shipments"
          />
        </TitleWithMargin>
        <Text>
          <FormattedMessage
            id="inbounds.nonCompliance.nonComplianceEmptyList.message"
            defaultMessage="You can track problem tickets within their associated shipping plans."
          />
        </Text>
      </EmptyListContainer>
    </NonComplianceCard>
  );
};
