import { ReturnItemMinimal } from "@deliverr/returns-client";

export const getEstimatedLabelCost = (returnItems: ReturnItemMinimal[], estimatedTotalCost) => {
  return returnItems.reduce((acc, item) => {
    acc -= (item.inspectionRate ?? 0) * item.quantity;
    return acc;
  }, estimatedTotalCost);
};

export const getNumberOfItems = (returnItems?: ReturnItemMinimal[]) => {
  return (
    returnItems?.reduce((acc, returnItem) => {
      acc += returnItem.quantity;
      return acc;
    }, 0) ?? 0
  );
};
