import { Button, Container, DefaultTheme, Input, Stack } from "common/components/ui";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useShareLabel } from "./useShareLabel";

const BuyerEmailInputContainer = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
  text-align: left;
  margin-bottom: ${theme.spacing.S4}
  `
);

const ShareLabelContainer = styled(Container)`
  height: auto;
`;
interface ShareLabelProps {
  shareLabelSuccess?: () => void;
}
const ShareLabel: FC<ShareLabelProps> = ({ shareLabelSuccess }) => {
  const {
    downloadClick,
    shareClick,
    setBuyerEmail,
    validateEmail,
    buyerEmail,
    shareDisabled,
    downloadDisabled,
    isBuyerEmailValid,
  } = useShareLabel(shareLabelSuccess);

  return (
    <ShareLabelContainer>
      <BuyerEmailInputContainer>
        <Input
          label={<FormattedMessage id="returns.shareLabel.emailInput.label" defaultMessage="Customer email" />}
          data-testid="returns-buyer-email-input"
          type="email"
          value={buyerEmail}
          onChange={(e) => {
            setBuyerEmail(e.target.value);
            validateEmail(e.target.value);
          }}
          placeholder="Enter email"
          hasError={!isBuyerEmailValid}
          errorMessage={
            <FormattedMessage id="returns.shareLabel.emailInput.invalidMessage" defaultMessage="Invalid email" />
          }
        />
      </BuyerEmailInputContainer>

      <Stack direction="HORIZONTAL">
        <Button data-testid="returns-label-share-button" disabled={shareDisabled} onClick={shareClick}>
          <FormattedMessage id={"returns.shareLabel.shareButton"} defaultMessage={"Email label"} />
        </Button>
        <Button
          data-testid="returns-label-download-button"
          disabled={downloadDisabled}
          secondary={true}
          onClick={downloadClick}
        >
          <FormattedMessage id={"returns.shareLabel.downloadButton"} defaultMessage={"Download label"} />
        </Button>
      </Stack>
    </ShareLabelContainer>
  );
};

export default ShareLabel;
