import { ReturnAddress, ReturnStatus, ReturnType } from "@deliverr/returns-client";

import { ReturnsFilterV2, ReturnsTypeFilterV2 } from "../ReturnsFilterMessagesV2";

export enum ReturnsTableActionTypes {
  RESET_RETURNS = "RESET_RETURNS",
  SEARCH_RETURNS_SUCCESS = "SEARCH_RETURNS_SUCCESS",
  SEARCH_RETURNS_UPDATE_TERM = "SEARCH_RETURNS_UPDATE_TERM",
  SEARCH_RETURNS_FAIL = "SEARCH_RETURNS_FAIL",
}

interface ReturnItemV2 {
  readonly returnId: number;
  readonly dsku: string;
  readonly quantity: number;
  readonly inspectionRate?: number;
}

export interface ReturnPageItemV2 {
  id: string;
  rma: string;
  channelId: string;
  channelOrderId: string;
  fulfillmentOrderId: string;
  createdAt: string;
  from: ReturnAddress;
  status: ReturnStatus;
  expectedItemCount: number;
  estimatedTotalCost: number;
  inspectionFeeCharged: number;
  shippingFeeCharged: number;
  estimatedShippingFeeCost: number;
  returnItems: ReturnItemV2[];
  qcId?: string;
  hasUnknownItem?: boolean;
  returnType: ReturnType;
}

export interface ReturnsListFilterV2 {
  status?: ReturnsFilterV2;
  returnType?: ReturnsTypeFilterV2;
}

export interface ReturnsListStateV2 {
  currentPageItems: ReturnPageItemV2[];
  searchTerm: string;
  pagingInfo: {
    currentPage: number;
    itemsPerPage: number;
    totalPages: number;
  };
  filter: ReturnsListFilterV2;
}
