import { batch } from "react-redux";
import { addLoader, clearLoader } from "../components/WithLoader/LoadingActions";
import { wholesaleClient } from "../../Clients";
import { Channel, WholesaleOrderDTO, WholesaleOrderUpsertRequestDTO } from "@deliverr/wholesale-client";
import { AnyAction } from "redux";
import { RootState } from "../../RootReducer";
import { WholesaleCreateState } from "../../wholesale/create/store/WholesaleCreateState";
import { SelectedProductData } from "../components/ProductChooser";
import { getWholesaleOrderWithPacks } from "storage/packUtils/getWholesaleorderWithPacks";
import { WholesaleErrorType } from "./utils/WholesaleErrorTypes";
import { FeatureName, isFeatureOn } from "common/Split";
import { createWholesaleOrderShipment } from "./createWholesaleOrderShipments";

interface UpdateWholesaleOrderParams {
  loaderId: string;
  createStateSelector: (state: RootState) => WholesaleCreateState & { fbaShipmentId?: string; fbaReferenceId?: string };
  productSelector: (state: RootState) => SelectedProductData[];
  setError: (errorType: string) => AnyAction;
  setValue: (order: WholesaleOrderDTO) => AnyAction;
}

export const sharedUpdateWholesaleOrder = ({
  createStateSelector,
  productSelector,
  loaderId,
  setError,
  setValue,
}: UpdateWholesaleOrderParams) => async (dispatch, getState): Promise<boolean> => {
  const state = getState();

  const {
    wholesaleOrder,
    shippingMethod,
    cargoType,
    fbaShipmentId,
    fbaReferenceId,
    originStorageWarehouse,
  } = createStateSelector(state);

  if (!wholesaleOrder?.id) {
    return false;
  }

  const isMultiPackEnabled = isFeatureOn(FeatureName.StorageMultiCasePack);

  const selectedProductsQty = productSelector(state);

  batch(() => {
    dispatch(addLoader(loaderId));
    dispatch(setError(WholesaleErrorType.NONE));
  });

  const response: WholesaleOrderDTO = await dispatch(getWholesaleOrderWithPacks(wholesaleOrder?.id as string));
  dispatch(setValue(response));

  const request: WholesaleOrderUpsertRequestDTO = {
    externalId: response.externalId ?? fbaShipmentId,
    createdAutomatically:
      response.createdAutomatically === true || response.channel === Channel.JIT_FBA_RECOMMENDATIONS,
    shipments: [
      createWholesaleOrderShipment(
        response,
        selectedProductsQty,
        shippingMethod,
        cargoType,
        fbaShipmentId,
        fbaReferenceId,
        originStorageWarehouse,
        isMultiPackEnabled
      ),
    ],
    channel: response.channel,
  };

  await wholesaleClient.updateOrderAndShipments(wholesaleOrder.id, "", request);

  dispatch(clearLoader(loaderId));
  return true;
};
