import { SelectComponents, Text } from "common/components/ui";
import { NoOptionsMessageProps } from "@deliverr/react-select-v1";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { TextAlign } from "common/components/ui/shared";

export const PlaceSearchNoOption: FC<NoOptionsMessageProps> = ({ children, ...props }) => {
  return (
    <SelectComponents.NoOptionsMessage {...props}>
      <Text as="div" textAlign={TextAlign.center} appearance="INFO">
        <FormattedMessage id="originSearch.noResults" defaultMessage="No results" />
      </Text>
    </SelectComponents.NoOptionsMessage>
  );
};
