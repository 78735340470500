import React, { FC } from "react";
import { Stack, Text, ThemeProps, LocalizedMessage } from "common/components/ui";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";

import { PROBLEM_BLADE_STATUS_LABELS } from "./labels";
import { TextAlign } from "common/components/ui/shared";
import { CompletedIcon, PendingIcon } from "common/components/ProblemBladeIcon";
import { SellerProblemStatus } from "@deliverr/legacy-inbound-client";

const SectionWrapper = styled.div<ThemeProps>(
  ({ theme }) =>
    `
    padding-top: ${theme.spacing.S4};
    text-align: center;
  `
);

export interface ProblemBladeStatusSectionProps {
  status: SellerProblemStatus;
  messageOverride?: LocalizedMessage;
  headerOverride?: LocalizedMessage;
}

export const ProblemBladeStatusSection: FC<ProblemBladeStatusSectionProps> = ({
  status,
  messageOverride,
  headerOverride,
}) => {
  const isInProgress =
    status === SellerProblemStatus.CREATED ||
    status === SellerProblemStatus.SUPPORT_CONTACTED ||
    status === SellerProblemStatus.ACTION_NEEDED;

  const statusIcon = isInProgress ? <PendingIcon /> : <CompletedIcon />;

  return (
    <SectionWrapper>
      <Stack center>
        {statusIcon}
        <Text size="bodyLarge" bold>
          {headerOverride ?? <FormattedMessage {...PROBLEM_BLADE_STATUS_LABELS[status].header} />}
        </Text>
        <Text textAlign={TextAlign.center}>
          {messageOverride ?? <FormattedMessage {...PROBLEM_BLADE_STATUS_LABELS[status].message} />}
        </Text>
      </Stack>
    </SectionWrapper>
  );
};
