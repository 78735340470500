import { Space, DefaultTheme, Responsive, getResponsiveStyles } from "../shared";
import { getNegativeMargin } from "./getNegativeMargin";
import { getPositiveMargin } from "./getPositiveMargin";

export const getHorizontalStyles = (
  theme: DefaultTheme,
  center: boolean,
  gap?: Responsive<keyof typeof Space | null>
) => `
  flex-direction: row;
  flex-wrap: wrap;
  ${center ? `justify-content: center;` : ``}
  ${getResponsiveStyles("margin-left", gap, getNegativeMargin(theme))}
  ${getResponsiveStyles("margin-right", gap, getNegativeMargin(theme))}
  > * {
    ${getResponsiveStyles("margin-left", gap, getPositiveMargin(theme))}
    ${getResponsiveStyles("margin-right", gap, getPositiveMargin(theme))}
  }
`;
