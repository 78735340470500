import { PrepType, PrepCombo } from "@deliverr/prep-service-client";
import { DtcPrepOption, UnitPrepService } from "inbounds/createShipment/CreateShipmentTypes";
import { DtcPrepOption as DtcPrepOptionInbound } from "@deliverr/legacy-inbound-client/lib/booking/v2/SubmitBookingRequest";

export const inboundDtcPrepToDtcPrepOption = (dtcPrepSelections: DtcPrepOptionInbound | undefined) => {
  const { prepTypes, prepCombo } = dtcPrepSelections ?? { prepTypes: [], prepCombo: undefined };

  const dtcPrepOption: DtcPrepOption = {
    boxLabelingUnitsSelected: false,
    unitPrepService: null,
  };

  if (prepTypes?.includes(PrepType.BOX_CONTENT_LABELS)) {
    dtcPrepOption.boxLabelingUnitsSelected = true;
  }
  if (prepCombo === PrepCombo.BAGGING_PROTECTION) {
    dtcPrepOption.unitPrepService = UnitPrepService.BAGGING_PROTECTION;
    return dtcPrepOption;
  }
  if (prepCombo === PrepCombo.DAMAGE_PROTECTION) {
    dtcPrepOption.unitPrepService = UnitPrepService.DAMAGE_PROTECTION;
    return dtcPrepOption;
  }
  if (prepTypes?.includes(PrepType.SKU_LABELS)) {
    dtcPrepOption.unitPrepService = UnitPrepService.UNIT_BARCODING;
    return dtcPrepOption;
  }

  return dtcPrepOption;
};
