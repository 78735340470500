import { combineReducers, Reducer } from "redux";

import { SelectedActionState, selectedActionReducer, initialSelectedAction } from "./selectedActionReducer";
import { UnsyncStatusState, unsyncStatusReducer, initialUnsyncStatus } from "./unsyncStatusReducer";
import { adSetsReducer, initialAdSets, WithAdSetSyncState } from "tags/facebook/common/state/adSetsReducer";

export type FacebookEditFlowState = {
  selectedAction: SelectedActionState;
  unsyncStatus: UnsyncStatusState;
} & WithAdSetSyncState;

export const facebookEditFlowInitialState: FacebookEditFlowState = {
  selectedAction: initialSelectedAction,
  unsyncStatus: initialUnsyncStatus,
  adSets: initialAdSets,
};

export const facebookEditFlowReducer: Reducer<FacebookEditFlowState> = combineReducers({
  selectedAction: selectedActionReducer,
  unsyncStatus: unsyncStatusReducer,
  adSets: adSetsReducer,
});
