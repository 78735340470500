import { InvalidOrderItemResolution } from "@deliverr/replenishment-client";
import styled from "@emotion/styled";
import Table, { TableProps } from "common/components/lists/Table";
import { IconV2, Pill, PillColors, Text } from "common/components/ui";
import { DefaultTheme, defaultTheme } from "common/components/ui/shared";
import { HorizontalAlignment } from "common/models";
import { compact, values } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ResolvedProductDetails } from "./ResolvedProductDetails";
import { UNRESOLVED_INVALID_ITEM_RESOLUTION, invalidOrderItemLabel } from "./invalidOrderItemLabel";
import { AugmentedInvalidOrderItem } from "./ReplenishmentInvalidOrderItems";
import { isEmpty } from "lodash/fp";

const StatusPill = styled(Pill)`
  line-height: 1.7rem;
  white-space: nowrap;
`;

const getStatusPillColor = (resolution?: InvalidOrderItemResolution | null) => {
  switch (resolution) {
    case InvalidOrderItemResolution.MODIFIED:
      return PillColors.GREEN;
    case InvalidOrderItemResolution.REMOVED:
      return PillColors.GRAY;
    case undefined:
    case null:
      return PillColors.RED;
    default:
      return PillColors.GRAY;
  }
};

export const invalidItemProblemTableColumns = [
  {
    header: <FormattedMessage id={"transfers.invalidOrderItem.problemType"} defaultMessage={"Problem Type"} />,
    renderCell: (item: AugmentedInvalidOrderItem) => (
      <>
        <Text bold>{<FormattedMessage {...invalidOrderItemLabel.reasons[item.reason]} />}</Text>
        <Text appearance="INFO" size="caption">
          {compact(values(item.identifier)).join(", ")}
        </Text>
      </>
    ),
    align: HorizontalAlignment.LEFT,
  },
  {
    header: <FormattedMessage id={"transfers.invalidOrderItem.resolvedProduct"} defaultMessage={"Resolved Product"} />,
    renderCell: (item: AugmentedInvalidOrderItem) => {
      return <ResolvedProductDetails dsku={item.resolvedDsku} />;
    },
  },
  {
    header: <FormattedMessage id={"transfers.invalidOrderItem.totalUnits"} defaultMessage={"Fulfilled quantity"} />,
    renderCell: (item: AugmentedInvalidOrderItem) => (
      <>
        {item.resolvedTotalUnits ?? "-"} / {item.requestedTotalUnits}
      </>
    ),
  },
  {
    header: <FormattedMessage id={"transfers.invalidOrderItem.unitsPerPack"} defaultMessage={"Units per pack"} />,
    renderCell: (item: AugmentedInvalidOrderItem) => (
      <Text>
        {item.requestedUnitsPerPack ? (
          item.resolvedUnitsPerPack ? (
            <>
              <s>{item.requestedUnitsPerPack}</s> {item.resolvedUnitsPerPack}
            </>
          ) : (
            item.requestedUnitsPerPack
          )
        ) : (
          item.resolvedUnitsPerPack ?? (
            <FormattedMessage id="transfers.invalidOrderItem.unitsPerPack.unavailable" defaultMessage="N/A" />
          )
        )}
      </Text>
    ),
  },
  {
    header: <FormattedMessage id={"transfers.valid.order.items.resolution.status"} defaultMessage={"Status"} />,
    renderCell: (item: AugmentedInvalidOrderItem) => <InvalidOrderItemStatusPill resolution={item.resolution} />,
  },
  {
    width: "44px",
    header: "",
    renderCell: () => <IconV2 type="angle-right" color={defaultTheme.colors.BLUE[300]} />,
  },
];

export const InvalidItemProblemTable = styled(Table)<TableProps<AugmentedInvalidOrderItem>, DefaultTheme>(
  ({ theme }) => `
  box-shadow: none;
  margin-bottom: 0;

  thead > tr > th {
    color: ${theme.colors.NEUTRAL["500"]};
  }

  tbody {
    tr, td {
      height: 70px;
    }
  }

  /* calculate width of first column with 2rem of extra space due to padding */
  tr > th:first-of-type, tr > td:first-of-type {
    padding-left: 0;
    width: calc(((998px - 2rem) / 6) + 2rem);
  }
`
);

export const InvalidOrderItemStatusPill: React.FC<{ resolution?: InvalidOrderItemResolution | null }> = ({
  resolution,
}) => {
  return (
    <div data-testid="replenishment-invalid-order-item-pill">
      <StatusPill color={getStatusPillColor(resolution)}>
        <FormattedMessage
          {...invalidOrderItemLabel.resolutions[isEmpty(resolution) ? UNRESOLVED_INVALID_ITEM_RESOLUTION : resolution!]}
        />
      </StatusPill>
    </div>
  );
};
