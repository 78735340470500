import React, { useState } from "react";
import { ManagedStep } from "common/components/StepContainer";
import { useIntl } from "react-intl";
import { wholesaleCreateLabels } from "../wholesaleCreate.labels";
import { Box, Dropzone, Textarea, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import { WholesaleShipmentType } from "@deliverr/wholesale-client";
import { useWholesaleLabelsStep } from "./useWholesaleLabelsStep";
import { useCheckDuplicationLabelsError } from "../../../common/wholesale-common/sharedCreateUploadWholesaleLabelFile";

const DropzoneContainer = styled.div`
  width: 100%;
`;

const TemporarilyHiddenDropzoneContainer = styled.div`
  width: 0;
  display: none;
`;

const FlexColumn = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${theme.spacing.S4};
`
);

const FlexRow = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  width: 100%;
  gap: ${theme.spacing.S4};
`
);

const FullWidthTextareaContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  width: 100%;
  label {
    width: 100%;
    max-width: calc(2 * (438px + ${theme.spacing.S4}) + ${theme.spacing.S4});
  }
`
);

export const LabelsStep = () => {
  const { formatMessage } = useIntl();
  const {
    cargoType,
    notes,
    onBoxFileChange,
    onPalletFileChange,
    onGuidelinesFileChange,
    onPackingSlipFileChange,
    onBoxFileClear,
    onPalletFileClear,
    onGuidelinesFileClear,
    onPackingSlipFileClear,
    isUploadingLabel,
    boxLabelFilename,
    palletLabelFilename,
    packingLabelFilename,
    guidelinesFilename,
    errorType,
  } = useWholesaleLabelsStep();
  const [currentAdditionalNotes, setCurrentAdditionalNotes] = useState(notes ?? "");
  const hasSecondRow = cargoType === WholesaleShipmentType.PARTIAL_PALLET;
  const errorMsgDuplicate = useCheckDuplicationLabelsError();

  const onAdditionalNotesChange = (newValue: string) => {
    setCurrentAdditionalNotes(newValue);
  };

  const prepareNextData = async () => {
    return {
      additionalNotes: currentAdditionalNotes,
    };
  };

  // Notes are disabled for now according to this ticket - https://shopifylogistics.atlassian.net/browse/B2BO-399
  const notesEnabled = false;

  return (
    <ManagedStep
      title={formatMessage({ ...wholesaleCreateLabels.steps.labels.title })}
      subtitle={formatMessage({ ...wholesaleCreateLabels.steps.labels.subtitle })}
      nextData={prepareNextData}
      isNextDisabled={false}
    >
      <Box width="50rem" maxWidth="85%">
        <FlexColumn>
          <FlexRow>
            <DropzoneContainer>
              <Dropzone
                id="dropzone-wholesale-box-labels"
                acceptedFileTypes={["pdf"]}
                onChange={onBoxFileChange}
                onFileClear={onBoxFileClear}
                loading={isUploadingLabel?.CASE_LABELS}
                label={formatMessage(wholesaleCreateLabels.steps.labels.boxLabels)}
                value={boxLabelFilename ? { fileName: boxLabelFilename } : undefined}
                dropzoneProps={{ maxFiles: 1 }}
                errorText={errorMsgDuplicate(errorType)}
              />
            </DropzoneContainer>
            <TemporarilyHiddenDropzoneContainer>
              <Dropzone
                id="dropzone-wholesale-guidelines"
                acceptedFileTypes={["pdf"]}
                onChange={onGuidelinesFileChange}
                loading={isUploadingLabel?.VENDOR_GUIDELINES}
                value={guidelinesFilename ? { fileName: guidelinesFilename } : undefined}
                onFileClear={onGuidelinesFileClear}
                label={formatMessage({ ...wholesaleCreateLabels.steps.labels.vendorGuidelines })}
                dropzoneProps={{ maxFiles: 1 }}
                errorText={errorMsgDuplicate(errorType)}
              />
            </TemporarilyHiddenDropzoneContainer>
          </FlexRow>
          {hasSecondRow && (
            <FlexRow>
              <DropzoneContainer>
                <Dropzone
                  id="dropzone-wholesale-pallet-labels"
                  acceptedFileTypes={["pdf"]}
                  onChange={onPalletFileChange}
                  onFileClear={onPalletFileClear}
                  loading={isUploadingLabel?.PALLET_LABELS}
                  label={formatMessage({ ...wholesaleCreateLabels.steps.labels.palletLabels })}
                  value={palletLabelFilename ? { fileName: palletLabelFilename } : undefined}
                  dropzoneProps={{ maxFiles: 1 }}
                  errorText={errorMsgDuplicate(errorType)}
                />
              </DropzoneContainer>
              <TemporarilyHiddenDropzoneContainer>
                <Dropzone
                  id="dropzone-wholesale-packing-slip"
                  acceptedFileTypes={["pdf"]}
                  onChange={onPackingSlipFileChange}
                  loading={isUploadingLabel?.PACKING_SLIP}
                  value={packingLabelFilename ? { fileName: packingLabelFilename } : undefined}
                  onFileClear={onPackingSlipFileClear}
                  label={formatMessage({ ...wholesaleCreateLabels.steps.labels.packingSlip })}
                  dropzoneProps={{ maxFiles: 1 }}
                  errorText={errorMsgDuplicate(errorType)}
                />
              </TemporarilyHiddenDropzoneContainer>
            </FlexRow>
          )}
          {notesEnabled && (
            <FlexRow hidden={true}>
              <FullWidthTextareaContainer>
                <Textarea
                  label={formatMessage({ ...wholesaleCreateLabels.steps.labels.additionalNotes })}
                  value={currentAdditionalNotes}
                  onChange={(evt) => onAdditionalNotesChange(evt.currentTarget.value)}
                />
              </FullWidthTextareaContainer>
            </FlexRow>
          )}
        </FlexColumn>
      </Box>
    </ManagedStep>
  );
};
