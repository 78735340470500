import { createActionCreator } from "common/ReduxUtils";
import { PrepActionTypes } from "prep/store/PrepActionTypes";
import { BoxContentLabelingOption } from "inbounds/types/BoxContentLabelingOption";

/**
 * @param selectedBclOption BoxContentLabelingOption value denoting which bcl option has been selected
 */
export const setBclSelection = createActionCreator<BoxContentLabelingOption | undefined, number>(
  PrepActionTypes.SET_BCL_SELECTION,
  "selectedBclOption",
  "shipmentId"
);
