import { datadogRum } from "@datadog/browser-rum";
import { LogContext } from "@deliverr/ui-logging";

export class DatadogFnLogTimer {
  fnStartTimes: Record<string, number | undefined>;

  constructor() {
    this.fnStartTimes = {};
  }

  start = ({ fn }: LogContext): void => {
    this.fnStartTimes[fn!] = window.performance.now();
  };

  clearStartTime = ({ fn }: LogContext): void => {
    this.fnStartTimes[fn!] = undefined;
  };

  error = this.clearStartTime;

  // send successful function timings to DataDog as custom actions
  success = (ctx: LogContext): void => {
    const { fn, ...context } = ctx;
    const startTime = this.fnStartTimes[fn!];
    if (typeof startTime !== "undefined") {
      const endTime = window.performance.now();
      const duration = endTime - startTime;
      // get the view attributes from the initial start log
      const startContext = datadogRum.getInternalContext(startTime);
      const startContextView = startContext?.view;
      const composedContext = { duration, ...context, overrides: { view: startContextView } };
      datadogRum.addAction(fn!, composedContext);
      this.clearStartTime(ctx);
    }
  };
}
