import { Reducer } from "redux";

import { createReducer } from "common/ReduxUtils";
import { MediaActions, MediaActionTypes } from "./MediaActions";

export enum MediaLoadingState {
  Loading = "loading",
  Loaded = "loaded",
  Error = "error",
}

export interface MediaState {
  [key: string]: MediaLoadingState;
}

export const mediaInitialState: MediaState = {};

export const mediaReducer: Reducer<MediaState, MediaActions> = createReducer(mediaInitialState, {
  [MediaActionTypes.MEDIA_FETCHED]: (state, { url }) => ({
    ...state,
    [url]: MediaLoadingState.Loading,
  }),

  [MediaActionTypes.MEDIA_LOADED]: (state, { url }) => ({
    ...state,
    [url]: MediaLoadingState.Loaded,
  }),

  [MediaActionTypes.MEDIA_LOADING_ERRORED]: (state, { url }) => ({
    ...state,
    [url]: MediaLoadingState.Error,
  }),
});
