import { SPThunkAction } from "common/ReduxUtils";
import { CreateBookingRequest, CreateBookingResponse } from "@deliverr/legacy-inbound-client";
import { inboundClient } from "Clients";
import { logError, logStart, logSuccess } from "Logger";
import { SmbBookingCreationTimedEvent } from "inbounds/createShipment/common/analytics/analytics.types";
import { getSellerId } from "common/user/UserSelectors";
import { selectBookingCreateRequest } from "../selectors/selectBookingCreateRequest";

export const updateBookingOrigin =
  (shippingPlanId: number): SPThunkAction<Promise<CreateBookingResponse>> =>
  async (dispatch, getState) => {
    const state = getState();
    const sellerId = getSellerId(state);
    const bookingRequest: CreateBookingRequest = selectBookingCreateRequest()(state);
    const ctx = logStart({ fn: SmbBookingCreationTimedEvent.UpdateBooking, shippingPlanId, bookingRequest });

    try {
      const response = await inboundClient.bookingSetOrigin(sellerId, shippingPlanId, bookingRequest);
      logSuccess(ctx, "successfully set origin");
      return response;
    } catch (error) {
      logError(ctx, error);
      throw error;
    }
  };
