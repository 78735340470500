import { LTL_PALLET_INITIAL_LENGTH, LTL_PALLET_INITIAL_WIDTH } from "freight/constants/cargoPalletConstants";
import { CargoPalletDetailsState } from "freight/types/CargoPalletTypes";
import { FreightPallet } from "freight/types/FreightPallet";

export const newPalletItem: FreightPallet = {
  totalPallets: 1,
  palletDimensions: {
    width: LTL_PALLET_INITIAL_WIDTH,
    length: LTL_PALLET_INITIAL_LENGTH,
    height: ("" as unknown) as number, // prevent a default value
    weight: ("" as unknown) as number, // prevent a default value
  },
};

export const cargoPalletDetailsInitialState: CargoPalletDetailsState = {
  totalPalletCount: undefined,
  totalPalletWeight: undefined,
  palletLineItems: [],
};
