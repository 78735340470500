import { InvalidOrderItemDto } from "@deliverr/replenishment-client";
import { ManagedStep } from "common/components/StepContainer";

import { BoxReset, Notification } from "common/components/ui";
import { ProductsUnitsTable } from "common/wholesale-common/components/ProductsUnitsTable";
import { ViewShipmentCard } from "components/Shipment/ViewShipmentCard";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { isEmpty, noop } from "lodash";
import React from "react";
import { InvalidItemProblemTable, invalidItemProblemTableColumns } from "./InvalidItemProblemTable";
import { InvalidOrderItemBlade } from "./InvalidOrderItemBlade";
import { invalidOrderItemLabel } from "./invalidOrderItemLabel";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import {
  ReplenishmentAvailableInventoryErrorNotification,
  useReplenishmentAvailableInventoryErrorNotification,
} from "transfers/create/steps/common/ReplenishmentAvailableInventoryErrorNotification";
import { getSkuQtiesForInvalidItemOrder } from "./InvalidItemOrderUtils";
import { useReplenishmentInvalidOrderItems } from "./useReplenishmentInvalidOrderItems";

export interface AugmentedInvalidOrderItem extends InvalidOrderItemDto {
  resolvedUnitsPerPack?: number;
}

export const ReplenishmentInvalidOrderItems = () => {
  const {
    formatMessage,
    isBladeOpen,
    invalidItem,
    setBladeOpen,
    replenishmentOrder,
    unifiedProductResponseCache,
    isLoading,
    onRowClick,
    isNextDisabled,
    areAllOrderItemsRemoved,
    augmentedInvalidOrderItems,
  } = useReplenishmentInvalidOrderItems();

  const { errorCode, shouldShowErrorNotification } = useReplenishmentAvailableInventoryErrorNotification(
    getSkuQtiesForInvalidItemOrder(replenishmentOrder!)
  );

  return (
    <ManagedStep
      data-testid="transfer-sps-invalid-items-step"
      title={formatMessage(invalidOrderItemLabel.step.title)}
      subtitle={formatMessage(invalidOrderItemLabel.step.subtitle)}
      isNextDisabled={isNextDisabled || shouldShowErrorNotification}
      isLoading={isLoading}
    >
      {invalidItem ? (
        <InvalidOrderItemBlade
          id={String(invalidItem?.id ?? "")}
          invalidItem={invalidItem!}
          isBladeOpen={isBladeOpen}
          onCloseClick={() => setBladeOpen(false)}
        />
      ) : null}

      {shouldShowErrorNotification && <ReplenishmentAvailableInventoryErrorNotification errorCode={errorCode} />}

      <BoxReset marginTop="S5">
        <>
          {areAllOrderItemsRemoved && (
            <Notification
              appearance="WARNING"
              content={{
                description: (
                  <b>
                    <FormattedMessage
                      id="replenishment.invalid.orderItems.allRemoved"
                      defaultMessage="No items left in the order"
                    />
                  </b>
                ),
              }}
            />
          )}

          {!isEmpty(replenishmentOrder?.orderItems) && (
            <ViewShipmentCard title={formatMessage(invalidOrderItemLabel.resolvedItems.title)}>
              <BoxReset marginTop={"S8"}>
                {replenishmentOrder?.orderItems && unifiedProductResponseCache ? (
                  <ProductsUnitsTable
                    items={
                      replenishmentOrder.orderItems.map((item) => ({
                        dsku: item.packOf!,
                        qty: item.totalUnits!,
                      })) ?? []
                    }
                    products={unifiedProductResponseCache}
                  />
                ) : (
                  <></>
                )}
              </BoxReset>
            </ViewShipmentCard>
          )}
          <ViewShipmentCard title={formatMessage(invalidOrderItemLabel.invalidItems.title)}>
            <InvalidItemProblemTable
              listLoaderType={InboundLoaderId.shipmentProblemsTable}
              columns={invalidItemProblemTableColumns}
              items={augmentedInvalidOrderItems}
              onRowClick={onRowClick}
              page="invalidItemProblemsList"
              onPageChange={noop}
              allowRowSelection={false}
              showCheckboxCondition={() => false}
              hidePaginate
            />
          </ViewShipmentCard>
        </>
      </BoxReset>
    </ManagedStep>
  );
};
