import { detect as detectBrowser } from "detect-browser";

export function initiateDownloadFromUrl(url: string, fileName: string): void {
  const anchor = document.createElement("a");
  try {
    anchor.href = url;
    anchor.download = fileName;
    /*
    opening download in new tab prevents firefox bug for users with open pdf in firefox setting enabled
    'open in firefox' redirects user to pdf page within same tab, which blocks being able to complete inbounds
    https://app.asana.com/0/inbox/1188345152813379/1200303530882316/1200303722472387
    */
    const browser = detectBrowser();
    if (browser?.name !== "chrome") {
      /*
        We do not open in new tab in chrome to prevent an issue in DataDog synthetic testing which also opens pdf in the same tab when this attribute is used.
      */
      anchor.setAttribute("target", "_blank");
    }

    document.body.appendChild(anchor);
    anchor.click();
  } finally {
    anchor.remove();
  }
}

export function initiateDownloadFromBlob(blob: Blob, filename: string): void {
  const url = URL.createObjectURL(blob);
  try {
    initiateDownloadFromUrl(url, filename);
  } finally {
    URL.revokeObjectURL(url);
  }
}
