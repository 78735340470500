import { defaultTheme } from "common/components/ui";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ReceivingInfo } from "@deliverr/legacy-inbound-client";
import { ShipmentStatusSliceAttribute } from "inbounds/steps/ship/ShipmentHeaderEta/utils/ShipmentStatusSliceAttribute";
import { useStorageShipmentUnitData } from "./useStorageShipmentUnitData";
import { StorageShipmentUnitBreakdown } from "./StorageShipmentUnitBreakdown";
import { getStorageReceiveInfo } from "../store/selectors/getStorageReceiveInfo";

/**
 * Get unit breakdown and colors for use in the Shipment ETA Status bar and tool top
 *
 * @returns array which is suitable for building tool tips
 */

export const useGetStorageShipmentUnitStatus = (): {
  unitBreakdown: StorageShipmentUnitBreakdown;
  slices: ShipmentStatusSliceAttribute[];
} => {
  const receivingInfo: ReceivingInfo | undefined = useSelector(getStorageReceiveInfo);

  const { formatMessage } = useIntl();

  const unitBreakdown: StorageShipmentUnitBreakdown = useStorageShipmentUnitData(receivingInfo);

  const slices: ShipmentStatusSliceAttribute[] = [];

  if (unitBreakdown.receivedQty > 0) {
    slices.push({
      value: unitBreakdown.receivedQty,
      color: defaultTheme.colors.GREEN[300],
      label: formatMessage({ id: "inbound.receiveEta.status.availableNow", defaultMessage: "Available now" }),
    });
  }
  if (unitBreakdown.damagedQty > 0) {
    slices.push({
      value: unitBreakdown.damagedQty,
      color: defaultTheme.colors.RED[300],
      label: formatMessage({ id: "inbound.receiveEta.status.damaged", defaultMessage: "Damaged" }),
    });
  }
  if (unitBreakdown.discrepancyQty > 0) {
    slices.push({
      value: unitBreakdown.discrepancyQty,
      color: defaultTheme.colors.PURPLE[400],
      label: formatMessage({ id: "inbound.receiveEta.status.notReceived", defaultMessage: "Receiving" }),
    });
  }

  return { unitBreakdown, slices };
};
