// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShipmentDetailCardButtonBar__buttonBarContainer--RX7G1 button:not(:last-child) {\n  margin-right: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/app/inbounds/steps/ship/view/cards/ShipmentDetailCardButtonBar.less"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ","sourcesContent":[".buttonBarContainer {\n  & button:not(:last-child) {\n    margin-right: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonBarContainer": "ShipmentDetailCardButtonBar__buttonBarContainer--RX7G1"
};
export default ___CSS_LOADER_EXPORT___;
