import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useSidebarContext } from "./SidebarContext";
import { SidebarItemContainer } from "./SidebarItemContainer";
import { SidebarItemContent } from "./SidebarItemContent";
import { SupportChatLauncher } from "../SupportChatLauncher";

/**
 * Sidebar item that launches support chat inside the application or opens support request form in new tab.
 */
export const SidebarContactItem: FC = () => {
  const { mode, onItemClick } = useSidebarContext();

  const description = <FormattedMessage id="sidebar.chat" defaultMessage="Talk to Support" />;

  return (
    <SidebarItemContainer mode={mode} active={false}>
      <SupportChatLauncher onLaunch={onItemClick}>
        <SidebarItemContent icon="chat" description={description} />
      </SupportChatLauncher>
    </SidebarItemContainer>
  );
};
