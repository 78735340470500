import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ManagedStep } from "common/components/StepContainer";
import { Appearance, BorderIcon, Box } from "common/components/ui";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { AmazonErrorLabels } from "transfers/fbaIntegration/AmazonErrorCodeErrorsLabels";

export const ReplenishmentShipmentCreationError = () => {
  const { formatMessage } = useIntl();

  const specificErrorMessage = AmazonErrorLabels.default;

  return (
    <ManagedStep
      title={formatMessage(transfersShipmentCreateLabels.steps.fbaIntegration.shipmentCreationErrors.title)}
      subtitle={
        <Box maxWidth="32rem">
          <FormattedMessage
            {...transfersShipmentCreateLabels.steps.fbaIntegration.shipmentCreationErrors.subtitle}
            values={{
              errorMessage: (
                <FormattedMessage
                  {...specificErrorMessage}
                  values={{
                    tryAgain: <FormattedMessage {...AmazonErrorLabels.tryAgain} />,
                  }}
                />
              ),
            }}
          />
        </Box>
      }
      header={<BorderIcon appearance={Appearance.DANGER} iconType="exclamation-triangle" size="XXL" />}
      isDone={true}
      hasBack={false}
      isNextDisabled={false}
    />
  );
};
