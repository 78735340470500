import AlgoliaService from "common/list/AlgoliaService";

const algoliaService = AlgoliaService.get({
  indexName: process.env.ALGOLIA_INDEX_INVENTORY!,
  searchConfig: {
    hitsPerPage: 10,
    maxValuesPerFacet: 3,
  },
  highlightMatches: true,
});

export default algoliaService;
