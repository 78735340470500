import { batch } from "react-redux";
import { setCurrentPage } from "./setCurrentPage";
import { ParcelListActionTypes } from "parcel/screens/list/ParcelListActionTypes";

export const setCurrentSearchTerm = (currentSearchTerm: string) => async (dispatch) =>
  batch(() => {
    dispatch({
      type: ParcelListActionTypes.SET_CURRENT_PARCEL_SEARCH_TERM,
      currentSearchTerm,
    });
    dispatch(setCurrentPage(0));
  });
