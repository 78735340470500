import { PaginationActionTypes } from "common/components/Pagination/PaginationActions";

export interface PaginationState {
  currentPage: number;
}

export const paginationInitialState: PaginationState = {
  currentPage: 1,
};

export const paginationReducer = (state: PaginationState = paginationInitialState, action) => {
  return action.type === PaginationActionTypes.UPDATE_PAGE ? { ...state, currentPage: action.page } : state;
};
