import React from "react";
import { DeliverrAddress } from "@deliverr/commons-objects";
import { logger } from "@deliverr/ui-logging";
import { toast } from "common/components/ui";
import { freightClient } from "Clients";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { ActionCreator, createActionCreator, SPThunkAction } from "common/ReduxUtils";
import { getSellerId } from "common/user/UserSelectors";
import { QuoteRequestDetails, formatQuoteRequest } from "freight/common/utils/formatQuoteRequest";
import { freightQuoteLabels } from "freight/freightQuote/freightQuoteLabels";
import {
  getFreightQuoting,
  getFreightQuotingDeliveryDetails,
  getFreightQuotingPickupDetails,
} from "freight/FreightSelectors";
import { FreightQuotingActionTypes } from "./FreightQuotingActionTypes";
import { FormattedMessage } from "react-intl";
import { CarrierEmailLink } from "common/components/CarrierEmailLink";
import { batch } from "react-redux";
import { QuoteLocationTypes } from "freight/types/QuoteLocationTypes";
import { setGeneratedFreightQuoteRequests, toggleListView } from "freight/store/freightList/actions/FreightListActions";
import { ListType } from "freight/FreightList/useFreightBookingList";
import { FreightLoaderId } from "freight/constants/FreightLoaderId";
import { Customer, QuoteRequestResponse } from "@deliverr/freight-client";

export const setFreightQuotingBladeOpen = createActionCreator<boolean>(
  FreightQuotingActionTypes.SET_FREIGHT_QUOTING_BLADE_OPEN,
  "freightQuotingBladeOpen"
);

export const setFreightQuotingPickupAddress = createActionCreator<DeliverrAddress>(
  FreightQuotingActionTypes.SET_FREIGHT_QUOTING_PICKUP_ADDRESS,
  "pickupAddress"
);

export const setFreightQuotingPickupLocationType = createActionCreator<QuoteLocationTypes>(
  FreightQuotingActionTypes.SET_FREIGHT_QUOTING_PICKUP_LOCATION_TYPE,
  "pickupLocationType"
);
export const setFreightQuotingPickupLiftgateRequired = createActionCreator<boolean>(
  FreightQuotingActionTypes.SET_FREIGHT_QUOTING_PICKUP_LIFTGATE_REQUIRED,
  "pickupLiftgateRequired"
);

export const setFreightQuotingDeliveryAddress = createActionCreator<DeliverrAddress>(
  FreightQuotingActionTypes.SET_FREIGHT_QUOTING_DELIVERY_ADDRESS,
  "deliveryAddress"
);

export const setFreightQuotingDeliveryLocationType = createActionCreator<QuoteLocationTypes>(
  FreightQuotingActionTypes.SET_FREIGHT_QUOTING_DELIVERY_LOCATION_TYPE,
  "deliveryLocationType"
);

export const setFreightQuotingDeliveryLiftgateRequired = createActionCreator<boolean>(
  FreightQuotingActionTypes.SET_FREIGHT_QUOTING_DELIVERY_LIFTGATE_REQUIRED,
  "deliveryLiftgateRequired"
);

export const setFreightQuotingNumberOfPallets = createActionCreator<number>(
  FreightQuotingActionTypes.SET_FREIGHT_QUOTING_NUMBER_OF_PALLETS,
  "numberOfPallets"
);

export const setFreightQuotingQuoteRequest = createActionCreator<QuoteRequestResponse>(
  FreightQuotingActionTypes.SET_FREIGHT_QUOTING_QUOTE_REQUEST,
  "quoteRequest"
);

export const setFreightQuotingPickupAddressIsDeliverrWarehouse = createActionCreator<boolean>(
  FreightQuotingActionTypes.SET_FREIGHT_QUOTING_PICKUP_ADDRESS_IS_DELIVERR_WAREHOUSE,
  "isDeliverrWarehouse"
);

export const setFreightQuotingDeliveryAddressIsDeliverrWarehouse = createActionCreator<boolean>(
  FreightQuotingActionTypes.SET_FREIGHT_QUOTING_DELIVERY_ADDRESS_IS_DELIVERR_WAREHOUSE,
  "isDeliverrWarehouse"
);

export const setFreightQuote = createActionCreator<number>(FreightQuotingActionTypes.SET_FREIGHT_QUOTE, "quoteId");

export const resetFreightQuoteRequest = createActionCreator(FreightQuotingActionTypes.RESET_FREIGHT_QUOTE_REQUEST);

export const resetFreightQuotingState = createActionCreator(FreightQuotingActionTypes.RESET_FREIGHT_QUOTING_STATE);

export const loadFreightQuoteRequest = createActionCreator<QuoteRequestResponse>(
  FreightQuotingActionTypes.LOAD_FREIGHT_QUOTE_REQUEST,
  "quoteRequest"
);

export const resetFreightQuoteRequestOnChange = (onChangeAction: ActionCreator): SPThunkAction => async (
  dispatch,
  getState
) => {
  const { quoteRequest } = getFreightQuoting(getState());

  // Only reset the quote request if there are existing quotes
  if (quoteRequest) {
    batch(() => {
      dispatch(resetFreightQuoteRequest());
      dispatch(onChangeAction());
    });
    return;
  }

  dispatch(onChangeAction());
};

export const submitFreightQuoteRequest = (): SPThunkAction => async (dispatch, getState) => {
  dispatch(addLoader(FreightLoaderId.GENERATE_FREIGHT_QUOTE));

  const ctx = { fn: "submitFreightQuoteRequest" };
  logger.info(ctx, "Starting to request freight quote");

  const { pickupAddress, pickupLiftgateRequired, pickupLocationType } = getFreightQuotingPickupDetails(getState());
  const { deliveryAddress, deliveryLiftgateRequired, deliveryLocationType } = getFreightQuotingDeliveryDetails(
    getState()
  );
  const { numberOfPallets } = getFreightQuoting(getState());
  const sellerId = getSellerId(getState());

  const quoteRequestDetails: QuoteRequestDetails = {
    sellerId,
    pickupAddress,
    pickupLocationType,
    pickupLiftgateRequired,
    deliveryAddress,
    deliveryLocationType,
    deliveryLiftgateRequired,
    // We can assert numberOfPallets as number because we validated it prior
    numberOfPallets: numberOfPallets!,
  };

  const quoteRequest = formatQuoteRequest(quoteRequestDetails);

  try {
    const quoteRequestResponse = await freightClient.generateQuotes(quoteRequest);

    batch(() => {
      dispatch(setFreightQuotingQuoteRequest(quoteRequestResponse));
      dispatch(toggleListView(ListType.QUOTES));
      dispatch(setGeneratedFreightQuoteRequests([quoteRequestResponse]));
    });
  } catch (err) {
    logger.error({ ...ctx, err }, "Unable to fetch quote");
    toast.error(<FormattedMessage {...freightQuoteLabels.quoteErrorMessage} values={{ email: CarrierEmailLink }} />, {
      autoClose: 5000,
      toastId: "freight.quoting.error",
    });
  } finally {
    dispatch(clearLoader(FreightLoaderId.GENERATE_FREIGHT_QUOTE));
  }
};

export const fetchAndLoadFreightQuoteRequest = (quoteRequestId: number): SPThunkAction => async (
  dispatch,
  getState
) => {
  const ctx = { fn: "loadFreightQuoteRequest" };
  logger.info(ctx, "Starting to load freight quote request");

  const sellerId = getSellerId(getState());

  try {
    const quoteRequestResponse = await freightClient.getQuoteRequestById(quoteRequestId, sellerId);

    dispatch(loadFreightQuoteRequest(quoteRequestResponse));
    logger.info({ ...ctx, quoteRequestResponse }, "Successfully loaded quote request");
  } catch (err) {
    logger.error({ ...ctx, err }, "Unable to load freight quote request");
    toast.error(<FormattedMessage {...freightQuoteLabels.loadQuoteErrorMessage} />, {
      autoClose: 5000,
      toastId: "freight.quoting.loadError",
    });
  }
};

export const fetchCustomer = (): SPThunkAction<Promise<Customer | undefined>> => async (dispatch, getState) => {
  const ctx = { fn: "getCustomer" };
  logger.info(ctx, "Starting to get customer from API");

  const sellerId = getSellerId(getState());

  try {
    return await freightClient.getCustomer(sellerId);
  } catch (err) {
    logger.error({ ...ctx, err }, "Unable to fetch customer details");
    return;
  }
};
