export enum WholesaleCreateActionTypes {
  WHOLESALE_CREATE_STORE_STEPS = "WHOLESALE_CREATE_STORE_STEPS",
  WHOLESALE_CREATE_ADD_PRODUCT = "WHOLESALE_CREATE_ADD_PRODUCT",
  WHOLESALE_CREATE_REMOVE_PRODUCT = "WHOLESALE_CREATE_REMOVE_PRODUCT",
  WHOLESALE_SELECT_DESTINATION_ADDRESS = "WHOLESALE_SELECT_DESTINATION_ADDRESS",
  WHOLESALE_SET_PO_NUMBER = "WHOLESALE_SET_PO_NUMBER",
  WHOLESALE_CREATE_RESET = "WHOLESALE_CREATE_RESET",
  WHOLESALE_CREATE_UPDATE_NUMBER_OF_CASES = "WHOLESALE_CREATE_UPDATE_NUMBER_OF_CASES",
  WHOLESALE_CREATE_CHOOSE_ORIGIN_STORAGE_WAREHOUSE = "WHOLESALE_CREATE_CHOOSE_ORIGIN_STORAGE_WAREHOUSE",
  WHOLESALE_SET_AVAILABLE_INVENTORY = "WHOLESALE_SET_AVAILABLE_INVENTORY",
  WHOLESALE_CREATE_SET_WHOLESALE_ORDER = "WHOLESALE_CREATE_SET_WHOLESALE_ORDER",
  WHOLESALE_CREATE_SET_ERROR = "WHOLESALE_CREATE_SET_ERROR",
  WHOLESALE_CREATE_CHOOSE_SHIPPING_CONFIG = "WHOLESALE_CREATE_CHOOSE_SHIPPING_CONFIG",
  WHOLESALE_CREATE_SET_UPLOADING = "WHOLESALE_CREATE_SET_UPLOADING",
  WHOLESALE_CREATE_SET_LABEL_FILENAME = "WHOLESALE_CREATE_SET_LABEL_FILENAME",
  WHOLESALE_CREATE_SET_NOTES = "WHOLESALE_CREATE_SET_NOTES",
  WHOLESALE_SET_SELECTED_PRODUCTS_CASE_INFO = "WHOLESALE_SET_SELECTED_PRODUCTS_CASE_INFO",
  WHOLESALE_CREATE_SET_VALID_SHIP_BY = "WHOLESALE_CREATE_SET_VALID_SHIP_BY",
}
