import React, { FC } from "react";
import NumberFormat from "react-number-format";
import { suffixFormatter } from "./suffixFormatter";

export interface CardValueProps {
  value?: number;
  applyPercentSuffix?: boolean;
  fixedDecimalScale?: boolean;
}

export const CardValue: FC<CardValueProps> = ({ value, applyPercentSuffix = false, fixedDecimalScale = false }) => {
  const formatFunction = value && value > 9999 ? suffixFormatter : undefined;
  const suffix = applyPercentSuffix ? "%" : undefined;

  return (
    <NumberFormat
      value={value}
      format={formatFunction}
      displayType="text"
      thousandSeparator={true}
      suffix={suffix}
      data-testid="card-value"
      decimalScale={1}
      fixedDecimalScale={fixedDecimalScale}
    />
  );
};
