import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { WarehousePublic } from "@deliverr/business-types";
import { isAuthenticated } from "../core/authentication";
import { logError } from "Logger";

export type WarehouseClientConfig = ApiClientConfig;

export const defaultWarehouseClientConfig = (): WarehouseClientConfig => {
  if (process.env.WAREHOUSE_SERVICE_URL) {
    return {
      baseURL: `${process.env.WAREHOUSE_SERVICE_URL}`,
    };
  } else {
    throw new Error(
      `Unable to get default warehouse client config, environment variable WAREHOUSE_SERVICE_URL could not be found`
    );
  }
};

export class WarehouseClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: WarehouseClientConfig = defaultWarehouseClientConfig()) {
    this.apiClient = new ApiClient({
      baseURL,
    });
  }

  async getWarehouses(ids: string[]): Promise<WarehousePublic[] | undefined> {
    try {
      return await Promise.all(
        ids.map(async (id: string) =>
          // Disabled this here since awaiting would prevent parallelization of calls
          // eslint-disable-next-line @typescript-eslint/return-await
          this.apiClient.get<WarehousePublic>({
            url: `/PUBLIC_DATA/v1/warehouses/${id}`,
            authentication: isAuthenticated,
          })
        )
      );
    } catch (err) {
      logError({ fn: "warehouseClient.getWarehouses", ids }, err);

      return undefined;
    }
  }

  async getWarehouse(id: string): Promise<WarehousePublic | undefined> {
    try {
      return await this.apiClient.get<WarehousePublic>({
        url: `PUBLIC_DATA/v1/warehouses/${id}`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      logError({ fn: "warehouseClient.getWarehouse", id }, err);

      return undefined;
    }
  }
}
