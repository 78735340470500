import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "RootReducer";

export const useReturnsDetailHeader = () => {
  const { formatMessage } = useIntl();
  const { returnsDetail } = useSelector((state: RootState) => state.returnsDetail);

  const title = formatMessage(
    {
      id: "returns.returnsDetail.titleReturnId",
      defaultMessage: "Return #{returnId}",
    },
    { returnId: returnsDetail.rma }
  );
  return {
    title,
    returnsDetail,
  };
};
