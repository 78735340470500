import cn from "classnames/dedupe";
import React, { FC } from "react";

import { ShipmentDetailCardButtonProps, ShipmentDetailCardButton } from "./ShipmentDetailCardButton";

import cls from "./ShipmentDetailCardButtonBar.less";

export interface ShipmentDetailCardButtonBarProps {
  buttons?: ShipmentDetailCardButtonProps[];
  buttonBarClassName?: string;
}

export const ShipmentDetailCardButtonBar: FC<ShipmentDetailCardButtonBarProps> = (props) => {
  const { buttons, buttonBarClassName } = props;
  return buttons && buttons.length > 0 ? (
    <div className={cn(cls.buttonBarContainer, buttonBarClassName)}>
      {buttons.map((buttonProps) => (
        <ShipmentDetailCardButton key={`${buttonProps.name}-btn`} {...buttonProps} />
      ))}
    </div>
  ) : null;
};
