import { PackageSummary, UnitSystem } from "inbounds/createShipment/CreateShipmentTypes";

const CFT_TO_CBM = 0.028317;

/**
 * Calculates the volume of a package in cubic feet or meters depending on the unit system
 */
export const calcPackageTotalVolume = (product: PackageSummary, unitSystem: UnitSystem) => {
  const { length, width, height, numberOfPkgs } = product;
  const caseVolumeCubicIn = (length ?? 0) * (width ?? 0) * (height ?? 0); // cubic in
  const caseVolumeCubicFt = caseVolumeCubicIn / 1728; // cubic feet
  const caseVolume = unitSystem === UnitSystem.CBM_KG ? caseVolumeCubicFt * CFT_TO_CBM : caseVolumeCubicFt;
  return Math.round(caseVolume * numberOfPkgs * 100) / 100;
};
