import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, BoxReset, Button, Select, Stack, Text } from "common/components/ui";
import { useDownloadPalletLabel } from "./useDownloadPalletLabel";

export const DownloadPalletLabel: React.FC = () => {
  const { handleOptionSelect, printSizeOptions, onDownloadClick, labelSize } = useDownloadPalletLabel();

  return (
    <Stack direction="VERTICAL" data-testid="storage.inbound.palletLabel.download.section">
      <BoxReset marginTop={null} marginBottom={"S2"}>
        <Text size="body">
          <FormattedMessage
            id="storage.inbounds.detail.shipment.instruction.palletLabel.text"
            defaultMessage="Attach pallet labels to all sides of each pallet."
          />
        </Text>
      </BoxReset>

      <Box>
        <Box width="22rem" marginBottom={"S5"}>
          <Select
            onChange={handleOptionSelect}
            options={printSizeOptions}
            placeholder={
              <FormattedMessage
                id="storage.inbounds.detail.shipment.instruction.palletLabel.select"
                defaultMessage="Select pallet labels"
              />
            }
            searchable={false}
          />
        </Box>

        <Button
          data-testid="storage.inbound.palletLabel.download.button"
          secondary
          onClick={onDownloadClick}
          disabled={!labelSize}
        >
          <FormattedMessage
            id="storage.inbounds.detail.shipment.instruction.palletLabel.download"
            defaultMessage={"Download pallet labels"}
          />
        </Button>
      </Box>
    </Stack>
  );
};
