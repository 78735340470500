import { useMemo } from "react";
import { DeliverrAddress } from "@deliverr/commons-objects";
import useSWR from "swr";

import { inboundClient } from "Clients";
import log from "Logger";

interface UseFetchShippingOptionsConfig {
  shipmentId?: number;
  fromAddress: DeliverrAddress;
  toAddress: DeliverrAddress;
}

export const useFetchShippingOptions = ({ shipmentId, fromAddress, toAddress }: UseFetchShippingOptionsConfig) => {
  const ctx = useMemo(() => ({ fn: "useFetchShippingOptions", shipmentId, fromAddress, toAddress }), [
    shipmentId,
    fromAddress,
    toAddress,
  ]);

  const { data, error, mutate } = useSWR(
    shipmentId !== undefined ? ["eligibleShippingConfig", shipmentId, fromAddress, toAddress] : null,
    async ([_, shipId, from, to]) => {
      log.info(ctx, "getting shipping config");
      const eligibleShippingConfig = await inboundClient.getEligibleShippingConfig(shipId, from, to);
      return eligibleShippingConfig;
    },
    {
      errorRetryCount: 3,
      revalidateOnFocus: false, // don't refresh automatically when the seller returns focus to the page
      onError: (err) => {
        log.warn({ ...ctx, err }, "error fetching shipping options");
      },
    }
  );

  return {
    data,
    isLoading: !data && !error,
    error,
    refetch: mutate,
  };
};
