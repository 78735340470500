import styled from "@emotion/styled";
import React from "react";
import { FormattedMessage } from "react-intl";

const StyledTooltipContainer = styled.div`
  width: 11.5rem;
`;

export const KittingDisabledTooltip = (
  <StyledTooltipContainer>
    <FormattedMessage
      id={"KittingDisabledTooltip.line1"}
      defaultMessage={`To select kitting prep, only add kitted products that require kitting prep to this shipping plan, as selected prep services apply to all units within a shipping plan.`}
    />
  </StyledTooltipContainer>
);
