import { BrowserStorageCacheClass } from "@aws-amplify/cache/lib/BrowserStorageCache";
import { CacheConfig, CacheItemOptions } from "@aws-amplify/cache/lib/types";
import { CookieStorage, ICognitoStorage } from "amazon-cognito-identity-js";

export default class AuthStorageConsumer extends BrowserStorageCacheClass implements Partial<ICognitoStorage> {
  public cookieStorage: CookieStorage;

  constructor(domain: string, config: CacheConfig) {
    super(config);

    this.cookieStorage = new CookieStorage({
      domain,
      path: "/",
      expires: 30,

      // cross-domain auth doesn't work with option secure = true :(
      secure: false,
    });
  }

  public getItem(key: string, options?: CacheItemOptions) {
    const cookieData = this.cookieStorage.getItem(key);

    if (cookieData) {
      super.setItem(key, cookieData, options);

      // remove cookie since it was transfered to localstorage
      this.cookieStorage.removeItem(key);
    }
    return super.getItem(key, options);
  }
}
