import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { CreateShipmentFormState, createShipmentInitialFormState } from "./store";
import { CreateShipmentInputName } from "./useCreateShipmentForm/useCreateShipmentForm.types";
import get from "lodash/fp/get";
import { useSelector } from "react-redux";
import { selectCreateShipmentForm } from "./store/selectors";

/** extends form context with helpers and adds typing to context */
export const useCreateShipmentFormContext = () => {
  const formContext = useFormContext<CreateShipmentFormState>();
  const defaultValues = useSelector(selectCreateShipmentForm);
  const { trigger, setValue } = formContext;

  const getFieldsValid = useCallback(async (fields: CreateShipmentInputName[]) => await trigger(fields), [trigger]);

  /** used to set a value only if it has not been updated in Redux */
  const setValueIfUnsaved = useCallback(
    (name: CreateShipmentInputName, value: any) => {
      const defaultValue = get(name)(defaultValues);
      const initialValue = get(name)(createShipmentInitialFormState);
      const isUnsaved = defaultValue === initialValue;
      if (isUnsaved) {
        setValue(name, value);
      }
    },
    [setValue, defaultValues]
  );

  return {
    ...formContext,
    getFieldsValid,
    setValueIfUnsaved,
  };
};
