import { defineMessages, MessageDescriptor } from "react-intl";
import { NotificationType } from "common/clients/notifications/Notification/NotificationType";
import { SupportedNotificationType } from "notifications/SupportedNotificationType";

export const messages: Record<SupportedNotificationType, MessageDescriptor> = defineMessages({
  [NotificationType.InboundDiscrepanciesType]: {
    id: "notifications.messages.InboundDiscrepanciesType",
    defaultMessage: 'Inbound "{name}" completed with discrepancies',
  },
  [NotificationType.InboundNonCompliantProductsType]: {
    id: "notifications.messages.InboundNonCompliantProductsType",
    defaultMessage: 'Shipping plan "{name}" has an unknown barcode',
  },
  [NotificationType.OutboundInvalidAddressType]: {
    id: "notifications.messages.OutboundInvalidAddressType",
    defaultMessage: "Order {name} has an invalid address",
  },
  [NotificationType.BulkUploadSuccessType]: {
    id: "notifications.messages.BulkUploadSuccessType",
    defaultMessage: "Your bulk order import has completed successfully",
  },
  [NotificationType.BulkUploadFailedType]: {
    id: "notifications.messages.BulkUploadFailedType",
    defaultMessage: "An error has occurred during your bulk order import. Please try again.",
  },
  [NotificationType.BulkUploadPartialType]: {
    id: "notifications.messages.BulkUploadPartialType",
    defaultMessage:
      "Your bulk order import partially succeeded. Please refer to your email for next steps on the orders with issues.",
  },
  [NotificationType.SMBMessageNotificationType]: {
    id: "notifications.messages.SMBMessageNotificationType",
    defaultMessage: "You have a new message associated with your {name} order",
  },
});

export const callToActionMessages: Record<SupportedNotificationType, MessageDescriptor> = defineMessages({
  [NotificationType.InboundDiscrepanciesType]: {
    id: "notifications.callToActionMessages.InboundDiscrepanciesType",
    defaultMessage: "Review inbound",
  },
  [NotificationType.InboundNonCompliantProductsType]: {
    id: "notifications.callToActionMessages.InboundNonCompliantProductsType",
    defaultMessage: "View problem",
  },
  [NotificationType.OutboundInvalidAddressType]: {
    id: "notifications.callToActionMessages.OutboundInvalidAddressType",
    defaultMessage: "Fix address",
  },
  [NotificationType.BulkUploadSuccessType]: {
    id: "notifications.callToActionMessages.BulkUploadSuccessType",
    defaultMessage: "View orders",
  },
  [NotificationType.BulkUploadFailedType]: {
    id: "notifications.callToActionMessages.BulkUploadFailedType",
    defaultMessage: "Go to order list",
  },
  [NotificationType.BulkUploadPartialType]: {
    id: "notifications.callToActionMessages.BulkUploadPartialType",
    defaultMessage: "View orders",
  },
  [NotificationType.SMBMessageNotificationType]: {
    id: "notifications.callToActionMessages.SMBMessageNotificationType",
    defaultMessage: "View message",
  },
});
