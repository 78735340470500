import { FormatInputValueFunction } from "react-number-format";

export const suffixFormatter: FormatInputValueFunction = (inputValue) => {
  const num = parseInt(inputValue);

  return Math.abs(num) > 999999
    ? `${Math.sign(num) * (Math.round(Math.abs(num) / 1000) / 1000)}m`
    : Math.abs(num) > 9999
    ? `${Math.sign(num) * (Math.round(Math.abs(num) / 100) / 10)}k`
    : `${Math.sign(num) * Math.abs(num)}`;
};
