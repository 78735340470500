import {
  findShopifyIntegrationV1,
  findShopifyIntegrationV2,
  findShopifyIntegrationV3,
} from "channels/ChannelsSelectors";

import { useSelector } from "react-redux";

export const useNotificationsHeader = () => {
  const hasShopifyIntegrationV1 = useSelector(findShopifyIntegrationV1) !== undefined;
  const hasShopifyIntegrationV2 = useSelector(findShopifyIntegrationV2) !== undefined;
  const hasShopifyIntegrationV3 = useSelector(findShopifyIntegrationV3) !== undefined;

  return {
    hasShopifyIntegration: hasShopifyIntegrationV1 || hasShopifyIntegrationV2 || hasShopifyIntegrationV3,
  };
};
