import { DskuQty } from "@deliverr/commons-clients";
import { ProductChooserKittedProducts } from "common/components/ProductChooser/ProductChooserReducer";

export const getDSKUQuantitiesForKittedProduct = (
  dskuQuantities: DskuQty[],
  productDetails?: ProductChooserKittedProducts
): DskuQty[] => {
  let updatedDskuQuantities: DskuQty[] = [];
  dskuQuantities.forEach((dskuQty) => {
    if (productDetails?.[dskuQty.dsku]) {
      updatedDskuQuantities = [
        ...updatedDskuQuantities,
        ...Object.values(productDetails?.[dskuQty.dsku].availableInventoryMap).map((component) => ({
          dsku: component.dsku,
        })),
      ];
    }
  });
  return updatedDskuQuantities;
};
