import produce from "immer";

import { createReducer, ReducerDictionary, ReducerState } from "common/ReduxUtils";
import { BladeActionTypes } from "common/components/Blade/BladeActions";

export interface BladeState {
  blades: { [bladeId: string]: boolean };
}

export const bladeInitialState: BladeState = { blades: {} };

const bladeReducerFunc: ReducerState<BladeState> = (state, action) =>
  produce(state, (draft) => {
    draft.blades[action.id] = action.type === BladeActionTypes.SHOW_BLADE;
  });

const reducers: ReducerDictionary<BladeState> = {
  [BladeActionTypes.SHOW_BLADE]: bladeReducerFunc,
  [BladeActionTypes.HIDE_BLADE]: bladeReducerFunc,
};

export const bladeReducer = createReducer<BladeState>(bladeInitialState, reducers);
