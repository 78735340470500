import { Dictionary, isEmpty, set } from "lodash";
import { LogisticsSearchClient, SearchOptions } from "@deliverr/logistics-search-client";
import { SearchRequest, SearchResults, SearchService } from "../SearchService";
import { addOpenSearchFilters, convertAlgoliaFiltersToOpenSearch } from "./OpenSearchUtils";

import { LogisticsSearchConfig } from "./LogisticsSearchConfig";
import { QueryDslQueryContainer } from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";
import { getBearerToken } from "../../clients/core/getBearerToken";
import { logError } from "Logger";

export abstract class OpenSearchService extends SearchService {
  protected readonly client: LogisticsSearchClient;
  protected readonly config: LogisticsSearchConfig;

  constructor(config: LogisticsSearchConfig) {
    super();
    this.config = config;
    this.client = new LogisticsSearchClient(config.indexName, {
      baseUrl: process.env.LOGISTICS_SEARCH_BASE_URL!,
      auth: {
        token: getBearerToken,
      },
    });
  }

  public async execute(request: SearchRequest): Promise<SearchResults> {
    try {
      const searchOptions = this.buildSearchOptions(request);

      // Setup search options
      const size = request.pageSize ?? this.config.searchConfig.hitsPerPage;

      // Setup request params
      const requestParams = searchOptions.hydrate ? { hydrate: true } : undefined;

      // Search query
      const results = await this.client.search(
        {
          query: searchOptions.query,
          sort: searchOptions.sort,
          page: searchOptions.page,
          size: searchOptions.size,
          highlight: searchOptions.highlight,
        },
        requestParams
      );
      return this.processSearchResults(results, size, request);
    } catch (err) {
      // Log error but fail silently. Return no results.
      // TBD if we want to notify the user of the issue.
      logError({ fn: "OpenSearchService.execute" }, `Error while querying logistics search platform: ${err.message}`);
    }
    return {
      hits: [],
      response: {
        nbHits: 0,
        nbPages: 0,
        hitsPerPage: 1,
        page: 1,
      },
    };
  }

  protected getHighlightOverrides(highlight: { [key: string]: string[] }): { [key: string]: string } {
    const resultObject = {};
    for (const key in highlight) {
      // Note: This method doesn't handle arrays correctly
      set(resultObject, key, highlight[key][0]);
    }
    return resultObject;
  }

  protected buildSearchOptions(request: SearchRequest): SearchOptions {
    const size = request.pageSize ?? this.config.searchConfig.hitsPerPage;

    const hasLegacyQueries = (request.filters?.length ?? 0) > 0 || request.numericFilter;
    const baseQuery: QueryDslQueryContainer = hasLegacyQueries ? convertAlgoliaFiltersToOpenSearch(request) : {};
    const openSearchQuery = addOpenSearchFilters(baseQuery, request.customizedOpenSearchFilters ?? []);

    // Setup search options
    let searchOptions: SearchOptions = {
      query: openSearchQuery,
      page: (request.page ?? 0) + 1, // page is 1-based (https://github.com/deliverr/logistics-search/blob/36f75b0dd8b0893ab38677ee28c8a9de02b37c2e/services/search/src/gateways/SearchGateway.ts#L41)
      size,
    };

    // Add sort to search if exists with correct syntax
    if (request?.sort && request?.sort?.fieldName) {
      const sort = [
        {
          [request.sort.fieldName]: {
            order: request.sort.direction,
            ...(request.sort.missing && { missing: request.sort.missing }),
            ...(request.sort.unmappedType && { unmapped_type: request.sort.unmappedType }),
          },
        },
      ];
      searchOptions = {
        ...searchOptions,
        sort,
      };
    }

    return searchOptions;
  }

  protected processSearchResults(results: any, size: number, request?: SearchRequest): SearchResults {
    const hits = results.hits.hits.map((hit: any) => {
      let rsp = {
        id: hit._id,
        ...hit._source,
        raw: {
          ...hit._source,
        },
      };
      if (hit.inner_hits) {
        rsp = {
          ...rsp,
          innerHits: hit.inner_hits,
        };
      }
      if (hit.highlight) {
        rsp = {
          ...rsp,
          ...this.getHighlightOverrides(hit.highlight),
        };
      }
      return rsp;
    });
    const nbPages = Math.ceil(results.hits?.total.value / size);
    return {
      hits,
      response: {
        nbHits: results.hits?.total.value,
        nbPages,
        hitsPerPage: size,
        page: request?.page ?? 1,
      },
    };
  }

  public async searchByIds(
    ids: Array<number | string>,
    query: string,
    idField: string,
    resultsSize?: number
  ): Promise<SearchResults> {
    const queryString = isEmpty(ids) ? "" : `(${ids.map((id) => `${idField}:${id}`).join(" OR ")})`;
    const size = resultsSize ?? 1000;
    const results = await this.client.search({
      query: {
        bool: {
          filter: [
            {
              query_string: {
                query: queryString,
                analyze_wildcard: true,
              },
            },
          ],
        },
      },
      size: resultsSize,
    });
    const hits = results.hits.hits.map((hit: any) => {
      return {
        ...hit._source,
        raw: {
          ...hit._source,
        },
      };
    });
    const nbPages = Math.ceil(results.hits?.total.value / size);
    return {
      hits,
      response: {
        nbHits: results.hits?.total.value,
        nbPages,
        hitsPerPage: size,
        page: 1,
      },
    };
  }

  public async getRecordByObjectId<T>(objectId: string): Promise<T> {
    return {} as T;
  }

  public async getAllRecordsByObjectIds<T>(objectIds: string[]): Promise<Dictionary<T>> {
    return {};
  }
}
