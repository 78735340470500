import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { SmbShipmentState, smbShipmentInitialState } from "./SmbShipmentState";
import { SmbShipmentActionTypes } from "./SmbShipmentActionTypes";
import { setShippingPlanDetailsReducer } from "./reducers";

const reducerDict: ReducerDictionary<SmbShipmentState> = {
  ...handleSimpleReducerUpdates([]),
  [SmbShipmentActionTypes.SET_SHIPPING_PLAN_DETAILS]: setShippingPlanDetailsReducer,
};

export const smbShipmentReducer = createReducer<SmbShipmentState>(smbShipmentInitialState, reducerDict);
