import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import produce from "immer";
import { ShopifyActionTypes } from "tags/shopify/actions/shopifyActionTypes";
import { shopifyInitialState, ShopifyState } from "../ShopifyState";

const reducers: ReducerDictionary<ShopifyState> = {
  ...handleSimpleReducerUpdates([
    ShopifyActionTypes.UPDATE_STORE_NAME,
    ShopifyActionTypes.UPDATE_USE_STORE_CREDIT,
    ShopifyActionTypes.UPDATE_IS_RECONFIGURING_SHOPIFY,
    ShopifyActionTypes.UPDATE_CART_MINIMUM,
    ShopifyActionTypes.STANDARD_BADGE_OPTION_SELECTED,
    ShopifyActionTypes.SELLER_CONNECTION_STATUS_RECEIVED,
    ShopifyActionTypes.SHOPIFY_SHOP_RECEIVED,
    ShopifyActionTypes.SHOPIFY_SHOP_CONFIG_RECEIVED,
    ShopifyActionTypes.SHOPIFY_SHOP_APP_EMBED_BLOCK_ENABLED_RECEIVED,
    ShopifyActionTypes.SHOPIFY_SHOP_APP_BLOCK_TEMPLATES_RECEIVED,
  ]),
  [ShopifyActionTypes.UPDATE_BADGE_PLACEMENT]: (state, { placementProp, useDeliverrPlacement }) => ({
    ...state,
    [placementProp]: useDeliverrPlacement,
  }),
  [ShopifyActionTypes.GET_SHOPIFY_EXAMPLE_LINK]: (state, { linkType, link }) => ({
    ...state,
    exampleLinks: {
      ...state.exampleLinks,
      [linkType]: link,
    },
  }),
  [ShopifyActionTypes.APP_INSTALLED]: (state, { appInstalled, shop }) => ({
    ...state,
    appInstalled,
    shop: shop ?? state.shop,
  }),
  [ShopifyActionTypes.SHOPIFY_SHOP_DEACTIVATED]: (state) =>
    produce(state, (draftState) => {
      draftState.shop = undefined;
    }),
  [ShopifyActionTypes.SHOPIFY_SHIPPING_SETTINGS_CONFIG_LOADED]: (state, { shippingSettingsItem }) =>
    produce(state, (draftState) => {
      const index = draftState.shippingSettings.findIndex(
        (settings) => settings.settingName === shippingSettingsItem.settingName
      );
      if (index === -1) {
        draftState.shippingSettings.push(shippingSettingsItem);
      }
    }),
  [ShopifyActionTypes.SHOPIFY_SHIPPING_SETTINGS_CONFIG_UPDATED]: (state, { settingName, prop, value }) =>
    produce(state, (draftState) => {
      const index = draftState.shippingSettings.findIndex((settings) => settings.settingName === settingName);
      draftState.shippingSettings[index][prop] = value;
      // If charge is set to pass through, reset shipping cost
      if (prop === "isPassThrough" && value === true) {
        draftState.shippingSettings[index].shippingCost = null;
      }
    }),
  [ShopifyActionTypes.SHOPIFY_SHIPPING_SETTINGS_CONFIG_RESET]: (state, { settingName }) =>
    produce(state, (draftState) => {
      const index = draftState.shippingSettings.findIndex((settings) => settings.settingName === settingName);
      draftState.shippingSettings[index] = {
        ...draftState.shippingSettings[index],
        cartMinimum: null,
        shippingCost: null,
        isPassThrough: false,
      };
    }),
  [ShopifyActionTypes.SET_EXPERIMENT_ENROLLMENT]: (state, { experimentNameProp, isActive }) => ({
    ...state,
    [experimentNameProp]: isActive,
  }),
};

export const shopifyReducer = createReducer<ShopifyState>(shopifyInitialState, reducers);
