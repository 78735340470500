import { DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import React, { FC } from "react";

const SpinnerContainer = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    padding: ${theme.spacing.S5};
    color: ${theme.colors.NEUTRAL[200]};
  `
);

export const NotificationsSpinner: FC = () => (
  <SpinnerContainer>
    <LoadingSpinner center />
  </SpinnerContainer>
);
