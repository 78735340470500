import cx from "classnames/dedupe";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { EmptySpace } from "common/components/EmptySpace";
import HtmlToReact from "common/components/HtmlToReact";
import { InboundSearchResult } from "inbounds/InboundTypes";
import promo from "assets/img/icons/promo-inbound.svg";
import { useListViewShippingPlanName } from "./useListViewShippingPlanName";
import { NCIcon } from "common/components/NCIcon";
import { NonComplianceProduct } from "@deliverr/commons-clients";
import {
  IconContainer,
  PromoIcon,
  ShippingPlanNameContainer,
  ShippingPlanNameWrapper,
  SubText,
} from "inbounds/components/ShippingPlanName/ShippingPlanName.styles";

export interface ShippingPlanListRow
  extends Pick<
    InboundSearchResult,
    | "shipmentId"
    | "shipmentIndex"
    | "shippingPlanId"
    | "shippingPlanName"
    | "shippingPlanStatus"
    | "isPromotional"
    | "shippingPlanCreatedAtUnix"
  > {}

export interface ShippingPlanNameProps {
  ncProductsByShippingPlanId: { [key: string]: NonComplianceProduct[] };
  row: ShippingPlanListRow;
  rowIdx: number;
  isMobile?: boolean;
}

export const ShippingPlanName: FC<ShippingPlanNameProps> = React.memo((props) => {
  const {
    isArchived,
    isNC,
    isPromotional,
    rawShippingPlanName,
    shipmentId,
    shipmentIndex,
    shippingPlanCreatedAt,
  } = useListViewShippingPlanName(props);

  const shouldRender = props.rowIdx === 0 || !shipmentId || shipmentIndex === 0;
  if (!shouldRender) {
    return null;
  }

  const shouldDisplayCreatedDate = !isPromotional && !props.isMobile && (!shipmentId || shipmentIndex === 0);

  return (
    <ShippingPlanNameWrapper data-testid={"ShippingPlanName__nameWrapper"}>
      {isPromotional && (
        <IconContainer>
          <PromoIcon html={promo} />
        </IconContainer>
      )}
      {!isPromotional && isNC && (
        <IconContainer>
          <NCIcon message={"This shipping plan is non-compliant"} tooltipWidth={139} />
        </IconContainer>
      )}
      <ShippingPlanNameContainer className={cx({ isArchived })}>
        <HtmlToReact className={"shippingPlanName"} html={rawShippingPlanName} />
        {shouldDisplayCreatedDate && (
          <SubText>
            <FormattedMessage id="archived" defaultMessage={`Created ${shippingPlanCreatedAt}`} />
          </SubText>
        )}
        {isArchived && (
          <span>
            <EmptySpace />
            <FormattedMessage id="archived" defaultMessage={"Archived"} />
          </span>
        )}
      </ShippingPlanNameContainer>
    </ShippingPlanNameWrapper>
  );
});
