import { ShippingPartner } from "@deliverr/replenishment-client";
import { WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";

export const getReplenishmentShippingPartnerFromWholesaleType = (
  wholesaleShippingMethod: WholesaleShippingMethod,
  destinationType?: TransferCreationTypes
): ShippingPartner => {
  if (wholesaleShippingMethod === WholesaleShippingMethod.DELIVERR) {
    return ShippingPartner.DELIVERR;
  } else if (wholesaleShippingMethod === WholesaleShippingMethod.COLLECT) {
    return ShippingPartner.COLLECT;
  } else {
    switch (destinationType) {
      case TransferCreationTypes.Wfs:
        return ShippingPartner.WFS;
      case TransferCreationTypes.Fba:
        return ShippingPartner.SELLER;
      case TransferCreationTypes.Ecom:
        return ShippingPartner.DELIVERR;
      case TransferCreationTypes.Wholesale:
        return ShippingPartner.SELLER;
      default:
        return ShippingPartner.FBA;
    }
  }
};
