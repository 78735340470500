import { Button, Title } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import Modal from "common/components/Modal";
import { useCreateMoreAdSetsModal } from "./useCreateMoreAdSetsModal";

import cls from "./CreateMoreAdSetsModal.less";

export interface CreateMoreAdSetsModalProps {
  nextPath: string;
}

export const CreateMoreAdSetsModal: FC<CreateMoreAdSetsModalProps> = ({ nextPath }) => {
  const { onDoneClick, onContinueSetupClick, modalId } = useCreateMoreAdSetsModal(nextPath);

  return (
    <Modal id={modalId} size="md" hasCloseButton={false}>
      <div className={cls.modalContainer}>
        <Title displayAs="h4" className={cls.title}>
          <FormattedMessage id="CreateMoreAdSetsModal.Title" defaultMessage="Do you want to create other ad sets?" />
        </Title>
        <div className={cls.body}>
          <FormattedMessage
            id="CreateMoreAdSetsModal.Description"
            defaultMessage="Simply enter another product (DSKU), then copy and paste the newly generated snippet."
          />
        </div>
        <div className={cls.buttons}>
          <Button secondary onClick={onContinueSetupClick}>
            <FormattedMessage
              id="CreateMoreAdSetsModal.ContinueSetupButton.Text"
              defaultMessage="I have other products to advertise"
            />
          </Button>
          <Button onClick={onDoneClick}>
            <FormattedMessage id="done" defaultMessage="I'm done" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
