import { getCargoDimsComplianceMessages } from "freight/store/cargoPalletDetails/selectors/getCargoDimsCompliance";
import { sum, sumBy } from "lodash/fp";
import { createSelector } from "reselect";
import { getStorageInboundCreate } from "storage/inbounds/create/store/selector/getStorageInboundCreate";

export const getStorageCargoDimsCompliance = createSelector(
  getStorageInboundCreate,
  ({ palletConfigurations, numOfPallets }) => {
    const quotePalletLineItem = palletConfigurations?.map((palletConfiguration) => {
      return { palletDimensions: palletConfiguration };
    });

    const currentPalletCount = sumBy("palletCount", palletConfigurations);
    const currentWeightSum = sum(
      palletConfigurations?.map(
        (palletConfiguration) => (palletConfiguration?.palletCount ?? 0) * (palletConfiguration?.weight ?? 0)
      )
    );

    return getCargoDimsComplianceMessages([currentPalletCount, currentWeightSum], quotePalletLineItem, numOfPallets);
  }
);
