import { useWatch } from "react-hook-form";
import { DtcMixedSkuInputDimValues, getInputName } from "./mixedSkuBoxConfigurationInputs/DtcMixedSkuInputValues";
import { DimensionsError } from "inbounds/steps/dimensions/DimensionsAndWeightsTable/DimensionsError";
import {
  INBOUND_SPD_MAX_BOX_LONGEST,
  INBOUND_SPD_MAX_BOX_MIDDLE,
  INBOUND_SPD_MAX_BOX_SHORTEST,
  INBOUND_SPD_MAX_BOX_WEIGHT,
} from "inbounds/constants/spdConstants";
import { composeGetIsOverDimensions } from "common/utils/product/composeGetIsOverDimensions";
import { composeGetIsOverWeight } from "common/utils/product/composeGetIsOverWeight";

const validateMixedSkuInputValues = (length: number, width: number, height: number, weight: number) => {
  const isMaxDimensions = composeGetIsOverDimensions({
    maxShortestSide: INBOUND_SPD_MAX_BOX_SHORTEST,
    maxMiddleSide: INBOUND_SPD_MAX_BOX_MIDDLE,
    maxLongestSide: INBOUND_SPD_MAX_BOX_LONGEST,
  });
  const isMaxWeight = composeGetIsOverWeight(INBOUND_SPD_MAX_BOX_WEIGHT);

  const isOverDimensions = isMaxDimensions({ length, width, height });
  const isOverWeight = isMaxWeight({ weight });

  return isOverDimensions && isOverWeight
    ? DimensionsError.OVER_DIMENSIONS_AND_WEIGHT
    : isOverDimensions
    ? DimensionsError.OVER_DIMENSIONS
    : isOverWeight
    ? DimensionsError.OVER_WEIGHT
    : undefined;
};

export const useDtcMixedSkuBoxConfiguration = (index) => {
  const length = useWatch({ name: getInputName(index, DtcMixedSkuInputDimValues.LENGTH) });
  const width = useWatch({ name: getInputName(index, DtcMixedSkuInputDimValues.WIDTH) });
  const height = useWatch({ name: getInputName(index, DtcMixedSkuInputDimValues.HEIGHT) });
  const weight = useWatch({ name: getInputName(index, DtcMixedSkuInputDimValues.WEIGHT) });

  const mixedSkuInputValidationError = validateMixedSkuInputValues(length ?? 0, width ?? 0, height ?? 0, weight ?? 0);

  return {
    mixedSkuInputValidationError,
  };
};
