import { DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/dedupe";
import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "RootReducer";
import loading from "assets/img/icons/loading.gif";
import { flexCenter } from "common/utils/style";

import cls from "./WithLoader.less";

interface SimpleLoaderProps {
  size?: "xs" | "sm" | "lg" | "2x";
  // if set to true, will hide while loading instead of showing spinner
  hide?: boolean;
  testId?: string;
}

interface WithLoaderProps extends SimpleLoaderProps {
  name: string;
  children?: React.ReactNode;
  loaderText?: React.ReactNode | string; // text to be displayed alongside spinner
  blueSpinner?: boolean; // if set to true, will use blue spinner img loading.gif and display text above
}

const SpinnerContainer = styled.div<{ blueSpinner: boolean }, DefaultTheme>(
  ({ blueSpinner, theme }) => `
  ${flexCenter}

  ${
    blueSpinner
      ? `
    flex-direction: column-reverse;
    gap: ${theme.spacing.S4};
  `
      : ""
  }
`
);

const Loading = styled.img`
  height: 85px;
  width: 85px;
  background: none;
`;

export const SimpleLoader = ({ size, hide, testId }: SimpleLoaderProps) => (
  <FontAwesomeIcon
    data-testid={testId ?? "loading-spinner"}
    icon="circle-notch"
    spin={true}
    size={size ?? "lg"}
    className={classNames(cls.spinner, hide ? cls.hidden : "")}
  />
);

// Sizes: https://fontawesome.com/how-to-use/svg-with-js#additional-styling
const WithLoader = ({ name, children, size, hide, loaderText, blueSpinner = false }: WithLoaderProps) => {
  const loaders = useSelector((state: RootState) => state.loading.loaders);

  return (
    <div style={{ width: "100%" }}>
      {loaders.includes(name) ? (
        <SpinnerContainer data-testid="loading-spinner" blueSpinner={blueSpinner}>
          {blueSpinner ? (
            <Loading src={loading} />
          ) : (
            <FontAwesomeIcon
              icon="circle-notch"
              spin={true}
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              size={size || "lg"}
              className={classNames({ [cls.hidden]: hide, [cls.loaderTextSpinner]: loaderText })}
            />
          )}
          {loaderText}
        </SpinnerContainer>
      ) : (
        children
      )}
    </div>
  );
};

export default WithLoader;
