import React, { FC } from "react";
import { useProductLotNumberSelect } from "./useProductLotNumberSelect";
import { DetailedSelect } from "../DetailedSelect/DetailedSelect";
import { Box } from "common/components/ui";
import { ATPDetails } from "common/clients/inventory/ATP/ATPDetails";

export interface ProductLotNumberSelectProps {
  productLotNumbers: ATPDetails[];
  selectedLotNumber?: ATPDetails;
  onSelectLotNumber?: (lotNumber?: string) => void;
}

export const ProductLotNumberSelect: FC<ProductLotNumberSelectProps> = ({
  productLotNumbers,
  selectedLotNumber,
  onSelectLotNumber,
}) => {
  const { selectionOptions, selectedOption, onLotNumberChange } = useProductLotNumberSelect({
    productLotNumbers,
    onSelectLotNumber,
    selectedLotNumber,
  });
  return (
    <Box marginLeft={"S4"} marginRight={"S4"} width="14rem">
      <DetailedSelect
        id="product_lot_number_selection"
        options={selectionOptions}
        onChange={onLotNumberChange}
        value={selectedOption}
        isSearchable={false}
      />
    </Box>
  );
};
