import classNames from "classnames/dedupe";
import React from "react";
import { FormattedMessage } from "react-intl";

import { BorderlessButton } from "common/components/BorderlessButton";
import { ButtonType, LoaderButton, LoaderButtonProps as YesProps } from "common/components/WithLoader/LoaderButton";
import { handleIfExists } from "common/EventUtils";

import cls from "./YesOrNo.less";

interface OwnProps {
  orientation: "vertical" | "horizontal";
  onYes?: React.MouseEventHandler<HTMLButtonElement>;
  onNo?: React.MouseEventHandler<HTMLSpanElement>;
  yesText?: React.ReactNode;
  noText?: React.ReactNode;
  yesButtonProps?: Partial<YesProps>;
  className?: string;
  yesClassName?: string;
  noClassName?: string;
  yesDanger?: boolean;
  useReversedNoButton?: boolean;
}

/**
 * Provides a seller with two options.
 * Can be oriented top to bottom or left to right.
 */
export class YesOrNo extends React.Component<OwnProps, {}> {
  public onYes = handleIfExists<React.MouseEvent<HTMLButtonElement>>(this.props.onYes);
  public onNo = handleIfExists<React.MouseEvent<HTMLSpanElement>>(this.props.onNo);

  public render() {
    const { orientation, yesButtonProps, yesText, noText, yesClassName, noClassName, yesDanger, useReversedNoButton } =
      this.props;
    const orientationClass = orientation === "vertical" ? cls.vertical : cls.horizontal;

    const noButtonClassName = classNames(cls.no, noClassName);
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const noButtonText = noText || <FormattedMessage id={"YesOrNo.cancel"} defaultMessage={"Cancel"} />;

    return (
      <div className={classNames(cls.yesOrNoContainer, orientationClass)}>
        <LoaderButton
          {...{
            buttonClass: classNames(cls.yes, orientationClass, yesClassName),
            name: "yesButton",
            buttonType: yesDanger ? ButtonType.Danger : ButtonType.Primary,
            onClick: this.onYes,
            ...yesButtonProps,
          }}
        >
          {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
          {yesText || <FormattedMessage id={"YesOrNo.confirm"} defaultMessage={"Confirm"} />}
        </LoaderButton>
        {useReversedNoButton ? (
          <LoaderButton
            name={"noButton"}
            buttonClass={noButtonClassName}
            buttonType={ButtonType.PrimaryReversed}
            onClick={this.onNo}
          >
            {noButtonText}
          </LoaderButton>
        ) : (
          <BorderlessButton className={noButtonClassName} onClick={this.onNo}>
            {noButtonText}
          </BorderlessButton>
        )}
      </div>
    );
  }
}
