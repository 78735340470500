import { DeliverrAddress } from "@deliverr/commons-objects";
import styled from "@emotion/styled";
import Address from "common/components/Address";
import { Text, DefaultTheme } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { replenishmentShipmentCreateLabels } from "./replenishmentShipmentCreate.labels";

interface WholesaleDestinationAddressProps {
  address: DeliverrAddress;
}

const StyledDiv = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    margin-top: -${theme.spacing.S4};
    margin-bottom: ${theme.spacing.S6};
  `
);

export const WholesaleDestinationAddress: FC<WholesaleDestinationAddressProps> = ({ address }) => (
  <StyledDiv>
    <Text as="span" bold>
      <FormattedMessage {...replenishmentShipmentCreateLabels.steps.shipping.shipTo} />{" "}
    </Text>
    <Address address={address} hideName hideCountry={false} />
  </StyledDiv>
);
