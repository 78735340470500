import React from "react";
import { defaultTheme } from "../ui";

export interface FlexportPlusIconProps {
  className?: string;
  theme?: "light" | "dark";
}

export const FlexportPlusIcon = React.memo(({ className, theme = "dark" }: FlexportPlusIconProps) => {
  const fill = theme === "dark" ? defaultTheme.colors.LOGO_BLUE : "#fff";
  return (
    <svg viewBox="0 0 60 12" width="60" height="12" className={className}>
      <path
        d="M5.75496 7.1639V0.0205078H8.08322V6.86905C8.08322 7.35097 8.18896 7.46891 8.68308 7.46891H8.88236L9.15687 9.35795C8.97996 9.38235 8.2581 9.43319 7.82295 9.43319C6.53986 9.43319 5.75293 8.98584 5.75293 7.1639H5.75496Z"
        fill={fill}
      />
      <path
        d="M23.5957 2.80218L25.4075 2.66187L25.5844 3.20276C25.9484 2.86115 26.5848 2.51953 27.9492 2.51953C30.3019 2.51953 30.8428 4.1788 30.8428 6.02514C30.8428 7.87148 30.2429 9.51855 27.8089 9.51855C26.7272 9.51855 26.2208 9.27047 25.926 9.00003V11.3527H23.5977V2.80218H23.5957ZM28.4779 6.11868C28.4779 5.32971 28.3376 4.63632 27.325 4.63632C26.2432 4.63632 25.926 5.18941 25.926 6.11868C25.926 7.04795 26.1619 7.55427 27.325 7.55427C28.3132 7.55427 28.4779 6.90764 28.4779 6.11868Z"
        fill={fill}
      />
      <path
        d="M39.1514 2.8023L40.9631 2.66199L41.199 3.36759C41.4939 2.967 42.3052 2.62742 43.269 2.59082V4.83368C41.7155 4.84588 41.4817 5.3095 41.4817 6.22657V9.41496H39.1534V2.80636L39.1514 2.8023Z"
        fill={fill}
      />
      <path
        d="M43.918 2.59281H45.1178L45.4939 1.22432L47.2935 0.835938V2.59281H48.7637V4.83363H47.2935V6.77758C47.2935 7.23713 47.3647 7.47097 48.0926 7.47097H48.7637V9.41289C48.4099 9.43729 48.0235 9.44745 47.5863 9.44745C45.622 9.44745 44.9754 8.8232 44.9754 7.12936V4.83363H43.916V2.59281H43.918Z"
        fill={fill}
      />
      <path
        d="M0 2.67191H1.27088V2.54177C1.27088 0.847934 1.90531 0 4.09327 0C4.66872 0 4.77446 0.0122001 5.05711 0.0345677V1.92768H4.41048C3.73945 1.92768 3.59915 2.09239 3.59915 2.52754V2.66784H5.05711V4.64432H3.59915V9.40862H1.27088V4.64432H0V2.66784V2.67191Z"
        fill={fill}
      />
      <path
        d="M14.2277 7.40005C13.8434 7.62576 13.6441 7.6766 12.8755 7.6766C11.9645 7.6766 11.6127 7.35328 11.4968 6.80223H15.9744C16.0558 4.42923 15.8565 2.51172 12.8755 2.51172C9.89451 2.51172 9.25195 4.16082 9.25195 5.99496C9.25195 7.8291 9.88231 9.53514 12.8755 9.53514C14.0549 9.53514 14.7991 9.31553 15.5901 8.80311L14.2277 7.40005ZM12.8064 4.42923C13.7641 4.42923 13.8353 4.88472 13.8698 5.43374H11.4724C11.566 4.79118 11.871 4.42923 12.8043 4.42923H12.8064Z"
        fill={fill}
      />
      <path
        d="M20.5924 9.41442L19.4497 7.78159L18.3089 9.41442H15.7041L18.1422 5.94135L15.9278 2.80379L18.559 2.80176L19.4619 4.08281L20.3627 2.80176L23.0244 2.80379L20.7693 5.92508L23.2135 9.41442H20.5924Z"
        fill={fill}
      />
      <path
        d="M31.3223 6.02319C31.3223 3.78846 32.3451 2.51758 34.9804 2.51758C37.6157 2.51758 38.6263 3.78846 38.6263 6.02319C38.6263 8.25791 37.6137 9.541 34.9804 9.541C32.3471 9.541 31.3223 8.25791 31.3223 6.02319ZM36.2858 6.09436C36.2858 5.24642 36.0744 4.6364 34.9804 4.6364C33.8864 4.6364 33.6749 5.24846 33.6749 6.09436C33.6749 6.94026 33.8864 7.55232 34.9804 7.55232C36.0744 7.55232 36.2858 6.92806 36.2858 6.09436Z"
        fill={fill}
      />
      <path
        d="M57.4281 2.59278V0.0205078H55.1913V2.59278H52.6211V4.82954H55.1913V7.40181H57.4281V4.82954H60.0004V2.59278H57.4281Z"
        fill={fill}
      />
    </svg>
  );
});
