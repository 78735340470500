import { defineMessages, MessageDescriptor } from "react-intl";
import { ProductCasePackDisplayData } from "./ProductCasePackSelect.types";
import { isNaN, isNil } from "lodash";
import { calculateReserveStorageAvailableQuantity } from "common/utils/product/calculateReserveStorageAvailableQuantity";

export enum ProductCasePackError {
  LargeCasePack = "LargeCasePack",
  OutOfStock = "OutOfStock",
}

export interface ProductCasePackErrorPredicateParams {
  productCasePack: ProductCasePackDisplayData;
  maximumSelectableCaseQty?: number;
}

export const ProductCasePackErrorMessages: Record<ProductCasePackError, MessageDescriptor> = defineMessages({
  [ProductCasePackError.LargeCasePack]: {
    id: "ProductSearch.largeCasePack",
    defaultMessage: "Case quantity of pack exceeds requested inventory",
  },
  [ProductCasePackError.OutOfStock]: {
    id: "ProductSearch.outOfStock",
    defaultMessage: "Out of stock",
  },
});

const ProductCasePackErrorPredicates: Record<
  ProductCasePackError,
  (params: ProductCasePackErrorPredicateParams) => boolean
> = {
  [ProductCasePackError.LargeCasePack]: ({ productCasePack, maximumSelectableCaseQty }) =>
    !isNaN(maximumSelectableCaseQty) && productCasePack.quantity > maximumSelectableCaseQty!,
  [ProductCasePackError.OutOfStock]: ({ productCasePack }) =>
    calculateReserveStorageAvailableQuantity(productCasePack) === 0,
};

export const getProductCasePackErrorMessage = (
  params: ProductCasePackErrorPredicateParams
): MessageDescriptor | undefined => {
  const error = Object.keys(ProductCasePackErrorPredicates).find((err) =>
    ProductCasePackErrorPredicates[err](params)
  ) as ProductCasePackError | undefined;

  return !isNil(error) ? ProductCasePackErrorMessages[error] : undefined;
};
