import { StorageItem } from "common/clients/storage/StorageItem/StorageItem";
import { ProductLotInformationFields } from "inbounds/steps/lot-information/types/LotInformationFormValues";

export const mapStorageItemToProductLotInformationFields = (
  storageItem: Partial<StorageItem>
): ProductLotInformationFields => ({
  hasFullExpirationDate: { value: !!storageItem.expirationDateHasDay },
  expirationDate: storageItem.expirationDate,
  lotNumber: storageItem.lotNumber,
});
