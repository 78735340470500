import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames/dedupe";
import React, { FC, MouseEvent, PropsWithChildren, useEffect, useState } from "react";
import cls from "./CollapsibleSection.less";

export interface Collapsible {
  isCollapsible?: boolean;
  isDefaultOpen?: boolean;
}

interface Props {
  isBolded?: boolean;
  collapsedContent: React.ReactNode;
  classNameCollapsed?: string;
  classNameExpanded?: string;
  isDefaultOpen?: boolean;
  noFill?: boolean;
  isDisabled?: boolean;
  className?: string;
  headerContent?: React.ReactNode;
}

export type CollapsibleSectionProps = PropsWithChildren<Props>;

export const CollapsibleSection: FC<CollapsibleSectionProps> = ({
  isBolded,
  collapsedContent,
  isDefaultOpen,
  classNameCollapsed,
  classNameExpanded,
  children,
  noFill,
  isDisabled,
  className,
  headerContent,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(Boolean(isDefaultOpen));

  useEffect(() => {
    setIsOpen(Boolean(isDefaultOpen));
  }, [isDefaultOpen]);

  const toggleOpen = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setIsOpen(!isOpen);
  };

  const isShownAsOpen = !isDisabled && isOpen;

  return (
    <>
      <div
        onClick={isDisabled ? undefined : toggleOpen}
        className={cn(
          {
            [cls.collapseHeader]: !classNameCollapsed,
            [cls.headerExpanded]: isShownAsOpen,
            [cls.noFill]: noFill,
            [cls.boldedHeader]: isBolded,
          },
          classNameCollapsed,
          className
        )}
        data-testid="collapsible_button"
      >
        {collapsedContent}
        {isDisabled ? null : (
          <div className={cls.openIndicator}>{<FontAwesomeIcon icon={isOpen ? "chevron-up" : "chevron-down"} />}</div>
        )}
        {headerContent}
      </div>
      {isShownAsOpen && (
        <div className={cn({ [cls.expandedSection]: !classNameExpanded, [cls.noFill]: noFill }, classNameExpanded)}>
          {children}
        </div>
      )}
    </>
  );
};
