import React from "react";
import styled from "@emotion/styled";
import { ThemeProps } from "../shared";
import { INPUT_FILE_DROP_DIMS } from "./DropzoneUtils";
import { Icon } from "../Icon";

interface FilePreviewProps {
  url: string;
}

const ImageContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  background-color: ${theme.colors.NEUTRAL[40]};
  margin-right: ${theme.spacing.S3};
  width: ${INPUT_FILE_DROP_DIMS};
  height: ${INPUT_FILE_DROP_DIMS};
`
);

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const NoPreview = styled.div<ThemeProps>(
  ({ theme }) => `
  padding-left: ${theme.spacing.S5};
  padding-right: ${theme.spacing.S3};
`
);

const StyledIcon = styled(Icon)<ThemeProps>(
  ({ theme }) => `
  font-size: 24px;
  color: ${theme.colors.NEUTRAL[100]};
`
);

export const FilePreview: React.FC<FilePreviewProps> = ({ url }) => {
  const [imgErr, setImgErr] = React.useState<boolean>(false);

  return (
    <>
      {!url || imgErr ? (
        <NoPreview>
          <StyledIcon type="file-alt" />
        </NoPreview>
      ) : (
        <ImageContainer>
          <Image src={`${process.env.STATIC_ASSETS_ROOT}/${url}`} onError={() => setImgErr(true)} />
        </ImageContainer>
      )}
    </>
  );
};
