import { generatePath, useHistory } from "react-router-dom";

import { Path } from "paths";
import { QcLookup } from "@deliverr/returns-client";
import { isNil } from "lodash";
import { useSellerInfo } from "returns/seller/useSellerInfo";

export const useConnectedRmtCard = () => {
  const { push } = useHistory();
  const { sellerInfo } = useSellerInfo();

  const qcAddresses =
    sellerInfo?.qcAddresses?.filter((address) => !(address.qcId === QcLookup.Self || isNil(address.qcId))) ?? [];
  const address = qcAddresses?.[0];

  const onDoneClicked = () => {
    push(generatePath(Path.returnsPolicy));
  };

  return { onDoneClicked, address };
};
