import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { head } from "lodash/fp";

import { DamagedProductBlade } from "common/components/ProblemBlade/DamagedProductBlade";
import { sellerProblemsViewClient } from "Clients";
import { getSellerId } from "common/user/UserSelectors";
import { logError } from "Logger";
import { SHIPMENT_PROBLEMS_DAMAGED_PRODUCT_BLADE_ID } from "../blades";
import { DamagedProductProblemRow } from "common/components/ProblemsTable/types";
import { getShippingPlan } from "inbounds/steps/InboundStepSelectors";
import { SelectedShipmentProblem } from "../../context";

interface DamagedProductNonComplianceBladeProps {
  shipmentProblem: DamagedProductProblemRow;
  refetch: () => void;
  hideShipmentProblemBlade: () => void;
  setSelectedProblem: (problem: SelectedShipmentProblem | undefined) => void;
  isShipmentProblemBladeOpen: boolean;
}

export const ShipmentProblemDamagedProductBlade: FC<DamagedProductNonComplianceBladeProps> = ({
  shipmentProblem,
  refetch,
  hideShipmentProblemBlade,
  setSelectedProblem,
  isShipmentProblemBladeOpen,
}) => {
  const sellerId = useSelector(getSellerId);
  const { name: shippingPlanName, id: shippingPlanId } = useSelector(getShippingPlan);

  const { id, mappedSkus } = shipmentProblem;

  useEffect(() => {
    if (id === undefined) {
      logError({ fn: "ShipmentProblemDamagedProductBlade", ...shipmentProblem }, "Problem case id is undefined");
    }
  }, [id, shipmentProblem]);

  if (id === undefined) {
    return null;
  }

  const resetBladeState = () => {
    hideShipmentProblemBlade();
    setSelectedProblem(undefined);
  };

  const onDisputeDamagedProductClick = async () => {
    const ctx = { fn: "onDisputeDamagedProductClick", sellerId, id };
    try {
      await sellerProblemsViewClient.disputeDamagedProduct(id);
    } catch (err) {
      logError(ctx, err);
      return;
    }

    await refetch();
  };

  return (
    <DamagedProductBlade
      id={SHIPMENT_PROBLEMS_DAMAGED_PRODUCT_BLADE_ID}
      shipmentProblem={shipmentProblem}
      onShippingPlanClick={resetBladeState}
      onCloseClick={resetBladeState}
      isBladeOpen={isShipmentProblemBladeOpen}
      product={head(mappedSkus)}
      onDisputeClick={onDisputeDamagedProductClick}
      shippingPlanId={shippingPlanId}
      shippingPlanName={shippingPlanName}
    />
  );
};
