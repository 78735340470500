import { Step } from "common/components/StepContainer";
import { transferCreateChooseOriginWarehouse } from "./actions";
import { selectTransferCreate } from "./store/TransferCreateSelectors";
import { TransferPickProductStep } from "./steps/fba";
import { fetchStorageProductsCaseInfo } from "./store/fetchStorageProductsCaseInfo";
import { TransferPickStorageWarehouseStep } from "./steps/fba/TransferPickStorageWarehouseStep";
import { TransferSelectProductQuantityStep } from "./steps/fba/TransferSelectProductQuantityStep";
import { WfsAuthorizationStep } from "./steps/wfs/WfsAuthorizationStep";
import { createOrUpdateReplenishmentOrder } from "./actions/replenishmentOrder/createOrUpdateReplenishmentOrder";
import { ReplenishmentOrderShippingStep } from "./steps/common/replenishment/ReplenishmentOrderShippingStep";
import { ReplenishmentOrderConfirmation } from "./steps/common/replenishment/confirm/ReplenishmentOrderConfirmation";
import { submitReplenishmentOrder } from "./actions/replenishmentOrder/submitReplenishmentOrder";
import { SharedConfirmedStep } from "common/wholesale-common/components/SharedConfirmedStep";
import { replenishmentShipmentCreateLabels } from "./steps/common/replenishment/replenishmentShipmentCreate.labels";
import { ReplenishmentShipmentCreationError } from "./steps/common/replenishment/ReplenishmentShipmentCreationError";

export enum WfsSteps {
  Authorize = "wfs/authorize",
  SelectProduct = "wfs/selectProduct",
  SelectStorageWarehouse = "wfs/selectStorageWarehouse",
  SelectQuantities = "wfs/selectQuantities",
  ShipmentCreationError = "wfs/ShipmentCreationError",
  SelectShippingMethod = "wfs/SelectShippingMethod",
  ShippingDetails = "wfs/ShippingDetails",
  Confirmed = "wfs/Confirmed",
}

export const wfsSteps: (dispatch, getState) => Record<WfsSteps, Step> = (dispatch, getState) => ({
  [WfsSteps.Authorize]: {
    component: WfsAuthorizationStep,
    next: async () => {
      return WfsSteps.SelectProduct;
    },
  },
  [WfsSteps.SelectProduct]: {
    component: TransferPickProductStep,
    async next() {
      const { availableInventory, selectedProducts } = selectTransferCreate(getState());
      await dispatch(fetchStorageProductsCaseInfo(Object.keys(selectedProducts)));
      if (availableInventory.length === 1) {
        dispatch(transferCreateChooseOriginWarehouse(availableInventory[0]));
        return WfsSteps.SelectQuantities;
      }
      return WfsSteps.SelectStorageWarehouse;
    },
  },
  [WfsSteps.SelectStorageWarehouse]: {
    component: TransferPickStorageWarehouseStep,
    next: async () => WfsSteps.SelectQuantities,
  },
  [WfsSteps.SelectQuantities]: {
    component: TransferSelectProductQuantityStep,
    next: async () => {
      if (await createOrUpdateReplenishmentOrder(dispatch, getState, true)) {
        return WfsSteps.SelectShippingMethod;
      }
      return;
    },
    reassess: () => {
      const { selectedProducts } = selectTransferCreate(getState());
      if (Object.keys(selectedProducts).length === 0) {
        return WfsSteps.SelectProduct;
      }
      return;
    },
  },
  [WfsSteps.ShipmentCreationError]: {
    component: ReplenishmentShipmentCreationError,
    hasNoBack: true,
  },
  [WfsSteps.SelectShippingMethod]: {
    component: ReplenishmentOrderShippingStep,
    hasNoBack: true,
    async next() {
      return WfsSteps.ShippingDetails;
    },
  },
  [WfsSteps.ShippingDetails]: {
    component: ReplenishmentOrderConfirmation,
    async next() {
      if (await submitReplenishmentOrder(dispatch, getState)) {
        return WfsSteps.Confirmed;
      }
      return;
    },
  },
  [WfsSteps.Confirmed]: {
    component: SharedConfirmedStep(replenishmentShipmentCreateLabels),
    hasNoBack: true,
  },
});
