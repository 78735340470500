import { useState } from "react";
import { useDispatch } from "react-redux";
import { ProductPreparationData, ProductPrepCategory } from "@deliverr/commons-clients/lib/product/ProductPreparation";
import { useIntl } from "react-intl";
import { toast } from "common/components/ui";
import { some, every } from "lodash/fp";
import { productClient } from "Clients";
import log, { logError, logStart, logSuccess } from "Logger";
import { assignCategoriesContent } from "./assignCategories.labels";
import { useProductCategoryOptions } from "common/hooks/useProductCategoryOptions";
import { ApiResponseCode } from "common/clients/seller/ApiResponse/ApiResponseCode";
import { useFulfillmentInboundNavigation } from "inbounds/navigation/fulfillment/useFulfillmentInboundNavigation";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { ShippingPlanItemDetail } from "inbounds/InboundTypes";

export const useAssignProductCategories = (planItemDetails: ShippingPlanItemDetail[], onSave?: () => void) => {
  const { goToNextStep, goToPreviousStep } = useFulfillmentInboundNavigation();
  const dispatch = useDispatch();

  const { formatMessage } = useIntl();
  const { categorySelectOptions } = useProductCategoryOptions();

  const [planItemCategories, setPlanItemCategories] = useState(() =>
    planItemDetails.map((itemDetail) => itemDetail.category)
  );

  const [selectedRows, setSelectedRows] = useState<Record<number, boolean>>(() =>
    planItemDetails.reduce((rows, _, i) => ({ ...rows, [i]: false }), {})
  );

  const allRowsSelected = every(Boolean, selectedRows);
  const anyRowsSelected = some(Boolean, selectedRows);
  const productsSelected = Object.values(selectedRows).filter(Boolean).length;

  const toggleRow = (idx: number, value: boolean) => setSelectedRows((rows) => ({ ...rows, [idx]: value }));

  const toggleAllRows = () => {
    setSelectedRows(planItemDetails.reduce((rows, _, i) => ({ ...rows, [i]: !anyRowsSelected }), {}));
  };

  const setItemCategory = (idx: number, value: ProductPrepCategory) => {
    setPlanItemCategories((categories) => [...categories.slice(0, idx), value, ...categories.slice(idx + 1)]);
  };

  const setCategoryForSelectedItems = (value: ProductPrepCategory) => {
    setPlanItemCategories((categories) => categories.map((category, idx) => (selectedRows[idx] ? value : category)));
  };

  // replace initial category values with in state values
  const itemDetails = planItemDetails.map((details, idx) => ({
    ...details,
    category: planItemCategories[idx],
  }));

  const onSubmit = async () => {
    const ctx = logStart({ fn: "submitProductCategories", planItemDetails, planItemCategories });
    try {
      dispatch(addLoader(InboundLoaderId.transition));
      const prepData: ProductPreparationData[] = [];
      planItemDetails.forEach(({ dsku, category: oldCategory }, idx) => {
        const category = planItemCategories[idx]!;
        if (category !== oldCategory) {
          prepData.push({
            dsku,
            category,
          });
        }
      });
      if (prepData.length) {
        log.info({ ...ctx, prepData }, "submitting product category assignments");
        const result = await productClient.createOrUpdateProductPreparation(prepData);
        if (result.some((res) => res.code === ApiResponseCode.ERROR)) {
          logError({ ...ctx, result }, "received error code in category assignment results");
          toast.error(formatMessage(assignCategoriesContent.submitProductCategoriesError), {
            toastId: "submitProductCategoriesError",
          });
          return;
        }
      }
      logSuccess(ctx, "successfully submitted product category assignments");
      if (onSave) {
        onSave?.();
      } else {
        await goToNextStep({ refreshProductCache: true });
      }
    } catch (err) {
      logError(ctx, err);
      toast.error(formatMessage(assignCategoriesContent.submitProductCategoriesError), {
        toastId: "submitProductCategoriesError",
      });
    } finally {
      dispatch(clearLoader(InboundLoaderId.transition));
    }
  };
  const hasMissingCategories = planItemCategories.some((category) => !category);

  return {
    allRowsSelected,
    anyRowsSelected,
    categorySelectOptions,
    hasMissingCategories,
    itemDetails,
    productsSelected,
    selectedRows,
    setCategoryForSelectedItems,
    setItemCategory,
    onNextClick: onSubmit,
    onPreviousClick: goToPreviousStep,
    toggleAllRows,
    toggleRow,
  };
};
