import React from "react";
import styled from "@emotion/styled";
import ReactDropzone, { DropzoneProps as ReactDropzoneProps } from "react-dropzone";
import { DefaultTheme, ThemeProps } from "../shared";
import { useTheme } from "emotion-theming";
import { isMobile } from "../shared/helpers/utils";
import { Stack } from "../Stack";
import { DropzoneMessaging } from "./DropzoneMessaging";
import { flatten } from "lodash";
import { ErrorContainer, HelpText, StyledErrorIcon } from "../FormGroup";
import { Text } from "../Text";
import { MultiMediaGallery } from "./MultiMediaGallery";
import {
  Container,
  FullWidthContainer,
  DragDropArea,
  UploadFileValue,
  DropzoneLabel,
  getFileTypes,
  FILE_TYPE_OPTIONS,
  FileTypes,
  LoadingContainer,
  DropzoneLoading,
  INPUT_FILE_DROP_DIMS,
} from "./DropzoneUtils";
import { LocalizedMessage } from "../types";

export interface MultiUploadFileValue extends UploadFileValue {
  fileType?: FileTypes | "unknown";
}

export interface MultiMediaDropzoneProps {
  id: string;
  isMandatory?: boolean;
  acceptedFileTypes: FileTypes[];
  onChange: ReactDropzoneProps["onDrop"];
  onFileClear: (UploadFileValue) => Promise<void>;
  loading?: boolean;
  label?: LocalizedMessage;
  helpText?: LocalizedMessage;
  values?: UploadFileValue[];
  progressPercent?: number;
  dropzoneProps?: ReactDropzoneProps;
  errorText?: LocalizedMessage;
  fullWidth?: boolean;
  allowRemovingMedia?: boolean;
}

export const StyledContainer = styled(Container)<ThemeProps>`
  height: auto;
`;

export const StyledFullWidthContainer = styled(FullWidthContainer)<ThemeProps>`
  height: auto;
`;

const MultiFilesLoadingContainer = styled(LoadingContainer)`
  height: ${INPUT_FILE_DROP_DIMS};
`;

export const MultiMediaDropzone: React.FC<MultiMediaDropzoneProps> = ({
  id,
  isMandatory = false,
  acceptedFileTypes,
  loading,
  onChange,
  onFileClear,
  label,
  helpText,
  values,
  dropzoneProps,
  errorText,
  fullWidth,
  allowRemovingMedia,
}) => {
  const theme = useTheme<DefaultTheme>();
  const mobile = isMobile(theme);

  const Wrapper = fullWidth ? StyledFullWidthContainer : StyledContainer;

  return (
    <Stack gap="S2">
      {label && (
        <DropzoneLabel htmlFor={id}>
          {label}
          {isMandatory && (
            <Text as="span" appearance="DANGER">
              &nbsp; *
            </Text>
          )}
        </DropzoneLabel>
      )}
      <Wrapper data-testid="multimedia-dropzone-container">
        {loading ? (
          <MultiFilesLoadingContainer data-testid="multimedia-loading-container">
            <DropzoneLoading />
          </MultiFilesLoadingContainer>
        ) : (
          <ReactDropzone
            disabled={loading}
            onDrop={onChange}
            accept={getFileTypes(acceptedFileTypes)}
            {...dropzoneProps}
          >
            {({ getRootProps, getInputProps, isDragActive, fileRejections }) => (
              <DragDropArea {...getRootProps()} isActive={isDragActive} hasError={fileRejections.length > 0}>
                <input {...getInputProps()} id={id} data-testid="multimedia-dropzone-input" />
                <DropzoneMessaging
                  expectedFileTypes={flatten(acceptedFileTypes.map((type) => FILE_TYPE_OPTIONS[type]))}
                  hasErrors={fileRejections.length > 0}
                  isActive={isDragActive}
                  isMobile={mobile}
                />
              </DragDropArea>
            )}
          </ReactDropzone>
        )}
        <MultiMediaGallery values={values} onFileClear={onFileClear} allowRemovingMedia={allowRemovingMedia} />
      </Wrapper>
      {helpText && <HelpText>{helpText}</HelpText>}
      {errorText && (
        <ErrorContainer>
          <StyledErrorIcon type="exclamation-circle" /> {errorText}
        </ErrorContainer>
      )}
    </Stack>
  );
};
