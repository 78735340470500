import React, { FC } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { Currency, CurrencyFormat } from "common/components/Currency";
import { TooltipLineItem } from "./ReturnsCost";

export const ReturnsFee: FC<{ returnsFee: number; messageDescriptor: MessageDescriptor }> = ({
  returnsFee,
  messageDescriptor,
}) => (
  <TooltipLineItem>
    <FormattedMessage id={messageDescriptor.id} defaultMessage={messageDescriptor.defaultMessage} />
    <span>
      <Currency currency={CurrencyFormat.USD} value={returnsFee} />
    </span>
  </TooltipLineItem>
);
