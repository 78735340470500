import React from "react";
import styled from "@emotion/styled";
import { DefaultTheme } from "../ui";
import { FlexportPlusIcon } from "./FlexportPlusIcon";

const Badge = styled.span<{ filled: boolean }, DefaultTheme>(
  ({ filled, theme }) => `
    border: 1px solid ${theme.colors.LOGO_BLUE};
    border-radius: ${theme.border.radius.R4};
    background: ${filled ? theme.colors.LOGO_BLUE : "transparent"};
    padding: ${theme.spacing.S1} ${theme.spacing.S2};
    display: inline-flex;
    align-items: center;
    height: 24px;
  `
);

export interface FlexportPlusBadgeProps {
  className?: string;
  filled?: boolean;
}

export const FlexportPlusBadge = ({ className, filled = false }: FlexportPlusBadgeProps) => {
  const iconTheme = filled ? "light" : "dark";
  return (
    <Badge className={className} filled={filled}>
      <FlexportPlusIcon theme={iconTheme} />
    </Badge>
  );
};
