import { ShippingType } from "@deliverr/replenishment-client";
import { WholesaleShipmentType } from "@deliverr/wholesale-client";

export const getReplenishmentShippingTypeFromWholesaleType = (
  wholesaleShippingMethod: WholesaleShipmentType
): ShippingType => {
  switch (wholesaleShippingMethod) {
    case WholesaleShipmentType.PARCEL:
      return ShippingType.PARCEL;
    default:
      return ShippingType.LTL;
  }
};
