import React, { FC } from "react";
import styled from "@emotion/styled";
import { Select } from "common/components/ui";
import { useInventoryLocationFilter } from "./useInventoryLocationFilter";

const StyledInventoryLocationSelect = styled(Select)`
  min-width: 150px;
`;

export const InventoryLocationFilter: FC = () => {
  const { filterOptions, selectedValue, handleOnSelect } = useInventoryLocationFilter();

  return (
    <StyledInventoryLocationSelect
      flexWidth
      options={filterOptions}
      onChange={handleOnSelect}
      value={filterOptions.find((option) => option.value === selectedValue)}
    />
  );
};
