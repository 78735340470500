import { defineMessages } from "react-intl";

export const fbaIntegrationCommonLabels = {
  header: defineMessages({
    title: {
      id: "common.fbaIntegration.header.title",
      defaultMessage: "Transfer to Amazon",
    },
  }),
  referenceId: defineMessages({
    detailsTitle: {
      id: "common.fbaIntegration.referenceId.detailsTitle",
      defaultMessage: "Add Amazon reference ID",
    },
    warningTitle: {
      id: "common.fbaIntegration.referenceId.warningTitle",
      defaultMessage: "Important",
    },
    warningSubtitle: {
      id: "common.fbaIntegration.referenceId.warningSubtitle",
      defaultMessage:
        "Before Flexport can process the shipment, you must provide the Amazon reference ID for the shipment. This can be found on the shipment details page in your Seller Central dashboard.",
    },
    warningLink: {
      id: "common.fbaIntegration.referenceId.warningLink",
      defaultMessage: "<a>View shipment in Seller Central</a>",
    },
    label: {
      id: "common.fbaIntegration.referenceId.label",
      defaultMessage: "Amazon reference ID",
    },
    labelWithShipmentId: {
      id: "common.fbaIntegration.referenceId.labelWithShipmentId",
      defaultMessage: "Amazon reference ID for shipment {fbaShipmentId}",
    },
    placeholder: {
      id: "common.fbaIntegration.referenceId.placeholder",
      defaultMessage: "000000000",
    },
    comment: {
      id: "common.fbaIntegration.referenceId.comment",
      defaultMessage: "You can add this later on the following page",
    },
  }),
  helpCenter: defineMessages({
    learnMore: {
      id: "common.fbaIntegration.helpCenter.learnMore",
      defaultMessage: "Learn more",
    },
  }),
  shipmentDetails: defineMessages({
    fbaDestination: {
      id: "common.fbaIntegration.shipmentDetails.fbaDestination",
      defaultMessage: "Amazon FBA",
    },
    multipleLocation: {
      id: "fbaIntegration.shipmentDetails.multipleLocation",
      defaultMessage: "Multiple Locations",
    },
    viewDetails: {
      id: "fbaIntegration.shipmentDetails.viewDetails",
      defaultMessage: "View Details",
    },
  }),
  shipmentsModal: defineMessages({
    title: {
      id: "common.fbaIntegration.shipmentsModal.title",
      defaultMessage: "Ship to",
    },
    subtitle: {
      id: "common.fbaIntegration.shipmentsModal.subtitle",
      defaultMessage: "This transfer will be sent to multiple destinations.",
    },
  }),
  shipmentSummary: defineMessages({
    title: {
      id: "common.fbaIntegration.shipmentSummary.title",
      defaultMessage: "Shipment {index} of {numShipments}",
    },
    subtitle: {
      id: "common.fbaIntegration.shipmentSummary.subtitle",
      defaultMessage: "This transfer will be sent to multiple destinations.",
    },
    details: {
      id: "common.fbaIntegration.shipmentSummary.details",
      defaultMessage:
        "<b> Shipment: </b> {shipmentId} \u00b7 <showOrder>{poNumber}</showOrder> <b> Address: </b> {addressName} \u00b7 {city}, {state} {zip}",
    },
    details2: {
      id: "common.fbaIntegration.shipmentSummary.details2",
      defaultMessage: "{numSkus} SKUs \u00b7 {numUnits} units",
    },
    detailsWithRefId: {
      id: "common.fbaIntegration.shipmentSummary.detailsWithRefId",
      defaultMessage:
        "<b> Shipment: </b> {shipmentId} \u00b7 <showOrder>{poNumber}</showOrder> <b> Reference ID: </b> {fbaReferenceId} \u00b7 <b> Address: </b> {addressName} \u00b7 {city}, {state} {zip}",
    },
    bolId: {
      id: "common.fbaIntegration.shipmentSummary.bolId",
      defaultMessage: "BOL# {bolId}",
    },
    shipBy: {
      id: "common.fbaIntegration.shipmentSummary.shipBy",
      defaultMessage: "Ready to ship by",
    },
    dateTBD: {
      id: "common.fbaIntegration.shipmentSummary.dateTBD",
      defaultMessage: "TBD",
    },
  }),
  shipmentConfirmation: defineMessages({
    shippingBy: {
      id: "common.fbaIntegration.shipmentConfirmation.shippingBy",
      defaultMessage: "Shipping by {shipBy}",
    },
    fulfillmentProgressLink: {
      id: "common.fbaIntegration.shipmentConfirmation.fulfillmentProgressLink",
      defaultMessage: "View fulfillment progress",
    },
    estimatedCost: {
      id: "common.fbaIntegration.shipmentConfirmation.estimatedCost",
      defaultMessage: "Estimated cost",
    },
    viewDetails: {
      id: "common.fbaIntegration.shipmentConfirmation.viewDetails",
      defaultMessage: "View details",
    },
    fulfillmentProgressTitle: {
      id: "common.fbaIntegration.shipmentConfirmation.fulfillmentProgressTitle",
      defaultMessage: "Shipment Fulfillment Progress",
    },
  }),
};
