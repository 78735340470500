import { SortDir } from "common/models/SortDir";
import { ReplenishmentOrderListTabFilter } from "transfers/list/store/TransferListState";
import { ReplenishmentListStatus } from "transfers/listV2/ReplenishmentListStatuses";
import { ReplenishmentIndexRow } from "transfers/listV2/ReplenishmentIndexRow";

export enum WholesaleIngestionChannel {
  SPS = "SPS",
  OTHERS = "OTHERS",
  ALL = "ALL",
}

export interface WholesaleListSearchFilters {
  statuses: ReplenishmentListStatus[];
  ingestionChannel: WholesaleIngestionChannel;
  tabFilter: ReplenishmentOrderListTabFilter;
}

export interface WholesaleListState {
  pageItems?: ReplenishmentIndexRow[];
  searchTerm: string;
  sort: {
    fieldName: string;
    direction: SortDir;
  };
  isError?: boolean;
  pageNum: number;
  nbPages?: number;
  searchFilters: WholesaleListSearchFilters;
}
