import { FreightMode } from "@deliverr/freight-client";
import { OrderStatus } from "freight/types";

export interface FreightBookingListItem {
  id: number;
  to: string;
  cargo: number;
  updatedAt: Date;
  status: OrderStatus;
  type: FreightMode;
}

export const isItemAnOrder = (item: FreightBookingListItem) => {
  return item.status !== OrderStatus.QUOTED;
};
