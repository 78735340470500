export interface ProfileInfoResponse {
  name: string;
  picture: {
    data: {
      url: string;
    };
  };
}
export interface ProfileInfo {
  name: string;
  picture: string;
}

export const hasProfileInfo = (response: Response): response is Response => response.status === 200;

export const getProfileInfo = ({
  name,
  picture: {
    data: { url: picture },
  },
}: ProfileInfoResponse): ProfileInfo => ({
  name,
  picture,
});
