import { AnyAction } from "redux";
import { SmbShipmentState } from "../SmbShipmentState";
import produce from "immer";

export const setShippingPlanDetailsReducer = (state: SmbShipmentState, action: AnyAction) =>
  produce(state, (draft) => {
    const { shippingPlanId, flexId, shipmentId } = action;
    const existingEntry = state.shippingPlanMap[shippingPlanId] ?? {};
    draft.shippingPlanMap[shippingPlanId] = {
      flexId: flexId ?? existingEntry.flexId,
      shipmentId: shipmentId ?? existingEntry.shipmentId,
    };
  });
