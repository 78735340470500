/* eslint-disable react/jsx-key */
import cx from "classnames";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { Path } from "paths";
import { FastTagsNav } from "tags/base/FastTagsNav";
import facebookStartGraphic from "assets/img/tags/facebook/facebook-start.png";
import instagramStartGraphic from "assets/img/tags/facebook/instagram-start.png";
import { useConnectStart } from "./useConnectStart";

import sharedCls from "../../../common/facebook.less";
import cls from "./ConnectStart.less";

export const steps = [
  <FormattedMessage id="FacebookFastTag.StepVerifyShopify" defaultMessage={"Connect Shopify"} />,
  <FormattedMessage id="FacebookFastTag.StepConnectAccount" defaultMessage={"Connect Facebook"} />,
  <FormattedMessage id="FacebookFastTag.StepSeeNewAdStructures" defaultMessage={"Select Ad Types"} />,
  <FormattedMessage id="FacebookFastTag.StepConfigureAdTargeting" defaultMessage={"Configure Targeting"} />,
  <FormattedMessage id="FacebookFastTag.StepEnableDeliverrCampaigns" defaultMessage={"Enable Campaigns"} />,
];

export const ConnectStart: FC = () => {
  const { cursor } = useConnectStart();

  return (
    <div className={sharedCls.container}>
      <div className={sharedCls.inner}>
        <h4 className={cx(sharedCls.title, sharedCls.center)}>
          <FormattedMessage id="FacebookFastTag.StartTitle" defaultMessage={"Get Started with Facebook Ads"} />
        </h4>
        <div className={sharedCls.body}>
          <div className={cls.steps}>
            {steps.map((step, index) => (
              <div key={index} className={cls.step}>
                <div className={cls.stepBullet}>{index + 1}</div>
                {step}
              </div>
            ))}
          </div>
          <div className={cls.startGraphicContainer}>
            <img
              src={facebookStartGraphic}
              className={cx(cls.startGraphic, { [cls.startGraphicActive]: cursor === 0 })}
            />
            <img
              src={instagramStartGraphic}
              className={cx(cls.startGraphic, { [cls.startGraphicActive]: cursor === 1 })}
            />
          </div>
        </div>
      </div>
      <FastTagsNav
        className={sharedCls.nav}
        nextPage={Path.facebookConnectShopify}
        previousPage={Path.fastTagConnect}
        nextButtonText={<FormattedMessage id="getStarted" defaultMessage={"Get Started"} />}
        previousButtonText={<FormattedMessage id="back" defaultMessage={"Back"} />}
        nextButtonClassName={sharedCls.actionButton}
        previousButtonClassName={sharedCls.actionButton}
      />
    </div>
  );
};
