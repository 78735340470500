import { ShipmentStatusGrouping } from "inbounds/ShipmentStatus";
import { defineMessages, MessageDescriptor } from "react-intl";

export enum ShippingPlanType {
  ALL_TYPES = "ALL_TYPES",
  DIRECT = "DIRECT",
  FORWARDING = "FORWARDING",
  STORAGE = "STORAGE", // dummy shipping plan with inboundSource=STORAGE
}

export const SHIPPING_PLAN_DISPLAY_TEXT: Record<ShippingPlanType, MessageDescriptor> = defineMessages({
  [ShippingPlanType.ALL_TYPES]: { defaultMessage: "All types", id: `shippingPlan.${[ShippingPlanType.ALL_TYPES]}` },
  [ShippingPlanType.DIRECT]: { defaultMessage: "Direct", id: `shippingPlan.${[ShippingPlanType.DIRECT]}` },
  [ShippingPlanType.FORWARDING]: { defaultMessage: "Forwarding", id: `shippingPlan.${[ShippingPlanType.FORWARDING]}` },
  [ShippingPlanType.STORAGE]: { defaultMessage: "Reserve Storage", id: `shippingPlan.${[ShippingPlanType.STORAGE]}` },
});

export enum DiscrepancyStatusType {
  ALL_STATES = "ALL_STATES",
  WITH_DISCREPANCY = "WITH_DISCREPANCY",
}

export const DISCREPANCY_STATUS_DISPLAY_TEXT: Record<DiscrepancyStatusType, MessageDescriptor> = defineMessages({
  [DiscrepancyStatusType.ALL_STATES]: {
    defaultMessage: "All states",
    id: `discrepancyStatus.${DiscrepancyStatusType.ALL_STATES}`,
  },
  [DiscrepancyStatusType.WITH_DISCREPANCY]: {
    defaultMessage: "With discrepancy",
    id: `discrepancyStatus.${DiscrepancyStatusType.WITH_DISCREPANCY}`,
  },
});

export interface InboundListSearchFilters {
  shipmentStatuses: ShipmentStatusGrouping[];
  shippingPlanType: ShippingPlanType;
  discrepancyStatus: DiscrepancyStatusType;
}
