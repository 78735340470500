import classNames from "classnames/dedupe";
import { format } from "date-fns-tz";
import { DefaultTheme, Appearance } from "common/components/ui/shared";
import styled from "@emotion/styled";
import { get } from "lodash/fp";
import React from "react";

import { InfoIcon, InfoIconProps } from "common/components/icons/InfoIcon";
import { ShortDateTimeWithTz } from "common/date/DateFormat";
import { getLocalTimeZone } from "common/date/getLocalTimeZone";
import { fromLooseDate } from "common/date/LooseDate";

import cls from "./Timeline.less";

export interface Phase {
  status: string;
  label: React.ReactNode;
  sublabel?: React.ReactNode;
  timestamp?: string;
  timestampLabel?: string;
  tz?: string;
  timeFormat?: string;
  icon?: React.ReactNode;
  infoIcon?: InfoIconProps;
}

export type TimelineAppearance = Extract<keyof typeof Appearance, "SUCCESS" | "DANGER" | "WARNING" | "CANCELLED">;

interface OwnProps<T> {
  phases: Phase[];
  data: T;
  calculateCurrentPhase: (data: T) => string;
  labelClassName?: string;
  vertical?: boolean; // Vertical timeline places labels on the right side
  testId?: string;
  isOnPartialPhase?: boolean;
  appearance?: TimelineAppearance;
}

const InfoIconContainer = styled.span<{}, DefaultTheme>(
  ({ theme }) => `
  margin-left: ${theme.spacing.S2};
`
);

export const Timeline = <T,>(props: OwnProps<T>) => {
  const { phases, data, calculateCurrentPhase, vertical, labelClassName, testId, isOnPartialPhase, appearance } = props;
  const currentPhase = calculateCurrentPhase(data);
  const currentPhaseIx = phases.findIndex((phase) => phase.status === currentPhase);
  const isCancelled = appearance === "CANCELLED";
  const hasWarning = appearance === "WARNING";
  const hasError = appearance === "DANGER";

  return (
    <div
      className={classNames(
        !vertical ? cls.horizontalTimeline : cls.verticalTimeline,
        {
          [cls.timelineWarning]: hasWarning,
        },
        { [cls.timelineError]: hasError },
        { [cls.timelineCancelled]: isCancelled }
      )}
      data-testid={testId}
    >
      {phases.map((phase, ix) => {
        const isCurrent = phase.status && phase.status === currentPhase;
        const isPast = ix < currentPhaseIx;
        const timeFormat = phase.timeFormat ?? ShortDateTimeWithTz;
        const time: Date | undefined = phase.timestamp ? get(phase.timestamp, data) : undefined;
        return (
          <div
            key={ix}
            className={classNames(isCurrent ? cls.currentTimelinePhase : cls.timelinePhase, {
              [cls.timelinePast]: isPast,
            })}
            data-testid={isCurrent ? "current-phase" : phase.label}
          >
            <div
              className={classNames(!phase.icon ? cls.timelinePoint : cls.timelinePointIcon, {
                [cls.partialPoint]: isOnPartialPhase,
              })}
            >
              {phase.icon}
            </div>
            <div className={classNames(cls.timelineLabelContainer, labelClassName)}>
              <div className={cls.timelineLabel}>
                {phase.label}
                {phase.infoIcon && (
                  <InfoIconContainer>
                    <InfoIcon {...phase.infoIcon} size="small" />
                  </InfoIconContainer>
                )}
              </div>
              {phase.sublabel && <div className={cls.timelineSublabel}>{phase.sublabel}</div>}
              {time && (isPast || isCurrent) && (
                <div className={cls.timelineTimestamp}>
                  {phase.timestampLabel ? phase.timestampLabel + " " : ""}

                  {
                    // This is necesary since the types being passed into Timeline are not accurate at compile time are being driven by lib commons types
                    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                    format(fromLooseDate(time), timeFormat, { timeZone: phase.tz || getLocalTimeZone() })
                  }
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
