import { DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import { useCurrencyValue, UseCurrencyValueOptions } from "common/components/Currency";
import React from "react";
import { CellProps } from "react-table";
import { BasicTableColumn } from "./BasicTableTypes";

const StyledSpan = styled.span<{ isDiscounted: boolean }, DefaultTheme>(
  ({ theme, isDiscounted }) => `
    ${isDiscounted && "text-decoration: line-through;"}
    ${isDiscounted && `color: ${theme.colors.NEUTRAL[300]};`}
  `
);
export interface UseRenderMoneyCellOptions extends UseCurrencyValueOptions {
  appendedLabel?: string | JSX.Element;
  isDiscounted?: boolean;
}

export const useRenderMoneyOrStringCell = (options?: UseRenderMoneyCellOptions) => {
  // truncate by default
  const isDiscounted = options?.isDiscounted ?? false;
  const getCurrencyVal = useCurrencyValue({ ...options, truncate: options?.truncate ?? true });
  return ({ value }: CellProps<BasicTableColumn>): JSX.Element => {
    if (value === undefined) {
      return <span>{options?.fallback ?? null}</span>;
    }

    if (typeof value === typeof "string") {
      return value;
    }

    return (
      <StyledSpan isDiscounted={isDiscounted}>
        {getCurrencyVal(value)}
        {options?.appendedLabel ?? null}
      </StyledSpan>
    );
  };
};
