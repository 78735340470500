import { transfersShipmentCreateLabels } from "../../transfersShipmentCreate.labels";
import React from "react";
import { useIntl } from "react-intl";
import { ManagedStep } from "common/components/StepContainer/ManagedStep";
import { useTransferPickStorageWarehouseStep } from "./useTransferPickStorageWarehouseStep";
import { RadioTileGroup } from "common/components/ui";
import { StorageNonCDLocationWarning } from "storage/inbounds/create/selectDestination/StorageNonCDLocationWarning";

export const TransferPickStorageWarehouseStep = () => {
  const { formatMessage } = useIntl();
  const {
    storageWarehouseOptions,
    onStorageWarehouseSelect,
    selectedWarehouseOption,
    isNextLoading,
    shouldShowNonCDLocationWarning,
  } = useTransferPickStorageWarehouseStep();
  return (
    <ManagedStep
      data-testid="transfer-pick-storage-warehouse"
      title={formatMessage(transfersShipmentCreateLabels.steps.origin.title)}
      subtitle={formatMessage(transfersShipmentCreateLabels.steps.origin.subtitle)}
      isNextDisabled={!selectedWarehouseOption}
      isLoading={isNextLoading}
    >
      <RadioTileGroup
        currentValue={selectedWarehouseOption}
        direction="VERTICAL"
        name="storageWarehouseOriginRadioGroup"
        options={storageWarehouseOptions}
        onChange={onStorageWarehouseSelect}
        block
      />
      {shouldShowNonCDLocationWarning && <StorageNonCDLocationWarning />}
    </ManagedStep>
  );
};
