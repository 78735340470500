import { DeliverrAddress } from "@deliverr/commons-objects";
import { defineMessages, MessageDescriptor } from "react-intl";

export type AddressInputField =
  | keyof Pick<
      DeliverrAddress,
      "name" | "street1" | "street2" | "country" | "city" | "zip" | "state" | "company" | "email"
    >
  | "usAddress";

export type IntlAddressInputField = keyof Pick<
  DeliverrAddress,
  "name" | "street1" | "street2" | "zip" | "state" | "country" | "city" | "email"
>;

export const addressInputFieldNames = defineMessages<MessageDescriptor, Record<AddressInputField, MessageDescriptor>>({
  name: {
    id: "common.address.name",
    defaultMessage: "First & Last Name",
  },
  company: {
    id: "common.address.company",
    defaultMessage: "Company name",
  },
  street1: {
    id: "common.address.street1",
    defaultMessage: "Street Address",
  },
  street2: {
    id: "common.address.street2",
    defaultMessage: "Street Address 2 (Optional)",
  },
  country: {
    id: "common.address.country",
    defaultMessage: "Country",
  },
  city: {
    id: "common.address.city",
    defaultMessage: "City",
  },
  zip: {
    id: "common.address.zip",
    defaultMessage: "ZIP Code",
  },
  state: {
    id: "common.address.state",
    defaultMessage: "State",
  },
  email: {
    id: "common.address.email",
    defaultMessage: "Email (Optional)",
  },
  usAddress: {
    id: "common.address.usAddress",
    defaultMessage: "U.S. address only",
  },
});

export const intlAddressInputFieldNames = defineMessages<
  MessageDescriptor,
  Record<IntlAddressInputField, MessageDescriptor>
>({
  name: {
    id: "common.address.intl.name",
    defaultMessage: "Facility Name",
  },
  street1: {
    id: "common.address.intl.street1",
    defaultMessage: "Address",
  },
  street2: {
    id: "common.address.intl.street2",
    defaultMessage: "Apt / suite / other (optional)",
  },
  zip: {
    id: "common.address.intl.zip",
    defaultMessage: "Postal code",
  },
  state: {
    id: "common.address.intl.state",
    defaultMessage: "State / Province / Region",
  },
  country: {
    id: "common.address.intl.country",
    defaultMessage: "Country",
  },
  city: {
    id: "common.address.intl.city",
    defaultMessage: "City",
  },
  email: {
    id: "common.address.intl.email",
    defaultMessage: "Email",
  },
});
