import styled from "@emotion/styled";
import { Grid } from "common/components/ui";
import React from "react";

const FooterGrid = styled(Grid)`
  align-items: center;
`;

const FooterCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const FooterRight = styled.div`
  display: flex;
  justify-content: end;
`;

export interface OnboardingFooterGridProps {
  footerRight?: JSX.Element;
  footerLeft?: JSX.Element;
  footerCenter?: JSX.Element;
}

export const OnboardingFooterGrid = ({ footerRight, footerLeft, footerCenter }: OnboardingFooterGridProps) => (
  <FooterGrid columns="1fr auto 1fr" gap={null}>
    <div>{footerLeft}</div>
    <FooterCenter>{footerCenter}</FooterCenter>
    <FooterRight>{footerRight}</FooterRight>
  </FooterGrid>
);
