// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/** Flexport colors */\n/** Design system colors as of 8/27/21 **/\n.CollapsableShipmentAddressDisplay__shipmentDetailedButton--O6CS5 {\n  background-color: #ffffff;\n  color: #2d8ced;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  padding-top: 18px;\n  padding-bottom: 18px;\n  cursor: pointer;\n  width: 100%;\n  border-bottom: 1px solid #dae1e8;\n}\n.CollapsableShipmentAddressDisplay__shipmentDetails--Yp8Qt {\n  background-color: #ffffff;\n  width: 100%;\n  border-bottom: 1px solid #dae1e8;\n}\n", "",{"version":3,"sources":["webpack://./src/app/inbounds/steps/ship/CollapsableShipmentAddressDisplay.less"],"names":[],"mappings":"AAAA,qBAAqB;AACrB,yCAAyC;AACzC;EACE,yBAAA;EACA,cAAA;EACA,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,yBAAA;MAAA,sBAAA;UAAA,mBAAA;EACA,wBAAA;MAAA,qBAAA;UAAA,uBAAA;EACA,iBAAA;EACA,oBAAA;EACA,eAAA;EACA,WAAA;EACA,gCAAA;AACF;AAEA;EACE,yBAAA;EACA,WAAA;EACA,gCAAA;AAAF","sourcesContent":["@import \"~app/common/styles/colors\";\n\n.shipmentDetailedButton {\n  background-color: @white;\n  color: @lightBlue;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-top: 18px;\n  padding-bottom: 18px;\n  cursor: pointer;\n  width: 100%;\n  border-bottom: 1px solid @borderGray;\n}\n\n.shipmentDetails {\n  background-color: @white;\n  width: 100%;\n  border-bottom: 1px solid @borderGray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shipmentDetailedButton": "CollapsableShipmentAddressDisplay__shipmentDetailedButton--O6CS5",
	"shipmentDetails": "CollapsableShipmentAddressDisplay__shipmentDetails--Yp8Qt"
};
export default ___CSS_LOADER_EXPORT___;
