import log from "Logger";
import { extractPacksDims } from "storage/packUtils/extractPacksDims";
import { Pack } from "common/clients/storage/Packs/Pack";
import { FBA_MAX_CASE_DIMENSION_IN, FBA_MAX_CASE_WEIGHT_LB } from "common/constants/fbaValidations.const";

export function productIsValidForFBASmallParcel(
  selectedProductData: Pick<Pack, "weight" | "width" | "height" | "length" | "lengthUnit" | "weightUnit">
): boolean {
  const dims = extractPacksDims(selectedProductData);
  if (
    selectedProductData.lengthUnit === "in" &&
    (dims.length > FBA_MAX_CASE_DIMENSION_IN ||
      dims.width > FBA_MAX_CASE_DIMENSION_IN ||
      dims.height > FBA_MAX_CASE_DIMENSION_IN)
  ) {
    log.warn({ storageProductData: selectedProductData }, "Invalid SP StorageProductData (Length)");
    return false;
  }
  if (selectedProductData.weightUnit === "lb" && dims.weight > FBA_MAX_CASE_WEIGHT_LB) {
    log.warn({ storageProductData: selectedProductData }, "Invalid SP StorageProductData (Weight)");
    return false;
  }
  return true;
}
