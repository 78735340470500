import { useEffect, useMemo } from "react";
import { useAsync } from "react-use";
import { useSelector } from "react-redux";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";
import { inboundClient } from "Clients";
import { getSellerId } from "common/user/UserSelectors";
import log from "Logger";

export const useFetchShippingPlan = ({ shippingPlanId }: { shippingPlanId?: number | null }) => {
  const { formatMessage } = useIntl();

  const sellerId = useSelector(getSellerId);

  const ctx = useMemo(() => ({ fn: "useFetchShippingPlan", sellerId, shippingPlanId }), [sellerId, shippingPlanId]);
  const {
    value,
    loading: isLoading,
    error,
  } = useAsync(async () => {
    if (!shippingPlanId) {
      return;
    }
    log.info({ ...ctx }, "getting shipping plan");
    return await inboundClient.getShippingPlan(sellerId, shippingPlanId);
  }, [sellerId, shippingPlanId]);

  useEffect(() => {
    if (error) {
      log.warn({ ...ctx, error }, "error fetching shipping plan");
      toast.error(
        formatMessage({
          id: "inbounds.shippingPlan.fetchError",
          defaultMessage: "There was an issue fetching your shipping plan",
        })
      );
    }
  }, [ctx, error, formatMessage]);

  return {
    value,
    isLoading,
    error,
  };
};
