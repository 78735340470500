import React from "react";
import styled from "@emotion/styled";
import { Grid } from "../Grid";
import { Icon } from "../Icon";
import { Text } from "../Text";
import { isMobile } from "../shared/helpers/utils";
import { ThemeProps } from "../shared/theme";

const innerMessageSize = "165px";
const MessageContainer = styled(Grid)<ThemeProps>(
  ({ theme }) => `
  max-width: ${innerMessageSize};
  align-items: center;

  svg {
    color: ${theme.colors.NEUTRAL[100]};
  }

  ${
    isMobile(theme)
      ? `
    color: ${theme.colors.BLUE[300]};
    grid-gap: ${theme.spacing.S2};

    svg {
      color: ${theme.colors.BLUE[300]};
    }
  `
      : ``
  }
`
);

const StyledIcon = styled(Icon)<ThemeProps>(
  ({ theme }) => `
  font-size: ${isMobile(theme) ? "16px" : "24px"};
`
);

const Underline = styled.span`
  text-decoration: underline;
`;

export const DropzoneMessaging = ({ hasErrors, isActive, isMobile: isMobileView, expectedFileTypes }) => {
  if (hasErrors) {
    return (
      <Text bold appearance="DANGER">
        File type must be {expectedFileTypes.map((type) => `.${type}`).join(", ")}
      </Text>
    );
  }
  if (isActive) {
    return (
      <Text bold appearance="PRIMARY">
        Drop your file here
      </Text>
    );
  }
  return (
    <MessageContainer columns="auto 1fr">
      <StyledIcon type="file-alt" />
      <span>
        {isMobileView ? (
          <Text bold>Choose file</Text>
        ) : (
          <>
            <Text as="span" bold>
              Drop your file here
            </Text>
            <span> or </span>
            <Underline>choose file</Underline>
          </>
        )}
      </span>
    </MessageContainer>
  );
};
