import { FormattedMessage, useIntl } from "react-intl";
import { Box, Text } from "common/components/ui";
import { WholesaleShippingMethod } from "@deliverr/wholesale-client";
import React, { FC } from "react";
import { wholesaleCreateLabels } from "../../wholesaleCreate.labels";
import { FlexRadioTileGroup } from "transfers/create/steps/fba/shipping/FlexRadioTileGroup";

interface ShippingMethodProps {
  value?: WholesaleShippingMethod;
  onChange: (value: WholesaleShippingMethod) => void;
  isInternationalWholesaleOrder: boolean;
  isV2?: boolean;
}

export const ShippingMethod: FC<ShippingMethodProps> = ({
  value,
  onChange,
  isInternationalWholesaleOrder,
  isV2 = false,
}) => {
  const { formatMessage } = useIntl();
  const sellerShippingMethodDescription = isInternationalWholesaleOrder
    ? { ...wholesaleCreateLabels.steps.shipping.options.deliverr.disabledOptionDescription }
    : { ...wholesaleCreateLabels.steps.shipping.options.deliverr.description };

  return (
    <Box width={isV2 ? "95%" : "80%"} paddingBottom="S6">
      <Box paddingBottom="S5">
        <Text bold>
          <FormattedMessage {...wholesaleCreateLabels.steps.shipping.selectCarrier} />
        </Text>
      </Box>
      <FlexRadioTileGroup
        direction="HORIZONTAL"
        options={[
          {
            value: WholesaleShippingMethod.DELIVERR,
            disabled: isInternationalWholesaleOrder,
            label: formatMessage(wholesaleCreateLabels.steps.shipping.options.deliverr.label),
            content: <FormattedMessage {...sellerShippingMethodDescription} />,
          },
          ...(value === WholesaleShippingMethod.COLLECT
            ? [
                {
                  value: WholesaleShippingMethod.COLLECT,
                  label: formatMessage(wholesaleCreateLabels.steps.shipping.options.yourCarrier.label),
                  content: (
                    <FormattedMessage {...wholesaleCreateLabels.steps.shipping.options.yourCarrier.description} />
                  ),
                },
              ]
            : [
                {
                  value: WholesaleShippingMethod.SELLER,
                  label: formatMessage(wholesaleCreateLabels.steps.shipping.options.yourCarrier.label),
                  content: (
                    <FormattedMessage {...wholesaleCreateLabels.steps.shipping.options.yourCarrier.description} />
                  ),
                },
              ]),
        ]}
        currentValue={value}
        onChange={(evt) => {
          onChange(evt.currentTarget.value as WholesaleShippingMethod);
        }}
      />
    </Box>
  );
};
