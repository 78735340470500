import { useSelector } from "react-redux";

import {
  facebookProfileInfo,
  hasValidPermissions as hasValidFacebookPermissions,
} from "tags/facebook/connect/state/FacebookSelectors";
import { Auth, isLoginSuccessful } from "tags/facebook/model/Auth";
import { useCheckFacebookLoginStatus } from "tags/facebook/util/useCheckFacebookLoginStatus";
import { useFacebookOnLogin } from "tags/facebook/util/useFacebookOnLogin";

export const useConnectAccount = () => {
  const profile = useSelector(facebookProfileInfo);
  const hasValidPermissions = useSelector(hasValidFacebookPermissions);
  const { onSuccessfulLogin } = useFacebookOnLogin();

  const { loginStatus } = useCheckFacebookLoginStatus(async (authResponse: Auth) => {
    if (isLoginSuccessful(authResponse)) {
      await onSuccessfulLogin(authResponse);
    }
  });

  return {
    hasValidPermissions,
    loginStatus,
    profile,
  };
};
