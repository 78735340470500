import { ReportsHeapEvent } from "./ReportsHeapEvent";
import { SPThunkAction, createActionCreator } from "common/ReduxUtils";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { difference, sortBy } from "lodash/fp";

import { Products } from "common/utils/product/Products";
import { Report } from "./Report";
import { SelectedProductData } from "common/components/ProductChooser";
import { SellerReportType } from "common/clients/reporting/Report/SellerReportType";
import { delayBy } from "common/DelayBy";
import { fetchProductsByDskus } from "common/utils/product/fetchProductsByDskus";
import { initiateDownloadFromUrl } from "common/InitiateDownload";
import log from "Logger";
import { notifyUserOfError } from "common/ErrorToast";
import { reportingClient } from "common/clients/instances";
import { toast } from "common/components/ui";
import { trackHeapEvent } from "common/utils/heap/trackHeapEvent";
import uniq from "lodash/uniq";

import { isReportWithSingleDateSelect } from "./utils";

export enum ReportsActionTypes {
  CLEAR_EMPTY_REPORT = "CLEAR_EMPTY_REPORT",
  CLEAR_REPORT_FIELDS = "CLEAR_REPORT_FIELDS",
  GENERATE_REPORT = "GENERATE_REPORT",
  GET_REPORTS = "GET_REPORTS",
  UPDATE_DATE_RANGE = "UPDATE_DATE_RANGE",
  UPDATE_LOGISTICS_SKU = "UPDATE_LOGISTICS_SKU",
  UPDATE_SELECTED_PRODUCT = "UPDATE_SELECTED_PRODUCT",
  UPDATE_REPORT_TYPE = "UPDATE_REPORT_TYPE",
}

const productsForReports = async (reports): Promise<Products> => {
  const logisticsSkus: string[] = [];
  for (const report of reports) {
    if (report.logisticsSku) {
      logisticsSkus.push(report.logisticsSku);
    }
  }

  if (logisticsSkus.length === 0) {
    return {};
  }

  try {
    return await fetchProductsByDskus(uniq(logisticsSkus));
  } catch {
    return {};
  }
};

const GET_REPORTS_INTERVAL = 15 * 1000; // 15 seconds
let GET_REPORTS_ATTEMPTS = 0;
const MAX_REPORTING_REQUESTS = 7; // 8 attempts -> 2 mins (each req 15 sec)
const TOAST_AUTO_CLOSE_TIME = 8000;

export const updateReportType = createActionCreator<string>(ReportsActionTypes.UPDATE_REPORT_TYPE, "reportType");

export const updateDateRange = createActionCreator<Date | undefined, Date | undefined>(
  ReportsActionTypes.UPDATE_DATE_RANGE,
  "dateStart",
  "dateEnd"
);

export const updateLogisticsSku = createActionCreator<string | undefined>(
  ReportsActionTypes.UPDATE_LOGISTICS_SKU,
  "logisticsSku"
);

export const updateSelectedProduct = createActionCreator<SelectedProductData | undefined>(
  ReportsActionTypes.UPDATE_SELECTED_PRODUCT,
  "selectedProduct"
);

const getNewReportIds = (reportsInState: Report[], fetchedReports: Report[]): string[] =>
  difference(
    fetchedReports.map(({ id }) => id),
    reportsInState.map(({ id }) => id)
  );

export const getReports =
  (intervalId?: number): SPThunkAction =>
  async (dispatch, getState) => {
    const ctx = { fn: "getReports" };
    log.info(ctx, "getting reports");
    const {
      user: { sellerId },
      reports: { reports: reportsInState },
    } = getState();

    try {
      const { types: reportTypes, reports: fetchedReports } = await reportingClient.getReports(sellerId);

      // possible to retrieve reports where the csv extension has not been applied yet, which can cause bugs
      const validFetchedReports = fetchedReports.filter((report) => report.name.includes(".csv"));
      const sortedFetchedReports = sortBy("createdAt", validFetchedReports).reverse();
      const newReportIds = getNewReportIds(reportsInState, sortedFetchedReports);
      const hasNewReports = newReportIds.length > 0;

      const areMaxAttemptsMade = GET_REPORTS_ATTEMPTS === MAX_REPORTING_REQUESTS;
      const shouldClearInterval = (areMaxAttemptsMade && !hasNewReports) || hasNewReports;

      // reporting service may not have report ready on first attempt, so we check check at least x times before showing error
      if (intervalId) {
        if (areMaxAttemptsMade && !hasNewReports) {
          toast.dismiss();
          notifyUserOfError({
            explanation: "The report you created is empty. Please run a different report or try again later.",
            toastId: "emptyReportsError",
          });
          GET_REPORTS_ATTEMPTS = 0;
          clearInterval(intervalId);
          log.info({ ...ctx, sellerId }, "Empty report generated");
          dispatch({ type: ReportsActionTypes.CLEAR_EMPTY_REPORT });
          return;
        }

        if (shouldClearInterval) {
          clearInterval(intervalId);
        } else {
          GET_REPORTS_ATTEMPTS++;
          return;
        }
      }

      const products = await productsForReports(sortedFetchedReports);

      const preparedReports: Report[] = sortedFetchedReports.map((report) => {
        const productName =
          report.logisticsSku && products[report.logisticsSku] ? products[report.logisticsSku].name : undefined;
        return { ...report, productName, pending: false };
      });

      dispatch({
        reports: preparedReports,
        reportTypes: reportTypes.sort(),
        type: ReportsActionTypes.GET_REPORTS,
        newReportIds,
      });

      toast.dismiss();
      return;
    } catch (err) {
      log.error({ ...ctx, err }, "error getting reports");
      notifyUserOfError({
        err,
        explanation: "Oops. There was a problem getting your reports.",
        toastId: "getReportsError",
      });
    }
  };

export const downloadReport =
  (reportName: string): SPThunkAction =>
  async (dispatch, getState) => {
    const ctx = { fn: "downloadReport" };
    log.info(ctx, "downloading report");
    const {
      user: { sellerId },
    } = getState();

    try {
      trackDownloadReport(sellerId, reportName);
      dispatch(addLoader(reportName));
      const url = await reportingClient.getReportUrl(sellerId, reportName);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      delayBy(1000); // provides feedback to seller since request resolves quickly
      initiateDownloadFromUrl(url, reportName);
    } catch (err) {
      log.error({ ...ctx, err }, "error getting report url");
      notifyUserOfError({
        err,
        explanation: "Oops. We were unable to retrieve that report.",
        toastId: "getReportUrlError",
      });
    } finally {
      dispatch(clearLoader(reportName));
    }
  };

const showGeneratingReportToast = () => {
  toast.info("We're creating your report now. It may take a few minutes to complete.", {
    toastId: "creatingReportInfo",
    autoClose: TOAST_AUTO_CLOSE_TIME,
  });
};

const trackGenerateReport = (
  sellerId: string,
  reportType?: SellerReportType,
  startDate?: Date,
  endDate?: Date,
  logisticsSku?: string
) => {
  trackHeapEvent<ReportsHeapEvent>("reports.generateReport", {
    reportType: reportType!.toString(),
    sellerId,
    startDate: startDate!.toISOString(),
    endDate: endDate!.toISOString(),
    logisticsSku: logisticsSku!,
  });
};

const trackDownloadReport = (sellerId: string, reportName: string) => {
  trackHeapEvent<ReportsHeapEvent>("reports.downloadReport", {
    reportName,
    sellerId,
  });
};

export const generateAndDownloadReport = (): SPThunkAction => async (dispatch, getState) => {
  const {
    reports: { reportType, dateStart, dateEnd, logisticsSku },
    user: { sellerId },
  } = getState();
  const ctx = { fn: "generateAndDownloadReport", reportType, dateStart, dateEnd, logisticsSku };
  log.info(ctx, "generateAndDownloadReport");

  trackGenerateReport(sellerId, reportType, dateStart, dateEnd, logisticsSku);
  showGeneratingReportToast();

  try {
    const result = await reportingClient.generateReport(sellerId, reportType!, dateStart!, dateEnd!, logisticsSku);
    const hasReportData = result.response.results!.rows[0].rows_unloaded !== 0;
    if (hasReportData) {
      const url = await reportingClient.getReportUrl(sellerId, result.response.reportName);
      initiateDownloadFromUrl(url, result.response.reportName);
    } else {
      toast.dismiss();
      notifyUserOfError({
        explanation: "The report you created is empty.",
        toastId: "emptyReportsError",
      });
      log.info({ ...ctx, sellerId }, "Empty report generated");
    }
  } catch (err) {
    log.error({ ...ctx, ...err }, "error generating report");
  }
};

export const generateReport = (): SPThunkAction => async (dispatch, getState) => {
  const {
    reports: { reportType, dateStart, dateEnd, logisticsSku, selectedProduct },
    user: { sellerId },
  } = getState();

  // This makes sure that we send the correct date to the server
  const fixDate = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    return new Date(Date.UTC(year, month, day));
  };

  const shouldFixDates = isReportWithSingleDateSelect(reportType);
  const actualDateStart = shouldFixDates ? fixDate(dateStart!) : dateStart!;
  const actualDateEnd = shouldFixDates ? fixDate(dateEnd!) : dateEnd!;

  const ctx = { fn: "generateReport", reportType, dateStart, dateEnd, logisticsSku };
  log.info(ctx, "generatingReport");

  trackGenerateReport(sellerId, reportType, dateStart, dateEnd, logisticsSku);
  showGeneratingReportToast();

  try {
    /*
    this request can take up to minutes to resolve - since we do nothing with its response
    we just call it, then use the getReports dispatch on intervals to keep checking for the result
    */
    // eslint-disable-next-line no-void
    void reportingClient.generateReport(sellerId, reportType!, actualDateStart!, actualDateEnd!, logisticsSku);
  } catch (err) {
    log.error({ ...ctx, ...err }, "error generating report");
  }

  dispatch({ type: ReportsActionTypes.CLEAR_REPORT_FIELDS });
  dispatch({
    type: ReportsActionTypes.GENERATE_REPORT,
    report: {
      type: reportType,
      dateStart,
      dateEnd,
      pending: true,
      logisticsSku,
      productName: selectedProduct?.raw.name,
    },
  });

  const intervalId = setInterval(() => dispatch(getReports(intervalId)), GET_REPORTS_INTERVAL);
};
