import { getReturn, resetReturn } from "./store/ReturnsDetailActions";
import { useDispatch, useSelector } from "react-redux";
import { useMount, useUnmount } from "react-use";

import { RootState } from "RootReducer";
import { useParams } from "react-router-dom";

export const useReturnsDetail = () => {
  const dispatch = useDispatch();
  const { returnId, orderId } = useParams<{ returnId: string; orderId: string }>();
  const {
    returnsDetail,
    returnItemDetails,
    isLoading,
    returnsQcDetails: { returnsQcItems },
  } = useSelector((state: RootState) => state.returnsDetail);
  const areQcItemsPresent = returnsQcItems.length > 0;

  useMount(() => {
    dispatch(getReturn(returnId, orderId));
  });

  useUnmount(() => {
    dispatch(resetReturn());
  });

  return {
    returnsDetail,
    returnItemDetails,
    isLoading,
    returnId,
    orderId,
    returnsQcItems,
    areQcItemsPresent,
  };
};
