import styled from "@emotion/styled";
import React from "react";
import { noop } from "lodash/fp";
import { useBillingSummary } from "billing/BillingSummary/useBillingSummary";
import { LocalizedText } from "common/components/LocalizedText";
import { Anchor, Box, IconV2, Stack, ThemeProps } from "common/components/ui";
import { useInboundBillingSetup } from "inbounds/hooks/useInboundBillingSetup";
import { ProcessingFeeInstruction } from "./ProcessingFeeInstruction";
import { PrimaryPaymentSource } from "./PrimaryPaymentSource";
import { ExternalLink } from "common/components/ExternalLink";
import { FormattedMessage } from "react-intl";
import { FLEXPORT_CLIENT_APP_BILLING_URL } from "inbounds/createShipment/steps/priceViewStep/chargesSummary/constants";

const PaymentMethodAnchor = styled(Anchor)`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const StyledLink = styled(ExternalLink)<ThemeProps>(
  ({ theme }) => `
  color: inherit;
  text-decoration: underline;
  font-weight: ${theme.font.weight.BOLD};
`
);

const IntlPaymentMessage = () => (
  <LocalizedText
    message={{
      id: "shipment.shippingSelection.payment.paymentMethod.instruction.intl",
      defaultMessage: "When your freight arrives, charges will appear within your Flexport Platform {here}.",
    }}
    values={{
      here: (
        <StyledLink href={FLEXPORT_CLIENT_APP_BILLING_URL}>
          <FormattedMessage id="inbounds.createShipment.chargesSummary.here" defaultMessage="here" />
        </StyledLink>
      ),
    }}
  />
);

const DomesticPaymentMessage = () => (
  <LocalizedText
    message={{
      id: "shipment.shippingSelection.payment.paymentMethod.instruction.domestic",
      defaultMessage:
        "Once you confirm your booking, charges won't appear on your invoice until your freight arrives at a Flexport facility.",
    }}
  />
);

export const PaymentMethod = ({ isIntlFlow }: { isIntlFlow?: boolean }) => {
  const { updatePaymentMethod } = useBillingSummary();
  // handle chargebee and billing initialization if necessary
  const { checkoutButtonKey, checkoutButtonProps } = useInboundBillingSetup(noop);

  return (
    <Stack gap="S5">
      <Box marginBottom={"S3"}>
        <Stack gap="S3">
          <ProcessingFeeInstruction />
          <LocalizedText
            size="bodyLarge"
            bold
            message={{
              id: "shipment.shippingSelection.payment.paymentMethod.subtitle",
              defaultMessage: "Secure payment method",
            }}
          />
          <LocalizedText
            message={{
              id: "shipment.shippingSelection.payment.paymentMethod.instruction",
              defaultMessage: "We need a valid payment method on file to book your shipment. {message}",
              values: {
                message: isIntlFlow ? <IntlPaymentMessage /> : <DomesticPaymentMessage />,
              },
            }}
          />
        </Stack>
      </Box>
      {checkoutButtonProps ? (
        <PaymentMethodAnchor
          key={checkoutButtonKey}
          data-cb-type={checkoutButtonProps["data-cb-type"]}
          data-cb-plan-id={checkoutButtonProps["data-cb-plan-id"]}
        >
          <IconV2 type="plus" size="small" />
          <LocalizedText
            bold
            message={{
              id: "shipment.shippingSelection.payment.paymentMethod.addNewPaymentMethod",
              defaultMessage: "Add a new payment method",
            }}
          />
        </PaymentMethodAnchor>
      ) : (
        <Stack gap="S3">
          <PrimaryPaymentSource />
          <PaymentMethodAnchor key={checkoutButtonKey} onClick={updatePaymentMethod}>
            <LocalizedText
              bold
              message={{
                id: "shipment.shippingSelection.payment.paymentMethod.managePaymentMethod",
                defaultMessage: "Manage payment methods",
              }}
            />
          </PaymentMethodAnchor>
        </Stack>
      )}
    </Stack>
  );
};
