import {
  DistributionChannel,
  OrderEstimateRequest,
  OrderEstimateResponse,
  OutboundChargeType,
  OutboundEntity,
} from "@deliverr/replenishment-client";
import { logError } from "Logger";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { WholesaleErrorType } from "common/wholesale-common/utils/WholesaleErrorTypes";
import { chain, sumBy } from "lodash";
import { batch } from "react-redux";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import {
  transferCreateSetError,
  transferCreateSetReplenishmentOrderQuotes,
  transferCreateSetReplenishmentError,
} from "../../TransferCreateActions";
import { ChargeRow } from "./ChargeRow";
import { feeDescriptionMessages } from "./feeDescriptionMessages";
import { replenishmentClient } from "Clients";
import { SPThunkAction } from "common/ReduxUtils";
import { PREP_TYPE_LABELS } from "prep/labels";

export const getShippingQuotes =
  (
    quoteRequest: OrderEstimateRequest,
    orderId: string,
    distributionChannel: DistributionChannel | undefined
  ): SPThunkAction<Promise<boolean>> =>
  async (dispatch, getState) => {
    batch(() => {
      dispatch(addLoader(CreateTransferLoader.CreateTransferOrderLoader));
      dispatch(transferCreateSetError(WholesaleErrorType.NONE));
      dispatch(transferCreateSetReplenishmentError({ errors: [] }));
    });
    try {
      const res: OrderEstimateResponse = (await replenishmentClient.getOrderEstimatesV2(orderId, quoteRequest)).value;

      let chargeRows: ChargeRow[] = chain(res.shipmentEstimates)
        .flatMap((quotes) => quotes.chargeBreakdown)
        .groupBy((breakdown) => breakdown.chargeType)
        .map((val, key) => {
          const isShippingFee = (val[0].chargeType as any) === "SHIPPING_FEE";
          const totalCost = sumBy(val, (chargesRecord) => chargesRecord.totalCost);
          const row: ChargeRow = {
            feeDescription: feeDescriptionMessages[key],
            chargeType: val[0].chargeType,
            unitCost: isShippingFee ? totalCost : val[0].unitCost,
            unitOfMeasure: isShippingFee ? OutboundEntity.ORDER : val[0].unitOfMeasure,
            unitCount: isShippingFee ? 1 : sumBy(val, (chargesRecord) => chargesRecord.unitCount),
            totalCost,
          };

          return row ?? [];
        })
        .value();

      // New chargeRows based on breakdown of prep charges
      const prepChargeRows = chain(res.shipmentEstimates)
        .flatMap((quotes) => quotes.chargeBreakdown)
        .flatMap((breakdown) => breakdown.prepChargeBreakdown ?? [])
        .groupBy((prepBreakdown) => prepBreakdown.estimatesItemType)
        .map((val, key) => {
          const row: ChargeRow = {
            feeDescription: PREP_TYPE_LABELS[key],
            chargeType: val[0].estimatesItemType,
            totalCost: sumBy(val, (chargesRecord) => chargesRecord.totalCost),
            unitCount: sumBy(val, (chargesRecord) => chargesRecord.count),
            unitCost: val[0].unitCost ?? 0,
            unitOfMeasure: val[0].unitOfMeasure,
          };
          return row ?? [];
        })
        .value();

      if (distributionChannel === DistributionChannel.ECOM_VIA_CROSSDOCK) {
        chargeRows = chargeRows.filter((charge) => charge.chargeType !== OutboundChargeType.PREP_FEE);
      }

      const charges = [
        ...(distributionChannel === DistributionChannel.ECOM_VIA_CROSSDOCK ? prepChargeRows : []),
        ...chargeRows,
      ];

      dispatch(transferCreateSetReplenishmentOrderQuotes({ charges }));
      dispatch(clearLoader(CreateTransferLoader.CreateTransferOrderLoader));
      return true;
    } catch (err) {
      batch(() => {
        dispatch(transferCreateSetError(WholesaleErrorType.UNTYPED));
      });
      dispatch(clearLoader(CreateTransferLoader.CreateTransferOrderLoader));
      logError({ fn: "getShippingQuotes" }, err);
      return false;
    }
  };
