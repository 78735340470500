import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Stack, Title, DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import { useReturnsCancel } from "./useReturnsCancel";

const StyledButton = styled(Button)`
  width: 45%;
`;
const StyledButtonStack = styled(Stack)`
  width: 100%;
  justify-content: space-between;
`;
const StyledModalParagraph = styled.p<{}, DefaultTheme>(
  ({ theme }) => `
    margin-left: ${theme.spacing.S3};
  `
);

export const ReturnsCancel: FC = () => {
  const { showReturnsCancelModal, showModal, handleCancel, hideReturnsCancelModal, disableCancel } = useReturnsCancel();
  return !disableCancel ? (
    <>
      <Button
        data-testid="returns-cancel-button"
        secondary
        appearance="DANGER"
        onClick={showModal}
        disabled={disableCancel}
      >
        <FormattedMessage id={"returns.returnsDetail.returnsCancel.cancelButton"} defaultMessage={"Cancel return"} />
      </Button>

      <Modal show={showReturnsCancelModal} onClose={hideReturnsCancelModal}>
        <Stack center gap="S5" data-testid="returns-cancel-modal">
          <Title as="h3" displayAs="h3">
            <FormattedMessage id={"returns.returnsDetail.returnsCancel.modalTitle"} defaultMessage={"Cancel Return"} />
          </Title>
          <StyledModalParagraph>
            <FormattedMessage
              id={"returns.returnsDetail.returnsCancel.modalText"}
              defaultMessage={"Are you sure you want to cancel this return? This action cannot be undone."}
            />
          </StyledModalParagraph>
          <StyledButtonStack direction="HORIZONTAL">
            <StyledButton secondary onClick={hideReturnsCancelModal} data-testid="returns-cancel-modal-close-button">
              <FormattedMessage
                id={"returns.returnsDetail.returnsCancel.modalKeepButton"}
                defaultMessage={"Keep return"}
              />
            </StyledButton>
            <StyledButton appearance="DANGER" onClick={handleCancel} data-testid="returns-cancel-modal-cancel-button">
              <FormattedMessage
                id={"returns.returnsDetail.returnsCancel.modalCancelButton"}
                defaultMessage={"Cancel return"}
              />
            </StyledButton>
          </StyledButtonStack>
        </Stack>
      </Modal>
    </>
  ) : null;
};
