import * as React from "react";

import { DefaultTheme, ThemeProps, defaultTheme } from "../shared";

import { FocusOn } from "react-focus-on";
import { Icon } from "../Icon";
import { IconSizes } from "../IconV2";
import { createPortal } from "react-dom";
import styled from "@emotion/styled";
import { useModal } from "./useModal";

const CloseIconSizeWidth = {
  [IconSizes.small]: "12px",
  [IconSizes.medium]: "16px",
};

const CloseButtonPadding = {
  [IconSizes.small]: "10px",
  [IconSizes.medium]: "20px",
};

export interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  show?: boolean;
  hasCloseButton?: boolean;
  onClose?: () => void;
  width?: string;
  borderRadius?: string;
  ignoreOutsideClick?: boolean;
  closeIconSize?: IconSizes.small | IconSizes.medium;
  displayOverflow?: boolean;
}

const ModalBackground = styled.div<ThemeProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: ${({ theme }) => theme.zindex.Z5};
`;

const ModalDialog = styled.div<{ displayOverflow?: boolean }, DefaultTheme>(
  ({ theme, displayOverflow }) => `
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: ${theme.zindex.Z5};
  max-width: 95%;
  max-height: 95%;
  ${displayOverflow ? "overflow-y: visible;" : "overflow-y: auto;"}
`
);

export const MODAL_BODY_PADDING = "30px";

const ModalBody = styled.div<{ width: string; borderRadius: string; displayOverflow?: boolean }, DefaultTheme>(
  ({ theme, width, borderRadius, displayOverflow }) => `
  position: relative;
  padding: ${MODAL_BODY_PADDING};
  background-color: ${theme.colors.NEUTRAL["00"]};
  border-radius: ${borderRadius};
  width: ${width};
  max-width: 100%;
  max-height: 95%;
  margin: 0 auto;
  ${displayOverflow ? "overflow: visible;" : "overflow: hidden;"}
`
);

const StyledIcon = styled(Icon)<{ closeIconSize: string }>(
  ({ closeIconSize }) => ` 
  font-size: ${CloseIconSizeWidth[closeIconSize]};
  width: ${CloseIconSizeWidth[closeIconSize]};
`
);

const StyledButton = styled.button<{ closeIconSize: string }, ThemeProps>(
  ({ closeIconSize }) => `
  cursor: pointer;
  position: absolute;
  right: ${CloseButtonPadding[closeIconSize]};
  top: ${CloseButtonPadding[closeIconSize]}};
  appearance: none;
  border: 0;
  background: none;
  :focus {
    outline: 0;
    box-shadow: ${({ theme }) => theme.input.focus.BLUE};
  }
`
);

const createModal = ({
  ignoreOutsideClick,
  onClose,
  hasCloseButton,
  children,
  width = "500px",
  borderRadius = defaultTheme.border.radius.R2,
  closeIconSize = IconSizes.small,
  displayOverflow = false,
}: Partial<ModalProps>) => (
  <div>
    <ModalBackground />
    <FocusOn onClickOutside={ignoreOutsideClick ? undefined : onClose} onEscapeKey={onClose}>
      <ModalDialog displayOverflow={displayOverflow}>
        <ModalBody role="dialog" width={width} borderRadius={borderRadius} displayOverflow={displayOverflow}>
          {hasCloseButton && (
            <StyledButton onClick={onClose} aria-label="Close" closeIconSize={closeIconSize}>
              <StyledIcon type="times" closeIconSize={closeIconSize} />
            </StyledButton>
          )}
          <div>{children}</div>
        </ModalBody>
      </ModalDialog>
    </FocusOn>
  </div>
);

export const Modal: React.FC<ModalProps> = ({
  show = true,
  onClose = () => null,
  hasCloseButton = true,
  children,
  width,
  borderRadius,
  ignoreOutsideClick,
  closeIconSize,
  displayOverflow,
}) => {
  const { showModal } = useModal(show);
  const portalElement = document.getElementById("modal-portal");
  return showModal
    ? portalElement
      ? createPortal(
          createModal({
            ignoreOutsideClick,
            onClose,
            hasCloseButton,
            children,
            width,
            borderRadius,
            closeIconSize,
            displayOverflow,
          }),
          document.getElementById("modal-portal")!
        )
      : createModal({ onClose, hasCloseButton, children, width, borderRadius, displayOverflow })
    : null;
};

export const ModalContainer: React.FC = () => <div id="modal-portal" />;
