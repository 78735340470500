import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isNil } from "lodash/fp";
import { toast } from "common/components/ui";
import { updateShipmentQuantity } from "inbounds/store/actions/shipment/updateShipmentQuantity";
import { getSellerId } from "common/user/UserSelectors";
import { getReceivingInfo } from "inbounds/steps/ship/view/ViewShipmentSelectors";
import { getLoadedShipmentId } from "inbounds/store/selectors/shipments/getLoadedShipmentId";
import { useIntl } from "react-intl";

export const useEditShipmentQuantity = (dsku: string) => {
  const shipmentId = useSelector(getLoadedShipmentId);
  const { items } = useSelector(getReceivingInfo);
  const { formatMessage } = useIntl();

  const { expectedQty } = useMemo(() => items.find((item) => item.dsku === dsku)!, [items, dsku]);
  const [quantity, setQuantity] = useState(expectedQty);
  const sellerId = useSelector(getSellerId);
  const dispatch = useDispatch();

  const handleInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(Number(value));
  };

  const submitEditShipmentQuantity = () => {
    if (!isNil(shipmentId) && !isNil(quantity) && quantity !== expectedQty) {
      try {
        dispatch(updateShipmentQuantity(sellerId, shipmentId, dsku, quantity));
      } catch (err) {
        toast.error(
          formatMessage({
            id: "inbounds.updateShipmentQty.error",
            defaultMessage: "Unexpected error updating quantity, please try again or contact support.",
          }),
          {
            toastId: "inbounds.updateShipmentQty.error",
          }
        );
      }
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitEditShipmentQuantity();
  };

  return {
    quantity,
    handleInputChange,
    handleSubmit,
  };
};
