import React from "react";
import { useSelector } from "react-redux";
import { getStorageInboundDetail } from "../../../store";
import { PalletPackingInstructions } from "./PalletPackingInstructions";
import { CasePackingInstructions } from "./CasePackingInstructions";
import { ShipmentType } from "@deliverr/storage-client";

export const PackingInstructions: React.FC = () => {
  const { storageInboundOrder } = useSelector(getStorageInboundDetail);

  return storageInboundOrder.shipmentType === ShipmentType.PALLETIZED ? (
    <PalletPackingInstructions />
  ) : (
    <CasePackingInstructions />
  );
};
