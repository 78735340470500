export enum TransferShipmentDetailActionTypes {
  TRANSFER_GET_DETAILS_SUCCESS_OLD = "TRANSFER_GET_DETAILS_SUCCESS_OLD",
  TRANSFER_GET_DETAILS_SUCCESS = "TRANSFER_GET_DETAILS_SUCCESS",
  TRANSFER_DETAILS_UPLOADING = "TRANSFER_DETAILS_UPLOADING",
  TRANSFER_DETAILS_CLEAR_ERROR = "TRANSFER_DETAILS_CLEAR_ERROR",
  TRANSFER_DETAILS_SET_ERROR = "TRANSFER_DETAILS_SET_ERROR",
  TRANSFER_REDIRECT_TO_INBOUND_ERROR = "TRANSFER_REDIRECT_TO_INBOUND_ERROR",
  TRANSFER_MUTATING = "TRANSFER_MUTATING",
  TRANSFER_MUTATING_CLEAR = "TRANSFER_MUTATING_CLEAR",
}
