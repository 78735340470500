import { PrepTaskResponse } from "@deliverr/prep-service-client";

/**
 * The Prep Service doesn't properly return dates as Date objects, so we need to transform them for all Date fields on the PrepTaskResponse.
 */
export const processPrepTaskResponse = (prepTaskResponse: PrepTaskResponse): PrepTaskResponse => {
  return {
    ...prepTaskResponse,
    updatedAt: new Date(prepTaskResponse.updatedAt),
    completedAt: prepTaskResponse.completedAt ? new Date(prepTaskResponse.completedAt) : undefined,
  };
};
