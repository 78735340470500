import * as React from "react";
import AsyncSelect from "react-select/async";
import { FormGroup, extractFormProps } from "../FormGroup";
import { DefaultTheme } from "../shared";
import {
  container,
  control,
  input,
  singleValue,
  option,
  menu,
  menuList,
  placeholder,
  indicatorSeparator,
  SelectProps,
} from "../Select/BaseSelect";
import { components, Props } from "react-select";
import { Icon, IconsProps } from "../Icon";
import styled from "@emotion/styled";
import { isMobile } from "../shared/helpers/utils";
import { useTheme } from "emotion-theming";

export const SelectComponentOption = components.Option;

const SearchIcon = styled(Icon)<IconsProps, DefaultTheme>(
  ({ theme }) => `
  margin-left: ${theme.spacing.S4};
  color: ${theme.colors.NEUTRAL[300]};
`
);

const ControlComponent = (props: any) => (
  <components.Control {...props}>
    <SearchIcon type="search" />
    {props.children}
  </components.Control>
);

const valueContainer = (theme, mobile) => (styles) => ({
  ...styles,
  padding: mobile
    ? `${theme.spacing.S3} ${theme.spacing.S4} ${theme.spacing.S3} ${theme.spacing.S2}`
    : `6px ${theme.spacing.S3} 6px ${theme.spacing.S2}`,
});

const dropdownIndicator = (styles) => ({
  ...styles,
  display: "none",
});

const selectStyles: (theme: DefaultTheme, hasError: boolean, flexWidth?: boolean) => Props["styles"] = (
  theme,
  hasError,
  flexWidth
) => {
  const mobile = isMobile(theme);
  return {
    container: container(flexWidth),
    control: control(theme, hasError, mobile),
    input: input(flexWidth),
    valueContainer: valueContainer(theme, mobile),
    singleValue: singleValue(theme, flexWidth),
    option: option(theme),
    menu: menu(theme, mobile),
    menuList: menuList(theme),
    placeholder: placeholder(theme),
    dropdownIndicator,
    indicatorSeparator,
  };
};

export const SelectAsync: React.FC<SelectProps> = (props) => {
  const theme = useTheme<DefaultTheme>();
  const [formProps, { flexWidth, ...selectProps }] = extractFormProps(props);
  const customComponents = props.customComponents || {};
  return (
    <FormGroup {...formProps} flexWidth={flexWidth}>
      <AsyncSelect
        components={{ Control: ControlComponent, ...customComponents }}
        styles={selectStyles(theme, formProps.hasError, flexWidth)}
        isClearable={true}
        {...selectProps}
      />
    </FormGroup>
  );
};
