import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";
import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import { ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";

const FooterStyledText = styled.div<{ marginBottom?: boolean; marginTop?: boolean } & ThemeProps>(
  ({ marginBottom, marginTop, theme }) => `
  font-weight: ${theme.font.weight.REGULAR};
  font-size: ${theme.font.size.F1};
  margin-bottom: ${marginBottom ? theme.spacing.S7 : 0};
  margin-top: ${marginTop ? theme.spacing.S7 : 0};
`
);

export const ReturnsRmtConnectHelp: FC<{ marginBottom?: boolean; marginTop?: boolean; articleId: ElevioArticleId }> = ({
  marginBottom,
  marginTop,
  articleId,
}) => {
  return (
    <FooterStyledText marginTop={marginTop} marginBottom={marginBottom}>
      <FormattedMessage
        id="returns.rmt.connect.needHelp"
        defaultMessage="Have questions? {getHelp}"
        values={{
          getHelp: (
            <ElevioBorderlessButton articleId={articleId}>
              <FormattedMessage id="returns.rmt.connect.needHelp" defaultMessage="Get help" />
            </ElevioBorderlessButton>
          ),
        }}
      />
    </FooterStyledText>
  );
};
