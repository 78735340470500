import { defineMessages, MessageDescriptor } from "react-intl";

export enum FreightQuoteSummaryErrorCodes {
  NO_PLAN_FOR_GIVEN_ID = "NO_PLAN_FOR_GIVEN_ID",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export const freightQuoteSummaryErrorMessages: Record<string, MessageDescriptor> = defineMessages({
  [FreightQuoteSummaryErrorCodes.NO_PLAN_FOR_GIVEN_ID]: {
    id: "freightQuote.freightQuoteSummary.quoteDoestNotExistError",
    defaultMessage: "Plan ID does not exist.",
  },
  [FreightQuoteSummaryErrorCodes.SYSTEM_ERROR]: {
    id: "freightQuote.freightQuoteSummary.systemError",
    defaultMessage: "The system is down and can’t get quotes right now. Try again later.",
  },
});
