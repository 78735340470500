import React from "react";
import { Box, Checkbox, defaultTheme, FormGroup, ThemeProps } from "common/components/ui";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { useController } from "react-hook-form";
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import { InfoIcon } from "common/components/icons";
import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";
import styled from "@emotion/styled";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";
import { DANGEROUS_GOODS_CHECKLIST } from "../useCargoDetailsStep";
import { DangerousGoodsRadioGroup } from "./DangerousGoodsRadioGroup";
import { LithiumBatteryPackagingChecklist } from "./LithiumBatteryPackagingChecklist";

const ChecklistContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: inline-grid;
  justify-content: start;
  gap: ${theme.spacing.S1};
`
);

const DANGEROUS_GOODS_INPUTS = [
  CreateShipmentInputName.DANGEROUS_GOODS_LITHIUM_BATTERIES,
  CreateShipmentInputName.DANGEROUS_GOODS_NON_LITHIUM_BATTERIES,
  CreateShipmentInputName.DANGEROUS_GOODS_OTHER,
  CreateShipmentInputName.DANGEROUS_GOODS_NONE,
];

const TooltipIcon = ({
  message,
  elevioLearnMoreButton,
}: {
  message: MessageDescriptor;
  elevioLearnMoreButton: JSX.Element;
}) => (
  <InfoIcon
    messageWidth={200}
    wrap
    placement={"top"}
    message={
      <FormattedMessage id={message.id} defaultMessage={message.defaultMessage} values={{ elevioLearnMoreButton }} />
    }
  />
);

const elevioLearnMoreButton = <ElevioLearnMoreButton articleId={ElevioArticleId.DangerousGoods} asLink />;

const DANGEROUS_GOODS_INPUT_LABELS = {
  [CreateShipmentInputName.DANGEROUS_GOODS_LITHIUM_BATTERIES]: defineMessages({
    label: {
      id: "inbounds.createShipment.dangerousGoods.lithiumBatteries",
      defaultMessage: "Lithium Batteries {tooltip}",
    },
    tooltip: {
      id: "inbounds.createShipment.dangerousGoods.lithiumBatteries.tooltip",
      defaultMessage:
        "Lithium ion (rechargeable) batteries and lithium metal (non-rechargeable) batteries. {elevioLearnMoreButton}",
    },
  }),
  [CreateShipmentInputName.DANGEROUS_GOODS_NON_LITHIUM_BATTERIES]: defineMessages({
    label: {
      id: "inbounds.createShipment.dangerousGoods.nonLitiumBatteries",
      defaultMessage: "Non-lithium Batteries {tooltip}",
    },
    tooltip: {
      id: "inbounds.createShipment.dangerousGoods.nonLitiumBatteries.tooltip",
      defaultMessage:
        "Batteries of other chemistry types than lithium,v such as alkaline (AA/AAA), nickel metal hydride, lead-acid, non-spillable, etc. {elevioLearnMoreButton}",
    },
  }),
  [CreateShipmentInputName.DANGEROUS_GOODS_OTHER]: defineMessages({
    label: {
      id: "inbounds.createShipment.dangerousGoods.other",
      defaultMessage: "Other Hazardous Materials {tooltip}",
    },
    tooltip: {
      id: "inbounds.createShipment.dangerousGoods.other.tooltip",
      defaultMessage:
        "Other hazardous material include magnetized material, dry ice, vehicles, explosives, gases, aerosols, flammable materials, peroxides, oxidizers, toxic substances, radioactive materials and acid. {elevioLearnMoreButton}",
    },
  }),
  [CreateShipmentInputName.DANGEROUS_GOODS_NONE]: defineMessages({
    label: {
      id: "inbounds.createShipment.dangerousGoods.noDangerousGoods",
      defaultMessage: "No, my products do not contain hazardous materials",
    },
    tooltip: null,
  }),
};

const CheckboxItem = ({ name }: { name: CreateShipmentInputName }) => {
  const { setValue, watch } = useCreateShipmentFormContext();
  const { field } = useController({ name });
  const { label, tooltip } = DANGEROUS_GOODS_INPUT_LABELS[name];
  const isLithiumBatteries = name === CreateShipmentInputName.DANGEROUS_GOODS_LITHIUM_BATTERIES ? watch(name) : false;
  const handleNoDangerousGoodsChange = () => {
    setValue(CreateShipmentInputName.DANGEROUS_GOODS_LITHIUM_BATTERIES, false);
    setValue(CreateShipmentInputName.DANGEROUS_GOODS_NON_LITHIUM_BATTERIES, false);
    setValue(CreateShipmentInputName.DANGEROUS_GOODS_OTHER, false);
    setValue(CreateShipmentInputName.HAS_DANGEROUS_GOODS, null);
    field.onChange(!field.value);
  };

  const handleDangerousGoodsChange = () => {
    setValue(CreateShipmentInputName.DANGEROUS_GOODS_NONE, false);
    field.onChange(!field.value);
  };

  return (
    <>
      <Checkbox
        label={
          tooltip ? (
            <FormattedMessage
              id={label.id}
              defaultMessage={label.defaultMessage}
              values={{ tooltip: <TooltipIcon message={tooltip} elevioLearnMoreButton={elevioLearnMoreButton} /> }}
            />
          ) : (
            <FormattedMessage id={label.id} defaultMessage={label.defaultMessage} />
          )
        }
        labelStyles={`color: ${defaultTheme.colors.NEUTRAL["500"]}`}
        checked={field.value}
        onChange={
          name === CreateShipmentInputName.DANGEROUS_GOODS_NONE
            ? handleNoDangerousGoodsChange
            : handleDangerousGoodsChange
        }
      />
      {name === CreateShipmentInputName.DANGEROUS_GOODS_LITHIUM_BATTERIES && isLithiumBatteries && (
        <Box paddingTop="S1" paddingLeft="S5" paddingBottom="S2">
          <LithiumBatteryPackagingChecklist />
        </Box>
      )}
    </>
  );
};

export const DangerousGoodsChecklist = () => {
  const { formatMessage } = useIntl();
  const { watch } = useCreateShipmentFormContext();
  // checking the validation with watch allows us to hide the error
  // the validation that sets and clears the error from state only runs on Next
  const dangerousGoodsValues = watch("dangerousGoods");
  const getHasSelections = (value: Record<string, boolean>) => Object.values(value).some((v) => v);

  const validateHasSelections = () => {
    return !getHasSelections(dangerousGoodsValues)
      ? formatMessage({
          id: "inbounds.createShipment.dangerousGoods.selectionRequired",
          defaultMessage: "Please confirm what kind of materials your shipment contains.",
        })
      : undefined;
  };

  // we use a general input for the checklist to validate on Next click whether any of the checkboxes are selected
  const { fieldState } = useController({
    name: DANGEROUS_GOODS_CHECKLIST,
    rules: {
      validate: {
        required: validateHasSelections,
      },
    },
  });

  const hasSelections = getHasSelections(dangerousGoodsValues);
  const shouldShowRadioGroup = hasSelections && !dangerousGoodsValues.hasNoDangerousGoods;

  return (
    <FormGroup
      as="div"
      label={formatMessage({
        id: "inbounds.createShipment.dangerousGoods.checklistLabel",
        defaultMessage: "Do your products contain any of the following?",
      })}
      hasError={fieldState.error && !hasSelections}
      errorMessage={fieldState.error?.message}
    >
      <ChecklistContainer>
        {DANGEROUS_GOODS_INPUTS.map((inputName) => (
          <CheckboxItem key={inputName} name={inputName} />
        ))}
      </ChecklistContainer>
      {shouldShowRadioGroup && (
        <Box marginTop="S4">
          <DangerousGoodsRadioGroup />
        </Box>
      )}
    </FormGroup>
  );
};
