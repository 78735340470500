import { Text } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { transfersShipmentCreateLabels } from "../transfersShipmentCreate.labels";
import { TextAlign } from "common/components/ui/shared";
import { StyledAnchor } from "../../ui/StyledAnchor";

export const ManualFbaMessage: FC<{ onManualClick: () => void; align: TextAlign }> = ({ onManualClick, align }) => {
  return (
    <Text size="body" appearance="INFO" as="div" textAlign={align}>
      <FormattedMessage
        {...transfersShipmentCreateLabels.steps.destination.options.fbaIntegration.notRecommended}
        values={{
          manualFbaStepLink: (text) => (
            <StyledAnchor data-testid="manual-fba-message" onClick={onManualClick}>
              {text}
            </StyledAnchor>
          ),
        }}
      />
    </Text>
  );
};
