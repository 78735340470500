import React from "react";
import ReactDropzone from "react-dropzone";
import { containsNonNullValues, DefaultTheme } from "../shared";
import { useTheme } from "emotion-theming";
import { isMobile } from "../shared/helpers/utils";
import { DropzoneView } from "./DropzoneView";
import { Stack } from "../Stack";
import { DropzoneMessaging } from "./DropzoneMessaging";
import { flatten } from "lodash";
import { ErrorContainer, HelpText, StyledErrorIcon } from "../FormGroup";
import { Text } from "../Text";
import {
  Container,
  DragDropArea,
  DropzoneLabel,
  DropzoneLoading,
  DropzoneProps,
  FILE_TYPE_OPTIONS,
  FullWidthContainer,
  getFileTypes,
  LoadingContainer,
} from "./DropzoneUtils";

export const Dropzone: React.FC<DropzoneProps> = ({
  id,
  isMandatory = false,
  acceptedFileTypes,
  loading,
  onChange,
  onFileClear,
  label,
  helpText,
  value,
  progressPercent,
  dropzoneProps,
  errorText,
  fullWidth,
}) => {
  const theme = useTheme<DefaultTheme>();
  const mobile = isMobile(theme);

  if (value && containsNonNullValues(value)) {
    return (
      <DropzoneView
        isMandatory={isMandatory}
        label={label}
        helpText={helpText}
        loading={loading}
        onFileClear={onFileClear}
        value={value}
        progressPercent={progressPercent}
        fullWidth={fullWidth}
      />
    );
  }

  const Wrapper = fullWidth ? FullWidthContainer : Container;

  return (
    <Stack gap="S2">
      {label && (
        <DropzoneLabel htmlFor={id}>
          {label}
          {isMandatory && (
            <Text as="span" appearance="DANGER">
              &nbsp; *
            </Text>
          )}
        </DropzoneLabel>
      )}
      <Wrapper data-testid="dropzone-container">
        {loading ? (
          <LoadingContainer>
            <DropzoneLoading />
          </LoadingContainer>
        ) : (
          <ReactDropzone onDrop={onChange} accept={getFileTypes(acceptedFileTypes)} {...dropzoneProps}>
            {({ getRootProps, getInputProps, isDragActive, fileRejections }) => (
              <DragDropArea {...getRootProps()} isActive={isDragActive} hasError={fileRejections.length > 0}>
                <input {...getInputProps()} id={id} />
                <DropzoneMessaging
                  expectedFileTypes={flatten(acceptedFileTypes.map((type) => FILE_TYPE_OPTIONS[type]))}
                  hasErrors={fileRejections.length > 0}
                  isActive={isDragActive}
                  isMobile={mobile}
                />
              </DragDropArea>
            )}
          </ReactDropzone>
        )}
      </Wrapper>
      {helpText && <HelpText>{helpText}</HelpText>}
      {errorText && (
        <ErrorContainer>
          <StyledErrorIcon type="exclamation-circle" /> {errorText}
        </ErrorContainer>
      )}
    </Stack>
  );
};
