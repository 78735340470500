import { useDispatch, useSelector } from "react-redux";
import { FacebookEditAction } from "../../FacebookEditAction";

import { facebookEditSelectedAction } from "../../state/facebookEditSelectedAction";
import { selectAction } from "../../state/selectAction";

export const useSelectAction = () => {
  const dispatch = useDispatch();
  const selectedAction = useSelector(facebookEditSelectedAction);

  const onSelectAction = (action: FacebookEditAction) => () => {
    dispatch(selectAction(action));
  };

  return {
    selectedAction,
    onSelectAction,
  };
};
