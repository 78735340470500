import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { CrossdockInboundQuote } from "./CrossdockInboundQuote/CrossdockInboundQuote";
import { CrossdockInboundQuoteRequest } from "./CrossdockInboundQuote/CrossdockInboundQuoteRequest";
import { CrossdockInboundShipment } from "./CrossdockInboundShipment/CrossdockInboundShipment";

export type CrossdockClientConfig = ApiClientConfig;

export class CrossdockClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: CrossdockClientConfig = { baseURL: mustBeDefined(process.env.CROSSDOCK_SERVICE_URL) }) {
    this.apiClient = new ApiClient({
      baseURL,
    });
  }

  async createInboundQuote({
    sellerId,
    shippingPlanId,
    ...body
  }: CrossdockInboundQuoteRequest): Promise<CrossdockInboundQuote> {
    return await this.apiClient.post({
      url: `/v1/inbound/quote/${sellerId}/${shippingPlanId}`,
      body,
      authentication: isAuthenticated,
    });
  }

  async getActiveInboundQuoteByShippingPlan(sellerId: string, shippingPlanId: number): Promise<CrossdockInboundQuote> {
    return await this.apiClient.get({
      url: `/v1/inbound/quote/${sellerId}/plan/${shippingPlanId}`,
      authentication: isAuthenticated,
    });
  }

  async getInboundShipmentByShippingPlan(
    sellerId: string,
    shippingPlanId: number
  ): Promise<CrossdockInboundShipment | undefined> {
    return await this.apiClient.get({
      url: `/v1/inbound/shipment/${sellerId}/plan/${shippingPlanId}`,
      authentication: isAuthenticated,
    });
  }
}
