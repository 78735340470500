import { FtlQuoteRequest, TruckCountBasedServiceRequest } from "@deliverr/freight-client";
import { FreightQuoteRequestOptions } from "../store";

// Method required during transition period from transportationClient to freightClient
export const makeQuoteRequest = (
  freightQuoteRequestOptions: FreightQuoteRequestOptions,
  createdBy: string
): FtlQuoteRequest => {
  const { numberOfTrucks, plan, internalUserId, ...quoteRequestOptions } = freightQuoteRequestOptions;

  const serviceRequest: TruckCountBasedServiceRequest[] = plan.map(({ service }) => ({
    type: service,
    numberOfTrucks,
  }));

  return {
    ...quoteRequestOptions,
    serviceRequest,
    createdBy,
  };
};
