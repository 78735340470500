import { Icon } from "common/components/ui";
import cx from "classnames";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { useFacebookLoginButton } from "./useFacebookLoginButton";

import cls from "./FacebookLoginButton.less";

export const FacebookLoginButton: FC = () => {
  const { isLoggedIn, onLoginClick, onReconnectClick } = useFacebookLoginButton();

  switch (isLoggedIn) {
    case false:
      return (
        <button name="Facebook.ConnectFacebookButton" className={cls.loginButton} onClick={onLoginClick}>
          <FormattedMessage id="Facebook.ConnectFacebook" defaultMessage={"Connect Facebook"} />
        </button>
      );
    default:
      return (
        <button
          name="Facebook.ReconnectFacebookButton"
          className={cx(cls.loginButton, cls.loggedIn)}
          onClick={onReconnectClick}
        >
          <Icon type="sync-alt" />
          <FormattedMessage id="Facebook.ReconnectFacebook" defaultMessage={"Reconnect Facebook"} />
        </button>
      );
  }
};
