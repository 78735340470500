import React from "react";
import { removeBarcodesDraft } from "barcodes/BarcodeDraft";
import { FormattedMessage } from "react-intl";
import { Box, Grid, Stack, ThemeProps } from "../../ui";
import { DtcSingleSkuSelectedProductRow } from "./DtcSingleSkuSelectedProductRow";
import styled from "@emotion/styled";
import { SelectedProductsListProps } from "../SelectedProductList";
import { SingleSkuPackageCollection } from "inbounds/createShipment/CreateShipmentTypes";
import { SelectedProductData } from "../SelectedProductData";

const Divider = styled.hr<ThemeProps>(
  ({ theme }) => `
    border-width: ${theme.border.width.B1} 0 0 0;
    border-color: ${theme.colors.NEUTRAL[60]};
    margin: 0 0 0 0;
    width: 100%;
  `
);

const StyledBox = styled(Box)`
  font-size: 12px;
  padding: 0 12px 4px 12px;
`;

const StyledGrid = styled(Grid)`
  width: 100%;
  margin-top: 24px;
  text-align: left;
`;

export const DtcSingleSkuSelectedProductList: React.FC<
  SelectedProductsListProps & {
    singleSkuPackages?: SingleSkuPackageCollection;
    selectedProductsByDsku: {
      [dsku: string]: SelectedProductData;
    };
  }
> = ({
  useCasePack,
  disabled,
  hasMaxValue,
  invalidDskus,
  removeProduct,
  checkForInvalidProducts,
  isOrder,
  showCasePackSelectionDropdown,
  onNewCasePackFormSubmit,
  shouldHideCasepackSingleUnitWarning,
  shouldShowBrandedPackagingAlert,
  singleSkuPackages,
  selectedProductsByDsku,
}) => {
  const onRemoveItemClick = async ({ currentTarget }: React.MouseEvent<HTMLDivElement>) => {
    const sku = currentTarget.dataset.sku;

    if (!sku) {
      return;
    }

    if (!disabled) {
      removeBarcodesDraft(sku);
      removeProduct(sku);
    }
    if (isOrder) {
      await checkForInvalidProducts!(sku);
    }
  };

  return (
    <Stack>
      {singleSkuPackages && Object.keys(singleSkuPackages).length > 0 ? (
        <>
          <StyledGrid columns="2fr 3fr" fullWidth>
            <StyledBox>
              <FormattedMessage id="skus" defaultMessage="Product" />
            </StyledBox>
            <Box>
              <Grid columns="5fr 2fr 1fr 1fr">
                <StyledBox>
                  <FormattedMessage id="inbounds.productChooser.casePackDetails" defaultMessage="Packing details" />
                </StyledBox>
                <StyledBox marginLeft="S3">
                  <FormattedMessage id="inbounds.productChooser.numOfCases" defaultMessage="Boxes" />
                </StyledBox>
                <StyledBox>
                  <FormattedMessage id="units" defaultMessage="Units" />
                </StyledBox>
                <StyledBox></StyledBox>
              </Grid>
            </Box>
          </StyledGrid>
          <Divider />
          {Object.keys(singleSkuPackages).map((productDsku: string, index) => {
            if (!selectedProductsByDsku[productDsku]) {
              return null;
            }
            return (
              <Box margin={null} key={productDsku}>
                <DtcSingleSkuSelectedProductRow
                  productIndex={index}
                  product={selectedProductsByDsku[productDsku]}
                  disabled={disabled}
                  hasMaxValue={hasMaxValue}
                  invalidDskus={invalidDskus}
                  onRemoveItemClick={onRemoveItemClick}
                  useCasePack={!!useCasePack}
                  showCasePackSelectionDropdown={showCasePackSelectionDropdown}
                  onNewCasePackFormSubmit={onNewCasePackFormSubmit}
                  shouldHideCasepackSingleUnitWarning={shouldHideCasepackSingleUnitWarning}
                  shouldShowBrandedPackagingAlert={shouldShowBrandedPackagingAlert}
                  singleSkuPackage={singleSkuPackages?.[productDsku]}
                  isLastRow={index === Object.keys(singleSkuPackages).length - 1}
                />
                {index < Object.keys(singleSkuPackages).length - 1 && <Divider />}
              </Box>
            );
          })}
        </>
      ) : (
        <Box marginTop="S4" marginBottom="S3"></Box>
      )}
    </Stack>
  );
};
