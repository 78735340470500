export enum CreateShipmentFormActionTypes {
  SET_ORIGIN_TYPE = "IPB_SET_ORIGIN_TYPE",
  SET_ORIGIN = "IPB_SET_ORIGIN",
  SET_DESTINATION = "IPB_SET_DESTINATION",
  SET_IS_LIFTGATE_REQUIRED = "IPB_SET_IS_LIFTGATE_REQUIRED",
  SET_DELIVERY_DATE = "IPB_SET_DELIVERY_DATE",
  SET_UNIT_SYSTEM = "IPB_SET_UNIT_SYSTEM",
  SET_SHIPMENT_TOTALS = "IPB_SET_SHIPMENT_TOTALS",
  SET_HAS_DANGEROUS_GOODS = "IPB_SET_HAS_DANGEROUS_GOODS",
  SET_LITHIUM_BATTERY_PACKAGING = "IPB_SET_LITHIUM_BATTERY_PACKAGING",
  SET_DANGEROUS_GOODS = "IPB_SET_DANGEROUS_GOODS",
  SET_CARGO_READY_DATE = "IPB_SET_CARGO_READY_DATE",
  SET_DESTINATION_SELECT = "IPB_SET_DESTINATION_SELECT",
  SET_AMAZON_DESTINATION_TYPE = "IPB_SET_AMAZON_DESTINATION_TYPE",
  SET_AMAZON_PREP_TYPE = "IPB_SET_AMAZON_PREP_TYPE",
  SET_AMAZON_ADDRESS = "IPB_SET_AMAZON_ADDRESS",
  SET_FREIGHT_SERVICE = "IPB_SET_FREIGHT_SERVICE",
  SET_DOMESTIC_DESTINATION_TYPE = "IPB_SET_DOMESTIC_DESTINATION_TYPE",
  SET_DISTRIBUTION_TYPE = "SET_DISTRIBUTION_TYPE",
  SET_SUPPLIER = "IPB_SET_SUPPLIER",
  SET_ORIGIN_FACILITY_NAME = "IPB_SET_ORIGIN_FACILITY_NAME",
  SET_DESTINATION_FACILITY_NAME = "IPB_SET_DESTINATION_FACILITY_NAME",
  SET_DESTINATION_ADDRESS_FID = "IPB_SET_DESTINATION_ADDRESS_FID",
  SET_IS_OWN_TRANSPORTATION = "IPB_SET_IS_OWN_TRANSPORTATION",
  CLEAR_FORM = "IPB_CLEAR_FORM",
  PREPOPULATE_FORM = "IPB_PREPOPULATE_FORM",
  SET_HAS_CARGO_INSURANCE = "IPB_SET_HAS_CARGO_INSURANCE",
  SET_INSURANCE_INFO = "IPB_SET_INSURANCE_INFO",
  SET_BOOKING_PRODUCTS = "IPB_SET_BOOKING_PRODUCTS",
  SET_AIR_SHIPMENT_CARGO_DETAILS = "SET_AIR_SHIPMENT_CARGO_DETAILS",
  SET_DOMESTIC_ECOMM_STATE = "IPB_SET_DOMESTIC_ECOMM_STATE",
  SET_DOMESTIC_STORAGE_STATE = "IPB_SET_DOMESTIC_STORAGE_STATE",
  SET_HAS_ANNUAL_CUSTOMS_BOND = "IPB_SET_HAS_ANNUAL_CUSTOMS_BOND",
  SET_FLEXPORT_CAPITAL_SELECTED = "IPB_SET_FLEXPORT_CAPITAL_SELECTED",
  SET_IS_ORIGIN_PORT = "IPB_SET_IS_ORIGIN_PORT",
  SET_CARGO_INPUT_TYPE = "IPB_SET_CARGO_INPUT_TYPE",
  SET_IS_CARGO_MIXED_SKU = "IPB_SET_IS_CARGO_MIXED_SKU",
  SET_DTC_PREP_OPTION = "IPB_SET_DTC_PREP_OPTION",
  SET_SINGLE_SKU_PACKAGES = "IPB_SET_SINGLE_SKU_PACKAGES",
  SET_MIXED_SKU_PACKAGES = "IPB_SET_MIXED_SKU_PACKAGES",
  SET_RESERVE_STORAGE = "IPB_SET_RESERVE_STORAGE",
  SET_CROSSDOCK_WAREHOUSE = "IPB_SET_CROSSDOCK_WAREHOUSE",
}

export enum CreateShipmentActionTypes {
  SET_STEP_STACK = "IPB_SET_STEP_STACK",
  SET_IS_IPB = "IPB_SET_IS_IPB",
  SET_SHIPPING_PLAN_ID = "IPB_SET_SHIPPING_PLAN_ID",
  SET_IS_SAVING = "IPB_SET_IS_SAVING",
  SET_IS_LOADING = "IPB_SET_IS_LOADING",
  SET_IS_RETURNING_USER = "IPB_SET_IS_RETURNING_USER",
  CLEAR_META = "IPB_CLEAR_META",
  HIDE_IPB_DEV_TOOLS = "IPB_HIDE_IPB_DEV_TOOLS",
  SET_SAVED_FREIGHT_SERVICE_TITLE = "IPB_SET_SAVED_FREIGHT_SERVICE_TITLE",
}

export enum CreateShipmentQueryActionTypes {
  SET_SUPPLIERS = "SET_SUPPLIERS",
}
