import { Carrier, CarrierAccount } from "@deliverr/replenishment-client";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { transferCreateSetSelectedCarrierAccount } from "transfers/create/actions";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { DeliverrAddress } from "@deliverr/commons-objects";
import { useLoader } from "common/components/WithLoader/useLoader";
import { ReplenishmentCarrierAccountLoader } from "../ReplenishmentCarrierAccountLoader";
import { WholesaleShippingMethod } from "@deliverr/wholesale-client";

/**
 * This represents a special carrier account for the "COLLECT" shipping with FedEx.
 * The displayName is set to "COLLECT" to indicate this specific shipping option.
 */
export const collectCarrierAccount = {
  carrier: Carrier.FEDEX,
  displayName: WholesaleShippingMethod.COLLECT,
} as CarrierAccount;

const collectOption = {
  label: WholesaleShippingMethod.COLLECT,
  value: collectCarrierAccount,
};

const createCarrierAccountOption = (carrierAccount?: CarrierAccount): CarrierAccountOption | undefined => {
  if (!carrierAccount) {
    return undefined;
  }
  const { displayName, companyName = "", carrier, accountNumber = "", postalCode = "" } = carrierAccount;
  return {
    value: carrierAccount,
    // React select uses label for substring search
    label: `${displayName}-${companyName}-${carrier}-${accountNumber}-${postalCode}`,
  };
};

const getCarrierAccountOption = (
  selectedCarrierAccount: CarrierAccount | undefined,
  carrierAccounts: CarrierAccount[],
  destinationAddress: DeliverrAddress | undefined
): CarrierAccountOption | undefined => {
  if (selectedCarrierAccount === collectCarrierAccount) {
    return collectOption;
  }

  if (selectedCarrierAccount && carrierAccounts.includes(selectedCarrierAccount)) {
    return createCarrierAccountOption(selectedCarrierAccount);
  }

  const companyName = destinationAddress?.company?.toLowerCase() ?? destinationAddress?.name?.toLowerCase() ?? "";
  const matchedCarrierAccount = carrierAccounts.find(
    (carrierAccount) => carrierAccount.companyName.toLowerCase() === companyName
  );

  return matchedCarrierAccount ? createCarrierAccountOption(matchedCarrierAccount) : collectOption;
};

export interface CarrierAccountOption {
  value: CarrierAccount;
  label: string;
}
export const useCarrierAccountSelect = (
  carrierAccounts: CarrierAccount[],
  selectedCarrierAccount: CarrierAccount | undefined
) => {
  const dispatch = useDispatch();
  const { destinationAddress } = useSelector(selectTransferCreate);
  const [selectedOption, setSelectedOption] = useState(
    getCarrierAccountOption(selectedCarrierAccount, carrierAccounts, destinationAddress)
  );
  const isLoading = useLoader(ReplenishmentCarrierAccountLoader.FetchCarrierLoader);

  useEffect(() => {
    const carrierAccountOption = getCarrierAccountOption(selectedCarrierAccount, carrierAccounts, destinationAddress);
    dispatch(transferCreateSetSelectedCarrierAccount(carrierAccountOption?.value));
    setSelectedOption(carrierAccountOption);
  }, [selectedCarrierAccount, carrierAccounts, destinationAddress, dispatch]);

  const handleChange = ({ value }: { value: CarrierAccount }) => {
    dispatch(transferCreateSetSelectedCarrierAccount(value));
    setSelectedOption(createCarrierAccountOption(value));
  };

  return {
    isLoading,
    value: selectedOption,
    // First Option in the dropdown will be a button to add a new carrier account
    options: [{ isAddCarrierAccountButton: true }, collectOption, ...carrierAccounts.map(createCarrierAccountOption)],
    onChange: handleChange,
  };
};
