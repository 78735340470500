import React from "react";
import { toast } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import history from "BrowserHistory";

import log from "Logger";
import { createActionCreator, SPThunkAction } from "common/ReduxUtils";
import { FreightQuote } from "../store/FreightQuoteSummaryState";
import { Path } from "paths";
import { freightQuoteSummaryErrorMessages } from "./FreightQuoteSummaryErrors";
import { freightApiAdapter } from "common/clients/instances";

export enum FreightQuoteSummaryActionTypes {
  SET_FREIGHT_QUOTE_SUMMARY_ID = "SET_FREIGHT_QUOTE_SUMMARY_ID",
  GET_FREIGHT_QUOTE_SUMMARY_SUCCESS = "GET_FREIGHT_QUOTE_SUMMARY_SUCCESS",
  GET_FREIGHT_QUOTE_SUMMARY = "GET_FREIGHT_QUOTE_SUMMARY",
  GENERATE_FREIGHT_QUOTE_PUBLIC_ID_SUCCESS = "GENERATE_FREIGHT_QUOTE_PUBLIC_ID_SUCCESS",
}

export const setFreightQuoteSummaryId = createActionCreator<string>(
  FreightQuoteSummaryActionTypes.SET_FREIGHT_QUOTE_SUMMARY_ID,
  "freightQuoteSummaryId"
);

export const requestFreightQuoteSummary =
  (freightQuoteSummaryId: string, isAuthenticated: boolean): SPThunkAction =>
  async (dispatch) => {
    dispatch({ type: FreightQuoteSummaryActionTypes.GET_FREIGHT_QUOTE_SUMMARY });
    const ctx = { fn: "requestFreightQuoteSummary", freightQuoteSummaryId };
    try {
      const freightQuoteSummary = isAuthenticated
        ? await freightApiAdapter.getFreightQuote(freightQuoteSummaryId)
        : await freightApiAdapter.getPublicFreightQuote(freightQuoteSummaryId);

      log.info(ctx, freightQuoteSummary, "received requested freight quote summary successfuly");
      dispatch(getFreightQuoteSummarySuccess(freightQuoteSummary));
    } catch (err) {
      log.warn({ ...ctx, err }, "error receiving requested freight quote summary");
      const errorMessage = freightQuoteSummaryErrorMessages[err.payload.data.code];
      history.push(Path.freightQuoteTool);
      toast.error(<FormattedMessage {...errorMessage} />, {
        autoClose: 5000,
        toastId: "freightQuoteSummaryError",
      });
    }
  };

export const generateFreightQuotePublicId =
  (freightQuote: FreightQuote): SPThunkAction<Promise<string>> =>
  async (dispatch) => {
    const freightQuotePublicId = await freightApiAdapter.generateFreightQuotePublicId(freightQuote.id);
    dispatch(generateFreightQuotePublicIdSuccess(freightQuotePublicId));
    return freightQuotePublicId;
  };

export const getFreightQuoteSummarySuccess = createActionCreator<FreightQuote>(
  FreightQuoteSummaryActionTypes.GET_FREIGHT_QUOTE_SUMMARY_SUCCESS,
  "freightQuoteSummary"
);

export const generateFreightQuotePublicIdSuccess = createActionCreator<string>(
  FreightQuoteSummaryActionTypes.GENERATE_FREIGHT_QUOTE_PUBLIC_ID_SUCCESS,
  "freightQuotePublicId"
);
