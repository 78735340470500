import { ReducerDictionary, createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { EntityError, OperationStatus, PackingOption } from "@deliverr/replenishment-client";
import { PackingOptionsActionTypes } from "./PackingOptionsActionTypes";

export interface PackingOptionsState {
  packingOptions: PackingOption[];
  packingOptionsFetchOperationStatus: OperationStatus;
  packingOptionsFetchOperationId?: number;
  selectedPackingOptionId?: number;
  packingOptionsErrors: EntityError[];
}

export const packingOptionsInitialState: PackingOptionsState = {
  packingOptions: [],
  packingOptionsFetchOperationStatus: OperationStatus.CREATED,
  packingOptionsFetchOperationId: undefined,
  selectedPackingOptionId: undefined,
  packingOptionsErrors: [],
};

const reducers: ReducerDictionary<PackingOptionsState> = {
  ...handleSimpleReducerUpdates([PackingOptionsActionTypes.PACKING_OPTIONS_SET_SELECTED_PACKING_OPTION_ID]),
  [PackingOptionsActionTypes.PACKING_OPTIONS_FETCH_SUCCESS]: (_state, action) => ({
    packingOptions: action.packingOptions,
    packingOptionsFetchOperationStatus: action.operationStatus,
    packingOptionsFetchOperationId: action.operationId,
    selectedPackingOptionId: action.selectedPackingOptionId,
    packingOptionsErrors: [],
  }),
  [PackingOptionsActionTypes.PACKING_OPTIONS_FETCH_ERROR]: (_state, action) => ({
    packingOptions: [],
    packingOptionsFetchOperationStatus: action.operationStatus,
    packingOptionsFetchOperationId: action.operationId,
    selectedPackingOptionId: undefined,
    packingOptionsErrors: action.errors,
  }),
  [PackingOptionsActionTypes.PACKING_OPTIONS_RESET_STATE]: () => packingOptionsInitialState,
};

export const packingOptionsReducer = createReducer<PackingOptionsState>(packingOptionsInitialState, reducers);
