import React, { FC } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import styled from "@emotion/styled";
import { Box, DefaultTheme, defaultTheme, Icon } from "common/components/ui";
import { WholesaleShipmentType } from "@deliverr/wholesale-client";
import { DatePicker, DatePickerProps } from "common/components/DatePicker";
import { InfoIcon } from "common/components/icons";
import { CommaDate } from "common/date/DateFormat";
import { isHolidayOrWeekend } from "../../date/isHolidayOrWeekend";

export interface ShipByProps extends Omit<DatePickerProps, "className" | "format" | "suffixMessage"> {
  label: MessageDescriptor;
  toolTip: MessageDescriptor;
  suffixMessage?: MessageDescriptor;
  selectedCargoType?: WholesaleShipmentType;
}

const Columns = styled.div(
  () => `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`
);

const InfoIconContainer = styled.span<{}, DefaultTheme>(
  ({ theme }) => `
  margin-left: ${theme.spacing.S1};
`
);

export const ShipBy: FC<ShipByProps> = ({
  date,
  minDate,
  maxDate,
  onDateChange,
  label,
  toolTip,
  suffixMessage,
  selectedCargoType,
}) => {
  return (
    <Columns>
      <Box paddingBottom="S5" width="358px">
        <>
          <FormattedMessage {...label} />
          <InfoIconContainer>
            <InfoIcon messageWidth={175} wrap placement="top" message={<FormattedMessage {...toolTip} />}>
              <Icon type="info-circle-alt" color={defaultTheme.colors.NEUTRAL[400]} />
            </InfoIcon>
          </InfoIconContainer>
        </>
        <DatePicker
          format={CommaDate}
          date={date}
          minDate={minDate}
          maxDate={maxDate}
          onDateChange={onDateChange}
          getIsDateDisabled={isHolidayOrWeekend}
          suffixMessage={suffixMessage && <FormattedMessage {...suffixMessage} />}
          adjustUTCOffset
        />
      </Box>
    </Columns>
  );
};
