import styled from "@emotion/styled";
import React from "react";
import { Omit, Required } from "utility-types";
import { Icon, IconTypes } from "../Icon";
import { Size, Appearance, ThemeProps, DefaultTheme } from "../shared";

export type ButtonIconAppearance = Extract<keyof typeof Appearance, "DEFAULT" | "PRIMARY" | "INFO">;
export type ButtonIconSize = "XS" | Extract<keyof typeof Size, "SM" | "MD" | "LG"> | "STANDARD";

export interface ButtonIconProps extends Required<React.ButtonHTMLAttributes<HTMLButtonElement>, "aria-label"> {
  /** Type of Icon displayed */
  iconType: IconTypes;
  /** Type of button */
  appearance?: ButtonIconAppearance;
  /** size of button */
  size?: ButtonIconSize;
  /** is a seconday action */
  secondary?: boolean;
  /** icon is surrounded by transparent clickable area. Overrides secondary styles */
  transparent?: boolean;
}

type ButtonIconStyleProps = Omit<ButtonIconProps, "iconType" | "aria-label">;

const secondaryStyles = ({ color, theme, appearanceColor }) => `
  background-color: ${theme.colors.NEUTRAL["00"]};
  color: ${color[300]};

  :hover {
    background-color: ${color[100]};
    border-color: ${color[300]};
  }

  :focus,
  :active {
    box-shadow: ${theme.input.focus[appearanceColor]};
  }

  :disabled,
  &[disabled] {
    background-color: ${theme.colors.NEUTRAL["00"]};
    border-color: ${theme.colors.NEUTRAL[80]};
    color: ${theme.colors.NEUTRAL[100]};
  }
`;

const defaultStyles = ({ color, theme, appearanceColor }) => `
  border-color: ${color[300]};
  background-color: ${color[300]};
  color: ${theme.colors.NEUTRAL["00"]};

  :hover {
    background-color: ${color[400]};
    border-color: ${color[400]};
  }

  :disabled,
  &[disabled] {
    background-color: ${theme.colors.NEUTRAL[100]};
    border-color: ${theme.colors.NEUTRAL[100]};
    color: ${theme.colors.NEUTRAL["00"]};

    :hover,
    :focus {
      background-color: ${theme.colors.NEUTRAL[100]};
      border-color: ${theme.colors.NEUTRAL[100]};
      color: ${theme.colors.NEUTRAL["00"]};
    }
  }

  :focus,
  :active {
    background-color: ${color[400]};
    border-color: ${color[400]};
    box-shadow: ${theme.input.focus[appearanceColor]};
  }
`;

const transparentStyles = ({ color, theme, appearanceColor }) => `
  background: transparent;
  color: ${color[300]};
  border: 0;
  border-radius: 0;
  :hover {
    color: ${color[400]};
  }
  :focus,
  :active {
    box-shadow: ${theme.input.focus[appearanceColor]};
  }
`;

const infoStyles = ({ color, theme, appearanceColor }) => `
  border-color: ${color[40]};
  background-color: ${color[40]};
  color: ${color[300]};
  :hover: {
    background-color: ${color[80]};
    border-color: ${color[80]};
  }
  :active,
  :focus {
    color: ${color[300]};
    background-color: ${color[80]};
    box-shadow: ${theme.input.focus[appearanceColor]};
  }
  :disabled {
    background-color: ${color[40]};
    border-color: ${color[40]};
    color: ${color[200]};

    :hover,
    :focus {
      background-color: ${color[40]};
      border-color: ${color[40]};
      box-shadow: none;
    }
  }
`;

const setAppearance = ({
  appearance = Appearance.PRIMARY,
  transparent = false,
  secondary = false,
  theme,
}: ThemeProps<ButtonIconStyleProps>) => {
  const appearanceColor = theme.config.colorByAppearance[appearance];
  const color = theme.colors[appearanceColor];
  let appliedStyle;
  if (transparent) {
    appliedStyle = transparentStyles;
  } else if (secondary) {
    appliedStyle = secondaryStyles;
  } else if (appearance === "INFO") {
    appliedStyle = infoStyles;
  } else {
    appliedStyle = defaultStyles;
  }

  return `
    :disabled,
    &[disabled] {
      cursor: not-allowed;
    }
    ${appliedStyle({ color, theme, appearanceColor })}
  `;
};

const propsBySize = ({ size = Size.MD, theme }: ThemeProps<ButtonIconStyleProps>) => {
  const sizeObj: Record<ButtonIconSize, string> = {
    XS: `
    width: 0.875rem;
    height: 0.875rem;
    font-size: 0.5rem;
  `,
    [Size.SM]: `
    width: 1.375rem;
    height: 1.375rem;
    font-size: 0.625rem;
  `,
    [Size.MD]: `
    width: 1.625rem;
    height: 1.625rem;
    font-size: 0.75rem;
  `,
    [Size.LG]: `
    width: 1.875rem;
    height: 1.875rem;
    font-size: 0.875rem;
  `,
    STANDARD: `
    width: ${theme.input.height};
    height: ${theme.input.height};
    font-size: 1rem;
  `,
  };
  return sizeObj[size];
};

export const StyledButtonIcon = styled.button<ButtonIconStyleProps, DefaultTheme>(
  ({ appearance, size = Size.MD, transparent, secondary, theme }) => `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
    border: ${theme.border.width.B1} ${theme.border.type};
    cursor: pointer;
    :focus {
      outline: 0;
      box-shadow: 0 0 4px 0;
    }
    ${setAppearance({ appearance, transparent, secondary, theme })}
    ${propsBySize({ size, theme })}
  `
);

const StyledIcon = styled(Icon)`
  pointer-events: none;
`;

/**
 * @deprecated This component is deprecated, please use our new component ButtonIconV2
 * */

export const ButtonIcon: React.FC<ButtonIconProps> = ({ iconType, ...props }) => (
  <StyledButtonIcon type="button" {...props}>
    <StyledIcon type={iconType} />
  </StyledButtonIcon>
);
