import React from "react";
import { FormattedMessage } from "react-intl";
import {
  FBA_MAX_FREIGHT_NUMBER_OF_BOXES,
  FBA_MAX_PARCEL_NUMBER_OF_BOXES_VIA_FLEXPORT,
} from "common/constants/fbaValidations.const";
import { transferCreateSetShipmentValidationError } from "transfers/create/actions";
import { ShippingPartner, ShippingType } from "@deliverr/replenishment-client";
import { replenishmentShipmentCreateLabels } from "../../common/replenishment/replenishmentShipmentCreate.labels";

export const shipmentTotalBoxesFbaValidation = (dispatch, totalNumberOfBoxes?: number) => {
  const labels = replenishmentShipmentCreateLabels.steps.shipping.cargoOptions;

  const defaultTransportValidationErrorState = {
    hasError: true,
    isPending: false,
    disabled: true,
    retryable: false,
  };

  if (totalNumberOfBoxes) {
    if (totalNumberOfBoxes > FBA_MAX_PARCEL_NUMBER_OF_BOXES_VIA_FLEXPORT) {
      const error = {
        ...defaultTransportValidationErrorState,
        errorMessage: (
          <FormattedMessage
            {...labels.parcel.invalidNumberOfBoxesForAmazon}
            values={{ numOfBoxes: FBA_MAX_PARCEL_NUMBER_OF_BOXES_VIA_FLEXPORT }}
          />
        ),
      };
      dispatch(transferCreateSetShipmentValidationError(ShippingPartner.DELIVERR, ShippingType.PARCEL, error));
    }
    if (totalNumberOfBoxes > FBA_MAX_FREIGHT_NUMBER_OF_BOXES) {
      const error = {
        ...defaultTransportValidationErrorState,
        errorMessage: (
          <FormattedMessage
            {...labels.pallet.invalidNumberOfBoxesForAmazon}
            values={{ numOfBoxes: FBA_MAX_FREIGHT_NUMBER_OF_BOXES }}
          />
        ),
      };
      dispatch(transferCreateSetShipmentValidationError(ShippingPartner.DELIVERR, ShippingType.LTL, error));
    }
  }
};
