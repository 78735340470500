import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { find } from "lodash/fp";
import { OnboardingStage } from "@deliverr/commons-clients";
import { User } from "common/clients/seller/User/User";
import { Role } from "common/clients/seller/User/Role";
import { getUserStatus } from "common/user/status/getUserStatus";

export const ENGAGEMENT_STAGES = ["base", "strategic", "enterprise", "trial", "churned", "inactive"];

export const getIsOrganizationLoading = (state: RootState) => !state?.organization?.sellerId;

export const getHasPaymentInformation = createSelector(
  getIsOrganizationLoading,
  (state) => state?.organization?.hasChargebeeAccount,
  (isOrganizationLoading, hasChargebeeAccount) => (isOrganizationLoading ? undefined : hasChargebeeAccount)
);

export const getOrgUsers = (state: RootState) => state?.organization?.users;

export const getOrgOnboardingStage = (state: RootState) => state?.organization?.onboardingStage;

export const getOrgDealStage = (state: RootState) => state?.organization?.dealStage;

export const getOrgHasFirstOrder = createSelector<RootState, string | undefined, boolean | undefined>(
  getOrgDealStage,
  (dealStage) => ENGAGEMENT_STAGES.filter((stage) => dealStage?.toLowerCase().includes(stage)).length > 0
);

export const getOrgCompletedInbound = createSelector<RootState, OnboardingStage | undefined, boolean | undefined>(
  getOrgOnboardingStage,
  (onboardingStage) => onboardingStage?.completedInbound
);

export const getOrgCreatedInbound = createSelector<RootState, OnboardingStage | undefined, boolean | undefined>(
  getOrgOnboardingStage,
  (onboardingStage) => onboardingStage?.createdInbound
);

export const getOrgAdmin = createSelector<RootState, User[], User | undefined>(getOrgUsers, (users) => {
  if (!users) {
    return undefined;
  }

  const activeAdmins = users.filter((user) => user.role === Role.ADMIN && getUserStatus(user) === "ACTIVE");

  return activeAdmins.length > 0 ? activeAdmins[0] : users.find((user) => user.role === Role.ADMIN);
});

export const getCurrentOrganizationUser = createSelector<RootState, string, User[], User | undefined>(
  (state: RootState) => state.user.email,
  (state: RootState) => state.organization.users,
  (email, organizationUsers) => find(["email", email], organizationUsers)
);
