import { Icon, Title } from "common/components/ui";
import React, { FC } from "react";
import cx from "classnames";

import { ExternalLink } from "common/components/ExternalLink";
import { FormattedMessage } from "react-intl";
import { FastTagsNav } from "tags/base/FastTagsNav";
import { FastTagPageComponentProps } from "tags/model/FastTagPage";

import sharedCls from "../../../common/facebook.less";
import { FacebookEditFlowState } from "../../state/FacebookEditFlowState";
import { useUnsyncAdSets } from "./useUnsyncAdSets";
import { ConfirmUnsyncModal } from "./ConfirmUnsyncModal";

export type UnsyncAdSetsProps = FastTagPageComponentProps<FacebookEditFlowState>;

export const UnsyncAdSets: FC<UnsyncAdSetsProps> = ({ flow, page, state }) => {
  const { nextPath, onNextClick, previousPath } = useUnsyncAdSets({ flow, page, state });

  return (
    <div className={sharedCls.container}>
      <div className={sharedCls.inner}>
        <Title displayAs="h4" className={cx(sharedCls.fullTitle, sharedCls.boundedWidth)}>
          <FormattedMessage id="FacebookFastTag.Edit.UnsyncAdSets.Title" defaultMessage="Unsyncing Ad Sets" />
        </Title>
        <p className={cx(sharedCls.description, sharedCls.boundedWidth)}>
          <FormattedMessage
            id="FacebookFastTag.Edit.UnsyncAdSets.Description"
            defaultMessage="To unsync an ad set simply remove the Flexport snippet from the ad set.
Once you’re done, click on the unsync button below."
          />
        </p>
        <ExternalLink className={sharedCls.facebookAdsManagerLink} href="https://www.facebook.com/ads/manager/accounts">
          <FormattedMessage
            id="FacebookFastTag.FacebookAdsManagerLinkText"
            defaultMessage="Open Facebook Ads Manager"
          />
          <Icon className={sharedCls.facebookAdsManagerLinkIcon} type="external-link-alt" />
        </ExternalLink>
      </div>
      <FastTagsNav
        className={sharedCls.nav}
        nextPage={nextPath}
        previousPage={previousPath}
        nextButtonText={<FormattedMessage id="Unsync" defaultMessage={"Unsync"} />}
        previousButtonText={<FormattedMessage id="back" defaultMessage={"Back"} />}
        nextButtonClassName={sharedCls.actionButton}
        previousButtonClassName={sharedCls.actionButton}
        onNext={onNextClick}
      />
      <ConfirmUnsyncModal />
    </div>
  );
};
