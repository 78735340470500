import { defineMessages } from "react-intl";

export const facilityOperatingHoursMessages = defineMessages({
  label: {
    id: "facilityOperatingHours.label",
    defaultMessage: "Facility hours of operation",
  },
  helpText: {
    id: "facilityOperatingHours.helpText",
    defaultMessage: "Input in location's timezone",
  },
  errorNotMinRange: {
    id: "facilityOperatingHours.errorNotMinRange",
    defaultMessage: "Time range is not at least {MIN_HOUR_RANGE} hours.",
  },
  minRange: {
    id: "facilityOperatingHours.minRange",
    defaultMessage: "Minimum range of {MIN_HOUR_RANGE} hours.",
  },
});
