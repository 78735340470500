import { createActionCreator } from "common/ReduxUtils";

export enum ErrorsActionTypes {
  SET_ERROR = "ERRORS_SET_ERROR",
  CLEAR_ERROR = "ERRORS_CLEAR_ERROR",
}

export enum BusinessErrorCode {
  LABEL_UPLOAD_DUPLICATE_ERROR = "LABEL_UPLOAD_DUPLICATE_ERROR",
}

export const clearGlobalError = createActionCreator<BusinessErrorCode>(ErrorsActionTypes.CLEAR_ERROR, "code");
export const setGlobalError = createActionCreator<BusinessErrorCode, unknown>(
  ErrorsActionTypes.SET_ERROR,
  "code",
  "data"
);
