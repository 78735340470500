import { Sort } from "common/models";

/**
 * This function is meant to assess the replica to be used when sorting by
 * fields on a particular index. It also houses any exceptions that need to be made
 * for sorting on specific indexes.
 *
 * Ex: product_prod is sorted by onHandQty -> desc by default, there is no need to
 * create a sort replica for this index.
 *
 * For more information see spike for initiative related to saving space in Algolia
 * https://flexport.atlassian.net/browse/SMBPE-1127
 *
 * @param sortBy
 * @param targetIndex
 * @returns
 */
export const getSortByIndex = (sortBy: Sort, targetIndex: string): string => {
  // If no exceptions return normal sort by
  return `${targetIndex}_by_${sortBy.fieldName}_${sortBy.direction}`;
};
