import { SPThunkAction } from "common/ReduxUtils";
import { FeatureName, getFeatureSelector } from "common/Split";
import { Product } from "@deliverr/commons-clients";
import { getProductCasePacks } from "../../selectProductCasePacks/actions/getProductCasePacks";
import { addStorageProduct } from "../../selectProducts/SelectStorageProductsActions";

export const addStorageProductWithCaseDetails = (dsku: string, product: Product): SPThunkAction => {
  return async (dispatch, getState) => {
    const state = getState();
    const isStorageMultiCasePackOn = !!getFeatureSelector(FeatureName.StorageMultiCasePack)(state);
    dispatch(addStorageProduct(dsku, product));
    return dispatch(getProductCasePacks(dsku, isStorageMultiCasePackOn));
  };
};
