import { SellerSalesChannels } from "common/clients/onboarding/SellerSalesChannels/SellerSalesChannels";
import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";

export enum SalesChannelsActionTypes {
  SET_SALES_CHANNELS = "SET_SALES_CHANNELS",
  GET_SALES_CHANNEL_ID = "GET_SALES_CHANNEL_ID",
  DEACTIVATE_SALES_CHANNEL = "DEACTIVATE_SALES_CHANNEL",
  ACTIVATE_SALES_CHANNEL = "ACTIVATE_SALES_CHANNEL",
}

export interface SalesChannelsState extends Omit<SellerSalesChannels, "sellerId"> {}

export const salesChannelsInitialState: SalesChannelsState = {
  salesChannels: [],
  recommendedChannels: [],
  directChannels: [],
  indirectChannels: [],
};

const findAndUpdateSalesChannel = (currentSalesChannels, salesChannel) => {
  return currentSalesChannels.map((channel) => (channel.slsUuid === salesChannel.slsUuid ? salesChannel : channel));
};

const reducers: ReducerDictionary<SalesChannelsState> = {
  ...handleSimpleReducerUpdates([SalesChannelsActionTypes.SET_SALES_CHANNELS]),
  [SalesChannelsActionTypes.DEACTIVATE_SALES_CHANNEL]: (state, { salesChannel }) => {
    return {
      ...state,
      salesChannels: findAndUpdateSalesChannel(state.salesChannels, salesChannel),
    };
  },
  [SalesChannelsActionTypes.ACTIVATE_SALES_CHANNEL]: (state, { salesChannel }) => {
    return {
      ...state,
      salesChannels: findAndUpdateSalesChannel(state.salesChannels, salesChannel),
    };
  },
};

export const salesChannelsReducer = createReducer<SalesChannelsState>(salesChannelsInitialState, reducers);
