import React, { FC } from "react";
import { SHIPMENT_PROBLEMS_MISMATCH_CASE_QTY_BLADE_ID } from "../blades";
import { ShipmentProblemUnexpectedSkuBladeProps } from "../ShipmentProblemUnexpectedSkuBlade/ShipmentProblemsUnexpectedSkuBlade";
import { MismatchCaseQtyBlade } from "common/components/ProblemBlade/MismatchCaseQtyBlade";
import { useShipmentProblemMismatchCaseQtyBlade } from "./useShipmentProblemMismatchCaseQtyBlade";

export const ShipmentProblemMismatchCaseQtyBlade: FC<ShipmentProblemUnexpectedSkuBladeProps> = ({
  shipmentProblem,
}) => {
  const {
    resetBladeState,
    shippingPlanId,
    shippingPlanName,
    isShipmentProblemBladeOpen,
    product,
  } = useShipmentProblemMismatchCaseQtyBlade(shipmentProblem);

  return (
    <MismatchCaseQtyBlade
      id={SHIPMENT_PROBLEMS_MISMATCH_CASE_QTY_BLADE_ID}
      shipmentProblem={shipmentProblem}
      onShippingPlanClick={resetBladeState}
      shippingPlanId={shippingPlanId}
      shippingPlanName={shippingPlanName}
      onCloseClick={resetBladeState}
      isBladeOpen={isShipmentProblemBladeOpen}
      productDetails={product}
    />
  );
};
