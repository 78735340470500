export enum LtlInputNames {
  NUMBER_OF_PALLETS = "numberOfPallets",
  TOTAL_CARGO_WEIGHT = "totalCargoWeight",

  PALLET_LENGTH = "length",
  PALLET_WIDTH = "width",
  PALLET_HEIGHT = "height",
  PALLET_WEIGHT = "weight",
  IDENTICAL_PALLETS = "identicalPallets",

  READY_DATE = "pickupDate",
  DECLARED_VALUE = "declaredValue",
  CONTACT_NAME = "contactName",
  CONTACT_PHONE = "contactPhone",
  CONTACT_EMAIL = "contactEmail",
  WEIGHT_CLASS = "weightClass",
  CUSTOMER_REFERENCE_NUMBER = "customerReferenceNumber",

  PICKUP_WINDOW = "preferredPickupWindow",
  PICKUP_WINDOW_START = "pickupWindowStart",
  PICKUP_WINDOW_END = "pickupWindowEnd",
  OPERATING_HOURS = "facilityOperatingHours",
  OPERATING_HOURS_START = "operatingHoursStart",
  OPERATING_HOURS_END = "operatingHoursEnd",
}
