import React from "react";
import styled from "@emotion/styled";
import { Accordion, ThemeProps, Title } from "common/components/ui";
import { InstructionCardInfo } from "inbounds/steps/ship/view/cards/InstructionCard";
import { InstructionCardList } from "inbounds/steps/ship/view/cards/InstructionCardList";
import { FormattedMessage, useIntl } from "react-intl";

import { useStorageShipmentInstructions } from "./useStorageShipmentInstructions";

const InstructionCardListWrapper = styled.div<ThemeProps>(
  ({ theme }) => `
    .continueButton {
      margin-top: ${theme.spacing.S4};
    }
    .finalButton {
      display: flex;
      justify-content: flex-end;
      margin-top: ${theme.spacing.S4};
    }
    .selectProductTable {
      -webkit-box-shadow: none;
      box-shadow: none;
      width: 75%;
    }
    .stepCardSection {
      border-top: none;
      margin-left: 3.5rem;
      padding-top: 0;
    }
    .stepCard{
      margin-top: 0;
    }
  `
);

const StyledTitle = styled(Title)<ThemeProps>(
  ({ theme }) => `
      line-height: ${theme.font.lineHeight.LH2};
      font-size: ${theme.font.size.F2};
      margin: 0;
    `
);

const NestedTitle = styled(Title)<ThemeProps>(
  ({ theme }) => `
      font-size: ${theme.font.size.F4};
      margin: 0;
    `
);

export const StorageShipmentInstructions: React.FC = () => {
  const {
    shipmentInstructionsSteps,
    toggleShipmentInstructionOpen,
    isShipmentInstructionOpen,
  } = useStorageShipmentInstructions();
  const { formatMessage } = useIntl();

  const title = (
    <StyledTitle displayAs="h5" as="h5" uppercase>
      <FormattedMessage id={"storage.inbounds.detail.instructions.title"} defaultMessage={"Shipment Instructions"} />
    </StyledTitle>
  );

  const steps = shipmentInstructionsSteps.map((step) => {
    return {
      id: step.id as string,
      content: <step.component />,
      title: <NestedTitle>{formatMessage(step.title)}</NestedTitle>,
      isDefaultOpen: true,
      isDisabled: false,
      cardSectionClassName: "stepCardSection",
      className: "stepCard",
    } as InstructionCardInfo;
  });
  return (
    <Accordion
      indicator
      isOpen={isShipmentInstructionOpen}
      toggleOpen={toggleShipmentInstructionOpen}
      headerContent={title}
      disabled={false}
      noBodyPadding
    >
      <InstructionCardListWrapper>
        <InstructionCardList
          ordinalOptions={{ enumeration: "numeral" }}
          isCollapsible={false}
          shouldRemoveSpaceBetweenCards
          items={steps}
        />
      </InstructionCardListWrapper>
    </Accordion>
  );
};
