import React from "react";
import styled from "@emotion/styled";
import { DefaultTheme } from "common/components/ui";
import { css } from "emotion";
import { useTheme } from "emotion-theming";
import noop from "lodash/noop";

import Table, { TableColumn, TableProps } from "common/components/lists/Table";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { ProblemRowData } from "./types";
import { getIsUnacknowledgedProblemRow } from "./getIsUnacknowledgedProblemRow";
import { SellerProblemStatus } from "@deliverr/legacy-inbound-client";

// emotion's css doesn't provide an explicit way to use theme, so I elected to pass
// it as an explicit param that produces a row-specific function
const getRowClass = (theme: DefaultTheme) => (row: ProblemRowData) => css`
  ${(row.status === SellerProblemStatus.CREATED || getIsUnacknowledgedProblemRow(row)) &&
  `
    font-weight: ${theme.font.weight.BOLD};
    border-top: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL["60"]};
    border-bottom: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL["60"]};
    `}
  ${getIsUnacknowledgedProblemRow(row) &&
  `
    /* add red dot before rows with action required */
    td:first-of-type::before {
      display: inline-block;
      position: absolute;
      /* -3px for radius offset */
      top: calc(50% - 3px);
      left: ${theme.spacing.S4};

      background: ${theme.colors.RED["300"]};
      content: '';
      height: 6px;
      width: 6px;
      border-radius: 50%;
    `}
`;

const StyledTable = styled(Table)<TableProps<ProblemRowData>, DefaultTheme>(
  ({ theme }) => `
  box-shadow: none;
  margin-bottom: 0;

  thead > tr > th {
    color: ${theme.colors.NEUTRAL["500"]};
  }

  tbody {
    tr, td {
      height: 70px;
    }
  }

  /* calculate width of first column with 2rem of extra space due to padding */
  tr > th:first-of-type, tr > td:first-of-type {
    padding-left: ${theme.spacing.S6};
    width: calc(((998px - 2rem) / 6) + 2rem);
  }
`
);

export interface ProblemsTableProps {
  columns: TableColumn<ProblemRowData>[];
  rows: ProblemRowData[];
  onRowClick: (row: ProblemRowData) => void;
  hidePaginate?: boolean;
  pagingInfo?: { totalPages: number; currentPage: number };
  onPageChange?: (page: number) => void;
  isDataLoading?: boolean;
}

export const ProblemsTable = ({
  columns,
  rows,
  onRowClick,
  hidePaginate = true,
  pagingInfo,
  onPageChange,
}: ProblemsTableProps) => {
  const theme = useTheme<DefaultTheme>();

  return (
    <StyledTable
      listLoaderType={InboundLoaderId.shipmentProblemsTable}
      columns={columns}
      items={rows}
      getRowClass={getRowClass(theme)}
      onRowClick={onRowClick}
      page="inboundProblemsList"
      onPageChange={onPageChange ?? noop}
      allowRowSelection={false}
      showCheckboxCondition={() => false}
      hidePaginate={hidePaginate}
      pagingInfo={pagingInfo}
    />
  );
};
