import { ManagedStep } from "common/components/StepContainer";
import { FormattedMessage, useIntl } from "react-intl";
import { transfersShipmentCreateLabels } from "../../transfersShipmentCreate.labels";
import React from "react";
import { WholesaleShipmentType, WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { AmazonShippingMethod } from "../fba/shipping/AmazonShippingMethod";
import { useDispatch, useSelector } from "react-redux";
import { selectTransferCreate } from "../../store/TransferCreateSelectors";
import { transferCreateChooseShippingConfig } from "../../actions";
import { AmazonShipmentType } from "../fba/shipping/AmazonShipmentType";
import { Box, Notification } from "common/components/ui";
import { useTransferEstimationsStatus } from "../fba/useTransferEstimationsStatus";
import { ElevioArticleId, ElevioBorderlessButton } from "../../../../common/elevio";
import { useParcelValidation } from "./useParcelValidation";

const AmazonEstimationErrorNotification = () => {
  const { formatMessage } = useIntl();
  return (
    <Box width="80%" paddingBottom="S6">
      <Notification
        data-testid="amazon-error-notification"
        appearance="DANGER"
        content={{ description: formatMessage(transfersShipmentCreateLabels.steps.shipping.amazonEstimationError) }}
      />
    </Box>
  );
};

const DeliverrEstimationErrorNotification = () => {
  const { formatMessage } = useIntl();
  return (
    <Box width="80%" paddingBottom="S6">
      <Notification
        data-testid="deliverr-error-notification"
        appearance="PRIMARY"
        content={{ description: formatMessage(transfersShipmentCreateLabels.steps.shipping.deliverrEstimationError) }}
      />
    </Box>
  );
};

const ReferenceIdNotification = () => {
  const { formatMessage } = useIntl();
  return (
    <Box width="80%" paddingBottom="S6" paddingRight={"S5"}>
      <Notification
        data-testid="reference-id-notification"
        appearance="WARNING"
        content={{
          title: formatMessage(transfersShipmentCreateLabels.steps.shipping.referenceIdNotificationTitle),
          description: formatMessage(transfersShipmentCreateLabels.steps.shipping.referenceIdNotification),
        }}
        append={
          <ElevioBorderlessButton articleId={ElevioArticleId.FbaReferenceId}>
            <FormattedMessage {...transfersShipmentCreateLabels.steps.shipping.learnMore} />
          </ElevioBorderlessButton>
        }
      />
    </Box>
  );
};

export const FbaIntegrationShippingMethod = () => {
  const { formatMessage } = useIntl();
  const { shippingMethod, cargoType } = useSelector(selectTransferCreate);

  const {
    isAutomatedFBAReady,
    deliverrPalletEstimation,
    deliverrParcelEstimation,
    thirdPartyParcelEstimation,
    isThirdPartyParcelError,
    isThirdPartyParcelPending,
    hasFailedEstimation,
    isDeliverrFreightPending,
    isDeliverrParcelPending,
    wholesaleOrder,
  } = useTransferEstimationsStatus();
  const dispatch = useDispatch();

  const dskus = wholesaleOrder?.orderItems?.map((item) => item.dsku);
  const { showParcelEstimationError } = useParcelValidation(dskus);

  const onChangeShippingMethod = (value: WholesaleShippingMethod) => {
    dispatch(transferCreateChooseShippingConfig({ shippingMethod: value }));
  };

  const onChangeShipmentType = (value: WholesaleShipmentType) => {
    dispatch(transferCreateChooseShippingConfig({ shipmentType: value }));
  };

  const isDeliverrMethod = shippingMethod === WholesaleShippingMethod.DELIVERR;
  const isAmazonMethod = shippingMethod === WholesaleShippingMethod.SELLER;

  const isAmazonWithError = isAmazonMethod && isThirdPartyParcelError;
  const isDeliverrWithError = isDeliverrMethod && hasFailedEstimation;

  const isDeliverrFreight = isDeliverrMethod && cargoType === WholesaleShipmentType.PARTIAL_PALLET;

  const isNextDisabled =
    !shippingMethod ||
    !cargoType ||
    (isAmazonMethod &&
      (isThirdPartyParcelPending || isThirdPartyParcelError) &&
      cargoType === WholesaleShipmentType.PARCEL);

  return (
    <ManagedStep
      data-testid="fba-integration-shipping-method"
      title={formatMessage(transfersShipmentCreateLabels.steps.shipping.title)}
      isNextDisabled={isNextDisabled}
    >
      <AmazonShippingMethod value={shippingMethod} onChange={onChangeShippingMethod} />
      {(isDeliverrMethod || (isAmazonMethod && !isThirdPartyParcelError)) && (
        <AmazonShipmentType
          shippingMethod={shippingMethod!}
          shipmentType={cargoType}
          isPendingPallet={!isAutomatedFBAReady || (isDeliverrMethod ? isDeliverrFreightPending : false)}
          isPendingParcel={
            !isAutomatedFBAReady || (isDeliverrMethod ? isDeliverrParcelPending : isThirdPartyParcelPending)
          }
          onChange={onChangeShipmentType}
          showParcelEstimationError={showParcelEstimationError}
          palletEstimation={isDeliverrMethod ? deliverrPalletEstimation : undefined}
          parcelEstimation={isDeliverrMethod ? deliverrParcelEstimation : thirdPartyParcelEstimation}
        />
      )}

      {isAmazonWithError && <AmazonEstimationErrorNotification />}
      {isDeliverrWithError && <DeliverrEstimationErrorNotification />}
      {isDeliverrFreight && <ReferenceIdNotification />}
    </ManagedStep>
  );
};
