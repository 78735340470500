import { batch } from "react-redux";
import { OrderListActionTypes } from "../OrderListActionTypes";
import { OrderListSummaryFilter } from "../OrderListReducer";
import { setCurrentPage } from "./setCurrentPage";

export const setSummaryFilter = (summary: OrderListSummaryFilter) => async (dispatch) =>
  batch(() => {
    dispatch({ type: OrderListActionTypes.SET_SUMMARY_FILTER, summary });
    dispatch(setCurrentPage(0));
  });
