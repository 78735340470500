import { createActionCreator, SPThunkAction } from "common/ReduxUtils";
import RouteParser from "common/RouteParser";
import { Path } from "paths";
import { RootState } from "RootReducer";
import log from "Logger";
import { FreightQuoteFormErrorCodes } from "./FreightQuoteFormErrors";
import { freightApiAdapter } from "common/clients/instances";
import { FreightQuotePlanService } from "../store/FreightQuoteFormState";
import { makeQuoteRequest } from "../utils/makeQuoteRequest";
import { freightClient } from "Clients";

export enum FreightQuoteFormActionTypes {
  UPDATE_FREIGHT_QUOTE_FORM = "UPDATE_FREIGHT_QUOTE_FORM",
  REQUEST_FREIGHT_QUOTE = "REQUEST_FREIGHT_QUOTE",
  REQUEST_FREIGHT_QUOTE_ERROR = "REQUEST_FREIGHT_QUOTE_ERROR",
  REQUEST_FREIGHT_QUOTE_SUCCESS = "REQUEST_FREIGHT_QUOTE_SUCCESS",
}

export const updateFreightQuoteForm = createActionCreator<
  string,
  string | number | FreightQuotePlanService[] | undefined
>(FreightQuoteFormActionTypes.UPDATE_FREIGHT_QUOTE_FORM, "name", "value");

export const submitFreightQuoteRequest = (): SPThunkAction => async (dispatch, getState: () => RootState) => {
  dispatch({
    type: FreightQuoteFormActionTypes.REQUEST_FREIGHT_QUOTE,
  });
  const state = getState();
  const internalUserId = state.user.email;
  const freightQuoteFormState = state.freightQuote.freightQuoteForm.form;
  const freightQuoteRequest = {
    ...freightQuoteFormState,
    internalUserId,
  };

  const quoteRequest = makeQuoteRequest(freightQuoteFormState, internalUserId);

  const ctx = { fn: "requestFreightQuote", freightQuoteRequest };
  try {
    const { id: freightQuoteId } = await freightApiAdapter.generateFreightQuote(freightQuoteRequest);

    if (freightQuoteId) {
      log.info(ctx, "received requested freight quote successfuly");
      dispatch({
        type: FreightQuoteFormActionTypes.REQUEST_FREIGHT_QUOTE_SUCCESS,
        freightQuoteId,
      });
      setTimeout(
        () => window.open(new RouteParser(Path.freightQuoteSummary).parse({ freightQuoteId }), "_blank"),
        3000
      );
    }

    // Generate quotes in both freight-service and transportation-service during the transition stage
    await freightClient.generateFtlQuote(quoteRequest);
  } catch (err) {
    log.warn({ ...ctx, err }, "error receiving requested freight quote");
    dispatch({ type: FreightQuoteFormActionTypes.REQUEST_FREIGHT_QUOTE_ERROR, reason: err.payload.data.payload.code });
  }
};

export const requestFreightQuoteSuccess = createActionCreator<string>(
  FreightQuoteFormActionTypes.REQUEST_FREIGHT_QUOTE_SUCCESS,
  "freightQuoteId"
);

export const requestFreightQuoteError = createActionCreator<FreightQuoteFormErrorCodes>(
  FreightQuoteFormActionTypes.REQUEST_FREIGHT_QUOTE_ERROR,
  "reason"
);
