import { SelectedProductData } from "common/components/ProductChooser/SelectedProductData";
import { createActionCreator } from "common/ReduxUtils";
import { WholesaleCreateActionTypes } from "../store/WholesaleCreateActionTypes";
import { WholesaleCreateState } from "../store/WholesaleCreateState";
import { AttachmentType, WholesaleOrderDTO, ValidShipByDTO } from "@deliverr/wholesale-client";
import { UploadAttachmentType } from "../../../transfers/create/actions";
import { WholesaleErrorType } from "../../../common/wholesale-common/utils/WholesaleErrorTypes";
import { StorageWarehouseInventory } from "common/clients/storage/StorageWarehouseInventory";

export const wholesaleCreateAddProduct = createActionCreator<string, SelectedProductData>(
  WholesaleCreateActionTypes.WHOLESALE_CREATE_ADD_PRODUCT,
  "dsku",
  "product"
);

export const wholesaleCreateRemoveProduct = createActionCreator<string>(
  WholesaleCreateActionTypes.WHOLESALE_CREATE_REMOVE_PRODUCT,
  "dsku"
);

export const wholesaleSelectDestinationAddress = createActionCreator<WholesaleCreateState["destinationAddress"]>(
  WholesaleCreateActionTypes.WHOLESALE_SELECT_DESTINATION_ADDRESS,
  "destinationAddress"
);

export const wholesaleSetPoNumber = createActionCreator<WholesaleCreateState["poNumber"]>(
  WholesaleCreateActionTypes.WHOLESALE_SET_PO_NUMBER,
  "poNumber"
);

export const wholesaleCreateReset = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_CREATE_RESET);

export const wholesaleCreateUpdateProductNumberOfCases = createActionCreator<string, number>(
  WholesaleCreateActionTypes.WHOLESALE_CREATE_UPDATE_NUMBER_OF_CASES,
  "dsku",
  "numberOfCases"
);

export const wholesaleCreateChooseOriginWarehouse = createActionCreator<WholesaleCreateState["originStorageWarehouse"]>(
  WholesaleCreateActionTypes.WHOLESALE_CREATE_CHOOSE_ORIGIN_STORAGE_WAREHOUSE,
  "originStorageWarehouse"
);

export const wholesaleCreateChooseShippingConfig = createActionCreator<{
  shippingMethod: WholesaleCreateState["shippingMethod"];
  cargoType: WholesaleCreateState["cargoType"];
  shipBy: WholesaleCreateState["shipBy"];
}>(WholesaleCreateActionTypes.WHOLESALE_CREATE_CHOOSE_SHIPPING_CONFIG, "shippingConfig");

export const wholesaleCreateAddNotes = createActionCreator<string>(
  WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_NOTES,
  "notes"
);

export const setAvailableInventoryInEachStorageWarehouse = createActionCreator<StorageWarehouseInventory[]>(
  WholesaleCreateActionTypes.WHOLESALE_SET_AVAILABLE_INVENTORY,
  "availableInventory"
);

export const wholesaleCreateSetWholesaleOrder = createActionCreator<WholesaleOrderDTO>(
  WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_WHOLESALE_ORDER,
  "wholesaleOrder"
);

export const wholesaleCreateSetError = createActionCreator<WholesaleErrorType>(
  WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_ERROR,
  "errorType"
);

export const wholesaleCreateStoreSteps = createActionCreator<WholesaleCreateState["stack"]>(
  WholesaleCreateActionTypes.WHOLESALE_CREATE_STORE_STEPS,
  "stack"
);

export const wholesaleCreateSetUploading = createActionCreator<AttachmentType, boolean>(
  WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_UPLOADING,
  "attachmentType",
  "value"
);

export const wholesaleCreateSetClearingLabel = createActionCreator<AttachmentType, boolean>(
  WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_UPLOADING,
  "attachmentType",
  "value"
);

export const wholesaleCreateSetLabelFilename = createActionCreator<UploadAttachmentType, string | null>(
  WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_LABEL_FILENAME,
  "label",
  "file"
);

export const wholesaleCreateSetValidShipBy = createActionCreator<ValidShipByDTO>(
  WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_VALID_SHIP_BY,
  "validShipBy"
);
