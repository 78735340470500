import { ShipmentType } from "@deliverr/storage-client";

export const getRenderMoneyPerUnitCellOptions = (cargoType: ShipmentType, perPalletLabel) => {
  const renderMoneyCellOptions = {
    maximumFractionDigits: 3,
  };
  return cargoType === ShipmentType.FLOOR_LOADED_CONTAINER
    ? renderMoneyCellOptions
    : { ...renderMoneyCellOptions, appendedLabel: perPalletLabel };
};
