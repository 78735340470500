import { LocalizedText } from "common/components/LocalizedText";
import { InfoIcon } from "common/components/icons";
import { Box, Grid, Icon, Stack, ThemeProps, defaultTheme } from "common/components/ui";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";
import React from "react";
import { getStorageInboundCreate } from "storage/inbounds/create/store/selector/getStorageInboundCreate";
import { useSelector } from "react-redux";
import { ProductCollection } from "common/models";
import styled from "@emotion/styled";
import { PackageSummary } from "inbounds/createShipment/CreateShipmentTypes";
import { sum } from "lodash";

const StyledBox = styled(Box)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[200]};
`
);

const calcProductQty = (packages: PackageSummary[], productDsku: string) =>
  sum(
    packages?.map(
      (pkg) =>
        pkg.numberOfPkgs * (sum(pkg.items.filter((item) => item.dsku === productDsku).map((item) => item.qty)) ?? 0)
    )
  );

const TooltipSummary = ({
  productDetails,
  packages,
  selectedProductDskus,
}: {
  productDetails: ProductCollection;
  packages: PackageSummary[];
  selectedProductDskus: string[];
}) => {
  return (
    <>
      {Object.values(selectedProductDskus).map((dsku, index) => {
        return (
          <Box key={index} marginTop="S2">
            <Stack gap={null}>
              <Grid columns="2fr 1fr">
                <Box>{productDetails[dsku].name}</Box>
                <Box>
                  <LocalizedText
                    message={{
                      id: "createShipment.shipmentTotals.infoIcon.Qty",
                      defaultMessage: "{qty} units",
                      values: {
                        qty: calcProductQty(packages, dsku),
                      },
                    }}
                  />
                </Box>
              </Grid>
              <StyledBox>{productDetails[dsku]?.msku}</StyledBox>
            </Stack>
          </Box>
        );
      })}
    </>
  );
};

const ProductSummaryInfo = ({
  productDetails,
  packages,
  selectedProductDskus,
}: {
  productDetails: ProductCollection;
  packages: PackageSummary[];
  selectedProductDskus: string[];
}) => {
  return (
    <InfoIcon
      wrap
      placement="top"
      message={
        <TooltipSummary
          productDetails={productDetails}
          packages={packages}
          selectedProductDskus={selectedProductDskus}
        />
      }
    >
      <Icon type="info-circle-alt" color={defaultTheme.colors.NEUTRAL[400]} />
    </InfoIcon>
  );
};

const UnconnectedProductSummaryDetail = ({
  totalUnits,
  packages,
  productDetails,
}: {
  totalUnits: number;
  packages: PackageSummary[];
  productDetails: ProductCollection;
}) => {
  const selectedProductDskus = [...new Set(packages.map((pkg) => pkg.items?.map((item) => item.dsku)).flat())];
  return (
    <LocalizedText
      message={{
        id: "createShipment.shipmentTotals.unitsReadOnly",
        defaultMessage: "{totalUnits} total units across {skus} SKUs {infoIcon}",
        values: {
          totalUnits,
          skus: selectedProductDskus.length,
          infoIcon: (
            <ProductSummaryInfo
              productDetails={productDetails}
              packages={packages}
              selectedProductDskus={selectedProductDskus}
            />
          ),
        },
      }}
    />
  );
};

export const ProductSummaryDetail = ({ packages }: { packages: PackageSummary[] }) => {
  const { watch } = useCreateShipmentFormContext();
  const { productDetails } = useSelector(getStorageInboundCreate);
  const totalUnits = watch(CreateShipmentInputName.TOTAL_UNITS);

  return (
    <UnconnectedProductSummaryDetail totalUnits={totalUnits} packages={packages} productDetails={productDetails} />
  );
};
