import { Product } from "@deliverr/commons-clients";
import { Modal } from "common/components/ui";
import React, { FC } from "react";
import { NewProductForm, NewProductFormProps } from "../NewProductForm";

interface NewProductModalProps extends NewProductFormProps {
  isOpen: boolean;
  onClose: () => void;
}

export const NewProductModal: FC<NewProductModalProps> = ({ isOpen, onClose, defaultValues, onSuccess }) => {
  const openProductInNewTab = (product: Product) => {
    window.open(`/inventory/${product.dsku}`, "_blank");
  };

  const handleSuccess = (product: Product) => {
    onSuccess ? onSuccess(product) : openProductInNewTab(product);
    onClose();
  };

  return (
    <Modal show={isOpen} onClose={onClose}>
      <NewProductForm defaultValues={defaultValues} onSuccess={handleSuccess} />
    </Modal>
  );
};
