import {
  packingOptionsInitialState,
  packingOptionsReducer,
  PackingOptionsState,
} from "../packingOptions/store/PackingOptionsState";
import { combineReducers } from "redux";
import { FbaV3ActionTypes } from "./FbaV3Actions";
import {
  placementOptionsInitialState,
  placementOptionsReducer,
  PlacementOptionsState,
} from "../placementOptions/store/PlacementOptionsState";
import {
  transportationOptionsInitialState,
  transportationOptionsReducer,
  TransportationOptionsState,
} from "../transportationOptions/state/TransportationOptionsState";

export interface FbaV3CreateState {
  packingOptionsState: PackingOptionsState;
  placementOptionsState: PlacementOptionsState;
  transportationOptionsState: TransportationOptionsState;
}

export const fbaV3CreateInitialState: FbaV3CreateState = {
  packingOptionsState: packingOptionsInitialState,
  placementOptionsState: placementOptionsInitialState,
  transportationOptionsState: transportationOptionsInitialState,
};

export const fbaV3CreateStateReducer = (state, action) => {
  if (action.type === FbaV3ActionTypes.RESET_FBA_V3_STATE) {
    state = fbaV3CreateInitialState;
  }

  return stateSpecificReducers(state, action);
};

const stateSpecificReducers = combineReducers<FbaV3CreateState>({
  packingOptionsState: packingOptionsReducer,
  placementOptionsState: placementOptionsReducer,
  transportationOptionsState: transportationOptionsReducer,
});
