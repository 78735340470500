import styled from "@emotion/styled";
import React from "react";
import { DefaultTheme, ThemeProps } from "../shared";
import { isMobile } from "../shared/helpers/utils";
import { createPortal } from "react-dom";
import { ButtonIcon } from "../ButtonIcon";
import { FocusOn } from "react-focus-on";
import { useBlade } from "./useBlade";

export interface BladeProps {
  /** The content for the header */
  headerContent: JSX.Element;
  /** Determines whether to show the blade */
  trigger: boolean;
  /** The footer */
  footer?: JSX.Element;
  onClose?: () => void;
  /** The aria-label for the close button */
  closeButtonLabel?: string;
  /** Configures the width of the blade */
  width?: string;
}

interface SectionProps {
  show: boolean;
  width?: string;
}

const desktopSectionWidth = "448px";
const iconSize = "16px";
const animation = "transform .25s ease-in-out";
const lightShadow = "rgba(0, 0, 0, 0.05)";

const Background = styled.div<ThemeProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(38, 46, 54, 0.1);
  z-index: ${({ theme }) => theme.zindex.Z5};
  transition: opacity ${animation};
`;

const StyledSection = styled.section<SectionProps, DefaultTheme>`
  ${({ show, width, theme }) => {
    const sectionWidth = isMobile(theme) ? "100%" : width ?? desktopSectionWidth;
    const transformHideWidth = isMobile(theme) ? "100%" : `calc(${sectionWidth} + ${theme.spacing.S5})`;
    /*
    z-index is set to 1000000 to ensure that the blade is always on top of other elements. 
    This was done in reference to the bug because of zendesk chat launcher.
    zendesk chat launcher has a z-index of 9999999, which causes the blade to be hidden behind the chat launcher.
    */
    return `
      width: ${sectionWidth};
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      transform: ${show ? "translateX(0)" : `translateX(${transformHideWidth})`};
      transition: ${animation};
      background-color: ${theme.colors.NEUTRAL["00"]};
      z-index: 1000000;
      display: grid;
      grid-template-rows: auto 1fr auto;
      box-shadow: 0px 5px 22px 0px ${lightShadow}, 0px 12px 17px 0px ${lightShadow};
      height: 100%;
    `;
  }}
`;

const StyledHeader = styled.header<ThemeProps>`
  ${({ theme }) => {
    const buttonWidth = isMobile(theme) ? "3rem" : "2.5rem";
    const buttonPadding = `calc(${theme.spacing.S5} - calc((${buttonWidth} - ${iconSize}) / 2))`;

    return `
      padding: ${theme.spacing.S3} ${theme.spacing.S5} ${theme.spacing.S3} ${buttonPadding};
      box-shadow: 0px 1px 0px ${theme.colors.SHADOW};
      display: grid;
      align-items: center;
      grid-template-columns: ${buttonWidth} 1fr;
      grid-gap: ${theme.spacing.S2};

      svg {
        width: ${iconSize};
        height: ${iconSize};
      }
    `;
  }}
`;

const HeaderContentContainer = styled.div<ThemeProps>`
  display: inline-block;
  vertical-align: text-top;
`;

const ContentContainer = styled.div<ThemeProps>`
  overflow-y: auto;
`;

const StyledFooter = styled.footer<ThemeProps>`
  ${({ theme }) => {
    return `
      padding: ${theme.spacing.S3} ${theme.spacing.S5};
      width: 100%;
      box-shadow: 0px 0px 2px ${lightShadow}, 0px -2px 4px ${lightShadow};
    `;
  }}
`;

export const Blade: React.FC<BladeProps> = ({
  headerContent,
  trigger = false,
  footer,
  onClose = () => null,
  closeButtonLabel,
  width,
  children,
}) => {
  const { displayPortal, showSection } = useBlade(trigger);

  return displayPortal
    ? createPortal(
        <FocusOn onClickOutside={onClose} onEscapeKey={onClose} autoFocus={false}>
          <div>
            <Background onClick={onClose} />
            <StyledSection show={showSection} width={width}>
              <StyledHeader>
                <ButtonIcon
                  iconType="times"
                  onClick={onClose}
                  transparent={true}
                  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                  aria-label={closeButtonLabel || "close"}
                  size="STANDARD"
                />
                <HeaderContentContainer>{headerContent}</HeaderContentContainer>
              </StyledHeader>
              <ContentContainer>{children}</ContentContainer>
              {footer ? <StyledFooter>{footer}</StyledFooter> : null}
            </StyledSection>
          </div>
        </FocusOn>,
        document.getElementById("blade-portal")!
      )
    : null;
};

export const BladeContainer: React.FC = () => <div id="blade-portal" />;
