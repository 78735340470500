import { QuoteLocation } from "freight/types/Quote";
import { getDrayagePortFromAddress } from "./getDrayagePortFromAddress";
import { FREIGHT_TABLE_PORT_LABELS } from "../labels";

export const getFormattedQuoteAddress = (location: QuoteLocation): string => {
  if (location.address) {
    const port = getDrayagePortFromAddress(location.zip);

    return port
      ? FREIGHT_TABLE_PORT_LABELS[port].defaultMessage
      : `${location.address.city}, ${location.address.state}`;
  }

  return location.zip;
};
