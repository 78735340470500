import { isNil } from "lodash/fp";
import { matchPath } from "react-router-dom";
import history from "BrowserHistory";

// Whereas in useCurrentStep we subscribe to history with useHistory, we import history here to get a precise read whenever
// at the moment this function is called

// <T extends Enum> does not exist in Typescript,
// so we make do with <T extends string>
export const getCurrentStep = <FlowStepType extends string>(
  // this type is the actual enum, but we pass it like this to allow
  // Object.values() to be called
  flowStepType: { [key: string]: FlowStepType },
  stepToPathMap: Record<FlowStepType, string>
) => {
  const flowSteps = Object.values(flowStepType);

  const currentStep = flowSteps.find(
    // look up the step that matches the current path
    (step) => !isNil(matchPath(history.location.pathname, { path: stepToPathMap[step] }))
  );

  return currentStep;
};
