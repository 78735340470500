import { MessageDescriptor } from "react-intl";

/**
 * Given an array of possible values and a record of react-intl message descriptors,
 * returns a list of options suitable for use with react-select.
 * @example
 * type Color = typeof colors[number];
 *
 * const colors = [
 *   "red",
 *   "green",
 *   "blue",
 * ] as const;
 *
 * const colorMessages: Record<Color, MessageDescriptor> = defineMessages({
 *   red: {
 *     id: "colors.red",
 *     defaultMessage: "Red",
 *   },
 *   green: {
 *     id: "colors.green",
 *     defaultMessage: "Green",
 *   },
 *   blue: {
 *     id: "colors.blue",
 *     defaultMessage: "Blue",
 *   },
 * });
 *
 * const getColorOptions = getSelectOptions(colors, colorMessages);
 *
 * const ColorPicker: React.FC = () => {
 *   const { formatMessage } = useIntl();
 *   const colorOptions = useMemo(() => getColorOptions(formatMessage), [formatMessage]);
 *
 *   return <Select options={colorOptions} />;
 * };
 */
export const getSelectOptions = <T extends string>(values: readonly T[], messages: Record<T, MessageDescriptor>) => (
  formatMessage: (descriptor: MessageDescriptor) => string
): { value: T; label: string }[] =>
  values.map((value) => ({
    value,
    label: formatMessage(messages[value]),
  }));
