import { Box, BoxReset, Card, Stack, ThemeProps, Title, Text } from "common/components/ui";
import styled from "@emotion/styled";
import { InboundFooter } from "inbounds/steps/InboundFooter";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CargoDetailSection } from "./CargoDetailSection";
import { PricingSummary } from "./pricingSummary/PricingSummary";
import { ShipmentDetail } from "./shipmentDetail/ShipmentDetail";
import { useConfirmShipment } from "./useConfirmShipment";
import { useInboundBillingSetup } from "inbounds/hooks/useInboundBillingSetup";
import { PaymentMethod } from "inbounds/createShipment/steps/priceViewStep/selectShipping/selectShippingFlow/steps/payment/PaymentMethod";
import { CarrierType } from "@deliverr/storage-client";

const StyledCard = styled(Card)<ThemeProps>(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.spacing.S7} ${theme.spacing.S5};
    height: 100%;
  `
);

export const ConfirmShipment: React.FC = () => {
  const {
    onConfirmShipmentClick,
    onPreviousClick,
    skuCount,
    unitCount,
    caseCount,
    numOfPallets,
    cargoType,
    containerSize,
    toAddress,
    warehouseProvider,
    fromAddress,
    cargoReadyDate,
    estimatedArrivalDate,
    carrierType,
  } = useConfirmShipment();
  const { checkoutButtonKey, checkoutButtonProps } = useInboundBillingSetup(onConfirmShipmentClick);
  const isDeliverrFreight = carrierType === CarrierType.DELIVERR;
  const confirmShipmentButtonText = (
    <Text bold size="body">
      <FormattedMessage id="storage.inbounds.create.confirmShipment.submit" defaultMessage="Confirm shipment" />
    </Text>
  );
  return (
    <Box width="100%">
      <StyledCard>
        <BoxReset marginBottom="S7" width="100%">
          <Stack center direction="VERTICAL">
            <BoxReset marginBottom={null}>
              <Title displayAs="h4">
                <FormattedMessage
                  id="storage.inbounds.create.confirmShipment.title"
                  defaultMessage="Confirm your shipment"
                />
              </Title>
            </BoxReset>

            <ShipmentDetail
              skuCount={skuCount}
              unitCount={unitCount}
              caseCount={caseCount}
              numOfPallets={numOfPallets}
              cargoType={cargoType}
              containerSize={containerSize}
              toAddress={toAddress}
              fromAddress={fromAddress}
              warehouseProvider={warehouseProvider}
              carrierType={carrierType}
            />
            {isDeliverrFreight && (
              <CargoDetailSection cargoReadyDate={cargoReadyDate} estimatedArrivalDate={estimatedArrivalDate} />
            )}
            <PricingSummary isDeliverrFreight={isDeliverrFreight} />
            <Box width={"80%"}>
              <PaymentMethod />
            </Box>
          </Stack>
        </BoxReset>
      </StyledCard>
      <InboundFooter
        onButtonClick={onConfirmShipmentClick}
        buttonText={confirmShipmentButtonText}
        onPreviousButtonClick={onPreviousClick}
        manualLoader
        loaderText={
          <FormattedMessage id="storage.inbounds.create.confirmingShipment" defaultMessage="Confirming shipment..." />
        }
        key={checkoutButtonKey}
        buttonProps={checkoutButtonProps}
      />
    </Box>
  );
};
