import { wishClient } from "common/clients/instances";
import { WishSeller } from "common/clients/wish/WishSeller/WishSeller";
import { notifyUserOfError } from "common/ErrorToast";
import { logError, logStart } from "Logger";

export enum WishActionTypes {
  WISH_CODE_RECEIVED = "WISH_CODE_RECEIVED",
  WISH_SELLER_VALIDATED = "WISH_SELLER_VALIDATED",
  WISH_FAST_TAGS_ACTIVE_CHANGED = "WISH_FAST_TAGS_ACTIVATED",
  WISH_SELLER_RECEIVED = "WISH_SELLER_RECEIVED",
  WISH_SELLER_UPDATED = "WISH_SELLER_UPDATED",
}

export const wishCodeRecieved = (code: string) =>
  ({
    type: WishActionTypes.WISH_CODE_RECEIVED,
    code,
  } as const);

export const wishSellerValidated = (success: boolean) =>
  ({
    type: WishActionTypes.WISH_SELLER_VALIDATED,
    success,
  } as const);

export const fastTagActiveChanged = (active: boolean) => ({
  type: WishActionTypes.WISH_FAST_TAGS_ACTIVE_CHANGED,
  active,
});

export const wishSellerUpdated = (success: boolean) =>
  ({
    type: WishActionTypes.WISH_SELLER_UPDATED,
    success,
  } as const);

export const wishSellerReceived = (seller: WishSeller) =>
  ({
    type: WishActionTypes.WISH_SELLER_RECEIVED,
    seller,
  } as const);

export const deactivateWish = () => async (_, getState) => {
  const ctx = logStart({ fn: "deactivateWish" });
  const {
    user: { sellerId },
  } = getState();
  try {
    await wishClient.updateSeller(sellerId, { isActive: false });
    location.reload();
  } catch (err) {
    notifyUserOfError({ err, toastId: "wishDeactivationError" });
    logError(ctx, err);
  }
};
