import { Step, StepManagerHandlers } from "common/components/StepContainer";
import { FbaOAuthAuthorizationStep } from "./steps/fbaIntegration/FbaOAuthAuthorizationStep";
import { TransferCreateInitialStep } from "./steps/createTransferSteps";
import { FbaIntegrationShippingMethod } from "./steps/fbaIntegration/FbaIntegrationShippingMethod";
import { transferCreateChooseDestinationType } from "./actions";
import { TransferCreationTypes } from "./transferCreationTypes";
import { FbaWaitForShipmentCreation } from "./steps/fbaIntegration/FbaWaitForShipmentCreation";
import { FbaShipmentCreationError } from "./steps/fbaIntegration/FbaShipmentCreationError";
import { selectTransferCreate } from "./store/TransferCreateSelectors";
import { FbaIntegrationConfirmed } from "./steps/fbaIntegration/FbaIntegrationConfirmed";
import { FbaWaitForShipmentConfirmation } from "./steps/fbaIntegration/FbaWaitForShipmentConfirmation";
import { updateFbaReferenceIdForAllShipmentInOrder } from "../../wholesale/detail/updateFbaReferenceId";
import { FbaV2Steps } from "./fbaV2Steps";

export enum FbaIntegrationV2Steps {
  Authorize = "fbaIntegration/v2/authorize",
  WaitForShipmentCreation = "fbaIntegration/v2/waitForShipmentCreation",
  ShipmentCreationError = "fbaIntegration/v2/shipmentCreationError",
  SelectShippingMethod = "fbaIntegration/v2/shippingMethod",
  WaitForShipmentConfirmation = "fbaIntegration/v2/waitForShipmentConfirmation",
  Confirmed = "fbaIntegration/v2/confirmed",
}

export const fbaIntegrationV2Steps: (dispatch, getState) => Record<FbaIntegrationV2Steps, Step> = (
  dispatch,
  getState
) => ({
  [FbaIntegrationV2Steps.Authorize]: {
    component: FbaOAuthAuthorizationStep,
    next: async (stepButton, { revert }) => {
      if (stepButton === 0) {
        return undefined;
      }
      dispatch(transferCreateChooseDestinationType(TransferCreationTypes.Fba));
      return [...revert(TransferCreateInitialStep), FbaV2Steps.SelectAmazonFacility];
    },
  },
  [FbaIntegrationV2Steps.WaitForShipmentCreation]: {
    component: FbaWaitForShipmentCreation,
    async next(value: { isTimeout: boolean }) {
      const { wholesaleOrder } = selectTransferCreate(getState());
      if (wholesaleOrder?.errors) {
        return FbaIntegrationV2Steps.ShipmentCreationError;
      } else if (value.isTimeout) {
        return FbaIntegrationV2Steps.ShipmentCreationError;
      } else {
        return FbaIntegrationV2Steps.SelectShippingMethod;
      }
    },
  },
  [FbaIntegrationV2Steps.ShipmentCreationError]: {
    component: FbaShipmentCreationError,
    hasNoBack: true,
  },
  [FbaIntegrationV2Steps.SelectShippingMethod]: {
    component: FbaIntegrationShippingMethod,
    next: async () => {
      return FbaV2Steps.Confirm;
    },
  },
  [FbaIntegrationV2Steps.WaitForShipmentConfirmation]: {
    component: FbaWaitForShipmentConfirmation,
    next: async () => {
      return FbaIntegrationV2Steps.Confirmed;
    },
  },
  [FbaIntegrationV2Steps.Confirmed]: {
    component: FbaIntegrationConfirmed,
    hasNoBack: true,
    async next(
      value: { isDeliverrFreight: boolean; fbaReferenceIdMap: Map<string, string> },
      _stepstack,
      handlers?: StepManagerHandlers
    ) {
      if (value.isDeliverrFreight) {
        const { wholesaleOrder } = selectTransferCreate(getState());
        dispatch(updateFbaReferenceIdForAllShipmentInOrder(value.fbaReferenceIdMap, wholesaleOrder!));
      }
      handlers?.done?.();
      return undefined;
    },
  },
});
