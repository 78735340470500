import { SkuCaseQuantity } from "common/clients/inbound/SkuQuantity/SkuCaseQuantity";
import { SelectedProductData } from "common/components/ProductChooser";
import { isEmpty, partition } from "lodash";
import { PackSkuQuantity } from "../../common/clients/storage/Packs/PackSkuQuantity";
import { ATPDetails } from "common/clients/inventory/ATP/ATPDetails";

const computeInventoryBreakdownForSingleDsku = (
  inventoryBreakdownByDsku: Record<string, PackSkuQuantity[]>,
  dsku: string,
  requiredInventory: number,
  caseQty: number,
  createZeroQuantityItems = false,
  selectedProductLotNumber?: ATPDetails
): (SkuCaseQuantity & { lotNumber?: string; expirationDate?: string })[] => {
  // We want to create items with qty 0 in draft scenario
  if (requiredInventory <= 0) {
    return createZeroQuantityItems ? [{ dsku, qty: 0, caseQty }] : [];
  }

  // Lot outbounds will only be done for child dskus assuming inventory migration is complete
  // Parent child mix scenarios are ignored
  if (selectedProductLotNumber) {
    return [
      {
        dsku,
        qty: requiredInventory,
        caseQty,
        lotNumber: selectedProductLotNumber.lotNumber ? selectedProductLotNumber.lotNumber : undefined,
        expirationDate: selectedProductLotNumber.expirationDate
          ? (selectedProductLotNumber.expirationDate as unknown as string)
          : undefined,
      },
    ];
  }

  const skuInventoryBreakdown = inventoryBreakdownByDsku[dsku] ?? [];
  if (isEmpty(skuInventoryBreakdown)) {
    return [{ dsku, qty: requiredInventory, caseQty }];
  }

  // prioritise existing product inventory
  const [productInventory, packInventory] = partition(skuInventoryBreakdown, (packSkuQty) =>
    isEmpty(packSkuQty.packOf)
  );
  const orderedSkuInventoryBreakdown = productInventory.concat(packInventory);

  const inventoryBreakdown: SkuCaseQuantity[] = [];
  let leftInventory: number = requiredInventory;

  for (const packSkuQty of orderedSkuInventoryBreakdown) {
    if (packSkuQty.qty < leftInventory) {
      inventoryBreakdown.push({
        dsku: packSkuQty.dsku,
        qty: packSkuQty.qty,
        caseQty,
      });
      leftInventory -= packSkuQty.qty;
    } else {
      inventoryBreakdown.push({
        dsku: packSkuQty.dsku,
        qty: leftInventory,
        caseQty,
      });
      return inventoryBreakdown;
    }
  }

  return [];
};

/**
 * Computes the inventory breakdown for supporting Multi-Pack,
 * priortising depletion of inventory on parent skus
 * The functioning of the method is coupled with the response of
 * getUnifiedAvailablePackInventoryByWarehouse API
 *
 * Assumptions of the below method:
 * - requiredInventory will always be less than or equal to
 *   net available inventory in inventoryBreakdownByDsku property
 * - requiredInventory and quantities in inventoryBreakdownByDsku will be multiples of caseQty
 */
export const mapProductsToMultiPackSkuInventory = (
  products: SelectedProductData[],
  inventoryBreakdownByDsku: Record<string, PackSkuQuantity[]>,
  createZeroQuantityItems?: boolean,
  selectedProductsLotNumbers?: { [dsku: string]: ATPDetails | undefined }
): (SkuCaseQuantity & { lotNumber?: string; expirationDate?: string })[] => {
  return products.flatMap(
    ({ dsku, qty, caseQty }) =>
      computeInventoryBreakdownForSingleDsku(
        inventoryBreakdownByDsku,
        dsku,
        qty,
        caseQty ?? 1,
        createZeroQuantityItems,
        selectedProductsLotNumbers?.[dsku]
      ) ?? []
  );
};
