import { SPThunkAction } from "common/ReduxUtils";
import { SearchResultsResponse } from "common/search/SearchService";
import { PrepListActionTypes } from "../../types/list/PrepListActionTypes";

export const searchPrepSuccess =
  ({ nbPages, page, nbHits, hitsPerPage }: SearchResultsResponse, hits): SPThunkAction =>
  (dispatch) =>
    dispatch({
      type: PrepListActionTypes.SEARCH_PREP_SUCCESS,
      searchInfo: {
        totalPages: nbPages,
        currentPage: page,
        totalHits: nbHits,
        itemsPerPage: hitsPerPage,
      },
      currentPageItems: hits,
    });
