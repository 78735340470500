import React, { FC } from "react";

import { GetFasterInternationalShipping } from "../GetFasterInternationalShipping/GetFasterInternationalShipping";
import { SeeWhatsNew } from "../SeeWhatsNew/SeeWhatsNew";
import { useNotificationsHeader } from "./useNotificationHeader";

export const NotificationHeader: FC = () => {
  const { hasShopifyIntegration } = useNotificationsHeader();
  return (
    <>
      {hasShopifyIntegration ? <GetFasterInternationalShipping /> : null}
      <SeeWhatsNew />
    </>
  );
};
