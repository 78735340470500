import { SelectedProductData } from "common/components/ProductChooser";
import produce from "immer";
import { find } from "lodash/fp";
import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { selectTransferCreate } from "./TransferCreateSelectors";
import { TransferCreateState } from "./TransferCreateState";

export const selectTransferSelectedProductsQty = createSelector<RootState, TransferCreateState, SelectedProductData[]>(
  selectTransferCreate,
  (transferCreate) => {
    const { selectedProducts, originStorageWarehouse } = transferCreate;
    const selectedProductsQtyForGivenStorageWarehouse = produce(selectedProducts, (draft) => {
      Object.keys(draft).forEach((dsku) => {
        const availableQtyForDskuInWarehouse = find(
          (dskuQty) => dskuQty.dsku === dsku,
          originStorageWarehouse?.dskuQuantities
        );
        const availableQty = availableQtyForDskuInWarehouse?.qty ?? 0;
        draft[dsku].storageOnHandQty = availableQty;
        draft[dsku].storageUnfilledOrderQty = 0;
        draft[dsku].storageUnavailableQty = 0;
      });
    });
    return Object.values(selectedProductsQtyForGivenStorageWarehouse);
  }
);
