import { useDispatch, useSelector } from "react-redux";
import { useAsyncFn } from "react-use";

import { logError } from "Logger";
import { loginStatusReceived } from "../connect/actions/FacebookActions";
import { facebookAccessToken, facebookUserId, isLoggedIntoFacebook } from "../connect/state/FacebookSelectors";
import { Auth, LoginOptions } from "../model/Auth";

export interface FacebookLoginHook {
  isLoggedIn: boolean;
  login: (options?: LoginOptions) => Promise<Auth>;
  logout: () => Promise<Auth>;
  userId: string | undefined;
  accessToken: string | undefined;
  getLoginStatus: () => Promise<Auth>;
}

export const useFacebookLogin = (): FacebookLoginHook => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedIntoFacebook);
  const userId = useSelector(facebookUserId);
  const accessToken = useSelector(facebookAccessToken);

  const FB = (window as any).FB;

  const getLoginStatus = async () =>
    await new Promise<Auth>((resolve, reject) => {
      FB.getLoginStatus((response: Auth) => {
        try {
          dispatch(loginStatusReceived(response));
          resolve(response);
        } catch (err) {
          reject(err);
        }
      });
    });

  const [, login] = useAsyncFn(
    async (options: LoginOptions) =>
      await new Promise<Auth>((resolve, reject) => {
        try {
          FB.login((response: Auth) => {
            dispatch(loginStatusReceived(response, true));
            resolve(response);
          }, options);
        } catch (err) {
          reject(err);
        }
      })
  );

  const [, logout] = useAsyncFn(
    async () =>
      await new Promise<Auth>((resolve, reject) => {
        try {
          FB.logout((response: Auth) => {
            dispatch(loginStatusReceived(response));
            resolve(response);
          });
        } catch (err) {
          logError({ fn: "useFacebookLogin.logout" }, err);
          reject(err);
        }
      })
  );

  return {
    accessToken,
    isLoggedIn,
    login,
    logout,
    userId,
    getLoginStatus,
  };
};
