import { InboundListState } from "./InboundListState";
import { inboundListFiltersInitialState } from "./inboundListFiltersInitialState";

export const inboundListInitialState: InboundListState = {
  currentPageItems: [],
  pageChecked: false,
  selectionCount: 0,
  selectedRows: {},
  searchLoading: false,
  filtersBladeOpen: false,
  searchFilters: { ...inboundListFiltersInitialState },
};
