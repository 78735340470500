import { useDispatch, useSelector } from "react-redux";
import { useAsync } from "react-use";

import { loginStatus as facebookLoginStatus, facebookAuth } from "../connect/state/FacebookSelectors";
import { useFacebookLogin } from "./useFacebookLogin";
import { loginStatusReceived } from "../connect/actions/FacebookActions";
import { Auth, LoginStatus } from "../model/Auth";

export type CheckFacebookLoginStatusHookArgs = [(authResponse: Auth) => unknown, unknown[]] | [];
export const useCheckFacebookLoginStatus = (onLoginStatusReceived?: (authResponse: Auth) => unknown) => {
  const dispatch = useDispatch();
  const { getLoginStatus } = useFacebookLogin();
  const loginStatus = useSelector(facebookLoginStatus);
  const auth = useSelector(facebookAuth);

  useAsync(async () => {
    if (loginStatus === LoginStatus.Unknown) {
      const response = await getLoginStatus();

      dispatch(loginStatusReceived(response));

      if (onLoginStatusReceived) {
        onLoginStatusReceived(response);
      }
    } else if (loginStatus === LoginStatus.LoggedIn) {
      if (onLoginStatusReceived) {
        onLoginStatusReceived(auth);
      }
    }
  }, []);

  return { loginStatus, auth };
};
