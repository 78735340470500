import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";
import { ProductCollection } from "common/models";
import React, { FC } from "react";
import { BarcodeEditComponent } from "barcodes/BarcodeEditComponent";
import { Card } from "common/components/Card";
import ProductComplianceModal from "inbounds/steps/compliance/ProductComplianceModal";
import { InboundFooter } from "inbounds/steps/InboundFooter";
import cls from "./BarcodeInputStep.less";
import { BarcodeConfirmModal } from "./confirm/BarcodeConfirmModal";
import { useBarcodeInputStep } from "./useBarcodeInputStep";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { getIsKittedProductSelected, getPlannedBarcodes } from "../InboundStepSelectors";
import { Appearance, Notification } from "common/components/ui";
import { selectHasBarcodingPrep } from "prep/store/selectors/selectHasBarcodingPrep";
import styled from "@emotion/styled";

export const StyledNotification = styled(Notification)`
  text-align: left;
`;

interface BarcodeInputStepProps {
  productDetailsCache: ProductCollection;
  goToNextStep;
  goToPreviousStep;
}
export const BarcodeInputStep: FC<BarcodeInputStepProps> = ({
  productDetailsCache,
  goToNextStep,
  goToPreviousStep,
}) => {
  const {
    onBarcodeWarningConfirm,
    barcodeValues,
    onBarcodeChange,
    barcodeSaveError,
    pageDuplicatedBarcodes,
    onNextClick,
    onPreviousClick,
    isNextDisabled,
  } = useBarcodeInputStep(goToNextStep, goToPreviousStep, productDetailsCache);
  const plannedBarcodes = useSelector(getPlannedBarcodes);
  const hasKittedProductSelected = useSelector(getIsKittedProductSelected);
  const hasBarcodePrep = useSelector(selectHasBarcodingPrep);

  return (
    <div>
      <BarcodeConfirmModal
        onYes={onBarcodeWarningConfirm}
        productDetailsCache={productDetailsCache}
        barcodeValues={barcodeValues}
      />
      <Card className={cls.card}>
        <form className={cls.selectedProducts}>
          <ProductComplianceModal goToPreviousStep={onPreviousClick} />

          <div className={cls.content}>
            <h1 className={cls.title}>
              <FormattedMessage id={"BarcodeTitle.inputTitle"} defaultMessage={"Provide Scannable Barcode Labels"} />
            </h1>
            <div className={cls.message}>
              <p className={cls.labelWarning}>
                <FormattedMessage
                  id={"BarcodeTitle.reviewLabels"}
                  defaultMessage={
                    "You may add up to 5 alternate barcode labels per product. These barcodes cannot be removed or edited once submitted. Products without scannable labels cannot be identified, resulting in receiving delays and non-compliance fees."
                  }
                />
              </p>
              <FormattedMessage
                id={"BarcodeTitle.reviewLabels"}
                defaultMessage={"Review all {link} before inbounding."}
                values={{
                  link: (
                    <ElevioBorderlessButton articleId={ElevioArticleId.HowBarcodeProducts}>
                      <FormattedMessage
                        id={"BarcodeTitle.labelingRequirements"}
                        defaultMessage={"labeling requirements"}
                      />
                    </ElevioBorderlessButton>
                  ),
                }}
              />
            </div>
            {hasKittedProductSelected && hasBarcodePrep && (
              <StyledNotification
                appearance={Appearance.PRIMARY}
                content={{
                  description: (
                    <FormattedMessage
                      id="BarcodeInputStep.kittingNotificationDescription"
                      defaultMessage="Your selected prep services include adding the barcodes below to your kitted product"
                    />
                  ),
                }}
              />
            )}
            <table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id={"BarcodeInput.productColHead"} defaultMessage={"Product"} />
                  </th>
                  <th>
                    <FormattedMessage id={"BarcodeInput.formatColHead"} defaultMessage={"Barcode Format"} />
                  </th>
                  <th>
                    <FormattedMessage id={"BarcodeInput.valueColHead"} defaultMessage={"Barcode Value"} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(productDetailsCache).map((dsku) => (
                  // eslint-disable-next-line react/jsx-key
                  <BarcodeEditComponent
                    product={productDetailsCache[dsku]}
                    onChange={onBarcodeChange}
                    serviceError={barcodeSaveError[dsku]}
                    pageDuplicatedBarcodes={pageDuplicatedBarcodes}
                    key={dsku}
                    plannedBarcodes={plannedBarcodes[dsku]}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </form>
      </Card>
      <InboundFooter
        onButtonClick={onNextClick}
        onPreviousButtonClick={onPreviousClick}
        disabled={isNextDisabled}
        manualLoader
      />
    </div>
  );
};
