import React, { FC, useState } from "react";
import { Text } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import { Product } from "@deliverr/commons-clients";

import { DimensionsRow } from "./DimensionsRow";
import { ProductDimensionsValidator } from "./ProductDimensionsValidator";
import { InboundStepTable } from "inbounds/components/InboundStepTable/InboundStepTable";
import { CaseDimensionsAboveRecommendedForFCInfo } from "storage/inbounds/create/dimensions/CaseDimensionsAboveRecommendedForFCInfo";
import { isCaseDimensionsAboveRecommendedForFC } from "storage/inbounds/utils/isCaseDimensionsAboveRecommendedForFC";

interface DimensionsAndWeightsTableProps {
  products: Product[];
  tableHeaderOverride?: JSX.Element;
  getDimensionsErrorOverride?: ProductDimensionsValidator;
  showCaseDimensionInfo?: boolean;
  isDimensionOverrideFeatureEnabled?: boolean;
}

export const DimensionsAndWeightsTable: FC<DimensionsAndWeightsTableProps> = ({
  products,
  tableHeaderOverride,
  showCaseDimensionInfo,
  getDimensionsErrorOverride,
  isDimensionOverrideFeatureEnabled,
}) => {
  const [productDimensionsAboveRecommendations, setProductDimensionsRecommendations] = useState<string[]>([]);

  const handleRowChange = (dsku: string, dimensions: Partial<Product>) => {
    const isDSKUInList = productDimensionsAboveRecommendations.includes(dsku);
    if (isCaseDimensionsAboveRecommendedForFC(dimensions)) {
      if (!isDSKUInList) {
        setProductDimensionsRecommendations((dskuList) => [...dskuList, dsku]);
      }
    } else if (isDSKUInList) {
      setProductDimensionsRecommendations((dskuList) => dskuList.filter((val) => val !== dsku));
    }
  };

  return (
    <>
      {showCaseDimensionInfo && productDimensionsAboveRecommendations.length > 0 && (
        <CaseDimensionsAboveRecommendedForFCInfo />
      )}
      <InboundStepTable>
        {tableHeaderOverride ?? (
          <thead>
            <tr>
              <td>
                <Text bold size="caption">
                  <FormattedMessage id="inbounds.dimensionsAndWeightsTable.skuHeader" defaultMessage="SKU" />
                </Text>
              </td>
              <td>
                <Text bold size="caption">
                  <FormattedMessage
                    id="inbounds.dimensionsAndWeightsTable.dimenionsHeader"
                    defaultMessage="Dimensions (in)"
                  />
                </Text>
              </td>
              <td>
                <Text bold size="caption">
                  <FormattedMessage id="inbounds.dimensionsAndWeights.weightHeader" defaultMessage="Weight (lbs)" />
                </Text>
              </td>
            </tr>
          </thead>
        )}
        <tbody>
          {products.map((product) => (
            <DimensionsRow
              key={product.dsku}
              product={product}
              getDimensionsError={getDimensionsErrorOverride}
              isDimensionOverrideFeatureEnabled={isDimensionOverrideFeatureEnabled}
              handleRowChange={handleRowChange}
            />
          ))}
        </tbody>
      </InboundStepTable>
    </>
  );
};
