// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-backdrop {\n  background: rgba(0, 3, 56, 0.5);\n}\n.modal-dialog {\n  margin: 0;\n}\n.Modal__modal--CycaG .modal-content {\n  -webkit-box-shadow: none;\n          box-shadow: none;\n  border-radius: 2px;\n  border: none;\n}\n.Modal__modal--CycaG h5 {\n  color: #02172c;\n  font-size: 22px;\n}\n.Modal__closeButton--TrPm7 {\n  height: 35px;\n  right: 0;\n  top: -40px;\n  background: none;\n  border: none;\n  color: white;\n  font-size: 28px;\n  position: absolute;\n  cursor: pointer;\n}\n.Modal__closeButton--TrPm7:focus {\n  outline: none;\n}\n", "",{"version":3,"sources":["webpack://./src/app/common/components/Modal/Modal.less"],"names":[],"mappings":"AAAA;EACE,+BAAA;AACF;AAEA;EACE,SAAA;AAAF;AAGA;EACE,wBAAA;UAAA,gBAAA;EACA,kBAAA;EACA,YAAA;AADF;AAIA;EACE,cAAA;EACA,eAAA;AAFF;AAKA;EACE,YAAA;EACA,QAAA;EACA,UAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;AAHF;AAMA;EACE,aAAA;AAJF","sourcesContent":[":global(.modal-backdrop) {\n  background: rgba(0, 3, 56, 0.5);\n}\n\n:global(.modal-dialog) {\n  margin: 0;\n}\n\n.modal :global(.modal-content) {\n  box-shadow: none;\n  border-radius: 2px;\n  border: none;\n}\n\n.modal h5 {\n  color: rgb(2, 23, 44);\n  font-size: 22px;\n}\n\n.closeButton {\n  height: 35px;\n  right: 0;\n  top: -40px;\n  background: none;\n  border: none;\n  color: white;\n  font-size: 28px;\n  position: absolute;\n  cursor: pointer;\n}\n\n.closeButton:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "Modal__modal--CycaG",
	"closeButton": "Modal__closeButton--TrPm7"
};
export default ___CSS_LOADER_EXPORT___;
