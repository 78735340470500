import { DeliverrAddress } from "@deliverr/commons-objects";
import { combineReducers } from "redux";

import { freightBookingReducer, FreightBookingState } from "./freightBooking/reducer/FreightBookingReducer";
import { freightBookingInitialState } from "./freightBooking/reducer/freightBookingInitialState";
import {
  freightOrderDetailInitialState,
  freightOrderReducer,
  FreightOrderDetailState,
} from "./orderDetail/FreightOrderReducer";
import {
  freightListInitialState,
  freightListReducer,
  FreightListState,
} from "./freightList/reducer/FreightListReducer";
import { freightQuotingReducer, FreightQuotingState } from "./freightQuoting/reducer/FreightQuotingReducer";
import { freightQuotingInitialState } from "./freightQuoting/reducer/freightQuotingInitialState";

export type OrderRequestLocation = Omit<DeliverrAddress, "name" | "phone" | "email">;

export interface FreightState {
  list: FreightListState;
  order: FreightOrderDetailState;
  booking: FreightBookingState;
  quoting: FreightQuotingState;
}

export const freightInitialState: FreightState = {
  list: freightListInitialState,
  order: freightOrderDetailInitialState,
  booking: freightBookingInitialState,
  quoting: freightQuotingInitialState,
};

export const freightReducer = combineReducers<FreightState>({
  order: freightOrderReducer,
  booking: freightBookingReducer,
  list: freightListReducer,
  quoting: freightQuotingReducer,
});
