import React, { FC } from "react";
import { Product } from "@deliverr/commons-clients";
import { Input, Select, Stack, Text, Box, BoxReset, ThemeProps } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { useLotInformationTableRow } from "./useLotInformationTableRow";
import { InputError } from "common/components/InputError";
import { Dash } from "common/components/Dash";
import { LOT_INFORMATION_ERROR_LABELS } from "inbounds/steps/lot-information/types/LotInformationError";

interface LotInformationTableRowProps {
  product: Product;
  hasLotTrackingEnabledProducts: boolean;
  hasFefoEnabledProducts: boolean;
}

const ErrorRow = styled.tr`
  td {
    padding-top: 0;
  }
`;

const InputFallback = styled(Dash)<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL[200]};
  `
);

export const LotInformationTableRow: FC<LotInformationTableRowProps> = ({
  product,
  hasFefoEnabledProducts,
  hasLotTrackingEnabledProducts,
}) => {
  const {
    isFefoEnabled,
    isLotTrackingEnabled,
    lotNumberFieldProps,
    hasFullExpirationDateFieldProps,
    expirationDateFieldProps,
    errors,
  } = useLotInformationTableRow({ product });

  return (
    <>
      <tr>
        <td>
          <Stack gap="S1">
            <Text bold>{product.name}</Text>
            <Text size="caption" appearance="INFO">
              {product.msku}
            </Text>
          </Stack>
        </td>
        {hasLotTrackingEnabledProducts && (
          <td>
            {isLotTrackingEnabled ? (
              <Box width="140px">
                <Input {...lotNumberFieldProps} />
              </Box>
            ) : (
              <InputFallback />
            )}
          </td>
        )}
        {hasFefoEnabledProducts && (
          <>
            <td>
              {isFefoEnabled ? (
                <Box width="140px">
                  <Select {...hasFullExpirationDateFieldProps} />
                </Box>
              ) : (
                <InputFallback />
              )}
            </td>
            <td>
              {isFefoEnabled ? (
                <BoxReset width="140px">
                  <Input {...expirationDateFieldProps} />
                </BoxReset>
              ) : (
                <InputFallback />
              )}
            </td>
          </>
        )}
      </tr>
      {!!errors.length && (
        <ErrorRow>
          <td colSpan={3}>
            {errors.map((error) => (
              <InputError
                key={error}
                appearance="DANGER"
                message={<FormattedMessage {...LOT_INFORMATION_ERROR_LABELS[error]} />}
              />
            ))}
          </td>
        </ErrorRow>
      )}
    </>
  );
};
