import { productClient } from "Clients";
import { CountryCode, PackagingType } from "@deliverr/commons-objects";
import { BoxDimension, BoxDimensions } from "common/components/EstimatedCosts/BoxDimensions";
import { notifyUserOfError } from "common/ErrorToast";
import { ActionCreator, createActionCreator, Thunk } from "common/ReduxUtils";
import log from "Logger";
import { DimensionSource } from "common/utils/product/isDimsConfirmed";
import { getEstimatedCosts } from "common/ProductUtils";
import { ServiceLevel } from "@deliverr/billing-pricer-client";
import { UnId } from "common/clients/core/UnId";

export enum CostEstimatorModalActionTypes {
  SET_COST_ESTIMATOR_PRODUCT = "SET_COST_ESTIMATOR_PRODUCT",
  ESTIMATE_COSTS_LISTING = "ESTIMATE_COSTS_LISTING",
  ESTIMATE_COSTS_DETAIL = "ESTIMATE_COSTS_DETAIL",
  SET_FIELD_VALUE = "SET_FIELD_VALUE",
  SET_SHOW_NEW_PRICING = "SET_SHOW_NEW_PRICING",
}

export type EstimatedCostType = "LISTING" | "DETAIL";

export const setCostEstimatorProduct: ActionCreator = (
  dsku: string,
  productName: string,
  dimensions: BoxDimensions = {},
  hazmatInfo: UnId[],
  shippingSpec: PackagingType,
  serviceLevel: ServiceLevel,
  countryCode: CountryCode
) => ({
  type: CostEstimatorModalActionTypes.SET_COST_ESTIMATOR_PRODUCT,
  dsku,
  productName,
  dimensions,
  hazmatInfo,
  shippingSpec,
  serviceLevel,
  countryCode,
});

/* When all values are entered in the modal, generate estimates for all service levels */
export const estimateCosts: Thunk = (type: EstimatedCostType) => async (dispatch, getState) => {
  const {
    user: { sellerId },
    costEstimatorModal: { dsku, dimensions, showNewPricing, hazmatInfo, shippingSpec, serviceLevel, countryCode },
  } = getState();
  const ctx = { fn: "estimateCosts", dsku, dimensions, hazmatInfo, shippingSpec };
  log.info(ctx, "estimating costs");

  if (!dsku) {
    throw new Error(`Attempt to estimate costs with no product specified in modal`);
  }

  try {
    await productClient.update({
      dsku,
      ...dimensions,
      dimSourceType: DimensionSource.SELLER,
      dimSource: "sellerPopUpFlow",
    });
    dispatch({
      type:
        type === "DETAIL"
          ? CostEstimatorModalActionTypes.ESTIMATE_COSTS_DETAIL
          : CostEstimatorModalActionTypes.ESTIMATE_COSTS_LISTING,
      dsku,
      dimensions,
      estimatedCosts: await getEstimatedCosts({
        items: [{ dsku, ...dimensions, hazmatIds: hazmatInfo, minimumPackagingType: shippingSpec }],
        showNewPrices: showNewPricing,
        serviceLevel,
        countryCode,
        sellerId,
      }),
    });
  } catch (err) {
    log.error({ ...ctx, err }, "error updating product dims");
    notifyUserOfError({
      err,
      explanation: "Oops. There was an issue updating your product dimensions. Please try again later.",
      toastId: "updateProductDimsError",
    });
  }
};

export const setFieldValue = createActionCreator<BoxDimension, number>(
  CostEstimatorModalActionTypes.SET_FIELD_VALUE,
  "field",
  "value"
);

export const setShowNewPricing = createActionCreator<boolean>(
  CostEstimatorModalActionTypes.SET_SHOW_NEW_PRICING,
  "showNewPricing"
);
