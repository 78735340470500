import styled from "@emotion/styled";
import { ThemeProps } from "common/components/ui";

export const UnstyledButton = styled.button<ThemeProps<{ highlightTitle?: boolean }>>(
  ({ theme, highlightTitle }) => `
    appearance: none;
    border: 0;
    padding: 0;
    background-color: transparent;
    text-align: left;


    ${
      highlightTitle
        ? `&:hover .card-title {
        color: ${theme.colors.BLUE["300"]};
        transition: color 250ms linear; 
      }`
        : ""
    }
  `
);
