import { FeatureName, isFeatureOn } from "common/Split";
import { getProductIsFefoEnabled } from "common/utils/product/getProductIsFefoEnabled";
import { getProductIsLotTrackingEnabled } from "common/utils/product/getProductIsLotTrackingEnabled";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getStorageInboundDetail } from "../store";

export const useStorageShipmentContents = () => {
  const { formatMessage } = useIntl();
  const { storageInboundOrder, storageItemDetails, storageInboundOrderProblems } = useSelector(getStorageInboundDetail);
  const storageItemProducts = Object.values(storageItemDetails);
  const hasFefoEnabledProducts = storageItemProducts.filter(getProductIsFefoEnabled).length > 0;
  const hasLotTrackingEnabledProducts = storageItemProducts.filter(getProductIsLotTrackingEnabled).length > 0;
  const shouldShowLotInformation =
    isFeatureOn(FeatureName.ReserveStorageLotTracking) &&
    isFeatureOn(FeatureName.FefoExpirationDate) &&
    !isFeatureOn(FeatureName.SkipAddingLotExpiry) &&
    (hasFefoEnabledProducts || hasLotTrackingEnabledProducts);
  const title = formatMessage({
    id: "storage.inbounds.details.contents.title",
    defaultMessage: "CONTENTS",
  });

  return {
    storageItemDetails,
    storageInboundOrder,
    title,
    shouldShowLotInformation,
    storageInboundOrderProblems,
  };
};
