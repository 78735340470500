/**
 * @generated SignedSource<<7cba198bb7442e38e5ab96fc436cf8b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type usePlaceSearchDestinationQuery$variables = {
  isAddressOnly?: boolean | null;
  query?: string | null;
};
export type usePlaceSearchDestinationQuery$data = {
  readonly destinationSearch: {
    readonly cities: ReadonlyArray<{
      readonly address: string | null;
      readonly city: string;
      readonly country: string | null;
      readonly countryCode: string | null;
      readonly latitude: number | null;
      readonly longitude: number | null;
      readonly state: string | null;
      readonly streetAddress: string | null;
      readonly zipCode: string | null;
    } | null> | null;
    readonly ports: ReadonlyArray<{
      readonly city: {
        readonly city: string;
        readonly countryCode: string | null;
        readonly state: string | null;
      };
      readonly distanceKm: number | null;
      readonly nearbyPorts: ReadonlyArray<{
        readonly abbr: string;
        readonly fid: string;
      } | null> | null;
    } | null> | null;
  };
};
export type usePlaceSearchDestinationQuery = {
  response: usePlaceSearchDestinationQuery$data;
  variables: usePlaceSearchDestinationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isAddressOnly"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "isAddressOnly",
        "variableName": "isAddressOnly"
      },
      {
        "kind": "Variable",
        "name": "query",
        "variableName": "query"
      }
    ],
    "concreteType": "DestinationSearchResults",
    "kind": "LinkedField",
    "name": "destinationSearch",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "IpbCity",
        "kind": "LinkedField",
        "name": "cities",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "streetAddress",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "country",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "zipCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "latitude",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "longitude",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DestinationNearbyPorts",
        "kind": "LinkedField",
        "name": "ports",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "IpbCity",
            "kind": "LinkedField",
            "name": "city",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DestinationNearbyPort",
            "kind": "LinkedField",
            "name": "nearbyPorts",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "abbr",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fid",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "distanceKm",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "usePlaceSearchDestinationQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "usePlaceSearchDestinationQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "94f4c9bef361299244118be0a0ed656b",
    "id": null,
    "metadata": {},
    "name": "usePlaceSearchDestinationQuery",
    "operationKind": "query",
    "text": "query usePlaceSearchDestinationQuery(\n  $query: String\n  $isAddressOnly: Boolean\n) {\n  destinationSearch(query: $query, isAddressOnly: $isAddressOnly) {\n    cities {\n      address\n      streetAddress\n      city\n      state\n      country\n      zipCode\n      latitude\n      longitude\n      countryCode\n    }\n    ports {\n      city {\n        city\n        state\n        countryCode\n      }\n      nearbyPorts {\n        abbr\n        fid\n      }\n      distanceKm\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5f36a39bd0e43db12a3f271a3558d2c4";

export default node;
