import { createSelector } from "reselect";

import { RootState } from "RootReducer";
import { getLoadedShipmentId } from "../shipments/getLoadedShipmentId";

const getLtlState = (state: RootState) => state.inbound.ltl;

export const getShipmentLtl = createSelector(
  getLoadedShipmentId,
  getLtlState,
  (loadedShipmentId, ltlState) => ltlState[loadedShipmentId!] ?? {}
);
