import { BreakPoint, mediaQueries } from "./breakpoint";
import { Responsive } from "./Responsive";

/**
 * Converts a single CSS property to a valid CSS.
 * Based on the breakpoint, property name, property value, and (optional) helper function provided.
 * */
function getStyles<T>(bp: string, styleKey: string, styleValue: T, toCssHelper?: (value: T) => string): string {
  return bp === BreakPoint.BASE
    ? `${styleKey}: ${toCssHelper ? toCssHelper(styleValue) : styleValue};`
    : `${mediaQueries[bp]} { ${styleKey}: ${toCssHelper ? toCssHelper(styleValue) : styleValue}; }`;
}

/**
 * Converts a single CSS property (that might be a object with multiple breakpoints) to a valid CSS.
 * Based on the property name, property value, and (optional) helper function provided.
 * */
export function getResponsiveStyles<T>(
  styleKey: string,
  styleValue: Responsive<T>,
  toCssHelper?: (value: T) => string
): string {
  let styles: string = "";

  if (styleValue && typeof styleValue === "object") {
    styles = Object.entries(BreakPoint)
      .filter(([bp]) => styleValue[bp] !== undefined)
      .map(([bp]) => getStyles(bp, styleKey, styleValue[bp], toCssHelper))
      .join(" ");
  } else {
    styles = getStyles(BreakPoint.BASE, styleKey, styleValue, toCssHelper);
  }

  return styles;
}
