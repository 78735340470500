import { OrderItemDto } from "@deliverr/replenishment-client";
import { SkuCaseQuantity } from "common/clients/inbound/SkuQuantity/SkuCaseQuantity";
import { SelectedProductData } from "common/components/ProductChooser";
import { FeatureName, getFeatureSelector } from "common/Split";
import { mapProductsToMultiPackSkuInventory } from "storage/packUtils/mapProductsToMultiPackSkuInventory";
import { getSelectedProductsWithPackOf } from "./getSelectedProductsWithPackOf";
import { SPThunkAction } from "common/ReduxUtils";
import { StorageWarehouseInventory } from "common/clients/storage/StorageWarehouseInventory";
import { ATPDetails } from "common/clients/inventory/ATP/ATPDetails";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";

export const getShipmentItems =
  (
    selectedProductsQty: SelectedProductData[],
    originStorageWarehouse: StorageWarehouseInventory | undefined,
    selectedProductsLotNumbers?: { [dsku: string]: ATPDetails | undefined }
  ): SPThunkAction<Promise<OrderItemDto[]>> =>
  async (dispatch, getState) => {
    const { isEachPickingOrder } = selectTransferCreate(getState());
    const isMultiCasePackEnabled = getFeatureSelector(FeatureName.StorageMultiCasePack)(getState());
    let inventoryBreakdown: (SkuCaseQuantity & { lotNumber?: string; expirationDate?: string })[] = [];
    if (isMultiCasePackEnabled) {
      inventoryBreakdown = mapProductsToMultiPackSkuInventory(
        selectedProductsQty,
        (originStorageWarehouse as any)?.inventoryBreakdown ?? {},
        true,
        selectedProductsLotNumbers
      );
    }
    const selectedProductsQtyWithMultiCasePriorityInventory = isMultiCasePackEnabled
      ? inventoryBreakdown
      : selectedProductsQty;

    const selectedProductsQtyWithPackOf = await getSelectedProductsWithPackOf(
      dispatch,
      selectedProductsQtyWithMultiCasePriorityInventory
    );
    const shipmentItems: OrderItemDto[] = selectedProductsQtyWithPackOf.map(
      ({ dsku, qty, caseQty, packOf, lotNumber, expirationDate }) => ({
        dsku,
        totalUnits: qty,
        unitsPerPack: isEachPickingOrder ? 1 : caseQty ?? 1,
        packOf,
        lotNumber,
        expirationDate,
      })
    );

    return shipmentItems;
  };
