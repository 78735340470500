import { defineMessages } from "react-intl";

export const STORAGE_DESTINATION_MESSAGES = defineMessages({
  recommendedWarehouse: {
    id: "storage.inbounds.destination.recommendedWarehouse.label",
    defaultMessage: "Recommended location",
  },
  specificWarehouse: {
    id: "storage.inbounds.destination.specificWarehouse.label",
    defaultMessage: "Select a different location",
  },
});
