import AlgoliaService from "../../list/AlgoliaService";
import { InventorySearchService } from "common/search/services/InventorySearchService";
import { ListType } from "common/list";
import { LogisticsSearchConfig } from "common/search/services/LogisticsSearchConfig";
import { Products } from "./Products";
import { SearchResults } from "common/search/SearchService";
import { configFor } from "common/search/services/SearchConfig";
import log from "Logger";

const isLogisticsSearchProductsReadEnabled = true;

const algoliaInventoryService = AlgoliaService.get({
  indexName: process.env.ALGOLIA_INDEX_INVENTORY!,
  searchConfig: {
    hitsPerPage: 10,
    maxValuesPerFacet: 1,
  },
});

const inventorySearchService = new InventorySearchService(configFor(ListType.InventoryV2) as LogisticsSearchConfig);

export const fetchProductsByDskus: (dskus: string[]) => Promise<Products> = async (dskus) => {
  const ctx = { fn: "addProductsByDsku" };
  log.info(ctx, "Searching products by dsku");
  const productsInInventory = await search(dskus);
  if (productsInInventory.hits.length) {
    return productsInInventory.hits.reduce((acc, product) => {
      acc[product.dsku] = product;
      return acc;
    }, {});
  }
  return {};
};

async function search(dskus: string[]): Promise<SearchResults> {
  if (isLogisticsSearchProductsReadEnabled) {
    return await inventorySearchService.searchByIds(dskus, "", "dsku");
  }

  return await algoliaInventoryService.searchByIds(dskus, "", "dsku");
}
