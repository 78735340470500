import history from "BrowserHistory";
import { Path } from "paths";
import React from "react";
import { useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { getStorageInboundCreate } from "../store/selector/getStorageInboundCreate";
import { shipmentConfirmedInstructionLabels } from "./shipmentConfirmedInstruction.labels";
import { ShipmentInstructionItem } from "./ShipmentInstructionItem";

export const useShipmentConfirmed = () => {
  const { shippingPlanId } = useParams<{ shippingPlanId: string }>();
  const onNextClick = () => {
    history.push(generatePath(Path.inboundsStorageDetail, { shippingPlanId }));
  };
  const { cargoType, carrierType } = useSelector(getStorageInboundCreate);
  const shipmentConfirmedInstructionSteps = Object.values(
    shipmentConfirmedInstructionLabels(cargoType, carrierType)
  ).map((shipmentInstructionLabel, index) => {
    return (
      <ShipmentInstructionItem
        key={shipmentInstructionLabel.id}
        index={index}
        shipmentInstructionLabel={shipmentInstructionLabel}
      />
    );
  });

  return {
    onNextClick,
    shipmentConfirmedInstructionSteps,
  };
};
