import { ContainerSize, PalletSize } from "@deliverr/storage-client";

export const STORAGE_INBOUNDS_RATE = {
  storageFeePerMonth: 14,
  palletInboundFee: [
    {
      size: PalletSize.STANDARD_60IN,
      fee: 13,
    },
  ],
  containerUnloadingFee: [
    {
      size: ContainerSize.GENERAL_40FT,
      fee: 650,
    },
    {
      size: ContainerSize.GENERAL_20FT,
      fee: 325,
    },
  ],
  currency: "USD",
};
