export enum FontFamily {
  STANDARD = "STANDARD",
  TITLE = "TITLE",
}

export enum FontSize {
  F1 = "F1",
  F2 = "F2",
  F3 = "F3",
  F4 = "F4",
  F5 = "F5",
  F6 = "F6",
  F7 = "F7",
}

export enum LineHeight {
  LH1 = "LH1",
  LH2 = "LH2",
  LH3 = "LH3",
  LH4 = "LH4",
  LH5 = "LH5",
  LH6 = "LH6",
  LH7 = "LH7",
}

export enum LetterSpacing {
  LS0 = "LS0",
  LS1 = "LS1",
  LS2 = "LS2",
  LS3 = "LS3",
  LS4 = "LS4",
  LS5 = "LS5",
}

export enum FontWeight {
  REGULAR = "REGULAR",
  BOLD = "BOLD",
  BLACK = "BLACK",
}

const FAMILY: Record<FontFamily, string> = {
  [FontFamily.STANDARD]: "Lato, sans-serif",
  [FontFamily.TITLE]: "Montserrat, sans-serif",
};

const SIZE: Record<FontSize, string> = {
  [FontSize.F1]: "0.75rem",
  [FontSize.F2]: "0.875rem",
  [FontSize.F3]: "1rem",
  [FontSize.F4]: "1.125rem",
  [FontSize.F5]: "1.375rem",
  [FontSize.F6]: "1.75rem",
  [FontSize.F7]: "2.25rem",
};

const LINEHEIGHT: Record<LineHeight, string> = {
  [LineHeight.LH1]: "1rem",
  [LineHeight.LH2]: "1.25rem",
  [LineHeight.LH3]: "1.5rem",
  [LineHeight.LH4]: "1.625rem",
  [LineHeight.LH5]: "2rem",
  [LineHeight.LH6]: "2.25rem",
  [LineHeight.LH7]: "2.625rem",
};

const WEIGHT: Record<FontWeight, string> = {
  [FontWeight.REGULAR]: "400",
  [FontWeight.BOLD]: "700",
  [FontWeight.BLACK]: "900",
};

const LETTERSPACING: Record<LetterSpacing, string> = {
  [LetterSpacing.LS0]: "0rem",
  [LetterSpacing.LS1]: "0rem",
  [LetterSpacing.LS2]: "0rem",
  [LetterSpacing.LS3]: "0rem",
  [LetterSpacing.LS4]: "0.0437rem",
  [LetterSpacing.LS5]: "0rem",
};

export const font = {
  /**
   * @constant `STANDARD` Lato, sans-serif
   * @constant `TITLE` Montserrat, sans-serif
   */
  family: FAMILY,
  /**
   * @constant `F1` 12px | 0.75rem
   * @constant `F2` 14px | 0.875rem
   * @constant `F3` 16px | 1rem
   * @constant `F4` 18px | 1.125rem
   * @constant `F5` 22px | 1.375rem
   * @constant `F6` 28px | 1.75rem
   * @constant `F7` 36px | 2.25rem
   */
  size: SIZE,
  /**
   * @constant `REGULAR` 400
   * @constant `BOLD` 700
   * @constant `BLACK` 900
   */
  weight: WEIGHT,
  /**
   * @constant `LH1` 16px | 1rem
   * @constant `LH2` 20px | 1.25rem
   * @constant `LH3` 24px | 1.5rem
   * @constant `LH4` 26px | 1.625rem
   * @constant `LH5` 32px | 2rem
   * @constant `LH6` 36px | 2.25rem
   * @constant `LH7` 42px | 2.625rem
   */
  lineHeight: LINEHEIGHT,
  letterSpacing: LETTERSPACING,
};
