import { createSelector } from "reselect";
import { getShipmentLtl } from "./getShipmentLtl";
import { DeliverrLtlShipment } from "inbounds/InboundTypes";
import { RootState } from "RootReducer";
import { LTL_MAX_PALLET_COUNT } from "freight/constants/cargoPalletConstants";

export const selectIsLtlPallets = createSelector<RootState, DeliverrLtlShipment, boolean>(
  getShipmentLtl,
  ({ numberOfPallets }) => numberOfPallets <= LTL_MAX_PALLET_COUNT
);
