import React from "react";
import { ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";

import HtmlToReact from "common/components/HtmlToReact";
import { packingInstructionsLabels } from "./packingInstructions.labels";
import LabeledPalletsSvg from "assets/img/inbounds/packing-instructions-pallet.svg";
import {
  LTL_MAX_INDIVIDUAL_PALLET_WEIGHT,
  LTL_MAX_INDIVIDUAL_PALLET_HEIGHT,
} from "freight/constants/cargoPalletConstants";

const StyledFigure = styled.figure<ThemeProps>(
  ({ theme }) => `
    position: relative;
    margin: ${theme.spacing.S6} auto 0 auto;
    height: 355px;
    width: 830px;
  `
);

const StyledHtmlToReact = styled(HtmlToReact)<ThemeProps>(
  ({ theme }) => `
    margin: auto ${theme.spacing.S9} auto ;
    width: 75%;
  `
);

const LabelsContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    position: absolute;
    top: 0;
    left: 0;
    width: 830px;
    height: 312.53px;
    figcaption {
      font-size: ${theme.font.size.F2};
      line-height: ${theme.font.lineHeight.LH2};
      position: absolute;
    };
  `
);

const FigCaptionCasePack = styled.figcaption`
  width: 154px;
  left: 0;
  top: 4px;
`;

const FigCaptionWoodenPallets = styled.figcaption`
  width: 139.11px;
  left: 344px;
  top: 249px;
`;

const FigCaptionMaxHeightLabel = styled.figcaption`
  width: 120px;
  left: 700px;
  top: 55px;
  bottom: 143px;
`;

const FigCaptionPalletLabel = styled.figcaption`
  width: 120px;
  left: 700px;
  top: 248px;
  bottom: 143px;
`;

const FigCaptionMaxWeight = styled.figcaption`
  width: 149px;
  left: 339px;
  top: 30px;
`;

export const PalletPackingInstructions: React.FC = () => {
  return (
    <StyledFigure>
      <StyledHtmlToReact key="storage.inbounds.detail.palletPackingInstructions" html={LabeledPalletsSvg} />
      <LabelsContainer>
        <FigCaptionCasePack>
          <FormattedMessage {...packingInstructionsLabels.casePackLabel} />
        </FigCaptionCasePack>
        <FigCaptionWoodenPallets>
          <FormattedMessage {...packingInstructionsLabels.woodenPalletsLabel} />
        </FigCaptionWoodenPallets>
        <FigCaptionMaxWeight>
          <FormattedMessage
            {...packingInstructionsLabels.maxWeightLabel}
            values={{
              maxWeight: LTL_MAX_INDIVIDUAL_PALLET_WEIGHT,
            }}
          />
        </FigCaptionMaxWeight>
        <FigCaptionMaxHeightLabel>
          <FormattedMessage
            {...packingInstructionsLabels.maxHeightLabel}
            values={{
              maxHeight: LTL_MAX_INDIVIDUAL_PALLET_HEIGHT,
            }}
          />
          {}
        </FigCaptionMaxHeightLabel>
        <FigCaptionPalletLabel>
          <FormattedMessage {...packingInstructionsLabels.palletLabel} />
        </FigCaptionPalletLabel>
      </LabelsContainer>
    </StyledFigure>
  );
};
