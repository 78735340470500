import sumBy from "lodash/sumBy";
import { RootState } from "RootReducer";
import { PrepCreationSource } from "prep/types/PrepCreationSource";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { selectCasePackTotalCaseQty } from "inbounds/store/selectors/plan/selectCasePackTotalCaseQty";

export const fetchCasePackTotalCaseQty =
  (source: PrepCreationSource) =>
  (state: RootState): number => {
    if (source === PrepCreationSource.INBOUNDS) {
      return selectCasePackTotalCaseQty(state);
    } else {
      const transferState = selectTransferCreate(state);
      const items = transferState.replenishmentOrder?.orderItems;
      return items && items.length > 0 ? sumBy(items, (item) => item.totalUnits / (item.unitsPerPack || 1)) : 0;
    }
  };
