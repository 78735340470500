import { DeliverrAddress } from "@deliverr/commons-objects";

// TODO: Temporary solution till we can utilize Port Primitives
export enum Ports {
  PORT_OF_NEWARK = "PORT_OF_NEWARK",
  PORT_OF_LONG_BEACH = "PORT_OF_LONG_BEACH",
  PORT_OF_LOS_ANGELES = "PORT_OF_LOS_ANGELES",
  PORT_OF_SAVANNAH = "PORT_OF_SAVANNAH",
  PORT_OF_JERSEY = "PORT_OF_JERSEY",
  OTHER = "OTHER",
}

export enum AllPorts {
  PORT_OF_NEWARK = "PORT_OF_NEWARK",
  PORT_OF_LONG_BEACH = "PORT_OF_LONG_BEACH",
  PORT_OF_LOS_ANGELES = "PORT_OF_LOS_ANGELES",
  PORT_OF_SAVANNAH = "PORT_OF_SAVANNAH",
  PORT_OF_JERSEY = "PORT_OF_JERSEY",
  PORT_OF_HOUSTON = "PORT_OF_HOUSTON",
  PORT_AUTHORITY_OF_NY_AND_NJ = "PORT_AUTHORITY_OF_NY_AND_NJ",
  JACKSONVILLE_PORT_AUTHORITY = "JACKSONVILLE_PORT_AUTHORITY",
  PORT_OF_CHICAGO = "PORT_OF_CHICAGO",
  CHARLESTON_PORT = "CHARLESTON_PORT",
  OTHER = "OTHER",
}

export const PORT_ID_MAP: Record<AllPorts, string> = {
  [AllPorts.PORT_OF_NEWARK]: "EWR",
  [AllPorts.PORT_OF_LONG_BEACH]: "LGB",
  [AllPorts.PORT_OF_LOS_ANGELES]: "LAX",
  [AllPorts.PORT_OF_SAVANNAH]: "SAV",
  [AllPorts.PORT_OF_JERSEY]: "BAY",
  [AllPorts.PORT_OF_HOUSTON]: "HOU",
  [AllPorts.PORT_AUTHORITY_OF_NY_AND_NJ]: "NYC",
  [AllPorts.JACKSONVILLE_PORT_AUTHORITY]: "JAX",
  [AllPorts.PORT_OF_CHICAGO]: "CHI",
  [AllPorts.CHARLESTON_PORT]: "CHS",
  [AllPorts.OTHER]: "UNKNOWN",
};

export const PORT_ADDRESSES: Record<AllPorts, DeliverrAddress> = {
  [AllPorts.PORT_OF_CHICAGO]: {
    street1: "3600 East 95th Street",
    city: "Chicago",
    state: "IL",
    zip: "60617",
    country: "US",
    name: "Port of Chicago",
  },
  [AllPorts.CHARLESTON_PORT]: {
    street1: "176 Concord Street",
    city: "Charleston",
    state: "SC",
    zip: "29401",
    country: "US",
    name: "Charleston Port",
  },
  [AllPorts.PORT_OF_HOUSTON]: {
    street1: "111 East Loop North",
    city: "Houston",
    state: "TX",
    zip: "77252-2562",
    country: "US",
    name: "Port of Houston",
  },
  [AllPorts.JACKSONVILLE_PORT_AUTHORITY]: {
    street1: "2831 Talleyrand Ave",
    city: "Jacksonville",
    state: "FL",
    zip: "32206",
    country: "US",
    name: "Jacksonville Port Authority",
  },
  [AllPorts.PORT_AUTHORITY_OF_NY_AND_NJ]: {
    street1: "225 Park Avenue South",
    city: "New York",
    state: "NY",
    zip: "10003",
    country: "US",
    name: "Port Authority of New York & New Jersey",
  },
  [AllPorts.PORT_OF_NEWARK]: {
    street1: "120 Corbin St",
    city: "Elizabeth",
    state: "NJ",
    zip: "07201",
    country: "US",
    name: "Port of Newark",
  },
  [AllPorts.PORT_OF_LONG_BEACH]: {
    street1: "415 W Ocean Blvd",
    city: "Long Beach",
    state: "CA",
    zip: "90802",
    country: "US",
    name: "Port of Long Beach",
  },
  [AllPorts.PORT_OF_LOS_ANGELES]: {
    street1: "425 S Palos Verdes St",
    city: "Los Angeles",
    state: "CA",
    zip: "90731",
    country: "US",
    name: "Port of Los Angeles",
  },
  [AllPorts.PORT_OF_SAVANNAH]: {
    street1: "2 Main St",
    city: "Port Wentworth",
    state: "GA",
    zip: "31407",
    country: "US",
    name: "Port of Savannah",
  },
  [AllPorts.PORT_OF_JERSEY]: {
    street1: "302 Port Jersey Boulevard",
    city: "Jersey City",
    state: "NJ",
    zip: "07305",
    country: "US",
    name: "Port of Jersey",
  },
  [AllPorts.OTHER]: {
    street1: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    name: "",
  },
};
