import { LocalizedMessage } from "common/components/ui";
import { CurrencyValue, useCurrencyValue } from "common/components/Currency";
import { commonMsgs } from "inbounds/utils/commonMsgs";
import { sum } from "lodash";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { PREP_CHARGE_GRANULARITY_LABELS, PREP_COST_SPECIAL_HANDLING_LABELS } from "../labels";
import {
  FLOOR_LOADED_CONTAINER_PRICING,
  PREP_COST_CONFIGS,
  PREP_COST_CONFIGS_REPLENISHMENT,
} from "../prepCostConfigs.const";
import { PrepChargeGranularity } from "../types";
import { usePrepBoxQuantities } from "./usePrepBoxQuantities";
import { selectFloorLoadedContainerDetails } from "../store/selectors/selectFloorLoadedContainerDetails";
import { FloorLoadedContainerType, PrepType } from "@deliverr/prep-service-client";
import { SuperPrepType } from "prep/types";
import { getContainerLengthInFeet } from "prep/util/getContainerLengthInFeet";
import { fetchTotalUnits } from "prep/store/selectors/fetchTotalUnits";
import { selectPrepCreationSource } from "prep/store/selectors/selectPrepCreationSource";
import { PrepCreationSource } from "prep/types/PrepCreationSource";
import { fetchProductDetails } from "prep/store/selectors/fetchProductDetails";

export interface PrepTypeCostValues {
  formattedItemCost?: CurrencyValue;
  formattedTotalCost?: CurrencyValue;
  formattedItemCostWithGranularity?: LocalizedMessage;
  formattedItemQty: LocalizedMessage;
  totalCost: number;
}

export const usePrepCostConfigs = () => {
  const { formatMessage } = useIntl();
  const formatTotalCurrency = useCurrencyValue({ maximumFractionDigits: 2 });
  const formatItemCurrency = useCurrencyValue({ maximumFractionDigits: 2 });
  const { numEstimatedBoxes } = usePrepBoxQuantities();
  const source = useSelector(selectPrepCreationSource);
  const prepCostConfigs = source === PrepCreationSource.INBOUNDS ? PREP_COST_CONFIGS : PREP_COST_CONFIGS_REPLENISHMENT;
  const totalShippingPlanUnits = useSelector(fetchTotalUnits(source));
  const floorLoadedContainerDetails = useSelector(selectFloorLoadedContainerDetails);
  const selectedKitProductDetails = useSelector(fetchProductDetails(source));
  const totalShippingPlanKitUnits = selectedKitProductDetails.length;

  /** Returns the formatted string with the quantification unit appended */
  const getQuantityWithUnits = useCallback(
    (prepType: SuperPrepType, quantity: number): LocalizedMessage | undefined => {
      if (prepType === PrepType.FLOOR_LOADED_CONTAINER) {
        return formatMessage(commonMsgs.containerWithLength, {
          containerLengthInFeet: getContainerLengthInFeet(floorLoadedContainerDetails?.type),
        });
      }

      const costConfig = prepCostConfigs[prepType];
      if (!costConfig) {
        return;
      }

      // special display for cases where we don't know the quantity (i.e. BCL Prep without case packs)
      if (quantity === 0) {
        return formatMessage(commonMsgs.valueToBeDetermined);
      }

      switch (costConfig.chargeGranularity) {
        case PrepChargeGranularity.PER_BOX:
          return formatMessage(commonMsgs.box, {
            boxesQty: quantity,
          });
        case PrepChargeGranularity.PER_UNIT:
          return formatMessage(commonMsgs.units, {
            units: quantity,
          });
        case PrepChargeGranularity.PER_KIT:
          return formatMessage(commonMsgs.units, {
            units: quantity,
          });
        default:
          return formatMessage(commonMsgs.valueToBeDetermined);
      }
    },
    [formatMessage, prepCostConfigs, floorLoadedContainerDetails]
  );

  // We only support a single floor loaded container at this time, and the display values are based on the length
  // of the container rather than on the quantity of containers, as such, we use a specific function for this case.
  const getFloorLoadedContainerCostDetails = useCallback(
    (containerType?: FloorLoadedContainerType): PrepTypeCostValues | undefined => {
      if (!containerType) {
        return;
      }
      const totalCost = FLOOR_LOADED_CONTAINER_PRICING[containerType];
      const costPerItem = formatItemCurrency(totalCost);

      return {
        totalCost,
        formattedTotalCost: formatTotalCurrency(totalCost),
        formattedItemCost: costPerItem,
        formattedItemQty: getQuantityWithUnits(PrepType.FLOOR_LOADED_CONTAINER, 1)!,
        formattedItemCostWithGranularity: formatMessage(PREP_CHARGE_GRANULARITY_LABELS.PER_CONTAINER, {
          costPerItem,
        }),
      };
    },
    [formatTotalCurrency, formatItemCurrency, formatMessage, getQuantityWithUnits]
  );

  /** Returns the cost details for a single kitting product */
  const getKittingPrepTypeCostValuesPerKit = useCallback(
    (totalComponentsQuantity: number, quantity: number): PrepTypeCostValues | undefined => {
      const costConfig = prepCostConfigs[PrepType.KITTING];
      if (!costConfig) {
        return;
      }

      const { costPerItemUsd: costPerComponentUsd, chargeGranularity, baseFee } = costConfig;

      const costPerKit = (baseFee ?? 0) + totalComponentsQuantity * costPerComponentUsd;

      const unadjustedTotal = costPerKit * quantity;
      const totalCost = Math.round(unadjustedTotal * 100) / 100;

      return {
        totalCost,
        formattedTotalCost: formatTotalCurrency(totalCost),
        formattedItemCost: formatItemCurrency(costPerKit),
        formattedItemQty: getQuantityWithUnits(PrepType.KITTING, quantity)!,
        formattedItemCostWithGranularity: formatMessage(PREP_CHARGE_GRANULARITY_LABELS[chargeGranularity], {
          costPerItem: formatItemCurrency(costPerKit),
        }),
      };
    },
    [prepCostConfigs, formatItemCurrency, formatMessage, formatTotalCurrency, getQuantityWithUnits]
  );

  /** Returns the total cost for all kitting products */
  const getKittingPrepTypeTotalCostValue = useCallback((): PrepTypeCostValues | undefined => {
    let { totalCost, totalQty } = selectedKitProductDetails.reduce(
      (acc, kitProduct) => {
        const totalCostPerKit =
          getKittingPrepTypeCostValuesPerKit(kitProduct.totalComponentQuantity, kitProduct.qty)?.totalCost ?? 0;
        return {
          totalCost: acc.totalCost + totalCostPerKit,
          totalQty: acc.totalQty + kitProduct.qty,
        };
      },
      { totalCost: 0, totalQty: 0 }
    );

    totalCost = Math.round(totalCost * 100) / 100;

    return {
      totalCost,
      formattedTotalCost: formatTotalCurrency(totalCost),
      formattedItemQty: getQuantityWithUnits(PrepType.KITTING, totalQty)!,
    };
  }, [selectedKitProductDetails, formatTotalCurrency, getQuantityWithUnits, getKittingPrepTypeCostValuesPerKit]);

  /** Returns cost summary information for a given Prep Type and quantity, including formatted labels */
  const getPrepTypeCostValues = useCallback(
    (prepType: SuperPrepType, quantity: number): PrepTypeCostValues | undefined => {
      if (prepType === PrepType.FLOOR_LOADED_CONTAINER && floorLoadedContainerDetails) {
        return getFloorLoadedContainerCostDetails(floorLoadedContainerDetails.type);
      }

      if (prepType === PrepType.KITTING) {
        return getKittingPrepTypeTotalCostValue();
      }

      const costConfig = prepCostConfigs[prepType];
      if (!costConfig) {
        return;
      }

      const { costPerItemUsd, chargeGranularity, specialHandling } = costConfig;

      const costPerItem = formatItemCurrency(costPerItemUsd);

      const costSpecialHandling = specialHandling
        ? formatMessage(PREP_COST_SPECIAL_HANDLING_LABELS[specialHandling])
        : undefined;

      const unadjustedTotal = costPerItemUsd * quantity;
      // required because of how JavaScript handles floating point numbers
      const totalCost = Math.round(unadjustedTotal * 100) / 100;

      return {
        totalCost,
        formattedTotalCost: formatTotalCurrency(totalCost),
        formattedItemCost: costPerItem,
        formattedItemQty: getQuantityWithUnits(prepType, quantity)!,
        formattedItemCostWithGranularity: formatMessage(PREP_CHARGE_GRANULARITY_LABELS[chargeGranularity], {
          costPerItem,
          costSpecialHandling,
        }),
      };
    },
    [
      floorLoadedContainerDetails,
      prepCostConfigs,
      formatItemCurrency,
      formatMessage,
      formatTotalCurrency,
      getQuantityWithUnits,
      getFloorLoadedContainerCostDetails,
      getKittingPrepTypeTotalCostValue,
    ]
  );

  /**
   * Returns the quantity to use for the given prep type's cost config
   */
  const getQuantityForCostConfig = useCallback(
    (prepType: SuperPrepType): number => {
      // For floor loaded containers we only support a single container charged on length so it's treated differently
      if (prepType === PrepType.FLOOR_LOADED_CONTAINER) {
        return 1;
      }
      switch (prepCostConfigs[prepType]?.chargeGranularity) {
        case PrepChargeGranularity.PER_BOX:
          return numEstimatedBoxes;
        case PrepChargeGranularity.PER_UNIT:
          return totalShippingPlanUnits;
        case PrepChargeGranularity.PER_KIT:
          return totalShippingPlanKitUnits;
        default:
          return 0;
      }
    },
    [numEstimatedBoxes, prepCostConfigs, totalShippingPlanKitUnits, totalShippingPlanUnits]
  );

  /**
   * Returns the total cost for the given prep types
   */
  const getTotalPrepCost = useCallback(
    (requestedPrepTypes: SuperPrepType[]): number => {
      return sum(
        requestedPrepTypes.map((prepType) => {
          const relevantQuantity = getQuantityForCostConfig(prepType);
          return getPrepTypeCostValues(prepType, relevantQuantity)?.totalCost ?? 0;
        })
      );
    },
    [getPrepTypeCostValues, getQuantityForCostConfig]
  );

  return {
    getPrepTypeCostValues,
    getKittingPrepTypeCostValuesPerKit,
    prepCostConfigs,
    getTotalPrepCost,
    getQuantityForCostConfig,
  };
};
