import { MessageDescriptor } from "react-intl";
import { ReturnOrderMinimal } from "@deliverr/returns-client";

export enum ReturnsLabelActionTypes {
  RETURN_INFO_SUCCESS = "RETURN_INFO_SUCCESS",
  RETURN_INFO_FAIL = "RETURN_INFO_FAIL",
  RETURN_LABEL_RESET = "RETURN_LABEL_RESET",
  SHARE_RETURN_LABEL_SUCCESS = "SHARE_RETURN_LABEL_SUCCESS",
  SHARE_RETURN_LABEL_FAIL = "SHARE_RETURN_LABEL_FAIL",
}

export interface ReturnsLabelState {
  returnItem?: ReturnOrderMinimal;
  error?: MessageDescriptor;
  shareLabelStatus?: Boolean;
  email?: string;
}
