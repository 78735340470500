import React from "react";

import { FormattedMessage } from "react-intl";

export const deleteShippingPlansError = (
  <FormattedMessage
    id="inbounds.inboundList.deleteShippingPlansErrorMessage"
    defaultMessage="Failed to delete shipping plans, please try again or contact support."
  />
);
