export const LTL_MAX_PALLET_COUNT = 6;
export const LTL_PALLET_INITIAL_WIDTH = 48;
export const LTL_PALLET_INITIAL_LENGTH = 40;

export const LTL_MAX_INDIVIDUAL_PALLET_WEIGHT = 2000;

export const LTL_MAX_INDIVIDUAL_PALLET_HEIGHT = 90;
export const LTL_MAX_INDIVIDUAL_PALLET_LENGTH = 50;
export const LTL_MAX_INDIVIDUAL_PALLET_WIDTH = 50;

export const LTL_MAX_TOTAL_PALLET_WEIGHT = 42000;
export const LTL_MIN_TOTAL_PALLET_WEIGHT = 150;

export const FTL_PALLETS_PER_TRUCK = 30;

export const MINIMUM_DRAYAGE_CONTAINER_COUNT = 1;
