import { SearchOptions } from "@deliverr/logistics-search-client";
import { SearchRequest } from "../SearchService";
import { OpenSearchService } from "common/search/services/OpenSearchService";

export class CarrierSearchService extends OpenSearchService {
  protected buildSearchOptions(request: SearchRequest): SearchOptions {
    const searchOptions = super.buildSearchOptions(request);
    const { searchTerm } = request;
    const shouldQueries: any[] = [];
    const CODE_FIELD = "code";
    const NAME_FIELD = "name";

    if (searchTerm) {
      shouldQueries.push({
        query_string: {
          query: [CODE_FIELD, NAME_FIELD].map((f) => `${f}:"*${searchTerm}*"`).join(" OR "),
          analyze_wildcard: true,
        },
      });
    }

    return {
      ...searchOptions,
      query: {
        bool: {
          should: shouldQueries,
          minimum_should_match: 1,
        },
      },
      hydrate: true,
    };
  }
}
