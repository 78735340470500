// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavBar__breadcrumbs--Pfb_C ._elevio_tooltip {\n  visibility: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/app/common/components/NavBar/NavBar.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF","sourcesContent":["// elevio strangely places tooltip on second li of any ol\n// TODO: remove when able to change elevio behavior\n.breadcrumbs :global(._elevio_tooltip) {\n  visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": "NavBar__breadcrumbs--Pfb_C"
};
export default ___CSS_LOADER_EXPORT___;
