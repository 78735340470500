import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { getHelpCenterBaseUrl } from "common/support/helpCenter";
import { ExternalLink } from "../ExternalLink";
import { useSidebarContext } from "./SidebarContext";
import { SidebarItemContainer } from "./SidebarItemContainer";
import { SidebarItemContent } from "./SidebarItemContent";

/**
 * Sidebar item that opens Deliverr Help Center in new tab.
 */
export const SidebarHelpItem: FC = () => {
  const { mode, onItemClick } = useSidebarContext();

  return (
    <SidebarItemContainer mode={mode} active={false}>
      <ExternalLink href={getHelpCenterBaseUrl()} onClick={onItemClick}>
        <SidebarItemContent
          icon="question-circle"
          description={<FormattedMessage id="sidebar.help" defaultMessage="Help Center" />}
        />
      </ExternalLink>
    </SidebarItemContainer>
  );
};
