import styled from "@emotion/styled";
import { BorderlessButton } from "common/components/BorderlessButton";
import { DefaultTheme, Stack, ThemeProps, Title, Text, Grid, Box } from "common/components/ui";
import { Direction, TextAlign } from "common/components/ui/shared";
import { CreateShipmentFormState } from "inbounds/createShipment/store";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import React from "react";
import { FieldArrayWithId } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { DtcMixedSkuNumberOfBoxesInput } from "./mixedSkuBoxConfigurationInputs/DtcMixedSkuNumberOfBoxesInput";
import { DtcMixedSkuBoxWeightInput } from "./mixedSkuBoxConfigurationInputs/DtcMixedSkuBoxWeightInput";
import { DtcMixedSkuBoxDimensionsInput } from "./mixedSkuBoxConfigurationInputs/DtcMixedSkuBoxDimensionsInput";
import { PackageSummary } from "inbounds/createShipment/CreateShipmentTypes";
import { sum } from "lodash";
import { IntlDtcInboundProductChooser } from "storage/inbounds/create/selectProducts/IntlDtcInboundProductChooser/IntlDtcInboundProductChooser";
import { useDtcMixedSkuBoxConfiguration } from "./useDtcMixedSkuBoxConfiguration";
import { InputError } from "common/components/InputError";
import { getDimensionErrorLabel } from "inbounds/steps/dimensions/DimensionsAndWeightsTable/DimensionsRowError.labels";

const StyledContainer = styled.section<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S3} ${theme.spacing.S3} ${theme.spacing.S3};
  background-color: ${theme.colors.NEUTRAL[40]};
  display: flex;
  justify-content: space-between;
`
);

const ProductChooserContainer = styled.section<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S5} ${theme.spacing.S5} ${theme.spacing.S2};
  border-top: 1px solid ${theme.colors.NEUTRAL[80]};
  border-bottom: 1px solid ${theme.colors.NEUTRAL[80]};
`
);

const StyledButton = styled(BorderlessButton)<{}, DefaultTheme>(
  ({ theme }) => `
    color: ${theme.colors[theme.config.colorByAppearance.PRIMARY][300]};

    &:hover {
      color: #11bfe3;
    }
    font-weight: bold;
  `
);

const TotalUnitContainer = styled(Box)`
  align-self: start;
  padding-left: 20px;
`;

const TotalUnitLabel = () => (
  <FormattedMessage id="createShipment.dtc.cargoDetailStep.mixedSku.totalUnits" defaultMessage="Total units" />
);

const BoxConrigurationHeader = ({
  index,
  isRemoveDisabled,
  removeBox,
}: {
  index: number;
  isRemoveDisabled: boolean;
  removeBox: (index?: number | number[]) => void;
}) => {
  return (
    <StyledContainer>
      <Title as="h4" displayAs="h5" uppercase>
        <FormattedMessage
          id="createShipment.cargoDetail.dtc.mixedSkuBoxConriguration.title"
          defaultMessage="box configuration {index}"
          values={{
            index: index + 1,
          }}
        />
      </Title>
      {!isRemoveDisabled && (
        <StyledButton onClick={() => removeBox(index)}>
          <FormattedMessage
            id="createShipment.cargoDetail.dtc.mixedSkuBoxConriguration.removeBox"
            defaultMessage="Remove"
            values={{
              index,
            }}
          />
        </StyledButton>
      )}
    </StyledContainer>
  );
};

const MixedSkuProductChooser = ({
  pkg,
  index,
  mixedSkuPackage,
}: {
  pkg: FieldArrayWithId<CreateShipmentFormState, CreateShipmentInputName.MIXED_SKU_PACKAGES, "id">;
  index: number;
  mixedSkuPackage: PackageSummary;
}) => {
  const unitSum = (sum(mixedSkuPackage?.items?.map((item) => item.qty)) ?? 0) * (mixedSkuPackage.numberOfPkgs ?? 0);
  const { mixedSkuInputValidationError } = useDtcMixedSkuBoxConfiguration(index);
  return (
    <ProductChooserContainer>
      <IntlDtcInboundProductChooser
        isIntlRs={false}
        isBulkUploadButtonEnabled={false}
        isDtcMixedSkuBooking
        index={index}
      />
      {pkg.items.length > 0 && (
        <Box paddingTop="S6">
          <Grid columns="13fr 2fr 2fr 1fr">
            <Stack direction={Direction.HORIZONTAL}>
              <DtcMixedSkuBoxWeightInput pkg={pkg} index={index} />
              <DtcMixedSkuBoxDimensionsInput pkg={pkg} index={index} />
            </Stack>
            <DtcMixedSkuNumberOfBoxesInput pkg={pkg} index={index} />
            <Stack>
              <Text size="body" textAlign={TextAlign.start}>
                <TotalUnitLabel />
              </Text>
              <TotalUnitContainer>{unitSum}</TotalUnitContainer>
            </Stack>
            <Box></Box>
          </Grid>
          {!!mixedSkuInputValidationError && (
            <InputError
              message={<FormattedMessage {...getDimensionErrorLabel(mixedSkuInputValidationError, false)} />}
            />
          )}
        </Box>
      )}
    </ProductChooserContainer>
  );
};

export const DtcMixedSkuBoxConfiguration = ({
  pkg,
  mixedSkuPackage,
  index,
  isRemoveDisabled,
  removeBox,
}: {
  pkg: FieldArrayWithId<CreateShipmentFormState, CreateShipmentInputName.MIXED_SKU_PACKAGES, "id">;
  mixedSkuPackage: PackageSummary;
  index: number;
  isRemoveDisabled: boolean;
  removeBox: (index?: number | number[]) => void;
}) => {
  return (
    <>
      <BoxConrigurationHeader index={index} removeBox={removeBox} isRemoveDisabled={isRemoveDisabled} />
      <MixedSkuProductChooser pkg={pkg} index={index} mixedSkuPackage={mixedSkuPackage} />
    </>
  );
};
