import { crossdockClient } from "common/clients/instances";
import { SPThunkAction } from "common/ReduxUtils";
import { setCrossdockInboundQuote } from ".";
import log from "Logger";
import { CrossdockInboundQuote } from "common/clients/crossdock/CrossdockInboundQuote/CrossdockInboundQuote";

export const fetchCrossdockInboundQuote = (
  sellerId: string,
  shippingPlanId: number
): SPThunkAction<Promise<CrossdockInboundQuote | undefined>> => async (
  dispatch
): Promise<CrossdockInboundQuote | undefined> => {
  const ctx = { fn: "fetchCrossdockInboundQuote", sellerId, shippingPlanId };
  log.info(ctx, "start");

  try {
    const quote = await crossdockClient.getActiveInboundQuoteByShippingPlan(sellerId, shippingPlanId);

    if (quote) {
      dispatch(setCrossdockInboundQuote(quote));
    }

    return quote;
  } catch (err) {
    log.info(ctx, "No quote found.");
    return;
  }
};
