import { HazmatInformationStatus } from "@deliverr/commons-clients";
import { removeEmTag } from "common/StringUtils";
import idx from "idx";
import { ProductListItem } from "inventory/list/InventoryListReducer";
import { isNumber, values } from "lodash/fp";
import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { isDimsConfirmed } from "../../common/utils/product/isDimsConfirmed";

export const getEstimatedCostsServiceLevel = (state: RootState) => state.inventoryList.estimatedCostsServiceLevel;
export const getFiltersBladeOpen = (state: RootState) => state.inventoryList.filtersBladeOpen;

export const getInventoryListDskus = createSelector<RootState, ProductListItem[], string[]>(
  (state) => state.inventoryList.productList.listItems,
  (listItems) => listItems.filter((item) => idx(item, (_) => _.dsku)).map(({ dsku }) => removeEmTag(dsku))
);

export const selectedItemsHasInventory = createSelector(
  (state: RootState) => state.inventoryList.productList.selectedListItems,
  (selectedListItems) =>
    Object.entries(selectedListItems).reduce((acc, [key, val]) => {
      if (isNumber(parseInt(key, 10))) {
        const innerSelectedItems = values(val) as ProductListItem[];
        innerSelectedItems.forEach((item) => {
          if (
            item &&
            (item.onHandQty === undefined ||
              item.onHandQty === 0 ||
              item.onHandQty - item.unfilledOrderQty - item.unavailableQty <= 0)
          ) {
            acc = false;
          }
        });
      }
      return acc;
    }, true)
);

export const selectHasValidItemDimensions = createSelector(
  (state: RootState) => state.inventoryList.productList.selectedListItems,
  (selectedListItems) =>
    Object.entries(selectedListItems).reduce((acc, [key, val]) => {
      if (isNumber(parseInt(key, 10))) {
        const innerSelectedItems = values(val) as ProductListItem[];
        innerSelectedItems.forEach((item) => {
          if (item && !isDimsConfirmed(item.dimSourceType)) {
            acc = false;
          }
        });
      }
      return acc;
    }, true)
);

export const selectedItemsHasValidHazmat = createSelector(
  (state: RootState) => state.inventoryList.productList.selectedListItems,
  (selectedListItems) =>
    Object.entries(selectedListItems).reduce((acc, [key, val]) => {
      if (isNumber(parseInt(key, 10))) {
        const innerSelectedItems = values(val) as ProductListItem[];
        innerSelectedItems.forEach((item) => {
          if (item?.hazmatInformation?.status && item?.hazmatInformation?.status !== HazmatInformationStatus.APPROVED) {
            acc = false;
          }
        });
      }
      return acc;
    }, true)
);

export const getCurrentTab = (state: RootState) => state.inventoryList.currentTab;

export const getSelectedInventoryLocation = (state: RootState) => state.inventoryList.inventoryLocation;
