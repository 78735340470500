interface ElevioEmailHashResponse {
  email: string;
  hash: string;
}

/**
 * Signs the user email as a HashMac for use with Elevio
 * Calls the elevio-emailhash-prod lambda on aws staging
 */
export const fetchElevioEmailHash = async (email: string): Promise<ElevioEmailHashResponse> => {
  const response = await fetch(`${process.env.ELEVIO_EMAILHASH_LAMBDA_URL}/prod/elevio?email=${email}`);
  return await response.json();
};
