import { useDispatch } from "react-redux";

import { UnsyncAdSetsProps } from "./UnsyncAdSets";
import { useConfirmUnsyncModal } from "./useConfirmUnsyncModal";
import { adUnsyncRequested } from "../../actions/adUnsyncRequested";
import { fastTagPagePath } from "tags/model/path/fastTagPagePath";

export const useUnsyncAdSets = ({ flow, page, state }: UnsyncAdSetsProps) => {
  const { showModal } = useConfirmUnsyncModal();
  const dispatch = useDispatch();
  const onNextClick = () => {
    dispatch(adUnsyncRequested());
    showModal();
  };
  const nextPath = fastTagPagePath(flow.getNextPage(page, state));
  const previousPath = fastTagPagePath(flow.getPreviousPage(page));

  return {
    nextPath,
    previousPath,
    onNextClick,
  };
};
