import styled from "@emotion/styled";
import React, { FC } from "react";
import { BasicTable } from "common/components/BasicTable";
import { ThemeProps } from "common/components/ui/shared/theme";
import { Text } from "common/components/ui/Text";
import { useIntl } from "react-intl";
import { ShipmentConfirmationEstimatedCostBreakupProps } from "./ShipmentConfirmationEstimatedCostBreakup.types";

const StyledSubTable = styled(BasicTable)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL["00"]};
  th {
    font-weight: ${theme.font.weight.BOLD};
  }
  th:not(:first-of-type),
  td:not(:first-of-type) {
    text-align: right;
    padding-left: ${theme.spacing.S5};
    /* column width is based on content width */
    width: 1%;
    white-space: nowrap;
  }
`
);

const StorageTotalContainer = styled(Text)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL["00"]};
  margin-top: ${theme.spacing.S3};
`
);

export const ShipmentConfirmationEstimatedCostBreakup: FC<ShipmentConfirmationEstimatedCostBreakupProps> = ({
  subTables,
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {subTables.map((tableProps, tableIndex) => {
        const hideHeaders: string[] | undefined =
          tableIndex > 0 ? ["unitCost", "amount"] : (tableProps.hideHeaders as string[] | undefined);
        return (
          <StyledSubTable
            key={`costTable-subTable-${tableIndex}`}
            {...tableProps}
            hideHeaders={hideHeaders}
            hasAggregatedRow
          />
        );
      })}
      <StorageTotalContainer size="caption" appearance="INFO">
        {formatMessage({
          id: "CostTable.estimatedTotalLabel",
          defaultMessage:
            "Your final invoice amount may change due to additional carrier pickup fees or unexpected cargo quantities, weights, and dimensions. You will be billed for monthly storage starting on the first day of the month.",
        })}
      </StorageTotalContainer>
    </>
  );
};
