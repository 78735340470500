import React, { FC } from "react";
import { NavBar } from "common/components/NavBar";
import { NavbarShippingPlanName } from "inbounds/components/ShippingPlanName";
import { Path } from "paths";
import { CreateInboundNavBarTitle } from "./CreateInboundNavBarTitle";
import { NavBarProgressRenderer } from "common/components/NavBarProgress/NavBarProgressRenderer";
import store from "store";
import { getEnabledFlowSteps } from "inbounds/navigation/getEnabledFlowSteps";
import { fulfillmentInboundEnabledStepMap } from "inbounds/navigation/fulfillment/fulfillmentInboundEnabledStepMap";
import { useCurrentFulfillmentInboundStep } from "inbounds/navigation/fulfillment/useCurrentFulfillmentInboundStep";
import { InboundStep } from "inbounds/InboundTypes";

export const FulfillmentInboundProgressNavBar: FC = () => {
  const state = store.getState();
  const enabledInboundSteps = getEnabledFlowSteps<InboundStep>(InboundStep, fulfillmentInboundEnabledStepMap, state);
  const currentStep = useCurrentFulfillmentInboundStep();

  return (
    <NavBar
      breadcrumbs={[
        {
          pageName: "Inbounds",
          path: { pathname: Path.inboundsList },
        },
        currentStep === InboundStep.SELECT_INBOUND_TYPE
          ? {
              pageName: "Create inbound",
              component: <CreateInboundNavBarTitle />,
            }
          : { pageName: "Shipping Plan", component: <NavbarShippingPlanName /> },
      ]}
    >
      <NavBarProgressRenderer steps={enabledInboundSteps} currentStep={currentStep} />
    </NavBar>
  );
};
