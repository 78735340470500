import React from "react";
import { defineMessages, MessageDescriptor } from "react-intl";
import { Box, defaultTheme, IconV2, Stack } from "common/components/ui";
import { LocalizedText, TextRendererProps } from "common/components/LocalizedText/LocalizedText";
import { PrepOption } from "@deliverr/business-types";

const SHIPPING_PREP_BULLETS = defineMessages({
  bullet1: {
    id: "transfers.create.steps.prepServices.option.shippingPrep.bullet1",
    defaultMessage: "Box labeling and handling",
  },
});

const UNIT_SHIPPING_PREP_BULLETS = defineMessages({
  bullet1: {
    id: "transfers.create.steps.prepServices.option.unitShippingPrep.bullet1",
    defaultMessage: "FNSKU labeling",
  },
  bullet2: {
    id: "transfers.create.steps.prepServices.option.unitShippingPrep.bullet2",
    defaultMessage: "Box and pallet labeling and handling",
  },
});

const UNIT_BARCODING_PREP_BULLETS = defineMessages({
  bullet1: {
    id: "transfers.create.steps.prepServices.option.unitBarcodingPrep.bullet1",
    defaultMessage: "Unit Barcoding",
  },
  bullet2: {
    id: "transfers.create.steps.prepServices.option.unitBarcodingPrep.bullet2",
    defaultMessage: "Box content labeling and handling",
  },
});

const BAGGING_PROTECTION_PREP_BULLETS = defineMessages({
  bullet1: {
    id: "transfers.create.steps.prepServices.option.baggingProtectionPrep.bullet1",
    defaultMessage: "Unit Barcoding",
  },
  bullet2: {
    id: "transfers.create.steps.prepServices.option.baggingProtectionPrep.bullet2",
    defaultMessage: "Box content labeling and handling",
  },
  bullet3: {
    id: "transfers.create.steps.prepServices.option.baggingProtectionPrep.bullet3",
    defaultMessage: "Bagging",
  },
});

const DAMAGE_PROTECTION_PREP_BULLETS = defineMessages({
  bullet1: {
    id: "transfers.create.steps.prepServices.option.damageProtectionPrep.bullet1",
    defaultMessage: "Unit Barcoding",
  },
  bullet2: {
    id: "transfers.create.steps.prepServices.option.damageProtectionPrep.bullet2",
    defaultMessage: "Box content labeling and handling",
  },
  bullet3: {
    id: "transfers.create.steps.prepServices.option.damageProtectionPrep.bullet3",
    defaultMessage: "Unit protected with Bubble Mailer or Bubble Wrap",
  },
});

const CheckmarkBullet = (props: TextRendererProps) => (
  <Stack direction="HORIZONTAL" alignItems="center" gap="S1">
    <IconV2 size="md" type="check" color={defaultTheme.colors.GREEN[300]} />
    <LocalizedText as="div" appearance="INFO" size="label" {...props} />
  </Stack>
);

export const PREP_OPTION_MESSAGES: Record<string, Record<string, MessageDescriptor>> = {
  [PrepOption.SHIPPING_ONLY]: defineMessages({
    label: {
      id: "transfers.create.steps.prepServices.option.shippingPrep.title",
      defaultMessage: "Shipping Prep only",
    },
    priceDescription: {
      id: "transfers.create.steps.prepServices.option.shippingPrep.priceDescription",
      defaultMessage: "for {numOfBoxes} boxes",
    },
    priceDescription2: {
      id: "transfers.create.steps.prepServices.option.shippingPrep.priceDescription2",
      defaultMessage: "+ {boxHandlingFee}/box or {palletHandlingFee}/pallet handling",
    },
    customPriceLabel: {
      id: "transfers.create.steps.prepServices.option.shippingPrep.priceDescription.customPriceLabel",
      defaultMessage: "{price}/label",
    },
    description: {
      id: "transfers.create.steps.prepServices.option.shippingPrep.subtitle",
      defaultMessage: "Products are sales-ready for Amazon and do not require unit level Prep from Flexport. {bullets}",
      values: {
        bullets: (
          <Box marginTop="S2">
            <Stack gap="S1">
              <CheckmarkBullet message={SHIPPING_PREP_BULLETS.bullet1} />
            </Stack>
          </Box>
        ),
      },
    },
  }),
  [PrepOption.UNIT_PREP]: defineMessages({
    label: {
      id: "transfers.create.steps.prepServices.option.unitPrep.title",
      defaultMessage: "Unit and Shipping Prep",
    },
    description: {
      id: "transfers.create.steps.prepServices.option.unitPrep.subtitle",
      defaultMessage:
        "Flexport will prepare each unit according to Amazon requirements. Allow for up to 3 business days for Unit Prep. {bullets}",
      values: {
        bullets: (
          <Box marginTop="S2">
            <Stack gap="S1">
              <CheckmarkBullet message={UNIT_SHIPPING_PREP_BULLETS.bullet1} />
              <CheckmarkBullet message={UNIT_SHIPPING_PREP_BULLETS.bullet2} />
            </Stack>
          </Box>
        ),
      },
    },
    priceDescription: {
      id: "createShipment.selectDistribution.unitPrep.priceDescription",
      defaultMessage: "for {numOfUnits} units",
    },
  }),
  [PrepOption.SKU_LABELS]: defineMessages({
    label: {
      id: "transfers.create.steps.prepServices.option.simplePrep.skuLabels.title",
      defaultMessage: "Unit Barcoding",
    },
    description: {
      id: "transfers.create.steps.prepServices.option.simplePrep.skuLabels.subtitle",
      defaultMessage:
        "Print and apply a DSKU barcode to each individual unit. Includes box content labeling and handling {bullets}",
      values: {
        bullets: (
          <Box marginTop="S2">
            <Stack gap="S1">
              <CheckmarkBullet message={UNIT_BARCODING_PREP_BULLETS.bullet1} />
              <CheckmarkBullet message={UNIT_BARCODING_PREP_BULLETS.bullet2} />
            </Stack>
          </Box>
        ),
      },
    },
    priceDescription: {
      id: "createShipment.selectDistribution.simplePrep.skuLabels.priceDescription",
      defaultMessage: "for {numOfUnits} units",
    },
  }),
  [PrepOption.BAGGING_PROTECTION]: defineMessages({
    label: {
      id: "transfers.create.steps.prepServices.option.simplePrep.baggingProtection.title",
      defaultMessage: "Bagging Protection",
    },
    description: {
      id: "transfers.create.steps.prepServices.option.simplePrep.baggingProtection.subtitle",
      defaultMessage:
        "Place each unit inside a sealed polybag for protection. Includes unit barcoding, and box content labeling and handling {bullets}",
      values: {
        bullets: (
          <Box marginTop="S2">
            <Stack gap="S1">
              <CheckmarkBullet message={BAGGING_PROTECTION_PREP_BULLETS.bullet1} />
              <CheckmarkBullet message={BAGGING_PROTECTION_PREP_BULLETS.bullet2} />
              <CheckmarkBullet message={BAGGING_PROTECTION_PREP_BULLETS.bullet3} />
            </Stack>
          </Box>
        ),
      },
    },
    priceDescription: {
      id: "createShipment.selectDistribution.simplePrep.baggingProtection.priceDescription",
      defaultMessage: "for {numOfUnits} units",
    },
  }),
  [PrepOption.DAMAGE_PROTECTION]: defineMessages({
    label: {
      id: "transfers.create.steps.prepServices.option.simplePrep.damageProtection.title",
      defaultMessage: "Damage Protection",
    },
    description: {
      id: "transfers.create.steps.prepServices.option.simplePrep.damageProtection.subtitle",
      defaultMessage:
        "Place each unit in a bubble mailer or bubble wrap for protection. Includes unit barcoding, and box content labeling and handling. {bullets}",
      values: {
        bullets: (
          <Box marginTop="S2">
            <Stack gap="S1">
              <CheckmarkBullet message={DAMAGE_PROTECTION_PREP_BULLETS.bullet1} />
              <CheckmarkBullet message={DAMAGE_PROTECTION_PREP_BULLETS.bullet2} />
              <CheckmarkBullet message={DAMAGE_PROTECTION_PREP_BULLETS.bullet3} />
            </Stack>
          </Box>
        ),
      },
    },
    priceDescription: {
      id: "createShipment.selectDistribution.simplePrep.damageProtection.priceDescription",
      defaultMessage: "for {numOfUnits} units",
    },
  }),
};
