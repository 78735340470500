import { AxiosError } from "axios";
import { ApiError, DeliverrError } from "@deliverr/errors";

export function handleSellerServiceError(functionName: string, err: AxiosError): never {
  if (!err.response) {
    const unknownError = new DeliverrError({
      code: ApiError.UNKNOWN_ERROR,
      functionName,
      payload: err,
    });
    throw unknownError;
  }

  if (err.response.data.subcode) {
    const reportableError = new DeliverrError({
      code: err.response.data.subcode,
      functionName,
    });
    throw reportableError;
  } else {
    const unknownError = new DeliverrError({
      code: err.response.data,
      functionName,
      payload: err,
    });
    throw unknownError;
  }
}
