import log from "Logger";
import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { getLoadedShipmentId } from "../shipments";

/**
 *  Iterates over all pallet configurations for the current shipment and calculates the total sum of all quantities
 *  (identicalPallets) and pallet weights, while factoring for multiple identicalPallets of the same weight.
 *
 *  @returns [sum of all pallet quantities, sum of all pallet weights]
 */
export const getPalletTotalDims = createSelector(
  getLoadedShipmentId,
  (state: RootState) => state.inbound.ltl[state.inbound.loadedShipmentId!]?.palletConfigurations,
  (loadedShipmentId, palletConfigurations) => {
    if (!palletConfigurations) {
      log.warn(
        { fn: "getPalletTotalDims", loadedShipmentId, palletConfigurations },
        "no ltl pallet configurations found"
      );
      return [0, 0];
    }

    return palletConfigurations.reduce(
      (sums, { identicalPallets, weight }) => {
        return [sums[0] + identicalPallets, sums[1] + weight * identicalPallets];
      },
      [0, 0]
    );
  }
);
