import { createBrowserHistory } from "history";

const history = createBrowserHistory({
  // executes on history.block
  getUserConfirmation: (_, proceed) => {
    window.proceed = proceed;
  },
});

export default history;
