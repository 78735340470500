import { Product } from "@deliverr/commons-clients";
import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { StorageInboundCreateState } from "../StorageInboundCreateState.types";
import { getStorageInboundCreate } from "./getStorageInboundCreate";
import { StorageInboundProductItemDetails } from "../StorageInboundProductItemDetails.types";

export type StorageProductWithCasePack = Product & StorageInboundProductItemDetails;

export const getSelectedProductsCaseDimensions = createSelector<
  RootState,
  StorageInboundCreateState,
  StorageProductWithCasePack[]
>(getStorageInboundCreate, (storageInboundCreate) => {
  const { selectedProductsCaseDimensions, productDetails, selectedProducts } = storageInboundCreate;
  return Object.keys(selectedProductsCaseDimensions).map((dsku) => ({
    ...productDetails[dsku],
    ...selectedProductsCaseDimensions[dsku],
    ...selectedProducts[dsku],
  }));
});
