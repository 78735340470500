import { FastTagPage } from "../FastTagPage";
import { unknownPage, UnknownPage } from "./UnknownPage";

export const getPreviousPageFromLinear = (pages: FastTagPage[]) => (page: FastTagPage): FastTagPage | UnknownPage => {
  const currentPageIndex = pages.findIndex(({ id }) => page.id === id);

  if (currentPageIndex < 1) {
    return unknownPage(page.fastTag);
  } else {
    return pages[currentPageIndex - 1];
  }
};
