import { Icon } from "common/components/ui";
import cx from "classnames";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { FastTagsNav } from "tags/base/FastTagsNav";
import { FastTagPageComponentProps } from "tags/model/FastTagPage";

import sharedCls from "../facebook.less";
import cls from "./SavedAudienceWarning.less";
import { FacebookEditFlowState } from "tags/facebook/edit/state/FacebookEditFlowState";
import { FacebookState } from "tags/facebook/connect/state/FacebookReducer";
import { fastTagPagePath } from "tags/model/path/fastTagPagePath";

export const SavedAudienceWarning: FC<FastTagPageComponentProps<FacebookEditFlowState | FacebookState>> = ({
  flow,
  page,
  state,
}) => {
  return (
    <div className={sharedCls.container}>
      <div className={cx(sharedCls.inner)}>
        <h4 className={cx(sharedCls.title, sharedCls.fullTitle)}>
          <Icon className={cls.warningIcon} type="exclamation-triangle" />
          <FormattedMessage id="FacebookFastTag.SavedAudienceWarning.Title" defaultMessage="Caution: saved audiences" />
        </h4>
        <p className={cx(sharedCls.description, sharedCls.boundedWidth)}>
          <FormattedMessage
            id="FacebookFastTag.SavedAudienceWarning.Description"
            defaultMessage="Ad sets with saved audiences can not be used for Flexport Fast Shipping ads."
          />
        </p>
        <p className={cx(sharedCls.description, sharedCls.boundedWidth)}>
          <FormattedMessage
            id="FacebookFastTag.SavedAudienceWarning.Description2"
            defaultMessage="We recommend duplicating an existing ad set and rebuilding the audience without saving it"
          />
        </p>
      </div>
      <FastTagsNav
        className={sharedCls.nav}
        nextPage={fastTagPagePath(flow.getNextPage(page, state))}
        previousPage={fastTagPagePath(flow.getPreviousPage(page))}
        nextButtonText={<FormattedMessage id="next" defaultMessage={"Next"} />}
        previousButtonText={<FormattedMessage id="back" defaultMessage={"Back"} />}
        nextButtonClassName={sharedCls.actionButton}
        previousButtonClassName={sharedCls.actionButton}
      />
    </div>
  );
};
