import { FlowStep, FlowStepType, StepValidationMap } from "common/components/AccordionFlow/AccordionFlowTypes";

export type StepCompletionMap = Partial<Record<FlowStepType, boolean>>;

interface StepValidatorProps {
  steps: FlowStep[];
  stepCompletionMap: StepCompletionMap;
}
export const useAccordionStepValidator = ({ steps, stepCompletionMap }: StepValidatorProps): StepValidationMap => {
  return steps.reduce((validationMap, { stepType }, index) => {
    const isValid = stepCompletionMap[stepType];
    validationMap[stepType] = {
      index,
      isValid,
    };
    return validationMap;
  }, {});
};
