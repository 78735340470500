import { FreightMode } from "@deliverr/freight-client";
import { FreightQuoteFormErrorCodes } from "../actions/FreightQuoteFormErrors";

export interface FreightQuotePlanService {
  service: FreightMode;
}

export interface FreightQuoteRequestOptions {
  fromZip: string;
  toZip: string;
  sellerId: string | undefined;
  internalUserId: string;
  numberOfTrucks: number;
  plan: FreightQuotePlanService[];
}

export interface FreightQuoteFormState {
  form: FreightQuoteRequestOptions;
  isLoading: boolean;
  error?: FreightQuoteFormErrorCodes;
}

export const freightQuoteFormInitialState: FreightQuoteFormState = {
  form: {
    fromZip: "",
    toZip: "",
    sellerId: undefined,
    internalUserId: "",
    numberOfTrucks: 1,
    plan: [{ service: FreightMode.FTL }],
  },
  isLoading: false,
};
