import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { ReturnsLabelActionTypes, ReturnsLabelState } from "./ReturnsLabelStore.types";

export const returnsLabelInitialState: ReturnsLabelState = {};

const reducers: ReducerDictionary<ReturnsLabelState> = {
  ...handleSimpleReducerUpdates([
    ReturnsLabelActionTypes.RETURN_INFO_SUCCESS,
    ReturnsLabelActionTypes.RETURN_INFO_FAIL,
    ReturnsLabelActionTypes.SHARE_RETURN_LABEL_FAIL,
    ReturnsLabelActionTypes.SHARE_RETURN_LABEL_SUCCESS,
  ]),

  [ReturnsLabelActionTypes.RETURN_LABEL_RESET]: () => ({
    ...returnsLabelInitialState,
  }),
};

export const returnsLabelReducer = createReducer<ReturnsLabelState>(returnsLabelInitialState, reducers);
