import { CardTable, createCellObject } from "../../../inbounds/steps/ship/view/cards/CardTable";
import { shipmentContentsLabels } from "../../../transfers/detail/shipmentContents.labels";
import React, { FC } from "react";
import { Products } from "common/utils/product/Products";
import { Text } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import { StyledHeader } from "../../../transfers/detail/ui/CardStyledHeader";
import { ProductDetailData } from "inbounds/steps/ship/view/cards/ProductDetailData";
import { getStorageProductDsku } from "storage/utils/getStorageProductDsku";
import { TextAlign } from "common/components/ui/shared";

const headers = [
  createCellObject(
    <StyledHeader bold size="caption">
      <FormattedMessage {...shipmentContentsLabels.productName} />
    </StyledHeader>,
    "left",
    `transfers-shipment-detail-contents-product`
  ),
  createCellObject(
    <StyledHeader bold size="caption" textAlign={TextAlign.end}>
      <FormattedMessage {...shipmentContentsLabels.units} />
    </StyledHeader>,
    "right",
    `transfers-shipment-detail-contents-units`
  ),
];

export const ProductsUnitsTable: FC<{
  items?: { dsku: string; qty: number; lotNumber?: string; expirationDate?: string }[];
  products: Products;
}> = ({ items, products }) => {
  const tableRows = (items ?? []).map((item) => {
    let { dsku, qty } = item;
    const product = products[dsku] ?? {};
    dsku = getStorageProductDsku(product) ?? dsku;

    const productSKUCell = (
      <ProductDetailData
        product={product}
        shouldShowPackInformation
        showStorageProductAndCaseDskuForAdmin
        shouldShowLotInformation
        planItem={item}
      />
    );

    const qtyCell = <Text textAlign={TextAlign.end}>{qty}</Text>;

    return [
      createCellObject(productSKUCell, "left", `transfers-shipment-details-contents-product-${dsku}`),
      createCellObject(qtyCell, "right", `transfers-shipment-details-contents-units-${dsku}`),
    ];
  });

  return <CardTable headerCells={headers} rows={tableRows} />;
};
