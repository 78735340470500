import { FreightMode, OrderResponse, QuoteRequestResponse, QuoteStatus } from "@deliverr/freight-client";
import { QuoteLocation } from "freight/types/Quote";
import { OrderStatus } from "freight/types";
import { minBy, orderBy, sumBy } from "lodash";
import { FreightBookingListItem } from "./FreightBookingListItem";
import { FreightQuoteListItem } from "./FreightQuotesTable/FreightQuoteListItem";
import { getFormattedQuoteAddress } from "freight/common/utils/getFormattedQuoteAddress";
import { MINIMUM_DRAYAGE_CONTAINER_COUNT } from "freight/constants/cargoPalletConstants";

export const mapFreightOrderToOrderListItem = (orders: OrderResponse[] | null): FreightBookingListItem[] => {
  if (!orders) {
    return [];
  }

  return orderBy(
    orders.map(
      (order): FreightBookingListItem => {
        const cargo = order.orderItems
          .map((orderItem) => {
            if (orderItem.type === FreightMode.DRAYAGE) {
              return MINIMUM_DRAYAGE_CONTAINER_COUNT;
            }

            if (orderItem.type === FreightMode.FTL) {
              return orderItem.palletCount;
            }

            if (orderItem.type === FreightMode.LTL) {
              return sumBy(orderItem.pallets, "palletCount");
            }

            return 0;
          })
          .filter((freightCargo) => freightCargo !== undefined && freightCargo > 0) as number[];

        const isDrayageOrder = order.orderItems.some((orderItem) => orderItem.type === FreightMode.DRAYAGE);

        return {
          id: order.id,
          updatedAt: order.updatedAt,
          to: order.deliveryDetails.location.street1,
          status: order.status as OrderStatus,
          cargo: cargo[0],
          type: isDrayageOrder ? FreightMode.DRAYAGE : order.orderItems[0].type,
        };
      }
    ),
    "id",
    "desc"
  );
};

const EXPIRATION_THRESHOLD = 3 * 1000 * 60 * 60 * 24; // 3 days
export const mapQuoteRequestResponseToQuoteListItem = (
  quoteRequestResponse: QuoteRequestResponse[] | null
): FreightQuoteListItem[] => {
  if (!quoteRequestResponse) {
    return [];
  }

  return orderBy(
    quoteRequestResponse
      .filter((quoteRequest) => !quoteRequest.quotes.some((quote) => quote.status === QuoteStatus.BOOKED))
      .map(
        ({ pickupDetails, deliveryDetails, quantity, quotes, id }): FreightQuoteListItem => {
          const from = getFormattedQuoteAddress(pickupDetails as QuoteLocation);
          const to = getFormattedQuoteAddress(deliveryDetails as QuoteLocation);
          const quotedPrice = minBy(quotes, (quote) => quote.totalCost)?.totalCost ?? 0;
          const isExpired = quotes.some(({ expiresAt }) => new Date(expiresAt).getTime() - Date.now() < 0);
          const isExpiringSoon =
            !isExpired &&
            quotes.some(({ expiresAt }) => new Date(expiresAt).getTime() - Date.now() < EXPIRATION_THRESHOLD);
          const expiresAt = quotes[0].expiresAt;
          const type = quotes[0].type;

          return {
            id,
            type,
            from,
            to,
            expiresAt,
            quotedPrice,
            cargo: quantity,
            isExpired,
            isExpiringSoon,
            numberOfOptions: quotes.length,
          };
        }
      ),
    "id",
    "desc"
  );
};
