import { BulkUploadTemplateType } from "./BulkUploadTemplateType";

export const bulkUploadTemplates: Record<BulkUploadTemplateType, string> = {
  [BulkUploadTemplateType.DEFAULT]:
    "https://deliverr-shipping-plan-upload-templates.s3.amazonaws.com/CasePackTemplateEnhanced.xlsx",
  [BulkUploadTemplateType.INDIVIDUAL]:
    "https://deliverr-shipping-plan-upload-templates.s3.amazonaws.com/IndividualTemplate.xlsx",
  [BulkUploadTemplateType.INDIVIDUAL_ENHANCED]:
    "https://deliverr-shipping-plan-upload-templates.s3.amazonaws.com/IndividualTemplateEnhanced.xlsx",
  [BulkUploadTemplateType.STORAGE]:
    "https://deliverr-shipping-plan-upload-templates.s3.amazonaws.com/StorageInboundTemplate.xlsx",
  [BulkUploadTemplateType.STORAGE_INTL]:
    "https://deliverr-shipping-plan-upload-templates.s3.amazonaws.com/StorageInboundTemplateInternational.xlsx",
  [BulkUploadTemplateType.REPLENISHMENT]:
    "https://deliverr-shipping-plan-upload-templates.s3.amazonaws.com/ReplenishmentBulkUploadTemplate.xlsx",
  [BulkUploadTemplateType.REPLENISHMENT_EACHES]:
    "https://deliverr-shipping-plan-upload-templates.s3.amazonaws.com/ReplenishmentEachesBulkUploadTemplate.xlsx",
};
