import cx from "classnames";
import { Button, Icon, Title } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import Content from "common/components/Content";
import { SimpleLoader } from "common/components/WithLoader/WithLoader";
import { FastTagPageComponentProps } from "tags/model/FastTagPage";
import { FacebookEditFlowState } from "../../state/FacebookEditFlowState";
import { useProcessUnsync } from "./useProcessUnsync";

import sharedCls from "../../../common/facebook.less";

export const ProcessUnsync: FC<FastTagPageComponentProps<FacebookEditFlowState>> = () => {
  const { unsyncStatus, onDoneClick } = useProcessUnsync();

  return (
    <div className={sharedCls.container}>
      <div className={sharedCls.inner}>
        {unsyncStatus !== "errored" && unsyncStatus !== "completed" && (
          <>
            <div>
              <SimpleLoader size="2x" />
            </div>
            <Title displayAs="h4">
              <FormattedMessage
                id="FacebookFastTag.ProcessUnsync.CheckingAdsTitle"
                defaultMessage="Checking your Ads..."
              />
            </Title>
          </>
        )}
        {unsyncStatus === "errored" && (
          <Content
            before={
              <div className={cx(sharedCls.completeIcon, sharedCls.errorIconContainer)}>
                <Icon type="times" className={sharedCls.completeIconInner} />
              </div>
            }
            body={
              <FormattedMessage
                id="FacebookFastTag.ProcessUnsync.ErrorText"
                defaultMessage="An error occurred while attempting to unsync your ad sets. Please try again or contact support."
              />
            }
            after={
              <Button onClick={onDoneClick}>
                <FormattedMessage id="done" defaultMessage={`Done`} />
              </Button>
            }
          />
        )}
        {unsyncStatus === "completed" && (
          <Content
            before={
              <div className={cx(sharedCls.completeIcon, sharedCls.largeCheckMarkContainer)}>
                <Icon className={sharedCls.completeIconInner} type="check" size="lg" />
              </div>
            }
            title={
              <FormattedMessage id="FacebookFastTag.ProcessUnsync.Success.Title" defaultMessage="Unsync Successful!" />
            }
            body={
              <FormattedMessage
                id="FacebookFastTag.ProcessUnsync.Success.Description"
                defaultMessage="Your ad sets have been successfully unsynced!"
              />
            }
            after={
              <Button onClick={onDoneClick}>
                <FormattedMessage id="done" defaultMessage={`Done`} />
              </Button>
            }
          />
        )}
      </div>
    </div>
  );
};
