import { OrderStatus } from "@deliverr/replenishment-client";
import { WholesaleOrderStatus } from "@deliverr/wholesale-client";
import { ShipmentStatusGrouping } from "inbounds/ShipmentStatus";

export const transfersListShipmentStatuses = [
  ShipmentStatusGrouping.IN_DRAFT,
  ShipmentStatusGrouping.READY_TO_SHIP,
  ShipmentStatusGrouping.FORWARDING,
  ShipmentStatusGrouping.IN_TRANSIT,
  ShipmentStatusGrouping.IN_PREP,
  ShipmentStatusGrouping.ARRIVED,
  ShipmentStatusGrouping.RECEIVING,
  ShipmentStatusGrouping.COMPLETED,
  ShipmentStatusGrouping.ACTION_NEEDED,
  OrderStatus.SUBMITTED,
  WholesaleOrderStatus.DRAFT,
  WholesaleOrderStatus.SCHEDULED,
  WholesaleOrderStatus.PROCESSING,
  WholesaleOrderStatus.CANCELLED,
  WholesaleOrderStatus.SHIPPED,
  WholesaleOrderStatus.DELIVERED,
] as const;

export type TransfersListShipmentStatus = (typeof transfersListShipmentStatuses)[number];
