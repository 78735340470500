import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";
import { PALLETS_INPUT_NAME } from "./PalletsInput";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { calcTotalVolumeToPallets } from "../utils/calcTotalVolumeToPallets";
import { trackSmbBookingCreationEvent } from "inbounds/createShipment/common/analytics/trackSmbBookingCreationEvent";

export const useEstimatePalletCount = () => {
  const { setValue, getValues, trigger } = useCreateShipmentFormContext();

  const estimatePalletCount = async () => {
    void trigger(CreateShipmentInputName.TOTAL_VOLUME);
    const volume = getValues(CreateShipmentInputName.TOTAL_VOLUME);
    if (volume) {
      trackSmbBookingCreationEvent("estimate_pallet_count");
      const unitSystem = getValues(CreateShipmentInputName.UNIT_SYSTEM);
      const estimate = calcTotalVolumeToPallets(volume, unitSystem);
      setValue(PALLETS_INPUT_NAME, estimate);
    }
  };

  return estimatePalletCount;
};
