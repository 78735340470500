import { emptyAddress } from "common/AddressUtils";
import { DeliveryDetailsState } from "freight/types/DeliveryTypes";

export const deliveryDetailsInitialState: DeliveryDetailsState = {
  address: { ...emptyAddress, country: "" },
  deliveryReferenceNumber: null,
  facilityOperatingHours: undefined,
  deliveryInstructions: null,
  contact: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phoneExt: "",
  },
  isAppointmentRequired: undefined,
};
