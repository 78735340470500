import { Bundle } from "@deliverr/commons-clients";
import { min } from "lodash/fp";
import { ATP } from "common/clients/inventory/ATP/ATP";

/**
 * The available quantity of a bundle is the minimum available qty of all it's bundle items.
 * The available quantity of a bundle item is the ATP of the product divided by it's quantity in the bundle.
 */
export const getBundleAvailableQty = (bundle: Bundle, productAtps: { [dsku: string]: ATP }): number => {
  const { bundleItems = [] } = bundle;
  const itemsAtps = bundleItems.map(({ dsku, qty }) => Math.floor((productAtps[dsku]?.availableToPurchase ?? 0) / qty));
  return min(itemsAtps) ?? 0;
};
