import { SortDir } from "common/models/SortDir";
import { TransferListItem } from "./TransferListItem";
import { TransferDestinationType } from "./TransferDestinationType";
import { TransfersListShipmentStatus } from "../TransfersListShipmentStatus";
import { DistributionChannel } from "@deliverr/replenishment-client";

export interface TransferListState {
  pageItems?: Array<TransferListItem>;
  searchTerm: string;
  sort: {
    fieldName: string;
    direction: SortDir;
  };
  isError?: boolean;
  pageNum: number;
  nbPages?: number;
  searchFilters: TransfersListSearchFilters;
  showChannelReplenishmentBanner: Record<DistributionChannel.FBA, boolean>;
}

export interface TransfersListSearchFilters {
  shipmentStatuses: TransfersListShipmentStatus[];
  destinationType: TransferDestinationType;
  tabFilter: ReplenishmentOrderListTabFilter;
}

export enum ReplenishmentOrderListTabFilter {
  ALL_ORDERS = "ALL_ORDERS",
  HAS_PROBLEM = "HAS_PROBLEM",
}
