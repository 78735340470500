import { CheckableTileGroupProps, Text, TextProps, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import HtmlToReact from "common/components/HtmlToReact";
import { PREP_TYPE_LABELS } from "prep/labels";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { PrepTileContent, PrepTileContentProps } from "./PrepTileContent";

// dimensions directly from designs
const IllustrationWrapper = styled.figure`
  height: 90px;
  width: 62px;
`;

const TileTitle = styled(Text)<ThemeProps<TextProps>>(
  ({ theme }) => `
  text-transform: capitalize;
  padding-bottom: ${theme.spacing.S1};
`
);

export const useBuildPrepTileOptionByPrepType = () => {
  const { formatMessage } = useIntl();

  const buildPrepTileOptionByPrepType = useCallback(
    ({ prepType, itemCount }: PrepTileContentProps): CheckableTileGroupProps["options"][0] => {
      const lowerCasePrepType = prepType.toLocaleLowerCase();
      const prepTypeTitle = formatMessage(PREP_TYPE_LABELS[prepType]);

      return {
        label: <TileTitle bold>{prepTypeTitle}</TileTitle>,
        value: prepType,
        content: <PrepTileContent prepType={prepType} itemCount={itemCount} />,
        imgContent: (
          <IllustrationWrapper
            aria-label={formatMessage(
              {
                id: "inbounds.prep.prepTileGroup.option.illustration.label",
                defaultMessage: "{localizedPrepType} illustration",
              },
              { localizedPrepType: prepTypeTitle }
            )}
          >
            <HtmlToReact html={require(`assets/img/prep/${lowerCasePrepType}.svg`)} />
          </IllustrationWrapper>
        ),
      };
    },
    [formatMessage]
  );

  return {
    buildPrepTileOptionByPrepType,
  };
};
