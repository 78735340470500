import { createSelector } from "reselect";
import { compact, map } from "lodash/fp";

import { getProductDetailsCache } from "./getProductDetailsCache";
import { selectPlanItems } from "../plan/selectPlanItems";

export const selectPlanProductDetails = createSelector(
  selectPlanItems,
  getProductDetailsCache,
  (planItems, productDetailsCache) => compact(map(({ dsku }) => productDetailsCache[dsku], planItems))
);
