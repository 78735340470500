import { FeatureName, isFeatureOn } from "common/Split";
import { selectIpbFeatureOn } from "inbounds/createShipment/store/selectors/selectIpbFeatureOn";
import { RootState } from "RootReducer";
import { FlowEnabledStepMap } from "../../../../inbounds/navigation/FlowEnabledStepMap";
import { StorageInboundStep } from "../StorageInboundStep";
import { getStorageInboundCreate } from "../store/selector/getStorageInboundCreate";
import { selectShouldShowStorageLotInformation } from "../store/selector/selectShouldShowStorageLotInformation";
import { CarrierType } from "@deliverr/storage-client";
import { getShowBarcodeInput } from "../../../../inbounds/store/selectors/steps/getShowBarcodeInput";

export const storageInboundEnabledStepMap: FlowEnabledStepMap<StorageInboundStep> = {
  // inbound type will not be shown
  [StorageInboundStep.SELECT_INBOUND_TYPE]: (state) => !selectIpbFeatureOn(state),
  [StorageInboundStep.STORAGE_SELECT_PRODUCTS]: () => true,
  [StorageInboundStep.STORAGE_ADD_LOT_INFORMATION]: selectShouldShowStorageLotInformation,
  [StorageInboundStep.STORAGE_ADD_CASE_DIMENSIONS]: () => !isFeatureOn(FeatureName.StorageMultiCasePack),
  [StorageInboundStep.STORAGE_ADD_PRODUCTS_BARCODE]: (state: RootState) => getShowBarcodeInput(state),
  [StorageInboundStep.STORAGE_FROM_ADDRESS]: (state) => !selectIpbFeatureOn(state),
  [StorageInboundStep.STORAGE_DESTINATION_WAREHOUSE]: () => true,
  [StorageInboundStep.STORAGE_SELECT_SHIPPING]: () => true,
  [StorageInboundStep.STORAGE_ADD_PICKUP_DETAILS]: (state: RootState) => {
    return getStorageInboundCreate(state).carrierType === CarrierType.DELIVERR;
  },
  [StorageInboundStep.STORAGE_CONFIRM_SHIPMENT]: () => true,
  [StorageInboundStep.STORAGE_SHIPMENT_CONFIRMED]: () => true,
};
