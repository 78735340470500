import styled from "@emotion/styled";
import React from "react";
import { ellipsis, transitionFast } from "../shared/helpers/css";
import { isMobile } from "../shared/helpers/utils";
import { Icon, IconsProps } from "../Icon";
import { FormGroup, FormGroupProps, extractFormProps } from "../FormGroup";
import { DefaultTheme, ThemeProps } from "../shared";

export interface InputProps extends FormGroupProps<React.InputHTMLAttributes<HTMLInputElement>> {
  onSubmitButtonClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmitLabel?: string;
  onClearButtonClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  onClearLabel?: string;
  /** Hide all icons in input field. Useful for thin inputs where the value would be obscured. */
  hideIcon?: boolean;
  /** Icon Prefixed */
  prefixIcon?: IconsProps["type"];
}

export const ERROR_COLOR = (theme: DefaultTheme) => theme.colors.RED[400];
export const TEXT_COLOR = (theme: DefaultTheme) => theme.colors.NEUTRAL[500];

const InputContainer = styled.div`
  position: relative;
`;

const Lock = styled(Icon)<IconsProps, DefaultTheme>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL[300]};
    position: absolute;
    height: ${theme.font.size.F3};
    top: 50%;
    transform: translateY(-50%);
    ${
      isMobile(theme)
        ? `
            right: ${theme.spacing.S4};`
        : `
            right: ${theme.spacing.S3};`
    }
  `
);

const InputButton = styled.button<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
    height: calc(${theme.input.height} + 2px);
    width: calc(${theme.input.height} + 2px);
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
    border: none;
    padding: 0;
    top: -1px;
    right: -1px;
    background-color: transparent;
    display: none;
  `
);

const InputButtonIcon = styled(Icon)<IconsProps, DefaultTheme>(
  ({ theme }) => `
  ${
    isMobile(theme)
      ? `
          font-size: ${theme.font.size.F6};
        `
      : `
          font-size: ${theme.font.size.F5};
        `
  }
  `
);

const SubmitButtonIcon = styled(InputButtonIcon)<IconsProps, DefaultTheme>(
  ({ theme }) => `
    color: ${theme.colors.BLUE[300]};
  `
);

const ClearButtonIcon = styled(InputButtonIcon)<IconsProps, DefaultTheme>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL[300]};
  `
);

const PREFIX_ICON_SIZE = "16px";

const PrefixIconContainer = styled.div<ThemeProps>`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  left: ${({ theme }) => theme.spacing.S3};
`;

const PrefixIcon = styled(Icon)<ThemeProps>`
  font-size: ${PREFIX_ICON_SIZE};
  color: ${({ theme }) => theme.colors.NEUTRAL[300]};
`;

export const StyledInput = styled.input<InputProps, DefaultTheme>(({ hasError, theme, prefixIcon }) => {
  const mobile = isMobile(theme);
  return `
    color: ${TEXT_COLOR(theme)};
    font-family: ${theme.font.family.STANDARD};
    border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
    line-height: ${theme.font.lineHeight.LH2};
    height: ${theme.input.height};
    outline: none;
    ${
      mobile
        ? `
        font-size: ${theme.font.size.F3};
        border-radius: ${theme.border.radius.R3};
        padding: ${theme.spacing.S3} ${theme.input.height} ${theme.spacing.S3} ${theme.spacing.S4};
      `
        : `
        font-size: ${theme.font.size.F2};
        border-radius: ${theme.border.radius.R2};
        padding: ${theme.spacing.S2} ${theme.input.height} ${theme.spacing.S2} ${theme.spacing.S3};
      `
    }
    width: 100%;
    ${transitionFast()}
    ${ellipsis("100%")}
    :not(:placeholder-shown) + ${InputButton} {
      display: flex;
    }
    :focus {
      border-color: ${theme.colors.BLUE[300]};
      box-shadow: ${theme.input.focus.BLUE};
    }
    ::placeholder {
      color: ${theme.colors.NEUTRAL[300]};
    }
    :disabled {
      cursor: not-allowed;
      background-color: ${theme.colors.NEUTRAL[60]};
      border-color: ${theme.colors.NEUTRAL[100]};
      padding-right: ${mobile ? theme.spacing.S6 : theme.spacing.S3};
    }
    ${
      prefixIcon
        ? `
      padding-left: calc(${PREFIX_ICON_SIZE} + ${mobile ? theme.spacing.S3 : theme.spacing.S2} + ${theme.spacing.S2});
    `
        : ``
    }
    ${
      hasError
        ? `
          border-color: ${ERROR_COLOR(theme)};
          :focus {
            border-color: ${ERROR_COLOR(theme)};
            box-shadow: ${theme.input.focus.RED};
          }
        `
        : ``
    }
    text-overflow: ellipsis;
  `;
});

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      onSubmitButtonClick,
      onSubmitLabel = "Submit field",
      onClearButtonClick,
      onClearLabel = "Clear field",
      type = "text",
      ...props
    },
    ref
  ) => {
    const [formProps, inputProps] = extractFormProps(props);
    const inputIcon = props.hideIcon ? null : props.disabled ? (
      <Lock type="lock" />
    ) : props.hasError && onClearButtonClick ? (
      <InputButton onClick={onClearButtonClick} aria-label={onClearLabel}>
        <ClearButtonIcon type="times-circle" />
      </InputButton>
    ) : !props.hasError && onSubmitButtonClick ? (
      <InputButton onClick={onSubmitButtonClick} aria-label={onSubmitLabel}>
        <SubmitButtonIcon type="arrow-circle-right" />
      </InputButton>
    ) : null;

    return (
      <FormGroup {...formProps}>
        <InputContainer>
          {props.prefixIcon && !props.hideIcon ? (
            <PrefixIconContainer>
              <PrefixIcon type={props.prefixIcon} size="lg" />
            </PrefixIconContainer>
          ) : null}
          <StyledInput type={type} hasIcon={!!inputIcon} {...inputProps} ref={ref} />
          {inputIcon}
        </InputContainer>
      </FormGroup>
    );
  }
);

Input.displayName = "Input";
