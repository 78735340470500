import { LiveFastTag } from "common/FastTag/LiveFastTag";

export const UNKNOWN_PAGE_TAG = "unknown";

export interface UnknownPage<FastTag extends LiveFastTag = LiveFastTag> {
  type: "unknown";
  fastTag: FastTag;
}

export const unknownPage = <FastTag extends LiveFastTag>(fastTag: FastTag): UnknownPage<FastTag> => ({
  type: "unknown",
  fastTag,
});

export const pageIsUnknown = (page: any): page is UnknownPage =>
  "tag" in page && page.tag === "unknown" && "fastTag" in page && typeof page.fastTag === "string";
