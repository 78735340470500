import { useFetchFlexportUser } from "common/flexportUser/useFetchFlexportUser";
import { GenericHeapInfo } from "common/types/GenericHeapInfo";
import { trackHeapEvent } from "common/utils/heap/trackHeapEvent";

/**
 * NOTE: This method relies on getting Flexport user, which is done by GraphQL so, some component in the hiearchy has to be wrapped in RelayEnvironmentProvider
 * @param HeapEventType Union type literal, defined elsewhere, should be a string of some kind.
 * @returns
 */
export const useHeapEvent = () => {
  const flexportUser = useFetchFlexportUser();
  // @param analytics Contains the information you'll need, right now HeapInfo consists of a name field, and other fields you're trying to load.
  const recordHeapEvent = <HeapEventType extends string>(eventType: HeapEventType, analytics: GenericHeapInfo) => {
    const defaultInfo = analytics?.info ? { ...analytics.info } : {};
    trackHeapEvent<HeapEventType>(eventType, {
      page_name: analytics.pageName,
      event_time: new Date().toISOString(),
      account_id: flexportUser?.dbid ?? 0,
      ...defaultInfo,
    });
  };

  return recordHeapEvent;
};
