import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { DeliverrError } from "@deliverr/errors";
import { SellerReportType } from "./Report/SellerReportType";
import { SellerReports } from "./Report/SellerReports";
import { isAuthenticated } from "../core/authentication";
import { mustBeDefined } from "common/utils/mustBeDefined";

export type ReportingClientConfig = ApiClientConfig;

export interface CreateReportResponse {
  response: {
    reportName: string;
    results?: {
      rows: Array<{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        rows_unloaded: number;
      }>;
    };
  };
}

export class ReportingClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: ReportingClientConfig = { baseURL: mustBeDefined(process.env.REPORTING_API_URL) }) {
    this.apiClient = new ApiClient({ baseURL: `${baseURL}/v1/reports` });
  }

  async getReports(sellerId: string): Promise<SellerReports> {
    return await this.apiClient.get({ url: `/${sellerId}`, authentication: isAuthenticated });
  }

  async getReportUrl(sellerId: string, reportName: string): Promise<string> {
    return await this.apiClient.get({
      url: `/${sellerId}/${reportName}`,
      authentication: isAuthenticated,
    });
  }

  async generateReport(
    sellerId: string,
    type: SellerReportType,
    dateStart: Date,
    dateEnd: Date,
    logisticsSku: string | undefined
  ): Promise<CreateReportResponse> {
    try {
      return await this.apiClient.post({
        url: `/${sellerId}`,
        body: {
          type,
          dateStart,
          dateEnd,
          logisticsSku,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      if (!err.response || err.response.status !== 400) {
        throw err;
      } else {
        throw new DeliverrError("MISSING_PARAMETERS");
      }
    }
  }
}
