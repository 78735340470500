import React, { FC } from "react";
import { Card, Stack, ThemeProps, Text } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import { format } from "date-fns";

import styled from "@emotion/styled";
import { PROBLEM_TYPE_LABEL_MAP } from "common/problems/problemTypeLabelMap";
import { CommaDateNoYear } from "common/date/DateFormat";
import { NCStatusPill } from "inbounds/steps/ship/NCStatusPill";
import { ProblemRowData } from "common/components/ProblemsTable/types";
import { ShippingPlanNameDisplay } from "common/components/ShippingPlanNameDisplay";

interface NonComplianceListMobileProps {
  rows: ProblemRowData[];
  onRowClick: (row: ProblemRowData) => void;
}

const NonComplianceListItemCard = styled(Card)<ThemeProps>(
  ({ theme }) => `
    border-radius: ${theme.border.radius.R4};
  `
);

const ListCardRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ListCardTitleRow = styled(ListCardRow)<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S4};
  `
);

const ListCardDetailsRow = styled(ListCardRow)<ThemeProps>(
  ({ theme }) => `
    align-items: flex-end;
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing.S1};
    }
  `
);

export const NonComplianceListMobile: FC<NonComplianceListMobileProps> = ({ rows, onRowClick }) => {
  return (
    <Stack gap="S2">
      {rows.map((row) => {
        const { id, problemType, updatedAt, shippingPlanId, shippingPlanName, status } = row;

        return (
          <NonComplianceListItemCard
            size="SM"
            key={`non-compliance-problem-${problemType}-${id}`}
            onClick={() => onRowClick(row)}
          >
            <ListCardTitleRow>
              <Text bold>{PROBLEM_TYPE_LABEL_MAP[problemType]}</Text>
              <Text size="label" appearance="INFO">
                <FormattedMessage
                  id="inbounds.nonCompliance.listMobile.dateUpdatedMessage"
                  defaultMessage="Updated {formattedUpdatedAt}"
                  values={{ formattedUpdatedAt: format(new Date(updatedAt), CommaDateNoYear) }}
                />
              </Text>
            </ListCardTitleRow>
            <ListCardDetailsRow>
              <Text size="label">
                <ShippingPlanNameDisplay shippingPlanId={shippingPlanId} shippingPlanName={shippingPlanName} />
              </Text>
              <NCStatusPill status={status} shipmentProblemType={problemType} />
            </ListCardDetailsRow>
          </NonComplianceListItemCard>
        );
      })}
    </Stack>
  );
};
