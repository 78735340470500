import { WithOrderItemFtlOrderItemOrLtlOrderItemOrDrayageOrderItemOrTransloadOrderItemOrAccessorialOrderItem } from "@deliverr/freight-client";

export interface FreightOrderState {
  orderResponse?: WithOrderItemFtlOrderItemOrLtlOrderItemOrDrayageOrderItemOrTransloadOrderItemOrAccessorialOrderItem[];
}

export enum OrderStatus {
  BOOKED = "BOOKED",
  PLANNED = "PLANNED",
  TENDERED = "TENDERED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  DRAFT = "DRAFT",
  QUOTED = "QUOTED",
  ERROR = "ERROR",
}
