export enum MediaActionTypes {
  MEDIA_FETCHED = "MEDIA_FETCHED",
  MEDIA_LOADED = "MEDIA_LOADED",
  MEDIA_LOADING_ERRORED = "MEDIA_LOADING_ERRORED",
}

export type MediaActions = ReturnType<typeof mediaHasLoaded | typeof mediaHasBeenFetched | typeof mediaLoadingFailed>;

export const mediaHasLoaded = (url: string) => ({
  type: MediaActionTypes.MEDIA_LOADED as const,
  url,
});

export const mediaHasBeenFetched = (url: string) => ({
  type: MediaActionTypes.MEDIA_FETCHED as const,
  url,
});

export const mediaLoadingFailed = (url: string, error: unknown) => ({
  type: MediaActionTypes.MEDIA_LOADING_ERRORED,
  url,
  error,
});
