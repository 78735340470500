import { Reducer } from "redux";
import { FacebookEditAction } from "../FacebookEditAction";
import { FacebookEditActionTypes } from "../actions/FacebookEditActionTypes";
import { selectAction } from "./selectAction";

export const initialSelectedAction: SelectedActionState = null;

export type SelectedActionState = FacebookEditAction | null;

export type SelectedActionAction = ReturnType<typeof selectAction>;

export const selectedActionReducer: Reducer<SelectedActionState, SelectedActionAction> = (
  state = initialSelectedAction,
  action
) => {
  switch (action.type) {
    case FacebookEditActionTypes.FACEBOOK_SELECT_ACTION:
      return action.action;
    default:
      return state;
  }
};
