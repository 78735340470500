import { InboundShipment } from "@deliverr/legacy-inbound-client";
import { SPThunkAction } from "common/ReduxUtils";
import { getSellerId } from "common/user/UserSelectors";
import { loadPrepByShippingPlanId } from "prep/store";
import { getShippingPlanId } from "inbounds/steps/InboundStepSelectors";
import { InboundShipmentActionTypes } from "inbounds/store/actions/shipment/InboundShipmentActionTypes";
import { getLoadedShipment } from "inbounds/store/selectors/shipments";
import { logError } from "Logger";
import { updateShipment } from "./updateShipment";
import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";

const BASE_SHIPMENT_STATUS: InboundShipmentStatus = InboundShipmentStatus.NEED_PACKAGE_DETAILS;

/**
 * Thunk for updating the loaded InboundShipment and other dependent objects
 */
export const updateLoadedShipmentContext =
  (updatedLoadedShipment: InboundShipment): SPThunkAction =>
  async (dispatch, getState) => {
    const shipmentId = updatedLoadedShipment.id;

    const state = getState();
    const currentLoadedShipment = getLoadedShipment(state);
    const sellerId = getSellerId(state);
    const shippingPlanId = getShippingPlanId(state);

    if (shipmentId !== currentLoadedShipment.id) {
      logError(
        {
          fn: "updateLoadedShipmentContext",
          updatedShipmentId: shipmentId,
          currentLoadedShipmentId: currentLoadedShipment.id,
        },
        new Error("Updated and current shipments do not match.")
      );
      return;
    }

    dispatch(updateShipment(updatedLoadedShipment.id, updatedLoadedShipment));

    // if the Shipment has rolled-back from another status, update other state
    if (
      updatedLoadedShipment.status === BASE_SHIPMENT_STATUS &&
      currentLoadedShipment.status !== BASE_SHIPMENT_STATUS
    ) {
      // reverting back to NEEDS_PACKAGE_DETAILS means that nothing should be confirmed or locked
      dispatch({ type: InboundShipmentActionTypes.ROLLBACK_CONFIRM_BOXES, shipmentId: updatedLoadedShipment.id });

      // since the server removes Prep when shipment status is rolled-back, the PrepRequest needs to be reloaded.
      await dispatch(loadPrepByShippingPlanId({ sellerId, shippingPlanId }));
    }
  };
