export enum ListType {
  Inventory = "inventoryList",
  InventoryV2 = "inventoryListV2",
  Bundle = "bundleList",
  BundleV2 = "bundleListV2",
  OrderIngestErrors = "orderIngestErrors",
  Orders = "orderList",
  Inbounds = "inboundList",
  Parcel = "parcelList",
  Prep = "prepList",
  ClaimUploads = "claimUploadsList",
  ReserveStorageOutbounds = "reserveStorageOutboundList",
  InboundListV2 = "inboundListV2",
  Carriers = "carriersList",
}

export const appendListType = (actionType: string, listType: ListType) => `${actionType}_${listType.toUpperCase()}`;
