import React from "react";
import styled from "@emotion/styled";
import { DefaultTheme } from "common/components/ui";

const StyledBreadcrumb = styled.ol<{}, DefaultTheme>(
  ({ theme }) => `
    list-style: none;
    padding: ${theme.spacing.S4};
    margin: 0;
    display: inline-block;
    white-space: nowrap;
`
);

export const Breadcrumb = (props) => <StyledBreadcrumb {...props} role="navigation" aria-label="breadcrumbs" />;

const StyledItem = styled.li<{ isAdminRoute: boolean }, DefaultTheme>(
  ({ isAdminRoute, theme }) => `
    display: inline-block;
    & a {
        color: ${isAdminRoute ? theme.colors.ORANGE[400] : theme.colors.NEUTRAL[500]};
        font-family: ${theme.font.family.TITLE!};
        font-size: ${theme.font.size.F4};
        text-overflow: ellipsis;
        :hover {
        color: ${isAdminRoute ? theme.colors.ORANGE[300] : theme.colors.BLUE[300]};
        }
    }
    :last-child a {
        font-weight: ${theme.font.weight.BOLD as string};
    }
    :nth-of-type(n + 2):before {
        color: ${isAdminRoute ? theme.colors.ORANGE[400] : theme.colors.NEUTRAL[200]};
        font-size: ${theme.font.size.F4};
        content: "\\203A";
        display: inline-block;
        padding: 0 ${theme.spacing.S4};
    }
`
);

Breadcrumb.Item = StyledItem;
