import { NetworkWithMiddlewareRequest, NetworkWithMiddlewareResponse } from "./createNetworkWithMiddleware";

type MiddlewareFn = (
  req: NetworkWithMiddlewareRequest,
  next: (req: NetworkWithMiddlewareRequest) => Promise<NetworkWithMiddlewareResponse>
) => Promise<NetworkWithMiddlewareResponse>;

/** Middleware allows you to access and modify both the response and requests in
 * the Network layer, manipulating fetchQuery. IMPORTANT: The only values
 * actually used in the request are `fetchOpts` and `graphqlUrl`, modifying
 * other values will not affect the response */
export class NetworkMiddleware {
  call: MiddlewareFn;

  constructor(call: MiddlewareFn) {
    this.call = call;
  }
}
