/* eslint-disable @typescript-eslint/promise-function-async */
export const resolveTimeout = <T>(ms: number, resolveValue: T | PromiseLike<T>) =>
  new Promise<T>((resolve) => {
    const id = window.setTimeout(() => {
      window.clearTimeout(id);
      resolve(resolveValue);
    }, ms);
  });

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const raceTimeout = <T1, T2 = void>(promise: T1, ms: number, resolveValue: T2 | PromiseLike<T2>) =>
  Promise.race<T1 | T2>([promise, resolveTimeout(ms, resolveValue)]);
