import { TimeWindow } from "@deliverr/commons-objects";

export enum StoragePickupInputNames {
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  PHONE_NUMBER = "PHONE_NUMBER",
  EMAIL = "EMAIL",
  OPERATING_HOURS = "OPERATING_HOURS",
  READY_DATE = "READY_DATE",
  LIFTGATE_REQUIRED = "LIFTGATE_REQUIRED",
  PICKUP_REFERENCE_NUMBER = "PICKUP_REFERENCE_NUMBER",
}
export interface StoragePickupInputValues {
  [StoragePickupInputNames.FIRST_NAME]: string;
  [StoragePickupInputNames.LAST_NAME]: string;
  [StoragePickupInputNames.PHONE_NUMBER]: string;
  [StoragePickupInputNames.EMAIL]: string;
  [StoragePickupInputNames.OPERATING_HOURS]: TimeWindow;
  [StoragePickupInputNames.READY_DATE]: Date;
  [StoragePickupInputNames.LIFTGATE_REQUIRED]: boolean;
  [StoragePickupInputNames.PICKUP_REFERENCE_NUMBER]: string;
}
