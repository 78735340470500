export const input = {
  /**
   * @constant `height` 40px | 2.5rem
   */
  height: "2.5rem",
  /**
   * @constant `selectionHeight` 16px  1rem
   */
  selectionHeight: "1rem",
  focus: {
    /**
     * @constant `BLUE` `0px 0px 0px 2px rgba(45, 140, 237, 0.2)`
     */
    BLUE: "0px 0px 0px 2px rgba(45, 140, 237, 0.2)",
    /**
     * @constant `RED` `0px 0px 0px 2px rgba(247, 67, 67, 0.2)`
     */
    RED: "0px 0px 0px 2px rgba(247, 67, 67, 0.2)",
  },
};
