import { useEffect, useMemo } from "react";
import { useAsync } from "react-use";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";
import { productClient } from "Clients";
import log from "Logger";

export const useFetchProductFromChildOrParentDsku = ({ dsku }: { dsku?: string }) => {
  const { formatMessage } = useIntl();

  const ctx = useMemo(() => ({ fn: "useFetchProduct", dsku }), [dsku]);
  const { value, loading: isLoading, error } = useAsync(async () => {
    if (!dsku) {
      return;
    }
    log.info({ ...ctx }, "getting product");
    const productDetails = await productClient.getUnifiedProducts([dsku]);
    if (!productDetails) {
      throw new Error("error fetching product");
    }
    if (productDetails[dsku]?.packOf) {
      return (await productClient.getUnifiedProducts([productDetails[dsku].packOf!]))[productDetails[dsku].packOf!];
    } else {
      return productDetails[dsku];
    }
  }, [dsku]);

  useEffect(() => {
    if (error) {
      log.warn({ ...ctx, error }, "error fetching product");
      toast.error(
        formatMessage({
          id: "product.fetchError",
          defaultMessage: "There was an issue fetching your product",
        })
      );
    }
  }, [ctx, error, formatMessage]);

  return {
    value,
    isLoading,
    error,
  };
};
