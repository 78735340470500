import { GlobalSearchResults, ServiceNames } from "../utils";
import { useWholesaleListTableV2 } from "wholesale/list/useWholesaleListTableV2";
import { usePrepListTable } from "prep/list/PrepListTable/usePrepListTable";
import { useReplenishmentListTable } from "transfers/listV2/useReplenishmentListTable";
import { useParcelListTable } from "parcel/screens/list/ParcelListTable/useParcelListTable";
import { generatePath, useHistory } from "react-router-dom";
import { useOrderListTable } from "order/listV2/OrderListTable/useOrderListTable";
import { Path } from "paths";
import { useUnconnectedInboundList } from "inbounds/list/useUnconnectedInboundList";

export const useSearchResultItem = (serviceName: ServiceNames, result: GlobalSearchResults[ServiceNames]) => {
  const history = useHistory();
  const { onRowClick: replenishmentItemClick } = useReplenishmentListTable();
  const { onRowClick: wholesaleItemClick } = useWholesaleListTableV2();
  const { onRowClick: prepItemClick } = usePrepListTable();
  const { onItemClick: parcelItemClick } = useParcelListTable();
  const { onItemClick: ecomItemClick } = useOrderListTable();
  const { onRowClick: inboundItemClick } = useUnconnectedInboundList();

  const inventoryItemClick = (item) => {
    history.push(generatePath(Path.inventoryDetail, { dsku: item.raw.dsku }));
  };

  const serviceClickHandlers = {
    [ServiceNames.REPLENISHMENT]: replenishmentItemClick,
    [ServiceNames.WHOLESALE]: wholesaleItemClick,
    [ServiceNames.PREP]: prepItemClick,
    [ServiceNames.PARCEL]: parcelItemClick,
    [ServiceNames.INVENTORY]: inventoryItemClick,
    [ServiceNames.ECOM]: ecomItemClick,
    [ServiceNames.INBOUND]: inboundItemClick,
  };

  const handleSearchResultItemClick = async (): Promise<void> => {
    const clickHandler = serviceClickHandlers[serviceName];
    if (clickHandler) {
      return await clickHandler(result);
    }
  };

  return {
    handleSearchResultItemClick,
  };
};
