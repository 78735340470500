import { wholesaleCreateLabels } from "../wholesaleCreate.labels";
import React, { useState, useEffect, ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ManagedStep } from "common/components/StepContainer";
import { useSelector } from "react-redux";
import sumBy from "lodash/sumBy";
import { ShipBy } from "../../../common/wholesale-common/components/ShipBy";
import { selectWholesaleCreate } from "../store/WholesaleCreateSelectors";
import { ShippingMethod, CargoType } from "./shipping";
import { AttachmentType, WholesaleShipmentType, WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { useWholesaleEstimationsStatus } from "./useWholesaleEstimationsStatus";
import { Box, Notification } from "common/components/ui";
import { isInternationalAddress } from "common/AddressUtils";
import { SelectedProductData } from "common/components/ProductChooser";
import { StorageProductData } from "common/clients/storage/StorageProduct/StorageProductData";
import { LTL_MINIMUM_TOTAL_WEIGHT } from "@deliverr/commons-clients/lib/transportation/legacy-copy-pasta";

export const EstimationErrorNotification = () => {
  const { formatMessage } = useIntl();
  return (
    <Box width="80%" paddingBottom="S6">
      <Notification
        data-testid="estimation-error-notification"
        appearance="DANGER"
        content={{ description: formatMessage(wholesaleCreateLabels.steps.shipping.estimationError) }}
      />
    </Box>
  );
};

export const WholesalePickShippingStep = () => {
  const { formatMessage } = useIntl();

  const {
    shippingMethod,
    cargoType,
    labelFiles,
    wholesaleOrder,
    validShipBy,
    destinationAddress,
    selectedProducts,
    selectedProductsCaseInfo,
  } = useSelector(selectWholesaleCreate);
  const shipments = wholesaleOrder?.shipments ?? [];
  const shipBy = shipments[0]?.shipBy;
  const { [AttachmentType.SHIPPING_LABELS]: shippingLabelFilename } = labelFiles ?? {};
  const { [AttachmentType.COMMERCIAL_INVOICE]: commercialInvoiceFilename } = labelFiles ?? {};
  const [selectedShippingMethod, setShippingMethod] = useState(shippingMethod);
  const [selectedCargo, setCargo] = useState(cargoType);
  const [selectedShipByDate, setShipByDate] = useState<Date | string | undefined>(
    shipBy ?? validShipBy?.calculatedASAPShipBy
  );
  const [hasShipByDateChanged, setHasShipByDateChanged] = useState(false);
  const { isCreateOrderLoading, deliverrParcelEstimation, deliverrPalletEstimation, hasFailedEstimation } =
    useWholesaleEstimationsStatus();

  useEffect(() => {
    if (!selectedShipByDate) {
      setShipByDate(shipBy ?? validShipBy?.calculatedASAPShipBy);
    }
  }, [validShipBy?.calculatedASAPShipBy, shipBy, selectedShipByDate, setShipByDate]);

  const onShippingMethodChange = (value) => {
    if (value !== selectedShippingMethod) {
      setShippingMethod(value);
      setCargo(undefined);
    }
  };
  const onShipmentTypeMethodChange = (value) => {
    setCargo(value);
  };
  const onShipByChange = (value: Date) => {
    setShipByDate(value);
    setHasShipByDateChanged(true);
  };

  const fetchShipmentWeight = (
    selectedProductsDSKUMap: { [dsku: string]: SelectedProductData },
    selectedProductsCaseInfoDSKUMap: { [dsku: string]: StorageProductData }
  ) =>
    sumBy(
      Object.values(selectedProductsDSKUMap),
      (details) => (selectedProductsCaseInfoDSKUMap?.[details.dsku]?.caseWeight ?? 0) * (details?.numberOfCases ?? 0)
    );

  const freightEstimationError = ((): ReactElement | null => {
    const shipmentWeight = fetchShipmentWeight(selectedProducts, selectedProductsCaseInfo);
    if (shipmentWeight < LTL_MINIMUM_TOTAL_WEIGHT) {
      return (
        <FormattedMessage
          {...wholesaleCreateLabels.steps.shipping.freightEstimationErrorForLessWeight}
          values={{ minimumWeight: LTL_MINIMUM_TOTAL_WEIGHT }}
        />
      );
    }
    return null;
  })();

  const isInternationalWholesaleOrder = isInternationalAddress(destinationAddress!);
  const isWaitingForCommercialInvoice: Boolean = isInternationalWholesaleOrder ? !commercialInvoiceFilename : false;
  const canGoNext =
    selectedShippingMethod &&
    selectedCargo &&
    (selectedShippingMethod === WholesaleShippingMethod.SELLER && selectedCargo === WholesaleShipmentType.PARCEL
      ? shippingLabelFilename && !isWaitingForCommercialInvoice
      : true);

  const { selectShipBy } = wholesaleCreateLabels.steps.shipping;

  return (
    <ManagedStep
      title={formatMessage(wholesaleCreateLabels.steps.shipping.title)}
      isNextDisabled={!canGoNext}
      isLoading={isCreateOrderLoading}
      nextData={async () => {
        return {
          selectedShippingMethod,
          selectedCargo,
          selectedShipByDate: hasShipByDateChanged ? selectedShipByDate : undefined,
        };
      }}
    >
      <>
        <ShippingMethod
          value={selectedShippingMethod}
          onChange={onShippingMethodChange}
          isInternationalWholesaleOrder={isInternationalWholesaleOrder}
        />
        {selectedShippingMethod && (
          <CargoType
            shipmentType={selectedCargo}
            shippingMethod={selectedShippingMethod}
            onCargoTypeChange={onShipmentTypeMethodChange}
            palletEstimatedTotal={deliverrPalletEstimation}
            parcelEstimatedTotal={deliverrParcelEstimation}
            isInternational={isInternationalWholesaleOrder}
            freightDisableReason={freightEstimationError}
            productsCaseInfo={selectedProductsCaseInfo}
            shipmentItems={wholesaleOrder?.shipments?.[0].shipmentItems ?? []}
          />
        )}
        <Box width="80%" paddingBottom="S6">
          <ShipBy
            date={parseDate(selectedShipByDate)}
            minDate={parseDate(validShipBy?.allowedScheduleRange?.startDate)}
            maxDate={parseDate(validShipBy?.allowedScheduleRange?.endDate)}
            onDateChange={onShipByChange}
            label={selectShipBy.label}
            toolTip={selectShipBy.toolTip}
            suffixMessage={hasShipByDateChanged || Boolean(shipBy) ? undefined : selectShipBy.suffixMessage}
            selectedCargoType={selectedCargo}
          />
        </Box>
        {hasFailedEstimation &&
          selectedShippingMethod !== WholesaleShippingMethod.SELLER &&
          !freightEstimationError && <EstimationErrorNotification />}
      </>
    </ManagedStep>
  );
};

const parseDate = (dateString: Date | string | undefined) => (dateString ? new Date(dateString) : undefined);
