import { keyBy } from "lodash/fp";
import { getInventorySearchService } from "inventory/list/InventoryListActions";
import { ProductListItem } from "inventory/list/InventoryListReducer";
import { ListType } from "common/list";

export const getProductListItemsByDsku = async (dskus: string[]) => {
  const service = getInventorySearchService(ListType.Inventory);
  const res = await service.searchByIds(dskus, "", "dsku");
  const hits = res.hits as ProductListItem[];
  return keyBy("dsku", hits);
};
