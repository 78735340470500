import { Product } from "@deliverr/commons-clients";
import { Packs } from "common/clients/storage/Packs/Packs";
import { getUnifiedProducts } from "common/wholesale-common/getUnifiedProducts";
import { compact, isEmpty, mapValues, uniq, values } from "lodash";

export const fetchMultiPackProductsByDskus = async (dskus: string[], dispatch): Promise<Packs> => {
  if (isEmpty(dskus)) {
    return {};
  }
  let unifiedProducts = await dispatch(getUnifiedProducts(dskus));
  const packProductSkus = compact(values(unifiedProducts).map((product) => product?.packOf));
  const uniqProductSkus = uniq(packProductSkus);

  if (packProductSkus.length) {
    const products = await dispatch(getUnifiedProducts(uniqProductSkus));
    unifiedProducts = { ...unifiedProducts, ...products };
  }

  return augmentPackProductInfo(unifiedProducts);
};

const augmentPackProductInfo = (unifiedProducts: { [dsku: string]: Product }): Packs => {
  return mapValues(unifiedProducts, (product) => {
    if (product.packOf) {
      // replace msku and name with parent product properties
      const parentProduct = unifiedProducts[product.packOf];
      return {
        ...parentProduct,
        ...product,
        msku: parentProduct.msku,
        name: parentProduct.name,
        barcodes: parentProduct.barcodes,
        packName: product.name,
      };
    } else {
      // products should be mapped to default packs,
      // irrespective of whether multi-pack feature is enabled or not
      const defaultPack = product.productCasePacks?.find((pack) => pack.isDefaultPack);
      return {
        ...product,
        ...(defaultPack && {
          packName: defaultPack?.packName,
          caseQty: defaultPack?.quantity,
          length: defaultPack?.length,
          width: defaultPack?.width,
          weight: defaultPack?.weight,
          height: defaultPack?.height,
          lengthUnit: defaultPack?.lengthUnit,
          weightUnit: defaultPack?.weightUnit,
        }),
      };
    }
  });
};
