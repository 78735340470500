import Tooltip, { RCTooltip } from "rc-tooltip";
import React, { FC, ReactNode, ReactNodeArray } from "react";

export type TooltipWithIconProps = {
  children?: ReactNode;
  message: JSX.Element | ReactNodeArray;
  tooltipProps?: Exclude<Partial<RCTooltip.Props>, "placement"> & { className?: string };
  className?: string;
  width?: number;
} & Pick<RCTooltip.Props, "placement">;

export const TooltipWithIcon: FC<TooltipWithIconProps> = ({ placement, message, tooltipProps, children }) => {
  return (
    <Tooltip trigger={["hover"]} placement={placement} overlay={message} {...tooltipProps}>
      {children}
    </Tooltip>
  );
};
