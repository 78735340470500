import { MarketplaceId } from "@deliverr/commons-objects";
import { formatListing } from "common/components/ui";
import { Marketplace } from "common/Marketplace";
import { marketplaceListingConfig } from "common/MarketplaceUtils";

const CHAR_LIMIT_FOR_NAME_COL = 9;

const breakLongNames = (name: string) =>
  name.length > CHAR_LIMIT_FOR_NAME_COL ? name.split(/(?=[A-Z])/).join(" ") : name;

export const getSalesChannel = (marketPlaceId: MarketplaceId | string) => {
  const { name, logo } = marketplaceListingConfig(marketPlaceId);
  const formattedName = name === Marketplace.UNKNOWN ? formatListing(marketPlaceId) : breakLongNames(name);
  return {
    name: formattedName,
    logo,
  };
};
