import React from "react";
import { Anchor, Notification } from "common/components/ui";
import { Appearance } from "common/components/ui/shared";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { useIntl } from "react-intl";
import { SIMPLE_PREP_PAGE_LABELS } from "./SimplePrepPage.labels";
import { PREP_PRICING_AND_BILLING_HELP_LINK } from "prep/constants";
import { PREP_HELP_CENTER_NOTIFICATION_LABELS } from "prep/PrepHelpCenterNotification.label";
import styled from "@emotion/styled";

const StyledAnchor = styled(Anchor)`
  margin-left: 1.5rem;
`;

export const KittingAdditionalFeesNotification = () => {
  const { formatMessage } = useIntl();
  return (
    <Notification
      appearance={Appearance.WARNING}
      content={{
        title: FormattedMessage({ ...SIMPLE_PREP_PAGE_LABELS.kittingNotificationTitle }),
        description: <FormattedMessage {...SIMPLE_PREP_PAGE_LABELS.kittingNotificationDescription} />,
      }}
      append={
        <StyledAnchor href={PREP_PRICING_AND_BILLING_HELP_LINK} target="_blank">
          {formatMessage({ ...PREP_HELP_CENTER_NOTIFICATION_LABELS.link })}
        </StyledAnchor>
      }
    />
  );
};
