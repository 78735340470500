import { KitComponentOrderItemDto } from "@deliverr/replenishment-client";

export const fetchCorrectComponentQuantity = (kitComponentOrderItems: KitComponentOrderItemDto[]) => {
  return kitComponentOrderItems.map(({ unitsPerPack, totalUnits, ...rest }) => {
    const totalUnitsInMultipleOfCasePack =
      totalUnits % unitsPerPack === 0 ? totalUnits : Math.ceil(totalUnits / unitsPerPack) * unitsPerPack;

    return { ...rest, unitsPerPack, totalUnits: totalUnitsInMultipleOfCasePack };
  });
};
