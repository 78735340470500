import React from "react";
import { Box, Title, Text } from "common/components/ui";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";
import { FlexDiv } from "common/components/FlexDiv";
import { FormattedMessage } from "react-intl";
import { TextAlign } from "common/components/ui/shared";

export const SelectStorageInboundProductsHeader: React.FC = () => (
  <Box marginBottom="S6">
    <FlexDiv justifyContent="center">
      <Title as="h3" displayAs="h4">
        <FormattedMessage id="inbounds.selectStorageInboundProducts.title" defaultMessage="Add case-packed products" />
      </Title>
    </FlexDiv>
    <Box width="30rem">
      <Text textAlign={TextAlign.center}>
        <FormattedMessage
          id="inbounds.selectStorageInboundProducts.description"
          defaultMessage="Case packs must have product information or a scannable barcode on the outside of the box. {learnMore}"
          values={{
            learnMore: <ElevioLearnMoreButton articleId={ElevioArticleId.CasePack} />,
          }}
        />
      </Text>
    </Box>
  </Box>
);
