import { CollapsibleSection } from "common/components/CollapsibleSection";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { Box, Text } from "common/components/ui";
import cls from "inbounds/steps/ship/CollapsableShipmentAddressDisplay.less";
import { UnconnectedShipmentAddressDisplay } from "inbounds/steps/ship/ShipmentAddressDisplay";
import { DeliverrAddress } from "@deliverr/commons-objects";

interface CollapsableStorageShipmentAddressDisplayProps {
  fromAddress?: DeliverrAddress;
  toAddress?: DeliverrAddress;
  toProvider: string;
}

const StatusSection = styled(Box)`
  width: 600px;
`;

export const CollapsableStorageShipmentAddressDisplay: FC<CollapsableStorageShipmentAddressDisplayProps> = ({
  fromAddress,
  toAddress,
  toProvider,
}) => {
  return (
    <CollapsibleSection
      classNameCollapsed={cls.shipmentDetailedButton}
      classNameExpanded={cls.shipmentDetails}
      collapsedContent={
        <Text bold>
          <FormattedMessage defaultMessage="Shipment Details" id="CollapsableShipmentAddressDisplay.address" />
        </Text>
      }
    >
      <StatusSection>
        <UnconnectedShipmentAddressDisplay fromAddress={fromAddress} toAddress={toAddress} toProvider={toProvider} />
      </StatusSection>
    </CollapsibleSection>
  );
};
