import log from "Logger";
import { FeatureName, SPLITS_WITH_DEFAULT_ON_TREATMENT } from "./FeatureName";
import { getSplitClient } from "./splitClient";

export const getFeatureTreatment = (featureName: FeatureName, attributes?: SplitIO.Attributes): string | undefined => {
  const ctx = { fn: "getFeatureTreatment", featureName, attributes };

  const splitClient = getSplitClient();
  if (!splitClient) {
    log.info(ctx, "No SplitIO client found, returning default treatments");
    return SPLITS_WITH_DEFAULT_ON_TREATMENT.includes(featureName) ? "on" : "off";
  }
  return splitClient.getTreatment(featureName, attributes);
};

/**
 * @deprecated in favor of useFeatureOn
 * features are now available in the redux store so you can access them with selectors, reducers, action creators, etc.
 */
export const isFeatureOn = (featureName: FeatureName, attributes?: SplitIO.Attributes): boolean => {
  const treatment = getFeatureTreatment(featureName, attributes);
  return treatment === "on";
};
