import { createReducer, ReducerDictionary, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { InternationalShippingActionTypes } from "./InternationalShippingActions";
import { ProductsWithCustoms } from "InternationalShipping/InternationalShippingTypes";
import { applyToAll } from "InternationalShipping/InternationalShippingHelpers";
import { pipe, reject, merge, useWith } from "lodash/fp";
import { notEmpty } from "common/utils/helpers";
import {
  hasFilledAndValidCustomProps,
  deserializeProductRes,
  sortByName,
} from "../../InternationalShipping/InternationalShippingHelpers";

export interface InternationalShippingState {
  products: ProductsWithCustoms;
  hasFetchedProducts: boolean;
}

export const internationalShippingInitialState: InternationalShippingState = {
  products: {},
  hasFetchedProducts: false,
};

const filterPersistedProducts = pipe(reject(hasFilledAndValidCustomProps), sortByName);
// eslint-disable-next-line react-hooks/rules-of-hooks
const mergePersistedProducts = useWith(merge, [deserializeProductRes, filterPersistedProducts]);
const handleProductData = (productsRes, persistedProducts) =>
  notEmpty(persistedProducts)
    ? mergePersistedProducts(productsRes, persistedProducts)
    : deserializeProductRes(productsRes);
export const handleProducts = pipe<any, any, any, any>(handleProductData, sortByName);

const reducers: ReducerDictionary<InternationalShippingState> = {
  ...handleSimpleReducerUpdates([InternationalShippingActionTypes.CLEAR_INTERNATIONAL_SHIPPING_PRODUCTS]),
  [InternationalShippingActionTypes.GET_INTERNATIONAL_SHIPPING_PRODUCTS]: (state, { products }) => ({
    ...state,
    products: handleProducts(products, state.products),
    hasFetchedProducts: true,
  }),
  [InternationalShippingActionTypes.UPDATE_INTERNATIONAL_SHIPPING_PRODUCT]: (state, { customsInformation }) => ({
    ...state,
    products: {
      ...state.products,
      [customsInformation.dsku]: {
        ...state.products[customsInformation.dsku],
        customsInformation: {
          ...state.products[customsInformation.dsku].customsInformation,
          ...customsInformation,
        },
      },
    },
  }),
  [InternationalShippingActionTypes.SAVE_INTERNATIONAL_SHIPPING_PRODUCT]: (state, { dsku }) => ({
    ...state,
    products: {
      ...state.products,
      [dsku]: {
        ...state.products[dsku],
        saved: true,
      },
    },
  }),
  [InternationalShippingActionTypes.UPDATE_INTERNATIONAL_SHIPPING_PRODUCTS]: (state, { prop, value }) => ({
    ...state,
    products: applyToAll(prop, value)(state.products),
  }),
};

export const internationalShippingReducer = createReducer<InternationalShippingState>(
  internationalShippingInitialState,
  reducers
);
