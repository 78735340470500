import { deriveStepId, getSellerRmtByRmtId } from "./RmtUtils";

import { RmtId } from "@deliverr/returns-client";
import { goToRmtStep } from "./store/action/RmtActions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRmtInfo } from "./store/useRmtInfo";

export const useReturnsRmtContainer = () => {
  const { sellerRmts, stepId } = useRmtInfo();
  const { rmtId } = useParams<{ rmtId: string }>() as { rmtId: RmtId };
  const { token } = useParams<{ token?: string }>();
  const dispatch = useDispatch();
  const isLoaded = sellerRmts.loaded;

  useEffect(() => {
    if (isLoaded && !stepId) {
      dispatch(goToRmtStep(deriveStepId(sellerRmts.data, rmtId, token)));
    }
  }, [dispatch, isLoaded, rmtId, sellerRmts.data, stepId]);

  return {
    sellerRmts,
    stepId,
    rmt: getSellerRmtByRmtId(sellerRmts.data, rmtId),
  };
};
