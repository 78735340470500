import React from "react";
import { Box, Radio, Stack, Checkbox, Pill, PillColors } from "common/components/ui";
import { LocalizedText } from "common/components/LocalizedText";
import { TextAlign } from "common/components/ui/shared";
import { Currency, CurrencyFormat, useCurrencyValue } from "common/components/Currency";
import {
  Tile,
  StyledContainer,
  DarkLocalizedText,
  Content,
  PriceColumn,
  PriceContainer,
  UndiscountedPrice,
  PriceDescription,
  FlexportPlusMessage,
  CustomPriceLabel,
} from "./ServiceSelector.styles";
import { ServiceEmphasisLabel, ServiceSelectorProps } from "./ServiceSelector.types";
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import { FlexportPlusBadge } from "common/components/FlexportPlus/FlexportPlusBadge";

const EMPHASIS_COLORS: Record<ServiceEmphasisLabel, PillColors> = {
  INCLUDED: PillColors.GRAY,
  RECOMMENDED: PillColors.GREEN,
  FASTEST: PillColors.BLUE,
  LEAST_EXPENSIVE: PillColors.PURPLE,
};

const EMPHASIS_LABELS: Record<ServiceEmphasisLabel, MessageDescriptor> = defineMessages({
  INCLUDED: {
    id: "common.included",
    defaultMessage: "Included",
  },
  RECOMMENDED: {
    id: "common.recommended",
    defaultMessage: "Recommended",
  },
  FASTEST: {
    id: "common.fastest",
    defaultMessage: "Fastest",
  },
  LEAST_EXPENSIVE: {
    id: "common.leastExpensive",
    defaultMessage: "Least Expensive",
  },
});

const PRICE_CONTAINER_MESSAGES = {
  PER_UNIT: {
    id: "serviceSelector.perUnit",
    defaultMessage: "/unit",
  },
  PER_BOX: {
    id: "serviceSelector.perUnit",
    defaultMessage: "/box",
  },
};

const getPriceContainerMessages = (
  isPerUnit: boolean,
  isPerBox: boolean,
  numberOfBoxes: number | undefined,
  numberOfUnits: number | undefined,
  price: number
) => {
  if (isPerUnit && !numberOfUnits) {
    return (
      <FormattedMessage
        id={PRICE_CONTAINER_MESSAGES.PER_UNIT.id}
        defaultMessage={PRICE_CONTAINER_MESSAGES.PER_UNIT.defaultMessage}
      />
    );
  }
  if (isPerBox && !numberOfBoxes) {
    return (
      <FormattedMessage
        id={PRICE_CONTAINER_MESSAGES.PER_BOX.id}
        defaultMessage={PRICE_CONTAINER_MESSAGES.PER_BOX.defaultMessage}
      />
    );
  }

  if (isPerUnit && numberOfUnits) {
    return (
      <FormattedMessage
        id="serviceSelector.perUnit"
        defaultMessage="/unit for {amount}"
        values={{
          amount: <Currency currency={CurrencyFormat.USD} value={numberOfUnits * price} maximumFractionDigits={2} />,
        }}
      />
    );
  }
  if (isPerBox && numberOfBoxes) {
    return (
      <FormattedMessage
        id="serviceSelector.perUnit"
        defaultMessage="/box for {amount}"
        values={{
          amount: <Currency currency={CurrencyFormat.USD} value={numberOfBoxes * price} maximumFractionDigits={2} />,
        }}
      />
    );
  }
  return (
    <FormattedMessage
      id={PRICE_CONTAINER_MESSAGES.PER_UNIT.id}
      defaultMessage={PRICE_CONTAINER_MESSAGES.PER_UNIT.defaultMessage}
    />
  );
};

export const ServiceSelector = ({
  as,
  value,
  label,
  name,
  description,
  appendToDescription,
  numberOfBoxes,
  price,
  undiscountedPrice,
  priceDescription,
  checked,
  defaultChecked,
  onChange,
  isPerUnit,
  isPerBox,
  flexportPlusMessage,
  isDisabled,
  numberOfUnits,
  compact,
  emphasisLabel,
  customPriceLabel,
  appendToPriceColumn,
  hasFullWidthDescription,
  totalUnits = 1,
}: ServiceSelectorProps) => {
  const { formatMessage } = useIntl();
  const formatCurrency = useCurrencyValue({
    truncateNonFractional: !isPerUnit,
  });
  const Input = as === "radio" ? Radio : Checkbox;
  const isTile = as === "tile";
  const Container = compact ? "div" : isTile ? Tile : StyledContainer;
  const priceFactor = !isPerUnit ? totalUnits : 1;

  const renderLabel = (
    <Stack direction="HORIZONTAL" gap="S2" alignItems="center">
      <div>
        <DarkLocalizedText bold as="div" message={label} />
      </div>
      {emphasisLabel && (
        <Pill color={EMPHASIS_COLORS[emphasisLabel]}>{formatMessage(EMPHASIS_LABELS[emphasisLabel])}</Pill>
      )}
    </Stack>
  );

  return (
    <Container>
      <Content>
        <Box maxWidth={hasFullWidthDescription ? undefined : "80%"}>
          {isTile ? (
            renderLabel
          ) : (
            <Input
              value={value}
              checked={checked}
              defaultChecked={defaultChecked}
              onChange={onChange}
              label={renderLabel}
              name={name}
              disabled={isDisabled}
              isRound={as === "roundCheckbox"}
            />
          )}
          {(description ?? appendToDescription) && (
            <Box paddingLeft={isTile ? undefined : "S5"} marginTop="S1">
              {description && <LocalizedText appearance="INFO" as="div" message={description} />}
              {flexportPlusMessage && (
                <FlexportPlusMessage>
                  {formatMessage(flexportPlusMessage, {
                    ...(flexportPlusMessage.values ?? {}),
                    flexportPlusBadge: <FlexportPlusBadge />,
                  })}
                </FlexportPlusMessage>
              )}
              {appendToDescription}
            </Box>
          )}
        </Box>
        {price !== undefined && (
          <PriceColumn>
            {customPriceLabel ? (
              <CustomPriceLabel message={customPriceLabel} />
            ) : (
              <>
                {price && (
                  <PriceContainer>
                    {undiscountedPrice && (
                      <Box marginRight="S1">
                        <UndiscountedPrice>{formatCurrency(undiscountedPrice * priceFactor)}</UndiscountedPrice>
                      </Box>
                    )}

                    {formatCurrency(price * priceFactor)}
                    {isPerUnit &&
                      getPriceContainerMessages(!!isPerUnit, !!isPerBox, numberOfBoxes, numberOfUnits, price)}
                    {isPerBox &&
                      getPriceContainerMessages(!!isPerUnit, !!isPerBox, numberOfBoxes, numberOfUnits, price)}
                  </PriceContainer>
                )}
              </>
            )}
            {priceDescription && (
              <PriceDescription textAlign={TextAlign.end} appearance="INFO" as="div" message={priceDescription} />
            )}
            {appendToPriceColumn}
          </PriceColumn>
        )}
      </Content>
    </Container>
  );
};
