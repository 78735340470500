import { BackButton } from "common/components/channel-integration/BackButton";
import { OnboardingMessages } from "onboarding/OnboardingMessages";
import { Path } from "paths";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

interface PreviousButtonProps {
  to: Path;
}

export const PreviousButton: FC<PreviousButtonProps> = ({ to }) => (
  <BackButton to={to} label={<FormattedMessage {...OnboardingMessages.previous} />} />
);
