import { Box, Text } from "common/components/ui";
import React from "react";
import { FormattedMessage } from "react-intl";
import { transfersShipmentCreateLabels } from "../../../transfersShipmentCreate.labels";
import {
  SelectSingleShipmentShippingType,
  SelectSingleShipmentShippingTypeProps,
} from "./SelectSingleShipmentShippingType";
import { useSelector } from "react-redux";
import { selectIsMultiShipmentWithFeatureOn } from "transfers/create/store/TransferCreateSelectors";
import { SelectMultipleShipmentShippingType } from "./SelectMultipleShipmentShippingType";

export interface ShipmentTypeTilesProps extends SelectSingleShipmentShippingTypeProps {}

export const ShipmentTypeTiles: React.FunctionComponent<ShipmentTypeTilesProps> = ({
  retryShippingEstimation,
  shippingEstimation,
  shippingMethod,
  cargoType,
}) => {
  const isMultiShipmentWithFeatureOn = useSelector(selectIsMultiShipmentWithFeatureOn);
  return (
    <Box width="95%" paddingBottom="S6" data-testid="shipping-method-selector">
      <Box paddingBottom="S5">
        <Text bold>
          <FormattedMessage {...transfersShipmentCreateLabels.steps.shipping.selectShippingMethod} />
        </Text>
      </Box>
      {isMultiShipmentWithFeatureOn ? (
        <SelectMultipleShipmentShippingType
          retryShippingEstimation={retryShippingEstimation}
          shippingEstimation={shippingEstimation}
          shippingMethod={shippingMethod}
          cargoType={cargoType}
        />
      ) : (
        <SelectSingleShipmentShippingType
          retryShippingEstimation={retryShippingEstimation}
          shippingEstimation={shippingEstimation}
          shippingMethod={shippingMethod}
          cargoType={cargoType}
        />
      )}
    </Box>
  );
};
