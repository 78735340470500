import React from "react";
import { ShowWarningForProductFn } from "common/components/ProductChooser/ShowWarningForProductFn";
import { FBAProductValidationWarningMessage } from "./FBAProductValidationWarningMessage";
import { isLargeCasePackForFBA } from "./isLargeCasePackForFBA";

const getValidationErrorMessage = (product) => {
  if (isLargeCasePackForFBA(product)) {
    return <FBAProductValidationWarningMessage />;
  }

  return null;
};

export const fbaValidations: ShowWarningForProductFn = (product) => {
  const errorMessage = getValidationErrorMessage(product);
  return {
    showError: !!errorMessage,
    message: errorMessage,
  };
};
