import React, { FC } from "react";
import styled from "@emotion/styled";
import { map } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { RadioTileProps, ThemeProps } from "common/components/ui";
import { useDispatch, useSelector } from "react-redux";
import { ShippingType } from "@deliverr/replenishment-client";
import { WholesaleShipmentType, WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { BorderlessButton } from "common/components/BorderlessButton";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { transferCreateChooseShippingConfig } from "transfers/create/actions";
import { FlexRadioTileGroup } from "../../fba/shipping/FlexRadioTileGroup";
import { InlineSpinner } from "../../fba/shipping/AmazonShipmentType";
import { getReplenishmentShippingPartnerFromWholesaleType } from "../utils/getReplenishmentShippingPartnerFromWholesaleType";
import { EstimateMap } from "./useReplenishmentOrderShippingStep";
import { shippingTypeMessages } from "./ReplenishmentOrderShippingTileMessages";
import { SelectSingleShipmentShippingTypeContent } from "./SelectSingleShipmentShippingTypeContent";

const StyledButton = styled(BorderlessButton)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.BLUE["300"]};
  font-weight: ${theme.font.weight.BOLD};
  margin-left: ${theme.spacing.S2};
  `
);

export interface SelectSingleShipmentShippingTypeProps {
  shippingEstimation: EstimateMap;
  shippingMethod: WholesaleShippingMethod;
  cargoType?: WholesaleShipmentType;
  retryShippingEstimation: (
    shippingMethod: WholesaleShippingMethod,
    destinationType: TransferCreationTypes,
    shippingType: ShippingType
  ) => Promise<void>;
}

export const SelectSingleShipmentShippingType: FC<SelectSingleShipmentShippingTypeProps> = ({
  retryShippingEstimation,
  shippingEstimation,
  shippingMethod,
  cargoType,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { destinationType, replenishmentOrder } = useSelector(selectTransferCreate);

  const onChangeShipmentType = (value: WholesaleShipmentType) => {
    dispatch(
      transferCreateChooseShippingConfig({
        // if the current shipping method is COLLECT and the user selects PARTIAL_PALLET, we should switch the shipping method to SELLER
        shippingMethod:
          shippingMethod === WholesaleShippingMethod.COLLECT ? WholesaleShippingMethod.SELLER : shippingMethod,
        shipmentType: value,
      })
    );
  };

  return (
    <FlexRadioTileGroup
      direction="HORIZONTAL"
      options={map(
        shippingEstimation[getReplenishmentShippingPartnerFromWholesaleType(shippingMethod, destinationType!)],
        (value, key) => {
          const shippingPartnerMessage = shippingTypeMessages[key];
          let content = (
            <div>
              <InlineSpinner />
              <FormattedMessage {...transfersShipmentCreateLabels.steps.shipping.cargoOptions.generatingQuote} />
            </div>
          );
          if (value?.hasError && value.errorMessage) {
            content = (
              <div>
                {value.errorMessage}
                {value.retryable && (
                  <StyledButton
                    onClick={async () =>
                      await retryShippingEstimation(shippingMethod, destinationType!, key as ShippingType)
                    }
                  >
                    <FormattedMessage
                      id={"replenishment.create.steps.shipping.cargoOptions.retry"}
                      defaultMessage="Retry"
                    />
                  </StyledButton>
                )}
              </div>
            );
          }
          if (!value?.hasError && value?.showMessage) {
            content = <div>{value.showMessage}</div>;
          }
          if (!value?.isPending && (value?.estimation ?? value?.hideEstimation)) {
            content = value?.hideEstimation ? (
              <></>
            ) : (
              <SelectSingleShipmentShippingTypeContent
                shipmentType={key as WholesaleShipmentType}
                shipmentTypeEstimate={value}
                shipmentItems={replenishmentOrder?.orderItems}
              />
            );
          }
          return {
            value: shippingPartnerMessage.value,
            label: formatMessage(shippingPartnerMessage.label),
            disabled: value?.disabled ?? value?.isPending ?? value?.hasError,
            content,
          } as RadioTileProps;
        }
      )}
      currentValue={cargoType}
      onChange={(evt) => {
        onChangeShipmentType(evt.currentTarget.value as WholesaleShipmentType);
      }}
    />
  );
};
