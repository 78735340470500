import {
  IngestionChannel,
  InvalidOrderItemDto,
  InvalidOrderItemReason,
  InvalidOrderItemResolution,
  OrderResponse,
} from "@deliverr/replenishment-client";
import { find } from "lodash/fp";

export const isOutOfStockOrder = (replenishmentOrder: OrderResponse): boolean =>
  replenishmentOrder?.ingestionChannel === IngestionChannel.SPS &&
  (replenishmentOrder.invalidOrderItems?.length ?? 0) > 0 &&
  !!find((a) => a.reason === InvalidOrderItemReason.OUT_OF_STOCK, replenishmentOrder?.invalidOrderItems ?? []);

export const isOutOfStockItemUnremoved = (invalidOrderItem: InvalidOrderItemDto): boolean =>
  invalidOrderItem.reason === InvalidOrderItemReason.OUT_OF_STOCK &&
  !!invalidOrderItem.resolvedDsku &&
  invalidOrderItem.resolution !== InvalidOrderItemResolution.REMOVED;
