import React, { FC, useEffect } from "react";
import { Modal, ModalProps, ThemeProps, Title, Text, BoxReset, Box, toast } from "common/components/ui";
import { TextAlign, defaultTheme } from "common/components/ui/shared";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { ShipmentResponse } from "@deliverr/replenishment-client";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { FormatAddress } from "common/components/Address";
import { getShipmentItemsSummary } from "./getShipmentItemsSummary";
import { CollapsibleSection } from "common/components/CollapsibleSection";
import classes from "./../../../../detail/ui/transferStatusTimeline.less";
import { ShipmentContents } from "inbounds/ShipmentDetails/ShipmentDetailCard/ShipmentContents";
import useSWR from "swr";
import { fetchMultiPackProductsByDskus } from "storage/utils/fetchMultiPackProductsByDskus";
import { useDispatch } from "react-redux";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import { isEmpty } from "lodash";

const ContentBox = styled.div<ThemeProps>(
  ({ theme }) => `
  padding-bottom: ${theme.spacing.S5};
`
);

interface ViewShipmentDetailsModalProps extends Pick<ModalProps, "show" | "onClose"> {
  shipment?: ShipmentResponse;
}

export const ViewShipmentDetailsModal: FC<ViewShipmentDetailsModalProps> = ({ show, onClose, shipment }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const dskus = shipment?.shipmentItems?.map(({ dsku }) => dsku) ?? [];

  const { data: shipmentProducts, error } = useSWR(
    !isEmpty(dskus) ? ["viewShipmentDetailsModal", dskus] : null,
    async () => {
      const products = await fetchMultiPackProductsByDskus(dskus, dispatch);
      return products;
    },
    {
      revalidateOnFocus: false,
    }
  );
  const isLoading = !shipmentProducts && !error;
  const isError = !!error;
  useEffect(() => {
    if (isError) {
      toast.error(
        <FormattedMessage
          id="shipmentDetails.fetchProductDetail.loadingError"
          defaultMessage="An error occurred while fetching shipment product details."
        />,
        {
          autoClose: 5000,
          toastId: "loadShipmentProductsDetailError",
        }
      );
    }
  }, [isError]);

  if (!shipment) {
    return null;
  }
  const { distributionChannelShipmentId, destinationWarehouseAddress } = shipment;
  const { numOfCases, numOfSkus, numOfUnits } = getShipmentItemsSummary(shipment);
  return (
    <Modal
      hasCloseButton
      show={show}
      onClose={onClose}
      key={`view-shipment-${shipment.shipmentId}`}
      borderRadius={defaultTheme.border.radius.R5}
    >
      <Title displayAs="h4" textAlign={TextAlign.center}>
        <FormattedMessage
          id="transfers.create.steps.multiShipments.viewDetails.title"
          defaultMessage="Shipment details"
        />
      </Title>

      {distributionChannelShipmentId && (
        <BoxReset marginTop="S5">
          <ContentBox>
            <Text bold>{formatMessage(transfersShipmentCreateLabels.steps.shipmentDetails.shipmentId.label)}</Text>
            <Text>{distributionChannelShipmentId}</Text>
          </ContentBox>
        </BoxReset>
      )}

      {destinationWarehouseAddress && (
        <ContentBox>
          <Text bold>
            <FormattedMessage
              id="transfers.create.steps.multiShipments.viewDetails.destination"
              defaultMessage="Destination"
            />
          </Text>
          <Text>
            <FormatAddress address={destinationWarehouseAddress} />
          </Text>
        </ContentBox>
      )}

      <Box>
        <Text bold>
          <FormattedMessage id="transfers.create.steps.multiShipments.viewDetails.contents" defaultMessage="Contents" />
        </Text>
        <Text>
          {formatMessage(transfersShipmentCreateLabels.steps.shipping.shipmentItemDesc, {
            numOfCases,
            numOfSkus,
            numOfUnits,
          })}
        </Text>
      </Box>

      <CollapsibleSection
        classNameCollapsed={classes.transferStatusDetailsButton}
        classNameExpanded={classes.transferStatusDetails}
        collapsedContent={
          <Text bold>
            <FormattedMessage
              id="transfers.create.steps.multiShipments.viewDetails.showDetails"
              defaultMessage="Show details"
            />
          </Text>
        }
      >
        {isLoading ? (
          <LoadingSpinner center />
        ) : (
          shipmentProducts && !isError && <ShipmentContents products={shipmentProducts} shipment={shipment} />
        )}
      </CollapsibleSection>
    </Modal>
  );
};
