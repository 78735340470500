import React from "react";
import styled from "@emotion/styled";
import { FormGroup, Stack, ThemeProps } from "common/components/ui";
import { UnitSystemSelector } from "./UnitSystemSelector";
import { ShipmentTotalsForm } from "./ShipmentTotalsForm";
import { ShipmentTotalsNotifications } from "./ShipmentTotalsNotifications";
import { useShipmentTotalsValidation } from "./useShipmentTotalsValidation";
import { EstimatablePalletsInput } from "./PalletsInput";

const StyledContainer = styled.section<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S5} ${theme.spacing.S5} ${theme.spacing.S4};
  border: 1px solid ${theme.colors.NEUTRAL[80]};
`
);

export const EstimatedTotalsSection = ({ isIntlDtc }: { isIntlDtc: boolean }) => {
  const { missingTotalsError } = useShipmentTotalsValidation({ omitPallets: true });

  return (
    <>
      <StyledContainer>
        <Stack gap="S4">
          <UnitSystemSelector />
          <FormGroup hasError={!!missingTotalsError} errorMessage={missingTotalsError}>
            <ShipmentTotalsForm shouldShowBoxes={!isIntlDtc} shouldShowUnit />
          </FormGroup>
        </Stack>
        <ShipmentTotalsNotifications />
      </StyledContainer>
      {!isIntlDtc && <EstimatablePalletsInput />}
    </>
  );
};
