import { useLayoutEffect, useState } from "react";

export const useModal = (show: boolean) => {
  const [showModal, setShowModal] = useState(false);

  useLayoutEffect(() => {
    setShowModal(show);
  }, [show]);

  return {
    showModal,
  };
};
