import React, { FC, useMemo } from "react";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Select, SelectOptionType } from "common/components/ui";
import { selectWholesaleListSearchFilters } from "./store/selectWholesaleList";
import { wholesaleListChangeSourceTypeFilter } from "./actions/WholesaleListAction";
import { wholesaleListIngestionChannelFilterLabels } from "./wholesaleListIngestionChannelFilter.labels";
import { IngestionChannel } from "@deliverr/replenishment-client";
import { WholesaleIngestionChannel } from "./store/WholesaleListState";

export const SPS_CHANNELS = [IngestionChannel.SPS, "SPS_TEST" as IngestionChannel];

const StyledSelectWrapper = styled.div`
  min-width: 10rem;

  & > label {
    min-width: 100%;
  }
`;

export const WholesaleListIngestionChannelFilter: FC = () => {
  const dispatch = useDispatch();
  const { ingestionChannel } = useSelector(selectWholesaleListSearchFilters);
  const { formatMessage } = useIntl();

  const selectedSourceType = useMemo(
    () => ({
      value: ingestionChannel,
      label: formatMessage(wholesaleListIngestionChannelFilterLabels[ingestionChannel]),
    }),
    [ingestionChannel, formatMessage]
  );

  const sourceTypeOptions = useMemo(
    () =>
      Object.values(WholesaleIngestionChannel).map((value) => ({
        value,
        label: formatMessage(wholesaleListIngestionChannelFilterLabels[value]),
      })),
    [formatMessage]
  );

  const handleChange = (inputSourceType: SelectOptionType) => {
    dispatch(wholesaleListChangeSourceTypeFilter(inputSourceType.value as WholesaleIngestionChannel));
  };

  return (
    <StyledSelectWrapper>
      <Select flexWidth onChange={handleChange} value={selectedSourceType} options={sourceTypeOptions} />
    </StyledSelectWrapper>
  );
};
