import { defineMessages, MessageDescriptor } from "react-intl";
import { PrepChargeGranularity } from "../types/PrepChargeGranularity";

export const PREP_CHARGE_GRANULARITY_LABELS: Record<PrepChargeGranularity, MessageDescriptor> = defineMessages({
  PER_BOX: {
    id: "inbounds.prep.chargeGranularity.perBox",
    defaultMessage: "{costPerItem}{costSpecialHandling}/box",
  },
  PER_UNIT: {
    id: "inbounds.prep.chargeGranularity.perUnit",
    defaultMessage: "{costPerItem}{costSpecialHandling}/unit",
  },
  PER_CONTAINER: {
    id: "inbounds.prep.chargeGranularity.perContainer",
    defaultMessage: "{costPerItem}/container",
  },
  PER_KIT: {
    id: "inbounds.prep.chargeGranularity.perKit",
    defaultMessage: "{costPerItem}/kit",
  },
});
