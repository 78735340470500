import { Box, Text, Title } from "common/components/ui";
import { TextAlign } from "common/components/ui/shared";
import * as React from "react";

interface Props {
  title: string;
  subtitle: string;
}

export const StepHeader = ({ title, subtitle }: Props) => (
  <Box marginBottom="S6">
    <Title as="h3" displayAs="h4">
      {title}
    </Title>
    <Text textAlign={TextAlign.center}>{subtitle}</Text>
  </Box>
);
