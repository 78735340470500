import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { ceil, sum, sumBy } from "lodash/fp";
import { divide, map } from "lodash";
import { useState } from "react";
import { getStorageInboundDetail } from "../store";
import { StorageShipmentConfirmationMessages } from "./StorageShipmentConfirmationMessages";
import { RootState } from "RootReducer";
import { format } from "date-fns";
import { CommaDateNoYear, TimeZone, VanillaTime } from "common/date/DateFormat";
import { getUniqueStorageItemSkus } from "storage/utils/getUniqueStorageItemSkus";
import { selectIsZoneBasedMerchant } from "common/user/UserSelectors";
import { ShipmentType } from "@deliverr/storage-client";

export const useShipmentConfirmation = () => {
  let cargoTypeContent, shippingContent, shippingDetails;
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const toggleOpen = () => setIsOpen((state) => !state);

  const { formatMessage } = useIntl();
  const cargoTypeTitle = formatMessage(StorageShipmentConfirmationMessages.CARGO_TYPE_TITLE);

  const { storageInboundDetail } = useSelector((state: RootState) => state);
  const { freightOrderDetails, storageItems } = storageInboundDetail.storageInboundOrder;

  const { storageInboundOrder, storageItemDetails } = useSelector(getStorageInboundDetail);
  const skuCount = getUniqueStorageItemSkus(storageItems, storageItemDetails).length;
  const unitCount = sumBy("expectedQty", storageItems);
  const caseCount = ceil(
    sum(map(storageItems, (item) => divide(item.expectedQty, storageItemDetails[item.dsku]?.caseQty ?? 1)))
  );
  const palletCount = storageInboundOrder.palletCount ?? 1;
  const isPalletReceivingSummaryDefined = !!storageInboundOrder.palletReceivingSummary?.totalPalletCount;

  const shippingTitle = isPalletReceivingSummaryDefined
    ? formatMessage(StorageShipmentConfirmationMessages.RECEIVED_TITLE)
    : formatMessage(StorageShipmentConfirmationMessages.SHIPPING_TITLE);

  const cargoReadyDateTitle = formatMessage(StorageShipmentConfirmationMessages.CARGO_READY_DATE);
  const cargoReadyDate =
    freightOrderDetails?.pickupDetails?.appointment?.startTime &&
    format(new Date(freightOrderDetails?.pickupDetails?.appointment?.startTime), CommaDateNoYear);
  const startTime =
    freightOrderDetails?.pickupDetails?.appointment?.startTime &&
    format(new Date(freightOrderDetails?.pickupDetails?.appointment?.startTime), VanillaTime);
  const endTime =
    freightOrderDetails?.pickupDetails?.appointment?.endTime &&
    format(new Date(freightOrderDetails?.pickupDetails?.appointment?.endTime), VanillaTime);
  const timezone =
    freightOrderDetails?.pickupDetails?.appointment?.endTime &&
    format(new Date(freightOrderDetails?.pickupDetails?.appointment?.endTime), TimeZone);
  const cargoReadyTimeWindow =
    startTime &&
    endTime &&
    formatMessage(StorageShipmentConfirmationMessages.CARGO_READY_TIME_WINDOW, { startTime, endTime, timezone });
  const isZoneBasedMerchant = useSelector(selectIsZoneBasedMerchant);

  switch (storageInboundOrder.shipmentType) {
    case ShipmentType.PALLETIZED:
      cargoTypeContent = formatMessage(StorageShipmentConfirmationMessages.PALLET_CARGO_TYPE_CONTENT);
      shippingContent = formatMessage(StorageShipmentConfirmationMessages.PALLET_SHIPPING_CONTENT, {
        palletCount,
      });

      shippingDetails = formatMessage(StorageShipmentConfirmationMessages.PALLET_SHIPPING_DETAILS, {
        skuCount,
        caseCount,
        unitCount,
      });

      break;

    default:
      cargoTypeContent = formatMessage(StorageShipmentConfirmationMessages.FLOOR_LOADED_CARGO_TYPE_CONTENT);
      shippingContent = formatMessage(StorageShipmentConfirmationMessages.FLOOR_LOADED_SHIPPING_CONTENT, {
        caseCount,
      });

      shippingDetails = formatMessage(StorageShipmentConfirmationMessages.FLOOR_LOADED_SHIPPING_DETAILS, {
        skuCount,
        unitCount,
      });

      break;
  }
  if (isPalletReceivingSummaryDefined) {
    shippingContent = formatMessage(StorageShipmentConfirmationMessages.PALLET_SHIPPING_CONTENT, {
      palletCount: storageInboundOrder.palletReceivingSummary?.totalPalletCount,
    });
    shippingDetails = formatMessage(
      storageInboundOrder.shipmentType === ShipmentType.FLOOR_LOADED_CONTAINER
        ? StorageShipmentConfirmationMessages.FLOOR_LOADED_EXPECTED_SHIPPING_DETAILS
        : StorageShipmentConfirmationMessages.PALLET_EXPECTED_SHIPPING_DETAILS,
      {
        skuCount,
        caseCount,
        unitCount,
        palletCount,
      }
    );
  }

  return {
    cargoTypeTitle,
    shippingTitle,
    cargoTypeContent,
    cargoReadyDateTitle,
    cargoReadyDate,
    cargoReadyTimeWindow,
    shippingContent,
    shippingDetails,
    toggleOpen,
    isOpen,
    isZoneBasedMerchant,
  };
};
