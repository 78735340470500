import { DefaultTheme, Text, BoxReset } from "common/components/ui";
import { TextAlign } from "common/components/ui/shared";
import styled from "@emotion/styled";
import { ExternalLink } from "common/components/ExternalLink";
import React from "react";

export const StyledUl = styled.ul<{}, DefaultTheme>(
  ({ theme }) => `
    padding-left: ${theme.spacing.S4};
    margin-bottom: 0;

    li:not(:last-child) {
      margin-bottom: ${theme.spacing.S4};
    }
  `
);

export const StyledOl = styled.ol<{}, DefaultTheme>(
  ({ theme }) => `
    padding-left: ${theme.spacing.S4};
    margin-bottom: 0;

    li:not(:last-child) {
      margin-bottom: ${theme.spacing.S4};
    }
  `
);

export const StyledExternalLink = styled(ExternalLink)<{}, DefaultTheme>(
  ({ theme }) => `
  font-weight: ${theme.font.weight.BOLD};
`
);

export const InstructionsText = ({ children }) => (
  <BoxReset marginBottom="S4">
    <Text size="bodyLarge" textAlign={TextAlign.center}>
      {children}
    </Text>
  </BoxReset>
);
