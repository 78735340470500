import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { ApiResponse } from "./ApiResponse/ApiResponse";
import { handleSellerServiceError } from "./handleSellerServiceError";
import { SearchSfnAccountResult } from "./Organization/SearchSfnAccountResult";

export type SfnClientConfig = ApiClientConfig;

export class SfnClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: SfnClientConfig = { baseURL: mustBeDefined(process.env.SELLER_SERVICE_URL) }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1/sfn`,
    });
  }

  async searchSfnAccounts(q: string): Promise<ApiResponse<SearchSfnAccountResult[]>> {
    try {
      return await this.apiClient.get({
        url: "/account",
        params: {
          q,
        },
        authentication: isAuthenticated,
      });
    } catch (error) {
      handleSellerServiceError("searchSfnAccounts", error);
    }
  }
}
