export const PARCEL_LEARN_MORE_LINK = "https://www.flexport.com/products/parcel/";
export const PARCEL_SCOPE = ["read_logistics_parcels", "write_logistics_parcels"];
export const PARCEL_API_GUIDE = "https://docs.logistics-api.flexport.com/";
export const PARCEL_CHROME_EXTENSION =
  "https://chrome.google.com/webstore/detail/flexport-parcel-shipstati/amnajinkpgoaijchbfmeiahdecpggfjn";
export const PARCEL_SHIPSTAION_GUIDE =
  "https://topaz-cart-51a.notion.site/Flexport-Parcel-ShipStation-Integration-Getting-Started-88f56c9f23e84c8b9f11d76c42528267";

export const SHIPSTATION_LINK = "https://signin.shipstation.com/";
export const EASYPOST_LINK = "https://www.easypost.com/login";
export const EXTENSIV_LINK = "https://app.extensiv.com/login";
export const PROSHIP_LINK = "https://proshipinc.com/contact-us/";
export const SUBMIT_REQUEST_FORM = "https://support.portal.flexport.com/hc/en-us/requests/new";
export const INTERESTED_IN_PARCEL_FORM = "https://fulfillment.flexport.com/talk-to-us?services_interested_in=Parcel";
export const FLEXPORT_TRACKING_URL = "https://tracker.flexport.com";
export const MAX_REIMBURSEMENT_FOR_WHOLESALE_COST = 100;
