import { SearchOptions } from "@deliverr/logistics-search-client";
import { SearchRequest } from "../SearchService";
import { OpenSearchService } from "common/search/services/OpenSearchService";
import { QueryDslQueryContainer } from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";
import { addOpenSearchFilters, convertAlgoliaFiltersToOpenSearch } from "./OpenSearchUtils";
import { Sort } from "@deliverr/logistics-search-client/lib/src/clients/LogisticsSearchClient";

export class BundleOpenSearchService extends OpenSearchService {
  protected buildSearchOptions(request: SearchRequest): SearchOptions {
    const size = request.pageSize ?? this.config.searchConfig.hitsPerPage;
    const hasLegacyQueries = (request.filters?.length ?? 0) > 0 || request.numericFilter;
    const baseQuery: QueryDslQueryContainer = hasLegacyQueries ? convertAlgoliaFiltersToOpenSearch(request) : {};
    const openSearchQuery = addOpenSearchFilters(baseQuery, request.customizedOpenSearchFilters ?? []);

    const sort: Sort[] = [];

    if (request?.sort && request?.sort?.fieldName) {
      sort.push({
        [request.sort.fieldName]: {
          order: request.sort.direction,
          ...(request.sort.missing && { missing: request.sort.missing }),
          ...(request.sort.unmappedType && { unmapped_type: request.sort.unmappedType }),
        },
      });
    }
    sort.push({
      _score: {
        order: "desc",
      },
    });

    // Setup search options
    const searchOptions: SearchOptions = {
      query: openSearchQuery,
      page: (request.page ?? 0) + 1, // page is 1-based (https://github.com/deliverr/logistics-search/blob/36f75b0dd8b0893ab38677ee28c8a9de02b37c2e/services/search/src/gateways/SearchGateway.ts#L41)
      size,
      sort,
    };

    if (this.config.searchConfig.highlightMatches) {
      return {
        ...searchOptions,
        // By default, the seller portal search for inventory
        // targets name, msku, bsku
        highlight: {
          fields: {
            name: {},
            msku: {},
            bsku: {},
          },
        },
        hydrate: true,
      };
    }

    return searchOptions;
  }
}
