import styled from "@emotion/styled";
import { Text } from "common/components/ui/Text";
import { ThemeProps } from "common/components/ui";

export const LightText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
`
);

export const InspectionText = styled.span<ThemeProps>(
  ({ theme }) => `
  margin-right: ${theme.spacing.S4};
`
);

export const BoldText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[500]};
  font-weight: ${theme.font.weight.BOLD};
`
);

export const WarningText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.YELLOW[500]};
  margin-left: ${theme.spacing.S1};
  font-weight: ${theme.font.weight.BOLD};
`
);
