import { wholesaleCreateSetError, wholesaleCreateSetWholesaleOrder } from "./WholesaleCreateActions";
import { selectWholesaleCreate } from "../store/WholesaleCreateSelectors";
import { CreateWholesaleLoader } from "../CreateWholesaleLoader.types";
import { sharedLoadWholesaleOrderAction } from "../../../common/wholesale-common/sharedLoadWholesaleOrder";

export const loadWholesaleOrderAction = sharedLoadWholesaleOrderAction({
  loaderId: CreateWholesaleLoader.CreateWholesaleOrderLoader,
  setError: wholesaleCreateSetError,
  setValue: wholesaleCreateSetWholesaleOrder,
  selector: selectWholesaleCreate,
});
