import { parseISO } from "date-fns";
import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { SupportedNotificationTypes } from "./SupportedNotificationType";
import { NotificationType } from "../common/clients/notifications/Notification/NotificationType";

export const isNotificationsSuccess = ({ notifications }: RootState) => !notifications.loading && !notifications.error;
export const isNotificationsError = ({ notifications }: RootState) => notifications.error;

export const getNotifications = createSelector(
  (state: RootState) => state.notifications.byId,
  (state: RootState) => state.notifications.sortedIds,
  (byId, sortedIds) =>
    sortedIds
      .map((id) => byId[id])
      .map((notification) => ({
        ...notification,
        createdAt: parseISO(notification.createdAt as any),
        updatedAt: parseISO(notification.updatedAt as any),
      }))
      .filter((notification) => (SupportedNotificationTypes as readonly NotificationType[]).includes(notification.type))
);

export const areAnyUnviewedNotifications = ({ notifications }: RootState) =>
  !notifications.viewed && notifications.sortedIds.length > 0;
