import { OnboardingStage } from "./Organization/OnboardingStage";
import { mustBeDefined } from "common/utils/mustBeDefined";
import { pickBy } from "lodash";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { ApiResponse } from "./ApiResponse/ApiResponse";
import { handleSellerServiceError } from "./handleSellerServiceError";
import { Organization } from "./Organization/Organization";
import { SecuredApiKeyResponse } from "./Organization/SecuredApiKeyResponse";
import { ContactArea } from "@deliverr/business-types";
import { Entity } from "./User/Entity";
import { Role } from "./User/Role";

export type OrganizationClientConfig = ApiClientConfig;

export class OrganizationClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: OrganizationClientConfig = { baseURL: mustBeDefined(process.env.SELLER_SERVICE_URL) }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`,
    });
  }

  async getOrganizationWithUsers(organizationId: string): Promise<ApiResponse<Organization>> {
    try {
      return await this.apiClient.get({
        url: "/organization",
        params: {
          organizationId,
          includeUsers: true,
          includeSfn: true,
        },
        authentication: isAuthenticated,
      });
    } catch (error) {
      handleSellerServiceError("getOrganizationWithUsers", error);
    }
  }

  async updateSellerClaims(
    sellerId: string,
    isCatalogImported?: boolean,
    onboardingStage?: OnboardingStage,
    dealStage?: string,
    hasChargebeeAccount?: boolean,
    showChecklist?: boolean
  ): Promise<ApiResponse | undefined> {
    const bodyObject = pickBy(
      {
        dealStage,
        hasChargebeeAccount,
        isCatalogImported,
        onboardingStage,
        showChecklist,
      },
      (value) => typeof value !== "undefined"
    );

    try {
      return await this.apiClient.put({
        url: `organization/${sellerId}/claims/update`,
        body: bodyObject,
        authentication: isAuthenticated,
      });
    } catch (error) {
      handleSellerServiceError("updateSellerClaims", error);
    }
  }

  async updateOrganization(
    organizationId: string,
    attributes: Partial<Organization>
  ): Promise<ApiResponse | undefined> {
    try {
      return await this.apiClient.put({
        url: `/organization/${organizationId}`,
        body: attributes,
        authentication: isAuthenticated,
      });
    } catch (err) {
      handleSellerServiceError("updateOrganizationActiveStatus", err);
    }
  }

  async addNewUserToExistingSellerGroup(
    organizationId: string,
    firstName: string,
    lastName: string,
    email: string,
    entities: Entity[],
    role: Role,
    contactAreas: ContactArea[],
    phoneNumber: string | null
  ): Promise<ApiResponse | undefined> {
    try {
      return await this.apiClient.post({
        url: `/organization/${organizationId}/user`,
        body: {
          firstName,
          lastName,
          email,
          entities,
          role,
          contactAreas,
          phoneNumber,
        },
        authentication: isAuthenticated,
      });
    } catch (error) {
      handleSellerServiceError("addNewUserToExistingSellerGroup", error);
    }
  }

  async resendSignUpEmailInvitation(organizationId: string, email: string): Promise<ApiResponse | undefined> {
    try {
      return await this.apiClient.post({
        url: `/organization/${organizationId}/user/${email}/resend_email_invite`,
        body: null,
        authentication: isAuthenticated,
      });
    } catch (error) {
      handleSellerServiceError("resendSignUpEmailInvitation", error);
    }
  }

  async changeUserRoleInOrganization(
    organizationId: string,
    email: string,
    isUpgraded: boolean
  ): Promise<ApiResponse | undefined> {
    try {
      return await this.apiClient.put({
        url: `/organization/${organizationId}/user/${email}/`,
        body: null,
        params: { upgrade: isUpgraded },
        authentication: isAuthenticated,
      });
    } catch (error) {
      handleSellerServiceError("changeUserRoleInOrganization", error);
    }
  }

  async getAlgoliaSearchKey(organizationId: string): Promise<SecuredApiKeyResponse> {
    try {
      return await this.apiClient.get({
        url: `/organization/${organizationId}/algoliaSearchKey`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      handleSellerServiceError("getAlgoliaSearchKey", err);
    }
  }

  async getIsFlexportAccountConnected(
    organizationId: string,
    logisticsAccountId?: string
  ): Promise<{ connected: boolean; authorizationUrl: string; firstConnection: boolean }> {
    const redirectUrl = window.location.href;

    try {
      return await this.apiClient.get({
        url: `/organization/${organizationId}/isFlexportAccountConnected`,
        params: { logisticsAccountId, redirectUrl },
        authentication: isAuthenticated,
      });
    } catch (err) {
      handleSellerServiceError("getIsFlexportAccountConnected", err);
    }
  }
}
