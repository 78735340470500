import { RootState } from "RootReducer";
import { getLoadedPlannedShipment } from "inbounds/steps/InboundStepSelectors";
import { getIsFreight } from "inbounds/utils/shippingMethodUtils";
import logger from "Logger";

export const getShipmentFreightInfo = (shipmentId: number) => (rootState: RootState) => {
  const plannedShipment = rootState.inbound.plannedShipments.byId[shipmentId];
  return getIsFreight(plannedShipment?.shippingMethod)
    ? rootState.inbound.freightShipmentInfo?.[shipmentId]
    : undefined;
};

export const getLoadedShipmentFreightInfo = (rootState: RootState) => {
  const plannedShipment = getLoadedPlannedShipment(rootState);

  if (!plannedShipment) {
    logger.warn({ fn: "getLoadedShipmentFreightInfo" }, "Could not retrieve the loaded PlannedShipment");
    return;
  }
  return getShipmentFreightInfo(plannedShipment.id)(rootState);
};
