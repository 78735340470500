import { createActionCreator } from "common/ReduxUtils";
import { InboundActionTypes } from "inbounds/store/InboundActionTypes";
import { FreightTrackingInfoSaveData } from "common/clients/transportation/FreightTrackingInfo/FreightTrackingInfoSaveData";
import { FreightShipmentInfoFlags } from "inbounds/InboundTypes";

export interface SetShipmentFreightInfoAction extends FreightTrackingInfoSaveData, FreightShipmentInfoFlags {
  saveSource?: "CREATE" | "FETCH" | "UPDATE"; // what server interaction caused the update (if any)
}

export const setShipmentFreightInfo = createActionCreator<SetShipmentFreightInfoAction>(
  InboundActionTypes.SET_SHIPMENT_FREIGHT_INFO,
  "shipmentFreightInfo"
);
