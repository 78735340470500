import { SearchOptions } from "@deliverr/logistics-search-client";
import { SearchRequest, SearchResults } from "../SearchService";
import { OpenSearchService } from "common/search/services/OpenSearchService";
import { QueryDslQueryContainer } from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";

const SEARCH_TERM_WORD_LIMIT: number = 5;
const SEARCH_TERM_ID_WORD_LIMIT: number = 4;

export class OrderSearchService extends OpenSearchService {
  private searchableAttributesShippingAddress = [
    "name",
    "phone",
    "street1",
    "street2",
    "city",
    "state",
    "zip",
    "country",
  ];

  private searchableAttributesCleanShippingAddress = [...this.searchableAttributesShippingAddress, "email"];

  private searchableKeywords: string[] = ["objectID", "id", "marketplaceId", "error", "status", "items.dsku"];

  private searchableText: string[] = [
    ...this.searchableAttributesCleanShippingAddress.map((f) => `cleanShippingAddress.${f}`),
    ...this.searchableAttributesShippingAddress
      .filter((f) => !["name", "street1"].includes(f))
      .map((f) => `originalShippingAddress.${f}`),
  ];

  private searchableFuzzyText: string[] = [
    "originalShippingAddress.name",
    "originalShippingAddress.street1",
    "items.msku",
  ];

  private searchTermDelimiter: RegExp = /[^a-zA-Z0-9]+/;

  private buildSearchTermFilter(queries: QueryDslQueryContainer[]): QueryDslQueryContainer {
    return {
      bool: {
        should: queries,
        minimum_should_match: 1,
      },
    };
  }

  private generatePrefixMatchingQueries(searchTerm: string): QueryDslQueryContainer[] {
    return [
      {
        multi_match: {
          query: `${searchTerm}`,
          fields: [...this.searchableFuzzyText, "marketplaceOrderId"],
          fuzziness: 0,
          operator: "and",
          analyzer: "autocomplete_search",
        },
      },
      {
        multi_match: {
          query: `${searchTerm}`,
          type: "bool_prefix",
          fields: [...this.searchableText],
          operator: "and",
        },
      },
      ...this.searchableKeywords.map((fieldName) => ({
        prefix: {
          [fieldName]: `${searchTerm}`,
        },
      })),
    ];
  }

  private generateFuzzyMatchingQueries(searchTerm: string): QueryDslQueryContainer[] {
    const searchWords: string[] = searchTerm.split(this.searchTermDelimiter);
    return [
      {
        multi_match: {
          query: `${searchTerm}`,
          fields: [...this.searchableFuzzyText, "marketplaceOrderId"],
          fuzziness: searchWords.length < SEARCH_TERM_WORD_LIMIT ? "AUTO" : 0,
          operator: "and",
          analyzer: "autocomplete_search",
          max_expansions: searchWords.length < SEARCH_TERM_WORD_LIMIT ? Math.round(50 / searchWords.length) : 1,
        },
      },
    ];
  }

  public async executeSearch(request: SearchRequest): Promise<SearchResults> {
    const trimmedSearchTerm: string = request.searchTerm ?? "";
    if (trimmedSearchTerm) {
      const customizedOpenSearchFilterList: QueryDslQueryContainer[] = request?.customizedOpenSearchFilters ?? [];

      // Make initial request without fuzziness to prioritize exact matches
      const prefixMatchingFilter: QueryDslQueryContainer = this.buildSearchTermFilter(
        this.generatePrefixMatchingQueries(trimmedSearchTerm)
      );
      const prefixSearchRequest: SearchRequest = {
        ...request,
        searchTerm: "",
        customizedOpenSearchFilters: [...customizedOpenSearchFilterList, prefixMatchingFilter],
      };
      const prefixResults: SearchResults = await this.execute(prefixSearchRequest);
      if (prefixResults.hits.length > 0) {
        return prefixResults;
      }

      // Make subsequent request with fuzziness to provide fuzzy matches if there are no exact matches
      const fuzzyMatchingFilter: QueryDslQueryContainer = this.buildSearchTermFilter(
        this.generateFuzzyMatchingQueries(trimmedSearchTerm)
      );
      const fuzzySearchRequest: SearchRequest = {
        ...request,
        searchTerm: "",
        customizedOpenSearchFilters: [...customizedOpenSearchFilterList, fuzzyMatchingFilter],
      };
      return await this.execute(fuzzySearchRequest);
    } else {
      return await this.execute(request);
    }
  }

  protected buildSearchOptions(request: SearchRequest): SearchOptions {
    const searchOptions = super.buildSearchOptions(request);
    const boolShouldQueries: QueryDslQueryContainer | QueryDslQueryContainer[] =
      searchOptions?.query?.bool?.should ?? [];
    const shouldQueries: QueryDslQueryContainer[] = Array.isArray(boolShouldQueries)
      ? boolShouldQueries
      : [boolShouldQueries];

    if (request.searchTerm) {
      const searchTerm: string = request.searchTerm;
      const searchWords: string[] = searchTerm.split(this.searchTermDelimiter);
      shouldQueries.push({
        multi_match: {
          query: `${searchTerm}`,
          fields: this.searchableFuzzyText,
          fuzziness: searchWords.length < SEARCH_TERM_WORD_LIMIT ? "AUTO" : 0,
          operator: "and",
          analyzer: "autocomplete_search",
          max_expansions: searchWords.length < SEARCH_TERM_WORD_LIMIT ? Math.round(50 / searchWords.length) : 1,
        },
      });
      shouldQueries.push({
        multi_match: {
          query: `${searchTerm}`,
          fields: ["marketplaceOrderId"],
          fuzziness: searchWords.length < SEARCH_TERM_ID_WORD_LIMIT ? "AUTO" : 0,
          operator: "and",
          analyzer: "autocomplete_search",
          max_expansions: searchWords.length < SEARCH_TERM_ID_WORD_LIMIT ? Math.round(50 / searchWords.length) : 1,
        },
      });
      shouldQueries.push({
        multi_match: {
          query: `${searchTerm}`,
          type: "bool_prefix",
          fields: [...this.searchableText],
          operator: "and",
        },
      });
      for (const fieldName of this.searchableKeywords) {
        shouldQueries.push({
          prefix: {
            [fieldName]: `${searchTerm}`,
          },
        });
      }
    }

    const additionalSearchOptions: Partial<SearchOptions> = {
      query: {
        bool: {
          ...(searchOptions?.query?.bool ?? {}),
          should: shouldQueries,
          minimum_should_match: shouldQueries.length > 0 ? 1 : 0,
        },
      },
    };
    if (this.config.searchConfig.highlightMatches && request.highlightMatches !== false) {
      additionalSearchOptions.highlight = {
        fields: {
          "cleanShippingAddress.name": {},
          "originalShippingAddress.name": {},
        },
      };
      additionalSearchOptions.hydrate = true;
    }
    return {
      ...searchOptions,
      ...additionalSearchOptions,
    };
  }
}
