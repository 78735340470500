import { DeliverrAddress, PackagingType } from "@deliverr/commons-objects";
import { Thunk, createActionCreator } from "common/ReduxUtils";
import { inventoryClient } from "common/clients/instances";

import AlgoliaService from "common/list/AlgoliaService";
import { InventorySearchService } from "common/search/services/InventorySearchService";
import { ItemizedPrice } from "@deliverr/billing-pricer-client";
import { ListType } from "common/list";
import { LogisticsSearchConfig } from "common/search/services/LogisticsSearchConfig";
import { Path } from "paths";
import { Product } from "@deliverr/commons-clients/lib/product/Product";
import { configFor } from "common/search/services/SearchConfig";
import { getShippingOptionsByAddress } from "order/new/NewOrderCreate/NewOrderSelectMethod/useNewOrderSelectMethod";
import history from "BrowserHistory";
import log from "Logger";
import { notifyUserOfError } from "common/ErrorToast";
import { fulfillmentOrderClient, productClient } from "Clients";

export interface AlgoliaProduct extends Product {
  sellerShippingSpecification?: {
    sourceUser?: string;
    minimumPackagingType?: PackagingType;
  };
}

export enum NewOrderActionTypes {
  ADD_PRODUCT = "ADD_NEW_ORDER_PRODUCT",
  ADD_PRODUCTS = "ADD_NEW_ORDER_PRODUCTS",
  REMOVE_PRODUCT = "REMOVE_NEW_ORDER_PRODUCT",
  CHECK_PRODUCT = "CHECK_PRODUCT",
  UPDATE_QTY = "UPDATE_NEW_ORDER_QTY",
  SET_ADDRESS = "SET_ADDRESS",
  REVIEW_ORDER = "REVIEW_ORDER",
  SET_ORDER_SHIPPING_METHOD = "SET_ORDER_SHIPPING_METHOD",
  SET_ORDER_SHIPPING_COSTS = "SET_ORDER_SHIPPING_COSTS",
  MODIFY_ORDER = "MODIFY_ORDER",
  CREATE_ORDER_START = "CREATE_ORDER_START",
  CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS",
  CLEAR_NEW_ORDER = "CLEAR_NEW_ORDER",
  SET_REMOVAL = "SET_REMOVAL",
  SET_LOT_REMOVAL = "SET_LOT_REMOVAL",
}

export const addProduct = createActionCreator<string, AlgoliaProduct>(
  NewOrderActionTypes.ADD_PRODUCT,
  "sku",
  "product"
);

const inventorySearchService = new InventorySearchService(configFor(ListType.InventoryV2) as LogisticsSearchConfig);

const algoliaService = AlgoliaService.get({
  indexName: process.env.ALGOLIA_INDEX_INVENTORY!,
  searchConfig: {
    hitsPerPage: 10,
    maxValuesPerFacet: 1,
  },
});

export const addProductsByDskus: Thunk = (dskus: string[], isLogisticsSearchEnabled?: boolean) => async (dispatch) => {
  const ctx = { fn: "addProductsByDsku" };

  try {
    log.info(ctx, "Searching products by dsku");
    const resultSize = Math.min(dskus.length, 200);
    const products = isLogisticsSearchEnabled
      ? await inventorySearchService.searchByIds(dskus, "", "dsku", resultSize)
      : await algoliaService.searchByIds(dskus, "", "dsku", resultSize);
    if (products.hits.length) {
      dispatch({
        type: NewOrderActionTypes.ADD_PRODUCTS,
        products: products.hits,
      });
    }
  } catch {
    log.error({ ...ctx, err: { dskus } }, "error search for dskus");
  }
};

export const checkForInvalidProducts: Thunk = (dsku: string) => async (dispatch, getState) => {
  const ctx = { fn: "checkForInvalidProducts" };
  const {
    orderNew: { invalidDskus },
  } = getState();

  let newInvalidDskus: string[] = invalidDskus || [];

  try {
    await productClient.isApprovedForSale(dsku);
    log.info(ctx, "product can accept orders");
  } catch (err) {
    if (err === false) {
      if (newInvalidDskus.includes(dsku)) {
        newInvalidDskus = newInvalidDskus.filter((invalidDsku) => {
          return invalidDsku !== dsku;
        });
      } else {
        newInvalidDskus.push(dsku);
      }
    } else {
      log.error({ ...ctx }, "A 5xx error occured");
      notifyUserOfError({ err, toastId: "checkForInvalidProducts5xxError" });
    }
  } finally {
    dispatch({
      type: NewOrderActionTypes.CHECK_PRODUCT,
      newInvalidDskus,
    });
  }
};

export const removeProduct = createActionCreator<string>(NewOrderActionTypes.REMOVE_PRODUCT, "sku");

export const updateQty = createActionCreator<string, number>(NewOrderActionTypes.UPDATE_QTY, "sku", "qty");

export const setAddress = createActionCreator<DeliverrAddress>(NewOrderActionTypes.SET_ADDRESS, "address");

export const reviewOrder = createActionCreator(NewOrderActionTypes.REVIEW_ORDER);

export const modifyOrder = createActionCreator(NewOrderActionTypes.MODIFY_ORDER);

export const setShippingMethod = createActionCreator<string>(
  NewOrderActionTypes.SET_ORDER_SHIPPING_METHOD,
  "shippingMethod"
);

export const setShippingCosts = createActionCreator<{ [key: string]: ItemizedPrice }>(
  NewOrderActionTypes.SET_ORDER_SHIPPING_COSTS,
  "shippingCosts"
);

export const clearOrder = createActionCreator(NewOrderActionTypes.CLEAR_NEW_ORDER);

export const duplicateOrder: Thunk = (orderId: string) => async (dispatch) => {
  const ctx = { fn: "duplicateOrder", orderId };
  log.info(ctx, "duplicating order");

  dispatch(clearOrder());

  try {
    const order = await fulfillmentOrderClient.getOrder(orderId);

    if (!order) {
      const err = new Error("ORDER_NOT_FOUND");
      log.error({ ...ctx, err }, "unable to find order");
      notifyUserOfError({ err, toastId: "unableToFindOrderError" });
      return;
    } else {
      log.info({ ...ctx, order }, "retrieved order");
    }

    const orderAddress = order.cleanShippingAddress ?? order.originalShippingAddress;

    dispatch(setAddress(orderAddress));

    const dskus = order.items.map(({ dsku }) => dsku);
    const [productMap, atpMap] = await Promise.all([
      productClient.getProducts(dskus, { includeHazmatInformation: true, includeShippingSpecifications: true }),
      inventoryClient.getATP(dskus, true),
    ]);

    Object.entries(productMap).forEach(([dsku, product]) => {
      const shippingSpec = product.shippingSpecifications?.seller?.minimumPackagingType;
      // Algolia gives the shipping spec info in a different format
      // Algolia's product interface is the one used to get pricing for new orders
      // See interface AlgoliaProduct
      const algoliaFormattedProduct = {
        ...product,
        sellerShippingSpecification: {
          minimumPackagingType: shippingSpec,
        },
      };
      dispatch(addProduct(dsku, { ...algoliaFormattedProduct, ...atpMap[dsku] }));
    });

    order.items.forEach(({ dsku, qty }) => dispatch(updateQty(dsku, qty)));

    if (order.requiredDeliveryInDays) {
      const shippingMethods = getShippingOptionsByAddress(orderAddress, order.sellerId);
      const matchingShippingMethod = shippingMethods.find(
        ({ requiredDeliveryInDays }) => requiredDeliveryInDays === order.requiredDeliveryInDays
      );
      if (matchingShippingMethod) {
        dispatch(setShippingMethod(matchingShippingMethod.name));
      }
    }

    history.push(Path.orderNew, { duplicateOrder: true });
  } catch (err) {
    log.error({ ...ctx, err }, "error duplicating order");
    notifyUserOfError({
      translation: {
        id: "orders.orderDetail.duplicateOrderError",
        defaultMessage: "Something went wrong duplicating this order. Please try again.",
      },
      toastId: "duplicateOrderError",
    });
  }
};

export const setRemoval = createActionCreator(NewOrderActionTypes.SET_REMOVAL);
export const setLotRemoval = createActionCreator(NewOrderActionTypes.SET_LOT_REMOVAL);
