import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { mapError } from "../core/mapError";
import { ServiceLevelCoverage } from "../fastTag/ServiceLevelCoverage";
import { ExperimentGroupData } from "./Experiment/ExperimentGroupData";
import { WebsiteFastTagError } from "./WebsiteFastTagError";
import { WebsiteFastTagSeller } from "./WebsiteFastTagSeller/WebsiteFastTagSeller";

export type WebsiteFastTagClientConfig = ApiClientConfig;

export class WebsiteFastTagClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: WebsiteFastTagClientConfig = { baseURL: mustBeDefined(process.env.WEBSITE_FAST_TAG_URL) }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`,
    });
  }

  async getWebsiteSeller(sellerId: string): Promise<WebsiteFastTagSeller> {
    try {
      return await this.apiClient.get<WebsiteFastTagSeller>({
        url: `/website/seller/${sellerId}`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [WebsiteFastTagError.NOT_FOUND]);
    }
  }

  async getServiceLevelCoverageForProduct(dsku: string): Promise<ServiceLevelCoverage> {
    try {
      return await this.apiClient.get<ServiceLevelCoverage>({
        url: `/dsku/${dsku}/coverage/servicelevel`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [WebsiteFastTagError.NOT_FOUND]);
    }
  }

  async getExperimentEnrollmentsForSeller(sellerId: string): Promise<Array<ExperimentGroupData>> {
    try {
      return await this.apiClient.get<Array<ExperimentGroupData>>({
        url: `/experiment/enrollments/seller/${sellerId}`,
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [WebsiteFastTagError.NOT_FOUND]);
    }
  }

  async setExperimentEnrollment(
    experimentName: string,
    sellerId: string,
    storeName: string,
    isActive: boolean
  ): Promise<void> {
    try {
      return await this.apiClient.put({
        url: `/experiment/${experimentName}/seller/${sellerId}/store/${storeName}`,
        body: {
          isActive,
        },
        authentication: isAuthenticated,
      });
    } catch (err) {
      throw mapError(err, [WebsiteFastTagError.NOT_FOUND]);
    }
  }
}
