import React from "react";
import { ControlledNumberInput } from "common/components/reactHookForm";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { FormattedMessage } from "react-intl";
import { Stack, Text } from "common/components/ui";
import { Direction, TextAlign, ThemeProps } from "common/components/ui/shared";
import { DimensionMultiplySymbol } from "common/components/DimensionMultiplySymbol";
import { FieldArrayWithId } from "react-hook-form";
import { CreateShipmentFormState } from "inbounds/createShipment/store";
import styled from "@emotion/styled";
import { DtcMixedSkuInputDimValues, getInputName } from "./DtcMixedSkuInputValues";

const StyledContainer = styled.section<ThemeProps>(
  ({ theme }) => `
  padding-top: ${theme.spacing.S2};
`
);

const StyledStack = styled(Stack)<ThemeProps>(
  ({ theme }) => `
  margin-top: 0;
  `
);

const BoxDimensionLabel = () => (
  <FormattedMessage id="createShipment.dtc.cargoDetailStep.mixedSku.boxDimensions" defaultMessage="Box dimensions" />
);

export const DtcMixedSkuBoxDimensionsInput = ({
  pkg,
  index,
}: {
  pkg: FieldArrayWithId<CreateShipmentFormState, CreateShipmentInputName.MIXED_SKU_PACKAGES, "id">;
  index: number;
}) => (
  <Stack>
    <Text size="body" textAlign={TextAlign.start}>
      <BoxDimensionLabel />
    </Text>
    <StyledStack direction={Direction.HORIZONTAL} alignItems="start">
      <ControlledNumberInput
        name={getInputName(index, DtcMixedSkuInputDimValues.LENGTH)}
        decimalScale={2}
        min={0}
        inputSize="medium"
        placeholder="L"
        defaultValue={pkg.length}
        required
      />
      <StyledContainer>
        <DimensionMultiplySymbol />
      </StyledContainer>
      <ControlledNumberInput
        name={getInputName(index, DtcMixedSkuInputDimValues.WIDTH)}
        decimalScale={2}
        min={0}
        inputSize="medium"
        placeholder="W"
        defaultValue={pkg.width}
        required
      />
      <StyledContainer>
        <DimensionMultiplySymbol />
      </StyledContainer>
      <ControlledNumberInput
        name={getInputName(index, DtcMixedSkuInputDimValues.HEIGHT)}
        decimalScale={2}
        min={0}
        inputSize="medium"
        placeholder="H"
        defaultValue={pkg.height}
        required
      />{" "}
      <StyledContainer>
        <FormattedMessage id="createShipment.dtc.cargoDetailStep.mixedSku.boxDimensionUnit" defaultMessage="in" />
      </StyledContainer>
    </StyledStack>
  </Stack>
);
