import { CheckableTileGroup, CheckableTileGroupProps, DefaultTheme } from "common/components/ui";
import styled from "@emotion/styled";
import React, { ChangeEventHandler, FC } from "react";
import { SimpleSellerSelfServePrepType } from "prep/types";

const StyledTileGroup = styled(CheckableTileGroup)<Pick<PrepTileGroupProps, "tileWidth">, DefaultTheme>(
  ({ tileWidth, theme }) => `
  row-gap:${theme.spacing.S2};

  & > label {
    /* explicit max-width from design */
    max-width: ${tileWidth ?? "28rem"};
  }
`
);

export interface PrepTileGroupProps {
  itemCount: number;
  selectedPrepTypes: SimpleSellerSelfServePrepType[];
  handleSelectPrepType: ChangeEventHandler<HTMLInputElement>;
  options: CheckableTileGroupProps["options"];
  disabled?: boolean;
  shouldShowKittingService?: boolean;
  isKittingServiceDisabled?: boolean;
  tileWidth?: CheckableTileGroupProps["tileWidth"];
}

export const PrepTileGroup: FC<PrepTileGroupProps> = ({
  selectedPrepTypes,
  handleSelectPrepType,
  options,
  disabled = false,
  tileWidth,
}) => {
  return (
    <StyledTileGroup
      data-testid="prep-tile-group"
      options={options}
      currentValues={selectedPrepTypes}
      onChange={handleSelectPrepType}
      type="checkbox" // currently only supports a single selection
      block
      iconType="circle"
      disabled={disabled}
      tileWidth={tileWidth}
    />
  );
};
