export enum Space {
  /** 0.25rem */
  "S1" = "S1",
  /** 0.5rem */
  "S2" = "S2",
  /** 0.75rem */
  "S3" = "S3",
  /** 1rem */
  "S4" = "S4",
  /** 1.5rem */
  "S5" = "S5",
  /** 2rem */
  "S6" = "S6",
  /** 2.5rem */
  "S7" = "S7",
  /** 3rem */
  "S8" = "S8",
  /** 4rem */
  "S9" = "S9",
}

/**
 * S1 = "0.25rem"
 *
 * S2 = "0.5rem"
 *
 * S3 = "0.75rem"
 *
 * S4 = "1rem"
 *
 * S5 = "1.5rem"
 *
 * S6 = "2rem"
 *
 * S7 = "2.5rem"
 *
 * S8 = "3rem"
 *
 * S9 = "4rem"
 */
export const spacing: Record<Space, string> = {
  [Space.S1]: "0.25rem",
  [Space.S2]: "0.5rem",
  [Space.S3]: "0.75rem",
  [Space.S4]: "1rem",
  [Space.S5]: "1.5rem",
  [Space.S6]: "2rem",
  [Space.S7]: "2.5rem",
  [Space.S8]: "3rem",
  [Space.S9]: "4rem",
};
