import React from "react";
import { FormattedMessage } from "react-intl";
import Address from "common/components/Address";
import styled from "@emotion/styled";
import { ThemeProps } from "common/components/ui/shared";
import { ReplenishmentOrderMultipleDestination } from "../confirm/ReplenishmentOrderMultipleDestination";
import { ReplenishmentShipmentModal } from "../confirm/ReplenishmentShipmentModal";
import {
  UseReplenishmentShipmentAddressDisplayProps,
  useReplenishmentShipmentAddressDisplay,
} from "./useReplenishmentShipmentAddressDisplay";

const StyledReplenishmentShipmentAddressDisplay = styled.div<ThemeProps>(
  ({ theme }) => `
    padding: ${theme.spacing.S5} ${theme.spacing.S7};
    width: 100%;
    display: flex;
  `
);

const StyledAddress = styled.div<ThemeProps>(
  ({ theme }) => `
    flex-grow: 1;
    white-space: pre-line;
    height: fit-content;

    .address {
      color: ${theme.colors.NEUTRAL[500]};
    }
  `
);

const StyledAddressTitle = styled.p<ThemeProps>(
  ({ theme }) => `
    font-weight: ${theme.font.weight.BOLD};
    margin: 0;
  `
);

export const ReplenishmentShipmentAddressDisplay: React.FC<UseReplenishmentShipmentAddressDisplayProps> = ({
  originWarehouseId,
  shipments,
  distributionChannel,
}) => {
  const {
    shouldShowShipmentsModal,
    onShipmentsModalClose,
    onViewMoreClickonShipmentsModal,
    toAddress,
    originAddress,
    hideDestinationName,
    isMultipleShipments,
  } = useReplenishmentShipmentAddressDisplay({ originWarehouseId, shipments });
  return (
    <>
      <StyledReplenishmentShipmentAddressDisplay>
        <StyledAddress>
          <StyledAddressTitle>
            <FormattedMessage id={"shipFrom"} defaultMessage={"Ship from"} />
          </StyledAddressTitle>
          {originAddress && <Address address={originAddress} />}
        </StyledAddress>
        <StyledAddress>
          <StyledAddressTitle>
            <FormattedMessage id={"shipTo"} defaultMessage={"Ship to"} />
          </StyledAddressTitle>
          {!isMultipleShipments && toAddress && <Address address={toAddress} hideName={hideDestinationName ?? true} />}
          {isMultipleShipments && distributionChannel && (
            <ReplenishmentOrderMultipleDestination
              distributionChannel={distributionChannel}
              onViewMoreClickonShipmentsModal={onViewMoreClickonShipmentsModal}
            />
          )}
        </StyledAddress>
      </StyledReplenishmentShipmentAddressDisplay>
      <ReplenishmentShipmentModal
        data-testid="confirm-shipments-modal"
        showModal={shouldShowShipmentsModal}
        onClose={onShipmentsModalClose}
        shipments={shipments}
      />
    </>
  );
};
