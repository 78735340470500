export enum DimensionSource {
  AMAZONSCRAPED = "amazonscraped",
  AVERAGEREGRESSION = "averageregression",
  CROSSDOCK = "crossdock",
  FCDIMSANDWEIGHT = "fc-dims-and-weight",
  MANUAL = "manual",
  SELLER = "seller",
  WAREHOUSE = "warehouse",
}

export const isDimsConfirmed = (dimSourceType: string = "") =>
  [
    DimensionSource.AMAZONSCRAPED,
    DimensionSource.AVERAGEREGRESSION,
    DimensionSource.CROSSDOCK,
    DimensionSource.FCDIMSANDWEIGHT,
    DimensionSource.MANUAL,
    DimensionSource.SELLER,
    DimensionSource.WAREHOUSE,
  ].includes(dimSourceType as DimensionSource);

export const isDimsEditable = (dimSourceType: string = "") =>
  ![
    DimensionSource.AMAZONSCRAPED,
    DimensionSource.AVERAGEREGRESSION,
    DimensionSource.CROSSDOCK,
    DimensionSource.FCDIMSANDWEIGHT,
    DimensionSource.MANUAL,
    DimensionSource.WAREHOUSE,
  ].includes(dimSourceType as DimensionSource);
