import { ProductCasePack } from "@deliverr/commons-clients/lib/product/ProductCasePack";
import { Container } from "@deliverr/commons-objects/lib/Container";

export const mapProductCasePackToContainer = (productCasePack: ProductCasePack): Container => {
  return {
    height: productCasePack.height!,
    weight: productCasePack.weight!,
    width: productCasePack.width!,
    length: productCasePack.length!,
    lengthUnit: productCasePack.lengthUnit!,
    weightUnit: productCasePack.weightUnit!,
  };
};
