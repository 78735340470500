import { Product } from "@deliverr/commons-clients";
import React from "react";
import { Text } from "common/components/ui";
import { getProductSku } from "inbounds/steps/ship/utils/getProductSku";

interface ProductDisplayProps {
  product: Pick<Product, "name" | "dsku" | "msku" | "packOf">;
}

export const ProductDisplay = ({ product: { name, dsku, msku, packOf } }: ProductDisplayProps) => (
  <>
    <Text bold>{name}</Text>
    <Text size="caption" appearance="INFO">
      {getProductSku({ dsku, msku, packOf })}
    </Text>
  </>
);
