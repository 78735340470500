import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { InternalCredit } from "./Credit/InternalCredit";
import { PaymentSource } from "@deliverr/billing-types";
import { Credit } from "@deliverr/billing-client";

export type BillingClientConfig = ApiClientConfig;

export class BillingClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: BillingClientConfig = { baseURL: mustBeDefined(process.env.BILLING_SERVICE_URL) }) {
    this.apiClient = new ApiClient({ baseURL });
  }

  async getPrimaryPaymentSource(sellerId: string): Promise<PaymentSource> {
    return await this.apiClient.get({
      url: `/invoice/v1/payment/sources/primary/${sellerId}`,
      authentication: isAuthenticated,
    });
  }

  async createInternalCredit(sellerIds: string[], credit: InternalCredit): Promise<Credit> {
    return await this.apiClient.post({
      url: "/v2/credits/internal",
      body: {
        sellerIds,
        credit,
      },
      authentication: isAuthenticated,
    });
  }
}
