import React from "react";
import { FormattedMessage } from "react-intl";
import { transferCreateSetShipmentValidationError } from "transfers/create/actions";
import { ShippingPartner, ShippingType } from "@deliverr/replenishment-client";
import { productIsValidForFBASmallParcel } from "./productIsValidForFBASmallParcel";
import { Link } from "react-router-dom";
import { SelectedProductData } from "common/components/ProductChooser";
import { DynamicRoutes } from "paths";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";

export const productsDimsFbaIntegrationValidation = (
  dispatch,
  selectedProducts: { [dsku: string]: SelectedProductData }
) => {
  const defaultTransportValidationErrorState = {
    hasError: true,
    isPending: false,
    disabled: true,
    retryable: false,
  };

  const invalidProducts = Object.keys(selectedProducts)
    .filter((dsku) => !productIsValidForFBASmallParcel(selectedProducts[dsku]))
    .map((dsku) => selectedProducts[dsku].packOf ?? dsku);

  if (invalidProducts.length > 0) {
    const error = {
      ...defaultTransportValidationErrorState,
      errorMessage: (
        <FormattedMessage
          id="transfers.create.fba.parcel.dims.validation"
          defaultMessage="{invalidProduct} {numOfInvalid, plural, one {} other {+ {remaining} other}} {numOfInvalid, plural, one {product exceeds} other {products exceed}} Amazon's requirements for small parcel delivery. {learnMore}."
          values={{
            learnMore: <ElevioLearnMoreButton articleId={ElevioArticleId.ShippingRestrictionsForFbaInbounds} />,
            numOfInvalid: invalidProducts.length,
            remaining: invalidProducts.length - 1,
            invalidProduct: (
              <Link to={DynamicRoutes.inventoryDetail.parse({ dsku: invalidProducts[0] })}>{invalidProducts[0]}</Link>
            ),
          }}
        />
      ),
    };
    dispatch(transferCreateSetShipmentValidationError(ShippingPartner.FBA, ShippingType.PARCEL, error));
  }
};
