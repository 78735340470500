import { defineMessages, MessageDescriptor } from "react-intl";
import { FeatureName, isFeatureOn } from "common/Split";
import { CarrierType, ShipmentType } from "@deliverr/storage-client";

const floorLoadedLabels = defineMessages({
  reviewPackingInstructions: {
    id: "storage.inbounds.create.shipmentConfirmed.reviewPackingInstructions",
    defaultMessage: `Review packing instructions`,
  },
  sendShipment: {
    id: "storage.inbounds.create.shipmentConfirmed.sendShipment",
    defaultMessage: `Send your shipment`,
  },
  sendAndTrackShipment: {
    id: "storage.inbounds.create.shipmentConfirmed.sendAndTrackShipment",
    defaultMessage: `Send and track your shipment`,
  },
  scheduleAppointment: {
    id: "storage.inbounds.create.shipmentConfirmed.scheduleAppointment",
    defaultMessage: `Request a delivery appointment`,
  },
});

const downloadPalletLabel = defineMessages({
  downloadPalletLabels: {
    id: "storage.inbounds.create.shipmentConfirmed.downloadPalletLabels",
    defaultMessage: `Download pallet labels`,
  },
});

const freightLabel = defineMessages({
  prepareFreightPickup: {
    id: "storage.inbounds.create.shipmentConfirmed.prepareFreightPickup",
    defaultMessage: `Prepare freight for pickup`,
  },
  downloadBillOfLading: {
    id: "storage.inbounds.create.shipmentConfirmed.downloadBillOfLading",
    defaultMessage: `Download bill of lading`,
  },
  trackYourShipment: {
    id: "storage.inbounds.create.shipmentConfirmed.trackYourShipment",
    defaultMessage: `Track your shipment`,
  },
});

export const shipmentConfirmedInstructionLabels = (
  cargoType: ShipmentType | undefined,
  carrierType?: CarrierType
): Record<string, MessageDescriptor> => {
  const { sendAndTrackShipment, ...updatedFloorLoadedLabelsWithoutSendAndTrackShipment } = floorLoadedLabels;
  const { sendShipment, ...updatedFloorLoadedLabelsWithoutSendShipment } = floorLoadedLabels;

  const updatedFloorLoadedLabels = isFeatureOn(FeatureName.SaveStorageInboundTrackingInfo)
    ? updatedFloorLoadedLabelsWithoutSendShipment
    : updatedFloorLoadedLabelsWithoutSendAndTrackShipment;

  if (carrierType === CarrierType.DELIVERR && cargoType === ShipmentType.PALLETIZED) {
    return { ...downloadPalletLabel, ...freightLabel };
  } else if (carrierType === CarrierType.OTHER && cargoType === ShipmentType.PALLETIZED) {
    return { ...downloadPalletLabel, ...updatedFloorLoadedLabels };
  } else {
    return updatedFloorLoadedLabels;
  }
};
