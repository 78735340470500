import React from "react";
import { getSidebarLogo } from "../../../../common/SidebarLogos";
import { ElevioArticleId, ElevioBorderlessButton } from "../../../../common/elevio";
import { FormattedMessage } from "react-intl";
import { fbaIntegrationCommonLabels } from "../../../../wholesale/common/FbaIntegrationCommon.labels";

const helpCenterLabels = fbaIntegrationCommonLabels.helpCenter;
const AmazonSvg = () => getSidebarLogo("AMAZON");

export const AmazonLogo = () => (
  <div style={{ width: "60px" }}>
    <AmazonSvg />
  </div>
);

export function getFbaHelpCenterLink() {
  return (
    <ElevioBorderlessButton articleId={ElevioArticleId.FbaIntegratedShipments}>
      <FormattedMessage {...helpCenterLabels.learnMore} />
    </ElevioBorderlessButton>
  );
}
