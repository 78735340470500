import React from "react";
import { generatePath } from "react-router";
import { Route, RouteProps } from "react-router-dom";

import { Path, PathParams } from "paths";
import { LoginRedirect } from "chunks";
import { FeatureName, useFeatureOn } from "common/Split";

export const shouldPreserveListState = (location, history) => {
  const { state: routeState } = location;
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  return (routeState && routeState.preserveState) || history.action === "POP";
};

export type GeneratePathArguments<P extends Path> = P extends keyof PathParams
  ? [P, Record<PathParams[P], string | number | boolean | undefined>]
  : [P];

export const generateSitePath = <P extends Path>(
  location: Pick<Location, "host" | "protocol">,
  ...generatePathArguments: GeneratePathArguments<P>
) => `${location.protocol}//${location.host}${generatePath(generatePathArguments[0], generatePathArguments[1])}`;

export const ProtectedRoute = ({ component, render, ...rest }: RouteProps) => {
  const isSmbFeatureOn = useFeatureOn(FeatureName.SmbAccelerate);

  if (render) {
    return <Route {...rest} render={(props) => (isSmbFeatureOn ? render(props) : <LoginRedirect />)} />;
  } else if (component) {
    const Component = component;
    return <Route {...rest} render={(props) => (isSmbFeatureOn ? <Component {...props} /> : <LoginRedirect />)} />;
  } else {
    throw new Error("ProtectedRoute requires either a component or render prop");
  }
};
