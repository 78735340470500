import styled from "@emotion/styled";
import { Select } from "common/components/ui";

export const StyledAddressSelect = styled(Select)`
  text-align: left;
`;

export const StyledErrorsList = styled.ul`
  list-style-type: decimal;
`;
