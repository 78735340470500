import { NetworkInboundShippingOption } from "@deliverr/legacy-inbound-client";
import { ShippingMethod } from "inbounds/ShippingMethod";

// maps BE shipping option value to value associated with one of our shipment setup flows
// FTL external is treated as LTL on the BE, as they are both external freight shipments
// FTL deliverr creates shipments through an external form, so it does not require this mapping
export const SHIPPING_OPTION_TO_METHOD: Record<NetworkInboundShippingOption, ShippingMethod> = {
  [NetworkInboundShippingOption.SPD]: ShippingMethod.SPD_DELIVERR,
  [NetworkInboundShippingOption.SPD_EXTERNAL]: ShippingMethod.SPD_EXTERNAL,
  [NetworkInboundShippingOption.LTL]: ShippingMethod.LTL_EXTERNAL,
  [NetworkInboundShippingOption.LTL_DELIVERR]: ShippingMethod.LTL_DELIVERR,
  [NetworkInboundShippingOption.LTL_EXTERNAL]: ShippingMethod.LTL_EXTERNAL,
  [NetworkInboundShippingOption.FTL_EXTERNAL]: ShippingMethod.FTL_EXTERNAL,
  [NetworkInboundShippingOption.FTL_DELIVERR]: ShippingMethod.FTL_DELIVERR,
};
