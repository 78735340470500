import React, { FC } from "react";
import { Text } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import { InboundStepTable } from "inbounds/components/InboundStepTable/InboundStepTable";
import { LotInformationTableRow } from "./LotInformationTableRow";
import { Product } from "@deliverr/commons-clients";
import { LoadingSpinner } from "common/components/LoadingSpinner";

export const LotInformationTable: FC<{
  products: Product[];
  hasFefoEnabledProducts: boolean;
  hasLotTrackingEnabledProducts: boolean;
}> = ({ products, hasFefoEnabledProducts, hasLotTrackingEnabledProducts }) => {
  return !products.length ? (
    <LoadingSpinner center />
  ) : (
    <InboundStepTable>
      <thead>
        <tr>
          <td>
            <Text bold size="caption">
              <FormattedMessage id="inbounds.lotInformationTable.skuHeader" defaultMessage="SKU" />
            </Text>
          </td>
          {hasLotTrackingEnabledProducts && (
            <td>
              <Text bold size="caption">
                <FormattedMessage id="inbounds.lotInformationTable.lotNumber" defaultMessage="Lot number" />
              </Text>
            </td>
          )}
          {hasFefoEnabledProducts && (
            <>
              <td>
                <Text bold size="caption">
                  <FormattedMessage
                    id="inbounds.lotInformationTable.expirationDateFormatHeader"
                    defaultMessage="Expiration date format"
                  />
                </Text>
              </td>
              <td>
                <Text bold size="caption">
                  <FormattedMessage
                    id="inbounds.lotInformationTable.expirationDateHeader"
                    defaultMessage="Expiration date"
                  />
                </Text>
              </td>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {products.map((product) => (
          <LotInformationTableRow
            key={product.dsku}
            product={product}
            hasFefoEnabledProducts={hasFefoEnabledProducts}
            hasLotTrackingEnabledProducts={hasLotTrackingEnabledProducts}
          />
        ))}
      </tbody>
    </InboundStepTable>
  );
};
