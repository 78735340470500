import React from "react";
import { defineMessages, useIntl } from "react-intl";

import { Stack } from "common/components/ui";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { UnitSystem } from "inbounds/createShipment/CreateShipmentTypes";
import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";
import { ControlledNumberInput } from "common/components/reactHookForm";
import { LocalizedText } from "common/components/LocalizedText";
import { BoxesInput } from "./BoxesInput";

const INPUT_LABELS = defineMessages({
  [CreateShipmentInputName.TOTAL_VOLUME]: {
    id: "createShipment.shipmentTotals.volume",
    defaultMessage: "Volume",
  },
  [CreateShipmentInputName.TOTAL_WEIGHT]: {
    id: "createShipment.shipmentTotals.weight",
    defaultMessage: "Weight",
  },
  [CreateShipmentInputName.TOTAL_BOXES]: {
    id: "createShipment.shipmentTotals.boxes",
    defaultMessage: "Boxes",
  },
  [CreateShipmentInputName.TOTAL_UNITS]: {
    id: "createShipment.shipmentTotals.units",
    defaultMessage: "Total units",
  },
});

interface UnconnectedProps {
  unitSystem: UnitSystem;
  isTotalUnitsReadOnly?: boolean;
  totalUnits: number;
  hasInlineInputs?: boolean;
  shouldShowBoxes?: boolean;
  shouldShowUnit?: boolean;
}

const UnconnectedShipmentTotalsForm = ({
  unitSystem,
  isTotalUnitsReadOnly,
  totalUnits,
  hasInlineInputs,
  shouldShowBoxes,
  shouldShowUnit,
}: UnconnectedProps) => {
  const { formatMessage } = useIntl();
  const getBaseProps = (name: CreateShipmentInputName, unitKey?: "volume" | "weight" | "boxes") => {
    const unitLabel = unitKey ? UNIT_MAP[unitKey][unitSystem] : undefined;
    let label = formatMessage(INPUT_LABELS[name]);
    if (!hasInlineInputs && unitLabel) {
      label += ` (${unitLabel})`;
    }

    if (hasInlineInputs) {
      label += ":";
    }

    return {
      name,
      label,
      unit: hasInlineInputs ? unitLabel : undefined,
    };
  };

  const UNIT_MAP = {
    volume: {
      [UnitSystem.CBM_KG]: formatMessage({
        id: "createShipment.shipmentTotals.volumeUnit.metric",
        defaultMessage: "cbm",
      }),
      [UnitSystem.CFT_LB]: formatMessage({
        id: "createShipment.shipmentTotals.volumeUnit.imperial",
        defaultMessage: "cu ft",
      }),
    },
    weight: {
      [UnitSystem.CBM_KG]: formatMessage({
        id: "createShipment.shipmentTotals.weightUnit.metric",
        defaultMessage: "kg",
      }),
      [UnitSystem.CFT_LB]: formatMessage({
        id: "createShipment.shipmentTotals.weightUnit.imperial",
        defaultMessage: "lb",
      }),
    },
  };

  return (
    <Stack direction="HORIZONTAL" gap={hasInlineInputs ? "S4" : "S5"} alignItems="center">
      <ControlledNumberInput
        {...getBaseProps(CreateShipmentInputName.TOTAL_VOLUME, "volume")}
        decimalScale={2}
        required
        inputSize="medium"
        inline={hasInlineInputs}
      />
      <ControlledNumberInput
        {...getBaseProps(CreateShipmentInputName.TOTAL_WEIGHT, "weight")}
        decimalScale={2}
        inputSize="medium"
        required
        inline={hasInlineInputs}
      />
      {shouldShowBoxes && <BoxesInput />}
      {shouldShowUnit &&
        (isTotalUnitsReadOnly ? (
          <LocalizedText
            message={{
              id: "createShipment.shipmentTotals.unitsReadOnly",
              defaultMessage: "Total units: {totalUnits}",
              values: { totalUnits },
            }}
          />
        ) : (
          <ControlledNumberInput
            {...getBaseProps(CreateShipmentInputName.TOTAL_UNITS)}
            decimalScale={0}
            inputSize="large"
            required
            min={1}
            inline={hasInlineInputs}
          />
        ))}
    </Stack>
  );
};

export const ShipmentTotalsForm = ({
  isTotalUnitsReadOnly,
  shouldShowBoxes,
  hasInlineInputs,
  shouldShowUnit,
}: Pick<UnconnectedProps, "isTotalUnitsReadOnly" | "hasInlineInputs" | "shouldShowBoxes" | "shouldShowUnit">) => {
  const { watch } = useCreateShipmentFormContext();
  const unitSystem = watch(CreateShipmentInputName.UNIT_SYSTEM);
  const totalUnits = watch(CreateShipmentInputName.TOTAL_UNITS);

  return (
    <UnconnectedShipmentTotalsForm
      unitSystem={unitSystem}
      isTotalUnitsReadOnly={isTotalUnitsReadOnly}
      totalUnits={totalUnits}
      hasInlineInputs={hasInlineInputs}
      shouldShowBoxes={shouldShowBoxes}
      shouldShowUnit={shouldShowUnit}
    />
  );
};
