import styled from "@emotion/styled";
import { getBoxStyles } from "../Box/getBoxStyles";
import * as React from "react";
import { DefaultTheme, Responsive, Space } from "../shared";

export interface BoxResetProps {
  margin?: Responsive<keyof typeof Space | null>;
  marginTop?: Responsive<keyof typeof Space | null>;
  marginBottom?: Responsive<keyof typeof Space | null>;
  marginLeft?: Responsive<keyof typeof Space | null>;
  marginRight?: Responsive<keyof typeof Space | null>;
  height?: Responsive<string | null>;
  minHeight?: Responsive<string | null>;
  maxHeight?: Responsive<string | null>;
  width?: Responsive<string | null>;
  minWidth?: Responsive<string | null>;
  maxWidth?: Responsive<string | null>;
  children: React.ReactElement;
}

const Container = styled.div<BoxResetProps, DefaultTheme>(
  ({ theme, ...styles }) => `
    .box-reset-child {
      ${getBoxStyles(theme, styles)}
    }
  `
);

export const BoxReset: React.FC<BoxResetProps> = ({ children, ...styles }) => {
  const styledChild = React.cloneElement<HTMLElement>(children, {
    style: {
      ...(children.props.style ?? {}),
    },
    className: "box-reset-child",
  });

  return <Container {...styles}>{styledChild}</Container>;
};
