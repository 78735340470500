import React from "react";
import { InboundFreightErrorCode, InboundRateErrorCode } from "@deliverr/legacy-inbound-client";
import { FormattedMessage } from "react-intl";
import { INBOUND_RATES_CONTACT_EMAIL } from "inbounds/constants/contactConstants";

type HandledLtlErrors =
  | InboundRateErrorCode
  | InboundFreightErrorCode
  | "ValidationError"
  | "LTL_SETUP_UNDERVALUED"
  | "GATEWAY_TIMEOUT"
  | "GENERIC_PURCHASING_ERROR"
  | "UNEXPECTED_ERROR"
  | "NO_PACKAGES_SET"
  | "silent error"; // this handles getting nothing back from transportation service

export const FREIGHT_SERVER_ERROR_MESSAGES: Partial<Record<HandledLtlErrors, JSX.Element>> = {
  LTL_SETUP_UNDERVALUED: (
    <FormattedMessage
      id="inbounds.freight.setupUndervalued"
      defaultMessage="The value of the shipment is too low to insure and ship. Please contact support"
    />
  ),
  [InboundFreightErrorCode.PICKUP_DATE_OFF_DAY]: (
    <FormattedMessage
      id="inbounds.freight.offDayPickupDate"
      defaultMessage="You selected a weekend or holiday. Please select a business day."
    />
  ),
  [InboundFreightErrorCode.PICKUP_DATE_SAME_DAY]: (
    <FormattedMessage
      id="inbounds.freight.sameDayPickupDate"
      defaultMessage="The pickup date selected has passed. Please select a future date."
    />
  ),
  [InboundFreightErrorCode.PICKUP_NON_SUPPORTED_REGION]: (
    <FormattedMessage
      id="inbounds.freight.nonSupportedRegion"
      defaultMessage="Freight is only avaliable to domestic pickup addresses."
    />
  ),
  [InboundRateErrorCode.QUOTE_EXPIRED]: (
    <FormattedMessage
      id="inbounds.freight.expiredQuote"
      defaultMessage="This quote has expired. Please request a new quote."
    />
  ),
  [InboundRateErrorCode.LTL_SETUP_TOO_EXPENSIVE]: (
    <FormattedMessage
      id="inbounds.freight.tooExpensive"
      defaultMessage="The value of the shipment is too expensive to insure and ship. Please contact support."
    />
  ),
  [InboundRateErrorCode.LTL_GTZ_INVALID_PHONE]: (
    <FormattedMessage
      id="inbounds.freight.invalidPhoneNumber"
      defaultMessage="The phone number provided is not valid. Please provide a new phone number."
    />
  ),
  [InboundRateErrorCode.LTL_SETUP_MISSING_INFO]: (
    <FormattedMessage
      id="inbounds.freight.missingInfo"
      defaultMessage="Setup information is missing. Please check that you have completed all previous steps."
    />
  ),
  [InboundRateErrorCode.TRANSACTION_SUCCESSFUL_EXISTS]: (
    <FormattedMessage
      id="inbounds.freight.alreadyPurchased"
      defaultMessage="Rates have already been bought for this shipment."
    />
  ),
  NO_PACKAGES_SET: (
    <FormattedMessage
      id="inbounds.freight.noPackages"
      defaultMessage="Please confirm your Shipment Packages and Boxes in Step 1 before proceeding."
    />
  ),
  ValidationError: (
    <FormattedMessage
      id="inbounds.freight.validationError"
      defaultMessage="Some of the information provided is invalid. Please verify it was entered correctly and try again"
    />
  ),
  GENERIC_PURCHASING_ERROR: (
    <FormattedMessage
      id="inbounds.freight.purchasingError"
      defaultMessage="There was an error purchasing your rate quote. Please try again or contact support if the problem continues."
    />
  ),
  GATEWAY_TIMEOUT: (
    <FormattedMessage
      id="inbounds.freight.gtzError"
      defaultMessage="We're having issues with our purchasing partner. Please try again later, or contact {contactEmail} to get a manual quote."
      values={{
        contactEmail: INBOUND_RATES_CONTACT_EMAIL,
      }}
    />
  ),
  "silent error": (
    <FormattedMessage
      id="inbounds.freight.gtzError"
      defaultMessage="We're having issues with our purchasing partner, or contact {contactEmail} to get a manual quote."
      values={{
        contactEmail: INBOUND_RATES_CONTACT_EMAIL,
      }}
    />
  ),
  UNEXPECTED_ERROR: (
    <FormattedMessage
      id="inbounds.freight.unexpectedError"
      defaultMessage="Unexpected error. Please try again later, or contact {contactEmail} to get a manual quote."
      values={{
        contactEmail: INBOUND_RATES_CONTACT_EMAIL,
      }}
    />
  ),
};
