import React, { FC } from "react";
import { SelectComponentOption, ThemeProps } from "common/components/ui";
import { OptionProps } from "@deliverr/react-select-v1";
import { IconV2 } from "common/components/ui/IconV2";
import styled from "@emotion/styled";

const IconContainer = styled.span<ThemeProps>(
  ({ theme }) => `
  margin-right: ${theme.spacing.S2};
  position: relative;
  top: ${theme.spacing.S1};
  `
);

const StyledSpan = styled.span<ThemeProps>(
  ({ theme }) => `
  position: relative;
  bottom: ${theme.spacing.S1};
`
);

const DistanceContainer = styled.span<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL["300"]}
`
);

const PortsIcons = (
  <>
    <IconV2 type="smb-ship" size="small" /> <StyledSpan>|</StyledSpan> <IconV2 type="smb-plane" size="small" />
  </>
);

const CitiesIcons = (type) =>
  type === "origin" ? (
    <>
      <IconV2 type="smb-truck" size="small" />
      <IconV2 type="arrow-right" size="small" />
      {PortsIcons}
    </>
  ) : (
    <>
      {PortsIcons}
      <IconV2 type="arrow-right" size="small" />
      <IconV2 type="smb-truck" size="small" />
    </>
  );

const Option = (PrefixIcon, props) => {
  return (
    <SelectComponentOption {...props}>
      <span>
        <IconContainer>{PrefixIcon}</IconContainer>
        {props.children}
        {props.data.value.distanceKm > 0 && (
          <DistanceContainer>{` ${props.data.value.distanceKm} kms`}</DistanceContainer>
        )}
      </span>
    </SelectComponentOption>
  );
};

export const OriginSearchOption: FC<OptionProps> = (props) => {
  const PrefixIcon = props.data.label.includes("All ports near") ? PortsIcons : CitiesIcons("origin");
  return Option(PrefixIcon, props);
};

export const DestinationSearchOption: FC<OptionProps> = (props) => {
  const PrefixIcon = props.data.label.includes("All ports near") ? PortsIcons : CitiesIcons("destination");
  return Option(PrefixIcon, props);
};
