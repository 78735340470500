import { DeliverrUser } from "@deliverr/commons-objects";
import { CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { fromCognito } from "./CognitoConverter";

export const cognitoInitialState = {};

export enum CognitoLegacyActions {
  COGNITO_LOGIN = "COGNITO_LOGIN",
  COGNITO_LOGOUT = "COGNITO_LOGOUT",
  COGNITO_UPDATE_USER_ATTRIBUTES = "COGNITO_UPDATE_USER_ATTRIBUTES",
}

export interface CognitoLegacyUser extends CognitoUser {
  attributes: CognitoUserAttribute;
  [attribute: string]: any;
}

export interface CognitoLegacyState extends DeliverrUser {
  user?: CognitoLegacyUser;
  attributes?: CognitoUserAttribute;
}

export type NonDeprecatedCognitoAttributes = Omit<DeliverrUser, "algoliaSearchKey" | "sellerUniqueId">;

export function createCognitoStateFromUser(user: CognitoLegacyUser): CognitoLegacyState {
  const groups = user.signInUserSession?.idToken?.payload["cognito:groups"];

  return {
    user,
    attributes: user.attributes,
    ...fromCognito(user.attributes),
    groups,
  };
}

export const cognitoReducer = (state: Partial<CognitoLegacyState> = cognitoInitialState, action) => {
  const { type, ...payload } = action;

  // propagates all amplify event payload to cognito state
  return type?.startsWith("COGNITO")
    ? type === CognitoLegacyActions.COGNITO_LOGIN || type === CognitoLegacyActions.COGNITO_UPDATE_USER_ATTRIBUTES
      ? createCognitoStateFromUser(payload.user)
      : payload
    : state;
};
