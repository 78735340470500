import React from "react";
import { BaseSelect, SelectProps } from "./BaseSelect";
import { LargeSelect } from "./LargeSelect";
import { MultiSelect } from "./MultiSelect";
import { SmallSelect } from "./SmallSelect";

export const Select: React.FC<SelectProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  if (props.multiple && (props.small || props.large)) {
    throw new Error("multiple and small/large prop cannot be passed simultaneously");
  }
  if (props.large && props.small) {
    throw new Error("small and large prop cannot be passed simultaneously");
  }

  return props.multiple ? (
    <MultiSelect {...props} />
  ) : props.large ? (
    <LargeSelect {...props} />
  ) : props.small ? (
    <SmallSelect {...props} />
  ) : (
    <BaseSelect {...props} />
  );
};
