import { createActionCreator } from "common/ReduxUtils";
import { StorageSelectProductsActionTypes } from "./StorageSelectProductAction.types";
import { ProductBulkUploadRecord } from "common/clients/storage/BulkUpload/StorageInboundBulkUploadModels";
import { ProductCasePack, Product } from "@deliverr/legacy-inbound-client/lib/legacy-copy-pasta/product";

export const addStorageProduct = createActionCreator<string, Product>(
  StorageSelectProductsActionTypes.STORAGE_ADD_PRODUCT,
  "dsku",
  "product"
);

export const addStorageProductDetails = createActionCreator<{ [dsku: string]: Product }>(
  StorageSelectProductsActionTypes.STORAGE_ADD_PRODUCT_DETAILS,
  "productDetails"
);

export const removeStorageProduct = createActionCreator<string>(
  StorageSelectProductsActionTypes.STORAGE_REMOVE_PRODUCT,
  "dsku"
);

export const updateCaseQty = createActionCreator<string, number>(
  StorageSelectProductsActionTypes.STORAGE_UPDATE_QTY_PER_CASE,
  "dsku",
  "caseQty"
);

export const updateNumberOfCases = createActionCreator<string, number>(
  StorageSelectProductsActionTypes.STORAGE_UPDATE_NUMBER_OF_CASES,
  "dsku",
  "numberOfCases"
);

export const addBulkUploadProduct = createActionCreator<ProductBulkUploadRecord, ProductCasePack | undefined>(
  StorageSelectProductsActionTypes.STORAGE_ADD_BULK_UPLOAD_PRODUCT,
  "product",
  "pack"
);
