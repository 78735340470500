import { Anchor, Notification } from "common/components/ui";
import { FormattedMessage } from "react-intl";
import React, { FC } from "react";
import { shipmentCreateLabels } from "../shipmentCreate.labels";
import { mustBeDefined } from "common/utils/mustBeDefined";

const labels = shipmentCreateLabels.steps;

interface Props {
  orderId?: string;
  errorMessage?: JSX.Element;
}

export const SummaryErrorNotification: FC<Props> = ({ orderId, errorMessage }) => {
  return (
    <Notification
      appearance="DANGER"
      content={{
        title: errorMessage ?? <FormattedMessage {...labels.confirm.error} />,
        description: orderId && (
          <FormattedMessage
            {...labels.confirm.contactSupportWithId}
            values={{
              orderId,
              linkText: (
                <Anchor href={`mailto:${mustBeDefined(process.env.SUPPORT_EMAIL)}?subject=Transfer ID: ${orderId}`}>
                  <FormattedMessage {...labels.confirm.contactSupportLinkText} />
                </Anchor>
              ),
            }}
          />
        ),
      }}
    />
  );
};
