import { RadioTileGroup } from "common/components/ui";
import styled from "@emotion/styled";
import React from "react";
import { useSelectCarrier } from "./useSelectCarrier";

const StyledRadioTileGroup = styled(RadioTileGroup)`
  justify-content: space-between;

  label {
    width: 47%;
  }
`;

export const SelectCarrier: React.FC = () => {
  const { carrierTypeOptions, onCarrierTypeChange, selectedCarrierType } = useSelectCarrier();
  return (
    <StyledRadioTileGroup
      currentValue={selectedCarrierType}
      direction="HORIZONTAL"
      name="cargoTypeRadioGroup"
      options={carrierTypeOptions}
      onChange={onCarrierTypeChange}
      block
    />
  );
};
