import { SellerProblemsDetailView } from "@deliverr/legacy-inbound-client";

import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { NonComplianceListActionTypes } from "./actions/NonComplianceListActionTypes";
import {
  NonComplianceProductWithPlanName,
  MissingBarcodeCaseWithPlanName,
  UnexpectedBarcodeCaseWithPlanName,
} from "./types";

export interface NonComplianceListState {
  ncProducts: NonComplianceProductWithPlanName[];
  missingBarcodeCases: MissingBarcodeCaseWithPlanName[];
  unexpectedBarcodeCases: UnexpectedBarcodeCaseWithPlanName[];
  unexpectedSkuCases: SellerProblemsDetailView[];
}

export const nonComplianceListInitialState: NonComplianceListState = {
  ncProducts: [],
  missingBarcodeCases: [],
  unexpectedBarcodeCases: [],
  unexpectedSkuCases: [],
};

const reducers: ReducerDictionary<NonComplianceListState> = {
  ...handleSimpleReducerUpdates([
    NonComplianceListActionTypes.SET_UNKNOWN_BARCODE_NC_CASES,
    NonComplianceListActionTypes.SET_MISSING_BARCODE_NC_CASES,
    NonComplianceListActionTypes.SET_UNEXPECTED_BARCODE_NC_CASES,
    NonComplianceListActionTypes.SET_UNEXPECTED_SKU_NC_CASES,
  ]),
};

export const nonComplianceListReducer = createReducer(nonComplianceListInitialState, reducers);
