import { DestinationType, PrepJobSourceType } from "@deliverr/prep-service-client";
import { RootState } from "RootReducer";
import { shouldPreserveListState } from "common/RouteUtils";
import { Text } from "common/components/ui";
import { setCurrentSearchTerm } from "parcel/screens/list/actions/setCurrentSearchTerm";
import { SourceLink } from "prep/list/PrepListTable/SourceLink";
import React, { useEffect, useRef } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { batch, useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { useMount } from "react-use";
import { Path } from "paths";
import { FeatureName, useFeatureOn } from "common/Split";
import { TableColumn } from "../../../common/components/lists/Table";
import { PREP_TYPE_LABELS } from "../../labels";
import { PrepStatusPill } from "../../PrepStatusPill";
import { PREP_LIST_LABELS } from "../PrepList.labels";
import { searchPrep } from "../actions/searchPrep";
import { setCurrentPage, setCurrentPageRoute } from "../actions/setCurrentPage";
import { PrepSearchResult } from "../../types";
import { getIsPrepListLoading, getQueryParams } from "./helpers";
import { NCIcon } from "common/components/NCIcon";
import { IconContainer, PrepPlanNameWrapper } from "prep/list/PrepListTable/PrepListTable.styles";

enum KNOWN_DESTINATIONS {
  D2C_NETWORK = "D2C_NETWORK",
  STORAGE = "STORAGE",
}

export const usePrepListTable = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { searchInfo, currentSearchTerm, currentPageItems } = useSelector((state: RootState) => state.prepList);
  const { resultsPerPage } = useSelector((state: RootState) => state.user);
  const shouldShowPrepDetailsPage = useFeatureOn(FeatureName.PrepDetailsPageEnabled);

  const isLoading = useSelector(getIsPrepListLoading);

  const prevResultsPerPage = useRef(resultsPerPage);
  const { formatMessage } = useIntl();

  useMount(() => {
    if (!(shouldPreserveListState(location, history) && searchInfo)) {
      const { formattedPage, search } = getQueryParams(location);

      if (formattedPage) {
        setCurrentPageRoute(formattedPage - 1);
      }
      if (search) {
        dispatch(setCurrentSearchTerm(search));
      }
      dispatch(searchPrep(formattedPage ? { page: formattedPage - 1 } : undefined));
    }
  });

  useEffect(() => {
    if (resultsPerPage !== prevResultsPerPage.current) {
      prevResultsPerPage.current = resultsPerPage;
      dispatch(searchPrep());
    }
  }, [resultsPerPage, prevResultsPerPage, dispatch]);

  const onPageChange = (page: number) => {
    batch(() => {
      dispatch(setCurrentPage(page));
      dispatch(searchPrep());
    });
    setCurrentPageRoute(page);
  };

  const getOriginLabel = (sourceType: PrepJobSourceType): string => {
    if (sourceType === PrepJobSourceType.SHIPPING_PLAN) {
      return formatMessage({ ...PREP_LIST_LABELS.origin.shippingPlan });
    } else if (sourceType === PrepJobSourceType.WHOLESALE_SHIPMENT) {
      return formatMessage({ ...PREP_LIST_LABELS.origin.wholesaleShipment });
    } else {
      return formatMessage({ ...PREP_LIST_LABELS.origin.externalTask });
    }
  };

  const getDestinationLabel = (destinationType: DestinationType, destination?: string): string | undefined => {
    if (destinationType === DestinationType.SL_NETWORK) {
      if (destination === KNOWN_DESTINATIONS.D2C_NETWORK) {
        return formatMessage({ ...PREP_LIST_LABELS.destination.d2cNetwork });
      } else if (destination === KNOWN_DESTINATIONS.STORAGE) {
        return formatMessage({ ...PREP_LIST_LABELS.destination.storage });
      }
    }
    return destination;
  };

  const prepListColumns: TableColumn<PrepSearchResult>[] = [
    {
      renderCell: (item) => (
        <PrepPlanNameWrapper>
          {item.nonComplianceReportedAt && (
            <IconContainer>
              <NCIcon message={"Prep job is non-compliant"} tooltipWidth={139} />
            </IconContainer>
          )}
          <div>
            <Text>{item.dpId}</Text>
            <Text appearance="INFO" size="caption">
              <FormattedMessage
                {...PREP_LIST_LABELS.list.createdAt}
                values={{
                  updatedAt: <FormattedDate value={item.createdAt} year="numeric" month="long" day="numeric" />,
                }}
              />
            </Text>
          </div>
        </PrepPlanNameWrapper>
      ),
      header: <FormattedMessage {...PREP_LIST_LABELS.list.dpIdHead} />,
    },
    {
      renderCell: (item) =>
        item.prepServices.map((prepService) => (
          <Text key={prepService}>{formatMessage(PREP_TYPE_LABELS[prepService])}</Text>
        )),
      header: <FormattedMessage {...PREP_LIST_LABELS.list.prepServicesHead} />,
    },
    {
      renderCell: (item) => (
        <SourceLink
          sourceId={item.sourceId}
          sourceType={item.sourceType}
          wholesaleOrderOrigin={item.wholesaleOrderOrigin}
          wholesaleOrderId={item.wholesaleOrderId}
        />
      ),
      header: <FormattedMessage {...PREP_LIST_LABELS.list.sourceIdHead} />,
    },
    {
      renderCell: (item) => <Text>{getOriginLabel(item.sourceType)}</Text>,
      header: <FormattedMessage {...PREP_LIST_LABELS.list.originHead} />,
    },
    {
      renderCell: (item) => <Text>{getDestinationLabel(item.destinationType, item.destination)}</Text>,
      header: <FormattedMessage {...PREP_LIST_LABELS.list.destinationHead} />,
    },
    {
      renderCell: (item) => <PrepStatusPill prepStatus={item.status} />,
      header: <FormattedMessage {...PREP_LIST_LABELS.list.statusHead} />,
    },
  ];

  const onRowClick = (item: PrepSearchResult) => {
    if (shouldShowPrepDetailsPage) {
      history.push(generatePath(Path.prepDetails, { prepId: item.dpId }));
    }
  };

  return {
    onPageChange,
    currentSearchTerm,
    currentPageItems,
    searchInfo,
    isLoading,
    prepListColumns,
    onRowClick,
  };
};
