import { ReturnOrderMinimal } from "@deliverr/returns-client";

export enum ReturnsListActionTypes {
  RETURNS_GET_LIST = "RETURNS_GET_LIST",
  RETURNS_GET_LIST_ERROR = "RETURNS_GET_LIST_ERROR",
  RETURNS_RESET_LIST = "RETURNS_RESET_LIST",
}

export interface OrderReturnsListState {
  orderReturnsList: ReturnOrderMinimal[];
}
