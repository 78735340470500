import React from "react";
import NumberFormat, { NumberFormatPropsBase } from "react-number-format";
import styled from "@emotion/styled";
import { DefaultTheme, ThemeProps } from "../shared";
import { transitionFast } from "../shared/helpers/css";
import { extractFormProps, FormGroup, FormGroupProps } from "../FormGroup";
import { IconV2 } from "../IconV2";

export interface CurrencyInputProps extends FormGroupProps<NumberFormatPropsBase> {
  currency?: string;
  isLoading?: boolean;
}

const InputContainer = styled.div<CurrencyInputProps & { defaultValue?: any }, DefaultTheme>(
  ({ hasError, disabled, theme }) => `
  position: relative;
  height: ${theme.input.height};
  display: flex;
  border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
  border-radius: ${theme.border.radius.R2};
  :focus-within {
    border-color: ${theme.colors.BLUE[300]};
    box-shadow: ${theme.input.focus.BLUE};
  }
  ${
    hasError &&
    `
    border-color: ${theme.colors.RED[300]};
    :focus-within {
        border-color: ${theme.colors.RED[300]};
        box-shadow: ${theme.input.focus.RED};
    }
    `
  }
  ${
    disabled &&
    `
    border-color: ${theme.colors.NEUTRAL[100]};
    `
  }
`
);

const StyledInput = styled(NumberFormat)<CurrencyInputProps, DefaultTheme>(
  ({ disabled, theme }) => `
  color: ${theme.colors.NEUTRAL[500]};
  font-family: ${theme.font.family.STANDARD!};
  line-height: ${theme.font.lineHeight.LH2};
  padding: ${theme.spacing.S2} ${theme.spacing.S3};
  border-radius: ${theme.border.radius.R2};
  outline: none;
  max-width: 100%;
  height: 100%;
  width: 100%;
  text-align: right;
  border: none;
  background-color: white;
  ${transitionFast("border-color")};
  ${
    disabled &&
    `
      background-color: ${theme.colors.NEUTRAL[60]};
      cursor: not-allowed;
    `
  }
`
);

const Prefix = styled.div<ThemeProps>(
  ({ theme }) => `
  padding-left: ${theme.spacing.S2};
  padding-right: ${theme.spacing.S2};
  color: ${theme.colors.NEUTRAL[300]};
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
`
);

const Currency = styled.div<CurrencyInputProps & { defaultValue?: any; $isLoading: boolean }, DefaultTheme>(
  ({ $isLoading, disabled, theme }) => `
  padding-left: ${theme.spacing.S3};
  padding-right: ${theme.spacing.S3};
  box-shadow: 1px 0px 0px 0px inset ${theme.colors.NEUTRAL[80]};
  height: 100%;
  display: flex;
  align-items: center;
  color: ${$isLoading ? "transparent" : theme.colors.NEUTRAL[300]};
  background-color: white;
  transition: border-color .3s ease-in-out;
  position: relative;
  ${
    disabled &&
    `
      background-color: ${theme.colors.NEUTRAL[60]};
      cursor: not-allowed;
    `
  }
`
);

export const LoadingSpinnerContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  position: absolute;
  z-index: 10;
  right: ${theme.spacing.S2};
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: ${theme.colors.NEUTRAL[300]};
`
);

export const CurrencyInput = React.forwardRef<HTMLInputElement, CurrencyInputProps>(
  (
    {
      prefix = "$",
      currency = "USD",
      allowNegative = false,
      decimalScale = 2,
      thousandSeparator = true,
      fixedDecimalScale = true,
      ...props
    },
    ref
  ) => {
    const [formProps, { hasError, isLoading, ...inputProps }] = extractFormProps(props);
    return (
      <FormGroup {...formProps}>
        <InputContainer hasError={hasError} disabled={props.disabled}>
          <Prefix>{prefix}</Prefix>
          <StyledInput
            getInputRef={ref}
            thousandSeparator={thousandSeparator}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            allowNegative={allowNegative}
            onClick={({ currentTarget }) => currentTarget.select()}
            {...inputProps}
          />
          <Currency disabled={props.disabled} $isLoading={isLoading}>
            {currency}
            {isLoading && (
              <LoadingSpinnerContainer>
                <IconV2 type="loading" spin={true} />
              </LoadingSpinnerContainer>
            )}
          </Currency>
        </InputContainer>
      </FormGroup>
    );
  }
);

CurrencyInput.displayName = "CurrencyInput";
