import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { mapError } from "../core/mapError";
import { InboundCostEstimatorParams } from "./InboundCostEstimatorParams/InboundCostEstimatorParams";

export class TransportationClient {
  apiClient: ApiClient;
  constructor({ baseURL }: ApiClientConfig = { baseURL: mustBeDefined(process.env.TRANSPORTATION_SERVICE_URL) }) {
    this.apiClient = new ApiClient({
      baseURL,
    });
  }

  async getCostEstimatorParams(): Promise<InboundCostEstimatorParams> {
    try {
      return await this.apiClient.get({ url: `/v1/inbound/params`, authentication: isAuthenticated });
    } catch (err) {
      throw mapError(err);
    }
  }
}
