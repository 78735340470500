import useSWRImmutable from "swr/immutable";
import { fetchIsExistingFlexportCompany } from "../user/UserActions";
import { logError } from "Logger";
import { useSPDispatch, useSPSelector } from "common/ReduxUtils";
import { getFlexportUser } from "./FlexportUserSelectors";
import { getUserEmail } from "common/user/UserSelectors";
import { useEffect } from "react";
import isUndefined from "lodash/isUndefined";

export const COMPANY_EXISTS_QUERY_KEY = "flexportUser.isFlexportCompanyExisting";

// This hook checks if the user has company record in Flexport
export const useIsFlexportCompanyExisting = () => {
  const userEmail = useSPSelector(getUserEmail);
  const flexportUser = useSPSelector(getFlexportUser);
  const isFlexportCompanyExisting = flexportUser.isFlexportCompanyExisting;
  const dispatch = useSPDispatch();

  const {
    data,
    error,
    mutate: revalidate,
  } = useSWRImmutable(
    isFlexportCompanyExisting ? null : [COMPANY_EXISTS_QUERY_KEY, userEmail],
    async () => await fetchIsExistingFlexportCompany(userEmail),
    {
      errorRetryCount: 3,
      onError: () => {
        logError({ fn: "useFetchFlexportUser", error }, "Error fetching flexportUser");
      },
    }
  );

  useEffect(() => {
    // SWR's onSuccess may not always get called due to this hook being remounted in multiple places
    if (!isUndefined(data) && data !== isFlexportCompanyExisting) {
      dispatch({
        type: "SET_IS_FLEXPORT_COMPANY_EXISTING",
        isFlexportCompanyExisting: data,
      });
    }
  }, [data, isFlexportCompanyExisting, dispatch]);

  const isLoading = isFlexportCompanyExisting === null && !error;

  return { isFlexportCompanyExisting, isLoading, revalidate };
};
