export enum OrderListActionTypes {
  SEARCH_ORDERS_SUCCESS = "SEARCH_ORDERS_SUCCESS",
  SEARCH_ORDERS_PREVIEW = "SEARCH_ORDERS_PREVIEW",
  SEARCH_ORDERS_ERROR = "SEARCH_ORDERS_ERROR",
  SET_ORDERS_SORT = "SET_ORDERS_SORT",
  SET_CURRENT_SEARCH_TERM = "SET_CURRENT_SEARCH_TERM",
  LOAD_ORDER_LIST = "LOAD_ORDER_LIST",
  SET_ORDER_SUMMARY = "SET_ORDER_SUMMARY",
  SET_CURRENT_PAGE = "SET_CURRENT_PAGE",
  SET_SUMMARY_FILTER = "SET_SUMMARY_FILTER",
  SET_ORDERS_FILTER = "SET_ORDERS_FILTER",
  CLEAR_FILTER_PREVIEW = "CLEAR_FILTER_PREVIEW",
  LOAD_IMPORT_ERRORS_TABLE = "LOAD_IMPORT_ERRORS_TABLE",
  SEARCH_IMPORT_ERRORS_START = "SEARCH_IMPORT_ERRORS_START",
  SEARCH_IMPORT_ERRORS_SUCCESS = "SEARCH_IMPORT_ERRORS_SUCCESS",
  SEARCH_IMPORT_ERRORS_ERROR = "SEARCH_IMPORT_ERRORS_ERROR",
  RESET_IMPORT_ERRORS = "RESET_IMPORT_ERRORS",
}
