import { PALLET_VOLUME_IN_CUBIC_INCH } from "@deliverr/storage-client";
import { UnitSystem } from "inbounds/createShipment/CreateShipmentTypes";

export const CBM_TO_CFT = 35.315;

export const calcTotalVolumeToPallets = (totalVolume: number, unitSystem: UnitSystem) => {
  const volumeCubicFeet = unitSystem === UnitSystem.CBM_KG ? totalVolume * CBM_TO_CFT : totalVolume;
  const volumeCubicInches = volumeCubicFeet * 1728;

  return Math.ceil((volumeCubicInches * 1.2) / PALLET_VOLUME_IN_CUBIC_INCH);
};
