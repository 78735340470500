import styled from "@emotion/styled";
import Modal from "common/components/Modal";
import { DefaultTheme } from "common/components/ui";

export const StyledModal = styled(Modal)<{ theme: DefaultTheme }>(
  ({ theme }) => `
    .modal-dialog {
      width: 30rem;
    }
    .modal-content{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${theme.spacing.S4};
      padding: ${theme.spacing.S6};
    }
  `
);

export const ModalHeading = styled.h3<{ theme: DefaultTheme }>(
  ({ theme }) => `
    font-size: ${theme.font.size.F4};
    font-weight: ${theme.font.weight.BOLD};
    line-height: ${theme.font.lineHeight.LH4};
    font-family: ${theme.font.family.TITLE};
  `
);

export const ModalForm = styled.form<{ theme: DefaultTheme }>(
  ({ theme }) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.S4};
  `
);

export const ModalFormButtonsContainer = styled.div<{ theme: DefaultTheme }>(
  ({ theme }) => `
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing.S2};
    margin-top: ${theme.spacing.S4};
    margin-bottom: ${theme.spacing.S4};
  `
);

export const StyledModalFormLabel = styled.label<{ theme: DefaultTheme }>(
  ({ theme }) => `
    font-size: ${theme.font.size.F2};
    color: ${theme.colors.NEUTRAL[500]};
  `
);

export const StyledModalSpan = styled.span<{ theme: DefaultTheme }>(
  ({ theme }) => `
    display: block;
    padding-bottom: ${theme.spacing.S2};
    line-height: ${theme.font.lineHeight.LH2};
  `
);
