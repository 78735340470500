import { getAuth } from "common/utils/SSO";
import { Brand } from "utility-types";
import log from "Logger";
import pify from "pify";

export type BearerToken = Brand<string, "bearer-token">;

let user;

export const getBearerToken = async (): Promise<BearerToken> => {
  if (!user) {
    const currentAuth = await getAuth();
    user = await currentAuth.currentAuthenticatedUser();
  }

  const ctx = { fn: "getBearerToken" };

  if (user) {
    const getSession = pify(user.getSession.bind(user));
    const session = await getSession();
    const bearerToken = session.getIdToken().getJwtToken() as BearerToken;

    if (bearerToken === undefined) {
      log.error({ ...ctx, user }, "Unable to find bearer token");
      throw new Error("Unable to find bearer token!");
    } else {
      return bearerToken as BearerToken;
    }
  } else {
    log.error({ ...ctx, user }, "Unable to find bearer token");
    throw new Error("Unable to find bearer token!");
  }
};
