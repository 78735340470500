import { WarehousePublic } from "@deliverr/business-types";
import { ContainerSize, ShipmentType } from "@deliverr/storage-client";

export const getIsCargoTypeNextButtonDisabled = (
  cargoType?: ShipmentType,
  containerSize?: ContainerSize,
  numOfPallets?: number,
  destinationStorageWarehouse?: WarehousePublic
) =>
  !cargoType ||
  (cargoType === ShipmentType.FLOOR_LOADED_CONTAINER && !containerSize) ||
  (cargoType === ShipmentType.PALLETIZED && !numOfPallets) ||
  !destinationStorageWarehouse;
