import { RmtId, SellerRmtMinimal } from "@deliverr/returns-client";
import { __, camelCase, defaultTo, pipe, prop, toUpper } from "lodash/fp";

import { ElevioArticleId } from "common/elevio";
import { RmtStepId } from "./store/RmtReducer";
import { getRoundedLogo } from "common/RoundedLogos";

interface RmtItem {
  name: string;
  /** Integration marketing page url */
  integrationPage: string;
  informationPage?: string;
  roundedLogo?: JSX.Element;
  helpArticleId?: ElevioArticleId;
  connectRmtElevioArticleId?: ElevioArticleId;
}

export type RmtIntegrations = Extract<
  RmtId,
  RmtId.LOOP | RmtId.RETURNGO | RmtId.RETURNGO | RmtId.AMAZON | RmtId.DELIVERR
>;

const DELIVERR_URL = "https://deliverr.com";
const INTEGRATIONS_URL = `${DELIVERR_URL}/integrations`;

export const returnManagementConfig: { [key: string]: RmtItem } = {
  LOOP: {
    name: "Loop",
    integrationPage: `${INTEGRATIONS_URL}/loop`,
    roundedLogo: getRoundedLogo("LOOP"),
    connectRmtElevioArticleId: ElevioArticleId.ReturnsRmtConnectLoop,
  },
  RETURNGO: {
    name: "ReturnGO",
    informationPage: "https://support.returngo.ai/integration-with-deliverr",
    integrationPage: `${INTEGRATIONS_URL}/returngo`,
    roundedLogo: getRoundedLogo("RETURNGO"),
    connectRmtElevioArticleId: ElevioArticleId.ReturnsRmtConnectReturnGo,
  },
  AMAZON: {
    name: "Amazon",
    integrationPage: `${INTEGRATIONS_URL}/amazon`,
    roundedLogo: getRoundedLogo("AMAZON"),
    connectRmtElevioArticleId: ElevioArticleId.ReturnsRmtConnectAmazon,
  },
  OTHER: {
    name: "Other",
    integrationPage: INTEGRATIONS_URL,
    roundedLogo: getRoundedLogo("UNKNOWN"),
  },
};

export interface RmtCardProps {
  rmt: SellerRmtMinimal;
}

type RmtProps = RmtId | string;

export const standardizeListing = pipe<RmtProps, string, string>(camelCase, toUpper);

export const rmtConfig = pipe<RmtProps, string, RmtItem, RmtItem>(
  standardizeListing,
  prop(__, returnManagementConfig),
  defaultTo(returnManagementConfig.OTHER)
);

export declare enum RmtState {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  NOTCONNECTED = "NOTCONNECTED",
}

export const deriveStepId = (sellerRmts: SellerRmtMinimal[], rmtId: RmtId, token?: string): RmtStepId => {
  const rmt = sellerRmts.find((sellerRmt) => sellerRmt.rmtId === rmtId);

  if (!rmt || token) {
    return RmtStepId.SETUP_RMT;
  }
  return RmtStepId.MANAGE_RMT;
};

export const getSellerRmtByRmtId = (sellerRmts: SellerRmtMinimal[], rmtId: RmtId): SellerRmtMinimal | undefined => {
  return sellerRmts?.find((rmt) => rmt.rmtId === rmtId);
};

export const rmtConfigProp = (listing: RmtProps, property: keyof RmtItem) => pipe(rmtConfig, prop(property))(listing);
