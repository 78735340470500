import React from "react";
import { Text, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";

const StyledText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  position: relative;
  left: ${theme.spacing.S3};
  width: 50%;
  `
);

export const PlaceSearchGroupHead = ({ children, ...props }) => (
  <StyledText size="caption">
    <span>
      <b>{children.title}</b>({children.description})
    </span>
  </StyledText>
);
