import { useSelector } from "react-redux";
import { getCurrentOrganizationUser } from "organization/OrganizationSelectors";
import { FeatureName, useFeatureOn } from "common/Split";

export const useNavBar = () => {
  const currentOrganizationUser = useSelector(getCurrentOrganizationUser);
  const isGlobalSearchEnable = useFeatureOn(FeatureName.GlobalSearch);

  return {
    currentOrganizationUser,
    isGlobalSearchEnable,
  };
};
