import React from "react";
import { Pill, PillColors } from "common/components/ui";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { ShipmentStatus } from "@deliverr/replenishment-client";
import { replenishmentOrderLabels } from "./replenishmentOrder.labels";

const pillColorForUnknownStatus = PillColors.GRAY;
const pillDescriptionForUnknownStatus = replenishmentOrderLabels.status.unknown;

const pillColorMap: Record<ShipmentStatus, PillColors> = {
  [ShipmentStatus.DRAFT]: PillColors.GRAY,
  [ShipmentStatus.CREATED]: PillColors.BLUE,
  [ShipmentStatus.SCHEDULED]: PillColors.GRAY,
  [ShipmentStatus.SUBMITTED]: PillColors.BLUE,
  [ShipmentStatus.PROCESSING]: PillColors.BLUE,
  [ShipmentStatus.READY_TO_SHIP]: PillColors.BLUE,
  [ShipmentStatus.SHIPPED]: PillColors.BLUE,
  [ShipmentStatus.CANCELLED]: PillColors.GRAY,
  [ShipmentStatus.DELIVERED]: PillColors.BLUE,
  [ShipmentStatus.ARRIVED]: PillColors.BLUE,
  [ShipmentStatus.RECEIVING]: PillColors.BLUE,
  [ShipmentStatus.COMPLETED]: PillColors.GREEN,
  [ShipmentStatus.ERROR]: PillColors.RED,
};

const pillStatusMap: Record<ShipmentStatus, MessageDescriptor> = {
  [ShipmentStatus.DRAFT]: replenishmentOrderLabels.status.draft,
  [ShipmentStatus.SCHEDULED]: replenishmentOrderLabels.status.scheduled,
  [ShipmentStatus.CREATED]: replenishmentOrderLabels.status.created,
  [ShipmentStatus.SUBMITTED]: replenishmentOrderLabels.status.processing,
  [ShipmentStatus.PROCESSING]: replenishmentOrderLabels.status.processing,
  [ShipmentStatus.READY_TO_SHIP]: replenishmentOrderLabels.status.readyToShip,
  [ShipmentStatus.SHIPPED]: replenishmentOrderLabels.status.shipped,
  [ShipmentStatus.CANCELLED]: replenishmentOrderLabels.status.cancelled,
  [ShipmentStatus.DELIVERED]: replenishmentOrderLabels.status.delivered,
  [ShipmentStatus.ERROR]: replenishmentOrderLabels.status.error,
  [ShipmentStatus.ARRIVED]: replenishmentOrderLabels.status.arrived,
  [ShipmentStatus.RECEIVING]: replenishmentOrderLabels.status.receiving,
  [ShipmentStatus.COMPLETED]: replenishmentOrderLabels.status.completed,
};

interface Props {
  status: ShipmentStatus;
}

export const ReplenishmentShipmentStatusPill: React.FC<Props> = ({ status }) => {
  const color = status ? pillColorMap[status] ?? pillColorForUnknownStatus : pillColorForUnknownStatus;
  const description = status
    ? pillStatusMap[status] ?? pillDescriptionForUnknownStatus
    : pillDescriptionForUnknownStatus;

  return (
    <>
      <Pill color={color}>
        <FormattedMessage {...description} />
      </Pill>
    </>
  );
};
