import { formatInTimeZone } from "date-fns-tz";

// Zendesk chat is active from 7am to 5pm CST, Monday through Friday
const DAYS_OF_WEEK = ["Mon", "Tue", "Wed", "Thu", "Fri"];
const WEEKEND_DAYS_OF_WEEK = ["Sat", "Sun"];
const TIME_ZONE = "America/Chicago";
const CHAT_ACTIVE_START_TIME_CST = 7;
const CHAT_ACTIVE_END_TIME_CST = 17;

const EXTENDED_HOURS_START_TIME_CST = 6;
const EXTENDED_HOURS_END_TIME_CST = 20;
const EXTENDED_HOURS_START_TIME_WEEKEND_CST = 7;
const EXTENDED_HOURS_END_TIME_WEEKEND_CST = 16;

const getCurrentDayAndHour = (currentDate: Date) => {
  const dayOfTheWeek = formatInTimeZone(currentDate, TIME_ZONE, "eee");
  const hour = Number(formatInTimeZone(currentDate, TIME_ZONE, "HH"));

  return { dayOfTheWeek, hour };
};

// America/Chicago is CST
export const getIsSupportChatLive = (currentDate: Date) => {
  const { dayOfTheWeek, hour } = getCurrentDayAndHour(currentDate);
  return DAYS_OF_WEEK.includes(dayOfTheWeek) && hour >= CHAT_ACTIVE_START_TIME_CST && hour < CHAT_ACTIVE_END_TIME_CST;
};

// extended hours for chat support Mon-Fri 6am-8pm CST, and Sat-Sun 7am-4pm CST
export const getIsSupportChatLiveExtendedHours = (currentDate: Date) => {
  const { dayOfTheWeek, hour } = getCurrentDayAndHour(currentDate);

  if (WEEKEND_DAYS_OF_WEEK.includes(dayOfTheWeek)) {
    return hour >= EXTENDED_HOURS_START_TIME_WEEKEND_CST && hour < EXTENDED_HOURS_END_TIME_WEEKEND_CST;
  }
  return hour >= EXTENDED_HOURS_START_TIME_CST && hour < EXTENDED_HOURS_END_TIME_CST;
};
