import { FloorLoadedContainerType, PrepCombo, PrepType } from "@deliverr/prep-service-client";
import { PrepChargeGranularity, PrepCostConfig, SuperPrepType } from "prep/types";

/**
 * Prep cost configs for each prep type. Please access these values exclusively via the `usePrepCostConfigs` hook.
 * @deprecated  This is a temporary solution until the Prep Service can provide this information.
 */
export const PREP_COST_CONFIGS: Record<SuperPrepType, PrepCostConfig | undefined> = {
  [PrepType.SKU_LABELS]: { chargeGranularity: PrepChargeGranularity.PER_UNIT, costPerItemUsd: 0.35 },
  [PrepType.BOX_CONTENT_LABELS]: { chargeGranularity: PrepChargeGranularity.PER_BOX, costPerItemUsd: 0.5 },
  [PrepType.BUBBLE_MAILER]: { chargeGranularity: PrepChargeGranularity.PER_UNIT, costPerItemUsd: 0.8 },
  [PrepType.BUBBLE_WRAPPING]: { chargeGranularity: PrepChargeGranularity.PER_UNIT, costPerItemUsd: 0.8 },
  [PrepType.BAGGING]: { chargeGranularity: PrepChargeGranularity.PER_UNIT, costPerItemUsd: 0.5 },
  [PrepCombo.BAGGING_PROTECTION]: { chargeGranularity: PrepChargeGranularity.PER_UNIT, costPerItemUsd: 0.85 },
  [PrepCombo.DAMAGE_PROTECTION]: { chargeGranularity: PrepChargeGranularity.PER_UNIT, costPerItemUsd: 1.05 },
  [PrepCombo.BAGGING_PROTECTION_RS]: undefined,
  [PrepCombo.DAMAGE_PROTECTION_RS]: undefined,
  [PrepCombo.SKU_LABELS_RS]: undefined,
  [PrepType.BOX_BUILDING]: undefined,
  [PrepType.FLOOR_LOADED_CONTAINER]: undefined,
  [PrepType.KITTING]: { chargeGranularity: PrepChargeGranularity.PER_KIT, costPerItemUsd: 0.15, baseFee: 1.6 },
  [PrepType.QUALITY_CHECK]: undefined,
  [PrepType.BARCODE_TRANSLATION]: undefined,
  [PrepType.CUSTOM_SKU_LABEL]: undefined,
  [PrepType.PALLET_LABEL]: undefined,
  [PrepType.CARTON_LABEL]: { chargeGranularity: PrepChargeGranularity.PER_BOX, costPerItemUsd: 0.5 },
  [PrepType.UNIT_PROTECTION]: undefined,
  [PrepType.COMBO_BOX_CONTENT_LABEL]: undefined,
  [PrepCombo.FBA_PARCEL_STANDARD]: undefined,
  [PrepCombo.FBA_FREIGHT_STANDARD]: undefined,
  [PrepCombo.B2B_PARCEL_STANDARD]: undefined,
  [PrepCombo.B2B_FREIGHT_STANDARD]: undefined,
};
// TODO: Get values from BE and remove this file
export const PREP_COST_CONFIGS_REPLENISHMENT: Record<SuperPrepType, PrepCostConfig | undefined> = {
  [PrepType.SKU_LABELS]: { chargeGranularity: PrepChargeGranularity.PER_UNIT, costPerItemUsd: 0.95 },
  [PrepType.BOX_CONTENT_LABELS]: { chargeGranularity: PrepChargeGranularity.PER_BOX, costPerItemUsd: 0.5 },
  [PrepType.BUBBLE_MAILER]: { chargeGranularity: PrepChargeGranularity.PER_UNIT, costPerItemUsd: 0.8 },
  [PrepType.BUBBLE_WRAPPING]: { chargeGranularity: PrepChargeGranularity.PER_UNIT, costPerItemUsd: 0.8 },
  [PrepType.BAGGING]: { chargeGranularity: PrepChargeGranularity.PER_UNIT, costPerItemUsd: 0.5 },
  [PrepCombo.BAGGING_PROTECTION]: { chargeGranularity: PrepChargeGranularity.PER_UNIT, costPerItemUsd: 1.4 },
  [PrepCombo.DAMAGE_PROTECTION]: { chargeGranularity: PrepChargeGranularity.PER_UNIT, costPerItemUsd: 1.6 },
  [PrepCombo.BAGGING_PROTECTION_RS]: undefined,
  [PrepCombo.DAMAGE_PROTECTION_RS]: undefined,
  [PrepCombo.SKU_LABELS_RS]: undefined,
  [PrepType.BOX_BUILDING]: undefined,
  [PrepType.FLOOR_LOADED_CONTAINER]: undefined,
  [PrepType.KITTING]: { chargeGranularity: PrepChargeGranularity.PER_KIT, costPerItemUsd: 0.6, baseFee: 1.6 },
  [PrepType.QUALITY_CHECK]: undefined,
  [PrepType.BARCODE_TRANSLATION]: undefined,
  [PrepType.CUSTOM_SKU_LABEL]: undefined,
  [PrepType.PALLET_LABEL]: undefined,
  [PrepType.CARTON_LABEL]: { chargeGranularity: PrepChargeGranularity.PER_BOX, costPerItemUsd: 0.5 },
  [PrepType.UNIT_PROTECTION]: undefined,
  [PrepType.COMBO_BOX_CONTENT_LABEL]: undefined,
  [PrepCombo.FBA_PARCEL_STANDARD]: undefined,
  [PrepCombo.FBA_FREIGHT_STANDARD]: undefined,
  [PrepCombo.B2B_PARCEL_STANDARD]: undefined,
  [PrepCombo.B2B_FREIGHT_STANDARD]: undefined,
};

/*
  We currently only allow a single container to be submitted via seller portal, and it is priced based on the length of the
  container rather than the quantity of containers. As such, we use a specific mapping for this case
*/
export const FLOOR_LOADED_CONTAINER_PRICING: Record<FloorLoadedContainerType, number> = {
  [FloorLoadedContainerType.TWENTY_FT]: 325,
  [FloorLoadedContainerType.FORTY_FT]: 650,
};
