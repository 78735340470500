import React, { FC, useContext } from "react";

import { MissingBarcodeCaseContext } from "../../context";
import { IdentifyProduct } from "inbounds/non-compliance/accordion-steps";

export const MissingBarcodeIdentify: FC = () => {
  const { onProductSelect, foundProduct, onSubmitProduct } = useContext(MissingBarcodeCaseContext);

  return <IdentifyProduct onProductSelect={onProductSelect} foundProduct={foundProduct} onContinue={onSubmitProduct} />;
};
