import { SearchResults } from "common/list/AlgoliaService";
import { SearchResults as OpenSearchResults } from "common/search/SearchService";

export const CARRIER_SELECT_ALGOLIA_CONFIG = {
  indexName: process.env.ALGOLIA_INDEX_CARRIERS!,
  searchConfig: {
    hitsPerPage: 10,
    maxValuesPerFacet: 3,
  },
};

export interface CarrierSelectOptions {
  carrier?: string;
  handleInputChange?: (search: string) => void;
  handleSelectionChange?: (option: string) => void;
}

export interface CarrierSelectOption {
  value: string;
  label: string;
}

export function processSearchResultsToOptions(
  data: OpenSearchResults | SearchResults | null | undefined,
  filterFn?: (option: CarrierSelectOption) => boolean
): CarrierSelectOption[] {
  const options = data?.hits
    ? data.hits.map((result) => ({
        label: result.name,
        value: result.code,
      }))
    : [];

  return filterFn ? options.filter(filterFn) : options;
}

export function carrierCodeToOption(carrierCode?: string): CarrierSelectOption | undefined {
  return carrierCode ? { value: carrierCode, label: carrierCode } : undefined; // TODO! Fix this to actually resolve the label
}

export const lookupCarrier = (carrierCode?: string) => {
  return (data: OpenSearchResults | SearchResults | null | undefined) => {
    if (!carrierCode) {
      return;
    }

    const results = processSearchResultsToOptions(data, (option: CarrierSelectOption) => option.value === carrierCode);
    return results?.[0];
  };
};

export const CARRIER_INPUT_DEBOUNCE_INTERVAL = 150;
