import { ApiError } from "@deliverr/errors";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { BulkOrderApiErrorResponse } from "./BulkUploadError";
import { BulkOrderPreview } from "./BulkUploadPreview";
import { BulkOrderCreateResponse } from "./BulkUploadCreate";

export interface BulkUploadApiResponse {
  data?: BulkOrderPreview;
  error?: BulkOrderApiErrorResponse;
}

export interface BulkUploadCreateApiResponse {
  data?: BulkOrderCreateResponse;
  error?: BulkOrderApiErrorResponse;
}

export type BulkUploadClientConfig = ApiClientConfig;

export const defaultBulkUploadClientConfig = (): BulkUploadClientConfig => {
  if (process.env.BULK_UPLOAD_SERVICE_URL) {
    return {
      baseURL: `${process.env.BULK_UPLOAD_SERVICE_URL}`,
    };
  } else {
    throw new Error(
      `Unable to get default orders client config, environment variable BULK_UPLOAD_SERVICE_URL could not be found`
    );
  }
};

export class BulkUploadClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: BulkUploadClientConfig = defaultBulkUploadClientConfig()) {
    this.apiClient = new ApiClient({
      baseURL,
    });
  }

  public async getPreview(sellerId: string, objectUrl: string): Promise<BulkUploadApiResponse | undefined> {
    try {
      const response = await this.apiClient.post<BulkUploadApiResponse, {}, { sellerId: string; objectUrl: string }>({
        url: `/validate`,
        body: {
          sellerId,
          objectUrl,
        },
        authentication: isAuthenticated,
      });

      return response;
    } catch (error) {
      switch (error.response?.status) {
        case 404:
          return undefined;
        case 504:
          throw new Error(ApiError.NETWORK_ERROR);
        default:
          throw new Error(ApiError.UNKNOWN_ERROR);
      }
    }
  }

  public async createOrders(sellerId: string, objectUrl: string): Promise<BulkUploadCreateApiResponse | undefined> {
    try {
      return await this.apiClient.post<BulkUploadCreateApiResponse, {}, { sellerId: string; objectUrl: string }>({
        url: "/orders",
        body: {
          sellerId,
          objectUrl,
        },
        authentication: isAuthenticated,
      });
    } catch (error) {
      switch (error.response?.status) {
        case 404:
          return undefined;
        case 504:
          throw new Error(ApiError.NETWORK_ERROR);
        default:
          throw new Error(ApiError.UNKNOWN_ERROR);
      }
    }
  }
}
