import { Product } from "@deliverr/commons-clients";
import { hasDimensionsAndWeight } from "common/utils/product/hasDimensionsAndWeight";
import { hasValidDimensionsAndWeight } from "common/utils/product/hasValidDimensionsAndWeight";
import { composeGetIsOverWeight } from "common/utils/product/composeGetIsOverWeight";
import { composeGetIsOverDimensions } from "common/utils/product/composeGetIsOverDimensions";
import { ProductDimensionsValidator } from "inbounds/steps/dimensions/DimensionsAndWeightsTable/ProductDimensionsValidator";
import {
  STORAGE_INBOUND_ADDITIONAL_MAX_CASE_LONGEST_IN_FOR_SOME_WH,
  STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
  STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
  STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS,
} from "../CaseDimensionsConstants";
import { DimensionsError } from "inbounds/steps/dimensions/DimensionsAndWeightsTable/DimensionsError";
import {
  OVERRIDE_STORAGE_INBOUND_MAX_CASE_LONGEST_IN,
  OVERRIDE_STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
  OVERRIDE_STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
} from "storage/inbounds/StorageDimensionsOverrideConstants";

const validateIfInvalidProduct = (
  isProductOverSized: boolean,
  isProductOverWeight: boolean,
  product: Partial<Product>
): DimensionsError | undefined => {
  if (isProductOverSized && isProductOverWeight) {
    return DimensionsError.STORAGE_OVER_DIMENSIONS_AND_WEIGHT;
  } else if (isProductOverSized) {
    return DimensionsError.STORAGE_OVER_DIMENSIONS;
  } else if (isProductOverWeight) {
    return DimensionsError.STORAGE_OVER_WEIGHT;
  } else if (!hasDimensionsAndWeight(product)) {
    return DimensionsError.INCOMPLETE;
  } else if (!hasValidDimensionsAndWeight(product)) {
    return DimensionsError.INVALID_DIMENSIONS_AND_WEIGHT;
  }

  return;
};

export const getCaseDimensionsError: ProductDimensionsValidator = (
  product: Partial<Product>,
  isDimensionOverrideFeatureEnabled?: boolean
) => {
  const isProductOverSized = composeGetIsOverDimensions({
    maxLongestSide: STORAGE_INBOUND_ADDITIONAL_MAX_CASE_LONGEST_IN_FOR_SOME_WH,
    maxMiddleSide: STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
    maxShortestSide: STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
  })(product);
  const isProductOverWeight = composeGetIsOverWeight(STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS)(product);
  if (isDimensionOverrideFeatureEnabled) {
    const isProductOverSizedAfterRelaxation = composeGetIsOverDimensions({
      maxLongestSide: OVERRIDE_STORAGE_INBOUND_MAX_CASE_LONGEST_IN,
      maxMiddleSide: OVERRIDE_STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
      maxShortestSide: OVERRIDE_STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
    })(product);
    return validateIfInvalidProduct(isProductOverSizedAfterRelaxation, isProductOverWeight, product);
  }

  return validateIfInvalidProduct(isProductOverSized, isProductOverWeight, product);
};
