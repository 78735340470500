import { convertCuCmToCuIn, convertKgToLb } from "common/utils/units";
import { getTotalUnitQuantity } from "inbounds/steps/InboundStepSelectors";
import { getProductDetailsCache } from "inbounds/store/selectors/productDetailsCache";
import { RootState } from "RootReducer";
import { InboundCostEstimatorParamsData } from "common/clients/transportation/InboundCostEstimatorParams/InboundCostEstimatorParamsData";
import { productHasTrustedDims } from "inbounds/crossdock/util/productHasTrustedDims";
import { LengthUnit, WeightUnit } from "@deliverr/commons-objects";
import log from "Logger";

export interface ShippingPlanItemTotals {
  totalItemQty: number;
  totalVolume?: number;
  totalWeight?: number;
}

export type FallbackDimsAndWeights = Pick<
  InboundCostEstimatorParamsData,
  "defaultUnitVolumeCubicIn" | "defaultUnitWeightLbs"
>;

/**
 * Get a function that aggregates the volume, weight, and total item quantity of the current Shipping Plan in imperial units
 * @param state
 * @returns
 */
export const getShippingPlanItemTotals = (state: RootState) => (
  untrustedDimsFallbacks?: FallbackDimsAndWeights
): ShippingPlanItemTotals => {
  const ctx = { fn: "getShippingPlanItemTotals", untrustedDimsFallbacks };
  const totalItemQty = getTotalUnitQuantity(state);
  const productDetailsCache = getProductDetailsCache(state);

  const planItems = Object.values(state.inbound.planItems?.byId ?? {});

  const productsWithDims = planItems.map(({ dsku, qty }) => {
    const product = productDetailsCache[dsku] ?? {};
    const { height, width, length, weight: productWeight } = product;

    const areDimsTrusted = productHasTrustedDims(product);

    // use volume fallback if dims are untrusted or any dimension is undefined
    let volume: number | undefined;
    let lengthUnit: LengthUnit | undefined = product.lengthUnit;
    if (!areDimsTrusted || [length, width, height].includes(undefined)) {
      volume = untrustedDimsFallbacks?.defaultUnitVolumeCubicIn;
      lengthUnit = "in"; // length is either undefined or using a fallback defined in in

      // log difference of original vs value with fallback
      if (untrustedDimsFallbacks) {
        const volumeDifference = (volume ?? 0) - (length ?? 0) * (width ?? 0) * (height ?? 0);
        log.info(
          { ...ctx, areDimsTrusted, length, width, height, lengthUnit, volumeDifference },
          "Using fallbacks for volume"
        );
      }
    } else {
      volume = length! * width! * height!;
    }

    // use weight fallback if dims are untrusted or product's weight is undefined
    let weight: number | undefined;
    let weightUnit: WeightUnit | undefined = product.weightUnit;
    if (!areDimsTrusted || productWeight === undefined) {
      weight = untrustedDimsFallbacks?.defaultUnitWeightLbs;
      weightUnit = "lb"; // weight is either undefined or using a fallback defined in lbs

      // log difference of original vs value with fallback
      if (untrustedDimsFallbacks) {
        const weightDifference = (weight ?? 0) - (productWeight ?? 0);
        log.info({ ...ctx, areDimsTrusted, weight, weightUnit, weightDifference }, "Using fallbacks for weight");
      }
    } else {
      weight = productWeight;
    }

    return {
      dsku,
      qty,
      volume,
      lengthUnit,
      weight,
      weightUnit,
    };
  });

  // If any value is undefined (AKA fallbacks didn't exist), just return totalItemQty
  // because otherwise per-unit calculations will be skewed
  if (productsWithDims.some(({ volume, weight }) => [volume, weight].includes(undefined))) {
    return {
      totalItemQty,
    };
  }

  const { totalVolume, totalWeight } = productsWithDims.reduce(
    (totals, { qty, volume, weight, weightUnit, lengthUnit }) => {
      let totalProductVolume: number | undefined;
      let totalProductWeight: number | undefined;

      if (volume !== undefined) {
        // get total volume per DSKU
        totalProductVolume = volume * qty;

        // convert it to inches if necessary
        if (lengthUnit === "cm") {
          totalProductVolume = convertCuCmToCuIn(totalProductVolume);
        }
      }

      if (weight !== undefined) {
        // get total weight per DSKU
        totalProductWeight = weight * qty;

        // convert it to pounds if necessary
        if (weightUnit === "kg") {
          totalProductWeight = convertKgToLb(totalProductWeight);
        }
      }

      return {
        totalVolume: totals.totalVolume + (totalProductVolume ?? 0),
        totalWeight: totals.totalWeight + (totalProductWeight ?? 0),
      };
    },
    { totalVolume: 0, totalWeight: 0 }
  );

  return {
    totalItemQty,
    // only set volume and weight if they're greater than 0 (AKA they exist)
    totalVolume: totalVolume > 0 ? totalVolume : undefined,
    totalWeight: totalWeight > 0 ? totalWeight : undefined,
  };
};
