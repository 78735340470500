import React from "react";
import {
  Appearance,
  Box,
  DefaultTheme,
  Icon,
  IconsProps,
  RadioTileGroup,
  Select,
  ThemeProps,
  Text,
} from "common/components/ui";
import styled from "@emotion/styled";
import { FormattedMessage, useIntl } from "react-intl";
import { StorageDestinationV2SelectCustomOption } from "./StorageDestinationV2SelectCustomOption";
import { TooltipWithIcon } from "common/components/icons/TooltipWithIcon";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import { StorageDestinationType } from "storage/inbounds/create/selectDestination/StorageDestinationType.types";
import { StorageDestinationTypeV2 } from "storage/inbounds/create/selectDestinationV2/StorageDestinationTypeV2.types";
import { StorageWarehouseSellerView } from "storage/inbounds/create/selectDestinationV2/StorageWarehouseSellerView";
import { STORAGE_DESTINATION_MESSAGES } from "./StorageDestinationMessages";
import { StorageDestinationSelectedOption } from "./StorageDestinationSelectedOption";
import { StorageWarehouseOption } from "storage/inbounds/create/selectDestinationV2/StorageWarehouseOption.types";
import { checkIfWarehousesHaveSameZipCode } from "./checkIfWarehousesHaveSameZipCode";
import { getStorageWarehouseDiscountString } from "./getStorageWarehouseDiscountString";
import { getWarehouseAddress } from "./getWarehouseAddress";
import { useSelector } from "react-redux";
import { getSelectedProductCasePacks } from "storage/inbounds/create/store/selector/getSelectedCasePacks";
import { isStorageWarehouseDisabled } from "inbounds/createShipment/steps/ReserveStorageFcSelectStep/useReserveStorageFcSelectStep";
import { FeatureName, useFeatureOn } from "common/Split";

const StyledSelect = styled(Select)<ThemeProps>(
  ({ theme }) => `
  &.selector {
  height: ${theme.spacing.S8};
  min-width: 292px;
  margin-bottom: ${theme.spacing.S2};
  margin-top: ${theme.spacing.S1};
}
.Select-control {
  height: ${theme.spacing.S8};
  width: 292px;
  border: ${theme.border.width.B1} ${theme.colors.NEUTRAL[80]} ${theme.border.type};
  border-radius: ${theme.border.radius.R2};
}
`
);

const PaddedIcon = styled(Icon)<IconsProps, DefaultTheme>(
  ({ theme }) => `
  margin-left: ${theme.spacing.S1};
  color: ${theme.colors.NEUTRAL[300]};
`
);

const Container = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    padding: ${theme.spacing.S1};
    font-style: italic;
    font-size: ${theme.font.size.F2};
    color: ${theme.colors.NEUTRAL[300]};
    margin-right: ${theme.spacing.S1};
    left: 0;
  `
);

interface StorageFacilitySelectProps {
  recommendedWarehouse: StorageWarehouseSellerView;
  storageWarehouseToBeShown: StorageWarehouseSellerView[];
  onFacilityTypeChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  destinationType: StorageDestinationTypeV2 | StorageDestinationType | undefined;
  onStorageWarehouseSelect: (selectedOption: StorageWarehouseOption) => void;
  selectedWarehouseOption?: StorageWarehouseOption;
}

export const StorageFacilitySelect = (props: StorageFacilitySelectProps) => {
  const {
    recommendedWarehouse,
    storageWarehouseToBeShown,
    onFacilityTypeChange,
    destinationType,
    onStorageWarehouseSelect,
    selectedWarehouseOption,
  } = props;
  const { formatMessage } = useIntl();
  const isOptionDisabled = (option: { value: StorageWarehouseSellerView; label: string; isDisabled: string }) =>
    option.isDisabled;

  const facilityTypeOptions = [
    {
      value: StorageDestinationTypeV2.RECOMMENDED_WAREHOUSE,
      label: formatMessage(STORAGE_DESTINATION_MESSAGES.recommendedWarehouse),
      content: recommendedWarehouse ? (
        <Box>
          {getWarehouseAddress(
            recommendedWarehouse,
            checkIfWarehousesHaveSameZipCode(recommendedWarehouse, storageWarehouseToBeShown),
            true
          )}
          {recommendedWarehouse.discount && (
            <Text appearance={Appearance.SUCCESS}>
              {getStorageWarehouseDiscountString(recommendedWarehouse.discount)}
            </Text>
          )}
        </Box>
      ) : (
        ""
      ),
    },
    {
      value: StorageDestinationTypeV2.SPECIFIC_WAREHOUSE,
      label: formatMessage(STORAGE_DESTINATION_MESSAGES.specificWarehouse),
    },
  ];

  const selectedProductCasePacks = useSelector(getSelectedProductCasePacks);
  const isDimensionOverrideFeatureEnabled = useFeatureOn(FeatureName.InboundRsDimRelaxation);
  // TODO the same check is already done in the useReserveStorageFcSelect, we should remove it from here
  const storageWarehouseOptions = storageWarehouseToBeShown
    .filter((warehouse) => warehouse.id !== recommendedWarehouse?.id)
    .map((warehouse) => ({
      value: warehouse,
      label: `${String(warehouse.region.charAt(0).toUpperCase()) + String(warehouse.region.slice(1))}`,
      isDisabled: isStorageWarehouseDisabled(selectedProductCasePacks, warehouse, isDimensionOverrideFeatureEnabled),
      hasSameZipCode: checkIfWarehousesHaveSameZipCode(warehouse, storageWarehouseToBeShown),
    }));

  const destinationSelectInputMarkup = (
    <Box marginTop="S4">
      <StyledSelect
        placeHolder={
          <FormattedMessage id="storage.inbounds.storageDestination.selectWarehouse" defaultMessage="Select facility" />
        }
        customComponents={{
          Option: StorageDestinationV2SelectCustomOption,
          SingleValue: StorageDestinationSelectedOption,
        }}
        options={storageWarehouseOptions}
        value={selectedWarehouseOption}
        isOptionDisabled={isOptionDisabled}
        onChange={onStorageWarehouseSelect}
        className="selector"
      />
    </Box>
  );

  const recommendedWarehouseOptionMarkUp = (
    <Box>
      <FormattedMessage {...STORAGE_DESTINATION_MESSAGES.recommendedWarehouse} />
      <TooltipWithIcon
        message={
          <Box maxWidth={"248px"}>
            <FormattedMessage
              id="storage.inbounds.storageDestination.recommendedWarehouse.toolTip"
              defaultMessage={
                "This location is recommended based on its proximity to your origin address and its available capacity."
              }
            />
          </Box>
        }
        placement="top"
      >
        <PaddedIcon type="question-circle-alt" size="sm" />
      </TooltipWithIcon>
    </Box>
  );

  const enrichedStorageDestinationOptions = facilityTypeOptions.map((storageDestinationOption) => {
    if (
      destinationType === StorageDestinationTypeV2.SPECIFIC_WAREHOUSE &&
      storageDestinationOption.value === StorageDestinationTypeV2.SPECIFIC_WAREHOUSE
    ) {
      return {
        ...storageDestinationOption,
        content: destinationSelectInputMarkup,
      };
    } else if (
      destinationType === StorageDestinationTypeV2.RECOMMENDED_WAREHOUSE &&
      storageDestinationOption.content === ""
    ) {
      return {
        ...storageDestinationOption,
        content: (
          <Container>
            <LoadingSpinner size="medium" />
            <FormattedMessage id="loading" defaultMessage=" Loading..." />
          </Container>
        ),
        label: recommendedWarehouseOptionMarkUp,
      };
    } else if (
      storageDestinationOption.content &&
      storageDestinationOption.value === StorageDestinationTypeV2.RECOMMENDED_WAREHOUSE
    ) {
      return {
        ...storageDestinationOption,
        label: recommendedWarehouseOptionMarkUp,
      };
    } else {
      return storageDestinationOption;
    }
  });

  return (
    <Box width="396px">
      <RadioTileGroup
        currentValue={destinationType}
        direction="VERTICAL"
        name="storageDestinationRadioGroup"
        options={enrichedStorageDestinationOptions}
        onChange={onFacilityTypeChange}
        block
      />
    </Box>
  );
};
