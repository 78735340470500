import React from "react";
import { Box, Title, Text } from "common/components/ui";
import { FlexDiv } from "common/components/FlexDiv";
import { FormattedMessage, FormattedPlural } from "react-intl";
import { TextAlign } from "common/components/ui/shared";
import { DeliverrAddress } from "@deliverr/commons-objects";
import Address from "common/components/Address";
import styled from "@emotion/styled";

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
`;

interface StorageSelectShippingHeaderProps {
  skuCount?: number;
  units?: number;
  fromAddress?: DeliverrAddress;
}

const StyledBox = styled(Box)(`
  text-align: center;
  width:100%;
`);

const InnerDiv = styled.div`
  display: inline-block;
`;

export const StorageSelectShippingHeader: React.FC<StorageSelectShippingHeaderProps> = ({
  units,
  skuCount,
  fromAddress,
}) => (
  <StyledBox>
    <InnerDiv>
      <FlexDiv justifyContent="center">
        <Title as="h3" displayAs="h4">
          <FormattedMessage id="inbounds.storageSelectShipping.title" defaultMessage="Select shipping" />
        </Title>
      </FlexDiv>
      <AddressContainer>
        <Text textAlign={TextAlign.center}>
          <FormattedMessage
            id="inbounds.storageSelectShipping.description"
            defaultMessage="{skuCount} {skus}, {units} {unitsMessage} from"
            values={{
              skuCount,
              skus: (
                <FormattedPlural
                  value={skuCount ?? 1}
                  one={<FormattedMessage id="inbounds.storageSelectShipping.skus.one" defaultMessage="SKU" />}
                  other={<FormattedMessage id="inbounds.storageSelectShipping.skus.plural" defaultMessage="SKUs" />}
                />
              ),
              units,
              unitsMessage: (
                <FormattedPlural
                  value={units ?? 1}
                  one={<FormattedMessage id="inbounds.storageSelectShipping.units.one" defaultMessage="unit" />}
                  other={<FormattedMessage id="inbounds.storageSelectShipping.units.plural" defaultMessage="units" />}
                />
              ),
            }}
          />
        </Text>
        {fromAddress && <Address address={fromAddress} hideName />}
      </AddressContainer>
    </InnerDiv>
  </StyledBox>
);
