import { createReducer } from "common/ReduxUtils";
import { GeneratedOrder } from "freight/types/GeneratedOrder";
import { FreightBookingActionTypes } from "../actions/FreightBookingActionTypes";

export type FreightGeneratedOrdersState = GeneratedOrder[] | null;

export const freightGeneratedOrdersInitialState = null;

export const freightGeneratedOrdersReducer = createReducer(freightGeneratedOrdersInitialState, {
  [FreightBookingActionTypes.FREIGHT_ORDERS_GENERATED]: (_, action) => action.orders,
});
