import { SPThunkAction } from "common/ReduxUtils";
import { getSellerId } from "common/user/UserSelectors";
import { selectIsTestSeller } from "organization/OrganizationSelectors";
import { addHeapUserProperties } from "common/utils/heap/addHeapUserProperties";
import { identifyUserToHeap } from "common/utils/heap/identifyUserToHeap";

export const initializeHeap = (): SPThunkAction => (_, getState) => {
  const state = getState();
  const sellerId = getSellerId(state);
  const isTestSeller = selectIsTestSeller({ isLoose: true })(state);

  identifyUserToHeap(sellerId);
  addHeapUserProperties({
    isTestSeller: isTestSeller ? "true" : "false",
  });
};
