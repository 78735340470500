import { QcItemStatus, QcReturnItemMinimal, ReturnStatus } from "@deliverr/returns-client";
import { QcStatus, ReturnsQcItem } from "returns/detail/store/ReturnsDetailStore.types";

import { forEach } from "lodash";

export const isQcEnabled = (qcId: string | undefined) => (qcId ?? "Self") !== "Self";

export const isQcEnabledAndStarted = (qcId: string | undefined, status: ReturnStatus) =>
  (qcId ?? "Self") !== "Self" && [ReturnStatus.ARRIVED, ReturnStatus.PROCESSED].includes(status);

export const isQcEnabledAndProcessed = (qcId: string | undefined, status: ReturnStatus) =>
  (qcId ?? "Self") !== "Self" && status === ReturnStatus.PROCESSED;

const getQcProcessedStatus = (approvedItems: number, totalItems: number) =>
  approvedItems === totalItems ? QcStatus.PASSED_INSPECTION : QcStatus.SOME_UNITS_WILL_NOT_BE_RESTOCKED;

const getQcCompletedStatus = (approvedItems: number, totalItems: number) => {
  if (approvedItems === totalItems) {
    return QcStatus.RESTOCKED;
  } else if (approvedItems === 0) {
    return QcStatus.NOT_RESTOCKABLE;
  }
  return QcStatus.PARTIALLY_RESTOCKED;
};

export const getQcStatus = (status: ReturnStatus, inspectedItems: QcReturnItemMinimal[]) => {
  let totalItems: number = 0;
  let approvedItems: number = 0;

  forEach(inspectedItems, (inspectedItem) => {
    totalItems += inspectedItem.quantity;
    if (inspectedItem.status === QcItemStatus.INSPECTION_APPROVED) {
      approvedItems += inspectedItem.quantity;
    }
  });

  if (status === ReturnStatus.PROCESSED) {
    return getQcProcessedStatus(approvedItems, totalItems);
  }
  return getQcCompletedStatus(approvedItems, totalItems);
};

export const aggregateItemsByDskuAndInspectionStatus = (returnsQcItems: ReturnsQcItem[]) => {
  return Object.values(
    returnsQcItems.reduce((acc, item) => {
      const key = String(item.dsku) + String(item.status?.toString()) + String(item.isUnexpected);
      if (!acc[key]) {
        acc[key] = { ...item };
      } else {
        acc[key].quantity = Number(acc[key].quantity) + Number(item.quantity);
      }
      return acc;
    }, {})
  ) as ReturnsQcItem[];
};

export const aggregateUnknownItemsByBarcodeAndStatus = (qcItems: QcReturnItemMinimal[]): ReturnsQcItem[] => {
  const aggregatedOutput = qcItems.reduce<{ [key: string]: ReturnsQcItem }>((acc, qcItem) => {
    const key = `${qcItem.barcode}_${qcItem.status}`;

    if (key in acc) {
      acc[key].quantity = (acc[key].quantity ?? 0) + qcItem.quantity;
    } else {
      const outputItem: ReturnsQcItem = {
        dsku: qcItem.dsku,
        status: qcItem.status,
        quantity: qcItem.quantity,
        isUnknown: true,
        isUnexpected: true,
        barcode: qcItem?.barcode,
      };
      acc[key] = outputItem;
    }

    return acc;
  }, {});

  const outputItems: ReturnsQcItem[] = Object.values(aggregatedOutput);
  return outputItems;
};
