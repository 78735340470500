import React from "react";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";
import { Icon } from "../Icon";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { ThemeProps } from "../shared";
import { FormattedMessage } from "../FormattedMessage";
import { isMobile } from "../shared/helpers/utils";

export interface PaginationProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Current page, zero-based */
  currentPage: number;
  /** Initial page selected, zero-based, defaults to zero if not passed */
  initialPage?: number;
  /** Fn to call when any page is clicked. */
  onPageChange: (page: number) => void;
  /** The number of pages to display for margins. */
  marginPagesDisplayed?: number;
  /** The range of ages to display. */
  pageRangeDisplayed?: number;
  /** Describes how many items we want to display per page. */
  itemsPerPage?: number;
  /** The total number of items in the list - we use this along with ${itemsPerPage} to calculate the total no. of pages. */
  totalItemsCount: number;
  /** Describes type of pagination, ie. 31-40 of 100 ${entity} */
  entity?: JSX.Element | string;
  /** Carryover props to ReactPaginate */
  reactPaginateProps?: Omit<
    ReactPaginateProps,
    | "initialPage"
    | "onPageChange"
    | "marginPagesDisplayed"
    | "pageRangeDisplayed"
    | "pageCount"
    | "previousLabel"
    | "nextLabel"
    | "breakLabel"
  >;
}

const paginationItemDim = "24px";

const PaginationWrapper = styled.div<ThemeProps>(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${theme.colors.NEUTRAL["00"]};
    font-size: ${theme.font.size.F2};

    ul {
      display: inline-flex;
    }

    .previous,
    .next {
      color: ${theme.colors.NEUTRAL[300]};

      & svg {
        width: 6.2px;
      }
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: ${theme.colors.NEUTRAL[300]};
      border-radius: ${theme.border.radius.R2};
      margin: 0 6px;
      min-height: ${paginationItemDim};
      min-width: ${paginationItemDim};

      a {
        padding: ${theme.spacing.S1} ${theme.spacing.S2};
        color: ${theme.colors.NEUTRAL[300]};
        outline: none;
      }

      &:hover:not(.activePagination, .next, .previous) {
        background-color: ${theme.colors.NEUTRAL[40]};
      }
    }

    .activePagination {
      background-color: ${theme.colors.NEUTRAL[500]};
      color: ${theme.colors.NEUTRAL["00"]};
      a {
        color: ${theme.colors.NEUTRAL["00"]};
      }
    }

    .disabled {
      color: ${theme.colors.NEUTRAL[100]};
      cursor: not-allowed;
      a {
        color: ${theme.colors.NEUTRAL[100]};
        cursor: not-allowed;
      }
    }

    ${isMobile(theme) &&
    `
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      li:not(.previous):not(.next) {
        display: none;
      }

      .pagination-details {
        width: calc(100% - ${paginationItemDim} - ${paginationItemDim} - ${theme.spacing.S3});
        position: absolute;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    `}
  `
);

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  initialPage = 0,
  onPageChange,
  marginPagesDisplayed = 2,
  pageRangeDisplayed = 2,
  totalItemsCount,
  itemsPerPage = 10,
  entity,
  reactPaginateProps,
  ...props
}) => {
  const totalPages = Math.ceil(totalItemsCount / Math.min(itemsPerPage, totalItemsCount));
  const start = currentPage * itemsPerPage + 1;
  const end = Math.min(start + Math.min(itemsPerPage - 1, totalItemsCount), totalItemsCount);

  return (
    <PaginationWrapper data-testid="pagination-wrapper" {...props}>
      <span data-testid="pagination-details" className="pagination-details">
        {start}-{end} <FormattedMessage defaultMessage="of" id="of" /> {totalItemsCount} {entity}
      </span>
      <ReactPaginate
        previousLabel={<Icon type="chevron-left" />}
        nextLabel={<Icon type="chevron-right" />}
        breakLabel="..."
        pageCount={totalPages}
        initialPage={initialPage}
        marginPagesDisplayed={marginPagesDisplayed}
        pageRangeDisplayed={pageRangeDisplayed}
        onPageChange={({ selected }: { selected: number }) => {
          onPageChange(selected);
        }}
        activeClassName="activePagination"
        {...reactPaginateProps}
      />
    </PaginationWrapper>
  );
};
