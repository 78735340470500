import { Appearance, Box, Notification } from "common/components/ui";
import React from "react";
import { FormattedMessage } from "react-intl";

export const StorageNewWarehouseInboundWarning = () => (
  <Box marginBottom={"S7"} width="70%">
    <Notification
      appearance={Appearance.WARNING}
      content={{
        title: (
          <FormattedMessage
            id="storage.inbounds.storageDestination.newWarehouseWarningTitle"
            defaultMessage="The location you last shipped to is not currently accepting inbounds."
          />
        ),
        description: (
          <FormattedMessage
            id="storage.inbounds.storageDestination.newWarehouseWarningDescription"
            defaultMessage="Sending inventory to a different location may result in the need to create split shipments later on."
          />
        ),
      }}
    />
  </Box>
);
