import { Title } from "common/components/ui";
import React from "react";
import { FormattedMessage } from "react-intl";

export const CreateStorageInboundNavBarTitle = () => (
  <Title displayAs="h4" as="span">
    <FormattedMessage
      id="inbounds.navBar.createReserveStorageInbound"
      defaultMessage="Create Reserve Storage inbound"
    />
  </Title>
);
