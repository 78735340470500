import log from "Logger";

// from https://developers.heap.io/reference/identify
export const identifyUserToHeap = (userId: string) => {
  const identify = (window as any).heap?.identify;

  if (!identify) {
    log.warn({ fn: "identifyUserToHeap" }, "heap identify not available");
    return;
  }

  identify(userId);
};
