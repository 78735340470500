// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdsSettings__inner--O37l7 {\n  padding-left: 0;\n  padding-right: 0;\n  margin: 0 auto;\n}\n.AdsSettings__adsSettings--BEbPl {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin-top: 40px;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  gap: 30px;\n}\n.AdsSettings__enableInstagramAdsSwitch--AOjWS {\n  display: -webkit-inline-box;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  gap: 8px;\n  margin-bottom: 20px;\n  font-weight: 700;\n}\n.AdsSettings__disabled--R5ka8 {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://./src/app/tags/facebook/connect/pages/AdsSettings/AdsSettings.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AACF;AAEA;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,gBAAA;EACA,4BAAA;EAAA,6BAAA;MAAA,0BAAA;UAAA,sBAAA;EACA,SAAA;AAAF;AAGA;EACE,2BAAA;EAAA,2BAAA;EAAA,oBAAA;EACA,yBAAA;MAAA,sBAAA;UAAA,mBAAA;EACA,QAAA;EACA,mBAAA;EACA,gBAAA;AADF;AAIA;EACE,aAAA;AAFF","sourcesContent":[".inner {\n  padding-left: 0;\n  padding-right: 0;\n  margin: 0 auto;\n}\n\n.adsSettings {\n  display: flex;\n  margin-top: 40px;\n  flex-direction: column;\n  gap: 30px;\n}\n\n.enableInstagramAdsSwitch {\n  display: inline-flex;\n  align-items: center;\n  gap: 8px;\n  margin-bottom: 20px;\n  font-weight: 700;\n}\n\n.disabled {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": "AdsSettings__inner--O37l7",
	"adsSettings": "AdsSettings__adsSettings--BEbPl",
	"enableInstagramAdsSwitch": "AdsSettings__enableInstagramAdsSwitch--AOjWS",
	"disabled": "AdsSettings__disabled--R5ka8"
};
export default ___CSS_LOADER_EXPORT___;
