import { DeliverrLtlShipment } from "inbounds/InboundTypes";
import { Dictionary } from "lodash";
import { initialDeliverrLtlShipment } from "../reducers/LtlReducer";

/**
 * Generates the initial boilerplate required for an LTL inbound for every shipment id on a plan
 */
export const initLtlById = (shipmentIds: number[]): Dictionary<DeliverrLtlShipment> =>
  shipmentIds.reduce((byIdMap, id) => {
    byIdMap[id] = initialDeliverrLtlShipment;
    return byIdMap;
  }, {});
