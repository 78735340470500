import React from "react";
import NumberFormat, { NumberFormatPropsBase } from "react-number-format";
import styled from "@emotion/styled";
import { DefaultTheme } from "../shared";
import { transitionFast } from "../shared/helpers/css";
import { extractFormProps, FormGroup, FormGroupProps } from "../FormGroup";

export interface FormattedNumberInputProps extends FormGroupProps<NumberFormatPropsBase> {
  currency?: string;
}

const InputContainer = styled.div<FormattedNumberInputProps & { defaultValue?: any }, DefaultTheme>(
  ({ hasError, disabled, theme }) => `
  position: relative;
  height: ${theme.input.height};
  display: flex;
  border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
  border-radius: ${theme.border.radius.R2};
  :focus-within {
    border-color: ${theme.colors.BLUE[300]};
    box-shadow: ${theme.input.focus.BLUE};
  }
  ${
    hasError &&
    `
    border-color: ${theme.colors.RED[300]};
    :focus-within {
        border-color: ${theme.colors.RED[300]};
        box-shadow: ${theme.input.focus.RED};
    }
    `
  }
  ${
    disabled &&
    `
    border-color: ${theme.colors.NEUTRAL[100]};
    `
  }
`
);

const StyledInput = styled(NumberFormat)<FormattedNumberInputProps, DefaultTheme>(
  ({ disabled, theme }) => `
  color: ${theme.colors.NEUTRAL[500]};
  font-family: ${theme.font.family.STANDARD!};
  line-height: ${theme.font.lineHeight.LH2};
  padding: ${theme.spacing.S2} ${theme.spacing.S3};
  border-radius: ${theme.border.radius.R2};
  outline: none;
  max-width: 100%;
  height: 100%;
  width: 100%;
  text-align: left;
  border: none;
  background-color: white;
  ${transitionFast("border-color")};
  ${
    disabled &&
    `
      background-color: ${theme.colors.NEUTRAL[60]};
      cursor: not-allowed;
    `
  }
`
);

export const FormattedNumberInput = React.forwardRef<HTMLInputElement, FormattedNumberInputProps>(
  ({ allowNegative = false, decimalScale = 2, thousandSeparator = true, fixedDecimalScale = true, ...props }, ref) => {
    const [formProps, { hasError, ...inputProps }] = extractFormProps(props);
    return (
      <FormGroup {...formProps}>
        <InputContainer hasError={hasError} disabled={props.disabled}>
          <StyledInput
            getInputRef={ref}
            thousandSeparator={thousandSeparator}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            allowNegative={allowNegative}
            onClick={({ currentTarget }) => currentTarget.select()}
            {...inputProps}
          />
        </InputContainer>
      </FormGroup>
    );
  }
);

FormattedNumberInput.displayName = "FormattedNumberInput";
