export enum ButtonMessage {
  Dismiss = "button.dismiss",
  Update = "button.update",
}

export enum ErrorMessage {
  CompanyTaken = "errors.companyTaken",
  Default = "errors.default",
  FillInFields = "errors.fillInFields",
  InvalidAccessCode = "error.invalidAccessCode",
  InvalidAddress = "error.invalidAddress",
  InvalidDateRange = "errors.invalidDateRange",
  InvalidDistributionCenter = "errors.invalidDistributionCenter",
  InvalidField = "errors.invalidField",
  InvalidLogin = "errors.invalidLogin",
  InvalidRateTables = "errors.invalidRateTables",
  InvalidSellbrite = "errors.invalidSellbrite",
  InvalidSignupCode = "errors.invalidSignupCode",
  InvalidShipFrom = "errors.invalidShipFrom",
  InvalidWalmartApiKeys = "errors.walmartInvalid",
  MissingAccount = "errors.missingAccount",
  DefaultErrorModalBody = "errors.modal.body.default",
  NewOrderStockout = "errors.newOrderStockout",
  UnableToCancelOrder = "errors.unableToCancelOrder",
}

export enum FieldMessage {
  Name = "fields.name",
  Company = "fields.company",
  Email = "fields.email",
  Phone = "fields.phone",
  AccountToken = "fields.accountToken",
  IntegrationKey = "fields.integrationKey",
  RegisteredEmail = "fields.registeredEmail",
  ApiKey = "fields.apiKey",
  ApiSecret = "fields.apiSecret",
  VerificationCode = "fields.verificationCode",
  SignupCode = "fields.signupCode",
  EstimatedDeliveryDate = "fields.estimatedDeliveryDate",
}

export enum Message {
  DeactivateEgd = "messages.egd.deactivateEgd",
}

export enum PlaceholderMessage {
  Company = "placeholders.company",
  Email = "placeholders.email",
  Name = "placeholders.name",
  Phone = "placeholders.phone",
  Password = "placeholders.password",
  ConfirmPassword = "placeholders.confirmPassword",
  Select = "placeholders.select",
  VerificationCode = "placeholders.verificationCode",
  SignupCode = "placeholders.signupCode",
}

export const messages = {
  // buttons
  [ButtonMessage.Dismiss]: "Dismiss",
  [ButtonMessage.Update]: "Update",

  // errors
  [ErrorMessage.CompanyTaken]: "We already have an account with that email",
  [ErrorMessage.Default]: "Oops, something went wrong",
  [ErrorMessage.FillInFields]: "Fields must not be empty",
  [ErrorMessage.InvalidAccessCode]: "Invalid access code",
  [ErrorMessage.InvalidAddress]: "Please enter a valid address",
  [ErrorMessage.InvalidDateRange]: "Invalid date range",
  [ErrorMessage.InvalidDistributionCenter]: "Please verify that Flexport distribution center was added correctly",
  [ErrorMessage.InvalidField]: "Invalid field: {fieldName}",
  [ErrorMessage.InvalidLogin]: "Oops, wrong email or password",
  [ErrorMessage.InvalidRateTables]: "Not all rate tables found. Please try running the egd browser extension again.",
  [ErrorMessage.InvalidSellbrite]: "Please double check your account token and api secret",
  [ErrorMessage.InvalidSignupCode]: "Please enter a valid signup code",
  [ErrorMessage.InvalidShipFrom]: "Please check your ship from address.",
  [ErrorMessage.InvalidWalmartApiKeys]: "Please double check that your api keys are correct.",
  [ErrorMessage.MissingAccount]: "We don't have an account with that email",
  [ErrorMessage.DefaultErrorModalBody]:
    "Something went wrong. Please try your last action again.\n" +
    "If the problem persists, please file a support ticket using the help icon.",
  [ErrorMessage.NewOrderStockout]: "At least one selected product is out of stock.",
  [ErrorMessage.UnableToCancelOrder]: "Sorry, we could not cancel your order",

  // field names
  [FieldMessage.Name]: "Full Name",
  [FieldMessage.Company]: "Company",
  [FieldMessage.Email]: "Email",
  [FieldMessage.Phone]: "Business Phone Number",
  [FieldMessage.AccountToken]: "Account Token",
  [FieldMessage.IntegrationKey]: "Integration Key",
  [FieldMessage.RegisteredEmail]: "Registered Email",
  [FieldMessage.ApiKey]: "API Key",
  [FieldMessage.ApiSecret]: "API Secret",
  [FieldMessage.VerificationCode]: "Verification Code",
  [FieldMessage.SignupCode]: "Signup Code",
  [FieldMessage.EstimatedDeliveryDate]: "Estimated delivery date",

  // messages
  [Message.DeactivateEgd]: "Successfully deactivated eBay Guaranteed Delivery",

  // placeholders
  [PlaceholderMessage.Company]: "Acme, Inc",
  [PlaceholderMessage.Email]: "yourname@company.com",
  [PlaceholderMessage.Name]: "First & Last Name",
  [PlaceholderMessage.Phone]: "###-###-####",
  [PlaceholderMessage.Password]: "Must be 8 characters or more",
  [PlaceholderMessage.ConfirmPassword]: "Making sure it's the same",
  [PlaceholderMessage.Select]: "Select one...",
  [PlaceholderMessage.VerificationCode]: "######",
  [PlaceholderMessage.SignupCode]: "#########",
};
