import { InboundShipmentItem } from "@deliverr/legacy-inbound-client";
import { getLoadedShipment } from "inbounds/store/selectors/shipments/getLoadedShipment";
import { keyBy } from "lodash/fp";
import { createSelector } from "reselect";
import { RootState } from "RootReducer";

export const getLoadedShipmentItemsByDsku = createSelector<
  RootState,
  InboundShipmentItem[],
  { [dsku: string]: InboundShipmentItem }
>(
  (rootState) => getLoadedShipment(rootState).items,
  (items) => keyBy("dsku", items)
);
