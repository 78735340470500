import React from "react";
import { FormattedMessage } from "react-intl";

import { StepAccordionSpec } from "common/components/AccordionFlow/AccordionFlowTypes";
import { UnknownBarcodeIdentify } from "./steps";

export enum UnknownBarcodeSteps {
  IDENTIFY_PRODUCT = "IDENTIFY_PRODUCT",
}

export const UNKNOWN_BARCODE_STEPS: StepAccordionSpec[] = [
  {
    id: UnknownBarcodeSteps.IDENTIFY_PRODUCT,
    title: (
      <FormattedMessage id="nonCompliance.resolutionSteps.identifyProductTitle" defaultMessage="Identify Product" />
    ),
    content: <UnknownBarcodeIdentify />,
  },
];
