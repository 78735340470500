import { FormGroup, FormGroupProps, extractFormProps } from "../FormGroup";
import ReactSelect, { Props, SelectComponentsConfig, components } from "react-select";

import { DefaultTheme } from "../shared";
import { ERROR_COLOR } from "../Input";
import { IconV2 } from "../IconV2";
import React from "react";
import { isMobile } from "../shared/helpers/utils";
import { useTheme } from "emotion-theming";

export interface SelectOptionType {
  label: string;
  value: string;
}

export interface SelectProps extends FormGroupProps<Exclude<Props<any>, "theme" | "onChange">> {
  /** Select width grows or shrinks according to selection. Please set min-width */
  flexWidth?: boolean;
  customComponents?: SelectComponentsConfig<any>;
  value?: any;
  pluralEntityName?: string;
  multiple?: boolean;
  small?: boolean;
  large?: boolean;
  controlHeight?: number;
  onChange: (value: any) => void;
  id?: string;
}

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    {props.selectProps.menuIsOpen ? <IconV2 type="angle-up" /> : <IconV2 type="angle-down" />}
  </components.DropdownIndicator>
);

export const container = (flexWidth?: boolean) => (styles, state) => ({
  ...styles,
  pointerEvents: "inherit",
  cursor: state.isDisabled ? "not-allowed" : "pointer",
  display: flexWidth ? "inline-block" : "inherit",
});

export const control =
  (theme: DefaultTheme, hasError: boolean, mobile: boolean, controlHeight?: number) => (styles, state) => ({
    ...styles,
    cursor: "pointer",
    color: state.isDisabled ? theme.colors.NEUTRAL[300] : theme.colors.NEUTRAL[500],
    backgroundColor: state.isDisabled ? theme.colors.NEUTRAL[60] : theme.colors.NEUTRAL["00"],
    pointerEvents: state.isDisabled ? "none" : "inherit",
    borderRadius: mobile ? theme.border.radius.R3 : theme.border.radius.R2,
    borderColor: state.isDisabled
      ? theme.colors.NEUTRAL[100]
      : hasError
      ? ERROR_COLOR(theme)
      : state.isFocused
      ? theme.colors.BLUE[300]
      : theme.colors.NEUTRAL[80],
    boxShadow: state.isFocused ? (hasError ? theme.input.focus.RED : theme.input.focus.BLUE) : "none",
    fontSize: mobile ? theme.font.size.F3 : theme.font.size.F2,
    transition: "all .2s ease-in-out",
    minHeight: theme.input.height,
    ":hover": {
      borderColor: hasError ? ERROR_COLOR(theme) : theme.colors.BLUE[300],
    },
    height: controlHeight ?? styles.height,
    flexWrap: "nowrap",
  });

export const input = (flexWidth?: boolean) => (styles) => {
  const flexWidthStyles = flexWidth
    ? {
        width: "100%",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
      }
    : {};
  return {
    ...styles,
    margin: 0,
    ...flexWidthStyles,
  };
};

export const valueContainer = (theme: DefaultTheme, mobile: boolean, isSearchable?: boolean) => (styles) => ({
  ...styles,
  padding: mobile ? `${theme.spacing.S3} ${theme.spacing.S4}` : `${theme.spacing.S2} ${theme.spacing.S4}`,
  cursor: isSearchable ? "text" : "pointer",
  color: theme.colors.NEUTRAL[300],
});

export const singleValue = (theme: DefaultTheme, flexWidth?: boolean, multiple?: boolean) => (styles) => {
  const flexWidthStyles = flexWidth
    ? {
        transform: "none",
        position: "relative",
        overflow: "visible",
      }
    : {};
  return {
    ...styles,
    color: theme.colors.NEUTRAL[500],
    fontFamily: theme.font.family.STANDARD,
    ...flexWidthStyles,
  };
};

export const optionColors = (theme: DefaultTheme) => ({
  backgroundColor: theme.colors.NEUTRAL["00"],
  hoverBackgroundColor: theme.colors.NEUTRAL[60],
  selected: {
    backgroundColor: theme.colors.NEUTRAL[40],
    hoverBackgroundColor: theme.colors.NEUTRAL[80],
  },
});

export const option = (theme: DefaultTheme) => (styles, state) => ({
  ...styles,
  backgroundColor: state.isSelected
    ? optionColors(theme).selected.backgroundColor
    : optionColors(theme).backgroundColor,
  fontFamily: theme.font.family.STANDARD,
  cursor: "pointer",
  color: theme.colors.NEUTRAL[500],
  padding: `10px ${theme.spacing.S3}`,
  label: {
    color: theme.colors.NEUTRAL[500],
  },
  ":hover": {
    backgroundColor: state.isSelected
      ? optionColors(theme).selected.hoverBackgroundColor
      : optionColors(theme).hoverBackgroundColor,
  },
});

export const menu = (theme: DefaultTheme, mobile: boolean) => (styles) => ({
  ...styles,
  marginTop: theme.spacing.S1,
  borderColor: theme.colors.NEUTRAL[80],
  borderRadius: mobile ? theme.border.radius.R4 : theme.border.radius.R3,
  boxShadow: mobile ? theme.shadow.S3 : styles.boxShadow,
  fontSize: mobile ? theme.font.size.F3 : theme.font.size.F2,
  zIndex: 3,
  width: "auto",
  minWidth: "100%",
});

export const menuList = (theme: DefaultTheme) => (styles) => ({
  ...styles,
  color: theme.colors.NEUTRAL[500],
  padding: 4,
  label: {
    color: theme.colors.NEUTRAL[500],
  },
});

export const placeholder = (theme: DefaultTheme, multiple?: boolean) => (styles) => ({
  ...styles,
  color: multiple ? theme.colors.NEUTRAL[500] : theme.colors.NEUTRAL[300],
  marginLeft: multiple ? 2 : 0,
  marginRight: 0,
});

export const dropdownIndicator = (theme: DefaultTheme, mobile: boolean) => (styles) => ({
  ...styles,
  color: theme.colors.NEUTRAL[300],
  padding: mobile ? `0 ${theme.spacing.S4}` : `0 ${theme.spacing.S2}`,
  fontSize: mobile ? theme.font.size.F5 : styles.fontSize,
});

export const indicatorSeparator = (styles) => ({
  ...styles,
  display: "none",
});

export const indicatorContainer = (styles) => ({
  ...styles,
  padding: `0 12px`,
});

export const selectStyles: (
  theme: DefaultTheme,
  hasError: boolean,
  flexWidth?: boolean,
  multiple?: boolean,
  isSearchable?: boolean,
  controlHeight?: number
) => Props["styles"] = (theme, hasError, flexWidth, multiple, isSearchable, controlHeight) => {
  const mobile = isMobile(theme);
  return {
    container: container(flexWidth),
    control: control(theme, hasError, mobile, controlHeight),
    input: input(flexWidth),
    valueContainer: valueContainer(theme, mobile, isSearchable),
    singleValue: singleValue(theme, flexWidth, multiple),
    option: option(theme),
    menu: menu(theme, mobile),
    menuList: menuList(theme),
    placeholder: placeholder(theme, multiple),
    dropdownIndicator: dropdownIndicator(theme, mobile),
    indicatorContainer,
    indicatorSeparator,
  };
};

export const BaseSelect: React.FC<SelectProps> = (props) => {
  const theme = useTheme<DefaultTheme>();
  const [formProps, { flexWidth, ...selectProps }] = extractFormProps(props);
  const customComponents = props.customComponents || {};
  return (
    <FormGroup {...formProps} flexWidth={flexWidth}>
      <ReactSelect
        isDisabled={Boolean(props.disabled)}
        menuPlacement="auto"
        components={{ DropdownIndicator, ...customComponents }}
        styles={selectStyles(
          theme,
          formProps.hasError,
          flexWidth,
          props.multiple,
          props.isSearchable,
          props.controlHeight
        )}
        {...selectProps}
      />
    </FormGroup>
  );
};

export const SelectComponents = components;
