import { WishAuth, wishAuthenticated, wishNotAuthenticated } from "./authentication";

import { createReducer, ReducerDictionary } from "common/ReduxUtils";
import { WishSeller } from "common/clients/wish/WishSeller/WishSeller";
import { WishSellerValidation } from "./validation";
import { WishActionTypes } from "./WishActions";

export interface WishState {
  auth: WishAuth;
  validation: WishSellerValidation;
  fastTagActive: boolean;
  seller?: WishSeller;
}

export const wishInitialState = {
  auth: wishNotAuthenticated,
  validation: WishSellerValidation.None,
  fastTagActive: false,
};

export const reducers: ReducerDictionary<WishState> = {
  [WishActionTypes.WISH_CODE_RECEIVED]: (state, { code }) => ({
    ...state,
    auth: wishAuthenticated(code),
  }),
  [WishActionTypes.WISH_SELLER_VALIDATED]: (state, { success }) => ({
    ...state,
    validation: success ? WishSellerValidation.Successful : WishSellerValidation.Failed,
  }),
  [WishActionTypes.WISH_FAST_TAGS_ACTIVE_CHANGED]: (state, { active }) => ({
    ...state,
    fastTagActive: active,
  }),
  [WishActionTypes.WISH_SELLER_RECEIVED]: (state, { seller }) => ({
    ...state,
    seller,
    fastTagActive: seller.isTwoDay,
  }),
};

export const wishReducer = createReducer<WishState>(wishInitialState, reducers);
