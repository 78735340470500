import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { defineMessages } from "react-intl";
import styled from "@emotion/styled";
import { Box, Radio, Stack, ThemeProps } from "common/components/ui";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { LocalizedText } from "common/components/LocalizedText";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { InfoIcon } from "common/components/icons";
import { CargoInputType } from "inbounds/createShipment/CreateShipmentTypes";

const MESSAGES = defineMessages({
  prompt: {
    id: "inbounds.createShipment.cargoDetails.cargoInputType.prompt",
    defaultMessage: "Provide shipment and product information in one of the following formats:",
  },
  byProducts: {
    id: "inbounds.createShipment.cargoDetails.cargoInputType.byProducts",
    defaultMessage: "By products",
  },
  byTotals: {
    id: "inbounds.createShipment.cargoDetails.cargoInputType.byTotals",
    defaultMessage: "By shipment total volume and weight",
  },
});

export const RadioLabel = styled(LocalizedText)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[500]};
`
);

export const CargoInputTypeSelector = () => {
  const { control } = useFormContext();
  const { field } = useController({
    name: CreateShipmentInputName.CARGO_INPUT_TYPE,
    control,
  });

  const handleChange = (value: string) => {
    field.onChange(value);
  };

  return (
    <div>
      <LocalizedText message={MESSAGES.prompt} />
      <Box marginTop="S2">
        <Stack direction="HORIZONTAL">
          <Radio
            {...field}
            label={<RadioLabel message={MESSAGES.byProducts} />}
            value={CargoInputType.PRODUCTS}
            checked={field.value === CargoInputType.PRODUCTS}
            onChange={() => handleChange(CargoInputType.PRODUCTS)}
          />
          <Radio
            {...field}
            label={<RadioLabel message={MESSAGES.byTotals} />}
            value={CargoInputType.TOTALS}
            checked={field.value === CargoInputType.TOTALS}
            onChange={() => handleChange(CargoInputType.TOTALS)}
          />
          <InfoIcon
            messageWidth={200}
            wrap
            placement={"top"}
            message={
              <FormattedMessage
                id="inbounds.createShipment.steps.cargoDetails.byTotalVolumeAndWeight.tooltip"
                defaultMessage="You will be able to enter your product information after you complete your booking, as this is required ahead of us receiving your cargo into our Fulfillment Network."
              />
            }
          />
        </Stack>
      </Box>
    </div>
  );
};
