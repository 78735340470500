import { defaultTheme } from "./theme";
import produce from "immer";
import { LetterSpacing } from "./font";

export const mobileTheme = produce(defaultTheme, (draft) => {
  draft.name = "mobile";
  draft.font.letterSpacing = {
    [LetterSpacing.LS0]: "0rem",
    [LetterSpacing.LS1]: "0rem",
    [LetterSpacing.LS2]: "0rem",
    [LetterSpacing.LS3]: "0rem",
    [LetterSpacing.LS4]: "0.0437rem",
    [LetterSpacing.LS5]: "0.0625rem",
  };
  draft.input.height = "3rem";
});
