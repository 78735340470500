import { createSelector } from "reselect";
import { RootState } from "RootReducer";
import { selectTransferCreate } from "./TransferCreateSelectors";
import { TransferCreateState } from "./TransferCreateState";
import { every } from "lodash/fp";
import { SelectedProductData } from "common/components/ProductChooser";

export const selectHasKittedProducts = createSelector<RootState, TransferCreateState, boolean>(
  selectTransferCreate,
  (transferCreate) => {
    const { selectedProducts } = transferCreate;
    return every((product: SelectedProductData) => product.isKit ?? false, selectedProducts);
  }
);
