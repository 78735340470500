import Checkbox from "common/components/Checkbox";
import Content from "common/components/Content";
import { EmptySpace } from "common/components/EmptySpace";
import Modal from "common/components/Modal";
import { hideModal } from "common/components/Modal/ModalActions";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";
import { stopPropagation } from "common/EventUtils";
import { ActionCreator } from "common/ReduxUtils";
import { getHelpCenterArticleUrl } from "common/support/helpCenter";
import { saveOldState } from "inbounds/InboundActions";
import InboundModalId from "inbounds/InboundModalId";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { RootState } from "RootReducer";
import {
  completeCompliance,
  toggleCompliance,
  clearProductCompliance,
  acceptAllComplianceCriteria,
} from "./ProductComplianceActions";
import { ExternalLink } from "common/components/ExternalLink";

import cls from "./ProductComplianceModal.less";
import { Stack } from "common/components/ui/Stack";

interface ParentProps {
  goToPreviousStep: () => void;
}

interface ReduxProps {
  hasAllConditionsMet: boolean;
  requirementText: { [name: string]: string };
  compliantWithRequirements: { [name: string]: boolean };
  isBatteriesEnabled: boolean;
}

interface DispatchProps {
  hideModal: ActionCreator;
  toggleCompliance: ActionCreator;
  completeCompliance: ActionCreator;
  clearProductCompliance: ActionCreator;
  saveOldState: ActionCreator;
  acceptAllComplianceCriteria: ActionCreator;
}

export const productComplianceModalId = "ProductComplianceModal";

class ProductComplianceModal extends React.Component<ParentProps & ReduxProps & DispatchProps, {}> {
  public hazmatLabelGuideLink =
    "https://www.fmcsa.dot.gov/" +
    "regulations/hazardous-materials/hazardous-materials-markings-labeling-and-placarding-guide-dot-chart";

  public checkRequirement = (name: string) => () => this.props.toggleCompliance(name);
  public onAccept = () => {
    this.props.completeCompliance();
    this.props.hideModal(InboundModalId.ProductCompliance);
  };

  public onCancel = () => {
    this.props.clearProductCompliance();
    this.props.goToPreviousStep();
    this.props.hideModal(InboundModalId.ProductCompliance);
  };

  public render() {
    return (
      <Modal id={productComplianceModalId} hasCloseButton={false} backdrop={"static"} size={"md"} keyboard={false}>
        <div className={cls.productComplianceModal}>
          <Content
            title={
              <FormattedMessage
                id={"inbounds.productComplianceModal.title"}
                defaultMessage={"Are your products compliant?"}
              />
            }
            body={
              <div>
                <div>
                  <FormattedMessage
                    id={"inbounds.productComplianceModal.description"}
                    defaultMessage={`Your products must meet the criteria below.
                  Products that do not meet the below criteria will be immediately
                  returned to sender and charged a noncompliance fee.`}
                  />
                </div>
                <ul className={cls.requirementsList}>
                  <li>
                    <Stack onClick={this.props.acceptAllComplianceCriteria}>
                      <FormattedMessage
                        id={"inbounds.productComplianceModal.eachNewItem"}
                        defaultMessage={`Each new individual item I'm sending...`}
                      />
                      <div className={cls.acceptAll}>
                        <Checkbox
                          className={cls.boxSizeCheckbox}
                          data-testid="compliance_checkbox_all"
                          checked={this.props.hasAllConditionsMet}
                        />
                        <em>
                          <FormattedMessage
                            id={"inbounds.productComplianceModal.acceptAll"}
                            defaultMessage={"Accept all"}
                          />
                        </em>
                      </div>
                    </Stack>
                  </li>
                  {Object.entries(this.props.requirementText).map(([id, defaultDesc]) => (
                    <li
                      onClick={this.checkRequirement(id)}
                      key={id}
                      className={this.props.compliantWithRequirements[id] ? cls.checked : ""}
                    >
                      <Checkbox
                        checked={this.props.compliantWithRequirements[id]}
                        className={cls.boxSizeCheckbox}
                        data-testid="compliance_checkbox"
                      />
                      <div>
                        <FormattedMessage
                          id={`inbounds.productComplianceModal.requirement.${id}`}
                          defaultMessage={defaultDesc}
                        />
                        {id === "requirements" && (
                          <FormattedMessage
                            id="deliverrProductRestrictionPackaged"
                            defaultMessage={"{requirements} and requires no additional prep at the fulfillment center."}
                            values={{
                              requirements: (
                                <ExternalLink href={getHelpCenterArticleUrl("360056601114")}>
                                  <strong>
                                    <FormattedMessage
                                      id="inbounds.productComplianceModal.deliverrRequirements"
                                      defaultMessage="Flexport's requirements"
                                    />
                                  </strong>
                                </ExternalLink>
                              ),
                            }}
                          />
                        )}
                        {id === "hazmat" && (
                          <span>
                            <a
                              href={this.hazmatLabelGuideLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={stopPropagation}
                            >
                              <strong>US DOT</strong>
                            </a>
                            <EmptySpace />
                            (e.g. aerosols, SKUs with batteries
                            {this.props.isBatteriesEnabled && ` that can't ship by air, etc.)`}.
                            <EmptySpace />
                            Dangerous goods require special labeling, which Flexport cannot provide.
                          </span>
                        )}
                        {id === "illegal" && (
                          <ElevioBorderlessButton articleId={ElevioArticleId.RestrictedProducts}>
                            <FormattedMessage
                              id="deliverrProductRestriction"
                              defaultMessage={"Flexport product restrictions."}
                            />
                          </ElevioBorderlessButton>
                        )}
                        {id === "msds" && (
                          <FormattedMessage
                            id="deliverrProductRestrictionMSDS"
                            defaultMessage={
                              "{products} must be allowed to ship by the air and the MSDS and UN test summary documents must be approved before inbounding."
                            }
                            values={{
                              products: (
                                <ExternalLink href="https://deliverr.zendesk.com/knowledge/articles/360052449494/en-us?brand_id=2965128">
                                  <strong>
                                    <FormattedMessage
                                      id="inbounds.productComplianceModal.clickHere"
                                      defaultMessage="Products containing batteries"
                                    />
                                  </strong>
                                </ExternalLink>
                              ),
                            }}
                          />
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
                <div className={cls.buttonContainer}>
                  <button onClick={this.onCancel} className={`btn btn-primary-reversed`}>
                    <FormattedMessage
                      id={"inbounds.productComplianceModal.buttons.Cancel"}
                      defaultMessage="No, I want to go back"
                    />
                  </button>
                  <button
                    onClick={this.onAccept}
                    className={`btn btn-primary`}
                    disabled={!this.props.hasAllConditionsMet}
                    data-testid="compliance_confirm"
                  >
                    <FormattedMessage
                      id={"inbounds.productComplianceModal.buttons.Confirm"}
                      defaultMessage="Yes, I confirm and want to continue"
                    />
                  </button>
                </div>
              </div>
            }
          />
        </div>
      </Modal>
    );
  }
}

export default connect<ReduxProps, DispatchProps>(
  (state: RootState) => ({
    requirementText: state.productCompliance.requirementText,
    compliantWithRequirements: state.productCompliance.compliantWithRequirements,
    hasAllConditionsMet: state.productCompliance.hasAllConditionsMet,
    isBatteriesEnabled: state.productCompliance.isBatteriesEnabled,
  }),
  {
    completeCompliance,
    hideModal,
    toggleCompliance,
    clearProductCompliance,
    saveOldState,
    acceptAllComplianceCriteria,
  }
)(ProductComplianceModal);
