import React from "react";
import { useController } from "react-hook-form";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import { FormGroup } from "common/components/ui";
import { DatePicker } from "common/components/DatePicker";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { DEFAULT_DATE } from "freight/constants/cargoPickupConstants";
import { useSimpleFieldValidators } from "common/hooks/reactHookForm/useSimpleFieldValidators";
import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";
import { useMount } from "react-use";
import { isNull } from "lodash";
import { selectDeliveryDate } from "inbounds/createShipment/store/selectors";
import { useSelector } from "react-redux";
import { isBefore, addDays } from "date-fns";
import { InfoIcon } from "common/components/icons";

const FIELD_NAME = CreateShipmentInputName.CARGO_READY_DATE;

const VALIDATION_MESSAGES = defineMessages({
  required: {
    id: "createShipment.cargoReadyDate.error.required",
    defaultMessage: "Please enter a cargo ready date.",
  },
  dateBeforeDeliveryDate: {
    id: "createShipment.cargoReadyDate.error.dateBeforeDeliveryDate",
    defaultMessage: "This date should be prior to your target delivery date ({deliveryDate}).",
  },
  datePassed: {
    id: "createShipment.cargoReadyDate.error.datePassed",
    defaultMessage: "This date has passed. Select a new date.",
  },
});

export const CargoReadyDateInput = () => {
  const { formatMessage, formatDate } = useIntl();
  const { setValueIfUnsaved } = useCreateShipmentFormContext();
  const { validateRequired, validateFutureDate } = useSimpleFieldValidators();
  const { date: deliveryDate } = useSelector(selectDeliveryDate);
  const firstAvailableDate = addDays(new Date(), 1);

  const validateDateBeforeDeliveryDate = (date: Date) => {
    if (deliveryDate && !isBefore(date, deliveryDate)) {
      return formatMessage(VALIDATION_MESSAGES.dateBeforeDeliveryDate, {
        deliveryDate: formatDate(deliveryDate),
      });
    }
    return undefined;
  };

  const { field, fieldState } = useController({
    name: FIELD_NAME,
    rules: {
      validate: {
        required: validateRequired(formatMessage(VALIDATION_MESSAGES.required)),
        dateBeforeDeliveryDate: validateDateBeforeDeliveryDate,
        dateInFuture: validateFutureDate(formatMessage(VALIDATION_MESSAGES.datePassed)),
      },
    },
    defaultValue: DEFAULT_DATE,
  });

  const handleCargoReadyDateChange = async (date: Date) => {
    field.onChange({
      target: {
        name: FIELD_NAME,
        value: date,
      },
    });
  };

  useMount(() => {
    if (isNull(deliveryDate)) {
      setValueIfUnsaved(FIELD_NAME, DEFAULT_DATE);
    } else if (!isBefore(DEFAULT_DATE, deliveryDate)) {
      setValueIfUnsaved(FIELD_NAME, null);
    } else {
      setValueIfUnsaved(FIELD_NAME, DEFAULT_DATE);
    }
  });

  return (
    <FormGroup
      label={
        <FormattedMessage
          id="inbounds.createShipment.steps.cargoDetails.cargoReadyDateInput"
          defaultMessage="When will your products be ready for pickup? {dateTooltip}"
          values={{
            dateTooltip: (
              <InfoIcon
                messageWidth={200}
                wrap
                placement={"top"}
                message={
                  <FormattedMessage
                    id="inbounds.createShipment.steps.cargoDetails.cargoReadyDateInput.tooltip"
                    defaultMessage="We use this date to coordinate your shipment and recommend offerings that best fit your needs"
                  />
                }
              />
            ),
          }}
        />
      }
      hasError={fieldState.invalid}
      errorMessage={fieldState.error?.message}
    >
      <DatePicker
        date={field.value ?? undefined}
        minDate={firstAvailableDate}
        defaultActiveStartDate={firstAvailableDate}
        onDateChange={handleCargoReadyDateChange}
        hasError={fieldState.invalid}
        nextYearButton={null}
        prevYearButton={null}
      />
    </FormGroup>
  );
};
